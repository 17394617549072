import React, {useEffect, useState} from 'react'
import Modal from 'react-bootstrap/Modal';
import moment from "moment/moment";
import config from "../../config";
import axios from "axios";

 const Accordion = (props)=>{
   const [accordionState , setAccordionState]= useState(false);


   const [recordingLogs,setRecordingLogs] = useState([]);
   const [isLoading,setIsLoading] = useState(true);
   const getAsteriskRecdording = (diaryId) => {
     axios
       .get(
         `${config.apiPath}/api/v1/leads/getRecordings/${diaryId}`, {})
       .then((res) => {
         setRecordingLogs(res.data)
         setIsLoading(false)
       })
   }
   useEffect(()=>{
     getAsteriskRecdording(props.diaryId)
   },[accordionState])


  return(
    <>
    <button style={{position:'absolute',right:0}} className={'btn btn-primary btn-sm'} onClick={()=>setAccordionState(!accordionState)} style={{ width: '10', height: '10'}}>
      <i className={'fa fa-list'}/>
    </button>

      {
        accordionState && (
          <div>
            <Modal size={'lg'} show={accordionState} onHide={()=>{setAccordionState(!accordionState)}}>
              <Modal.Header closeButton>
                <Modal.Title>Call Log</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div style={{maxHeight:'500px',overflowY:'scroll'}}>
                  <table className={'br-lead-table table table-bordered table-striped'}>
                    <thead>
                    <tr>
                      <th style={{maxWidth: '150px', minWidth: '150px'}}>User Name</th>
                      <th style={{maxWidth: '250px', minWidth: '200px'}}>Call Start Time</th>
                      <th style={{maxWidth: '250px', minWidth: '250px'}}> Audio</th>
                      <th style={{maxWidth: '100px', minWidth: '100px',textAlign:'right'}}>Duration</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                      !isLoading && recordingLogs.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td style={{padding:"4px 6px"}}>{item.name}</td>
                            <td style={{padding:"4px 0px"}}>{moment(item.createdAt).format('DD-MM-YYYY (hh:mm A)')}</td>
                            <td style={{padding:"4px 0px"}}>
                              {(item.duration>0 && (item.disposition ==='ANSWER' || item.disposition ==='ANSWERED')) &&(
                                <audio style={{height: '25px', width: '100%'}} controls>
                                  <source src={`${config.asteriskUrl}/api/recording.php?filename=`+(item.recording)} type='audio/mp3'/>
                                </audio>
                              )}
                            </td>
                            <td style={{textAlign:'right',padding:"4px 0px"}}> {item.duration} sec</td>
                            {/* <td> {item.callResponseTime}</td> */}
                          </tr>
                        )
                      })
                    }
                    {
                      isLoading && (
                        <tr>
                          <td colSpan={4}>Please wait ...</td>
                        </tr>
                      )
                    }
                    </tbody>
                  </table>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        )
      }
   {accordionState ?
     <div style={{ display: "block",border:"1px solid black"}}>



   </div>: null
   }

    </>
   )
 }
  export default Accordion;
