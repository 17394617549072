/** @format */

import { constants } from './constant_data'
import axios from 'axios'
import moment from 'moment'
import config from '../config'
import Swal from 'sweetalert2'
import menuItems from '../components/HorizontalNav/Menu/MenuItems'
import { PermissionActions, PermissionFeatures } from './permissionConstants'
import { getPermissionValue } from '../components/_unlockComponent'
import { isAira } from '../components/common/LeadView/Wanted/WantedUtilHelper'
import { getCurrentShift } from '../components/SlotManagement/SlotHelper'
import crypto from 'crypto'

export const checkAssignedSharedStatus = (user, lead) => {
  if (user && lead) {
    if (lead.status === constants.lead_closed_lost || lead.status === constants.lead_closed_won) {
      return false
    }
    if (user.id === lead.assigned_to_armsuser_id || user.id === lead.shared_with_armsuser_id)
      return true
    else {
      return false
    }
  } else return false
}

export const arms_encrypt = (action, string) => {
  let output = false;
  let encrypt_method = "AES-256-CBC";
  let secret_key = 'helloworld';
  let secret_iv = 'propsure';
  let key = crypto.createHash('sha256').update(secret_key).digest('hex').substring(0, 32)
  let iv = crypto.createHash('sha256').update(secret_iv).digest('hex').substring(0, 16)

  if (action == 'E') {
      const cipher = crypto.createCipheriv(encrypt_method, key, iv)
      output = cipher.update(string, 'utf8', 'base64') + cipher.final('base64');
      output = Buffer.from(output, 'utf8').toString('base64')
  } else if (action == 'D') {
      string = Buffer.from(string, 'base64').toString('utf8')
      const decipher = crypto.createDecipheriv(encrypt_method, key, iv)
      output = decipher.update(string, 'base64', 'utf8') + decipher.final('utf8');
  }
  return output;
}

export const checkMyDiary = (property, user) => {
  let check = false
  if (user && property && property.diaries && property.diaries.length > 0) {
    property.diaries.map((item) => {
      if (item.userId === user && user.id) check = true
    })
    return check
  } else return check
}

export const checkGoogleAuthorize = (user, callback) => {
  if (!user || (user.userInfo && !user.userInfo.googleAuth)) {
    axios.get(`${config.apiPath}/api/user/auth/googleCalendar`).then((res) => {
      if (
        (typeof res.data === 'string' || res.data instanceof String) &&
        res.data.trim().startsWith('http')
      ) {
        let gcWindow = window.open(
          res.data,
          'gcAuth',
          'width=1000,height=1000,0,status=0,scrollbars=1'
        )
        let timer = setInterval(checkChild, 500)

        function checkChild() {
          if (gcWindow.closed) {
            clearInterval(timer)
            callback(false)

            axios.get(`${config.apiPath}/api/user/me`).then((res) => {
              if (!res.data.googleAuth)
                Swal.fire({
                  type: 'info',
                  title: 'Google Calendar',
                  icon: 'info',
                  html:
                    'Unable to add or update tasks or meeting in google calendar. Please follow google calendar configuration steps again',
                })
            })
          }
        }
      } else {
        callback(true)
      }
    })
  } else callback(true)
}

export const sortListByDateValue = (list, key) => {
  return list.sort((a, b) => {
    if (moment(a[key]) < moment(b[key])) {
      return -1
    }
    if (moment(a[key]) > moment(b[key])) {
      return 1
    }
    return 0
  })
}

export const scrollToBottom = (scrollContainerID) => {
  let el = document.getElementById(scrollContainerID)
  if (el !== null) el.scrollTop = el.scrollHeight
}

export const extractRoute = (route) => {
  return route.split('?')[0]
}

export const routeHaveHorizontalNavbar = (path) => {
  let matchWithMainMenu, matchWithSubMenu
  let navbarItems = {
    navbar: false,
    moreFilter: false,
    sort: false,
  }
  Object.keys(menuItems).map((key) => {
    menuItems[key].map((menu) => {
      matchWithMainMenu = extractRoute(menu.link) === path
      matchWithSubMenu = menu.subMenus && menu.subMenus.filter((m) => extractRoute(m.link) === path)
      if (matchWithMainMenu || (matchWithSubMenu && matchWithSubMenu.length))
        navbarItems.navbar = true

      if (
        (matchWithMainMenu && menu.moreFilter) ||
        (matchWithSubMenu && matchWithSubMenu.length && matchWithSubMenu[0].moreFilter)
      )
        navbarItems.moreFilter = true

      if (
        (matchWithMainMenu && menu.sortOptions) ||
        (matchWithSubMenu && matchWithSubMenu.length && matchWithSubMenu[0].sortOptions)
      )
        navbarItems.sort = true
    })
  })
  return navbarItems
}

export const getLeadType = (diary) => {
  if (diary) {
    if (diary.armsLeadId) return 'BuyRent'
    else if (diary.armsProjectLeadId) return 'Project'
    else if (diary.wantedId) return 'Wanted'
    else return ''
  }
}

// export const filterDiaryFeedbacks = (feedbacks, section, taskType, actionType, leadType) => {
//   Object.keys(feedbacks).map(key => {
//     feedbacks[key].filter(feedback )
//   }
// }
export const isREA = (user = null) => {
  try {
    if (!user) user = localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'))
  } catch (e) {}
  let userOrg = user && user.organization
  const isPP = userOrg && (userOrg.isPP || userOrg.name.toLowerCase().trim() === 'agency21')

  return (
    getPermissionValue(
      PermissionFeatures.BUY_RENT_LEADS,
      PermissionActions.BUY_RENT_REA_WORKFLOW
    ) || isPP
  )
}

export const encodeQueryData = (data) => {
  const ret = []
  for (let d in data) ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]))
  return ret.join('&')
}
//show all leads for these speicific group
//return true to show all leads
//other wise return false
export const shouldShowAllLeads = (user) => {
  let userRole = user && user.userInfo && user.userInfo.armsUserRole
  if (!userRole) {
    userRole = {}
    userRole.groupManger = localStorage.getItem('groupManager')
    userRole.roleName = localStorage.getItem('role')
  }
  if (userRole) {
    if (
      userRole.groupManger == true ||
      userRole.groupManger == 'true' ||
      userRole.roleName === 'system_administrator'
    ) {
      return true
    }
  }
  return false
}

const get_cookie = (name) => {
  return document.cookie.split(';').some((c) => {
    return c.trim().startsWith(name + '=')
  })
}

export const delete_cookie = (name, path, domain = true) => {
  if (get_cookie(name)) {
    document.cookie =
      name +
      '=' +
      (path ? ';path=' + path : '') +
      (domain ? ';domain=' + config.domain : '') +
      ';expires=Thu, 01 Jan 1970 00:00:01 GMT'
  }
}

export const read = (details) => {
  console.log('&&', details.status)

  const loginUserId = localStorage.getItem('user_id')
  const loginUserRole = localStorage.getItem('role')

  let returnObject = { ownLeadReadOnly: false, showReadOnly: false, checkForManager: false }

  const isREA = (user) => {
    let userOrg = user && user.organization
    const isPP = userOrg && (userOrg.isPP || userOrg.name.toLowerCase().trim() === 'agency21')

    return (
      getPermissionValue(
        PermissionFeatures.BUY_RENT_LEADS,
        PermissionActions.BUY_RENT_REA_WORKFLOW
      ) || isPP
    )
  }

  if (loginUserRole === 'AIRA Manager') {
    returnObject = { ownLeadReadOnly: false, showReadOnly: false, checkForManager: false }
    return
  }

  if (details && details.armsuser && parseInt(loginUserId) === parseInt(details.armsuser.id)) {
    returnObject['ownLeadReadOnly'] = true
  }
  if (loginUserRole === 'sub_admin 1') {
    returnObject['checkForManager'] = true
  }

  if (
    (details && details.armsuser && parseInt(loginUserId) !== parseInt(details.armsuser.id)) ||
    details.status === 'closed_lost' ||
    details.status === 'closed_won' ||
    details.status === 'closed_lost'
  ) {
    returnObject['showReadOnly'] = true
  }
  if (isREA(loginUserRole) && details && details.requiredProperties) {
    // returnObject['isREA'] = true
    returnObject = { ownLeadReadOnly: false, showReadOnly: false, checkForManager: false }
  } else if (isAira() && !returnObject.ownLeadReadOnly) {
    returnObject = {
      ownLeadReadOnly: false,
      showReadOnly: false,
      checkForManager: false,
      isAiraView: true,
    }
  }
  return returnObject // this.setState({ checkForRead: returnObject })
}

export const checkForMyShortListedProperty = (buyRentShortListProperties) => {
  const loginUserId = localStorage.getItem('user_id')
  const isFound =
    buyRentShortListProperties &&
    buyRentShortListProperties.rows &&
    buyRentShortListProperties.rows.find(({ armsuser }) =>
      armsuser ? parseInt(armsuser.id) === parseInt(loginUserId) : false
    )
  return isFound ? true : false
}

export const showToastMsg = (titleData, type, customTimer) => {
  const Toast = Swal.mixin({
    customClass: {
      container: 'isToast',
    },
    toast: true,
    position: 'top',
    showConfirmButton: false,
    timer: customTimer ? customTimer : 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    },
  })

  Toast.fire({
    icon: `${type}`,
    title: `${
      type.charAt(0).toUpperCase() + type.slice(1)
    }<br><span style="font-weight:normal"> ${titleData} </span>`,
  })
}

export const fetchAllCities = (cb) => {
  axios
    .get(`${config.apiPath}/api/cities?all=true`)
    .then((res) => {
      let array = []
      res.data &&
        res.data.length &&
        res.data.filter((item) => {
          return array.push({ label: item.name, value: item.id })
        })
      cb(array)
    })
    .catch(() => cb([]))
}

export const removeExtrachar = (string) => {
  return string && string.replace(/[ -_]/g, '').toLocaleLowerCase()
}

export const isTerminalUser = () => {
  return localStorage.getItem('isTerminalUser') && localStorage.getItem('isTerminalUser') === 'true'
}
export const formatOptionFieldName = (project) => {
  const optional_fields = project.optional_fields !== null ? project.optional_fields : '[]'

  let modifiedOptionalFields =
    project.optional_fields !== null || project.optional_fields !== '{}'
      ? JSON.parse(optional_fields && optional_fields).map((item) => ({
          ...item,
          data: '',
          fieldType: item.fieldType ? item.fieldType.value : '',
        }))
      : ''

  const entries = Object.fromEntries(modifiedOptionalFields.entries())

  const mappedNames =
    modifiedOptionalFields &&
    modifiedOptionalFields.map((item, key) => [`${item.fieldName}`, entries[key]])
  const optFields = Object.keys(Object.fromEntries(mappedNames))

  // const requiredOrder = ['Tower', 'Design Type', 'No of Beds', 'Category']
  // const finalFields = []
  // requiredOrder.map((item) => {
  //   if (optFields.indexOf(item) > -1) finalFields.push(item)
  // })
  return optFields
}

export const isDemandLead = (lead, property, user) => {
  return user && lead && property && lead.assigned_to_armsuser_id !== user.id && property.assigned_to_armsuser_id === user.id
}

export const buildFormData = (formData, data, parentKey) => {
  if (data && typeof data === 'object') {
    Object.keys(data).forEach(key => {
      return buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
    });
  } else {
    const value = data == null ? '' : data;
    formData.append(parentKey, value);
  };
  return formData;
};
