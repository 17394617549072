import React from "react";
import NoResults from './../images/noResult.png'

const Load = (props) => {
    const { loading, spinClass } = props;
    return (
        <span className={`btn-block text-center loaderSpan mt-5 mb-5`}>
            {
                loading === true ?
                    <i className={`fa fa-circle-o-notch fa-spin ${spinClass}`}></i>
                    :
                    <img src={NoResults} style={{width: '300px'}}/>
            }
        </span>
    );
};

export default Load;
