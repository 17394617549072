/** @format */

import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import Button from '../../common/Button'

const ConfirmationModal = ({ isOpen, toggle, header, children, cb }) => {
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      dialogClassName={`modal-main-legal`}
    >
      <ModalHeader>{header}</ModalHeader>
      <ModalBody>
        { children }
      </ModalBody>
      <ModalFooter>
        <Button
          text={'No'}
          textColor={'white'}
          display={'inline-block'}
          buttonColor="#026ff2"
          className={'px-4 mr-2 bg-secondary'}
          onClick={() => cb('no')}
        />
        <Button
          text={'Yes'}
          textColor={'white'}
          display={'inline-block'}
          // buttonColor="#026ff2"
          className={'px-4 mr-2 bg-success text-white'}
          onClick={() => cb('yes')}
        />
      </ModalFooter>
    </Modal>
  )
}
export default ConfirmationModal
