import * as ActionTypes from "../actions/roles";


const initialState = {};

const Roles = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_ALL_ROLES:
      return {
        ...state,
        roles: action.data
      };
    default:
      return {
        ...state
      };
  }
};

export default Roles;
