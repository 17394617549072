/** @format */

import React, { useEffect } from 'react'
import MenuItem from './MenuItem'
import menuItems from './MenuItems'
import { extractRoute } from '../../../utils/helper'
import './Submenu.scss'

const SubMenu = (props) => {
  let pageSubMenus = []
  // select sub menus on base of current route
  Object.keys(menuItems).map((key) => {
    // check if any one menu link or sub menu link matches then select the main menu immediately
    menuItems[key].map((menu) => {
      if (
        (extractRoute(menu.link) === props.path ||
        (menu.subMenus &&
          menu.subMenus.filter((submenu) => extractRoute(submenu.link) === props.path).length))
      )
        pageSubMenus = menu.subMenus || []
    })
  })

  return (
    <>
      <div className={'sub-menu d-flex'}>
        {pageSubMenus.map((menu, k) => (
          <>
          {/*Check for applying permissions */}
          {menu.isShow
            ?<MenuItem key={k} selected={props.path === extractRoute(menu.link)} menu={menu} />
            :null
          }
          </>
        ))}
      </div>
    </>
  )
}

export default SubMenu
