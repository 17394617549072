/** @format */

import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import BuyRentLeadCreation from '../../../../views/BuyRentLeadCreation'
import LeadCreation from '../../../../views/LeadCreation'

const LeadEditModal = (props) => {
  const closeModal = () => {
    props.setLeadModal(false)
  }
  return (
    <Modal isOpen={props.leadModal} toggle={(toggle) => props.setLeadModal(!toggle)} size={'lg'}>
      <ModalBody>
        <div className="close-icon-container">
          <i
            className="fal fa-times-circle close-icon"
            onClick={() => props.setLeadModal(false)}
          ></i>
        </div>
        <h4 style={{ marginBottom: props.editable ? 30 : 0 }}>
          {props.leadType === 'Project'
            ? props.editable
              ? 'Edit Investment Lead'
              : 'Add Investment Lead'
            : props.editable
            ? 'Edit Buy/Rent Lead'
            : 'Add Buy Rent Lead'}
        </h4>
        <div
          style={{
            // height: 'calc(100vh - 170px)',
            overflowY: 'auto',
            overflowX: 'hidden',
          }}
        >
          {props.leadType === 'Project' ? (
            <LeadCreation
              editable={props.editable}
              lead={props.lead}
              popup={true}
              callBack={(action, data) => {
                props.setLeadModal(false)
                props.callBack(action, data)
              }}
              closeModal={closeModal}
            />
          ) : (
            <BuyRentLeadCreation
              editable={props.editable}
              lead={props.lead}
              popup={true}
              callBack={(action, data) => {
                props.setLeadModal(false)
                props.callBack(action, data)
              }}
              closeModal={closeModal}
            />
          )}
        </div>
      </ModalBody>
    </Modal>
  )
}

export const mapDispatchToProps = (dispatch) => {
  return {}
}

export const mapStateToProps = (state) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LeadEditModal))
