/** @format */

import { priceRange, unitPriceRange, investmentProduct, infiniteRange } from './constant_data'
/** @format */

export const newFormatPrice = (price) => {
  if (price / 10000000 >= 1) {
    const r = price / 10000000
    return isFloat(r) ? `${r.toFixed(2)} Crore` : `${r} Crore`
  } else if (price / 100000 >= 1) {
    const r = price / 100000
    return isFloat(r) ? `${r.toFixed(2)} Lac` : `${r} Lac`
  } else if (price / 1000 >= 1) {
    const r = price / 1000
    return isFloat(r) ? `${r.toFixed(2)} K` : `${r} K`
  } else {
    return `${price}`
  }
}

export const formatPrice = (price) => {
  if (price / 10000000 >= 1) {
    const r = price / 10000000
    return isFloat(r) ? `${r.toFixed(2)} Crore` : `${r} Crore`
  } else if (price / 100000 >= 1) {
    const r = price / 100000
    return isFloat(r) ? `${r.toFixed(2)} Lac` : `${r} Lac`
  } else if (price / 1000 >= 1) {
    const r = price / 1000
    return isFloat(r) ? `${r.toFixed(2)} K` : `${r} K`
  } else {
    return `${price}`
  }
}

export const isPaymentFound = (payments, paymentCategory, added_by) => {
  let is_found = false
  payments &&
    payments
      .filter((obj) => obj.paymentCategory === paymentCategory && obj.addedBy === added_by)
      .map((item) => {
        is_found = true
      })
  return is_found
}

export const getPayment = (payments, paymentCategory, added_by) => {
  let payment = false
  payments &&
    payments
      .filter((obj) => obj.paymentCategory === paymentCategory && obj.addedBy === added_by)
      .map((item) => {
        payment = item
      })
  return payment
}

export const amountInDigitsToWords = (amount) => {
  const a = [
    '',
    'one ',
    'two ',
    'three ',
    'four ',
    'five ',
    'six ',
    'seven ',
    'eight ',
    'nine ',
    'ten ',
    'eleven ',
    'twelve ',
    'thirteen ',
    'fourteen ',
    'fifteen ',
    'sixteen ',
    'seventeen ',
    'eighteen ',
    'nineteen ',
  ]
  const b = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety']

  if ((amount = amount.toString()).length > 9) return '---'
  const n = ('000000000' + amount).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/)
  if (!n) return
  var str = ''
  str += n[1] != 0 ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'Crore ' : ''
  str += n[2] != 0 ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'Lac ' : ''
  str += n[3] != 0 ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'Thousand ' : ''
  str += n[4] != 0 ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'Hundred ' : ''
  str +=
    n[5] != 0
      ? (str != '' ? 'and ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) + 'only '
      : ''
  return str
}

function isFloat(n) {
  return Number(n) === n && n % 1 !== 0
}

var a = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
]
var b = ['', '10', '20', '30', '40', '50', '60', '70', '80', '90']

export const formatPrice2 = (num) => {
  if ((num = num.toString()).length > 9) return num
  var n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/)
  if (!n) return
  var str = ''
  str += !(n[1] === '00')
    ? (a[Number(n[1])] || parseInt(b[n[1][0]]) + parseInt(a[n[1][1]])) + ' Crore '
    : ''
  str += !(n[2] === '00')
    ? (a[Number(n[2])] || parseInt(b[n[2][0]]) + parseInt(a[n[2][1]])) + ' Lac '
    : ''
  return str
}
export const isPriceMaxVal = (price, type) => {
  return priceRange[type][priceRange[type].length - 1] <= price
}

export const isPriceMinVal = (price, type) => {
  return priceRange[type][0] >= price
}

export const formatPriceMaxValue = (min, max, type) => {
  let isPriceMax = isPriceMaxVal(max, type)
  if (isPriceMax)
    if (min === 0) return 'Any'
    else return 'More'
  else return formatPrice(max)
}

export const formatPriceRange = (min, max, type) => {
  if (max === '' || max === null || max === 0) max = priceRange[type][priceRange[type].length - 1]
  if (min === '' || min === null || min === 0) min = priceRange[type][0]

  let isPriceMin = isPriceMinVal(min, type)
  let isPriceMax = isPriceMaxVal(max, type)
  if (isPriceMin && isPriceMax) return 'Any'
  else if (!isPriceMin && isPriceMax) return formatPrice(min) + ' or More'
  else if (isPriceMin && !isPriceMax) return 'Upto ' + formatPrice(max)
  else return formatPrice(min) + ' - ' + formatPrice(max)
}
export const IpformatPriceRange = (min, max, type) => {
  if (max === '' || max === null || max === 0) max = priceRange[type][priceRange[type].length - 1]
  if (min === '' || min === null || min === 0) min = priceRange[type][0]

  let isPriceMin = isPriceMinVal(min, type)
  let isPriceMax = isPriceMaxVal(max, type)
  if (isPriceMin && isPriceMax) return 'Any'
  else if (!isPriceMin && isPriceMax) return formatPrice(min)+'%' + ' or More'
  else if (isPriceMin && !isPriceMax) return 'Upto ' + formatPrice(max) + '%'
  else return formatPrice(min) + '%' + ' - ' + formatPrice(max) + '%'
}
export const formatFrequencyIp = (min, max, type) => {
  if (max === '' || max === null || max === 0)
    max = investmentProduct[type][investmentProduct[type].length - 1]
  if (min === '' || min === null || min === 0) min = investmentProduct[type][0]

  let isPriceMin = investmentProduct[type][0] >= min
  let isPriceMax = investmentProduct[type][investmentProduct[type].length - 1] <= max
  if (isPriceMin && isPriceMax) return 'Any'
  else if (!isPriceMin && isPriceMax) return formatPrice(min) + ' or More'
  else if (isPriceMin && !isPriceMax) return 'Upto ' + formatPrice(max)
  else return formatPrice(min) + ' - ' + formatPrice(max)
}
export const formatUnitPriceRange = (min, max) => {
  let isPriceMin = min <= unitPriceRange.min
  let isPriceMax = max >= unitPriceRange.max
  if (isPriceMin && isPriceMax) return 'Any'
  else if (!isPriceMin && isPriceMax) return formatPrice(min) + ' or More'
  else if (isPriceMin && !isPriceMax) return 'Upto ' + formatPrice(max)
  else return formatPrice(min) + ' - ' + formatPrice(max)
}

export const projectInvestmentPriceRange = (min, max, type) => {
  if (max === '') max = priceRange[type][priceRange[type].length - 1]
  if (min === '') min = priceRange[type][0]

  let isPriceMin = isPriceMinVal(min, type)
  let isPriceMax = isPriceMaxVal(max, type)

  if (isPriceMax && isPriceMin) return 'Any'
  if (!isPriceMin && isPriceMax) return formatPrice(min) + ' or More'
  else if (isPriceMin && !isPriceMax) return 'Upto ' + formatPrice(max)
  else return formatPrice(min) + ' - ' + formatPrice(max)
}

export const formatInstallmentRange = (min, max) => {
  if (max === '' || max === null || max === 0)
    max = infiniteRange[infiniteRange.length - 1]
  if (min === '' || min === null || min === 0) min = infiniteRange[0]

  let isPriceMin = infiniteRange[0] >= min
  let isPriceMax = infiniteRange[infiniteRange.length - 1] <= max
  if (isPriceMin && isPriceMax) return 'Any'
  else if (!isPriceMin && isPriceMax) return formatPrice(min) + ' or More'
  else if (isPriceMin && !isPriceMax) return 'Upto ' + formatPrice(max)
  else return formatPrice(min) + ' - ' + formatPrice(max)
}
