/** @format */

import React, { useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from 'reactstrap'
import Select from 'react-select'
import Load from '../../../components/Loader'
import { normalizeCnic } from '../../../utils/formatCnic'

const NextKinDetailsModal = ({
  shareToggleNextKin,
  kinShareToggle,
  loader,
  name,
  // onChangeBank,
  onChangeKin,
  KinModalToggle,
  nextKin,
}) => {
  let [searchString, setSearchString] = useState('')

  searchString = searchString.trim().toLowerCase()
  if (searchString.length > 0) {
    nextKin =
      nextKin &&
      nextKin.filter(function (i) {
        let fullName = i.firstName + ' ' + i.lastName

        return fullName.toLowerCase().match(searchString)
      })
  }

  return (
    <Modal
      isOpen={shareToggleNextKin}
      toggle={kinShareToggle}
      size="lg"
      style={{  width: '100%' }}
      contentClassName="client-creation-modal-width "
    >
      <ModalHeader className="d-inline" >

 {/* booking form next of kin header */}
      <div className="row ml-1">
          <div style={{marginTop:"0.5%"}}>Select {name}</div>


        <div className="">
          <div className="text-right">
           

            <button
                  className="newFormButton mr-5"
                  onClick={() => {
                    KinModalToggle()
                  }}
                >
                  + Add {name}
                </button>

          </div>
        </div>
    
        <div className="close-icon-container">
            <i
              className="fal fa-times-circle close-icon"
              onClick={() => {
                kinShareToggle()
              }}
            ></i>
          </div>

        </div>



        
      {/* <div className="row">
      <div className='col-8'>  Select {name}</div> 
      <div className="col-4 text-right">
                <button
                  className="newFormButton"
                  onClick={() => {
                    KinModalToggle()
                  }}
                >
                  + Add {name}
                </button>
              </div>
        </div> */}
       
      </ModalHeader>
      <ModalBody>
        <>
          <div className="share-lead-container">
            <div className="row">
              {/* <div className="col-md-4">
                <div className="search-box">
                  <input
                    type="text"
                    className="search-box-style"
                    placeholder={
                      'Search by Name'
                      // +
                      // name
                      //  + ' Name'
                    }
                    onChange={(e) => setSearchString(e.target.value)}
                  />
                </div>
              </div> */}
              {/* <div className="col-md-8 text-right">
                <button
                  className="newFormButton"
                  onClick={() => {
                    KinModalToggle()
                  }}
                >
                  + Add {name}
                </button>
              </div> */}
            </div>
          </div>
          <div className="row ">
            <div className="BankAccountAndNextOfkin-form">
              <table className="share-modal-table" id="table" style={{ width: '100%' }}>
                <thead>
                  <tr>
                    <th style={{ minWidth: 90, maxWidth: 90 }}>ID</th>
                    <th style={{ minWidth: 200, maxWidth: 200 }}>Name</th>
                    <th style={{ minWidth: 165, maxWidth: 165 }}>CNIC/NTN</th>
                    <th style={{ minWidth: 165, maxWidth: 165 }}>Phone</th>
                    <th style={{ minWidth: 120, maxWidth: 120 }}>Action</th>
                  </tr>
                </thead>
                {loader ? (
                  <Load title="Next Of Kins" />
                ) : nextKin && nextKin && nextKin.length === 0 ||  nextKin === undefined? (
                  <div style={{ marginTop: '60px', marginBottom: 50 }} className="text-center">
                    <h3 className="dimgray">No Users Found</h3>
                  </div>
                ) : (
                  <tbody>
                  
                    {nextKin &&
                      nextKin.map((item) => (
                        <tr>
                          <td style={{ minWidth: 90, maxWidth: 90 }}>{item.id}</td>
                          <td style={{ minWidth: 200, maxWidth: 200 }}>
                            {item.firstName + ' ' + item.lastName}
                          </td>
                          <td style={{ minWidth: 165, maxWidth: 165 }}>{item.cnic}</td>
                          <td style={{ minWidth: 165, maxWidth: 165 }}>{item.phone}</td>
                          <td style={{ minWidth: 120, maxWidth: 120 }}>
                            <button
                              className=" link-style mr-2 ml-2 btn-hollow-activate"
                              onClick={() => {
                                onChangeKin(item)
                              }}
                            >
                              Select
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </>
      </ModalBody>

    </Modal>
  )
}

export default NextKinDetailsModal
