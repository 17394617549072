/** @format */

import React from 'react'

import NotFound from '../../images/no_inventory.png'
import './no-result-style.scss'

const NoResult = () => {
  return <img src={NotFound} className="no-result-image" alt="No Result Found" />
}

export default NoResult
