/** @format */

import axios from 'axios'
import config from '../config'
import { capitalizeWordsWithoutUnderscore } from './commonFormat'
import moment from 'moment'
import { showToastMsg } from '../utils/helper'

export const diaryCreateUpdateAPI = (taskData, showMessage = false, cb = null) => {
  let promise
  let isUpdate = 'id' in taskData
  if (isUpdate) {
    promise = axios.patch(`${config.apiPath}/api/diary/update?id=${taskData.id}`, taskData)
  } else {
    promise = axios.post(`${config.apiPath}/api/leads/task`, taskData)
  }

  promise.then((res) => {
    if (cb) cb(res.data)
    if (showMessage) {
      let msg = `${capitalizeWordsWithoutUnderscore(taskData.taskType)} has been ${
        taskData.status !== 'completed' ? (isUpdate ? 'rescheduled' : 'scheduled') : 'done'
      }${
        isUpdate ? ` to <b>${moment(new Date(taskData.start)).format('hh:mm a DD MMM')}</b>` : ''
      } successfully`
      if (taskData.status === 'completed') msg = 'Task marked as done successfully'
      showToastMsg(msg, 'success')
    }
  })
}

export const makeTaskTimePayload = (bookedTime, taskType, others = {}) => {
  return {
    subject: `${capitalizeWordsWithoutUnderscore(taskType)} scheduled`,
    taskCategory: 'leadTask',
    start: bookedTime ? moment(bookedTime.startTime).format('YYYY-MM-DDTHH:mm:ssZ') : '',
    diaryTime: bookedTime ? moment(bookedTime.startTime).format('YYYY-MM-DDTHH:mm:ssZ') : '',
    date: bookedTime ? moment(bookedTime.date).format('YYYY-MM-DDTHH:mm:ssZ') : '',
    end: bookedTime ? moment(bookedTime.endTime).format('YYYY-MM-DDTHH:mm:ssZ') : '',
    slots:
      bookedTime && bookedTime.slots
        ? bookedTime.slots.map((slot) => {
            return slot.id
          })
        : '',
    taskType: taskType,
    ...others,
  }
}
