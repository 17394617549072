/** @format */

import * as ActionTypes from '../actions/propsure'
const initialState = {}

const Propsure = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.FETCH_PROPSURE:
            return {
                ...state,
                propsureView: action.data,
            }
        case ActionTypes.FETCH_PROPSURE_LOADER:
            return {
                ...state,
                propsureViewLoader: action.data
            }
        case ActionTypes.FETCH_PROPSURE_SUCCESS:
            return {
                ...state,
                propsureViewLoader: action.data
            }
        case ActionTypes.FETCH_PROPSURE_FILTER:
            return {
                ...state,
                propsureView: action.data
            }
        case ActionTypes.FETCH_PROPSURE_FILTER_LOADER:
            return {
                ...state,
                propsureViewLoader: action.data
            }
        case ActionTypes.FETCH_PROPSURE_FILTER_SUCCESS:
            return {
                ...state,
                propsureViewLoader: action.data
            }
        default:
            return {
                ...state,
            }
    }
}

export default Propsure
