import React, {useEffect, useMemo, useState} from 'react'

import axios from "axios";
import config from "../../config";
import Select from "react-select";


const AgentDropDown = (props) => {

  const [agentNames, setAgentNames] = useState([])
  const [selectedArmUserId, setSelectedArmUserId] = useState({})

  const getAgents = async () => {
    try {
      const response = await axios.get(`${config.apiPath}/api/cdrreport/getDistinctData`);
      if (response && response.data && response.data.agentData) {
        const agents = response.data.agentData;
        const teamEntry = { value: 'team', label: 'Team' };
        setAgentNames([teamEntry, ...agents]);
        props.allAgents([teamEntry, ...agents]);
        setSelectedArmUserId(teamEntry);
      }
    } catch (error) {
      console.error('Error fetching agent data:', error);
    }
  };
  useEffect(() => {
    getAgents()
  }, [])

  useEffect(()=>{
    setSelectedArmUserId(() => (props.propSelectedArmUserId));
  },[props.propSelectedArmUserId])

  return (
    <>
            <Select
                  name="agentName"
                  placeholder="Select Agent Name"
                  value={selectedArmUserId}
                  options={agentNames}
                  onChange={(event) => {
                    props.armUserId(event.value,event.label)
                    setSelectedArmUserId(() => (agentNames.filter(function (option) {
                      return option.value === event.value;
                    })));
                  }}
                />   
    </>
  )
}

export default AgentDropDown;
