/** @format */

import React from 'react'
import { Modal, ModalBody, ModalFooter } from 'reactstrap'
import './styles.scss'
import ShortListedProperties from '../../Properties/ShortListedProperties'

const PropertiesModalShortListed = ({
  togglePropertiesShortListed,
  toggleButtonModalShortListed,
}) => {
  return (
    <Modal
      isOpen={togglePropertiesShortListed}
      toggle={toggleButtonModalShortListed}
      size="lg"
      className="properties-modal"
      contentClassName="comment-modal-width comment-modal-width-new"
    >
      <ModalBody>
        <div className="comment-main-wrapper">
          <div className="comment-popup-body-header">
            <div className="popup-body-header-title">Select Property For Transaction</div>
            <i
              className="fal fa-times-circle popup-body-header-icon"
              onClick={() => {
                toggleButtonModalShortListed(false)
              }}
            ></i>
          </div>
        </div>
        <ShortListedProperties toggleButton={toggleButtonModalShortListed} />
      </ModalBody>
    </Modal>
  )
}

export default PropertiesModalShortListed
