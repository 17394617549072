/** @format */

export const getPermissionByAction = (permissions, action) => {
  let p = false
  permissions &&
    permissions.map((permission, index) => {
      if (permission.action === action) {
        p = permission.value
      }
    })
  return p
}
