/** @format */

import { toast } from 'react-toastify'
import React from 'react'
import { capitalizeWordsWithoutUnderscore } from './commonFormat'

export const isValidData = (value, required_fields) => {
  toast.dismiss()
  let errors = []
  Object.keys(required_fields).map((item, i) => {
    if (!value.hasOwnProperty(item))
      errors.push(
        <div key={i}>
          <strong>{required_fields[item]}</strong>: Field is required{' '}
        </div>
      )
  })
  if (errors.length) {
    // wait for existing toast to dismiss
    setTimeout(() => {
      toast(() => errors, { type: toast.TYPE.ERROR })
    }, 500)

    return false
  }

  return true
}

export const connectFormReqFields = (purpose) => {
  let requiredFields = { customer: ['first_name', 'last_name', 'phone'], lead: [] }
  if (purpose) {
    if (['sale', 'rent', 'invest'].indexOf(purpose.value) > -1) requiredFields.lead.push('city')

    if (['sale', 'rent'].indexOf(purpose.value) > -1)
      requiredFields.lead.push(...['wanted_areas', 'type', 'subtype'])
  }
  return requiredFields
}

export const missingFieldHtml = (lead) => {
  let missingRequiredFields = ''
  if (lead) {
    const requiredFields = connectFormReqFields({ value: lead && lead.purpose })
    requiredFields.customer.map((field) => {
      if (!lead.customer[field])
        missingRequiredFields += `<p class='mb-1 text-left'>${capitalizeWordsWithoutUnderscore(
          field
        )}</p>`
    })
    requiredFields.lead.map((field) => {
      if (!lead[field])
        missingRequiredFields += `<p class='mb-1 text-left'>${capitalizeWordsWithoutUnderscore(
          field
        )}</p>`
    })
    if (
      ['sell', 'rent out'].indexOf(lead && lead.purpose) > -1 &&
      (!lead.properties || !lead.properties.length)
    )
      missingRequiredFields += `<p class='mb-1 text-left'>Property</p>`
  }
  return missingRequiredFields
}

export const removeZero = (phoneNumber,dialCode) => {
  if (!phoneNumber) return ''
  const splitArray = phoneNumber.split('+92')
  if (splitArray.length !== 2) return phoneNumber
  const mobileNumber = splitArray[1]
  var replaced = (mobileNumber && mobileNumber.replace(/^0+/, '')) || '' 
   if(dialCode === '92' && replaced.length >= 11)
   {
   replaced =  replaced.substring (0,9) ;
   }

  return '+92' + replaced
}
export const removeZerowithoutPlus = (phoneNumber) => {
  if (!phoneNumber) return ''
  const splitArray = phoneNumber.split('92')
  if (splitArray.length !== 2) return phoneNumber
  const mobileNumber = splitArray[1]
  const replaced = (mobileNumber && mobileNumber.replace(/^0+/, '')) || ''
  return '92' + replaced
}

export const findZero = (phoneNumber) => {
  if (!phoneNumber) return ''
  const splitArray = phoneNumber.split('92')
  if (splitArray.length !== 2) return phoneNumber
  const mobileNumber = splitArray[1].charAt(0);
  if(mobileNumber==="0") return true
  else return false
}
