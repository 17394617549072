/** @format */

import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { setTitle } from '../../actions/generalSetting'
import { fetchUserClients, addBuyRentLead } from '../../actions/leads'
import { fetchCities } from '../../actions/user'
import { fetchSubProperties } from '../../actions/property'
import { properties, leadType, sizeRange, priceRange } from '../../utils/constant_data'
import { fetchAreas } from '../../actions/area'
import LeadCreationInner from './leadCreationInner'
import './leadCreation.scss'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
import ClientCreationModal from '../../components/Modals/ClientCreationModal'
import AddClientModal from '../AddClient'
import axios from 'axios'
import config from '../../config'
import { showToastMsg } from '../../utils/helper'
import { isAira } from "../../components/common/LeadView/Wanted/WantedUtilHelper";

const LeadCreation = ({
  setTitle,
  fetchUserClients,
  userClients,
  fetchCities,
  cities,
  areas,
  subProperties,
  fetchSubProperties,
  fetchAreas,
  addLeadLoader,
  addLeadFunc,
  history,
  location,
  editable,
  lead,
  callBack,
  closeModal,
  dialerLeadInformation
}) => {
  const [selectedClient, setselectedClient] = useState(null)
  const [shareToggleState, setShareToggleState] = useState(false)
  const [activeClientModal, setactiveClientModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState({
    client: false,
    city: false,
    leadAreas: false,
    type: false,
    subtype: false,
  })
  useEffect(() =>{
    if (isAira()) {
      leadType.push(
        { label: "Sell", value: "sell" },
        { label: "Rent Out", value: "rentOut" }
      );
    }
  },[leadType])
  useEffect(() => {
    setselectedClient(lead && lead.customer)
    if (location && location.state) {
      location.state && location.state.customer && setselectedClient(location.state.customer)
    }
    fetchUserClients()
    fetchCities()
    if (editable !== true) {
      setTitle({ name: 'Add Buy/Rent Lead' })
    }
  }, [])

  const onchangeClient = (value) => {
    setselectedClient(value)
    shareToggle()
  }

  // const

  const shareToggle = () => {
    setShareToggleState(!shareToggleState)
  }

  const apiCallDonClientCreated = (newClient) => {
    if (newClient) {
      onchangeClient(newClient)
      setactiveClientModal(false)
      setShareToggleState(false)
    }
  }

  const clientModalToggle = (newClient) => {
    setactiveClientModal(!activeClientModal)
    setShareToggleState(!shareToggleState)
    fetchUserClients()
  }

  const addLeadForm = (value) => {
    // if(!isValidData(value, required_fields) || typeof addLeadLoader !== "undefined")
    //   return false
    let phones
    if (selectedClient) {
      phones =
        selectedClient &&
        selectedClient.customerContacts &&
        selectedClient.customerContacts.map((item) => item.phone)
      if (location && location.state) {
        phones = [location.state && location.state.customer && location.state.customer.phone]
      }
    } else {
      phones =
        value.customerId &&
        value.customerId.customerContacts &&
        value.customerId.customerContacts.map((item) => item.phone)
      if (location && location.state) {
        phones = [location.state && location.state.customer && location.state.customer.phone]
      }
    }

    let data

    if (
      (!selectedClient && !value.customerId) ||
      !value.cityId ||
      !value.areaIds ||
      !value.type ||
      !value.subtype
    ) {
      setErrorMessage({
        ...errorMessage,
        city: !value.cityId ? true : false,
        client: !selectedClient && !value.customerId ? true : false,
        leadAreas: !value.areaIds ? true : false,
        type: !value.type ? true : false,
        subtype: !value.subtype ? true : false,
      })
    } else {
      data = {
        customerId: selectedClient ? selectedClient && selectedClient.id : value.customerId.id,
        city_id: value.cityId.value,
        type: value.type.value,
        subtype: value.subtype.value,
        purpose: value.purpose ? value.purpose.value : leadType[0].value,
        min_price: value.ranger && value.ranger.price_min,
        price: value.ranger && value.ranger.price_max,
        size: value.sizeRanger && value.sizeRanger.size_min,
        max_size: value.sizeRanger && value.sizeRanger.size_max,
        bed: value.bedRanger && value.bedRanger.minVal ? value.bedRanger.minVal : 0,
        maxBed: value.bedRanger && value.bedRanger.maxVal ? value.bedRanger.maxVal : 0,
        bath: value.bathRanger && value.bathRanger.minVal ? value.bathRanger.minVal : 0,
        maxBath: value.bathRanger && value.bathRanger.maxVal ? value.bathRanger.maxVal : 0,
        size_unit: value.sizeRanger && value.sizeRanger.unit.value,
        description: value.description,
        leadAreas: value.areaIds.map((area) => (area.value ? area.value : area.id)),
        phones,
      }
      if (dialerLeadInformation) {
        data.isDialer = true
      }
      if (editable === true) {
        axios.patch(`${config.apiPath}/api/leads/update/${lead && lead.id}`, data).then((res) => {
          showToastMsg('Lead has been updated successfully', 'success')
          callBack(res)
        })
      } else {
        console.log('hellooooo')
        addLeadFunc(data, (lead) => {
          history.push('/diary', {
            send_data: {
              getCallFromCustomer: true,
              diaryId: lead.diaryId
            }})
        })
      }
    }
  }

  return (
    <div className="verticalScrollAdmin">
      <div className="lead-creation-container">
        {editable !== true && (
          <div className="title">
            Enter details for your {editable ? 'existing' : 'new'} buy/rent lead
          </div>
        )}
        <ToastContainer
          position="top-right"
          autoClose={10000}
          type={toast.TYPE.ERROR}
          hideProgressBar={false}
          newestOnTop={false}
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
        {/*main lead creation form*/}
        <LeadCreationInner
          userClients={userClients}
          areas={areas}
          selectedClient={selectedClient}
          shareToggleState={shareToggleState}
          setShareToggleState={setShareToggleState}
          leadType={leadType}
          sizeRange={sizeRange}
          properties={properties}
          subProperties={subProperties}
          fetchSubProperties={fetchSubProperties}
          cities={cities}
          fetchAreas={fetchAreas}
          addLeadLoader={addLeadLoader}
          onSubmit={addLeadForm}
          history={history}
          errorMessage={errorMessage}
          editable={editable}
          lead={lead}
          setLeadModal={closeModal}
        />
        {/*Client selection modal window*/}
        {editable !== true && (
          <>
            <ClientCreationModal
              name={'Client'}
              shareToggleState={shareToggleState}
              clients={userClients && userClients.rows}
              clientModalToggle={clientModalToggle}
              shareToggle={shareToggle}
              onchangeClient={onchangeClient}
            />
            <AddClientModal
              activeClientModal={activeClientModal}
              fromPage={'buyrentlead'}
              apiCallDonClientCreated={apiCallDonClientCreated}
              shareToggle={shareToggle}
              clientModalToggle={clientModalToggle}
            />
          </>
        )}
      </div>
    </div>
  )
}

export const mapDispatchToProps = (dispatch) => {
  return {
    setTitle: (title) => dispatch(setTitle(title)),
    fetchUserClients: () => dispatch(fetchUserClients()),
    fetchCities: () => dispatch(fetchCities()),
    fetchAreas: (cityId) => dispatch(fetchAreas(cityId)),
    fetchSubProperties: (property) => dispatch(fetchSubProperties(property)),
    addLeadFunc: (data, cb) => dispatch(addBuyRentLead(data, false, cb)),
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
    userClients: state.Leads.userClients,
    cities: state.user.cities,
    areas: state.Areas.allAreas,
    subProperties: state.Property.subProperties,
    addLeadLoader: state.Leads.addLeadLoader,
    addLead: state.Leads.addLead,
    lastAddedLead: state.Leads.lastAddedLead,
    dialerLeadInformation: state.Diary.dialerLeadInformation
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LeadCreation))
