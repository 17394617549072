
import axios from 'axios'
import config from '../src/config'
import {showToastMsg} from "./utils/helper";


const onlineAgent = async (agent) =>{
  return new Promise((resolve, reject)=>{
    axios.post(`${config.apiPath}/api/asterisk/setOnlineAgent`,agent).then((data)=>{
      /* showToastMsg('Agent Online','success')*/
      resolve({
        success:true,
        data:data.data
      })
    }).catch((error)=>{
      reject({success:false})
    })
  })
}
const offlineAgent = async (agentId) =>{
  return new Promise((resolve, reject)=>{
    axios.post(`${config.apiPath}/api/asterisk/setOfflineAgent`,{agentId}).then((data)=>{
      /* showToastMsg('Agent Online','success')*/
      resolve({
        success:true,
        data:data.data
      })
    }).catch((error)=>{
      reject({success:false})
    })
  })
}


const getAsteriskAgents = async () =>{
  return new Promise((resolve,reject)=>{
    axios.get(`${config.apiPath}/api/asterisk/getAgents`).then((data)=>{
      resolve({
        success:true,
        data:data.data
      })
    }).catch((error)=>{
      reject({
        success:false,
        data:''
      })
    })
  })
}




export {
  onlineAgent,
  offlineAgent,
  getAsteriskAgents
}
