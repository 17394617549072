/** @format */

import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import * as ActionTypes from '../actions/user'
import user from './user'
import Projects from './projects'
import { routerReducer as routing } from 'react-router-redux'
import Organizations from './organizations'
import Team from './team'
import ErrorMessage from './cmlead'
import Leads from './leads'
import Areas from './area'
import Target from './target'
import Propsure from './propsure'
import Legal from './legal'
import LeadSource from './leadsource'
import GeneralSetting from './generalSetting'
import ActivityLog from './activityLog.js'
import Property from './property'
import { navToggle } from './navToggle'
import agentsManagement from './agentManagement'
import InvestmentLeadOrder from './investmentLeadOrder'
import Roles from './roles'
import Diary from './diary'
import WantedLeads from './wantedLeads'
import ProjectLeads from './projectLeads'
import BuyRentLeads from './buyrentLeads'
import setDialerDataReducer  from './setDialerData'

const appReducer = combineReducers({
  routing,
  user,
  Projects,
  Leads,
  Team,
  Organizations,
  ErrorMessage,
  GeneralSetting,
  Target,
  Areas,
  ActivityLog,
  Propsure,
  navToggle,
  form: formReducer,
  Legal,
  LeadSource,
  Property,
  agentsManagement,
  InvestmentLeadOrder,
  Roles,
  Diary,
  WantedLeads,
  ProjectLeads,
  BuyRentLeads,
  setDialerDataReducer
})

export const rootReducer = (state, action) => {
  if (action.type === ActionTypes.USER_LOGOUT) {
    state = {}
  }

  return appReducer(state, action)
}
