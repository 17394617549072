/** @format */

import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import Button from '../../common/Button'
import React, { useState } from 'react'
import config from '../../../config'
import axios from 'axios'
import { showToastMsg } from '../../../utils/helper'
import Swal from 'sweetalert2'

const CloseAsDuplicateModal = (props) => {
  const [loader, setLoader] = useState(false)
  const { modal, setModal, lead, leadType, callback } = props

  const updateLeadStatus = (lead, leadType, callback) => {
    let url = `${config.apiPath}/api/leads/project`
    if (leadType === 'BuyRent') url = `${config.apiPath}/api/leads`
    else if (leadType === 'Wanted') url = `${config.apiPath}/api/wanted`
    axios({
      method: 'patch',
      url: url,
      params: {
        id: leadType === 'Wanted' ? lead && lead.id : lead && [lead.id],
      },
      data: {
        [leadType === 'Wanted' ? 'reason' : 'reasons']: 'Close as duplicate',
        status: 'closed_lost',
      },
    }).then((res) => {
      showToastMsg(
        'Lead has been marked as Closed Lost (with closing reason as Duplicate)',
        'success'
      )
      callback()
    })
  }

  const markAsDuplicate = (lead, leadType, callback) => {
    axios
      .get(`${config.apiPath}/api/leads/count`, {
        params: {
          leadTypes: [leadType.toLowerCase()],
          customerId: lead && lead.customer && lead.customer.id,
        },
      })
      .then((res) => {
        if (res.data.leadCount <= 1) {
          Swal.fire({
            type: 'error',
            icon: 'error',
            title: 'Error',
            text: 'Client has only one lead so this lead can not be closed as duplicate.',
          })
          callback()
        } else updateLeadStatus(lead, leadType, callback)
      })
  }

  return (
    <Modal
      isOpen={modal}
      toggle={setModal}
      dialogClassName={`modal-main-legal`}
      backdrop={loader ? 'static' : true}
    >
      <ModalHeader>Close as Duplicate (Close Lost)</ModalHeader>
      <ModalBody>
        <p>
          Are you sure you want to mark this lead, close as duplicate (Close Lost)? This process
          cannot be undone
        </p>
      </ModalBody>
      <ModalFooter>
        <Button
          text={'Cancel'}
          textColor={'white'}
          display={'inline-block'}
          disabled={loader}
          buttonColor="#026ff2"
          className={'px-4 mr-2 bg-secondary'}
          onClick={() => !loader && setModal(false)}
        />
        <Button
          width={105}
          text={loader ? <span className="fa fa-circle-o-notch fa-spin"></span> : 'Confirm'}
          textColor={'white'}
          display={'inline-block'}
          // buttonColor="#026ff2"
          className={'px-4 mr-2 bg-danger text-white'}
          onClick={() => {
            setLoader(true)
            markAsDuplicate(lead, leadType, () => {
              setLoader(false)
              callback()
            })
          }}
        />
      </ModalFooter>
    </Modal>
  )
}
export default CloseAsDuplicateModal
