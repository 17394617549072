/** @format */

import React, { useState } from 'react'
import './tooltip.scss'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
// const useStyles = makeStyles({
//   root: (props) => ({
//     '&::before': {
//       ...props.caretStyles,
//     },
//   }),
// })
const Tooltip = (props) => {
  let timeout
  const [active, setActive] = useState(false)
  // const classes = useStyles(props)

  const showTip = () => {
    timeout = setTimeout(() => {
      if (isOverFlowText() || props.alwaysShowOnHover) {
        setActive(true)
      }
    }, props.delay || 400)
  }

  const hideTip = () => {
    clearInterval(timeout)
    setActive(false)
  }
  const isOverFlowText = () => {
    if (props.contentRef && props.contentRef.current) {
      if (props.contentRef.current.offsetWidth < props.contentRef.current.scrollWidth) {
        return true
      } else {
        return false
      }
    }
    return true
  }

  return (
    <div
      className="Tooltip-Wrapper"
      // When to show the tooltip
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
    >
      {/* Wrapping */}
      {props.children}
      {(props.open || active) && (
        <div
          className={`Tooltip-Tip ${props.direction || 'top'}`}
          style={props.styles ? props.styles : {}}
        >
          {/* Content */}
          {props.content}
        </div>
      )}
    </div>
  )
}

export default Tooltip

Tooltip.propTypes = {
  open: PropTypes.bool,
  styles: PropTypes.object,
  alwaysShowOnHover: PropTypes.bool,
}

Tooltip.defaultProps = {
  open: false,
  styles: {},
  alwaysShowOnHover: false,
}
