/** @format */

import * as ActionTypes from '../actions/activityLog'
const initialState = {}

const ActivityLog = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.ALL_ENTITIES:
      return {
        ...state,
        entities: action.data,
      }
    case ActionTypes.ALL_ACTIVITY_LOGS:
      return {
        ...state,
        allActivityLogs: action.data,
      }
    case ActionTypes.ALL_ACTIVITY_LOG_LOADER:
      return {
        ...state,
        allActivityLogLoader: action.data,
      }
    default:
      return {
        ...state,
      }
  }
}

export default ActivityLog
