import React, {useEffect, useRef, useState} from 'react'

const TransferAgentComponent = (props) => {

  return (
    <>
      <div className={'transfer-calss'}>
        <button className={'btn btn-danger btn-sm close-btn'} onClick={(e) => {
          props.closeTransferAgent(false)
        }} type={'button'}><i className={'fa fa-close'}/></button>
        <div className="transfer-body">
          <div className="table-res">
            <table className="table table-striped table-bordered table-hover">
              <thead>
              <tr>
                <th>Name</th>
                <th>Agent Id</th>
                <th>Time</th>
                <th>Action</th>
              </tr>
              </thead>
              <tbody>
              {
                props.onlineAgents.map((row, index) => {
                  return (
                    <tr key={index}>
                      <td>{row.label} <span style={{fontSize: '8px'}}>{row.status}</span></td>
                      <td>{row.accountId}</td>
                      <td>{row.loginAt}</td>
                      <td>
                        <button disabled={(row.status === 'on_call')} className={'btn btn-primary btn-sm'}
                                onClick={(e) => {
                                  props.closeTransferAgent(false)
                                  props.transferCallHandle(e,row.accountId)
                                }} type={'button'}>
                          <i className={'fa fa-arrow-circle-o-right'}/>
                        </button>
                      </td>
                    </tr>
                  )
                })
              }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}
export default TransferAgentComponent;
