/** @format */

import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import CopyIcon from '../../../images/copy.svg'
import WhatsappIcon from '../../../images/wtsapp.svg'
import Button from '../../common/Button'
import DeviceModal from '../DeviceModal'
import { showErrorMessage } from '../../../utils/commonFormat'
import ConfirmModal from '../DeviceModal/Confirm'
import { makeCall } from '../../../actions/leads'
import { connect } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import callAsterisk from '../../../images/call3.svg'
import axios from 'axios'
import config from '../../../config'
import { showToastMsg } from '../../../utils/helper'
import Swal from 'sweetalert2'
import dialer_agent from '../../Dialer/dailer_agent'
import { CallIcon } from '../../Call/CallIcon'
import {activateDialer, mwsSoftPhoneDialNumber} from '../../../actions/diary'

const useStyles = makeStyles({
  root: {},
  socialIcon: {
    width: 35,
    paddingRight: 8,
  },
  copyIcon: {
    width: 20,
    marginLeft: 8,
    cursor: 'pointer',
  },
  contactWraper: {
    columnGap: 16,
    fontSize: 18,
  },
  phoneTag: {
    color: '#000',
    border: '1px solid #ccc',
    padding: '1px 10px',
    borderRadius: 20,
  },
  verticalSeparator: {
    cursor: 'pointer',
    borderRight: '2px solid #c6c6c6',
    width: 4,
    boxShadow: '1px 2px 9px 1px #eee',
    marginLeft: 15,
    height: 30,
  },
})

// let copiedRef, clickToCopyRef

const ClientContact = (props) => {
  const classes = useStyles(props)
  const [phone, setPhone] = useState(false)
  const [callMethod, setCallMethod] = useState(null)
  const [showDevicesModal, setShowDevicesModal] = useState(false)
  const [device, setDevice] = useState(false)
  const [confirmModal, setConfirmModal] = useState(false)
  // const [showLoader, setShowLoader] = useState(false)
  const [agent, setAgent] = useState({})

  useEffect(() => {
    dialer_agent().then((result) => {
      if (result.success) {
        setAgent(result)
      } else {
        setAgent({})
      }
    })
  }, [])

  const { customer, makeCall, onCallCompleted, leadId, diaryId, leadType } = props
  return (
    <>
      <div className="px-4 py-3 border-top">
        <div className={'d-flex align-items-center justify-content-between'}>
          <div className={`${classes.contactWraper} d-flex align-items-center`}>
            {/* {
              showLoader && (
                ''
              )
            } */}
            {customer && customer.phone ? (
              <>
                <div className={'d-flex align-items-center'}>
                  {/* Referece ticket ARMS-3307
                Comment this code so that user can acheive the functionlity of again call through wtsapp  */}
                  <img
                    // style={{cursor: callMethod === null ? 'pointer' : 'unset'}}
                    onClick={() => {
                      // if(callMethod === null) {
                      setPhone(customer.phone)
                      setCallMethod('whatsapp')
                      window.open(`https://wa.me/${customer.phone}`, '_blank')
                      // }
                    }}
                    className={`${classes.socialIcon}`}
                    src={WhatsappIcon}
                  />
                  <span
                    style={{
                      cursor: 'pointer',
                    }}
                    className={`${classes.phoneTag} ${
                      callMethod === null ? ' bg-primary text-white border-primary' : ''
                    }`}
                  >
                    <span
                      onClick={() => {
                        // if(callMethod === null) {
                        setPhone(customer.phone)
                        setShowDevicesModal(true)
                        // }
                      }}
                    >
                      <i
                        className={`fa fa-phone ${
                          callMethod === null ? 'text-white' : 'text-primary'
                        }`}
                      ></i>{' '}
                      {customer.phone}{' '}
                    </span>
                  </span>
                  <CallIcon
                    phone={customer.phone}
                    setPhone={setPhone}
                    leadId={leadId}
                    agent={agent}
                    onCallCompleted={onCallCompleted}
                    diaryId={diaryId}
                    callMethod={callMethod}
                    mwsDialCall={(data)=>{
                      props.activateDialer(false)
                      props.activateDialer(true)
                      data.leadType=leadType;
                      localStorage.setItem('mwsLeadId',JSON.stringify({leadId,diaryId,leadType}))
                      props.mwsSoftPhoneDialNumber(data)
                    }}
                    activateDialerClick={(status) => {
                      props.activateDialer(status)
                    }}
                    dialCode = {customer.dialCode}
                  />
                </div>
              </>
            ) : (
              customer &&
              customer.customerContacts &&
              Array.isArray(customer.customerContacts) &&
              customer.customerContacts.map((num, i) => (
                <div className={'d-flex align-items-center'}>
                  {/* Referece ticket ARMS-3307
                Comment this code so that user can acheive the functionlity of again call through wtsapp  */}
                  <img
                    // style={{cursor: callMethod === null ? 'pointer' : 'unset'}}
                    onClick={() => {
                      // if(callMethod === null) {
                      setPhone(num.phone)
                      setCallMethod('whatsapp')
                      window.open(`https://wa.me/${num.phone}`, '_blank')
                      // }
                    }}
                    className={`${classes.socialIcon}`}
                    src={WhatsappIcon}
                  />
                  <span
                    style={{
                      cursor: 'pointer',
                    }}
                    className={`${classes.phoneTag} ${
                      callMethod === null ? ' bg-primary text-white border-primary' : ''
                    }`}
                  >
                    <span
                      onClick={() => {
                        // if(callMethod === null) {
                        setPhone(num.phone)
                        setShowDevicesModal(true)
                        // }
                      }}
                    >
                      <i
                        className={`fa fa-phone ${
                          callMethod === null ? 'text-white' : 'text-primary'
                        }`}
                      ></i>{' '}
                      {num.phone}{' '}
                    </span>
                  </span>
                  {/* <img
                    onMouseOver={() => ReactTooltip.show(clickToCopyRef)}
                    onMouseLeave={() => {
                      ReactTooltip.hide(clickToCopyRef)
                      ReactTooltip.hide(copiedRef)
                    }}
                    onClick={() => {
                      ReactTooltip.hide(clickToCopyRef)
                      ReactTooltip.show(copiedRef)
                      navigator.clipboard.writeText(num.phone)
                    }}
                    className={`${classes.copyIcon}`}
                    src={CopyIcon}
                  />
                  <p ref={(ref) => (clickToCopyRef = ref)} data-tip={'Click to Copy'}></p>
                  <p ref={(ref) => (copiedRef = ref)} data-tip={'Copied!'}></p> */}
                  <CallIcon
                    //showLoader={showLoader}
                    //setShowLoader={setShowLoader}
                    phone={num.phone}
                    dialCode={num.dialCode}
                    setPhone={setPhone}
                    leadId={leadId}
                    agent={agent}
                    onCallCompleted={onCallCompleted}
                    diaryId={diaryId}
                    callMethod={callMethod}
                    activateDialerClick={(status) => {
                      props.activateDialer(status)
                    }}
                  />
                  {i < customer.customerContacts.length - 1 && (
                    <div className={`${classes.verticalSeparator}`}></div>
                  )}
                </div>
              ))
            )}
            <ReactTooltip type="dark" place="right" delayHide={100} effect="solid"></ReactTooltip>
          </div>
          <div>
            <Button
              text={
                props.loading ? (
                  <span className="fa fa-circle-o-notch fa-spin"></span>
                ) : (
                  'Register Call Feedback'
                )
              }
              width={208}
              textColor={'white'}
              className={'bg-primary rounded-pill px-4 py-2'}
              disabled={props.loading || props.disableRegister}
              onClick={() => onCallCompleted(callMethod, phone)}
            />
          </div>
        </div>
        {showDevicesModal && (
          <DeviceModal
            makeCall={(device) => {
              makeCall(
                {
                  firstName: customer.first_name,
                  lastName: customer.last_name,
                  countryCode: customer.countryCode ? customer.countryCode.toUpperCase() : null,
                  dialCode: customer.dialCode ? customer.dialCode.toUpperCase() : null,
                  number: phone,
                  role: 'Client',
                  deviceId: device.id,
                  leadType: !!leadType ? leadType : null,
                  diaryId: diaryId,
                  leadId,
                },
                (res) => {
                  if (res.data.status === true) {
                    setCallMethod('phone')
                    setShowDevicesModal(false)
                    setConfirmModal(true)
                    setDevice(device)
                  } else showErrorMessage("Couldn't access your device!")
                }
              )
            }}
            showDevicesModal={showDevicesModal}
            setShowDevicesModal={(toggle) => setShowDevicesModal(toggle)}
          />
        )}
        <ConfirmModal
          setConfirmModal={(toggle) => setConfirmModal(toggle)}
          confirmModal={confirmModal}
          callBack={() => {
            setConfirmModal(false)
          }}
          device={device}
        />
      </div>
    </>
  )
}

const mapdispatchToProps = (dispatch) => {
  return {
    makeCall: (data, callback) => dispatch(makeCall(data, callback)),
    activateDialer: (data) => dispatch(activateDialer(data)),
    mwsSoftPhoneDialNumber: (data) => dispatch(mwsSoftPhoneDialNumber(data)),
  }
}

const mapdispatchToState = (state) => {
  return {}
}

export default connect(mapdispatchToState, mapdispatchToProps)(ClientContact)
