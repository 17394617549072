/** @format */

import React from 'react'

import './toggle-button-style.scss'

const ToggleButton = ({ text, active, searchByOrg, badgeCount }) => {
  return (
    <div className="toggle-button-container">
      <button
        className={
          active ? 'toggle-button toggle-active-button' : 'toggle-button toggle-none-active-button'
        }
        onClick={() => searchByOrg('organization', text)}
      >
        {text}
        <span className="toggle-button-badges">
          {badgeCount === undefined ? (
            <span className="fa fa-circle-o-notch fa-spin" />
          ) : (
            badgeCount
          )}
        </span>
      </button>
    </div>
  )
}

export default ToggleButton
