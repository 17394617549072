/** @format */

import React from 'react'

/** @format */

export const ColumnName = {
  DATE_CREATED: 'date-created',
  PROJECT_NAME: 'project-name',
  PROJECTNAME: 'projectName',
  ASSIGNED_TO: 'assign-to',
  STATUS: 'status',
  CLASSIFICATION: 'classification',
  CLIENT_NAME: 'client-name',
  FOLLOW_UP_TIME: 'follow-up-time',
  ORIGIN: 'origin',
  UNIT_TYPE: 'unit-type',
  ADDITIONAL_DETAILS: 'additional-details',
  ADVISOR_CONTACT_NO: 'advisor-contact-no',
  CLOSING_REASON: 'closing-reason',
  FORM_ID: 'form-id',
  CITY: 'city',
  LEAD_ID: 'lead-id',
  LEAD_TAG: 'lead-tag',
  FINAL_PRICE: 'final-price',
  FLOOR: 'floor',
  UNIT: 'unit',
  CHECKBOX: 'checkbox',
  TIME_STATUS: 'time-status',
  ACTION: 'action',
  TRANSFERRED_FROM: 'transferred-from',
  TRANSFERRED_DATE: 'transferred-date',
}

export const columnColor = '#50525e'

export const headerTemplate = () => {
  return [
    // {
    //   component: 'INPUTCHECKBOX',
    //   props: {
    //     label: 'checkbox',
    //     onClick: handleCheckbox,
    //     checked: allCheckboxSelected,
    //   },
    //   tdClassName: 'pl-3',
    //   columnName: ColumnName.CHECKBOX,
    //   ignorePropConstruct: ['onClick'],
    // },
    {
      component: 'LABEL',
      props: {
        label: '',
      },
      tdClassName: 'text-center',
      columnName: ColumnName.TIME_STATUS,
    },
    {
      component: 'LABEL',
      props: {
        label: 'Lead ID',
      },
      tdClassName: '',
      columnName: ColumnName.LEAD_ID,
    },
    {
      component: 'LABEL',
      props: {
        label: 'DATE CREATED',
      },
      columnName: ColumnName.DATE_CREATED,
    },
    {
      component: 'LABEL',
      props: {
        label: 'CLIENT NAME',
      },
      columnName: ColumnName.CLIENT_NAME,
    },
    {
      component: 'LABEL',
      props: {
        label: 'PROJECT',
      },
      columnName: ColumnName.PROJECT_NAME,
    },
    {
      component: 'LABEL',
      props: {
        label: 'ASSIGNED TO',
      },
      columnName: ColumnName.ASSIGNED_TO,
    },
    {
      component: 'LABEL',
      props: {
        label: 'STATUS',
      },
      columnName: ColumnName.STATUS,
      tdClassName: 'text-center',
    },
    {
      component: 'LABEL',
      props: {
        label: 'CLASSIFICATION',
      },
      tdClassName: 'text-center',
      columnName: ColumnName.CLASSIFICATION,
    },
    {
      component: 'LABEL',
      props: {
        label: 'FOLLOW-UP',
      },
      columnName: ColumnName.FOLLOW_UP_TIME,
    },

    {
      component: 'LABEL',
      props: {
        label: 'ORIGIN',
      },
      columnName: ColumnName.ORIGIN,
    },
    {
      component: 'LABEL',
      props: {
        label: 'UNIT TYPE',
      },
      columnName: ColumnName.UNIT_TYPE,
    },
    {
      component: 'LABEL',
      props: {
        label: 'CITY',
      },
      columnName: ColumnName.CITY,
    },
    {
      component: 'LABEL',
      props: {
        label: 'ADDITIONAL DETAILS',
      },
      columnName: ColumnName.ADDITIONAL_DETAILS,
    },
    {
      component: 'LABEL',
      props: {
        label: 'ADVISOR CONTACT NO',
      },
      columnName: ColumnName.ADVISOR_CONTACT_NO,
    },
    {
      component: 'LABEL',
      props: {
        label: 'FORM ID',
      },
      columnName: ColumnName.FORM_ID,
    },
    {
      component: 'LABEL',
      props: {
        label: 'CLOSING REASON',
      },
      columnName: ColumnName.CLOSING_REASON,
    },
    {
      component: 'LABEL',
      props: {
        label: '',
      },
      tdClassName: 'noColor',
      columnName: ColumnName.ACTION,
    },
  ]
}
export const bodyTemplate = (
  getValue,
  handleAction,
  props,
  handleCheckbox,
  selectedLeads,
  selectAll = false
) => {
  const constructProp = (templateProperty, cellIndex, rowIndex, rowData, columnName) =>
    getValue(
      templateProperty,
      cellIndex,
      rowIndex,
      rowData,
      props,
      columnName,
      selectedLeads,
      selectAll
    )
  return [
    // {
    //   component: 'INPUTCHECKBOX',
    //   props: {
    //     onClick: handleCheckbox,
    //     label: 'id',
    //     tdClassName: 'text-center',
    //     checked: constructProp,
    //   },
    //   tdClassName: 'pl-3',
    //   ignorePropConstruct: ['onClick'],
    // },
    {
      component: 'LABEL',
      props: {
        label: constructProp,
      },
      tdClassName: 'text-center',
    },
    {
      component: 'LABEL',
      props: {
        label: constructProp,
        className: 'project-lead-link',
      },
      tdClassName: '',
    },
    {
      component: 'LABEL',
      props: {
        label: constructProp,
        textColor: columnColor,
      },
    },
    {
      component: 'LABEL',
      props: {
        label: constructProp,
        textColor: columnColor,
      },
    },
    {
      component: 'LABEL',
      props: {
        label: constructProp,
        textColor: columnColor,
      },
    },
    {
      component: 'LABEL',
      props: {
        label: constructProp,
        textColor: columnColor,
      },
    },
    {
      component: 'CHIP',
      props: {
        text: constructProp,
        chipColor: constructProp,
        textColor: constructProp,
        fontWeight: '700',
        padding: '6px 10px',
      },
      tdClassName: 'd-flex justify-content-center align-items-center h-100',
      notFoundTemplate: { text: '' },
    },
    {
      component: 'LABEL',
      props: {
        label: constructProp,
        textColor: columnColor,
      },
      tdClassName: 'text-center',
    },
    {
      component: 'LABEL',
      props: {
        label: constructProp,
        textColor: columnColor,
      },
    },
    {
      component: 'LABEL',
      props: {
        label: constructProp,
      },
    },
    {
      component: 'LABEL',
      props: {
        label: constructProp,
        textColor: columnColor,
      },
    },
    {
      component: 'LABEL',
      props: {
        label: constructProp,
        textColor: columnColor,
      },
    },
    {
      component: 'LABEL',
      props: {
        label: constructProp,
        textColor: columnColor,
      },
    },
    {
      component: 'LABEL',
      props: {
        label: constructProp,
        textColor: columnColor,
      },
    },
    {
      component: 'LABEL',
      props: {
        label: constructProp,
        textColor: columnColor,
      },
    },
    {
      component: 'LABEL',
      props: {
        label: constructProp,
        textColor: columnColor,
        className: 'elipsis wlRequirementLabelWidth',
      },
    },
    {
      component: 'BUTTON',
      tdClassName: 'pr-3',
      props: {
        display: constructProp,
        text: 'Re-assign',
        buttonColor: 'white',
        textColor: '#026ff2',
        orientation: 'left',
        autoAdjustOrientation: true,
        width: '70px',
        padding: '7px 5px',
        actionItems: constructProp,
        onClick: handleAction,
        emptyMenuItem: 'No Action Available',
        style: { border: '1px solid #026ff2' },
      },
      ignorePropConstruct: [
        'onClick',
        'autoAdjustOrientation',
        'type',
        'width',
        'orientation',
        'textColor',
        'buttonColor',
        'text',
        'emptyMenuItem',
        ,
      ],
      showOnHover: true,
      showOnRowSelect: false,
    },
  ]
}
