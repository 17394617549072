/** @format */

import React, { useEffect, useState, useMemo } from 'react'
import axios from 'axios'
import config from '../../../config'
//import Pagination from '../UnitBookingForm/Inventory/Pagination'
import { withCookies } from 'react-cookie'
import { setTitle } from '../../../actions/generalSetting'
import { connect } from 'react-redux'
import Pagination from 'rc-pagination'
import local from '../../../local/en_US'
import 'rc-pagination/assets/index.css'
import './ccmc.scss'

import { Chart, Tooltip, Legend, Facet, getTheme } from 'bizcharts'
import { withRouter } from 'react-router-dom'
import { Dropdown, DropdownMenu, DropdownToggle, Modal, ModalBody } from 'reactstrap'
import moment from 'moment'
import { showToastMsg } from '../../../utils/helper'
import { useRef } from 'react'
import { capitalize_Words } from '../../../utils/commonFormat'
import PaymentMethods from '../../../utils/paymentMethods'
import Select from 'react-select'
import { DateRangePicker } from 'react-dates'
import { useDownloadExcel } from 'react-export-table-to-excel';

const calcPercentage = (val1, val2, isgraph = false) => {
  if (val1 && !val2) {
    if (isgraph) {
      return 0
    } else {
      return -1
    }
  }
  if (!val1) {
    return 0
  }
  if (!val2) {
    return 0
  }
  if (isgraph) {
    return parseInt(
      ((parseInt(val1) / parseInt(val2)) * 100).toFixed(1) < 100
        ? ((parseInt(val1) / parseInt(val2)) * 100).toFixed(1)
        : 100
    )
  }
  return ((parseInt(val1) / parseInt(val2)) * 100).toFixed(1)
}

const calcNegPercentage = (val1, val2) => {
  if (val1 && !val2) {
    return 100
  }
  if (!val1) {
    return 100
  }
  if (!val2) {
    return 100
  }
  let res =
    (parseInt(val1) / parseInt(val2)) * 100 < 100
      ? ((parseInt(val1) / parseInt(val2)) * 100).toFixed(0)
      : 100
  res = 100 - res
  return parseInt(res.toFixed(1))
}

const CcmcGM = (props) => {
  const [loader, setLoader] = useState(false)
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [total, setTotal] = useState(0)
  const [reportData, setReportData] = useState([])
  const [allData, setAllData] = useState([])
  const [filters, setFilters] = useState({
    fromDate: moment().startOf('month'),
    toDate: moment().endOf('day'),
    teamId: ''
  })
  const [isDateChanged, setIsDateChanged] = useState(false)
  const [isRoleChanged, setIsRoleChanged] = useState(false)
  const [focusedInput, setFocusedInput] = useState(null)
  const [roleName, setRoleName] = useState({ value: '1', label: 'Level 1' })
  const [showGraph, setShowGraph] = useState(false)
  const [teamFilter, setTeamFilter] = useState(false)
  const [graphData, setGraphData] = useState([])
  const [teams, setTeams] = useState([])
  const [allTeams, setAllTeams] = useState([])
  const [officeLocations, setOfficeLocations] = useState([])
  const agentLevel = '4'
  let sortedBy = useRef(null)
  const ccmcRef = useRef(null);
  
  const changeTitle = () => {
    let t = 'CCMC Report'
    document.title = t
    props.setTitle({ name: t })
  }

  useEffect(() => {
    getTeams()
    getData()
    // setAllData(DUMMY_DATA)
    // setReportData(DUMMY_DATA)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      changeTitle()
    }, 300)
  }, [window.location.href])

  const getTeams = async () => {

    await axios.get(`${config.apiPath}/api/user/getAllUserTeamDetails?all=true&projectTeams=true`).then((res) => {
      let t = res.data.rows.map((item) => ({
        label: item.teamName,
        value: item.id,
        organizationId: item.teamOrganizationId,
      }))
      let officeLocations = res.data && res.data.rows && res.data.rows.map((ol) => ol.armsuser && ol.armsuser.officeLocation)
      officeLocations = officeLocations.filter((t) => t !== null)
      const filteredOfficeLocations = Object.values(officeLocations.reduce((a, c) => (a[`${c.id}${c.name}`] = c, a), {}));

      let officeLocation = filteredOfficeLocations.map((ol) => ({
        label: ol.name,
        value: ol.id
      }))
      setTeams(t)
      setAllTeams(t)

      setOfficeLocations( officeLocation)
    })
  }

  const getData = async (teamsArray) => {
    setLoader(true)
    const teamId = teamsArray && teamsArray.length > 0 ? `&teamId=${teamsArray}` : ''
    const officeLocationId = filters.officeLocationId && filters.officeLocationId.value ? `&officeLocationId=${filters.officeLocationId && filters.officeLocationId.value}` : ''
    const organizationId = filters.organizationId && filters.organizationId.value ? `&organizationId=${filters.organizationId && filters.organizationId.value}` : ''
    const region = filters.region && filters.region.value ? `&region=${filters.region && filters.region.value}` : ''
    // const isAgent = roleName.value === '4' ? `&agents=true` : ''
    const level = roleName.value ? `&level=${roleName.value}` : ''
    await axios
      .get(
        `${config.apiPath}/api/reporting/projectSalesGM?page=${page}&pageSize=${
          config.pageSize
        }&startDate=${moment(filters.fromDate).format('YYYY-MM-DD')}&endDate=${moment(
          filters.toDate
        ).format('YYYY-MM-DD')}${teamId}${organizationId}${level}${region}`
      )
      .then((res) => {
        let tempData = res.data
        sortedBy.current = {attr: "abc", desc: false}
        sortData(tempData, "totalMeetings", "targetMeetings")
        setAllData(tempData)
        setTeamFilter(false)
        setTotal(tempData.length)
        formatGraphData(tempData)
        // if(roleName.value === agentLevel){
        //   let agentTeams = res.data.map((ol) => ({
        //     label: ol.teamName,
        //     id: ol.teamId
        //   }))
        //   const filteredTeam = teams.filter(({ value: value }) => agentTeams.some(({ id: id }) => id == value));
        //   if (filters.teamId && filters.teamId.value) {
        //     let temp = tempData.filter((t) => {
        //       return t.teamId == filters.teamId.value
        //     })
        //     setReportData(temp)
        //     formatGraphData(temp)
        //   }

        //   setTeams(filteredTeam)
        //   setAllTeams(filteredTeam)
        // }
        setIsDateChanged(false)

        if (roleName.value === '1' ) {
          const regions = ['N', 'C', 'S'];
          const regionResult = {N: {totalCalls: 0, totalMeetings: 0, totalConversions: 0, totalCRs: 0, targetAmount: 0,
              targetCalls: 0, targetMeetings: 0, targetConversions: 0, targetCRs: 0,}, S: {totalCalls: 0, totalMeetings: 0, totalConversions: 0, totalCRs: 0, targetAmount: 0,
              targetCalls: 0, targetMeetings: 0, targetConversions: 0, targetCRs: 0,}, C:{totalCalls: 0, totalMeetings: 0, totalConversions: 0, totalCRs: 0, targetAmount: 0,
              targetCalls: 0, targetMeetings: 0, targetConversions: 0, targetCRs: 0,}};
          res.data.map((item)=>{
            if(regions.includes(item.region)) {
              regionResult[item.region]['totalCalls'] += !!item.totalCalls ? item.totalCalls : 0;
              regionResult[item.region]['targetCalls'] += !!item.targetCalls ? item.targetCalls : 0;
              regionResult[item.region]['totalCRs'] += !!item.totalCRs ? item.totalCRs : 0;
              regionResult[item.region]['targetCRs'] += !!item.targetCRs ? item.targetCRs : 0;
              regionResult[item.region]['totalMeetings'] += !!item.totalMeetings ? item.totalMeetings : 0;
              regionResult[item.region]['targetMeetings'] += !!item.targetMeetings ? item.targetMeetings : 0;
              regionResult[item.region]['totalConversions'] += !!item.totalConversions ? item.totalConversions : 0;
              regionResult[item.region]['targetAmount'] += !!item.targetAmount ? item.targetAmount : 0;
            }
          })
          let temp = res.data.filter((t) => {
            return t.level == roleName.value
          })
          let rData = [];
          rData.push({firstName: 'North', teamId: 'N', ...regionResult['N']})
          rData.push({firstName: 'South', teamId: 'S', ...regionResult['S']})
          rData.push({firstName: 'Central', teamId: 'C', ...regionResult['C']})
          if(filters.region && filters.region.value){
            const regionIndex = {
              'N': 0, 'S': 1, 'C': 2
            }
            sortedBy.current = {attr: "abc", desc: false}
            sortData([rData[regionIndex[filters.region.value]]], "totalMeetings", "targetMeetings")
          }else {
            sortedBy.current = {attr: "abc", desc: false}
            sortData(rData, "totalMeetings", "targetMeetings")
          }

          formatGraphDataForRegion(rData)
        }
      })
      setIsRoleChanged(false)

    setLoader(false)
  }

  const formatGraphData = (d) => {
    var t = []
    d.forEach((r) => {
      // let id = level == '4' ? r.armsUserId :
      let data = [
        // ...t,
        {
          indexCode: 'Calls',
          timeDate:
            (roleName.value === agentLevel ? "(" + r.armsUserId + ") " : "") +
            capitalize_Words(r.firstName) +
            ' ' +
            (r.lastName ? capitalize_Words(r.lastName) : ""),
          name: capitalize_Words(r.firstName) + " " + capitalize_Words(r.lastName),
          type: 'done',
          value: calcPercentage(r.totalCalls, r.targetCalls, true),
          actualValue: (r.totalCalls ? r.totalCalls : 0) + "/" + (r.targetCalls ? r.targetCalls : 0),
        },
        {
          indexCode: 'Calls',
          timeDate:
            (roleName.value === agentLevel ? "(" + r.armsUserId + ") " : "") +
            capitalize_Words(r.firstName) +
            ' ' +
            (r.lastName ? capitalize_Words(r.lastName) : ""),
          name: capitalize_Words(r.firstName) + " " + capitalize_Words(r.lastName),
          type: 'remain',
          value: calcNegPercentage(r.totalCalls, r.targetCalls),
          actualValue: (r.totalCalls ? r.totalCalls : 0) + "/" + (r.targetCalls ? r.targetCalls : 0),
        },
        {
          indexCode: 'Clients_Register',
          timeDate:
          (roleName.value === agentLevel ? "(" + r.armsUserId + ") " : "") +
            capitalize_Words(r.firstName) +
            ' ' +
            (r.lastName ? capitalize_Words(r.lastName) : ""),
          name: capitalize_Words(r.firstName) + " " + capitalize_Words(r.lastName),
          type: 'done',
          value: calcPercentage(r.totalCRs, r.targetCRs, true),
          actualValue: (r.totalCRs ? r.totalCRs : 0) + "/" + (r.targetCRs ? r.targetCRs : 0),
        },
        {
          indexCode: 'Clients_Register',
          timeDate:
          (roleName.value === agentLevel ? "(" + r.armsUserId + ") " : "") +
            capitalize_Words(r.firstName) +
            ' ' +
            (r.lastName ? capitalize_Words(r.lastName) : ""),
          name: capitalize_Words(r.firstName) + " " + capitalize_Words(r.lastName),
          type: 'remain',
          value: calcNegPercentage(r.totalCRs, r.targetCRs),
          actualValue: (r.totalCRs ? r.totalCRs : 0) + "/" + (r.targetCRs ? r.targetCRs : 0),
        },
        {
          indexCode: 'Meetings',
          timeDate:
          (roleName.value === agentLevel ? "(" + r.armsUserId + ") " : "") +
            capitalize_Words(r.firstName) +
            ' ' +
            (r.lastName ? capitalize_Words(r.lastName) : ""),
          name: capitalize_Words(r.firstName) + " " + capitalize_Words(r.lastName),
          type: 'done',
          value: calcPercentage(r.totalMeetings, r.targetMeetings, true),
          actualValue: (r.totalMeetings ? r.totalMeetings : 0) + "/" + (r.targetMeetings ? r.targetMeetings : 0),
        },
        {
          indexCode: 'Meetings',
          timeDate:
          (roleName.value === agentLevel ? "(" + r.armsUserId + ") " : "") +
            capitalize_Words(r.firstName) +
            ' ' +
            (r.lastName ? capitalize_Words(r.lastName) : ""),
          name: capitalize_Words(r.firstName) + " " + capitalize_Words(r.lastName),
          type: 'remain',
          value: calcNegPercentage(r.totalMeetings, r.targetMeetings),
          actualValue: (r.totalMeetings ? r.totalMeetings : 0) + "/" + (r.targetMeetings ? r.targetMeetings : 0),
        },
        {
          indexCode: 'Conversions',
          timeDate:
          (roleName.value === agentLevel ? "(" + r.armsUserId + ") " : "") +
            capitalize_Words(r.firstName) +
            ' ' +
            (r.lastName ? capitalize_Words(r.lastName) : ""),
          name: capitalize_Words(r.firstName) + " " + capitalize_Words(r.lastName),
          type: 'done',
          value: calcPercentage(r.totalConversions, r.targetConversions, true),
          actualValue: (r.totalConversions ? r.totalConversions : 0) + "/" + (r.targetConversions ? r.targetConversions : 0),
        },
        {
          indexCode: 'Conversions',
          timeDate:
          (roleName.value === agentLevel ? "(" + r.armsUserId + ") " : "") +
            capitalize_Words(r.firstName) +
            ' ' +
            (r.lastName ? capitalize_Words(r.lastName) : ""),
          name: capitalize_Words(r.firstName) + " " + capitalize_Words(r.lastName),
          type: 'remain',
          value: calcNegPercentage(r.totalConversions, r.targetConversions),
          actualValue: (r.totalConversions ? r.totalConversions : 0) + "/" + (r.targetConversions ? r.targetConversions : 0),
        },
      ]
      t.push(...data)
    })
    setGraphData(t)
  }

  const formatGraphDataForRegion = (d) => {
    var t = []
    d.forEach((r) => {
      // let id = level == '4' ? r.armsUserId :
      let data = [
        // ...t,
        {
          indexCode: 'Calls',
          timeDate: capitalize_Words(r.firstName),
          name: capitalize_Words(r.firstName),
          type: 'done',
          value: calcPercentage(r.totalCalls, r.targetCalls, true),
          actualValue: (r.totalCalls ? r.totalCalls : 0) + "/" + (r.targetCalls ? r.targetCalls : 0),
        },
        {
          indexCode: 'Calls',
          timeDate: capitalize_Words(r.firstName),
          name: capitalize_Words(r.firstName),
          type: 'remain',
          value: calcNegPercentage(r.totalCalls, r.targetCalls),
          actualValue: (r.totalCalls ? r.totalCalls : 0) + "/" + (r.targetCalls ? r.targetCalls : 0),
        },
        {
          indexCode: 'Clients_Register',
          timeDate: capitalize_Words(r.firstName),
          name: capitalize_Words(r.firstName),
          type: 'done',
          value: calcPercentage(r.totalCRs, r.targetCRs, true),
          actualValue: (r.totalCRs ? r.totalCRs : 0) + "/" + (r.targetCRs ? r.targetCRs : 0),
        },
        {
          indexCode: 'Clients_Register',
          timeDate: capitalize_Words(r.firstName),
          name: capitalize_Words(r.firstName),
          type: 'remain',
          value: calcNegPercentage(r.totalCRs, r.targetCRs),
          actualValue: (r.totalCRs ? r.totalCRs : 0) + "/" + (r.targetCRs ? r.targetCRs : 0),
        },
        {
          indexCode: 'Meetings',
          timeDate: capitalize_Words(r.firstName),
          name: capitalize_Words(r.firstName),
          type: 'done',
          value: calcPercentage(r.totalMeetings, r.targetMeetings, true),
          actualValue: (r.totalMeetings ? r.totalMeetings : 0) + "/" + (r.targetMeetings ? r.targetMeetings : 0),
        },
        {
          indexCode: 'Meetings',
          timeDate: capitalize_Words(r.firstName),
          name: capitalize_Words(r.firstName),
          type: 'remain',
          value: calcNegPercentage(r.totalMeetings, r.targetMeetings),
          actualValue: (r.totalMeetings ? r.totalMeetings : 0) + "/" + (r.targetMeetings ? r.targetMeetings : 0),
        },
        {
          indexCode: 'Conversions',
          timeDate: capitalize_Words(r.firstName),
          name: capitalize_Words(r.firstName),
          type: 'done',
          value: calcPercentage(r.totalConversions, r.targetConversions, true),
          actualValue: (r.totalConversions ? r.totalConversions : 0) + "/" + (r.targetConversions ? r.targetConversions : 0),
        },
        {
          indexCode: 'Conversions',
          timeDate: capitalize_Words(r.firstName),
          name: capitalize_Words(r.firstName),
          type: 'remain',
          value: calcNegPercentage(r.totalConversions, r.targetConversions),
          actualValue: (r.totalConversions ? r.totalConversions : 0) + "/" + (r.targetConversions ? r.targetConversions : 0),
        },
      ]
      t.push(...data)
    })
    setGraphData(t)
  }

  const setRoleNameHandler = (v) => {
    setIsRoleChanged(true)
    if (v.value !== agentLevel) {
      setFilters({ ...filters, teamId: '' })
      setRoleName(v)
    }
    else {
      setRoleName(v)
    }
  }

  const filterData = () => {
    let teamsArray = []

      if(filters.teamId) {
        teamsArray = filters.teamId.map((team) => {
          return team.value
        })
      }
      setLoader(true)
      getData(teamsArray)

  }

  const clearFilter = async () => {
    setFilters({ fromDate: moment().startOf('month'), toDate: moment().endOf('day'), teamId: '' })
    setRoleName({ value: '1', label: 'Level 1' })
    setLoader(true)
    await axios
      .get(
        `${config.apiPath}/api/reporting/projectSalesGM?page=${page}&pageSize=${
          config.pageSize
        }&startDate=${moment().startOf('month').format('YYYY-MM-DD')}&endDate=${moment()
          .endOf('month')
          .format('YYYY-MM-DD')}&level=1`
      )
      .then((res) => {
        setReportData(res.data)
        formatGraphData(res.data)
        setAllData(res.data)
        setIsDateChanged(false)
        setTotal(res.data.length)
        setLoader(false)
        //if (roleName.value === '1' ) {
          const regions = ['N', 'C', 'S'];
          const regionResult = {
            N: {
              totalCalls: 0, totalMeetings: 0, totalConversions: 0, totalCRs: 0, targetAmount: 0,
              targetCalls: 0, targetMeetings: 0, targetConversions: 0, targetCRs: 0,
            }, S: {
              totalCalls: 0, totalMeetings: 0, totalConversions: 0, totalCRs: 0, targetAmount: 0,
              targetCalls: 0, targetMeetings: 0, targetConversions: 0, targetCRs: 0,
            }, C: {
              totalCalls: 0, totalMeetings: 0, totalConversions: 0, totalCRs: 0, targetAmount: 0,
              targetCalls: 0, targetMeetings: 0, targetConversions: 0, targetCRs: 0,
            }
          };
          res.data.map((item) => {
            if (regions.includes(item.region)) {
              regionResult[item.region]['totalCalls'] += !!item.totalCalls ? item.totalCalls : 0;
              regionResult[item.region]['targetCalls'] += !!item.targetCalls ? item.targetCalls : 0;
              regionResult[item.region]['totalCRs'] += !!item.totalCRs ? item.totalCRs : 0;
              regionResult[item.region]['targetCRs'] += !!item.targetCRs ? item.targetCRs : 0;
              regionResult[item.region]['totalMeetings'] += !!item.totalMeetings ? item.totalMeetings : 0;
              regionResult[item.region]['targetMeetings'] += !!item.targetMeetings ? item.targetMeetings : 0;
              regionResult[item.region]['totalConversions'] += !!item.totalConversions ? item.totalConversions : 0;
              regionResult[item.region]['targetAmount'] += !!item.targetAmount ? item.targetAmount : 0;
            }
          })
          let temp = res.data.filter((t) => {
            return t.level == roleName.value
          })
          let rData = [];
          rData.push({ firstName: 'North', teamId: 'N', ...regionResult['N'] })
          rData.push({ firstName: 'South', teamId: 'S', ...regionResult['S'] })
          rData.push({ firstName: 'Central', teamId: 'C', ...regionResult['C'] })


          setReportData(rData)

          formatGraphDataForRegion(rData)
        //}

      })
  }

  const filterTeams = (v) => {
    if (!v) {
      setTeams(allTeams)
    }
    else {
      let t = allTeams.filter((team) => {
        return team.organizationId === v.value
      })
      setTeams(t)
    }
  }

  const roleOptions = [
    { value: '1', label: 'Level 1' },
    { value: '2', label: 'Level 2' },
    { value: '3', label: 'Level 3' },
    { value: '4', label: 'Level 4' },
  ]

  //================GRAPH DATA
  const chartTheme = getTheme().colors10
  const myIndexNameColorMap = {
    Calls: {
      title: 'Calls',
      color: chartTheme[0],
    },
    Conversions: {
      title: 'Conversions',
      color: chartTheme[1],
    },
    Clients_Register: {
      title: 'Clients Register',
      color: chartTheme[2],
    },
    Meetings: {
      title: 'Meetings',
      color: chartTheme[3],
    },
  }

  const myTypeNameMap = {
    done: 'Achieved',
    remain: 'Remaining',
  }

  const regionOptions = [
    { value: 'N', label: 'North' },
    { value: 'S', label: 'South' },
    { value: 'C', label: 'Central' },
  ]

  const organization = [
    { value: 2, label: 'Graana' },
    { value: 1, label: 'Agency21' },
  ]

  const sortData = (data, attr1, attr2) => {
    setLoader(true)
    let desc = sortedBy.current.desc
    if(sortedBy.current.attr == attr1){
      desc = !desc
      sortedBy.current = {...sortedBy.current, desc: desc}
    } else {
      desc = true
      sortedBy.current = { attr: attr1, desc: true}
    }
    let x = data.sort((a, b) => {
          const valA1 = desc ? a[attr1] : b[attr1]
          const valB1 = desc ? b[attr1] : a[attr1]
          const valA2 = desc ? a[attr2] : b[attr2]
          const valB2 = desc ? b[attr2] : a[attr2]
          const valA = calcPercentage(valA1, valA2)
          const valB = calcPercentage(valB1, valB2)
          if (valA && !valB) {
              return -1
          } else if (!valA && valB) {
              return 1
          } else if (!valA && !valB) {
              return 0
          }
          return valB - valA
      })
      setReportData(x)
      setTimeout(() => {
        setLoader(false)
      }, 200)
  }

  const fetchReporting = async (team, check) => {
    if (!!check) {
      return
    }
    setLoader(true)
    const teamId = roleName.value === '1' ? `&region=${team}` : `&teamId=${team}`
    const officeLocationId = filters.officeLocationId && filters.officeLocationId.value ? `&officeLocationId=${filters.officeLocationId && filters.officeLocationId.value}` : ''
    const organizationId = filters.organizationId && filters.organizationId.value ? `&organizationId=${filters.organizationId && filters.organizationId.value}` : ''
    const region = filters.region && filters.region.value ? `&region=${filters.region && filters.region.value}` : ''
    let level = roleName.value !== '4' ? parseInt(roleName.value)+1 : parseInt(roleName.value)
    const roleLevel = `&level=${level}`
    setRoleName({ value: `${level}`, label: `Level ${level}` })
    await axios
      .get(
        `${config.apiPath}/api/reporting/projectSalesGM?page=${page}&pageSize=${
          config.pageSize
        }&startDate=${moment(filters.fromDate).format('YYYY-MM-DD')}&endDate=${moment(
          filters.toDate
        ).format('YYYY-MM-DD')}${teamId}${organizationId}${roleLevel}${region}`
      )
      .then((res) => {
        let tempData = res.data
        sortedBy.current = {attr: "abc", desc: false}
        sortData(tempData, "totalMeetings", "targetMeetings")
        setAllData(tempData)
        setTeamFilter(false)
        setTotal(tempData.length)
        formatGraphData(tempData)
        setIsDateChanged(false)
      })
      setIsRoleChanged(false)

    setLoader(false)
  }

  const {onDownload} =     useDownloadExcel({
    currentTableRef: ccmcRef.current,
    filename: 'CCMC Report_'+ moment().format('YYYY:MM:DD HH:mm')+':'+(new Date()).getSeconds(),
    sheet: 'ccmc'
})

  return (
    <>
      <div className="card mt-2">
        <div style={{ padding: '1%', height: '85vh' }}>
          <div className="ccmc-filters row">
            <div className="col-md-8 row">
              <div className="col-md-4 ccmcDateRange ccmc-filter">
                <DateRangePicker
                  isOutsideRange={() => false}
                  startDate={filters.fromDate}
                  endDate={filters.toDate}
                  startDateId="buyrent_date_input_start"
                  minimumNights={0}
                  endDateId="buyrent_date_input_end"
                  className="activeDatePicker"
                  onDatesChange={({ startDate: dateStart, endDate: dateEnd }) => {
                    if (dateStart) {
                      setFilters((filters) => ({
                        ...filters,
                        fromDate: dateStart,
                      }))
                      setIsDateChanged(true)
                    }
                    if (dateEnd) {
                      setFilters((filters) => ({
                        ...filters,
                        toDate: dateEnd,
                      }))
                      setIsDateChanged(true)
                    }
                  }}
                  focusedInput={focusedInput}
                  onFocusChange={(input) => setFocusedInput(input)}
                  showClearDates
                  noBorder={false}
                  displayFormat="DD/MM/YYYY"
                />
              </div>
              <div className="col-md-4 ccmc-filter">
                <Select
                  styles={{
                    // Fixes the overlapping problem of the component
                    menu: provided => ({ ...provided, zIndex: 9999 })
                  }}
                  name="company"
                  placeholder="Company"
                  value={filters.organizationId ? filters.organizationId : ''}
                  options={organization}
                  noOptionsMessage={() => 'No Options'}
                  isClearable={true}
                  onChange = {(value) => {
                    filterTeams(value)
                    setIsDateChanged(true)
                    setFilters({ ...filters, organizationId: value })
                  }}
                />
              </div>
              <div className="col-md-4 ccmc-filter">
                <Select
                  styles={{
                    // Fixes the overlapping problem of the component
                    menu: provided => ({ ...provided, zIndex: 9999 })
                  }}
                  name="Regions"
                  placeholder="Select Region"
                  value={filters.region ? filters.region : ''}
                  options={regionOptions}
                  noOptionsMessage={() => 'No Options'}
                  isClearable={true}
                  onChange = {(value) => {
                    setIsDateChanged(true)
                    setFilters({ ...filters, region: value })
                  }}
                />
              </div>
              <div className="col-md-4 ccmc-filter">
                <Select
                  name="role"
                  placeholder="Select Role"
                  value={roleName}
                  options={roleOptions}
                  noOptionsMessage={() => 'No Options'}
                  onChange={(value) => {
                    roleName.value === agentLevel && setTeamFilter(true)
                    setRoleNameHandler(value)
                  }}
                />
              </div>
              {roleName.value === agentLevel &&
                <div className="col-md-4 ccmc-filter accessMulti">
                  <Select
                    name="Team"
                    placeholder="Team"
                    value={filters.teamId ? filters.teamId : ''}
                    options={teams}
                    noOptionsMessage={() => 'No Options'}
                    isClearable={true}
                    isMulti
                    onChange={(value) => {
                      setFilters({ ...filters, teamId: value })
                    }}
                  />
                </div>
              }
              <div className="col-md-4 ccmc-filter">
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="customSwitch1"
                    onChange={() => setShowGraph(!showGraph)}
                  />
                  <label class="custom-control-label" for="customSwitch1">
                    Show Graph
                  </label>
                </div>
              </div>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-3 row justify-content-end ">
              <div className="col-md-6">
                <button className="export-btn" onClick={onDownload}>
                  Export
                </button>
              </div>
              <div className="col-md-6">
                <div className="ccmc-clearFilter">
                  <button className={`newFormSearch`} onClick={filterData}>
                    <span className={`fa fa-search`}></span>
                  </button>
                  <button className={`newFormClear`} onClick={clearFilter}>
                    Clear
                  </button>
                </div>
              </div>
            </div>
          </div>
          {showGraph ? (
            <div style={{ height: '65vh', overflow: 'scroll' }}>
              {!loader ? (
                <Chart
                  autoFit
                  data={graphData}
                  height={graphData.length < 40 ? (10+(graphData.length * 8)) : graphData.length * 5}
                  padding={[20, 20, 20, 200]}
                  appendPadding={[10]}
                >
                  <Legend
                    visible={false}
                    itemName={{
                      formatter: (text, item) => {
                        return myTypeNameMap[text]
                      },
                    }}
                  />
                  <Tooltip showMarkers={false} />
                  <Facet
                    fields={['indexCode']}
                    type="rect"
                    columnTitle={{
                      offsetY: -15,
                      style: {
                        fontSize: 18,
                        fontWeight: 700,
                        fill: '#505050',
                      },
                      formatter: (val) => {
                        return myIndexNameColorMap[val].title
                      },
                    }}
                    eachView={(view, facet) => {
                      view.coordinate().transpose()

                      if (facet.columnIndex === 0) {
                        view.axis('timeDate', {
                          tickLine: null,
                          line: null,
                        })
                        view.axis('value', false)
                      } else {
                        view.axis(false)
                      }

                      const color = myIndexNameColorMap[facet.columnValue].color

                      view
                        .interval()
                        .adjust('stack')
                        .position('timeDate*value')
                        .color('type', [color, '#ebedf0'])
                        .size(20)
                        .label('value*type', (value, type) => {
                          if (type === 'remain') {
                            return null
                          }
                          const offset = value < 30 ? value+10 : -4
                          return {
                            content: `${value}%`,
                            offset,
                          }
                        })
                        .tooltip('name*value*indexCode*type*actualValue', (name, value, indexCode, type, actualValue) => {
                          return {
                            title: `Name：${name}`,
                            value: `${actualValue}`,
                            name: indexCode,
                          }
                        })
                      view.interaction('element-active')
                    }}
                  />
                </Chart>
              ) : (
                <p style={{ paddingLeft: '36vw', paddingTop: '10vh' }}>Loading ...</p>
              )}
            </div>
          ) : (
            <div className="mg-table">
              <table style={{ height: '72%'}}  ref={ccmcRef}>
                <div className='headerTR'>
                  <thead>
                  <tr>
                    <th style={{ minWidth: '3vw' }}>Sr.</th>
                    <th style={{ minWidth: '14vw' }}>Advisor Name</th>
                    <th className="clickable-div" onClick={() => {sortData(reportData, 'totalCalls', 'targetCalls')}} style={{ minWidth: '9vw', cursor: "pointer" }}>Connect (10)<i className="fa fa-sort ml-2"></i></th>
                    <th style={{ minWidth: '4vw' }}>%</th>
                    <th className="clickable-div" onClick={() => {sortData(reportData, 'totalCRs', "targetCRs")}} style={{ minWidth: '9vw', cursor: "pointer" }}>CR (2)<i className="fa fa-sort ml-2"></i></th>
                    <th style={{ minWidth: '4vw' }}>%</th>
                    <th className="clickable-div" onClick={() => {sortData(reportData, 'totalMeetings', "targetMeetings")}} style={{ minWidth: '9vw', cursor: "pointer" }}>Meetings (1)<i className="fa fa-sort ml-2"></i></th>
                    <th style={{ minWidth: '4vw' }}>%</th>
                    <th className="clickable-div" onClick={() => {sortData(reportData, 'totalConversions', "targetConversions")}} style={{ minWidth: '9vw', cursor: "pointer" }}>Conversions<i className="fa fa-sort ml-2"></i></th>
                    <th style={{ minWidth: '4vw' }}>%</th>
                    {/* <th style={{ minWidth: '10vw' }}>Avg Response Time (Hours)</th> */}
                  </tr>
                  </thead>
                </div>
                <tbody>
                {loader ? (
                  <tr>
                    <p
                      style={{ paddingLeft: '36vw', paddingTop: '10vh' }}
                      colSpan={10}
                      className={''}
                    >
                      Loading ...
                    </p>
                  </tr>
                ) : reportData && reportData.length ? (
                  reportData &&
                  reportData.map((e, idx) => {
                    return <TableTr key={idx} data={e} index={idx + 1} fetchReporting={fetchReporting} />
                  })
                ) : (
                  <tr>
                    <p
                      style={{ paddingLeft: '36vw', paddingTop: '10vh' }}
                      colSpan={10}
                      className={''}
                    >
                      No records found
                    </p>
                  </tr>
                )}
                </tbody>
              </table>
              <div className="row mt-3 mb-2">
                <div className="col-md-4 totalCountAlignment">
                  <span className="totalCountStyle">
                    Showing
                    <span className="totalCountSubStyle">{reportData.length}</span> /{' '}
                    {reportData.length}
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

const TableTr = ({ key, data, index, fetchReporting }) => {
  return (
    <tr className={!!data.lastName ? "" : 'hoverForTr'} style = {{ cursor: !!data.lastName ? "" : "pointer" }} onClick={() =>  fetchReporting(data.teamId, data.lastName)}>
      <td className="index-td" style={{ minWidth: '3vw' }}>
        {index}
      </td>
      <td className="td-border td-name" style={{ maxWidth: '14vw', minWidth: '14vw', paddingLeft: '1%' }}>
        {data.firstName} {data.lastName}
      </td>
      <td className="td-border" style={{ minWidth: '9vw' }}>
        {(!!data.totalCalls ? Number(data.totalCalls).toLocaleString() : 0)}
      </td>
      <td
        className={
          calcPercentage(data.totalCalls, data.targetCalls) < 50
            ? 'red-percent'
            : calcPercentage(data.totalCalls, data.targetCalls) < 70
              ? 'yellow-percent'
              : 'green-percent'
        }
        style={{ minWidth: '4vw' }}
      >
        {calcPercentage(data.totalCalls, data.targetCalls) === -1
          ? 'N/A'
          : calcPercentage(data.totalCalls, data.targetCalls) + `%`}
      </td>
      <td className="td-border" style={{ minWidth: '9vw' }}>
        {(!!data.totalCRs ? Number(data.totalCRs).toLocaleString() : 0)}
      </td>
      <td
        className={
          calcPercentage(data.totalCRs, data.targetCRs) < 50
            ? 'red-percent'
            : calcPercentage(data.totalCRs, data.targetCRs) < 70
              ? 'yellow-percent'
              : 'green-percent'
        }
        style={{ minWidth: '4vw' }}
      >
        {calcPercentage(data.totalCRs, data.targetCRs) === -1
          ? 'N/A'
          : calcPercentage(data.totalCRs, data.targetCRs) + `%`}
      </td>
      <td className="td-border" style={{ minWidth: '9vw' }}>
        {(!!data.totalMeetings ? Number(data.totalMeetings).toLocaleString() : 0)}
      </td>
      <td
        className={
          calcPercentage(data.totalMeetings, data.targetMeetings) < 50
            ? 'red-percent'
            : calcPercentage(data.totalMeetings, data.targetMeetings) < 70
              ? 'yellow-percent'
              : 'green-percent'
        }
        style={{ minWidth: '4vw' }}
      >
        {calcPercentage(data.totalMeetings, data.targetMeetings) === -1
          ? 'N/A'
          : calcPercentage(data.totalMeetings, data.targetMeetings) + `%`}
      </td>
      <td className="td-border" style={{ minWidth: '9vw' }}>
        {(!!data.totalConversions ? Number(data.totalConversions).toLocaleString() : 0)}
      </td>
      <td
        className={
          calcPercentage(data.totalConversions, data.targetAmount) < 50
            ? 'red-percent'
            : calcPercentage(data.totalConversions, data.targetAmount) < 70
              ? 'yellow-percent'
              : 'green-percent'
        }
        style={{ minWidth: '4vw' }}
      >
        {calcPercentage(data.totalConversions, data.targetAmount) === -1
          ? 'N/A'
          : calcPercentage(data.totalConversions, data.targetAmount) + `%`}
      </td>
      {/* <td className="td-border" style={{ minWidth: '10vw' }}>
        {!!data.responseTime && data.responseTime !== 0 ? data.responseTime.toFixed(1) : '---'}
      </td> */}
    </tr>
  )
}

export const mapDispatchToProps = (dispatch) => {
  return {
    setTitle: (title) => dispatch(setTitle(title)),
    //    fetchAllProjects: () => dispatch(fetchAllProjectsNew({ offset: 0 })),
  }
}
export const mapStateToProps = (state, ownProps) => {
  return {
    projects: state.Projects.allProjects,
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withCookies(CcmcGM)))
