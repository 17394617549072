import React, { useEffect, useState } from 'react'
import IosIcon from '../../../images/device/ios.svg'
import WindowsIcon from '../../../images/device/windows_phone.svg'
import AndroidIcon from '../../../images/device/android.svg'
import PhoneIcon from '../../../images/device/phone.svg'
import TickIcon from '../../../images/device/tick.svg'
import './index.scss'
import moment from 'moment'
import Button from '../../common/Button'

const SingleDevice = (props) => {
  const [deviceLoader, setDeviceLoader] = useState(null)

  let d = props.device

  return (<div className={`${props.fromList ? "single-device row pt-2 pb-2": "single-device selected row pt-2 pb-2"}`}>
                <div className={"col-sm-2 d-flex justify-content-center align-items-center"}>
                  <img src={
                    d.deviceDetails ?
                      (d.deviceDetails.osName === 'Android' ? AndroidIcon:
                        d.deviceDetails.osName === 'iOS' ? IosIcon:
                          d.deviceDetails.osName === 'Windows' ? WindowsIcon: PhoneIcon) :PhoneIcon} className="device-img container-image" />
                  {d.isDefault ? <img src={TickIcon} className="preferred-device container-image" />:<></>}
                </div>
                <div className={"device-detail pl-0 col-sm-10 "+`${!props.fromList ? 'd-flex align-items-center':''}`}>
                  <div className={"device-name"}>{d.deviceDetails && d.deviceDetails.deviceName || 'N/A'}</div>
                  {props.fromList && <div className={"device-date"} style={{color:'#909094'}}>{
                    d.lastModified ? moment(d.lastModified).format("h:mm A ddd, MMM DD") : '--'
                  }</div>}
                  {props.fromList &&
                  <div style={{position:'absolute', top: 13, right: 20}}>
                    <Button
                      style={{width: 100}}
                      textColor={'white'}
                      buttonColor='#026ff2'
                      className={"rounded-pill"} onClick={() => {
                        setDeviceLoader(true)
                        props.makeCall(props.device)
                    }}
                    text={ deviceLoader ? <span className="fa fa-circle-o-notch fa-spin"></span>: 'Call'}
                    />
                  </div>}
                </div>
              </div>
  )
}

export default SingleDevice
