/** @format */

import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { connect } from 'react-redux'
import { capitalizeWordsWithoutUnderscore } from '../../../utils/commonFormat'
import {
  destroyBuyRentCompanyLeadState,
  destroyBuyRentLeadState,
  getBuyRentLeads,
  setBuyRentLeadFilters,
  setLeadCountFilter,
} from '../../../actions/buyrentLeads'
import { setProjectLeadCountFilter, setProjectLeadFilters } from '../../../actions/projectLeads'
const LeadStatusCard = (props) => {
  const PickCardColor = (color) => {
    let obj = {
      open: { background: '#CEECFC', color: '#1D1D26' },
      total_leads: { background: '#006FF2', color: '#fff' },
      shortlisting: { background: '#FEF3C6', color: '#1D1D26' },
      in_progress: { background: '#FEF3C6', color: '#1D1D26' },
      pending_token: { background: '#FEF3C6', color: '#1D1D26' },
      token: { background: '#FCE7CE', color: '#1D1D26' },
      payment: { background: '#CAF4A4', color: '#1D1D26' },
      closed_lost: { background: '#FDE0E2', color: '#1D1D26' },
      closed_won: { background: '#caf4a4', color: '#536d3a' },
    }

    return obj[color]
  }

  const MaskFieldValue = (value,isProjectLead,pageType) => {
    let originalData = {
      offer: 'Shortlisting',
      token: 'Token',
      payment:  isProjectLead ? 'Payment' :'Payment & Legal',
      shortlisting: 'Shortlisting',
      in_progress: 'In Progress',
      pending_token: 'Pending Token',
      open: 'Open',
      total_leads: 
               pageType  ==='/project-deal' || 
               pageType === "/referred-project-deal" ||
               pageType === '/project-deactivated-deal' ||
               pageType  === '/team-project-deal' ||
               pageType  === '/buy-rent-deal' ||
               pageType  === '/referred-buy-rent-deal' ||
               pageType  === '/buy-rent-deactivated-deal' ||
               pageType  === '/team-buy-rent-deal' 
               ? 'Total Deals'  :'Total Leads',
      closed_lost: 'Closed Lost',
      closed_won: 'Closed Won',
      inprogress: 'In Progress',
    }

    return originalData[value]
  }

  const useStyles = makeStyles({
    leadStatusCard: {
      width: '10%',
      margin: 20,
      backgroundColor:
        PickCardColor(props.item.status) && PickCardColor(props.item.status).background,
      borderRadius: 5,
      height: 80,
      width: '100vw',
      padding: 15,
      alignItems: 'flex-start',
      justifyContent: 'center',
      display: 'flex',
      flexDirection: 'column',
    },
    leadStatusMainTitle: {
      color: PickCardColor(props.item.status) && PickCardColor(props.item.status).color,
      fontWeight: '400',
      fontSize: 20,
    },
    leadStatusCardSubtitle: {
      color: PickCardColor(props.item.status) && PickCardColor(props.item.status).color,
      fontWeight: 700,
      fontSize: 24,
    },
  })

  const { item, leadCountFilter, projectLeadCountFilter,pageType } = props
  // console.log('leadbox',props)

  const check = (value, isProjectLead) => {
    let listStatus = [
      { label: 'Shortlisting', value: 'offer' },
      { label: 'Token', value: 'token' },
      { label: 'Payment & Legal', value: 'payment' },
      { label: 'Shortlisting', value: 'shortlisting' },
      { label: 'Open', value: 'open' },
      { label: 'Total Leads', value: 'total_leads' },
      { label: 'Closed Lost', value: 'closed_lost' },
      { label: 'Closed Won', value: 'closed_won' },
      { label: 'In Progress', value: 'in_progress' },
      { label: 'Pending Token', value: 'pending_token' },
    ]

    // console.log(props.history)
    let a = listStatus.filter((item) => {
      return item.value === value
    })

    let appliedFilters = {
      ...props.buyrentLeadFilters,
      status: {
        value: a[0].value,
        label: a[0].label,
      },
    }
    /*if(props.buyrentLeadFilters){
      appliedFilters = {...appliedFilters, props.buyrentLeadFilters}
    }*/
    /*let pageUrl = '&pageType=myLeads&hasBooking=false'
    if(buyrentLeads && buyrentLeads.companyLeads)
      pageUrl = '&pageType=myLeads&hasBooking=false&companyLead=yes'
    else if(buyrentLeads && buyrentLeads.referredLeads)
      pageUrl = '&pageType=referredLeads&hasBooking=false'
    else if(buyrentLeads && buyrentLeads.teamLeads)
      pageUrl = '&pageType=teamLeads&hasBooking=false'
    else if(buyrentLeads && buyrentLeads.teamDeals)
      pageUrl = '&pageType=teamDeals&hasBooking=false'
    else if(buyrentLeads && buyrentLeads.demandLeads)
      pageUrl = '&pageType=demandLeads&hasBooking=false'
    else if(buyrentLeads && buyrentLeads.deactivatedLeads)
      pageUrl = '&pageType=deactivatedLeads&hasBooking=false'
    else if(buyrentLeads && buyrentLeads.deactivatedDeals)
      pageUrl = '&pageType=deactivatedLeads&hasBooking=true'
    else if(buyrentLeads && buyrentLeads.myLeads)
      pageUrl = '&pageType=myLeads&hasBooking=false&companyLead=no'*/

    if ((leadCountFilter && leadCountFilter === value) || (projectLeadCountFilter && projectLeadCountFilter === value) || value === 'total_leads') {
      isProjectLead ? props.setProjectLeadFilters({}) : props.setBuyRentLeadFilters({})
      isProjectLead ? props.setProjectLeadCountFilter('none') : props.setLeadCountFilter('none')
    } else {
      isProjectLead ? props.setProjectLeadFilters(appliedFilters) : props.setBuyRentLeadFilters(appliedFilters)
      isProjectLead ? props.setProjectLeadCountFilter(value) : props.setLeadCountFilter(value)
    }
  }

  const classes = useStyles(props)
  return (
    <div
      className={`${classes.leadStatusCard}`}
      onClick={() => check(item.status, item.isProjectLead)}
      style={{ cursor: 'pointer' }}
    >
     
      <div className={classes.leadStatusMainTitle}>
        {capitalizeWordsWithoutUnderscore(MaskFieldValue(item.status,item.isProjectLead,pageType))}
      </div>
      <div className={classes.leadStatusCardSubtitle}>{item.count}</div>
    </div>
  )
}

export const mapDispatchToProps = (dispatch) => {
  return {
    setBuyRentLeadFilters: (filters) => dispatch(setBuyRentLeadFilters(filters)),
    setProjectLeadFilters: (filters) => dispatch(setProjectLeadFilters(filters)),
    setLeadCountFilter: (status) => dispatch(setLeadCountFilter(status)),
    setProjectLeadCountFilter: (status) => dispatch(setProjectLeadCountFilter(status)),
  }
}

export const mapStateToProps = (state) => {
  return {
    leads: state.BuyRentLeads.companyLeads,
    isLoading: state.BuyRentLeads.isLoading,
    user: state.user.userInfo,
    moreFilter: state.GeneralSetting.moreFilter,
    buyrentLeadFilters: state.BuyRentLeads.filters.filters,
    projectLeadFilters: state.ProjectLeads.filters.filters,
    isClientFilterLoading: state.Leads.isClientFilterLoading,
    isTerminalUser: state.user.isTerminalUser,
    countries: state.user.countries,
    organizations: state.user.allOrganisations,
    cities: state.user.cities,
    origins: state.Leads.origins,
    areaGroups: state.Areas.areaGroups,
    leadOrder: state.GeneralSetting.dataSort,
    quickFilter: state.GeneralSetting.quickFilter,
    clientNames: state.Leads.allClients,
    leadCountFilter: state.BuyRentLeads.leadCountFilter,
    projectLeadCountFilter: state.ProjectLeads.projectLeadCountFilter,
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(LeadStatusCard)
