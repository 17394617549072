/** @format */

import React, { useEffect, useState } from 'react'
import { meetingStatusValues, callFeedback } from '../../utils/constant_data'
import { showErrorMessage, showInfoMessage } from '../../utils/commonFormat'
import axios from 'axios'
import config from '../../config'
import { toast, ToastContainer } from 'react-toastify'
import { Modal, ModalBody } from 'reactstrap'
import ClosedLostConfirm from '../Modals/ClosedLostConfirm'
import { connect } from 'react-redux'
import { fetchLeads } from '../../actions/leads'
import { checkGoogleAuthorize } from '../../utils/helper'
import AfterCallActionModal from '../Modals/AfterCallActionModal'
import ActionDropList from '../ActionDropList'

const MeetingActions = (props) => {
  const [feedbackValue, setFeedbackValue] = useState('')
  const [loader, setLoader] = useState(false)
  const [diaryAction, setDiaryAction] = useState(null)
  const [hideFeedbackModal, setHideFeedbackModal] = useState(false)
  const [afterCallActionModal, setAfterCallActionModal] = useState(false)

  const lowercasedFilter = feedbackValue.toLowerCase()

  let options = meetingStatusValues
  if (props.feedbackType === 'Call') options = callFeedback

  const filteredData = options.filter((item) => {
    return Object.keys(item).some((key) => item[key].toLowerCase().includes(lowercasedFilter))
  })

  useEffect(() => {
    if (['dealSigned', 'followup', 'closedLost'].indexOf(diaryAction) > -1) {
      // we need google authentication for only these type of tasks
      if (['meeting', 'task', 'follow_up'].indexOf(props.meeting.taskType) > -1)
        checkGoogleAuthorize(props.user, afterGoogleAuthCallback)
      else changeFeedbackStatus(props.meeting.id)
    }
  }, [diaryAction])

  const afterGoogleAuthCallback = (token_exist_already = false) => {
    if (!token_exist_already) props.fetchUser()

    if (['dealSigned', 'followup', 'closedLost'].indexOf(diaryAction) > -1)
      changeFeedbackStatus(props.meeting.id)
  }

  const closedLost = () => {
    let url = `${config.apiPath}/api/leads/project`
    if (props.leadType === 'buy-rent') url = `${config.apiPath}/api/leads`

    setLoader(true)
    axios({
      method: 'patch',
      url: url,
      params: { id: [props.leadId] },
      data: { reasons: feedbackValue },
    }).then((res) => {
      setLoader(false)
      props.setCloseLostToggleState(false)
      props.closedLost()
    })
  }

  const afterDiaryUpdate = () => {
    props.setShowFeedback(false)
    if (diaryAction === 'dealSigned') props.dealSigned()
    else if (diaryAction === 'followup') props.setFollowupModalFlag(true)
    else if (diaryAction === 'closedLost') {
      closedLost(feedbackValue)
      props.closedLost()
    }

    setDiaryAction(null)

    toast.dismiss()
  }

  const changeFeedbackStatus = (id) => {
    if (props.taskModal) showInfoMessage('Marking meeting as done...')

    setLoader(true)

    let data = {
      response: feedbackValue,
      comments: feedbackValue,
      status: 'completed',
      title: props.feedbackType === 'Call' ? 'whatsapp' : 'meeting',
    }
    if (props.leadType === 'buy-rent') data.rcmLeadId = props.leadId
    else data.leadId = props.leadId

    if (props.meeting.id) {
      axios.patch(`${config.apiPath}/api/diary/update?id=${id}`, data).then((res) => {
        setAfterCallActionModal(true)
        setHideFeedbackModal(true)
        if (props.onMeetingDone) props.onMeetingDone()

        toast.dismiss()
      })
    } else {
      let data = {
        response: feedbackValue,
        comments: feedbackValue,
        status: 'completed',
        title: 'phone',
      }

      // in case when data is not added in dairy yet! that's the case of initiate call
      axios
        .post(`${config.apiPath}/api/leads/project/meeting`, {
          ...props.meeting,
          ...data,
        })
        .then((res) => {
          setAfterCallActionModal(true)
          setHideFeedbackModal(true)
          toast.dismiss()
        })
    }
  }

  return (
    <>
      {!hideFeedbackModal ? (
        <Modal
          isOpen={props.showFeedback}
          toggle={(toggle) => props.setShowFeedback(toggle)}
          size="lg"
          className="feedback-popup-width"
          contentClassName="feedback-width"
        >
          <ModalBody>
            <div className="popup-body-header-title">
              <h5>{props.feedbackType} feedback</h5>
            </div>
            <div className="close-icon-container">
              <i
                className="fal fa-times-circle close-icon"
                onClick={() => props.setShowFeedback(false)}
              ></i>
            </div>
            <div className="feedback-container">
              <div>
                <textarea
                  rows="4"
                  onChange={(e) => setFeedbackValue(e.target.value)}
                  value={feedbackValue}
                  placeholder="Enter Comment "
                />
              </div>
              <div className="feedback-area mb-2">
                {filteredData &&
                  filteredData.map((item, i) => (
                    <div
                      key={i}
                      className="space-between"
                      style={{ borderColor: `${item.colorCode}` }}
                      onClick={() => setFeedbackValue(item.name)}
                    >
                      {item.name}
                    </div>
                  ))}
              </div>

              <div className="button center-align-feedback">
                <div
                  className="task-button"
                  style={{ width: 150 }}
                  onClick={() => {
                    if (!feedbackValue) {
                      showErrorMessage('Please select reason')
                      return
                    }
                    if (!loader) changeFeedbackStatus(props.meeting.id)
                  }}
                >
                  {loader ? <i className={`fa fa-circle-o-notch fa-spin`}></i> : 'Submit'}
                </div>
              </div>
            </div>
            <ToastContainer
              position="bottom-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnVisibilityChange
              draggable
              pauseOnHover
            />
          </ModalBody>
        </Modal>
      ) : (
        <></>
      )}

      <AfterCallActionModal
        shouldCloseAfterAdd={(toggle) => props.shouldCloseAfterAdd(toggle)}
        setShowFeedbackModal={(toggle) => props.setShowFeedback(toggle)}
        setHideFeedbackModal={(toggle) => setHideFeedbackModal(toggle)}
        meetingToggle={() => props.meetingToggle()}
        setCloseLostToggleState={() => props.setCloseLostToggleState(true)}
        callToggle={() => props.callToggle()}
        afterCallActionModal={afterCallActionModal}
        setAfterCallActionModal={(toggle, action) => {
          setAfterCallActionModal(toggle)
          props.setShowFeedback(false)
          setHideFeedbackModal(false)
          props.callBack(action)
        }}
        setFollowupModalFlag={(toggle) => props.setFollowupModalFlag(toggle)}
      />
    </>
  )
}

export const mapDispatchToProps = (dispatch) => {
  return {
    fetchLeads: (query, sortingOrder) => dispatch(fetchLeads(query, sortingOrder)),
  }
}

export default connect(null, mapDispatchToProps)(MeetingActions)
