/** @format */

import { startAsyncValidation } from 'redux-form'
import Swal from 'sweetalert2'
import * as ActionTypes from '../actions/team'

const initialState = {}

const Team = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.ADD_TEAM:
      if (action.data.data === 'team added Sucessfully') {
        Swal.fire('Team Created', 'Team Created Successfully', 'success')
      }
      return {
        ...state,
        team: action.data,
      }
    case ActionTypes.SIGN_UP_FAIL:
      return false
    case ActionTypes.UPDATE_TEAM:
      return {
        ...state,
        team: action.data,
      }
    case ActionTypes.UPDATE_TEAM_FAILURE:
      return false
    case ActionTypes.LOADER:
      return {
        ...state,
        loader: true,
      }
    case ActionTypes.LOADER_SUCCESS:
      return {
        ...state,
        loader: false,
      }
    case ActionTypes.FETCH_TEAMS:
      return {
        ...state,
        teamsData: action.data,
      }
    case ActionTypes.CLEAR_BUTTON_LOADER:
      return {
        ...state,
        clearButton: true,
      }
    case ActionTypes.CLEAR_BUTTON_SUCCESS:
      return {
        ...state,
        clearButton: false,
      }
    case ActionTypes.DEACTIVATED_FETCH_TEAM:
      return {
        ...state,
        deactivatedTeamData: action.data,
        isLoading: false,
      }
    case ActionTypes.ADD_TEAM_LOADER:
      return {
        ...state,
        addTeamLoader: true,
      }
    case ActionTypes.ADD_TEAM_LOADER_SUCCESS:
      return {
        ...state,
        addTeamLoader: false,
      }
    case ActionTypes.FETCH_ALL_TEAMS:
      return {
        ...state,
        allTeams: action.data,
      }
    case ActionTypes.DEACTIVATED_TEAM_FAILURE:
      return false
    default:
      return {
        ...state,
      }
  }
}

export default Team
