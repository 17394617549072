/** @format */

import axios from 'axios'
import config from '../config'
import history from '../utils/routingHistory'
import { toast } from 'react-toastify'
import { encodeQueryData, scrollToBottom, shouldShowAllLeads } from '../utils/helper'
import { isAira } from '../components/common/LeadView/Wanted/WantedUtilHelper'
import { accountsPermissions } from '../utils/checkPermissions'
import { showToastMsg } from '../utils/helper'
export const ERROR_SHOW = 'ERROR_SHOW'
export const SUCCESS_MESSAGE = 'SUCCESS_MESSAGE'
export const FETCH_LEAD = 'FETCH_LEAD'
export const FETCH_CLIENT_LEAD = 'FETCH_CLIENT_LEAD'
export const FETCH_BR_CLIENT_LEAD = 'FETCH_BR_CLIENT_LEAD'
export const FETCH_LEAD_FAILURE = 'FETCH_LEAD_FAILURE'
export const LEAD_LOADING = 'LEAD_LOADING'
export const CLIENT_LEAD_LOADING = 'CLIENT_LEAD_LOADING'
export const LEAD_LOADING_SUCCESS = 'LEAD_LOADING_SUCCESS'
export const CLIENT_LEAD_LOADING_SUCCESS = 'CLIENT_LEAD_LOADING_SUCCESS'
export const FETCH_CLIENT_LEAD_FAILURE = 'FETCH_CLIENT_LEAD_FAILURE'
export const FETCH_BR_LEAD = 'FETCH_BR_LEAD'
export const FETCH_BR_LEAD_LOAD = 'FETCH_BR_LEAD_LOAD'
export const FETCH_BR_CLIENT_LEAD_LOAD = 'FETCH_BR_CLIENT_LEAD_LOAD'
export const BR_LEAD_LOADING_SUCCESS = 'BR_LEAD_LOADING_SUCCESS'
export const CLIENT_BR_LEAD_LOADING_SUCCESS = 'CLIENT_BR_LEAD_LOADING_SUCCESS'
export const FETCH_BR_LEAD_FAILURE = 'FETCH_BR_LEAD_FAILURE'
export const FETCH_BR_CLIENT_LEAD_FAILURE = 'FETCH_BR_CLIENT_LEAD_FAILURE'
export const FETCH_USERS = 'FETCH_USERS'
export const FETCH_USER_LOADING = 'FETCH_USER_LOADING'
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS'
export const FETCH_ACCOUNT_VIEW = 'FETCH_ACCOUNT_VIEW'
export const FETCH_ACCOUNT_VIEW_LOADER = 'FETCH_ACCOUNT_VIEW_LOADER'
export const FETCH_ACCOUNT_VIEW_SUCCESS = 'FETCH_ACCOUNT_VIEW_SUCCESS'
export const FETCH_ACCOUNT_VIEW_FAILURE = 'FETCH_ACCOUNT_VIEW_FAILURE'
export const FETCH_BUY_RENT_SALES_VIEW = 'FETCH_BUY_RENT_SALES_VIEW'
export const FETCH_BUY_RENT_SALES_VIEW_LOADER = 'FETCH_BUY_RENT_SALES_VIEW_LOADER'
export const FETCH_BUY_RENT_SALES_VIEW_SUCCESS = 'FETCH_BUY_RENT_SALES_VIEW_SUCCESS'
export const FETCH_BUY_RENT_SALES_VIEW_FAILURE = 'FETCH_BUY_RENT_SALES_VIEW_FAILURE'
export const FETCH_PAYMENT_DETAIL = 'FETCH_PAYMENT_DETAIL'
export const FETCH_PAYMENT_DETAIL_LOADER = 'FETCH_PAYMENT_DETAIL_LOADER'
export const FETCH_PAYMENT_DETAIL_SUCCESS = 'FETCH_PAYMENT_DETAIL_SUCCESS'
export const FETCH_PAYMENT_DETAIL_FAILURE = 'FETCH_PAYMENT_DETAIL_FAILURE'
export const FETCH_ALL_CLIENT = 'FETCH_ALL_CLIENT'
export const FETCH_SINGLE_INVESTMENT_LEAD = 'FETCH_SINGLE_INVESTMENT_LEAD'
export const FETCH_SINGLE_INVESTMENT_LOADER = 'FETCH_SINGLE_INVESTMENT_LOADER'
export const FETCH_SINGLE_INVESTMENT_SUCCESS = 'FETCH_SINGLE_INVESTMENT_SUCCESS'
export const FETCH_SINGLE_INVESTMENT_FAILURE = 'FETCH_SINGLE_INVESTMENT_FAILURE'
export const FETCH_LEAD_TIMELINE = 'FETCH_LEAD_TIMELINE'
export const FETCH_LEAD_TIMELINE_LOADER = 'FETCH_LEAD_TIMELINE_LOADER'
export const FETCH_LEAD_TIMELINE_SUCCESS = 'FETCH_LEAD_TIMELINE_SUCCESS'
export const ADD_DIARY_CALL = 'ADD_DIARY_CALL'
export const ADD_DIARY_CALL_LOADER = 'ADD_DIARY_CALL_LOADER'
export const ADD_DIARY_CALL_FAILURE = 'ADD_DIARY_CALL_FAILURE'
export const LEAD_CUSTOM_LOADER = 'LEAD_CUSTOM_LOADER'
export const SET_DIARY_DATE = 'SET_DIARY_DATE'
export const SET_DIARY_TIME = 'SET_DIARY_TIME'
export const SET_DIARY_END_TIME = 'SET_DIARY_END_TIME'
export const ADD_DIARY_MEETING = 'ADD_DIARY_MEETING'
export const ADD_DIARY_MEETING_FAILURE = 'ADD_DIARY_MEETING_FAILURE'
export const FETCH_ALL_COMMENTS = 'FETCH_ALL_COMMENTS'
export const FETCH_ALL_COMMENTS_LOADER = 'FETCH_ALL_COMMENTS_LOADER'
export const FETCH_ALL_COMMENTS_FAILURE = 'FETCH_ALL_COMMENTS_FAILURE'
export const ADD_COMMENT = 'ADD_COMMENT'
export const ADD_COMMENT_LOADER = 'ADD_COMMENT_LOADER'
export const ADD_COMMENT_FAILURE = 'ADD_COMMENT_FAILURE'
export const SET_DESCRIPTION = 'SET_DESCRIPTION'
export const ADD_TASK_SUBJECT = 'ADD_TASK_SUBJECT'
export const ADD_SELECTED_AGENTS = 'ADD_SELECTED_AGENTS'
export const SET_CLOSED_OPTIONS = 'SET_CLOSED_OPTIONS'
export const ADD_DETAIL = 'ADD_DETAIL'
export const ADD_DETAIL_LOADER = 'ADD_DETAIL_LOADER'
export const FETCH_FRESH_LEADS = 'FETCH_FRESH_LEADS'
export const FETCH_FRESH_BR_LEADS = 'FETCH_FRESH_BR_LEADS'
export const ADD_TOKEN = 'ADD_TOKEN'
export const ADD_TOKEN_LOADER = 'ADD_TOKEN_LOADER'
export const ADD_PAYMENT = 'ADD_PAYMENT'
export const ADD_PAYMENT_LOADER = 'ADD_PAYMENT_LOADER'
export const ADD_NEXTOFKIN_LOADER = 'ADD_NEXTOFKIN_LOADER'
export const ADD_BANK_LOADER = 'ADD_BANK_LOADER'
export const ADD_TAX = 'ADD_TAX'
export const ADD_TAX_LOADER = 'ADD_TAX_LOADER'
export const FETCH_SINGLE_PAYMENT = 'FETCH_SINGLE_PAYMENT'
export const EDIT_SINGLE_PAYMENT = 'EDIT_SINGLE_PAYMENT'
export const TOGGLE_EDIT = 'TOGGLE_EDIT'
export const INVESTMENT_CALL = 'INVESTMENT_CALL'
export const INVESTMENT_CALL_LOADER = 'INVESTMENT_CALL_LOADER'
export const FETCH_USER_CLIENTS = 'FETCH_USER_CLIENTS'
export const FETCH_CLIENTS_BANK = 'FETCH_CLIENTS_BANK'
export const FETCH_Next_Kin = 'FETCH_Next_Kin'
export const ADD_LEAD = 'ADD_LEAD'
export const ADD_LEAD_LOADER = 'ADD_LEAD_LOADER'
export const ADD_MEETING = 'ADD_MEETING'
export const ADD_MEETING_LOADER = 'ADD_MEETING_LOADER'
export const SHARE_LEAD = 'SHARE_LEAD'
export const SHARE_LEAD_LOADER = 'SHARE_LEAD_LOADER'
export const ASSIGN_LEAD = 'ASSIGN_LEAD'
export const ASSIGN_LEAD_LOADER = 'ASSIGN_LEAD_LOADER'
export const ADD_TASK = 'ADD_TASK'
export const ADD_TASK_LOADER = 'ADD_TASK_LOADER'
export const LEAD_EDIT_TOGGLE = 'LEAD_EDIT_TOGGLE'
export const EDIT_DIARY = 'EDIT_DIARY'
export const TOGGLE_LEAD_TAB = 'TOGGLE_LEAD_TAB'
export const FETCH_PUBLISHED_PRODUCTS = 'FETCH_PUBLISHED_PRODUCTS'
export const FETCH_ALL_ATTACHMENT_LOADER = 'FETCH_ALL_ATTACHMENT_LOADER'
export const FETCH_ALL_ATTACHMENTS = 'FETCH_ALL_ATTACHMENTS'
export const FETCH_LEAD_TASKS = 'FETCH_LEAD_TASKS'
export const FETCH_FOLLOWUP_LEAD_TASKS = 'FETCH_FOLLOWUP_LEAD_TASKS'
export const ACTIVE_INVESTMENT_LEAD_TAB = 'ACTIVE_INVESTMENT_LEAD_TAB'
export const FETCH_LEAD_ATTACHMENTS = 'FETCH_LEAD_ATTACHMENTS'
export const FETCH_ATTACHMENTS_LOADER = 'FETCH_ATTACHMENTS_LOADER'
export const MAKE_CALL_SUCCESS = 'MAKE_CALL_SUCCESS'
export const DELETE_LEAD_ATTACHMENT = 'DELETE_LEAD_ATTACHMENT'
export const FETCH_BUY_RENT_SHORTLISTED_PROPERTIES = 'FETCH_BUY_RENT_SHORTLISTED_PROPERTIES'
export const EXCLUDE_LEAD = 'EXCLUDE_LEAD'
export const BUY_RENT_QUICK_FILTER = 'BUY_RENT_QUICK_FILTER'
export const PROJECT_LEAD_QUICK_FILTER = 'PROJECT_LEAD_QUICK_FILTER'
export const IS_CLIENT_FILTER_LOADING = 'IS_CLIENT_FILTER_LOADING'
export const FETCH_RENTAL_VIEW = 'FETCH_RENTAL_VIEW'
export const FETCH_LEAD_BOOKING = 'FETCH_LEAD_BOOKING'
export const FETCH_ADD_BOOKING = 'FETCH_ADD_BOOKING'
export const FETCH_RENTAL_VIEW_LOADER = 'FETCH_RENTAL_VIEW_LOADER'
export const FETCH_RENTAL_VIEW_SUCCESS = 'FETCH_RENTAL_VIEW_SUCCESS'
export const FETCH_LEAD_BOOKING_SUCCESS = 'FETCH_LEAD_BOOKING_SUCCESS'
export const FETCH_ADD_BOOKING_SUCCESS = 'FETCH_ADD_BOOKING_SUCCESS'
export const FETCH_RENTAL_VIEW_FAILURE = 'FETCH_RENTAL_VIEW_FAILURE'
export const FETCH_BOOKING_FAILURE = 'FETCH_BOOKING_FAILURE'
export const FETCH_ADD_BOOKING_FAILURE = 'FETCH_ADD_BOOKING_FAILURE'
export const LAST_ADDED_LEAD = 'LAST_ADDED_LEAD'
export const FETCH_LEAD_ORIGINS = 'FETCH_LEAD_ORIGINS'
export const AUTO_LEAD_ASSIGNMENT = 'AUTO_LEAD_ASSIGNMENT'
export const AUTO_LEAD_ASSIGNMENT_LOADER = 'AUTO_LEAD_ASSIGNMENT_LOADER'
export const AUTO_LEAD_ASSIGNMENT_RESET = 'AUTO_LEAD_ASSIGNMENT_RESET'
export const SELECT_CUSTOMER_DETAIL = 'SELECT_CUSTOMER_DETAIL'
export const FETCH_ALL_PREVIOUS_ADVISORS = 'FETCH_ALL_PREVIOUS_ADVISORS'
export const FETCH_ALL_ORIGINS = 'FETCH_ALL_ORIGINS'

export const deleteLeadAttachment = (id) => {
  return (dispatch) => {
    axios.delete(`${config.apiPath}/api/leads/comments/remove?id=${id}`).then((res) => {
      dispatch({ type: DELETE_LEAD_ATTACHMENT, data: res.data })
    })
  }
}

export const selectCustomerDetail = (data) => {
  return (dispatch) => {
    dispatch({ type: SELECT_CUSTOMER_DETAIL, data })
  }
}

export const setBuyRentQuickFilters = (filters) => {
  return (dispatch) => {
    dispatch({ type: BUY_RENT_QUICK_FILTER, data: filters })
  }
}

export const setProjectLeadQuickFilters = (filters) => {
  return (dispatch) => {
    dispatch({ type: PROJECT_LEAD_QUICK_FILTER, data: filters })
  }
}

export const fetchLeadAttachments = (id) => {
  return (dispatch) => {
    dispatch({ type: FETCH_ATTACHMENTS_LOADER, data: true })
    axios
      .get(`${config.apiPath}/api/leads/comments?cmLeadId=${id}&type=attachment&web=true&all=true`)
      .then((res) => {
        dispatch({ type: FETCH_ATTACHMENTS_LOADER, data: false })
        dispatch({ type: FETCH_LEAD_ATTACHMENTS, data: res.data })
        scrollToBottom('attachment-scroll-container')
      })
  }
}

export const fetchAllLeadAttachments = (id, leadType) => {
  return (dispatch) => {
    dispatch({ type: FETCH_ATTACHMENTS_LOADER, data: true })
    dispatch({ type: FETCH_LEAD_ATTACHMENTS, data: [] })
    axios
      .get(`${config.apiPath}/api/leads/attachments?leadId=${id}&leadType=${leadType}`)
      .then((res) => {
        let reformatedAttachments = []
        Object.keys(res.data).map((key) => res.data[key].map((a) => reformatedAttachments.push(a)))
        dispatch({ type: FETCH_ATTACHMENTS_LOADER, data: false })
        dispatch({ type: FETCH_LEAD_ATTACHMENTS, data: reformatedAttachments })
        scrollToBottom('attachment-scroll-container')
      })
  }
}

export const fetchAllPreviousAdvisors = () => {
  return (dispatch) => {
    axios.get(`${config.apiPath}/api/v1/leads/previousAdvisor`).then((res) => {
      dispatch({ type: FETCH_ALL_PREVIOUS_ADVISORS, data: res.data })
    })
  }
}

export const fetchPublishProducts = (id, unitType) => {
  return (dispatch) => {
    if (id) {
      axios
        .get(`${config.apiPath}/api/project/products?projectId=${id}&unitType=${unitType}`)
        .then((res) => {
          return dispatch({ type: FETCH_PUBLISHED_PRODUCTS, data: res.data })
        })
    }
  }
}

export const fetchAllOrigins = (type) => {
  return (dispatch) => {
    axios
      .get(
        `${config.apiPath}/api/leads/project/origins?rcmLead=${
          type === 'project' ? 'ArmsProjectLeads' : 'ArmsLeads'
        }`
      )
      .then((res) => {
        return dispatch({ type: FETCH_ALL_ORIGINS, data: res.data })
      })
  }
}

export const resetAutoLeadAssignment = () => {
  return (dispatch) => {
    return dispatch({ type: AUTO_LEAD_ASSIGNMENT, data: '' })
  }
}

export const leadAutoReAssignment = (payload) => {
  return (dispatch) => {
    dispatch({ type: AUTO_LEAD_ASSIGNMENT_LOADER, data: true })
    axios.patch(`${config.apiPath}/api/leads/autoReAssign`, payload).then((res) => {
      showToastMsg(res.data.message, 'success')
      dispatch({ type: AUTO_LEAD_ASSIGNMENT, data: res.data })
      return dispatch({ type: AUTO_LEAD_ASSIGNMENT_LOADER, data: false })
    })
  }
}

export const leadTabToggle = (data) => {
  return (dispatch) => {
    return dispatch({ type: TOGGLE_LEAD_TAB, data })
  }
}

export const editDiary = (data, fetchTimeLine, id, leadId) => {
  return (dispatch) => {
    axios.patch(`${config.apiPath}/api/diary/update?id=${id}`, data).then((res) => {
      fetchTimeLine(leadId)
      return dispatch({ type: EDIT_DIARY, data: res.data })
    })
  }
}

export const leadEditToggle = (data) => {
  return (dispatch) => {
    return dispatch({ type: LEAD_EDIT_TOGGLE, data })
  }
}

export const investmentShareLead = (
  data,
  fetchTimeLine,
  leadId,
  type,
  showLeadReferSuccessMsgHandler
) => {
  let url = `${config.apiPath}/api/leads/project/shareLead`
  if (type === 'buy-rent') url = `${config.apiPath}/api/leads/shareLead`

  return (dispatch) => {
    dispatch({ type: SHARE_LEAD_LOADER, data: true })
    axios
      .post(url, data)
      .then((res) => {
        showLeadReferSuccessMsgHandler()
        dispatch({ type: SHARE_LEAD_LOADER, data: false })
        if (fetchTimeLine !== null) {
          fetchTimeLine(leadId)
        }
        return dispatch({ type: SHARE_LEAD, data: res.data })
      })
      .catch((error) => {})
  }
}

export const investmentAssignLead = (data, fetchTimeLine, leadId) => {
  return (dispatch) => {
    dispatch({ type: ASSIGN_LEAD_LOADER, data: true })
    axios.patch(`${config.apiPath}/api/leads/assign`, data).then((res) => {
      dispatch({ type: ASSIGN_LEAD_LOADER, data: false })
      fetchTimeLine(leadId)
      return dispatch({ type: ASSIGN_LEAD, data: res.data })
    })
  }
}

export const investmentCall = (data, fetchTimeLine, type = 'project') => {
  return (dispatch) => {
    dispatch({ type: INVESTMENT_CALL_LOADER, data: true })
    axios.post(`${config.apiPath}/api/leads/project/meeting`, data).then((res) => {
      dispatch({ type: INVESTMENT_CALL_LOADER, data: false })
      if (type === 'project') fetchTimeLine(res.data.armsProjectLeadId)
      else fetchTimeLine(res.data.armsLeadId, type)
      return dispatch({ type: INVESTMENT_CALL, data: res.data })
    })
  }
}

export const investmentTask = (data, fetchTimeLine, leadId) => {
  return (dispatch) => {
    dispatch({ type: ADD_TASK_LOADER, data: true })
    axios
      .post(`${config.apiPath}/api/leads/task`, data)
      .then((res) => {
        dispatch({ type: ADD_TASK_LOADER, data: false })
        fetchTimeLine(leadId, res.data)
        return dispatch({ type: ADD_TASK, data: res.data })
      })
      .catch((e) => {
        showToastMsg('Error encountered while creating Task', 'error')
      })
  }
}

export const investmentMeeting = (data, fetchTimeLine, type = 'project') => {
  return (dispatch) => {
    dispatch({ type: ADD_MEETING_LOADER, data: true })
    axios.post(`${config.apiPath}/api/leads/project/meeting`, data).then((res) => {
      dispatch({ type: ADD_MEETING_LOADER, data: false })
      if (fetchTimeLine !== undefined) {
        if (type === 'project') fetchTimeLine(res.data.armsProjectLeadId, res.data)
        else fetchTimeLine(res.data.armsLeadId, type)
      }

      return dispatch({ type: INVESTMENT_CALL, data: res.data })
    })
  }
}

export const fetchSinglePayment = (id) => {
  return (dispatch) => {
    axios.get(`${config.apiPath}/api/leads/project/payment?id=${id}`).then((res) => {
      return dispatch({ type: FETCH_SINGLE_PAYMENT, data: res.data })
    })
  }
}

export const toggleEdit = (con, type) => {
  let data = { con, type }
  return { type: TOGGLE_EDIT, data }
}

export const addLead = (data, isClientPage, cb = null ) => {
  return (dispatch) => {
    dispatch({ type: ADD_LEAD_LOADER, data: true })
    axios.post(`${config.apiPath}/api/leads/project`, data).then((res) => {
      dispatch({ type: LAST_ADDED_LEAD, data: res.data })
      if (res.data.message === 'Lead cannot be created as same lead already exists:') {
        showToastMsg(res.data.message, 'error')
      } else {
        if (history.location.search === '?source=availableInventory') {
          showToastMsg('Lead has been created successfully', 'success')
          setTimeout(() => {
            window.location.href = `/project-lead-detail/${res.data.leadId}`
          }, 1000)
          return
        }
        if (!isClientPage) {
          showToastMsg('Lead has been created successfully', 'success')
          cb && cb(res.data)
          setTimeout(() => {
            //window.location.href = `/project-lead?page=1&pageSize=${config.pageSize}`
          }, 1000)
        }
      }
      setTimeout(() => {
        dispatch({ type: ADD_LEAD_LOADER, data: false })
      }, 5000)
      return dispatch({ type: ADD_LEAD, data: res.data.message })
    })
  }
}

export const addBuyRentLead = (data, isClientPage, cb = null) => {
  return (dispatch) => {
    dispatch({ type: ADD_LEAD_LOADER, data: true })
    axios.post(`${config.apiPath}/api/leads`, data).then((res) => {
      dispatch({ type: LAST_ADDED_LEAD, data: res.data.createdLeadData })
      if (!isClientPage) {
        showToastMsg('Lead has been created successfully', 'success')
        setTimeout(() => {
          //window.location.href = `/buy-rent-lead?page=1&pageSize=${config.pageSize}`
          cb && cb(res.data)
        }, 1000)
      }
      setTimeout(() => {
        dispatch({ type: ADD_LEAD_LOADER, data: false })
      }, 5000)
      return dispatch({ type: ADD_LEAD, data: res.data })
    })
  }
}

export const fetchUserClients = (advisorId = null) => {
  const advisor = !!advisorId ? `&advisorId=${advisorId}` : ''
  return (dispatch) => {
    axios.get(`${config.apiPath}/api/customer/find?all=true${advisor}`).then((res) => {
      return dispatch({ type: FETCH_USER_CLIENTS, data: res.data })
    })
  }
}

export const fetchClientsBank = (id) => {
  return (dispatch) => {
    dispatch({ type: ADD_BANK_LOADER, data: true })
    axios
      .get(`${config.apiPath}/api/booking/findCustomerBankDetailsbyId?customerId=${id}`)
      .then((res) => {
        dispatch({ type: ADD_BANK_LOADER, data: false })
        return dispatch({ type: FETCH_CLIENTS_BANK, data: res.data })
      })
  }
}

export const fetchNextOfKin = (id) => {
  return (dispatch) => {
    dispatch({ type: ADD_NEXTOFKIN_LOADER, data: true })
    axios.get(`${config.apiPath}/api/booking/getKinDetails?customerId=${id}`).then((res) => {
      dispatch({ type: ADD_NEXTOFKIN_LOADER, data: false })
      return dispatch({ type: FETCH_Next_Kin, data: res.data })
    })
  }
}

// reusability
export const addDetail = (data, id) => {
  return (dispatch) => {
    dispatch({ type: ADD_DETAIL_LOADER, data: true })
    axios.patch(`${config.apiPath}/api/leads/project?id[]=${id}`, data).then((res) => {
      dispatch({ type: ADD_DETAIL_LOADER, data: false })
      return dispatch({ type: ADD_DETAIL, data: res.data })
    })
  }
}

export const addPayment = (data, selectImages, tokenToggle, fetchLead) => {
  return (dispatch) => {
    dispatch({ type: ADD_PAYMENT_LOADER, data: true })
    axios.post(`${config.apiPath}/api/leads/project/payments`, data).then((res) => {
      selectImages &&
        selectImages.map((item) => {
          let formData = new FormData()
          formData.append('file', item)
          axios
            .post(`${config.apiPath}/api/leads/paymentAttachment?id=${res.data.id}`, formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            })
            .then((response) => {
              const lastItem = selectImages[selectImages.length - 1]
              if (item === lastItem) {
                tokenToggle(false)
                fetchLead(res.data.cmLeadId)
                dispatch({ type: ADD_PAYMENT_LOADER, data: false })
              }
            })
        })
      if (selectImages && selectImages.length === 0) {
        tokenToggle(false)
        fetchLead(res.data.cmLeadId)
        dispatch({ type: ADD_PAYMENT_LOADER, data: false })
      }

      return dispatch({ type: ADD_PAYMENT, data: res.data })
    })
  }
}

export const editPayment = (data, id, selectImages, tokenToggle, fetchLead) => {
  return (dispatch) => {
    dispatch({ type: ADD_PAYMENT_LOADER, data: true })
    axios.patch(`${config.apiPath}/api/leads/project/payment?id=${id}`, data).then((res) => {
      selectImages &&
        selectImages.map((item) => {
          let formData = new FormData()
          formData.append('file', item)
          axios
            .post(`${config.apiPath}/api/leads/paymentAttachment?id=${id}`, formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            })
            .then((response) => {
              const lastItem = selectImages[selectImages.length - 1]
              if (item === lastItem) {
                tokenToggle(false)
                fetchLead(res.data.payment.cmLeadId)
                dispatch({ type: ADD_PAYMENT_LOADER, data: false })
              }
            })
        })
      if (selectImages && selectImages.length === 0) {
        dispatch({ type: ADD_PAYMENT_LOADER, data: false })
        tokenToggle(false)
        fetchLead(res.data.payment.cmLeadId)
      }

      return dispatch({ type: EDIT_SINGLE_PAYMENT, data: res.data })
    })
  }
}

export const addTax = (data, selectImages, tokenToggle, fetchLead) => {
  return (dispatch) => {
    dispatch({ type: ADD_TAX_LOADER, data: true })
    axios.post(`${config.apiPath}/api/leads/project/payments`, data).then((res) => {
      selectImages &&
        selectImages.map((item) => {
          let formData = new FormData()
          formData.append('file', item)
          axios
            .post(`${config.apiPath}/api/leads/paymentAttachment?id=${res.data.id}`, formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            })
            .then((response) => {
              const lastItem = selectImages[selectImages.length - 1]
              if (item === lastItem) {
                tokenToggle(false)
                fetchLead(res.data.cmLeadId)
                dispatch({ type: ADD_TAX_LOADER, data: false })
              }
            })
        })
      if (selectImages && selectImages.length === 0) {
        tokenToggle(false)
        fetchLead(res.data.cmLeadId)
        dispatch({ type: ADD_TAX_LOADER, data: false })
      }

      dispatch({ type: ADD_TAX_LOADER, data: false })
      return dispatch({ type: ADD_TAX, data: res.data })
    })
  }
}

export const addToken = (data, selectImages, tokenToggle, fetchLead) => {
  return (dispatch) => {
    dispatch({ type: ADD_TOKEN_LOADER, data: true })
    axios.post(`${config.apiPath}/api/leads/project/payments`, data).then((res) => {
      selectImages &&
        selectImages.map((item) => {
          let formData = new FormData()
          formData.append('file', item)
          axios
            .post(`${config.apiPath}/api/leads/paymentAttachment?id=${res.data.id}`, formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            })
            .then((response) => {
              const lastItem = selectImages[selectImages.length - 1]
              if (item === lastItem) {
                tokenToggle(false)
                fetchLead(res.data.cmLeadId)
                dispatch({ type: ADD_TOKEN_LOADER, data: false })
              }
            })
        })
      if (selectImages && selectImages.length === 0) {
        dispatch({ type: ADD_TOKEN_LOADER, data: false })
      }

      return dispatch({ type: ADD_TOKEN, data: res.data })
    })
  }
}

export const fetchLeads = (
  req,
  sortingOrder,
  callback = () => {},
  hasBooking = false,
  onlyOpenStatus = false,
  clientLead = false,
  tabUrl,
  client,
  query = '',
  fromPage = ''
) => {
  const urlParams = new URLSearchParams(window.location.search)
  let sortingOrderApi = sortingOrder ? sortingOrder : ''
  let page = urlParams.get('page') || 1
  let hasBookingParam = ''
  if (hasBooking != null || hasBooking != undefined) {
    hasBookingParam = `&hasBooking=${hasBooking}`
  }
  if (fromPage === 'createTask' && req.offset) page = req.offset
  var endpoint = `${config.apiPath}/api/v1/leads/projects?${hasBookingParam}&pageSize=${config.pageSize}&page=${page}${sortingOrderApi}`
  if (req && req.offset && req.pageSize) {
    endpoint = `${config.apiPath}/api/v1/leads/projects?${hasBookingParam}&pageSize=${config.pageSize}&page=${page}${sortingOrderApi}`
  } else if (req && req.all === true) {
    endpoint = `${config.apiPath}/api/v1/leads/projects?web=true${hasBookingParam}`
  }
  if (req && req.clientId) endpoint += `&customerId=${req.clientId}`
  if (query) {
    endpoint += query
  }
  if (hasBooking === null && req && req.clientId) endpoint += `&customerLeads=true`

  return (dispatch, getState) => {
    let showAllLeads = shouldShowAllLeads(getState().user)
    if (showAllLeads) {
      endpoint += `&showAllLeads=${showAllLeads}`
    }

    dispatch({
      type: hasBooking === null && fromPage != 'createTask' ? CLIENT_LEAD_LOADING : LEAD_LOADING,
    })
    dispatch({
      type: hasBooking === null && fromPage != 'createTask' ? FETCH_CLIENT_LEAD : FETCH_LEAD,
      data: null,
    })
    axios
      .get(endpoint)
      .then(function (response) {
        if (clientLead && tabUrl === 'project' && response.data.rows.length === 0) {
          history.push({ pathname: '/new-lead', state: { customer: client } })
        }

        if (onlyOpenStatus)
          response.data.rows = response.data.rows.filter((l) => l.status === 'open')
        callback(response.data)
        dispatch({
          type:
            hasBooking === null && fromPage != 'createTask'
              ? CLIENT_LEAD_LOADING_SUCCESS
              : LEAD_LOADING_SUCCESS,
        })
        const excludeLead = getState().Leads.excludeLead
        return dispatch({
          type: hasBooking === null && fromPage != 'createTask' ? FETCH_CLIENT_LEAD : FETCH_LEAD,
          data:
            hasBooking === null && excludeLead && excludeLead.type === 'Project'
              ? {
                  rows: response.data.rows.filter((dt) => dt.id !== excludeLead.lead.id),
                  count: response.data.count,
                }
              : response.data,
        })
      })
      .catch(function (xhr, status, err) {
        return dispatch({
          type:
            hasBooking === null && fromPage != 'createTask'
              ? FETCH_CLIENT_LEAD_FAILURE
              : FETCH_LEAD_FAILURE,
          data: xhr,
        })
      })
  }
}

export const fetchFreshLeads = (url, callback = () => {}) => {
  return (dispatch) => {
    axios.get(url).then((res) => {
      callback(res.data)
      return dispatch({ type: FETCH_FRESH_LEADS, data: res.data })
    })
  }
}
export const fetchFreshBuyRentLeads = (url, callback = () => {}) => {
  return (dispatch) => {
    axios.get(url).then((res) => {
      callback(res.data)
      return dispatch({ type: FETCH_FRESH_BR_LEADS, data: res.data })
    })
  }
}

export const fetchAccountView = (req) => {
  const role = localStorage.getItem('role')
  const urlParams = new URLSearchParams(window.location.search)
  const page = urlParams.get('page')
  var endpoint = `${config.apiPath}/api/v1/leads/projects?sales=true${
    accountsPermissions('accounts') && !accountsPermissions('accountsHq')
      ? '&saleType=pendingAccount'
      : accountsPermissions('accountsHq')
      ? '&saleType=pendingAccountHq'
      : ''
  }&web=true&pageSize=${config.pageSize}&page=${page}`
  if (req && req.offset && req.pageSize) {
    endpoint = `${config.apiPath}/api/v1/leads/projects?sales=true${
      accountsPermissions('accounts') && !accountsPermissions('accountsHq')
        ? '&saleType=pendingAccount'
        : accountsPermissions('accountsHq')
        ? '&saleType=pendingAccountHq'
        : ''
    }&web=true&pageSize=${config.pageSize}&page=${page}`
  } else if (req && req.all === true) {
    endpoint = `${config.apiPath}/api/v1/leads/projects?sales=true${
      accountsPermissions('accounts') && !accountsPermissions('accountsHq')
        ? '&saleType=pendingAccount'
        : accountsPermissions('accountsHq')
        ? '&saleType=pendingAccountHq'
        : ''
    }&web=true&`
  }
  return (dispatch) => {
    dispatch({ type: FETCH_ACCOUNT_VIEW_LOADER, data: true })
    axios
      .get(endpoint)
      .then(function (response) {
        dispatch({ type: FETCH_ACCOUNT_VIEW_SUCCESS, data: false })

        return dispatch({ type: FETCH_ACCOUNT_VIEW, data: response.data })
      })
      .catch(function (xhr, status, err) {
        return dispatch({ type: FETCH_ACCOUNT_VIEW_FAILURE, data: xhr })
      })
  }
}
export const fetchAccountViewRe = (req) => {
  const role = localStorage.getItem('role')
  const urlParams = new URLSearchParams(window.location.search)
  const page = urlParams.get('page')
  var endpoint = `${config.apiPath}/api/leads/project/allpayments?sales=true${
    accountsPermissions('accounts') && !accountsPermissions('accountsHq')
      ? '&status=pendingAccount'
      : accountsPermissions('accountsHq')
      ? '&status=pendingAccountHq'
      : ''
  }&web=true&pageSize=${config.pageSize}&page=${page}`
  if (req && req.offset && req.pageSize) {
    endpoint = `${config.apiPath}/api/leads/project/allpayments?sales=true${
      accountsPermissions('accounts') && !accountsPermissions('accountsHq')
        ? '&status=pendingAccount'
        : accountsPermissions('accountsHq')
        ? '&status=pendingAccountHq'
        : ''
    }&web=true&pageSize=${config.pageSize}&page=${page}`
  } else if (req && req.all === true) {
    endpoint = `${config.apiPath}/api/leads/project/allpayments?sales=true${
      accountsPermissions('accounts') && !accountsPermissions('accountsHq')
        ? '&status=pendingAccount'
        : accountsPermissions('accountsHq')
        ? '&status=pendingAccountHq'
        : ''
    }&web=true&`
  }
  return (dispatch) => {
    dispatch({ type: FETCH_ACCOUNT_VIEW_LOADER, data: true })
    axios
      .get(endpoint)
      .then(function (response) {
        dispatch({ type: FETCH_ACCOUNT_VIEW_SUCCESS, data: false })

        return dispatch({ type: FETCH_ACCOUNT_VIEW, data: response.data })
      })
      .catch(function (xhr, status, err) {
        return dispatch({ type: FETCH_ACCOUNT_VIEW_FAILURE, data: xhr })
      })
  }
}

export const fetchLeadBooking = (req) => {
  const urlParams = new URLSearchParams(window.location.search)
  const page = urlParams.get('page')
  var endpoint
  if (req && req.offset && req.pageSize) {
    endpoint = `${config.apiPath}/api/bookingDashboard/getBookingDashboardListing?pageSize=${config.pageSize}&page=${page}`
  } else if (req && req.filterCheck === true && req.url !== null) {
    endpoint = req.url
  } else if (req && req.url && req.url.check === true) {
    endpoint = req.url.url + '&pageSize=' + config.pageSize + '&page=' + page
  } else {
    endpoint = `${config.apiPath}/api/bookingDashboard/getBookingDashboardListing?page=1&pageSize=${config.pageSize}`
  }
  return (dispatch) => {
    dispatch({ type: FETCH_LEAD_BOOKING_SUCCESS, data: true })
    axios
      .get(endpoint)
      .then(function (response) {
        dispatch({ type: FETCH_LEAD_BOOKING_SUCCESS, data: false })

        return dispatch({ type: FETCH_LEAD_BOOKING, data: response.data })
      })
      .catch(function (xhr, status, err) {
        return dispatch({ type: FETCH_BOOKING_FAILURE, data: xhr })
      })
  }
}

export const fetchAddBooking = (req) => {
  const urlParams = new URLSearchParams(window.location.search)
  const page = urlParams.get('page')
  var endpoint

  if (req && req.pagination === true && req.data !== null) {
    endpoint = `${config.apiPath}/api/v1/leads/projects?pageType=accountBooking` + req.data
  } else if (req && req.filterCheck === true && req.url !== null) {
    endpoint = req.url
  } else if (req && req.filterPagination === true && req.url !== null) {
    endpoint = req.url
  } else {
    endpoint = `${config.apiPath}/api/v1/leads/projects?pageType=accountBooking&hasBooking=false&page=1&pageSize=${config.pageSize}`
  }
  return (dispatch) => {
    dispatch({ type: FETCH_ADD_BOOKING_SUCCESS, data: true })
    axios
      .get(endpoint)
      .then(function (response) {
        dispatch({ type: FETCH_ADD_BOOKING_SUCCESS, data: false })

        return dispatch({ type: FETCH_ADD_BOOKING, data: response.data })
      })
      .catch(function (xhr, status, err) {
        return dispatch({ type: FETCH_ADD_BOOKING_FAILURE, data: xhr })
      })
  }
}

export const fetchRentalView = (req) => {
  const role = localStorage.getItem('role')
  const urlParams = new URLSearchParams(window.location.search)
  const page = urlParams.get('page')
  var endpoint = `${config.apiPath}/api/leads/project/clearedPayment?sales=true&web=true&pageSize=${config.pageSize}&page=${page}`
  if (req && req.offset && req.pageSize) {
    endpoint = `${config.apiPath}/api/leads/project/clearedPayment?sales=true&web=true&pageSize=${config.pageSize}&page=${page}`
  } else if (req && req.all === true) {
    endpoint = `${config.apiPath}/api/leads/project/clearedPayment?sales=true&web=true&`
  }
  return (dispatch) => {
    dispatch({ type: FETCH_RENTAL_VIEW_LOADER, data: true })
    axios
      .get(endpoint)
      .then(function (response) {
        dispatch({ type: FETCH_RENTAL_VIEW_SUCCESS, data: false })

        return dispatch({ type: FETCH_RENTAL_VIEW, data: response.data })
      })
      .catch(function (xhr, status, err) {
        return dispatch({ type: FETCH_RENTAL_VIEW_FAILURE, data: xhr })
      })
  }
}

export const fetchRentalViewFilter = (url) => {
  const urlParams = new URLSearchParams(window.location.search)
  const page = urlParams.get('page')
  return (dispatch) => {
    dispatch({ type: FETCH_RENTAL_VIEW_LOADER, data: true })
    axios
      .get(`${url}&page=${page}&pageSize=${config.pageSize}`)
      .then(function (response) {
        dispatch({ type: FETCH_RENTAL_VIEW_SUCCESS, data: false })
        return dispatch({ type: FETCH_RENTAL_VIEW, data: response.data })
      })
      .catch(function (xhr, status, err) {
        return dispatch({ type: FETCH_RENTAL_VIEW_FAILURE, data: xhr })
      })
  }
}

export const fetchBuyRentSalesView = (req) => {
  const role = localStorage.getItem('role')
  const urlParams = new URLSearchParams(window.location.search)
  const page = urlParams.get('page')
  var endpoint = `${config.apiPath}/api/leads/rcmSales?sales=true${
    accountsPermissions('accounts') && !accountsPermissions('accountsHq')
      ? '&saleType=pendingAccount'
      : accountsPermissions('accountsHq')
      ? '&saleType=pendingAccountHq'
      : ''
  }&web=true&pageSize=${config.pageSize}&page=${page}`
  if (req && req.offset && req.pageSize) {
    endpoint = `${config.apiPath}/api/leads/rcmSales?sales=true${
      accountsPermissions('accounts') && !accountsPermissions('accountsHq')
        ? '&saleType=pendingAccount'
        : accountsPermissions('accountsHq')
        ? '&saleType=pendingAccountHq'
        : ''
    }&web=true&pageSize=${config.pageSize}&page=${page}`
  } else if (req && req.all === true) {
    endpoint = `${config.apiPath}/api/leads/rcmSales?sales=true${
      accountsPermissions('accounts') && !accountsPermissions('accountsHq')
        ? '&saleType=pendingAccount'
        : accountsPermissions('accountsHq')
        ? '&saleType=pendingAccountHq'
        : ''
    }&web=true`
  }
  return (dispatch) => {
    dispatch({ type: FETCH_BUY_RENT_SALES_VIEW_LOADER, data: true })
    axios
      .get(endpoint)
      .then(function (response) {
        dispatch({ type: FETCH_BUY_RENT_SALES_VIEW_SUCCESS, data: false })

        return dispatch({ type: FETCH_BUY_RENT_SALES_VIEW, data: response.data })
      })
      .catch(function (xhr, status, err) {
        return dispatch({ type: FETCH_BUY_RENT_SALES_VIEW_FAILURE, data: xhr })
      })
  }
}
export const fetchPaymentDetail = (id) => {
  return (dispatch) => {
    dispatch({ type: FETCH_PAYMENT_DETAIL_LOADER, data: true })
    axios
      .get(`${config.apiPath}/api/leads/projectPayments?leadId=${id}`)
      .then(function (response) {
        dispatch({ type: FETCH_PAYMENT_DETAIL_SUCCESS, data: false })
        return dispatch({ type: FETCH_PAYMENT_DETAIL, data: response.data })
      })
      .catch(function (xhr, status, err) {
        return dispatch({ type: FETCH_PAYMENT_DETAIL_FAILURE, data: xhr })
      })
  }
}

export const fetchSingleLead = (
  id,
  by_project = true,
  callback = null,
  check_MyDeal_Or_MyLeads
) => {

  {console.log("@@@",check_MyDeal_Or_MyLeads)}

  // id for null testing 432100
  let url = `${config.apiPath}/api/leads/project/byid?id=${id}`
  if (!by_project) url = `${config.apiPath}/api/leads/byid?id=${id}`
  const rebondData = (response) => {
    //copydata.projectProduct.name
    //ARMS- 3645
    let copydata = { ...response.data }
    let bookingFormsData = response.data.bookingForms[0]
    let { unit } = copydata

    if (unit === null) {
      let unit = {
        finalPrice: bookingFormsData.finalPrice,
        discount_amount: bookingFormsData.discountedAmount,
        discounted_price: bookingFormsData.discountedPrice,
        category_charges:
          bookingFormsData.unitCategoryCharges === null ? 0 : bookingFormsData.unitCategoryCharges,
        pricePerSqFt: bookingFormsData.unitpricePerSqFt,
        area: bookingFormsData.unitArea,
        rentPerSqFt: bookingFormsData.unitRentPerSqFt,
        rate_per_sqft: bookingFormsData.unitRatePerSqrFt,
        unit_price: bookingFormsData.price,
        bookingStatus: bookingFormsData.unitStatus,
        finalPrice: bookingFormsData.finalPrice,
        discount: bookingFormsData.productDiscountPercentage,
      }

      copydata.paymentPlan = bookingFormsData.paymentPlan
      copydata.installmentFrequency = bookingFormsData.productInstallmentFrequency // wrong foramt:  bookingFormsData.productInstallmentFrequency
      copydata.paymentPlanDuration = bookingFormsData.paymentPlanDuration
      copydata.downPayment = bookingFormsData.downPayment

      copydata = { ...copydata, unit }
    } else {
      copydata.unit.finalPrice = bookingFormsData.finalPrice

      unit['discount_amount'] = bookingFormsData.discountedAmount
      unit['discounted_price'] = bookingFormsData.discountedPrice

      copydata.unit.category_charges =
        bookingFormsData.unitCategoryCharges === null ? 0 : bookingFormsData.unitCategoryCharges
      copydata.unit.pricePerSqFt = bookingFormsData.unitpricePerSqFt
      copydata.unit.area = bookingFormsData.unitArea
      copydata.unit.rentPerSqFt = bookingFormsData.unitRentPerSqFt
      copydata.unit.rate_per_sqft = bookingFormsData.unitRatePerSqrFt
      copydata.unit.unit_price = bookingFormsData.price
      copydata.unit.bookingStatus = bookingFormsData.unitStatus
      copydata.unit.finalPrice = bookingFormsData.finalPrice
      copydata.unit.discount = bookingFormsData.productDiscountPercentage

      copydata.paymentPlan = bookingFormsData.paymentPlan
      copydata.installmentFrequency = bookingFormsData.productInstallmentFrequency // wrong foramt:  bookingFormsData.productInstallmentFrequency
      copydata.paymentPlanDuration = bookingFormsData.paymentPlanDuration
      copydata.downPayment = bookingFormsData.downPayment
    }

    //***  investment plain  **//
    if (copydata.projectProduct) {
      let invstmntPln = copydata.projectProduct

      invstmntPln.name = bookingFormsData.productName

      invstmntPln.validFrom = invstmntPln.validFrom //A
      invstmntPln.validTo = invstmntPln.validTo //A
      invstmntPln.description = invstmntPln.description //A
      invstmntPln.reservationAmount = invstmntPln.reservationAmount //A   both use for  Reservation Amount
      invstmntPln.value = invstmntPln.value //A   both use for  Reservation Amount
      invstmntPln.downPayment = bookingFormsData.downPaymentPercentage
      invstmntPln.possessionCharges = parseFloat(bookingFormsData.possessionChargesPercentage)
      invstmntPln.annualProfit = bookingFormsData.productAnnualProfit
      invstmntPln.monthlyRent = bookingFormsData.productMonthlyRent
      invstmntPln.investmentDuration = bookingFormsData.productInvestmentDuration
      copydata.projectProduct = invstmntPln
    }

    copydata.paymentPlan = bookingFormsData.paymentPlan
    // copydata.installmentFrequency = copydata.installmentFrequency// wrong foramt:  bookingFormsData.productInstallmentFrequency
    copydata.installmentFrequency = bookingFormsData.productInstallmentFrequency // wrong foramt:  bookingFormsData.productInstallmentFrequency
    copydata.paymentPlanDuration = bookingFormsData.paymentPlanDuration

    //copydata.unit.discount = discountPercentage ? discountPercentage : bookingFormsData.productDiscountPercentage
    copydata.unit.discount = bookingFormsData.productDiscountPercentage

    copydata.downPayment = bookingFormsData.downPayment

    return copydata
  }

  return (dispatch) => {
    const urlParams = new URLSearchParams(window.location.search)
    dispatch({ type: FETCH_SINGLE_INVESTMENT_LOADER, data: true })
    axios
      .get(url)
      .then(function (response) {
        
        dispatch({ type: FETCH_SINGLE_INVESTMENT_SUCCESS, data: false })
        if (callback) callback(response.data)
        return dispatch({
          type: FETCH_SINGLE_INVESTMENT_LEAD,
          data:
            check_MyDeal_Or_MyLeads && check_MyDeal_Or_MyLeads === true
              ? rebondData(response)
              : response.data,
        })
      })
      .catch(function (xhr, status, err) {
        return dispatch({ type: FETCH_SINGLE_INVESTMENT_FAILURE, data: xhr })
      })
  }
}

export const fetchFiltersData = (url, origin, callback = null, status) => {
  const urlParams = new URLSearchParams(window.location.search)
  const page = urlParams.get('page')
  const pageSize = urlParams.get('pageSize')
  const params = {
    status:
      status !== undefined && status.value === 'in-progress'
        ? ['follow_up', 'meeting']
        : status === undefined
        ? ''
        : status.value,
  }
  if (origin) params.origin = origin

  return (dispatch) => {
    dispatch({ type: LEAD_LOADING })
    axios
      .get(`${url}&web=true&page=${page}&pageSize=${pageSize}`, {
        params,
      })
      .then(function (response) {
        dispatch({ type: LEAD_LOADING_SUCCESS })
        if (callback) callback(response.data)
        return dispatch({ type: FETCH_LEAD, data: response.data })
      })
      .catch(function (xhr, status, err) {
        return dispatch({ type: FETCH_LEAD_FAILURE, data: xhr })
      })
  }
}

export const buyAndRentLeads = (
  req,
  callback = () => {},
  hasBooking = false,
  clientLeadModal,
  tabUrl,
  client,
  sorting = '',
  query = '',
  web = true
) => {
  const urlParams = new URLSearchParams(window.location.search)
  let page = urlParams.get('page') || req.offset || 1
  var endpoint = `${config.apiPath}/api/v1/leads?${
    isAira() ? 'aira=true' : web ? 'web=true' : ''
  }&hasBooking=${hasBooking}&pageSize=${config.pageSize}&page=${page}`

  if (req && req.offset && req.pageSize) {
    // page = urlParams.get('page') || req.offset
    endpoint = `${config.apiPath}/api/v1/leads?${
      isAira() ? 'aira=true' : web ? 'web=true' : ''
    }&hasBooking=${hasBooking}&pageSize=${config.pageSize}&page=${page}`
  } else if (req && req.all === true) {
    endpoint = `${config.apiPath}/api/v1/leads?${
      isAira() ? 'aira=true' : web ? 'web=true' : ''
    }&hasBooking=${hasBooking}`
  }

  if (req && req.clientId) endpoint += `&customerId=${req.clientId}`

  if (hasBooking === null && req && req.clientId) endpoint += `&customerLeads=true`

  if (query) {
    endpoint += query
  }
  endpoint += sorting || ''
  return (dispatch, getState) => {
    dispatch({ type: hasBooking === null ? FETCH_BR_CLIENT_LEAD_LOAD : FETCH_BR_LEAD_LOAD })
    dispatch({ type: hasBooking === null ? FETCH_BR_CLIENT_LEAD : FETCH_BR_LEAD, data: null })
    let showAllLeads = shouldShowAllLeads(getState().user)
    if (showAllLeads) {
      endpoint += `&showAllLeads=${showAllLeads}`
    }
    axios
      .get(endpoint)
      .then(function (response) {
        if (clientLeadModal && tabUrl === 'existinglead' && response.data.length === 0) {
          history.push({ pathname: '/add-buy-rent-lead', state: { customer: client } })
        }
        if (clientLeadModal && tabUrl === 'buyrent' && response.data.rows.length === 0) {
          history.push({ pathname: '/add-buy-rent-lead', state: { customer: client } })
        }

        dispatch({
          type: hasBooking === null ? CLIENT_BR_LEAD_LOADING_SUCCESS : BR_LEAD_LOADING_SUCCESS,
        })
        callback(response.data)
        return dispatch({
          type: hasBooking === null ? FETCH_BR_CLIENT_LEAD : FETCH_BR_LEAD,
          data:
            hasBooking === null && !getState().Leads.excludeLead
              ? response.data
              : hasBooking === null && getState().Leads.excludeLead.type === 'BuyRent'
              ? {
                  rows: response.data.rows.filter(
                    (dt) => dt.id !== getState().Leads.excludeLead.lead.id
                  ),
                  count: response.data.count,
                }
              : response.data,
        })
      })
      .catch(function (xhr, status, err) {
        return dispatch({
          type: hasBooking === null ? FETCH_BR_CLIENT_LEAD_FAILURE : FETCH_BR_LEAD_FAILURE,
          data: xhr,
        })
      })
  }
}

export const fetchRentAndLeadFilter = (url, offset, callback = null, status) => {
  const urlParams = new URLSearchParams(window.location.search)
  const page = urlParams.get('page')
  return (dispatch) => {
    dispatch({ type: FETCH_BR_LEAD_LOAD })
    axios
      .get(`${url}&web=true&page=${page}&pageSize=${config.pageSize}`, {
        params: {
          status:
            status !== undefined && status.value === 'shortlisting'
              ? ['follow_up', 'meeting', 'offer', 'viewing', 'propsure']
              : status === undefined
              ? ''
              : status.value,
        },
      })
      .then(function (response) {
        dispatch({ type: BR_LEAD_LOADING_SUCCESS })
        if (callback) callback(response.data)
        return dispatch({ type: FETCH_BR_LEAD, data: response.data })
      })
      .catch(function (xhr, status, err) {
        return dispatch({ type: FETCH_BR_LEAD_FAILURE, data: xhr })
      })
  }
}

export const fetchUsers = (page, url) => {
  return (dispatch) => {
    dispatch({ type: FETCH_USER_LOADING })

    axios.get(`${config.apiPath}${url}&page=${page * 1}`).then((res) => {
      setTimeout(() => {
        return dispatch({ type: FETCH_USER_SUCCESS })
      }, 20)
      return dispatch({ type: FETCH_USERS, data: res.data })
    })
  }
}

export const fetchAllClients = (clientName = null, type = null, parameters = '') => {
  return (dispatch) => {
    let params = {}
    // in case search input is less than 3 characters then don't send API call
    if (!clientName || clientName.length > 2) {
      if (clientName) {
        params.name = clientName
        dispatch({ type: IS_CLIENT_FILTER_LOADING, data: true })
      }
      if (type) params.type = type

      let endPoint = `${config.apiPath}/api/customer/teamClients?${encodeQueryData(
        params
      )}${parameters}`

      axios.get(endPoint).then((res) => {
        dispatch({ type: IS_CLIENT_FILTER_LOADING, data: false })
        return dispatch({ type: FETCH_ALL_CLIENT, data: res && res.data && res.data })
      })
    }
  }
}

export const fetchAccountViewFilter = (url) => {
  const urlParams = new URLSearchParams(window.location.search)
  const page = urlParams.get('page')
  return (dispatch) => {
    dispatch({ type: FETCH_ACCOUNT_VIEW_LOADER, data: true })
    axios
      .get(`${url}&page=${page}&pageSize=${config.pageSize}`)
      .then(function (response) {
        dispatch({ type: FETCH_ACCOUNT_VIEW_SUCCESS, data: false })
        return dispatch({ type: FETCH_ACCOUNT_VIEW, data: response.data })
      })
      .catch(function (xhr, status, err) {
        return dispatch({ type: FETCH_ACCOUNT_VIEW_FAILURE, data: xhr })
      })
  }
}

export const fetchAccountViewBuyAndRentFilter = (url) => {
  const urlParams = new URLSearchParams(window.location.search)
  const page = urlParams.get('page')
  return (dispatch) => {
    dispatch({ type: FETCH_BUY_RENT_SALES_VIEW_LOADER, data: true })
    axios
      .get(`${url}&page=${page}&pageSize=${config.pageSize}`)
      .then(function (response) {
        dispatch({ type: FETCH_BUY_RENT_SALES_VIEW_SUCCESS, data: false })
        return dispatch({ type: FETCH_BUY_RENT_SALES_VIEW, data: response.data })
      })
      .catch(function (xhr, status, err) {
        return dispatch({ type: FETCH_ACCOUNT_VIEW_FAILURE, data: xhr })
      })
  }
}

export const fetchLeadTimeLine = (id, type = 'project') => {
  let url = `${config.apiPath}/api/leads/leadActivity?leadId=${id}&web=true`

  // in case of buy rent lead, everything is same except the endpoint
  if (type === 'buy-rent') url = `${config.apiPath}/api/leads/timeLine?leadId=${id}&web=true`

  return (dispatch) => {
    dispatch({ type: FETCH_LEAD_TIMELINE_LOADER, data: true })
    axios
      .get(url)
      .then(function (response) {
        dispatch({ type: FETCH_LEAD_TIMELINE_LOADER, data: false })
        dispatch({ type: FETCH_LEAD_TIMELINE, data: response.data })
        // lets scroll to bottom
        scrollToBottom('timeline-scroll-container')
      })
      .catch(function (xhr, status, err) {
        return dispatch({ type: FETCH_LEAD_TIMELINE_SUCCESS, data: xhr })
      })
  }
}

export const addDiaryCall = (data, toggle, url) => {
  return (dispatch) => {
    dispatch({ type: LEAD_CUSTOM_LOADER, data: true })
    axios(url)
      .then((res) => {
        dispatch({ type: LEAD_CUSTOM_LOADER, data: false })
        dispatch({ type: ADD_DIARY_CALL, data })
        toggle(false)
      })
      .catch(function (xhr, status, err) {
        dispatch({ type: LEAD_CUSTOM_LOADER, data: false })
        return dispatch({ type: ADD_DIARY_CALL_FAILURE, data: xhr })
      })
  }
}

export const setDiaryDate = (date) => {
  return (dispatch) => {
    dispatch({ type: SET_DIARY_DATE, data: date })
  }
}

export const setDiaryTime = (date) => {
  return (dispatch) => {
    dispatch({ type: SET_DIARY_TIME, data: date })
  }
}

export const setDiaryEndTime = (date) => {
  return (dispatch) => {
    dispatch({ type: SET_DIARY_END_TIME, data: date })
  }
}

export const fetchAllAttachments = (id) => {
  return (dispatch) => {
    dispatch({ type: FETCH_ALL_ATTACHMENT_LOADER, data: true })
    axios
      .get(`${config.apiPath}/api/leads/comments?cmLeadId=${id}&type=attachment`)
      .then(function (response) {
        dispatch({ type: FETCH_ALL_ATTACHMENT_LOADER, data: false })
        return dispatch({ type: FETCH_ALL_ATTACHMENTS, data: response.data })
      })
  }
}

export const fetchAllComments = (id, type = 'project') => {
  let lead_param_name = 'cmLeadId'
  if (type === 'buy-rent') lead_param_name = 'rcmLeadId'

  return (dispatch) => {
    dispatch({ type: FETCH_ALL_COMMENTS_LOADER, data: true })
    axios
      .get(`${config.apiPath}/api/leads/comments?${lead_param_name}=${id}&type=comment&web=true`)
      .then(function (response) {
        dispatch({ type: FETCH_ALL_COMMENTS_LOADER, data: false })
        return dispatch({ type: FETCH_ALL_COMMENTS, data: response.data })
      })
      .catch(function (xhr, status, err) {
        return dispatch({ type: FETCH_ALL_COMMENTS_FAILURE, data: xhr })
      })
  }
}

export const addComment = (obj, callback, type = 'project') => {
  return (dispatch) => {
    dispatch({ type: ADD_COMMENT_LOADER, data: true })
    axios
      .post(`${config.apiPath}/api/leads/comments`, obj)
      .then(function (response) {
        dispatch({ type: ADD_COMMENT_LOADER, data: false })
        if (type === 'project') callback(response.data.armsProjectLeadId)
        else if (type === 'buy-rent') callback(response.data.armsLeadId, type)
        else if (type === 'property') callback(response.data)
        return dispatch({ type: ADD_COMMENT, data: response.data })
      })
      .catch(function (xhr, status, err) {
        return dispatch({ type: ADD_COMMENT_FAILURE, data: xhr })
      })
  }
}

export const setDescription = (data) => {
  return (dispatch) => {
    dispatch({ type: SET_DESCRIPTION, data })
  }
}

export const addTaskData = (data) => {
  return (dispatch) => {
    dispatch({ type: ADD_TASK_SUBJECT, data })
  }
}

export const addSelectedAgents = (data) => {
  return (dispatch) => {
    dispatch({ type: ADD_SELECTED_AGENTS, data })
  }
}

export const setClosedOption = (data) => {
  return (dispatch) => {
    dispatch({ type: SET_CLOSED_OPTIONS, data })
  }
}

export const setActiveInvestmentLeadTab = (data) => {
  return (dispatch) => {
    dispatch({ type: ACTIVE_INVESTMENT_LEAD_TAB, data })
  }
}

export const fetchLeadTasks = (leadId, params, callback = () => 'default') => {
  let url = `${config.apiPath}/api/v1/leads/tasks?web=true`

  return (dispatch) => {
    dispatch({ type: FETCH_LEAD_TASKS, payload: [], leadTaskFlag: true })
    axios
      .get(url, { params })
      .then(function (response) {
        dispatch({ type: FETCH_LEAD_TASKS, payload: response.data, leadTaskFlag: false })
        callback(response.data)
      })
      .catch(function (xhr, status, err) {
        dispatch({ type: FETCH_LEAD_TASKS, payload: false, leadTaskFlag: false })
      })
  }
}

export const fetchLeadFollowUpTasks = (leadId, params, callback = () => 'default') => {
  let url = `${config.apiPath}/api/v1/leads/tasks?web=true`

  return (dispatch) => {
    dispatch({ type: FETCH_FOLLOWUP_LEAD_TASKS, payload: false, leadTaskFlag: false })
    axios
      .get(url, { params })
      .then(function (response) {
        dispatch({ type: FETCH_FOLLOWUP_LEAD_TASKS, payload: response.data, leadTaskFlag: true })
        callback(response.data)
      })
      .catch(function (xhr, status, err) {
        dispatch({ type: FETCH_FOLLOWUP_LEAD_TASKS, payload: false, leadTaskFlag: null })
      })
  }
}

export const makeCall = (data, callback) => {
  return (dispatch) => {
    axios.post(`${config.apiPath}/api/diary/makeCall`, data).then((response) => {
      toast.dismiss()
      callback(response)
    })
  }
}

export const fetchAllShortlistedProperties = (id, callBack = () => {}) => {
  return (dispatch) => {
    dispatch({ type: FETCH_BUY_RENT_SHORTLISTED_PROPERTIES, data: [] })
    axios.get(`${config.apiPath}/api/leads/${id}/shortlist`).then((response) => {
      callBack(response.data)
      dispatch({ type: FETCH_BUY_RENT_SHORTLISTED_PROPERTIES, data: response.data })
    })
  }
}

export const setExcludeLead = (data) => {
  return { type: EXCLUDE_LEAD, data }
}

export const fetchLeadOrigins = () => {
  return (dispatch) => {
    dispatch({
      type: FETCH_LEAD_ORIGINS,
      data: [{ origins: 'ARMS' }, { origins: 'Wanted' }, { origins: 'Listing Inquiry' }],
    })
    // axios.get(`${config.apiPath}/api/leads/project/origins?rcmLead=true`).then((res) => {
    //   dispatch({ type: FETCH_LEAD_ORIGINS, data: res.data })
    // })
  }
}
