/** @format */

import React, { useState, useEffect, useMemo } from 'react'
import Select from 'react-select'
import { connect } from 'react-redux'
import Pagination from 'rc-pagination'
import local from '../../local/en_US'
import config from '../../config'
import { fetchAreas } from '../../actions/area'
import {
  fetchMatches,
  addShortlistedProperties,
  fetchMatchingPropertiesCount,
} from '../../actions/property'
import { fetchAllShortlistedProperties } from '../../actions/leads'
import General, {
  MakeMarlaRangerList,
  MakeBathRangerList,
  MakeBedRangerList,
  MakePriceRangerList,
} from '../../utils/general'
import {
  MatchPropertiesTemplate,
  MatchPropertiesRowTemplate,
  MatchPropertiesCellWidth,
} from '../../components/TableConfig/MatchPropertiesConfig'
import CustomTable from '../../components/common/Table/Table'
import { RenderList, ListMasking } from '../../utils/SelectMethods'
import PropertiesFilter from '../TopFilter/PropertiesFilter'
import { properties, priceRange, sizeRange, bedBathRange } from '../../utils/constant_data'
import ToggleButton from '../ToggleButton'
import './properties-style.scss'
import _, { filter } from 'lodash'
import Button from '../common/Button'
import FuzzyReactSelect from '../../hooks/FuzzyReactSelect'

const Properties = ({
  cities,
  singleLeadRecord,
  fetchAreasFun,
  Areas,
  fetchMatchesFun,
  matchProperties,
  matchPropertiesLoader,
  addShortlistedPropertiesFun,
  addShortlistedPropertiesLoader,
  toggleButton,
  fetchAllShortlistedProperties,
  LeadDetail,
  buyRentShortListProperties,
  fetchMatchingPropertiesCountFun,
  matchingCounting,
}) => {
  let areas = singleLeadRecord && singleLeadRecord.armsLeadAreas

  const Lead = LeadDetail ? LeadDetail : singleLeadRecord
  const { type, city, id, purpose, subtype } = singleLeadRecord
  const defaultPageSize = config.pageSize
  const [page, setPage] = useState(1)
  const [filterToggle, setFilterToggle] = useState(true)
  const [oldData, setOldData] = useState({})
  const [toggleRestrict, setToggleRestrict] = useState(false)
  const [filterHighlight, setFilterHightlight] = useState([])
  const [selectedRow, setSelectedRow] = useState([])
  const [grCurrentPage, setGrCurrentPage] = useState(1)
  const [armsCurrentPage, setArmsCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(defaultPageSize)
  const [currentTab, setTabState] = useState('Graana')
  const [filterData, setFilterData] = useState({
    city: ListMasking(city, 'id', 'name', false),
    areas: RenderList(areas, 'id', 'name', 'area'),
    propertyType: ListMasking(type, null, null, true),
    propertySubType: ListMasking(subtype, null, null, true),
    marlaRange: MakeMarlaRangerList(Lead, sizeRange),
    priceRanger: MakePriceRangerList(Lead, priceRange),
    bedRanger: MakeBedRangerList(Lead, bedBathRange),
    bathRanger: MakeBathRangerList(Lead, bedBathRange),
    organization: 'Graana',
  })

  useEffect(() => {
    fetchCityAreas()
    setOldData(filterData)
  }, [])

  useEffect(() => {
    fetchMatchesProperties()
  }, [])

  const updateFilterData = (type, value) => {
    setFilterData({ ...filterData, [type]: value })
  }

  const addShortListedPropertiesLocal = () => {
    addShortlistedPropertiesFun(Lead, selectedRow)

    setTimeout(() => {
      toggleButton(false)
      // setFilterData({})
      fetchAllShortlistedProperties(singleLeadRecord && singleLeadRecord.id)
    }, 300)
  }

  const handleRowClick = (id) => {
    let indexObject = _.find(matchProperties && matchProperties.rows, { id })
    let matchPropertiesLocal = Object.assign([], selectedRow)
    if (_.findIndex(matchPropertiesLocal, { id }) === -1) {
      matchPropertiesLocal.push(indexObject)
    } else {
      matchPropertiesLocal.splice(_.findIndex(matchPropertiesLocal, { id }), 1)
    }
    setSelectedRow(matchPropertiesLocal)
  }

  const getPropValue = (templateProperty, cellIndex, rowIndex, rowData, props, columnName) => {
    switch (cellIndex) {
      case 6:
        if (templateProperty === 'label') {
          if (rowData && rowData.armsuser) {
            return (
              (rowData.armsuser.firstName ? rowData.armsuser.firstName : '') +
              ' ' +
              (rowData.armsuser.lastName ? rowData.armsuser.lastName : '')
            )
          } else if (rowData && rowData.user) {
            return (
              (rowData.user.first_name ? rowData.user.first_name : '') +
              ' ' +
              (rowData.user.last_name ? rowData.user.last_name : '')
            )
          } else {
            return '--'
          }
        }
        break
    }
  }

  const fetchCityAreas = (cityId) => {
    fetchAreasFun(cityId ? cityId : city && city.id)
  }

  const fetchMatchesProperties = (orgValue, cPage = 1) => {
    let priceAnyValue = 10000000000

    let params = {
      leadId: id,
      organization:
        orgValue === 'Graana'
          ? 'graanaDU'
          : orgValue === 'ARMS'
          ? 'arms'
          : filterData && filterData.organization === 'Graana'
          ? 'graanaDU'
          : 'arms',
      type: filterData && filterData.propertyType && filterData.propertyType.value,
      subtype:
        filterData && filterData.propertySubType !== null
          ? filterData && filterData.propertySubType && filterData.propertySubType.value
          : null,
      area_id: General.extractSelectArray(filterData && filterData.areas),
      purpose: purpose,
      price_min:
        filterData && filterData.priceRanger !== null
          ? filterData && filterData.priceRanger && filterData.priceRanger.price_min
          : null,
      price_max:
        (filterData && filterData.priceRanger !== 0) ||
        (filterData && filterData.priceRanger !== null)
          ? filterData && filterData.priceRanger && filterData.priceRanger.price_max
          : priceAnyValue,
      city_id: filterData && filterData.city && filterData.city.value,
      maxBed:
        (filterData && filterData.bedRanger.maxVal === undefined) ||
        (filterData && filterData.bedRanger.maxVal === 0)
          ? 1000
          : filterData && filterData.bedRanger !== null
          ? filterData && filterData.bedRanger.maxVal
          : 10000,
      maxBath:
        (filterData && filterData.bathRanger.maxVal === undefined) ||
        (filterData && filterData.bathRanger.maxVal === 0)
          ? 1000
          : filterData && filterData.bathRanger !== null
          ? filterData && filterData.bathRanger.maxVal
          : 1000,
      max_size: filterData && filterData.marlaRange && filterData.marlaRange.size_max,
      size: filterData && filterData.marlaRange && filterData.marlaRange.min,
      page: cPage,
      pageSize: defaultPageSize,
      unit: filterData.marlaRange.unit.value,
    }

    fetchMatchesFun(params)
    fetchMatchingPropertiesCountFun(params)
    setFilterHightlight(filterData)
  }

  const toggleFilterButton = () => {
    setFilterToggle(!filterToggle)
  }
  const customStyles = {
    control: (base) => ({
      ...base,
      '&:hover': {
        borderColor: '#046ef2',
        color: '#046ef2',
      },
      '&': {
        borderColor: '#046ef2',
        color: '#046ef2',
      },
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    menu: (provided) => ({ ...provided, zIndex: '9999 !important' }),
    dropdownIndicator: (base) => ({
      ...base,
      color: 'inherit',
    }),
    indicatorSeparator: (base) => ({
      ...base,
      backgroundColor: '#046ef2',
    }),
    singleValue: (base) => ({
      ...base,
      color: 'inherit',
    }),
  }

  const searchByOrg = (type, value) => {
    setTabState(value)
    setGrCurrentPage(1)
    setArmsCurrentPage(1)
    fetchMatchesProperties(value, 1)
    setFilterData({ ...filterData, [type]: value })
  }

  useMemo(() => {
    if (_.isEqual(filterData, oldData) && toggleRestrict) {
      fetchMatchesProperties()
    }
  }, [filterData, oldData, toggleRestrict])

  const fieldHeight = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    menu: (provided) => ({ ...provided, zIndex: '9999 !important' }),
    control: (base) => ({
      ...base,
      height: 35,
      minHeight: 35,
      border: '1px solid rgb(204, 204, 204)',
      boxShadow: 'none',
      '&:hover': {
        border: '1px solid rgb(204, 204, 204)',
      },
    }),
    singleValue: (base) => ({
      ...base,
      color: 'hsl(0,0%,50%)',
    }),
  }
  const textItemRender = (current, type, element) => {
    if (type === 'prev') {
      return 'Prev'
    }
    if (type === 'next') {
      return 'Next'
    }
    return element
  }
  const getPagination = () => {
    let cPage = currentTab === 'Graana' ? grCurrentPage : armsCurrentPage
    let totalCount = matchProperties && matchProperties.count ? matchProperties.count : 0
    if (totalCount <= pageSize) return ''
    return (
      <div className="pagination">
        <div className="totalCountAlignment">
          <span className="totalCountStyle">
            Showing
            <span className="totalCountSubStyle">{`${(cPage - 1) * pageSize + 1}-${
              cPage * pageSize < totalCount ? cPage * pageSize : totalCount
            }/${totalCount}`}</span>
          </span>
        </div>
        <Pagination
          className="ps-pagination"
          hideOnSinglePage={true}
          pageSize={pageSize}
          current={parseInt(cPage, 10)}
          showPrevNextJumpers={false}
          itemRender={textItemRender}
          onChange={(cPage, pSize) => {
            if (currentTab === 'Graana') {
              setGrCurrentPage(cPage)
            } else {
              setArmsCurrentPage(cPage)
            }
            fetchMatchesProperties(currentTab, cPage)
          }}
          total={totalCount}
          locale={local}
        />
      </div>
    )
  }

  const fuzzyOptions = {
    keys: ['customValue'],
  }
  return (
    <>
      <div className="row mt-4">
        <div className="col-2-2">
          <Select
            styles={filterHighlight && filterHighlight.city !== null ? customStyles : fieldHeight}
            options={RenderList(cities, 'id', 'name', null)}
            placeholder="City"
            onChange={(value) => {
              fetchCityAreas(value.value)
              setFilterData({ ...filterData, city: value, areas: [] })
            }}
            value={filterData && filterData.city}
          />
        </div>

        <div className="col-md-3">
          <FuzzyReactSelect
            styles={
              filterHighlight && filterHighlight.areas && filterHighlight.areas.length > 0
                ? customStyles
                : fieldHeight
            }
            options={RenderList(Areas && Areas.items, 'id', 'name', null)}
            fuzzyOptions={fuzzyOptions}
            autoCorrect="off"
            spellCheck="off"
            placeholder="Areas"
            onChange={(value) => {
              setFilterData({ ...filterData, areas: value })
            }}
            value={filterData && filterData.areas}
            isMulti
          />
        </div>

        <div className="col-2-2">
          <Select
            styles={
              filterHighlight && filterHighlight.propertyType !== null ? customStyles : fieldHeight
            }
            options={RenderList(properties && properties.type, 'value', 'name', null)}
            placeholder="Property Type"
            onChange={(value) => {
              setFilterData({ ...filterData, propertyType: value })
            }}
            value={filterData && filterData.propertyType}
          />
        </div>

        <div className="col-2-2">
          <Select
            styles={
              filterHighlight && filterHighlight.propertySubType !== null
                ? customStyles
                : fieldHeight
            }
            options={RenderList(
              properties &&
                properties.subType &&
                properties.subType[
                  filterData && filterData.propertyType && filterData.propertyType.value
                ],
              'value',
              'name',
              null
            )}
            value={filterData && filterData.propertySubType}
            placeholder="Property Sub type"
            onChange={(value) => {
              setFilterData({ ...filterData, propertySubType: value })
            }}
          />
        </div>

        <div className="col-2-5 pr-0">
          <div className="text-right">
            <button
              className="filter__btn"
              data-toggle="collapse"
              data-target="#moreFilters"
              onClick={() => {
                toggleFilterButton()
                setToggleRestrict(false)
              }}
            >
              More Filters
              {filterToggle ? (
                <i className="fal fa-chevron-circle-up"></i>
              ) : (
                <i className="fal fa-chevron-circle-down"></i>
              )}
            </button>
            <button
              className="newFormSearch"
              type="submit"
              onClick={() => fetchMatchesProperties()}
            >
              <i className="fal fa-search"></i>
            </button>
            <input
              className="newFormClear"
              type="submit"
              value="Reset"
              onClick={() => {
                setFilterData(oldData)
                setToggleRestrict(true)
              }}
            />
          </div>
        </div>
      </div>
      {filterToggle && (
        <div className="mt-2" id="moreFilters">
          <PropertiesFilter
            updateFilterDataFun={updateFilterData}
            LeadDetail={Lead}
            filterHighlight={filterHighlight}
          />
        </div>
      )}

      <div className="row">
        <div className="col-0-5">
          <ToggleButton
            text="Graana"
            active={filterData.organization === 'Graana' ? true : false}
            searchByOrg={searchByOrg}
            badgeCount={
              matchingCounting && matchingCounting.graana && matchingCounting.graana.count
            }
            badgeCountShow={true}
          />
        </div>
        <div className="col-0-5">
          <ToggleButton
            text="ARMS"
            active={
              filterData.organization === 'Agency21' || filterData.organization === 'ARMS'
                ? true
                : false
            }
            searchByOrg={searchByOrg}
            badgeCount={matchingCounting && matchingCounting.arms && matchingCounting.arms.count}
            badgeCountShow={true}
          />
        </div>
      </div>
      <div className="mt-2">
        <CustomTable
          headerTemplate={MatchPropertiesTemplate()}
          bodyTemplate={MatchPropertiesRowTemplate(
            handleRowClick,
            getPropValue,
            buyRentShortListProperties,
            matchProperties,
            selectedRow
          )}
          tableContinerClassName="properties-container-class"
          data={
            matchProperties && matchProperties.rows && matchProperties.rows.length > 0
              ? matchProperties.rows
              : []
          }
          noResult={matchProperties && matchProperties.rows && matchProperties.rows.length === 0}
          headerHeight={'50px'}
          headerColor={'#f6f7fa'}
          rowColor={() => {
            return 'white'
          }}
          rowHeight={() => {
            return '50px'
          }}
          rowHoverColor={() => {
            return '#f6f7fa'
          }}
          selectedRowColor={() => {
            return '#046ef21f'
          }}
          cellWidth={MatchPropertiesCellWidth()}
          fixedHeader={true}
          fixedColumns={{ left: 0, right: 0 }}
          resizeColumns={false}
          tableClassName="properties-table-name"
          noResultClassName="no-result-container"
          headerClassName="properties-header-size"
          loader={matchPropertiesLoader}
          useMemo={false}
        />
        {getPagination()}
      </div>

      <div className="match-properties-button-container">
        <div className="match-properties-button">
          <Button
            text={'Cancel'}
            textColor={'black'}
            buttonColor="white"
            className={'px-5 rounded-pill border'}
            onClick={() => {
              toggleButton(false)
              setFilterData({})
            }}
          />
        </div>
        <div className="match-properties-button">
          <button
            className="selected-matches"
            disabled={selectedRow && selectedRow.length === 0}
            onClick={() => addShortListedPropertiesLocal()}
          >
            {addShortlistedPropertiesLoader ? (
              <i className={`fa fa-circle-o-notch fa-spin`}></i>
            ) : selectedRow && selectedRow.length > 0 ? (
              `  Continue with ${selectedRow && selectedRow.length} selected properties`
            ) : (
              'Select Properties'
            )}
          </button>
        </div>
      </div>
    </>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchAreasFun: (data) => dispatch(fetchAreas(data)),
    fetchMatchesFun: (data) => dispatch(fetchMatches(data)),
    addShortlistedPropertiesFun: (lead, data) => dispatch(addShortlistedProperties(lead, data)),
    fetchAllShortlistedProperties: (leadId) => dispatch(fetchAllShortlistedProperties(leadId)),
    fetchMatchingPropertiesCountFun: (data) => dispatch(fetchMatchingPropertiesCount(data)),
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
    cities: state.user.cities,
    Areas: state.Areas.allAreas,
    matchProperties: state.Property.matchData,
    matchPropertiesLoader: state.Property.matchDataLoader,
    addShortlistedPropertiesLoader: state.Property.addShortlistedPropertiesLoader,
    addShortListedProperties: state.Property.addShortListedProperties,
    buyRentShortListProperties: state.Leads.buyRentShortListProperties,
    matchingCounting: state.Property.matchingCounting,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Properties)
