/** @format */

const checkBaseDomain = (host) => {
  switch (host) {
    case 'beta.arms.graana.com':
      return 'https://beta.arms.graana.com'
    case 'localhost':
      //return 'http://localhost:3050'
      return 'https://beta.arms.graana.com'
      //return 'https://dev.arms.graana.rocks'

      return 'https://snpm start' + 'tage.arms.graana.rocks'

    //return 'https://arms.graana.com'
    // return 'https://uat.arms.graana.rocks'

    // return 'https://api.stage.realtor.agency21.rocks'

    case 'realtor.agency21.rocks':
      return 'https://api.realtor.agency21.rocks'
    case 'stage.realtor.agency21.rocks':
      return 'https://api.stage.realtor.agency21.rocks'
    default:
      return 'https://beta.arms.graana.com'
  }
}

const socketBaseDomain = (host) => {
  switch (host) {
    case 'localhost':
      return {
        socketUrl: 'wss://ptcs.imarat.com.pk',
        callUrl: 'https://ptcs.imarat.com.pk',
        asteriskUrl: 'https://ptcc.imarat.com.pk',
      }
    default:
      return {
        socketUrl: 'wss://ptcs.imarat.com.pk',
        callUrl: 'https://ptcs.imarat.com.pk',
        asteriskUrl: 'https://ptcc.imarat.com.pk',
      }
  }
}
export { checkBaseDomain, socketBaseDomain }
