/** @format */

import React, { useEffect, useState, useMemo } from 'react'
import axios from 'axios'
import config from '../../config'
//import Pagination from '../UnitBookingForm/Inventory/Pagination'
import '../InProgressBooking/style.scss'
import { withCookies } from 'react-cookie'
import { setTitle } from '../../actions/generalSetting'
import { connect } from 'react-redux'
import Pagination from "rc-pagination";
import local from "../../local/en_US";
import "rc-pagination/assets/index.css";


import { fetchAllProjects, fetchAllProjectsNew } from '../../actions/projects'
import MoreFilter from './moreFilter'

import { withRouter } from 'react-router-dom'
import { Dropdown, DropdownMenu, DropdownToggle, Modal, ModalBody } from 'reactstrap'
import moment from 'moment'
import {showToastMsg} from "../../utils/helper";
import {useRef} from "react";

const InProgressBooking = (props) => {
  const [loader, setLoader] = useState(false)
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [bookings, setBookings] = useState([])
  const [pagination, setPagination] = useState({})
  const [showMoreFilter, setShowMoreFilter] = useState(false)
  const [filter, setFilter] = useState({})
  const [resetFilter, setResetFilter] = useState(false)

  const toggleShowMoreFilter = (payload) => {
    setShowMoreFilter(payload ? payload : !showMoreFilter)
  }

  const changeTitle = (title) => {
    let t = 'In Progress Booking'
    switch (title) {
      case 'InProgress':
        t = 'In Progress Booking'
        break
      case 'Completed':
        t = 'Completed Booking'
        break
    }
    resetFilters()
    document.title = t
    props.setTitle({ name: t })
  }

  const feedFormInput = (name, payload) => {
    setFilter({ ...filter, [name]: payload })
  }

  const resetFilters = () => {
    setFilter({
      id: '',
      from_date: '',
      to_date: '',
      client_email: '',
      clientName: '',
      phoneNo: '',
      projectId: '',
      productId: '',
    })
    setResetFilter(true)
  }

  useEffect(() => {
    changeTitle(props.match.params.bookingStatus)
    props.fetchAllProjects()
    setBookings([])
    setPagination({})
    getBookings(props.match.params.bookingStatus, page, perPage)
  }, [props.match.params.bookingStatus])
  useEffect(() => {
    getBookings(props.match.params.bookingStatus, page, perPage)
    setTimeout(() => {
      changeTitle(props.match.params.bookingStatus)
    }, 300)
  }, [window.location.href])

  const getBookings = (bookingStatus, page, perPage) => {
    setLoader(true)
    getBookingsPromise(bookingStatus, page, perPage)
      .then((result) => {
        setBookings(result.data)
        setPagination(result.meta)
        setLoader(false)
      })
      .catch((error) => {
        setBookings([])
        setPagination({})
        setLoader(false)
      })
  }

  const searchFilter = (bookingStatus, page, perPage) => {
    setLoader(true)
    return getBookingsPromise(bookingStatus, page, perPage)
      .then((result) => {
        setBookings(result.data)
        setPagination(result.meta)
        setLoader(false)
      })
      .catch((error) => {
        setBookings([])
        setPagination({})
        setLoader(false)
      })
  }


  const textItemRender = (current, type, element) => {
    if (type === "prev") {
      return "Prev";
    }
    if (type === "next") {
      return "Next";
    }
    return element;
  };


  const getBookingsPromise = (bookingStatus, page, perPage) => {
    setPage(page);
    setPerPage(perPage);
    let url = `${config.apiPath}/api/imarat/booking?status=${bookingStatus}&productType=${props.match.params.bookingType}&page=${page}&per_page=${perPage}`

    if (Object.keys(filter).length > 0) {
      const {
        id,
        fromDate,
        toDate,
        client_email,
        clientName,
        phoneNo,
        projectId,
        productId,
        booked_sq_ft,
        rate_per_sq_ft,
        agent_name,
        advisor_name,
        old_reference_no,
        unique_code,
        lead_id,
      } = filter
      if (id) url += `&id=${id}`
      if (fromDate) url += `&start_date=${moment(filter.fromDate).format('YYYY-MM-DD')}`
      if (toDate) url += `&end_date=${moment(filter.toDate).format('YYYY-MM-DD')}`
      if (client_email) url += `&client_email=${client_email}`
      if (clientName) url += `&client_name=${clientName.label}`
      if (phoneNo) url += `&client_phone=${phoneNo}`
      if (projectId) url += `&project_name=${projectId.label}`
      if (productId) url += `&product_type_id=${productId.value}`
      if (booked_sq_ft) url += `&booked_sq_ft=${booked_sq_ft}`
      if (rate_per_sq_ft) url += `&rate_per_sq_ft=${rate_per_sq_ft}`
      if (agent_name) url += `&agent_name=${agent_name.label}`
      if (advisor_name) url += `&advisor_name=${advisor_name.label}`
      if (old_reference_no) url += `&old_reference_no=${old_reference_no}`
      if (unique_code) url += `&unique_code=${unique_code}`
      if (lead_id) url += `&lead_id=${lead_id}`
    }

    return new Promise((resolve, reject) => {
      axios
        .get(url)
        .then((result) => {
          resolve(result.data)
        })
        .catch((error) => {
          reject(error.response)
        })
    })
  }

  useMemo(() => {
    if (resetFilter) {
      getBookingsPromise(props.match.params.bookingStatus, 1, 10)
        .then((result) => {
          setBookings(result.data)
          setPagination(result.meta)
          setLoader(false)
          setResetFilter(false)
        })
        .catch((error) => {
          setBookings([])
          setPagination({})
          setLoader(false)
          setResetFilter(false)
        })
    }
  }, [resetFilter])

  const refreshBookings = (flag) =>{
    getBookings(props.match.params.bookingStatus, page, perPage)
  }
  const pageActionHandle = (page, per_page) => {
    getBookings(props.match.params.bookingStatus, page, per_page)
  }

  let pageStatus = props.match.params.bookingStatus


  return (
    <>
      <div className="card mt-2">
        <div className="row">
          <div className="col-md-2">
            <h5 className={'card-title p-3'}></h5>
          </div>

          <div className="col-md-10 mt-3 text-right">
            <button
              class="more-filter-btn client-more-filter  mr-3"
              onClick={() => toggleShowMoreFilter(true)}
            >
              <i class="fa fa-filter ml-0 mr-2" aria-hidden="true" />
              More Filters{' '}
            </button>
          </div>
        </div>

        {showMoreFilter && (
          <Modal
            isOpen={showMoreFilter}
            toggle={toggleShowMoreFilter}
            className="device-modal"
            style={{ minWidth: 1100 }}
            size={'lg'}
          >
            <ModalBody>
              <MoreFilter
                showMoreFilter={showMoreFilter}
                toggleShowMoreFilter={toggleShowMoreFilter}
                feedFormInput={feedFormInput}
                filters={filter}
                projects={props.projects}
                searchFilter={searchFilter}
                bookingStatus={props.match.params.bookingStatus}
                resetFilters={resetFilters}
              />
            </ModalBody>
          </Modal>
        )}

        <div className="card-body">
          <div className="inv-table">
            <table style={{ height: '100%' }}>
              <thead>
                <tr>
                  <th style={{ minWidth: "60px" }}>ID</th>
                  <th style={{ minWidth: "100px" }}>Lead ID</th>
                  <th style={{ minWidth: "160px" }}>Booking Start Date</th>
                  <th style={{ minWidth: "160px" }}>Old Reference No.</th>
                  <th style={{ minWidth: "160px" }}>New Reference No.</th>
                  <th style={{ minWidth: "130px" }}>Advisor Name</th>
                  <th style={{ minWidth: "110px" }}>Agent Name</th>
                  <th style={{ minWidth: "160px" }}>Customer Name</th>
                  <th style={{ minWidth: "100px" }}>Project</th>
                  <th style={{ minWidth: "100px" }}>Sqft</th>
                  <th style={{ minWidth: "100px" }}>Rate</th>
                  <th style={{ minWidth: "160px" }}>Total Unit Amount</th>
                  <th style={{ minWidth: "160px" }}>Product Type</th>
                  <th style={{ minWidth: "100px" }}>Action</th>
                </tr>
              </thead>
              <tbody>
                {loader ? (
                  <tr>
                    <p style={{paddingLeft:"36vw",paddingTop:"10vh"}} colSpan={10} className={''}>
                      Loading ...
                    </p>
                  </tr>
                ) : (
                  bookings.map((booking, index) => {
                    return (
                      <TableTr
                        bookingStatus={props.match.params.bookingStatus}
                        refreshBookings={refreshBookings}
                        booking={booking}
                        key={index}
                        {...props}
                      />
                    )
                  })
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className="row mt-3 mb-2">
                <div className="col-md-4 totalCountAlignment">
                  <span className="totalCountStyle">
                    Showing
                    <span className="totalCountSubStyle">
                      {(page - 1) * perPage + 1} -{" "}
                      {pagination.total >= page * perPage
                        ? page * perPage
                        : pagination.total}
                    </span>{" "}
                    / {pagination.total}
                  </span>
                </div>

                <div className="col-md-6" style={{ marginLeft: 0 }}>
                  <Pagination
                    className="ps-pagination"
                    // hideOnSinglePage={true}
                    showPrevNextJumpers={false}
                    itemRender={textItemRender}
                    pageSize={parseInt(perPage, 10)}
                    current={parseInt(page, 10)}
                    onChange={(page, perPage) =>
                      pageActionHandle(page, perPage)
                    }
                    total={pagination.total}
                    locale={local}
                  />
                </div>
                <div className="col-md-2"> </div>
              </div>
      </div>
    </>
  )
}

const TableTr = (props) => {
  const { booking } = props
  const [viewingDropdown, setViewingDropdown] = useState(false)
  const [regenerateLoader,setRegenerateLoader]=useState(false)
  const [loader, setLoader] = useState(false)
  const uploadFile = useRef(null)
  const uploadFileHandle = (e) => {
    if (!loader) {
      uploadFile.current.click()
    } else {
      showToastMsg('Please wait Uploading already in Progress!', 'warning')
    }
  }
  const fileInputHandle = (e, bookingId) => {
    setLoader(true)
    const formData = new FormData()
    formData.append('attachment', e.target.files[0])
    axios
      .post(`${config.apiPath}/api/imarat/booking/${bookingId}/upload-report`, formData)
      .then((result) => {
        props.refreshBookings(true)
        showToastMsg('Signed Uploaded successfully!', 'success')
        setLoader(false)
      })
      .catch((error) => {
        showToastMsg(error.response.message, 'error')
        setLoader(false)
      })
  }

  const reGenerateSlip = (id) => {
    setRegenerateLoader(true)
    axios
      .post(`${config.apiPath}/api/imarat/booking/bookingSlip/${id}/regenerate`)
      .then((result) => {
        setRegenerateLoader(false)
        window.open(result.data.data.unpaid_invoice, '_blank')
      })
  }



  let pageStatus = props.match.params.bookingStatus
  return (
    <tr className='hover__for__btns hoverForTr'>
      <td style={{ minWidth: "60px" }}>{booking.id}</td>
      <td style={{ minWidth: "100px" }}>{booking.lead_id && booking.lead_id !== 1 ? booking.lead_id : '--'}</td>
      <td style={{ minWidth: "160px" }}>{booking.booking_date}</td>
      <td style={{ minWidth: "160px" }}>{booking && booking.existing_ref_no ? booking.existing_ref_no : '--'}</td>
      <td style={{ minWidth: "160px" }}>{booking && booking.in_unique_code ? booking.in_unique_code : '--'}</td>
      <td style={{ minWidth: "130px" }}>{booking.advisor_name}</td>
      <td style={{ minWidth: "110px" }}>{booking.agent_name ? booking.agent_name : '--'}</td>
      <td style={{ minWidth: "160px" }}>
        <div /*className={'tags'}*/>
          {booking.customers.map((customer, i) => {
            return (
              <span key={`c${i}`}>{`${customer}${booking.customers.length > 1 ? ',' : ''}`}</span>
            )
          })}
        </div>
      </td>
      <td style={{ minWidth: "100px" }}>{booking.project_name}</td>
      <td style={{ minWidth: "100px" }}>{booking.booked_sq_ft ? booking.booked_sq_ft : '--'}</td>
      <td style={{ minWidth: "100px" }}>{booking.rate_per_sq_ft ? Number(booking.rate_per_sq_ft).toLocaleString() : '--'}</td>
      <td style={{ minWidth: "160px" }}>{booking.payable_amount ? Number(booking.payable_amount).toLocaleString() : '--'}</td>
      <td style={{ minWidth: "160px" }}>{booking.product_name ? booking.product_name : '--'}</td>
      <td style={{ minWidth: "100px" }} className="text-center">
        <Dropdown
          className={`d-inline dropDownForViwing`}
          style={{ paddingRight: '1px', cursor: 'pointer', top: '5px' }}
          isOpen={viewingDropdown}
          toggle={() => setViewingDropdown((viewDrop) => !viewDrop)}
        >
          <DropdownToggle tag="span">
            <i className="far fa-ellipsis-v text-blue pr-1 pr-3 " style={{ fontSize: '22px' }} />
          </DropdownToggle>
          <DropdownMenu style={{ width: '250px' }} className={'p-0'}>
            {/* {
              (props.bookingStatus === 'InProgress') && (
                <div
                  onClick={() => {
                    props.history.push(`/booking/financial/${booking.id}`)
                  }}
                  className={'container_separator card-container-text'}
                >
                  {props.bookingStatus === 'InProgress' ? (
                    <>
                      <i className={'fa fa-edit'} /> Edit Booking
                    </>
                  ) : (
                    <>
                      <i className={'fa fa-eye'} /> View Booking
                    </>
                  )}
                </div>
              )
            } */}
            <div
              onClick={() => {
                props.history.push(`/booked/InventoryDetail/${booking.slug}`)
              }}
              className={'container_separator card-container-text'}
            >
              <i className={'fa fa-eye'} /> Detail
            </div>
            <div
              onClick={() => {
                window.open(booking.kfi_document)
              }}
              className={'container_separator card-container-text'}
            >
              KFI Document
            </div>

            {
              (props.bookingStatus === 'Completed') && (
                <>
                  {
                    (booking.paid_invoice !==null && booking.paid_invoice !==undefined )?
                      (
                        <div
                          onClick={() => {window.open(booking.paid_invoice,'_blank')}}
                          className={'container_separator card-container-text'}
                        >Signed Receiving Report</div>
                      ):
                      (
                        <>
                          <div
                            onClick={() => {window.open(booking.unpaid_invoice,'_blank')}}
                            className={'container_separator card-container-text'}
                          >Unsigned Receiving Report</div>
                          <div
                            onClick={uploadFileHandle}
                            className={'container_separator card-container-text'}
                          >
                            <>
                              <button
                                disabled={loader}
                                style={{
                                  background: 'transparent',
                                  border: 'none',
                                  boxShadow: 'none',
                                  color: '#000',
                                }}
                                type={'button'}
                                className={'btn btn-primary btn-sm pl-0'}
                              >
                                {loader ? (
                                  <i style={{ color: '#000' }} className={'fa fa-spinner'} />
                                ) : (
                                  <i style={{ color: '#000' }} className={'fa fa-upload'} />
                                )}
                              </button>
                              <input
                                onChange={(e) => {
                                  fileInputHandle(e, booking.id)
                                }}
                                ref={uploadFile}
                                type="file"
                                style={{ display: 'none' }}
                              />
                              &nbsp;Upload Signed Report

                            </>
                          </div>
                        </>
                      )
                  }
                </>
              )
            }
  <div
              onClick={() => {
                reGenerateSlip(booking.id)
              }}
              className={'container_separator card-container-text'}
            >
              {regenerateLoader?<i className={`fa fa-circle-o-notch fa-spin`}></i>:'Re-Generate Receiving Report'}

            </div>
          </DropdownMenu>
        </Dropdown>
      </td>
    </tr>
  )
}

export const mapDispatchToProps = (dispatch) => {
  return {
    setTitle: (title) => dispatch(setTitle(title)),
    fetchAllProjects: () => dispatch(fetchAllProjectsNew({ offset: 0 })),
  }
}
export const mapStateToProps = (state, ownProps) => {
  return {
    projects: state.Projects.allProjects,
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withCookies(InProgressBooking)))
