/** @format */

import { DIARY_TASK_ACTION, properties } from './constant_data'
import { getWantedAssignedTo } from '../components/common/Diary/DiaryUtilHelper'
import { getPermissionValue } from '../components/_unlockComponent'
import { PermissionActions, PermissionFeatures } from './permissionConstants'
import { isAira } from '../components/common/LeadView/Wanted/WantedUtilHelper'
import axios from 'axios'
import config from '../config'
import { removeExtrachar, showToastMsg } from './helper'
import { missingFieldHtml } from './validate'
import Swal from 'sweetalert2'
import moment from 'moment'

export const capitalizeLeadTypeFirstLetter = (string) => {
  if (string === 'project') {
    string = 'Project'
  } else if (string === 'buy' || string === 'rent') {
    string = 'BuyRent'
  }
  return string
}
export const isLeadClosed = (lead) => ['closed_lost', 'closed_won'].indexOf(lead.status) > -1

export const isLeadClosedWon = (lead) => 'closed_won' === lead.status

export const assignedToMe = (lead) => {
  const loginUserId = localStorage.getItem('user_id')
  return parseInt(loginUserId) === parseInt(lead.assigned_to_armsuser_id)
}

export const sharedWithMe = (lead) => {
  const loginUserId = localStorage.getItem('user_id')
  return parseInt(lead.shared_with_armsuser_id) === parseInt(loginUserId)
}

export const isReadOnly = (lead, leadType) => {
  let returnObject = { ownLeadReadOnly: false, showReadOnly: false, checkForManager: false }
  const loginUserRole = localStorage.getItem('role')
  if (loginUserRole === 'sub_admin 1') {
    returnObject['checkForManager'] = true
  }
  if (leadType === 'Project') {
    if (assignedToMe(lead) || sharedWithMe(lead)) returnObject['ownLeadReadOnly'] = true

    if (!assignedToMe(lead) || isLeadClosed(lead)) returnObject['showReadOnly'] = true
  } else if (leadType === 'BuyRent') {
    if (lead && lead.armsuser !== null) {
      if (assignedToMe(lead)) returnObject['ownLeadReadOnly'] = true

      if (assignedToMe(lead) || isLeadClosed(lead)) {
        returnObject['showReadOnly'] = true
      }
      if (lead.wanted_id && isAira()) {
        returnObject['showReadOnly'] = false
        returnObject['checkForManager'] = true
        returnObject['airaWantedLead'] = true
      }
    }
  }
  return returnObject
}

export const getLeadActions = (leadType, lead) => {
  const assignToMe = assignedToMe(lead)
  const isLeadClose = isLeadClosed(lead)
  const isLeadCloseWon = isLeadClosedWon(lead)
  switch (leadType) {
    case 'Project':
      return [
        DIARY_TASK_ACTION.LEAD_ADDITIONAL_DETAILS,

        getPermissionValue(PermissionFeatures.PROJECT_LEADS, PermissionActions.SHARE) &&
          !isLeadClose &&
          assignToMe &&
          DIARY_TASK_ACTION.REFER_LEAD,

        getPermissionValue(PermissionFeatures.PROJECT_LEADS, PermissionActions.ASSIGN_REASSIGN) &&
          !isLeadClose &&
          assignToMe &&
          DIARY_TASK_ACTION.REASSIGN_LEAD,

        !lead.guideReference && !isLeadClose && assignToMe && DIARY_TASK_ACTION.INVESTMENT_GUIDE,

        !isLeadClose && assignToMe && DIARY_TASK_ACTION.CLOSE_AS_LOST,

        isLeadCloseWon && assignToMe && DIARY_TASK_ACTION.RE_OPEN_LEAD

      ].filter((f) => f)
      break
    case 'BuyRent':
      return [
        DIARY_TASK_ACTION.LEAD_ADDITIONAL_DETAILS,

        getPermissionValue(PermissionFeatures.BUY_RENT_LEADS, PermissionActions.SHARE) &&
          !isLeadClose &&
          assignToMe &&
          DIARY_TASK_ACTION.REFER_LEAD,

        getPermissionValue(PermissionFeatures.PROJECT_LEADS, PermissionActions.ASSIGN_REASSIGN) &&
          !isLeadClose &&
          assignToMe &&
          DIARY_TASK_ACTION.REASSIGN_LEAD,

        !isLeadClose && assignToMe && DIARY_TASK_ACTION.CLOSE_AS_LOST,

        isLeadCloseWon && assignToMe && DIARY_TASK_ACTION.RE_OPEN_LEAD
      ].filter((f) => f)
      break
    case 'Wanted':
      return [
        assignToMe && getWantedAssignedTo({ wanted: lead }),
        getPermissionValue(PermissionFeatures.WANTED_LEADS, PermissionActions.ASSIGN_REASSIGN) &&
          DIARY_TASK_ACTION.REASSIGN_LEAD,

        ['rejected', 'assigned'].indexOf(lead.armsStatus) === -1 &&
          getPermissionValue(PermissionFeatures.WANTED_LEADS, PermissionActions.UPDATE) &&
          DIARY_TASK_ACTION.CLOSE_AS_LOST,

        isLeadCloseWon && assignToMe && DIARY_TASK_ACTION.RE_OPEN_LEAD
      ].filter((f) => f)
      break
  }
}

export const leadStatusClass = (lead, leadType) => {
  return leadType === 'BuyRent'
    ? lead.status === 'follow_up' ||
      lead.status === 'meeting' ||
      lead.status === 'propsure' ||
      lead.status === 'viewing' ||
      lead.status === 'offer' ||
      lead.status === 'shortlisting'
      ? 'shortlisting'
      : ['closed_lost', 'token', 'open', 'pending_token', 'payment', 'closed_won'].indexOf(lead.status) > -1
      ? lead.status
      : ''
    : leadType === 'Project'
    ? lead.status === 'closed_lost' || lead.status === 'refund' || lead.status === 'buyback'
      ? 'closed_lost'
      : lead.status === 'intermall_adjustment'
      ? 'intermall'
      : lead.status === 'token'
      ? 'token'
      : lead.status && lead.status.toLowerCase() === 'pending_token'
      ? 'pending_token'
      : lead.status === 'follow_up' || lead.status === 'in_progress' || lead.status === 'meeting'
      ? 'nurture'
      : lead.status === 'open'
      ? 'open'
      : lead.status && lead.status.toLowerCase() === 'payment'
      ? 'payment'
      : lead.status === 'closed_won'
      ? 'closed_won'
      : ''
    : ''
}

export const leadStatusColor = (status) => {
  let background = ''
  let textColor = ''
  switch (removeExtrachar(status)) {
    case 'open':
      background = '#ceecfc'
      textColor = '#28566a'
      break
    case 'followup':
    case 'meeting':
    case 'propsure':
    case 'viewing':
    case 'offer':
    case 'shortlisting':
    case 'inprogress':
      background = '#fef3c6'
      textColor = '#916121'
      break
    case 'closedlost':
    case 'refund':
    case 'buyback':
    case 'intermalladjustment':
      background = '#fde0e2'
      textColor = '#7a3635'
      break
    case 'token':
      background = '#fce7ce'
      textColor = '#6a5628'
      break
    case 'pendingtoken':
      background = '#fce7ce'
      textColor = '#6a5628'
      break
    case 'closedwon':
      background = '#caf4a4'
      textColor = '#536d3a'
      break
    case 'payment':
      background = '#cefce2'
      textColor = '#286a4f'
      break
  }
  return {
    background,
    textColor,
  }
}

export const canAssignWantedLead = (lead) =>
  lead.armsStatus !== 'rejected' &&
  lead.armsStatus !== 'assigned' &&
  getPermissionValue(PermissionFeatures.WANTED_LEADS, PermissionActions.UPDATE)

export const convertWantedLead = (lead, callback) => {
  let missingRequiredFields = missingFieldHtml(lead)

  if (missingRequiredFields.length) {
    Swal.fire({
      title: '',
      html: `<p class='text-left mb-1 font-weight-bold'>Following mandatory field(s) are required before leads assignment:</p>${missingRequiredFields}`,
      icon: 'error',
      width: 650,
    })
  } else {
    axios
      .post(`${config.apiPath}/api/wanted/convert-to-lead/${lead.id}`)
      .then((data) => {
        if (data.data.type === 'success') {
          showToastMsg('Lead has been assigned successfully', 'success')
          callback(true)
        } else {
          showToastMsg("Error occurred! Lead couldn't be assigned", 'error')
          callback(false)
        }
      })
      .catch((error) => {
        let msg = "Error occurred. Lead couldn't be assigned"
        showToastMsg(
          (error.response && error.response.data && error.response.data.message) || msg,
          'error'
        )
        callback(false)
      })
  }
}
export const fetchAllFormIds = (cb) => {
  axios.get(`${config.apiPath}/api/leads/formIds`).then((res) => {
    let array = []
    res.data &&
      res.data.length &&
      res.data.filter((item) => {
        return array.push({ label: item.form_id, value: item.id })
      })
    cb(array)
  })
}

export const fetchOrigin = (cb) => {
  axios
    .get(`${config.apiPath}/api/leads/project/origins?rcmLead=true`)
    .then((res) => {
      cb && cb(res.data)
    })
    .catch(() => cb && cb([]))
}

export const fetchAllAreasGroup = (cb) => {
  axios
    .get(`${config.apiPath}/api/areas/areaGroups?all=true`)
    .then((res) => {
      var array = []
      res.data &&
        res.data.rows.length > 0 &&
        res.data.rows.filter((item) => {
          return array.push({ label: item.name, value: item.id })
        })
      cb && cb(array)
    })
    .catch(() => cb && cb([]))
}

export const fetchPropertySubTypes = (type) => {
  return properties.subType[type]
    ? properties.subType[type].map((t) => {
        return { label: t.name, value: t.value }
      })
    : []
}

export const fetchServerTime = (cb) => {
  axios
    .get(`${config.apiPath}/api/user/serverTime?fullTime=true`)
    .then((res) => {
      cb && cb(res.data)
    })
    .catch(() => cb([]))
}

export const getLeadTag = (lead, serverTime) => {
  var statusColor = null
  var curDate = moment(serverTime).format('DD')
  var leadDate = moment(lead.assigned_at).format('DD')
  var time = moment.duration(moment(serverTime).diff(moment(lead.assigned_at))).asMinutes()
  time = time.toFixed(0)
  let user_id = localStorage.getItem('user_id')

  if (user_id !== lead && lead.shared_with_armsuser_id) {
    statusColor = 'Referred'
  } else if (lead.shared_with_armsuser_id != null) {
    statusColor = 'shared'
  } else {
    if (curDate === leadDate && lead.status === 'open') {
      if (time < 30) {
        if (lead.readAt != null) {
          // statusColor = 'leadOpen'
        } else {
          statusColor = 'new'
        }
      }
      if (time > 30 && time < 60) {
        statusColor = 'delay__30__60'
      }
      if (time > 60) {
        statusColor = 'delay__60__above'
      }
    } else {
      if (lead.status === 'open') {
        if (time < 30) {
          if (lead.readAt != null) {
            // statusColor = 'leadOpen'
          } else {
            statusColor = 'new'
          }
        }
        if (time > 30 && time < 60) {
          statusColor = 'delay__30__60'
        }
        if (time > 60) {
          statusColor = 'delay__60__above'
        }
      }
    }
  }

  return statusColor
}
