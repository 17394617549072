/** @format */

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import React, { useState } from 'react'
import Upload from '../../../images/upload.png'
import AttachedModalDetail from '../../AttachedModalDetail'
import Swal from 'sweetalert2'
import _ from 'lodash'
import axios from 'axios'
import config from '../../../config'
import { toast } from 'react-toastify'
import { showToastMsg } from '../../../utils/helper'
const ReferenceGuideModal = (props) => {
  const [showOverlay, setShowOverlay] = useState(false)
  const [showProgressBar, setShowProgressBar] = useState(false)
  const [attachmentLoader, setAttachmentLoader] = useState(false)
  const [progressInfos, setProgressInfos] = useState([])
  const [selectedFiles, setSelectedFiles] = useState([])
  const [disabledButton, setDisabledButton] = useState(false)
  const [guideRefNo, setGuideRefNo] = useState('')
  const [submitting, setSubmitting] = useState(false)
  let unique = _.uniqBy(selectedFiles, 'name')

  const dragOver = (e) => {
    e.preventDefault()
  }
  const dragEnter = (e) => {
    e.preventDefault()
    setShowOverlay(true)
  }

  const dragLeave = (e) => {
    e.preventDefault()
  }

  const fileDrop = (e) => {
    e.preventDefault()
    const files = e.dataTransfer.files
    if (files.length > 10) {
      Swal.fire('Warning !', 'Maximum files upload limit is 10', 'warning')
      return false
    }
    if (files.length) {
      handleFiles(files)
    }
  }

  const handleFiles = (files) => {
    for (let i = 0; i < files.length; i++) {
      if (validateFile(files[i])) {
        setSelectedFiles([...selectedFiles, files[i]])
        setShowOverlay(false)
      }
    }
  }

  const addDocuments = (selectedFiles) => {
    setSubmitting(true)
    const leadId = props.leadId
    setShowProgressBar(true)
    setDisabledButton(true)
    setAttachmentLoader(true)

    let promises = []

    let bodyFormData = new FormData()
    for (let i = 0; i < selectedFiles.length; i++) {
      bodyFormData.append('files[]', selectedFiles[i])
    }

    const referenceNumberUrl = `${config.apiPath}/api/diary/bulkGuideAttachments?cmLeadId=${leadId}&guideReference=${guideRefNo}`
    axios.post(referenceNumberUrl, bodyFormData).then((response) => {
      if (response && response.data.status) {
        setSubmitting(false)
        setShowProgressBar(false)
        setDisabledButton(false)
        setSelectedFiles([])
        setAttachmentLoader(false)
        showToastMsg('Guide Reference # has been added successfully', 'success')
        props.callBack()
      } else {
        setSubmitting(false)
        showToastMsg(response.data.message, 'error')
      }
    })
  }

  const validateFile = (file) => {
    const validTypes = [
      'image/jpeg',
      'image/jpg',
      'image/png',
      'image/gif',
      'image/x-icon',
      'image/bmp',
      'application/pdf',
      'application/vnd.openxmlformats-officedocument.wordml.document',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'text/csv',
    ]
    if (validTypes.indexOf(file.type) === -1) {
      showToastMsg('The file format is not supported', 'success')
      setShowOverlay(false)
      return false
    }
    return true
  }

  const addManualAttachments = (event) => {
    const files = Array.from(event.target.files)

    if (files.length > 10) {
      Swal.fire('Warning !', 'Maximum files upload limit is 10', 'warning')
      return false
    }

    files.forEach((file) => {
      if (validateFile(file)) {
        file.percentage = 0
        setSelectedFiles([...selectedFiles, file])
      }
    })
  }

  const removeSpecificData = (value) => {
    let index = _.findIndex(selectedFiles, { name: value })
    selectedFiles.splice(index, 1)
    setSelectedFiles([...selectedFiles])
  }

  return (
    <Modal
      isOpen={props.guideReferenceModal}
      toggle={() => {
        if (!submitting) props.setGuideReferenceModal(false)
      }}
      size="lg"
      style={{ maxWidth: '700px', width: '100%' }}
      contentClassName="general-popup-normal-size task-modal"
    >
      <ModalBody>
        <div className="close-icon-container">
          <i
            className="mt-1 fal fa-times-circle close-icon"
            onClick={() => {
              if (!submitting) props.setGuideReferenceModal(false)
            }}
          ></i>
        </div>
        <div className="attachment-main-container">
          <div className="attachment-modal-header">
            <div className="attachment-header-title">Investment Guide Detail</div>
          </div>
          <div>
            <div className="main-attachment-body">
              <div className="row">
                <div className="col-sm-12">
                  <input
                    type="text"
                    value={guideRefNo}
                    className="task-input-container"
                    placeholder="Investment Guide Reference #"
                    onChange={(e) => setGuideRefNo(e.target.value)}
                  />
                </div>
              </div>
              <br />
              <div
                className={
                  showOverlay ? 'attachment-body-edges dropdown-overlay' : 'attachment-body-edges'
                }
                onDragOver={dragOver}
                onDragEnter={dragEnter}
                onDragLeave={dragLeave}
                onDrop={fileDrop}
              >
                {showOverlay ? (
                  <div className="drag-drop-overlay-text">Drop Here :) </div>
                ) : (
                  <div className="attachment-body">
                    <img src={Upload} className="upload-icon-size" />
                    <div className="drag-drop-text">Drag and Drop Here</div>
                    <div className="drag-drop-text">Or</div>
                    <div>
                      <input
                        type="file"
                        name="uploadfile"
                        id="img"
                        style={{ display: 'none' }}
                        multiple="multiple"
                        onChange={addManualAttachments}
                      />
                      <label htmlFor="img" className="attached-file-text">
                        Browse Files
                      </label>
                    </div>
                    <div className="drag-drop-text">Max Upload Size: 10mb</div>
                  </div>
                )}
              </div>
            </div>

            {showProgressBar
              ? progressInfos &&
                progressInfos.map((progressInfo, index) => (
                  <div className="mb-2 mt-2" key={index}>
                    <span>{progressInfo.name}</span>
                    <div className="progress">
                      <div
                        className="progress-bar progress-bar-info"
                        role="progressbar"
                        aria-valuenow={progressInfo.percentage}
                        aria-valuemin="0"
                        aria-valuemax="100"
                        style={{ width: progressInfo.percentage + '%' }}
                      >
                        {progressInfo.percentage}%
                      </div>
                    </div>
                  </div>
                ))
              : unique &&
                unique.length > 0 && (
                  <div className="attached-container" style={{ height: 'calc(100vh - 525px)' }}>
                    {unique &&
                      unique.map((item, index) => (
                        <AttachedModalDetail
                          key={index}
                          item={item}
                          removeData={(value) => removeSpecificData(value)}
                        />
                      ))}
                  </div>
                )}

            <div className="attachment-upload-container mt-3">
              <button
                className={'py-2 px-4'}
                style={{
                  backgroundColor: '#016ff2',
                  color: '#fff',
                  borderRadius: 50,
                  minWidth: 150,
                  border: 'none',
                }}
                onClick={() => {
                  if (!submitting) {
                    addDocuments(unique)
                  }
                }}
                // disabled={!guideRefNo}
              >
                {submitting ? (
                  <span className="fa fa-circle-o-notch fa-spin"></span>
                ) : unique && unique.length > 0 ? (
                  'Upload and Continue'
                ) : (
                  'Continue'
                )}
              </button>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ReferenceGuideModal
