/** @format */

import React, { useEffect, useState } from 'react'
import config from '../../config'
import axios from 'axios'
import { DateRangePicker } from 'react-dates'
import moment from 'moment/moment'
import Load from '../../components/Loader'

const AgentStatsComponent = (props) => {
  const { isSupervisor, id, project_id } = props
  const [focusedInputForIncoming, setFocusedInputForIncoming] = useState(null)
  const [startDate, setStartDate] = useState(moment())
  const [endDate, setEndDate] = useState(moment())
  const [agentStats, setAgentStats] = useState({})
  const [loader, setLoader] = useState(true)

  const getAgentStats = async (startDate, endDate) => {
    try {
      setLoader(true)
      const data = {
        startDate: moment(startDate).format('YYYY-MM-DD'),
        endDate: moment(endDate).format('YYYY-MM-DD'),
        isSupervisor: isSupervisor,
      }
      if (id) {
        data.id = id
      }
      if (project_id) {
        data.project_id = project_id
      }
      const response = await axios.post(`${config.apiPath}/api/cdrreport/getAgentCallStats`, data)
      if (response && response.data) {
        setAgentStats(response.data)
        setLoader(false)
      }
    } catch (error) {
      console.error('Error fetching agent stats:', error)
    }
  }
  useEffect(() => {
    getAgentStats(startDate, endDate)
  }, [startDate, endDate, id, project_id])

  return (
    <>
      {loader ? (
        <div className="dash-container br-lead-page-wrap">
          <div className="text-center" style={{ marginTop: 60, marginBottom: 50 }}>
            <Load title="Agent Performances...."></Load>
          </div>
        </div>
      ) : (
        <div className={'call-stats-logs'}>
          <div className="row">
            <div className="col-md-12 header-filter mb-1">
              <label>Date filter:</label>
              <DateRangePicker
                isOutsideRange={() => false}
                startDate={startDate}
                endDate={endDate}
                startDateId="callCenter_date_input_start_for_incoming"
                minimumNights={0}
                endDateId="callCenter_date_input_end_for_incoming"
                onDatesChange={({ startDate: dateStart, endDate: dateEnd }) => {
                  if (dateStart) {
                    setStartDate(dateStart)
                    getAgentStats(dateStart, dateEnd)
                  }
                  if (dateEnd) {
                    setEndDate(dateEnd)
                    getAgentStats(dateStart, dateEnd)
                    props.setStartDateAndEndDataHAndler(startDate, dateEnd)
                  }
                }}
                focusedInput={focusedInputForIncoming}
                onFocusChange={(input) => setFocusedInputForIncoming(input)}
                showClearDates
              />
            </div>
          </div>
          <div>
            <h3>TALK TIME</h3>
          </div>
          <div className="row">
            <div className="col-md-4">
              <CallCard
                cardClass={'bg-default'}
                headerText={'New Customers'}
                bodyText={agentStats.total_new_customers}
              />
            </div>
            <div className="col-md-4">
              <CallCard
                cardClass={'bg-default'}
                headerText={'Total inbound Talk Time'}
                bodyText={secondsToHms(agentStats.total_inbound_talktime)}
              />
            </div>

            <div className="col-md-4">
              <CallCard
                cardClass={'bg-default'}
                headerText={'Total outbound Talk Time'}
                bodyText={secondsToHms(agentStats.total_outbound_talktime)}
              />
            </div>
          </div>

          <div>
            <h3>INBOUND CALL REPORTS</h3>
          </div>

          <div className="row">
            <div className="col-md-3 pr-0">
              <CallCard
                cardClass={'bg-default'}
                headerText={'Total Clients'}
                bodyText={
                  agentStats.inbound && agentStats.inbound.total_inbound_unique
                    ? agentStats.inbound.total_inbound_unique
                    : 0
                }
              />
            </div>
            <div className="col-md-3 pr-0">
              <CallCard
                cardClass={'bg-default'}
                headerText={'Total inbound Calls'}
                bodyText={
                  agentStats.inbound && agentStats.inbound.total_inbound_calls
                    ? agentStats.inbound.total_inbound_calls
                    : 0
                }
              />
            </div>
            <div className="col-md-3 pr-0">
              <CallCard
                cardClass={'bg-default'}
                headerText={'Total Answered Calls'}
                bodyText={
                  agentStats.inbound && agentStats.inbound.total_inbound_answered
                    ? agentStats.inbound.total_inbound_answered
                    : 0
                }
              />
            </div>
            <div className="col-md-3">
              <CallCard
                cardClass={'bg-default'}
                headerText={'Total Unanswered Calls'}
                bodyText={
                  agentStats.inbound && agentStats.inbound.total_inbound_unanswered
                    ? agentStats.inbound.total_inbound_unanswered
                    : 0
                }
              />
            </div>
            <div className="col-md-3 pr-0">
              <CallCard
                cardClass={'bg-default'}
                headerText={'AVG Talk Time'}
                bodyText={secondsToHms(
                  agentStats.inbound && agentStats.inbound.average_talktime_inbound
                    ? agentStats.inbound.average_talktime_inbound
                    : 0
                )}
              />
            </div>
            <div className="col-md-3 pr-0">
              <CallCard
                cardClass={'bg-default'}
                headerText={'AVG Wait Time'}
                bodyText={secondsToHms(
                  agentStats.inbound && agentStats.inbound.average_waittime_inbound
                    ? agentStats.inbound.average_waittime_inbound
                    : 0
                )}
              />
            </div>
            <div className="col-md-3 pr-0">
              <CallCard
                cardClass={'bg-default'}
                headerText={'Answered %'}
                bodyText={
                  agentStats.inbound && agentStats.inbound.inbound_answered_call_percent
                    ? agentStats.inbound.inbound_answered_call_percent
                    : 0
                }
              />
            </div>
          </div>
          <div>
            <h3>OUTBOUND CALL REPORTS</h3>
          </div>
          <div className="row">
            <div className="col-md-3 pr-0">
              <CallCard
                cardClass={'bg-default'}
                headerText={'Total Clients'}
                bodyText={
                  agentStats.outbound && agentStats.outbound.total_outbound_unique
                    ? agentStats.outbound.total_outbound_unique
                    : 0
                }
              />
            </div>
            <div className="col-md-3 pr-0">
              <CallCard
                cardClass={'bg-default'}
                headerText={'Total Calls'}
                bodyText={
                  agentStats.outbound && agentStats.outbound.total_outbound_calls
                    ? agentStats.outbound.total_outbound_calls
                    : 0
                }
              />
            </div>
            <div className="col-md-3 pr-0">
              <CallCard
                cardClass={'bg-default'}
                headerText={'Total Answered Calls'}
                bodyText={
                  agentStats.outbound && agentStats.outbound.total_outbound_answered
                    ? agentStats.outbound.total_outbound_answered
                    : 0
                }
              />
            </div>
            <div className="col-md-3">
              <CallCard
                cardClass={'bg-default'}
                headerText={'Total Unanswered Calls'}
                bodyText={
                  agentStats.outbound && agentStats.outbound.total_outbound_unanswered
                    ? agentStats.outbound.total_outbound_unanswered
                    : 0
                }
              />
            </div>
            <div className="col-md-3 pr-0">
              <CallCard
                cardClass={'bg-default'}
                headerText={'AVG Talk Time'}
                bodyText={secondsToHms(
                  agentStats.outbound && agentStats.outbound.average_talktime_outbound
                    ? agentStats.outbound.average_talktime_outbound
                    : 0
                )}
              />
            </div>
            <div className="col-md-3 pr-0">
              <CallCard
                cardClass={'bg-default'}
                headerText={'AVG Wait Time'}
                bodyText={secondsToHms(
                  agentStats.outbound && agentStats.outbound.average_waittime_outbound
                    ? agentStats.outbound.average_waittime_outbound
                    : 0
                )}
              />
            </div>
            <div className="col-md-3 pr-0">
              <CallCard
                cardClass={'bg-default'}
                headerText={'Answered %'}
                bodyText={
                  agentStats.outbound && agentStats.outbound.outbound_answered_call_percent
                    ? agentStats.outbound.outbound_answered_call_percent
                    : 0
                }
              />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

const CallCard = (props) => {
  return (
    <div className={`mws-class-card card ${props.cardClass} mb-2`}>
      <div className="card-header">{props.headerText}</div>
      <div className="card-body">{props.bodyText}</div>
    </div>
  )
}

const secondsToHms = (d) => {
  d = Number(d)
  var h = Math.floor(d / 3600)
  var m = Math.floor((d % 3600) / 60)
  var s = Math.floor((d % 3600) % 60)
  var hDisplay = h > 0 ? h + (h == 1 ? '' : '') : ''
  var mDisplay = m > 0 ? m + (m == 1 ? '' : '') : ''
  var sDisplay = s > 0 ? s + (s == 1 ? '' : '') : ''
  if (hDisplay.length === 1) {
    hDisplay = '0' + hDisplay
  } else if (hDisplay.length === 0) {
    hDisplay = '00'
  }
  if (mDisplay.length === 1) {
    mDisplay = '0' + mDisplay
  } else if (mDisplay.length === 0) {
    mDisplay = '00'
  }
  if (sDisplay.length === 1) {
    sDisplay = '0' + sDisplay
  } else if (sDisplay.length === 0) {
    sDisplay = '00'
  }
  return `${hDisplay}:${mDisplay}:${sDisplay}`
}
export default AgentStatsComponent
