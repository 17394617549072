export const SET_DIALER_DATA = 'SET_DIALER_DATA'
export const GLOBAL_SOCKET_CALL_CENTER = 'GLOBAL_SOCKET_CALL_CENTER'
export const LOGOUT_SIP_USER = 'LOGOUT_SIP_USER'

export const setDialerData = (agentUserId) => {
  return (dispatch) => {
    dispatch({type: SET_DIALER_DATA, payload: agentUserId})
  }
}
export const logoutSipUser = (userLogout) => {
  return (dispatch) => {
    console.log(userLogout,'ssss')
    dispatch({type: LOGOUT_SIP_USER, payload: userLogout})
  }
}

export const mwsGlobalSocket = (data) => {
  return (dispatch) => {
    dispatch({type: GLOBAL_SOCKET_CALL_CENTER, payload: data})
  }
}
