/** @format */

import React, { useState, useEffect } from 'react'
import getComponent from './ComponentList'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import _ from 'lodash'
import Label from '../Label'

const useStyles = makeStyles({
  cell: (props) => ({
    width: props.cellWidth,
    backgroundColor: props.color,
    minWidth:props.cellMinWidth,
  }),
  header: {
    position: 'relative',
  },
  stickLeft: (props) => ({
    position: 'sticky !important',
    left: 0,
    zIndex: props.isHeader ? 99 : 2,
  }),
  stickRight: (props) => ({
    position: 'sticky !important',
    right: 0,
    zIndex: props.isHeader ? 99 : 2,
  }),
  hideContent: {
    '&>div': {
      opacity: 0,
    },
  },
})

const TableCell = (props) => {
  const classes = useStyles(props)

  const mappingNotFound = (property, text) => {
    const notFoundTemplate = props.template.notFoundTemplate
    if (notFoundTemplate) {
      for (const [key, value] of Object.entries(notFoundTemplate)) {
        if (key == property) return value
      }
    }
    return text
  }

  const constructProps = (template, data) => {
    let tProps = _.cloneDeep(template.props)
    let ignoreProps = template.ignorePropConstruct ? template.ignorePropConstruct : []
    if (!data) return tProps
    for (const property in tProps) {
      if (ignoreProps.indexOf(property) <= -1) {
        if (Array.isArray(tProps[property])) {
          for (let i = 0; i < tProps[property].length; i++) {
            const propData = _.get(data, tProps[property][i])
            tProps[property][i] = propData
              ? propData
              : mappingNotFound(property, tProps[property][i])
          }
        } else if (tProps[property] instanceof Function) {
          //callback should capture property, cellIndex, rowIndex, rowData, columnName
          tProps[property] = tProps[property](
            property,
            props.cellIndex,
            props.rowIndex,
            data,
            props.cellName
          )
        } else {
          const propData = _.get(data, tProps[property])
          tProps[property] = propData ? propData : mappingNotFound(property, tProps[property])
        }
      }
    }
    return tProps
  }

  const getCell = () => {
    if (props.isHeader) {
      return (
        <th
          className={clsx(
            classes.cell,
            classes.header,
            props.stickLeft ? classes.stickLeft : '',
            props.stickRight ? classes.stickRight : '',
            props.cellsClassName,
            props.template.tdClassName,
            props.template.showOnHover ? classes.hideContent : '',
            props.template.showOnHover ? ' showOnHover ' : ''
          )}
          onClick={(event) => {
            props.onCellClick(props.cellIndex, 'header', props.data, event)
          }}
        >
          {getComponent(props.template.component, constructProps(props.template, props.data))}
        </th>
      )
    }
    return (
      <td
        onClick={(event) => {
          props.onCellClick(props.cellIndex, props.rowIndex, props.data, props.cellName, event)
        }}
        className={clsx(
          props.stickLeft ? classes.stickLeft : '',
          props.stickRight ? classes.stickRight : '',
          props.cellsClassName,
          props.template.tdClassName,
          props.template.showOnHover ? classes.hideContent : '',
          props.template.showOnHover ? ' showOnHover ' : '',
          props.template.showOnRowSelect ? ' showOnSelect ' : ''
        )}
      >
        {getComponent(props.template.component, constructProps(props.template, props.data))}
      </td>
    )
  }

  return getCell()
}

export default React.memo(TableCell, (prevProps, nextProps) => {
  return (
    _.isEqual(prevProps.data, nextProps.data) &&
    _.isEqual(
      _.omit(prevProps.template.props, prevProps.template.ignorePropConstruct),
      _.omit(nextProps.template.props, nextProps.template.ignorePropConstruct)
    )
  )
})

TableCell.propTypes = {
  onCellClick: PropTypes.func,
  data: PropTypes.any,
  cellIndex: PropTypes.number,
  rowIndex: PropTypes.number,
  template: PropTypes.object,
  cellsClassName: PropTypes.string,
  stickLeft: PropTypes.bool,
  stickRight: PropTypes.bool,
}

TableCell.defaultProps = {
  onCellClick: () => {},
  cellsClassName: '',
  stickLeft: false,
  stickRight: false,
}
