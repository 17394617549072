/** @format */

import * as ActionTypes from '../actions/leadsource'

const initialState = { fetchFlag: false, fetchRuleFlag: false, agentModalToggle: false }

const LeadSource = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_LEAD_ORG_BEGIN:
      return state
    case ActionTypes.FETCH_LEAD_ORG_SUCCESS:
      return {
        ...state,
        leadOrg: action.payload,
      }
    case ActionTypes.FETCH_LEAD_ORG_FAILED:
      return {
        ...state,
        leadOrg: action.payload,
      }
    case ActionTypes.ADD_RULE_BEGIN:
      return state
    case ActionTypes.ADD_RULE_SUCCESS:
      return {
        ...state,
        rules: action.payload,
      }
    case ActionTypes.ADD_RULE_FAILED:
      return {
        ...state,
        rules: action.payload,
      }
    case ActionTypes.FETCH_RULE_BEGIN:
      return state
    case ActionTypes.FETCH_RULE_BEGIN_FLAG:
      return {
        ...state,
        fetchRuleFlag: action.payload,
      }
    case ActionTypes.FETCH_RULE_SUCCESS_FLAG:
      return {
        ...state,
        fetchRuleFlag: action.payload,
      }
    case ActionTypes.FETCH_RULE_SUCCESS:
      return {
        ...state,
        allRules: action.payload,
        fetchFlag: true,
      }
    case ActionTypes.FETCH_RULE_FAILED:
      return {
        ...state,
        allRules: action.payload,
      }
    case ActionTypes.FETCH_FB_COMPAIGN_BEGIN:
      return state
    case ActionTypes.FETCH_FB_COMPAIGN_BEGIN_FLAG:
      return {
        ...state,
        fbFetchFlag: action.payload,
      }
    case ActionTypes.FETCH_FB_COMPAIGN_SUCCESS:
      return {
        ...state,
        fbCompaigns: action.payload,
      }
    case ActionTypes.FETCH_FB_COMPAIGN_SUCCESS_FLAG:
      return {
        ...state,
        fbFetchFlag: action.payload,
      }
    case ActionTypes.FETCH_FB_COMPAIGN_FAILED:
      return state
    case ActionTypes.FETCH_FB_ACTIVE_COMPAIGN_BEGIN:
      return state
    case ActionTypes.FETCH_FB_ACTIVE_COMPAIGN_BEGIN_FLAG:
      return {
        ...state,
        fbFetchActiveFlag: action.payload,
      }
    case ActionTypes.FETCH_FB_ACTIVE_COMPAIGN_SUCCESS:
      return {
        ...state,
        fbActiveCompaigns: action.payload,
      }
    case ActionTypes.FETCH_FB_ACTIVE_COMPAIGN_SUCCESS_FLAG:
      return {
        ...state,
        fbFetchActiveFlag: action.payload,
      }
    case ActionTypes.FETCH_FB_ACTIVE_COMPAIGN_FAILED:
      return state
    case ActionTypes.FETCH_FB_DEACTIVE_COMPAIGN_BEGIN:
      return state
    case ActionTypes.FETCH_FB_DEACTIVE_COMPAIGN_BEGIN_FLAG:
      return {
        ...state,
        fbFetchDeActiveFlag: action.payload,
      }
    case ActionTypes.FETCH_FB_DEACTIVE_COMPAIGN_SUCCESS:
      return {
        ...state,
        fbDeActiveCompaigns: action.payload,
      }
    case ActionTypes.FETCH_FB_DEACTIVE_COMPAIGN_SUCCESS_FLAG:
      return {
        ...state,
        fbFetchDeActiveFlag: action.payload,
      }
    case ActionTypes.FETCH_FB_DEACTIVE_COMPAIGN_FAILED:
      return state
    case ActionTypes.AGENT_CITY_TOGGLE:
      return {
        ...state,
        agentModalToggle: !state.agentModalToggle,
      }
    default:
      return state
  }
}
export default LeadSource
