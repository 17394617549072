/** @format */
import moment from 'moment'
import { getPermissionValue } from './components/_unlockComponent'
import { PermissionActions, PermissionFeatures } from './utils/permissionConstants'
const { getPermissionByAction } = require('./hooks/Helper')
const CryptoJS = require('crypto-js')

let getPermissions = localStorage.getItem('permissions')
let checkUpdatePermission
let checkCreatePermission
let permissions

if (getPermissions !== null) {
  let bytes = CryptoJS.AES.decrypt(getPermissions, 'secret key 099')
  getPermissions = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
  permissions = getPermissions.armsPermission['Booking Form']

  checkUpdatePermission = getPermissionByAction(permissions, 'Update')
  checkCreatePermission = getPermissionByAction(permissions, 'Create')
}

export const StaticData = {
  filtersName: [
    { value: 'id', label: 'ID' },
    { value: 'name', label: 'Name' },
    { value: 'cnic', label: 'CNIC' },
    { value: 'phone', label: 'Phone Number' },
  ],
  clientManagementList: [
    { label: 'Book Unit', value: 'book_unit', permission: true },
    { label: 'Add Project Lead', value: 'project', permission: getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.PROJECT_LEADS_PAGE_VIEW) ||
        getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.MY_DEALS_PROJECT) },
    { label: 'Add Buy/Rent Lead', value: 'buyrent', permission: getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.BUYRENT_LEADS_PAGE_VIEW) ||
        getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.MY_DEALS_BUY_RENT) },
    { label: 'View Existing leads', value: 'existinglead', permission: true },
    { label: 'Edit Client Details', value: 'edit_client_details', permission: true },
    { label: 'Manage Bank Accounts', value: 'manage_bank_accounts', permission: true },
    { label: 'Manage Next of Kin', value: 'manage_next_of_kin', permission: true },
  ],
  inventoryTableHead: [
    { name: 'ID', width: '4.5' },
    { name: 'Project Name', width: '13' },
    { name: 'Type', width: '6.5' },
    { name: 'Monthly Installments', width: '7' },
    { name: 'Category Charges', width: '13' },
    { name: 'Rent Available', width: '10' },
    { name: 'Custom Fields', width: '10' },
    { name: 'Date Created', width: '10' },
    { name: '# of floors', width: '10' },
    { name: '# of units', width: '10' },
    { name: 'Actions', width: '10' },
  ],
  clientSource: ['Personal Client', 'UAN', 'Walk-In', 'Live Chat', 'Event', 'Others'],
  accountType: ['Personal', 'Joint'],
  filer: ['Yes', 'No'],
  gender: ['Male', 'Female'],
  clientType: ['Dealer', 'EndUser'],
  buyRentLeadActions: [
    { value: 'call', label: 'Call' },
    { value: 'viewing', label: 'Viewing' },
    { value: 'offer', label: 'Offer' },
    { value: 'propsure', label: 'Propsure' },
    { value: 'more_actions', label: 'More Actions' },
  ],
  REAbuyRentLeadActions: [
    { value: 'call', label: 'Call' },
    { value: 'viewing', label: 'Viewing' },
    { value: 'offer', label: 'Offer' },
    { value: 'more_actions', label: 'More Actions' },
  ],
  installmentFrequencey: [
    { label: 'Monthly', value: 'monthly' },
    { label: 'Quarterly', value: 'quarterly' },
    { label: 'Semi Annual', value: 'semi annual' },
  ],
  developerOptions: [
    { label: 'Imarat', value: false },
    { label: 'External', value: true },
  ],
  installmentFrequenceyMonthly: [{ label: 'Monthly', value: 'monthly' }],
  installmentFrequenceyQuarterly: [{ label: 'Quarterly', value: 'quarterly' }],
  investmentDurationOptions: [
    { label: 'Limited', value: 'limited' },
    { label: 'Unlimited', value: 'unlimited' },
  ],
  paymentPlanDuration: {
    sizeUnit: [
      { value: 'sqm', label: 'M²' },
      { value: 'sqft', label: 'Ft' },
      { value: 'sqyd', label: 'Yd²' },
      { value: 'marla', label: 'Marla' },
      { value: 'kanal', label: 'Kanal' },
    ],
    unitRange: {
      marla: [0, 1, 2, 3, 4, 5],
    },
  },
  fullUnit: [{ label: 'Full Payment', value: 'full_payment' }],
  pearlUnitType: [
    { label: 'Full Payment', value: 'full_payment' },
    { label: 'Pearl', value: 'pearl' },
  ],
  productPaymentPlan: [
    { label: 'Full Payment', value: 'full_payment' },
    { label: 'Installments', value: 'installments' },
  ],
  projectPaymentPlan: [
    { label: 'Booking Date', value: 'booking_date' },
    { label: 'Specific Date', value: 'specific_date' },
  ],
  aging: [
    { value: '30', label: '30 - 59 mins' },
    { value: '60', label: '60 mins or above' },
    // { value: 'custom', label: 'Custom' },
  ],
  reservationAmountOption: [
    { label: 'Fixed Amount', value: 'fix_amount' },
    { label: 'Percentage', value: 'percentage' },
  ],
  reSaleOptions: [
    { label: 'Yes', value: 'yes' },
    { label: 'No', value: 'no' },
  ],
  tableHead: [
    { value: 'small', name: 'id' },
    { value: 'medium', name: 'Name' },
    { value: 'medium', name: 'Client Type' },
    { value: 'btMdlong', name: 'Contact #' },
    { value: 'medium', name: 'Email' },
    { value: 'medium', name: 'CNIC/NTN' },
    { value: 'medium', name: 'City' },
    { value: 'btMdlong', name: 'Profession' },
    { value: 'btnLg', name: 'Belongs To' },
    { value: 'btMdlong', name: 'Created Date' },
    { value: 'medium', name: '' },
  ],
  investmentProductTable: [
    { value: 'small', name: 'ID' },
    { value: 'medium', name: 'Name' },
    { value: 'long', name: 'Description' },
    { value: 'medium', name: 'Status' },
    { value: 'long', name: 'Valid Through' },
    { value: 'long', name: 'Projects' },
    { value: 'long', name: 'Reservation Amount' },
    { value: 'medium', name: 'Payment Plan' },
    { value: 'long', name: 'Down Payment' },
    { value: 'long', name: 'Possession Charges' },
    { value: 'extra-long', name: 'Payment Plan Duration' },
    { value: 'extra-long', name: 'Installment Frequency' },
    { value: 'long', name: 'Annual Profit' },
    { value: 'long', name: 'Annual Rent' },
    { value: 'long', name: 'Investment Duration' },
    { value: 'medium', name: 'Discount' },
    { value: 'action', name: 'Actions' },
  ],
  parkingCharges: [
    { value: 'no', label: 'No' },
    { value: 'yes', label: 'Yes' },
  ],
  statusOptionsGiveToAgentBuyer: [
    { value: 'cleared', label: 'Cleared' },
    { value: 'returnToAgent', label: 'Given to Advisor' },
  ],
  statusOptionsGiveToAgentSaller: [
    { value: 'cleared', label: 'Cleared' },
    { value: 'at_property_agent', label: 'Given to Advisor sale' },
  ],
  statusOptionsOnlyGiveToAgent: [{ value: 'returnToAgent', label: 'Given to Advisor' }],
  leagalDocumentTableTh: [
    { value: 'DOCUMENT NAME', label: 'DOCUMENT NAME' },
    { value: 'BUYER/SELLER', label: 'BUYER/SELLER' },
    { value: 'Updated AT', label: 'Submitted AT' },
    { value: 'STATUS', label: 'STATUS' },
    { value: 'ACTIONS', label: 'ACTIONS' },
    { value: 'REQUESTED BY', label: 'REQUESTED BY' },
    { value: 'TEAM', label: 'TEAM' },
    { value: 'Timeline', label: 'Timeline' },
    { value: 'REGION', label: 'REGION' },
    { value: 'ORGANIZATION', label: 'ORGANIZATION' },
    { value: 'CONTACT NUMBER', label: 'CONTACT NUMBER' },
    { value: 'EMAIL', label: 'EMAIL' },
  ],
  legalStatuses: [
    { value: 'completed', label: 'Completed' },
    { value: 'pending', label: 'pending Upload' },
    { value: 'uploaded', label: 'pending' },
    { value: 'pending_legal', label: 'pending Approval' },
    { value: 'rejected', label: 'Rejected' },
    { value: 'approved', label: 'Approved' },
    { value: 'cleared', label: 'Cleared' },
    { value: 'pending_upload', label: 'Pending Upload' },
  ],
  documentNameList: [
    { value: 'sole_multi_agency_agreement', label: 'Sole/Multi Agency Agreement' },
    { value: 'cnic', label: 'CNIC Front' },
    { value: 'cnic_back', label: 'CNIC Back' },
    { value: 'base_property_report_propsure', label: 'Basic Property Report - Propsure' },
    { value: 'service_charge_agreement', label: 'Service Charge Agreement' },
    { value: 'property_report_propsure', label: 'Property Report - Propsure' },
    { value: 'sale_agreement', label: 'Sales Agreement' },
    { value: 'rent_agreement', label: 'Rent Agreement' },
    { value: 'initial_payment_receipt', label: 'Initial Payment Receipt' },
    { value: 'legal_checklist', label: 'Checklist' },
    {
      value: 'police_verification_report_optional',
      label: 'Police Verification Report (Optional)',
    },
    { value: 'disclaimer', label: 'Disclaimer' },
    { value: 'other', label: 'Other Document' },
  ],
  InventoryConfig: [
    // {
    //   label: 'Booking From',
    //   component: 'RenderSelect',
    //   placeholder: 'Select Option',
    //   name: 'booking_from',
    //   createPermission: checkCreatePermission,
    //   updatePermission: checkUpdatePermission,
    // },
    {
      component: 'renderSelectionField',
      placeholder: 'Offer Discount',
      name: 'discount',
      createPermission: checkCreatePermission,
      updatePermission: checkUpdatePermission,
    },
  ],
  ProductType: [
    {
      label: 'Customer Demand Area(sq.ft)',
      component: 'renderCustomArea',
      placeholder: 'Enter..',
      name: 'cust_demand_sq_ft',
      type: 'number',
      message: 'Customer demand area cannot be edited again.',
      messageHeading: 'Please Select Product Type First',
      createPermission: checkCreatePermission,
      updatePermission: checkUpdatePermission,
    },
  ],
  confirmationOptions: [
    { label: 'Graana', value: 'graana' },
    { label: 'Agency21', value: 'agency21' },
  ],
  projectJourneyInventory: [
    { label: 'Project Name', value: 'project', sub_value: 'name' },
    { label: 'Unique code', value: 'unique_code' },
    { label: 'Inventory Type', value: 'project', sub_value: 'project_type' },
    { label: 'Floor', value: 'floor', sub_value: 'floor_name' },
    { label: 'Rooms', value: 'total_rooms' },
    { label: 'Wash rooms', value: 'total_washrooms' },
    { label: 'Category', value: 'category_view',sub_value: 'label' },
    { label: 'Rate per sq.ft', value: 'rate_per_sq_ft' },
    { label: 'Total sq.ft', value: 'total_sq_ft' },
    { label: 'Total Unit Price', value: 'total_unit_price' },
    { label: 'Fractional', value: 'is_fractional' },
    { label: 'Available sq.ft', value: 'available_sqft' },
  ],
  tableHeadListForTimelineTable: [
    { value: 'date', label: 'Date' },
    { value: 'name', label: 'Name' },
    { value: 'old status', label: 'Old Status' },
    { value: 'new status', label: 'New Status' },
    { value: 'task type', label: 'Action Type' },
    { value: 'subject', label: 'Subject' },
    { value: 'comments', label: 'Comments' },
    { value: 'attachment', label: 'Attachment' },
  ],
  buyRentLeadDetailActionList: [
    // { label: 'Scheduled Tasks', value: 'scheduled_task' },
    { label: 'Details', value: 'details' },
    { label: 'Additional Information', value: 'additional_information' },
    { label: 'Refer', value: 'refer' },
    { label: 'Re-Assign', value: 're_assign' },
    // { label: 'Delete', value: 'delete' },
  ],
  buyRentLeadDetailActionListWithCloseWon: [
    // { label: 'Scheduled Tasks', value: 'scheduled_task' },
    { label: 'Details', value: 'details' },
    { label: 'Additional Information', value: 'additional_information' },
    { label: 'Refer', value: 'refer' },
    { label: 'Re-Assign', value: 're_assign' },
    // { label: 'Delete', value: 'delete' },
    { label: 'Close Won', value: 'close_won' },
  ],
  REAbuyRentLeadDetailActionListCloseWon: [
    //{ label: 'Scheduled Tasks', value: 'scheduled_task' },
    { label: 'Close Won', value: 'close_won' },
    { label: 'Call to Advisor', value: 'call_to_agent' },
  ],
  REAbuyRentLeadDetailActionList: [
    //{ label: 'Scheduled Tasks', value: 'scheduled_task' },
    { label: 'Call to Advisor', value: 'call_to_agent' },
  ],


  projectLeadStatus: [
    { label: 'Open', value: 'open' },
    { label: 'Token', value: 'token' },
    { label: 'In Progress', value: 'in_progress' },
    { label: 'Payments', value: 'payment' },
    { label: 'Closed Won', value: 'closed_won' },
    { label: 'Closed Lost', value: 'closed_lost' },
  ],
  buyRentLeadActionList: [
    { label: 'Comment', value: 'comment' },
    { label: 'Geotag', value: 'geo_tag' },
    { label: 'Property Re-Verification', value: 'property_reverification' },
  ],
  buyRentLeadActionListWithDelete: [
    { label: 'Comment', value: 'comment' },
    { label: 'Geotag', value: 'geo_tag' },
    { label: 'Property Re-Verification', value: 'property_reverification' },
    { label: 'Delete', value: 'delete' },
  ],
  buyRentLeadActionARMSList: [
    { label: 'Comment', value: 'comment' },
    { label: 'Geotag', value: 'geo_tag' },
  ],
  facebookFilterData: [
    { value: 'yes', label: 'Yes' },
    { value: 'no', label: 'No' },
  ],
  singleFilterData: [{ value: 'true', label: 'Single Task' }],

  assignToMeFilterData: [{ value: 'true', label: 'Assigned To Me' }],

  projectLeadStatuses: [
    { value: 'all', label: 'All' },
    { value: 'open', label: 'Open' },
    { value: 'in_progress', label: 'In Progress' },
    { value: 'closed_lost', label: 'Closed Lost' },
  ],
  projectDealStatuses: [
    { value: 'all', label: 'All' },
    { value: 'token', label: 'Token' },
    { value: 'payment', label: 'Payments' },
    { value: 'closed_won', label: 'Closed Won' },
    { label: 'Intermall Adjustment', value: 'intermall_adjustment' },
    { label: 'Buy Back', value: 'buyback' },
    { label: 'Refund', value: 'refund' },
  ],
  projectLeadStatusesExcludeClosedWon: [
    { value: 'all', label: 'All' },
    { value: 'open', label: 'Open' },
    { value: 'in-progress', label: 'In Progress' },
    { value: 'token', label: 'Token' },
    { value: 'payment', label: 'Payments' },
    { value: 'closed_lost', label: 'Closed Lost' },
  ],

  projectLeadStatusAdditional: [
    { label: 'Nurture', value: 'nurture' },
    { label: 'Meeting', value: 'meeting' },
  ],
  buyRentLeadStatuses: [
    { value: 'all', label: 'All' },
    { value: 'open', label: 'Open' },
    { value: 'shortlisting', label: 'Shortlisting' },
    { value: 'token', label: 'Token' },
    { value: 'payment', label: 'Payment & Legal' },
    { value: 'closed_won', label: 'Closed Won' },
    { value: 'closed_lost', label: 'Closed Lost' },
  ],
  buyRentLeadStatusesExcludeClosedWon: [
    { value: 'all', label: 'All' },
    { value: 'open', label: 'Open' },
    { value: 'shortlisting', label: 'Shortlisting' },
    { value: 'token', label: 'Token' },
    { value: 'payment', label: 'Payment & Legal' },
    { value: 'closed_lost', label: 'Closed Lost' },
  ],
  leagalFilter: [
    { value: 'pending_legal', label: 'Pending Approval' },
    { value: 'approved', label: 'Approved' },
    { value: 'rejected', label: 'Rejected' },
    { value: 'all', label: 'All' },
  ],
  leagalStatusFilter: [
    { value: 'pending_upload_by_legal', label: 'Pending Upload' },
    { value: 'completed', label: 'Uploaded' },
    { value: 'all', label: 'All' },
  ],
  productSearchFilter: [
    { value: 'name', label: 'Product Name' },
    { value: 'project', label: 'Projects' },
    { value: 'validThrough', label: 'Currently Valid' },
    { value: 'paymentPlan', label: 'Payment Plan' },
  ],
  investFullPaymentMode: [
    { value: 'cash', label: 'Cash' },
    { value: 'cheque', label: 'Cheque' },
    { value: 'discount', label: 'Discount' },
    { value: 'pay-Order', label: 'Pay-Order' },
    { value: 'Remittance', label: 'Remittance' },
    { value: 'bank-Transfer', label: 'Bank-Transfer' },
    { value: 'e-Gateway', label: 'E-Gateway' },
    { value: 'Buy Back Adjustment', label: 'Buy Back Adjustment' },
    { value: 'Rebate Adjustment', label: 'Rebate Adjustment' },
    { value: 'Rent Adjustment', label: 'Rent Adjustment' },
    { value: 'Inter-Mall Adjustment', label: 'Inter-Mall Adjustment' },
    { value: 'asset_adjustment', label: 'Asset Adjustment' },
  ],
  instrumentNumber: [
    { value: 'cheque', label: 'Cheque' },
    { value: 'pay-Order', label: 'Pay-Order' },
    { value: 'bank-Transfer', label: 'Bank-Transfer' },
  ],
  leadStatus: [
    {
      label: 'Active',
      value: 'active',
    },
    {
      label: 'Inter-Mall Adjustment',
      value: 'intermall_adjustment',
    },
    {
      label: 'Buy Back',
      value: 'buyback',
    },
    {
      label: 'Refund',
      value: 'refund',
    },
    {
      label: 'Cancel Booking',
      value: 'cancel_booking',
    },
  ],
  investFullPaymentType: [
    { value: 'token', label: 'Token' },
    { value: 'down payment', label: 'Down Payment' },
    { value: 'full payment', label: 'Full Payment' },
    { value: 'installment', label: 'Installment' },
    { value: 'possession charges', label: 'Possession Charges' },
    { value: 'payment', label: 'Payment' },
    { value: 'tax', label: 'Tax' },
  ],
  paymentAndLegalTypesToken: [{ value: 'token', label: 'Token' }],
  paymentAndLegalTypesPayment: [
    { value: 'down payment', label: 'Down Payment' },
    { value: 'full payment', label: 'Full Payment' },
    { value: 'installment', label: 'Installment' },
    { value: 'possession charges', label: 'Possession Charges' },
    { value: 'payment', label: 'Payment' },
  ],
  paymentAndLegalTypesCommission: [{ value: 'commission', label: 'Commission' }],
  investmentBookingPaymentType: [
    { value: 'token', label: 'Token' },
    { value: 'down payment', label: 'Down Payment' },
    { value: 'full payment', label: 'Full Payment' },
    { value: 'installment', label: 'Installment' },
    { value: 'possession charges', label: 'Possession Charges' },
    { value: 'payment', label: 'Payment' },
    { value: 'tax', label: 'Tax' },
  ],

  graanaPropertiesStatusForRent: [
    { label: 'Available for Rent', value: 'available_for_rent' },
    { label: 'Rented', value: 'rented' },
  ],
  graanaPropertiesStatusForSale: [
    { label: 'Available for Sale', value: 'available_for_sale' },
    { label: 'Sold', value: 'sold' },
  ],
  investmentTaxOption: [{ value: 'tax', label: 'Tax' }],
  investmentPaymentOptions: [
    { value: 'down payment', label: 'Down Payment' },
    { value: 'full payment', label: 'Full Payment' },
    { value: 'installment', label: 'Installment' },
    { value: 'possession charges', label: 'Possession Charges' },
    { value: 'payment', label: 'Payment' },
  ],
  investmentPropsureOption: [{ value: 'propsure_services', label: 'Propsure Services' }],
  InvestmentLeadsSortingOption: [
    { label: 'Newest First', value: '&order=Desc&field=createdAt' },
    { label: 'Newest Last', value: '&order=Asc&field=createdAt' },
    { label: 'Recently Modified First', value: '&order=Desc&field=updatedAt' },
    { label: 'Recently Modified Last', value: '&order=Asc&field=updatedAt' },
    { label: 'Status Based', value: '&order=sort&field=status' },
  ],
  countriesList: [
    { value: '92', label: 'Pakistan' },
    { value: 'afghanistan', label: 'Afghanistan' },
    { value: 'austria', label: 'Austria' },
    { value: 'bangladesh', label: 'Bangladesh' },
    { value: 'brazil', label: 'Brazil' },
    { value: 'canada', label: 'Canada' },
    { value: 'cyprus', label: 'Cyprus' },
    { value: 'iran', label: 'Iran' },
    { value: 'turkey', label: 'Turkey' },
    { value: 'zimbabwe', label: 'Zimbabwe' },
  ],

  priceRange: [
    500000,
    1000000,
    2000000,
    3000000,
    4000000,
    5000000,
    6000000,
    7000000,
    8000000,
    10000000,
    12500000,
    15000000,
    20000000,
    25000000,
    30000000,
    40000000,
    50000000,
    100000000,
    250000000,
    500000000,
    1000000000,
    10000000000,
  ],
  assignedFilterOption: [
    { label: 'Assigned to my team', value: 'added' },
    { label: 'Created by my team', value: 'created' },
    { label: 'Shared', value: 'shared' },
  ],
  followUpOptions: [
    { label: 'Today', value: 'today' },
    { label: 'Tomorrow', value: 'tomorrow' },
  ],
  leadCategories: [
    {
      label: 'Hot',
      value: 'Hot',
      colorCode: '#e63434',
    },
    {
      label: 'Warm',
      value: 'Warm',
      colorCode: '#ff6c00',
    },
    {
      label: 'Cold',
      value: 'Cold',
      colorCode: '#1688d8',
    },
    // {
    //   label: 'Call back',
    //   value: 'Call back',
    //   colorCode: '#61a70b',
    // },
    // {
    //   label: 'Interested to meet',
    //   value: 'Interested to meet',
    //   colorCode: '#61a70b',
    // },
    // {
    //   label: 'Powered off',
    //   value: 'Powered off',
    //   colorCode: '#9a9a9a',
    // },
    // {
    //   label: 'No response',
    //   value: 'No response',
    //   colorCode: '#9a9a9a',
    // },
  ],

}

export const csvFileFormate = (data) => {
  const returnData =
    data &&
    data.length > 0 &&
    data.map((item) => {
      const fileds = item.optional_fields != null ? JSON.parse(item.optional_fields) : null
      const allObjectKeys = fileds && Object.keys(fileds)
      const optionalFields = {}
      allObjectKeys &&
        allObjectKeys != null &&
        allObjectKeys.length > 0 &&
        allObjectKeys.forEach((m) => {
          optionalFields[m] = fileds[m].data != '' ? fileds[m].data : '--'
        })
      return {
        'Project Name': item.project.name,
        'Floor Name': item.floor && item.floor.name,
        'Unit Name': item.name,
        'Unit Type': item.type,
        'Unit Size': item.area,
        ...optionalFields,
        Status: item.bookingStatus,
        Remarks: item.remarks ? item.remarks : '--',
        'Unit Date Created': moment(item.createdAt).format('DD-MM-YYYY (hh:mm A)'),
      }
    })
  return returnData
}


