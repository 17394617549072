/** @format */

import React, { useState, useMemo } from 'react'
import { Modal, ModalBody, Button, ModalHeader, ModalFooter } from 'reactstrap'
import moment from 'moment'
import PDFThumbnail from '../../PDFThumbnail'

import Upload from '../../../images/upload.png'
import AttachedModalDetail from '../../../components/AttachedModalDetail'
import AttachmentIcon from '../../../images/attachment.svg'
import NoAttachment from '../../../images/no-attachment.svg'
import _ from 'lodash'

const AtttachmentModalEditProperty = ({
  attachmentToggleState,
  attachmentToggle,
  leadAttachmentLoader,
  leadAttachments,
  updateHover,
  showCloseIcon,
  deleteAttachment,
  addDocuments,
  dragOver,
  dragEnter,
  dragLeave,
  fileDrop,
  showOverlay,
  showProgressBar,
  progressInfos,
  unique,
  removeSpecificData,
  disabledButton,
  addManualAttachments,
  attachmentLoader,
  checkForRead,
  showAlertToggle,
  onChange,
  watermarkHandler,
  onImageRemove,
  uploadImagesClearFromState,
  imagesLength,
  CheckTotalImages
  
}) => {
  const [showAttachmentContainer, setShowAttachmentContainer] = useState(false)
  const [hoverItemId, setHoverItemId] = useState('')
  const [attachmentId, setAttachmentId] = useState('')
  const [deleteToggle, setDeleteToggle] = useState(false)
  const [waterMarkFlag, setWaterMarkFlag] = useState(false)
  const [imgFrom , setImgFrom] = useState('uploadMoreImages')
  
  let userId = JSON.parse(localStorage.getItem('user_id'))

  useMemo(() => {
    if (!attachmentLoader) {
      setShowAttachmentContainer(false)
    }
  }, [attachmentLoader])

  const deleteToggleFun = (id) => {
    setDeleteToggle(!deleteToggle)
    setAttachmentId(id)
  }

  const waterMarkHandlerInner = ()=>
  {
    setWaterMarkFlag(!waterMarkFlag)
  }

  const extractFileExtension = (str) => {
    let fileExtension = str.split('.').pop()
    return fileExtension
  }


  const totalPendingImgesLength = (currentUploadedImages)=>
  {
    return  (20 - currentUploadedImages)

  }


  return (
    <>
      <Modal
        isOpen={attachmentToggleState}
        toggle={attachmentToggle}
        size="lg"
        style={{ maxWidth: '890px', width: '100%' }}
        contentClassName=" task-modal"
      >
        <ModalBody>
          <div style={{height:"auto"}} className="attachment-main-container">
            <div className="attachment-modal-header">
              <div className="attachment-header-title">
                {showAttachmentContainer ? 'Select Attachment' : 'Select Attachment'}
              </div>
              <i
                className="fal fa-times-circle attachment-close-icon"
                onClick={() => attachmentToggle()}
              ></i>
            </div>

            {leadAttachmentLoader && leadAttachmentLoader ? (
              <i className={`fa fa-circle-o-notch fa-spin`}></i>
            ) : leadAttachments && showAttachmentContainer ? (
              <div className="loaded-attachments-body">
                {leadAttachments && leadAttachments.length > 0 &&
                  leadAttachments.map((item, index) => (
                    <div className=" container-fluid pl-0 mb-4" key={index}>
                      <div className="row pl-0">
                        <div className="col-md-1">
                          <img src={AttachmentIcon} alt="attachment icon" className="icon-size" />
                        </div>
                        <div className="col-md-8" style={{ paddingLeft: 0 }}>
                          <span className="title pr-1">
                            {item &&
                              item.armsuser &&
                              item.armsuser.firstName + ' ' + item.armsuser.lastName}
                          </span>
                          has attached <span className="title pl-1">{item && item.fileName}</span>
                        </div>
                        <div className="col-md-3">
                          <div className="time-attachment-main-container">
                            <span className="time-main-container-text">
                              {moment().format('dddd MMM D, LT')}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div
                        className="image-container"
                        onMouseEnter={() => {
                          setHoverItemId(item.id)
                          updateHover(true)
                        }}
                        onMouseLeave={() => updateHover(false)}
                        onClick={() => window.open(item.value, '_blank')}
                      >
                        {(checkForRead && !checkForRead.showReadOnly) ||
                        (checkForRead && checkForRead.ownLeadReadOnly) ? (
                          showCloseIcon && item.id === hoverItemId ? (
                            <i
                              className="fas fa-times-circle icon-position-attachment"
                              onMouseEnter={() => {
                                updateHover(true)
                              }}
                              onClick={(e) => {
                                e.stopPropagation()
                                deleteToggleFun(item.id)
                              }}
                            ></i>
                          ) : null
                        ) : null}

                        {extractFileExtension(item.value) === 'pdf' ? (
                          <PDFThumbnail file={item.value} timeLine={true} />
                        ) : (
                          <img src={item.value} className="image-size" />
                        )}
                      </div>
                    </div>
                  ))}
              </div>
            ) : null}

            {!showAttachmentContainer ? (
              <>
                <div className="main-attachment-body">
                  <div
                    className={
                      showOverlay
                        ? 'attachment-body-edges dropdown-overlay'
                        : 'attachment-body-edges'
                    }
                    onDragOver={dragOver}
                    onDragEnter={dragEnter}
                    onDragLeave={dragLeave}
                    onDrop={fileDrop}
                    onImageUpload={unique}
                  >
                    {showOverlay ? (
                      <div className="drag-drop-overlay-text">Drop Here :) </div>
                    ) : (
                      <div className="attachment-body">
                        <img src={Upload} className="upload-icon-size" />
                        <div className="drag-drop-text">Drag and Drop Here</div>
                        <div className="drag-drop-text">Or</div>
                        <div>
                          <input
                            type="file"
                            name="uploadfile"
                            id="img"
                            style={{ display: 'none' }}
                            multiple="multiple"
                            onChange={addManualAttachments}
                          />
                          <label htmlFor="img" className="attached-file-text">
                            Browse Files
                          </label>
                        </div>
                        <div className="drag-drop-text">Max Upload Images Limit Is 20</div>
                      </div>
                    )}
                  </div>
                </div>
                { CheckTotalImages >= 20  && <div>
               <p className='mt-2' style={{color:"red"}}>Max Upload Images Limit Reached</p>
               </div>  }    
                {showProgressBar
                  ? progressInfos &&
                    progressInfos.map((progressInfo, index) => (
                      <div className="mb-2 mt-2" key={index}>
                        <span>{progressInfo.name}</span>
                        <div className="progress">
                          <div
                            className="progress-bar progress-bar-info"
                            role="progressbar"
                            aria-valuenow={progressInfo.percentage}
                            aria-valuemin="0"
                            aria-valuemax="100"
                            style={{ width: progressInfo.percentage + '%' }}
                          >
                            {progressInfo.percentage}%
                          </div>
                        </div>
                      </div>
                    ))
                  : unique &&
                    unique.length > 0 && (
                      <div style={{height:"auto"}} className="attached-container">
                        {unique &&
                          unique.map((item, index) => (
                            <AttachedModalDetail
                              key={index}
                              item={item}
                              removeData={(value) => removeSpecificData(value)}
                            />
                          ))}
                      </div>
                    )}
            
            <div  className="popup-body-header-title mt-2 ml-1">
              <span style={{cursor: 'pointer'}}   onClick={() => waterMarkHandlerInner()}>
              <input
              
                type="checkbox"
                className="checkbox-container mr-1"
                checked={waterMarkFlag}
                 onClick={() => waterMarkHandlerInner()}
              />{' '} 
             Apply Watermark
             </span>
            </div>


                <div className="attachment-upload-container">
                  <button
                    className="attachment-outline mr-2"
                    onClick={() => 
                      {
                      attachmentToggle()
                      setShowAttachmentContainer(false)
                      }
                    }
                  >
                    Cancel
                  </button>
                  <button
                    className="attachment-upload-button"
                    onClick={() => {
                      onChange(unique,null,imgFrom)
                      attachmentToggle()
                      watermarkHandler([waterMarkFlag])
                      if (checkForRead && !checkForRead.showReadOnly) {
                        addDocuments(unique)
                        
                      }
                      uploadImagesClearFromState()
                    }}
                    disabled={unique && unique.length === 0}
                  >
                    Upload
                  </button>
                </div>
              </>
            ) : (
              <div
                className="attachment-upload-container"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {leadAttachments && leadAttachments.length === 0 ? (
                  <img src={NoAttachment} className="no-attachment-image" />
                ) : null}

                {
                  <button
                    className="attachment-upload-button"
                    onClick={() => {
                      if (
                        (checkForRead && !checkForRead.showReadOnly) ||
                        (checkForRead && checkForRead.ownLeadReadOnly)
                      ) {
                        setShowAttachmentContainer(true)
                      } else {
                        showAlertToggle()
                      }
                    }}
                  >
                    Add Attachment
                  </button>
                }
              </div>
            )}
          </div>
        </ModalBody>
      </Modal>
      <Modal isOpen={deleteToggle} toggle={deleteToggleFun} dialogClassName={`modal-main-legal`}>
        <ModalHeader>Delete Attachment</ModalHeader>
        <ModalBody>
          <p>Are you sure you want to delete this attachment? This process cannot be undone</p>
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={() => {
              setDeleteToggle(false)
            }}
          >
            Cancel
          </Button>
          <Button
            color="danger"
            onClick={() => {
              deleteAttachment(attachmentId)
              setTimeout(() => {
                setDeleteToggle(false)
              }, 500)
            }}
          >
            Delete
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default AtttachmentModalEditProperty
