/** @format */

import React, { useState, useEffect } from 'react'
import { Modal, ModalBody } from 'reactstrap'
import Button from '../common/Button'
import axios from 'axios'
import config from '../../config'
import CircleLoader from './../../components/CircleLoader'
import Diary from '../../components/common/Diary/Diary'
import { getLeadType } from '../../utils/helper'
import DiaryTaskModal from '../Modals/DiaryTaskModal/DiaryTaskModal'
import _ from 'lodash'

const SlotDetails = (props) => {
  const [diary, setDiary] = useState([])
  const [diaryCount, setDiaryCount] = useState(0)
  const [isLoading, setLoading] = useState(false)
  const [selectedDiary, setSelectedDiary] = useState(null)
  const [showDetails, detailsToggle] = useState(false)
  useEffect(() => {
    if (props.isOpen) {
      setLoading(true)
      let queryParams = ''
      let selectedSlotsSt = _.cloneDeep(props.selectedSlots)
      if (
        selectedSlotsSt &&
        selectedSlotsSt.slots &&
        Array.isArray(selectedSlotsSt.slots) &&
        selectedSlotsSt.slots.length > 0
      ) {
        let selectedSlotIds = selectedSlotsSt.slots.map((s) => s.id)
        let diaryIds = []
        if (selectedSlotIds && selectedSlotIds.length > 0) {
          selectedSlotIds.map((slId) => {
            let thisSlotDiaries = selectedSlotsSt.allDiaries.find((d) => d.slotId === slId)
            if (thisSlotDiaries && thisSlotDiaries.diary && thisSlotDiaries.diary.length > 0) {
              let slDiaryId = thisSlotDiaries.diary.map((slDiary) => slDiary.id)
              diaryIds = [...diaryIds, ...slDiaryId]
            }
          })
        }
        if (diaryIds && diaryIds.length > 0) {
          diaryIds = [...new Set([...diaryIds])]
          queryParams = diaryIds
            .map((dId) => {
              return `diaryId=${dId}`
            })
            .join('&')
        }
      }
      if (!queryParams) {
        queryParams = `fromDate=${props.selectedSlots.startTime}&toDate=${props.selectedSlots.endTime}`
      }

      axios
        .get(
          `${config.apiPath}/api/diary/all?${queryParams}${
            props.userId ? '&userID=' + props.userId : ''
          }`
        )
        .then((res) => {
          setDiary(res.data.rows)
          setDiaryCount(res.data.count)
          setLoading(false)
        })
    }
  }, [props.isOpen])
  const setDiaryAction = (action) => {
    if (action === 'detail') detailsToggle(true)
  }
  return (
    <>
      <Modal
        isOpen={props.isOpen}
        toggle={(toggle) => props.toggle()}
        contentClassName="setup-followup-modal ml-0"
        style={{ maxWidth: 1111, width: '100%' }}
        className="setup-followup-modal"
      >
        <ModalBody>
          <h4>{props.title}</h4>
          <div className="close-icon-container">
            <i className="fal fa-times-circle close-icon" onClick={() => props.toggle()}></i>
          </div>
          <div className={'row mt-3 justify-content-center mb-4'} style={{ height: '520px' }}>
            {isLoading ? (
              <CircleLoader loading={isLoading}></CircleLoader>
            ) : diaryCount <= 0 ? (
              <div
                className="my-3 mx-2 p-3 rounded border"
                style={{ height: 'fit-content', width: '100%' }}
              >
                <div className="text-center">
                  <h5 className="dimgray">
                    There are no task scheduled in the selected time slots.
                  </h5>
                </div>
              </div>
            ) : (
              <div className={'slotDetailsDiary'}>
                <Diary
                  // setActionType={(actionType) => setActionType(actionType)}
                  setDiary={(diary) => setSelectedDiary(diary)}
                  selectedDiaryTask={selectedDiary}
                  // setAction={(action) => setAction(action)}
                  setDiaryAction={(action) => setDiaryAction(action)}
                  // setConnectModal={(toggle) => setConnectModal(toggle)}
                  // setAdditionalDetailsModal={(toggle) => setAdditionalDetailsModal(toggle)}
                  // setReferModal={(toggle) => shareToggle(toggle)}
                  // callAgentsfun={(toggle) => fetchAgents(toggle)}
                  setDiaryModal={() => detailsToggle()}
                  // setDeleteDiary={() => deleteDiary(diary)}
                  data={diary}
                  dataCount={diaryCount}
                  // onChangePage={handleQueryChange}
                  pagination={{ currentPage: 1, pageSize: 100 }}
                  // setNextAction={(action) => setNextAction(action)}
                  isReadOnly={true}
                  diaryMode={'Slot'}
                ></Diary>
              </div>
            )}
          </div>
        </ModalBody>
      </Modal>
      <DiaryTaskModal
        isReadOnly={true}
        taskModalToggle={showDetails}
        leadId={(selectedDiary && selectedDiary.armsLeadId) || ''}
        diary={selectedDiary}
        screen={'diary'}
        setDiary={() => {}}
        leadType={getLeadType(selectedDiary)}
        taskCategory={'simpleTask'}
        diaryAction={'detail'}
        closeTaskModal={() => detailsToggle(!showDetails)}
        fetchDiaryTasks={() => {
          // setPagination(defaultPage)
          // props.fetchDiaryTasks(defaultPage)
        }}
      />
    </>
  )
}
export default SlotDetails
