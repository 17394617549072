/** @format */

import React, {useEffect, useState} from 'react'
import axios from 'axios'
import moment from 'moment'
import {hourFormat} from '../../views/CallCenter/InboundLog'
import config from '../../config'
import incoming_call_miscall from "../../callCenter/icons/incoming_call_miscall.png";
import outcoming_call_miscall from "../../callCenter/icons/outcoming_call_miscall.png";

function RecentLogs(props) {
  const [data, setData] = useState([])
  const [loader, setLoader] = useState(false)

  const getData = async (armUserId) => {
    setData([])
    return new Promise((reslove, reject) => {
      const param = {
        armUserId,
      }
      axios
        .get(
          `${config.apiPath}/api/cdrreport/recentclogs`, {params: param})
        // `http://localhost:3001/api/cdrreport/recentclogs`,
        // { params: param }
        // )
        .then((res) => {
          reslove(res.data)
        })
    })
  }

  useEffect(() => {
    if (props.reloadLog) {
      setLoader(true)
      const loginUserId = localStorage.getItem('user_id')
      getData(loginUserId).then((res) => {
        setData(res)
        setLoader(false)
        props.reloadLogHandle(false)
      })
    }
  }, [props.reloadLog])

  useEffect(() => {
    setLoader(true)
    const loginUserId = localStorage.getItem('user_id')
    getData(loginUserId).then((res) => {
      setData(res)
      setLoader(false)
    })
  }, [])
  return (
    <>
      <ul className='p-2' style={{width: '100%'}}>
        {data ? (
          data.map((call, i) => {
            return (
              <li key={i} className="lsting-logs" style={{display: 'block'}}>
                <div className="row">
                  <div className="col-md-10 pr-0">
                    <div className={'row'}>
                      <div className={'col-md-2  pr-0 pl-0'} style={{textAlign: 'center'}}>
                        <img style={{width: '30px', marginTop: '10px'}}
                             src={call.direction === 'incoming' ? (call.disposition_status === 1 ? props.incomingIcon : incoming_call_miscall) : (call.disposition_status === 0 ?outcoming_call_miscall: props.outgoingIcon )}
                             alt=""/>
                      </div>
                      <div className={'col-md-10 pr-0 pl-0'}>
                        <div style={{lineHeight: '23px', fontSize: '14px'}}>{call.phone_no}</div>
                        <div style={{lineHeight: '23px', fontSize: '14px'}}>{call.call_date_time
                          ? moment(call.call_date_time).format('DD-MM-YYYY (hh:mm A)')
                          : ''}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2 pl-0" style={{lineHeight: '55px'}}>
                    {hourFormat(call.total_seconds)}
                  </div>
                </div>
              </li>
            )
          })
        ) : (
          <li><span>No Recent Record.</span></li>
        )}
      </ul>
    </>
  )
}

export default RecentLogs
