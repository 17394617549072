import React, {useEffect, useState} from "react";
const SECOND = 1000;
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;

export const CallDuration = (props) => {
  const [time, setTime] = useState(0);
  useEffect(() => {
    let i = 0
    const interval = setInterval(
      () => {
        setTime((time)=>(time+1))
      },
      1000,
    );
    return () => {
      props.callDuration(localStorage.getItem('soft_phone_duration'))
      clearInterval(interval)
      localStorage.removeItem('soft_phone_duration')
    };
  }, []);

  useEffect(()=>{
    localStorage.setItem('soft_phone_duration',time)
  },[time])

  const secondsToTime = (e) => {
    const h = Math.floor(e / 3600).toString().padStart(2, '0'),
      m = Math.floor(e % 3600 / 60).toString().padStart(2, '0'),
      s = Math.floor(e % 60).toString().padStart(2, '0');
    return `${m}:${s}`;
  }

  return (
    <span className={'call-duration'}>{secondsToTime(time)}</span>
  );
};
export default CallDuration
