/** @format */

import React, { Component } from 'react'
import SecondaryNav from '../SecondaryNav/index'
import { withRouter, Link, matchPath } from 'react-router-dom'
import { parse } from 'query-string'
import {
  compareName,
  headerAction,
  onClickRedirect,
  isDisabled,
} from '../../utils/redirectFromButton'
import config from '../../config'
import 'bootstrap/dist/js/bootstrap'
import 'bootstrap/dist/css/bootstrap.css'
import './style.scss'
import axios from 'axios'
import WhiteLogo from '../../images/logo-white-arms.svg'
import dairyIcon from '../../images/diary.jpeg'
import TopLogoSmall from '../../images/icons_large/logo-sm.svg'
import { connect } from 'react-redux'
import { fetchAllTeams } from '../../actions/team'
import { fetchProjects } from '../../actions/projects'
import { agentToggle } from '../../actions/leadsource'
import MainMenu from '../HorizontalNav/Menu/MainMenu'
import SubMenu from '../HorizontalNav/Menu/SubMenu'
import QuickFilter from '../HorizontalNav/Filter/QuickFilter'
import Button from '../../components/common/Button'
import { activateDialer, setDiaryMenuAction } from '../../actions/diary'
import { setTerminalUser } from '../../actions/user'
import {
  nav_toggle,
  mouseEnter,
  mouseLeave,
  showArrowEnter,
  showArrowLeave,
  collapseMenu,
  closeCollapse,
} from '../../actions/navToggle'
import { setProjectLeadQuickFilters, setBuyRentQuickFilters } from '../../actions/leads'

import CollapseMenu from '../SecondaryNav/collapseMenuFile'
import { UserInfoFetch } from '../../actions/user'
import { getPermissionValue } from '../_unlockComponent'
import { PermissionActions, PermissionFeatures } from '../../utils/permissionConstants'
import BackIcon from '../../images/back.svg'
import { routeHaveHorizontalNavbar } from '../../utils/helper'
import { setDataSort, setMoreFilter, setQuickFilter } from '../../actions/generalSetting'
import Sort from '../HorizontalNav/Sort/Sort'
import { defaultDataSort, defaultMoreFilterValue } from '../../reducers/generalSetting'
import { diaryCreateTaskToggle } from '../../actions/diary'
import ActionButton from '../common/ActionButton'
import { StaticData } from '../../StaticData'
import General, { RenderLeadStatus } from '../../utils/general'
import ShowDiary from './ShowDiary.js'
import DiaryViewModal from '../../views/Lead/DiaryModal'
import LeadStatusCard from '../common/LeadStatusCard'
import { LeadStatusURL } from '../../utils/constant_data'
import _ from 'lodash'
import Dialer from '../../views/Lead/Diary/Dialer'
import DialerComponent from '../Dialer'
import CryptoJS from "crypto-js";
import {getPermissionByAction} from "../../hooks/Helper";
import dialer_agent from "../Dialer/dailer_agent";

axios.defaults.withCredentials = true
const queryString = require('query-string')

class Layout extends Component {
  constructor(props) {
    super(props)
    const { location } = this.props

    let getPermissions = localStorage.getItem('permissions')
    let isFinance = false;
    try{
      let bytes = CryptoJS.AES.decrypt(getPermissions, 'secret key 099')
      getPermissions = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
      let financePermissions = getPermissions.armsPermission['Finance']
      let role = localStorage.getItem('role')
      if (financePermissions !==undefined){
        isFinance  = !getPermissionByAction(financePermissions, 'Create')
      } else if(role === 'Propsure Call Center'){
        isFinance = true;
      }
    }
    catch (e) {

    }
    this.state = {
      agent: '',
      showDropDown: false,
      isDialerShown: isFinance,
      isCallCenterConnected:false,
      isLiveCallRing:false,
      notificationData: [],
      showMe: true,
      userType: '',
      userProfileData: [],
      userName: '',
      userTitle: '',
      UserSub_role: '',
      showDialer: false,
      setPropertiesType: '',
      sideMenuToggle: false,
      mouseEnterFlag: false,
      isShowDiary: false,
      horizontalMenuItems: routeHaveHorizontalNavbar(location.pathname),
      isAttachmentPageRequest: location.pathname === '/attachments' ? window.location.href : null,
      SortedListForLeadsStatus :[
        { status: "open",count:123 },
        { status: "in_progress",count:123 },
        { status: "shortlisting",count:123 },
        { status: "pending_token",count:123 },
        { status: "token",count:123 },
        { status: "payment",count:123 },
        { status: "closed_lost",count:123 },
        { status: "closed_won",count:123 },
      ]
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      location,
      buyRentQuickFilter,
      projectLeadQuickFilter,
      projectLeadFilters,
      activateDialer,
    } = this.props
    if (prevProps.activateDialer !== activateDialer) {
      this.setState({
        showDialer: activateDialer,
      })
    }
    if (prevProps.location.pathname !== location.pathname) {
      this.setState({ horizontalMenuItems: routeHaveHorizontalNavbar(location.pathname) })

      this.props.setDataSort(defaultDataSort)
      // return buyRentQuickFilter !== undefined || projectLeadQuickFilter !== undefined
      //   ? null
      //   : this.props.setMoreFilter(defaultMoreFilterValue)
      this.props.setProjectLeadQuickFilters({})
      this.props.setBuyRentQuickFilters({})
      // this.props.setDiaryFilters({})
      // this.props.setOverDueFilters({})
      this.props.setMoreFilter({
        ...defaultMoreFilterValue,
        rowData: prevProps && prevProps.moreFilter && prevProps.moreFilter.rowData,
        page:
          projectLeadFilters && projectLeadFilters.id
            ? 1
            : prevProps && prevProps.moreFilter && prevProps.moreFilter.page,
      })
    }
  }

  operation = () => {
    this.setState({
      showMe: !this.state.showMe,
    })
  }

  callback = (path) => {
    const { history } = this.props
    const parsed = queryString.parse(this.props.location.search)

    if (path == '/agentsManagement') {
      this.props.agentToggle()
    }
    if (path == '/createDiaryTask') {
      this.props.diaryCreateTaskToggle()
    }
    if (path == '/propertyDetails') {
      history.push(`/addArmsProperty?id=${parsed.id}&origin=${parsed.origin}&edit=true`)
    }
    if (path == '/armsView') {
      history.push('/addArmsProperty')
    }
  }

  componentDidMount() {
    const {
      location: { search },
    } = this.props
    this.setState({ setPropertiesType: search.split('=')[2] })
    const { isAttachmentPageRequest } = this.state
    // this.userDetails()
    this.props.UserInfoFetch()
    axios.get(`${config.apiPath}/api/imarat/projects`).then((res) => {
      localStorage.setItem('projectData', JSON.stringify(res.data.data))
    })

    if (isAttachmentPageRequest !== null) {
      localStorage.setItem('AttachmentLink', isAttachmentPageRequest)
    }

    // axios.get(`${config.apiPath}/api/leads/gpToken`).then((res) => {
    axios.defaults.headers.common['gptoken'] = ``
    // })

    //this.props.fetchAllTeams()

    var user = localStorage.getItem('user_agency_mgmt')
    var token = user ? JSON.parse(user).data.token : null
    axios.defaults.headers.common['Authorization'] = token

    axios.get(`${config.apiPath}/api/user/me`).then((res) => {
      this.setState({
        userProfileData: res.data,
      })
      localStorage.setItem('user', JSON.stringify(res.data))
    })
    const { pathname } = this.props.location
    const match = matchPath(pathname, { path: '/set-password/:id/:tempToken' })
    if (token === null && !match) {
      this.props.history.push('/logout')
    }

    const handle =
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.state &&
      this.props.history.location.state.check &&
      this.props.history.location.state.check
    const edit =
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.state &&
      this.props.history.location.state.edit &&
      this.props.history.location.state.edit

    const fromAccount =
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.state &&
      this.props.history.location.state.fromAccount &&
      this.props.history.location.state.fromAccount
    const leadId = this.props.match.params.leadId
    if (handle || fromAccount) {
      this.setState({
        checkFromAccounts: true,
      })
    } else if (edit || fromAccount) {
      this.setState({
        checkFromAccountsEdit: true,
      })
    } else {
    }
    this.setState({ isShowDiary: false })
  }

  showBackButton = (currentPageURL, Lead) => {
    let projectLeadURL = `/project-lead-detail/${Lead && Lead.id}`
    let projectDealURL = `/project-deal-detail/${Lead && Lead.id}`
    let buyRentLeadURL = `/buyRentLeadDetails/${Lead && Lead.id}`
    let buyRentLeadDetailURL = `/buy-rent-deail-detail/${Lead && Lead.id}`
    let PropertiesGraanaDetailPage = `/propertyDetails`

    switch (currentPageURL) {
      case projectLeadURL:
        return true
      case buyRentLeadURL:
        return true
      case projectDealURL:
        return true
      case PropertiesGraanaDetailPage:
        return true
      case buyRentLeadDetailURL:
        return true
      default:
        return false
    }
  }

  toggleBackActionButton = (currentPageURL, Lead) => {
    const {
      location: { search },
    } = this.props

    let buyrentPagination = localStorage.getItem('buyrentPagination') || 1
    let projectLeadPagination = localStorage.getItem('projectLeadPagination') || 1
    let projectLeadURL = `/project-lead-detail/${Lead && Lead.id}`
    let projectDealURL = `/project-deal-detail/${Lead && Lead.id}`
    let buyRentLeadURL = `/buyRentLeadDetails/${Lead && Lead.id}`
    let buyRentLeadDetailURL = `/buy-rent-deail-detail/${Lead && Lead.id}`
    let PropertiesGraanaDetailPage = `/propertyDetails`

    let backButtonLink = search.split('=')[2]
    switch (currentPageURL) {
      case projectLeadURL:
        return `/project-lead?page=${projectLeadPagination}&pageSize=${config.pageSize}`
      case buyRentLeadURL:
        return `/buy-rent-lead?page=${buyrentPagination}&pageSize=${config.pageSize}`
      case projectDealURL:
        return `/project-deal?page=1&pageSize=${config.pageSize}`
      case PropertiesGraanaDetailPage:
        return `/${backButtonLink}`
      case buyRentLeadDetailURL:
        return `/buy-rent-deal?page=1&pageSize=${config.pageSize}`
      default:
        return
    }
  }

  renderLeadStatusColor = (status) => {
    switch (status) {
      case 'follow_up':
        return 'shortlisting'
      case 'meeting':
        return 'shortlisting'
      case 'propsure':
        return 'shortlisting'
      case 'viewing':
        return 'shortlisting'
      case 'offer':
        return 'shortlisting'
      default:
        return status
    }
  }

  // userDetails = () => {
  //   axios
  //     .get(`${config.apiPath}/api/user/me`)
  //     .then((res) => {
  //       // if (
  //       //   this.props.location.pathname.includes('diary') ||
  //       //   this.props.location.pathname.includes('overdue')
  //       // ) {
  //       this.props.setTerminalUser(res.data)
  //       // }
  //
  //     })
  //     .catch((error) => console.log(error))
  // }
  RouteTo = (route) => {
    this.props.history.push(route)
  }
  componentWillMount() {
    var user = localStorage.getItem('user_agency_mgmt')
    var token = user ? JSON.parse(user).data.token : null
    axios.defaults.headers.common['Authorization'] = token
    const { pathname } = this.props.location
    const match = matchPath(pathname, { path: '/set-password/:id/:tempToken' })
    if (!match) {
      axios
        .get(`${config.apiPath}/api/user`, { withCredentials: true })
        .then((response) => {})
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.message === 'Token is not valid') {
            this.props.history.push('/logout')
          }
        })
    }

    const handle =
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.state &&
      this.props.history.location.state.check &&
      this.props.history.location.state.check
    const edit =
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.state &&
      this.props.history.location.state.edit &&
      this.props.history.location.state.edit

    const fromAccount =
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.state &&
      this.props.history.location.state.fromAccount &&
      this.props.history.location.state.fromAccount
    const leadId = this.props.match.params.leadId
    if (handle || fromAccount) {
      this.setState({
        checkFromAccounts: true,
      })
    } else if (edit || fromAccount) {
      this.setState({
        checkFromAccountsEdit: true,
      })
    } else {
    }
    dialer_agent().then((result) => {
      if (result.success) {
        this.setState({
          agent: result
        })
      }
    });
  }

  sideBarToggle = () => {
    this.props.nav_toggle()
  }
  handleMouseEnter = () => {
    this.props.mouseEnter()
  }
  handleMouseLeave = () => {
    this.props.mouseLeave()
  }
  showNotification = () => {}
  getBtnPermission = (btnTitle) => {
    let permFeature = null
    let permAction = null
    switch (btnTitle) {
      case '+ Add New User':
        permFeature = PermissionFeatures.USERS
        permAction = PermissionActions.CREATE
        break
      // case '+ Add New Project':
      //   permFeature = PermissionFeatures.INVENTORY
      //   permAction = PermissionActions.CREATE
      //   break
      case '+ Add New Organization':
        permFeature = PermissionFeatures.ORGANIZATION
        permAction = PermissionActions.CREATE
        break

      case '+ Add New Lead':
        permFeature = PermissionFeatures.WEB_PAGES
        permAction = PermissionActions.LEADS_PAGE_VIEW
        break
      case '+ Add Agents':
        permFeature = PermissionFeatures.LMS_AGENTS_MANAGEMENT
        permAction = PermissionActions.CREATE
        break
      case 'Edit Property':
        permFeature = PermissionFeatures.PROPERTIES
        permAction = PermissionActions.UPDATE
        break
      case '+ Add Property':
        permFeature = PermissionFeatures.PROPERTIES
        permAction = PermissionActions.CREATE
        break
      case '+ Add Investment Product':
        permFeature = PermissionFeatures.INVESTMENT_PRODUCTS
        permAction = PermissionActions.CREATE
        break
      case '+ Add New Role':
        permFeature = PermissionFeatures.ROLE
        permAction = PermissionActions.CREATE
        break
      case '+ Add Office Locations':
        permFeature = PermissionFeatures.OFFICE_LOCATIONS
        permAction = PermissionActions.CREATE
        break
      case '+ Add New Team':
        permFeature = PermissionFeatures.OFFICE_LOCATIONS
        permAction = PermissionActions.CREATE
        break
      case '+ Add Area Groups':
        permFeature = PermissionFeatures.AREA_GROUPS
        permAction = PermissionActions.CREATE
        break
      default:
        break
    }
    if (permFeature && permAction) {
      return getPermissionValue(permFeature, permAction)
    }
    return false
  }
  hasReadPermission = (path) => {
    switch (path) {
      case '/diary':
      case '/overdue':
        return getPermissionValue(PermissionFeatures.DIARY, PermissionActions.READ)
      case '/projectLeads':
      case '/project-lead':
        return getPermissionValue(PermissionFeatures.PROJECT_LEADS, PermissionActions.READ)
      case '/buy-rent-lead':
      case '/buyRentLeads':
        return getPermissionValue(PermissionFeatures.BUY_RENT_LEADS, PermissionActions.READ)
      case '/wanted':
        return getPermissionValue(PermissionFeatures.WANTED_LEADS, PermissionActions.READ)
      default:
        return true
    }
  }

  renderStatusContainer = (name, data) => {
    let extractValues = _.filter(data, function (_) {
      return General.MakeShortListing(_.status)
    })

    let object = {
      [name]:
        data &&
        data.map((item) => ({

          ...item,
          isProjectLead: false,
          status: General.MakeShortListing(item.status) ? 'shortlisting' : item.status,
          count: General.MakeShortListing(item.status)
            ? _.sumBy(extractValues, function (__) {
                return __.count
              })
            : item.count,
        })),
    }
    let newArry = [];
    let newArry2 = [];
    newArry = [...object[name]]
    // sorting leads status
    this.state.SortedListForLeadsStatus.filter(o2 => newArry.some(o1 => {if( o1.status === o2.status){newArry2.push(o1)}}));
    return newArry2
  }

  renderProjectLeadStatus = (name, data) => {
    let extractValues = _.filter(data, function (_) {
      return General.MakeProjectLeadListing(_.status)
    })

    let object = {
      [name]:
        data &&
        data.map((item) => ({
          ...item,
          status: item.status,
          isProjectLead: true,
          count: General.MakeProjectLeadListing(item.status)
            ? _.sumBy(extractValues, function (__) {
                return __.count
              })
            : item.count,
        })),
    }

    let newArry = [];
    let newArry2 = [];
    newArry = [...object[name]]
    this.state.SortedListForLeadsStatus.filter(o2 => newArry.some(o1 => {if( o1.status === o2.status){newArry2.push(o1)}}));
    return newArry2
  }

  render() {
    const { pathname } = this.props.location
    const match = matchPath(pathname, { path: '/set-password/:id/:tempToken' })
    const showNave = !(pathname === '/' || pathname === '/login' || match)
    // const showNave =
    const { userName, UserSub_role, userTitle } = {
      userName: this.props.user ? this.props.user.firstName + ' ' + this.props.user.lastName : '',
      userTitle: this.props.user ? this.props.user.title : '',
    }

    const {
      horizontalMenuItems,
      checkFromAccounts,
      checkFromAccountsEdit,
      isShowDiary,
      showDialer,
    } = this.state
    if ((pathname === '/diary' || pathname === '/overdue') && isShowDiary === true)
      this.setState({ isShowDiary: false })
    const {
      showArrow,
      collapseMenu,
      title,
      setMoreFilter,
      moreFilter,
      singleLeadRecord,
      goBackUrl,
      buyRentLeads,
      myLeads,
      deals,
      referredLeads,
      companyLeads,
      deactivatedLeads,
      teamLeads,
      teamDeals,
      projectLeads,
      projectMyLeads,
      projectCompanyLeads,
      projectReferredLead,
      projectDeactivatedLeads,
      projectteamLeadsLeads,
      projectDeals,
      projectReferredDeals,
      projectDeactivatedDeals,
      projectTeamDeals,
    } = this.props

    const initials = userName.toUpperCase().match(/\b\w/g) || []
    const parsed = queryString.parse(this.props.location.search)
    let btnTitle = compareName(pathname, parsed.origin)
    if (!this.getBtnPermission(btnTitle)) {
      btnTitle = null
    }

    const leadId = singleLeadRecord && singleLeadRecord.id
    let buyRentLeadURL = `/buyRentLeadDetails/${leadId}`

    let currentPath = window.location.pathname

    let isLeadStatusShow = LeadStatusURL.includes(currentPath)

    let leadStatusContainer =
      projectDeals && projectDeals.status_count
        ? this.renderProjectLeadStatus('ProjectLeads', projectDeals && projectDeals.status_count)
        : projectReferredDeals && projectReferredDeals.status_count
        ? this.renderProjectLeadStatus(
            'ProjectLeads',
            projectReferredDeals && projectReferredDeals.status_count
          )
        : projectDeactivatedDeals && projectDeactivatedDeals.status_count
        ? this.renderProjectLeadStatus(
            'ProjectLeads',
            projectDeactivatedDeals && projectDeactivatedDeals.status_count
          )
        : projectTeamDeals && projectTeamDeals.status_count
        ? this.renderProjectLeadStatus(
            'ProjectLeads',
            projectTeamDeals && projectTeamDeals.status_count
          )
        : projectteamLeadsLeads && projectteamLeadsLeads.status_count
        ? this.renderProjectLeadStatus(
            'ProjectLeads',
            projectteamLeadsLeads && projectteamLeadsLeads.status_count
          )
        : projectDeactivatedLeads && projectDeactivatedLeads.status_count
        ? this.renderProjectLeadStatus(
            'ProjectLeads',
            projectDeactivatedLeads && projectDeactivatedLeads.status_count
          )
        : projectLeads && projectLeads.status_count
        ? this.renderProjectLeadStatus('ProjectLeads', projectLeads && projectLeads.status_count)
        : projectMyLeads && projectMyLeads.status_count
        ? this.renderProjectLeadStatus(
            'ProjectLeads',
            projectMyLeads && projectMyLeads.status_count
          )
        : projectReferredLead && projectReferredLead.status_count
        ? this.renderProjectLeadStatus(
            'ProjectLeads',
            projectReferredLead && projectReferredLead.status_count
          )
        : projectCompanyLeads && projectCompanyLeads.status_count
        ? this.renderProjectLeadStatus(
            'ProjectLeads',
            projectCompanyLeads && projectCompanyLeads.status_count
          )
        : buyRentLeads && buyRentLeads.status_count
        ? this.renderStatusContainer('buyRentLeads', buyRentLeads && buyRentLeads.status_count)
        : myLeads && myLeads.status_count
        ? this.renderStatusContainer('myLeads', myLeads.status_count)
        : deals && deals.status_count
        ? this.renderStatusContainer('deals', deals && deals.status_count)
        : referredLeads && referredLeads.status_count
        ? this.renderStatusContainer('referredLeads', referredLeads && referredLeads.status_count)
        : companyLeads && companyLeads.status_count
        ? this.renderStatusContainer('companyLeads', companyLeads && companyLeads.status_count)
        : deactivatedLeads && deactivatedLeads.status_count
        ? this.renderStatusContainer(
            'deactivatedLeads',
            deactivatedLeads && deactivatedLeads.status_count
          )
        : teamLeads && teamLeads.status_count
        ? this.renderStatusContainer('teamLeads', teamLeads && teamLeads.status_count)
        : teamDeals && teamDeals.status_count
        ? this.renderStatusContainer('teamDeals', teamDeals && teamDeals.status_count)
        : []
    leadStatusContainer &&
      leadStatusContainer.unshift({
        status: 'total_leads',
        isProjectLead: projectDeactivatedLeads || projectteamLeadsLeads ||
          projectDeals || projectReferredDeals || projectDeactivatedDeals ||
          projectTeamDeals || projectReferredLead || projectLeads || projectMyLeads || projectCompanyLeads ? true : false,
        count:
          projectDeactivatedLeads && projectDeactivatedLeads.count
            ? projectDeactivatedLeads && projectDeactivatedLeads.count
            : projectteamLeadsLeads && projectteamLeadsLeads.count
            ? projectteamLeadsLeads && projectteamLeadsLeads.count
            : projectDeals && projectDeals.count
            ? projectDeals && projectDeals.count
            : projectReferredDeals && projectReferredDeals.count
            ? projectReferredDeals && projectReferredDeals.count
            : projectDeactivatedDeals && projectDeactivatedDeals.count
            ? projectDeactivatedDeals && projectDeactivatedDeals.count
            : projectTeamDeals && projectTeamDeals.count
            ? projectTeamDeals && projectTeamDeals.count
            : projectReferredLead && projectReferredLead.count
            ? projectReferredLead && projectReferredLead.count
            : projectLeads && projectLeads.count
            ? projectLeads && projectLeads.count
            : projectMyLeads && projectMyLeads.count
            ? projectMyLeads && projectMyLeads.count
            : projectCompanyLeads && projectCompanyLeads.count
            ? projectCompanyLeads && projectCompanyLeads.count
            : buyRentLeads && buyRentLeads.count
            ? buyRentLeads && buyRentLeads.count
            : myLeads && myLeads.count
            ? myLeads && myLeads.count
            : deals && deals.count
            ? deals && deals.count
            : referredLeads && referredLeads.count
            ? referredLeads && referredLeads.count
            : companyLeads && companyLeads.count
            ? companyLeads && companyLeads.count
            : deactivatedLeads && deactivatedLeads.count
            ? deactivatedLeads && deactivatedLeads.count
            : teamLeads && teamLeads.count
            ? teamLeads && teamLeads.count
            : teamDeals && teamDeals.count
            ? teamDeals && teamDeals.count
            : null,
      })
    leadStatusContainer = _.uniqBy(leadStatusContainer, function (e) {
      return e.status
    })


    return (
      <>
        {showNave ? (
          <>
            <div className="row bg-light">
              {
                this.state.isDialerShown && (
                  <DialerComponent
                    liveCallRing={(isLiveCall)=>{
                      this.setState({isLiveCallRing:isLiveCall})
                    }}
                    isCallCenterConnected={(isCallCenterConnected)=>{
                      this.setState({isCallCenterConnected:isCallCenterConnected})
                    }}
                    closeDialer={(status) => {
                      this.setState({ showDialer: status })
                      this.props.activateDialerClick(status)
                    }}
                    dialerShow={this.state.showDialer}
                  />
                )
              }
              <div
                className={
                  collapseMenu
                    ? 'col-md-1 img-wraper collapseLeftSideFixedWidth'
                    : 'col-md-2 img-wraper FixedWidth'
                }
              >
                <img src={collapseMenu ? TopLogoSmall : WhiteLogo} />
              </div>
              <div
                className={`${
                  collapseMenu
                    ? 'col-md-8 justify-content-center align-self-center'
                    : 'col-md-7 justify-content-center align-self-center'
                }`}
                style={{
                  borderBottom: '1px solid #eeeeee',
                  padding: title && title.status ? 0 : 13,
                  position: 'relative',
                  left: title && title.status ? 13 : 0,
                  height: 70,
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                {false && horizontalMenuItems.navbar ? (
                  <MainMenu path={this.props.location.pathname} />
                ) : (
                  <div className="row">
                    {this.showBackButton(pathname, singleLeadRecord) ? (
                      <div
                        className="ml-2 back-button-position"
                        onClick={() =>
                          this.props.history.push(
                            goBackUrl
                              ? goBackUrl
                              : checkFromAccounts === true
                              ? `/bookingform?page=1&pageSize=${config.pageSize}`
                              : checkFromAccountsEdit === true
                              ? `/bookingform?page=1&pageSize=${config.pageSize}`
                              : this.toggleBackActionButton(pathname, singleLeadRecord)
                            // goBackUrl
                            //   ? goBackUrl
                            //   : this.toggleBackActionButton(pathname, singleLeadRecord)
                          )
                        }
                      >
                        <span>
                          <img src={BackIcon} alt="back_button" className="back-button-image" />
                          <span className="back-button-text">Go Back</span>
                        </span>
                      </div>
                    ) : null}

                    <div className="col-md-10">
                      <div
                        className={
                          title && title.subtitle ? 'navbar-pro-center' : 'navbar-center-title'
                        }
                      >

                        {title && title.name}
                        {title && title.status && (
                          <span
                            className={
                              buyRentLeadURL === pathname
                                ? 'buyRentLeadTopMenuContainer align-self-center'
                                : 'leadTopMenuStatusContainer align-self-center'
                            }
                            style={{ padding: 0, position: 'relative', top: 0, marginLeft: 18 }}
                          >
                            {
                              <div
                                className={
                                  buyRentLeadURL === pathname
                                    ? this.renderLeadStatusColor(title && title.status)
                                    : title && title.status === 'meeting'
                                    ? 'meeting p-2-1'
                                    : (title && title.status === 'closed_lost') ||
                                      (title && title.status === 'refund') ||
                                      (title && title.status === 'buyback')
                                    ? 'closed_lost p-2-1'
                                    : title && title.status === 'intermall adjustment'
                                    ? 'intermall'
                                    : title && title.status === 'token'
                                    ? 'token p-2-1'
                                    : title && title.status === 'pending_token'
                                    ? 'pending_token p-2-1'
                                    : title && title.status === 'follow_up'
                                    ? 'nurture p-2-1'
                                    : title && title.status === 'open'
                                    ? 'open p-2-1'
                                    : title && title.status === 'payment'
                                    ? 'payment p-2-1'
                                    : title && title.status === 'closed_won'
                                    ? 'closed_won p-2-1'
                                    : title && title.status === 'offer'
                                    ? 'offer p-2-1'
                                    : title && title.status === 'propsure'
                                    ? 'propsure p-2-1'
                                    : title && title.status === 'viewing'
                                    ? 'viewing p-2-1'
                                    : title && title.status === 'in_progress'
                                    ? 'nurture p-2-1'
                                    : null
                                }
                              >
                                {RenderLeadStatus(
                                  title && title.status,
                                  StaticData.projectLeadStatus
                                )}
                                {/* {title && title.status === 'follow_up'
                                  ? 'Nurture'
                                  : title && title.status.replace(/_/g, ' ')} */}
                              </div>
                            }
                          </span>
                        )}
                        {/*want to check there  */}
                        {/* {btnTitle !== null ? (
                        <button
                          className="newFormButton"
                          type="submit"
                          onClick={() => onClickRedirect(pathname, this.props, this.callback)}
                          style={{ marginRight: '10px' }}
                        >
                          {btnTitle}
                        </button>
                      ) : (
                        ''
                      )} */}
                        {compareName(pathname, parsed.origin) !== null && btnTitle !== null ? (
                          <button
                            className="newFormButton"
                            type="submit"
                            onClick={() => onClickRedirect(pathname, this.props, this.callback)}
                            style={{ marginRight: '10px' }}
                          >
                            {compareName(pathname, parsed.origin)}
                          </button>
                        ) : (
                          ''
                        )}
                      </div>
                      {title && title.subtitle ? (
                        <div className="navbar-subtitle">{title && title.subtitle}</div>
                      ) : null}
                    </div>
                  </div>
                )}
              </div>

              <div
                className="col-md-3 userProfile text-right"
                style={{ borderBottom: '1px solid #eeeeee' }}
              >
                <Link className="custom-link" to={'/userEditInfo'}>
                  <div className="tooltip-wrap" style={{ marginLeft: 90 }}>
                    <p>Edit Profile</p>
                  </div>
                </Link>
                <div className="main-user-wrap">
                  <div className="user-img-wrap">
                    <div className="cirle" style={{ backgroundColor: 'rgb(4 110 242)' }}>
                      <h6 className="circle-text">
                        {initials && initials[0] && initials[1]
                          ? `${initials[0]} ${initials[1]}`
                          : []}
                      </h6>
                    </div>
                  </div>
                  <div className="info-wrap">
                    <h6 className="userName">{userName}</h6>
                    <p className="designation">
                      {/*{UserSub_role && UserSub_role !== null && UserSub_role == 'propsure_qa'
                          ? 'QA Executive'
                          : UserSub_role && UserSub_role !== null
                          ? UserSub_role.replace('_', ' ')
                          : null}*/}
                      {userTitle && userTitle !== null ? userTitle : ''}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className={'dialer-top-icons'}>
                {pathname !== '/diary' &&
                  pathname !== '/overdue' &&
                  getPermissionValue(PermissionFeatures.DIARY, PermissionActions.READ) && (
                    <div className={`diaryIconContainer${isShowDiary ? ' diaryActive' : ''}`}>
                      {/*<img*/}
                      {/*onClick={() => {*/}
                      {/*  this.setState({ isShowDiary: !isShowDiary })*/}
                      {/*}}*/}
                      {/*className='adDairyicon' src={collapseMenu ? TopLogoSmall : dairyIcon} />*/}
                      <i
                        className="fal fa-book"
                        aria-hidden="true"
                        onClick={() => {
                          this.setState({ isShowDiary: !isShowDiary })
                        }}
                      ></i>
                    </div>
                  )}
                {(!this.state.showDialer && this.state.isDialerShown && this.state.agent.agentServer && this.state.agent.agentUser && this.state.agent.agentPassword) && (
                  <div className={`diaryIconContainer phone-icons-mws ${this.state.isCallCenterConnected?'call_connected':'call_disconnected'}`}>
                    <i
                      onClick={() => {
                        this.setState({ showDialer: true })
                        try{
                          document.body.classList.add('dialer-open');
                        }catch (e) {

                        }

                      }}
                      style={{color:(this.state.isCallCenterConnected?'green':'black')}}
                      className={(this.state.isLiveCallRing)?'fal fa-phone': 'fal fa-phone'}
                    />
                  </div>
                )}
              </div>
              <div
                className={
                  collapseMenu
                    ? 'col-md-1 left-menu-container collapseLeftSideFixedWidth'
                    : 'col-md-2 left-menu-container FixedWidth'
                }
                onMouseEnter={() => this.props.showArrowEnter()}
                onMouseLeave={() => this.props.showArrowLeave()}
              >
                {collapseMenu ? (
                  <>
                    {showArrow && (
                      <div className="row">
                        <div
                          className="btn-wrapper-right"
                          onClick={() => this.props.closeCollapse()}
                        >
                          <i className="fa fa-angle-right" aria-hidden="true"></i>
                        </div>
                      </div>
                    )}{' '}
                    <CollapseMenu />
                  </>
                ) : (
                  <>
                    {showArrow && (
                      <div className="row">
                        <div className="btn-wrapper" onClick={() => this.props.setCollapseFun()}>
                          <i className="fa fa-angle-left" aria-hidden="true"></i>
                        </div>
                      </div>
                    )}

                    <SecondaryNav />
                  </>
                )}

                {collapseMenu ? (
                  <>
                    <div className="row" style={{ paddingLeft: 12 }}>
                      <div
                        className="collapseLogoutContainer"
                        onClick={() => this.props.history.push('/logout')}
                      >
                        <div className="mainContainer collapseMenuLogout">
                          <i className="fal fa-sign-out-alt iconSize"></i>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="row">
                    <div
                      className="logout-container "
                      onClick={() => this.props.history.push('/logout')}
                    >
                      <div className="row" style={{ paddingLeft: 20 }}>
                        <div className="col-md-7">
                          <div className="logout-icon-container">
                            <i className="fad fa-sign-out-alt"></i>
                            <span className="pl-2">Logout</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div
                className={`${
                  collapseMenu
                    ? 'col-md-11 collapseMenu collapseLeftSideFixedRightWidth'
                    : 'col-md-10 nonCollapseSide'
                }`}
                // onMouseEnter={() => this.props.mouseLeave()}
              >
                {isLeadStatusShow && leadStatusContainer.length > 1 && (
                  <div className="lead-status-container">
                    {leadStatusContainer &&
                      leadStatusContainer.map((item, index) => (
                        <LeadStatusCard key={index} item={item}  pageType={this.props.location.pathname}/>
                      ))}
                  </div>
                )}

                {horizontalMenuItems.moreFilter && (
                  <div
                    className={'d-flex justify-content-between align-items-center my-2'}
                    style={{
                      marginLeft: -7,
                      fontSize: 14,
                      height: 60,
                    }}
                  >
                    <div style={{ paddingLeft: 7 }}>
                      <SubMenu path={this.props.location.pathname} />
                    </div>
                    {this.hasReadPermission(pathname) && (
                      <>
                        <div className={'p-0'}>
                          <QuickFilter path={this.props.location.pathname} />
                        </div>
                        <div className={'d-flex align-items-center justify-content-end pr-2'}>
                          {horizontalMenuItems.sort && (
                            <div className={'mr-3'} style={{ fontSize: 20 }}>
                              <Sort path={this.props.location.pathname} />
                            </div>
                          )}
                          {horizontalMenuItems.moreFilter && (
                            <div>
                              <button
                                style={{ padding: '6px 12px' }}
                                className={`more-filter-btn mr-2 ${
                                  moreFilter.activeFilter ? 'active' : ''
                                }`}
                                onClick={() =>
                                  setMoreFilter({
                                    ...moreFilter,
                                    showModal: true,
                                  })
                                }
                              >
                                <i className={'fa fa-filter ml-0'}></i>&nbsp; More Filters &nbsp;
                                {moreFilter.activeFilter && (
                                  <span>{moreFilter.activeFilter && moreFilter.filterCount}</span>
                                )}
                              </button>
                            </div>
                          )}
                          {compareName(pathname, parsed.origin) !== null ? (
                            <Button
                              style={{
                                padding: '6px 12px',
                                border: '1px solid #1173ef',
                                whiteSpace: 'nowrap',
                              }}
                              textColor={'white'}
                              onClick={() => onClickRedirect(pathname, this.props, this.callback)}
                              text={compareName(pathname, parsed.origin)}
                              disabled={isDisabled(pathname, this.props.diaryProps)}
                            />
                          ) : (
                            ''
                          )}
                          {headerAction(pathname) != null ? (
                            <ActionButton
                              orientation="left"
                              actionItems={headerAction(
                                pathname,
                                (payload) => {
                                  this.props.setDiaryMenuAction(payload)
                                },
                                this.props.isTerminalUser
                              )}
                              iconClassName={'navBarIcon'}
                            ></ActionButton>
                          ) : (
                            ''
                          )}
                        </div>
                      </>
                    )}
                  </div>
                )}
                {this.props.children}
              </div>
            </div>
            <div className={`diarySlider`}>
              {pathname !== '/diary' && pathname !== '/overdue' && (
                <div className={`${isShowDiary === true ? 'displayDiary row' : 'hideDiary'}`}>
                  <div className=" col-12">
                    <i
                      className="close fa-times-circle fal"
                      style={{ cursor: 'pointer', opacity: 1 }}
                      onClick={() => {
                        this.setState({ isShowDiary: !isShowDiary })
                      }}
                    ></i>
                    <DiaryViewModal isOverdue={false} isShowDiary={isShowDiary} />
                  </div>
                </div>
              )}
            </div>
          </>
        ) : (
          <React.Fragment>{this.props.children}</React.Fragment>
        )}
      </>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const urlParams = new URLSearchParams(ownProps.location.search)
  return {
    ...urlParams,
    sideNavToggle: state.navToggle.sideBarToggle,
    mouseEnterFlag: state.navToggle.mouseEnterFlag,
    showArrow: state.navToggle.showArrow,
    collapseMenu: state.navToggle.collapseMenu,
    title: state.GeneralSetting.title,
    agentModalToggle: state.LeadSource.agentModalToggle,
    createTaskModalToggle: state.Diary.createTaskModalToggle,
    moreFilter: state.GeneralSetting.moreFilter,
    singleLeadRecord: state.Leads.singleLeadRecord,
    buyRentQuickFilter: state.Leads.buyRentQuickFilter,
    projectLeadQuickFilter: state.Leads.projectLeadQuickFilter,
    diaryProps: state.Diary,
    activateDialer: state.Diary.activateDialer,
    isTerminalUser: state.user.isTerminalUser,
    user: state.user.userInfo,
    goBackUrl: state.GeneralSetting.backUrl,
    projectLeadFilters: state.ProjectLeads.filters.filters,
    buyRentLeads: state.BuyRentLeads.leads,
    myLeads: state.BuyRentLeads.myLeads,
    deals: state.BuyRentLeads.deals,
    referredLeads: state.BuyRentLeads.referredLeads,
    companyLeads: state.BuyRentLeads.companyLeads,
    deactivatedLeads: state.BuyRentLeads.deactivatedLeads,
    teamLeads: state.BuyRentLeads.teamLeads,
    teamDeals: state.BuyRentLeads.teamDeals,
    projectLeads: state.ProjectLeads.leads,
    projectMyLeads: state.ProjectLeads.myLeads,
    projectCompanyLeads: state.ProjectLeads.companyLeads,
    projectReferredLead: state.ProjectLeads.referredLeads,
    projectDeactivatedLeads: state.ProjectLeads.deactivatedLeads,
    projectteamLeadsLeads: state.ProjectLeads.teamLeads,
    projectDeals: state.ProjectLeads.deals,
    projectReferredDeals: state.ProjectLeads.referredDeals,
    projectDeactivatedDeals: state.ProjectLeads.deactivatedDeals,
    projectTeamDeals: state.ProjectLeads.teamDeals,
  }
}
export const mapDispatchToProps = (dispatch) => {
  return {
    activateDialerClick: (data) => dispatch(activateDialer(data)),
    fetchProjects: () => dispatch(fetchProjects()),
    setCollapseFun: () => dispatch(collapseMenu()),
    nav_toggle: () => dispatch(nav_toggle()),

    mouseEnter: () => {},
    mouseLeave: () => {},
    showArrowEnter: () => dispatch(showArrowEnter()),
    showArrowLeave: () => dispatch(showArrowLeave()),
    closeCollapse: () => dispatch(closeCollapse()),
    UserInfoFetch: () => dispatch(UserInfoFetch()),
    agentToggle: () => dispatch(agentToggle()),
    diaryCreateTaskToggle: () => dispatch(diaryCreateTaskToggle()),
    setMoreFilter: (moreFilter) => dispatch(setMoreFilter(moreFilter)),
    setQuickFilter: (quickFilter) => dispatch(setQuickFilter(quickFilter)),
    setDataSort: (dataSort) => dispatch(setDataSort(dataSort)),
    setDiaryMenuAction: (action) => dispatch(setDiaryMenuAction(action)),
    setProjectLeadQuickFilters: (filters) => dispatch(setProjectLeadQuickFilters(filters)),
    setBuyRentQuickFilters: (moreFilter) => dispatch(setBuyRentQuickFilters(moreFilter)),
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout))
