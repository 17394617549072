/** @format */

import React, { Fragment } from 'react'
import ShareLead from '../../images/share-lead.svg'
import AtttachIcon from '../../images/timeline-icons/attach.svg'
import CallIcon from '../../images/callIcon.svg'
import MeetingIcon from '../../images/timeline-icons/meeting-lineicon.svg'
import Whatsapp from '../../images/whatsapp.png'
import TaskIcon from '../../images/timeline-icons/task-lineicon.svg'
import CommentIcon from '../../images/timeline-icons/comment-lineicon.svg'
import followUp from '../../images/followupIcon.svg'
import meetingIcon from '../../images/meetingIcon.svg'
import deleteIcon from '../../images/DeleteIcon.svg'
import assign from '../../images/assign.svg'
import rejectIcon from '../../images/reject.svg'
import reopenIcon from '../../images/reopen.svg'
import './style.scss'
const ActionDropList = (props) => {
  const {
    callToggle,
    fetchCallLogs,
    meetingToggle,
    commentToggle,
    fetchAllComments,
    attachmentToggle,
    fetchAgents,
    shareToggle,
    singleLeadIdState,
    customer,
    item,
    checkForRead,
    deleteToggle,
    reOpenToggle,
    followUpModalLocal,
    setCloseLostToggleState,
    addFollowUp,
    localStateUpdate,
    itemId,
    fetchLeadAttachmentsFunc,
    setCloseWonToggleState,
    closeWonToggle,
  } = props

  let mainRole = localStorage.getItem('role')
  let callWtsappCheckComment = checkForRead.ownLeadReadOnly === true || !checkForRead.showReadOnly
  let checkForAssign = checkForRead.checkForManager && item.status !== 'closed_lost' &&
    item.status !== 'closed_won'
  let checkForDelete = mainRole === 'sub_admin 1'
  let checkForReopen =
    mainRole === 'sub_admin 1' && item.status === 'closed_won' && checkForRead.checkForManager

  return (
    <div
      className="dropdown-menu"
      id={`hoverActionList${item.id}`}
      aria-labelledby="dropdownMenuButton"
      onClick={(e) => e.stopPropagation()}
    >
      <button
        className="action-separator-main dropdown-item"
        // disabled={callWtsappCheckComment ? false : true}
        onClick={(e) => {
          // if (callWtsappCheckComment) {
          e.stopPropagation()
          callToggle(customer, 'inner')
          fetchCallLogs(item)
          // }
        }}
      >
        Call
      </button>

      <button
        className="action-separator-main dropdown-item"
        // disabled={callWtsappCheckComment ? false : true}
        onClick={(e) => {
          // if (callWtsappCheckComment) {
          e.stopPropagation()
          callToggle(customer, 'inner')
          fetchCallLogs(item)
          // }
        }}
      >
        Whatsapp
      </button>

      <button
        className="action-separator-main dropdown-item"
        // disabled={!checkForRead.showReadOnly ? false : true}
        onClick={(e) => {
          // if (!checkForRead.showReadOnly) {
          e.stopPropagation()
          meetingToggle()
          // }
        }}
      >
        Meetings
      </button>

      <button
        className="action-separator-main dropdown-item"
        // disabled={!checkForRead.showReadOnly ? false : true}
        onClick={(e) => {
          // if (!checkForRead.showReadOnly) {
          e.stopPropagation()
          meetingToggle()
          // }
        }}
      >
        Tasks
      </button>

      <button
        className="action-separator-main dropdown-item"
        // disabled={!checkForRead.showReadOnly ? false : true}
        onClick={(event) => {
          // if (!checkForRead.showReadOnly) {
          followUpModalLocal(true)
          addFollowUp(event)
          // }
        }}
      >
        Follow up
      </button>

      <button
        className="action-separator-main dropdown-item"
        // disabled={callWtsappCheckComment ? false : true}
        onClick={(e) => {
          // if (callWtsappCheckComment) {
          fetchAllComments(singleLeadIdState && singleLeadIdState)
          commentToggle(true)
          // }
        }}
      >
        Comments
      </button>

      <button
        className="action-separator-main dropdown-item"
        // disabled={!checkForRead.showReadOnly ? false : true}
        onClick={(e) => {
          // if (!checkForRead.showReadOnly) {
          e.stopPropagation()
          attachmentToggle(item.id)
          // }
        }}
      >
        Attachments
      </button>
      {!checkForRead.showReadOnly && item.shared_with_armsuser_id === null ? (
        <button
          className="action-separator-main dropdown-item"
          disabled={!checkForRead.showReadOnly ? false : true}
          onClick={(e) => {
            if (!checkForRead.showReadOnly) {
              fetchAgents()
              shareToggle('share')
            }
          }}
        >
          Share
        </button>
      ) : null}

      {checkForAssign ? (
        <button
          className="action-separator-main dropdown-item"
          disabled={checkForAssign ? false : true}
          onClick={(e) => {
            if (checkForAssign) {
              fetchAgents()
              shareToggle('assign')
              localStateUpdate(item.id)
            }
          }}
        >
          Assign
        </button>
      ) : null}

      {!checkForRead.showReadOnly ? (
        <button
          className="action-separator-main dropdown-item"
          disabled={!checkForRead.showReadOnly ? false : true}
          onClick={() => {
            if (!checkForRead.showReadOnly) {
              setCloseLostToggleState(item.id)
            }
          }}
        >
          Reject
        </button>
      ) : null}
      {checkForReopen ? (
        <button
          className="action-separator-main dropdown-item"
          disabled={checkForReopen ? false : true}
          onClick={(e) => {
            if (checkForReopen) {
              reOpenToggle()
            }
          }}
        >
          Reopen
        </button>
      ) : null}

      {checkForDelete ? (
        <button
          className="action-separator-main dropdown-item"
          disabled={checkForDelete ? false : true}
          onClick={(e) => {
            if (checkForDelete) {
              deleteToggle()
            }
          }}
        >
          Delete
        </button>
      ) : null}

      {/* {!checkForRead.showReadOnly ? (
        <button
          className="action-separator-main dropdown-item"
          disabled={!checkForRead.showReadOnly ? false : true}
          onClick={(e) => {
            if (checkForDelete) {
              closeWonToggle()
            }
          }}
        >
          <img src={deleteIcon} alt="Meeting icon" className="icon-main" />
          Close Won
        </button>
      ) : null} */}
    </div>
  )
}

export default ActionDropList
