/** @format */

import ConnectFeedback from './index'
import { getLeadType } from '../../../utils/helper'
import React, { useEffect, useState } from 'react'
import { fetchAllShortlistedProperties } from '../../../actions/leads'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { getActiveBookingProperties } from '../../../utils/property'
import { missingFieldHtml } from '../../../utils/validate'
import Swal from 'sweetalert2'
import axios from 'axios'
import config from '../../../config'
import { FEEDBACK_ACTIONS } from '../../../utils/constant_data'
import { diaryCreateUpdateAPI, makeTaskTimePayload } from '../../../utils/diary'
import SlotModal from '../../SlotManagement/SlotModal'
import moment from 'moment'
import ReConnect from './ReConnect'
import { getDiaryTasks } from '../../../actions/diary'
import RWR from './RWR'

const ConnectFlow = (props) => {
  const {
    diary,
    setDiary,
    leadScheduledProperties,
    setConnectFlow,
    setConnectModal,
    actionType,
    setActionType,
    callback,
    singleLeadRecord,
  } = props

  const defaultPage = { currentPage: 1, pageSize: props.noPaginate ? 100 : 50 }
  const FA = FEEDBACK_ACTIONS

  const [canFeedbackModalClose, setCanFeedbackModalClose] = useState(false)
  const [connectFeedback, setConnectFeedback] = useState({})
  const [selectedProperty, setSelectedProperty] = useState([])
  const [newTask, setNewTask] = useState(null)
  const [nextAction, setNextAction] = useState(null)
  const [pagination, setPagination] = useState(defaultPage)
  const [slotModal, setSlotModal] = useState(false)
  const [cancelAction, setCancelAction] = useState(false)
  const [rescheduleViewingModal, setRescheduleViewingModal] = useState(false)
  const [offerWorkFlow, setOfferWorkFlow] = useState(false)
  const [viewingModal, setViewingModal] = useState(false)
  const [shouldAssign, setShouldAssign] = useState(false)
  const [addMeetingFromLeadDetails, setAddMeetingFromLeadDetails] = useState(false)
  const [updateLeadTasks, setUpdateLeadTasks] = useState(false)
  const [reConnect, setReConnect] = useState(false)
  const [investLeadModal, setInvestLeadModal] = useState(false)
  const [rwrModal, setRwrModal] = useState(false)
  const [btnLoader, setBtnLoader] = useState(false)
  const [again, setAgain] = useState(false)
  const [connectFeedbackModal, setConnectFeedbackModal] = useState(false)

  useEffect(() => setConnectFeedbackModal(true), [])

  useEffect(() => {
    if (
      nextAction &&
      [
        FA.BOOK_UNIT,
        FA.SHORTLIST_PROPERTIES,
        FA.PROPSURE,
        FA.SELECT_PROPERTY_FOR_TRANSACTION,
        FA.SETUP_VIEWING,
        FA.OFFER,
        FA.SETUP_MORE_VIEWING,
      ].indexOf(nextAction) > -1
    ) {
      saveOrUpdateDiaryTask({
        id: diary.id,
        otherTasksToUpdate: connectFeedback.otherTasksToUpdate.map((task) => {
          return {
            id: task.id,
            comments: task.comment,
            response: task.comment,
            feedbackId: connectFeedback.id,
            feedbackTag: connectFeedback.tag,
            status: 'completed',
          }
        }),
        response: connectFeedback.comment,
        feedbackId: connectFeedback.id,
        feedbackTag: connectFeedback.tag,
        status: 'completed',
      })
    }

    if ([FA.ADD_MEETING, FA.RESCHEDULE_MEETING, FA.SETUP_ANOTHER_MEETING].indexOf(nextAction) > -1)
      setSlotModal(true)
    else if (nextAction === FA.BOOK_UNIT)
      props.history.push(`/booking/ci?clientId=${diary.armsProjectLead.customer.id}&leadId=${diary.armsProjectLeadId}`)/*/project-lead-detail/${diary.armsProjectLeadId}*/
    else if (['cancel-meeting', 'mark-as-done', 'cancel-viewing'].indexOf(nextAction) > -1) {
      setCanFeedbackModalClose(true)
      setActionType(nextAction === 'mark-as-done' ? 'Done' : 'Cancel')
      setDiary({ ...diary, status: nextAction === 'mark-as-done' ? 'completed' : 'cancelled' })
      setNextAction(null)
      setNewTask({ id: diary.id })
      if (diary.taskType === 'viewing') props.fetchLeadScheduledProperties(diary.armsLeadId)
      if (['cancel-meeting', 'cancel-viewing'].indexOf(nextAction) > -1) setCancelAction(true)
      if (
        (diary && ['viewing', 'follow_up', 'connect'].indexOf(diary.taskType) > -1) ||
        nextAction === 'cancel-meeting'
      )
        setConnectFeedbackModal(true)
      else if (diary && diary.taskType === 'meeting' && nextAction === 'mark-as-done') {
        setConnectFeedbackModal(true)
        // if (diary.armsProjectLead.guideReference) setConnectFeedbackModal(true)
        // else setGuideReferenceModal(true)
      } else saveOrUpdateDiaryTask({ ...diary, status: 'completed' }, true)
    } else if (
      [
        FA.SHORTLIST_PROPERTIES,
        FA.PROPSURE,
        FA.SELECT_PROPERTY_FOR_TRANSACTION,
        FA.SETUP_VIEWING,
        FA.OFFER,
        FA.SETUP_MORE_VIEWING,
      ].indexOf(nextAction) > -1
    ) {
      props.history.push(`/buyRentLeadDetails/${diary.armsLeadId}`)
    } else if (FA.RESCHEDULE_VIEWING === nextAction) {
      props.fetchLeadScheduledProperties(diary.armsLeadId)
      setRescheduleViewingModal(true)
    } else if (FA.ASSIGN === nextAction) {
      let missingRequiredFields = missingFieldHtml(diary && diary.wanted)

      if (missingRequiredFields.length) {
        Swal.fire({
          title: '',
          html: `<p class='text-left mb-1 font-weight-bold'>Following mandatory field(s) are required before leads assignment:</p>${missingRequiredFields}`,
          icon: 'error',
          width: 650,
        })
        setNextAction(null)
      } else {
        convertLead(diary.wanted, true)
        setNextAction(null)
      }
    } else if (
      [FA.OFFER, FA.SETUP_MORE_VIEWING, FA.PROPSURE, FA.SELECT_PROPERTY_FOR_TRANSACTION].indexOf(
        nextAction
      ) > -1
    ) {
      axios
        .get(`${config.apiPath}/api/leads/shortlistedProperty/${diary.propertyId}`)
        .then((res) => {
          setProperty(res.data)
          if (nextAction === FA.OFFER) setOfferWorkFlow(true)
          else if (nextAction === FA.SETUP_MORE_VIEWING) setViewingModal(true)
        })
    }
  }, [nextAction])

  useEffect(() => {
    setNextFlow()
  }, [connectFeedback])

  const convertLead = (lead, updateTask = false, newLeadType = null, leadClosed = false) => {
    axios
      .post(`${config.apiPath}/api/wanted/convert-to-lead/${lead.id}`)
      .then((data) => {
        if (data.data.type === 'success') {
          if (updateTask) {
            setConnectModal(false)
            saveOrUpdateDiaryTask({
              id: diary.id,
              response: connectFeedback.comment,
              feedbackId: connectFeedback.id,
              feedbackTag: connectFeedback.tag,
              status: 'completed',
            })
            Swal.fire('', 'Lead has been assigned successfully', 'success')
          }
        } else {
          Swal.fire('', "Error occurred! Lead couldn't be assigned", 'error')
        }
        setShouldAssign(false)
      })
      .catch((error) => {
        let msg = "Error occurred. Lead couldn't be assigned"
        if (leadClosed) msg = 'Lead has been closed but New Investment lead creation was failed.'
        Swal.fire(
          '',
          (error.response && error.response.data && error.response.data.message) || msg,
          'error'
        )
        setShouldAssign(false)
      })
  }

  const saveOrUpdateDiaryTask = (taskData, showMessage = false, callback = null) => {
    diaryCreateUpdateAPI(taskData, showMessage, () => {
      let leadParam = 'projectId'
      if (props.leadType === 'BuyRent') leadParam = 'buyrentId'
      else if (props.leadType === 'Wanted') leadParam = 'wantedId'
      if (callback) callback()
      if (nextAction !== FA.RESCHEDULE_VIEWING) setNextAction(null)
    })
  }

  const [property, setProperty] = useState(null)

  const renderConnectFlowUpdateTask = (bookedTime) => {
    setSlotModal(false)
    let payload = {
      ...newTask,
      otherTasksToUpdate: newTask.otherTasksToUpdate.map((task) => {
        return {
          ...task,
          status: cancelAction ? 'cancelled' : 'completed',
        }
      }),
      status: cancelAction ? 'cancelled' : 'completed',
    }
    if (
      connectFeedback.section === 'Actions' &&
      [FA.RESCHEDULE_MEETING, FA.RESCHEDULE_VIEWING].indexOf(nextAction) > -1
    ) {
      payload = {
        ...payload,
        status: 'pending',
        makeHistory: true,
        start: bookedTime ? moment(bookedTime.startTime).format('YYYY-MM-DDTHH:mm:ssZ') : '',
        diaryTime: bookedTime ? moment(bookedTime.startTime).format('YYYY-MM-DDTHH:mm:ssZ') : '',
        date: bookedTime ? moment(bookedTime.date).format('YYYY-MM-DDTHH:mm:ssZ') : '',
        end: bookedTime ? moment(bookedTime.endTime).format('YYYY-MM-DDTHH:mm:ssZ') : '',
        slots:
          bookedTime && bookedTime.slots
            ? bookedTime.slots.map((slot) => {
                return slot.id
              })
            : '',
      }
    }
    const newTaskType =
      connectFeedback.section === 'Actions' &&
      [FA.ADD_MEETING, FA.SETUP_ANOTHER_MEETING].indexOf(nextAction) > -1
        ? 'meeting'
        : ["Couldn't Connect", 'Follow up', 'Cancel Viewing', 'Cancel Meeting'].indexOf(
            connectFeedback.section
          ) > -1
        ? 'follow_up'
        : null

    saveOrUpdateDiaryTask(payload, newTaskType ? false : true, () => {
      if (nextAction === FA.RESCHEDULE_VIEWING) props.fetchLeadScheduledProperties(diary.armsLeadId)

      if (newTaskType)
        saveOrUpdateDiaryTask(
          makeTaskTimePayload(bookedTime, newTaskType, {
            notes: newTask.response,
            reasonId: connectFeedback.id,
            reasonTag: connectFeedback.tag,
            [getLeadType(diary) === 'Project'
              ? 'leadId'
              : getLeadType(diary) === 'BuyRent'
              ? 'armsLeadId'
              : 'wantedId']:
              diary && (diary.armsLeadId || diary.armsProjectLeadId || diary.wantedId),
          }),
          true,
          () => setConnectFlow(false)
        )
      else setConnectFlow(false)
    })
    if (nextAction !== FA.RESCHEDULE_VIEWING) {
      setConnectFeedback({})
      setNewTask(null)
    }
    setCancelAction(false)
  }

  const setNextFlow = () => {
    if (Object.keys(connectFeedback).length) {
      setConnectFeedbackModal(false)
      setNewTask({
        ...diary,
        otherTasksToUpdate: connectFeedback.otherTasksToUpdate.map((task) => {
          return {
            ...newTask,
            id: task.id,
            comments: task.comment,
            response: task.comment,
            feedbackId: connectFeedback.id,
            feedbackTag: connectFeedback.tag,
          }
        }),
        comments: connectFeedback.comment,
        response: connectFeedback.comment,
        feedbackId: connectFeedback.id,
        feedbackTag: connectFeedback.tag,
      })
      if (connectFeedback.section === "Couldn't Connect") setReConnect(true)
      else if (
        ['Follow up', 'Cancel Viewing', 'Cancel Meeting'].indexOf(connectFeedback.section) > -1
      ) {
        if (
          connectFeedback.section !== 'Cancel Viewing' ||
          ['Done', 'Cancel'].indexOf(actionType) > -1
        )
          if (
            connectFeedback.section === 'Cancel Viewing' &&
            actionType === 'Cancel' &&
            props.leadScheduledProperties &&
            connectFeedback.otherTasksToUpdate.length + 1 !==
              getActiveBookingProperties(props.leadScheduledProperties.rows).length
          ) {
            // exclude direct cancel(outside of connect flow) viewing case
            // no followup - just cancelled the selected viewings in case any booking for current lead is OPEN
            saveOrUpdateDiaryTask(
              {
                ...newTask,
                otherTasksToUpdate: connectFeedback.otherTasksToUpdate.map((task) => {
                  return {
                    ...newTask,
                    id: task.id,
                    comments: task.comment,
                    response: task.comment,
                    feedbackId: connectFeedback.id,
                    feedbackTag: connectFeedback.tag,
                    status: 'cancelled',
                  }
                }),
                comments: connectFeedback.comment,
                response: connectFeedback.comment,
                feedbackId: connectFeedback.id,
                feedbackTag: connectFeedback.tag,
                status: 'cancelled',
              },
              false,
              () => {
                viewingCancelled()
                setNextAction(null)
              }
            )
          } else setSlotModal(true)
        else {
          setRescheduleViewingModal(true)
          props.fetchLeadScheduledProperties(diary.armsLeadId)
          setCancelAction(true)
        }
      } else if (connectFeedback.section === 'refer') setInvestLeadModal(true)
      else if (connectFeedback.section === 'Reject') setRwrModal(true)
      else if (connectFeedback.section === 'No Action Required')
        saveOrUpdateDiaryTask({
          ...newTask,
          makeHistory: true,
          otherTasksToUpdate: connectFeedback.otherTasksToUpdate.map((task) => {
            return {
              ...newTask,
              id: task.id,
              makeHistory: true,
              comments: task.comment,
              response: task.comment,
              feedbackId: connectFeedback.id,
              feedbackTag: connectFeedback.tag,
            }
          }),
          comments: connectFeedback.comment,
          response: connectFeedback.comment,
          feedbackId: connectFeedback.id,
          feedbackTag: connectFeedback.tag,
        })
    }
  }

  const viewingCancelled = () =>
    Swal.fire('', 'Viewing(s) has been cancelled successfully', 'success')

  return (
    <>
      {connectFeedbackModal && (
        <ConnectFeedback
          canClose={true}
          actionType={actionType}
          viewingProperties={
            leadScheduledProperties ? getActiveBookingProperties(leadScheduledProperties.rows) : []
          }
          taskType={diary.taskType}
          taskStatus={diary.status}
          leadType={getLeadType(diary)}
          propertyId={diary.propertyId}
          taskId={diary.id}
          lead={props.lead}
          connectFeedback={connectFeedback}
          setNextFlow={(feedback) => setConnectFeedback(feedback)}
          setNextAction={(action, feedback, selectedProperty) => {
            setSelectedProperty(selectedProperty)
            setNewTask({
              id: diary.id,
              taskType: diary.taskType,
              otherTasksToUpdate: feedback.otherTasksToUpdate.map((task) => {
                return {
                  id: task.id,
                  response: feedback.comment,
                  comments: feedback.comment,
                  feedbackTag: feedback.tag,
                  feedbackId: feedback.id,
                }
              }),
              response: feedback.comment,
              comments: feedback.comment,
              feedbackTag: feedback.tag,
              feedbackId: feedback.id,
            })
            setConnectFeedback(feedback)
            setNextAction(action)
            setConnectFeedbackModal(false)
          }}
          connectFeedbackModal={connectFeedbackModal}
          setConnectFeedbackModal={(toggle) => {
            setConnectFeedbackModal(toggle)
            setCanFeedbackModalClose(false)
            setConnectFlow(false)
          }}
        />
      )}

      {connectFeedback && reConnect && (
        <ReConnect
          reConnect={reConnect}
          loader={btnLoader}
          setLoader={(toggle) => setBtnLoader(toggle)}
          setAgainOrDoNothing={(action) => {
            setReConnect(false)
            if (action === 'again') {
              setAgain(true)
              setConnectModal(true)
            }
            saveOrUpdateDiaryTask(
              {
                ...newTask,
                makeHistory: true,
                otherTasksToUpdate: connectFeedback.otherTasksToUpdate.map((task) => {
                  return {
                    ...newTask,
                    id: task.id,
                    makeHistory: true,
                    comments: task.comment,
                    response: task.comment,
                    feedbackId: connectFeedback.id,
                    feedbackTag: connectFeedback.tag,
                  }
                }),
              },
              false,
              () => {
                setConnectFeedback({})
              }
            )
          }}
          taskType={diary.taskType}
          again={(again) => again}
          setFollowUpSetup={(toggle, cancelAction = false) => {
            if (cancelAction && diary.taskType === 'viewing') {
              props.fetchLeadScheduledProperties(diary.armsLeadId)
              setRescheduleViewingModal(true)
            } else setSlotModal(true)
            setReConnect(false)
            setCancelAction(cancelAction)
          }}
          setReConnect={(toggle) => setReConnect(toggle)}
        />
      )}

      {rwrModal && (
        <RWR
          connectFeedback={connectFeedback}
          rwrModal={rwrModal}
          singleLeadRecord={singleLeadRecord}
          lead={diary && (diary.armsLead || diary.armsProjectLead || diary.wanted || {})}
          leadType={getLeadType(diary)}
          setRwrModal={(toggle) => setRwrModal(toggle)}
          callback={() => {
            saveOrUpdateDiaryTask(
              {
                id: diary.id,
                response: connectFeedback.comment,
                feedbackId: connectFeedback.id,
                feedbackTag: connectFeedback.tag,
                status: 'completed',
              },
              false,
              () => callback()
            )
          }}
        />
      )}

      <SlotModal
        slotModal={slotModal}
        setSlotModal={(toggle) => {
          setSlotModal(toggle)
          setConnectFlow(false)
        }}
        title={`Select slot for ${
          (connectFeedback.section === 'Actions' &&
            [FA.ADD_MEETING, FA.SETUP_ANOTHER_MEETING, FA.RESCHEDULE_MEETING].indexOf(nextAction) >
              -1) ||
          addMeetingFromLeadDetails
            ? 'Meeting task'
            : ["Couldn't Connect", 'Follow up', 'Cancel Viewing', 'Cancel Meeting'].indexOf(
                connectFeedback.section
              ) > -1
            ? 'Follow-up task'
            : connectFeedback.section === 'Actions' && FA.RESCHEDULE_VIEWING === nextAction
            ? 'Viewing'
            : 'task'
        }`}
        taskType={
          (connectFeedback.section === 'Actions' &&
            [FA.ADD_MEETING, FA.SETUP_ANOTHER_MEETING, FA.RESCHEDULE_MEETING].indexOf(nextAction) >
              -1) ||
          addMeetingFromLeadDetails
            ? 'meeting'
            : ["Couldn't Connect", 'Follow up', 'Cancel Viewing', 'Cancel Meeting'].indexOf(
                connectFeedback.section
              ) > -1
            ? 'follow-up'
            : connectFeedback.section === 'Actions' && FA.RESCHEDULE_VIEWING === nextAction
            ? 'viewing'
            : null
        }
        callBack={(bookedTime) => {
          setSlotModal(false)
          if (addMeetingFromLeadDetails) {
            saveOrUpdateDiaryTask(
              makeTaskTimePayload(bookedTime, 'meeting', {
                [getLeadType(diary) === 'Project'
                  ? 'leadId'
                  : getLeadType(diary) === 'BuyRent'
                  ? 'armsLeadId'
                  : 'wantedId']:
                  diary && (diary.armsLeadId || diary.armsProjectLeadId || diary.wantedId),
              }),
              true,
              () => setUpdateLeadTasks(!updateLeadTasks)
            )
            setAddMeetingFromLeadDetails(false)
          } else renderConnectFlowUpdateTask(bookedTime)
        }}
      />
    </>
  )
}

export const mapDispatchToProps = (dispatch) => {
  return {
    fetchLeadScheduledProperties: (id) => dispatch(fetchAllShortlistedProperties(id)),
  }
}

export const mapStateToProps = (state) => {
  return {
    leadScheduledProperties: state.Leads.buyRentShortListProperties,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ConnectFlow))
