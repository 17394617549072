/** @format */

import React, { useEffect } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import ClientReadView from './ClientReadView'
import RequirementReadView from './RequirementReadView'
import { assignedToMe } from '../../../../utils/lead'

const useStyles = makeStyles({
  root: {
    display: 'grid',
    gridAutoColumns: '1fr',
    gridAutoFlow: 'column',
    gridTemplateAreas: '"CLIENT CLIENT REQUIREMENT REQUIREMENT"',
  },
})

let ConnectInfo = (props) => {
  const classes = useStyles(props)
  const { lead, leadType, leadProperty, setLead } = props
  const { customer } = lead

  const read = () => {
    if (lead) {
      let returnObject = {}
      returnObject['diaryReadOnly'] = props.isReadOnly ? true : false
      return returnObject
    }
  }

  return (
    <div className={`${classes.root}`}>
      <ClientReadView
        setLeadCustomer={(customer) =>
          setLead({
            ...lead,
            customer: {
              ...lead.customer,
              ...customer,
            },
          })
        }
        customer={customer}
        checkForRead={read()}
        editable={assignedToMe(lead)}
      />
      <RequirementReadView
        lead={lead}
        leadProperty={leadProperty}
        leadType={leadType}
        checkForRead={read()}
        editable={assignedToMe(lead)}
      />
    </div>
  )
}

export const mapDispatchToProps = (dispatch) => {
  return {}
}

const mapStateToProps = (state) => {
  return {}
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ConnectInfo))
