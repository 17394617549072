/** @format */

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import React from 'react'

const ClosedLostConfirm = (props) => {
  return (
    <Modal
      isOpen={props.closeLostToggleState}
      toggle={() => props.setCloseLostToggleState(false)}
      className="general-content"
    >
      <ModalHeader>Reject ( Close as Lost ) </ModalHeader>
      <ModalBody>
        <p>Are you sure you want to continue ? </p>
      </ModalBody>
      <ModalFooter>
        <Button
          color="secondary"
          onClick={() => {
            props.setCloseLostToggleState(false)
          }}
        >
          Cancel
        </Button>
        <Button color="danger" onClick={() => props.closedLost()}>
          Close Lost
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ClosedLostConfirm
