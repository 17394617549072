/** @format */

import React from 'react'
import moment from 'moment'

import './commentDetailStyle.scss'

const CommentDetail = ({ data: { name, date, comment, title }, checkCallFrom }) => {
  let initials = name.toUpperCase().match(/\b\w/g) || []

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-9 pl-0">
          <div className="comment-container">
            <div className="comment-user-avatar">
              <div className="user-img-wrap">
                <div className="cirle" style={{ backgroundColor: 'rgb(4 110 242)' }}>
                  <h6 className="circle-text">
                    {initials && initials[0] && initials[1] ? `${initials[0]} ${initials[1]}` : []}
                  </h6>
                </div>
              </div>
            </div>
            <div className="user-container">
            {checkCallFrom && checkCallFrom === 'viewing' ? (
              <div className="user-name-title">
                <span className="font-weight-bold mr-1"> {name}</span>
                {title === 'comment' || title === null ? (
                  'added a comment'
                ) : (
                  <>added a comment.</>
                )}
              </div>
            ) : (
              <div className="user-name-title">
                <span className="font-weight-bold mr-1"> {name}</span>
                {title === 'comment' || title === null ? (
                  'added a comment'
                ) : (
                  <>
                    added a comment on{' '}
                    <span className="font-weight-bold text-capitalize">
                      {title === 'phone' ? 'Call' : title}
                    </span>
                  </>
                )}
              </div>
            )}
              <div className="comment-section">{comment}</div>
            </div>
          </div>
        </div>
        <div className="col-md-3 text-right mt-3">
          <div className="time-main-container">
            <span className="time-main-container-text">
              {moment(date).format('dddd MMM D, LT')}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CommentDetail
