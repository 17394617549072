import React, {useEffect} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {getSocketCall, mwsAssignLeadIdDiaryId, mwsSoftPhoneDialNumber} from "../actions/diary";
import {showToastMsg} from "../utils/helper";


const LeadClickComponent = (props) => {

  const {leadId, diaryId,leadType} = props.leadData
  useEffect(()=>{
  },[props.leadData])
  return (
    <>
      {
        (Object.keys(props.dialerLeadInformation)).length > 0 && (
          <button
            className="btn-cus-add action-cus call_action_buttons"
            type="button"
            onClick={() => {
              let liveCall = localStorage.getItem('liveCall');
              liveCall = (liveCall !== undefined && liveCall !== '' && liveCall !== null) ? JSON.parse(liveCall) : {};
              if (liveCall !== undefined && Object.keys(liveCall).length > 0 && liveCall.phoneNumber !== undefined) {
                const data = {
                  /* phoneNumber: liveCall.phoneNumber,*/
                  leadId: leadId,
                  diaryId: diaryId,
                  leadType:leadType
                };
                props.mwsAssignLeadIdDiaryId(data);
                showToastMsg("Lead Assign to Call", 'success')
              } else {
                showToastMsg("Call is not Connected", 'warning')
              }
            }
            }
          >
            Assign to CALL
          </button>
        )
      }
    </>
  )
}
const mapDispatchToProps = (dispatch) => ({
  mwsAssignLeadIdDiaryId: (data) => dispatch(mwsAssignLeadIdDiaryId(data)),

})
const mapStateToProps = (state) => {
  return {
    dialerLeadInformation: state.Diary.dialerLeadInformation
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LeadClickComponent))

