/** @format */
/** @format */

import React, { useState, useEffect, useMemo } from 'react'
import Diary from '../../../components/common/Diary/Diary'
import ConnectFeedback from '../../../components/Modals/ConnectFeedback'
import ReConnect from '../../../components/Modals/ConnectFeedback/ReConnect'
import InvestLeadForm from '../../../components/Modals/ConnectFeedback/InvestLeadForm'
import RWR from '../../../components/Modals/ConnectFeedback/RWR'
import ConnectModal from '../../../components/Modals/ConnectFeedback/ConnectModal'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { fetchCities, UserInfoFetch } from '../../../actions/user'
import DiaryTaskModal from '../../../components/Modals/DiaryTaskModal/DiaryTaskModal'
import AdditionalDetails from '../../../components/Modals/AdditionalDetailsModal/AdditionalDetails'
import ReferModal from '../../../components/Modals/ReferModal'
import config from '../../../config'
import ReadOnly from '../../../images/readOnly.svg'
import { PermissionFeatures, PermissionActions } from '../../../utils/permissionConstants'
import { getPermissionValue } from '../../../components/_unlockComponent'
import { diaryFilter } from '../../../components/HorizontalNav/Menu/MenuItems'
import { canMarkCloseAsLost } from '../../../components/common/Diary/DiaryUtilHelper'
import { fetchSingleLead } from '../../../actions/leads'
import {
  diaryCreateTaskToggle,
  getDiaryTasks,
  setDiaryOverDueCount,
  setDiaryFilters,
  setDiaryQuickFilters,
  setOverDueFilters,
  setOverDueQuickFilters,
  setIsOverDue,
  setDiaryMenuAction,
  setSelectedAgent,
  setDiaryDataSort,
  setOverDueDataSort,
} from '../../../actions/diary'

import {
  addInvestmentShareLead,
  investmentShareLead,
  fetchLeadTimeLine,
  fetchLeads,
  fetchAllShortlistedProperties,
} from '../../../actions/leads'

import {
  DIARY_MENU_ACTION,
  FEEDBACK_ACTIONS,
  DIARY_QUICK_FILTERS,
  taskTypes,
} from '../../../utils/constant_data'
import { setDataSort, setMoreFilter, setQuickFilter } from '../../../actions/generalSetting'
import Load from '../../../components/Loader'
import DiaryFilters from '../../../components/common/Diary/DiaryFilters'
import moment from 'moment'
import { getLeadType, shouldShowAllLeads } from '../../../utils/helper'
import ShiftReport from '../../../components/common/Diary/ShiftReport'
import SetMeetingModal from '../../../components/Modals/MeetingModal'
import axios from 'axios'
import ShortListedPropertiesModal from '../../../components/Modals/ShortlistedProperties'
import AgentsModel from '../../../components/common/Diary/AgentsModal'
import { toast } from 'react-toastify'
import { capitalizeWordsWithoutUnderscore, showSuccessMessage } from '../../../utils/commonFormat'
import Swal from 'sweetalert2'
import { showToastMsg } from '../../../utils/helper'
import { managerSelectionDropdown } from '../../../utils/constant_data'
import OfferWorkflow from '../../../components/AddPropertyWorkflow/OfferWorkflow'
import ViewingScreen from '../../../components/AddPropertyWorkflow/ViewingScreen'
import { addSelectedAgents } from '../../../actions/leads'
import { connectFormReqFields, missingFieldHtml } from '../../../utils/validate'
import RescheduleViewing from '../../../components/Modals/ConnectFeedback/RescheduleViewing'
import { fetchAllProjects } from '../../../actions/projects'
import SlotModal from '../../../components/SlotManagement/SlotModal'
import ReferenceGuideModal from '../../../components/Modals/TaskModal/ReferenceGuideModal'
import { setTitle } from '../../../actions/generalSetting'
import { diaryCreateUpdateAPI, makeTaskTimePayload } from '../../../utils/diary'
import DiaryModal from '../../../components/Modals/DiaryModal'
import { isReadOnly } from '../../../utils/lead'
import { getActiveBookingProperties } from '../../../utils/property'
import CloseAsDuplicateModal from '../../../components/Modals/CloseDuplicate'
import CloseAsLostModal from '../../../components/Modals/CloseLost'
import DiaryDateQuickFilter from '../../../components/HorizontalNav/Filter/DiaryDateQuickFilter'

const DiaryViewModal = (props) => {
  const getInitialFilters = () => {
    return {
      date: null,
      followUpReason: null,
      leadType: null,
      clientId: null,
      leadId: null,
      clientName: null,
      clientContact: null,
    }
  }
  const FA = FEEDBACK_ACTIONS

  const [diaryData, setDiaryData] = useState(null)
  const [again, setAgain] = useState(false)
  const [diaryDataCount, setDiaryDataCount] = useState(null)

  const [reConnect, setReConnect] = useState(false)
  const [connectModal, setConnectModal] = useState(false)
  const [additionalDetailsModal, setAdditionalDetailsModal] = useState(false)
  const [connectFeedbackModal, setConnectFeedbackModal] = useState(false)
  const [investLeadModal, setInvestLeadModal] = useState(false)
  const [rwrModal, setRwrModal] = useState(false)
  const [connectFeedback, setConnectFeedback] = useState({})
  const [shortListPropertyModal, setShortListPropertyModal] = useState(false)
  const [timeSlot, setTimeSlot] = useState(true)
  const [diary, setDiary] = useState(null)
  const [action, setAction] = useState(null)
  const [diaryAction, setDiaryAction] = useState(null)
  const [isLoading, setLaoding] = useState(props.isLoading)
  const [nextAction, setNextAction] = useState(null)
  const [meetingModal, setMeetingModal] = useState(false)
  const [actionType, setActionType] = useState(null)
  const [newTask, setNewTask] = useState(null)
  const [shareToggleState, setShareToggleState] = useState(false)
  const [shareLeadType, setShareLeadType] = useState('')
  const [agents, setAgents] = useState([])
  const [agentCount, setAgentCount] = useState(0)
  const [shareloader, setShareloader] = useState(false)
  const [btnLoader, setBtnLoader] = useState(false)
  const [singleLeadIdState, setSingleLeadIdState] = useState(null)
  const [rescheduleViewingModal, setRescheduleViewingModal] = useState(false)
  const [canFeedbackModalClose, setCanFeedbackModalClose] = useState(false)
  const [shouldAssign, setShouldAssign] = useState(false)
  const [leadTypeParam, setLeadTypeParam] = useState(null)
  const [checkLeadtype] = useState('diary')

  const { user, fetchUser, createTaskModalToggle, diaryCreateTaskToggle } = props

  // buyrent view workflow related
  const [property, setProperty] = useState(null)
  const [offerWorkFlow, setOfferWorkFlow] = useState(false)
  const [viewingModal, setViewingModal] = useState(false)

  const [slotModal, setSlotModal] = useState(false)
  const [cancelAction, setCancelAction] = useState(false)

  // meeting actions
  const defaultPage = { currentPage: 1, pageSize: props.noPaginate ? 100 : 50 }
  const [pagination, setPagination] = useState(defaultPage)
  // permissions
  const [diaryPageView] = useState(
    getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.DIARY_PAGE_VIEW)
  )
  const [selectedProperty, setSelectedProperty] = useState([])
  const [guideReferenceModal, setGuideReferenceModal] = useState(false)

  const [addMeetingFromLeadDetails, setAddMeetingFromLeadDetails] = useState(false)
  const [reschedule, setReschedule] = useState(null) // meeting or viewing

  const [updateLeadTasks, setUpdateLeadTasks] = useState(false)
  const [diaryModal, setDiaryModal] = useState(false)
  const [closeAsDuplicateModal, setCloseAsDuplicateModal] = useState(false)
  const [closeAsLostModal, setCloseAsLostModal] = useState(false)

  const { fetchSingleLeadProps, singleLeadRecord } = props

  const handleCloseAsLost = () => {
    if (canMarkCloseAsLost(singleLeadRecord && singleLeadRecord, leadTypeParam)) {
      Swal.fire(
        'Error',
        'This lead cannot be Closed as Lost as it has some payments. Delete all payments before closing this lead.',
        'error'
      ).then(() => {})
    } else {
      setCloseAsLostModal(true)
    }
  }

  const isOverDue = () => {
    if (
      props &&
      props.location &&
      props.location.pathname &&
      props.location.pathname.includes('diary')
    )
      return false
    else if (
      props &&
      props.location &&
      props.location.pathname &&
      props.location.pathname.includes('overdue')
    )
      return true
    else return null
  }
  //reset pagination on filter change
  useEffect(() => {
    setPagination(defaultPage)
  }, [props.diaryFilters, props.diaryQuickFilter, props.overDueFilters, props.overDueQuickFilters])

  useEffect(() => {
    // don't set title when open in modal
    if (!props.lead) props.setTitle({ name: 'Diary' })

    /*props.fetchCities()*/
    //props.fetchAllProjects()
    if (!props.lead && isOverDue() !== null) {
      props.setOverDueCount()
      if (isOverDue()) props.setIsOverDue(true)
      else props.setIsOverDue(false)
    }
  }, [])

  useEffect(() => {
    if (props.lead) {
      let leadParam = 'projectId'
      if (props.leadType === 'BuyRent') leadParam = 'buyrentId'
      else if (props.leadType === 'Wanted') leadParam = 'wantedId'
      let userIdParam = ''
      if (props.userId) {
        userIdParam = `&userID=${props.userId}`
      }
      props.fetchDiaryTasks(
        pagination,
        '',
        null,
        `${leadParam}=${props.lead.id}${userIdParam}`,
        'pending',
        props.lead
      )
    }
  }, [props.lead])

  useEffect(() => {
    if (
      nextAction &&
      [
        FA.BOOK_UNIT,
        FA.SHORTLIST_PROPERTIES,
        FA.PROPSURE,
        FA.SELECT_PROPERTY_FOR_TRANSACTION,
        FA.SETUP_VIEWING,
        FA.OFFER,
        FA.SETUP_MORE_VIEWING,
      ].indexOf(nextAction) > -1
    ) {
      saveOrUpdateDiaryTask({
        id: diary.id,
        otherTasksToUpdate: connectFeedback.otherTasksToUpdate.map((task) => {
          return {
            id: task.id,
            comments: task.comment,
            response: task.comment,
            feedbackId: connectFeedback.id,
            feedbackTag: connectFeedback.tag,
            status: 'completed',
          }
        }),
        response: connectFeedback.comment,
        feedbackId: connectFeedback.id,
        feedbackTag: connectFeedback.tag,
        status: 'completed',
      })
    }

    if ([FA.ADD_MEETING, FA.RESCHEDULE_MEETING, FA.SETUP_ANOTHER_MEETING].indexOf(nextAction) > -1)
      setSlotModal(true)
    else if (nextAction === FA.BOOK_UNIT)
      props.history.push(`/booking/ci?clientId=${diary.armsProjectLead.customer.id}&leadId=${diary.armsProjectLeadId}`)/*/project-lead-detail/${diary.armsProjectLeadId}*/
    else if (['cancel-meeting', 'mark-as-done', 'cancel-viewing'].indexOf(nextAction) > -1) {
      setCanFeedbackModalClose(true)
      setActionType(nextAction === 'mark-as-done' ? 'Done' : 'Cancel')
      setDiary({ ...diary, status: nextAction === 'mark-as-done' ? 'completed' : 'cancelled' })
      setNextAction(null)
      setNewTask({ id: diary.id })
      if (diary.taskType === 'viewing') props.fetchLeadScheduledProperties(diary.armsLeadId)
      if (['cancel-meeting', 'cancel-viewing'].indexOf(nextAction) > -1) setCancelAction(true)
      if (
        (diary && ['viewing', 'follow_up', 'connect'].indexOf(diary.taskType) > -1) ||
        nextAction === 'cancel-meeting'
      )
        setConnectFeedbackModal(true)
      else if (diary && diary.taskType === 'meeting' && nextAction === 'mark-as-done') {
        setConnectFeedbackModal(true)
        // if (diary.armsProjectLead.guideReference) setConnectFeedbackModal(true)
        // else setGuideReferenceModal(true)
      } else setConnectFeedbackModal(true)
    } else if (
      [
        FA.SHORTLIST_PROPERTIES,
        FA.PROPSURE,
        FA.SELECT_PROPERTY_FOR_TRANSACTION,
        FA.SETUP_VIEWING,
        FA.OFFER,
        FA.SETUP_MORE_VIEWING,
      ].indexOf(nextAction) > -1
    ) {
      props.history.push(`/buyRentLeadDetails/${diary.armsLeadId}`)
    } else if (FA.RESCHEDULE_VIEWING === nextAction) {
      props.fetchLeadScheduledProperties(diary.armsLeadId)
      setRescheduleViewingModal(true)
    } else if (FA.ASSIGN === nextAction) {
      let missingRequiredFields = missingFieldHtml(diary && diary.wanted)

      if (missingRequiredFields.length) {
        Swal.fire({
          title: '',
          html: `<p class='text-left mb-1 font-weight-bold'>Following mandatory field(s) are required before leads assignment:</p>${missingRequiredFields}`,
          icon: 'error',
          width: 650,
        })
        setNextAction(null)
      } else {
        convertLead(diary.wanted, true)
        setNextAction(null)
      }
    } else if (
      [FA.OFFER, FA.SETUP_MORE_VIEWING, FA.PROPSURE, FA.SELECT_PROPERTY_FOR_TRANSACTION].indexOf(
        nextAction
      ) > -1
    ) {
      axios
        .get(`${config.apiPath}/api/leads/shortlistedProperty/${diary.propertyId}`)
        .then((res) => {
          setProperty(res.data)
          if (nextAction === FA.OFFER) setOfferWorkFlow(true)
          else if (nextAction === FA.SETUP_MORE_VIEWING) setViewingModal(true)
        })
    }
  }, [nextAction])

  useMemo (()=>{
    setTimeout(props.isShowDiary === true && props.fetchDiaryTasks(pagination,null,null,null,null,null,props.isShowDiary),2000);
  },[props.isShowDiary])

  useEffect(() => {
    if (props.location.search == '?createTask=true') toggleTaskToggle()
    if (!props.lead && isOverDue() !== null) {
      if (isOverDue() === true) {
        props.setIsOverDue(true)
        props.fetchDiaryTasks(pagination)
        props.setMoreFilter({
          showModal: false,
          filterCount: props.overDueFilters ? Object.keys(props.overDueFilters).length : 0,
          activeFilter: props.overDueFilters
            ? Object.keys(props.overDueFilters).length > 0
              ? true
              : false
            : false,
        })
        props.setQuickFilters(props.overDueQuickFilters)
        props.setGeneralDataSort(props.overDueDataSort)
      } else if (isOverDue() === false) {
        props.setIsOverDue(false)
        //if quick filter not set
        if (props.diaryQuickFilter && props.diaryQuickFilter.length <= 0) {
          props.setDiaryQuickFilters([diaryFilter[0]])
          props.setQuickFilters([diaryFilter[0]])
        }
        // da call zwe
        props.isShowDiary !== true &&  props.fetchDiaryTasks(pagination)
        props.setMoreFilter({
          showModal: false,
          filterCount: props.diaryFilters ? Object.keys(props.diaryFilters).length : 0,
          activeFilter: props.diaryFilters
            ? Object.keys(props.diaryFilters).length > 0
              ? true
              : false
            : false,
        })
        props.setQuickFilters(props.diaryQuickFilter)
        props.setGeneralDataSort(props.diaryDataSort)
      }
    }
  }, [props.location])

  useEffect(() => {
    props.diaryTasks && setDiaryData(props.diaryTasks.rows)
    props.diaryTasks && props.diaryTasks.count
      ? setDiaryDataCount(props.diaryTasks.count)
      : setDiaryDataCount(0)
  }, [props.diaryTasks])

  useEffect(() => {
    setLaoding(props.isLoading)
  }, [props.isLoading])

  useEffect(() => {
    if (props.diaryQuickFilter && props.diaryQuickFilter.length <= 0) {
      props.setDiaryQuickFilters([diaryFilter[0]])
      props.setQuickFilters([diaryFilter[0]])
    }
  }, [])

  const convertLead = (lead, updateTask = false, newLeadType = null, leadClosed = false) => {
    axios
      .post(`${config.apiPath}/api/wanted/convert-to-lead/${lead.id}`)
      .then((data) => {
        if (data.data.type === 'success') {
          if (updateTask) {
            setConnectModal(false)
            saveOrUpdateDiaryTask({
              id: diary.id,
              response: connectFeedback.comment,
              feedbackId: connectFeedback.id,
              feedbackTag: connectFeedback.tag,
              status: 'completed',
            })
            showToastMsg('Lead has been assigned successfully', 'success')
          }
        } else {
          showToastMsg("Error occurred! Lead couldn't be assigned", 'error')
        }
        setShouldAssign(false)
      })
      .catch((error) => {
        let msg = "Error occurred. Lead couldn't be assigned"
        if (leadClosed) msg = 'Lead has been closed but New Investment lead creation was failed.'
        showToastMsg(
          (error.response && error.response.data && error.response.data.message) || msg,
          'error'
        )
        setShouldAssign(false)
      })
  }

  const setFilters = (filters) => {
    if (isOverDue()) {
      props.setOverDueFilters(filters)
    } else {
      props.setDiaryFilters(filters)
    }
  }

  const getFilters = () => {
    if (isOverDue()) {
      return props.overDueFilters
    } else {
      return props.diaryFilters
    }
  }

  const viewingCancelled = () =>
    showToastMsg('Viewing(s) has been cancelled successfully', 'success')

  const saveOrUpdateDiaryTask = (taskData, showMessage = false, callback = null) => {
    diaryCreateUpdateAPI(taskData, showMessage, () => {
      let leadParam = 'projectId'
      if (props.leadType === 'BuyRent') leadParam = 'buyrentId'
      else if (props.leadType === 'Wanted') leadParam = 'wantedId'
      if (callback) callback()
      props.fetchDiaryTasks(
        pagination,
        '',
        null,
        props.lead ? `${leadParam}=${props.lead.id}` : undefined,
        props.lead ? 'pending' : undefined
      )
      if (nextAction !== FA.RESCHEDULE_VIEWING) setNextAction(null)
    })
  }

  const setNextFlow = () => {
    if (Object.keys(connectFeedback).length) {
      setConnectFeedbackModal(false)
      setNewTask({
        ...newTask,
        otherTasksToUpdate:
          connectFeedback &&
          connectFeedback.otherTasksToUpdate &&
          connectFeedback.otherTasksToUpdate.map((task) => {
            return {
              ...newTask,
              id: task.id,
              comments: task.comment,
              response: task.comment,
              feedbackId: connectFeedback.id,
              feedbackTag: connectFeedback.tag,
            }
          }),
        comments: connectFeedback.comment,
        response: connectFeedback.comment,
        feedbackId: connectFeedback && connectFeedback.id,
        feedbackTag: connectFeedback && connectFeedback.tag,
      })
      if (connectFeedback.section === "Couldn't Connect") setReConnect(true)
      else if (
        ['Follow up', 'Cancel Viewing', 'Cancel Meeting'].indexOf(connectFeedback.section) > -1
      ) {
        if (
          connectFeedback.section !== 'Cancel Viewing' ||
          ['Done', 'Cancel'].indexOf(actionType) > -1
        )
          if (
            connectFeedback.section === 'Cancel Viewing' &&
            actionType === 'Cancel' &&
            props.leadScheduledProperties &&
            connectFeedback.otherTasksToUpdate.length + 1 !==
              getActiveBookingProperties(props.leadScheduledProperties.rows).length
          ) {
            // exclude direct cancel(outside of connect flow) viewing case
            // no followup - just cancelled the selected viewings in case any booking for current lead is OPEN
            saveOrUpdateDiaryTask(
              {
                ...newTask,
                otherTasksToUpdate: connectFeedback.otherTasksToUpdate.map((task) => {
                  return {
                    ...newTask,
                    id: task.id,
                    comments: task.comment,
                    response: task.comment,
                    feedbackId: connectFeedback.id,
                    feedbackTag: connectFeedback.tag,
                    status: 'cancelled',
                  }
                }),
                comments: connectFeedback.comment,
                response: connectFeedback.comment,
                feedbackId: connectFeedback.id,
                feedbackTag: connectFeedback.tag,
                status: 'cancelled',
              },
              false,
              () => {
                viewingCancelled()
                setNextAction(null)
              }
            )
          } else setSlotModal(true)
        else {
          setRescheduleViewingModal(true)
          props.fetchLeadScheduledProperties(diary.armsLeadId)
          setCancelAction(true)
        }
      } else if (connectFeedback.section === 'refer') setInvestLeadModal(true)
      else if (connectFeedback.section === 'Reject') setRwrModal(true)
      else if (connectFeedback.section === 'No Action Required')
        saveOrUpdateDiaryTask({
          ...newTask,
          makeHistory: true,
          otherTasksToUpdate: connectFeedback.otherTasksToUpdate.map((task) => {
            return {
              ...newTask,
              id: task.id,
              makeHistory: true,
              comments: task.comment,
              response: task.comment,
              feedbackId: connectFeedback.id,
              feedbackTag: connectFeedback.tag,
            }
          }),
          comments: connectFeedback.comment,
          response: connectFeedback.comment,
          feedbackId: connectFeedback.id,
          feedbackTag: connectFeedback.tag,
        })
      else if (!connectFeedback.id && !connectFeedback.section) {
        saveOrUpdateDiaryTask(
          {
            ...diary,
            status: 'completed',
            comments: connectFeedback.comment,
            response: connectFeedback.comment,
          },
          true
        )
      }
    }
  }

  const toggleTaskToggle = () => {
    setDiaryAction('')
    diaryCreateTaskToggle()
  }

  const deleteDiary = (diary) => {
    if (diary) {
      let deleteMsg = diary.isRecurring
        ? 'This is a recurring task, and deleting this task will delete its future tasks.<br><br>'
        : ''
      let diaryStartDate = diary.start
      let currentDate = moment(new Date()).format('YYYY-MM-DD')
      if (moment(currentDate).isBefore(diaryStartDate)) {
        const swalWithBootstrapButtons = Swal.mixin({
          customClass: {
            confirmButton: 'swalConfirmBtn px-4 rounded-pill small mr-2',
            denyButton: 'denyConfirmBtn px-4 rounded-pill mr-2 small',
          },
          buttonsStyling: false,
        })
        swalWithBootstrapButtons
          .fire({
            // title: 'Are you sure you want to delete this task?',
            html: deleteMsg + 'Are you sure you want to delete this task?',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Yes',
            confirmButtonColor: '#026ff2',
            denyButtonText: `No`,
            denyButtonColor: '#fff',
            icon: 'warning',
          })
          .then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              axios
                .delete(`${config.apiPath}/api/diary/delete?id=${diary.id}`)
                .then((res) => {
                  showToastMsg('Task successfully deleted.', 'success')
                  props.fetchDiaryTasks(pagination, '', isOverDue())
                })
                .catch((error) => {
                  showToastMsg('An error occurred while deleting this task.', 'error')
                })
            }
          })
      } else {
        showToastMsg("You can't delete an overdue task.", 'error')
      }
    }
  }

  const updateDiary = (lead) => {
    let keyToUpdate = diary.armsLeadId
      ? 'armsLead'
      : diary.armsProjectLeadId
      ? 'armsProjectLead'
      : 'wanted'
    setDiary({
      ...diary,
      [keyToUpdate]: {
        ...diary[keyToUpdate],
        ...lead,
        city: lead.city ? { id: lead.city.value, name: lead.city.label } : null,
        customer: lead.customer
          ? {
              ...lead.customer,
              customerContacts: lead.customer.customersContacts
                ? lead.customer.customersContacts
                : diary[keyToUpdate].customer.customerContacts,
            }
          : diary[keyToUpdate].customer,
        wanted_areas: lead.wanted_areas
          ? lead.wanted_areas.map((area) => {
              return { area: { id: area.value, name: area.label } }
            })
          : diary[keyToUpdate].wanted_areas,
      },
    })
  }
  const additionalInfoToggle = () => {
    setAdditionalDetailsModal(!additionalDetailsModal)
  }

  useEffect(() => {
    setNextFlow()
  }, [connectFeedback])

  const handleQueryChange = (currentPage, pageSize) => {
    setPagination({ currentPage: currentPage, pageSize: pageSize })
    props.fetchDiaryTasks({ currentPage: currentPage, pageSize: pageSize })
  }

  const shareToggle = (shareLeadType) => {
    setShareToggleState(!shareToggleState)
    setShareLeadType(shareLeadType)
  }

  const fetchLeadTimeLine = (id) => {
    props.fetchLeadTimeLine(id)
    props.fetchLeads()
  }

  const fetchAgents = (data, page, LeadType) => {
    let leadTypeParam = ''
    if (diary.armsLead) {
      leadTypeParam = '&leadType=BuyRent'
    } else if (diary.armsProjectLead) {
      leadTypeParam = '&leadType=Project'
    }
    let url = ''

    if(LeadType !== 'assign') {
      let allOrg = shouldShowAllLeads(props.user)
      url = `${
        config.apiPath
      }/api/user/agents?sharing=${true}&page=${page}&pageSize=${config.pageSize}&web=true${leadTypeParam}`
      if(allOrg)
        url += '&allOrg=true'
    } else {

      const isGroupManager = localStorage.getItem('groupManager')

      let groupManagerParam = ''
      if (
        (isGroupManager && isGroupManager === true) ||
        (isGroupManager && isGroupManager.toLocaleLowerCase() === 'true')
      ) {
        groupManagerParam = `&groupManager=true`
      }

      url = `${config.apiPath}/api/role/sub-users?roleId=${
        props.user && props.user.id
      }&addManager=${true}${groupManagerParam}`
    }

    setShareloader(true)
    axios
      .get(url)
      .then((res) => {
        setShareloader(false)
        setAgents(LeadType === 'assign' ? res.data : res.data.rows)
        setAgentCount(LeadType === 'assign' ? res.data.length : res.data.count)
      })
  }

  const filterReferLeadAgentName = (filterName, page) => {
    setShareloader(true)
    let leadTypeParam = ''
    if (diary.armsLead) {
      leadTypeParam = '&leadType=BuyRent'
    } else if (diary.armsProjectLead) {
      leadTypeParam = '&leadType=Project'
    }
    let url = `${
      config.apiPath
    }/api/user/agents?sharing=${true}&page=${page}&pageSize=${config.pageSize}&web=true&name=${filterName}${leadTypeParam}`
    let allOrg = shouldShowAllLeads(props.user)
    if(allOrg)
      url += '&allOrg=true'
    axios
      .get(url)
      .then((res) => {
        setShareloader(false)
        setAgents(res.data.rows)
        setAgentCount(shareLeadType === 'assign' ? res.data.length : res.data.count)
      })
  }

  const investmentLeadReferFun = (id, firtName, lastName) => {
    let CheckLeadType = ''
    let LeadId = null
    if (diary.armsLead) {
      CheckLeadType = 'buy-rent'
      LeadId = diary.armsLead.id
    } else if (diary.wanted) {
      CheckLeadType = 'wanted'
      LeadId = diary.wanted.id
    } else {
      CheckLeadType = 'investment'
      LeadId = diary.armsProjectLead.id
    }
    const user_id = JSON.parse(localStorage.getItem('user_id'))
    // let LeadId = null
    const { addInvestmentShareLead, fetchLeadTimeLine, fetchLeads } = props
    // if (diary.armsLeadId !== null) {
    //   LeadId = diary.armsLeadId
    // } else if (diary.armsProjectLeadId !== null) {
    //   LeadId = diary.armsProjectLeadId
    // }
    //const user_id = localStorage.getItem('user_id')
    let lead = diary.armsLead || diary.armsProjectLead || diary.wanted
    let shareData = {
      userId: id,
      leadId: LeadId,
      //leadId : singleLeadIdState,
    }
    setShareToggleState(false)
    const showLeadReferSuccessMsgHandler = () => {
      setTimeout(() => {
        showToastMsg(
          `Lead has successfully been referred to <b>${firtName + ' ' + lastName} </b>`,
          'success'
        )
        fetchLeads()
        props.fetchDiaryTasks(pagination)
      }, 800)
    }
    addInvestmentShareLead(
      shareData,
      fetchLeadTimeLine,
      singleLeadIdState,
      CheckLeadType,
      showLeadReferSuccessMsgHandler
    )

    fetchLeads()
  }

  // for reassign
  const localStateUpdate = (id) => {
    setSingleLeadIdState(id)
    props.updatePropState(id)
    props.setSelectedLeadId(id)
  }

  const setSelectedLead = (lead) => {
    localStateUpdate(lead.id)
    props.setSelectedLead(lead)
  }

  const isDiaryView = () => {
    if (window.location.pathname.includes('diary')) return true
    else if (window.location.pathname.includes('overdue')) return false
    else return null
  }

  const assignLeadFun = (id, firstName, lastName) => {
    let CheckLeadType = ''
    if (diary.armsLead) {
      CheckLeadType = 'buyRent'
    } else if (diary.wanted) {
      CheckLeadType = 'wanted'
    } else {
      CheckLeadType = 'investment'
    }
    let lead = diary.armsLead || diary.armsProjectLead || diary.wanted
    let space = ' '
    let assignData = {
      leadId: [lead.id],
      type: CheckLeadType,
      userId: id,
    }
    axios.patch(`${config.apiPath}/api/leads/assign`, assignData).then((res) => {
      setShareToggleState(false)
      showToastMsg(
        `Lead re-assign to <b>${firstName + space + lastName}</b> Successfully`,
        'success'
      )
      props.fetchDiaryTasks(pagination)
    })
  }

  const renderConnectFlowUpdateTask = (bookedTime) => {
    setSlotModal(false)
    let payload = {
      ...newTask,
      otherTasksToUpdate: newTask.otherTasksToUpdate.map((task) => {
        return {
          ...task,
          status: cancelAction ? 'cancelled' : 'completed',
        }
      }),
      status: cancelAction ? 'cancelled' : 'completed',
    }
    if (
      connectFeedback.section === 'Actions' &&
      [FA.RESCHEDULE_MEETING, FA.RESCHEDULE_VIEWING].indexOf(nextAction) > -1
    ) {
      payload = {
        ...payload,
        status: 'pending',
        makeHistory: true,
        start: bookedTime ? moment(bookedTime.startTime).format('YYYY-MM-DDTHH:mm:ssZ') : '',
        diaryTime: bookedTime ? moment(bookedTime.startTime).format('YYYY-MM-DDTHH:mm:ssZ') : '',
        date: bookedTime ? moment(bookedTime.date).format('YYYY-MM-DDTHH:mm:ssZ') : '',
        end: bookedTime ? moment(bookedTime.endTime).format('YYYY-MM-DDTHH:mm:ssZ') : '',
        slots:
          bookedTime && bookedTime.slots
            ? bookedTime.slots.map((slot) => {
                return slot.id
              })
            : '',
      }
    }
    const newTaskType =
      connectFeedback.section === 'Actions' &&
      [FA.ADD_MEETING, FA.SETUP_ANOTHER_MEETING].indexOf(nextAction) > -1
        ? 'meeting'
        : ["Couldn't Connect", 'Follow up', 'Cancel Viewing', 'Cancel Meeting'].indexOf(
            connectFeedback.section
          ) > -1
        ? 'follow_up'
        : null

    saveOrUpdateDiaryTask(payload, newTaskType ? false : true, () => {
      if (nextAction === FA.RESCHEDULE_VIEWING) props.fetchLeadScheduledProperties(diary.armsLeadId)

      if (newTaskType)
        saveOrUpdateDiaryTask(
          makeTaskTimePayload(bookedTime, newTaskType, {
            notes: newTask.response,
            reasonId: connectFeedback.id,
            reasonTag: connectFeedback.tag,
            [getLeadType(diary) === 'Project'
              ? 'leadId'
              : getLeadType(diary) === 'BuyRent'
              ? 'armsLeadId'
              : 'wantedId']:
              diary && (diary.armsLeadId || diary.armsProjectLeadId || diary.wantedId),
          }),
          true
        )
    })
    if (nextAction !== FA.RESCHEDULE_VIEWING) {
      setConnectFeedback({})
      setNewTask(null)
    }
    setCancelAction(false)
  }

  return (
    getPermissionValue(PermissionFeatures.DIARY, PermissionActions.READ) && (
      <>
        {props.selectedAgent && (
          <div className="read__only">
            <img src={ReadOnly} />
            <b>
              You are viewing{' '}
              {props.selectedAgent.firstName + ' ' + props.selectedAgent.lastName + "'s "} Diary.
            </b>{' '}
            Click{' '}
            <a
              style={{ color: '#007bff', textDecoration: 'underline', cursor: 'pointer' }}
              onClick={() => {
                props.setDiaryMenuAction(DIARY_MENU_ACTION.VIEW_MY_DIARY)
              }}
            >
              here
            </a>{' '}
            to view your Diary.
          </div>
        )}
        <div
          className={`my-3 ${
            props.screen === 'lead-task' ? 'mx-0 p-1' : 'mx-2 p-3'
          } rounded border`}
          style={{
            backgroundColor: 'white',
            boxShadow: props.isShowDiary ? '0px 0px 9px 0px' : 'none',
            overflow: props.isShowDiary ? 'auto' : 'inherit',
            maxHeight: props.noPaginate ? 600 : props.isShowDiary ? 'calc(100vh - 150px)' : 'calc(100vh - 160px)',
            height:
              props.isShowDiary && (props.noPaginate || (diaryData && diaryData.length <= 0))
              ? '70%'
              : props.noPaginate || (diaryData && diaryData.length <= 0)
                ? 'unset'
                : props.isShowDiary ? '60%'  : 'calc(100vh - 160px)',
          }}
        >
          {isLoading ? (
            <div style={{minWidth: '680px'}}>
              <Load title="Diary"></Load>
            </div>
          ) : diaryData && diaryData.length > 0 ? (
            <>
              <div className={'row'}>
                <div className={'col-md-6 justify-content-center flex-btns font-sm'}>
                  <DiaryDateQuickFilter
                    selectedFilters={props.diaryQuickFilter || []}
                    setSelectedQuickFilter={(quickFilter) => {
                      props.setIsOverDue(false)
                      props.setDiaryQuickFilters(quickFilter, true)
                    }}
                  ></DiaryDateQuickFilter>
                </div>
              </div>
              <Diary
                isShowShortDiary={props.isShowDiary}
                setGuideReferenceModal={(toggle) => setGuideReferenceModal(toggle)}
                setAgain={(toggle) => setAgain(toggle)}
                setActionType={(actionType) => setActionType(actionType)}
                setDiary={(diary) => {
                  if (diary) {
                    let res = getLeadType(diary)
                    setLeadTypeParam(res)
                    if (diary.armsProjectLeadId || diary.armsLeadId) {
                      fetchSingleLeadProps(
                        res === 'Project' ? diary.armsProjectLeadId : diary.armsLeadId,
                        res === 'Project' ? true : false,
                        null
                      )
                    }
                  }
                  setDiary(diary)
                }}
                // selectedDiaryTask={diary}
                setAction={(action) => setAction(action)}
                setDiaryAction={(action) => setDiaryAction(action)}
                setConnectModal={(toggle) => {
                  setDiaryModal(false)
                  setConnectModal(toggle)
                }}
                setAdditionalDetailsModal={(toggle) => setAdditionalDetailsModal(toggle)}
                setReferModal={(share) => shareToggle(share)}
                callAgentsfun={(toggle, page, LeadType) => fetchAgents(toggle, page, LeadType)}
                setDiaryModal={() => toggleTaskToggle()}
                setDeleteDiary={() => deleteDiary(diary)}
                data={diaryData}
                dataCount={diaryDataCount}
                onChangePage={handleQueryChange}
                pagination={pagination}
                setNextAction={(action) => setNextAction(action)}
                assignedToMe={
                  diary &&
                  diary.armsLead &&
                  props.user &&
                  diary.armsLead.assigned_to_armsuser_id === props.user.id
                    ? true
                    : false
                }
                isReadOnly={
                  props.selectedAgent ||
                  !getPermissionValue(PermissionFeatures.DIARY, PermissionActions.UPDATE)
                    ? true
                    : props.checkForRead
                      ? props.checkForRead
                      : false
                }

                rescheduleViewing={() => {
                  setReschedule('viewing')

                  setCancelAction(false)
                  setNextAction(FA.RESCHEDULE_VIEWING)
                  props.fetchLeadScheduledProperties(diary.armsLeadId)
                  setRescheduleViewingModal(true)
                }}
                rescheduleMeeting={() => {
                  setReschedule('meeting')
                  setCancelAction(false)
                  setNextAction(FA.RESCHEDULE_MEETING)
                  setSlotModal(true)
                }}
              ></Diary>
            </>

          ) : (
            <>
              <div style={{minWidth: '680px'}}>
              <div className={'row'}>
                <div className={'col-md-6 justify-content-center flex-btns font-sm'}>
                  <DiaryDateQuickFilter
                    selectedFilters={props.diaryQuickFilter || []}
                    setSelectedQuickFilter={(quickFilter) => {
                      props.setIsOverDue(false)
                      props.setDiaryQuickFilters(quickFilter, true)
                    }}
                  ></DiaryDateQuickFilter>
                </div>
              </div>
              <div className="text-center">
                <h5 className="dimgray">No Records Found</h5>
              </div>
              </div>
            </>

          )}

          <AgentsModel
            onSelectAgent={(agent) => {
              props.setSelectedAgent(agent)
            }}
            open={
              props.diaryHeaderActions &&
              props.diaryHeaderActions === DIARY_MENU_ACTION.VIEW_AGENT_DIARY
                ? true
                : false
            }
            toggle={() => {
              props.setDiaryMenuAction(null)
            }}
            user={props.user}
          ></AgentsModel>

          <ShiftReport
            open={
              props.diaryHeaderActions &&
              props.diaryHeaderActions === DIARY_MENU_ACTION.VIEW_DIALY_REPORT
                ? true
                : false
            }
            toggle={() => {
              props.setDiaryMenuAction(null)
            }}
            user={props.user}
          ></ShiftReport>

          {connectModal && diary && (diary.armsLead || diary.armsProjectLead || diary.wanted) ? (
            <ConnectModal
              shouldAssign={shouldAssign}
              setShouldAssign={(shouldAssign) => setShouldAssign(shouldAssign)}
              isReadOnly={props.selectedAgent ? true : false}
              action={action}
              connectModal={connectModal}
              again={again}
              diary={diary}
              leadType={getLeadType(diary)}
              callBack={() => props.fetchDiaryTasks(pagination, '', isOverDue())}
              updateDiary={(lead) => updateDiary(lead)}
              lead={diary && (diary.armsLead || diary.armsProjectLead || diary.wanted || {})}
              setConnectModal={(toggle) => {
                setConnectModal(toggle)
                setActionType(null)
              }}
              setNewTask={(newTask) => {
                setNewTask(newTask)
                setConnectModal(false)
                setConnectFeedbackModal(true)
              }}
              convertLead={(lead) => convertLead(lead, true)}
              updateLeadTasks={updateLeadTasks}
              onAction={(action) => {
                if (action === 'scheduled-task') {
                  setConnectModal(false)
                  setDiaryModal(true)
                } else if (action === 'additional-details') setAdditionalDetailsModal(true)
                else if (action === 'refer-lead') {
                  shareToggle('share')
                  fetchAgents(null, 1, 'share')
                } else if (action === 'reassign-lead') {
                  shareToggle('assign')
                  fetchAgents(null, 1, 'assign')
                } else if (action === 'investment-guide') setGuideReferenceModal(true)
                else if (action === 'close-as-duplicate') setCloseAsDuplicateModal(true)
                else if (action === 'close-as-lost') handleCloseAsLost()
                else if (action === 'add-meeting') {
                  setSlotModal(true)
                  setAddMeetingFromLeadDetails(true)
                }
              }}
            />
          ) : (
            <></>
          )}

          {additionalDetailsModal && (
            <AdditionalDetails
              onToggle={additionalInfoToggle}
              isOpen={additionalDetailsModal}
              additionalDetailLeadrecord={
                diary && (diary.armsLead || diary.armsProjectLead || diary.wanted || {})
              }
              checkLeadtype={checkLeadtype}
              leadType={getLeadType(diary)}
            ></AdditionalDetails>
          )}

          <ReferModal
            shareToggleState={shareToggleState}
            shareToggle={shareToggle}
            managerSelectionDropdown={managerSelectionDropdown}
            loader={shareloader}
            fetchAgents={fetchAgents}
            filterReferLeadAgentName={filterReferLeadAgentName}
            agents={agents}
            agentCount={agentCount}
            shareLeadType={shareLeadType}
            investmentLeadShareFun={investmentLeadReferFun}
            assignLeadFun={assignLeadFun}
          />

          {createTaskModalToggle ? (
            <DiaryTaskModal
              isReadOnly={props.selectedAgent ? true : props.checkForRead ? true : false}
              taskModalToggle={createTaskModalToggle}
              leadId={(diary && diary.armsLeadId) || ''}
              diary={diary}
              screen={props.screen ? props.screen : 'diary'}
              setDiary={(diary) => {
                if (diary) {
                  let res = getLeadType(diary)
                  setLeadTypeParam(res)
                  fetchSingleLeadProps(
                    res === 'Project' ? diary.armsProjectLeadId : diary.armsLeadId,
                    res === 'Project' ? true : false,
                    null
                  )
                }
                setDiary(diary)
              }}
              leadType={getLeadType(diary)}
              taskCategory={'simpleTask'}
              diaryAction={diaryAction ? diaryAction : 'add'}
              closeTaskModal={() => toggleTaskToggle()}
              fetchDiaryTasks={(leadType) => {
                if (leadType) {
                  let leadParam = 'projectId'
                  if (props.leadType === 'BuyRent') leadParam = 'buyrentId'
                  else if (props.leadType === 'Wanted') leadParam = 'wantedId'
                  props.fetchDiaryTasks(
                    pagination,
                    '',
                    null,
                    props.lead ? `${leadParam}=${props.lead.id}` : undefined,
                    props.lead ? 'pending' : undefined
                  )
                } else {
                  setPagination(defaultPage)
                  props.fetchDiaryTasks(defaultPage)
                }
              }}
            />
          ) : null}

          {connectFeedbackModal && (
            <ConnectFeedback
              canClose={canFeedbackModalClose}
              actionType={actionType}
              viewingProperties={
                props.leadScheduledProperties
                  ? getActiveBookingProperties(props.leadScheduledProperties.rows)
                  : []
              }
              lead={(diary && diary.armsProjectLead) || null}
              taskType={diary.taskType}
              taskStatus={diary.status}
              leadType={getLeadType(diary) || false}
              propertyId={diary.propertyId}
              taskId={diary.id}
              connectFeedback={connectFeedback}
              setNextFlow={(feedback) => setConnectFeedback(feedback)}
              setNextAction={(action, feedback, selectedProperty) => {
                setSelectedProperty(selectedProperty)
                setNewTask({
                  id: diary.id,
                  taskType: diary.taskType,
                  otherTasksToUpdate: feedback.otherTasksToUpdate.map((task) => {
                    return {
                      id: task.id,
                      response: feedback.comment,
                      comments: feedback.comment,
                      feedbackTag: feedback.tag,
                      feedbackId: feedback.id,
                    }
                  }),
                  response: feedback.comment,
                  comments: feedback.comment,
                  feedbackTag: feedback.tag,
                  feedbackId: feedback.id,
                })
                setConnectFeedback(feedback)
                setNextAction(action)
                setConnectFeedbackModal(false)
              }}
              connectFeedbackModal={connectFeedbackModal}
              setConnectFeedbackModal={(toggle) => {
                setConnectFeedbackModal(toggle)
                setCanFeedbackModalClose(false)
              }}
            />
          )}

          {connectFeedback && reConnect && (
            <>
              <ReConnect
                reConnect={reConnect}
                loader={btnLoader}
                setLoader={(toggle) => setBtnLoader(toggle)}
                setAgainOrDoNothing={(action) => {
                  setReConnect(false)
                  if (action === 'again') {
                    setAgain(true)
                    setConnectModal(true)
                  }
                  saveOrUpdateDiaryTask(
                    {
                      ...newTask,
                      makeHistory: true,
                      otherTasksToUpdate: connectFeedback.otherTasksToUpdate.map((task) => {
                        return {
                          ...newTask,
                          id: task.id,
                          makeHistory: true,
                          comments: task.comment,
                          response: task.comment,
                          feedbackId: connectFeedback.id,
                          feedbackTag: connectFeedback.tag,
                        }
                      }),
                    },
                    false,
                    () => {
                      setConnectFeedback({})
                    }
                  )
                }}
                taskType={diary.taskType}
                again={(again) => again}
                setFollowUpSetup={(toggle, cancelAction = false) => {
                  if (cancelAction && diary.taskType === 'viewing') {
                    props.fetchLeadScheduledProperties(diary.armsLeadId)
                    setRescheduleViewingModal(true)
                  } else setSlotModal(true)
                  setReConnect(false)
                  setCancelAction(cancelAction)
                }}
                setReConnect={(toggle) => setReConnect(toggle)}
              />
            </>
          )}

          {rwrModal && (
            <RWR
              connectFeedback={connectFeedback}
              rwrModal={rwrModal}
              singleLeadRecord={singleLeadRecord}
              lead={diary && (diary.armsLead || diary.armsProjectLead || diary.wanted || {})}
              leadType={getLeadType(diary)}
              setRwrModal={(toggle) => setRwrModal(toggle)}
              callback={() => {
                saveOrUpdateDiaryTask({
                  id: diary.id,
                  response: connectFeedback.comment,
                  feedbackId: connectFeedback.id,
                  feedbackTag: connectFeedback.tag,
                  status: 'completed',
                })
              }}
            />
          )}
          {shortListPropertyModal && (
            <ShortListedPropertiesModal
              toggleButton={() => setShortListPropertyModal(false)}
              singleLeadRecord={diary && diary.armsLead}
              shortListedPropertiesToggle={(toggle) => setShortListPropertyModal(true)}
            />
          )}

          {offerWorkFlow && (
            <OfferWorkflow
              property={property}
              user={user}
              singleLeadRecord={diary && diary.armsLead}
              setViewingModal={(toggle) => setOfferWorkFlow(toggle)}
              viewingModal={offerWorkFlow}
            ></OfferWorkflow>
          )}
          {viewingModal && (
            <ViewingScreen
              property={property}
              singleLeadRecord={diary && diary.armsLead}
              selectedViewing={0}
              setTempSelectedProperties={(properties) => {}}
              tempSelectedProperties={[]}
              user={user}
              viewingModal={viewingModal}
              setViewingModal={(toggle) => setViewingModal(toggle)}
            />
          )}

          {rescheduleViewingModal && (
            <RescheduleViewing
              properties={
                props.leadScheduledProperties
                  ? getActiveBookingProperties(props.leadScheduledProperties.rows)
                  : []
              }
              excludeDiaryId={diary.id}
              propertyId={diary.propertyId}
              modal={rescheduleViewingModal}
              setModal={(toggle) => {
                if (!toggle) {
                  setConnectFeedback({})
                  setNewTask(null)
                }
                setRescheduleViewingModal(toggle)
              }}
              setSlotModal={(toggle) => setSlotModal(toggle)}
              action={nextAction === FA.RESCHEDULE_VIEWING ? 'Reschedule' : 'Cancel'}
              setTask={(task) =>
                setNewTask({
                  ...newTask,
                  id: task.id,
                })
              }
              callback={(selectedDiaryIds) => {
                setRescheduleViewingModal(false)
                if (cancelAction) {
                  // ask for follow up only when all viewing are cancelled
                  if (
                    props.leadScheduledProperties &&
                    getActiveBookingProperties(props.leadScheduledProperties.rows).length ===
                      selectedDiaryIds.length + 1
                  ) {
                    setNewTask({
                      ...newTask,
                      otherTasksToUpdate: selectedDiaryIds.map((diaryId) => {
                        return {
                          ...newTask,
                          id: diaryId,
                        }
                      }),
                    })
                    setSlotModal(true)
                  } else {
                    // no followup - just cancelled the selected viewings
                    saveOrUpdateDiaryTask(
                      {
                        ...newTask,
                        status: 'cancelled',
                        otherTasksToUpdate: selectedDiaryIds.map((diaryId) => {
                          return {
                            ...newTask,
                            id: diaryId,
                            status: 'cancelled',
                          }
                        }),
                      },
                      false,
                      () => {
                        viewingCancelled()
                        setNextAction(null)
                      }
                    )
                  }
                } else setNextAction(null)
              }}
            />
          )}

          <SlotModal
            slotModal={slotModal}
            setSlotModal={setSlotModal}
            title={`Select slot for ${
              (connectFeedback.section === 'Actions' &&
                [FA.ADD_MEETING, FA.SETUP_ANOTHER_MEETING, FA.RESCHEDULE_MEETING].indexOf(
                  nextAction
                ) > -1) ||
              addMeetingFromLeadDetails
                ? 'Meeting task'
                : ["Couldn't Connect", 'Follow up', 'Cancel Viewing', 'Cancel Meeting'].indexOf(
                    connectFeedback.section
                  ) > -1
                ? 'Follow-up task'
                : connectFeedback.section === 'Actions' && FA.RESCHEDULE_VIEWING === nextAction
                ? 'Viewing'
                : reschedule
                ? reschedule
                : 'task'
            }`}
            taskType={
              (connectFeedback.section === 'Actions' &&
                [FA.ADD_MEETING, FA.SETUP_ANOTHER_MEETING, FA.RESCHEDULE_MEETING].indexOf(
                  nextAction
                ) > -1) ||
              addMeetingFromLeadDetails
                ? 'meeting'
                : ["Couldn't Connect", 'Follow up', 'Cancel Viewing', 'Cancel Meeting'].indexOf(
                    connectFeedback.section
                  ) > -1
                ? 'follow-up'
                : connectFeedback.section === 'Actions' && FA.RESCHEDULE_VIEWING === nextAction
                ? 'viewing'
                : reschedule
                ? reschedule
                : null
            }
            callBack={(bookedTime) => {
              setSlotModal(false)
              if (addMeetingFromLeadDetails) {
                saveOrUpdateDiaryTask(
                  makeTaskTimePayload(bookedTime, 'meeting', {
                    [getLeadType(diary) === 'Project'
                      ? 'leadId'
                      : getLeadType(diary) === 'BuyRent'
                      ? 'armsLeadId'
                      : 'wantedId']:
                      diary && (diary.armsLeadId || diary.armsProjectLeadId || diary.wantedId),
                  }),
                  true,
                  () => setUpdateLeadTasks(!updateLeadTasks)
                )
                setAddMeetingFromLeadDetails(false)
              } else if (reschedule) {
                saveOrUpdateDiaryTask(
                  makeTaskTimePayload(bookedTime, reschedule, {
                    [getLeadType(diary) === 'Project'
                      ? 'leadId'
                      : getLeadType(diary) === 'BuyRent'
                      ? 'armsLeadId'
                      : 'wantedId']:
                      diary && (diary.armsLeadId || diary.armsProjectLeadId || diary.wantedId),
                    id: diary.id,
                  }),
                  true
                )
                setRescheduleViewingModal(false)
                setReschedule(null)
              } else renderConnectFlowUpdateTask(bookedTime)
            }}
          />

          {guideReferenceModal && (
            <ReferenceGuideModal
              leadId={diary && diary.armsProjectLeadId}
              callBack={() => {
                setGuideReferenceModal(false)
                let leadParam = 'projectId'
                if (props.leadType === 'BuyRent') leadParam = 'buyrentId'
                else if (props.leadType === 'Wanted') leadParam = 'wantedId'
                props.fetchDiaryTasks(
                  pagination,
                  '',
                  null,
                  props.lead ? `${leadParam}=${props.lead.id}` : undefined,
                  props.lead ? 'pending' : undefined
                )
              }}
              guideReferenceModal={guideReferenceModal}
              setGuideReferenceModal={(toggle) => setGuideReferenceModal(toggle)}
            />
          )}

          {diaryModal && (
            <DiaryModal
              leadType={getLeadType(diary)}
              checkForRead={isReadOnly(
                diary && (diary.armsLead || diary.armsProjectLead || diary.wanted || {}),
                getLeadType(diary)
              )}
              diaryModal={diaryModal}
              setDiaryModal={(toggle) => setDiaryModal(toggle)}
              setLeadTaskModal={(toggle) => toggleTaskToggle(true) }
              fetchLeadsInModal={false}
              setFetchLeadsInModal={(toggle) => {}}
              lead={diary && (diary.armsLead || diary.armsProjectLead || diary.wanted || {})}
            />
          )}

          {closeAsDuplicateModal && (
            <CloseAsDuplicateModal
              lead={diary && (diary.armsLead || diary.armsProjectLead || diary.wanted || {})}
              leadType={getLeadType(diary)}
              modal={closeAsDuplicateModal}
              setModal={(toggle) => setCloseAsDuplicateModal(toggle)}
              callback={() => {
                setCloseAsDuplicateModal(false)
                let leadParam = 'projectId'
                if (props.leadType === 'BuyRent') leadParam = 'buyrentId'
                else if (props.leadType === 'Wanted') leadParam = 'wantedId'
                props.fetchDiaryTasks(
                  pagination,
                  '',
                  null,
                  props.lead ? `${leadParam}=${props.lead.id}` : undefined,
                  props.lead ? 'pending' : undefined
                )
              }}
            />
          )}

          {closeAsLostModal && (
            <CloseAsLostModal
              lead={diary && (diary.armsLead || diary.armsProjectLead || diary.wanted || {})}
              leadType={getLeadType(diary)}
              modal={closeAsLostModal}
              setModal={(toggle) => setCloseAsLostModal(toggle)}
              callback={() => {
                setCloseAsLostModal(false)
                let leadParam = 'projectId'
                if (props.leadType === 'BuyRent') leadParam = 'buyrentId'
                else if (props.leadType === 'Wanted') leadParam = 'wantedId'
                props.fetchDiaryTasks(
                  pagination,
                  '',
                  null,
                  props.lead ? `${leadParam}=${props.lead.id}` : undefined,
                  props.lead ? 'pending' : undefined
                )
              }}
            />
          )}
        </div>
      </>
    )
  )
}

export const mapDispatchToProps = (dispatch) => {
  return {
    fetchUser: () => dispatch(UserInfoFetch()),
    diaryCreateTaskToggle: () => dispatch(diaryCreateTaskToggle()),
    fetchDiaryTasks: (pagination, filters, isOverDue, leadParam, status, leadTask,isShowShortDiary) =>
      dispatch(getDiaryTasks(pagination, filters, isOverDue, leadParam, status, leadTask,isShowShortDiary)),
    setOverDueCount: () => dispatch(setDiaryOverDueCount()),
    setMoreFilter: (status) => dispatch(setMoreFilter(status)),
    setGeneralDataSort: (dataSort) => dispatch(setDataSort(dataSort)),
    setQuickFilters: (quickFilters) => dispatch(setQuickFilter(quickFilters)),
    fetchCities: (callback) => dispatch(fetchCities(callback)),
    setIsOverDue: (isOverDue) => dispatch(setIsOverDue(isOverDue)),

    setDiaryFilters: (filters) => dispatch(setDiaryFilters(filters)),
    // setDiaryDataSort: (dataSort) => dispatch(setDiaryDataSort(dataSort)),
    setDiaryQuickFilters: (quickFilters, isChange) => dispatch(setDiaryQuickFilters(quickFilters, isChange)),
    setOverDueFilters: (overDueFilters) => dispatch(setOverDueFilters(overDueFilters)),
    // setOverDueDataSort: (dataSort) => dispatch(setOverDueDataSort(dataSort)),
    // setOverDueQuickFilters: (overDueQuickFilters) =>
    //   dispatch(setOverDueQuickFilters(overDueQuickFilters)),
    setDiaryMenuAction: (action) => dispatch(setDiaryMenuAction(action)),

    setSelectedAgent: (agent) => dispatch(setSelectedAgent(agent)),
    fetchLeads: (query, sortingOrder) => dispatch(fetchLeads(query, sortingOrder)),
    addInvestmentShareLead: (
      obj,
      callback,
      leadId,
      CheckLeadType,
      showLeadReferSuccessMsgHandler
    ) =>
      dispatch(
        investmentShareLead(obj, callback, leadId, CheckLeadType, showLeadReferSuccessMsgHandler)
      ),
    fetchLeadTimeLine: (id) => dispatch(fetchLeadTimeLine(id)),
    fetchLeadScheduledProperties: (id) => dispatch(fetchAllShortlistedProperties(id)),
    setTitle: (title) => dispatch(setTitle(title)),
    fetchSingleLeadProps: (leadId, byProject, callBack) =>
      dispatch(fetchSingleLead(leadId, byProject, callBack)),
    //fetchAllProjects: () => dispatch(fetchAllProjects()),
  }
}

export const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user.userInfo,
    createTaskModalToggle: state.Diary.createTaskModalToggle,
    diaryTasks: ownProps.lead ? state.Diary.leadDiaryTasks : state.Diary.diaryTasks,
    isLoading: ownProps.lead ? state.Diary.isLoadingLeadTask : state.Diary.isLoading,
    moreFilter: state.GeneralSetting.moreFilter,
    quickFilter: state.GeneralSetting.quickFilter,
    generalDataSort: state.GeneralSetting.dataSort,
    diaryDataSort: state.Diary.filters.diary.dataSort,
    overDueDataSort: state.Diary.filters.overDue.dataSort,
    singleLeadRecord: state.Leads.singleLeadRecord,
    diaryHeaderActions: state.Diary.menuAction,

    diaryFilters: state.Diary.filters.diary.filters,
    diaryQuickFilter: state.Diary.filters.diary.quickFilters,
    overDueFilters: state.Diary.filters.overDue.filters,
    overDueQuickFilters: state.Diary.filters.overDue.quickFilters,

    selectedAgent: state.Diary.selectedAgent,

    leadScheduledProperties: state.Leads.buyRentShortListProperties,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DiaryViewModal))
DiaryViewModal.defaultProps = {
  quickFilter: [],
  diaryQuickFilter: [],
  overDueQuickFilters: [],
}
