import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import AddProperty from '../../AddProperty'

const AddPropertyModal = (props) => {

  return (
    <Modal
      isOpen={props.propertyModal}
      toggle={(toggle) => props.setPropertyModal(!toggle)}
      size={'lg'}
    >
      <ModalBody style={{marginBottom: 50, height: 'calc(100vh - 110px)'}}>
        <div className="close-icon-container">
          <i
            className="fal fa-times-circle close-icon"
            onClick={() => props.setPropertyModal(false)}
          ></i>
        </div>
        <h4>{props.propertyId ? 'Edit Property' : 'Add Property'}</h4>
        <div style={{height: 'calc(100vh - 170px)', overflowY: 'auto'}}>
          <AddProperty
            purpose={props.purpose}
            defaultCustomer={props.defaultCustomer}
            wantedId={props.wantedId} propertyId={props.propertyId} popup={true} callBack={(action, data) => {
            props.setPropertyModal(false)
            props.callBack(action, data)
          }} />
        </div>

      </ModalBody>
    </Modal>
  )
}

export const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export const mapStateToProps = (state) => {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AddPropertyModal))
