/** @format */
import moment, { isMoment } from 'moment'
import { capitalize_Words } from '../../../../utils/commonFormat'
import { ColumnName } from '../../../TableConfig/BuyRentLeadConfig'
import React from 'react'
import { normalizePhoneFormat } from '../../../../utils/formatPhone'
import axios from 'axios'
import config from '../../../../config'
import Swal from 'sweetalert2'
import { getPermissionValue } from '../../../_unlockComponent'
import { PermissionActions, PermissionFeatures } from '../../../../utils/permissionConstants'
import { BUY_RENT_LEAD_TYPE_SALE, ReferedBuyRentLeadUrl } from '../../../../utils/constant_data'
import { isREA } from '../../../../utils/helper'
import { getLeadTag, leadStatusColor, isLeadClosed } from '../../../../utils/lead'
import YellowLead from '../../../../images/yellow-marker.svg'
import RedLead from '../../../../images/red-marker.svg'
import { RenderLeadStatus } from '../../../../utils/general'
import { StaticData } from '../../../../StaticData'

export const getPropValue = (templateProperty, cellIndex, rowIndex, rowData, props, columnName) => {
  switch (columnName) {
    case ColumnName.LEAD_ID:
      if (templateProperty === 'label') {
        let tag = ''
        if (props.page !== ReferedBuyRentLeadUrl) {
          tag = getLeadTag(rowData)
          tag =
            tag && ['delay__30__60', 'delay__60__above'].indexOf(tag) === -1 ? (
              ['shared', 'Referred'].indexOf(tag) > -1 ? (
                <span className="statusText investLeadStatus shared">Referred</span>
              ) : tag === 'new' ? (
                <span className="statusText investLeadStatus new">New</span>
              ) : (
                ''
              )
            ) : (
              ''
            )
        }
        return (
          <>
            <span className="buyrent-lead-link">{rowData.id}</span>
            {tag}
          </>
        )
      }
      break
    case ColumnName.LEAD_TYPE:
      if (templateProperty === 'label') {
        return rowData.purpose
          ? capitalize_Words(
              rowData && rowData.purpose === 'sale' ? BUY_RENT_LEAD_TYPE_SALE : rowData.purpose
            )
          : '--'
      }
      break
    case ColumnName.TIME_STATUS:
      if (templateProperty === 'label' && props.serverTime) {
        const leadTag = getLeadTag(rowData, props.serverTime)
        return ['delay__30__60', 'delay__60__above'].indexOf(leadTag) > -1 ? (
          <img src={leadTag === 'delay__30__60' ? YellowLead : RedLead} style={{ width: 12 }} />
        ) : (
          ''
        )
      }
      break
    case ColumnName.CLIENT_NAME:
      if (templateProperty === 'label') {
        return rowData.customer && rowData.customer.customerName !== ' '
          ? rowData.customer && rowData.customer.customerName
          : '--'
      }
      break
    case ColumnName.PROPERTY_TYPE:
      if (templateProperty === 'label') {
        return rowData.type ? capitalize_Words(rowData.type) : '--'
      }
      break
    case ColumnName.PROPERTY_SUB_TYPE:
      if (templateProperty === 'label') {
        return rowData.subtype ? capitalize_Words(rowData.subtype) : '--'
      }
      break
    case ColumnName.TRANSFERRED_FROM:
      if (templateProperty === 'label')
        return rowData.transferFrom
          ? `${rowData.transferFrom.firstName} ${rowData.transferFrom.lastName}`
          : '--'
      break
    case ColumnName.TRANSFERRED_DATE:
      if (templateProperty === 'label')
        return rowData.transferedDate
          ? moment(rowData.transferedDate).format('D MMM, h:mm a')
          : '--'
      break
    case ColumnName.AREA:
      let area = ''
      if (templateProperty === 'label') {
        if (rowData.armsLeadAreas && rowData.armsLeadAreas.length) {
          rowData.armsLeadAreas.map((childItem, index) => {
            if (childItem.area) area += `${(index ? ', ' : '') + childItem.area.name}`
          })
        }
        return area || '--'
      }
      break
    case ColumnName.CITY:
      if (templateProperty === 'label')
        return rowData.city && rowData.city != null && rowData.city.name != null
          ? rowData.city.name
          : '--'
      break
    case ColumnName.STATUS:
      const { background, textColor } = leadStatusColor(rowData.status.toLowerCase())

      if (templateProperty === 'text') {
        return capitalize_Words(
          RenderLeadStatus(rowData.status, StaticData.projectLeadStatus, true)
        )
      } else if (templateProperty === 'chipColor') {
        return background
      } else if (templateProperty === 'textColor') {
        return textColor
      }
      break
    case ColumnName.ASSIGNED_TO:
      if (templateProperty === 'label')
        return rowData && rowData.armsuser
          ? rowData.armsuser &&
              capitalize_Words(rowData.armsuser.firstName) +
                ' ' +
                capitalize_Words(rowData.armsuser.lastName)
          : '--'
      break
    case ColumnName.ORIGIN:
      if (templateProperty === 'label') return rowData.origin ? rowData.origin : '--'
      break
    case ColumnName.ADVISOR_CONTACT_NO:
      if (templateProperty === 'label')
        return rowData.armsuser && rowData.armsuser.phoneNumber
          ? normalizePhoneFormat(rowData.armsuser.phoneNumber)
          : '--'
      break
    case ColumnName.WANTED_ID:
      if (templateProperty === 'label') return rowData.wanted_id || '--'
      break
    case ColumnName.CLOSING_REASON:
      if (templateProperty === 'label') return rowData.reason || '--'
      break
    case ColumnName.DATE_CREATED:
      if (templateProperty === 'label')
        return moment(rowData.createdAt).format('DD-MM-YYYY (hh:mm a)')
      break
    case ColumnName.ACTION:
      if (templateProperty === 'display')
        return rowData.requiredProperties ||
          props.hideAction ||
          // isLeadClosed(rowData) ||
          !getPermissionValue(PermissionFeatures.BUY_RENT_LEADS, PermissionActions.ASSIGN_REASSIGN)
          ? 'none'
          : 'block'
  }
}

export const fetchApplicableOrigins = (filters) => {
  if (filters['origin']) return `origin[]=${filters['origin'].value}`
  else if (filters.origin.value === 'ARMS') return 'origin[]=arms'
  else if (filters.origin.value === 'Wanted') return 'origin[]=pp-wanted&origin[]=aira'
  else if (filters.origin.value === 'Listing Inquiry')
    return 'origin[]=graana_property_page_lead&origin[]=agency21_website_property_page'
}

const arrayParams = ['purpose', 'time']

export const mapFiltersToQuery = (filters, quickFilters) => {
  const mapToParams = (filters, quickFilters) => {
    let queryParams = []
    if (filters) {
      let appliedFilters = Object.keys(filters)
      let filtersQueryArr = appliedFilters.map((key) => {
        if (typeof filters[key] === 'object') {
          if (isMoment(filters[key])) {
            return key + '=' + moment(filters[key]).format('YYYY-MM-DD')
          } else if (filters[key].value && Array.isArray(filters[key].value)) {
            return filters[key].value
              .map((feedback) => {
                if (arrayParams.indexOf(key) > -1) return key + '[]=' + feedback
                else return key + '=' + feedback
              })
              .join('&')
          } else {
            if (arrayParams.indexOf(key) > -1) return key + '[]=' + filters[key].value
            else {
              if (key === 'origin') return fetchApplicableOrigins(filters)
              else return key + '=' + filters[key].value
            }
          }
        } else {
          if (arrayParams.indexOf(key) > -1) return key + '[]=' + filters[key]
          else return key + '=' + filters[key]
        }
      })
      queryParams = [...filtersQueryArr]
    }
    if (quickFilters) {
      let quickFiltersQueryArr = quickFilters.map((quickFilter) => {
        if (arrayParams.indexOf(quickFilter.name) > -1)
          return quickFilter.name + '[]=' + quickFilter.value
        else return quickFilter.name + '=' + quickFilter.value
      })
      queryParams = [...queryParams, ...quickFiltersQueryArr]
    }
    return queryParams.join('&')
  }
  return mapToParams(filters, quickFilters)
}

export const assignLeadFun = (
  id,
  firstName,
  lastName,
  selectedLeads,
  filteredSelectedLeads,
  type,
  cb = null,
  is_confirm = false
) => {
  let finalLeadIds
  if (filteredSelectedLeads.length > 0) {
    finalLeadIds = selectedLeads.filter((value) => filteredSelectedLeads.includes(value))
  } else {
    finalLeadIds = selectedLeads
  }

  let assignData = {
    leadId: finalLeadIds,
    type: type,
    userId: id,
    web: true,
  }

  if (is_confirm) {
    assignData.skipDiffAgentWarning = true
  }

  axios.patch(`${config.apiPath}/api/leads/assign`, assignData).then((res) => {
    if (is_confirm || (res && res.data && res.data.message)) {
      Swal.fire(
        'Success',
        `Leads Re-assigned to <b>${firstName + ' ' + lastName}</b> successfully`,
        'success'
      )
    }
    cb && cb(res.data)
  })
}
