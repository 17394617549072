/** @format */

import axios from 'axios'
import config from '../config'

export const FETCH_TARGETS = 'FETCH_TARGETS'
export const FETCH_TARGET_LOADER = 'FETCH_TARGET_LOADER'
export const FETCH_TARGET_SUCCESS = 'FETCH_TARGET_SUCCESS'
export const FETCH_TARGET_FAILURE = 'FETCH_TARGET_FAILURE'

export const fetchTargets = (req, month, userId, own, flag) => {
  var endpoint = `${config.apiPath}/api/user/targ?targetMonth=${month}&page=${req.page}&pageSize=${
    req.pageSize
  }&userId=${userId !== undefined ? userId : ''}`
  if (req && req.offset && req.pageSize) {
    const { page, pageSize } = req
    endpoint = `${
      config.apiPath
    }/api/user/targ?targetMonth=${month}&page=${page}&pageSize=${pageSize}&userId=${
      userId !== undefined ? userId : ''
    }`
  } else if (req && req.all === true) {
    endpoint = `${config.apiPath}/api/user/targ?targetMonth=${month}&page=${req.page}&pageSize=${
      req.pageSize
    }&userId=${userId !== undefined ? userId : ''}`
  }
  return (dispatch) => {
    if (!flag) {
      dispatch({ type: FETCH_TARGET_LOADER, data: true })
    }

    axios
      .get(endpoint)
      .then(function (response) {
        if (!flag) {
          dispatch({ type: FETCH_TARGET_SUCCESS, data: false })
        }

        return dispatch({ type: FETCH_TARGETS, data: response.data })
      })
      .catch(function (xhr, status, err) {
        return dispatch({ type: FETCH_TARGET_FAILURE, data: xhr })
      })
  }
}
