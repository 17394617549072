import callAsterisk from '../../images/call3.svg'
import Swal from 'sweetalert2'
import axios from 'axios'
import config from '../../config'
import React, {useState} from 'react'
import {activateDialer} from "../../actions/diary";


export const CallIcon = (props) => {
  let {
    phone,
    dialCode,
    setPhone,
    agent,
    onCallCompleted,
    diaryId,
    callMethod,
    leadId,activateDialerClick
  } = props;
  const [showLoader, setShowLoader] = useState(false);
  return !showLoader ? (
    <img
      src={callAsterisk}
      onClick={() => {
        const PAK_COUNTRY_CODE = '+92'
        setPhone(phone)
        if (phone.includes(PAK_COUNTRY_CODE)) {
          phone = phone.replace(PAK_COUNTRY_CODE, '0')
        }
        if(phone.charAt(0) !== '+' && phone.charAt(0) !== '0') {
          if(!dialCode || dialCode === '92') {
            phone = '0' + phone;
          }
         // setPhone(phone)
        }


        let phone1 = phone;
        if (phone.trim().startsWith('92')) {
          phone1 = '0' + phone.trim().slice(2)
        }

        const customerCall = {
          phoneNumber:phone1,
          leadId
        };
        if (diaryId){
          customerCall.diaryId = diaryId;
        }
        console.log(customerCall,phone,'customer Call')
        setShowLoader(false)
        // props.mwsDialCall('03159322282')
        props.mwsDialCall(customerCall)

        return;

        if (agent.agentUser === null) {
          Swal.fire({
            title: '',
            html: `<div class='text-left'>Agent is not configured yet.</div>`,
            icon: 'warning'
          });
          return;
        }
        setShowLoader(true);

        let agentUser = agent.agentUser;
        let asteriskAPIPath = `${config.apiPath}/api/asterisk/makeCall?agentUser=${agentUser}&leadId=${leadId}&phoneNumber=${phone}`
        if (diaryId)
          asteriskAPIPath = `${asteriskAPIPath}&diaryId=${diaryId}`

        axios.get(asteriskAPIPath).then((res) => {
          const {success, data, message} = res.data
          if (success) {

            activateDialerClick(true);

            Swal.fire({
              title: '',
              html: `<div class='text-left'>
                                        ${(data.dial_status !==undefined)?`Dial Status : <b>${data.dial_status}</b><br/>`:''}
                                        Pause Status : <b>${data.pause_status}</b><br/>
                                        Hangup Status : <b>${data.hangup_status}</b><br/>
                                    </div>`,
              icon: 'success',
              width: 700,
            }).then(() => {
              onCallCompleted && onCallCompleted(callMethod, phone);
            });
          } else {
            console.log(message, 'message')
            Swal.fire({
              title: '',
              html: `<div class='text-left'>${message}</div>`,
              icon: 'error',
              width: 700,
            });
          }
          setShowLoader(false)
        }).catch((error) => {
          if (error.response) {
            Swal.fire({
              title: '',
              html: `<div class='text-left'>${error.response.data}</div>`,
              icon: 'error',
              width: 700,
            });
          }
          setShowLoader(false)
        });
      }}
      style={{marginLeft: '7px', cursor: 'pointer'}}
    />
  ) : (
    <span style={{color: '#fff', borderRadius: '10px'}} className={'show_loader badge bg-danger p-1 m-1'}><i
      className={'fa fa-spinner'}/></span>
  )
};
