import * as ActionTypes from "../actions/organizations";

const initialState = {};

const Organizations = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_DEFAULT_ORGANIZATIONS:
      return {
        ...state,
        organizations: action.data
      };
    case ActionTypes.FETCH_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        isLoading: true
      };
    case ActionTypes.FETCH_ORGANIZATIONS_FAIL:
      return {
        ...state,
        isLoading: false
      };
    default:
      return {
        ...state
      };
  }
};

export default Organizations;
