/** @format */

export const SETTING_TITLE = 'SETTING_TITLE'
export const MORE_FILTER = 'MORE_FILTER'
export const DATA_SORT = 'DATA_SORT'
export const QUICK_FILTER = 'QUICK_FILTER'
export const GENERAL_QUICK_FILTER = 'GENERAL_QUICK_FILTER'
export const RETAIN_WITHOUT_FILTER = 'RETAIN_WITHOUT_FILTER'
export const BACK_URL = 'BACK_URL'

export const backUrl = (url) => {
  return (dispatch) => {
    dispatch({ type: BACK_URL, data: url })
  }
}

export const setTitle = (title) => {
  return (dispatch) => {
    dispatch({ type: SETTING_TITLE, data: title })
  }
}

export const setMoreFilter = (data) => {
  return (dispatch) => {
    dispatch({ type: MORE_FILTER, data: data })
  }
}

export const setDataSort = (data) => {
  return (dispatch) => {
    dispatch({ type: DATA_SORT, data: data })
  }
}

export const setQuickFilter = (data) => {
  return (dispatch) => {
    dispatch({ type: QUICK_FILTER, data: data })
  }
}

export const generalQuickFitler = (data) => {
  let pathName = window.location.pathname.replace(/\//g, '')
  let formattedName = `${GENERAL_QUICK_FILTER}_${pathName.toUpperCase()}`
  return (dispatch) => {
    dispatch({ type: formattedName, data })
  }
}

export const storeWithoutFilter = (data) => {
  let pathName = window.location.pathname.replace(/\//g, '')
  let retainWithoutFilter = `${RETAIN_WITHOUT_FILTER}_${pathName.toUpperCase()}`
  return (dispatch) => {
    dispatch({ type: retainWithoutFilter, data })
  }
}
