/** @format */

import * as ActionTypes from '../actions/property'
import {
  FETCH_BUYER_DOCS_LOADER,
  FETCH_SELETED_PROPERTY_LEGAL_DOCUMENTS_LOADER,
} from '../actions/property'
const initialState = {}

const Property = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_ARMS_DATA_BEGIN:
      return state
    case ActionTypes.FETCH_ARMS_DATA_FLAG:
      return {
        ...state,
        fetchArmsFlag: action.payload,
      }
    case ActionTypes.FETCH_ARMS_DATA_SUCCESS:
      return {
        ...state,
        armsPropData: action.payload,
      }
    case ActionTypes.FETCH_ARMS_DATA_FAIL:
      return state
    case ActionTypes.FETCH_FIELD_DATA_BEGIN:
      return state
    case ActionTypes.FETCH_FIELD_DATA_FLAG:
      return {
        ...state,
        fetchFieldAppFlag: action.payload,
      }
    case ActionTypes.FETCH_FIELD_DATA_SUCCESS:
      return {
        ...state,
        fieldPropData: action.payload,
      }
    case ActionTypes.FETCH_FIELD_DATA_FAIL:
      return state
    case ActionTypes.FETCH_GRAANA_DATA_BEGIN:
      return state
    case ActionTypes.FETCH_GRAANA_DATA_FLAG:
      return {
        ...state,
        fetchGraanaFlag: action.payload,
      }
    case ActionTypes.FETCH_GRAANA_DATA_SUCCESS:
      return {
        ...state,
        graanaPropData: action.payload,
      }
    case ActionTypes.FETCH_SUB_PROPERTIES:
      return {
        ...state,
        subProperties: action.payload,
      }
    case ActionTypes.FETCH_GRAANA_DATA_FAIL:
      return state
    case ActionTypes.FETCH_PROPERTY_DETAIL_BEGIN:
      return state
    case ActionTypes.FETCH_PROPERTY_DETAIL_FLAG:
      return {
        ...state,
        propertyLoadFlag: action.payload,
      }
    case ActionTypes.FETCH_PROPERTY_DETAIL_SUCCESS:
      return {
        ...state,
        propertyDetail: action.payload,
        propertyLoadFlag: true,
      }
    case ActionTypes.FETCH_PROPERTY_DETAIL_FAIL:
      return {
        ...state,
        propertyDetail: action.payload,
        propertyLoadFlag: false,
      }
    case ActionTypes.FETCH_SELECTED_MATCH_PROPERTIES:
      return {
        ...state,
        workflowSelectedProperties: action.payload,
        shortlistedPropertyFlag: action.shortlistedPropertyFlag,
      }
    case ActionTypes.FETCH_MATCH_DATA:
      return {
        ...state,
        matchData: action.data,
      }
    case ActionTypes.FETCH_MATCH_DATA_LOADER:
      return {
        ...state,
        matchDataLoader: action.data,
      }
    case ActionTypes.ADD_SHORTLISTED_PROPERTIES_LOADER:
      return {
        ...state,
        addShortlistedPropertiesLoader: action.data,
      }
    case ActionTypes.ADD_SHORTLISTED_PROPERTIES:
      return {
        ...state,
        addShortListedProperties: action.data,
      }
    case ActionTypes.FETCH_SELECTED_SHORT_LISTED_PROPERTY:
      return {
        ...state,
        selectedShortListedProperty: action.data,
      }
    case ActionTypes.FETCH_SELECTED_SHORT_LISTED_PROPERTY_LOADER:
      return {
        ...state,
        fetchSelectedShortListedPropertyLoader: action.data,
      }
    case ActionTypes.SET_BUY_RENT_ACTION:
      return {
        ...state,
        showMoreActions: action.data,
      }
    case ActionTypes.SET_BUY_RENT_DETAILS_MODAL_TOGGLE:
      return {
        ...state,
        buyRentDetailsModalToggle: action.data,
      }
    case ActionTypes.FETCH_SELETED_PROPERTY_LEGAL_DOCUMENTS_BUYER:
      return {
        ...state,
        selectedPropertyLegalDocumentsBuyer: action.data,
      }
    case ActionTypes.FETCH_BUYER_DOCS_LOADER:
      return {
        ...state,
        buyerDocsLoader: action.data,
      }
    case ActionTypes.FETCH_SELETED_PROPERTY_LEGAL_DOCUMENTS_SELLER:
      return {
        ...state,
        selectedPropertyLegalDocumentsSeller: action.data,
      }
    case ActionTypes.FETCH_SELLER_DOCS_LOADER:
      return {
        ...state,
        sellerDocsLoader: action.data,
      }
    case ActionTypes.SET_PROPERTY_LEGAL_DOCUMENT_LOADER:
      return {
        ...state,
        selectedPropertyLegalDocumentCommentsLoader: action.data,
      }
    case ActionTypes.FETCH_PROPERTY_LEGAL_DOCUMENT_COMMENTS:
      return {
        ...state,
        selectedPropertyLegalDocumentComments: action.data,
      }
    case ActionTypes.FETCH_LEGAL_PAYMENT_INFO:
      return {
        ...state,
        legalPaymentInfo: action.data,
      }
    case ActionTypes.FETCH_SHORTLISTED_COMMENT:
      return {
        ...state,
        shortListedPropertiesComment: action.data,
      }
    case ActionTypes.FETCH_SHORTLISTED_COMMENT_LOADER:
      return {
        ...state,
        shortListedPropertiesCommentLoader: action.data,
      }
    case ActionTypes.FETCH_PROPERTIES_COUNTING:
      return {
        ...state,
        matchingCounting: action.data,
      }
    default:
      return state
  }
}
export default Property
