/** @format */

import React, { useState, useEffect } from 'react'
import { Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { RadioGroup, DateTimePicker, DatePicker, TimePicker } from 'react-rainbow-components'
import { connect } from 'react-redux'
import { fetchLeads, fetchLeadFollowUpTasks, investmentTask } from '../../../../../actions/leads'
import axios from 'axios'
import config from '../../../../../config'
import moment from 'moment'
import Swal from 'sweetalert2'
import './followUp.scss'
import { checkGoogleAuthorize, scrollToBottom, sortListByDateValue } from '../../../../../utils/helper'
import { dateAddDays } from '../../../../../utils/setDate'
import './style.scss'
import { constants } from '../../../../../utils/constant_data'
import { capitalize_Words, showInfoMessage, showSuccessMessage } from '../../../../../utils/commonFormat'
import TaskIconTimeline from '../../../../../images/task-icon.svg'
import Select from 'react-select'
import { toast } from 'react-toastify'
import { DiaryItem } from '../../../../../components/CardInfo/DiaryItem'
import CancelConfirm from '../../../../../components/Modals/TaskModal/CancelConfirm'
import CancelConfirmModal from '../../../../../components/Modals/TaskModal/CancelConfirm'

const customStyles = {
  option: (provided, state) => ({
    ...provided,
  }),
  control: () => ({}),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1
    const transition = 'opacity 300ms'

    return { ...provided, opacity, transition }
  },
}

const FollowUpModal = (props) => {
  //props destructuring
  const {
    modalToggle,
    timeline,
    fetchLeadTimeLine,
    id,
    addTask,
    fetchLeads,
    isOpen,
    type,
    user,
    fetchLeadFollowUpTasks,
    followUpTaskFlag,
    lead,
    fetchUser,
    callBack,
    whichPage,
    followUpAction,
    setFollowUpAction,
    checkForRead,
    showAlertToggle,
  } = props
  var currentDate = new Date()
  let data = {}
  currentDate.setHours(currentDate.getHours() + 1)
  currentDate.setMinutes(currentDate.getMinutes())
  const currTime =
    currentDate.getHours() < 10
      ? '0' + currentDate.getHours() + ':' + currentDate.getMinutes()
      : currentDate.getMinutes() < 10
      ? currentDate.getHours() + ':' + '0' + currentDate.getMinutes()
      : currentDate.getMinutes() > 10
      ? currentDate.getHours() + ':' + currentDate.getMinutes()
      : currentDate.getHours() + ':' + currentDate.getMinutes()

  const morningTime = '09:00'
  // state defined
  const [followUpOpt, setFollowUpOpt] = useState('today')
  const [startDate, setStartDate] = useState(currentDate)
  const [time, setTime] = useState(currTime)
  const [diaryAction, setDiaryAction] = useState(null)
  const [selectedDiary, setSelectedDiary] = useState(null)
  const [subject, setSubject] = useState(null)
  const [notes, setNotes] = useState(null)
  const [addTaskLoader, setAddTaskLoader] = useState(false)
  const [followUpTasks, setFollowUpTasks] = useState([])
  const [anyChange, setAnyChange] = useState(false)
  const [cancelConfirmModal, setCancelConfirmModal] = useState(false)

  let leadTypeParam = 'cmLeadId'
  // if (leadType === 'project') leadTypeParam = 'cmLeadId'
  // else if (leadType === 'buy-rent') leadTypeParam = 'rcmLeadId'

  const leadTaskParams = { taskType: ['follow_up'], [leadTypeParam]: id }

  useEffect(() => {
    fetchLeadFollowUpTasks(id, leadTaskParams, (data) => {
      setFollowUpTasks(data)
      scrollToBottom("followup-scroll-container")
    })
  }, [])

  useEffect(() => {
    if (['add-followup', 'cancel-task', 'task-done'].indexOf(diaryAction) > -1)
      checkGoogleAuthorize(user, afterGoogleAuthCallback)
  }, [diaryAction])

  useEffect(() => {
    setFollowUpOpt('today')
    setStartDate(new Date())
    setSubject(null)
    setNotes(null)
    setAnyChange(false)
  }, [isOpen])

  const afterAddTask = (data) => {
    setAnyChange(true)
    showSuccessMessage('Task added')
    setFollowUpTasks((tasks) => sortListByDateValue([...tasks, data], 'start'))
    setAddTaskLoader(false)
    setFollowUpOpt('today')
    setStartDate(new Date())
    setSubject(null)
    setNotes(null)
    if (props.closeAfterAdd) {
      modalToggle()
      props.shouldCloseAfterAdd(false)
    }
    setFollowUpAction('list')
  }

  const afterGoogleAuthCallback = (token_exist_already = false) => {
    if (!token_exist_already) fetchUser()

    if (diaryAction === 'add-followup') {
      setAddTaskLoader(true)
      submitHandler()
    } else if (diaryAction === 'cancel-task') {
      showInfoMessage('Cancelling task ...')
      deleteDiary(selectedDiary.id, 'task')
    } else if (diaryAction === 'task-done') {
      showInfoMessage('Marking task as done...')
      markAsDone(selectedDiary.id)
    }

    setSelectedDiary(null)
    setDiaryAction(null)
  }

  const markAsDone = (task_id) => {
    let leadTypeParam = 'leadId'
    // if (leadType === 'project') leadTypeParam = 'leadId'
    // else if (leadType === 'buy-rent') leadTypeParam = 'rcmLeadId'

    axios
      .patch(`${config.apiPath}/api/diary/update/?id=${task_id}`, {
        [leadTypeParam]: id,
        status: 'completed',
      })
      .then((res) => {
        setAnyChange(true)
        setFollowUpTasks((tasks) =>
          tasks.map((task) => {
            return task.id === task_id ? { ...task, status: 'completed' } : task
          })
        )
        toast.dismiss()
      })
  }

  const deleteDiary = (task_id, type) => {
    let leadTypeParam = 'cmLeadId'
    // if (leadType === 'project') leadTypeParam = 'cmLeadId'
    // else if (leadType === 'buy-rent') leadTypeParam = 'leadId'
    axios
      .delete(`${config.apiPath}/api/diary/delete?id=${task_id}&${leadTypeParam}=${id}`)
      .then((res) => {
        setAnyChange(true)
        setFollowUpTasks((tasks) => tasks.filter((task) => task_id !== task.id))
        toast.dismiss()
        setTimeout(() => {
          if (type === 'task') showSuccessMessage('Task cancelled')
        }, 700)
      })
  }

  // all functions defined
  const radioSelectHandler = (e) => {
    setFollowUpOpt(e.target.value)

    e.stopPropagation()
  }
  const dateStartHandler = (value) => {
    setStartDate(value)
  }
  const closeModal = () => {
    modalToggle()
  }
  const submitHandler = () => {
    let date = moment(startDate)
    let startTime = moment(time, 'HH:mm:ss')
    let startDateTime = date
      .set({
        hour: startTime.get('hour'),
        minute: startTime.get('minute'),
        second: startTime.get('second'),
      })
      .format('YYYY-MM-DDTHH:mm:ssZ')

    let endTime = startTime.clone().add(1, 'hours')

    let endDateTime = date
      .clone()
      .set({
        hour: endTime.get('hour'),
        minute: endTime.get('minute'),
        second: endTime.get('second'),
      })
      .format('YYYY-MM-DDTHH:mm:ssZ')

    let taskData = {
      cmLeadId: id,
      date: date.format('YYYY-MM-DDTHH:mm:ssZ'),
      status: 'pending',
      start: startDateTime,
      end: endDateTime,
      subject: subject || `Follow up with ${lead && lead.customer ? lead.customer.customerName : 'client'}`,
      taskType: 'follow_up',
      taskCategory: 'leadTask',
      time: startDateTime,
      notes: notes,
      addedBy: 'self'
    }

    addTask(taskData, (leadId, data) => {
      // for investment lead detail page need to refresh timeline
      if (timeline) fetchLeadTimeLine(id)
      afterAddTask(data)
    })
  }
  const timeHandler = (value, followUpOpt) => {
    setTime(value)
  }
  const refreshLeadData = () => {
    const urlParams = new URLSearchParams(window.location.search)
    const pageSize = urlParams.get('pageSize')
    const page = urlParams.get('page')
    var offset = 0
    if (isNaN((page - 1) * pageSize)) {
      offset = 0
    } else {
      offset = (page - 1) * pageSize
    }
    let query = { offset, pageSize }
    if (type !== 'buy-rent') fetchLeads(query)
  }
  // Constant values
  const options = [
    { value: 'today', label: 'Today' },
    { value: 'tomorrow', label: 'Tomorrow' },
    { value: 'threeDays', label: '3 Days' },
    { value: 'nextWeek', label: 'Next Week' },
    { value: 'custom', label: 'Custom' },
  ]

  return (
    <>
      {isOpen ? (
        <Modal
          toggle={(toggle) => {
            if (anyChange && !timeline) callBack()
            setFollowUpAction('list')
            modalToggle(toggle)
          }}
          isOpen={isOpen}
          style={{ maxWidth: '890px', width: '100%' }}
          contentClassName="follow-up-modal"
        >
          <ModalHeader>
            {followUpAction === 'add' ? 'Add Follow Up' : 'Follow Up Tasks'}
            <div className="close-icon-container">
              <i
                className="mt-1 fal fa-times-circle close-icon"
                onClick={() => {
                  if (anyChange && !timeline) callBack()
                  setFollowUpAction('list')
                  modalToggle()
                }}
              ></i>
            </div>
          </ModalHeader>
          <ModalBody style={{ padding: '0 17px 17px 17px' }}>
            {followUpAction === 'add' ? (
              <div className={'mt-4 pr-3 pl-3'}>
                <div className="row justify-content-center">
                  <div
                    onClick={() => {
                      setFollowUpOpt('today')
                      dateStartHandler(new Date())
                      setTime(currTime)
                    }}
                    className={
                      followUpOpt === 'today'
                        ? 'col-md-2 follow-up-active active-link'
                        : 'col-md-2 follow-up-active none-active'
                    }
                  >
                    Today
                  </div>
                  <div
                    onClick={() => {
                      setFollowUpOpt('tomorrow')
                      dateStartHandler(dateAddDays(1))
                      setTime(morningTime)
                    }}
                    className={
                      followUpOpt === 'tomorrow'
                        ? 'col-md-2 follow-up-active active-link'
                        : 'col-md-2 follow-up-active none-active'
                    }
                  >
                    Tomorrow
                  </div>
                  <div
                    onClick={() => {
                      setFollowUpOpt('threeDays')
                      dateStartHandler(dateAddDays(3))
                      setTime(morningTime)
                    }}
                    className={
                      followUpOpt === 'threeDays'
                        ? 'col-md-2 follow-up-active active-link'
                        : 'col-md-2 follow-up-active none-active'
                    }
                  >
                    3 Days
                  </div>
                  <div
                    onClick={() => {
                      setFollowUpOpt('nextWeek')
                      dateStartHandler(dateAddDays(7))
                      setTime(morningTime)
                    }}
                    className={
                      followUpOpt === 'nextWeek'
                        ? 'col-md-2 follow-up-active active-link'
                        : 'col-md-2 follow-up-active none-active'
                    }
                  >
                    Next Week
                  </div>
                  <div
                    onClick={() => {
                      setFollowUpOpt('custom')
                      dateStartHandler(new Date())
                      setTime(currTime)
                    }}
                    className={
                      followUpOpt === 'custom'
                        ? 'col-md-2 follow-up-active active-link'
                        : 'col-md-2 follow-up-active none-active'
                    }
                  >
                    Custom
                  </div>
                </div>
                <div className="row ml-2 mr-2 mt-3 mb-2">
                  <div className="col-sm-6">
                    <TimePicker
                      value={time}
                      onChange={(value) => timeHandler(value, followUpOpt)}
                    />
                  </div>
                  <div className="col-sm-6">
                    <div>
                      <DatePicker
                        formatStyle="small"
                        value={startDate}
                        onChange={(value) => {
                          dateStartHandler(value)
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="row ml-2 mr-2 mt-2 mb-2">
                  <div className="col-sm-12">
                    <input
                      type="text"
                      value={subject}
                      onChange={(e) => setSubject(e.target.value)}
                      className="task-input-container"
                      placeholder="Title"
                    />
                  </div>
                </div>
                <div className="row ml-2 mr-2 mt-2 mb-3">
                  <div className={'col-sm-12'}>
                    <textarea
                      rows="4"
                      value={notes}
                      onChange={(e) => setNotes(e.target.value)}
                      placeholder="Details"
                    />
                  </div>
                </div>
                <div className="row d-flex justify-content-center footer-btn">
                  <div className={'col-5 d-flex justify-content-center'}>
                    <div
                      className="task-button outline mr-2"
                      onClick={() => setFollowUpAction('list')}
                    >
                      Cancel
                    </div>
                    <button
                      className={subject ? 'ml-2 btn task-button' : 'ml-2 btn task-button disabled'}
                      onClick={() => {
                        if (!addTaskLoader && subject) {
                          setDiaryAction('add-followup')
                        }
                      }}
                    >
                      {addTaskLoader ? (
                        <span className="fa fa-circle-o-notch fa-spin"></span>
                      ) : (
                        'Add'
                      )}
                    </button>
                  </div>
                </div>
              </div>
            ) : followUpAction === 'list' ? (
              <>
                <div className={'pr-3 pl-3'}>
                  <div className="task-list" id={"followup-scroll-container"}>
                    {followUpTaskFlag === false ? (
                      <div className={'data-loader'}>
                        <span className="fa fa-circle-o-notch fa-spin"></span>
                      </div>
                    ) : followUpTasks && followUpTasks.length ? (
                      followUpTasks.map((task, i) => (
                        <DiaryItem
                          key={i}
                          index={i}
                          className={`mt-2`}
                          dropdown={task.status !== 'completed'}
                          markAsDone={(id) => {
                            setSelectedDiary(task)
                            setDiaryAction('task-done')
                          }}
                          cancelTask={(id) => {
                            setSelectedDiary(task)
                            setCancelConfirmModal(true)
                            // setDiaryAction('cancel-task')
                          }}
                          item={task}
                          lead={lead}
                          cardType={task.taskType}
                          checkForRead={checkForRead}
                        />
                      ))
                    ) : (
                      <div className="task-image-container mt-4">
                        <img src={TaskIconTimeline} className="container-image" />
                      </div>
                    )}
                  </div>
                  <br />
                  {/* {checkForRead && !checkForRead.showReadOnly || checkForRead && checkForRead.ownLeadReadOnly ? ( */}
                    <div className={'row d-flex justify-content-center footer-btn'}>
                      <div className={'col-5 d-flex justify-content-center'}>
                        <div
                          className="task-button"
                          onClick={() => {
                            if ((checkForRead && !checkForRead.showReadOnly) || (checkForRead && checkForRead.ownLeadReadOnly)) {
                              setFollowUpAction('add')
                            } else {
                              showAlertToggle()
                            }
                          }}
                        >
                          Add Follow Up
                        </div>
                      </div>
                    </div>
                  {/* ) : null} */}
                </div>
              </>
            ) : (
              <></>
            )}
          </ModalBody>
        </Modal>
      ) : (
        <></>
      )}

      {cancelConfirmModal ? (
        <CancelConfirmModal
          diary={selectedDiary}
          cancelConfirmModal={cancelConfirmModal}
          setCancelConfirmModal={(toggle) => setCancelConfirmModal(toggle)}
          setDiaryAction={(action) => setDiaryAction(action)}
          setSelectedDiary={(diary) => setSelectedDiary(diary)}
        />
      ) : (
        <></>
      )}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    ...state,
    followUpTasks: state.Leads.followUpTasks,
    followUpTaskFlag: state.Leads.followUpTaskFlag,
  }
}

export const mapDispatchToProps = (dispatch) => {
  return {
    fetchLeads: (query) => dispatch(fetchLeads(query)),
    addTask: (data, callback, leadId) => dispatch(investmentTask(data, callback, leadId)),
    fetchLeadFollowUpTasks: (leadId, params, callback) =>
      dispatch(fetchLeadFollowUpTasks(leadId, params, callback)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(FollowUpModal)
