/** @format */

export const getActiveBookingProperties = (properties) => {
  const user_id = localStorage.getItem('user_id')
  return properties
    ? properties.filter(
        (p) =>
          p.diaries &&
          p.diaries.filter(
            (d) => ['completed', 'cancelled'].indexOf(d.status) === -1 && d.userId == user_id
          ).length
      )
    : []
}
