import React from 'react'

/** @format */

export const ColumnName = {
  LEAD_ID: 'lead-id',
  LEAD_TYPE: 'lead-type',
  CLIENT_NAME: 'client-name',
  PROPERTY_TYPE: 'property-type',
  PROPERTY_SUB_TYPE: 'property-sub-type',
  AREA: 'area',
  CITY: 'city',
  STATUS: 'status',
  ASSIGNED_TO: 'assign-to',
  ADVISOR_CONTACT_NO: 'advisor-contact-no',
  ORIGIN: 'origin',
  WANTED_ID: 'wanted-id',
  CLOSING_REASON: 'closing-reason',
  DATE_CREATED: 'date-created',
  TIME_STATUS: 'time-status',
  ACTION: 'action',
  TRANSFERRED_FROM: 'transferred-from',
  TRANSFERRED_DATE: 'transferred-date',
}

export const columnColor = '#50525e'

export const headerTemplate = () => {
  return [
    {
      component: 'LABEL',
      props: {
        label: '',
      },
      tdClassName: 'px-2',
      columnName: ColumnName.TIME_STATUS
    },
    {
      component: 'LABEL',
      props: {
        label: 'Lead ID',
      },
      tdClassName: '',
      columnName: ColumnName.LEAD_ID,
    },
    {
      component: 'LABEL',
      props: {
        label: 'LEAD TYPE',
      },
      columnName: ColumnName.LEAD_TYPE,
    },
    {
      component: 'LABEL',
      props: {
        label: 'CLIENT NAME',
      },
      columnName: ColumnName.CLIENT_NAME,
    },
    {
      component: 'LABEL',
      props: {
        label: 'PROPERTY TYPE',
      },
      columnName: ColumnName.PROPERTY_TYPE,
    },
    {
      component: 'LABEL',
      props: {
        label: 'PROPERTY SUBTYPE',
      },
      columnName: ColumnName.PROPERTY_SUB_TYPE,
    },
    {
      component: 'LABEL',
      props: {
        label: 'STATUS',
      },
      columnName: ColumnName.STATUS,
      tdClassName: 'text-center',
    },
    {
      component: 'LABEL',
      props: {
        label: 'AREA',
      },
      columnName: ColumnName.AREA,
    },
    {
      component: 'LABEL',
      props: {
        label: 'CITY',
      },
      columnName: ColumnName.CITY,
    },
    {
      component: 'LABEL',
      props: {
        label: 'ASSIGNED TO',
      },
      columnName: ColumnName.ASSIGNED_TO,
    },
    {
      component: 'LABEL',
      props: {
        label: 'ADVISOR CONTACT NO',
      },
      columnName: ColumnName.ADVISOR_CONTACT_NO,
    },
    {
      component: 'LABEL',
      props: {
        label: 'ORIGIN',
      },
      columnName: ColumnName.ORIGIN,
    },
    {
      component: 'LABEL',
      props: {
        label: 'WANTED ID',
      },
      columnName: ColumnName.WANTED_ID,
    },
    {
      component: 'LABEL',
      props: {
        label: 'CLOSING REASON',
      },
      columnName: ColumnName.CLOSING_REASON,
    },
    {
      component: 'LABEL',
      props: {
        label: 'DATE CREATED',
      },
      columnName: ColumnName.DATE_CREATED,
    },
    {
      component: 'LABEL',
      props: {
        label: '',
      },
      tdClassName: 'noColor',
      columnName: ColumnName.ACTION,
    },
  ]
}
export const bodyTemplate = (getValue, handleAction, props) => {
  const constructProp = (templateProperty, cellIndex, rowIndex, rowData, columnName) =>
    getValue(templateProperty, cellIndex, rowIndex, rowData, props, columnName)
  return [
    {
      component: 'LABEL',
      props: {
        label: constructProp,
      },
      tdClassName: 'pl-3 pr-2',
    },
    {
      component: 'LABEL',
      props: {
        label: constructProp,
        className: 'buyrent-lead-link'
      },
    },
    {
      component: 'LABEL',
      props: {
        label: constructProp,
        textColor: columnColor
      },
    },
    {
      component: 'LABEL',
      props: {
        label: constructProp,
        textColor: columnColor
      },
    },
    {
      component: 'LABEL',
      props: {
        label: constructProp,
        textColor: columnColor
      },
    },
    {
      component: 'LABEL',
      props: {
        label: constructProp,
        textColor: columnColor
      },
    },
    {
      component: 'CHIP',
      props: {
        text: constructProp,
        chipColor: constructProp,
        textColor: constructProp,
        fontWeight: '700',
        padding: '6px 10px'
      },
      tdClassName: 'd-flex justify-content-center align-items-center h-100',
      notFoundTemplate: { text: '' },
    },
    {
      component: 'LABEL',
      props: {
        label: constructProp,
        textColor: columnColor,
        className: 'elipsis wlRequirementLabelWidth',
      },
    },
    {
      component: 'LABEL',
      props: {
        label: constructProp,
        textColor: columnColor
      },
    },
    {
      component: 'LABEL',
      props: {
        label: constructProp,
        textColor: columnColor
      },
    },
    {
      component: 'LABEL',
      props: {
        label: constructProp,
        textColor: columnColor
      },
    },
    {
      component: 'LABEL',
      props: {
        label: constructProp,
        textColor: columnColor
      },
    },
    {
      component: 'LABEL',
      props: {
        label: constructProp,
        textColor: columnColor
      },
    },
    {
      component: 'LABEL',
      props: {
        label: constructProp,
        textColor: columnColor,
        className: 'elipsis wlRequirementLabelWidth',
      },
    },
    {
      component: 'LABEL',
      props: {
        label: constructProp,
        textColor: columnColor,
      },
    },
    {
      component: 'BUTTON',
      tdClassName: 'pr-3',
      props: {
        display: constructProp,
        text: 'Re-assign',
        buttonColor: 'white',
        textColor: '#026ff2',
        orientation: 'left',
        autoAdjustOrientation: true,
        width: '70px',
        padding: '7px 5px',
        actionItems: constructProp,
        onClick: handleAction,
        emptyMenuItem: 'No Action Available',
        style: {border: '1px solid #026ff2'}
      },
      ignorePropConstruct: [
        'onClick',
        'autoAdjustOrientation',
        'type',
        'width',
        'orientation',
        'textColor',
        'buttonColor',
        'text',
        'emptyMenuItem',
        ,
      ],
      showOnHover: true,
      showOnRowSelect: false,
    },
  ]
}
