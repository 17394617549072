/** @format */

import React, { useState, useEffect } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import Load from '../../../components/Loader'
import { capitalize_Words } from '../../../utils/commonFormat'
import axios from 'axios'
import config from '../../../config'

const AgentsModel = (props) => {
  const [agents, setAgents] = useState([])
  const [isLoading, setLoading] = useState(false)
  const [searchString, setSearchString] = useState('')
  const [filteredAgents, setFilteredAgents] = useState([])

  const fetchAgents = () => {
    setLoading(true)
    let userRole = props.user && props.user.armsUserRole
    if (!userRole) {
      userRole = {}
      userRole.groupManger = localStorage.getItem('groupManager')
      userRole.roleName = localStorage.getItem('role')
    }
    let groupManagerParam = ''
    if (userRole && userRole.groupManger) {
      groupManagerParam = `&groupManager=true`
    }
    axios
      .get(
        `${config.apiPath}/api/role/sub-users?roleId=${props.user.armsUserRoleId}${groupManagerParam}`
      )
      .then((res) => {
        setAgents(res.data)
        setFilteredAgents(res.data)
        setLoading(false)
      })
  }
  useEffect(() => {
    if (props.open) {
      fetchAgents()
    }
  }, [props.open])

  useEffect(() => {
    let searchStr = searchString.trim().toLowerCase()
    if (searchStr.length > 0) {
      let allAgents = [...agents]
      let filteredAg = allAgents.filter(function (i) {
        let fullName = i.firstName + ' ' + i.lastName
        return fullName.toLowerCase().match(searchStr)
      })
      if (filteredAg && filteredAg.length > 0) setFilteredAgents(filteredAg)
    } else {
      setFilteredAgents(agents)
    }
  }, [searchString])

  return (
    <Modal
      isOpen={props.open}
      toggle={props.toggle}
      size="lg"
      style={{ maxWidth: '1156px', width: '100%' }}
      contentClassName="share-modal-width "
    >
      <ModalHeader>
        Select Advisor
        <div className="close-icon-container">
          <i
            className="fal fa-times-circle close-icon"
            onClick={() => {
              props.toggle()
            }}
          ></i>
        </div>
      </ModalHeader>
      <ModalBody>
        <>
          <div className="row ">
            <div className="row">
              <div className="ml-5">
                <div className="search-box" style={{ margin: 0 }}>
                  <input
                    type="text"
                    className="search-box-style"
                    placeholder="Search by advisor name"
                    onChange={(e) => setSearchString(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="user-form">
              <table className="share-modal-table" id="table" style={{ width: '100%' }}>
                <thead>
                  <tr>
                    <th style={{ minWidth: 90, maxWidth: 90 }}>Id</th>
                    <th style={{ minWidth: 150, maxWidth: 150 }}>Name</th>
                    <th style={{ minWidth: 165, maxWidth: 165 }}>Title</th>
                    <th style={{ minWidth: 160, maxWidth: 160 }}>Organisation</th>
                    <th style={{ minWidth: 150, maxWidth: 150 }}>Region</th>
                    <th style={{ minWidth: 170, maxWidth: 170 }}>Role</th>
                    <th style={{ minWidth: 120, maxWidth: 120 }}>Team</th>
                    <th style={{ minWidth: 120, maxWidth: 120 }}>Action</th>
                  </tr>
                </thead>
                {isLoading ? (
                  <Load title="Users" />
                ) : filteredAgents && filteredAgents && filteredAgents.length === 0 ? (
                  <div style={{ marginTop: '60px', marginBottom: 50 }} className="text-center">
                    <h3 className="dimgray">No Advisors Found</h3>
                  </div>
                ) : (
                  <tbody>
                    {filteredAgents &&
                      filteredAgents.map((item) => (
                        <tr>
                          <td style={{ minWidth: 90, maxWidth: 90 }}>{item.id}</td>
                          <td style={{ minWidth: 150, maxWidth: 150 }}>
                            {item.firstName + ' ' + item.lastName}
                          </td>
                          <td style={{ minWidth: 165, maxWidth: 165 }}>
                            {item.title ? item.title : '--'}
                          </td>
                          <td style={{ minWidth: 160, maxWidth: 160 }}>
                            {item && item.organization
                              ? capitalize_Words(item.organization.name)
                              : '--'}
                          </td>

                          <td style={{ minWidth: 150, maxWidth: 150 }}>
                            {(item && item.region && item.region.name) || '--'}
                          </td>
                          <td style={{ minWidth: 170, maxWidth: 170 }}>
                            {item && item.armsUserRole && item.armsUserRole.subRole}
                          </td>

                          <td style={{ minWidth: 120, maxWidth: 120 }}>
                            {item && item.armsTeam ? item && item.armsTeam.teamName : '--'}
                          </td>

                          <td style={{ minWidth: 120, maxWidth: 120 }}>
                            <button
                              className=" link-style mr-2 ml-2 btn-hollow-activate"
                              onClick={() => {
                                props.onSelectAgent(item)
                                props.toggle()
                              }}
                            >
                              {'Select'}
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </>
      </ModalBody>
    </Modal>
  )
}

export default AgentsModel
