/** @format */

import React, { Component, Fragment } from 'react'
import Pagination from 'rc-pagination'
import { connect } from 'react-redux'
import axios from 'axios'
import config from '../../config'
import Load from '../../components/Loader'
import Inner from './Inner'
import { normalizeCnic } from '../../utils/formatCnic'
import 'rc-pagination/assets/index.css'
import 'rc-select/assets/index.css'
import AddNewNextOfKinModal from './modal'
import './style.scss'
import { setTitle } from '../../actions/generalSetting'
import moment from 'moment'
import { showToastMsg } from '../../utils/helper'

class AddNextOfkin extends Component {
  constructor(props) {
    super(props)
    this.scrollRef = null
    this.setScrollRef = (element) => {
      this.scrollRef = element
    }
    this.state = {
      formData: {
        id: '',
        customerId: '',
        firstName: '',
        lastName: '',
        address: '',
        email: '',
        cnic: '',
        secondaryAddress: '',
        checkRequiredField: false,
        relationStatus: '',
        relationWithApplicant: '',
        relativeName: '',
        profession: '',
        passport: '',
        country: '',
        province: '',
        district: '',
        city: '',
        mCountry: '',
        mProvince: '',
        mDistrict: '',
        mCity: '',
        mAddress: '',
        nationality: '',
        dob: '',
        checkCnicLength: '',
      },
      phone: {
        countryCode: 'pk',
        dialCode: '',
        phone: '',
      },
      validationCheck: false,
      loadingOnsubmit: false,
      editAble: false,
      cnicValidation: false,
      phoneValidate: false,
      phoneValidate2: false,
      phoneValidate3: false,
      emailValidate: false,
      name: '',
    }
  }
  componentWillMount() {
    // this.props.setTitle({ name: 'Clients' })
  }

  componentWillReceiveProps(nextProps) {
    const { SelectedRecordIdOfnextOfKin, createNewNextofKin } = this.props

    if (createNewNextofKin === true) {
      this.emptyForm()
    } else {
      if (SelectedRecordIdOfnextOfKin && SelectedRecordIdOfnextOfKin.customer) {
        this.editClientData(SelectedRecordIdOfnextOfKin)
      }
    }
  }

  componentDidUpdate(prevState) {
    const { createNewNextofKin, NextOfkinEditRecordCheck, SelectedRecordIdOfnextOfKin } = this.props

    if (createNewNextofKin === true && prevState.createNewNextofKin === false) {
      this.emptyForm()
    } else if (
      createNewNextofKin === false &&
      prevState.createNewNextofKin === true &&
      SelectedRecordIdOfnextOfKin
    ) {
      // this.editClientData(SelectedRecordIdOfnextOfKin)
    }

    if (
      NextOfkinEditRecordCheck &&
      NextOfkinEditRecordCheck &&
      SelectedRecordIdOfnextOfKin !== prevState.SelectedRecordIdOfnextOfKin
    ) {
      this.editClientData(SelectedRecordIdOfnextOfKin)
    }
  }

  editClientData = (SelectedRecordIdOfnextOfKin) => {
    const { formData, phone } = this.state
    var newFormData = { ...formData }
    var newPhone = { ...phone }
    newFormData['id'] = SelectedRecordIdOfnextOfKin.id
    newFormData['customerId'] = SelectedRecordIdOfnextOfKin.customerId
    newFormData['firstName'] = SelectedRecordIdOfnextOfKin.firstName
    newFormData['lastName'] = SelectedRecordIdOfnextOfKin.lastName
    newFormData['cnic'] = normalizeCnic(SelectedRecordIdOfnextOfKin.cnic)

    newFormData['relationStatus'] = SelectedRecordIdOfnextOfKin.relationStatus
    newFormData['relationWithApplicant'] = SelectedRecordIdOfnextOfKin.realationwithApplicant

    newPhone['phone'] = SelectedRecordIdOfnextOfKin.phone
    newFormData['relativeName'] = SelectedRecordIdOfnextOfKin.nameOfRelative
    //Mailing Address
    newFormData['mCountry'] = SelectedRecordIdOfnextOfKin.mailingCountry
    newFormData['mProvince'] = SelectedRecordIdOfnextOfKin.mailingProvince
    newFormData['mDistrict'] = SelectedRecordIdOfnextOfKin.mailingDistrict
    newFormData['mCity'] = SelectedRecordIdOfnextOfKin.mailingCity
    newFormData['mAddress'] = SelectedRecordIdOfnextOfKin.mailingAddress
    // Permanent Address
    newFormData['country'] = SelectedRecordIdOfnextOfKin.Country
    newFormData['province'] = SelectedRecordIdOfnextOfKin.Province
    newFormData['district'] = SelectedRecordIdOfnextOfKin.District
    newFormData['city'] = SelectedRecordIdOfnextOfKin.City
    newFormData['address'] = SelectedRecordIdOfnextOfKin.Address

    this.setState({
      formData: newFormData,
      phone: newPhone,
      editAble: true,
    })
  }

  checkCnicValidation = (data) => {
    var newdata = data.replace(/-/g, '')
    if (data.length <= 15) {
      this.setState({ checkCnicLength: newdata })
    }
  }

  handleChange = (e, dropDownName, resetArray) => {
    const { formData } = this.state
    if (dropDownName) {
      var name = dropDownName
      var value = e.value
    } else {
      var name = e.target.name
      var value = e.target.value
    }
    var newFormData = { ...formData }
    if (resetArray && resetArray.length > 0) {
      for (let i = 0; i < resetArray.length; i++) {
        newFormData[resetArray[i]] = ''
      }
    }
    newFormData[name] = value
    if (name === 'cnic') {
      this.validateCnic(value)
    }
    if (name === 'email') {
      this.validateEmail(value)
    }
    this.setState({
      formData: newFormData,
    })
  }

  handleDob = (dob) => {
    const { formData } = this.state
    var newFormData = { ...formData }
    newFormData['dob'] = dob
    this.setState({
      formData: newFormData,
    })
  }

  nextOfkinFormEmpty = (who) => {}

  resetData = (array) => {
    const { formData } = this.state
    if (array) {
      for (let i = 0; i < array.length; i++) {
        formData[array[i]] = ''
      }
      var newFormData = { ...formData }
      this.setState({
        formData: newFormData,
      })
    }
  }

  validateEmail = (value) => {
    var format = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    if (format.test(value)) {
      this.setState({ emailValidate: false })
    } else {
      this.setState({
        emailValidate: true,
      })
    }
  }

  validatePhone = (value) => {
    if (value.length < 6 || value === '') this.setState({ phoneValidate: true })
    else this.setState({ phoneValidate: false })
  }

  validateContact1 = (value) => {
    if (value.length < 6 && value !== '') this.setState({ phoneValidate1: true })
    else this.setState({ phoneValidate1: false })
  }

  validateContact2 = (value) => {
    if (value.length < 6 && value !== '') this.setState({ phoneValidate2: true })
    else this.setState({ phoneValidate2: false })
  }

  validateCnic = (value) => {
    var value = value.replace(/-/g, '')
    if (value.length === 7 || value.length === 8  || value.length >= 13 && value !== '') {
      this.setState({ cnicValidation: false })
    }  
    else{
      this.setState({ cnicValidation: true })
    }
  }

  handleChangePhone = (value, country, e, formattedValue) => {
    const { phone } = this.state
    var newPhone = { ...phone }

    var name = e.target && e.target.name

    if (name === 'phone') {
      newPhone['countryCode'] = country.countryCode
      newPhone['dialCode'] = country.dialCode
      newPhone[name] = '+' + value
      // this.validatePhone(value)
      this.setState({
        phone: newPhone,
      })
    }
  }

  emptyForm = () => {
    let formData = {
      firstName: '',
      lastName: '',
      address: '',
      email: '',
      cnic: '',
      secondaryAddress: '',
      relationStatus: '',
      relativeName: '',
      profession: '',
      passport: '',
      country: '',
      province: '',
      district: '',
      city: '',
      mCountry: '',
      mProvince: '',
      mDistrict: '',
      mCity: '',
      mAddress: '',
      nationality: '',
      dob: '',
    }
    let phone = {
      countryCode: 'pk',
      dialCode: '',
      phone: '',
    }
    let contact1 = {
      countryCode: 'pk',
      dialCode: '',
      contact1: '',
    }
    let contact2 = {
      countryCode: 'pk',
      dialCode: '',
      contact2: '',
    }

    this.setState({
      formData: formData,
      phone: phone,
      contact1: contact1,
      contact2: contact2,
      editAble: false,
    })
  }

  scrollTopFunc = () => {
    if (this.scrollRef) this.scrollRef.scrollTo({ top: 0, behavior: 'smooth' })
  }

  createClient = (check) => {
    const {
      phone,
      formData,
      contact1,
      contact2,
      editAble,
      cnicValidation,
      phoneValidate,
      phoneValidate1,
      phoneValidate2,
      emailValidate,
    } = this.state

    const {
      fromPage,
      clientModalToggle,
      apiCallDonClientCreated,
      clientId,
      SelectedRecordId,
      NextOfKinSelectedRecordCustomerId,
      onAddNewNextOfKinRecordHandler,
      getNextOfKinListingData,
      nextOfkinCancel,
      addBankAccountFrom,
      apiCallDonKinCreated,
      addNextOfKinFrom,
    } = this.props

    var validEmail = false
    if (formData.dob == '') {
      formData.dob = null
    }
    if (emailValidate === true && formData.email != '') {
      validEmail = true
    } else {
      validEmail = false
    }

    this.setState({ loadingOnsubmit: true })

    if (
      formData.firstName === '' ||
      formData.lastName === '' ||
      formData.cnic === '' ||
      // phone.phone === '' ||
      // phone.phone.length === 1 ||
      cnicValidation === true ||
      phoneValidate === true ||
      validEmail === true
    ) {
      this.scrollTopFunc()
      this.setState({
        validationCheck: true,
        loadingOnsubmit: false,
      })
    } else {
      if (editAble === true) {
        var customersContactsArray = []
        let IdOfclient = null
        if (fromPage !== 'investmentLead' && fromPage !== 'clientNextOfKin') {
          const { params } = this.props.match
          IdOfclient = params.clientId
        }
        if ((fromPage === 'investmentLead' || fromPage === 'clientNextOfKin') && editAble) {
          if (clientId) {
            IdOfclient = clientId
          }
        }

        this.setState({
          loadingOnsubmit: true,
        })

        customersContactsArray.push(phone)

        var cnic =
          formData.cnic != '' && formData.cnic != null ? formData.cnic.replace(/-/g, '') : null
        var body = {
          id: formData.id,
          customerId: formData.customerId,
          firstName: formData.firstName,
          lastName: formData.lastName,
          relationStatus: formData.relationStatus
            ? formData.relationStatus
            : formData.relativeName
            ? 'son of'
            : '',
          nameOfRelative: formData.relativeName,
          realationwithApplicant: formData.relationWithApplicant,
          phone: phone.phone,
          cnic: cnic,
          mailingCountry: formData.mCountry,
          mailingProvince: formData.mProvince,
          mailingDistrict: formData.mDistrict,
          mailingCity: formData.mCity,
          mailingAddress: formData.mAddress,

          Address: formData.address != '' && formData.address != null ? formData.address : null,
          Country: formData.country,
          Province: formData.province,
          District: formData.district,
          City: formData.city,
        }

        axios
          .patch(`${config.apiPath}/api/booking/updateKinDetails`, body)
          .then((res) => {
            this.setState({
              validationCheck: false,
              loadingOnsubmit: false,
              cnicValidation: false,
            })

            if (res.data.message && res.data.message !== '') {
              showToastMsg(res.data.message, 'warning')
            }
            if (res.data.status === true) {
              showToastMsg('Next of kin has been updated successfully', 'success')
              onAddNewNextOfKinRecordHandler({ id: NextOfKinSelectedRecordCustomerId })
              getNextOfKinListingData(formData.customerId)
              this.emptyForm()
            }
          })
          .catch(() => {
            this.setState({
              validationCheck: false,
              loadingOnsubmit: false,
              cnicValidation: false,
            })
            showToastMsg('Something Went Wrong!!', 'error')
          })
      } else {
        var cnic =
          formData.cnic != '' && formData.cnic != null ? formData.cnic.replace(/-/g, '') : null
        var body = {
          firstName: formData.firstName,
          lastName: formData.lastName,
          relationStatus: formData.relationStatus
            ? formData.relationStatus
            : formData.relativeName
            ? 'son of'
            : '',
          nameOfRelative: formData.relativeName,
          realationwithApplicant: formData.relationWithApplicant,
          phone: phone.phone,
          cnic: cnic,
          mailingCountry: formData.mCountry,
          mailingProvince: formData.mProvince,
          mailingDistrict: formData.mDistrict,
          mailingCity: formData.mCity,
          mailingAddress: formData.mAddress,

          Address: formData.address != '' && formData.address != null ? formData.address : null,
          Country: formData.country,
          Province: formData.province,
          District: formData.district,
          City: formData.city,
          customerId:
            NextOfKinSelectedRecordCustomerId && NextOfKinSelectedRecordCustomerId.toString(),
        }
        axios
          .post(`${config.apiPath}/api/booking/createKinDetails`, body)
          .then((res) => {
            this.setState({
              validationCheck: false,
              loadingOnsubmit: false,
            })
            if (res.data.status === true) {
              showToastMsg('Next Of Kin has been added successfully', 'success')
              if (addNextOfKinFrom === 'BookingForm') {
                this.emptyForm()
                apiCallDonKinCreated(res.data.data)
              } else {
                onAddNewNextOfKinRecordHandler({ id: NextOfKinSelectedRecordCustomerId })
                getNextOfKinListingData(NextOfKinSelectedRecordCustomerId)
              }

              if (
                this.props &&
                this.props.path &&
                this.props.path.pathname &&
                this.props.path.pathname === '/available-inventory'
              ) {
                this.props.onchangeClient(res.data)
              }
            }
          })
          .catch(() => {
            this.setState({
              validationCheck: false,
              loadingOnsubmit: false,
            })
            showToastMsg('Something went wrong!!', 'error')
          })
      }
    }
  }

  render() {
    const {
      formData,
      phone,
      contact1,
      contact2,
      validationCheck,
      loadingOnsubmit,
      cnicValidation,
      phoneValidate,
      phoneValidate1,
      phoneValidate2,
      emailValidate,
      editAble,
      checkCnicLength,
    } = this.state
    const {
      fromPage,
      activeClientModal,
      shareToggle,
      clientModalToggle,
      checkPurchaser,
      NextOfKinEditRecord,
      SelectedRecordIdOfnextOfKin,
      NextOfkinEditRecordCheck,
      createNewNextofKin,
      nextOfkinCancel,
    } = this.props

    return (
      <Fragment>
        {}
        {fromPage !== 'investmentLead' && fromPage !== 'clientNextOfKin' ? (
          <div ref={this.setScrollRef} className={`mainAddClient`}></div>
        ) : (
          <AddNewNextOfKinModal
            activeClientModal={activeClientModal}
            shareToggle={shareToggle}
            clientModalToggle={clientModalToggle}
            nextOfkinCancel={nextOfkinCancel}
            formData={formData}
            phone={phone}
            setRef={(ele) => this.setScrollRef(ele)}
            contact1={contact1}
            contact2={contact2}
            nextOfkinFormEmpty={this.emptyForm}
            loadingOnsubmit={loadingOnsubmit}
            validationCheck={validationCheck}
            cnicValidation={cnicValidation}
            phoneValidate={phoneValidate}
            phoneValidate1={phoneValidate1}
            phoneValidate2={phoneValidate2}
            emailValidate={emailValidate}
            editAble={editAble}
            fromPage={fromPage}
            handleChange={this.handleChange}
            handleChangePhone={this.handleChangePhone}
            resetData={this.resetData}
            handleDob={this.handleDob}
            createClient={this.createClient}
            checkPurchaserPage={checkPurchaser}
            checkCnicLength={checkCnicLength}
            checkCnicValidation={this.checkCnicValidation}
          />
        )}
      </Fragment>
    )
  }
}

export const mapStateTopProps = (state, ownProps) => {
  return {}
}

export const mapDispatchTopProps = (dispatch) => {
  return {
    setTitle: (title) => dispatch(setTitle(title)),
  }
}

export default connect(mapStateTopProps, mapDispatchTopProps)(AddNextOfkin)
