/** @format */

import React from 'react'

let componentList = {
  LABEL: React.lazy(() => import('./../Label')),
  ACTIONBUTTON: React.lazy(() => import('./../ActionButton')),
  COLORTILE: React.lazy(() => import('./../ColorTile')),
  CHIP: React.lazy(() => import('./../Chip')),
  BUTTON: React.lazy(() => import('./../Button')),
  INPUTDROPDOWN: React.lazy(() => import('./../InputDropdown')),
  CHECKBOX: React.lazy(() => import('./../CheckBox')),
  INPUTCHECKBOX: React.lazy(() => import('./../InputCheckbox')),
  IMAGESLIDER: React.lazy(() => import('./../ImageSlider')),
}
export default function getComponent(comp, props, children) {
  return React.createElement(componentList[comp], { ...props }, { ...children })
}
