/** @format */

import React, { useEffect } from 'react'
import axios from 'axios'
import config from '../../config'
import { useState } from 'react'
import Pagination from '../UnitBookingForm/Inventory/Pagination'
import InventoryFilter from './InventoryFilter'
import { setTitle } from '../../actions/generalSetting'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap'

const InternalSaleableInventory = (props) => {
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [showLoader, setShowLoader] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [items, setItems] = useState([])
  const [pagination, setPagination] = useState({})

  const [projectId, setProjectId] = useState(null)
  const [floorId, setFloorId] = useState(null)
  const [SearchProjectByUniqueCodeValue, setSearchProjectByUniqueCodeValue] = useState(null)
  const [SearchProjectByIdValue, setSearchProjectBYID] = useState(null)

  useEffect(() => {
    setTimeout(() => {
      props.setTitle({
        name:
          capitalizeFirstLetter(props.match.params.inventoryType)
            .replace('_', ' / ')
            .replace('appreciation', 'Appreciation') +
          ' Inventory (Internal)',
      })
    }, 300)
  }, [])

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }
  useEffect(() => {
    const inventoryType = props.match.params.inventoryType
    if (inventoryType !== undefined && inventoryType !== null) {
      document.title = capitalizeFirstLetter(inventoryType).replace('_', ' / ')
      props.setTitle({
        name:
          `Internal ` +
          capitalizeFirstLetter(inventoryType)
            .replace('_', ' / ')
            .replace('appreciation', 'Appreciation') +
          ' Inventory',
      })
      installmentApi(inventoryType, page, perPage, projectId, floorId)
    }
  }, [props.match.params.inventoryType])

  const pageActionHandle = (page, per_page) => {
    if (
      props.match.params.inventoryType !== undefined &&
      props.match.params.inventoryType !== null
    ) {
      installmentApi(props.match.params.inventoryType, page, per_page, projectId, floorId)
    }
  }

  const getInstallments = async (
    slug,
    page = 1,
    per_page = 3,
    project_id = null,
    floor_id = null,
    SearchProjectByIdValue = null,
    SearchProjectByUniqueCodeValue = null,
    is_fractional=null
  ) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${config.apiPath}/api/imarat/inventories/${slug}?productType=internal&product_type=${inventoryType}`, {
          params: {
            page,
            per_page,
            project_id,
            floor_id,
            id: SearchProjectByIdValue,
            unique_code: SearchProjectByUniqueCodeValue,
            is_fractional:is_fractional,
          },
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.response)
        })
    })
  }
  const installmentApi = (
    slug,
    page,
    per_page,
    project_id = null,
    floor_id = null,
    SearchProjectByIdValue = null,
    SearchProjectByUniqueCodeValue = null,
    is_fractional=null,
  ) => {
    setPage(page)
    setShowLoader(true)
    setItems([])
    setPagination({})
    getInstallments(
      slug,
      page,
      per_page,
      project_id,
      floor_id,
      SearchProjectByIdValue,
      SearchProjectByUniqueCodeValue,
      is_fractional
    )
      .then((result) => {
        const { data, meta } = result
        setPagination(meta)
        setItems(data.length > 0 ? data : [])
        setShowLoader(false)
      })
      .catch((error) => {
        setShowLoader(false)
      })
  }

  const updateInventoryHandle = (filter) => {
    setProjectId(filter.project_id)
    setFloorId(filter.floor_id)
    setSearchProjectBYID(filter.SearchProjectByIdValue)
    setSearchProjectByUniqueCodeValue(filter.SearchProjectByUniqueCodeValue)
    if (
      props.match.params.inventoryType !== undefined &&
      props.match.params.inventoryType !== null
    ) {
      installmentApi(
        props.match.params.inventoryType,
        page,
        perPage,
        filter.project_id,
        filter.floor_id,
        filter.SearchProjectByIdValue,
        filter.SearchProjectByUniqueCodeValue,
        filter.is_fractional
      )
    }
  }
  const inventoryType = props.match.params.inventoryType;

// 16-09-2022
  return (
    <div className='row'>
    <div style={{ paddingRight: "0.2%" }} className={'col-sm-12 col-md-12'}>
      <div className="card-header">
        <InventoryFilter updateInventoryHandle={updateInventoryHandle} type="internal" inventoryType={inventoryType} />
      </div>
      <div className='pl-3 pr-3'>
      <div className="inv-table">
        <table style={{ height: '100%' }}>
          <thead>
            <tr>
            <th style={{ minWidth: "120px" }}>Inventory ID</th>
              <th style={{ minWidth: "160px" }}>Project Name</th>
              <th style={{ minWidth: "100px" }}>Floor Name</th>
              <th style={{ minWidth: "160px" }}>Inventory RFC</th>
              <th style={{ minWidth: "110px" }}>Total sq.ft</th>
              <th style={{ minWidth: "110px" }}>Rate per sq.ft</th>
              <th style={{ minWidth: "160px" }}>Total Unit Price</th>
              <th style={{ minWidth: "220px" }}>Product Names</th>

              {inventoryType === 'installment' ?
              <>
               <th style={{ minWidth: "180px" }}>Shop/Apartment No</th>
               <th style={{ minWidth: "100px" }}>Tower</th>
               <th style={{ minWidth: "100px" }}>Beds</th>
               <th style={{ minWidth: "100px" }}>Bed Type</th>
               <th style={{ minWidth: "160px" }}>Category View</th>
               <th style={{ minWidth: "160px" }}>Category Charges</th>
               <th style={{ minWidth: "160px" }}>Down Payment</th>
               <th style={{ minWidth: "160px" }}>Possession Charges</th>
               <th style={{ minWidth: "180px" }}>Monthly installment</th>
              </>
              :
              <>
                <th style={{ minWidth: "220px" }}>Inventory Type</th>
                <th style={{ minWidth: "140px" }}>Is Fractional</th>
                <th style={{ minWidth: "140px" }}>Available sq.ft</th>
                {/*<th style={{ minWidth: "220px" }}>Rental</th>
                <th style={{ minWidth: "220px" }}>Appreciation</th>*/}
              </> }
              <th>
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {showLoader ? (
              <tr>
                <p  style={{paddingLeft:"36vw",paddingTop:"10vh"}} colSpan={10} className={''}>
                  Please wait ...
                </p>
              </tr>
            ) : (
              items.length > 0 &&
              items.map((item, index) => {
                return <TableTr item={item} inventoryType={inventoryType} key={`tr_${index}`} {...props} />
              })
            )}
          </tbody>
        </table>
      </div>
      <div className='col-md-6'>
        <Pagination pagination={pagination} pageAction={pageActionHandle} />
      </div>
    </div>
    </div>
    </div>
  )
}

const TableTr = (props) => {
  const [viewingDropdown, setViewingDropdown] = useState(false)
  const { item ,inventoryType} = props
  let productTypes = item.product_types && item.product_types.length && item.product_types.map((ptypes,index)=>
    <span>{(index ? ', ' : '') + ptypes.product_name}</span>
  )

  return (
    <tr className='hover__for__btns hoverForTr'>
      <td style={{ minWidth: "120px" }}>{item.id}</td>
      <td style={{ minWidth: "160px" }}>{item.project_name}</td>
      <td style={{ minWidth: "100px" }}>{item.floor_name}</td>
      <td style={{ minWidth: "160px" }}>{item.unique_code}</td>
      <td style={{ minWidth: "110px" }}>{parseFloat(item.total_sq_ft)}</td>
      <td style={{ minWidth: "110px" }}>{Number(item.rate_per_sq_ft).toLocaleString()}</td>
      <td style={{ minWidth: "160px" }}>{Number(item.total_unit_price).toLocaleString()}</td>
      <td style={{ minWidth: "220px" }}>{productTypes && productTypes.length ? productTypes : '---'}</td>

      {inventoryType === 'installment' ?
      <>
        <td style={{ minWidth: "180px" }}>{item.shop_apartment_no ? item.shop_apartment_no : "--"}</td>
        <td style={{ minWidth: "100px" }}>{item.tower ? item.tower : "--"}</td>
        <td style={{ minWidth: "100px" }}>{item.no_of_beds ? item.no_of_beds : "--"}</td>
        <td style={{ minWidth: "100px" }}>{item.bed_type}</td>
        <td style={{ minWidth: "160px" }}>{item.category_view}</td>
        <td style={{ minWidth: "160px" }}>{item.category_charges}</td>
        <td style={{ minWidth: "160px" }}>{Number(item.down_payment_amount).toLocaleString()}</td>
        <td style={{ minWidth: "160px" }}>{Number(item.possession_charges_amount).toLocaleString()}</td>
        {<td style={{ minWidth: "180px" }}>{item.monthly_installment_amount ? Number(item.monthly_installment_amount.toFixed(2)).toLocaleString() : "--"}</td>}
      </>
      :
      <>
        <td style={{ minWidth: "220px" }}>{item.inventory_type}</td>
        <td style={{ minWidth: "140px" }}>{item.is_fractional}</td>
        <td style={{ minWidth: "140px" }}>{parseFloat(item.available_sqft)}</td>
        {/*<td style={{ minWidth: "220px" }}>{item.rental}</td>
        <td style={{ minWidth: "220px" }}>{item.appreciation}</td>*/}
      </> }
      <td style={{ width: "60px"}}>
        <Dropdown
          className={`d-inline float-right text-right`}
          style={{ paddingRight: '1px', cursor: 'pointer', top: '5px' }}
          isOpen={viewingDropdown}
          toggle={() => setViewingDropdown((viewDrop) => !viewDrop)}
        >
          <DropdownToggle tag="span">
            <i className="far fa-ellipsis-v text-blue pr-1 pr-3 " style={{ fontSize: '22px' }} />
          </DropdownToggle>
          <DropdownMenu style={{ width: '150px' }} className={'p-0'}>
            <div
              onClick={() => {
                props.history.push(`/inventoryDetail/${item.slug}`)
              }}
              className={'container_separator card-container-text'}
            >
              <i className={'fa fa-eye'} /> Detail
            </div>
          </DropdownMenu>
        </Dropdown>
      </td>
    </tr>
  )
}

// END 16-09-2022

export const mapDispatchToProps = (dispatch) => {
  return {
    setTitle: (title) => dispatch(setTitle(title)),
  }
}
export const mapStateToProps = (state, ownProps) => {
  return {}
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(InternalSaleableInventory))
