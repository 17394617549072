/** @format */

import React, { useEffect, useState } from 'react'

const Pagination = (props) => {
  const { from, last_page, current_page, per_page, total, links, to } = props.pagination
  const [pages, setPages] = useState([])
  useEffect(() => {
    let loops = []
    if (links !== undefined) {
      links.map((link, index) => {
        if (!isNaN(link.label)) {

        }
        loops.push(link.label)
      })
    }
    setPages(loops)
  }, [props.pagination])
  const pageHandle = (e, page) => {
    if (page != current_page) {
      props.pageAction(page, per_page)
    }
  }

  return (
    <>
      <div class="d-flex bd-highlight mb-3 pr-2">
        <div class="mr-auto p-2 bd-highlight" style={{ marginTop: 15 }}>
          Showing{' '}
          <span style={{ color: '#016ff2' }}>
            {from}-{to}/{total}
          </span>
        </div>
        <div class="p-2 bd-highlight">
          <ul className="pagination d-inline-flex">
            {
              (current_page !==1) && (
                <li className="page-item">
                  <button
                    onClick={(e) => {
                      pageHandle(e, current_page - 1)
                    }}
                    className="page-link"
                  >
                    Previous
                  </button>
                </li>
              )
            }
            {pages.map((page, index) => {
              return (
                (page !== 'pagination.previous' && page !== 'pagination.next') && (
                  <li
                    key={index}
                    className={current_page === Number(page) ? 'page-item active' : 'page-item'}
                  >
                    <button
                      onClick={(e) => {
                        if (page !=='...' && current_page !== Number(page)){
                          pageHandle(e, Number(page))
                        }
                      }}
                      className="page-link"
                    >
                      {page}
                    </button>
                  </li>
                )
              )
            })}
            {
              (current_page !== last_page ) && (
                <li className="page-item">
                  <button
                    onClick={(e) => {
                      pageHandle(e, current_page + 1)
                    }}
                    className="page-link"
                  >
                    Next
                  </button>
                </li>
              )
            }
          </ul>
        </div>
      </div>
    </>
  )
}
export default Pagination
