/** @format */

import React, { useState } from 'react'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'

import {
  renderOptions,
  customTextArea,
  paymentRanger,
  sizeRanger,
  investmentLeadCityDropdown,
  investmentLeadAreaDrodpown,
  bedBathRanger,
  isRequired,
  isFieldRequired,
} from '../../components/ReduxForm/index'
import { sizeRange, priceRange, leadType, bedBathRange } from '../../utils/constant_data'
import { useEffect } from 'react'

let LeadCreationInner = ({
  areas,
  leadType,
  cities,
  properties,
  sizeRange,
  fetchAreas,
  subProperties,
  fetchSubProperties,
  setShareToggleState,
  selectedClient,
  addLeadLoader,
  handleSubmit,
  history,
  errorMessage,
  cityId,
  areaIds,
  type,
  subtype,
  editable,
  lead,
  isClientPage,
  setLeadModal,
}) => {
  useEffect(() => {
    editable && lead && lead.armsLeadAreas && fetchAreas(lead.city.id)
    if (editable && lead && lead.type) {
      fetchSubProperties(lead.type)
      setPropertyType(lead.type)
    }
  }, [])
  const [purpose, setPurpose] = useState(leadType[0].value)
  const [propertyType, setPropertyType] = useState()
  const [subPropertyType, setPropertySubType] = useState(null)

  const [city, setCity] = useState()
  const [area, setArea] = useState()

  const customStyles = {
    control: (base) => ({
      ...base,
      height: 35,
      minHeight: 35,
      '&:hover': {
        borderColor: '#ff2420',
        color: '#ff2420',
      },
      '&': {
        borderColor: '#ff2420',
        color: '#ff2420',
      },
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: 'inherit',
    }),
    indicatorSeparator: (base) => ({
      ...base,
      backgroundColor: '#ff2420',
    }),
    singleValue: (base) => ({
      ...base,
      color: 'inherit',
    }),
  }

  const findIndexOfMin = (minPrice, priceRange) => {
    let min = priceRange.rent.indexOf(minPrice)
    return min
  }
  let editClass = editable ? 'col-md-6' : 'col-md-4'
  return (
    <>
      <form onSubmit={handleSubmit} className="buyRentForm">
        {!isClientPage && (
          <div className="row">
            <div className={editClass}>
              <div className="subtitle">
                Lead Type <span className="text-danger">*</span>
              </div>
              <Field
                component={renderOptions}
                type="text"
                styles={errorMessage.purpose ? customStyles : ''}
                name="purpose"
                label="Select Lead Type"
                options={leadType}
                onChange={(value) => setPurpose(value.value)}
                defaultValue={editable && lead && lead.purpose ? calcType() : leadType[0]}
              />
            </div>
            <div className={editClass}>
              <div className="subtitle">
                Client <span className="text-danger ml-1">*</span>
              </div>

              <div
                className={
                  errorMessage.client && !selectedClient
                    ? 'client-wrapper-error'
                    : 'select-client-wrapper'
                }
                style={{
                  cursor: lead && lead.customer !== undefined ? 'not-allowed' : null,
                  backgroundColor: selectedClient ? '#eee' : lead && lead.customer !== undefined ? '#eaedf0' : null,
                  borderColor: lead && lead.customer !== undefined ? ' rgb(230, 230, 230)' : null,
                }}
                onClick={() => {
                  if (selectedClient) {
                    return
                  }
                  if (editable !== true) {
                    setShareToggleState(true)
                  }
                }}
              >
                {(selectedClient &&
                  selectedClient.message &&
                  selectedClient.message.includes('Client belongs to the')) ||
                  selectedClient !== null ||
                  (lead && lead.customer !== undefined) ? (
                  <span className="black-color">
                    {selectedClient && selectedClient.firstName
                      ? selectedClient && selectedClient.firstName + ' ' + selectedClient.lastName
                      : selectedClient && selectedClient.first_name + ' ' + selectedClient.last_name
                        ? selectedClient && selectedClient.first_name + ' ' + selectedClient.last_name
                        : lead &&
                        lead.customer &&
                        lead.customer.first_name + ' ' + lead.customer.last_name}
                  </span>
                ) : (
                  'Select Client'
                )}
              </div>
              {errorMessage.client && !selectedClient && (
                <p className="error-show-font">Client is required</p>
              )}
            </div>
          </div>
        )}
        <div className={`row ${isClientPage ? '' : 'mt-4'}`}>
          <div className={editClass}>
            <div className="subtitle">
              City <span className="text-danger">*</span>
            </div>
            <Field
              component={investmentLeadCityDropdown}
              type="text"
              name="cityId"
              errorShow={errorMessage.city && !cityId ? true : false}
              errorMessageText="City is required"
              styles={errorMessage.city && !cityId ? customStyles : ''}
              label="Select City"
              fetchAreas={fetchAreas}
              defaultValue={editable && cityId ? { label: cityId.name, value: cityId.id } : null}
              options={
                cities &&
                cities.map((item) => ({
                  label: item.name,
                  value: item.id,
                }))
              }
              onCallback={(value) => {
                setCity(value)
                setArea([])
              }}
            />
          </div>
          <div className={editClass}>
            <div className="subtitle">
              Areas <span className="text-danger">*</span>
            </div>
            <Field
              component={investmentLeadAreaDrodpown}
              type="text"
              styles={errorMessage.leadAreas && !areaIds ? customStyles : ''}
              errorShow={errorMessage.leadAreas && !areaIds ? true : false}
              errorMessageText="Areas are required"
              name="areaIds"
              isMulti={true}
              label="Select Areas"
              options={
                areas &&
                areas.items &&
                areas.items.map((item) => ({
                  label: item.name,
                  value: item.id,
                }))
              }
              onChange={(v) => {
                setArea(v)
              }}
              // defaultValue={editable && calcAreas()}
              customValue={area || (editable && calcAreas())}
              // customValue={area}
              city={city}
            />
          </div>
        </div>
        <div className="row mt-4">
          <div className={editClass}>
            <div className="subtitle">
              Property Type <span className="text-danger">*</span>
            </div>
            <Field
              component={renderOptions}
              styles={errorMessage.type && !type ? customStyles : ''}
              errorShow={errorMessage.type && !type ? true : false}
              errorMessageText="Property type is required"
              type="text"
              name="type"
              label="Select Property"
              options={
                properties.type &&
                properties.type.map((item) => ({
                  label: item.name,
                  value: item.value,
                }))
              }
              onChange={(value) => {
                fetchSubProperties(value.value)
                setPropertyType(value.value)
              }}
              defaultValue={editable && type ? { label: type.name, value: type.value } : null}
            />
          </div>
          <div className={editClass}>
            <div className="subtitle">
              Property Subtype <span className="text-danger">*</span>
            </div>
            <Field
              component={renderOptions}
              type="text"
              name="subtype"
              styles={errorMessage.subtype && !subtype ? customStyles : ''}
              errorShow={errorMessage.subtype && !subtype ? true : false}
              errorMessageText="Property Subtype is required"
              label="Select Property SubType"
              onChange={(value) => {
                setPropertySubType(value)
              }}
              options={
                subProperties &&
                subProperties.map((item) => ({
                  label: item.name,
                  value: item.value,
                }))
              }
              defaultValue={
                editable && subtype ? { label: subtype.name, value: subtype.value } : null
              }
            />
          </div>
        </div>
        <div className="row mt-4">
          <div className={editClass}>
            <div className="subtitle">Size Range</div>
            <Field
              component={sizeRanger}
              options={sizeRange}
              dataValue={
                editable
                  ? {
                    unit:
                      lead.size_unit === 'marla'
                        ? { value: 'marla', label: 'Marla' }
                        : lead.size_unit === 'acre'
                          ? { value: 'acre', label: 'Acre' }
                          : lead.size_unit,
                    size_min: lead.size,
                    min: lead.size,
                    size_max: lead.max_size,
                    max: lead.max_size,
                  }
                  : null
              }
              type="number"
              name="sizeRanger"
              label="Final Size"
            />
          </div>
          <div className={editClass}>
            <div className="subtitle">Price Range</div>
            <Field
              component={paymentRanger}
              purpose={purpose}
              dataValue={
                editable
                  ? {
                    price_min: lead.min_price,
                    price_max: lead.price,
                    min: findIndexOfMin(lead.min_price, priceRange),
                    max: findIndexOfMin(lead.price, priceRange),
                  }
                  : null
              }
              options={priceRange}
              type="number"
              name="ranger"
              label="Final Price"
            />
          </div>
        </div>
        {(propertyType || (lead && lead.type)) &&
          (subPropertyType || (lead && lead.subtype)) &&
          (propertyType === 'residential' || (lead && lead.type === 'residential')) && (
            <div className="row mt-4">
              <div className={editClass}>
                <div className="subtitle">Beds</div>
                <Field
                  component={bedBathRanger}
                  options={bedBathRange}
                  dataValue={
                    editable
                      ? {
                        maxVal: lead.maxBed,
                        minVal: lead.bed,
                        min: lead.bed,
                        max: lead.maxBed,
                      }
                      : null
                  }
                  type="number"
                  name="bedRanger"
                  label="Final Size"
                />
              </div>
              <div className={editClass}>
                <div className="subtitle">Baths</div>
                <Field
                  component={bedBathRanger}
                  purpose={purpose}
                  dataValue={
                    editable
                      ? {
                        maxVal: lead.maxBath,
                        minVal: lead.bath,
                        min: lead.bath,
                        max: lead.maxBath,
                      }
                      : null
                  }
                  options={bedBathRange}
                  type="number"
                  name="bathRanger"
                  label="Final Price"
                />
              </div>
            </div>
          )}

        <div className="row mt-4">
          {editable && (lead || lead.description) ? (
            <div className="col-md-12 mt-2">
              <Field
                component={customTextArea}
                type="text"
                name="description"
                label="Description"
                defaultValue={editable && lead && lead.description && lead.description}
              />
            </div>
          ) : (
            <div className="col-md-8 mt-2">
              <Field
                component={customTextArea}
                type="text"
                name="description"
                label="Description"
              />
            </div>
          )}
        </div>
        {!isClientPage && (
          <div className="mt-4">
            <button
              type="button"
              className="cancel mr-2"
              style={{ marginLeft: '10px' }}
              onClick={() => {
                if (editable) {
                  setLeadModal()
                } else {
                  history.push('/buy-rent-lead')
                }
              }}
            >
              Cancel
            </button>
            <button disabled={addLeadLoader} type="submit" className="add-button">
              {addLeadLoader ? <span className={'fa fa-circle-o-notch fa-spin'}></span> : 'Submit'}
            </button>
          </div>
        )}
      </form>
    </>
  )

  function calcType() {
    const found = leadType && leadType.find((id) => id.value === lead.purpose)
    return { label: found.label, value: found.value }
  }
  async function calcAreas() {
    const found =
      areas &&
      areas.items &&
      lead.armsLeadAreas &&
      areas.items.filter((area) => {
        return lead.armsLeadAreas.find((leadArea) => leadArea.area.id === area.id)
      })
    if (found && found.length > 0) {
      const res = found.map((f) => {
        return { value: f.id, label: f.name }
      })
      setArea(res)
    } else {
      return null
    }
  }
}

let selector = formValueSelector('leadCreationForms')
const mapStateToProps = (state, ownProps) => {
  const cityId = selector(state, 'cityId')
  const type = selector(state, 'type')
  const subtype = selector(state, 'subtype')
  const areaIds = selector(state, 'areaIds')

  let errorCheck = {
    purpose: selector(state, 'purpose'),
  }

  if (ownProps.editable === true) {
    const foundArea =
      ownProps.areas &&
      ownProps.areas.items &&
      ownProps.lead.armsLeadAreas &&
      ownProps.areas.items.filter((area) => {
        return ownProps.lead.armsLeadAreas.find((leadArea) => leadArea.area.id === area.id)
      })
    let res
    if (foundArea && foundArea.length > 0) {
      res = foundArea.map((f) => {
        return { value: f.id, label: f.name }
      })
    } else {
      return null
    }
    const foundType =
      ownProps &&
      ownProps.properties &&
      ownProps.properties.type &&
      ownProps.properties.type.find((tt) => tt.value === ownProps.lead.type)
    let found
    if (foundType.value === 'plot') {
      found =
        ownProps.properties &&
        ownProps.properties.subType &&
        ownProps.properties.subType.plot &&
        ownProps.properties.subType.plot.find((tt) => tt.value === ownProps.lead.subtype)
    } else if (foundType.value === 'commercial') {
      found =
        ownProps.properties &&
        ownProps.properties.subType &&
        ownProps.properties.subType.commercial &&
        ownProps.properties.subType.commercial.find((tt) => tt.value === ownProps.lead.subtype)
    } else if (foundType.value === 'residential') {
      found =
        ownProps.properties &&
        ownProps.properties.subType &&
        ownProps.properties.subType.residential &&
        ownProps.properties.subType.residential.find((tt) => tt.value === ownProps.lead.subtype)
    }
    return {
      initialValues: {
        purpose:
          ownProps.lead.purpose === 'rent'
            ? { label: 'Rent', value: 'rent' }
            : { label: 'Buy', value: 'buy' },
        cityId: { label: ownProps.lead.city.name, value: ownProps.lead.city.id },
        customerId: ownProps.lead.customer,
        areaIds: res,
        type: { label: foundType && foundType.name, value: foundType && foundType.value },
        subtype: { label: found && found.name, value: found && found.value },
        sizeRanger: {
          unit: ownProps.lead.size_unit,
          size_min: ownProps.lead.size,
          min: ownProps.lead.size,
          size_max: ownProps.lead.max_size,
          max: ownProps.lead.max_size,
        },
        description: ownProps.lead.description,
      },
    }
  } else {
    return {
      errorCheck,
      cityId,
      areaIds,
      type,
      subtype,
    }
  }
}

export default withRouter(
  connect(mapStateToProps)(
    reduxForm({
      form: 'leadCreationForms',
    })(LeadCreationInner)
  )
)
