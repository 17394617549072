import React, { useEffect, useState} from "react";
import "rc-pagination/assets/index.css";
import Pagination from "rc-pagination";
import local from "../../local/en_US";
import axios from "axios";
import config from "../../config";
import  AddCampaignForm  from "./AddCampaignForm";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import "./style.scss";
const CampaignOutage = () => {
  const [loader, setLoader] = useState(false);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [campaignsDataList, setcampaignsDataList] = useState([]);
  const [pagination, setPagination] = useState({});
  const [isaddCampaignFormOpen, setIsaddCampaignFormOpen] = useState(false);
  const [editCampaignData, setEditCampaignData] = useState();
  const [editMode, setEditMode] = useState(false);

  const textItemRender = (current, type, element) => {
    if (type === "prev") {
      return "Prev";
    }
    if (type === "next") {
      return "Next";
    }
    return element;
  };

  const getCampaignsData = () => {
    let url = `${config.apiPath}/api/campaign/getCampaigns`;
    axios
      .get(url)
      .then((result) => {
        setcampaignsDataList(result.data.data);
        setLoader(false);
      })
      .catch((error) => {
        setcampaignsDataList([]);
        setLoader(false);
      });
  };

  const EditCampaignHandler = (id) => {
    let url = `${config.apiPath}/api/campaign/viewCampaign?queue_id=${id}
    `;
    new Promise((resolve, reject) => {
      axios
        .get(url)
        .then((result) => {
          setEditMode(true);
          openAddCampaignFormHandler();
          setEditCampaignData(result.data);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  };

  useEffect(() => {
    setLoader(true);
    getCampaignsData();
  }, []);

  const pageActionHandle = (page, per_page) => {
    // setPage(page);
    // setPerPage(perPage);
    // getBookings(props.match.params.bookingStatus, page, per_page)
  };

  const openAddCampaignFormHandler = () => {
    setIsaddCampaignFormOpen(!isaddCampaignFormOpen);
  };

  return (
    <div className="mainWrapper">
      <button
        onClick={() => {
          setIsaddCampaignFormOpen(!isaddCampaignFormOpen);
          setEditMode(false);
        }}
        className="addCompainBtn btn-primary"
      >
        + Add Campaign{" "}
      </button>
      <div className="card-body py-5 px-0 ">
        <div className="inv-table pt-3">
          <table style={{ height: "100%" }}>
            <thead>
              <tr>
                <th className="pl-5" style={{ minWidth: "260px" }}>UAN Number</th>
                <th style={{ minWidth: "260px" }}>Queue Name</th>
                <th style={{ minWidth: "260px" }}>Outage</th>
                <th style={{ minWidth: "260px" }}>Total phone_number</th>
                <th style={{ minWidth: "120px" }}>Action</th>
              </tr>
            </thead>
            <tbody>
              {loader ? (
                <tr>
                  <p
                    style={{ paddingLeft: "36vw", paddingTop: "10vh" }}
                    colSpan={10}
                    className={""}
                  >
                    Loading ...
                  </p>
                </tr>
              ) : (
                <>
                  {campaignsDataList &&
                    campaignsDataList.map((data, index) => {
                      return (
                        <TableTr
                          data={data}
                          EditCampaignHandler={EditCampaignHandler}
                        />
                      );
                    })}{" "}
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <div className="row mt-3 mb-2">
        <div className="col-md-4 totalCountAlignment">
          <span className="totalCountStyle">
            Showing
            <span className="totalCountSubStyle">
              {(page - 1) * perPage + 1} -{" "}
              {pagination.total >= page * perPage
                ? page * perPage
                : pagination.total}
            </span>{" "}
            / {pagination.total}
          </span>
        </div>

        <div className="col-md-6" style={{ marginLeft: 0 }}>
          <Pagination
            className="ps-pagination"
            // hideOnSinglePage={true}
            showPrevNextJumpers={false}
            itemRender={textItemRender}
            pageSize={parseInt(perPage, 10)}
            current={parseInt(page, 10)}
            onChange={(page, perPage) => pageActionHandle(page, perPage)}
            total={pagination.total}
            locale={local}
          />
        </div>
        <div className="col-md-2"> </div>
      </div>
      <AddCampaignForm
        isaddCampaignFormOpen={isaddCampaignFormOpen}
        editCampaignData={editCampaignData}
        editMode={editMode}
        openAddCampaignFormHandler={openAddCampaignFormHandler}
        getCampaignsData={getCampaignsData}
      />
    </div>
  );
};
const TableTr = ({ data, EditCampaignHandler }) => {
  const [viewingDropdown, setViewingDropdown] = useState(false);
  return (
    <tr className="hover__for__btns hoverForTr">
      <td className="pl-5" style={{ minWidth: "260px" }}>{data.queue_id}</td>
      <td style={{ minWidth: "260px" }}>{data.queue_name}</td>
      <td style={{ minWidth: "260px" }}>{data.outage}</td>
      <td style={{ minWidth: "260px" }}>{data.phone_count}</td>

      <td style={{ width: "60px" }}>
        <Dropdown
          className={`d-inline float-right text-right`}
          style={{ paddingRight: "1px", cursor: "pointer", top: "5px" }}
          isOpen={viewingDropdown}
          toggle={() => setViewingDropdown((viewDrop) => !viewDrop)}
        >
          <DropdownToggle tag="span">
            <i
              className="far fa-ellipsis-v text-black pr-1 pr-3 "
              style={{ fontSize: "22px" }}
            />
          </DropdownToggle>
          <DropdownMenu style={{ width: "150px" }} className={"p-0"}>
            <div
              onClick={() => {
                EditCampaignHandler(data.queue_id);
              }}
              className={"container_separator card-container-text"}
            >
              <i className={"fa fa-eye"} /> Edit
            </div>
          </DropdownMenu>
        </Dropdown>
      </td>
    </tr>
  );
};

export default CampaignOutage;
