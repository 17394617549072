/** @format */

import React, { useEffect, useState } from 'react'
import { Modal, ModalBody } from 'reactstrap'
import Loader from 'react-loader-spinner'

import CommentDetail from '../../../components/CommentDetail'
import NoComment from '../../../images/no_comments.svg'
import { scrollToBottom } from '../../../utils/helper'

const CommentModal = ({
  commentToggleState,
  commentToggle,
  allCommentsLoader,
  commentData,
  addCommentToServer,
  addCommentLoader,
  fetchLeadTimeLine,
  leadId,
  checkForRead,
  commentTitle,
  checkCallFrom,
}) => {
  const [comment, setComment] = useState('')
  const [commentCopy, setCommentCopy] = useState('')
  let callWtsappCheckComment =
    (checkForRead && checkForRead.checkForManager) ||
    (checkForRead && !checkForRead.showReadOnly) ||
    (checkForRead && checkForRead.ownLeadReadOnly)

  useEffect(() => {
    scrollToBottom('comment-scroll-container')
  }, [commentData])

  return (
    <Modal
      isOpen={commentToggleState}
      toggle={commentToggle}
      size="lg"
      style={{ maxWidth: '1050px', width: '100%' }}
      contentClassName="comment-modal-width"
    >
      <ModalBody>
        <div className="comment-main-wrapper">
          <div className="comment-popup-body-header">
            <div className="popup-body-header-title">
              {commentTitle ? commentTitle : 'Comments'}
            </div>
            <i
              className="fal fa-times-circle popup-body-header-icon"
              onClick={() => {
                if (commentCopy) {
                  fetchLeadTimeLine(leadId)
                  setCommentCopy('')
                }

                commentToggle(false)
              }}
            ></i>
          </div>

          {allCommentsLoader ? (
            <div className="comment-loader-area">
              <Loader type="RevolvingDot" height={30} width={30} color="#046EF2" />
            </div>
          ) : (
            <div className="comment-main-body" id={'comment-scroll-container'}>
              {commentData && commentData.length === 0 ? (
                <>
                  <div className="comment-image-container">
                    <img src={NoComment} className="not-found-image" />
                    <div className="mt-4 no-comments-title">No Comments</div>
                  </div>
                </>
              ) : (
                commentData &&
                commentData.map((item, index) => (
                  <CommentDetail data={item} key={index} checkCallFrom={checkCallFrom} />
                ))
              )}
            </div>
          )}

          <div className="text-area-container">
            <textarea
              rows="3"
              className="comment-description"
              onChange={(e) => {
                setCommentCopy(e.target.value)
                setComment(e.target.value)
              }}
              value={comment}
            ></textarea>
          </div>
          {callWtsappCheckComment ? (
            <button
              className="comment-button"
              disabled={!comment}
              onClick={() => {
                if (callWtsappCheckComment) {
                  setComment('')
                  addCommentToServer(comment)
                }
              }}
            >
              {addCommentLoader ? (
                <i className={`fa fa-circle-o-notch fa-spin`}></i>
              ) : (
                'Add Comment'
              )}{' '}
            </button>
          ) : null}
        </div>
      </ModalBody>
    </Modal>
  )
}

export default CommentModal
