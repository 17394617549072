/** @format */

import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import config from '../../config'
import Select from 'react-select'
import '../../../src/App.scss'
import { filter } from 'lodash'
const InventoryFilter = (props) => {
  const [projects, setProjects] = useState([])
  const [floors, setFloors] = useState([])
  const [loader, setLoader] = useState(false)
  const [filters, setFilters] = useState({})
  const [isClear, setIsClear] = useState(true)
  const [filterSelected, setFilterSelected] = useState('PROJECT')
  const [dropDownOpt, setDropDownOpt] = useState([
    { label: 'PROJECT', value: 'PROJECT' },
    { label: 'ID', value: 'ID' },
    { label: 'UNIQUE CODE', value: 'UNIQUE CODE' },
  ])
  const [fractionalDownOpt, setFractionalDownOpt] = useState([
    { label: 'YES', value: 'yes' },
    { label: 'NO', value: 'no' },
  ])

  const projectRef = useRef(null)
  const floorRef = useRef(null)
  const fractionalRef = useRef(null)

  const getProjects = () => {
    setLoader(true)
    getFetchPromise('projects')
      .then((result) => {
        setProjects(result.data)
        setFloors([])
        setLoader(false)
      })
      .catch((error) => {
        setProjects([])
        setLoader(false)
        setFloors([])
      })
  }
  const getFloors = (projectId) => {
    setLoader(true)
    getFetchPromise('floors', { project_id: projectId })
      .then((result) => {
        setFloors(result.data)
        setLoader(false)
      })
      .catch((error) => {
        setLoader(false)
        setFloors([])
      })
  }


  const getFetchPromise = (slug,params={}) => {
    return new Promise((resolve,reject)=>{
      axios.get(`${config.apiPath}/api/imarat/${slug}${props.type ? `?productType=${props.type}` : null}`,{params}).then((result)=>{
        resolve(result.data)
      }).catch((error)=>{
        reject(error.response)
      })
    })
  }

  useEffect(() => {
    getProjects()
  }, [])

  const projectHandle = (e) => {
    setFilters({
      ...filters,
      project_id: e.target.value,
    })
    getFloors(e.target.value)
    setIsClear(false)
    if (props.updateInventoryHandle) {
      return props.updateInventoryHandle({ ...filters, project_id: e.target.value })
    }
  }
  const floorHandle = (e) => {
    setFilters({
      ...filters,
      floor_id: e.target.value,
    })
    setIsClear(false)
    if (props.updateInventoryHandle) {
      return props.updateInventoryHandle({ ...filters, floor_id: e.target.value })
    }
  }

  const fractionalHandle = (e) => {
    setFilters({
      ...filters,
      is_fractional: e.target.value,
    })
  }

  const projectBYIDHandle = (value) => {
    setFilters({
      ...filters,
      SearchProjectByIdValue: value,
    })
  }

  const SearchProjectById = () => {
    setIsClear(false)
    props.updateInventoryHandle({
      ...filters,
      SearchProjectByIdValue: filters.SearchProjectByIdValue,
      is_fractional: filters.is_fractional
    })
  }

  const ProjectBYUNIQUECODEHandler = (value) => {
    setFilters({
      ...filters,
      SearchProjectByUniqueCodeValue: value,
      is_fractional: filters.is_fractional
    })
  }

  const SearchProjectByUNIQUECODE = () => {
    setIsClear(false)
    props.updateInventoryHandle({
      ...filters,
      SearchProjectByUniqueCodeValue: filters.SearchProjectByUniqueCodeValue,
      is_fractional: filters.is_fractional
    })
  }

  const clearFilterHandle = () => {
    setFilters({})
    setIsClear(true)
    projectRef.current.value = ''
    floorRef.current.value = ''
    if(props.inventoryType !== "installment")
     {  fractionalRef.current.value = ''}
    props.updateInventoryHandle({})
  }

  const clearFilterHandleTwo = () => {
    setFilters({})
    setIsClear(true)
    props.updateInventoryHandle({})
  }
  return (
    <div className={'inventory-filters'}>
      <div className="row pt-4 pr-4">
        {filterSelected === 'PROJECT' && (
          <>
            <div className="col-md-2 col-lg-2 form-group">
              <select
                ref={projectRef}
                onChange={projectHandle}
                className={'custom-select pl-2 mb-0'}
              >
                <option value="">Select a Project</option>
                {projects.map((project, index) => {
                  return <option value={project.id}>{project.name}</option>
                })}
              </select>
            </div>
            <div className="col-md-2 col-lg-2 form-group">
              <div className="input-group">
                <select ref={floorRef} onChange={floorHandle} className={'custom-select pl-2 mb-0'}>
                  <option value="">Select a Floors</option>
                  {floors.map((floor, index) => {
                    return <option value={floor.id}>{floor.floor_name}</option>
                  })}
                </select>
              </div>
            </div>{' '}
            {props.inventoryType !== "installment" &&
            <div className="col-md-2 col-lg-2 form-group">
              <div className="input-group">
                <select ref={fractionalRef} onChange={fractionalHandle} className={'custom-select pl-2 mb-0'}>
                  <option value="">Fractional</option>
                  {fractionalDownOpt.map((data, index) => {
                    return <option value={data.value}>{data.label}</option>
                  })}
                </select>
              </div>
            </div>}
          </>
        )}
        <div className="col-md-2 col-lg-2 form-group">
          <div class="input-group mb-3">
            <input
              type="number"
              placeholder="ID"
              class="form-control"
              value={filters.SearchProjectByIdValue ? filters.SearchProjectByIdValue : ' '}
              onChange={(e) => projectBYIDHandle(e.target.value)}
            />
          </div>
        </div>



        <div className="col-md-3 col-lg-3 form-group">
          <div class="input-group mb-3">
            {/*<input
              type="text"
              placeholder="uni code"
              class="form-control"
              value={
                filters.SearchProjectByUniqueCodeValue ? filters.SearchProjectByUniqueCodeValue : ''
              }
              onChange={(e) => {
                ProjectBYUNIQUECODEHandler(e.target.value)
              }}
            />*/}

            {filterSelected === 'ID' || filterSelected === 'UNIQUE CODE' ? (
              <div className="ml-4">
                <button
                  class="btn btn-primary"
                  type="button"
                  id="button-addon2"
                  onClick={() => SearchProjectByUNIQUECODE()}
                >
                  Search
                </button>
                <span className="ml-2">
                  {!isClear && (
                    <button onClick={clearFilterHandle} className={'btn btn-primary'}>
                      <span> Reset</span>
                    </button>
                  )}
                </span>
              </div>
            ) : (
              <div className="ml-4">
                <button
                  class="btn btn-primary"
                  type="button"
                  id="button-addon2"
                  onClick={() => SearchProjectById()}
                >
                  Search
                </button>
                <span className="ml-2">
                  {!isClear && (
                    <button onClick={clearFilterHandle} className={'btn btn-primary'}>
                      <span> Reset</span>
                    </button>
                  )}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>



      {filterSelected === 'ID' && (
        <div className="col-md-4 col-lg-4 form-group">
          <div class="input-group mb-3">
            <button onClick={clearFilterHandleTwo} className={'btn btn-primary'}>
              <span> Reset</span>
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default InventoryFilter
