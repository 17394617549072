/** @format */

import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
// import 'bootstrap/dist/css/bootstrap.min.css';
import * as Sentry from '@sentry/browser'
import App from './App'
import registerServiceWorker from './serviceWorker'
import { configureStore } from './store/configureStore'

const host = window.location.hostname

if (host === 'arms.graana.com') {
  Sentry.init({ dsn: 'https://6e577505defc447d9b92ebc04ed5c5e1@sentry.graana.rocks/9' })
}

const store = configureStore()

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
)
registerServiceWorker()
