import React,{useState, useEffect, useRef} from 'react'
import axios from 'axios'
import config from '../../config'
import Pagination from 'rc-pagination'
import moment from 'moment'
import { DateRangePicker } from 'react-dates'
import Select from 'react-select'
import { CSVLink } from 'react-csv'
import { connect } from 'react-redux'
import { setTitle } from '../../actions/generalSetting'
import CallCenterDatePicker from "../../callCenter/CallCenterDatePicker";

const InboundLog = (props) => {
  const csvLink = useRef()
  const { setTitle}=props;
  const [loader, setLoader] = useState(false)
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [reportData, setReportData] = useState([])
  const [agentNames, setAgentNames] = useState([])
  const [queueIds, setQueueIds] = useState([])
  const [pagePermission, setPagePermission] = useState(true)
  const [totalRows, setTotalRows] = useState(1)
  const [csvData, setCsvData] = useState([])
  const [filters, setFilters] = useState({
    fromDate: moment().startOf('month'),
    toDate: moment().endOf('month'),
    queueId:{},
    agentName:{},
    status:{}
  })
  const [statusList,setstatusList] = useState([])
  const [focusedInput, setFocusedInput] = useState(null)
  const getData = async (page,perPage) => {
    setLoader(true)

    const param={
      page,
      pageSize:perPage,
      startingDate:moment(filters.fromDate).format('YYYY-MM-DD'),
      endingDate:moment( filters.toDate).format('YYYY-MM-DD'),
      agentId:filters.agentName.value,
      custPhoneNo:filters.custPhoneNo,
      queueId:filters.queueId.value,
      status:filters.status.value
    }
    await axios
      .get(
        `${config.apiPath}/api/cdrreport/inbound_calls`, {params: param} )
      .then((res) => {
        setReportData(res.data)
        setTotalRows(res.data.length > 0 && res.data[0].total_rows || 0)
      })
    setLoader(false)
  }
  const getQueue = async () => {
    await axios.get(`${config.apiPath}/api/cdrreport/getDistinctData`).then((res) => {
    // await axios.get(`http://localhost:3001/api/cdrreport/getDistinctData`).then((res) => {
        setAgentNames(res.data.agentData);
        setQueueIds(res.data.queueData);
      setstatusList(res.data.callStatuses.map((data)=>{
        return {label:data.lable , value:data.value}

      }))
    })
  }
  const getCsvData = async () => {
    const param = {
      page,
      pageSize: perPage,
      startingDate: moment(filters.fromDate).format('YYYY-MM-DD'),
      endingDate: moment(filters.toDate).format('YYYY-MM-DD'),
      agentId:filters.agentName.value,
      custPhoneNo:filters.custPhoneNo,
      queueId:filters.queueId.value,
      status:filters.status.value,
      isCSV:1
    }
    await axios
    .get(
      // `http://localhost:3001/api/cdrreport/inbound_calls`, {params: param} )
      `${config.apiPath}/api/cdrreport/inbound_calls`, {params: param} )
    .then((data) => {
        const allRebates=
        data.data.length > 0 ?
        data.data.map((data) => ({
          'User ID': data.agent_id,
          'User Name': data.user_name,
          'Transfer To': data.transfer_to_name,
          'Call Date': data.call_date_time?moment(data.call_date_time).format('DD-MM-YYYY (hh:mm A)'):'',
          'Phone': data.phone_no,
          'Agent Phone': data.agentPhoneNumber?data.agentPhoneNumber:'N/A',
          'Supervisor Phone': data.agentSupervisiorPhoneNumber?data.agentSupervisiorPhoneNumber:'N/A',
          'Duration': hourFormat(data.total_seconds),
          'Status': data.disposition,
          'HangUp By': data.hangup_by,
        })):[];

        console.log("@@allRebates",allRebates)
        setCsvData(allRebates);
      })
      .catch((e) => console.log(e))
    csvLink.current.link.click()
  }
  useEffect(() => {
    setTitle({ name: 'Inbound Logs' })
    let isTeamLead = ( localStorage.getItem('user_agency_mgmt')!== ''  && localStorage.getItem('user_agency_mgmt')!==null)? JSON.parse(localStorage.getItem('user_agency_mgmt')).data.isTeamLead : false;
    setPagePermission(isTeamLead)
    getData(page,perPage);
    getQueue()
  }, [])
  useEffect(() => {
    getData(page,perPage)
  }, [page])

  const pageActionHandle = (page, perPage) => {
    getData(page,perPage)
  }
  return (
    <>
      {pagePermission ? (
        <div className="card mt-2">
          <div style={{ padding: '1%', height: '85vh' }}>
            <div className="ccmc-filters row">
              <div className="col-md-10 row">
                <div className="col-md-3 ccmcDateRange">
                  {/*<CallCenterDatePicker targetDateHandle={(dateStart, dateEnd) => {
                    if (dateStart) {
                      setFilters((filters) => ({
                        ...filters,
                        fromDate: dateStart,
                      }))
                    }
                    if (dateEnd) {
                      setFilters((filters) => ({
                        ...filters,
                        toDate: dateEnd,
                      }))
                    }
                  }}/>*/}
                  <DateRangePicker
                    isOutsideRange={() => false}
                    startDate={filters.fromDate}
                    endDate={filters.toDate}
                    startDateId="buyrent_date_input_start"
                    minimumNights={1}
                    endDateId="buyrent_date_input_end"
                    className="activeDatePicker"
                    onDatesChange={({ startDate: dateStart, endDate: dateEnd }) => {
                      if (dateStart) {
                        setFilters((filters) => ({
                          ...filters,
                          fromDate: dateStart,
                        }))
                      }
                      if (dateEnd) {
                        setFilters((filters) => ({
                          ...filters,
                          toDate: dateEnd,
                        }))
                      }
                    }}
                    focusedInput={focusedInput}
                    onFocusChange={(input) => setFocusedInput(input)}
                    showClearDates
                    noBorder={false}
                  />*
                </div>
                {/* <div className="col-md-3">
                <Select
                    name="queueId"
                    placeholder="Select Queue Id"
                    value={queueIds.filter(function(option) {
                      return option.value === filters.queueId.value;
                    })}
                    options={queueIds}
                    noOptionsMessage={() => 'No Options'}
                    onChange={(event) => {
                      setFilters({ ...filters, queueId: event })
                    }}
                  />
                </div> */}
                <div className="col-md-3">
                <Select
                    name="agentName"
                    placeholder="Select Agent Name"
                    value={agentNames.filter(function(option) {
                      return option.value === filters.agentName.value;
                    })}
                    options={agentNames}
                    noOptionsMessage={() => 'No Options'}
                    onChange={(event) => {
                      setFilters({ ...filters, agentName: event})
                    }}
                  />
                </div>



                <div className="col-md-3">
                  <input
                    type="number"
                    placeholder="Customer Phone No."
                    className="form-control"
                    name="custPhoneNo"
                    onChange={(event) => {
                      setFilters({ ...filters, custPhoneNo: event.target.value })
                    }}
                    value={filters.custPhoneNo ? filters.custPhoneNo : ''}
                  />
                </div>


                <div className="col-3">
                <Select
                    name="status"
                    placeholder="Select Status"
                    value={statusList.filter(function(option) {
                      return option.value === filters.status.value;
                    })}
                    options={statusList}
                    noOptionsMessage={() => 'No Options'}
                    onChange={(event) => {
                      setFilters({ ...filters, status: event})
                    }}
                  />
                </div>



              </div>
              {/* <div className="col-md-1"></div> */}
              <div className="col-md-2 row justify-content-end ">
                {/*  <div className="col-md-6">
            <button className="export-btn" onClick={exportData}>
              Export
            </button>
            </div> */}
                <div className="col-md-6 justify-content-space-between">
                  <div className="ccmc-clearFilter">
                    <button className={`newFormSearch`} onClick={() => getData(1, perPage)}>
                      <span className={`fa fa-search`}></span>
                    </button>
                    {/* <button className={`newFormClear`} onClick={clearFilter}>
                Clear
              </button> */}
                    {reportData.length > 0 && (
                      <div>
                        <button onClick={getCsvData} className="newFormSearch ml-1">
                          <span className={`fa fa-download`}></span>
                        </button>
                        <CSVLink
                          data={csvData}
                          filename="inbound_Call_logs.csv"
                          className="hidden"
                          ref={csvLink}
                          target="_blank"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="reports-table">
              <table style={{ height: '80%' }}>
                <thead>
                  <tr>
                    {/* <th style={{ minWidth: '200px' }}>Campaign Name</th> */}
                    <th style={{ minWidth: '160px' }}>User ID</th>
                    <th style={{ minWidth: '160px' }}>User Name</th>
                    <th style={{ minWidth: '160px' }}>Transfer To</th>
                    <th style={{ minWidth: '180px' }}>Call Date</th>
                    <th style={{ minWidth: '160px' }}>Phone</th>
                    <th style={{ minWidth: '160px' }}>Agent Phone</th>
                    <th style={{ minWidth: '160px' }}>Supervisor Phone</th>
                    <th style={{ minWidth: '160px' }}>HangUp By</th>
                    <th style={{ minWidth: '160px' }}>Duration</th>
                    <th style={{ minWidth: '160px' }}>Status</th>
                    <th style={{ minWidth: '160px' }}>Recordings</th>
                  </tr>
                </thead>
                <tbody>
                  {loader ? (
                    <tr>
                      <p
                        style={{ paddingLeft: '36vw', paddingTop: '10vh' }}
                        colSpan={10}
                        className={''}
                      >
                        Loading ...
                      </p>
                    </tr>
                  ) : (
                    <>
                      {reportData.length > 0 ? (
                        reportData.map((e) => {
                          return <TableTr data={e} />
                        })
                      ) : (
                        <tr>
                          <p style={{ paddingLeft: '36vw', paddingTop: '10vh' }}
                            colSpan={10}
                            className={''} >
                            No Data Found
                          </p>
                        </tr>
                      )}
                    </>
                  )}
                </tbody>
                {(reportData.length > 0 && !loader )&& (
                <tfoot className="row mt-2 mb-1">
                  <tr className="col-md-12 d-flex flex-column justify-content-center">
                    <td colSpan={14} align="center">
                      <div className="pagination  justify-content-center">
                        <Pagination
                          className="ps-pagination"
                          hideOnSinglePage={true}
                          pageSize={perPage}
                          current={page}
                          showPrevNextJumpers={false}
                          onChange={(cPage, pSize) => {
                            setPage(cPage)
                          }}
                          total={totalRows}
                        />
                      </div>
                    </td>
                  </tr>
                </tfoot>
                )}
              </table>
            </div>
          </div>
        </div>
      ) : null}
    </>
  )
}

const TableTr = ({ data }) => {
  return (
    <tr className="hover__for__btns hoverForTr">
      {/* <td style={{ minWidth: '200px' }}>{data.queue}</td> */}
      <td style={{ minWidth: '160px' }}>{data.agent_id}</td>
      <td style={{ minWidth: '160px' }}>{data.user_name}</td>
      <td style={{ minWidth: '160px' }}>{data.transfer_to_name}</td>
      <td style={{ minWidth: '180px' }}>{data.call_date_time?moment(data.call_date_time).format('DD-MM-YYYY (hh:mm A)'):''}</td>
      <td style={{ minWidth: '160px' }}>{data.phone_no}</td>
      <td style={{ minWidth: '160px' }}>{data.agentPhoneNumber?data.agentPhoneNumber:'N/A'}</td>
      <td style={{ minWidth: '160px' }}>{data.agentSupervisiorPhoneNumber?data.agentSupervisiorPhoneNumber:'N/A'}</td>
      <td style={{ minWidth: '160px' }}>{data.hangup_by}</td>
      <td style={{ minWidth: '160px' }}>{hourFormat(data.total_seconds)}</td>
      <td style={{ minWidth: '160px' }}>{data.disposition}</td>
      {/* <td style={{ minWidth: '160px' }}>{data.recording}</td> */}
      <td style={{ minWidth: '200px' }}>
        {
          data.recording && (
            <audio className='audio_st' controls>
              <source src={`${config.asteriskUrl}/api/recording.php?filename=${data.recording}`} type='audio/mp3'/>
            </audio>
          )
        }
      </td>
    </tr>
  )
}

const mapDispatchToProps = (dispatch) => ({
  setTitle: (title) => dispatch(setTitle(title)),
})
const mapStateToProps = (state) => ({
})
export default connect(mapStateToProps, mapDispatchToProps)(InboundLog);
export   const hourFormat=(getTotalsecounds)=>{
  const totalSeconds = getTotalsecounds;

  const minutes = Math.floor(totalSeconds / 60);

  const seconds = totalSeconds % 60;

  function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  const result = `${padTo2Digits(minutes)}:${padTo2Digits(seconds)}`;

  return result

}
