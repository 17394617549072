/** @format */

import React from 'react'
import Modal from 'react-bootstrap/Modal'
import ModalBody from 'react-bootstrap/ModalBody'
import TimelineTile from './timelineTile'
import { StaticData } from '../../StaticData'
import Loader from './../CircleLoader'
import './style.scss'

const TimlinesModal = (props) => {
  const { active, viewTimelineModalClose, dataForTimelinePopup, timelineLoading } = props
  const tableHeadColumn = StaticData.tableHeadListForTimelineTable
  return (
    <div>
      <Modal show={active} dialogClassName="timelineModal">
        <ModalBody>
          <div className="timeline-body">
            <div class="table-header-main">{`Activity Timeline`} </div>
            <div className="table-main">
              {dataForTimelinePopup &&
              dataForTimelinePopup.length > 0 &&
              timelineLoading === false ? (
                <div className="table-responcive">
                  <table className="table">
                    <thead>
                      <tr>
                        {tableHeadColumn.map((item) => {
                          return <th>{item.label}</th>
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {dataForTimelinePopup.map((item) => {
                        return <TimelineTile data={item} />
                      })}
                    </tbody>
                  </table>
                </div>
              ) : (
                <Loader loading={timelineLoading} />
              )}
            </div>
            <div class="table-footer-main">
              <button
                className="btn btn-secondary"
                onClick={() => {
                  viewTimelineModalClose()
                }}
              >{`Cancel`}</button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default TimlinesModal
