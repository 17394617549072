/** @format */
export const headerTemplate = () => {
  return [
    {
      component: 'LABEL',
      props: {
        label: 'Wanted ID',
      },
      tdClassName: 'pl-3',
    },
    {
      component: 'LABEL',
      props: {
        label: 'NAME',
      },
    },
    {
      component: 'LABEL',
      props: {
        label: 'REQUIREMENTS',
      },
    },
    {
      component: 'LABEL',
      props: {
        label: 'STATUS',
      },
    },
    {
      component: 'LABEL',
      props: {
        label: 'ARMS LEAD ID',
      },
    },
    {
      component: 'LABEL',
      props: {
        label: 'ASSIGNED TO',
      },
    },
    {
      component: 'LABEL',
      props: {
        label: 'ADVISOR’S CONTACT NO',
      },
    },
    {
      component: 'LABEL',
      props: {
        label: 'CLOSING REASON',
      },
    },
    {
      component: 'LABEL',
      props: {
        label: 'Created Date',
      },
    },
    {
      component: 'LABEL',
      props: {
        label: '',
      },
      tdClassName: 'noColor',
    },
  ]
}
export const bodyTemplate = (getValue, handleAction, props) => {
  const constructProp = (templateProperty, cellIndex, rowIndex, rowData) =>
    getValue(templateProperty, cellIndex, rowIndex, rowData, props)
  return [
    {
      component: 'LABEL',
      props: {
        label: 'id',
        className: 'wanted-lead-link'
      },
      tdClassName: 'pl-3',
    },
    {
      component: 'LABEL',
      props: {
        label: constructProp,
      },
    },
    {
      component: 'LABEL',
      props: {
        label: constructProp,
        className: 'elipsis wlRequirementLabelWidth',
      },
    },
    {
      component: 'CHIP',
      props: {
        text: constructProp,
        chipColor: constructProp,
        textColor: constructProp,
      },
      notFoundTemplate: { text: '' },
    },
    {
      component: 'LABEL',
      props: {
        label: 'armsLead.id',
      },
      notFoundTemplate: { label: '' },
    },
    {
      component: 'LABEL',
      props: {
        label: ['assignedToUser.firstName', 'assignedToUser.lastName'],
        seperator: ' ',
      },
      notFoundTemplate: { label: '' },
    },
    {
      component: 'LABEL',
      props: {
        label: 'assignedToUser.phoneNumber',
      },
      notFoundTemplate: { label: '' },
    },
    {
      component: 'LABEL',
      props: {
        label: 'reason',
      },
      notFoundTemplate: { label: '' },
    },
    {
      component: 'LABEL',
      props: {
        label: 'created_at',
        type: 'date',
        format: 'DD-MM-YYYY (hh:mm a)',
      },
      ignorePropConstruct: ['type', 'format'],
    },
    {
      component: 'BUTTON',
      props: {
        display: constructProp,
        text: 'Re-assign',
        // type: 'dropdown',
        buttonColor: '#026ff2',
        textColor: 'white',
        orientation: 'left',
        autoAdjustOrientation: true,
        width: '70px',
        //   display: constructProp,
        actionItems: constructProp,
        onClick: handleAction,
        emptyMenuItem: 'No Action Available',
      },
      ignorePropConstruct: [
        'onClick',
        'autoAdjustOrientation',
        'type',
        'width',
        'orientation',
        'textColor',
        'buttonColor',
        'text',
        'emptyMenuItem',
        ,
      ],
      showOnHover: true,
      showOnRowSelect: false,
      //   tdClassName: 'borderTop borderBottom',
    },
  ]
}
