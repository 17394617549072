/** @format */

import React, { useEffect, useState, useRef } from 'react'
import AgentStatsComponent from './AgentStatsComponent'
import axios from 'axios'
import config from '../../config'
import Load from '../../components/Loader'
import SupervisorCallsGrap from './SupervisorCallsGrap'
import AgentDropDown from '../../components/CallCenter/AgentDropDown'
import { setTitle } from '../../actions/generalSetting'
import { connect } from 'react-redux'
import Select from 'react-select'
import { CSVLink } from 'react-csv'
import moment from 'moment'

const AgentPerformanceReport = (props) => {
  const { setTitle } = props
  const [agentNames, setAgentNames] = useState([])
  const [armUserId, setArmUserId] = useState('')
  const [armUserName, setArmUserName] = useState('')
  const [loader, setLoader] = useState(true)
  const [selectedArmUserId, setSelectedArmUserId] = useState({})
  const [project, setProject] = useState([])
  const [selectedProject, setSelectedProject] = useState(null)
  const [selectedProjectId, setSelectedProjectId] = useState(null)
  const [csvData, setCsvData] = useState([])
  const csvLink = useRef()
  const [date, setDate] = useState({
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
    queueId: '',
  })

  const getAgents = async () => {
    setLoader(true)
    try {
      const agentDataRes = await axios.get(`${config.apiPath}/api/cdrreport/getDistinctData`)
      const projectRes = await axios.get(`${config.apiPath}/api/cdrreport/getProjectNames`)
      if (agentDataRes.data && agentDataRes.data.agentData) {
        setAgentNames(agentDataRes.data.agentData)
      }
      if (projectRes.data && projectRes.data.projectNames) {
        setProject(
          projectRes.data.projectNames.map((proj) => ({
            value: proj.id,
            label: proj.name,
          }))
        )
      }
      setLoader(false)
    } catch (error) {
      console.error('Error fetching data:', error)
      setLoader(false)
    }
  }
  useEffect(() => {
    setTitle({ name: 'Agent Performances' })
    getAgents().then(() => {})
  }, [])

  const getCsvData = async () => {
    let data = [
      { id: 1, name: 'Mike', city: 'philps', state: 'New York' },
      { id: 2, name: 'Steve', city: 'Square', state: 'Chicago' },
      { id: 3, name: 'Jhon', city: 'market', state: 'New York' },
      { id: 4, name: 'philps', city: 'booket', state: 'Texas' },
      { id: 5, name: 'smith', city: 'brookfield', state: 'Florida' },
      { id: 6, name: 'Broom', city: 'old street', state: 'Florida' },
    ]

    data = data.filter(function (item) {
      return item.state == 'New York'
    })

    let setName = ''

    const setNameHandler = (data) => {
      agentNames.filter((option) => {
        if (option.value === data.arm_user_id) {
          setName = option.label
        }
      })
    }

    const param = {
      isSupervisor: true,
      startDate: date.startDate,
      endDate: date.endDate,
    }
    await axios
      .post(`${config.apiPath}/api/cdrreport/getAgentCallStats`, param)
      .then((data) => {
        const allRebates =
          data.data.length > 0
            ? data.data.map(
                (data) => (
                  setNameHandler(data),
                  {
                    'Campaign Name': setName,
                    'NEW CUSTOMERS': data.total_new_customers,
                    'TOTAL INBOUND TALK TIME': secondsToHms(data.total_inbound_talktime),
                    'TOTAL OUTBOUND TALK TIME': secondsToHms(data.total_outbound_talktime),

                    'Inbound Total Clients': data.inbound.total_inbound_unique,
                    'Inbound Total Calls': data.inbound.total_inbound_calls,
                    'Inbound Total Answered Calls': data.inbound.total_inbound_answered,
                    'Inbound Total Unanswered Calls': data.inbound.total_inbound_unanswered,

                    'Inbound AVG Talk Time': secondsToHms(data.inbound.average_talktime_inbound),
                    'Inbound AVG Wait Time': secondsToHms(data.inbound.average_waittime_inbound),
                    'Inbound Answered %': data.inbound.inbound_answered_call_percent,

                    'Outbound Total Clients': data.outbound.total_outbound_unique,
                    'Outbound Total Calls': data.outbound.total_outbound_calls,
                    'Outbound Total Answered Calls': data.outbound.total_outbound_answered,
                    'Outbound Total Unanswered Calls': data.outbound.total_outbound_unanswered,
                    'Outbound AVG Talk Time': secondsToHms(data.outbound.average_talktime_outbound),
                    'Outbound AVG Wait Time': secondsToHms(data.outbound.average_waittime_outbound),
                    'Outbound Answered %': data.outbound.outbound_answered_call_percent,
                  }
                )
              )
            : []
        setCsvData(allRebates)
      })
      .catch((e) => console.log(e))
    csvLink.current.link.click()
  }
  return (
    <>
      <div style={{ height: '85vh' }} className="card mt-2">
        <div className="ccmc-filters row">
          <div className="col-4 row"></div>
          <div className="col-5 card-header">
            <h2 className={'text-center'}>Agent Performances</h2>
          </div>
          <div className="col-md-3 row justify-content-end ">
            <div className="col-md-6">
              <div className="ccmc-clearFilter">
                {agentNames.length > 0 && (
                  <div>
                    <button onClick={getCsvData} className="newFormSearch ml-1">
                      <span className={`fa fa-download`}></span>
                    </button>
                    <CSVLink
                      data={csvData}
                      filename="agent performances_inbound_and_outbound_Call_logs.csv"
                      className="hidden"
                      ref={csvLink}
                      target="_blank"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="card-body" style={{ overflowY: 'scroll' }}>
          <div className="ccmc-filters row">
            <div className="col-md-10 col">
              <div className="row">
                <div className="col-md-3">
                  <AgentDropDown
                    allAgents={(allagents) => {
                      setAgentNames(allagents)
                    }}
                    propSelectedArmUserId={selectedArmUserId}
                    armUserId={(armUserId, armUserName) => {
                      setArmUserId(armUserId)
                      setArmUserName(armUserName)
                    }}
                  />
                </div>
                <div className="col-md-3">
                  <Select
                    name="campaignName"
                    placeholder="Select Project"
                    value={selectedProject}
                    options={project}
                    onChange={(selectedOption) => {
                      setSelectedProject(selectedOption)
                      setSelectedProjectId(selectedOption.value)
                    }}
                    noOptionsMessage={() => 'No Options'}
                  />
                </div>
              </div>
            </div>
          </div>
          {loader && (
            <div className="dash-container br-lead-page-wrap">
              <div className="text-center" style={{ marginTop: 60, marginBottom: 50 }}>
                <Load title="Agent Performances"></Load>
              </div>
            </div>
          )}
          {agentNames && agentNames.length > 0 && (
            <>
              <AgentStatsComponent
                isSupervisor={true}
                id={armUserId === 'team' ? '' : armUserId}
                project_id={selectedProjectId}
                setStartDateAndEndDataHAndler={(fromDate, toDate) => {
                  setDate({
                    startDate: moment(fromDate).format('YYYY-MM-DD'),
                    endDate: moment(toDate).format('YYYY-MM-DD'),
                  })
                }}
                currentArmUser={(armUserId) => {
                  setArmUserId((armUserId) => armUserId)
                  setSelectedArmUserId(() =>
                    agentNames.filter(function (option) {
                      return option.value === armUserId
                    })
                  )
                }}
              />
            </>
          )}
          <SupervisorCallsGrap />
        </div>
      </div>
    </>
  )
}
const secondsToHms = (d) => {
  d = Number(d)
  var h = Math.floor(d / 3600)
  var m = Math.floor((d % 3600) / 60)
  var s = Math.floor((d % 3600) % 60)
  var hDisplay = h > 0 ? h + (h == 1 ? '' : '') : ''
  var mDisplay = m > 0 ? m + (m == 1 ? '' : '') : ''
  var sDisplay = s > 0 ? s + (s == 1 ? '' : '') : ''
  if (hDisplay.length === 1) {
    hDisplay = '0' + hDisplay
  } else if (hDisplay.length === 0) {
    hDisplay = '00'
  }
  if (mDisplay.length === 1) {
    mDisplay = '0' + mDisplay
  } else if (mDisplay.length === 0) {
    mDisplay = '00'
  }
  if (sDisplay.length === 1) {
    sDisplay = '0' + sDisplay
  } else if (sDisplay.length === 0) {
    sDisplay = '00'
  }
  return `${hDisplay}:${mDisplay}:${sDisplay}`
}
const mapDispatchToProps = (dispatch) => ({
  setTitle: (title) => dispatch(setTitle(title)),
})
export default connect(null, mapDispatchToProps)(AgentPerformanceReport)
