/** @format */

import React from 'react'
import { Card } from 'reactstrap'
// import Bath from '../../images/bath_light.svg'
// import Bed from '../../images/bed_light.svg'
import Bath from '../../images/bathtub.svg'
import Bed from '../../images/bed.svg'
import NotFound from '../../images/not-found-img.png'
import { formatPrice } from '../../utils/formatPrice'
import { capitalize_Words, capitalizeWordsWithoutUnderscore } from '../../utils/commonFormat'

export const PropertyWorkFlowTile = (props) => {
  const { property } = props
  let imagesList =
    property && property.armsuser && property.armsPropertyImages.length !== 0
      ? property.armsPropertyImages
      : property.property_images

  const styles = {
    bedBathIcon: {
      width: 18,
    },
  }

  return (
    <Card>
      <div className={'row align-items-center'} style={{ lineHeight: '38px' }}>
        <div className={'col-5 d-flex align-items-center'}>
          <span className={'img-wrap d-inline-block py-2 pl-2 mr-2'}>
            {imagesList && imagesList.length > 0 ? (
              <img
                style={{ height: 40, width: 45, marginTop: '-5px' }}
                src={imagesList && imagesList[0] && imagesList[0].url}
                alt="property image"
                className=""
              />
            ) : (
              <img
                style={{ height: 40, width: 45, marginTop: '-5px' }}
                src={NotFound}
                alt="no img"
                className=""
              />
            )}
            {/* {imagesList && imagesList.length > 0 ? (
              <img
                style={{ height: 40, width: 45 }}
                className={'mw-100 rounded'}
                src={imagesList[0].url}
              />
            ) : (
              <div>
                <img
                  style={{ height: 40, width: 45 }}
                  src={NotFound}
                  alt="no img"
                  className="not-found-img"
                />
              </div>
            )} */}
          </span>
          <strong className={'name-wrap'}>{`${property.size} ${capitalize_Words(property.size_unit)}
          ${capitalizeWordsWithoutUnderscore(property.subtype)}`}</strong>
        </div>
        <div className={'loc-wrap col-3 px-0 text-secondary'}>
          <small>{property.area.name || ''}, {property.city.name}</small>
        </div>
        <div
          className={
            'price-wrap col-2 pr-0 text-primary font-weight-bold d-flex align-items-center'
          }
        >
          <small className={' font-weight-bold'}>PKR</small> &nbsp;{formatPrice(property.price)}
        </div>
        <div className="bed-bath-wrap col-2 d-flex justify-content-end text-secondary">
          <div className={'d-flex'}>
            <span className="bath-img d-flex">
              <img src={Bath} style={styles.bedBathIcon} />
            </span>
            <span className="number">&nbsp;{property.bath || 0}</span>
          </div>
          <div className="ml-3 mr-4 d-flex">
            <span className="bath-img d-flex">
              <img src={Bed} style={styles.bedBathIcon} />
            </span>
            <span className="number">&nbsp;{property.bed || 0}</span>
          </div>
        </div>
      </div>
    </Card>
  )
}
