/** @format */

import React from 'react'
import NotFound from '../../images/not-found-img.png'
import { newFormatPrice } from '../../utils/formatPrice'
import './buy-rent-tile-card.scss'
import _ from 'lodash'
import { OrderBy, propsurePendingStatuses } from '../../utils/general'

const ShortListedPropertiesTileCard = ({ item, selectedVal, setSelectedVal }) => {
  const {
    id,
    armsPropertyImages,
    price,
    size,
    size_unit,
    subtype,
    purpose,
    area,
    city,
    bed,
    bath,
    property_images,
    leadOffers,
    diaries,
    agreedOffer,
    propsures,
    cmInstallments,
    armsuser,
  } = item

  let imagesList =
    armsuser && armsPropertyImages && armsPropertyImages.length !== 0
      ? armsPropertyImages
      : property_images

  const LeadCheckListStatus = { leadOffers, diaries, agreedOffer, propsures, cmInstallments }

  let checkPropsurePendingDocument =
    LeadCheckListStatus &&
    LeadCheckListStatus['propsures'] &&
    LeadCheckListStatus['propsures'].length !== 0 &&
    _.every(LeadCheckListStatus && LeadCheckListStatus['propsures'], { status: 'pending' })

  let checkPropsureVerificationDocument =
    LeadCheckListStatus &&
    LeadCheckListStatus['propsures'] &&
    LeadCheckListStatus['propsures'].length !== 0 &&
    _.every(LeadCheckListStatus && LeadCheckListStatus['propsures'], { status: 'verified' })

  let checkPropsurePaymentCompleted =
    LeadCheckListStatus &&
    LeadCheckListStatus['cmInstallments'] &&
    LeadCheckListStatus['cmInstallments'].length !== 0 &&
    _.every(LeadCheckListStatus && LeadCheckListStatus['cmInstallments'], { status: 'cleared' })

  let Offer = OrderBy(LeadCheckListStatus && LeadCheckListStatus['leadOffers'], ['id'], 'asc')

  let user_id = parseInt(localStorage.getItem('user_id'))
  let Diaries = _.filter(LeadCheckListStatus['diaries'], function (o) {
    return o.userId === parseInt(user_id)
  })
  Diaries = OrderBy(Diaries, ['id'], 'asc')

  return (
    <div>
      <div className="row col-md-12" style={{ marginBottom: '15px' }}>
        <div className="col-md-1 checkbox-center">
          <input
            type="radio"
            className="checkbox-container checkbox"
            name="property_checkbox"
            id="property_checkbox"
            value={id}
            checked={id === selectedVal}
            onClick={() => setSelectedVal(id)}
          />
        </div>
        <div className="col-md-11">
          <div className="buy-rent-tile-card one-edge-shadow">
            <div className="row">
              <div className="col-md-2">
                <div className="slider-card-img">
                  {imagesList && imagesList.length > 0 ? (
                    <img
                      style={{ height: 120, width: '100%', marginTop: '-5px' }}
                      src={imagesList && imagesList[0] && imagesList[0].url}
                      alt="property image"
                      className=""
                    />
                  ) : (
                    <img
                      style={{ height: 120, width: 120, marginTop: '-5px' }}
                      src={NotFound}
                      alt="no img"
                      className=""
                    />
                  )}
                </div>
              </div>
              <div className="col-md-7">
                <div className="card-price-title">
                  <span className="card-price-currency">pkr</span>
                  <b>{item && item.price ? newFormatPrice(price) : 0}</b>{' '}
                </div>
                <div className="property-info capitilized-text">{`${item && item.size ? size : '---'
                  } ${item && item.size_unit ? size_unit : '---'} ${item && item.subtype ? subtype : '---'
                  } for ${item && item.purpose ? purpose : '---'}`}</div>
                <div className="property-location capitilized-text">
                  <i className="fal fa-map-marker-alt location-icon"></i>
                  {` ${item && item.area && item.area.name ? area.name : '---'}, ${item && item.city && item.city.name ? city.name : '---'
                    }`}
                </div>
                <div className="property-bath-bed-info capitilized-text">
                  <i className="fal fa-bed-alt bed-icon"></i>
                  {` ${item && item.bed ? bed : '---'} `}
                  <i className="fal fa-bath bath-icon"></i>
                  {` ${item && item.bath ? bath : '---'}`}
                </div>
              </div>
              <div className="col-md-3 text-right capitilized-text">
                <div className="right-options-text">
                  {LeadCheckListStatus['diaries'] && LeadCheckListStatus['diaries'].length > 0 ? (
                    <div className="text-right">
                      {(Diaries[Diaries && Diaries.length - 1] &&
                        Diaries[Diaries && Diaries.length - 1].status === 'cancelled' &&
                        Diaries[Diaries && Diaries.length - 2] &&
                        Diaries[Diaries && Diaries.length - 2].status === 'completed') ||
                        (Diaries[Diaries && Diaries.length - 1] &&
                          Diaries[Diaries && Diaries.length - 1].status === 'completed') ? (
                        <i className="fa fa-check-circle check-circle-icon" aria-hidden="true"></i>
                      ) : null}
                      Viewing{' '}
                      {Diaries[Diaries && Diaries.length - 1] &&
                        Diaries[Diaries && Diaries.length - 1].status === 'cancelled' &&
                        Diaries[Diaries && Diaries.length - 2] &&
                        Diaries[Diaries && Diaries.length - 2].status === 'completed'
                        ? 'Done'
                        : Diaries[Diaries && Diaries.length - 1] &&
                          Diaries[Diaries && Diaries.length - 1].status === 'cancelled' &&
                          Diaries[Diaries && Diaries.length - 2] &&
                          Diaries[Diaries && Diaries.length - 2].status === 'pending'
                          ? 'Pending'
                          : Diaries[Diaries && Diaries.length - 1] &&
                            Diaries[Diaries && Diaries.length - 1].status === 'completed'
                            ? 'Done'
                            : 'Pending'}
                    </div>
                  ) : null}
                </div>
                <div className="right-options-text">
                  {LeadCheckListStatus['agreedOffer'] &&
                    LeadCheckListStatus['agreedOffer'].length > 0 ? (
                    <div className="text-right">
                      <i className="fa fa-check-circle check-circle-icon" aria-hidden="true"></i>
                      Agreed Amount
                    </div>
                  ) : null}

                  {Offer &&
                    Offer.length === 1 &&
                    Offer[Offer && Offer.length - 1].from === 'seller' ? null : Offer &&
                      Offer.length > 0 &&
                      Offer[Offer && Offer.length - 1].from === 'seller' &&
                      LeadCheckListStatus['agreedOffer'] &&
                      LeadCheckListStatus['agreedOffer'].length === 0 ? (
                    <div className="text-right">Demand Revised</div>
                  ) : Offer &&
                    Offer.length > 0 &&
                    Offer[Offer && Offer.length - 1].from === 'customer' &&
                    LeadCheckListStatus['agreedOffer'] &&
                    LeadCheckListStatus['agreedOffer'].length === 0 ? (
                    <div className="text-right">Offer Review</div>
                  ) : null}
                </div>
                <div className="right-options-text">
                  {propsurePendingStatuses(item) !== false ? (
                    <div className="text-right">
                      {propsurePendingStatuses(item) === 'Propsure Completed' && (
                        <i className="fa fa-check-circle check-circle-icon" aria-hidden="true"></i>
                      )}
                      {propsurePendingStatuses(item)}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ShortListedPropertiesTileCard
