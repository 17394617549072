/** @format */

import React, { useState } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import Select from 'react-select'
import Load from '../../../components/Loader'
import { normalizeCnicAndNTN } from '../../../utils/formatCnic'
const ClientCreationModal = ({
  shareToggleState,
  shareToggle,
  loader,
  clients,
  agents,
  fetchAgents,
  onchangeClient,
  shareLeadType,
  assignLeadFun,
  clientModalToggle,
  name,
  callFrom
}) => {
  let [searchString, setSearchString] = useState('')

  searchString = searchString.trim().toLowerCase()
  if (searchString.length > 0) {
    clients =
      clients &&
      clients.filter(function (i) {
        let fullName = i.firstName + ' ' + i.lastName

        return fullName.toLowerCase().match(searchString)
      })
  }

  return (
    <Modal
      isOpen={shareToggleState}
      toggle={shareToggle}
      size="lg"
      style={{ width: '806px', width: '100%' }}
      contentClassName="client-creation-modal-width "
    >
      <ModalHeader toggle={shareToggle}>Select {name}</ModalHeader>
      <ModalBody>
        <>
          <div className="share-lead-container">
            <div className="row">
              <div className="col-md-4">
                <div className="search-box">
                  <input
                    type="text"
                    className="search-box-style"
                    placeholder={'Search by ' + name + ' Name'}
                    onChange={(e) => setSearchString(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-md-8 text-right">
              { callFrom !== "addDetail" && <button
                  className="newFormButton"
                  onClick={() => {
                    clientModalToggle()
                  }}
                >
                  + Register New {name}
                </button> }
              </div>
            </div>
          </div>
          <div className="row ">
            <div className="user-form">
              <table className="share-modal-table" id="table" style={{ width: '100%' }}>
                <thead>
                  <tr>
                    <th style={{ minWidth: 90, maxWidth: 90 }}>ID</th>
                    <th style={{ minWidth: 200, maxWidth: 200 }}>Name</th>
                    <th style={{ minWidth: 165, maxWidth: 165 }}>CNIC/NTN</th>
                    <th style={{ minWidth: 165, maxWidth: 165 }}>Phone</th>
                    <th style={{ minWidth: 120, maxWidth: 120 }}>Action</th>
                  </tr>
                </thead>
                {loader ? (
                  <Load title="Users" />
                ) : agents && agents && agents.length === 0 ? (
                  <div style={{ marginTop: '60px', marginBottom: 50 }} className="text-center">
                    <h3 className="dimgray">No Users Found</h3>
                  </div>
                ) : (
                  <tbody>
                    {clients &&
                      clients.map((item) => (
                        <tr>
                          <td style={{ minWidth: 90, maxWidth: 90 }}>{item.id}</td>
                          <td style={{ minWidth: 200, maxWidth: 200 }}>
                            {item.firstName + ' ' + item.lastName}
                          </td>
                          <td style={{ minWidth: 165, maxWidth: 165 }}>
                            {normalizeCnicAndNTN(item.cnic)}
                          </td>
                          <td style={{ minWidth: 165, maxWidth: 165 }}>{item.contact1}</td>

                          <td style={{ minWidth: 120, maxWidth: 120 }}>
                            <button
                              className=" link-style mr-2 ml-2 btn-hollow-activate"
                              onClick={() => {
                                onchangeClient(item)
                              }}
                            >
                              Select
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                )}
              </table>
                <div className="mt-2 row">
                  <div className="col-4 pt-2">
                    <span className="totalCountStyle">
                      Total Clients:
                      <span className="totalCountSubStyle">
                        {clients && clients.length ?clients.length :0}
                      </span>{' '}
                    </span>
                  </div>
                </div>
            </div>
          </div>
        </>
      </ModalBody>
    </Modal>
  )
}

export default ClientCreationModal
