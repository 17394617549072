import React, { useState } from 'react'
import { Input, Modal, ModalBody } from 'reactstrap'
import { PropertyWorkFlowTile } from '../../AddPropertyWorkflow/PropertyWorkFlowTile'
import moment from 'moment'
import { makeStyles } from '@material-ui/styles'
import { connect } from 'react-redux'

const useStyles = makeStyles({
  tileWrap: {
    display: 'grid',
    gridTemplateColumns: props => props.showCheckbox ? '3% 75% 22%' : '78% 22%',
    alignItems: 'center',
    marginBottom: 15,
  },
  modalContent: {
    minHeight: 300,
    maxHeight: 'calc(100vh - 160px)',
    overflowY: 'auto'
  },
  scheduleTime: {
    fontSize: 13,
    fontWeight: 'bold'
  }
})
const UpdatePropertyTitle = (props) => {
  const classes = useStyles(props)
  const { property, propertyId, user } = props
  const diaries = property.diaries && property.diaries.filter(d =>
    ['completed','cancelled'].indexOf(d.status) === -1 && d.userId === user.id
  )

  const [comment, setComment] = useState(diaries && diaries.length && diaries[0].response)
  return diaries && diaries.length ? <>
            <div className={`${props.action !== 'Cancel' ? 'col-12' : ''} ${classes.tileWrap}`}>
              {props.showCheckbox &&
                <div className={"d-flex align-items-center justify-content-center"}>
                  <Input checked={props.selectedProperty.indexOf(property.id) > -1} onChange={e => {
                    if(e.target.checked) {
                      props.setSelectedProperty(p => [...p, property.id])
                      props.onComment({
                        comment: comment,
                        id: diaries[0].id,
                        propertyId: property.id
                      })
                    } else {
                      props.setSelectedProperty(p => p.filter(pp => pp !== property.id || property.id === propertyId))
                    }
                    }
                  }
                     type={"checkbox"} />
                </div>}
                <PropertyWorkFlowTile property={property} />
                <div className={`d-flex justify-content-between mx-3 align-items-center ${classes.scheduleTime}`}>
                  <div>
                    <span className={"text-primary"}> Viewing scheduled at </span><br/>
                    <span className={"date"}>{moment(diaries[0].start).format('ddd MMM D, LT')}</span>
                  </div>
                  {props.showChange &&
                  <div style={{cursor: 'pointer'}} onClick={() => props.onChange(diaries[0])} className={"text-decoration-underline text-primary"}>Change</div>}
                </div>
            </div>
            {props.showComment && props.selectedProperty.indexOf(property.id) > -1 && <div className={'col-12'}>
              <Input rows={4} type={"textarea"} defaultValue={diaries[0].response}
                   onChange={e => {
                     props.onComment({
                       comment: e.target.value,
                       id: diaries[0].id,
                       propertyId: property.id
                     })
                   }} name={"response"} placeholder={"Comment"} /><br/></div>}
    </>: <></>
}

export const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export const mapStateToProps = (state) => {
  return {
    user: state.user.userInfo,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePropertyTitle)
