/** @format */

import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import Tooltip from './Tooltip/Tooltip'

// const useStyles = makeStyles({
//   root: (props) => (),
// })

const Chip = (props) => {
  // const classes = useStyles(props)
  const chipRef = useRef(null)
  return props.text ? (
    <Tooltip content={props.text} direction="top" contentRef={chipRef}>
      <div
        style={{
          width: props.width,
          minWidth: props.minWidth,
          height: props.height,
          color: props.textColor,
          border: props.border,
          borderRadius: props.borderRadius,
          background: props.chipColor,
          padding: props.padding,
          display: props.hide == true || props.hide == 'true' ? 'none' : 'block',
          fontSize: props.fontSize,
          cursor: props.cursor,
          verticalAlign: 'middle',
          textAlign: 'center',
          fontWeight: props.fontWeight
        }}
        onClick={(e) => props.onClick(e)}
        className={props.className}
        ref={chipRef}
      >
        {props.text}
      </div>
    </Tooltip>
  ) : (
    <></>
  )
}

export default Chip

Chip.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  text: PropTypes.string,
  textColor: PropTypes.string,
  chipColor: PropTypes.string,
  borderRadius: PropTypes.any,
  border: PropTypes.string,
  onClick: PropTypes.func,
  cursor: PropTypes.string,
  className: PropTypes.string,
}

Chip.defaultProps = {
  width: 'fit-content',
  minWidth: 10,
  height: 'auto',
  text: '',
  textColor: 'black',
  chipColor: 'white',
  borderRadius: '5px',
  border: 'none',
  fontSize: '10px',
  padding: '5px',
  cursor: 'normal',
  fontWeight: 'normal',
  className: '',
  onClick: () => {},
}
