/** @format */

import axios from 'axios'
import config from '../config'
import Swal from 'sweetalert2'
import history from '../utils/routingHistory'
import { showToastMsg } from '../utils/helper'
import { delete_cookie } from '../utils/helper'
const CryptoJS = require('crypto-js')
axios.defaults.withCredentials = true

export const USER_LOGIN = 'USER_LOGIN'
export const USER_LOGIN_FAILURE = 'USER_LOGIN_FAILURE'
export const USER_LOGOUT = 'USER_LOGOUT'
export const ADD_USER = 'ADD_USER'
export const USER_LOADING = 'USER_LOADING'
export const SIGN_UP_FAIL = 'SIGN_UP_FAIL'
export const FETCH_ZONES = 'FETCH_ZONES'
export const FETCH_CITIES = 'FETCH_CITITES'
export const FETCH_COUNTRIES = 'FETCH_COUNTRIES'
export const FETCH_AIRA_CITIES = 'FETCH_AIRA_CITIES'
export const DEACTIVATED_USERS_FETCH = 'DEACTIVATED_USERS_FETCH'
export const FETCH_MANAGER_LIST = 'FETCH_MANAGER_LIST'
export const FETCH_EMPLOYEE_ID = 'FETCH_EMPLOYEE_ID'
export const FETCH_AGENCIES = 'FETCH_AGENCIES'
export const ADD_ORGANIZATION = 'ADD_ORGANIZATION'
export const FAIL_ORGANIZATION = 'FAIL_ORGANIZATION'
export const CLEAR_BUTTON_LOADER = 'CLEAR_BUTTON_LOADER'
export const CLEAR_BUTTON_SUCCESS = 'CLEAR_BUTTON_SUCCESS'
export const FETCH_DIARY = 'FETCH_DIARY'
export const FETCH_DIARY_SUCCESS = 'FETCH_DIARY_SUCCESS'
export const FETCH_DIARY_FAILURE = 'FETCH_DIARY_FAILURE'
export const ADD_USER_LOADER = 'ADD_USER_LOADER'
export const ADD_USER_LOADER_SUCCESS = 'ADD_USER_LOADER_SUCCESS'
export const FETCH_DELETION_USERS = 'FETCH_DELETION_USERS'
export const FETCH_DELETION_USERS_LOADER = 'FETCH_DELETION_USERS_LOADER'
export const FETCH_DELETION_USERS_SUCCESS = 'FETCH_DELETION_USERS_SUCCESS'

export const SHOW_USER_UPDATE_SUCCESS = 'SHOW_USER_UPDATE_SUCCESS'
export const SHOW_USER_UPDATE_FAILURE = 'SHOW_USER_UPDATE_FAILURE'

export const SHOW_USER = 'SHOW_USER'
export const SHOW_USER_SUCCESS = 'SHOW_USER_SUCCESS'
export const SHOW_USER_FAILURE = 'SHOW_USER_FAILURE'

export const FETCH_USERS = 'FETCH_USERS'
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS'
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE'

export const FETCH_ALL_USERS = 'FETCH_ALL_USERS'
export const FETCH_ALL_USERS_SUCCESS = 'FETCH_ALL_USERS_SUCCESS'
export const FETCH_ALL_USERS_FAILURE = 'FETCH_ALL_USERS_FAILURE'

export const DELETE_USERS = 'DELETE_USERS'
export const DELETE_USERS_SUCCESS = 'DELETE_USERS_SUCCESS'
export const DELETE_USERS_FAILURE = 'DELETE_USERS_FAILURE'
export const ACTIVE_USERS_SUCCESS = 'ACTIVE_USERS_SUCCESS'
export const DELETE_USER_FAILED = 'DELETE_USER_FAILED'

export const CHANGED_PASSWORD = 'CHANGED_PASSWORD'
export const CHANGED_PASSWORD_SUCCESS = 'CHANGED_PASSWORD_SUCCESS'
export const CHANGED_PASSWORD_FAILURE = 'CHANGED_PASSWORD_FAILURE'
export const RESET_USER_RESPONSE = 'RESET_USER_RESPONSE'

export const FETCH_UNASSIGNED_ALL_AREAS = 'FETCH_UNASSIGNED_ALL_AREAS'
export const FETCH_UNASSIGNED_ALL_AREAS_LAODER = 'FETCH_UNASSIGNED_ALL_AREAS_LAODER'
export const FETCH_UNASSIGNED_ALL_AREAS_SUCCESS = 'FETCH_UNASSIGNED_ALL_AREAS_SUCCESS'
export const FETCH_UNASSIGNED_ALL_AREAS_FAILURE = 'FETCH_UNASSIGNED_ALL_AREAS_FAILURE'
export const ALL_USERS = 'ALL_USERS'

export const USERS_INFO_BEGIN = 'USERS_INFO_BEGIN'
export const USERS_INFO_SUCCESS = 'USERS_INFO_SUCCESS'
export const USERS_INFO_FAILURE = 'USERS_INFO_FAILURE'
export const FETCH_USER_DEVICES = 'FETCH_USER_DEVICES'

export const FETCH_USER_SUBUSERS = 'FETCH_USER_SUBUSERS'
export const FETCH_USER_SUBUSERS_SUCCESS = 'FETCH_USER_SUBUSERS_SUCCESS'
export const FETCH_USER_SUBUSERS_FAILURE = 'FETCH_USER_SUBUSERS_FAILURE'
export const SET_IS_TERMINAL_USER = 'SET_IS_TERMINAL_USER'
export const FETCH_ALL_PRIMARY_JOURNEY = 'FETCH_ALL_PRIMARY_JOURNEY'
export const LEAD_TYPE = 'LEAD_TYPE'
export const LAST_ADDED_CLIENT = 'LAST_ADDED_CLIENT'
export const SET_CLIENT_LEAD_ERROR = 'SET_CLIENT_LEAD_ERROR'
export const FETCH_ALL_ORGANIZATIONS = 'FETCH_ALL_ORGANIZATIONS'
export const FETCH_NON_TERMINAL_AGENTS = 'FETCH_NON_TERMINAL_AGENTS'
export const FETCH_ALL_AGENTS = 'FETCH_ALL_AGENTS'

export const setLeadClientError = (isLeadClientError) => {
  return (dispatch) => {
    dispatch({ type: SET_CLIENT_LEAD_ERROR, data: isLeadClientError })
  }
}
export const setLeadType = (leadType) => {
  return (dispatch) => {
    dispatch({ type: LEAD_TYPE, data: leadType })
  }
}
export const setLastAddedClient = (client) => {
  return (dispatch) => {
    dispatch({ type: LAST_ADDED_CLIENT, data: client })
  }
}

export const ChangePasswordFunction = (model) => {
  return (dispatch) => {
    dispatch({ type: CHANGED_PASSWORD })
    axios
      .patch(`${config.apiPath}/api/user/resetpassword`, model)
      .then((response) => {
        return dispatch({
          type: CHANGED_PASSWORD_SUCCESS,
          data: response.data.msg,
        })
      })
      .catch((error) => {
        return dispatch({
          type: CHANGED_PASSWORD_FAILURE,
          data: error.response.data,
        })
      })
  }
}

export const fetchAllUnAssignedAreas = (url, arr, orgId) => {
  return (dispatch) => {
    dispatch({ type: FETCH_UNASSIGNED_ALL_AREAS_LAODER, data: true })
    axios
      .get(url, {
        params: {
          city_id: arr,
        },
      })
      .then((response) => {
        dispatch({ type: FETCH_UNASSIGNED_ALL_AREAS_SUCCESS, data: false })
        return dispatch({
          type: FETCH_UNASSIGNED_ALL_AREAS,
          data: response.data,
        })
      })
      .catch((error) => {
        return dispatch({
          type: FETCH_UNASSIGNED_ALL_AREAS_FAILURE,
          data: error && error.response && error.response.data,
        })
      })
  }
}

export const DeleteUser = (id) => {
  return (dispatch) => {
    dispatch({ type: DELETE_USERS, id: true })
    axios
      .delete(`${config.apiPath}/api/user/delete?id=${id}`)
      .then((response) => {
        setTimeout(() => {
          window.location = `/users?page=1&pageSize=${config.pageSize}`
        }, 2000)
        return dispatch({ type: DELETE_USERS_SUCCESS, data: id })
      })
      .catch(function (xhr, status, err) {
        // dispatch({ type: DELETE_USER_FAILED, data: 'false' });
        Swal.fire({
          type: 'error',
          icon: 'error',
          title: 'Error',
          text: xhr.response.data.msg,
        })
      })
  }
}

export const showEmployeeId = (id) => {
  return (dispatch) => {
    axios.get(`${config.apiPath}/api/user/all?id=${id.id}`).then((response) => {
      return dispatch({ type: SHOW_USER_SUCCESS, data: response })
    })
  }
}

export const fetchAllJourney = () => {
  return (dispatch) => {
    axios.get(`${config.apiPath}/api/user/primaryJourneys`).then((response) => {
      return dispatch({ type: FETCH_ALL_PRIMARY_JOURNEY, data: response.data })
    })
  }
}

export const activeUser = (id) => {
  return (dispatch) => {
    dispatch({ type: DELETE_USERS, id: true })
    axios
      .patch(`${config.apiPath}/api/user/activate?id=${id}`)
      .then((response) => {
        return dispatch({ type: ACTIVE_USERS_SUCCESS, data: id })
      })
      .catch(function (xhr, status, err) {
        return dispatch({ type: DELETE_USERS_FAILURE, data: xhr })
      })
  }
}

export const UserLogin = (data) => {
  return (dispatch) => {
    dispatch({ type: USER_LOADING, data: true })
    axios
      .post(`${config.apiPath}/api/user/login`, data, { withCredentials: true })
      .then(async function (response) {
        const { token } = response.data
        const Response = { data : response.data };
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
        localStorage.setItem('user_agency_mgmt', JSON.stringify(Response))
        localStorage.setItem('roleId', response.data.roleId)
        localStorage.setItem('organizationId', response.data.organizationId)
        localStorage.setItem('systemUser', response.data.isSystemRole)
        localStorage.setItem('isAdmin', response.data.isAdmin)
        localStorage.setItem('groupManager', response.data.groupManager)
        localStorage.setItem('userType', response.data.organization && response.data.organization.type)
        try {
          let permissions = await axios.get(
            `${config.apiPath}/api/rolepermission/fetch?roleId=${response.data.roleId}&all=true`
          )
          console.log(permissions)
          permissions = CryptoJS.AES.encrypt(
            JSON.stringify(permissions.data),
            'secret key 099'
          ).toString()
          localStorage.setItem('permissions', permissions)
        } catch (e) {
          console.log(e)
        }
        dispatch({ type: USER_LOADING, data: false })
        return dispatch({ type: USER_LOGIN, data: response })
      })
      .catch(function (xhr, status, err) {
        dispatch({ type: USER_LOADING, data: false })
        return dispatch({ type: USER_LOGIN_FAILURE, error: xhr })
      })
  }
}

export const CheckAndSaveLoggedInData = () => {
  return (dispatch) => {
    let userData = JSON.parse(localStorage.getItem('user_agency_mgmt'))
    if (userData) {
      return dispatch({ type: USER_LOGIN, data: userData })
    }
  }
}

export const LogoutUser = () => {
  return (dispatch) => {
    localStorage.clear()
    delete_cookie('authorization')
    delete_cookie('auth-expiry')
    return dispatch({ type: USER_LOGOUT })
  }
}

export const resetUserResponse = () => {
  return (dispatch) => {
    return dispatch({ type: RESET_USER_RESPONSE, data: {} })
  }
}

export const fetchAgencies = () => {
  return (dispatch) => {
    axios.get(`${config.apiPath}/api/agency`).then((res) => {
      return dispatch({ type: FETCH_AGENCIES, data: res.data })
    })
  }
}
export const SetUser = (data) => {
  let modified = {
    ...data,
    agencyName: data.agencyName === 'Select Agency' ? null : data.agencyName,
  }

  return (dispatch) => {
    dispatch({ type: ADD_USER_LOADER })
    axios
      .post(`${config.apiPath}/api/user/signup`, modified)
      .then(function (response) {
        dispatch({ type: ADD_USER_LOADER_SUCCESS })
        history.push(`/users?page=1&pageSize=${config.pageSize}`)
        return dispatch({ type: ADD_USER, data: response })
      })
      .catch(function (xhr, status, err) {
        Swal.fire({
          type: 'error',
          title: 'Error',
          icon: 'error',
          text: xhr.response.data,
        })

        return dispatch({ type: SIGN_UP_FAIL, data: xhr })
      })
  }
}

export const addOrganization = (data) => {
  return (dispatch) => {
    axios({
      method: 'post',
      url: `${config.apiPath}/api/user/organization`,
      data: {
        name: data,
      },
    })
      .then(function (response) {
        return dispatch({ type: ADD_ORGANIZATION, data: response })
      })
      .catch(function (xhr, status, err) {
        return dispatch({ type: FAIL_ORGANIZATION, data: xhr })
      })
  }
}

export const EditUser = (data, navigation) => {
  const modified = {
    ...data,
    agencyName: data.agencyName === 'Select Agency' ? null : data.agencyName,
  }
  return (dispatch) => {
    dispatch({ type: ADD_USER_LOADER })
    axios
      .patch(`${config.apiPath}/api/user/update?id=${data.id}`, modified)
      .then(function (response) {
        dispatch({ type: SHOW_USER_UPDATE_SUCCESS, data: response })
        showToastMsg('User has been successfully updated', 'success')
        dispatch({ type: ADD_USER_LOADER_SUCCESS })
        setTimeout(() => {
          history.push(`/users?page=1&pageSize=${config.pageSize}`)
        }, 2000)
      })
      .catch(function (xhr, status, err) {
        showToastMsg(xhr.response.data, 'error')
        return dispatch({ type: SHOW_USER_UPDATE_FAILURE, data: xhr })
      })
  }
}

export const fetchDiary = (id) => {
  return (dispatch) => {
    dispatch({ type: FETCH_DIARY })
    axios
      .post(`${config.apiPath}/api/user/fetchDiary`)
      .then(function (response) {
        return dispatch({ type: FETCH_DIARY_SUCCESS, data: response })
      })
      .catch(function (xhr, status, err) {
        return dispatch({ type: FETCH_DIARY_FAILURE, data: xhr })
      })
  }
}

export const fetchDiaryOfAgent = (id) => {
  return (dispatch) => {
    dispatch({ type: FETCH_DIARY })
    axios
      .post(`${config.apiPath}/api/user/fetchDiary`)
      .then(function (response) {
        return dispatch({ type: FETCH_DIARY_SUCCESS, data: response })
      })
      .catch(function (xhr, status, err) {
        return dispatch({ type: FETCH_DIARY_FAILURE, data: xhr })
      })
  }
}

export const ShowNameData = (item) => {
  return (dispatch) => {
    dispatch({ type: SHOW_USER })
    axios
      .get(`${config.apiPath}/api/user/all?name=${item.firstName}`)
      .then(function (response) {
        return dispatch({ type: SHOW_USER_SUCCESS, data: response })
      })
      .catch(function (xhr, status, err) {
        return dispatch({ type: SHOW_USER_FAILURE, data: xhr })
      })
  }
}

export const showDactivatedFiltersData = (url, offset) => {
  return (dispatch) => {
    dispatch({ type: CLEAR_BUTTON_LOADER })
    dispatch({ type: FETCH_USERS })
    axios
      .get(`${url}&offset=${offset === undefined ? 0 : offset}&limit=${config.pageSize}`)
      .then(function (response) {
        dispatch({ type: CLEAR_BUTTON_SUCCESS })
        return dispatch({ type: DEACTIVATED_USERS_FETCH, data: response.data })
      })
      .catch(function (xhr, status, err) {
        return dispatch({ type: SHOW_USER_FAILURE, data: xhr })
      })
  }
}

export const ShowDeactivatedByName = (item) => {
  return (dispatch) => {
    dispatch({ type: SHOW_USER })
    axios
      .get(`${config.apiPath}/api/user/all?deActivated=true&name=${item.firstName}`)
      .then(function (response) {
        return dispatch({ type: DEACTIVATED_USERS_FETCH, data: response.data })
      })
      .catch(function (xhr, status, err) {
        return dispatch({ type: SHOW_USER_FAILURE, data: xhr })
      })
  }
}

export const ShowEmailData = (item) => {
  return (dispatch) => {
    dispatch({ type: SHOW_USER })
    axios
      .get(`${config.apiPath}/api/user/all?email=${item.email}`)
      .then(function (response) {
        return dispatch({ type: SHOW_USER_SUCCESS, data: response })
      })
      .catch(function (xhr, status, err) {
        return dispatch({ type: SHOW_USER_FAILURE, data: xhr })
      })
  }
}

export const showDeactivatedEmailData = (item) => {
  return (dispatch) => {
    dispatch({ type: SHOW_USER })
    axios
      .get(`${config.apiPath}/api/user/all?deActivated=true&email=${item.email}`)
      .then(function (response) {
        return dispatch({ type: DEACTIVATED_USERS_FETCH, data: response.data })
      })
      .catch(function (xhr, status, err) {
        return dispatch({ type: SHOW_USER_FAILURE, data: xhr })
      })
  }
}

export const fetchFiltersData = (url, offset) => {
  return (dispatch) => {
    dispatch({ type: CLEAR_BUTTON_LOADER, data: true })
    dispatch({ type: FETCH_USERS })
    axios
      .get(`${url}&offset=${offset === undefined ? 0 : offset}&limit=${config.pageSize}`)
      .then(function (response) {
        dispatch({ type: CLEAR_BUTTON_SUCCESS, data: false })
        return dispatch({ type: SHOW_USER_SUCCESS, data: response })
      })
      .catch(function (xhr, status, err) {
        return dispatch({ type: SHOW_USER_FAILURE, data: xhr })
      })
  }
}

export const ShowPhoneNumberData = (item) => {
  return (dispatch) => {
    dispatch({ type: SHOW_USER })
    axios
      .get(`${config.apiPath}/api/user/all?phoneNumber=${item.phoneNumber}`)
      .then(function (response) {
        return dispatch({ type: SHOW_USER_SUCCESS, data: response })
      })
      .catch(function (xhr, status, err) {
        return dispatch({ type: SHOW_USER_FAILURE, data: xhr })
      })
  }
}

export const fetchAllUsers = () => {
  return (dispatch) => {
    axios.get(`${config.apiPath}/api/user/all/names?all=true`).then((res) => {
      return dispatch({ type: ALL_USERS, data: res.data })
    })
  }
}

export const showManagerData = (item) => {
  return (dispatch) => {
    dispatch({ type: SHOW_USER })
    axios
      .get(`${config.apiPath}/api/user/all?managerId=${item.id}`)
      .then(function (response) {
        return dispatch({ type: SHOW_USER_SUCCESS, data: response })
      })
      .catch(function (xhr, status, err) {
        return dispatch({ type: SHOW_USER_FAILURE, data: xhr })
      })
  }
}

export const ShowDeactivatedPhoneData = (item) => {
  return (dispatch) => {
    dispatch({ type: SHOW_USER })
    axios
      .get(`${config.apiPath}/api/user/all?deActivated=true&phoneNumber=${item.phoneNumber}`)
      .then(function (response) {
        return dispatch({
          type: DEACTIVATED_USERS_FETCH,
          data: response.data,
        })
      })
      .catch(function (xhr, status, err) {
        return dispatch({ type: SHOW_USER_FAILURE, data: xhr })
      })
  }
}

export const fetchZones = () => {
  return (dispatch) => {
    axios.get(`${config.apiPath}/api/areas/zones?status=active&all=true`).then((res) => {
      return dispatch({ type: FETCH_ZONES, data: res.data })
    })
  }
}

export const fetchManagers = () => {
  return (dispatch) => {
    axios.get(`${config.apiPath}/api/user/managers`).then((res) => {
      return dispatch({ type: FETCH_MANAGER_LIST, data: res.data })
    })
  }
}

export const fetchCities = (callback = null) => {
  return (dispatch) => {
    axios.get(`${config.apiPath}/api/cities`).then((res) => {
      return dispatch({ type: FETCH_CITIES, data: res.data })
    })
  }
}

export const fetchCountriesNameWithCountriesCode = () => {
  return (dispatch) => {
    axios.get(`${config.apiPath}/api/user/getCountryCodes`).then((res) => {
      let countriesListWihCode = [{ label: 'Abroad', value: '' }]
      res.data.map((item) => {
        countriesListWihCode.push({
          label: item.name,
          value: item.phone,
        })
      })
      return dispatch({ type: FETCH_COUNTRIES, data: countriesListWihCode })
    })
  }
}

export const fetchAiraCities = (callback = null) => {
  return (dispatch) => {
    axios.get(`${config.apiPath}/api/cities?wanted=true`).then((res) => {
      return dispatch({ type: FETCH_AIRA_CITIES, data: res.data })
    })
  }
}

export const fetchAllOrg = (params = {}) => {
  return (dispatch) => {
    axios
      .get(`${config.apiPath}/api/user/organizations`, {
        params,
      })
      .then((res) => {
        return dispatch({ type: FETCH_ALL_ORGANIZATIONS, data: res.data.rows })
      })
  }
}

export const fetchUsers = (req) => {
  let orgId = localStorage.getItem('organizationId')
  var endpoint = `${config.apiPath}/api/user/all?limit=${config.pageSize}${
    orgId !== 'null' ? `&organizationId=${orgId}` : ''
  }`
  if (req && req.offset && req.pageSize) {
    const { offset, pageSize } = req
    endpoint =
      `${config.apiPath}/api/user/all?${
        orgId !== 'null' ? `organizationId=${orgId}&` : ''
      }offset=` +
      offset +
      '&limit=' +
      pageSize
  } else if (req && req.all === true) {
    endpoint = `${config.apiPath}/api/user/all?${orgId !== 'null' ? `organizationId=${orgId}` : ''}`
  }
  return (dispatch) => {
    dispatch({ type: CLEAR_BUTTON_LOADER })
    dispatch({ type: FETCH_USERS })
    axios
      .get(endpoint)
      .then(function (response) {
        dispatch({ type: CLEAR_BUTTON_LOADER })
        return dispatch({ type: FETCH_USERS_SUCCESS, data: response.data })
      })
      .catch(function (xhr, status, err) {
        return dispatch({ type: FETCH_USERS_FAILURE, data: xhr })
      })
  }
}

export const fetchUsersForDeletion = (page, url) => {
  return (dispatch) => {
    dispatch({ type: FETCH_DELETION_USERS_LOADER, data: true })

    axios.get(`${config.apiPath}${url}&page=${page * 1}`).then((res) => {
      setTimeout(() => {
        return dispatch({ type: FETCH_DELETION_USERS_SUCCESS, data: false })
      }, 20)
      return dispatch({ type: FETCH_DELETION_USERS, data: res.data })
    })
  }
}

export const deactivatedUsers = (req) => {
  let filter = ''
  let organizationId = localStorage.getItem('organizationId')
  if (organizationId && organizationId !== 'null') {
    filter = `&organizationId=${organizationId}`
  }
  var endpoint = `${config.apiPath}/api/user/all?deActivated=true${filter}`
  if (req && req.offset && req.pageSize) {
    const { offset, pageSize } = req
    endpoint =
      `${config.apiPath}/api/user/all?deActivated=true${filter}&offset=` +
      offset +
      '&limit=' +
      pageSize
  } else if (req && req.all === true) {
    endpoint = `${config.apiPath}/api/user/all?deActivated=true${filter}`
  }
  return (dispatch) => {
    dispatch({ type: FETCH_USERS })
    axios
      .get(endpoint)
      .then(function (response) {
        dispatch({ type: CLEAR_BUTTON_LOADER })
        return dispatch({ type: DEACTIVATED_USERS_FETCH, data: response.data })
      })
      .catch(function (xhr, status, err) {
        return dispatch({ type: FETCH_USERS_FAILURE, data: xhr })
      })
  }
}

export const AllUsers = (params = '') => {
  return (dispatch) => {
    dispatch({ type: FETCH_ALL_USERS })
    axios
      .get(`${config.apiPath}/api/user/all/names?all=true${params}`)
      .then(function (response) {
        return dispatch({ type: FETCH_ALL_USERS_SUCCESS, data: response.data })
      })
      .catch(function (xhr, status, err) {
        return dispatch({ type: FETCH_ALL_USERS_FAILURE, data: xhr })
      })
  }
}

export const fetchAgents = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_ALL_USERS })
    axios
      .get(`${config.apiPath}/api/user/agents?allOrg=true`)
      .then(function (response) {
        return dispatch({ type: FETCH_ALL_USERS_SUCCESS, data: response.data })
      })
      .catch(function (xhr, status, err) {
        return dispatch({ type: FETCH_ALL_USERS_FAILURE, data: xhr })
      })
  }
}


// export const SetUser = (data) => {
//     return dispatch => {
//         axios.post('user/', data)
//             .then(function (response) {
//                 return dispatch({ type: S, data: response })
//             })
//             .catch(function (xhr, status, err) {
//                 console.error({ xhr, status: status, Message: "USER FETCH API ISSUE", Error: err });
//             });
//     }
// }
export const UserInfoFetch = () => {
  return (dispatch, getState) => {
    dispatch({ type: USERS_INFO_BEGIN })
    axios
      .get(`${config.apiPath}/api/user/me`)
      .then((response) => {
        if (!(getState().user && getState().user.isTerminalUser)) {
          dispatch(setTerminalUser(response.data))
        }
        return dispatch({ type: USERS_INFO_SUCCESS, data: response.data })
      })
      .catch(function (xhr) {
        return dispatch({ type: USERS_INFO_FAILURE, data: xhr })
      })
  }
}

export const fetchDevices = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_USER_DEVICES, deviceFlag: false })
    axios
      .get(`${config.apiPath}/api/user/devices`)
      .then((response) => {
        return dispatch({ type: FETCH_USER_DEVICES, data: response.data, deviceFlag: true })
      })
      .catch(function (xhr) {
        return dispatch({ type: FETCH_USER_DEVICES, data: xhr, deviceFlag: null })
      })
  }
}

export const GetSubUsers = (params = '') => {
  return (dispatch, getState) => {
    let userState = { ...getState().user }
    let roleId = null
    if (userState.userInfo) {
      roleId = userState.userInfo.armsUserRoleId
    }
    dispatch({ type: FETCH_USER_SUBUSERS })
    axios
      .get(`${config.apiPath}/api/role/sub-users?roleId=${roleId}&addSelf=true${params}`)
      .then((response) => {
        return dispatch({ type: FETCH_USER_SUBUSERS_SUCCESS, data: response.data })
      })
      .catch(function (xhr) {
        return dispatch({ type: FETCH_USER_SUBUSERS_FAILURE, data: xhr })
      })
  }
}

export const setTerminalUser = (thisUser = null) => {
  return (dispatch, getState) => {
    let user = thisUser ? thisUser : getState().user
    let currentUser = user && user.userInfo ? user.userInfo : user
    let userOrgId = currentUser && currentUser.organizationId
    let userRoleId = currentUser && currentUser.armsUserRoleId
    //
    let userRole = user && user.armsUserRole
    if (!userRole) {
      userRole = {}
      userRole.groupManger = localStorage.getItem('groupManager')
      userRole.roleName = localStorage.getItem('role')
    }
    if (!userRoleId) {
      userRoleId = localStorage.getItem('roleId')
    }
    let groupManagerParam = ''
    if (userRole && userRole.groupManger && userRole.groupManger.toString() == 'true') {
      groupManagerParam = `&groupManager=true`
    }
    axios
      .get(`${config.apiPath}/api/role/sub-users?roleId=${userRoleId}${groupManagerParam}`)
      .then((res) => {
        if (res && res.data && res.data.length) {
          localStorage.setItem('isTerminalUser', 'false')
          return dispatch({ type: SET_IS_TERMINAL_USER, data: false })
        } else {
          localStorage.setItem('isTerminalUser', 'true')
          return dispatch({ type: SET_IS_TERMINAL_USER, data: true })
        }
      })
    //
    /* if (
      userOrgId != null &&
      userOrgId != undefined &&
      userRoleId !== null &&
      userRoleId !== undefined
    ) {
      axios
        .get(`${config.apiPath}/api/role/parent-roles?orgId=${userOrgId}`)
        .then((res) => {
          if (res && res.data) {
            let index = res.data.findIndex((d) => d && d.id === userRoleId)
            if (index === -1) {
              return dispatch({ type: SET_IS_TERMINAL_USER, data: true })
            } else {
              return dispatch({ type: SET_IS_TERMINAL_USER, data: false })
            }
          }
        })
        .catch(() => {
          return dispatch({ type: SET_IS_TERMINAL_USER, data: false })
        })
    }*/
  }
}

export const fetchNonTerminalAgents = (page = null) => {
  return (dispatch, getState) => {
    const role_id = localStorage.getItem('roleId')
    const isGroupManager = localStorage.getItem('groupManager')

    let groupManagerParam = ''
    if (
      (isGroupManager && isGroupManager === true) ||
      isGroupManager.toLocaleLowerCase() === 'true'
    ) {
      groupManagerParam = `&groupManager=true`
    }
    let url = `${config.apiPath}/api/role/sub-users?roleId=${role_id}${groupManagerParam}`
    url += '&excludeTerminal=true'
    if(page) url +=`${page}`

    axios.get(url).then((res) => {
      let array = []
      if (res && res.data && res.data.length > 0) {
        res.data.map((item) => {
          array.push({
            label: item.teamName,
            value: item.id,
          })
        })
      }

      return dispatch({ type: FETCH_NON_TERMINAL_AGENTS, data: array })
    })
  }
}

export const fetchAllAgents = () => {
  return (dispatch) => {
    const role_id = localStorage.getItem('roleId')
    const isGroupManager = localStorage.getItem('groupManager')

    let groupManagerParam = ''
    if (
      (isGroupManager && isGroupManager === true) ||
      isGroupManager.toLocaleLowerCase() === 'true'
    ) {
      groupManagerParam = `&groupManager=true`
    }
    let url = `${config.apiPath}/api/role/sub-users?roleId=${role_id}${groupManagerParam}`

    axios.get(url).then((res) => {
      let array = []
      res &&
        res.data &&
        res.data.length > 0 &&
        res.data.filter((item) => {
          return array.push({
            label: item.firstName + ' ' + item.lastName,
            value: item.id,
          })
        })

      return dispatch({ type: FETCH_ALL_AGENTS, data: array })
    })
  }
}
