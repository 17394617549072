import axios from 'axios'
import config from '../config'

export const openLead = (lead, leadType, cb = null) => {
  let url = `${config.apiPath}/api/leads?id[]=${lead.id}`
  if(leadType === 'Project')
    url = `${config.apiPath}/api/leads/project?id[]=${lead.id}`
  axios
    .patch(url,{
      reOpenLead: true
    }, )
    .then((res) => cb && cb(res.data))
}
