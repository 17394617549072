/** @format */

import React, { useState, useEffect } from 'react'
import ShortListedPropertiesTileCard from '../BuyRentTileCard/ShortListedPropertiesTileCard'
import './buy-rent-tile.scss'

const ShortListedPropertiesTile = ({ rows, setSelectedPropertyID, setIsDisable, isDisbale }) => {
  const [selectedVal, setSelectedVal] = useState(null)

  useEffect(() => {
    if (selectedVal) {
      setSelectedPropertyID(selectedVal)
      setIsDisable(false)
    }
  }, [isDisbale, selectedVal])
  
  return (
    <div className="properties-modal-scroll">
      {rows &&
        rows.map((item, index) => (
          <ShortListedPropertiesTileCard
            selectedVal={selectedVal}
            setSelectedVal={(val) => setSelectedVal(val)}
            item={item}
            key={index}
          />
        ))}
    </div>
  )
}

export default ShortListedPropertiesTile
