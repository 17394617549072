/** @format */

import axios from 'axios'
import config from '../config'
import Swal from 'sweetalert2'
axios.defaults.withCredentials = true

export const FETCH_AREAS_BEGIN = 'FETCH_AREAS_BEGIN'
export const FETCH_AREAS_SUCCESS = 'FETCH_AREAS_SUCCESS'
export const FETCH_AREAS_FAILED = 'FETCH_AREAS_FAILED'
export const FETCH_AREAS_LOADER = 'FETCH_AREAS_LOADER'
export const FETCH_AREAS_LOADER_SUCCESS = 'FETCH_AREAS_LOADER_SUCCESS'

export const SEARCH_QUERY_BEGINS = 'SEARCH_QUERY_BEGINS'
export const SEARCH_QUERY_SUCCESS = 'SEARCH_QUERY_SUCCESS'
export const SEARCH_QUERY_FAILED = 'SEARCH_QUERY_FAILED'
export const FETCH_AIRA_AREAS_SUCCESS = 'FETCH_AIRA_AREAS_SUCCESS';
export const FETCH_AIRA_AREAS_FAILED = 'FETCH_AIRA_AREAS_FAILED';

export const DELETE_AREA_BEGIN = 'DELETE_AREA_BEGIN'
export const DELETE_AREA_SUCCESS = 'DELETE_AREA_SUCCESS'
export const DELETE_AREA_FAILED = 'DELETE_AREA_FAILED'

export const ASSIGN_AREA_BEGIN = 'ASSIGN_AREA_BEGIN'
export const ASSIGN_AREA_SUCCESS = 'ASSIGN_AREA_SUCCESS'
export const ASSIGN_AREA_FAILED = 'ASSIGN_AREA_FAILED'
export const FETCH_AREA_GROUPS = 'FETCH_AREA_GROUPS'

export const SELECT_AGENT_VALUE = 'SELECT_AGENT_VALUE'

export const selectAgentValue = (agent) => ({
  type: SELECT_AGENT_VALUE,
  payload: agent,
})

export const fetch_area_begin = () => ({
  type: FETCH_AREAS_BEGIN,
})
export const fetch_area_success = (areas) => ({
  type: FETCH_AREAS_SUCCESS,
  payload: areas,
})
export const fetch_area_failed = (error) => ({
  type: FETCH_AREAS_FAILED,
  payload: error,
})

export const search_query_begin = () => ({ type: SEARCH_QUERY_BEGINS })
export const search_query_success = (areas) => ({ type: SEARCH_QUERY_SUCCESS, payload: areas })
export const search_query_failed = (error) => ({ type: SEARCH_QUERY_FAILED, payload: error })
export const fetch_aira_area_success = (areas) => ({
  type: FETCH_AIRA_AREAS_SUCCESS,
  payload: areas
})
export const fetch_aira_area_failed = error => ({
  type: FETCH_AIRA_AREAS_FAILED,
  payload: error
})

export const delete_area_begin = () => ({ type: DELETE_AREA_BEGIN });
export const delete_area_success = (areaId) => ({ type: DELETE_AREA_SUCCESS, payload: areaId });
export const delete_area_failed = (error) => ({ type: DELETE_AREA_FAILED, payload: error });

export const fetchAllAreas = (req, org = null) => {
  const urlParams = new URLSearchParams(window.location.search)
  const page = urlParams.get('page')
  var endpoint = `${config.apiPath}/api/areas/assignedAreas?pageSize=` + config.pageSize + '&page=' + page
  if (req && req.offset && req.pageSize) {
    endpoint = `${config.apiPath}/api/areas/assignedAreas?pageSize=` + config.pageSize + '&page=' + page
  } else if (req && req.all === true) {
    endpoint = `${config.apiPath}/api/areas/assignedAreas&all=true`
  }
  return (dispatch) => {
    dispatch({ type: FETCH_AREAS_LOADER, payload: true })
    dispatch(fetch_area_success())
    return axios
      .get(`${config.apiPath}/api/areas/assignedAreas?pageSize=${config.pageSize}&page=${page}`, {
        params: {
          org,
        },
      })
      .then((response) => {
        dispatch({ type: FETCH_AREAS_LOADER_SUCCESS, payload: false })
        dispatch(fetch_area_success(response.data))
        return response.data
      })
      .catch((error) => {
        dispatch(fetch_area_failed(error))
        return error
      })
  }
}

export const areaSearchQuery = (url, status, city_id = null, userId = null, org = null) => {
  const urlParams = new URLSearchParams(window.location.search)
  const page = urlParams.get('page')
  return (dispatch) => {
    dispatch({ type: FETCH_AREAS_LOADER, payload: true })
    dispatch(search_query_begin())
    if (status == '') {
      return axios
        .get(`${url}&page=${page}&pageSize=${config.pageSize}`, {
          params: {
            city_id,
            userId,
            org,
          },
        })
        .then((res) => {
          dispatch({ type: FETCH_AREAS_LOADER_SUCCESS, payload: false })
          dispatch(fetch_area_success(res.data))
          return res.data
        })
        .catch((error) => {
          dispatch(search_query_failed(error))
          return error
        })
    } else {
      return axios
        .get(`${url}${status}=true&page=${page}&pageSize=${config.pageSize}`, {
          params: {
            city_id,
            userId,
            org,
          },
        })
        .then((res) => {
          dispatch({ type: FETCH_AREAS_LOADER_SUCCESS, payload: false })
          dispatch(fetch_area_success(res.data))
          return res.data
        })
        .catch((error) => {
          dispatch(search_query_failed(error))
          return error
        })
    }
  }
}


export const fetchAreas = (city_id=null, wanted=false, all=true, name = null, cb = (() => {}), limit = null, id = []) => {
  return (dispatch) => {
    dispatch({ type: FETCH_AREAS_LOADER, payload: true })
    dispatch(fetch_area_success())
    return axios.post(`${config.apiPath}/api/areas`,
       {
        city_id: city_id && Array.isArray(city_id) && city_id.length ? city_id : !Array.isArray(city_id) ? city_id : null,
        all: all || null,
        wanted: wanted ? wanted : '',
        q: name,
        id: id && id.length > 0 ? id : null,
        limit
      },
    )
      .then(response => {
        if(wanted){
          dispatch(fetch_aira_area_success(response.data))
          cb(response.data)
          return response.data
        }else{
          dispatch({ type: FETCH_AREAS_LOADER_SUCCESS, payload: true })
          dispatch(fetch_area_success(response.data))
          cb(response.data)
          return response.data
        }
      })
      .catch(error => {
        dispatch(fetch_aira_area_failed(error))
        return error;
      })
  }
}

export const deleteArea = (areaIds) => {
  return (dispatch) => {
    dispatch(delete_area_begin())
    return axios
      .delete(`${config.apiPath}/api/user/removearea?`, {
        params: {
          areas: areaIds,
        },
      })
      .then((res) => {
        dispatch(delete_area_success(res.data))
        Swal.fire('Success', 'Area UnAssigned successfully', 'success')
        return res.data
      })
      .catch((error) => {
        dispatch(delete_area_failed(error))
        return error
      })
  }
}

export const fetchAreaGroups = () => {
  return (dispatch) => {
    axios.get(`${config.apiPath}/api/areas/areaGroups?all=true`).then((res) => {
      var array = []
      res.data &&
      res.data.rows.length > 0 &&
      res.data.rows.filter((item) => {
        return array.push({ label: item.name, value: item.id })
      })
      dispatch({ type: FETCH_AREA_GROUPS, payload: array })

    })
  }

}
