/** @format */

import { toast } from 'react-toastify'

export const capitalize_Words = (str) => {
  return (
    str &&
    str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    })
  )
}

export const capitalizeWordsWithoutUnderscore = (str, skip = false) => {
  return (
    str &&
    str.replace(/(^|_)./g, function (txt) {
      let withOut = txt.replace(/_/, ' ')
      if (skip) return withOut.charAt(0).toUpperCase() + withOut.substr(1)
      else return withOut.charAt(0).toUpperCase() + withOut.substr(1).toUpperCase()
    })
  )
}

export const paymentStatus = (payment) => {
  return( payment === 'pendingAccount'
    ? 'Pending Accounts'
    : payment === 'pendingAccountHq'
    ? 'Pending Account HQ'
    : payment === 'bankPending'
    ? 'Bank Pending'
    : payment === 'notCleared'
    ? 'Not Cleared'
    : payment === 'cleared'
    ? 'Cleared'
    : payment === 'pendingSales'
    ? 'Pending Sales'
    : payment === 'at_buyer_agent'
    ? 'At Buyer Agent'
    : payment === 'at_property_agent'
    ? 'At Property Agent'
    : payment === 'given_back_to_buyer'
    ? 'Given Back To Buyer'
    : payment === 'at_buyer_agent_pending_verification'
    ? 'At Buyer Agent Pending Verification'
    : payment === 'returnToAgent'
    ? 'Return To Agent'
    : payment === 'bankPending'
    ? 'Bank Pending'
    : payment === 'pendingAccount'
    ? 'Pending Account'
    : payment === 'given_to_property_owner'
    ? 'Given To Property Owner'
    : payment === 'at_property_agent_pending_verification'
    ? 'At Property Agent Pending Verification'
    : payment === 'pendingAccountHq'
    ? 'Pending Account HQ'
    : 'Open')
}

export const extractFileExtension = (str) => {
  let fileExtension = str.split('.').pop()
  return fileExtension
}

export const formatProductDesc = (str) => {
  let fileExtension = str.split('.').pop()
  return fileExtension
}

export const replaceWithBr = (str) => {
  if(str)
    return str.replace(/\n/g, "<br />")
  else
    return '';
}
export const showErrorMessage = (message) => {
  toast.error(message, {
    toastId: 'loginError',
    position: 'bottom-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  })
}

export const showInfoMessage = (message) => {
  toast.info(message, {
    toastId: 'infoError',
    position: 'bottom-right',
    hideProgressBar: true,
    autoClose: false,
    closeOnClick: false,
    pauseOnHover: false,
    draggable: false,
  })
}

export const showSuccessMessage = (message) => {
  toast.success(message, {
    toastId: 'successMsg',
    position: 'bottom-right',
    hideProgressBar: true,
    autoClose: true,
    closeOnClick: false,
    pauseOnHover: false,
    draggable: false,
  })
}
