/** @format */

import React, { Component } from 'react'
import LeadDetailTabViewInner from './LeadDetailTabViewInner'
import { connect } from 'react-redux'
import { fetchSingleLead, fetchUserClients, fetchClientsBank } from '../../actions/leads'
import { withRouter } from 'react-router-dom'
import { normalizeCnic, normalizeCnicAndNTN } from '../../utils/formatCnic'
import { newFormatPrice } from '../../utils/formatPrice'
import Loader from 'react-loader-spinner'
import axios from 'axios'
import moment from 'moment'
import config from '../../config'
import ReactAudioPlayer from 'react-audio-player'
import { StaticData } from '../../StaticData'
import { leadType } from '../../utils/constant_data'

class LeadDetailTabView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      editShow: false,
      details: '',
      collapse: false,
      showArrow: false,
    }
  }
  componentDidMount() {
    const { singleLeadRecord } = this.props
    let leadId = this.props.match.params.leadId
    if (!leadId && this.props.leadId) leadId = this.props.leadId

    if (this.props.checkLeadtype === 'diary') {
      if (this.props.leadType === 'Project') {
        this.props.fetchSingleLead(leadId, true, null, singleLeadRecord && singleLeadRecord.hasBooking)
      } else {
        this.props.fetchSingleLead(leadId, false, null, singleLeadRecord && singleLeadRecord.hasBooking)
      }
    } else {
      this.props.fetchSingleLead(leadId, this.props.ApiCall, null, singleLeadRecord && singleLeadRecord.hasBooking)
    }
    this.props.fetchUserClients()
  }

  udpateDescription = () => {
    const { singleLeadRecord } = this.props
    const { details } = this.state
    let body = { description: details }
    axios
      .patch(
        `${config.apiPath}/api/leads/project?id[]=${singleLeadRecord && singleLeadRecord.id}`,
        body
      )

      .then((res) => {
        this.props.fetchSingleLead(singleLeadRecord && singleLeadRecord.id, true, null, singleLeadRecord && singleLeadRecord.hasBooking)
      })
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      details: nextProps && nextProps.singleLeadRecord && nextProps.singleLeadRecord.description,
    })
  }

  capitalize(str) {
    return str ? str.charAt(0).toUpperCase() + str.slice(1) : ''
  }

  checkStatus(status, armsStatus, componentOf) {
    if (armsStatus) {
      return armsStatus
    } else {
      return status
    }
  }

  checkLeadType(singleLeadRecord, checkLeadtype, checkProjectId, CheckPurpose) {
    if (checkLeadtype === 'Project') {
      if (singleLeadRecord && singleLeadRecord.projectId !== null) {
        return 'investment'
      } else if (singleLeadRecord && singleLeadRecord.wanted_id !== null) {
        return 'wanted'
      }
    } else if (checkLeadtype === 'diary' || 'overdue') {
      if (checkProjectId || (singleLeadRecord && singleLeadRecord.projectId)) {
        return 'investment'
      } else if (CheckPurpose || (singleLeadRecord && singleLeadRecord.purpose)) {
        return (CheckPurpose && CheckPurpose) || singleLeadRecord.purpose
      } else if (singleLeadRecord && singleLeadRecord.wanted_id !== null) {
        return 'wanted'
      }
    } else {
      return '--'
    }
  }

  checkLeadSource = () => {
    const { singleLeadRecord } = this.props
    if (singleLeadRecord.origin) {
      if (singleLeadRecord.origin === 'arms') {
        return `${singleLeadRecord.origin.split('_').join(' ').toLocaleUpperCase()} ${
          singleLeadRecord.creator
            ? `(${this.capitalize(singleLeadRecord.creator.firstName)} ${this.capitalize(
                singleLeadRecord.creator.lastName
              )})`
            : ''
        }`
      } else {
        return `${singleLeadRecord.origin.split('_').join(' ').toLocaleUpperCase()}`
      }
    } else {
      return null
    }
  }

  extractAdditionalDetails = (value) => {
    // const parsedData = JSON.parse(value)

    let objectKeys = Object.keys(value)

    let renderAdditionalInformations =
      objectKeys &&
      objectKeys.map((item, index) =>
        value[item].map((internalItem) => <span>{(index ? ', ' : '') + internalItem}</span>)
      )

    return renderAdditionalInformations
  }
  render() {
    const {
      singleLeadRecord,
      singleLeadRecordLoader,
      tabActive,
      updateCollapse,
      showReadOnly,
      ownLeadReadOnly,
      checkForRead,
      fetchLeadTimeLine,
      fetchSingleLead,
      ApiCall,
      checkLeadtype,
      checkProjectId,
      CheckPurpose,
      armsStatus,
      componentOf,
      currentUser,
      userClients,
    } = this.props
    const { showArrow, details } = this.state
    let leadSource = singleLeadRecord && this.checkLeadSource()
    let discountedAmount =
      (singleLeadRecord && singleLeadRecord.unit && singleLeadRecord.unit.unit_price) -
      (singleLeadRecord && singleLeadRecord.unit && singleLeadRecord.unit.finalPrice)

    const contentType =
      tabActive === 'payment' &&
      singleLeadRecord &&
      singleLeadRecord.project !== null &&
      singleLeadRecord
      // singleLeadRecord &&
      // singleLeadRecord.payment &&
      // singleLeadRecord.payment.length !== 0
        ? [
            {
              label: 'Project',
              content:
                singleLeadRecord && singleLeadRecord.paidProject !== null
                  ? singleLeadRecord &&
                    singleLeadRecord.paidProject &&
                    singleLeadRecord.paidProject.name
                  : singleLeadRecord && singleLeadRecord.project && singleLeadRecord.project.name,
            },
            {
              label: 'Client Name',
              clientShow: true,
              content: singleLeadRecord && singleLeadRecord.customer && singleLeadRecord.customer.customerName,
            },
            // {
            //   label: 'Booking For',
            //   clientShow: true,
            //   content: '',
            // },
            {
              label: 'Applicants',
              showFormBooking: true,
              content: '',
            },
            {
              label: 'Floor',
              content:
                singleLeadRecord && singleLeadRecord.floor !== null
                  ? singleLeadRecord && singleLeadRecord.floor && singleLeadRecord.floor.name
                  : '--',
            },
            {
              label: 'Unit Type',
              content:
                singleLeadRecord && singleLeadRecord.unit !== null
                  ? singleLeadRecord &&
                    singleLeadRecord.unit &&
                    singleLeadRecord.unit.type === 'regular'
                    ? 'Full Unit'
                    : 'Pearl'
                  : '--',
            },
            {
              label: 'Unit Price',
              content:
                singleLeadRecord && singleLeadRecord.unit !== null
                  ? `PKR ${newFormatPrice(
                      singleLeadRecord && singleLeadRecord.unit && singleLeadRecord.unit.unit_price
                    )}`
                  : '--',
            },
            {
              label: 'Down Payment',
              content:
                singleLeadRecord && singleLeadRecord.unit !== null
                  ? singleLeadRecord && singleLeadRecord.downPayment === 0
                    ? 0
                    : singleLeadRecord && singleLeadRecord.downPayment !== null
                    ? `PKR ${singleLeadRecord && singleLeadRecord.downPayment}`
                    : '--'
                  : '--',
            },
            {
              label: 'Unit Status',
              content:
                singleLeadRecord && singleLeadRecord.unit !== null
                  ? `${newFormatPrice(
                      singleLeadRecord &&
                        singleLeadRecord.unit &&
                        singleLeadRecord.unit.bookingStatus
                    )}`
                  : '--',
            },
            {
              label: 'Schedule of Payment',
              showDetail: true,
              content: '',
            },
            {
              label: 'Investment Plan',
              investment: true,
              content: '',
            },
            {
              label: 'KFI Document',
              linkView: true,
              content: '',
            },
            // {
            //   label: 'Sales Memo',
            //   linkViewSales: true,
            //   content: '',
            // },
            {
              label: 'Approved Discount',
              content:
                singleLeadRecord && singleLeadRecord.unit !== null
                  ? Number(
                      (100 * discountedAmount) /
                        (singleLeadRecord &&
                          singleLeadRecord.unit &&
                          singleLeadRecord.unit.unit_price)
                    ).toFixed(2) + '%'
                  : '--',
            },
            {
              label: 'Discount Amount',
              content:
                (singleLeadRecord && singleLeadRecord.unit && singleLeadRecord.unit.unit_price) ===
                (singleLeadRecord && singleLeadRecord.unit && singleLeadRecord.unit.finalPrice)
                  ? 0
                  : `PKR ${newFormatPrice(
                      Number(
                        (singleLeadRecord &&
                          singleLeadRecord.unit &&
                          singleLeadRecord.unit.unit_price) -
                          (singleLeadRecord &&
                            singleLeadRecord.unit &&
                            singleLeadRecord.unit.finalPrice)
                      ).toFixed(2)
                    )}`,
            },
            {
              label: 'Final Price',
              content:
                singleLeadRecord && singleLeadRecord.unit !== null
                  ? `PKR ${newFormatPrice(
                      singleLeadRecord && singleLeadRecord.unit && singleLeadRecord.unit.finalPrice
                    )}`
                  : '--',
            },
            !checkForRead.showReadOnly || checkForRead.ownLeadReadOnly
              ? {
                  label: 'Client CNIC/NTN',
                  content:
                    singleLeadRecord && singleLeadRecord.unit !== null
                      ? normalizeCnicAndNTN(
                          singleLeadRecord &&
                            singleLeadRecord.customer &&
                            singleLeadRecord.customer.cnic
                        )
                      : '--',
                }
              : {},
            // {
            //   label: 'Additional Info',
            //   content:
            //     singleLeadRecord && singleLeadRecord.fbAdditionalDetails != null
            //       ? this.extractAdditionalDetails(JSON.parse(singleLeadRecord.fbAdditionalDetails))
            //       : '--',
            // },
          ]
        : tabActive === 'calls' && [
            {
              label: 'Lead Type',
              content: 'Investment',
            },
            {
              label: 'Classification',
              setCategory: true,
              color:
                (singleLeadRecord &&
                  singleLeadRecord.leadCategory &&
                  StaticData.leadCategories.filter((c) => c.value === singleLeadRecord.leadCategory)
                    .length &&
                  StaticData.leadCategories.filter(
                    (c) => c.value === singleLeadRecord.leadCategory
                  )[0].colorCode) ||
                null,
              content: (singleLeadRecord && singleLeadRecord.leadCategory) || '--',
            },
            {
              label: 'Client Name',
              clientShow: true,
              content:
                singleLeadRecord &&
                singleLeadRecord.customer &&
                singleLeadRecord.customer.customerName
                  ? singleLeadRecord &&
                    singleLeadRecord.customer &&
                    singleLeadRecord.customer.customerName
                  : '--',
            },
            {
              label: 'Requirments',
              content:
                singleLeadRecord &&
                singleLeadRecord.productTypes &&
                singleLeadRecord.productTypes !== null
                  ? singleLeadRecord &&
                    singleLeadRecord.productTypes &&
                    singleLeadRecord.productTypes.name
                  : '--',
            },
            {
              label: 'Project',
              content:
                singleLeadRecord && singleLeadRecord.project && singleLeadRecord.project.name,
            },
            {
              label: 'Price Range',
              content:
                (singleLeadRecord && singleLeadRecord.minPrice !== null) ||
                (singleLeadRecord && singleLeadRecord.maxPrice !== null)
                  ? `PKR ${newFormatPrice(
                      singleLeadRecord && singleLeadRecord.minPrice
                    )} - PKR ${newFormatPrice(singleLeadRecord && singleLeadRecord.maxPrice)}`
                  : '--',
            },
            {
              label: 'Assigned',
              content:
                singleLeadRecord &&
                singleLeadRecord.armsuser &&
                singleLeadRecord.armsuser.firstName + ' ' + singleLeadRecord.armsuser.lastName,
            },
            {
              label: 'Lead ID',
              content: singleLeadRecord && singleLeadRecord.id,
            },
            {
              label: 'Voice Note',
              hidden:
                !singleLeadRecord || !singleLeadRecord.wanted || !singleLeadRecord.wanted.voiceLead,
              className: 'audio-player-wrapper',
              content: (
                <ReactAudioPlayer
                  controlsList={'nodownload noremoteplayback'}
                  src={
                    singleLeadRecord &&
                    singleLeadRecord.wanted &&
                    singleLeadRecord.wanted.voiceLead &&
                    singleLeadRecord.wanted.voiceLead.voiceNoteLink
                  }
                  controls
                />
              ),
            },
            {
              label: 'Additional Info',
              content:
                singleLeadRecord && singleLeadRecord.fbAdditionalDetails != null
                  ? this.extractAdditionalDetails(JSON.parse(singleLeadRecord.fbAdditionalDetails))
                  : '--',
            },
          ]

    const afterDetails = [
      {
        label: 'Assigned To',
        content:
          singleLeadRecord && singleLeadRecord.armsuser && singleLeadRecord.armsuser.firstName
            ? singleLeadRecord.armsuser.firstName + ' ' + singleLeadRecord.armsuser.lastName
            : '--',
      },
      {
        label: 'Assigned Date',
        content:
          singleLeadRecord && singleLeadRecord.assigned_at
            ? moment(singleLeadRecord.assigned_at).format('MMM DD YYYY, hh:mm A')
            : '--',
      },
      {
        label: 'Created Date',
        content:
          singleLeadRecord && singleLeadRecord.createdAt
            ? moment(singleLeadRecord.createdAt).format('MMM DD YYYY, hh:mm A')
            : '--',
      },
      {
        label: 'Modified Date',
        content:
          singleLeadRecord && singleLeadRecord.updatedAt
            ? moment(singleLeadRecord.updatedAt).format('MMM DD YYYY, hh:mm A')
            : '--',
      },
      {
        label: 'Lead Source',
        content:
          singleLeadRecord && singleLeadRecord.projectId && singleLeadRecord.bulk
            ? leadSource + '(Bulk uploaded)'
            : '--',
      },
      {
        label: 'Lead City',
        content:
          singleLeadRecord && singleLeadRecord.city != null && singleLeadRecord.city.name != null
            ? singleLeadRecord.city.name
            : '--',
      },
      {
        label: 'Assigned by',
        content:
          singleLeadRecord && singleLeadRecord.cmAssignedBy != null
            ? singleLeadRecord.cmAssignedBy.firstName + ' ' + singleLeadRecord.cmAssignedBy.lastName
            : '--',
      },
      {
        label: 'Lead ID',
        content: singleLeadRecord ? singleLeadRecord.id : '--',
      },
    ]

    const additionalDetails = [
      {
        label: 'ID',
        content: singleLeadRecord ? singleLeadRecord.id : '--',
      },
      {
        label: 'Rule ID',
        content:
          singleLeadRecord && singleLeadRecord.armsLeadRuleId
            ? singleLeadRecord.armsLeadRuleId
            : '--',
      },
      {
        label: 'Status',
        content:
          singleLeadRecord && singleLeadRecord.status !== null
            ? this.checkStatus(singleLeadRecord.status, armsStatus, componentOf)
            : '--',
      },
      {
        label: 'Type',
        content:
          singleLeadRecord && singleLeadRecord.purpose && singleLeadRecord.purpose !== null
            ? singleLeadRecord.purpose
            : this.checkLeadType(singleLeadRecord, checkLeadtype, checkProjectId, CheckPurpose),
        //content: (Leadtype && Leadtype) ? Leadtype : '--',
      },

      {
        label: 'Classification',
        color:
          (singleLeadRecord &&
            singleLeadRecord.leadCategory &&
            StaticData.leadCategories.filter((c) => c.value === singleLeadRecord.leadCategory)
              .length &&
            StaticData.leadCategories.filter((c) => c.value === singleLeadRecord.leadCategory)[0]
              .colorCode) ||
          null,
        content: (singleLeadRecord && singleLeadRecord.leadCategory) || '--',
      },
      (checkLeadtype === 'Project' || checkLeadtype === 'diary') && {
        label: 'Reference Guide #',
        content:
          singleLeadRecord &&
          singleLeadRecord.guideReference !== null &&
          singleLeadRecord.guideReference !== '{}' &&
          singleLeadRecord.guideReference
            ? singleLeadRecord.guideReference
            : '--',
        // this.extractAdditionalDetails(JSON.parse(item.fbAdditionalDetails))}
      },
      {
        label: 'Assigned To',
        content:
          singleLeadRecord && singleLeadRecord.armsuser && singleLeadRecord.armsuser.firstName
            ? singleLeadRecord.armsuser.firstName + ' ' + singleLeadRecord.armsuser.lastName
            : '--',
      },
      {
        label: 'Assigned Date',
        content:
          singleLeadRecord && singleLeadRecord.assigned_at
            ? moment(singleLeadRecord.assigned_at).format('MMM DD YYYY, hh:mm A')
            : '--',
      },
      {
        label: 'Created Date',
        content:
          singleLeadRecord && singleLeadRecord.createdAt
            ? moment(singleLeadRecord.createdAt).format('MMM DD YYYY, hh:mm A')
            : '--',
      },
      {
        label: 'Modified Date',
        content:
          singleLeadRecord && singleLeadRecord.updatedAt
            ? moment(singleLeadRecord.updatedAt).format('MMM DD YYYY, hh:mm A')
            : '--',
      },
      {
        label: 'Lead Source',
        content: singleLeadRecord && singleLeadRecord.origin ? singleLeadRecord.origin : '--',
      },
      {
        label: 'Lead City',
        content:
          singleLeadRecord && singleLeadRecord.city != null && singleLeadRecord.city.name != null
            ? singleLeadRecord.city.name
            : '--',
      },
      checkLeadtype === 'Project' ||
        (checkLeadtype === 'diary' && {
          label: 'Additional Information',
          content:
            singleLeadRecord &&
            singleLeadRecord.fbAdditionalDetails !== null &&
            singleLeadRecord.fbAdditionalDetails !== '{}' &&
            singleLeadRecord.fbAdditionalDetails
              ? singleLeadRecord.fbAdditionalDetails
              : '--',
          // this.extractAdditionalDetails(JSON.parse(item.fbAdditionalDetails))}
        }),
      {
        label: 'Assigned By',
        content:
          singleLeadRecord &&
          singleLeadRecord.rcmAssignedBy !== null &&
          singleLeadRecord.rcmAssignedBy !== '{}' &&
          singleLeadRecord.rcmAssignedBy
            ? singleLeadRecord.rcmAssignedBy.firstName + singleLeadRecord.rcmAssignedBy.lastName
            : '--',
      },
      {
        label: 'Referred By',
        content:
          singleLeadRecord &&
          singleLeadRecord.referredBy !== null &&
          singleLeadRecord.referredBy !== '{}' &&
          singleLeadRecord.referredBy
            ? singleLeadRecord.referredBy.firstName + singleLeadRecord.referredBy.lastName
            : '--',
      },
    ]

    return (
      <div
        className={`${
          !checkForRead.showReadOnly || checkForRead.ownLeadReadOnly ? '' : 'changeHeight'
        } ${showReadOnly ? 'payment-scroller-readonly' : 'parent-scroller-container'}`}
        onMouseEnter={() => this.setState({ showArrow: true })}
        onMouseLeave={() => this.setState({ showArrow: false })}
      >
        {showArrow ? (
          <div
            className="icon-position"
            style={{ top: !checkForRead.showReadOnly || checkForRead.ownLeadReadOnly ? null : 62 }}
            onMouseEnter={() => this.setState({ showArrow: true })}
            onMouseLeave={() => this.setState({ showArrow: false })}
          >
            <div className="button-circle" onClick={() => updateCollapse(true)}>
              <i className="fa fa-angle-right icon-color" aria-hidden="true"></i>
            </div>
          </div>
        ) : null}

        {singleLeadRecordLoader ? (
          <div className="loader-spinner ">
            <Loader type="RevolvingDot" color="#0f73ee" height="30" width="30" />
          </div>
        ) : (
          <>
            {contentType &&
              contentType.map((item, index) =>
                !item.hidden ? (
                  <LeadDetailTabViewInner
                    item={item}
                    key={index}
                    checkForRead={checkForRead}
                    checkLeadtype={checkLeadtype}
                    showReadOnly={showReadOnly}
                    ownLeadReadOnly={ownLeadReadOnly}
                    singleLeadRecord={singleLeadRecord}
                    fetchLeadTimeLine={null}
                    currentUser={currentUser}
                    userClients={userClients}
                  />
                ) : (
                  <></>
                )
              )}
            {tabActive === 'payment'
              ? null
              : tabActive === 'calls' && (
                  <div className="description-container">
                    <span className="icon-edit">
                      <i
                        className="fal fa-edit icon-pointer"
                        onClick={() => {
                          if (!checkForRead.showReadOnly || checkForRead.ownLeadReadOnly) {
                            this.udpateDescription()
                          }
                        }}
                      ></i>
                    </span>
                    <textarea
                      rows="5"
                      className="container-style"
                      placeholder="Description"
                      disabled={
                        !checkForRead.showReadOnly || checkForRead.ownLeadReadOnly ? false : true
                      }
                      onChange={(e) => this.setState({ details: e.target.value })}
                      cols="50"
                      value={details}
                    />
                  </div>
                )}

            {tabActive !== 'payment' &&
              tabActive !== 'additionalDetailTab' &&
              afterDetails &&
              afterDetails.map((item, index) => (
                <LeadDetailTabViewInner
                  item={item}
                  key={index}
                  checkForRead={checkForRead}
                  showReadOnly={showReadOnly}
                  ownLeadReadOnly={ownLeadReadOnly}
                  singleLeadRecord={singleLeadRecord}
                  currentUser={currentUser}
                />
              ))}
            {tabActive === 'additionalDetailTab' &&
              additionalDetails &&
              additionalDetails.map((item, index) => (
                <LeadDetailTabViewInner
                  item={item}
                  key={index}
                  checkForRead={checkForRead}
                  showReadOnly={showReadOnly}
                  ownLeadReadOnly={ownLeadReadOnly}
                  singleLeadRecord={singleLeadRecord}
                  checkFrom="additionalDetailTab"
                  componentOf={componentOf}
                  currentUser={currentUser}
                />
              ))}
          </>
        )}
      </div>
    )
  }
}

export const mapDispatchToProps = (dispatch) => {
  return {
    // maybe fetch single lead problem 4136
    fetchSingleLead: (query, byProject, callBack,check_MyDeal_Or_MyLeads) => dispatch(fetchSingleLead(query, byProject, callBack,check_MyDeal_Or_MyLeads)),
    getClientsData: () => dispatch(fetchUserClients()),
    fetchUserClients: () => dispatch(fetchUserClients()),
  }
}

const mapStateToProps = (state, ownProps) => {
  const urlParams = new URLSearchParams(ownProps.location.search)
  const pageSize = urlParams.get('pageSize')
  const page = urlParams.get('page')
  return {
    page,
    pageSize,
    singleLeadRecord: state.Leads.singleLeadRecord,
    singleLeadRecordLoader: state.Leads.singleLeadRecordLoader,
    currentUser: state.user.userInfo,
    userClients: state.Leads.userClients,
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LeadDetailTabView))
