/** @format */

import React, { useEffect, useRef, useState } from 'react'
import { UA, WebSocketInterface } from 'jssip'
import SocketAction from './SocketAction'
import _ from 'lodash'
import ringing from './ringing.mp3'
import { setDialerData } from '../actions/setDialerData'
import { connect } from 'react-redux'
import { mwsSoftPhoneDialNumber, getSocketCall } from '../actions/diary'
import { showToastMsg } from '../utils/helper'
import CallDuration from './CallDuration'
import { validateNumber } from './ptclCodes'
import RecentLogs from './src/recentLogs'
import axios from 'axios'
import config from '../config'
import BarCharts from './BarCharts'
import TransferAgentComponent from './TransferAgentComponent'
import hold_call from '../callCenter/icons/hold_call.png'
import unhold_call from '../callCenter/icons/unhold_call.png'
import customer_call from '../callCenter/icons/customer_call.png'
import hangup_call from '../callCenter/icons/hangup_call.png'
import incoming_call from '../callCenter/icons/incoming_call.png'
import outcoming_call from '../callCenter/icons/outcoming_call.png'
import transfer_call from '../callCenter/icons/transfer_call.png'

import './style.scss'
import './style.css'
import { onlineAgent } from '../agentOnlineSession'
import AgentMisscall from './AgentMisscall'

const Phone = (props) => {
  const is_outgoing_allowed = true
  let sip
  const agentUser = props.agentUser
  const domain = props.domain
  const password = props.password
  const socket = new WebSocketInterface(`${props.ssl}://${domain}:${props.port}/ws`)

  const [isSocketConnected, setIsSocketConnected] = useState(false)
  const [agentDisplayName, setAgentDisplayName] = useState('')
  const [armUser, setArmUser] = useState({})
  useEffect(() => {
    setIsSocketConnected(props.isSocketConnected)
  }, [props.isSocketConnected])
  useEffect(() => {
    let user = props.user !== undefined ? props.user : {}
    setArmUser((armUser) => props.user)
    if (
      props.user !== undefined &&
      props.user.firstName !== undefined &&
      props.user.lastName !== undefined
    ) {
      setAgentDisplayName(`${props.user.firstName} ${props.user.lastName}`)
      localStorage.setItem('agentName', `${props.user.firstName} ${props.user.lastName}`)
    }
  }, [props.user])
  const [isConnecting, setIsConnecting] = useState(false)
  const [userRegister, setUserRegister] = useState({
    register: false,
    cssClass: 'danger',
    message: 'connecting...',
  })
  const [isDurationHide, setIsDurationHide] = useState(false)
  const [customerName, setCustomerName] = useState('')
  const [queueName, setQueueName] = useState('')
  const [queueId, setQueueId] = useState('')
  const [leadId, setLeadId] = useState('')
  const [diaryId, setDiaryId] = useState('')
  const [reloadLog, setReloadLog] = useState(false)
  const [sipDial, setSipDial] = useState('')
  const [callAlert, setCallAlert] = useState({})
  const [isClientDetail, setIsClientDetail] = useState(false)
  const [callAlertPrev, setCallAlertPrev] = useState({})
  const [activeCall, setActiveCall] = useState('')
  const [queueCalls, setQueueCalls] = useState('')
  const [transferAgent, setTransferAgent] = useState(false)

  const [audioInputs, setAudioInput] = useState([])
  const [isOpenBarChartsModel, setIsOpenBarChartsModel] = useState(false)
  const [isOpenMissCall, setIsOpenMissCall] = useState(false)

  const [dialPhoneNumber, setDialPhoneNumber] = useState('')
  const phoneAudioRef = useRef(null)
  const ringer = useRef(null)
  const dialPhoneNumberRef = useRef(null)
  const configuration = {
    traceSip: true,
    register: true,
    hackIpInContact: true,
    hackWssInTransport: true,
    register_expires: 3600,
    displayName: agentDisplayName.toString(),
    authorizationUser: agentUser,
    wsServers: `${props.ssl}://${domain}:${props.port}/ws`,
    rtcpMuxPolicy: 'negotiate',
    sockets: [socket],
    uri: `sip:${agentUser}@${domain}`,
    password: password.toString(),
  }

  const getCustomerName = (phoneNumber) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${config.apiPath}/api/asterisk/getCustomerName?phoneNumber=${phoneNumber.substring(1)}`
        )
        .then((customeName) => {
          resolve(customeName.data)
        })
        .catch((error) => {
          reject('')
        })
    })
  }
  const [onlineAgents, setOnlineAgents] = useState([])
  const [callUniqueId, setCallUniqueId] = useState('')
  const [callQueueId, setCallQueueId] = useState('')
  useEffect(() => {
    setOnlineAgents((onlineAgents) => props.onlineAgents)
  }, [props.onlineAgents])

  const registerWebrtcEvent = (call, callAlert) => {
    const defaultCallEventsToHandle = [
      'peerconnection',
      'connecting',
      'sending',
      'progress',
      'accepted',
      'newDTMF',
      'newInfo',
      'hold',
      'unhold',
      'muted',
      'unmuted',
      'reinvite',
      'update',
      'refer',
      'replaces',
      'sdp',
      'icecandidate',
      'getusermediafailed',
      'ended',
      'failed',
      'connecting',
      'confirmed',
      'peerconnection:createofferfailed',
      'peerconnection:createanswerfailed',
      'peerconnection:setlocaldescriptionfailed',
      'peerconnection:setremotedescriptionfailed',
    ]
    _.forEach(defaultCallEventsToHandle, (eventType) => {
      call.on(eventType, (data, cause) => {
        sessionEvent(eventType, data, cause, call.id, call)
      })
    })
  }
  useEffect(() => {
    if (Object.keys(callAlert).length > 0) {
      setCallAlert((callAlert) => ({
        ...callAlert,
        callUniqueId: props.callUniqueId,
      }))
      updateLiveCallToLocalStorage('callUniqueId', props.callUniqueId)
      setCallUniqueId((cal) => props.callUniqueId)
    } else {
      updateLiveCallToLocalStorage('callUniqueId', props.callUniqueId)
    }
  }, [props.callUniqueId])

  useEffect(() => {
    if (Object.keys(callAlert).length > 0) {
      setCallAlert((callAlert) => ({
        ...callAlert,
        callQueueId: props.callQueueId,
      }))
      updateLiveCallToLocalStorage('callQueueId', props.callQueueId)
      setCallQueueId(props.callQueueId)
    }
  }, [props.callQueueId])

  useEffect(() => {
    sip = new UA(configuration)
    sip.on('connected', (e) => {
      setUserRegister((userRegister) => ({
        ...userRegister,
        cssClass: 'warning',
        register: false,
        message: 'connected',
      }))
    })
    sip.on('disconnected', (e) => {
      setUserRegister((userRegister) => ({
        ...userRegister,
        cssClass: 'danger',
        register: false,
        message: 'disconnected ',
      }))
      setIsConnecting(false)
    })
    sip.on('newRTCSession', (e) => {
      const { session: call } = e
      console.log(call._request.headers, 'Call')
      let incallId = ''
      let incallQueue = ''
      if (
        call._request.headers['Sip-Caller-Id'] !== undefined &&
        call._request.headers['Sip-Caller-Id'][0] !== undefined &&
        call._request.headers['Sip-Caller-Id'][0].raw !== undefined
      ) {
        incallId = call._request.headers['Sip-Caller-Id'][0].raw
      }

      if (
        call._request.headers['Sip-Queue-Name'] !== undefined &&
        call._request.headers['Sip-Queue-Name'][0] !== undefined &&
        call._request.headers['Sip-Queue-Name'][0].raw !== undefined
      ) {
        setQueueName(call._request.headers['Sip-Queue-Name'][0].raw)
      }

      if (
        call._request.headers['Sip-Queue-Id'] !== undefined &&
        call._request.headers['Sip-Queue-Id'][0] !== undefined &&
        call._request.headers['Sip-Queue-Id'][0].raw !== undefined
      ) {
        incallQueue = call._request.headers['Sip-Queue-Id'][0].raw
        setQueueId(incallQueue)
      }

      const callId = call.direction === 'incoming' ? incallId : call._request.call_id
      setActiveCall((activeCall) => call)
      setQueueCalls((queueCalls) => call)
      const loginUserId = localStorage.getItem('user_id')
      let liveCall = localStorage.getItem('liveCall')
      liveCall =
        liveCall !== undefined && liveCall !== '' && liveCall !== null ? JSON.parse(liveCall) : {}
      const customerPhoneNumber = call.remote_identity.display_name
        ? call.remote_identity.uri.user || 'NIL'
        : call.remote_identity.uri.user

      setOnlineAgentStatus(
        agentUser,
        agentDisplayName,
        'ringing',
        customerPhoneNumber,
        props.socket
      )
      setCustomerName(() => '')
      getCustomerName(customerPhoneNumber).then((c) => {
        setCustomerName(c)
      })
      let mwsLeadId = localStorage.getItem('mwsLeadId')

      mwsLeadId =
        mwsLeadId !== undefined && mwsLeadId !== null && mwsLeadId !== ''
          ? JSON.parse(mwsLeadId)
          : {}
      const newData = {
        ...callAlert,
        sessionId: callId,
        callUniqueId: '',
        callQueueId: call.direction === 'incoming' ? incallQueue : '',
        phoneNumber: customerPhoneNumber,
        direction: call.direction,
        agentId: agentUser,
        cause: '',
        isHold: false,
        status: 'ringing',
        hangupBy: '',
        arm_user_id: loginUserId,
        leadId: mwsLeadId.leadId,
        diaryId: mwsLeadId.diaryId,
        isLead: !mwsLeadId.leadId,
        isMiscall: liveCall.isMiscall,
        cdrId: liveCall.cdrId,
      }
      if (call.direction === 'incoming') {
        props.mwsCloseDialer(true)
        playRing()
        setCallAlert((callAlert) => newData)
      } else {
        setCallAlert((callAlert) => newData)
        connectAudio(call)
      }

      if (customerPhoneNumber.length > 6) {
        // props.ClientLoadHandle(customerPhoneNumber)
      }
      if (props.mwsSoftPhoneDial.phoneNumber !== '') {
        newData.leadId = props.mwsSoftPhoneDial.leadId
        newData.diaryId = props.mwsSoftPhoneDial.diaryId
        newData.isLead = true
      } else {
        newData.isLead = false
      }
      localStorage.setItem('liveCall', JSON.stringify(newData))
      registerWebrtcEvent(call, callAlert)
    })

    sip.on('registered', (e) => {
      setUserRegister((userRegister) => ({
        ...userRegister,
        cssClass: 'success',
        register: true,
        message: 'registered',
      }))
      setIsConnecting(false)
    })
    sip.on('unregistered', (e) => {
      setUserRegister((userRegister) => ({
        ...userRegister,
        cssClass: 'info',
        register: false,
        message: 'unregistered',
      }))
      setIsConnecting(false)
    })
    sip.on('registrationFailed', (e) => {
      setUserRegister((userRegister) => ({
        ...userRegister,
        cssClass: 'danger',
        register: false,
        message: 'registration failed',
      }))
      setIsConnecting(false)
    })
    sip.on('invite', (e) => {})

    sip.start()
    /* navigator.getUserMedia(
      {
        audio: true,
        video: false,
      },
      (stream) => {

      },
      (error) => {}
    ); */
    setSipDial(sip)

    console.log(sip, 'SIPSIP')
    props.globalSipDial(sip)
  }, [])

  useEffect(() => {}, [callAlert])
  const sessionEvent = (eventType, data, cause1, id, call) => {
    const { cause } = data
    switch (cause) {
      case 'Canceled':
        /*console.log(eventType, data, id, call)
        setCallAlert((callAlert) => ({
          ...callAlert,
          hangupBy: 'phone'
        }))*/
        callDisconnect()
        break
      case 'accepted':
        console.log('accepted')
        //  connectAudio()
        break
      case 'confirmed':
        //connectAudio()
        break

      case 'Terminated':
        callDisconnect()
        break
    }

    let currentCall = localStorage.getItem('liveCall')
    switch (eventType) {
      case 'accepted':
        setCallAlert((callAlert) => ({
          ...callAlert,
          status: 'connected',
        }))

        break
      case 'confirmed':
        break
      case 'update':
        break
      case 'reinvite':
        break
      case 'refer':
        break
      case 'failed':
        currentCall = localStorage.getItem('liveCall')

        const { originator, cause } = data
        if (currentCall !== undefined && currentCall !== null && currentCall !== '') {
          currentCall = JSON.parse(currentCall)
          if (originator === 'remote') {
            currentCall.hangupBy = currentCall.phoneNumber
            if (currentCall.status === 'ringing') {
              currentCall.status = 'customer_miscall'
            } else {
              currentCall.status = 'call_failed_customer'
            }
            currentCall.cause = cause
            setCallAlertPrev((callAlert) => currentCall)
          } else {
            currentCall.hangupBy = currentCall.agentId
            currentCall.status = 'call_failed_agent'
            /* currentCall.originator = originator;*/
            currentCall.cause = cause
          }
          showToastMsg(`Call Failed :  Customer :${cause}`, 'warning')
          emitCallLogToARMS(currentCall)
          setOnlineAgentStatus(
            currentCall.agentId,
            agentDisplayName,
            'online',
            currentCall.phoneNumber,
            props.socket
          )
          callDisconnect()
        }
        break

      case 'ended':
        setIsDurationHide(true)
        let currentCall = localStorage.getItem('liveCall')
        if (currentCall !== undefined && currentCall !== null && currentCall !== '') {
          currentCall = JSON.parse(currentCall)

          const { originator, cause } = data
          if (originator === 'remote') {
            currentCall.hangupBy = call.remote_identity.display_name
              ? call.remote_identity.display_name || 'NIL'
              : call.remote_identity.uri.user
            setCallAlertPrev((callAlert) => currentCall)
            // setCallAlert((callAlert) => ({}))
          }
          if (originator === 'local') {
            currentCall.hangupBy = agentUser
            setCallAlertPrev((callAlert) => currentCall)
            //setCallAlert((callAlert) => ({}))
          }

          currentCall.status = 'call_ended'
          currentCall.cause = 'ANSWER'

          showToastMsg(`Call Hangup By ${currentCall.hangupBy}`, 'warning')
          emitCallLogToARMS(currentCall)
          props.mwsCloseDialer(false)
          setOnlineAgentStatus(agentUser, agentDisplayName, 'online', '', props.socket)
        }

        localStorage.removeItem('liveCall')
        callDisconnect()
        setIsDurationHide(false)
        break

      /*  'peerconnection:createofferfailed',
    'peerconnection:createanswerfailed',
    'peerconnection:setlocaldescriptionfailed',
    'peerconnection:setremotedescriptionfailed'*/
      case 'peerconnection:createofferfailed':
        break
      case 'peerconnection:createanswerfailed':
        break
      case 'peerconnection':
        break
    }
    /* console.log(eventType, data, cause, id, 'sessionEvent');*/
  }

  const emitCallLogToARMS = (currentCall) => {
    //onsole.log('Here')
    console.log(JSON.stringify(currentCall))
    //if(currentCall.sessionId !=='') {
    props.getSocketCall(currentCall)
    //}
    props.socket.emit('agent_call_data', JSON.stringify(currentCall))
  }
  const callDisconnect = () => {
    setActiveCall('')
    setQueueCalls('')
    setCallAlert((callAlert) => ({}))
    setCustomerName('')
    setQueueId('')
    setQueueName('')
    props.mwsSoftPhoneDialNumber({
      phoneNumber: '',
      leadId: '',
      diaryId: '',
    })
    setLeadId('')
    setDiaryId('')
    localStorage.removeItem('mwsLeadId')
  }
  const acceptHandle = (e) => {
    if (queueCalls !== '') {
      stopRing()
      setActiveCall((queueCall) => queueCalls)
      if (queueCalls !== '') {
        queueCalls.answer({
          mediaConstraints: {
            audio: true,
          },
        })
        setCallAlert((callAlert) => ({
          ...callAlert,
          status: 'connected',
          cause: 'call_accepted',
        }))
        connectAudio(queueCalls)
        setOnlineAgentStatus(
          agentUser,
          agentDisplayName,
          'on_call',
          callAlert.phoneNumber,
          props.socket
        )
        if (callAlert.phoneNumber.length > 6) {
          //props.ClientLoadHandle(callAlert.phoneNumber)
        }
        /* props.mwsCloseDialer(false);*/
      } else {
        showToastMsg('Something went wrong! 1', 'error')
        callDisconnect()
      }
    }
  }
  const rejectHandle = (e) => {
    if (queueCalls !== '') {
      setOnlineAgentStatus(
        agentUser,
        agentDisplayName,
        'online',
        callAlert.phoneNumber,
        props.socket
      )
      queueCalls.terminate()
      phoneAudioRef.current.defaultMuted = true
      phoneAudioRef.current.autoplay = false
      phoneAudioRef.current.volume = 0
      setCallAlertPrev((callAlert1) => ({
        ...callAlert,
        hangupBy: agentUser,
      }))
      showToastMsg(`Call Hangup By ${agentUser}`, 'warning')
      setCallAlert((callAlert) => ({}))

      setTransferAgent(false)

      props.mwsSoftPhoneDialNumber({
        phoneNumber: '',
        leadId: '',
        diaryId: '',
      })
    } else {
      showToastMsg('Something went wrong! 2', 'error')
      callDisconnect()
    }
  }
  const callPhoneNumber = async (phoneNumber) => {
    console.log(phoneNumber,phoneNumber.match(/^[0-9]+$/));
    if (
      sipDial !== '' &&
      phoneNumber !== undefined &&
      phoneNumber !== ''/* &&*/
      /*phoneNumber.match(/^[0-9]+$/) != null*/ /*&& isMobileValid(phoneNumber)*/
    ) {
      phoneAudioRef.current.defaultMuted = false
      phoneAudioRef.current.autoplay = true
      phoneAudioRef.current.volume = 1
      const options = {
        mediaConstraints: { audio: true, video: false },
      }
      await sipDial.call(`sip:${phoneNumber}@${domain}`, options)
      setOnlineAgentStatus(agentUser, agentDisplayName, 'on_call', phoneNumber, props.socket)
    } else {
      showToastMsg('Phone number is not allowed!', 'error')
    }
  }
  const dialPhoneNumberHandle = (e) => {
    callPhoneNumber(dialPhoneNumber).then((res) => {})
  }

  const connectAudio = (queueCalls) => {
    if (queueCalls !== '') {
      phoneAudioRef.current.defaultMuted = false
      phoneAudioRef.current.autoplay = true
      phoneAudioRef.current.volume = 1
      queueCalls.connection.addEventListener('addstream', (event) => {
        phoneAudioRef.current.srcObject = event.stream
      })
    } else {
      showToastMsg('Audio error occur !', 'error')
      callDisconnect()
    }
  }
  const playRing = () => {
    ringer.current.currentTime = 0
    ringer.current.play()
  }
  const stopRing = () => {
    ringer.current.currentTime = 0
    ringer.current.pause()
  }
  const transferCallHandle = (e, agentId) => {
    setCallAlert((callAlert) => ({ ...callAlert, transferTo: agentId }))
    if (queueCalls !== '') {
      console.log(agentId)
      console.log(`##${agentId}`)
      updateLiveCallToLocalStorage('transferTo', agentId)
      queueCalls.sendDTMF(`##${agentId}`)
      showToastMsg('Call transfer successfully!', 'success')
    } else {
      showToastMsg('Call Transfer error!', 'error')
    }
  }

  const updateLiveCallToLocalStorage = (key, value) => {
    let liveCall = localStorage.getItem('liveCall')
    if (liveCall !== undefined && liveCall !== null && liveCall !== '') {
      const loginUserId = localStorage.getItem('user_id')
      liveCall = JSON.parse(liveCall)
      liveCall[key] = value
      liveCall.arm_user_id = loginUserId

      if (props.mwsSoftPhoneDial.phoneNumber !== '') {
        liveCall.leadId = props.mwsSoftPhoneDial.leadId
        liveCall.diaryId = props.mwsSoftPhoneDial.diaryId
        liveCall.isLead = true
      } else {
        liveCall.isLead = false
      }
      localStorage.setItem('liveCall', JSON.stringify(liveCall))
    }
  }
  /* const updateLeadInfomation = (liveCall) =>{
     if (props.mwsSoftPhoneDial.leadId !==''){
       liveCall.leadId = props.mwsSoftPhoneDial.leadId;
       liveCall.diaryId = props.mwsSoftPhoneDial.diaryId;
       liveCall.isLead =true
     } else {
       liveCall.isLead =false
     }
     return liveCall;
   }*/
  useEffect(() => {
    if (
      props.mwsSoftPhoneDial.phoneNumber !== '' &&
      props.mwsSoftPhoneDial.phoneNumber !== undefined
    ) {
      updateLiveCallToLocalStorage('leadId', props.mwsSoftPhoneDial.leadId)
      updateLiveCallToLocalStorage('diaryId', props.mwsSoftPhoneDial.diaryId)
      updateLiveCallToLocalStorage('isLead', true)
      setDiaryId(props.mwsSoftPhoneDial.diaryId)
      setLeadId(props.mwsSoftPhoneDial.leadId)
      callPhoneNumber(props.mwsSoftPhoneDial.phoneNumber).then((res) => {
        //props.mwsSoftPhoneDialNumber('')
      })
    }

    /*setDialPhoneNumber((dialPhoneNumber) => (props.mwsSoftPhoneDial.phoneNumber))
    setTimeout(() => {
      if (dialPhoneNumberRef.current) {
        dialPhoneNumberRef.current.click() ?+6!@Yp=@]uP
      }
    }, 400)*/
  }, [props.mwsSoftPhoneDial])

  useEffect(() => {
    if (
      props.mws_leadId_diaryId !== undefined &&
      props.mws_leadId_diaryId.leadId !== undefined &&
      props.mws_leadId_diaryId.leadId !== ''
    ) {
      updateLiveCallToLocalStorage('leadId', props.mws_leadId_diaryId.leadId)
      updateLiveCallToLocalStorage('diaryId', props.mws_leadId_diaryId.diaryId)
      updateLiveCallToLocalStorage('isLead', true)
      setDiaryId(props.mws_leadId_diaryId.diaryId)
      setLeadId(props.mws_leadId_diaryId.leadId)
    }
  }, [props.mws_leadId_diaryId])
  const isMobileValid = (number) => {
    return validateNumber(number)
    //return /^03\d{3}\d{6}$/.test(number);
  }

  const setOnlineAgentStatus = (agentUser, agentDisplayName, status, customerPhone, socket) => {
    const userId = Number(localStorage.getItem('user_id'))
    const agentName = localStorage.getItem('agentName')
    const socketData = {
      agentId: agentUser,
      agentName: agentName,
      agentStatus: 'Connected',
      customerPhone: customerPhone,
      isOutGoing: callAlert.direction,
      status: status /* online , on_call,busy */,
      isLogin: false,
      agentArmId: userId,
    }
    try {
      socket.emit('setAgentsOnline', JSON.stringify(socketData))
    } catch (e) {}
    onlineAgent(socketData).then((agents) => {
      if (agents.success) {
        props.updateAgentsStats(agents.data)
      }
    })
  }
  const dialPhoneNumberHandleEnter = (event) => {
    if (event.key === 'Enter') {
      callPhoneNumber(dialPhoneNumber).then((re) => {})
    }
  }

  const callDurationHandle = (totalSeconds) => {
    updateLiveCallToLocalStorage('callDuration', totalSeconds)
  }

  useEffect(() => {
    /*  navigator.mediaDevices
      .enumerateDevices()
      .then(getMicoPhoneDevices)
      .then(startAudio)
      .catch(handleError);*/
  }, [])

  const getMicoPhoneDevices = (deviceInfos) => {
    deviceInfos.map((audio, index) => {
      if (audio.kind === 'audioinput') {
        setAudioInput((audioInputs) => [
          ...audioInputs,
          { deviceId: audio.deviceId, deviceName: audio.label },
        ])
      }
    })
  }

  const InputAudioHandle = (e) => {
    startAudio(e.target.value)
  }
  const handleError = (error) => {
    /* console.log(
      "navigator.MediaDevices.getUserMedia error: ",
      error.message,
      error.name
    ); */
  }

  const startAudio = (audio = '') => {
    /*let stream ;
    // Second call to getUserMedia() with changed device may cause error, so we need to release stream before changing device
    if (window.stream) {
      //stream.getAudioTracks()[0].stop();
    }*/
    if (audioInputs.length > 0) {
      let audioSource
      if (audio) {
        audioSource = audio
      } else {
        audioSource = audioInputs[0].deviceId
      }
      const constraints = {
        audio: { deviceId: audioSource ? { exact: audioSource } : undefined },
      }

      /* navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {})
        .catch(handleError); */
    }
  }

  useEffect(() => {
    if (sipDial) {
      sipDial.unregister()
    }
    console.log('unregister user', props.user_sip_logout)
  }, [props.user_sip_logout])
  const isOpenBarChartHandler = () => {
    setIsOpenBarChartsModel(!isOpenBarChartsModel)
  }
  const closeOpenModalState = () => {
    setIsOpenMissCall(!isOpenMissCall)
  }

  return (
    <>
      <div className={'phone'}>
        <audio
          id={'audioOnline'}
          preload="auto"
          width={'100%'}
          height={'100px'}
          style={{ width: '100%', height: '100px' }}
          ref={phoneAudioRef}
        ></audio>

        <audio preload="auto" ref={ringer}>
          <source src={ringing} type="audio/mpeg" />
        </audio>
        <div className="call-status" style={{ lineHeight: '34px' }}>
          <span>
            <div>
              <div
                style={{
                  display: 'inline-block',
                  width: '50%',
                  fontSize: '12px',
                }}
              >
                User: <b>{agentDisplayName}</b>
              </div>
              <div
                style={{
                  display: 'inline-block',
                  textAlign: 'right',
                  width: '50%',
                  fontSize: '12px',
                }}
              >
                {' '}
                Status: <b>{userRegister.message}</b>
              </div>
            </div>
            {leadId && (
              <div>
                <div
                  style={{
                    display: 'inline-block',
                    width: '50%',
                    fontSize: '12px',
                  }}
                >
                  Lead ID # : <b>{leadId ? leadId : 'N/A'}</b>
                </div>
                <div
                  style={{
                    display: 'inline-block',
                    textAlign: 'right',
                    width: '50%',
                    fontSize: '12px',
                  }}
                >
                  {' '}
                  Diary Id : <b>{diaryId ? diaryId : 'N/A'}</b>
                </div>
              </div>
            )}
            {queueId && (
              <div>
                <div
                  style={{
                    display: 'inline-block',
                    width: '50%',
                    fontSize: '12px',
                  }}
                >
                  Queue # : <b>{queueId ? queueId : 'N/A'}</b>
                </div>
                <div
                  style={{
                    display: 'inline-block',
                    textAlign: 'right',
                    width: '50%',
                    fontSize: '12px',
                  }}
                >
                  {' '}
                  Queue Name : <b>{queueName ? queueName : 'N/A'}</b>
                </div>
              </div>
            )}

            {(!userRegister.register || !props.isSocketConnected) && (
              <span className={'register-sip'} style={{ paddingLeft: '6px' }}>
                <button
                  disabled={isConnecting}
                  onClick={() => {
                    setIsConnecting(true)
                    props.sipReload(false)
                  }}
                  style={{ height: '32px' }}
                  type={'button'}
                  className={'btn btn-success btn-sm'}
                >
                  {isConnecting ? 'Please wait ...' : 'Connect'}
                </button>
              </span>
            )}
          </span>
        </div>
        <div className="call">
          {callAlert.phoneNumber && (
            <div className="calling-info">
              {callAlert.status !== 'connected' && (
                <label>
                  {callAlert.direction === 'incoming' ? 'Incoming Call' : 'Dialing ...'}{' '}
                </label>
              )}
              <b>{customerName}</b>
              <span>
                {' '}
                <b>{callAlert.phoneNumber}</b>
              </span>
              {callAlert.status === 'connected' && (
                <label>
                  {' '}
                  Duration : {!isDurationHide && (
                    <CallDuration callDuration={callDurationHandle} />
                  )}{' '}
                </label>
              )}
            </div>
          )}
          <div className="btn-block">
            {callAlert.direction === 'incoming' && callAlert.status === 'ringing' && (
              <div className="tooltipMain" style={{ marginTop: '80px' }}>
                <span tooltip="Accept Call">
                  <button className="btns btn-accept" onClick={acceptHandle} type={'button'}>
                    <img src={incoming_call} alt="" />
                  </button>
                </span>
              </div>
            )}
            {callAlert.status && callAlert.status !== 'connected' && (
              <div className="tooltipMain" style={{ marginTop: '80px' }}>
                <span tooltip="Hang Up Call">
                  <button className="btns btn-reject" onClick={rejectHandle} type={'button'}>
                    <img src={hangup_call} alt="" />
                  </button>
                </span>
              </div>
            )}
          </div>
          {/*<button type={'button'} onClick={() => {   sdsdds
            props.ClientLoadHandle('03159322282').then((res) => {
              setIsClientDetail(false)
            })
          }}>show
          </button>*/}
          <div style={{ display: 'block', padding: 30 }}></div>
          {callAlert.status === 'connected' && (
            <div className={'btn-block call-hold'}>
              <div className="tooltipMain">
                <span tooltip="customer info">
                  <button
                    disabled={isClientDetail}
                    className="btns btn-accept"
                    type={'button'}
                    onClick={() => {
                      if (callAlert.phoneNumber.length > 6) {
                        setIsClientDetail(true)
                        props.ClientLoadHandle(callAlert.phoneNumber).then((res) => {
                          setIsClientDetail(false)
                        })
                      }
                    }}
                  >
                    <img src={customer_call} alt="" />
                  </button>
                </span>
              </div>

              {callAlert.isHold ? (
                <div className="tooltipMain">
                  <span tooltip="Unhold Call">
                    <button
                      disabled={!callAlert.isHold}
                      className="btns btn-accept"
                      type={'button'}
                      onClick={(e) => {
                        setCallAlert((callAlert) => ({
                          ...callAlert,
                          isHold: false,
                        }))
                        activeCall.unhold()
                      }}
                    >
                      <img src={unhold_call} alt="" />
                    </button>{' '}
                  </span>
                </div>
              ) : (
                <div className="tooltipMain">
                  <span tooltip="Hold Call">
                    <button
                      disabled={callAlert.isHold}
                      className="btns btn-reject"
                      type={'button'}
                      onClick={(e) => {
                        setCallAlert((callAlert) => ({
                          ...callAlert,
                          isHold: true,
                        }))
                        activeCall.hold()
                      }}
                    >
                      <img src={hold_call} alt="" />
                    </button>
                  </span>
                </div>
              )}
              {callAlert.status && (
                <div className="tooltipMain">
                  <span tooltip="Hang Up Call">
                    <button className="btns btn-reject" onClick={rejectHandle} type={'button'}>
                      <img src={hangup_call} alt="" />
                    </button>
                  </span>
                </div>
              )}
              <div className="tooltipMain">
                <span tooltip="Transfer Call">
                  <button
                    disabled={onlineAgents.length === 0 || callAlert.direction === 'outgoing'}
                    className="btns btn-reject"
                    type={'button'}
                    onClick={(e) => {
                      setTransferAgent(!transferAgent)
                      props.refreshAgentLists()
                    }}
                  >
                    <img src={transfer_call} alt="" />
                  </button>
                </span>
              </div>
            </div>
          )}

          {Object.keys(callAlert).length === 0 && (
            <>
              {userRegister.message === 'registered' && is_outgoing_allowed && (
                <div className={'dialNumber'}>
                  <input
                    onKeyDown={dialPhoneNumberHandleEnter}
                    className="input-dial"
                    value={dialPhoneNumber}
                    type="number"
                    onChange={(e) => {
                      setDialPhoneNumber(e.target.value)
                    }}
                  />
                  <button
                    ref={dialPhoneNumberRef}
                    className="btn btn-dial"
                    disabled={callAlert.register || dialPhoneNumber.length === 0}
                    onClick={dialPhoneNumberHandle}
                    type={'button'}
                  >
                    Dial
                  </button>
                </div>
              )}
              {!is_outgoing_allowed && (
                <div
                  style={{
                    display: 'inline-block',
                    width: '80%',
                    fontSize: '12px',
                  }}
                >
                  Outbound Calls Are not Allowed
                </div>
              )}
            </>
          )}
          <div style={{ display: 'none' }} className="inputDevices pt-2">
            <select onChange={InputAudioHandle} className="custom-select">
              {audioInputs.map((device, i) => {
                return (
                  <option key={i} value={device.deviceId}>
                    {device.deviceName}
                  </option>
                )
              })}
            </select>
          </div>
          {callAlert.status === 'connected' && transferAgent && (
            <TransferAgentComponent
              onlineAgents={onlineAgents}
              closeTransferAgent={(flag) => {
                setTransferAgent(flag)
              }}
              transferCallHandle={transferCallHandle}
            />
          )}

          {/* <div>{JSON.stringify(callAlert)}</div>
          <div>{JSON.stringify(props.mwsSoftPhoneDial)}</div>*/}
          <div className="call-logs pt-5">
            <h6 style={{ lineHeight: '34px' }} className="heading-lbl">
              CALL LOGS
              <div
                style={{ float: 'right' }}
                className="btn-group"
                role="group"
                aria-label="Basic example"
              >
                <button
                  className={'btn btn-primary btn-sm mr-2'}
                  onClick={() => {
                    setIsOpenMissCall(true)
                  }}
                >
                  <i className={'fas fa-phone'} />
                </button>
                <button
                  className={'btn btn-primary btn-sm mr-2'}
                  onClick={() => {
                    setIsOpenBarChartsModel(true)
                  }}
                >
                  <i className={'fas fa-chart-bar'} />
                </button>

                <button
                  className={'btn btn-primary btn-sm mr-1'}
                  onClick={() => {
                    setReloadLog(true)
                  }}
                >
                  <i className={'fa fa-refresh'} />
                </button>
              </div>
            </h6>
            <RecentLogs
              reloadLog={reloadLog}
              incomingIcon={incoming_call}
              outgoingIcon={outcoming_call}
              reloadLogHandle={(flag) => {
                setReloadLog(flag)
              }}
            />

            <AgentMisscall
              isOpenMissCall={isOpenMissCall}
              closeOpenModalState={closeOpenModalState}
              phoneDialCall={(phone, cdrId, time) => {
                callPhoneNumber(phone).then((re) => {
                  console.log('loaded')
                  updateLiveCallToLocalStorage('isMiscall', true)
                  updateLiveCallToLocalStorage('cdrId', cdrId)
                  updateLiveCallToLocalStorage('misscall_datetime', time)
                })
              }}
            />

            <BarCharts
              isOpenBarChartsModel={isOpenBarChartsModel}
              isOpenBarChartHandler={isOpenBarChartHandler}
            />
          </div>
        </div>
      </div>
    </>
  )
}

const mapDispatchToProps = (dispatch) => ({
  mwsSoftPhoneDialNumber: (data) => dispatch(mwsSoftPhoneDialNumber(data)),
  getSocketCall: (data) => dispatch(getSocketCall(data)),
})

const mapStateToProps = (state) => {
  return {
    mwsSoftPhoneDial: state.Diary.mwsSoftPhoneDial,
    mws_leadId_diaryId: state.Diary.mws_leadId_diaryId,
    user: state.user.userInfo,
    user_sip_logout: state.setDialerDataReducer.user_sip_logout,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Phone)
