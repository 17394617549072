/** @format */

import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import Button from '../../common/Button'

const CloseLostWarningModal = ({ confirmationModal, toggleConfirmationModal, closeAsLostFun }) => {
  return (
    <Modal
      isOpen={confirmationModal}
      toggle={toggleConfirmationModal}
      dialogClassName={`modal-main-legal`}
    >
      <ModalHeader>Confirmation Lead Closing</ModalHeader>
      <ModalBody>
        <p>Lead will be closed as lost with this action. Are you sure you want to continue?</p>
      </ModalBody>
      <ModalFooter>
        <Button
          text={'No'}
          textColor={'white'}
          display={'inline-block'}
          buttonColor="#026ff2"
          className={'px-4 mr-2 bg-secondary'}
          onClick={() => {
            toggleConfirmationModal(false)
          }}
        />
        <Button
          text={'Yes'}
          textColor={'white'}
          display={'inline-block'}
          // buttonColor="#026ff2"
          className={'px-4 mr-2 bg-success text-white'}
          onClick={() => {
            closeAsLostFun()
          }}
        />
      </ModalFooter>
    </Modal>
  )
}
export default CloseLostWarningModal
