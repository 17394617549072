/** @format */

import React from 'react'
import ReadOnly from '../../images/readOnly.svg'
import './style.scss'

const ReadOnlyAlert = (props) => {
  const { closeAlert, heading, info } = props
  return (
    <div className="read__only">
      <img src={ReadOnly} />
      <b>{heading}</b>
      {info}
      {/* <i
                className="fal fa-times-circle"
                onClick={() => closeAlert()}
            ></i> */}
    </div>
  )
}

export default ReadOnlyAlert

ReadOnlyAlert.defaultProps = {
  heading: 'You are in read-only view!',
  info: ` You can't make changes to lead, because lead is not assigned to you or already closed`,
}
