/** @format */

import React, { useEffect, useState, useMemo } from 'react'
import axios from 'axios'
import config from '../../../config'
//import Pagination from '../UnitBookingForm/Inventory/Pagination'
import { withCookies } from 'react-cookie'
import { setTitle } from '../../../actions/generalSetting'
import { connect } from 'react-redux'
import Pagination from 'rc-pagination'
import local from '../../../local/en_US'
import 'rc-pagination/assets/index.css'
import './ccmc.scss'

import { withRouter } from 'react-router-dom'
import { Dropdown, DropdownMenu, DropdownToggle, Modal, ModalBody } from 'reactstrap'
import moment from 'moment'
import { showToastMsg } from '../../../utils/helper'
import { useRef } from 'react'
import { capitalize_Words } from '../../../utils/commonFormat'
import PaymentMethods from '../../../utils/paymentMethods'
import Select from 'react-select'
import { DateRangePicker } from 'react-dates'
import { set } from 'lodash'

const summaryView = (props) => {
  const [loader, setLoader] = useState(false)
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [total, setTotal] = useState(0)
  const [reportData, setReportData] = useState([])
  const [roleName, setRoleName] = useState({ value: '1', label: 'Regional Head' })
  const [orgName, setOrgName] = useState({ value: null, label: 'All' })
  const [filters, setFilters] = useState({
    fromDate: moment().startOf('month'),
    toDate: moment().endOf('month'),
  })
  const [focusedInput, setFocusedInput] = useState(null)

  const changeTitle = () => {
    let t = 'CCMC Report'
    document.title = t
    props.setTitle({ name: t })
  }

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    setTimeout(() => {
      changeTitle()
    }, 300)
  }, [window.location.href])

  const getData = async () => {
    setLoader(true)

    await axios
      .get(
        `${config.apiPath}/api/reporting/projectSalesSummary?roleName=${
          roleName.value
        }&organization=${orgName.value}&page=${page}&pageSize=${config.pageSize}&startDate=${moment(
          filters.fromDate
        ).format('YYYY-MM-DD')}&endDate=${moment(filters.toDate).format('YYYY-MM-DD')}&teamId=${
          filters.teamId && filters.teamId.value
        }`
      )
      .then((res) => {
        setReportData(res.data)
        setTotal(res.data.length)
      })
    setLoader(false)
  }

  const clearFilter = async () => {
    setLoader(true)
    setFilters({ fromDate: moment().startOf('month'), toDate: moment().endOf('month') })
    setRoleName({ value: '1', label: 'Regional Head' })
    setOrgName({ value: null, label: 'All' })
    await axios
      .get(
        `${config.apiPath}/api/reporting/projectSalesSummary?page=${page}&pageSize=${
          config.pageSize
        }&roleName=1&organization=null&startDate=${moment()
          .startOf('month')
          .format('YYYY-MM-DD')}&endDate=${moment().endOf('month').format('YYYY-MM-DD')}`
      )
      .then((res) => {
        setReportData(res.data)
        setTotal(res.data.length)
      })
    setLoader(false)
  }

  const static_td = [
    { value: 'C', label: 'Calls', target: 'targetCalls', total: 'totalCalls' },
    { value: 'C', label: 'Clients Register', target: 'targetCRs', total: 'totalCRs' },
    { value: 'M', label: 'Meetings', target: 'targetMeetings', total: 'totalMeetings' },
    { value: 'C', label: 'Conversions', target: 'targetConversions', total: 'totalConversions' },
  ]

  const roleOptions = [
    { value: '1', label: 'Regional Head' },
    { value: '2', label: 'Zonal Manager' },
    { value: '3', label: 'Area Manager' },
  ]

  const orgOptions = [
    { value: null, label: 'All' },
    { value: '2', label: 'Graana' },
    { value: '1', label: 'Agency 21' },
  ]

  return (
    <>
      <div className="card mt-2">
        <div style={{ padding: '1%', height: '85vh' }}>
          <div className="ccmc-filters row">
            <div className="col-md-8 row">
              <div className="col-md-4 ccmcDateRange">
                <DateRangePicker
                  isOutsideRange={() => false}
                  startDate={filters.fromDate}
                  endDate={filters.toDate}
                  startDateId="buyrent_date_input_start"
                  minimumNights={1}
                  endDateId="buyrent_date_input_end"
                  className="activeDatePicker"
                  onDatesChange={({ startDate: dateStart, endDate: dateEnd }) => {
                    if (dateStart) {
                      setFilters((filters) => ({
                        ...filters,
                        fromDate: dateStart,
                      }))
                    }
                    if (dateEnd) {
                      setFilters((filters) => ({
                        ...filters,
                        toDate: dateEnd,
                      }))
                    }
                  }}
                  focusedInput={focusedInput}
                  onFocusChange={(input) => setFocusedInput(input)}
                  showClearDates
                  noBorder={false}
                />
              </div>
              <div className="col-md-4">
                <Select
                  name="role"
                  placeholder="Select Role"
                  value={roleName}
                  options={roleOptions}
                  noOptionsMessage={() => 'No Options'}
                  onChange={(value) => {
                    setRoleName(value)
                  }}
                />
              </div>
              <div className="col-md-4">
                <Select
                  name="role"
                  placeholder="Select Role"
                  value={orgName}
                  options={orgOptions}
                  noOptionsMessage={() => 'No Options'}
                  onChange={(value) => {
                    setOrgName(value)
                  }}
                />
              </div>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-3 row">
              <div className="col-md-6"></div>
              <div className="col-md-6">
                <div className="ccmc-clearFilter">
                  <button className={`newFormSearch`} onClick={() => getData()}>
                    <span className={`fa fa-search`}></span>
                  </button>
                  <button className={`newFormClear`} onClick={clearFilter}>
                    Clear
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="summary-table">
            <table style={{ height: 'fit-content' }}>
              <div>
                <thead>
                <tr>
                  <th style={{ minWidth: '21vw', colSpan: '3' }}>Revenue Target: {reportData.targetConversions}</th>
                  <th style={{ minWidth: '14vw', colSpan: '2' }}>Target Achieved</th>
                </tr>
                </thead>
              </div>
              <tbody>
              {loader ? (
                <tr>
                  <p
                    style={{ paddingLeft: '16vw', paddingTop: '10vh', paddingBottom: '10vh' }}
                    colSpan={10}
                    className={''}
                  >
                    Loading ...
                  </p>
                </tr>
              ) : (
                static_td.map((e, idx) => {
                  return <TableTr staticData={e} data={reportData} index={idx + 1} />
                })
              )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}

const TableTr = ({ staticData, data, index }) => {
  const calcPercentage = (val1, val2) => {
    if(val1 && !val2) {
      return 100
    }
    if (!val1) {
      return 0
    }
    if (!val2) {
      return 0
    }
    return ((parseInt(val1) / parseInt(val2)) * 100).toFixed(1)
  }
  return (
    <tr>
      <td className="idx-td" style={{ minWidth: '4vw' }}>
        {staticData.value}
      </td>
      <td className="idx-td-dull" style={{ minWidth: '4vw' }}>
        {data[staticData.target]}
      </td>
      <td className="td-border td-name" style={{ minWidth: '11vw' }}>
        {staticData.label}
      </td>
      <td
        className={
          calcPercentage(data[staticData.total], data[staticData.target]) < 50
            ? 'red-percent'
            : calcPercentage(data[staticData.total], data[staticData.target]) < 70
              ? 'yellow-percent'
              : 'green-percent'
        }
        style={{ minWidth: '4vw' }}
      >
        {data[staticData.total]}
      </td>
      <td
        className={
          calcPercentage(data[staticData.total], data[staticData.target]) < 50
            ? 'red-percent'
            : calcPercentage(data[staticData.total], data[staticData.target]) < 70
              ? 'yellow-percent'
              : 'green-percent'
        }
        style={{ minWidth: '9vw' }}
      >
        {calcPercentage(data[staticData.total], data[staticData.target])}%
      </td>
    </tr>
  )
}

export const mapDispatchToProps = (dispatch) => {
  return {
    setTitle: (title) => dispatch(setTitle(title)),
    //    fetchAllProjects: () => dispatch(fetchAllProjectsNew({ offset: 0 })),
  }
}
export const mapStateToProps = (state, ownProps) => {
  return {
    projects: state.Projects.allProjects,
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withCookies(summaryView)))
