import React from "react";
import moment from 'moment'
import './style.scss'

const TimelineTile = (props) => {
    const { data } = props
    //Check For User Name
    let checkForArmsUser = data.armsuser && data.armsuser != null && data.armsUserId != null ? true : false
    let checkForUser = data.user && data.user != null ? true : false
    let name = checkForArmsUser === true ? data.armsuser.firstName + ' ' + data.armsuser.lastName : checkForUser && data.user.firstName !=  null ? data.user.firstName + ' ' + data.user.lastName : '--'
    
    // Cherck For Statuses
    let oldStatus = data && data.entity ? JSON.parse(data.previousValue) : { status: '--' }
    let newStatus = data && data.entity ? JSON.parse(data.newValue) : { status: '--' }
    oldStatus = oldStatus.status ? oldStatus.status : oldStatus.response != null ? oldStatus.response : '--'
    console.log(newStatus)
    newStatus = newStatus.status ? newStatus.status : newStatus.response != null ? newStatus.response : '--'

    // More Columns
    let comments = data.type === 'comment' ? data.value : '--'
    let checkingTaskTypeExist = data.taskType ? true : false
    let taskType = checkingTaskTypeExist === true ? data.taskType : '--'
    let subject = checkingTaskTypeExist === true ? data.subject : '--'
    let attachment = data.type === 'attachment' ? <a target='_blank' href={data.value}>View Attachment</a> : '--'

    return (
        <tr>
            <td class="max-table-width  text-capitalize">{data.start != null ? moment(data.start).format('DD-MM-YYYY (hh:mm A)') : '--'}</td>
            <td class="max-table-width  text-capitalize">{name}</td>
            <td class="max-table-width  text-capitalize">{oldStatus}</td>
            <td class="max-table-width  text-capitalize">{newStatus}</td>
            <td class="max-table-width  text-capitalize">{taskType}</td>
            <td class="max-table-width  text-capitalize">{subject}</td>
            <td class="max-table-width  text-capitalize">{comments}</td>
            <td class="max-table-width  text-capitalize">{attachment}</td>
        </tr>
    );
};

export default TimelineTile