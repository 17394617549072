import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'

const AfterCallActionModal = (props) => {

  return (
    <>
    <Modal
      isOpen={props.afterCallActionModal}
      toggle={(toggle) => props.setAfterCallActionModal(!toggle, 'update')}
      contentClassName="modal-action-size"
      className="modal-action-size after-call-action-modal"
    >
      <ModalBody>

        <div className="close-icon-container">
          <i
            className="fal fa-times-circle close-icon"
            onClick={() => {
              props.setHideFeedbackModal(false)
              props.setShowFeedbackModal(false)
              props.setAfterCallActionModal(false, 'update')
            }}
          ></i>
        </div>
        <div className={"row mt-3 justify-content-center"}>
          <div className={"col-sm-11"}>
            <h4>What would you like to do next ?</h4>
          </div>
        </div>

        <div className={"row mt-3 justify-content-center mb-4"}>
          <div className={"col-sm-11"}>
            <div
              className="task-button btn-sm"
              onClick={() => {
                props.setHideFeedbackModal(false)
                props.callToggle()
                props.setShowFeedbackModal(false)
                props.setAfterCallActionModal(false)
              }}
            >
              Call Again
            </div>
            <div
              className="task-button btn-sm"
              onClick={() => {
                props.shouldCloseAfterAdd(true)
                props.setHideFeedbackModal(false)
                props.meetingToggle()
                props.setShowFeedbackModal(false)
                props.setAfterCallActionModal(false)
              }}
            >
              Setup Meeting
            </div>
            <div
              className="task-button btn-sm followup"
              onClick={() => {
                props.shouldCloseAfterAdd(true)
                props.setHideFeedbackModal(false)
                props.setFollowupModalFlag(true)
                props.setShowFeedbackModal(false)
                props.setAfterCallActionModal(false)
              }}
            >
              Follow Up
            </div>
            <div
              className="task-button btn-sm reject"
              onClick={() => {
                props.setHideFeedbackModal(false)
                props.setCloseLostToggleState()
                props.setShowFeedbackModal(false)
                props.setAfterCallActionModal(false)
              }}
            >
              RWR
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>

    </>
  )
}
export default AfterCallActionModal
