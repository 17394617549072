/** @format */

import React, { useState } from 'react'
import { Modal, ModalBody } from 'reactstrap'
import SlotManager from './SlotManager'
import Button from '../common/Button'
import SlotDetails from './SlotDetails'
import Swal from 'sweetalert2'

const SlotModal = (props) => {
  const [selectedSlots, setSelectedSlots] = useState(null)
  const [showDetails, setShowDetails] = useState(false)
  return (
    <>
      <Modal
        isOpen={props.slotModal}
        toggle={(toggle) => props.setSlotModal(!toggle)}
        contentClassName="setup-followup-modal ml-0"
        style={{ maxWidth: 890, width: '100%' }}
        className="setup-followup-modal"
      >
        <ModalBody>
          <h4>{props.title}</h4>
          <div className="close-icon-container">
            <i
              className="fal fa-times-circle close-icon"
              onClick={() => props.setSlotModal(false)}
            ></i>
          </div>
          <div className={'row mt-3 mb-4'} style={{ margin: 'auto' }}>
            <SlotManager
              userId={props.userId ? props.userId : null}
              setSelectedSlots={(start, end, slotsArr, hasCredit, diary) => {
                if (start && end && slotsArr && slotsArr.length > 0)
                  setSelectedSlots({
                    startTime: start,
                    endTime: end,
                    slots: slotsArr,
                    hasTimeCredit: hasCredit,
                    allDiaries: diary,
                  })
                else setSelectedSlots(null)
              }}
              taskType={props.taskType}
            ></SlotManager>
          </div>
          <div className="btn-sm d-flex justify-content-end mt-3 mb-4">
            <Button
              text={'Show Details'}
              buttonColor={'white'}
              textColor={'#1372ef'}
              style={{
                border: '1px solid #1372ef',
              }}
              disabled={selectedSlots === null}
              className={'px-3 mr-2'}
              onClick={() => setShowDetails(true)}
            />
            <Button
              text={'Save'}
              textColor={'white'}
              buttonColor={'#1372ef'}
              className={'px-3'}
              disabled={selectedSlots === null}
              onClick={() => {
                if (selectedSlots.hasTimeCredit) {
                  selectedSlots && props.callBack(selectedSlots)
                } else {
                  Swal.fire('Slots Limit exceed', '40% of slot should remain empty')
                }
              }}
            />
          </div>
        </ModalBody>
      </Modal>
      {showDetails && (
        <SlotDetails
          userId={props.userId}
          isOpen={showDetails}
          toggle={() => setShowDetails(!showDetails)}
          title={'Details'}
          selectedSlots={selectedSlots}
        ></SlotDetails>
      )}
    </>
  )
}
export default SlotModal
