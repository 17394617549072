import { createFormValidation } from '@lemoncode/fonk';
import { iban } from '@lemoncode/fonk-iban-validator';

// this is use for check alphanumeric
export const validIban = (value) => {
 
    var regEx = /^[0-9a-zA-Z]+$/;
    if(value && value.match(regEx) && value.length === 24)
      {
       return true;
      }
    else
      {
      return false;
      }
}

export const validateIBAN = (ibanNumber) => {
  const validationSchema = {
    field: {
      account: [iban.validator],
    },
  };
  let isValid = true;
  
  const formValidation = createFormValidation(validationSchema);

  return Promise.all([
    formValidation.validateField('account', ibanNumber),
  ]);
}

const modulo = (aNumStr, aDiv) => {
    var tmp = "";
    var i, r;
    for (i = 0; i < aNumStr.length; i++) {
        tmp += aNumStr.charAt(i);
        r = tmp % aDiv;
        tmp = r.toString();
    }
    return tmp / 1;
}
