/** @format */

import React, { useState } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import Select from 'react-select'
import Load from '../../../components/Loader'
import { normalizeCnic } from '../../../utils/formatCnic'

const BankDetailsModal = ({
  shareToggleBankState,
  bankShareToggle,
  loader,
  name,
  onChangeBank,
  BankModalToggle,
  clientsBank,
}) => {
    let [searchString, setSearchString] = useState('')

    searchString = searchString.trim().toLowerCase()
    if (searchString.length > 0) {
      clientsBank =
        clientsBank &&
        clientsBank.filter(function (i) {
          let fullName = i.firstName + ' ' + i.lastName

          return fullName.toLowerCase().match(searchString)
        })
    }

  return (
    <Modal
      isOpen={shareToggleBankState}
      toggle={bankShareToggle}
      size="lg"
      style={{ width: '100%'}}
      contentClassName="client-creation-modal-width"
    >
       {/* booking form Add bank account header */}
      <ModalHeader style={{display:"inline"}}>
        
        <div className="row ml-1 mr-3">
          <div style={{marginTop:"0.5%"}}>Select {name}</div>

    
        <div className="">
          <div className="text-right">
            <button
              className="newFormButton"
              onClick={() => {
                BankModalToggle()
              }}

              
            >
              {" "}
              + Add New {name}
            </button>
          </div>
        </div>
    

          <div className="close-icon-container">
            <i
              className="fal fa-times-circle close-icon"
              onClick={() => {
                bankShareToggle()
              }}
            ></i>
          </div>
        </div>
      </ModalHeader>



      <ModalBody>
        <>
          <div className="share-lead-container">
            {/* <div className="row">
              <div className="col-md-4">
                <div className="search-box">
                  <input
                    type="text"
                    className="search-box-style"
                    placeholder={'Search by ' + name + ' Name'}
                    onChange={(e) => setSearchString(e.target.value)}
                  />
                </div> 
              </div>
              <div className="col-md-8 text-right">
                <button
                  className="newFormButton"
                  onClick={() => {
                    BankModalToggle()
                  }}
                >
                  + Add New {name}
                </button>
              </div>
            </div> */}
          </div>
          <div className="row ">
            <div className="BankAccountAndNextOfkin-form">
              <table className="share-modal-table" id="table" style={{ width: '100%' }}>
                <thead>
                  <tr>
                    <th style={{ minWidth: 250, maxWidth: 250 }}>Account Title</th>
                    <th style={{ minWidth: 200, maxWidth: 200 }}>Bank Name</th>
                    <th style={{ minWidth: 165, maxWidth: 165 }}>IBAN</th>
                    <th style={{ minWidth: 120, maxWidth: 120 }}>Action</th>
                  </tr>
                </thead>
                {loader ? (
                  <Load title="Banks" />
                ) : clientsBank && clientsBank && clientsBank.length === 0 || clientsBank === undefined? (
                  <div style={{ marginTop: '60px', marginBottom: 50 }} className="text-center">
                    <h3 className="dimgray">No Bank Accounts Found</h3>
                  </div>
                ) : (
                  <tbody>
                    {clientsBank &&
                      clientsBank.map((item) => (
                        <tr>
                          <td style={{ minWidth: 250, maxWidth: 250 }}>{item.accountTitle}</td>
                          <td style={{ minWidth: 200, maxWidth: 200 }}>
                            {item.bankName}
                          </td>
                          <td style={{ minWidth: 165, maxWidth: 165 }}>
                            {item.IBAN}
                          </td>
                          <td style={{ minWidth: 120, maxWidth: 120 }}>
                            <button
                              className="link-style mr-2 ml-2 btn-hollow-activate"
                              onClick={() => {
                                onChangeBank(item)
                              }}
                            >
                              Select
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </>
      </ModalBody>
    </Modal>
  )
}

export default BankDetailsModal
