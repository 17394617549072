import React from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from 'reactstrap'
import './style.scss'

const LeadReopen = (props) => {
    const {
        reOpenModal,
        reOpenToggle,
        selectedLeads,
        reOpen,
    } = props
    return (
        <Modal isOpen={reOpenModal} toggle={reOpenToggle} className="delete-popup">
            <ModalHeader>Re-Open Leads?</ModalHeader>
            <ModalBody>
                <p>
                    {(selectedLeads && selectedLeads.length === 1) ||
                        (selectedLeads && selectedLeads.length === 0)
                        ? 'Are you sure you want to re-open?'
                        : `Are you sure you want to re-open these ${selectedLeads && selectedLeads.length
                        } leads?`}
                </p>
            </ModalBody>
            <ModalFooter>
                <Button
                    color="secondary"
                    onClick={() => {
                        reOpenToggle()
                    }}
                >
                    Cancel
                </Button>
                <Button
                    color="success"
                    onClick={() => {
                        reOpen()
                    }}
                >
                    Re-Open
                </Button>
            </ModalFooter>
        </Modal>
    )

}

export default LeadReopen;