/** @format */

import React, { useEffect, useState, useMemo } from 'react'
import axios from 'axios'
import config from '../../../config'
//import Pagination from '../UnitBookingForm/Inventory/Pagination'
import { withCookies } from 'react-cookie'
import { setTitle } from '../../../actions/generalSetting'
import { connect } from 'react-redux'
import Pagination from 'rc-pagination'
import local from '../../../local/en_US'
import 'rc-pagination/assets/index.css'
import './ccmc.scss'

import { withRouter } from 'react-router-dom'
import { Dropdown, DropdownMenu, DropdownToggle, Modal, ModalBody } from 'reactstrap'
import moment from 'moment'
import { showToastMsg } from '../../../utils/helper'
import { useRef } from 'react'
import { capitalize_Words } from '../../../utils/commonFormat'
import PaymentMethods from '../../../utils/paymentMethods'
import Select from 'react-select'
import { DateRangePicker } from 'react-dates'
import { set } from 'lodash'
import { useDownloadExcel } from 'react-export-table-to-excel';

const calcPercentage = (val1, val2, isgraph = false) => {
  if (val1 && !val2) {
    if (isgraph) {
      return 0
    } else {
      return -1
    }
  }
  if (!val1) {
    return 0
  }
  if (!val2) {
    return 0
  }
  if (isgraph) {
    return parseInt(
      ((parseInt(val1) / parseInt(val2)) * 100).toFixed(1) < 100
        ? ((parseInt(val1) / parseInt(val2)) * 100).toFixed(1)
        : 100
    )
  }
  return ((parseInt(val1) / parseInt(val2)) * 100).toFixed(1)
}

const CompactView = (props) => {
  const [loader, setLoader] = useState(false)
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [total, setTotal] = useState(0)
  const [reportData, setReportData] = useState([])
  const [filters, setFilters] = useState({
    fromDate: moment().startOf('month'),
    toDate: moment().endOf('day'),
  })
  const [focusedInput, setFocusedInput] = useState(null)
  const [teams, setTeams] = useState([])
  const [officeLocations, setOfficeLocations] = useState([])
  let sortedBy = useRef(null)
  const ccmcRef = useRef(null);

  const changeTitle = () => {
    let t = 'CCMC Report'
    document.title = t
    props.setTitle({ name: t })
  }

  useEffect(() => {
    getData([], true)
    getTeams()
  }, [])

  const filterData = () => {
    let teamsArray = []

    if(filters.teamId) {
      teamsArray = filters.teamId.map((team) => {
        return team.value
      })
    }
    setLoader(true)
    getData(teamsArray)

  }

  useEffect(() => {
    setTimeout(() => {
      changeTitle()
    }, 300)
  }, [window.location.href])

  //  var teamsOpt = []
  const getTeams = async () => {

    await axios.get(`${config.apiPath}/api/reporting/getAllUserTeams`).then((res) => {
      let t = res.data.rows.map((item) => ({
        label: item.teamName,
        value: item.id,
      }))
      setTeams(t)
      // let officeLocations = res.data && res.data.rows && res.data.rows.map((ol) => ol.armsuser && ol.armsuser.officeLocation)
      // officeLocations = officeLocations.filter((t) => t !== null)
      // const filteredOfficeLocations = Object.values(officeLocations.reduce((a, c) => (a[`${c.id}${c.name}`] = c, a), {}));

      // let officeLocation = filteredOfficeLocations.map((ol) => ({
      //   label: ol.name,
      //   value: ol.id
      // }))

      // setOfficeLocations( officeLocation)
    })
  }

  const getData = async (teamsArray, populateFilter) => {
    setLoader(true)
    const teamId = teamsArray && teamsArray.length > 0 ? `&teamId=${teamsArray}` : ''
    const officeLocationId = filters.officeLocationId && filters.officeLocationId.value ? `&officeLocationId=${filters.officeLocationId && filters.officeLocationId.value}` : ''

    await axios
      .get(
        `${config.apiPath}/api/reporting/projectSales?page=${page}&pageSize=${
          config.pageSize
        }&startDate=${moment(filters.fromDate).format('YYYY-MM-DD')}&endDate=${moment(
          filters.toDate
        ).format('YYYY-MM-DD')}${teamId}${officeLocationId}`
      )
      .then((res) => {
        let tempData = res.data
        if(populateFilter) {
          let officeLocations = tempData.map(e => {
            if(e.officeLocation && e.officeLocation.id && e.officeLocation.name){
              return {
                value: e.officeLocation.id,
                label: e.officeLocation.name
              }
            }
          }).filter(e => !!e)
          var filteredOfficeLocations = officeLocations.filter((arr, index, self) =>
                                    index === self.findIndex((t) =>
                                      (t.value === arr.value && t.label === arr.label)))
          setOfficeLocations(filteredOfficeLocations)
        }
        sortedBy.current = {attr: "abc", desc: false}
        sortData(tempData, "totalMeetings", "targetMeetings")
        setReportData(res.data)
        setTotal(res.data.length)
      })
    setLoader(false)
  }

  const clearFilter = async () => {
    // let st = new Date(moment().startOf('month').format())
    // let end = new Date(moment().endOf('month').format())
    // setStartDate(st)
    // setEndDate(end)
    setFilters({ fromDate: moment().startOf('month'), toDate: moment().endOf('day') })
    setLoader(true)

    await axios
      .get(
        `${config.apiPath}/api/reporting/projectSales?page=${page}&pageSize=${
          config.pageSize
        }&startDate=${moment().startOf('month').format('YYYY-MM-DD')}&endDate=${moment()
          .endOf('day')
          .format('YYYY-MM-DD')}`
      )
      .then((res) => {
        sortedBy.current = { attr: 'totalMeetings', desc: false }
        sortData(res.data, 'totalMeetings', "targetMeetings")
        setTotal(res.data.length)
      })
    setLoader(false)
  }

  const sortData = (data, attr1, attr2) => {
    setLoader(true)
    let desc = sortedBy.current.desc
    if(sortedBy.current.attr == attr1){
      desc = !desc
      sortedBy.current = {...sortedBy.current, desc: desc}
    } else {
      desc = true
      sortedBy.current = { attr: attr1, desc: true}
    }
    let x = data.sort((a, b) => {
      const valA1 = desc ? a[attr1] : b[attr1]
      const valB1 = desc ? b[attr1] : a[attr1]
      const valA2 = desc ? a[attr2] : b[attr2]
      const valB2 = desc ? b[attr2] : a[attr2]
      const valA = calcPercentage(valA1, valA2)
      const valB = calcPercentage(valB1, valB2)
      if (valA && !valB) {
        return -1
      } else if (!valA && valB) {
        return 1
      } else if (!valA && !valB) {
        return 0
      }
      return valB - valA
    })
    setReportData(x)
    setTimeout(() => {
      setLoader(false)
    }, 200)
  }

  const {onDownload} =     useDownloadExcel({
    currentTableRef: ccmcRef.current,
    filename: 'CCMC Report_'+ moment().format('YYYY:MM:DD HH:mm')+':'+(new Date()).getSeconds(),
    sheet: 'ccmc'
})
  
  return (
    <>
      <div className="card mt-2">
        <div style={{ padding: '1%', height: '85vh' }}>
          <div className="ccmc-filters row">
            <div className="col-md-8 row">
              <div className="col-md-4 ccmcDateRange">
                <DateRangePicker
                  isOutsideRange={() => false}
                  startDate={filters.fromDate}
                  endDate={filters.toDate}
                  startDateId="buyrent_date_input_start"
                  minimumNights={1}
                  endDateId="buyrent_date_input_end"
                  className="activeDatePicker"
                  onDatesChange={({ startDate: dateStart, endDate: dateEnd }) => {
                    if (dateStart) {
                      setFilters((filters) => ({
                        ...filters,
                        fromDate: dateStart,
                      }))
                    }
                    if (dateEnd) {
                      setFilters((filters) => ({
                        ...filters,
                        toDate: dateEnd,
                      }))
                    }
                  }}
                  focusedInput={focusedInput}
                  onFocusChange={(input) => setFocusedInput(input)}
                  showClearDates
                  noBorder={false}
                />
              </div>
              <div className="col-md-4">
                <Select
                  name="Team"
                  placeholder="Team"
                  value={filters.teamId ? filters.teamId : ''}
                  options={teams}
                  isMulti
                  noOptionsMessage={() => 'No Options'}
                  isClearable={true}
                  onChange={(value) => {
                    setFilters({ ...filters, teamId: value })
                  }}
                />
              </div>
              <div className="col-md-4">
                <Select
                  name="Office Location"
                  placeholder="Office Location"
                  value={filters.officeLocationId ? filters.officeLocationId : ''}
                  options={officeLocations}
                  noOptionsMessage={() => 'No Options'}
                  isClearable={true}
                  onChange = {(value) => {
                    setFilters({ ...filters, officeLocationId: value })
                  }}
                />
              </div>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-3 row justify-content-end ">
              <div className="col-md-6">
                <button className="export-btn" onClick={onDownload}>
                  Export
                </button>
              </div>
              <div className="col-md-6">
                <div className="ccmc-clearFilter">
                  <button className={`newFormSearch`} onClick={filterData}>
                    <span className={`fa fa-search`}></span>
                  </button>
                  <button className={`newFormClear`} onClick={clearFilter}>
                    Clear
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="compact-table">
            <table style={{ height: '80%' }} ref={ccmcRef}>
              <div>
                <thead>
                <tr>
                  <th style={{ minWidth: '3vw' }}>Sr.</th>
                  <th style={{ minWidth: '14vw' }}>Advisor Name</th>
                  <th className="clickable-div" onClick={() => {sortData(reportData, 'totalCalls', 'targetCalls')}} style={{ minWidth: '9vw', cursor: "pointer" }}>Connect (10)<i className="fa fa-sort ml-2"></i></th>
                  <th style={{ minWidth: '4vw' }}>%</th>
                  <th className="clickable-div" onClick={() => {sortData(reportData, 'totalCRs', "targetCRs")}} style={{ minWidth: '9vw', cursor: "pointer" }}>CR (2)<i className="fa fa-sort ml-2"></i></th>
                  <th style={{ minWidth: '4vw' }}>%</th>
                  <th className="clickable-div" onClick={() => {sortData(reportData, 'totalMeetings', "targetMeetings")}} style={{ minWidth: '9vw', cursor: "pointer" }}>Meetings (1)<i className="fa fa-sort ml-2"></i></th>
                  <th style={{ minWidth: '4vw' }}>%</th>
                  <th className="clickable-div" onClick={() => {sortData(reportData, 'totalConversions', "targetConversions")}} style={{ minWidth: '9vw', cursor: "pointer" }}>Conversions<i className="fa fa-sort ml-2"></i></th>
                  <th style={{ minWidth: '4vw' }}>%</th>
                  {/*<th style={{ minWidth: '10vw' }}>Avg Response Time</th>*/}
                </tr>
                </thead>
              </div>
              <tbody>
              {loader ? (
                <tr>
                  <p
                    style={{ paddingLeft: '36vw', paddingTop: '10vh' }}
                    colSpan={10}
                    className={''}
                  >
                    Loading ...
                  </p>
                </tr>
              ) : reportData && reportData.length ? (
                reportData && reportData.map((e, idx) => {
                  return <TableTr data={e} index={idx + 1} />
                })
              ) : (
                <tr>
                  <p
                    style={{ paddingLeft: '36vw', paddingTop: '10vh' }}
                    colSpan={10}
                    className={''}
                  >
                    No records found
                  </p>
                </tr>
              )}
              </tbody>
            </table>
            <div className="row mt-3 mb-2">
              <div className="col-md-4 totalCountAlignment">
                <span className="totalCountStyle">
                  Showing
                  <span className="totalCountSubStyle">{reportData.length}</span> /{' '}
                  {reportData.length}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const TableTr = ({ data, index }) => {
  const formatNumber = (num) => {
    if (num < 10) {
      return "0"+num
    }
    else {
      return num
    }
  }
  const formatTime = (time) => {
    return Math.floor(time/24/60) + " Days, " + Math.floor(time/60%24) + ':' + Math.floor(time%60) + ':' + Math.floor((time*60)%60) ;
  }
  return (
    <tr>
      <td className="index-td" style={{ minWidth: '3vw' }}>
        {index}
      </td>
      <td className="td-border td-name" style={{ maxWidth: '14vw', minWidth: '14vw', paddingLeft: '1%' }}>
        {data.firstName} {data.lastName}
      </td>
      <td className="td-border" style={{ minWidth: '9vw' }}>
        {(!!data.totalCalls ? Number(data.totalCalls).toLocaleString() : 0)}
      </td>
      <td
        className={
          calcPercentage(data.totalCalls, data.targetCalls) < 50
            ? 'red-percent'
            : calcPercentage(data.totalCalls, data.targetCalls) < 70
              ? 'yellow-percent'
              : 'green-percent'
        }
        style={{ minWidth: '4vw' }}
      >
        {calcPercentage(data.totalCalls, data.targetCalls) === -1
          ? 'N/A'
          : calcPercentage(data.totalCalls, data.targetCalls) + `%`}
      </td>
      <td className="td-border" style={{ minWidth: '9vw' }}>
        {(!!data.totalCRs ? Number(data.totalCRs).toLocaleString() : 0)}
      </td>
      <td
        className={
          calcPercentage(data.totalCRs, data.targetCRs) < 50
            ? 'red-percent'
            : calcPercentage(data.totalCRs, data.targetCRs) < 70
              ? 'yellow-percent'
              : 'green-percent'
        }
        style={{ minWidth: '4vw' }}
      >
        {calcPercentage(data.totalCRs, data.targetCRs) === -1
          ? 'N/A'
          : calcPercentage(data.totalCRs, data.targetCRs) + `%`}
      </td>
      <td className="td-border" style={{ minWidth: '9vw' }}>
        {(!!data.totalMeetings ? Number(data.totalMeetings).toLocaleString() : 0)}
      </td>
      <td
        className={
          calcPercentage(data.totalMeetings, data.targetMeetings) < 50
            ? 'red-percent'
            : calcPercentage(data.totalMeetings, data.targetMeetings) < 70
              ? 'yellow-percent'
              : 'green-percent'
        }
        style={{ minWidth: '4vw' }}
      >
        {calcPercentage(data.totalMeetings, data.targetMeetings) === -1
          ? 'N/A'
          : calcPercentage(data.totalMeetings, data.targetMeetings) + `%`}
      </td>
      <td className="td-border" style={{ minWidth: '9vw' }}>
        {(!!data.totalConversions ? Number(data.totalConversions).toLocaleString() : 0)}
      </td>
      <td
        className={
          calcPercentage(data.totalConversions, data.targetAmount) < 50
            ? 'red-percent'
            : calcPercentage(data.totalConversions, data.targetAmount) < 70
              ? 'yellow-percent'
              : 'green-percent'
        }
        style={{ minWidth: '4vw' }}
      >
        {calcPercentage(data.totalConversions, data.targetAmount) === -1
          ? 'N/A'
          : calcPercentage(data.totalConversions, data.targetAmount) + `%`}
      </td>
      {/*<td className="td-border" style={{ minWidth: '10vw' }}>*/}
      {/*  {!!data.responseTime ? data.responseTime : '---'}*/}
      {/*</td>*/}
    </tr>
  )
}

export const mapDispatchToProps = (dispatch) => {
  return {
    setTitle: (title) => dispatch(setTitle(title)),
    //    fetchAllProjects: () => dispatch(fetchAllProjectsNew({ offset: 0 })),
  }
}
export const mapStateToProps = (state, ownProps) => {
  return {
    projects: state.Projects.allProjects,
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withCookies(CompactView)))
