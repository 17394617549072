/** @format */

import React, { useEffect, useMemo, useState } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import Button from '../../common/Button'
import Diary from '../../common/Diary/Diary'
import { getDiaryTasks } from '../../../actions/diary'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import DiaryView from '../../../views/Lead/Diary'

const DiaryModal = (props) => {
  const { fetchLeadsInModal, checkForRead, isShowDiary, checkForReadForBuyRent } = props
  useEffect(() => {
    if (fetchLeadsInModal === true) {
      let leadParam = 'projectId'
      if (props.leadType === 'BuyRent') leadParam = 'buyrentId'
      else if (props.leadType === 'Wanted') leadParam = 'wantedId'
      let userIdParam = ''
      if (props.userId) {
        userIdParam = `&userID=${props.userId}`
      }
      props.fetchDiaryTasks(
        { currentPage: 1, pageSize: props.noPaginate ? 100 : 10 },
        '',
        null,
        `${leadParam}=${props.lead.id}${userIdParam}`,
        'pending'
      )
    }
  }, [fetchLeadsInModal])

  useMemo(() => {
    if (fetchLeadsInModal === true) {
      let leadParam = 'projectId'
      if (props.leadType === 'BuyRent') leadParam = 'buyrentId'
      else if (props.leadType === 'Wanted') leadParam = 'wantedId'
      let userIdParam = ''
      if (props.userId) {
        userIdParam = `&userID=${props.userId}`
      }
      props.fetchDiaryTasks(
        { currentPage: 1, pageSize: props.noPaginate ? 100 : 10 },
        '',
        null,
        `${leadParam}=${props.lead.id}${userIdParam}`,
        'pending'
      )
    }
  }, [isShowDiary])

  return (
    <Modal
      isOpen={props.diaryModal}
      toggle={(toggle) => {
        props.setDiaryModal(!toggle)
        props.setFetchLeadsInModal(false)
      }}
      size={'xl'}
    >
      <ModalBody className={'px-3'}>
        <div className="close-icon-container">
          <i
            className="fal fa-times-circle close-icon"
            onClick={() => {
              props.setDiaryModal(false)
              props.setFetchLeadsInModal(false)
            }}
          ></i>
        </div>
        <h4>Lead Tasks</h4>
        <div style={{ minHeight: 300, maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}>
          <DiaryView
            noPaginate={true}
            checkForRead={props.checkForRead ? props.checkForRead.showReadOnly : false}
            lead={props.lead}
            leadType={props.leadType}
            screen={'lead-task'}
            userId={props.userId ? props.userId : null}
          />
        </div>

        {checkForReadForBuyRent ||
        (!checkForRead.showReadOnly && checkForRead.ownLeadReadOnly) ||
        checkForRead.airaWantedLead ||
        checkForRead.isAiraView ? (
          <>
            <div className={'d-flex mt-2 mb-2 justify-content-center'}>
              <Button
                textColor={'white'}
                buttonColor="#026ff2"
                className={'px-5 rounded-pill'}
                onClick={() => props.setLeadTaskModal(true)}
                text={'Create Lead Task'}
              />
            </div>
          </>
        ) : (
          <></>
        )}
      </ModalBody>
    </Modal>
  )
}

export const mapDispatchToProps = (dispatch) => {
  return {
    fetchDiaryTasks: (pagination, filters, isOverDue, leadParam, status) =>
      dispatch(getDiaryTasks(pagination, filters, isOverDue, leadParam, status)),
  }
}

export const mapStateToProps = (state) => {
  return {
    diaryTasks: state.Diary.diaryTasks,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DiaryModal))
