import { getPermissionValue } from '../components/_unlockComponent'
import { PermissionActions, PermissionFeatures } from './permissionConstants'
import { accountsPermissions } from './checkPermissions'

/** @format */
const { PermissionPageURL } = require('./permissionConstants')
const Swal = require('sweetalert2')
const CryptoJS = require('crypto-js')
const _ = require('lodash')

const showErrorMessage = () => {
  return Swal.fire(
    'Access is denied',
    'You do not have an access to the dashboard. Contact an administrator who has the manage user permissions',
    'error'
  ).then(() => (window.location.href = '/logout'))
}

export const userRedirect = () => {
  let permissions = localStorage.getItem('permissions')
  let bytes = CryptoJS.AES.decrypt(permissions, 'secret key 099')
  permissions = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))

  let webpages =
    permissions && permissions.armsPermission && permissions.armsPermission['Web (Pages)']

  let grantedPermission = _.filter(webpages, function (item) {
    return item.value
  })

 let checkPerm
  let financeChek = grantedPermission.filter((item) => {
    grantedPermission.filter((check)=>{
      if (item.action === 'Finance' && check.action !== "Diary") {
        checkPerm = true
      }
    })
  })
  let checkPermHQ = false
  let hQChek = grantedPermission.filter((item) => {
      if (item.action === 'Buy/Rent Payments') {
        checkPermHQ = true
      }
  })

  let defaultPermission = financeChek && grantedPermission[0]
  let redirectRoute
  if (checkPerm) {
    redirectRoute = '/in-progress-booking/InProgress'
  } else if(checkPermHQ){
        redirectRoute = PermissionPageURL[PermissionActions.BUY_RENT_PAYMENTS_PAGE_VIEW]
  } else {
    redirectRoute = !defaultPermission
      ? null
      : PermissionPageURL[defaultPermission && defaultPermission.action]
      ? PermissionPageURL[defaultPermission && defaultPermission.action]
      : '/logout'
  }

  if (redirectRoute === null) {
    showErrorMessage()
  } else {
    window.location.href = redirectRoute
  }
}
