import React, { useEffect, useState } from 'react'
import { getDiaryTasks } from '../../actions/diary'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import DiaryView from '../../views/Lead/Diary'

    const ShowDiary = (props) => {

    // useEffect(() => {
       
    //       props.fetchDiaryTasks(
    //         { currentPage: 1, pageSize: props.noPaginate ? 100 : 10 },
    //         '',
    //         null,
    //         null
            
    //       )
    //     })
   

  return (

    <DiaryView/>
  )
}

export const mapDispatchToProps = (dispatch) => {
    return {
      fetchDiaryTasks: (pagination, filters, isOverDue, leadParam, status) =>
        dispatch(getDiaryTasks(pagination, filters, isOverDue, leadParam, status)),
    }
  }
  export const mapStateToProps = (state) => {
    return {
      diaryTasks: state.Diary.diaryTasks,
    }
  }

  export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ShowDiary))