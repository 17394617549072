export const NAV_TOGGLE = 'NAV_TOGGLE';
export const MOUSE_ENTER = 'MOUSE_ENTER';
export const MOUSE_LEAVE = 'MOUSE_LEAVE';
export const SHOW_ARROW_ENTER = 'SHOW_ARROW_ENTER';
export const SHOW_ARROW_LEAVE = 'SHOW_ARROW_LEAVE';
export const SHOW_COLLAPSE_MENU = 'SHOW_COLLAPSE_MENU';
export const SHOW_COLLAPSE_CLOSE = 'SHOW_COLLAPSE_CLOSE';
export const SET_UNIT_TRUE_FLAG = 'SET_UNIT_TRUE_FLAG';
export const SET_UNIT_FALSE_FLAG = 'SET_UNIT_FALSE_FLAG';

export const UNIT_FLAG_DETECT = 'UNIT_FLAG_DETECT'


export const collapseMenu = () => ({
  type: SHOW_COLLAPSE_MENU
})
export const closeCollapse = () => ({
  type: SHOW_COLLAPSE_CLOSE
})

export const nav_toggle = () => ({
  type: NAV_TOGGLE
})
export const mouseEnter = () => ({
  type: MOUSE_ENTER
})
export const mouseLeave = () => ({
  type: MOUSE_LEAVE
})
export const showArrowEnter = () => ({
  type: SHOW_ARROW_ENTER
})
export const showArrowLeave = () => ({
  type: SHOW_ARROW_LEAVE
})
export const unitTrueFlag = () => ({
  type: SET_UNIT_TRUE_FLAG
})
export const unitFalseFlag = () => ({
  type: SET_UNIT_FALSE_FLAG
})


export const unitFloorDetect = (value) => {
  return dispatch => {
    dispatch({ type: UNIT_FLAG_DETECT, data: value })
  }
}
export const unitFloorDetectNew = (value) => {
  return dispatch => {
    dispatch({ type: UNIT_FLAG_DETECT, data: value })
  }
}