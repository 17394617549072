/** @format */
import {GLOBAL_SOCKET_CALL_CENTER, LOGOUT_SIP_USER, setDialerData} from '../actions/setDialerData'
import {SET_DIALER_DATA} from '../actions/setDialerData'
import * as ActionTypes from "../actions/diary";

const initialState = {
  agentUserId: 0,
  callCenterSocket:'',
  user_sip_logout:'',
}

const setDialerDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DIALER_DATA:
      return {
        ...state,
        agentUserId: action.payload,
      }
    case GLOBAL_SOCKET_CALL_CENTER:
      return {
        ...state,
        callCenterSocket: action.payload
      }
    case LOGOUT_SIP_USER:

      console.log(action.payload,'ddd')
      return {
        ...state,
        user_sip_logout:action.payload
      }


    default:
      return {
        ...state,
      }
  }
}

export default setDialerDataReducer
