/** @format */

import React, { useState } from 'react'
import { Card, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Tooltip } from 'reactstrap'
import moment from 'moment'
import './CardInfo.scss'
import MeetingIcon from '../../images/meeting.svg'
import TaskIcon from '../../images/task.svg'
import FollowUpIcon from '../../images/followup.svg'
import { capitalize_Words } from '../../utils/commonFormat'
import DoneIcon from '../../images/done.svg'
import { isREA } from '../../utils/helper'

export const DiaryItem = (props) => {
  const { checkForRead } = props
  const [viewingDropdown, setViewingDropdown] = useState(false)
  const [subTitleTooltip, setSubTitleTooltip] = useState(false)

  const toggle = () => setSubTitleTooltip(!subTitleTooltip)

  return (
    <div
      onClick={() => {
        if (props.feedback) {
          props.showFeedbackToggle(true)
          props.setSelectedDiary(props.item)
        }
      }}
      className={`pt-2 pb-2 diary-item ${props.className}`}
    >
      <div className={'align-items-center'}>
        <span className={'mr-2'}>
          <img
            className={'diary-icon'}
            src={
              ['meeting', 'viewing'].indexOf(props.cardType) > -1
                ? MeetingIcon
                : props.cardType === 'task'
                ? TaskIcon
                : FollowUpIcon
            }
          />
        </span>
        <span>
          {capitalize_Words(props.cardType).replace('_', ' ')}{' '}
          {props.item.status === 'completed' ? 'done' : 'scheduled'}
        </span>
        {props.date && <div className={'text-blue'}> {moment(props.date).format('LLL')}</div>}
        {props.dropdown ? (
          <Dropdown
            className={`d-inline float-right text-right dropDownForViwing`}
            style={{ paddingRight: '1px', cursor: 'pointer', top: '5px' }}
            isOpen={viewingDropdown}
            toggle={() => setViewingDropdown((viewDrop) => !viewDrop)}
          >
            {(checkForRead && !checkForRead.showReadOnly) ||
            (checkForRead && checkForRead.ownLeadReadOnly) ? (
              <DropdownToggle tag="span">
                <i
                  className="far fa-ellipsis-v text-blue pr-1 pl-3 "
                  style={{ fontSize: '22px' }}
                />
              </DropdownToggle>
            ) : null}

            {props.cardType === 'task' ? (
              <DropdownMenu className={'p-0'}>
                <div
                  className={'container_separator card-container-text'}
                  onClick={() => props.markAsDone(props.item.id)}
                >
                  Mark as done
                </div>
                <div
                  className={'container_separator card-container-text'}
                  onClick={() => props.cancelTask(props.item.id)}
                >
                  Cancel task
                </div>
              </DropdownMenu>
            ) : props.cardType === 'follow_up' ? (
              <DropdownMenu className={'p-0'}>
                <div
                  className={'container_separator card-container-text'}
                  onClick={() => props.markAsDone(props.item.id)}
                >
                  Mark as done
                </div>
                <div
                  className={'container_separator card-container-text'}
                  onClick={() => props.cancelTask(props.item.id)}
                >
                  Cancel follow up
                </div>
              </DropdownMenu>
            ) : props.cardType === 'meeting' ? (
              <DropdownMenu className={'p-0'}>
                <div
                  className={'container_separator card-container-text'}
                  onClick={() => props.doneMeeting(props.item)}
                >
                  Meeting Done
                </div>
                <div
                  className={'container_separator card-container-text'}
                  onClick={() => props.cancelMeeting(props.item.id)}
                >
                  Cancel Meeting
                </div>
              </DropdownMenu>
            ) : props.cardType === 'viewing' ? (
              <DropdownMenu className={'col-md-4 p-0'}>
                <div
                  className={'container_separator card-container-text'}
                  onClick={() => props.doneViewing()}
                >
                  Viewing done
                </div>
                {(!isREA(props.user)
                  ? true
                  : props.user && props.lead.assigned_to_armsuser_id === props.user.id) && (
                  <div
                    className={'container_separator card-container-text'}
                    onClick={() => props.cancelViewing(props.item)}
                  >
                    Cancel Viewing
                  </div>
                )}
              </DropdownMenu>
            ) : (
              <></>
            )}
          </Dropdown>
        ) : props.item.status === 'completed' ? (
          <span className={'done-meeting'}>
            <img style={{ height: 18 }} src={DoneIcon} />
          </span>
        ) : (
          <span className={'float-right'} style={{ width: 25.5, height: 1 }}></span>
        )}
        <div className="icon-position-right mt-1">
          <div className="time-main-container">
            <span className="time-main-container-text">
              {moment(props.item.start).format('dddd MMM D, LT')}
            </span>
          </div>
        </div>
      </div>
      {props.item.response && props.cardType === 'meeting' && (
        <div id={`sub_title_${props.index}`} className={'diary-feedback '}>
          <strong>Feedback: </strong> {props.item.response}
        </div>
      )}
      {props.item.customer_feedback && props.cardType === 'viewing' && (
        <div id={`sub_title_${props.index}`} className={'diary-feedback '}>
          <strong>Feedback: </strong> {props.item.customer_feedback}
        </div>
      )}
      {props.item.notes && props.cardType === 'follow_up' && (
        <div id={`sub_title_${props.index}`} className={'diary-feedback '}>
          <strong>Details: </strong> {props.item.notes}
        </div>
      )}
    </div>
  )
}
