/** @format */

import React, { Component } from 'react'
import LeadTimeLine from '../TimelineIcon'

class LeadTimelineInner extends Component {
  render() {
    const { item, user, fetchUser, checkForRead, closeAfterAdd, shouldCloseAfterAdd,fetchSingleLead } = this.props
    return (
      <>
        <LeadTimeLine
          fetchSingleLead={fetchSingleLead}
          user={user}
          fetchUser={() => fetchUser()}
          leadType={'investment'}
          timelineData={item}
          checkForRead={checkForRead}
          closeAfterAdd={closeAfterAdd}
          shouldCloseAfterAdd={toggle => shouldCloseAfterAdd(toggle)}
        />
      </>
    )
  }
}

export default LeadTimelineInner
