/** @format */

import { Link } from 'react-router-dom'
import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { connect } from 'react-redux'
import { SUB_MENUS_LABEL } from '../../../utils/constant_data'
import { getPermissionValue } from '../../_unlockComponent'
import { PermissionActions, PermissionFeatures } from '../../../utils/permissionConstants'

const useStyles = makeStyles({
  badge: {
    background: '#026ff2',
    width: '20px',
    height: '20px',
    display: 'inline-block',
    fontSize: '10px',
    color: 'white',
    textAlign: 'center',
    borderRadius: '10px',
    top: '-5px',
    right: '-1px',
    position: 'absolute',
    lineHeight: '20px',
  },
})

const MenuItem = (props) => {
  const classes = useStyles(props)

  return props.menu.hideLabel ? (
    ''
  ) : (
    <div className={`menu-item p-2 ${props.selected ? 'selected' : ''}`}>
      <Link to={props.menu.link} style={{ whiteSpace: 'nowrap' }}>
        {/* {props.preLabelTxt + props.menu.label} */}
        {props.menu.label}
      </Link>
      {props.menu.showBadge &&
        getPermissionValue(PermissionFeatures.DIARY, PermissionActions.READ) && (
          <span className={classes.badge}>{props.badgeText}</span>
        )}
    </div>
  )
}

export const mapDispatchToProps = (dispatch) => {
  return {}
}

export const mapStateToProps = (state, ownProps) => {
  // let preLabelTxt = ''
  let badgeTxt = ''
  if (
    (ownProps.menu.label === SUB_MENUS_LABEL.DIARY ||
      ownProps.menu.label === SUB_MENUS_LABEL.OVERDUE) &&
    !ownProps.main
  ) {
    if (state.Diary && ownProps.menu.showBadge && ownProps.menu.label === SUB_MENUS_LABEL.OVERDUE) {
      badgeTxt = state.Diary.overDueCount
    }
    // if (state.Diary && state.Diary.selectedAgent != null) {
    //   preLabelTxt = state.Diary.selectedAgent.firstName + "'s "
    // }
  }
  return {
    badgeText: badgeTxt,
    // ownProps.menu.showBadge && ownProps.menu.label === 'Overdue' ? state.Diary.overDueCount : '',
    // preLabelTxt: preLabelTxt,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuItem)
// export default MenuItem
