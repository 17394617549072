/** @format */

import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { setTitle } from '../../actions/generalSetting'
import { fetchUserClients, addLead } from '../../actions/leads'
import { fetchAllProjects } from '../../actions/projects'
import { fetchCities } from '../../actions/user'
import LeadCreationInner from './leadCreationInner'
import ClientCreationModal from '../../components/Modals/ClientCreationModal'
import AddClientModal from '../AddClient'
import axios from 'axios'
import config from '../../config'
import './leadCreation.scss'
import { useState } from 'react'
import { showToastMsg } from '../../utils/helper'

const LeadCreation = ({
  setTitle,
  fetchUserClients,
  userClients,
  fetchCities,
  cities,
  allProjects,
  fetchAllProjects,
  addLeadLoader,
  addLeadFunc,
  history,
  location,
  editable,
  lead,
  callBack,
  closeModal,
  selectedCustomerDetail,
  dialerLeadInformation,
}) => {
  const [selectedClient, setselectedClient] = useState(null)
  const [shareToggleState, setShareToggleState] = useState(false)
  const [forBooking, setForBooking] = useState(false)
  const [activeClientModal, setactiveClientModal] = useState(false)
  const [name, setName] = useState('')
  const [errorMessage, setErrorMessage] = useState({
    client: false,
    city: false,
  })

  useEffect(() => {
    setselectedClient(lead && lead.customer)
    if (location && location.state) {
      location.state && location.state.customer && setselectedClient(location.state.customer)
      location.state && location.state.booking && setForBooking(true)
    }

    if (selectedCustomerDetail) {
      setselectedClient(selectedCustomerDetail)
    }
    fetchUserClients()
    fetchCities()
    fetchAllProjects()
    if (editable !== true) {
      setTitle({ name: 'Add Investment Lead' })
    }
  }, [])

  useEffect(() => {
    if (lead) setselectedClient(lead.customer)
  }, [lead])

  const onchangeClient = (value) => {
    setselectedClient(value)
    shareToggle()
  }
  const shareToggle = () => {
    setShareToggleState(!shareToggleState)
  }

  const apiCallDonClientCreated = (newClient) => {
    if (newClient) {
      onchangeClient(newClient)
      setactiveClientModal(false)
      setShareToggleState(false)
    }
  }

  const clientModalToggle = (newClient) => {
    setactiveClientModal(!activeClientModal)
    setShareToggleState(!shareToggleState)
    fetchUserClients()
  }

  const addLeadForm = (value) => {
    let phones
    if (selectedClient) {
      phones =
        selectedClient &&
        selectedClient.customerContacts &&
        selectedClient.customerContacts.map((item) => item.phone)
      if (location && location.state) {
        phones = [location.state && location.state.customer && location.state.customer.phone]
      }
    } else {
      phones =
        value.customerId &&
        value.customerId.customerContacts &&
        value.customerId.customerContacts.map((item) => item.phone)
      if (location && location.state) {
        phones = [location.state && location.state.customer && location.state.customer.phone]
      }
    }

    let data
    if (!selectedClient || !value.cityId) {
      setErrorMessage({
        ...errorMessage,
        city: !value.cityId ? true : false,
        client: !selectedClient ? true : false,
      })
    } else {
      data = {
        customerId: selectedClient ? selectedClient.id : value.customerId.id,
        cityId: value.cityId.value,
        projectId:
          value.project && value.project.value
            ? value.project.value
            : value.projectId
              ? value.projectId
              : null,
        projectName:
          value.project && value.project.label
            ? value.project.label
            : value.projectName
              ? value.projectName
              : null,
        projectType:
          value.projectType && value.projectType.label
            ? value.projectType.label
            : value.projects && value.projects
              ? value.projects && value.projects
              : null,
        minPrice: parseInt(value.ranger.price_min),
        maxPrice: parseInt(value.ranger.price_max),
        description: value.description,
        armsProjectTypeId:
          value.projectType && value.projectType.value
            ? value.projectType.value
            : value.subTypeId
              ? value.subTypeId
              : null,
        phones: phones,
      }
      if (dialerLeadInformation) {
        data.isDialer = true
      }

      if (editable === true) {
        axios
          .patch(`${config.apiPath}/api/leads/project/update/${lead && lead.id}`, data)

          .then((res) => {
            showToastMsg('Lead has been updated successfully', 'success')
            callBack(res)
            // this.props.fetchSingleLead(singleLeadRecord && singleLeadRecord.id)
          })
      } else {
        //to={`/booking/ci?clientId=${lead.customer.id}&leadId=${lead.id}`}
        if(forBooking){
          addLeadFunc(data, (lead) => {
            history.push(`/booking/ci?clientId=${lead.projectLeadData ? lead.projectLeadData.customerId : selectedClient.id}&leadId=${lead.leadId}`)
          })
        }else{
          addLeadFunc(data, (lead) => {
            history.push('/diary', {
              send_data: {
                getCallFromCustomer: true,
                diaryId: lead.diaryId
              }})
          })
        }

      }
    }
  }

  return (
    <div className="lead-creation-container">
      {editable !== true && (
        <div className="title">
          Enter details for your {editable ? 'existing' : 'new'} investment lead
        </div>
      )}
      <LeadCreationInner
        setName={setName}
        cities={cities}
        allProjects={allProjects}
        selectedClient={selectedClient}
        addLeadLoader={addLeadLoader}
        shareToggleState={shareToggleState}
        setShareToggleState={setShareToggleState}
        onSubmit={addLeadForm}
        history={history}
        errorMessage={errorMessage}
        lead={lead}
        editable={editable}
        setLeadModal={closeModal}
      />

      <ClientCreationModal
        name={name}
        shareToggleState={shareToggleState}
        clients={userClients && userClients.rows}
        clientModalToggle={clientModalToggle}
        shareToggle={shareToggle}
        onchangeClient={onchangeClient}
      />

      <AddClientModal
        name={name}
        activeClientModal={activeClientModal}
        fromPage={'investmentLead'}
        apiCallDonClientCreated={apiCallDonClientCreated}
        shareToggle={shareToggle}
        clientModalToggle={clientModalToggle}
      />
    </div>
  )
}

export const mapDispatchToProps = (dispatch) => {
  return {
    setTitle: (title) => dispatch(setTitle(title)),
    fetchUserClients: () => dispatch(fetchUserClients()),
    fetchCities: () => dispatch(fetchCities()),
    fetchAllProjects: () => dispatch(fetchAllProjects()),
    addLeadFunc: (data, cb) => dispatch(addLead(data, false, cb)),
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
    userClients: state.Leads.userClients,
    cities: state.user.cities,
    allProjects: state.Projects.allProjects,
    addLeadLoader: state.Leads.addLeadLoader,
    selectedCustomerDetail: state.Leads.selectedCustomerDetail,
    dialerLeadInformation: state.Diary.dialerLeadInformation
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LeadCreation))
