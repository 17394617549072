/** @format */

import Swal from 'sweetalert2'
import * as ActionTypes from '../actions/InvestmentLeadOrder'
const initialState = {
    order: { label: 'Recently Modified First', value: '&order=Desc&field=updatedAt' },
}

const InvestmentLeadOrder = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.SORTING_ORDER:
            return {
                ...state,
                order: action.data,
            }
        default:
            return {
                ...state,
            }
    }
}

export default InvestmentLeadOrder
