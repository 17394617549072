/** @format */

import React, { useEffect, useState } from 'react'
import { Form, Input, Modal, ModalBody } from 'reactstrap'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import { formatPrice, formatPriceRange } from '../../../../utils/formatPrice'
import { allLeadTypes, bedBathRange, properties } from '../../../../utils/constant_data'
import { capitalize_Words } from '../../../../utils/commonFormat'
import { formatSizeRange } from '../../../../utils/formatSize'
import axios from 'axios'
import config from '../../../../config'
import { formatBedBathRange } from '../../../../utils/formatBedBath'
import ReactAudioPlayer from 'react-audio-player'
import Button from '../../../common/Button'
import LeadEditModal from './LeadEditModal'
import { isAira } from '../../../common/LeadView/Wanted/WantedUtilHelper'

const useStyles = makeStyles({
  requirementSection: { gridArea: 'REQUIREMENT' },
  form: {
    overflowY: 'hidden',
    overflowX: 'hidden',
    '&:hover': {
      overflowY: 'auto',
    },
  },
  requirementInfo: (props) => (props.styles && props.styles.requirement) || { maxHeight: 500 },
  data: { fontWeight: 'bold', color: '#026ff2' },
  iconEdit: {
    position: 'absolute',
    cursor: 'pointer',
    top: 6,
    right: 6,
    color: '#016ff2',
  },
  textArea: {
    backgroundColor: '#f4f5f6',
    paddingRight: 30,
  },
  audio_player_wraper: {
    width: 205,
    backgroundColor: '#f1f3f4',
    overflow: 'hidden',
    position: 'relative',
    height: 33,
    borderRadius: 5,

    '& audio': {
      left: -14,
      position: 'relative',
      top: -10,
    },
  },
})

let ConnectInfo = (props) => {
  const [leadModal, setLeadModal] = useState(false)
  const [detailToggle, setDetailToggle] = useState(false)
  const [projectLeadRes, setProjectLeadRes] = useState()
  const [buyRentLeadRes, setBuyRentLeadRes] = useState()
  const leadModalToggle = () => {
    setLeadModal(!leadModal)
    setDetailToggle(!detailToggle)
  }

  const classes = useStyles(props)
  const [description, setDescription] = useState(props.lead.description)
  const [transcriptModal, setTranscriptModal] = useState(false)
  const { lead, leadType } = props
  const getSelectedValue = (options, key) => {
    let val = lead[key] === 'sale' && key === 'purpose' ? 'buy' : lead[key]
    let opt = options.filter((o) => o.value === val)
    if (opt.length) return opt[0].label || opt[0].name
    else return val || '--'
  }

  const area_key = leadType === 'Wanted' ? 'wanted_areas' : 'armsLeadAreas'
  const voiceLead = lead.voiceLead || (lead.wanted && lead.wanted.voiceLead) || null

  const resData = (data) => {
    if (data && data.data && data.data.projectLeadData) {
      setProjectLeadRes(data.data.projectLeadData)
    } else if (data && data.data && data.data.createdLeadData) {
      setBuyRentLeadRes(data.data.createdLeadData)
    }
  }
  useEffect(() => {}, [projectLeadRes, buyRentLeadRes])

  return (
    <>
      <div className={`${classes.requirementSection} pl-4 pr-2`}>
        {!props.hideTitle && (
          <div className={'d-flex align-items-center'} style={{ columnGap: 10 }}>
            <h4>Requirements</h4>
            {(props.checkForRead &&
              !props.checkForRead.diaryReadOnly &&
              props.editable &&
              props.lead.status === 'open' &&
              props.leadType !== 'Wanted') || isAira() && (
                <span style={{ cursor: 'pointer' }} onClick={() => setLeadModal(true)}>
                  <i className={'fa fa-edit text-primary mb-2'}></i>
                </span>
              )}
          </div>
        )}
        <div className={`${classes.form} ${classes.requirementInfo} pr-2 mt-3`}>
          {['BuyRent', 'Wanted'].indexOf(leadType) > -1 && (
            <>
              <div className={`${classes.label}`}>Purpose</div>
              <div className={`${classes.data}`}>
                {buyRentLeadRes
                  ? capitalize_Words(buyRentLeadRes.purpose)
                  : getSelectedValue(allLeadTypes, 'purpose')}
              </div>
              <hr />
            </>
          )}

          {leadType === 'Wanted' && ['sell', 'rent out'].indexOf(lead.purpose) > -1 && (
            <>
              <div className={`${classes.label}`}>Property Id</div>
              <div className={`${classes.data}`}>
                {(props.leadProperty && props.leadProperty.id) || '--'}
              </div>
              <hr />
            </>
          )}
          {(leadType !== 'Wanted' || (lead.purpose !== 'sell' && lead.purpose !== 'rent out')) && (
            <>
              <div className={`${classes.label}`}>City</div>
              <div className={`${classes.data}`}>
                {projectLeadRes
                  ? projectLeadRes.city && projectLeadRes.city.name
                  : buyRentLeadRes && buyRentLeadRes.city.name
                  ? buyRentLeadRes.city.name
                  : lead.city && lead.city.name
                  ? lead.city && lead.city.name
                  : '--'}
              </div>
              <hr />
            </>
          )}
              <div className={`${classes.label}`}>Wanted ID</div>
              <div className={`${classes.data}`}>
                {projectLeadRes
                  ? projectLeadRes.wanted_id && projectLeadRes.wanted_id
                  : buyRentLeadRes && buyRentLeadRes.wanted_id
                  ? buyRentLeadRes.wanted_id
                  : lead.wanted_id && lead.wanted_id
                  ? lead.wanted_id && lead.wanted_id
                  : '--'}
              </div>
              <hr />
       
         
          {(leadType === 'BuyRent' ||
            (leadType === 'Wanted' && ['sale', 'buy', 'rent'].indexOf(lead.purpose) > -1)) && (
            <>
              <div className={`${classes.label}`}>Area</div>
              <div className={`${classes.data}`}>
                {buyRentLeadRes && buyRentLeadRes[area_key]
                  ? buyRentLeadRes[area_key] &&
                    buyRentLeadRes[area_key].map((area, k) => {
                      return `${area.area && area.area.name && area.area.name}${
                        k < buyRentLeadRes[area_key].length - 1 ? ', ' : ''
                      }`
                    })
                  : lead[area_key] &&
                    lead[area_key].map((area, k) => {
                      return area.area
                        ? `${area.area.name}${k < lead[area_key].length - 1 ? ', ' : ''}`
                        : ''
                    })
                  ? lead[area_key] &&
                    lead[area_key].map((area, k) => {
                      return area.area
                        ? `${area.area.name}${k < lead[area_key].length - 1 ? ', ' : ''}`
                        : ''
                    })
                  : '--'}
              </div>
              <hr />
              <div className={`${classes.label}`}>Property Type</div>
              <div className={`${classes.data}`}>
                {buyRentLeadRes && buyRentLeadRes.type
                  ? capitalize_Words(buyRentLeadRes.type)
                  : getSelectedValue(properties.type, 'type')}
              </div>
              <hr />
              
              <div className={`${classes.label}`}>Property Sub Type</div>
              <div className={`${classes.data}`}>
                {buyRentLeadRes && buyRentLeadRes.subtype
                  ? capitalize_Words(buyRentLeadRes.subtype)
                  : getSelectedValue(lead.type ? properties.subType[lead.type] : [], 'subtype')}
              </div>
              <hr />
              <div className={`${classes.label}`}>Size</div>
              <div className={`${classes.data}`}>
                {buyRentLeadRes &&
                buyRentLeadRes.size &&
                buyRentLeadRes.max_size &&
                buyRentLeadRes.size_unit
                  ? formatSizeRange(
                      buyRentLeadRes.size,
                      buyRentLeadRes.max_size,
                      buyRentLeadRes.size_unit
                    )
                  : formatSizeRange(lead.size, lead.max_size, lead.size_unit)}
              </div>
              <hr />
              <div className={`${classes.label}`}>Price</div>
              <div className={`${classes.data}`}>
                {buyRentLeadRes && (buyRentLeadRes.price || buyRentLeadRes.min_price)
                  ? formatPriceRange(
                      buyRentLeadRes.min_price,
                      leadType === 'BuyRent' ? buyRentLeadRes.price : buyRentLeadRes.max_price,
                      'project'
                    )
                  : formatPriceRange(
                      lead.min_price,
                      leadType === 'BuyRent' ? lead.price : lead.max_price,
                      'project'
                    )}
              </div>
              <hr />

              {lead.type === 'residential' && (
                <>
                  <div className={`${classes.label}`}>Bed</div>
                  <div className={`${classes.data}`}>
                    {formatBedBathRange(
                      lead.bed,
                      leadType === 'Wanted' ? lead.bed_max : lead.maxBed
                    )}
                  </div>
                  <hr />
                  <div className={`${classes.label}`}>Bath</div>
                  <div className={`${classes.data}`}>
                    {formatBedBathRange(
                      lead.bath,
                      leadType === 'Wanted' ? lead.bath_max : lead.maxBath
                    )}
                  </div>
                  <hr />
                </>
              )}
            </>
          )}
          {(leadType === 'Project' || (leadType === 'Wanted' && lead.purpose === 'invest')) && (
            <>
              <div className={`${classes.label}`}>Project</div>
              <div className={`${classes.data}`}>
                {projectLeadRes
                  ? projectLeadRes.projectName && projectLeadRes.projectName
                  : lead.projectName
                  ? lead.projectName
                  : '--'}
              </div>
              <hr />
              <div className={`${classes.label}`}>Unit Type</div>
              <div className={`${classes.data}`}>
                {projectLeadRes
                  ? projectLeadRes.projectType && projectLeadRes.projectType
                  : lead.projectType
                  ? lead.projectType
                  : '--'}
              </div>
              <hr />
              <div className={`${classes.label}`}>Investment Range</div>
              <div className={`${classes.data}`}>
                {formatPriceRange(
                  leadType === 'Wanted'
                    ? lead.min_price
                    : projectLeadRes
                    ? projectLeadRes && projectLeadRes.minPrice
                    : lead.minPrice,
                  leadType === 'BuyRent'
                    ? lead.price
                    : leadType === 'Wanted'
                    ? lead.max_price
                    : projectLeadRes
                    ? projectLeadRes && projectLeadRes.maxPrice
                    : lead.maxPrice,
                  'sale'
                )}
              </div>
              <hr />
            </>
          )}
          {lead && lead.origin && <>
            <div className={`${classes.label}`}>Origin</div>
            <div className={`${classes.data}`}>
              {lead.origin}
            </div>
            <hr /></> }
          {lead && lead.campaigns_project_mapping && lead.campaigns_project_mapping.campaign_name && <>
            <div className={`${classes.label}`}>Campaign</div>
              <div className={`${classes.data}`}>
                {lead.campaigns_project_mapping.campaign_name}
              </div>
              <hr />
          </>}
          <div className={' position-relative'}>
            <div className={`${classes.label}`}>Description</div>
            <div className={`${classes.data}`}>
              {projectLeadRes && projectLeadRes.description
                ? projectLeadRes.description
                : buyRentLeadRes && buyRentLeadRes.description
                ? buyRentLeadRes.description
                : lead.description
                ? lead.description
                : '--'}
            </div>
            {/*<Input rows={4} readOnly={true} onChange={(e) => setDescription(e.target.value)} type={'textarea'} value={lead.description} />*/}
          </div>
          {voiceLead && voiceLead.voiceNoteLink && (
            <>
              <br />
              <div className={'d-flex align-items-center'} style={{ columnGap: 3 }}>
                <div className={classes.audio_player_wraper}>
                  <ReactAudioPlayer
                    // controlsList={'nodownload noremoteplayback'}
                    src={voiceLead.voiceNoteLink}
                    controls
                  />
                </div>
                <Button
                  onClick={(e) => {
                    if (voiceLead.voiceNoteText) setTranscriptModal(true)
                  }}
                  text={<i className="fa fa-file-text-o" aria-hidden="true"></i>}
                  style={{ padding: '1.5px 9px', border: '1px solid #1173ef' }}
                  buttonColor={'white'}
                  textColor={'#1173ef'}
                  disabled={!voiceLead.voiceNoteText}
                />
              </div>
            </>
          )}
        </div>
      </div>

      {voiceLead && voiceLead.voiceNoteText && (
        <Modal
          isOpen={transcriptModal}
          toggle={(toggle) => setTranscriptModal(!toggle)}
          contentClassName={`no-custom`}
        >
          <ModalBody>
            <h2>Voice Transcript</h2>
            <div className="close-icon-container">
              <i
                className="fal fa-times-circle close-icon"
                onClick={() => setTranscriptModal(false)}
              ></i>
            </div>
            <div className={'border rounded py-2 px-3 mt-2'} style={{ minHeight: 200 }}>
              {voiceLead.voiceNoteText}
            </div>
          </ModalBody>
        </Modal>
      )}

      <LeadEditModal
        leadModal={leadModal}
        setLeadModal={leadModalToggle}
        lead={projectLeadRes ? projectLeadRes : buyRentLeadRes ? buyRentLeadRes : lead}
        leadType={leadType}
        editable={props.editable}
        callBack={resData}
      />
    </>
  )
}

export const mapDispatchToProps = (dispatch) => {
  return {}
}

const mapStateToProps = (state) => {
  return {}
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ConnectInfo))
