import React from "react";
import ReactApexChart from "react-apexcharts";

class BarChart extends React.Component {
  constructor(props) {
    super(props);
    const { Proplabel } = this.props;
    this.state = {
      series: [],
      options: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          toolbar: {
            show: true,
          },
          zoom: {
            enabled: true,
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        colors: [
          "#90ee7e",
          "#d4526e",
          "#ffff00",
          "#f9a3a4",
          "#f48024",
          "#A5978B",
          "#2b908f",
          "#303030",
          "#2b228c",
          '#8c4e22'
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,

            dataLabels: {
              total: {
                enabled: true,
                style: {
                  fontSize: "13px",
                  fontWeight: 900,
                },
              },
            },
          },
        },
        title: {
          text: Proplabel,
          align: "center",
          offsetX: 10,
          offsetY: 10,
        },
        xaxis: {
          type: "text",
          categories: [],
        },
        legend: {
          position: "right",
          offsetY: 40,
        },
        fill: {
          opacity: 1,
        },
      },
    };
  }

  componentWillReceiveProps(nextProps) {
    const { options } = this.state;
    if (
      nextProps &&
      nextProps.series &&
      nextProps.series.length > 0 &&
      nextProps &&
      nextProps.categories &&
      nextProps.categories.length > 0
    ) {
      this.setState(() => ({
        series: nextProps.series,

        options: {
          ...options,
          xaxis: {
            categories: nextProps.categories,
          },
        },
      }));
    }
  }

  render() {
    return (
      <div id="chart">
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="bar"
          height={350}
        />
      </div>
    );
  }
}
export default BarChart;
