/** @format */

import React, { useState, useEffect } from 'react'
import TableHeader from './TableHeader'
import TableBody from './TableBody'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { createResizableTable } from './Resizer'
import './styles.scss'
import _ from 'lodash'

const useStyles = makeStyles({
  root: {
    tableLayout: 'auto',
    // width: 'max-content',
    // height: 'max-content',
    width: '-webkit-fill-available',
    height: '-webkit-fill-available',
    overflow: 'auto',
  },
})

const Table = (props) => {
  const classes = useStyles(props)
  const [selectedRowIndex, setSelectedRowIndex] = useState(null)
  const [selectedColumnIndex, setSelectedColumnIndex] = useState(null)
  const [selectAll, isSelectAll] = useState(false)
  const [selectedRowData, setSelectedRowData] = useState(null)

  const handleRowClick = (rowIndex, rowData, event) => {
    setSelectedRowIndex(rowIndex)
    // setSelectedRowData(rowData)
    props.onRowClick && props.onRowClick({ rowIndex: rowIndex, rowData: rowData }, event)
  }

  const handleCellClick = (cellIndex, rowIndex, rowData, event) => {
    // setSelectedColumnIndex(cellIndex)
    props.onCellClick && props.onCellClick(cellIndex, rowIndex, rowData, event)
  }

  useEffect(() => {
    props.resizeColumns && createResizableTable(document.getElementById('resizeableTable'))
  }, [])

  return (
    <div
      id={props.resizeColumns ? 'resizeableTable' : ''}
      className={'table-container ' + props.tableContinerClassName}
    >
      <table
        className={clsx({
          [classes.root]: true,
          [props.tableClassName]: true,
          [props.noResultClassName]: props.noResult,
        })}
      >
        {props.headerTemplate ? (
          <TableHeader
            headerTemplate={props.headerTemplate}
            onCellClick={() => {}}
            headerClassName={props.headerClassName}
            headerCellsClassName={props.headerCellsClassName}
            headerColor={props.headerColor}
            headerHeight={props.headerHeight}
            cellWidth={props.cellWidth}
            cellMinWidth={props.cellMinWidth}
            fixedHeader={props.fixedHeader}
            fixedColumns={props.fixedColumns}
            resizeColumns={props.resizeColumns}
            isMultiSelect={props.isMultiSelect}
            onSelectAll={(e) => {
              props.onSelectAll && props.onSelectAll(e)
              isSelectAll(e)
            }}
          />
        ) : (
          ''
        )}

        {props.loader ? (
          <div className="table-loader-container">
            <span className={'fa fa-circle-o-notch fa-spin loader-color'} />
          </div>
        ) : (
          <TableBody
            rowTemplate={props.bodyTemplate}
            headerTemplate={props.headerTemplate}
            data={props.data}
            selectedRowIndex={selectedRowIndex}
            onCellClick={handleCellClick}
            rowsClassName={props.rowsClassName}
            cellsClassName={props.cellsClassName}
            rowHeight={props.rowHeight}
            rowStyles={props.rowStyles}
            rowHoverColor={props.rowHoverColor}
            fixedColumns={props.fixedColumns}
            selectedRowColor={props.selectedRowColor}
            rowHoverStyles={props.rowHoverStyles}
            noResult={props.noResult}
            {...props}
            onRowClick={handleRowClick}
            isMultiSelect={props.isMultiSelect}
            isSelectAll={selectAll}
            onMultiSelect={props.onMultiSelect}
          />
        )}
      </table>
    </div>
  )
}
// export default Table
export default React.memo(Table, (prevProps, nextProps) => {
  if (nextProps.useMemo === false) {
    return false
  }
  return _.isEqual(prevProps.data, nextProps.data)
  // &&
  // _.isEqual(prevProps.bodyTemplate, nextProps.bodyTemplate) &&
  // _.isEqual(prevProps.headerTemplate, nextProps.headerTemplate)
})

TableBody.propTypes = {
  rowHeight: PropTypes.func,
  rowHoverColor: PropTypes.func,
  selectedRowColor: PropTypes.func,
  onRowClick: PropTypes.func,
  onCellClick: PropTypes.func,
  data: PropTypes.any,
  bodyTemplate: PropTypes.array,
  rowsClassName: PropTypes.any,
  cellsClassName: PropTypes.string,
  fixedColumns: PropTypes.object,
  resizeColumns: PropTypes.bool,
  isMultiSelect: PropTypes.bool,
  onSelectAll: PropTypes.func,
  onMultiSelect: PropTypes.func,
  useMemo: PropTypes.bool,
}

TableBody.defaultProps = {
  rowHeight: () => {},
  rowHoverColor: () => {},
  selectedRowColor: () => {},
  onRowClick: () => {},
  onCellClick: () => {},
  rowsClassName: '',
  cellsClassName: '',
  resizeColumns: false,
  isMultiSelect: false,
  onSelectAll: () => {},
  onMultiSelect: () => {},
  useMemo: true,
}
