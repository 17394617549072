/** @format */

import React, { Component, Fragment } from "react";
import Pagination from "rc-pagination";
import { connect } from "react-redux";
import axios from "axios";
import config from "../../config";
import Load from "../../components/Loader";
import Inner from "./Inner";
import { normalizeCnic } from "../../utils/formatCnic";
import "rc-pagination/assets/index.css";
import "rc-select/assets/index.css";
import ClinetCreationModal from "./modal";
import "./style.scss";
import { setTitle } from "../../actions/generalSetting";
import { setLastAddedClient } from "../../actions/user";
import { fetchLeadTasks } from "../../actions/leads";
import moment from "moment";
import { buildFormData, showToastMsg } from "../../utils/helper";
import { findZero } from "../../utils/validate";
import { removeZero } from "../../utils/validate";

import { withRouter } from "react-router-dom";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { submit } from "redux-form";
import { leadType } from "../../utils/constant_data";
import PostRegModal from "./PostRegModal";
import { diaryCreateUpdateAPI, makeTaskTimePayload } from "../../utils/diary";
import { isReadOnly, capitalizeLeadTypeFirstLetter } from "../../utils/lead";
import { managerSelectionDropdown } from "../../utils/constant_data";
import ConnectModal from "../../components/Modals/ConnectFeedback/ConnectModal";
import ConnectFlow from "../../components/Modals/ConnectFeedback/ConnectFlow";
import ReferenceGuideModal from "../../components/Modals/TaskModal/ReferenceGuideModal";
import SlotModal from "../../components/SlotManagement/SlotModal";
import DiaryModal from "../../components/Modals/DiaryModal";
import AdditionalDetails from "../../components/Modals/AdditionalDetailsModal/AdditionalDetails";
import ReferModal from "../../components/Modals/ReferModal";
import { getFormValues } from "redux-form";
import { validateIBAN } from '../../utils/checkIban'

class AddClent extends Component {
  constructor(props) {
    super(props);
    this.scrollRef = null;
    this.setScrollRef = (element) => {
      this.scrollRef = element;
    };
    let exUrl = new URL(window.location.href);
    let mob = exUrl.searchParams.get("phoneNumber");
    this.state = {
      userId: null,
      formData: {
        firstName: "",
        lastName: "",
        address: "",
        email: "",
        cnic: "",
        secondaryAddress: "",
        checkRequiredField: false,
        relationStatus: "",
        relativeName: "",
        profession: "",
        passport: "",
        country: "",
        province: "",
        district: "",
        city: "",
        mCountry: "",
        mProvince: "",
        mDistrict: "",
        mCity: "",
        mAddress: "",
        nationality: "",
        dob: "",
        example: false,
        clientSource: "Personal Client",
        clientType: "Dealer",
        profilePic: null,
        cnicFront: null,
        cnicBack: null,
        kinCnicFront: null,
        kinCnicBack: null,
        existingClientInfo: null,
        otherInvestmentInfo: null,
      },
      phone: {
        countryCode: "pk",
        dialCode: "92",
        phone: "",
      },
      contact1: {
        countryCode: "pk",
        dialCode: "92",
        contact1: "",
      },
      contact2: {
        countryCode: "pk",
        dialCode: "",
        contact2: "",
      },
      kinContact: {
        countryCode: "pk",
        dialCode: "",
        kinContact: "",
      },
      soi: [],
      sof: [],
      otherSourceOfIncome: "",
      sourceOfFunds: "",
      AssociationWithanypoliticalparty: null,
      ExistingImaratClient: null,
      OtherProjectsInv: null,
      RegisterWithFBR: null,
      fbrRegistered: null,
      politicalAssoc: null,

      validationCheck: false,
      loadingOnsubmit: false,
      editAble: false,
      cnicValidation: false,
      phoneValidate: false,
      phoneValidate2: false,
      kinPhoneValidate: false,
      phoneValidate3: false,
      emailValidate: false,
      name: "",
      activePostRegModal: false,
      openConnectModal: false,
      registerFeedbackModal: false,
      newtask: null,
      diaryModal: false,
      taskModal: false,
      guideReferenceModal: false,
      additionalInfoToggleState: false,
      fetchLeadsInModal: false,
      shareToggleState: false,
      shareloader: false,
      shareLeadType: "",
      agents: [],
      agentCount: 0,
      isBookingFor: true,
      ifZeroExists: false,
      redirectionModal: false,
      redirectionType: "",
    };
    }

  componentDidMount() {
    const { fromPage, editable, clientId } = this.props;

    let exUrl = new URL(window.location.href);
    let mob = exUrl.searchParams.get("phoneNumber");
    mob && this.setPhoneNumberFromUrl(mob);

    if (fromPage !== "investmentLead" && fromPage !== "buyrentlead") {
      const { params } = this.props.match;
      if (params.clientId) {
        this.editClientData(params.clientId);
      }
      if (params.clientId) {
        this.props.setTitle({ name: "Edit Client Details" });
        document.title = "Edit Client Details"
      } else {
        this.props.setTitle({ name: "Client Registration" });
        document.title = "Client Registration"

      }
    }

    if (
      (fromPage === "investmentLead" || fromPage === "buyrentlead") &&
      editable
    ) {
      if (clientId) {
        this.editClientData(clientId);
      }
    }
    // let exUrl = new URL(window.location.href);
    // let mob = exUrl.searchParams.get("phoneNumber");
  }

  editClientData = (id) => {
    const { formData, phone, contact1, contact2, kinContact } = this.state;
    axios.get(`${config.apiPath}/api/customer/${id}`).then((res) => {
      if (res.data.soi) {
        let soiArray = res.data.soi.split(", ");
        let soiCheckBoxesArray = [
            "salaried",
            "business",
            "property",
            "foreign_source",
          ],
          getotherSourceOfIncome = soiArray.filter(
            (entry1) => !soiCheckBoxesArray.some((entry2) => entry1 === entry2)
          );

        let getsoiCheckboxesArray = soiArray.filter((entry1) =>
          soiCheckBoxesArray.some((entry2) => entry1 === entry2)
        );

        this.setState({ soi: getsoiCheckboxesArray });
        if (getotherSourceOfIncome.length > 0) {
          this.setState({ otherSourceOfIncome: getotherSourceOfIncome[0] });
        }
      }

      //
      if (res.data.sof) {
        let sofArray = res.data.sof.split(", ");
        let sofCheckBoxesArray = ["form_loan", "saving", "business_income"];

        let getotherSourceOfFunds = sofArray.filter(
          (entry1) => !sofCheckBoxesArray.some((entry2) => entry1 === entry2)
        );
        if (getotherSourceOfFunds.length > 0) {
          this.setState({ sourceOfFunds: getotherSourceOfFunds[0] });
        }

        let getsofCheckboxesArray = sofArray.filter((entry1) =>
          sofCheckBoxesArray.some((entry2) => entry1 === entry2)
        );

        this.setState({
          sof: getsofCheckboxesArray,
        });

      }

      var newFormData = { ...formData };
      var newPhone = { ...phone };
      var newcontact1 = { ...contact1 };
      var newcontact2 = { ...contact2 };
      var newkinContact = { ...kinContact };

      newFormData["firstName"] = res.data.first_name;
      newFormData["lastName"] = res.data.last_name;
      newFormData["cnic"] = normalizeCnic(res.data.cnic);
      newFormData["email"] = res.data.email;
      newFormData["address"] = res.data.address;
      newFormData["secondaryAddress"] = res.data.secondary_address;
      newFormData["origin"] = res.data.origin;
      newFormData["originalOwner"] = res.data.originalOwner;
      newFormData["assigned_to_armsuser_id"] = res.data.assigned_to_armsuser_id;
      newFormData["assigned_to_organization"] =
        res.data.assigned_to_organization;
      newFormData["relationStatus"] = res.data.relationStatus;
      newFormData["relativeName"] = res.data.relativeName;
      newFormData["profession"] = res.data.profession;
      newFormData["passport"] = res.data.passport;
      newFormData["country"] = res.data.country;
      newFormData["clientSource"] = res.data.clientSource;
      newFormData["clientType"] = res.data.clientType;
      newFormData["province"] = res.data.province;
      newFormData["district"] = res.data.district;
      newFormData["city"] = res.data.city;
      newFormData["mCountry"] = res.data.mCountry;
      newFormData["mProvince"] = res.data.mProvince;
      newFormData["mDistrict"] = res.data.mDistrict;
      newFormData["mCity"] = res.data.mCity;
      newFormData["mAddress"] = res.data.mAddress;
      newFormData["mCountry"] = res.data.mCountry;
      newFormData["nationality"] = res.data.nationality;
      newFormData["nextOfKin"] = res.data.nextOfKin;
      newFormData["kinRelation"] = res.data.kinRelation;
      // newFormData['kinCnicFront']=res.data.kinCnicFront
      // newFormData['kinCincBack']=res.data.kinCincBack
      newFormData["motherName"] = res.data.motherName;
      newFormData["accountType"] = res.data.accountType;
      newFormData["kinCnic"] = res.data.kinCnic;
      newFormData["kinEmail"] = res.data.kinEmail;
      newFormData["bank"] = res.data.bank;
      newFormData["iBan"] = res.data.iBan;
      newFormData["filer"] = res.data.filer;
      newFormData["accountNo"] = res.data.accountNo;
      newFormData["accountTitle"] = res.data.accountTitle;
      newFormData["nameOnCnic"] = res.data.nameOnCnic;
      newFormData["gender"] = res.data.gender;
      newFormData["cnicExpiry"] = res.data.cnicExpiry;
      newFormData["dob"] = res.data.dob;
      newFormData["orgId"] = res.data.organizationId;
      newFormData["orgName"] = res.data.added_by_organization;
      newFormData["existingClientInfo"] = res.data.existing_client_info;
      newFormData["otherInvestmentInfo"] = res.data.other_investment_info;
      newFormData["profilePic"] = res.data.avatar;
      newFormData["cnicFront"] = res.data.cnicFront;
      newFormData["cnicBack"] = res.data.cnicBack;
      newFormData["kinCnicFront"] = res.data.kinCnicFront;
      newFormData["kinCnicBack"] = res.data.kinCnicBack;

      newPhone["countryCode"] = res.data.countryCode;
      newPhone["dialCode"] = res.data.dialCode;
      newPhone["phone"] = res.data.phone;

      newkinContact["countryCode"] = res.data.countryCode;
      newkinContact["dialCode"] = res.data.dialCode;
      newkinContact["kinContact"] = res.data.kinContact;

      // Check For Contact > 1 Phone Number
      if (res.data.customerContacts && res.data.customerContacts.length > 0) {
        var moreCOntacts =
          res.data.customerContacts &&
          res.data.customerContacts.length > 0 &&
          res.data.customerContacts.filter((item) => {
            return item.phone != res.data.phone && item;
          });

        // Check For Contact 1
        if (moreCOntacts.length > 0) {
          newcontact1["countryCode"] = moreCOntacts[0].countryCode;
          newcontact1["dialCode"] = moreCOntacts[0].dialCode;
          newcontact1["contact1"] = moreCOntacts[0].phone;
        }

        // Check For Contact 2
        if (moreCOntacts.length > 1) {
          newcontact2["countryCode"] = moreCOntacts[1].countryCode;
          newcontact2["dialCode"] = moreCOntacts[1].dialCode;
          newcontact2["contact2"] = moreCOntacts[1].phone;
        }
      }

      this.setState({
        formData: newFormData,
        ExistingImaratClient: res.data.existing_client,
        OtherProjectsInv: res.data.other_investment,
        RegisterWithFBR: res.data.fbrRegistered,
        AssociationWithanypoliticalparty: res.data.politicalAssoc,
        phone: newPhone,
        contact1: newcontact1,
        contact2: newcontact2,
        kinContact: newkinContact,
        editAble: true,
        userId: res && res.data && res.data.user && res.data.user.id,
      });
    });
  };

  handleChange = (e, dropDownName, resetArray) => {
    const { formData } = this.state;
    if (dropDownName) {
      var name = dropDownName;
      var value = e.value;
    } else {
      var name = e.target.name;
      var value = e.target.value;
    }
    var newFormData = { ...formData };
    if (resetArray && resetArray.length > 0) {
      for (let i = 0; i < resetArray.length; i++) {
        newFormData[resetArray[i]] = "";
      }
    }

    if (name === "firstName" || name === "lastName") {
      if (value.length > 25) {
        value = value.slice(0,25)
    }
    }

    if (name === "cnic") {
      var value = value.replace(/-/g, "");
      if (value.length > 13) {
        value = value.slice(0,13)
      }
      this.validateCnic(value);
    }
    if (name === "email") {
      this.validateEmail(value);
    }
    if (name === "profilePic" || name === "cnicFront" || name === "cnicBack" || name === "kinCnicFront" || name === "kinCnicBack") {
      newFormData[name] = e.target.files[0];
    }
    else {
      newFormData[name] = value;
    }
    this.setState({
      formData: newFormData,
    });
  };

  handleFile = (e) => {
    const file = e.target.files[0];
    this.setState({
      formData: {
        ...this.state.formData,
        kinCnicFront: file,
      },
    });
  };

  handleDob = (dob) => {
    const { formData } = this.state;
    var newFormData = { ...formData };
    newFormData["dob"] = dob;
    this.setState({
      formData: newFormData,
    });
  };

  handleprofession = (profession) => {
    const { formData } = this.state;
    var newFormData = { ...formData };
    newFormData["profession"] = profession;
    this.setState({
      formData: newFormData,
    });
  };

  resetData = (array) => {
    const { formData } = this.state;
    if (array) {
      for (let i = 0; i < array.length; i++) {
        formData[array[i]] = "";
      }
      var newFormData = { ...formData };
      this.setState({
        formData: newFormData,
      });
    }
  };

  validateEmail = (value) => {
    var format =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (format.test(value)) {
      this.setState({ emailValidate: false });
    } else {
      this.setState({
        emailValidate: true,
      });
    }
  };

  validatePhone = (value,countryCode) => {
    var condition = countryCode === "92" ? 12 : 6
    // let zeroCheck = findZero(value)
    if (value.length <  condition  && value !== "")
      this.setState({ phoneValidate: true });
    else this.setState({ phoneValidate: false });
  };

  validateContact1 = (value,countryCode) => {
    // let zeroCheck = findZero(value)
    var condition = countryCode === "92" ? 12 : 6
    // let zeroCheck = findZero(value)
    if (value.length < condition && value !== "")
      this.setState({ phoneValidate1: true });
    else this.setState({ phoneValidate1: false });
  };

  validateContact2 = (value,countryCode) => {
    var condition = countryCode === "92" ? 12 : 6
    // let zeroCheck = findZero(value)
    if (value.length < condition && value !== "")
      this.setState({ phoneValidate2: true });
    else this.setState({ phoneValidate2: false });
  };

  validateKinPhone = (value,countryCode) => {
    var condition = countryCode === "92" ? 12 : 6
    if (value.length < condition && value !== "")
      this.setState({ kinPhoneValidate: true });
    else this.setState({ kinPhoneValidate: false });
  };

  validateCnic = (value) => {

    if (
      value.length === 7 ||
      value.length === 8 ||
      (value.length >= 13 && value !== "")
    ) {
      this.setState({ cnicValidation: false });
    } else {
      this.setState({ cnicValidation: true });
    }
  };

  setPhoneNumberFromUrl = (mob) => {
    const { phone } = this.state;
    var newPhone = {};

    let value = mob ? mob : "";
    newPhone["countryCode"] = "pk";
    newPhone["dialCode"] = "";
    newPhone["phone"] = "" + mob;
    this.validatePhone(value);
    this.setState({
      phone: newPhone,
    });
  };

  handleChangePhone = (value, country, e, formattedValue) => {
    const { phone } = this.state;
    var NewsetValue = value
    if(country.dialCode === '92')
    {
      if (NewsetValue[2] == 0) {
        NewsetValue = NewsetValue.slice(0,2) + NewsetValue.slice(3)
      }
      if (NewsetValue.length >= 13) {
        NewsetValue =  NewsetValue.substring(0,11);
      }
    }
    const setValue=()=>{
      var newPhone = { ...phone };
      newPhone["countryCode"] = country.countryCode;
      newPhone["dialCode"] = country.dialCode;
      newPhone["phone"] = "+" +  NewsetValue;
      this.validatePhone(NewsetValue,country.dialCode);
      this.setState({
        phone: newPhone,
      });
    }

    setValue()

  };

  handleChangeContact1 = (value, country, e, formattedValue) => {
    const { contact1 } = this.state;

    var NewsetValue = value
    if(country.dialCode === '92' && NewsetValue.length >= 13)
   {
    NewsetValue =  NewsetValue.substring (0,11) ;
   }


    var newcontact1 = { ...contact1 };
    newcontact1["countryCode"] = country.countryCode;
    newcontact1["dialCode"] = country.dialCode;
    newcontact1["contact1"] = "+" + NewsetValue;
    this.validateContact1(NewsetValue,country.countryCode);
      this.setState({
        contact1: newcontact1,
    });
  };

  handleChangeContact2 = (value, country, e, formattedValue) => {
    const { contact2 } = this.state;

    var NewsetValue = value
    if(country.dialCode === '92' && NewsetValue.length >= 13)
   {
    NewsetValue =  NewsetValue.substring (0,11) ;
   }


    var newcontact2 = { ...contact2 };
    newcontact2["countryCode"] = country.countryCode;
    newcontact2["dialCode"] = country.dialCode;
    newcontact2["contact2"] = "+" + NewsetValue;
    this.validateContact2(NewsetValue,country.countryCode);
      this.setState({
        contact2: newcontact2,
    });
  };

  handleChangeKin = (value, country, e, formattedValue) => {
    const { kinContact } = this.state;
    var NewsetValue = value
    if(country.dialCode === '92')
   {
      if (NewsetValue[2] == 0) {
        NewsetValue = NewsetValue.slice(0,2) + NewsetValue.slice(3)
    }
      if (NewsetValue.length >= 13) {
        NewsetValue =  NewsetValue.substring(0,11);
      }
    }
    const setValue=()=>{
      var newkincontact = { ...kinContact };
    newkincontact["countryCode"] = country.countryCode;
    newkincontact["dialCode"] = country.dialCode;
      newkincontact["kinContact"] = "+" +  NewsetValue;
      this.validateKinPhone(NewsetValue,country.dialCode);
      this.setState({
        kinContact: newkincontact,
    });
    }

    setValue()
  };

  SourceOfIncomehandle = (event) => {
    const { soi } = this.state;
    let soiListCopy = [...soi];
    if (event.target.checked) {
      soiListCopy = [...soi, event.target.value];
    } else {
      soiListCopy.splice(soi.indexOf(event.target.value), 1);
    }
    this.setState({ soi: soiListCopy });
  };

  SourceOfFundsHandler = (event) => {
    const { sof } = this.state;
    let sofListCopy = [...sof];
    if (event.target.checked) {
      sofListCopy = [...sof, event.target.value];
    } else {
      sofListCopy.splice(sof.indexOf(event.target.value), 1);
    }
    this.setState({ sof: sofListCopy });
  };

  otherSourceOfIncomeHandler = (otherSourceOfIncome) => {
    this.setState({ otherSourceOfIncome });
  };

  otherSourceOfFundsHandler = (sourceOfFunds) => {
    this.setState({ sourceOfFunds });
  };



  AssociationWithanypoliticalpartyHandler = (value) => {
    this.setState({
      AssociationWithanypoliticalparty: value === "true" ? true : false,
    });
  };

  ExistingImaratClientHandler = (value) => {
    this.setState({
      ExistingImaratClient: value === "true" ? true : false,
      })
    }
  OtherProjectsInvHandler = (value) => {
    this.setState({
      OtherProjectsInv: value === "true" ? true : false,
    })
  }

  RegisterWithFBRHandler = (value) => {
    this.setState({ RegisterWithFBR: value === "true" ? true : false });
  };

  emptyForm = () => {
    let formData = {
      firstName: "",
      lastName: "",
      address: "",
      email: "",
      cnic: "",
      secondaryAddress: "",
      relationStatus: "",
      relativeName: "",
      profession: "",
      passport: "",
      country: "",
      province: "",
      district: "",
      city: "",
      mCountry: "",
      mProvince: "",
      mDistrict: "",
      mCity: "",
      mAddress: "",
      nationality: "",
      dob: "",
    };
    let phone = {
      countryCode: "pk",
      phone: "",
    };
    let contact1 = {
      countryCode: "pk",
      dialCode: "",
      contact1: "",
    };
    let contact2 = {
      countryCode: "pk",
      dialCode: "",
      contact2: "",
    };
let kinContact = {
      countryCode: "pk",
      dialCode: "",
      kinContact: "",
    };

    this.setState({
      formData: formData,
      phone: phone,
      contact1: contact1,
      contact2: contact2,
      kinContact: kinContact,
      soi: [],
      otherSourceOfIncome: "",
      sourceOfFunds: "",
      sof: [],
    });
  };

  scrollTopFunc = () => {
    if (this.scrollRef) this.scrollRef.scrollTo({ top: 0, behavior: "smooth" });
  };
  validationFailed = (message = "Some Required Fields are invalid", type = "error") => {
    showToastMsg(message, type);
              this.setState({
                validationCheck: false,
                loadingOnsubmit: false,
                cnicValidation: false,
              });
  }
  createClient = (leadType) => {
    const urlParams = new URLSearchParams(window.location.search)
    const {
      phone,
      formData,
      contact1,
      contact2,
      kinContact,
      editAble,
      cnicValidation,
      phoneValidate,
      phoneValidate1,
      phoneValidate2,
      kinPhoneValidate,
      emailValidate,
      dob,
      soi,
      otherSourceOfIncome,
      AssociationWithanypoliticalparty,
      ExistingImaratClient,
      OtherProjectsInv,
      sof,
      sourceOfFunds,
      RegisterWithFBR,
    } = this.state;

    const {
      fromPage,
      clientModalToggle,
      apiCallDonClientCreated,
      clientId,
      editable,
    } = this.props;
    var validEmail = false;
    if (formData.dob == "") {
      formData.dob = null;
    }
    if (emailValidate === true && formData.email != "") {
      validEmail = true;
    } else {
      validEmail = false;
    }
    this.setState({ loadingOnsubmit: true });
    const checkInvalid = () => {
       if(!formData.dob){
        return 'user'
      } else if(!(formData.cnicFront !== null && formData.cnicBack && formData.cnic)) {
        return 'cnic'
      }
      else if (cnicValidation || (formData.cnic && normalizeCnic(formData.cnic).length !== 15)) {
        return "cnic"
      }
      else if (!formData.profession){
        return "profession"
      } else if(!formData.address){
        return 'address'
      } else if(!(formData.kinRelation && formData.nextOfKin && kinContact && kinContact.kinContact && kinContact.kinContact !== "")){
        return 'kin'
      }
      else if (kinPhoneValidate || (kinContact && kinContact.kinContact && kinContact.kinContact.length !== 13 )) {
        return 'kin'
      }
      else if (!(((soi && soi.length) || (otherSourceOfIncome && otherSourceOfIncome !== ""))
        && ((sof && sof.length) || (sourceOfFunds && sourceOfFunds !== ""))
        && AssociationWithanypoliticalparty !== null && RegisterWithFBR !== null && ExistingImaratClient !== null && OtherProjectsInv !== null)){
          return 'others'
      }
    }
    if (this.props.checkPurchaser) {
      if (
        formData.firstName === "" ||
        formData.lastName === "" ||
        (formData.cnic === "" && (leadType === "" || leadType === null))
      ) {
        this.setState({
          validationCheck: true,
          loadingOnsubmit: false,
        });
      } else {
        if (formData.cnic.length < 13) {
          this.setState({ cnicValidation: true });
        } else {
          if (editAble === true) {
            var customersContactsArray = [];
            let IdOfclient = null;
            if (fromPage !== "investmentLead" && fromPage !== "buyrentlead") {
              const { params } = this.props.match;
              IdOfclient = params.clientId;
            }
            if (
              (fromPage === "investmentLead" || fromPage === "buyrentlead") &&
              editable
            ) {
              if (clientId) {
                IdOfclient = clientId;
              }
            }

            customersContactsArray.push(phone);
            if (contact1.contact1 != "" && contact1.contact1 != "+") {
              customersContactsArray.push({
                countryCode: contact1.countryCode,
                dialCode: contact1.dialCode,
                phone: contact1.contact1,
              });
            }
            if (contact2.contact2 != "" && contact2.contact2 != "+") {
              customersContactsArray.push({
                countryCode: contact2.countryCode,
                dialCode: contact2.dialCode,
                phone: contact2.contact2,
              });
            }

            var cnic =
              formData.cnic != "" && formData.cnic != null
                ? formData.cnic.replace(/-/g, "")
                : null;
            var body = {
              address: formData.address,
              cnic: cnic,
              customersContacts: customersContactsArray,
              email: formData.email,
              first_name: formData.firstName,
              last_name: formData.lastName,
              phone: phone,
              kinContact: kinContact.kinContact,
              secondary_address: formData.mAddress +", "+formData.mCity +", "+ formData.mDistrict +", "+ formData.mProvince +", "+ formData.mCountry,
              relationStatus: formData.relationStatus,
              relativeName: formData.relativeName,
              profession: formData.profession,
              passport: formData.passport,
              country: formData.country,
              province: formData.province,
              district: formData.district,
              city: formData.city,
              mCountry: formData.mCountry,
              mProvince: formData.mProvince,
              mDistrict: formData.mDistrict,
              mCity: formData.mCity,
              mAddress: formData.mAddress,
              nationality: formData.nationality,
              dob: formData.dob,
              clientSource: formData.clientSource,
              nextOfKin: formData.nextOfKin,
              kinRelation: formData.kinRelation,
              // kinCnicFront: formData.kinCnicFront,
              // kinCincBack: formData.kinCincBack,
              motherName: formData.motherName,
              accountType: formData.accountType,
              filer: formData.filer,
              kinCnic: formData.kinCnic,
              //kinContact: formData.kinContact,
              kinEmail: formData.kinEmail,
              bank: formData.bank,
              iBan: formData.iBan,
              accountNo: formData.accountNo,
              accountTitle: formData.accountTitle,
              cnicExpiry: formData.cnicExpiry,
              gender: formData.gender,
              nameOnCnic: formData.nameOnCnic,
              existing_client: ExistingImaratClient,
              other_investment: OtherProjectsInv,
              existing_client_info: formData.existingClientInfo,
              other_investment_info: formData.otherInvestmentInfo,
              // soi : [...soi,otherSourceOfIncome],
              // politicalAssoc: AssociationWithanypoliticalparty,
              // sof : [...sof,sourceOfFunds],
              // fbrRegistered: RegisterWithFBR
            };
            validateIBAN(formData.iBan).then(([result]) => {
              if(result['succeeded']) {
            axios
                .patch(
                  `${config.apiPath}/api/customer/update?id=${IdOfclient}`,
                  body
                )
              .then((res) => {
                this.setState({
                  validationCheck: false,
                  loadingOnsubmit: false,
                  cnicValidation: false,
                  });

                  if (res.data.message && res.data.message !== "") {
                    showToastMsg(res.data.message, "warning");
                } else {
                  showToastMsg(
                      res.data.message && res.data.message !== ""
                      ? res.data.message
                        : "Client has been updated successfully.",
                      "warning"
                    );
                    if (
                      fromPage !== "investmentLead" &&
                      fromPage !== "buyrentlead"
                    ) {
                      urlParams.get('ci') ?
                        this.props.history.push(`/booking/ci?clientId=${urlParams.get('clientId')}&leadId=${urlParams.get('leadId')}`)
                      :
                        this.props.history.push("/clientManagement");
                  } else {
                      apiCallDonClientCreated(body);
                      this.emptyForm();
                  }
                }
              })
              .catch(() => {
                this.setState({
                  validationCheck: false,
                  loadingOnsubmit: false,
                  cnicValidation: false,
                  });
                  showToastMsg("Something went wrong!!", "error");
                });
              } else {
                showToastMsg('invalid IBAN!!!', "error");
                this.setState({
                  validationCheck: false,
                  loadingOnsubmit: false,
                  cnicValidation: false,
                });
              }
              })
          } else {
            var cnic =
              formData.cnic != "" && formData.cnic != null
                ? formData.cnic.replace(/-/g, "")
                : null;
            var body = {
              address:
                formData.address != "" && formData.address != null
                  ? formData.address
                  : null,
              cnic: cnic,
              contact1:
                (contact1 && contact1.contact1 === "") ||
                contact1.contact1 == "+"
                  ? null
                  : contact1,
              contact2:
                (contact2 && contact2.contact2 === "") ||
                contact1.contact1 == "+"
                  ? null
                  : contact2,
              email: formData.email != "" ? formData.email : null,
              first_name: formData.firstName,
              last_name: formData.lastName,
              name: formData.firstName + " " + formData.lastName,
              phone: phone,
              kinContact: kinContact.kinContact,
              secondary_address: formData.mAddress +", "+formData.mCity +", "+ formData.mDistrict +", "+ formData.mProvince +", "+ formData.mCountry,
              relationStatus: formData.relationStatus,
              relativeName: formData.relativeName,
              profession: formData.profession,
              passport: formData.passport,
              country: formData.country,
              province: formData.province,
              district: formData.district,
              city: formData.city,
              mCountry: formData.mCountry,
              mProvince: formData.mProvince,
              mDistrict: formData.mDistrict,
              mCity: formData.mCity,
              mAddress: formData.mAddress,
              nationality: formData.nationality,
              dob: formData.dob,
              clientSource: formData.clientSource,
              clientType: formData.clientType,
              nextOfKin: formData.nextOfKin,
              kinRelation: formData.kinRelation,
              // kinCnicFront: formData.kinCnicFront,
              // kinCincBack: formData.kinCincBack,
              motherName: formData.motherName,
              accountType: formData.accountType,
              filer: formData.filer,
              kinCnic: formData.kinCnic,
              kinEmail: formData.kinEmail,
              bank: formData.bank,
              iBan: formData.iBan,
              accountNo: formData.accountNo,
              accountTitle: formData.accountTitle,
              cnicExpiry: formData.cnicExpiry,
              gender: formData.gender,
              nameOnCnic: formData.nameOnCnic,
              areaIds: this.props.formStates.areaIds,
              leadType: this.props.leadType,
              existing_client: ExistingImaratClient,
              other_investment: OtherProjectsInv,
              existing_client_info: formData.existingClientInfo,
              other_investment_info: formData.otherInvestmentInfo,
              // profilePic: formData.profilePic,
              // cnicFront: formData.cnicFront,
              // cnicBack: formData.cnicBack,
              // kinCnicFront: formData.kinCnicFront,
              // kinCnicBack: formData.kinCnicBack,
              // soi : [...soi,otherSourceOfIncome],
              // politicalAssoc: AssociationWithanypoliticalparty,
              // sof : [...sof,sourceOfFunds],
              // fbrRegistered: RegisterWithFBR
            };
            if (this.props.checkPurchaser) {
              body.bookUnit = true;
            }



            // picData.append('name', document.name);
            // picData.append('attachment', document.attachment);

            validateIBAN(formData.iBan).then(([result]) => {
              if(result['succeeded']) {
            axios
              .post(`${config.apiPath}/api/customer/create`, body)
              .then((res) => {
                if (
                    this.props.leadType === "project" ||
                    this.props.leadType === "buy" ||
                    this.props.leadType === "rent"
                ) {
                    this.props.setLastAddedClient(res.data);
                    this.props.submitlead();
                }
                this.setState({
                  validationCheck: false,
                  loadingOnsubmit: false,
                  });
                if (
                    res.data.message === "CLIENT CREATED" &&
                    (this.props.leadType !== "project" ||
                      this.props.leadType !== "buy" ||
                      this.props.leadType !== "rent")
                ) {
                  if (this.props.checkPurchaser === true) {
                      showToastMsg(
                        "Client has been created successfully",
                        "success"
                      );
                  } else {
                      showToastMsg(
                        "Lead has been created successfully",
                        "success"
                      );
                  }
                  } else if (res.data.message === "Client already exists") {
                    showToastMsg(res.data.message, "error");
                    return;
                } else if (
                    res.data.message === "CLIENT CREATED" &&
                    (this.props.leadType === "project" ||
                      this.props.leadType === "buy" ||
                      this.props.leadType === "rent")
                ) {
                    showToastMsg(
                      "Client has been created successfully",
                      "success"
                    );
                } else {
                    showToastMsg(res.data.message, "error");
                }

                  if (
                    fromPage !== "investmentLead" &&
                    fromPage !== "buyrentlead"
                  ) {
                    urlParams.get('ci') ?
                        this.props.history.push(`/booking/ci?clientId=${urlParams.get('clientId')}&leadId=${urlParams.get('leadId')}`)
                      :
                        this.props.history.push("/clientManagement");
                } else {
                    apiCallDonClientCreated(res.data);
                    this.emptyForm();
                }
              })
              .catch(() => {
                this.setState({
                  validationCheck: false,
                  loadingOnsubmit: false,
                  });
                  showToastMsg("Something went wrong!!", "error");
                });
              } else {
                showToastMsg('invalid IBAN!!!', "error");
                this.setState({
                  validationCheck: false,
                  loadingOnsubmit: false,
                  cnicValidation: false,
                });
              }
            });
          }
        }
      }
    } else {
      if (
        formData.firstName === "" ||
        formData.lastName === "" ||
        phone.phone === "" ||
        phone.phone.length === 1 ||
        phoneValidate === true ||
        ((leadType === "" || leadType === null) && !editAble)
      ) {
        this.scrollTopFunc();
        this.setState({
          validationCheck: true,
          loadingOnsubmit: false,
        });
        showToastMsg("Some Required Fields are missing or invalid", "error")
      } else {
        if (editAble === true) {
          var customersContactsArray = [];
          let IdOfclient = null;
          if (fromPage !== "investmentLead" && fromPage !== "buyrentlead") {
            const { params } = this.props.match;
            IdOfclient = params.clientId;
          }
          if (
            (fromPage === "investmentLead" || fromPage === "buyrentlead") &&
            editable
          ) {
            if (clientId) {
              IdOfclient = clientId;
            }
          }

          customersContactsArray.push(phone);
          if (contact1.contact1 != "" && contact1.contact1 != "+") {
            customersContactsArray.push({
              countryCode: contact1.countryCode,
              dialCode: contact1.dialCode,
              phone: contact1.contact1,
            });
          }
          if (contact2.contact2 != "" && contact2.contact2 != "+") {
            customersContactsArray.push({
              countryCode: contact2.countryCode,
              dialCode: contact2.dialCode,
              phone: contact2.contact2,
            });
          }

          var cnic =
            formData.cnic != "" && formData.cnic != null
              ? formData.cnic.replace(/-/g, "")
              : null;
          var body = {
            address: formData.address,
            cnic: cnic,
            customersContacts: customersContactsArray,
            email: formData.email,
            first_name: formData.firstName,
            last_name: formData.lastName,
            phone: phone,
            kinContact: kinContact.kinContact,
            secondary_address: formData.mAddress +", "+formData.mCity +", "+ formData.mDistrict +", "+ formData.mProvince +", "+ formData.mCountry,
            relationStatus: formData.relationStatus,
            relativeName: formData.relativeName,
            profession: formData.profession,
            passport: formData.passport,
            country: formData.country,
            province: formData.province,
            district: formData.district,
            city: formData.city,
            mCountry: formData.mCountry,
            mProvince: formData.mProvince,
            mDistrict: formData.mDistrict,
            mCity: formData.mCity,
            mAddress: formData.mAddress,
            nationality: formData.nationality,
            dob: formData.dob,
            clientType: formData.clientType,
            clientSource: formData.clientSource,
            nextOfKin: formData.nextOfKin,
            kinRelation: formData.kinRelation,
            // kinCnicFront: formData.kinCnicFront,
            // kinCincBack: formData.kinCincBack,
            motherName: formData.motherName,
            accountType: formData.accountType,
            filer: formData.filer,
            kinCnic: formData.kinCnic,
            kinEmail: formData.kinEmail,
            bank: formData.bank,
            iBan: formData.iBan,
            accountNo: formData.accountNo,
            accountTitle: formData.accountTitle,
            cnicExpiry: formData.cnicExpiry,
            gender: formData.gender,
            nameOnCnic: formData.nameOnCnic,
            soi: [...soi, otherSourceOfIncome],
            politicalAssoc: AssociationWithanypoliticalparty,
            existing_client: ExistingImaratClient,
            other_investment: OtherProjectsInv,
            existing_client_info: formData.existingClientInfo,
            other_investment_info: formData.otherInvestmentInfo,
            sof: [...sof, sourceOfFunds],
            fbrRegistered: RegisterWithFBR,
            // profilePic: formData.profilePic,
            // cnicFront: formData.cnicFront,
            // cnicBack: formData.cnicBack,
            // kinCnicFront: formData.kinCnicFront,
            // kinCnicBack: formData.kinCnicBack,
          };

          // update client payload
          validateIBAN(formData.iBan).then(async ([result])=> {
            if(result['succeeded']) {
              if(checkInvalid() == null || !urlParams.get('ci')){
              await axios
              .patch(
                `${config.apiPath}/api/customer/update?&id=${IdOfclient}`,
                body
              )
              .then(async (res) => {
                // if(
                //  formData.cnicFront !== null && typeof(formData.cnicFront) != 'string'
                //  && formData.cnicBack !== null && typeof(formData.cnicBack) != 'string'
                //  && !!res.data.id
                //  ){
                  await axios
                    .post(`${config.apiPath}/api/customer/customerAttachments?id=${res.data.id}`, {
                      profilePic: formData.profilePic,
                      cnicFront: formData.cnicFront,
                      cnicBack: formData.cnicBack,
                      kinCnicFront: formData.kinCnicFront,
                      kinCnicBack: formData.kinCnicBack,
                    }, {
                    headers: {
                      'Content-Type': 'multipart/form-data',
                    },
                  })
                // }

              this.setState({
                validationCheck: false,
                loadingOnsubmit: false,
                cnicValidation: false,

                });

                if (res.data.message && res.data.message !== "") {
                  showToastMsg(res.data.message, "warning");
              } else {
                  showToastMsg(
                    "Client has been successfully updated.",
                    "success"
                  );
                  if (
                    fromPage !== "investmentLead" &&
                    fromPage !== "buyrentlead"
                  ) {
                    urlParams.get('ci') ?
                        this.props.history.push(`/booking/ci?clientId=${urlParams.get('clientId')}&leadId=${urlParams.get('leadId')}`)
                      :
                        this.props.history.push("/clientManagement");
                } else {
                    apiCallDonClientCreated(body);
                    this.emptyForm();
                }
              }
            })
            .catch(() => {
              this.setState({
                validationCheck: false,
                loadingOnsubmit: false,
                cnicValidation: false,
                });
                showToastMsg("Something Went Wrong!!", "error");
              });
            } else if(checkInvalid() == 'address'){
               this.validationFailed("Your Primary Address required","error")
            } else if(checkInvalid() == 'cnic'){
              this.validationFailed("CNIC details required", "error")
            } else if(checkInvalid() == 'user'){
              this.validationFailed("User Date of birth is required", "error");
            }else if(checkInvalid() == "profession"){
              this.validationFailed("Profession Details Required", "error");
            } else if(checkInvalid() == 'kin'){
              this.validationFailed("Kin Details Required", "error");
            } else if(checkInvalid() == 'others'){
              this.validationFailed("Others section is Required", "error");
        } else {
              this.validationFailed()
            }} else {
              showToastMsg('invalid IBAN!!!', "error");
              this.setState({
                validationCheck: false,
                loadingOnsubmit: false,
                cnicValidation: false,
              });
            }
          });
        } else {
          // create client payload
          var cnic =
            formData.cnic != "" && formData.cnic != null
              ? formData.cnic.replace(/-/g, "")
              : null;
          var body = {
            address:
              formData.address != "" && formData.address != null
                ? formData.address
                : null,
            cnic: cnic,
            contact1:
              (contact1 && contact1.contact1 === "") || contact1.contact1 == "+"
                ? null
                : contact1,
            contact2:
              (contact2 && contact2.contact2 === "") || contact1.contact1 == "+"
                ? null
                : contact2,
            email: formData.email != "" ? formData.email : null,
            first_name: formData.firstName,
            last_name: formData.lastName,
            name: formData.firstName + " " + formData.lastName,
            phone: phone,
            kinContact: kinContact.kinContact,
            secondary_address: formData.mAddress +", "+formData.mCity +", "+ formData.mDistrict +", "+ formData.mProvince +", "+ formData.mCountry,
            relationStatus: formData.relationStatus,
            relativeName: formData.relativeName,
            profession: formData.profession,
            passport: formData.passport,
            country: formData.country,
            province: formData.province,
            district: formData.district,
            city: formData.city,
            mCountry: formData.mCountry,
            mProvince: formData.mProvince,
            mDistrict: formData.mDistrict,
            mCity: formData.mCity,
            mAddress: formData.mAddress,
            nationality: formData.nationality,
            dob: formData.dob,
            clientSource: formData.clientSource,
            clientType: formData.clientType,
            nextOfKin: formData.nextOfKin,
            kinRelation: formData.kinRelation,
            // kinCnicFront: formData.kinCnicFront,
            // kinCincBack: formData.kinCincBack,
            motherName: formData.motherName,
            accountType: formData.accountType,
            filer: formData.filer,
            kinCnic: formData.kinCnic,
            kinEmail: formData.kinEmail,
            bank: formData.bank,
            iBan: formData.iBan,
            accountNo: formData.accountNo,
            accountTitle: formData.accountTitle,
            cnicExpiry: formData.cnicExpiry,
            gender: formData.gender,
            nameOnCnic: formData.nameOnCnic,
            areaIds:
              this.props.formStates && this.props.formStates.areaIds
                ? this.props.formStates.areaIds
                : null,
            leadType: this.props.leadType,
            soi: [...soi, otherSourceOfIncome],
            politicalAssoc: AssociationWithanypoliticalparty,
            existing_client: ExistingImaratClient,
            other_investment: OtherProjectsInv,
            existing_client_info: formData.existingClientInfo,
            other_investment_info: formData.otherInvestmentInfo,
            sof: [...sof, sourceOfFunds],
            fbrRegistered: RegisterWithFBR,
            profession:formData.profession
            // profilePic: formData.profilePic,
            // cnicFront: formData.cnicFront,
            // cnicBack: formData.cnicBack,
            // kinCnicFront: formData.kinCnicFront,
            // kinCnicBack: formData.kinCnicBack,
          };

          if (this.props.leadType === "project" && !this.props.cityId) {
            this.scrollTopFunc();
            this.props.submitlead();
            this.setState({
              validationCheck: true,
              loadingOnsubmit: false,
            });
          } else if (
            (this.props.leadType === "buy" || this.props.leadType === "rent"  || this.props.leadType === "sell"  || this.props.leadType === "rentOut") &&
            (!this.props.cityId ||
              !this.props.type ||
              !this.props.subtype ||
              !this.props.areaIds)
          ) {
            this.scrollTopFunc();
            this.props.submitlead();
            this.setState({
              validationCheck: true,
              loadingOnsubmit: false,
            })
          } else {

            validateIBAN(formData.iBan).then(async ([result]) => {
              if(result['succeeded']) {
                await axios
              .post(`${config.apiPath}/api/customer/create`, body)
                  .then(async (res) => {
                    if((formData.cnicFront || formData.cnicBack || formData.kinCnicFront || formData.kinCnicBack || formData.profilePic) && res.data.id)
                    await axios
                    .post(`${config.apiPath}/api/customer/customerAttachments?id=${res.data.id}`, {
                      profilePic: formData.profilePic,
                      cnicFront: formData.cnicFront,
                      cnicBack: formData.cnicBack,
                      kinCnicFront: formData.kinCnicFront,
                      kinCnicBack: formData.kinCnicBack,
                    }, {
                    headers: {
                      'Content-Type': 'multipart/form-data',
                    },
                  })
                if (
                  this.props.leadType === "project" ||
                  this.props.leadType === "buy" ||
                  this.props.leadType === "rent"
                  || this.props.leadType === "sell"  || this.props.leadType === "rentOut"
                ) {
                      this.props.setLastAddedClient(res.data);
                      res.data.message !== "Client already exists" &&
                        res.data.message !==
                          "An error occurred while creating client" &&
                        this.props.submitlead();
                }
                if (
                  res.data.message === "CLIENT CREATED" &&
                  this.props.leadType !== "project" &&
                  this.props.leadType !== "buy" &&
                  this.props.leadType !== "rent" &&
                  this.props.leadType !== "sell"  && this.props.leadType !== "rentOut"
                ) {
                  if (this.props.activeClientModal) {
                        this.props.clientModalToggle();
                        this.emptyForm();
                  }
                      showToastMsg(
                        "Client has been registered successfully",
                        "success"
                      );
                  if (
                    this.props &&
                    this.props.path &&
                    this.props.path.pathname &&
                        this.props.path.pathname === "/available-inventory"
                  ) {
                        this.props.onchangeClient(res.data);
                  }
                } else if (
                  res.data.message === "Client already exists" ||
                  res.data.message === "An error occurred while creating client"
                ) {
                  this.setState({
                    validationCheck: false,
                    loadingOnsubmit: false,
                  });
                  showToastMsg(res.data.message, "error");
                  return;
                } else if (
                  res.data.message === "CLIENT CREATED" &&
                  (this.props.leadType === "project" ||
                    this.props.leadType === "buy" ||
                    this.props.leadType === "rent" ||
                     this.props.leadType === "sell"  || this.props.leadType === "rentOut")
                ) {
                  setTimeout(() => {
                        if (this.props.addLead && !(
                          this.props.addLead === "Lead cannot be created as same lead already exists:" || this.props.addLead.message === "Lead cannot be created as same lead already exists:"
                          )) {
                            if (res.data.obj && res.data.obj.info && res.data.obj.info.duplicateDealerExists) {
                              showToastMsg(
                                `${
                                  this.props.leadType ? this.props.leadType : ""
                                } lead have been assigned to already existing client.`,
                                "success"
                              );
                            }
                            else {
                    showToastMsg(
                      `Client and ${
                                  this.props.leadType ? this.props.leadType : ""
                      } lead have been registered successfully.`,
                                "success"
                              );
                            }
                          if (this.state.redirectionModal) {
                    this.setState({
                      openConnectModal: true,
                      validationCheck: false,
                      loadingOnsubmit: false,
                              redirectionType: "client",
                            });
                          }

                          this.props.history.push("/diary", {
                      send_data: {
                        getCallFromCustomer: true,
                              diaryId: this.props.addLead.diaryId
                                ? this.props.addLead.diaryId
                                : this.props.lastAddedLead.diaryId,
                            },
                          })
                        }
                        else {
                          // showToastMsg("Lead cannot be created as same lead already exists", "error");

                          this.setState({
                            validationCheck: false,
                            loadingOnsubmit: false,
                          });
                        }

                  }, 8000)
                } else {
                      showToastMsg(res.data.message, "error");
                      this.setState({
                        validationCheck: false,
                        loadingOnsubmit: false,
                      });
                }

                if (
                  fromPage !== "investmentLead" &&
                  fromPage !== "buyrentlead" &&
                  this.props.leadType !== "project" &&
                  this.props.leadType !== "buy" &&
                  this.props.leadType !== "rent" &&
                  this.props.leadType !== "sell"  && this.props.leadType !== "rentOut"
                ) {
                      urlParams.get('ci') ?
                        this.props.history.push(`/booking/ci?clientId=${urlParams.get('clientId')}&leadId=${urlParams.get('leadId')}`)
                      :
                        this.props.history.push("/clientManagement");
                    } else if (
                      fromPage === "investmentLead" &&
                      fromPage === "buyrentlead"
                    ) {
                      apiCallDonClientCreated(res.data);
                      this.emptyForm();
                }
                if (
                  res.data.message === "CLIENT CREATED" &&
                  this.props.leadType !== "project" &&
                  this.props.leadType !== "buy" &&
                  this.props.leadType !== "rent" &&
                  this.props.leadType !== "sell"  && this.props.leadType !== "rentOut"
                ) {
                      this.setState({
                        validationCheck: false,
                        loadingOnsubmit: false,
                      });
                }
              })
                  .catch((err) => {
                this.setState({
                  validationCheck: false,
                  loadingOnsubmit: false,
                    });
                    showToastMsg(
                      "An error occurred while creating client.",
                      "error"
                    );
                  });
              } else {
                showToastMsg('invalid IBAN!!!', "error");
                this.setState({
                  validationCheck: false,
                  loadingOnsubmit: false,
                  cnicValidation: false,
                });
          }
            });
        }
      }
    }
  }
  };
  fetchAgents = (data, page, LeadType) => {
    const role_id = localStorage.getItem("roleId");
    const leadType = capitalizeLeadTypeFirstLetter(this.props.leadType);
    this.setState({
      shareloader: true,
    });
    axios
      .get(
        LeadType === "assign"
          ? `${
              config.apiPath
            }/api/role/sub-users?roleId=${role_id}&addManager=${true}&page=${page}&pageSize=${
              config.pageSize
            }&web=true&leadType=${leadType}`
          : `${
              config.apiPath
            }/api/user/agents?sharing=${true}&page=${page}&pageSize=${
              config.pageSize
            }&web=true&leadType=${leadType}`
      )
      .then((res) => {
        this.setState({
          agents: LeadType === "assign" ? res.data : res.data.rows,
          agentCount: LeadType !== "assign" ? res.data.count : "",
          shareloader: false,
        });
      });
  };
  shareToggle = (shareLeadType) => {
    const { shareToggleState } = this.state;
    this.fetchAgents(null, 1, "assign");
    this.setState({
      shareToggleState: !shareToggleState,
      shareLeadType: shareLeadType,
      bulkAction: true,
    });
  };
  closeActivePostRegModal = () => {
    this.setState({ activePostRegModal: false });
  };
  openConnectModal = () => {
    this.setState({ openConnectModal: true });
  };
  openRegisterFeedbackModal = () => {
    const leadTaskParams = {
      taskType: ["connect"],
      [this.props.leadType === "project"
        ? "cmLeadId"
        : this.props.leadType === "buy" || this.props.leadType === "rent"
        ? "rcmLeadId"
        : "wantedId"]: this.props.lastAddedLead.id,
    };
    this.props.fetchLeadTasks(
      this.props.lastAddedLead.id,
      leadTaskParams,
      (data) => {
      // task = data[0]
      this.setState({
        newtask: {
          ...data[0],
            [this.props.leadType === "project"
              ? "armsProjectLeadId"
              : "armsLeadId"]: this.props.lastAddedLead.id,
            [this.props.leadType === "project"
              ? "armsProjectLead"
              : "armsLead"]: this.props.lastAddedLead,
        },
        registerFeedbackModal: true,
        });
  }
    );
  };
  read = (details) => {
    const { loginUserId } = this.state;
    const loginUserRole = localStorage.getItem("role");
    let returnObject = {
      ownLeadReadOnly: false,
      showReadOnly: false,
      checkForManager: false,
    };
    let armUser =
      details && details.armsuser && details.armsuser.id
        ? details.armsuser.id
        : null;
    let shareUser =
      details && details.shareUser && details.shareUser.id
        ? details.shareUser.id
        : null;
    if (
      parseInt(loginUserId) === parseInt(armUser) ||
      parseInt(shareUser) === parseInt(loginUserId)
    ) {
      returnObject["ownLeadReadOnly"] = true;
    }
    if (loginUserRole === "sub_admin 1") {
      returnObject["checkForManager"] = true;
    }
    if (
      (details &&
        details.armsuser &&
        parseInt(loginUserId) !== parseInt(armUser)) ||
      (details && details.status === "closed_lost") ||
      (details && details.status === "closed_won")
    ) {
      returnObject["showReadOnly"] = true;
    }
    return returnObject;
  };
  additionalInfoToggle = () => {
    this.setState({
      additionalInfoToggleState: !this.state.additionalInfoToggleState,
    });
  };
  render() {
    const {
      formData,
      phone,
      kinContact,
      contact1,
      contact2,
      validationCheck,
      loadingOnsubmit,
      cnicValidation,
      phoneValidate,
      phoneValidate1,
      phoneValidate2,
      kinPhoneValidate,
      emailValidate,
      editAble,
      activePostRegModal,
      openConnectModal,
      registerFeedbackModal,
      newtask,
      diaryModal,
      taskModal,
      guideReferenceModal,
      additionalInfoToggleState,
      slotModal,
      fetchLeadsInModal,
      shareToggleState,
      shareloader,
      agents,
      agentCount,
      shareLeadType,
      isBookingFor,
      redirectionType,
      soi,
      sof,
      AssociationWithanypoliticalparty,
      ExistingImaratClient,
      OtherProjectsInv,
      RegisterWithFBR,
      otherSourceOfIncome,
      sourceOfFunds,
    } = this.state;
    const {
      fromPage,
      activeClientModal,
      shareToggle,
      clientModalToggle,
      editable,
      checkPurchaser,
      leadType,
      history,
      addLead,
    } = this.props;

    return (
      <Fragment>
        {registerFeedbackModal && (
          <ConnectFlow
            setConnectModal={(toggle) => {
              this.setState({
                taskModal: toggle,
                registerFeedbackModal: false,
                openConnectModal: toggle,
              });
            }}
            setConnectFlow={() => {
              this.props.history.push("/clientManagement");
              this.setState({ registerFeedbackModal: false });
            }}
            diary={newtask}
            actionType={"Connect"}
            callback={() => {
              this.setState({ registerFeedbackModal: false });
              // this.handleQueryChange(currentPage, pageSize)
            }}
          />
        )}
        {openConnectModal && (
          <ConnectModal
            fromClient={true}
            redirectionType={redirectionType}
            history={history}
            connectModal={openConnectModal}
            lead={this.props.lastAddedLead}
            leadType={capitalizeLeadTypeFirstLetter(this.props.leadType)}
            action={null}
            checkForRead={this.read(this.props.lastAddedLead)}
            setNewTask={(newTask) => {
              this.openRegisterFeedbackModal();
              this.setState({ openConnectModal: false });
            }}
            onAction={(action) => {
              if (action === "scheduled-task")
                this.setState({ diaryModal: true });
              else if (action === "additional-details")
                this.setState({ additionalInfoToggleState: true });
              else if (action === "refer-lead") this.shareToggle("share");
              else if (action === "reassign-lead") this.shareToggle("assign");
              else if (action === "investment-guide")
                this.setState({ guideReferenceModal: true });
              else if (action === "close-as-duplicate")
                this.setState({ guideReferenceModal: true });
              else if (action === "add-meeting")
                this.setState({ slotModal: true });
            }}
            // callBack={(action) => {}}
            setConnectModal={(toggle) =>
              this.setState({ openConnectModal: toggle })
            }
            setLeadTaskModal={(toggle) =>
              this.setState({ openConnectModal: toggle })
            }
            setCallRecord={(callRecord) => {
              this.setState({ openConnectModal: false });
            }}
          />
        )}
        <PostRegModal
          activePostRegModal={activePostRegModal}
          toggle={clientModalToggle}
          closeModal={this.closeActivePostRegModal}
          setRef={(ele) => this.setScrollRef(ele)}
          leadType={leadType}
          history={history}
          openConnectModal={this.openConnectModal}
          openRegisterFeedbackModal={this.openRegisterFeedbackModal}
          closeActivePostRegModal={this.closeActivePostRegModal}
          // shareToggle={shareToggle}
        />
        {fromPage !== "investmentLead" && fromPage !== "buyrentlead" ? (
          <div ref={this.setScrollRef} className={`mainAddClient clientInner`}>
            <Fragment>
              {/* <div className={'addPageHeading'}> */}
              {/* <h4>{editAble === true ? 'Edit' : 'Register'} Client</h4> */}
              {/* <h4> Client Form</h4> */}
              {/* </div> */}
              <div className={`clientFilterMainWrap`}>
                <Inner
                  valueCheck={this.valueCheck}
                  userId={this.state.userId}
                  formData={formData}
                  phone={phone}
                  contact1={contact1}
                  contact2={contact2}
                  kinContact={kinContact}
                  loadingOnsubmit={loadingOnsubmit}
                  validationCheck={validationCheck}
                  cnicValidation={cnicValidation}
                  phoneValidate={phoneValidate}
                  phoneValidate1={phoneValidate1}
                  phoneValidate2={phoneValidate2}
                  kinPhoneValidate={kinPhoneValidate}
                  emailValidate={emailValidate}
                  editAble={editAble}
                  handleChange={this.handleChange}
                  handleChangePhone={this.handleChangePhone}
                  handleChangeContact1={this.handleChangeContact1}
                  handleChangeContact2={this.handleChangeContact2}
                  handleChangeKin={this.handleChangeKin}
                  resetData={this.resetData}
                  createClient={this.createClient}
                  handleDob={this.handleDob}
                  checkPurchaserPage={checkPurchaser}
                  isBookingFor={isBookingFor}
                  SourceOfIncomehandle={this.SourceOfIncomehandle}
                  SourceOfFundsHandler={this.SourceOfFundsHandler}
                  otherSourceOfIncomeHandler={this.otherSourceOfIncomeHandler}
                  otherSourceOfFundsHandler={this.otherSourceOfFundsHandler}
                  AssociationWithanypoliticalpartyHandler={
                    this.AssociationWithanypoliticalpartyHandler
                  }
                  AssociationWithanypoliticalparty={
                    AssociationWithanypoliticalparty
                  }
                  ExistingImaratClientHandler={
                    this.ExistingImaratClientHandler
                  }
                  ExistingImaratClient={
                    ExistingImaratClient
                  }
                  OtherProjectsInvHandler={
                    this.OtherProjectsInvHandler
                  }
                  OtherProjectsInv={
                    OtherProjectsInv
                  }
                  RegisterWithFBRHandler={this.RegisterWithFBRHandler}
                  RegisterWithFBR={RegisterWithFBR}
                  soi={soi}
                  sourceOfFunds={sourceOfFunds}
                  otherSourceOfIncome={otherSourceOfIncome}
                  sof={sof}
                />
              </div>
            </Fragment>
          </div>
        ) : (
          <ClinetCreationModal
            activeClientModal={activeClientModal}
            shareToggle={shareToggle}
            clientModalToggle={clientModalToggle}
            formData={formData}
            phone={phone}
            setRef={(ele) => this.setScrollRef(ele)}
            contact1={contact1}
            contact2={contact2}
            loadingOnsubmit={loadingOnsubmit}
            validationCheck={validationCheck}
            cnicValidation={cnicValidation}
            showAdditionInfo={this.props.showAdditionInfo}
            phoneValidate={phoneValidate}
            phoneValidate1={phoneValidate1}
            phoneValidate2={phoneValidate2}
            kinPhoneValidate={kinPhoneValidate}
            emailValidate={emailValidate}
            editAble={editAble}
            fromPage={fromPage}
            handleChange={this.handleChange}
            handleChangePhone={this.handleChangePhone}
            handleChangeContact1={this.handleChangeContact1}
            handleChangeContact2={this.handleChangeContact2}
            handleChangeKin={this.handleChangeKin}
            resetData={this.resetData}
            handleDob={this.handleDob}
            createClient={this.createClient}
            checkPurchaserPage={checkPurchaser}
            SourceOfIncomehandle={this.SourceOfIncomehandle}
            SourceOfFundsHandler={this.SourceOfFundsHandler}
            otherSourceOfIncomeHandler={this.otherSourceOfIncomeHandler}
            otherSourceOfFundsHandler={this.otherSourceOfFundsHandler}
            AssociationWithanypoliticalpartyHandler={
              this.AssociationWithanypoliticalpartyHandler
            }
            AssociationWithanypoliticalparty={
              AssociationWithanypoliticalparty
            }
            ExistingImaratClientHandler={
              this.ExistingImaratClientHandler
            }
            ExistingImaratClient={
              ExistingImaratClient
            }
            OtherProjectsInvHandler={
              this.OtherProjectsInvHandler
            }
            OtherProjectsInv={
              OtherProjectsInv
            }
            RegisterWithFBRHandler={this.RegisterWithFBRHandler}
            RegisterWithFBR={RegisterWithFBR}
            soi={soi}
            sourceOfFunds={sourceOfFunds}
            otherSourceOfIncome={otherSourceOfIncome}
            sof={sof}
            valueCheck={this.valueCheck}
            kinContact={kinContact}
          />
        )}
        {guideReferenceModal && (
          <ReferenceGuideModal
            leadId={this.props.lastAddedLead && this.props.lastAddedLead.id}
            callBack={() => {
              this.setState({ guideReferenceModal: false });
              // this.handleQueryChange(1, pageSize)
            }}
            guideReferenceModal={guideReferenceModal}
            setGuideReferenceModal={(toggle) =>
              this.setState({ guideReferenceModal: toggle })
            }
          />
        )}
        {diaryModal && (
          <DiaryModal
            leadType={capitalizeLeadTypeFirstLetter(leadType)}
            checkForRead={isReadOnly(
              this.props.lastAddedLead,
              this.props.leadType
            )}
            diaryModal={diaryModal}
            setDiaryModal={(toggle) => this.setState({ diaryModal: toggle })}
            setLeadTaskModal={(toggle) =>
              this.setState({ leadTaskModal: toggle })
            }
            fetchLeadsInModal={fetchLeadsInModal}
            setFetchLeadsInModal={(toggle) => {
              this.setState({ fetchLeadsInModal: toggle });
              // this.handleQueryChange(currentPage, pageSize)
            }}
            lead={this.props.lastAddedLead}
          />
        )}
        <SlotModal
          slotModal={slotModal}
          setSlotModal={(toggle) => this.setState({ slotModal: toggle })}
          title={`Select slot for Meeting Task`}
          taskType={"meeting"}
          callBack={(bookedTime) => {
            this.setState({ slotModal: false });
            diaryCreateUpdateAPI(
              makeTaskTimePayload(bookedTime, "meeting", {
                leadId: this.props.lastAddedLead && this.props.lastAddedLead.id,
              }),
              true,
              () =>
                this.setState({ updateLeadTasks: !this.state.updateLeadTasks })
            );
          }}
        />
        {additionalInfoToggleState && (
          <AdditionalDetails
            onToggle={this.additionalInfoToggle}
            isOpen={additionalInfoToggleState}
            additionalDetailLeadrecord={this.props.lastAddedLead}
            checkLeadtype={capitalizeLeadTypeFirstLetter(leadType)}
          ></AdditionalDetails>
        )}
        <ReferModal
          shareToggleState={shareToggleState}
          shareToggle={this.shareToggle}
          managerSelectionDropdown={managerSelectionDropdown}
          loader={shareloader}
          agents={agents}
          fetchAgents={this.fetchAgents}
          filterReferLeadAgentName={this.filterReferLeadAgentName}
          investmentLeadShareFun={this.investmentLeadReferFun}
          assignLeadFun={this.assignLeadFun}
          shareLeadType={shareLeadType}
          agentCount={agentCount}
        />
      </Fragment>
    );
  }
}
const onSubmit = (values, dispatch) => {
  dispatch(submit("leadCreationForms"));
};
AddClent = reduxForm({
  form: "leadCreationForms",
  onSubmit,
})(AddClent);

const selector = formValueSelector("leadCreationForms");
AddClent = connect((state) => {
  const cityId = selector(state, "cityId");
  const type = selector(state, "type");
  const subtype = selector(state, "subtype");
  const areaIds = selector(state, "areaIds");
  const purpose = selector(state, "leadType");

  return {
    cityId,
    type,
    subtype,
    areaIds,
    purpose,
  };
})(AddClent);
export const mapStateTopProps = (state, ownProps) => {
  return {
    client: state.user.client,
    leadType: state.user.leadType,
    isLeadClientError: state.user.isLeadClientError,
    addLead: state.Leads.addLead,
    lastAddedLead: state.Leads.lastAddedLead,
    formStates: getFormValues("leadCreationForms")(state),
  };
};

export const mapDispatchTopProps = (dispatch) => {
  return {
    setTitle: (title) => dispatch(setTitle(title)),
    setLastAddedClient: (client) => dispatch(setLastAddedClient(client)),
    fetchLeadTasks: (leadId, params, callback) =>
      dispatch(fetchLeadTasks(leadId, params, callback)),
    submitlead: () => dispatch(submit("leadCreationForms")),
  };
};
export default connect(mapStateTopProps, mapDispatchTopProps)(AddClent);
