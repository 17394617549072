/** @format */

import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { connect } from 'react-redux'
import CallIconModal from '../../images/accounts.svg'
import WtsappIcon from '../../images/wtsapp.svg'
import PhoneIcon from '../../images/call2.svg'
import { makeCall } from '../../actions/leads'
import moment from 'moment'
import './style.scss'
import { normalizePhoneFormat } from '../../utils/formatPhone'
import DeviceModal from '../../components/Modals/DeviceModal'
import { showErrorMessage } from '../../utils/commonFormat'
import ConfirmModal from '../Modals/DeviceModal/Confirm'
import { isREA, scrollToBottom } from '../../utils/helper'
import { CallIcon } from '../Call/CallIcon'
import dialer_agent from '../Dialer/dailer_agent'
const InvestmentCallModal = (props) => {
  const {
    callingFrom,
    callToggleType,
    callToggleState,
    callToggle,
    customersPhoneNumbers,
    addInvestmentCallFun,
    customerPhoneLoader,
    makeCall,
    callLogs,
    accountUserData,
    checkForRead,
    showAlertToggle,
    user,
    item,
    callLogitem,
    tableDataFrom,
    lead,
    leadType,
    isDemandLead
  } = props
  let callLogItem = callLogitem
    ? callLogitem
    : item && item.armsProperty
    ? item.armsProperty
    : item && item.property
    ? item && item.property
    : null

  const [showDevicesModal, setShowDevicesModal] = useState(false)
  const [confirmModal, setConfirmModal] = useState(false)
  const [device, setDevice] = useState(false)
  const [showCallModal, setShowCallModal] = useState(true)
  const [phone, setPhone] = useState(false)
  const [showLoader, setShowLoader] = useState(false)
  const [agent, setAgent] = useState({});


  useEffect(() => {
    dialer_agent().then((result)=>{
      if (result.success){
        setAgent(result)
      } else {
        setAgent({});
      }
    });
  }, []);

  useEffect(() => {
    scrollToBottom('mainScroll')
  }, [callLogs])

  const setAgentName = (tableDataFrom, callLogItem) => {
    if(isDemandLead) {
      return lead && lead.armsuser ? `${lead.armsuser.firstName} ${lead.armsuser.lastName}` : ''
    } else {
      if (tableDataFrom === 'graana') {
        return callLogItem.user.first_name + ' ' + callLogItem.user.last_name
      } else if (tableDataFrom === 'fieldApp') {
        return callLogItem.rider.first_name + ' ' + callLogItem.rider.last_name
      } else if (tableDataFrom === 'arms') {
        return (
          callLogItem &&
          callLogItem.armsuser &&
          callLogItem.armsuser.firstName + ' ' + callLogItem.armsuser.lastName
        )
      }
    }
  }

  const setAgentPhone = (tableDataFrom, callLogItem) => {
    if(isDemandLead) {
      return lead && lead.armsuser ? lead.armsuser.phoneNumber : ''
    } else {
      if (tableDataFrom === 'graana') {
        return callLogItem.user.phone
      } else if (tableDataFrom === 'fieldApp') {
        return callLogItem.rider.phone
      } else if (tableDataFrom === 'arms') {
        return callLogItem.armsuser !== undefined ? callLogItem.armsuser.phoneNumber : null
      }
    }
  }

  let mobileNumbers =
    customersPhoneNumbers &&
    customersPhoneNumbers.customerContacts &&
    customersPhoneNumbers.customerContacts.length === 1
      ? 'col-12'
      : 'col-12'
  let callWtsappCheckComment =
    (checkForRead && checkForRead.ownLeadReadOnly === true) ||
    (checkForRead && !checkForRead.showReadOnly)

  let property = item && item.armsProperty ? item && item.armsProperty : item && item.property
  let userId = parseInt(localStorage.getItem('user_id'), 10)

  return (
    <>
      {showDevicesModal ? (
        <DeviceModal
          makeCall={(device) =>{
            makeCall(
              {
                firstName: customersPhoneNumbers.first_name,
                lastName: customersPhoneNumbers.last_name,
                countryCode: customersPhoneNumbers.countryCode ? customersPhoneNumbers.countryCode.toUpperCase() : null,
                dialCode: customersPhoneNumbers.dialCode ? customersPhoneNumbers.dialCode.toUpperCase() : null,
                number: phone,
                role: callToggleType === 'accountUser' ? 'Accounts' : 'Client',
                deviceId: device.id,
                leadType : !!leadType ? leadType : null,
                leadId : !!lead ? lead.id : null,
              },
              (res) => {
                if (res.data.status === true) {
                  setShowCallModal(false)
                  setShowDevicesModal(false)
                  setConfirmModal(true)
                  setDevice(device)
                } else showErrorMessage("Couldn't access your device!")
              }
            )
          }

          }
          phone={phone}
          setPhone={setPhone}
          showDevicesModal={showDevicesModal}
          setShowDevicesModal={(toggle) => setShowDevicesModal(toggle)}
        />
      ) : (
        <></>
      )}

      {confirmModal ? (
        <ConfirmModal
          setConfirmModal={(toggle) => setConfirmModal(toggle)}
          confirmModal={confirmModal}
          callBack={() => {
            setConfirmModal(false)
            addInvestmentCallFun('phone', phone)
          }}
          device={device}
        />
      ) : (
        <></>
      )}



      {showCallModal ? (
        <Modal
          isOpen={callToggleState}
          toggle={callToggle}
          size="lg"
          style={{ maxWidth: '890px', width: '100%' }}
        >
          <ModalHeader>
            {callToggleType === 'accountUser' ? 'Contact Accounts Team ' : 'Call'}
            {(callLogs && !callLogs.length) || callingFrom !== 'inner'
              ? props.tableView !== 'ArmsView' && (
                  <>
                    {customersPhoneNumbers && customersPhoneNumbers.first_name ? ' to ' : ''}
                    {`${(customersPhoneNumbers && customersPhoneNumbers.first_name) || ''} ${
                      (customersPhoneNumbers && customersPhoneNumbers.last_name) || ''
                    }`}
                  </>
                )
              : ' Logs'}
            <div className="close-icon-container">
              <i className="fal fa-times-circle close-icon" onClick={() => callToggle(true)}></i>
            </div>
          </ModalHeader>
          <ModalBody>
            <div className="flex__call__wrap">
              <div className="call__logs__scroll" id="mainScroll">
                {callingFrom === 'inner' && callLogs && callLogs.length > 0 ? (
                  callLogs.map((item, i) => {
                    return (
                      <div key={i} className="main__call__logs">
                        <div className="callLogs__icon">
                          <img src={item.calledOn === 'whatsApp' ? WtsappIcon : PhoneIcon} />
                        </div>
                        <div className="callLogs__content">
                          <span>
                            {item && item.user && item.user.firstName} {item.user.lastName}{' '}
                          </span>
                          {item.subject}
                          {callWtsappCheckComment ? (
                            <span className="phoneNumber">
                              {item.calledNumber !== null ? ` (${item.calledNumber})` : ''}
                            </span>
                          ) : null}
                          <p className="redColor">{item.response != null ? item.response : ''}</p>
                        </div>
                        <div className="callLogs__time">
                          {/* 03:30PM Wed,Oct 28 */}
                          {moment(item.createdAt).format('dddd MMM D, LT')}
                        </div>
                      </div>
                    )
                  })
                ) : (
                  <div className="call-image-container">
                    <img src={CallIconModal} className="container-image" />
                  </div>
                )}
              </div>
     <div className={`container-body  ${props.tableView === 'ArmsView' && 'ml-5'}`}>
                {(item && item.armsuser === null && item.property && props.tableView === 'ArmsView')
               ? (
                  <div className="row borderLine">
                    <div>
         <div className="mb-3">
                        {' '}
                        <h5> Owner : {callLogItem ? callLogItem.owner_name : null}</h5>
                      </div>
                      {callLogItem && callLogItem.owner_phone ? (
                        <div className={`call-connect`}>
                          <span
                            className={`customer-phone-number ${
                              callWtsappCheckComment ? '' : 'disableHover'
                            }`}
                            onClick={() => {
                              if (callWtsappCheckComment) {
                                if (callLogItem.owner_phone) {
                                  setShowDevicesModal(true)
                                  setPhone(callLogItem.owner_phone)
                                }
                              } else {
                                showAlertToggle()
                              }
                            }}
                          >
                            <i className="fa fa-phone"></i>

                            {callWtsappCheckComment
                              ? normalizePhoneFormat(
                                  callLogItem.owner_phone && callLogItem.owner_phone
                                )
                              : ''}
                          </span>
                          <CallIcon
                            showLoader={showLoader}
                            setShowLoader={setShowLoader}
                            phone={callLogItem.owner_phone}
                            setPhone={setPhone}
                            leadId={lead && lead.id}
                            agent={agent}
                          />
                          <div
                            className={`call__icons ${
                              callWtsappCheckComment ? '' : 'disableHover'
                            }`}
                          >
                            <img
                              src={WtsappIcon}
                              alt=""
                              onClick={() => {
                                if (callWtsappCheckComment) {
                                  addInvestmentCallFun('whatsApp', callLogItem.owner_phone)
                                } else {
                                  showAlertToggle()
                                }
                              }}
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="row mb-2 ml-1">
                          <div className="text-center colNotDataFound">No Contact Data Found</div>
                        </div>
                      )}
                    </div>
                  </div>
                ) : null}
                {(item && item.armsuser === null && item.property && props.tableView === 'ArmsView')  ? (
                  <div className="row borderLine">
                    <div>
                      <div className="mb-3">
                        <h5>
                          {' '}
                          Point Of Contact :{' '}
                          {callLogItem ? callLogItem && callLogItem.poc_name : null}
                        </h5>{' '}
                      </div>
                      {callLogItem &&
                      callLogItem.poc_phone !== null &&
                      callLogItem &&
                      callLogItem.poc_phone ? (
                        <div className={`call-connect tai ml-0`}>
                          <span
                            className={`customer-phone-number ${
                              callWtsappCheckComment ? '' : 'disableHover'
                            }`}
                            onClick={() => {
                              if (callWtsappCheckComment) {
                                if (callLogItem.poc_phone) {
                                  setShowDevicesModal(true)
                                  setPhone(callLogItem.poc_phone)
                                }
                              } else {
                                showAlertToggle()
                              }
                            }}
                          >
                            <i className="fa fa-phone"></i>

                            {callWtsappCheckComment
                              ? normalizePhoneFormat(callLogItem.poc_phone)
                              : ''}
                          </span>
                          <CallIcon
                            showLoader={showLoader}
                            setShowLoader={setShowLoader}
                            phone={callLogItem.poc_phone}
                            setPhone={setPhone}
                            leadId={lead && lead.id}
                            agent={agent}
                          />
                          <div
                            className={`call__icons ${
                              callWtsappCheckComment ? '' : 'disableHover'
                            }`}
                          >
                            <img
                              src={WtsappIcon}
                              alt=""
                              onClick={() => {
                                if (callWtsappCheckComment) {
                                  addInvestmentCallFun('whatsApp', callLogItem.poc_phone)
                                } else {
                                  showAlertToggle()
                                }
                              }}
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="row mb-2 ml-1">
                          <div className="text-center colNotDataFound">No Contact Data Found</div>
                        </div>
                      )}
                    </div>
                  </div>
                ) : null}










                <div className={`row  ${props.tableView === 'ArmsView' && 'mb-2'}`}>
                  {callToggleType === 'accountUser' &&
                  accountUserData &&
                  accountUserData.length > 0 ? (
                    accountUserData.map((item, index) => {
                      return (
                        <>
                          <div className="col-md-2 text-right">
                            {' '}
                            {item && item.firstName + ' ' + item.lastName}
                          </div>
                          <div className="col-md-4">
                            <div className="call-connect">
                              <span
                                className="customer-phone-number"
                                onClick={() => {
                                  setShowDevicesModal(true)
                                  setPhone(item.phoneNumber)
                                }}
                              >
                                <i className="fa fa-phone"></i>
                                {normalizePhoneFormat(item.phoneNumber)}
                              </span>
                            </div>
                          </div>
                        </>
                      )
                    })
                  ) : callToggleType === 'accountUser' &&
                    accountUserData &&
                    accountUserData &&
                    accountUserData.length === 0 && !customerPhoneLoader ? (
                    <div className="col-12">
                      <div className="text-center">No Data Found</div>
                    </div>
                  ) : customerPhoneLoader === true ? (
                    <div className="col-12 ">
                      <div className="notch__center">
                        <span className="fa fa-circle-o-notch fa-spin"></span>
                      </div>
                    </div>
                  ) : null}

                  {
                  props.tableView === 'ArmsView' ?
                    (
                    <>

                        <div>
                          <div className="col-12 p-0">
                            <div className="mb-3">
                              <h5> Advisor : {setAgentName(tableDataFrom, item)} </h5>
                            </div>
                          </div>

                          <div
                            className={`${mobileNumbers} ${
                              props.tableView === 'ArmsView' && 'p-0'
                            }`}
                          >
                            {setAgentPhone(tableDataFrom, item) ? (
                              <div
                                className={`call-connect ${
                                  props.tableView === 'ArmsView' && 'm-0'
                                }`}
                              >
                                <span
                                  className={`customer-phone-number ${
                                    callWtsappCheckComment ? '' : 'disableHover'
                                  }`}
                                  onClick={() => {
                                    if (callWtsappCheckComment) {
                                      if (callLogItem.phone) {
                                        setShowDevicesModal(true)
                                        setPhone(setAgentPhone(tableDataFrom, item))
                                      }
                                    } else {
                                      showAlertToggle()
                                    }
                                  }}
                                >
                                  <i className="fa fa-phone"></i>

                                  {callWtsappCheckComment
                                    ? normalizePhoneFormat(setAgentPhone(tableDataFrom, item))
                                    : ''}
                                </span>
                                <CallIcon
                                  showLoader={showLoader}
                                  setShowLoader={setShowLoader}
                                  phone={setAgentPhone(tableDataFrom, item)}
                                  setPhone={setPhone}
                                  leadId={lead && lead.id}
                                  agent={agent}
                                />
                                <div
                                  className={`call__icons ${
                                    callWtsappCheckComment ? '' : 'disableHover'
                                  }`}
                                >
                                  <img
                                    src={WtsappIcon}
                                    alt=""
                                    onClick={() => {
                                      if (callWtsappCheckComment) {
                                        addInvestmentCallFun(
                                          'whatsApp',
                                          setAgentPhone(tableDataFrom, item)
                                        )
                                      } else {
                                        showAlertToggle()
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                            ) : (
                              <div className="row mb-2 ml-1">
                                <div className="text-center colNotDataFound">
                                  No Contact Data Found
                                </div>
                              </div>
                            )}
                          </div>
                        </div>

                    </>
                  ) : null}
                  {props.tableView !== 'ArmsView' && (
                    <div>
                      {callToggleType === 'accountUser' ? null : customersPhoneNumbers != null &&
                        customersPhoneNumbers.customerContacts &&
                        customersPhoneNumbers.customerContacts.length > 0 ? (
                        customersPhoneNumbers.customerContacts.map((item, index) => {
                          return (
                            <div
                              className={`${mobileNumbers} ${
                                props.tableView === 'ArmsView' && 'p-0'
                              }`}
                            >
                              <div
                                className={`call-connect ${
                                  props.tableView === 'ArmsView' && 'm-0'
                                }`}
                              >
                                <span
                                  className={`customer-phone-number ${
                                    callWtsappCheckComment ? '' : 'disableHover'
                                  }`}
                                  onClick={() => {
                                    if (callWtsappCheckComment) {
                                      if (item.phone) {
                                        setShowDevicesModal(true)
                                        setPhone(item.phone)
                                      }
                                    } else {
                                      showAlertToggle()
                                    }
                                  }}
                                >
                                  <i className="fa fa-phone"></i>

                                  {callWtsappCheckComment ? normalizePhoneFormat(item.phone) : ''}
                                </span>
                                <CallIcon
                                  showLoader={showLoader}
                                  setShowLoader={setShowLoader}
                                  phone={item.phone}
                                  setPhone={setPhone}
                                  leadId={lead && lead.id}
                                  agent={agent}
                                />
                                <div
                                  className={`call__icons ${
                                    callWtsappCheckComment ? '' : 'disableHover'
                                  }`}
                                >
                                  <img
                                    src={WtsappIcon}
                                    alt=""
                                    onClick={() => {
                                      if (callWtsappCheckComment) {
                                        addInvestmentCallFun('whatsApp', item.phone)
                                      } else {
                                        showAlertToggle()
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          )
                        })
                      ) : customerPhoneLoader === true ? (
                        <div className="col-12 ">
                          <div className="notch__center">
                            <span className="fa fa-circle-o-notch fa-spin"></span>
                          </div>
                        </div>
                      ) : (
                        props.tableView !== 'ArmsView' && (
                          <div className="col-12">
                            <div className="text-center">No Data Found</div>
                          </div>
                        )
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>

          </ModalBody>
        </Modal>
      ) : (
        <></>
      )}
    </>
  )
}
const mapdispatchToProps = (dispatch) => {
  return {
    makeCall: (data, callback) => dispatch(makeCall(data, callback)),
  }
}

const mapdispatchToState = (state) => {
  return {
    user: state.user,
  }
}

export default connect(mapdispatchToState, mapdispatchToProps)(InvestmentCallModal)
