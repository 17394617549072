/** @format */

import axios from 'axios'
import config from '../config'
import { isREA, shouldShowAllLeads } from '../utils/helper'
import { mapFiltersToQuery } from './../components/common/LeadView/BuyRent/BuyRentUtilHelper'
import {
  FETCH_PROJECT_DEALS,
  FETCH_PROJECT_LEADS,
  FETCH_PROJECT_REFERRED_LEADS,
  FETCH_PROJECT_TEAM_DEALS,
  FETCH_PROJECT_TEAM_LEADS,
} from './projectLeads'
axios.defaults.withCredentials = true

export const FETCH_BUYRENT_LEADS = 'FETCH_BUYRENT_LEADS'
export const FETCH_BUYRENT_COMPANY_LEADS = 'FETCH_BUYRENT_COMPANY_LEADS'
export const FETCH_BUYRENT_MY_LEADS = 'FETCH_BUYRENT_MY_LEADS'
export const SET_BUYRENT_LEADS_COUNT_FILTER = 'SET_BUYRENT_LEADS_COUNT_FILTER'
export const FETCH_BUYRENT_DEACTIVATED_LEADS = 'FETCH_BUYRENT_DEACTIVATED_LEADS'
export const FETCH_BUYRENT_DEACTIVATED_DEALS = 'FETCH_BUYRENT_DEACTIVATED_DEALS'
export const FETCH_BUYRENT_DEALS = 'FETCH_BUYRENT_DEALS'
export const FETCH_BUYRENT_TEAM_LEADS = 'FETCH_BUYRENT_TEAM_LEADS'
export const FETCH_BUYRENT_TEAM_DEALS = 'FETCH_BUYRENT_TEAM_DEALS'
export const FETCH_BUYRENT_REFERRED_LEADS = 'FETCH_BUYRENT_REFERRED_LEADS'
export const FETCH_BUYRENT_REFERRED_DEALS = 'FETCH_BUYRENT_REFERRED_DEALS'
export const FETCH_BUYRENT_DEMAND_LEADS = 'FETCH_BUYRENT_DEMAND_LEADS'
export const SET_BUYRENT_LEADS_FILTERS = 'SET_BUYRENT_LEADS_FILTERS'

export const getBuyRentLeads = (
  pagination = { currentPage: 1, pageSize: config.pageSize },
  customerId = null,
  query = ''
) => {
  return (dispatch, getState) => {
    let buyrentLeadState = { ...getState().BuyRentLeads }
    let moreFilters = buyrentLeadState.filters.filters
    let leadCountFilter = buyrentLeadState.leadCountFilter

    let quickFilters = getState().GeneralSetting.quickFilter
    let leadOrder = getState().GeneralSetting.dataSort
    let user = getState().user.userInfo
    let filters = mapFiltersToQuery(moreFilters, quickFilters)
    let showAllLeads = shouldShowAllLeads(getState().user)
    let allLeadParam = ''
    if (showAllLeads) {
      allLeadParam = `&showAllLeads=${showAllLeads}`
    }

    const { currentPage, pageSize } = pagination
    var url = `${config.apiPath}/api/leads?web=true&page=${currentPage}&pageSize=${pageSize}&isShowCount=true`
    if (customerId) url += `&customerId=${customerId}`
    if (filters) url += `&${filters}`
    if (leadCountFilter && leadCountFilter !== 'none') url += `&status=${leadCountFilter}`
    if (showAllLeads) url += allLeadParam
    if (leadOrder) url += leadOrder.value
    if (isREA(user)) url += '&isREA=true'
    if (query) {
      url += query
    }

    let redux_key = FETCH_BUYRENT_LEADS
    if (query.indexOf('pageType=myDeals') > -1) redux_key = FETCH_BUYRENT_DEALS
    else if (query.indexOf('pageType=referredLeads') > -1) redux_key = FETCH_BUYRENT_REFERRED_LEADS
    else if (query.indexOf('pageType=teamLeads') > -1) redux_key = FETCH_BUYRENT_TEAM_LEADS
    else if (query.indexOf('pageType=teamDeals') > -1) redux_key = FETCH_BUYRENT_TEAM_DEALS
    else if (query.indexOf('pageType=demandLeads') > -1) redux_key = FETCH_BUYRENT_DEMAND_LEADS
    else if (query.indexOf('pageType=referredLeads&hasBooking=false') > -1) redux_key = FETCH_BUYRENT_REFERRED_LEADS
    else if (query.indexOf('companyLead=yes') > -1) redux_key = FETCH_BUYRENT_COMPANY_LEADS
    else if (query.indexOf('companyLead=no') > -1) redux_key = FETCH_BUYRENT_MY_LEADS
    else if (query.indexOf('hasBooking=false&deactivatedLead=yes') > -1) redux_key = FETCH_BUYRENT_DEACTIVATED_LEADS
    else if (query.indexOf('hasBooking=true&deactivatedLead=yes') > -1) redux_key = FETCH_BUYRENT_DEACTIVATED_DEALS

    dispatch({ type: redux_key, data: [], loading: true })
    axios
      .get(url)
      .then(function (response) {
        dispatch({ type: redux_key, data: response.data, loading: false })
      })
      .catch(function (xhr, status, err) {
        return dispatch({ type: redux_key, data: [], loading: false })
      })
  }
}

export const destroyBuyRentDeactivatedLeadState = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_BUYRENT_DEACTIVATED_LEADS, data: [], loading: false })
  }
}
export const destroyBuyRentDeactivatedDealState = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_BUYRENT_DEACTIVATED_DEALS, data: [], loading: false })
  }
}
export const destroyBuyRentLeadState = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_BUYRENT_LEADS, data: [], loading: false })
  }
}
export const destroyBuyRentMyLeadState = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_BUYRENT_MY_LEADS, data: [], loading: false })
  }
}
export const destroyBuyRentCompanyLeadState = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_BUYRENT_COMPANY_LEADS, data: [], loading: false })
  }
}
export const destroyBuyRentDealState = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_BUYRENT_DEALS, data: [], loading: false })
  }
}
export const destroyBuyRentReferredLeadState = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_BUYRENT_REFERRED_LEADS, data: [], loading: false })
  }
}
export const destroyBuyRentReferredDealState = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_BUYRENT_REFERRED_DEALS, data: [], loading: false })
  }
}
export const destroyBuyRentDemandLeadState = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_BUYRENT_DEMAND_LEADS, data: [], loading: false })
  }
}
export const destroyBuyRentTeamLeadState = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_BUYRENT_TEAM_LEADS, data: [], loading: false })
  }
}
export const destroyBuyRentTeamDealState = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_BUYRENT_TEAM_DEALS, data: [], loading: false })
  }
}
export const setBuyRentLeadFilters = (filters, cb = null) => {
  return (dispatch) => {
    dispatch({ type: SET_BUYRENT_LEADS_FILTERS, data: filters })
    cb && cb()
  }
}
export const setLeadCountFilter = (status) => {
  return (dispatch) => {
    dispatch({ type: SET_BUYRENT_LEADS_COUNT_FILTER, data: status })
  }
}
