import React,{useState, useEffect, useRef} from 'react'
import axios from 'axios'
import config from '../../config'
import Pagination from 'rc-pagination'
import moment from 'moment'
import { DateRangePicker } from 'react-dates'
import { CSVLink } from 'react-csv'
import { connect } from 'react-redux'
import { setTitle } from '../../actions/generalSetting'

const OutboundCampaignLog = (props) => {
  const {setTitle}=props;
  const [loader, setLoader] = useState(false)
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [reportData, setReportData] = useState([])
  const [totalRows, setTotalRows] = useState(1)
  const [pagePermission, setPagePermission] = useState(true)
  const [filters, setFilters] = useState({
    fromDate: moment().startOf('month'),
    toDate: moment().endOf('month'),
    queueId:''
  })
  const [focusedInput, setFocusedInput] = useState(null)
  const [csvData, setCsvData] = useState([])
  const csvLink = useRef()

  const getData = async () => {
    const param={
      page,
      pageSize:perPage,
      startingDate:moment(filters.fromDate).format('YYYY-MM-DD'),
      endingDate:moment( filters.toDate).format('YYYY-MM-DD'),
      // agentId:'',
      queueId:filters.queueId,
    }
    setLoader(true)
    await axios
      .get(
        `${config.apiPath}/api/cdrreport/outgoing_call_log`, {params: param}
      )
      .then((res) => {
        setReportData(res.data)
        setTotalRows(res.data.length > 0 && res.data[0].total_rows || 0)
      })
    setLoader(false)
  }

  const getCsvData = async () => {
    const param = {
      page,
      pageSize:perPage,
      startingDate:moment(filters.fromDate).format('YYYY-MM-DD'),
      endingDate:moment( filters.toDate).format('YYYY-MM-DD'),
      queueId:filters.queueId,
      isCSV:1
    }
    await axios
    .get(
      // `http://localhost:3001/api/cdrreport/outgoing_call_log`, {params: param} )
      `${config.apiPath}/api/cdrreport/outgoing_call_log`, {params: param} )
    .then((data) => {
        const allRebates=
        data.data.length > 0 ?  
        data.data.map((data) => ({
          'User ID': data.arm_user_id,
          'User Name': data.user_name,
          'Connected Call': data.total_attended,
          'Not Connected Calls': data.not_attended,
          'UNIQUE PHONE CALLS': data.unique_phone_no,
          'Total Calls': data.total_calls,
        })):[];
        setCsvData(allRebates);
      })
      .catch((e) => console.log(e))
    csvLink.current.link.click()
  }

  useEffect(() => {
    setTitle({ name: 'Outbound User Logs' })
    let isTeamLead = ( localStorage.getItem('user_agency_mgmt')!== ''  && localStorage.getItem('user_agency_mgmt')!==null)? JSON.parse(localStorage.getItem('user_agency_mgmt')).data.isTeamLead : false;
    setPagePermission(isTeamLead)
    getData();
  }, [])
  useEffect(() => {
    getData()
  }, [page])

  return(
    <>
    {pagePermission?
    <div className="card mt-2">
        <div style={{ padding: '1%', height: "85vh" }}>
        <div className="ccmc-filters row">
            <div className="col-md-8 row">
              <div className="col-md-4 ccmcDateRange">
                <DateRangePicker
                  isOutsideRange={() => false}
                  startDate={filters.fromDate}
                  endDate={filters.toDate}
                  startDateId="buyrent_date_input_start"
                  minimumNights={1}
                  endDateId="buyrent_date_input_end"
                  className="activeDatePicker"
                  onDatesChange={({ startDate: dateStart, endDate: dateEnd }) => {
                    if (dateStart) {
                      setFilters((filters) => ({
                        ...filters,
                        fromDate: dateStart,
                      }))
                    }
                    if (dateEnd) {
                      setFilters((filters) => ({
                        ...filters,
                        toDate: dateEnd,
                      }))
                    }
                  }}
                  focusedInput={focusedInput}
                  onFocusChange={(input) => setFocusedInput(input)}
                  showClearDates
                  noBorder={false}
                />
              </div>
          
              <div className="col-md-4">
              {/* <label>queue id</label> */}
              </div>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-3 row justify-content-end ">
              {/*  <div className="col-md-6">
                <button className="export-btn" onClick={exportData}>
                  Export
                </button>
                </div> */}
              <div className="col-md-6">
                <div className="ccmc-clearFilter">
                  <button className={`newFormSearch`} onClick={() =>  getData(1,perPage)}>
                    <span className={`fa fa-search`}></span>
                  </button>
                  {/* <button className={`newFormClear`} onClick={clearFilter}>
                    Clear
                  </button> */}
                  {reportData.length > 0 && (
                      <div>
                        <button onClick={getCsvData} className="newFormSearch ml-1">
                          <span className={`fa fa-download`}></span>
                        </button>
                        <CSVLink
                          data={csvData}
                          filename="campaign_inbound_Call_logs.csv"
                          className="hidden"
                          ref={csvLink}
                          target="_blank"
                        />
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div> 
          <div className="reports-table">
            <table style={{ height: '80%'}}>
              <thead>
              <tr>
                <th style={{ minWidth: '200px' }}>User ID</th>
                <th style={{ minWidth: '220px' }}>User Name</th>
                <th style={{ minWidth: '200px' }}>Connected Calls</th>
                <th style={{ minWidth: '200px' }}>Not Connected Calls</th>
                <th style={{ minWidth: '200px' }}>UNIQUE PHONE CALLS</th>
                <th style={{ minWidth: '200px' }}>Total Calls</th>
              </tr>
              </thead>
              <tbody>
              {loader ? (
                <tr>
                  <p
                    style={{ paddingLeft: '36vw', paddingTop: '10vh' }}
                    colSpan={10}
                    className={''}
                  >
                    Loading ...
                  </p>
                </tr>
              ) : (
                <>{
                  reportData.length > 0 ?
                    reportData.map((e) => {
                      return <TableTr data={e} />
                    })
                  :<tr>
                  <p style={{ paddingLeft: '36vw', paddingTop: '10vh' }}
                    colSpan={10}
                    className={''} >
                    No Data Found
                  </p>
                </tr>}
                </>
              )}
              </tbody>
                {(reportData.length > 0 && !loader ) && (
              <tfoot className="row mt-2 mb-1">
                <tr className="col-md-12 d-flex flex-column justify-content-center" >
                      <td colSpan={10}> 
                        <div className="pagination justify-content-center">        
                            <Pagination
                              className="ps-pagination"
                              hideOnSinglePage={true}
                              pageSize={perPage}
                              current={page}
                              showPrevNextJumpers={false}
                              onChange={(cPage, pSize) => {
                                setPage(cPage)
                              }}
                              total={totalRows}
                            />
                          </div>
                      </td>
                    </tr>
                </tfoot>)}
            </table>
          </div>
        </div>
      </div>:null}
    </>
  )
}

const TableTr = ({ data }) => {
  return (
    <tr className="hover__for__btns hoverForTr">
      <td style={{ minWidth: '200px' }}>{data.arm_user_id}</td>
      <td style={{ minWidth: '220px' }}>{data.user_name}</td>
      <td style={{ minWidth: '200px' }}>{data.total_attended}</td>
      <td style={{ minWidth: '200px' }}>{data.not_attended}</td>
      <td style={{ minWidth: '200px' }}>{data.unique_phone_no}</td>
      <td style={{ minWidth: '200px' }}>{data.total_calls}</td>
    </tr>
  )
}

const mapDispatchToProps = (dispatch) => ({
  setTitle: (title) => dispatch(setTitle(title)),
})
const mapStateToProps = (state) => ({
})
export default connect(mapStateToProps, mapDispatchToProps)(OutboundCampaignLog);
