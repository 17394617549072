/** @format */

import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { normalizeCnic } from '../../utils/formatCnic'
import { removeZero } from '../../utils/validate'
import Select from 'react-select'
import DatePicker from '../../components/common/DatePicker/datePicker'
import Button from '../../components/common/Button'

class Inner extends Component {
  constructor(props) {
    super(props)
    this.state = {
      maxIndex: '',
      requiredField: false,
    }
  }

  checkRequiredField = () => {
    const { formData } = this.props
    if (
      (formData.bank && formData.bank !== '') ||
      (formData.iBan && formData.iBan !== '') ||
      (formData.accountTitle && formData.accountTitle !== '')
    ) {
      return true
    } else return false
  }

  arrayToOptions = (array) => {
    return (
      array &&
      array.map((item) => {
        return {
          label: item,
          value: item,
        }
      })
    )
  }

  render() {
    const {
      formData,
      handleChange,
      handleChangePhone,
      phone,
      createNextKin,
      contact1,
      contact2,
      validationCheck,
      loadingOnsubmit,
      cnicValidation,
      phoneValidate,
      phoneValidate1,
      phoneValidate2,
      editAble,
      emailValidate,
      fromPage,
      clientModalToggle,
      requiredField,
    } = this.props
    const { maxIndex } = this.state
    const className = 'col-md-6'
    const relationShipStatus = ['Son of', 'Daughter of', 'Wife of']
    const relationShipWithApplicant = ['Son', 'Daughter', 'Wife']
    const countries = [
      'Afghanistan',
      'Albania',
      'Algeria',
      'Andorra',
      'Angola',
      'Anguilla',
      'Antigua &amp; Barbuda',
      'Argentina',
      'Armenia',
      'Aruba',
      'Australia',
      'Austria',
      'Azerbaijan',
      'Bahamas',
      'Bahrain',
      'Bangladesh',
      'Barbados',
      'Belarus',
      'Belgium',
      'Belize',
      'Benin',
      'Bermuda',
      'Bhutan',
      'Bolivia',
      'Bosnia &amp; Herzegovina',
      'Botswana',
      'Brazil',
      'British Virgin Islands',
      'Brunei',
      'Bulgaria',
      'Burkina Faso',
      'Burundi',
      'Cambodia',
      'Cameroon',
      'Cape Verde',
      'Cayman Islands',
      'Chad',
      'Chile',
      'China',
      'Colombia',
      'Congo',
      'Cook Islands',
      'Costa Rica',
      'Cote D Ivoire',
      'Croatia',
      'Cruise Ship',
      'Cuba',
      'Cyprus',
      'Czech Republic',
      'Denmark',
      'Djibouti',
      'Dominica',
      'Dominican Republic',
      'Ecuador',
      'Egypt',
      'El Salvador',
      'Equatorial Guinea',
      'Estonia',
      'Ethiopia',
      'Falkland Islands',
      'Faroe Islands',
      'Fiji',
      'Finland',
      'France',
      'French Polynesia',
      'French West Indies',
      'Gabon',
      'Gambia',
      'Georgia',
      'Germany',
      'Ghana',
      'Gibraltar',
      'Greece',
      'Greenland',
      'Grenada',
      'Guam',
      'Guatemala',
      'Guernsey',
      'Guinea',
      'Guinea Bissau',
      'Guyana',
      'Haiti',
      'Honduras',
      'Hong Kong',
      'Hungary',
      'Iceland',
      'India',
      'Indonesia',
      'Iran',
      'Iraq',
      'Ireland',
      'Isle of Man',
      'Israel',
      'Italy',
      'Jamaica',
      'Japan',
      'Jersey',
      'Jordan',
      'Kazakhstan',
      'Kenya',
      'Kuwait',
      'Kyrgyz Republic',
      'Laos',
      'Latvia',
      'Lebanon',
      'Lesotho',
      'Liberia',
      'Libya',
      'Liechtenstein',
      'Lithuania',
      'Luxembourg',
      'Macau',
      'Macedonia',
      'Madagascar',
      'Malawi',
      'Malaysia',
      'Maldives',
      'Mali',
      'Malta',
      'Mauritania',
      'Mauritius',
      'Mexico',
      'Moldova',
      'Monaco',
      'Mongolia',
      'Montenegro',
      'Montserrat',
      'Morocco',
      'Mozambique',
      'Namibia',
      'Nepal',
      'Netherlands',
      'Netherlands Antilles',
      'New Caledonia',
      'New Zealand',
      'Nicaragua',
      'Niger',
      'Nigeria',
      'Norway',
      'Oman',
      'Pakistan',
      'Palestine',
      'Panama',
      'Papua New Guinea',
      'Paraguay',
      'Peru',
      'Philippines',
      'Poland',
      'Portugal',
      'Puerto Rico',
      'Qatar',
      'Reunion',
      'Romania',
      'Russia',
      'Rwanda',
      'Saint Pierre &amp; Miquelon',
      'Samoa',
      'San Marino',
      'Satellite',
      'Saudi Arabia',
      'Senegal',
      'Serbia',
      'Seychelles',
      'Sierra Leone',
      'Singapore',
      'Slovakia',
      'Slovenia',
      'South Africa',
      'South Korea',
      'Spain',
      'Sri Lanka',
      'St Kitts &amp; Nevis',
      'St Lucia',
      'St Vincent',
      'St. Lucia',
      'Sudan',
      'Suriname',
      'Swaziland',
      'Sweden',
      'Switzerland',
      'Syria',
      'Taiwan',
      'Tajikistan',
      'Tanzania',
      'Thailand',
      "Timor L'Este",
      'Togo',
      'Tonga',
      'Trinidad &amp; Tobago',
      'Tunisia',
      'Turkey',
      'Turkmenistan',
      'Turks &amp; Caicos',
      'Uganda',
      'Ukraine',
      'United Arab Emirates',
      'United Kingdom',
      'Uruguay',
      'Uzbekistan',
      'Venezuela',
      'Vietnam',
      'Virgin Islands (US)',
      'Yemen',
      'Zambia',
      'Zimbabwe',
    ]
    const province = [
      'Punjab',
      'Balochistan',
      'Islamabad Capital Territory',
      'Khyber Pakhtunkhwa',
      'Sindh',
    ]

    const onDobChangeHandler = (value) => {
      if (value) formData.dob = value
      // setLeaves({...leaves , startDate:value});
    }
    const classNameAddress = 'col-md-8'

    const checkClient = () => {
      const { userInfo } = this.props
      const client = this.props.formData

      if (!client) return ''
      if (!client.originalOwner) {
        if (
          userInfo &&
          client.assigned_to_armsuser_id &&
          client.assigned_to_armsuser_id === userInfo.id
        )
          return 'Personal Client'
        else return client.assigned_to_organization ? client.assigned_to_organization : ''
      } else {
        if (userInfo && client.originalOwner.id === userInfo.id) return 'Personal Client'
        else {
          if (client.originalOwner.organization) return client.originalOwner.organization.name
          else return client.originalOwner.firstName + ' ' + client.originalOwner.lastName
        }
      }
    }

    return (
      <Fragment>
        <div className={'clientMainContainer kinForm container-fluid'}>
          <label className="headers"> </label>
          <div className={'basicInfoContainer row'}>
            <div className={className}>
              <label>First Name </label>
              <span className={`text-danger`}> *</span>
              <div className="filterInputWrap">
                <input
                  type="text"
                  className={
                    validationCheck === true && formData.firstName === ''
                      ? `form-control invalid`
                      : `form-control`
                  }
                  placeholder={'Enter First Name'}
                  name={'firstName'}
                  onChange={(e) => {
                    handleChange(e)
                  }}
                  value={formData.firstName}
                  required
                />
                {validationCheck === true && formData.firstName === '' && (
                  <p className={'requiredCheck'}>Required</p>
                )}
              </div>
            </div>
            <div className={className}>
              <label>Last Name </label>
              <span className={`text-danger`}> *</span>
              <div className="filterInputWrap">
                <input
                  type="text"
                  className={
                    validationCheck === true && formData.lastName === ''
                      ? `form-control invalid`
                      : `form-control`
                  }
                  placeholder={'Enter Last Name'}
                  name={'lastName'}
                  required
                  onChange={(e) => {
                    handleChange(e)
                  }}
                  value={formData.lastName || ''}
                />
                {validationCheck === true && formData.lastName === '' && (
                  <p className={'requiredCheck'}>Required</p>
                )}
              </div>
            </div>
          </div>
          <div className={'basicInfoContainer row'}>
            <div className={className}>
              <label>S/O, D/O, W/O</label>
              <div className="filterInputWrap">
                <Select
                  closeMenuOnSelect={true}
                  key={Math.random(10)}
                  className={'select-control'}
                  defaultValue={
                    formData.relationStatus != '' &&
                    formData.relationStatus != null && {
                      label: formData.relationStatus,
                      value: formData.relationStatus,
                    }
                  }
                  options={this.arrayToOptions(relationShipStatus)}
                  onChange={(e) => {
                    handleChange(e, 'relationStatus')
                  }}
                  placeholder={'(S/O,D/O,W/O)'}
                />
              </div>
            </div>
            <div className={className}>
              <label>
                {formData.relationStatus === undefined ? 'Son of' : formData.relationStatus}
              </label>
              <div className="filterInputWrap">
                <input
                  type="text"
                  className={'form-control'}
                  placeholder={'Enter Name'}
                  name={'relativeName'}
                  required
                  onChange={(e) => {
                    handleChange(e)
                  }}
                  value={formData.relativeName || ''}
                />
              </div>
            </div>
          </div>
          <div className={'basicInfoContainer row'}>
            <div className={className}>
              <label>Relationship With Applicant</label>
              <div className="filterInputWrap">
                <Select
                  closeMenuOnSelect={true}
                  key={Math.random(10)}
                  className={'select-control'}
                  defaultValue={
                    formData.relationWithApplicant != '' &&
                    formData.relationWithApplicant != null && {
                      label: formData.relationWithApplicant,
                      value: formData.relationWithApplicant,
                    }
                  }
                  options={this.arrayToOptions(relationShipWithApplicant)}
                  onChange={(e) => {
                    handleChange(e, 'relationWithApplicant')
                  }}
                  placeholder={'(S,D,W)'}
                />
              </div>
            </div>
            <div className={className}>
              <label>CNIC/NTN</label>
              {this.props.checkPurchaserPage && <span className={`text-danger`}> *</span>}
              {this.props && this.props.checkPurchaserPage ? (
                <div className={`filterInputMain`}>
                  <div className="filterInputWrap">
                    <input
                      type="text"
                      className={
                        validationCheck === true &&
                        (formData.cnic === '' || formData.cnic.length < 13)
                          ? `form-control invalid`
                          : `form-control`
                      }
                      placeholder={'CNIC/NTN'}
                      name={'cnic'}
                      required
                      onChange={(e) => {
                        handleChange(e)
                      }}
                      value={normalizeCnic(formData.cnic)}
                    />
                    {validationCheck === true && formData.cnic === '' && (
                      <p className={'requiredCheck'}>Required</p>
                    )}

                    {cnicValidation === true && formData.cnic.length < 13 && (
                      <p className={'requiredCheck'}>Enter Correct CNIC/NTN Number</p>
                    )}
                  </div>
                </div>
              ) : (
                <div className={`filterInputMain`}>
                  <div className="filterInputWrap">
                    <input
                      type="text"
                      className={'form-control'}
                      placeholder={'CNIC/NTN'}
                      name={'cnic'}
                      required
                      onChange={(e) => {
                        handleChange(e)
                      }}
                      value={normalizeCnic(formData.cnic)}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className={'basicInfoContainer row'}>
            <div className={className}>
              <label>Phone Number #1</label>
              <span className={`text-danger`}> *</span>
              <div
                className={`filterInputMain`}
                onClick={() => {
                  this.setState({ maxIndex: 1 })
                }}
              >
                {this.props && this.props.checkPurchaserPage ? (
                  <div className={`filterInputWrap ${maxIndex === 1 ? 'max-index' : ''}`}>
                    <PhoneInput
                      placeholder="Enter phone number"
                      country={'pk'}
                      inputProps={{
                        name: 'phone',
                      }}
                      value={phone && phone.phone !== '' ? removeZero(phone.phone) : ''}
                      inputClass={
                        (validationCheck === true && phone.phone === '') ||
                        (validationCheck === true && phone.phone.length < 2) ||
                        phoneValidate === true
                          ? `form-control invalid`
                          : `form-control`
                      }
                      onChange={handleChangePhone}
                      countryCodeEditable={false}
                    />
                  </div>
                ) : (
                  <div className={`filterInputWrap ${maxIndex === 1 ? 'max-index' : ''}`}>
                    <PhoneInput
                      placeholder="Enter phone number"
                      country={'pk'}
                      inputProps={{
                        name: 'phone',
                        // required: true,
                        // autoFocus: true
                      }}
                      value={phone && phone.phone !== '' ? removeZero(phone.phone || '') : ''}
                      inputClass={
                        (validationCheck === true && phone.phone === '') ||
                        (validationCheck === true && phone.phone.length < 2) ||
                        phoneValidate === true
                          ? `form-control invalid`
                          : `form-control`
                      }
                      onChange={handleChangePhone}
                      disabled={
                        this.props.match.path !== '/available-inventory' &&
                        this.props.match.path !== '/add-client' &&
                        this.props.match.path !== '/new-lead' &&
                        this.props.match.path !== '/leadDetails/:leadId' &&
                        this.props.match.path !== '/add-buy-rent-lead' &&
                        checkClient() !== 'Personal Client'
                      }
                      countryCodeEditable={false}
                    />
                    {validationCheck === true && phone.phone === '' ? (
                      <p className={'requiredCheck'}>Required</p>
                    ) : validationCheck === true && phone.phone.length < 2 ? (
                      <p className={'requiredCheck'}>Required</p>
                    ) : (
                      ''
                    )}
                    {phoneValidate === true && (
                      <p className={'requiredCheck'}>Enter Correct Phone Number</p>
                    )}
                  </div>
                )}
              </div>
            </div>
            {/* <div className={className}>
              <label>Phone Number #2</label>
              <div
                className={`filterInputMain`}
                onClick={() => {
                  this.setState({ maxIndex: 2 })
                }}
              >
                <div className={`filterInputWrap ${maxIndex === 2 ? 'max-index' : ''}`}>
                  <PhoneInput
                    placeholder="Enter phone number"
                    country={'pk'}
                    inputProps={{
                      name: 'contact1',
                      // required: true,
                      // autoFocus: true
                    }}
                    value={
                      contact1 && contact1.contact1 !== '' ? removeZero(contact1.contact1) : ''
                    }
                    inputClass={'form-control'}
                    onChange={handleChangePhone}
                  />
                  {phoneValidate1 === true && (
                    <p className={'requiredCheck'}>Enter Correct Phone Number</p>
                  )}
                </div>
              </div>
            </div>
            <div className={className}>
              <label>Phone Number #3</label>
              <div
                className={`filterInputMain`}
                onClick={() => {
                  this.setState({ maxIndex: 3 })
                }}
              >
                <div className={`filterInputWrap ${maxIndex === 3 ? 'max-index' : ''}`}>
                  <PhoneInput
                    placeholder="Enter phone number"
                    country={'pk'}
                    inputProps={{
                      name: 'contact2',
                      // required: true,
                      // autoFocus: true
                    }}
                    value={
                      contact2 && contact2.contact2 !== '' ? removeZero(contact2.contact2) : ''
                    }
                    inputClass={'form-control'}
                    onChange={handleChangePhone}
                  />
                  {phoneValidate2 === true && (
                    <p className={'requiredCheck'}>Enter Correct Phone Number</p>
                  )}
                </div>
              </div>
            </div> */}
          </div>
          <div className={'basicInfoContainer row'}>
            {/* <div className={className}>
              <label>Email</label>
              <div className={`filterInputMain`}>
                <div className="filterInputWrap">
                  <input
                    type="email"
                    className={'form-control'}
                    placeholder={'Email'}
                    name={'email'}
                    required
                    onChange={(e) => {
                      handleChange(e)
                    }}
                    value={formData.email}
                  />
                  {emailValidate === true && formData.email != '' && (
                    <p className={'requiredCheck'}>Email is not valid</p>
                  )}
                </div>
              </div>
            </div> */}

            {/* <div className={className}>
              <label>Passport</label>
              <div className="filterInputWrap">
                <input
                  type="text"
                  className={'form-control'}
                  placeholder={'Enter Passport Number'}
                  name={'passport'}
                  onChange={(e) => {
                    handleChange(e)
                  }}
                  value={formData.passport}
                  required
                />
              </div>
            </div> */}
          </div>
          <br />
          <label className="headers">Mailing Address</label>

          <div className={'mailAddressContainer row'}>
            <div className={className}>
              <label>Country</label>
              <div className="filterInputWrap">
                <Select
                  name={'mCountry'}
                  key={Math.random(10)}
                  closeMenuOnSelect={true}
                  className={'select-control'}
                  defaultValue={
                    formData.mCountry != '' &&
                    formData.mCountry != null && {
                      label: formData.mCountry,
                      value: formData.mCountry,
                    }
                  }
                  options={this.arrayToOptions(countries)}
                  onChange={(e) => {
                    handleChange(e, 'mCountry', ['mProvince', 'mDistrict', 'mCity', 'mAddress'])
                  }}
                  placeholder={'Select Country'}
                />
              </div>
            </div>
            {formData.mCountry === 'Pakistan' ? (
              <div className={className}>
                <label>Province</label>
                <div className="filterInputWrap">
                  <Select
                    name={'mProvince'}
                    key={Math.random(10)}
                    closeMenuOnSelect={true}
                    className={'select-control'}
                    defaultValue={
                      formData.mProvince !== '' &&
                      formData.mProvince !== null && {
                        label: formData.mProvince,
                        value: formData.mProvince,
                      }
                    }
                    options={this.arrayToOptions(province)}
                    onChange={(e) => {
                      handleChange(e, 'mProvince')
                    }}
                    placeholder={'Select Province'}
                  />
                </div>
              </div>
            ) : (
              <div className={className}>
                <label>Province</label>
                <div className="filterInputWrap">
                  <input
                    type="text"
                    className={'form-control'}
                    placeholder={'Select Province'}
                    name={'mProvince'}
                    onChange={(e) => {
                      handleChange(e)
                    }}
                    value={formData.mProvince}
                    required
                  />
                </div>
              </div>
            )}
          </div>
          <div className={'mailAddressContainer row'}>
            <div className={className}>
              <label>District</label>
              <div className="filterInputWrap">
                <input
                  type="text"
                  className={'form-control'}
                  placeholder={'Select District'}
                  name={'mDistrict'}
                  onChange={(e) => {
                    handleChange(e)
                  }}
                  value={formData.mDistrict}
                  required
                />
              </div>
            </div>
            <div className={className}>
              <label>City</label>
              <div className="filterInputWrap">
                <input
                  type="text"
                  className={'form-control'}
                  placeholder={'Select City'}
                  name={'mCity'}
                  onChange={(e) => {
                    handleChange(e)
                  }}
                  value={formData.mCity}
                  required
                />
              </div>
            </div>
          </div>
          <div className={'mailAddressContainer row'}>
            <div className={classNameAddress}>
              <label>Address</label>
              <div className="filterInputWrap">
                <textarea
                  type="text"
                  className={'form-control'}
                  placeholder={'Enter Address'}
                  name={'mAddress'}
                  onChange={(e) => {
                    handleChange(e)
                  }}
                  rows={4}
                  value={formData.mAddress}
                  required
                />
              </div>
            </div>
          </div>

          <br />
          <label className="headers">Permanent Address</label>
          <div className={'permAddressContainer row'}>
            <div className={className}>
              <label>Country</label>
              <div className="filterInputWrap">
                <Select
                  name={'country'}
                  key={Math.random(10)}
                  closeMenuOnSelect={true}
                  className={'select-control'}
                  defaultValue={
                    formData.country != '' &&
                    formData.country != null && { label: formData.country, value: formData.country }
                  }
                  options={this.arrayToOptions(countries)}
                  onChange={(e) => {
                    handleChange(e, 'country', ['province', 'district', 'city', 'address'])
                  }}
                  placeholder={'Select Country'}
                />
              </div>
            </div>
            {formData.country === 'Pakistan' ? (
              <div className={className}>
                <label>Province</label>
                <div className="filterInputWrap">
                  <Select
                    name={'province'}
                    key={Math.random(10)}
                    closeMenuOnSelect={true}
                    className={'select-control'}
                    defaultValue={
                      formData.province !== '' &&
                      formData.province !== null && {
                        label: formData.province,
                        value: formData.province,
                      }
                    }
                    options={this.arrayToOptions(province)}
                    onChange={(e) => {
                      handleChange(e, 'province')
                    }}
                    placeholder={'Select Province'}
                  />
                </div>
              </div>
            ) : (
              <div className={className}>
                <label>Province</label>
                <div className="filterInputWrap">
                  <input
                    type="text"
                    className={'form-control'}
                    placeholder={'Select Province'}
                    name={'province'}
                    onChange={(e) => {
                      handleChange(e)
                    }}
                    value={formData.province}
                    required
                  />
                </div>
              </div>
            )}
          </div>
          <div className={'permAddressContainer row'}>
            <div className={className}>
              <label>District</label>
              <div className="filterInputWrap">
                <input
                  type="text"
                  className={'form-control'}
                  placeholder={'Select District'}
                  name={'district'}
                  onChange={(e) => {
                    handleChange(e)
                  }}
                  value={formData.district}
                  required
                />
              </div>
            </div>
            <div className={className}>
              <label>City</label>
              <div className="filterInputWrap">
                <input
                  type="text"
                  className={'form-control'}
                  placeholder={'Select City'}
                  name={'city'}
                  onChange={(e) => {
                    handleChange(e)
                  }}
                  value={formData.city}
                  required
                />
              </div>
            </div>
          </div>
          <div className={'permAddressContainer row'}>
            <div className={classNameAddress}>
              <label>Address</label>
              <div className="filterInputWrap">
                <textarea
                  type="text"
                  className={'form-control'}
                  placeholder={'Enter Address'}
                  name={'address'}
                  onChange={(e) => {
                    handleChange(e)
                  }}
                  rows={4}
                  value={formData.address}
                  required
                />
              </div>
            </div>
          </div>
          <br />
          <div className={`filterInputMain`}>
            <div className="registerButtons">
              <Button
                text={'Cancel'}
                textColor={'black'}
                width={'90px'}
                height={'30px'}
                display={'inline-block'}
                buttonColor="#fff"
                className={'px-4 rounded-pill small'}
                style={{ border: '1px solid #d3d3d3' }}
                onClick={() => {
                  fromPage ? clientModalToggle() : this.props.history.push('/clientManagement')
                }}
              />
              <Button
                text={editAble === true ? 'Update' : 'Register'}
                loader={loadingOnsubmit}
                textColor={'white'}
                width={'90px'}
                height={'30px'}
                display={'inline-block'}
                buttonColor="#026ff2"
                className={'px-4 rounded-pill mr-2 small'}
                onClick={() => {
                  return createNextKin()
                }}
              />
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}

export const mapStateTopProps = (state, ownProps) => {
  return { userInfo: state.user.userInfo }
}

export const mapDispatchTopProps = (dispatch) => {
  return {}
}

export default connect(mapStateTopProps, mapDispatchTopProps)(withRouter(Inner))
