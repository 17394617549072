import React, { useEffect, useRef, useState } from 'react'
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap'
import Button from '../../common/Button'
import GoogleMapContainer from '../../GoogleMap'
import Loader from 'react-loader-spinner'
import { connect } from 'react-redux'

const GeoTaggingModal = (props) => {
  const [manualGeoTag, setManualGeoTag] = useState(false)
  const [propsureTag, setPropsureTag] = useState(true)
  const [latLng, setLatLng] = useState(null)
  const [loader, setLoader] =  useState(false)
  const [iframeZindex, setIframeZindex] =  useState(-1)
  const [loaderDivStyle, setLoaderDivStyle] =  useState({
    position: 'absolute',
    top: '50%',
    left: '50%',
    padding: '0',
  })
  const iframeElm = useRef(null)
  const {
    selectedProperty,
    userInfo,
    existingLngValue,
    existingLatValue,
    chkGeoTag
  } = props
  let plot_id = selectedProperty && selectedProperty.propsure_id ? selectedProperty.propsure_id : null;
  let lead_id = selectedProperty && selectedProperty.id ? selectedProperty.id : null;
  let assigned_by = userInfo && userInfo.email ? userInfo.email : null;
  let encodedParams = btoa(`plot_id=${plot_id}&lead_id=${lead_id}&assigned_by=${assigned_by}&platform=ARMS Web`)

  useEffect(() => {
    if(selectedProperty && selectedProperty.propsure_id == null && selectedProperty.locate_manually == true){
      setManualGeoTag(true)
      setPropsureTag(false)
    }

  }, [selectedProperty])

  const handleMapSelection = () => {
     props.geoTagSubmitHandler();
     setLoader(true);
  }
  const iframeLoaded = () => {
    setTimeout(() => {
      if(iframeElm && iframeElm.current){
        setLoader(false);
        setIframeZindex(1);
        setLoaderDivStyle ({
          zIndex: '-1'
        })
      }
    }, 6000);
  }
  const modalHeaderStyle = {
    border: '0',
    background: 'transparent !important',
  };

  return (
    <Modal
      isOpen={props.geoTagModal}
      toggle={(toggle) => props.setGeoTagModal(!toggle) }
      size={'xl'}
    >
      <ModalBody>
        <div className="close-icon-container">
          <i
            className="fal fa-times-circle close-icon"
            onClick={() => props.setGeoTagModal(false) }
          ></i>
        </div>
        <div className={"d-flex mt-2 mb-4"}>
          <h4 className={"mr-5"}>GeoTag</h4>

            <Button
              textColor={!propsureTag ? 'black' : 'white'}
              buttonColor='#026ff2'
              className={`px-5 rounded-pill mr-2 ${propsureTag ? `bg-primary` : `bg-light border border-secondary`}`}
              text={"Propsure GeoTagging"}
              onClick={() => {
                /*if(iframeZindex === -1){
                  setLoader(true)
                }*/
                setPropsureTag(true);
                setManualGeoTag(false);}
              }
            />


            <Button
              textColor={!manualGeoTag ? 'black' : 'white'}
              buttonColor='#fff'
              className={`px-5 rounded-pill ${manualGeoTag ? `bg-primary` : `bg-light border border-secondary`}`}
              text={"Manual GeoTagging"}
              onClick={() => {
                setLoader(false);
                setManualGeoTag(true);
                setPropsureTag(false);}
              }
            />
        </div>
        {manualGeoTag && !propsureTag ? (
          <GoogleMapContainer defaultLng={existingLngValue} defaultLat={existingLatValue} onSelect={(lngLat) => {props.geoTagHandler(lngLat); setLatLng(lngLat)}}></GoogleMapContainer>
        ) : null}
        {!manualGeoTag && propsureTag && iframeZindex ? (
          /*<iframe ref={iframeElm} onLoadedData={iframeLoaded()} src={`https://maps.staging.graana.rocks/${encodedParams}`} style={{ height: '70vh', width: '100%', border: '0px', position:'relative', zIndex:`${iframeZindex}`}}></iframe>*/
          <div className={'align-items-center d-flex justify-content-center flex-column'} style={{minHeight: '300px'}}>
            {selectedProperty && selectedProperty.propsure_id != null && (
              <p className={'mb-3'}>
                <b>Propsure ID: {selectedProperty.propsure_id}</b>
              </p>
            )}
            <p className={'mb-3'}>Propsure Geotagging is not yet available on ARMS Web.</p>
            <p>You can either use Manual Geotagging, or use Field App or Graana Admin Portal for Propsure Geotagging.</p>
          </div>
        ) : null}
        {loader ? (
          <div className="loader-parent" style={loaderDivStyle}>
            <Loader type="ThreeDots" color="#0f73ee" height="40" width="40" />
          </div>
        ) : null}
      </ModalBody>
      <ModalFooter className={`justify-content-center`}>
        {
          manualGeoTag && (
            <div className={"d-flex mt-2 mb-2 justify-content-center"}>
              <input
                className={'form-control mr-5'}
                type="number"
                placeholder={'Latitude'}
                disabled={true}
                value={latLng !== null ? latLng.lat : existingLatValue !== null ? existingLatValue : ''}
              />
              <input
                className={'form-control'}
                type="number"
                placeholder={'Longitude'}
                disabled={true}
                value={latLng !== null ? latLng.lng : existingLngValue !== null ? existingLngValue : ''}
              />

              {latLng !== null  && (
                <Button
                  textColor={'white'}
                  buttonColor='#026ff2'
                  className={"px-5 ml-5 rounded-pill bg-primary"}
                  text={"Save"}
                  loader={loader && latLng}
                  onClick={() => handleMapSelection()}
                />
              )}
            </div>
          )
        }
      </ModalFooter>
    </Modal>
  )
}
const mapStateToProps = state => ({
  userInfo: state.user.userInfo
})
export default connect(mapStateToProps)(GeoTaggingModal);
