/** @format */

import React, { useState, useEffect } from 'react'
import '../AddArmsProperty/AddArmsProperty.scss'
import Select from 'react-select'
import { CheckboxGroup, RadioGroup } from 'react-rainbow-components'
import ImageUploading from 'react-images-uploading'
import { fetchCities } from '../../actions/user'
import { connect } from 'react-redux'
import { values } from 'lodash'
import axios from 'axios'
import config from '../../config'
import GeoTagModal from '../GeoTagModal'
import Swal from 'sweetalert2'
import Loader from 'react-loader-spinner'
import UploadImages from '../AddArmsProperty/UploadImages'
import GeoTaggingModal from '../../components/Modals/GeoTaggingModal'
import { showToastMsg } from '../../utils/helper'
const queryString = require('query-string')

const AddArmsProperty = (props) => {
  // props destructure

  const defaultCustomer = {
    label: `${props.defaultCustomer.first_name} ${props.defaultCustomer.last_name}`,
    value: props.defaultCustomer.id,
  }
  const { cities, fetchCities } = props
  // useState here
  const [titleName, setTitleName] = useState(null)
  const [projectType, setProjectType] = useState(null)
  const [subTypeValue, setSubTypeValue] = useState(null)
  const [availableType, setAvailableType] = useState(props.purpose)
  const [priceValue, setPriceValue] = useState(null)
  const [descValue, setDescValue] = useState(null)
  const [areaSizeValue, setAreaSizeValue] = useState(null)
  const [areaUnitValue, setAreaUnitValue] = useState(null)
  const [images, setImages] = useState([])
  const [watermarkFlagValue, setWatermarkFlagValue] = useState([])
  const [gradeValue, setGradeValue] = useState(null)
  const [cityValue, setCityValue] = useState(null)
  const [areaValue, setAreaValue] = useState(null)
  const [areaOpt, setAreaOpt] = useState([])
  const [manualLocFlag, setManualLocFlag] = useState([])
  const [latValue, setLatValue] = useState(null)
  const [lngValue, setLngValue] = useState(null)
  const [ownerOpt, setOwnerOpt] = useState(null)
  const [ownerValue, setOwnerValue] = useState(defaultCustomer)
  const [POCNameValue, setPOCNameValue] = useState(defaultCustomer)
  const [autoGenFlag, setAutoGenFlag] = useState([])
  const [propTypeFlag, setPropTypeFlag] = useState(false)
  const [cityFlag, setCityFlag] = useState(false)
  const [areaFlag, setAreaFlag] = useState(false)
  const [sizeFlag, setSizeFlag] = useState(false)
  const [availableFlag, setAvailableFlag] = useState(false)
  const [subtypeFlag, setSubTypeFlag] = useState(false)
  const [ownerNameFlag, setOwnerNameFlag] = useState(false)
  const [geoTagModal, setGeoTagModal] = useState(false)
  const [geoTagged, setGeoTagged] = useState(false)
  const [propsureId, setPropsureId] = useState(null)
  const [locLoader, setLocLoader] = useState(false)
  const [addressText, setAddressText] = useState(null)
  const [addressFlag, setAddressFlag] = useState(false)
  const [areaValueFlag, setAreaValueFlag] = useState(false)
  const [uploadFlag, setUploadFlag] = useState(false)
  const [email, setEmail] = useState(null)
  const [selectedProperty, setSelectedProperty] = useState(null)
  const [priceFlag, setPriceFlag] = useState(false)

  // useEffect
  useEffect(() => {
    const parsed = props.location ? queryString.parse(props.location.search) : {}
    const idCheck = 'id' in parsed
    fetchCities()
    axios
      .get(`${config.apiPath}/api/user/me`)
      .then((res) => setEmail(res.data.email))
      .catch((err) => console.log(err))
    axios
      .get(`${config.apiPath}/api/customer/find?all=true`)
      .then((res) => {
        setOwnerOpt(res.data.rows)
        const ownerData = res.data.rows
        if (
          (idCheck == true &&
            parsed !== null &&
            parsed !== undefined &&
            parsed.id !== null &&
            parsed.origin !== null) ||
          props.propertyId
        ) {
          axios
            .get(`${config.apiPath}/api/property/one-property/${props.propertyId || parsed.id}`)
            .then((res) => {
              const data = res.data
              setSelectedProperty(data)
              setTitleName(data.title)
              const filterdType = productTypeOpt.find((item) => item.value == data.type)
              const subType = { value: data.subtype, label: capatilizeStr(data.subtype) }
              setProjectType(filterdType)
              setSubTypeValue(subType)
              setAvailableType({ value: data.purpose, label: capatilizeStr(data.purpose) })
              setPriceValue(data.price)
              setDescValue(data.description)
              setAreaSizeValue(data.size)
              const sizeUnitFilter = areaUnitOpt.find((item) => item.value == data.size_unit)
              setAreaUnitValue(sizeUnitFilter)
              setGradeValue(data.grade)
              setCityValue({ value: data.city.id, label: data.city.name })
              setImages(data.armsPropertyImages)
              axios
                .get(`${config.apiPath}/api/areas?city_id=${data.city.id}&all=true`)
                .then((res) => {
                  setAreaOpt(res.data.items)
                })
              setPropsureId(data.propsure_id)
              setAreaValue({ value: data.area.id, label: data.area.name })
              setManualLocFlag(data.locate_manually == true ? [`${data.locate_manually}`] : [])
              setLatValue(data.lat)
              setLngValue(data.lng ? data.lng : data.lon)
              setOwnerValue({
                value: data.customer.id,
                label: `${data.customer.first_name} ${data.customer.last_name}`,
              })
              const namePOC = data.poc_name.split(' ')
              const filterdPocName = ownerData.find((item) => item.firstName == namePOC[0])
              setPOCNameValue(
                filterdPocName !== null && filterdPocName !== undefined
                  ? {
                      value: filterdPocName.id,
                      label: `${filterdPocName.firstName} ${filterdPocName.lastName}`,
                    }
                  : data.poc_name === defaultCustomer.label
                  ? defaultCustomer
                  : null
              )
            })
            .catch((err) => console.log(err))
        }
      })
      .catch((err) => console.log(err))
  }, [])

  const geoTagSubmitHandler = () => {
    if (selectedProperty && latValue !== null && lngValue !== null) {
      setGeoTagged(true)
    }
    setGeoTagModal(false)
  }

  const geoTagHandlerNew = (lngLat) => {
    if (lngLat !== null) {
      setLatValue(lngLat.lat)
      setLngValue(lngLat.lng)
    }
  }
  // functions here
  const capatilizeStr = (str) => {
    const lower = str.toLowerCase()
    return str.charAt(0).toUpperCase() + lower.slice(1)
  }
  const titleHandler = (e) => {
    setTitleName(e.target.value)
  }
  // const autoGenHandler = (value) => {
  //   setAutoGenFlag(value)
  // }
  const projectTypeHandler = (value) => {
    setPropTypeFlag(false)
    setProjectType(value)
    setSubTypeValue(null)
  }
  const subtypeHandler = (value) => {
    setSubTypeFlag(false)
    setSubTypeValue(value)
  }
  const availableTypeHandler = (value) => {
    setAvailableFlag(false)
    setAvailableType(value)
  }
  const priceHandler = (e) => {
    setPriceFlag(false)
    setPriceValue(e.target.value)
  }
  const descHandler = (e) => {
    setDescValue(e.target.value)
  }
  const addressHandler = (e) => {
    setAddressFlag(false)
    setAddressText(e.target.value)
  }
  const areaSizeHandler = (e) => {
    setAreaSizeValue(e.target.value)
    setAreaFlag(false)
  }
  const areaUnitHandler = (value) => {
    setAreaUnitValue(value)
    setSizeFlag(false)
  }
  const onChange = (imageList, addUpdateIndex, imgFrom) => {
    setUploadFlag(true)
    // data for submit
    imageList.map((item) => {
      let formData = new FormData()
      if (imgFrom === 'uploadMoreImages') {
        // setImages([])
        formData.append('image', item)
      } else {
        formData.append('image', item.file)
      }
      // will have to change this for graana and field app we have to make it dynamic
      axios.post(`${config.apiPath}/api/inventory/image?graana=false`, formData).then((res) => {
        setImages((images) => [...images, res.data])
        setUploadFlag(false)
      })
    })
  }
  const onImageRemove = (image, index) => {
    axios.delete(`${config.apiPath}/api/inventory/image/${image.id}`).then((res) => {})
    const filteredImages = images.filter((item) => item !== image)
    // console.log('filteredImages', filteredImages)
    setImages(filteredImages)
  }
  const watermarkHandler = (value) => {
    setWatermarkFlagValue(value)
  }
  const gradeHandler = (e) => {
    setGradeValue(e.target.value)
  }
  const cityHandler = (value) => {
    setCityFlag(false)
    setCityValue(value)
    setAreaValue(null)
    axios.get(`${config.apiPath}/api/areas?city_id=${value.value}&all=true`).then((res) => {
      setAreaOpt(res.data.items)
    })
  }
  const areaHandler = (value) => {
    setAreaValue(value)
    setAreaValueFlag(false)
  }
  const manualLocHandler = (value) => {
    setManualLocFlag(value)
  }
  const latitudeHandler = (e) => {
    setLatValue(e.target.value)
  }
  const longitudeHandler = (e) => {
    setLngValue(e.target.value)
  }
  const ownerNameHandler = (value) => {
    setOwnerNameFlag(false)
    setOwnerValue(value)
    setPOCNameValue(value)
  }
  const POCNameHandler = (value) => {
    setPOCNameValue(value)
  }
  const geoTagHandler = (lngLat, propsureId) => {
    console.log(lngLat)
    if (lngLat !== null) {
      setLatValue(lngLat[0])
      setLngValue(lngLat[1])
      setPropsureId(propsureId)
    } else {
      setLatValue(null)
      setLngValue(null)
      setPropsureId(null)
      Swal.fire({
        title: 'Error',
        type: 'error',
        icon: 'error',
        text: 'No plot was selected',
      })
    }
  }
  const submitHandler = () => {
    let propertyResponse
    const parsed = props.location ? queryString.parse(props.location.search) : {}
    const imageArrIds = images.map((item) => item.id)
    if (
      projectType == null ||
      projectType == undefined ||
      areaSizeValue == null ||
      areaSizeValue == undefined ||
      areaSizeValue == '' ||
      areaUnitValue == null ||
      areaUnitValue == undefined ||
      availableType == null ||
      availableType == undefined ||
      cityValue == null ||
      cityValue == undefined ||
      ownerValue == null ||
      ownerValue == undefined ||
      areaValue == null ||
      areaValue == undefined
    ) {
      if (projectType == null || projectType == undefined) {
        setPropTypeFlag(true)
      }
      if (areaSizeValue == null || areaSizeValue == undefined || areaSizeValue == '') {
        setAreaFlag(true)
      }
      if (areaUnitValue == null || areaUnitValue == undefined) {
        setSizeFlag(true)
      }
      if (availableType == null || availableType == undefined) {
        setAvailableFlag(true)
      }
      if (cityValue == null || cityValue == undefined) {
        setCityFlag(true)
      }
      if (ownerValue == null || ownerValue == undefined) {
        setOwnerNameFlag(true)
      }
      if (areaValue == null || areaValue == undefined) {
        setAreaValueFlag(true)
      }
      if (subTypeValue == null || subTypeValue == undefined) {
        setSubTypeFlag(true)
      }
      if (addressText == null || addressText == undefined) {
        setAddressFlag(true)
      }
      if (priceValue == null || priceValue == undefined) {
        setPriceFlag(true)
      }
    } else {
      if (parsed.edit == 'true' || props.propertyId) {
        let url = `${config.apiPath}/api/property/update/${props.propertyId}`
        if (parsed.origin == 'FieldsInventories' || parsed.origin == 'GraanaInventories') {
          url = `${config.apiPath}/api/inventory/update/fieldProperties?id=${
            props.propertyId || parsed.id
          }&isGraana=${parsed.origin == 'FieldsInventories' ? true : true}`
        }
        axios
          .patch(url, {
            type: projectType.value,
            subtype: subTypeValue && subTypeValue.value,
            purpose: availableType && availableType.value,
            custom_title: titleName,
            price: priceValue,
            description: descValue,
            grade: gradeValue,
            city_id: cityValue.value,
            area_id: areaValue && areaValue.value,
            size: areaSizeValue,
            size_unit: areaUnitValue.value,
            locate_manually: geoTagged,
            lat: latValue,
            lng: lngValue,
            lon: lngValue,
            geotagged_date: geoTagged ? new Date() : null,
            poc_name: POCNameValue && POCNameValue.label,
            customer_id: ownerValue.value,
            features: [],
            propsure_id: propsureId !== null ? propsureId : null,
            address: addressText !== null ? addressText : null,
            imageIds: imageArrIds.length ? imageArrIds : [],
            showWaterMark: watermarkFlagValue[0] == 'true' || true ? true : false,
          })
          .then((res) => {
            propertyResponse = res.data
            // axios
            //   .post(`https://maps.graana.rocks/api/map/arms-propsure`, {
            //     plot_id: propsureId, // propsure_id
            //     arms_id: res.data.id, // property id of arms
            //     assigned_by: email, // logged in user email
            //     platform: 'ARMS Web',
            //   })
            //   .then((res) => console.log(res))
            //   .catch((err) => console.log(err))
            Swal.fire({
              title: 'Success',
              type: 'success',
              icon: 'success',
              text: 'Property edited successfully',
            }).then((res) => {
              if (res.value) {
                props.popup
                  ? props.callBack('updated', propertyResponse)
                  : props.history.push(`/armsView`)
              } else {
                return
              }
            })
          })
          .catch((err) => console.log(err))
      } else {
        let dataToCreate = {
          type: projectType.value,
          subtype:
            subTypeValue && subTypeValue.value !== null && subTypeValue.value !== undefined
              ? subTypeValue.value
              : null,
          purpose:
            availableType && availableType.value !== null && availableType.value !== undefined
              ? availableType.value
              : null,
          custom_title: titleName,
          price: priceValue,
          description: descValue,
          grade: gradeValue,
          city_id: cityValue.value,
          area_id:
            areaValue && areaValue.value !== null && areaValue.value !== undefined
              ? areaValue.value
              : null,
          size: areaSizeValue,
          size_unit: areaUnitValue.value,
          locate_manually: geoTagged,
          lat: latValue,
          lng: lngValue,
          lon: lngValue,
          geotagged_date: geoTagged ? new Date() : null,
          poc_name:
            POCNameValue && POCNameValue.label !== null && POCNameValue.label !== undefined
              ? POCNameValue.label
              : null,
          customer_id: ownerValue.value,
          features: [],
          propsure_id: propsureId !== null ? propsureId : null,
          address: addressText !== null ? addressText : null,
          imageIds: imageArrIds.length ? imageArrIds : [],
          showWaterMark: watermarkFlagValue[0] == 'true' ? true : false,
        }
        if (props.wantedId) dataToCreate.wanted_id = props.wantedId
        axios
          .post(`${config.apiPath}/api/property`, dataToCreate)
          .then((res) => {
            propertyResponse = res.data
            showToastMsg(`Property added successfully`, 'success')  
            if (res.data) {
              console.log('property response ', propertyResponse)
              props.popup
                ? props.callBack('added', propertyResponse)
                : props.history.push(`/armsView`)
            } else {
              return
            }
          })
          .catch((err) => console.log(err))
      }
    }
  }
  const geoTagModalToggle = () => {
    setGeoTagModal(!geoTagModal)
  }
  const getCurrentLocation = () => {
    setLocLoader(true)
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(function (position) {
        setLatValue(position.coords.latitude)
        setLngValue(position.coords.longitude)
        setLocLoader(false)
      })
    } else {
      Swal.fire({
        type: 'error',
        title: 'Error',
        icon: 'error',
        text: 'Enable browsers/machines location settings',
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
      }).then((res) => {
        if (res.value) {
          setLocLoader(false)
        } else {
          setLocLoader(false)
          return
        }
      })
    }
  }
  const formatPrice = (price) => {
    if (price / 10000000 >= 1) {
      const r = price / 10000000
      return isFloat(r) ? `${r.toFixed(2)} Crore` : `${r} Crore `
    } else if (price / 100000 >= 1) {
      const r = price / 100000
      return isFloat(r) ? `${r.toFixed(2)} Lac` : `${r} Lac`
    } else {
      return `${price != '' ? parseFloat(price).toFixed(2) : ''}`
    }
  }

  function isFloat(n) {
    return Number(n) === n && n % 1 !== 0
  }
  // constant values
  const productTypeOpt = [
    { value: 'residential', label: 'Residential' },
    { value: 'plot', label: 'Plot' },
    { value: 'commercial', label: 'Commercial' },
  ]
  const availableTypeOpt = [
    // { value: 'sale', label: 'Sale' },
    // { value: 'rent', label: 'Rent' },
    props.purpose,
  ]
  const areaUnitOpt = [
    { value: 'sqm', label: 'M²' },
    { value: 'sqft', label: 'Ft²' },
    { value: 'sqyd', label: 'Yd²' },
    { value: 'kanal', label: 'Kanal' },
    { value: 'marla', label: 'Marla' },
  ]
  const watermarkOptions = [{ value: 'true', label: 'Show watermark on images', disabled: false }]
  const gradeOptions = [
    { value: 'gradeA', label: 'Grade A' },
    { value: 'gradeB', label: 'Grade B' },
    { value: 'gradeC', label: 'Grade C' },
  ]
  const cityOpt =
    cities && cities.length
      ? cities.map((item) => {
          return {
            label: item.name,
            value: item.id,
          }
        })
      : []
  const areaOptions =
    areaOpt && areaOpt.length
      ? areaOpt.map((item) => {
          return {
            label: item.name,
            value: item.id,
          }
        })
      : []
  const ownerNameOptions =
    ownerOpt &&
    ownerOpt.map((item) => {
      return {
        label: `${item.firstName} ${item.lastName}`,
        value: item.id,
      }
    })
  const subTypeOptions =
    projectType && projectType.value == 'residential'
      ? [
          { value: 'house', label: 'House' },
          { value: 'guest house', label: 'Guest house' },
          { value: 'apartment', label: 'Apartment' },
          { value: 'upper portion', label: 'Upper portion' },
          { value: 'lower portion', label: 'Lower portion' },
          { value: 'farm house', label: 'Farm house' },
          { value: 'room', label: 'Room' },
          { value: 'penthouse', label: 'Penthouse' },
          { value: 'hotel suites', label: 'Hotel suites' },
          { value: 'basement', label: 'Basement' },
          { value: 'annexe', label: 'Annexe' },
          { value: 'hostel', label: 'Hostel' },
          { value: 'other', label: 'Other' },
        ]
      : projectType && projectType.value == 'plot'
      ? [
          { value: 'residential plot', label: 'Residential Plot' },
          { value: 'commercial plot', label: 'Commercial Plot' },
          { value: 'agricultural land', label: 'Agricultural Land' },
          { value: 'industrial land', label: 'Industrial Land' },
          { value: 'plot file', label: 'Plot File' },
          { value: 'farmhouse plot', label: 'Farmhouse Plot' },
        ]
      : projectType && projectType.value == 'commercial'
      ? [
          { value: 'office', label: 'Office' },
          { value: 'shop', label: 'Shop' },
          { value: 'warehouse', label: 'Warehouse' },
          { value: 'factory', label: 'Factory' },
          { value: 'building', label: 'Building' },
          { value: 'theatre', label: 'Theatre' },
          { value: 'Gym', label: 'Gym' },
          { value: 'Food Court', label: 'Food Court' },
          { value: 'hall', label: 'Hall' },
          { value: 'land', label: 'Land' },
          { value: 'plaza', label: 'Plaza' },
          { value: 'other', label: 'Other' },
        ]
      : []
  const maxNumber = 25
  const parsed = props.location ? queryString.parse(props.location.search) : {}
  return (
    <div className="add-prop-container">
      {props.popup || <h5 className="headings-cus">Property Info</h5>}
      <div className="input-wrap">
        <div className="single-input">
          <label>Add a Custom Title</label>
          <input
            className="form-control"
            placeholder="Enter Title"
            value={titleName}
            onChange={(e) => titleHandler(e)}
            disabled={autoGenFlag == 'true' ? true : false}
          />
        </div>
        <div className="single-input">
          <label>Property Type</label>
          <Select
            placeholder={'Select Type'}
            options={productTypeOpt}
            value={projectType}
            onChange={(value) => projectTypeHandler(value)}
          />
          {propTypeFlag ? (
            <p style={{ color: 'red', marginTop: '5px' }}>Property type should be selected</p>
          ) : null}
        </div>
        {projectType && projectType.value !== null ? (
          <div className="single-input">
            <label>Subtype</label>
            <Select
              placeholder={'Select subtype'}
              options={subTypeOptions}
              value={subTypeValue}
              onChange={(value) => subtypeHandler(value)}
            />
            {subtypeFlag ? (
              <p style={{ color: 'red', marginTop: '5px' }}>Subtype should be selected</p>
            ) : null}
          </div>
        ) : null}
        <div className="single-input">
          <label>Available For</label>
          <Select
            placeholder={'Availble For'}
            options={availableTypeOpt}
            value={availableType}
            onChange={(value) => availableTypeHandler(value)}
          />
          {availableFlag ? (
            <p style={{ color: 'red', marginTop: '5px' }}>Available for should be selected</p>
          ) : null}
        </div>
        <div className="single-input">
          <label>Demand Price</label>
          <input
            className="form-control"
            type="number"
            placeholder={'Enter Price'}
            value={priceValue}
            onChange={(e) => priceHandler(e)}
          />
          {priceFlag ? (
            <p style={{ color: 'red', marginTop: '5px' }}>Demand Price should be selected</p>
          ) : null}
        </div>
        {priceValue == null ? null : (
          <div className="priceValue">
            <p>{formatPrice(priceValue)}</p>
          </div>
        )}
        <div className="single-input">
          <label>Description</label>
          <textarea
            className="form-control"
            rows={4}
            cols={6}
            placeholder={'Enter Description'}
            value={descValue}
            onChange={(e) => descHandler(e)}
          />
        </div>
        <div className="single-input area-wrap">
          <div className="inner-div1">
            <label>Area Size</label>
            <input
              className="form-control"
              type="number"
              placeholder={'Enter Size'}
              value={areaSizeValue}
              onChange={(e) => areaSizeHandler(e)}
            />
            {areaFlag ? (
              <p style={{ color: 'red', marginTop: '5px' }}>Area should be selected</p>
            ) : null}
          </div>
          <div className="inner-div2 ml-4">
            <label>Area Size Unit</label>
            <Select
              placeholder={'Select Unit'}
              options={areaUnitOpt}
              value={areaUnitValue}
              onChange={(value) => areaUnitHandler(value)}
            />
            {sizeFlag ? (
              <p style={{ color: 'red', marginTop: '5px' }}>Area size should be selected</p>
            ) : null}
          </div>
        </div>

        <div className="single-input uploader-container">
          <ImageUploading
            multiple={true}
            value={images}
            onChange={onChange}
            maxNumber={maxNumber}
            dataURLKey="data_url"
          >
            {({ imageList, onImageUpload, isDragging, dragProps }) => (
              // write your building UI
              <div className="upload__image-wrapper">
                {/* <div className={isDragging ? 'img-drag' : 'img-reg'} {...dragProps}>
                  <p>
                    Drag and drop or
                    <span className="browse-p" onClick={onImageUpload}>
                      Browse
                    </span>
                    Images
                  </p>

                </div> */}
                {uploadFlag ? (
                  <div style={{ textAlign: 'center' }}>
                    <p className="mt-2"> Uploading</p>{' '}
                    <Loader type="ThreeDots" color="blue" height="20" width="40" />
                  </div>
                ) : null}
                <UploadImages
                  onChange={onChange}
                  watermarkHandler={watermarkHandler}
                  imagesLength={images}
                />
                <div className="uploaded-img-wrap">
                  {images.map((image, index) => (
                    <div key={index} className="image-item">
                      <img
                        className="thumb-img"
                        style={{
                          backgroundImage: `url(${image.url})`,
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: 'center center',
                          backgroundSize: 'cover',
                        }}
                        alt=""
                      />
                      <div className="image-item__btn-wrapper">
                        <span onClick={() => onImageRemove(image, index)}>
                          <i class="fas fa-trash-alt"></i>
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </ImageUploading>
        </div>
        {/* <div className="single-input">
          <CheckboxGroup
            className="cus-water-box"
            id="checkbox-group-1"
            options={watermarkOptions}
            value={watermarkFlagValue}
            onChange={(value) => watermarkHandler(value)}
          />
        </div> */}
        <div className="single-input">
          <RadioGroup
            className="cus-radio-btn"
            id="radio-group-component-1"
            options={gradeOptions}
            value={gradeValue}
            onChange={(e) => gradeHandler(e)}
            orientation="horizontal"
          />
        </div>
        <div className="single-input">
          <h5 className="headings-cus">Property Location</h5>
        </div>
        <div className="single-input">
          <label>City</label>
          <Select
            placeholder={'Select City'}
            options={cityOpt}
            value={cityValue}
            onChange={(value) => cityHandler(value)}
          />
          {cityFlag ? (
            <p style={{ color: 'red', marginTop: '5px' }}>City should be selected</p>
          ) : null}
        </div>
        <div className="single-input">
          <label>Area</label>
          <Select
            placeholder={'Select Area'}
            options={areaOptions}
            value={areaValue}
            onChange={(value) => areaHandler(value)}
            isDisabled={areaOptions && areaOptions.length ? false : true}
          />
          {areaValueFlag ? (
            <p style={{ color: 'red', marginTop: '5px' }}>Area should be selected</p>
          ) : null}
        </div>
        <div className="single-input">
          <label>Address</label>
          <textarea
            className="form-control"
            rows={4}
            cols={6}
            placeholder={'Enter Address'}
            value={addressText}
            onChange={(e) => addressHandler(e)}
          />
          {addressFlag ? (
            <p style={{ color: 'red', marginTop: '5px' }}>Address should be selected</p>
          ) : null}
        </div>
        {/* <div className="single-input">
          <CheckboxGroup
            className="manual-cus"
            id="checkbox-group-2"
            options={[{ value: 'true', label: 'Mark Property Manually' }]}
            value={manualLocFlag}
            onChange={(value) => manualLocHandler(value)}
          />
        </div> */}
        {/* <div className="single-input area-wrap">
          <div style={{ width: '35%', marginRight: '10px' }}>
            <label>Latitude</label>
            <input
              className="form-control"
              type="number"
              placeholder={'Enter Latitude'}
              value={latValue}
              onChange={(e) => latitudeHandler(e)}
            />
          </div>
          <div style={{ width: '35%' }}>
            <label>Longitude</label>
            <input
              className="form-control"
              type="number"
              placeholder={'Enter Longitude'}
              value={lngValue}
              onChange={(e) => longitudeHandler(e)}
            />
          </div>
          {manualLocFlag == 'true' ? (
            <div className="pl-2 mark-manual-div">
              <button className="mark-btn" onClick={() => getCurrentLocation()}>
                {manualLocFlag == 'true' && locLoader ? (
                  <Loader type="ThreeDots" color="#a2a2a2" height="20" width="40" />
                ) : (
                  'LOCATION'
                )}
              </button>
            </div>
          ) : null}
        </div> */}
        {/*{manualLocFlag.length == 0 ? (*/}
        <div className="single-input">
          <div className="add-tag-wrap">
            <button onClick={() => geoTagModalToggle()} className={'btn-add-tagging'}>
              {latValue !== null && lngValue !== null && propsureId !== null
                ? 'GEO TAGGED'
                : 'GEO TAGGING'}
            </button>
          </div>
        </div>
        {/*) : null}*/}
        <div className="single-input">
          <h5 className="headings-cus">Owner Info</h5>
        </div>
        <div className="single-input">
          <label>Owner Name</label>
          <Select
            placeholder={'Owner name'}
            options={ownerNameOptions ? [defaultCustomer, ...ownerNameOptions] : [defaultCustomer]}
            value={ownerValue}
            onChange={(value) => ownerNameHandler(value)}
          />
          {ownerNameFlag ? (
            <p style={{ color: 'red', marginTop: '5px' }}>Owner name should be selected</p>
          ) : null}
        </div>
        <div className="single-input">
          <label>Point Of Contact Name</label>
          <Select
            placeholder={'Point of Contact Name'}
            options={ownerNameOptions ? [defaultCustomer, ...ownerNameOptions] : [defaultCustomer]}
            value={POCNameValue}
            onChange={(value) => POCNameHandler(value)}
          />
        </div>
        <div
          className="single-input area-wrap"
          style={
            props.popup
              ? {
                  position: 'absolute',
                  bottom: -38,
                  left: 0,
                  display: 'flex',
                  justifyContent: 'center',
                }
              : {}
          }
        >
          <div>
            <button
              style={{ marginRight: '10px' }}
              className="cancel"
              onClick={() => {
                if (props.popup) props.callBack('cancel')
                else props.history.push(`/armsView`)
              }}
            >
              Cancel
            </button>
          </div>
          <div>
            <button className="success" onClick={() => submitHandler()}>
              {parsed.edit == 'true' || props.propertyId ? 'Save' : 'Add Property'}
            </button>
          </div>
        </div>
      </div>
      {geoTagModal ? (
        <GeoTaggingModal
          existingLngValue={lngValue && lngValue !== null ? lngValue : null}
          existingLatValue={latValue && latValue !== null ? latValue : null}
          geoTagModal={geoTagModal}
          selectedProperty={selectedProperty}
          geoTagHandler={(lngLat) => geoTagHandlerNew(lngLat)}
          setGeoTagModal={(toggle) => geoTagModalToggle(toggle)}
          geoTagSubmitHandler={geoTagSubmitHandler}
          /*geoTagHandler={(lngLat, propsureId) => geoTagHandler(lngLat, propsureId)}*/
        />
      ) : null}
    </div>
  )
}
const mapDispatchToProps = (dispatch) => ({
  fetchCities: () => dispatch(fetchCities()),
})
const mapStateToProps = (state) => ({
  cities: state.user.cities,
})
export default connect(mapStateToProps, mapDispatchToProps)(AddArmsProperty)
