/** @format */

import * as ActionTypes from '../actions/buyrentLeads'

const initialState = {
  isLoading: false,
  isDealsLoading: false,
  isDemandLeadsLoading: false,
  isReferredLeadLoading: false,
  isTeamLeadLoading: false,
  isTeamDealLoading: false,
  filters: {
    filters: {},
    quickFilters: [],
  },
}

const BuyRentLeads = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_BUYRENT_LEADS:
      return {
        ...state,
        leads: action.data,
        deactivatedLeads: [],
        myLeads: [],
        companyLeads: [],
        deals: [],
        referredLeads: [],
        referredDeals: [],
        demandLeads: [],
        teamLeads: [],
        teamDeals: [],
        isLoading: action.loading,
      }
    case ActionTypes.FETCH_BUYRENT_MY_LEADS:
      return {
        ...state,
        myLeads: action.data,
        deactivatedLeads: [],
        deactivatedDeals: [],
        companyLeads: [],
        leads: [],
        deals: [],
        referredLeads: [],
        referredDeals: [],
        demandLeads: [],
        teamLeads: [],
        teamDeals: [],
        isLoading: action.loading,
      }
    case ActionTypes.FETCH_BUYRENT_DEACTIVATED_LEADS:
      return {
        ...state,
        deactivatedLeads: action.data,
        deactivatedDeals: [],
        myLeads: [],
        companyLeads: [],
        leads: [],
        deals: [],
        referredLeads: [],
        referredDeals: [],
        demandLeads: [],
        teamLeads: [],
        teamDeals: [],
        isLoading: action.loading,
      }
    case ActionTypes.FETCH_BUYRENT_DEACTIVATED_DEALS:
      return {
        ...state,
        deactivatedLeads: [],
        deactivatedDeals: action.data,
        myLeads: [],
        companyLeads: [],
        leads: [],
        deals: [],
        referredLeads: [],
        referredDeals: [],
        demandLeads: [],
        teamLeads: [],
        teamDeals: [],
        isLoading: action.loading,
      }
    case ActionTypes.FETCH_BUYRENT_COMPANY_LEADS:
      return {
        ...state,
        companyLeads: action.data,
        deactivatedLeads: [],
        deactivatedDeals: [],
        myLeads: [],
        leads: [],
        deals: [],
        referredLeads: [],
        referredDeals: [],
        demandLeads: [],
        teamLeads: [],
        teamDeals: [],
        isLoading: action.loading,
      }
    case ActionTypes.FETCH_BUYRENT_DEALS:
      return {
        ...state,
        deals: action.data,
        deactivatedLeads: [],
        deactivatedDeals: [],
        myLeads: [],
        companyLeads: [],
        leads: [],
        referredLeads: [],
        referredDeals: [],
        demandLeads: [],
        teamLeads: [],
        teamDeals: [],
        isDealsLoading: action.loading,
      }
    case ActionTypes.FETCH_BUYRENT_REFERRED_LEADS:
      return {
        ...state,
        referredLeads: action.data,
        referredDeals: [],
        deactivatedLeads: [],
        deactivatedDeals: [],
        deals: [],
        myLeads: [],
        companyLeads: [],
        leads: [],
        demandLeads: [],
        teamLeads: [],
        teamDeals: [],
        isReferredLeadLoading: action.loading,
      }
    case ActionTypes.FETCH_BUYRENT_REFERRED_DEALS:
      return {
        ...state,
        referredLeads: [],
        referredDeals: action.data,
        deactivatedLeads: [],
        deactivatedDeals: [],
        deals: [],
        myLeads: [],
        companyLeads: [],
        leads: [],
        demandLeads: [],
        teamLeads: [],
        teamDeals: [],
        isReferredLeadLoading: action.loading,
      }
    case ActionTypes.FETCH_BUYRENT_DEMAND_LEADS:
      return {
        ...state,
        demandLeads: action.data,
        deactivatedLeads: [],
        deactivatedDeals: [],
        myLeads: [],
        companyLeads: [],
        deals: [],
        referredLeads: [],
        referredDeals: [],
        leads: [],
        teamLeads: [],
        teamDeals: [],
        isDemandLeadsLoading: action.loading,
      }
    case ActionTypes.FETCH_BUYRENT_TEAM_LEADS:
      return {
        ...state,
        teamLeads: action.data,
        deactivatedLeads: [],
        deactivatedDeals: [],
        myLeads: [],
        companyLeads: [],
        deals: [],
        referredLeads: [],
        referredDeals: [],
        demandLeads: [],
        leads: [],
        teamDeals: [],
        isTeamLeadLoading: action.loading,
      }
    case ActionTypes.FETCH_BUYRENT_TEAM_DEALS:
      return {
        ...state,
        teamDeals: action.data,
        deactivatedLeads: [],
        deactivatedDeals: [],
        myLeads: [],
        companyLeads: [],
        deals: [],
        referredLeads: [],
        referredDeals: [],
        demandLeads: [],
        teamLeads: [],
        leads: [],
        isTeamDealLoading: action.loading,
      }
    case ActionTypes.SET_BUYRENT_LEADS_FILTERS:
      return {
        ...state,
        filters: { ...state.filters, filters: action.data },
      }
    case ActionTypes.SET_BUYRENT_LEADS_COUNT_FILTER:
      return {
        ...state,
        leadCountFilter: action.data,
      }
    default:
      return {
        ...state,
      }
  }
}

export default BuyRentLeads
