/** @format */

import React, { useState } from 'react'

import { Modal, ModalBody, ModalFooter, ModalHeader,} from 'reactstrap'
import Button from "../../components/common/Button";


import Inner from './Inner'
import './style.scss'

const AddNewNextOfKinModal = (props) => {
  const {
    activeClientModal,
    clientModalToggle,
    formData,
    handleChange,
    handleChangePhone,
    phone,
    createClient,
    validationCheck,
    loadingOnsubmit,
    cnicValidation,
    phoneValidate,
    phoneValidate1,
    phoneValidate2,
    editAble,
    emailValidate,
    fromPage,
    checkPurchaserPage,
    nextOfkinFormEmpty,
    nextOfkinCancel,
    checkCnicLength,
    checkCnicValidation,
    checkProfession
  } = props

  return (
    <Modal
      isOpen={activeClientModal}
      toggle={clientModalToggle}
      size="lg"
      style={{ maxWidth: '730px', width: '100%' }}
      contentClassName="NextOfKin-modal-width nextOfKin"

    >
      <div className="createClientModal hasScroll" ref={props.setRef}>
        <ModalHeader toggle={clientModalToggle} className='headerKIN'>
          {editAble === true ? 'Edit' : 'Add'} Next Of Kin
        </ModalHeader>

        <ModalBody>
          <div className={`filterMainWrap padding__modal`}>
            <Inner
              isModal={true}
              formData={formData}
              phone={phone}
              loadingOnsubmit={loadingOnsubmit}
              validationCheck={validationCheck}
              cnicValidation={cnicValidation}
              checkProfession={checkProfession}
              phoneValidate={phoneValidate}
              phoneValidate1={phoneValidate1}
              phoneValidate2={phoneValidate2}
              emailValidate={emailValidate}
              editAble={editAble}
              handleChange={handleChange}
              handleChangePhone={handleChangePhone}
              createClient={createClient}
              fromPage={fromPage}
              clientModalToggle={clientModalToggle}
              nextOfkinCancel={nextOfkinCancel}
              checkPurchaserPage={checkPurchaserPage}
              nextOfkinFormEmpty={nextOfkinFormEmpty}
              checkCnicLength={checkCnicLength}
              checkCnicValidation={checkCnicValidation}
            />
          </div>
        </ModalBody>
        <ModalFooter style={{justifyContent: "start"}}>


        <div className={`filterInputMain`}>
              <div className="registerButtonsNextOfkin">
                <Button
                  text={'Cancel'}
                   textColor={'black'}
                   width={'90px'}
                   height={'30px'}
                   display={'inline-block'}
                   className={'px-4 rounded-pill small cancelBtn'}
                  onClick={() => {
                   //  clientModalToggle()
                     nextOfkinCancel()
                  }}
                >
                  Cancel
                   </Button>
                <Button

                  text =  { editAble && editAble === true ? ( 'Update' ) : ('Add')}
                  textColor={'white'}
                   width={'90px'}
                   height={'30px'}
                   display={'inline-block'}
                   loader={loadingOnsubmit}
                   buttonColor="#026ff2"
                    className={'bgClr px-4 rounded-pill mr-2 small bgClr'}
                   style={{ border: '1px solid #d3d3d3'}}
                  onClick={() => {
                    return createClient()
                  }}
                >

          

                </Button>
                </div>
            </div>


{/*
        <Button >Save</Button>
        <Button >Close</Button> */}
      </ModalFooter>
      </div>
    </Modal>
  )
}

export default AddNewNextOfKinModal
