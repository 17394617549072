import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import NoDeviceIcon from '../../../images/device/no_device.png'
import { connect } from 'react-redux'
import './index.scss'
import { fetchDevices } from '../../../actions/user'
import SingleDevice from './SingleDevice'
import Confirm from './Confirm'
import ConfirmModal from './Confirm'
import Button from '../../common/Button'

const DeviceModal = (props) => {

  useEffect(() => {
    props.fetchDevices()
  }, [])

  return (
    <>
        <Modal
          isOpen={props.showDevicesModal}
          toggle={(toggle) => props.setShowDevicesModal(!toggle)}
          contentClassName="modal-action-size"
          className="modal-action-size device-modal"
        >
          <ModalBody>
            {/* <div className="close-icon-container">
              <i
                className="fal fa-times-circle close-icon"
                onClick={() => props.setShowDevicesModal(false)}
              ></i>
            </div> */}
            <div className={"row mt-3 justify-content-center"}>
              <div className={"col-sm-12 feedback-header"}>
                <h4 className="feedback-msg">{props.devices && props.devices.length ? 'Select mobile device' : 'Unable to make call'}</h4>
                {props.devices && props.devices.length ?
                  <p>Select mobile device {props.devices.length > 1 ? 'from below list':''} to initiate call</p> :
                  <p>You are not logged into ARMS on any mobile device.</p>
                }
              </div>
            </div>

            <div className={`devices-list mt-4  px-3 ${props.devices && props.devices.length ? 'mb-5' : 'mb-3'}`}>
              {props.devices && props.deviceFlag && props.devices.length ?
                props.devices.map((d, i) => <SingleDevice
                  device={d}
                  makeCall={(d) => props.makeCall(d)}
                  fromList={true}
                  key={i}
                />):
                props.deviceFlag === false ?
                (<div class={"data-loader"}>
                <span className="fa fa-circle-o-notch fa-spin"></span>
                </div>)
                :
                  <>
                    <div className={"m-4 d-flex justify-content-center"}>
                      <img src={NoDeviceIcon} className="no-device-img container-image" />
                    </div>
                    <div className={"row  justify-content-center"}>
                      <div className={"col-sm-12 feedback-msg"}>
                        <p>You can make call manually and Register call feedback.</p>
                      </div>
                    </div>
                    <div className={"d-flex justify-content-center mt-4"}>
                    <Button
                      text={'OK'}
                      textColor={'white'}
                      width={'90px'}
                      height={'30px'}
                      display={'inline-block'}
                      buttonColor="#026ff2"
                      className={'px-4 rounded-pill mr-2 small'}
                      onClick={() => props.setShowDevicesModal(false)}
                    />
                    </div>
                    {/* <div className={"d-flex justify-content-center mt-5"}>
                      <a onClick={() => props.fetchDevices()} className={"text-white task-button btn btn-sm"}>Refresh list</a>
                    </div> */}
                  </>
                }
            </div>
          </ModalBody>
        </Modal>
      </>
  )
}
const mapdispatchToProps = (dispatch) => {
  return {
    fetchDevices: () => dispatch(fetchDevices())
  }
}

const mapdispatchToState = (state) => {
  return {
    devices: state.user.devices,
    deviceFlag: state.user.deviceFlag
  }
}

export default connect(mapdispatchToState, mapdispatchToProps)(DeviceModal)
