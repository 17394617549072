import React from 'react'
import { Modal, ModalBody } from 'reactstrap'
import Button from '../../common/Button'
import connectOptions from './Config'
import { capitalize_Words } from '../../../utils/commonFormat'

const ReConnect = (props) => {

  return (
    <>
      <Modal
        isOpen={props.reConnect}
        toggle={(toggle) => props.setReConnect(!toggle)}
        contentClassName="modal-action-size"
        className="modal-action-size"
        backdrop={'static'}
      >
        <ModalBody>
          <h4>What would you like to do next ?</h4>

          <div className={"row mt-3 justify-content-center mb-4"}>
            <div className={"col-sm-11 mt-5 mb-3"}>
              <div
                className="btn-sm d-flex justify-content-center"
              >
                <Button
                  text={"Connect Again"}
                  className={"px-3 rounded-pill py-2"}
                  onClick={() => props.setAgainOrDoNothing('again')}
                  buttonColor='#026ff2'
                  textColor={'white'}
                  style={{border:'2px solid #026ff2', width: 135}}
                />
              </div>
              {['meeting', 'viewing'].indexOf(props.taskType) > -1 ?
                <>
                <div
                  className="btn-sm d-flex justify-content-center mt-3 mb-3"
                >
                  <Button
                    text={`Cancel ${capitalize_Words(props.taskType)}`}
                    className={"px-3 rounded-pill py-2"}
                    onClick={() => {
                      if(!props.loader)
                        props.setFollowUpSetup(true, true)
                    }}
                    textColor='#026ff2'
                    buttonColor={'white'}
                    style={{border:'2px solid #026ff2', width: 135}}
                  />
                </div>

                  <div
                    className="btn-sm d-flex justify-content-center mt-3 mb-4"
                  >
                    <Button
                      text={`Do nothing`}
                      className={"px-3 rounded-pill py-2"}
                      onClick={() => props.setAgainOrDoNothing('do_nothing')}
                      textColor='#026ff2'
                      buttonColor={'white'}
                      style={{border:'2px solid #026ff2', width: 135}}
                    />
                  </div>
                </> :
              <div
                className="btn-sm d-flex justify-content-center mt-3 mb-4"
              >
                <Button
                  text={"Set Followup"}
                  className={"px-3 rounded-pill py-2"}
                  onClick={() => {
                    if(!props.loader)
                      props.setFollowUpSetup(true)
                  }}
                  textColor='#026ff2'
                  buttonColor={'white'}
                  style={{border:'2px solid #026ff2', width: 135}}
                />
              </div> }
            </div>
          </div>
        </ModalBody>
      </Modal>

    </>
  )
}
export default ReConnect
