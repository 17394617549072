/** @format */
import axios from 'axios'
import config from '../config'
import { mapFiltersToQuery } from '../components/common/Diary/DiaryUtilHelper'
import { DIARY_MENU_ACTION } from '../utils/constant_data'
import actions from 'redux-form/lib/actions'
import moment, { isMoment } from 'moment'
import {showToastMsg} from "../utils/helper";

export const DIARY_TASK_TOGGLE = 'DIARY_TASK_TOGGLE'
export const FETCH_DIARY_TASKS = 'FETCH_DIARY_TASKS'
export const FETCH_LEAD_DIARY_TASKS = 'FETCH_LEAD_DIARY_TASKS'
export const SET_LOADING = 'SET_LOADING'
export const SET_OVERDUE_COUNT = 'SET_OVERDUE_COUNT'
export const FETCH_SOCKET_DATA = 'FETCH_SOCKET_DATA'
export const FETCH_DIARY_FEEDBACKS = 'FETCH_DIARY_FEEDBACKS'
export const FETCH_OVERDUE_DIARY_TASKS = 'FETCH_OVERDUE_DIARY_TASKS'

export const SET_DIARY_FILTERS = 'SET_DIARY_FILTERS'
export const SET_OVERDUE_FILTERS = 'SET_OVERDUE_FILTERS'
export const SET_DIARY_QUICK_FILTERS = 'SET_DIARY_QUICK_FILTERS'
export const SET_OVERDUE_QUICK_FILTERS = 'SET_OVERDUE_QUICK_FILTERS'
export const SET_DIARY_DATA_SORT = 'SET_DIARY_DATA_SORT'
export const SET_DIARY_OVER_DUE_SORT = 'SET_DIARY_OVER_DUE_SORT'

export const SET_IS_OVER_DUE = 'SET_IS_OVER_DUE'
export const SET_DIARY_MENU_ACTION = 'SET_DIARY_MENU_ACTION'
export const SET_SELECTED_AGENT = 'SET_SELECTED_AGENT'
export const ACTIVATE_DIALER = 'ACTIVATE_DIALER'
export const DIALER_LEAD_INFO = 'DIALER_LEAD_INFO'
export const SOFT_PHONE_DIAL_NUMBER = 'SOFT_PHONE_DIAL_NUMBER'
export const ASSIGN_LEAD_ID_DIARY_ID = 'ASSIGN_LEAD_ID_DIARY_ID'

export const activateDialer = (isActivate) => {
  return (dispatch) => {
    dispatch({ type: ACTIVATE_DIALER, payload: isActivate })
  }
}

export const dialerLeadInfo = (data) => {
  return (dispatch) => {
    dispatch({ type: DIALER_LEAD_INFO, payload: data })
  }
}
export const mwsSoftPhoneDialNumber = (data) => {
  return (dispatch) => {
    dispatch({ type: SOFT_PHONE_DIAL_NUMBER, payload: data })
  }
}
export const mwsAssignLeadIdDiaryId = (data) => {
  return (dispatch) => {
    dispatch({ type: ASSIGN_LEAD_ID_DIARY_ID, payload: data })
  }
}



export const diaryCreateTaskToggle = () => {
  return (dispatch) => {
    dispatch({ type: DIARY_TASK_TOGGLE })
  }
}

export const getDiaryTasks = (
  pagination = { currentPage: 1, pageSize: config.pageSize },
  filters = '',
  isOverDue = null,
  leadParam = null,
  status = null,
  leadTask = null,
  isShowDiaryFromEveryWhere = null
) => {
  return (dispatch, getState) => {
    let diaryState = { ...getState().Diary }
    let moreFilters =
      diaryState.isOverDue === false
        ? diaryState.filters.diary.filters
        : diaryState.filters.overDue.filters
    let quickFilters =
      diaryState.isOverDue === false
        ? diaryState.filters.diary.quickFilters
        : diaryState.filters.overDue.quickFilters
    let dataSort =
      diaryState.isOverDue === false
        ? diaryState.filters.diary.dataSort
        : diaryState.filters.overDue.dataSort
    filters = mapFiltersToQuery(moreFilters, quickFilters, dataSort)
    const { currentPage, pageSize } = pagination
    var url = `${config.apiPath}/api/v1/diary/all?page=${currentPage}&pageSize=${pageSize}`
    if (isShowDiaryFromEveryWhere !== true) {
      if (!leadParam) {
        if (filters) url += `&${filters}`
        let isBeforeDate = false
        if (!diaryState.isOverDue && quickFilters && quickFilters.length > 0) {
          let dateFilter = quickFilters.filter((f) => f.for.includes('date'))
          if (dateFilter && dateFilter.length > 0 && isMoment(moment(dateFilter[0].value))) {
            if (moment(dateFilter[0].value, 'YYYY-MM-DD').isBefore(moment().format('YYYY-MM-DD'))) {
              isBeforeDate = true
            }
          }
        }
        if (diaryState.isOverDue && !isBeforeDate) {
          url += `&overDue=${diaryState.isOverDue}&status=pending`
        } else if (!isBeforeDate) url += `&overDue=${diaryState.isOverDue}`
      } else url += `&${leadParam}`
    } else {
      if (filters) url += `&${filters}`
      url += `&date[]=${moment().format('YYYY-MM-DD')}`
      url += `&overDue=${false}`
    }
    if (status) url += `&status=${status}`
    if (diaryState.selectedAgent) {
      url += `&userID=${diaryState.selectedAgent.id}`
    }

    if (leadTask) dispatch({ type: FETCH_LEAD_DIARY_TASKS, data: [], loading: true })
    else dispatch({ type: FETCH_DIARY_TASKS, data: [], loading: true })

    if (isShowDiaryFromEveryWhere) {
    }

    axios
      .get(url)
      .then(function (response) {
        // if (isOverDue) dispatch({ type: FETCH_OVERDUE_DIARY_TASKS, data: response.data })
        // else
        if (leadTask)
          dispatch({ type: FETCH_LEAD_DIARY_TASKS, data: response.data, loading: false })
        else dispatch({ type: FETCH_DIARY_TASKS, data: response.data, loading: false })
      })
      .catch(function (xhr, status, err) {
        if (leadTask) dispatch({ type: FETCH_LEAD_DIARY_TASKS, data: [], loading: false })
        else return dispatch({ type: FETCH_DIARY_TASKS, data: [], loading: false })
      })
  }
}

export const setDiaryOverDueCount = () => {
  var url = `${config.apiPath}/api/diary/overdue/count`

  return (dispatch, getState) => {
    let diaryState = { ...getState().Diary }
    if (diaryState.selectedAgent) {
      url += `?userID=${diaryState.selectedAgent.id}`
    }
    axios.get(url).then(function (response) {
      dispatch({ type: SET_OVERDUE_COUNT, data: response.data && response.data.count })
    })
  }
}

export const getSocketCall = (params = {}) => {
  // var url = `http://localhost:3001/api/cdrreport/getSocketAction`
  var url = `${config.apiPath}/api/cdrreport/createCdr`

  return (dispatch) => {
    dispatch({ type: FETCH_SOCKET_DATA, data: [], loading: true })

    axios.post(url, {
      ...params
    })
    .then(function (response) {
      dispatch({ type: FETCH_SOCKET_DATA, data: response.data})
      showToastMsg('Record Added!','success')
    })
    .catch(function (xhr, status, err) {
      dispatch(getSocketCall(params));
      return dispatch({ type: FETCH_SOCKET_DATA, data: [], loading: false })
    })
  }
}
// diary reason issue fixed by taimoor khan
export const getDiaryFeedbacks = (params = {}) => {
  let url = `${config.apiPath}/api/feedbacks/fetch?`
  if (params.section) url += `section=${params.section}&`
  if (params.taskType) url += `taskType=${params.taskType}&`
  if (params.actionType) url += `actionType=${params.actionType}&`
  if (params.leadType) url += `leadType=${params.leadType}`

  return (dispatch) => {
    if(params.actionType && params.leadType || params.section) {

      dispatch({ type: FETCH_DIARY_FEEDBACKS, data: [], loading: true })
      axios
        .get(url)
        .then(function (response) {
          dispatch({ type: FETCH_DIARY_FEEDBACKS, data: response.data, loading: false })
        })
        .catch(function (xhr, status, err) {
          return dispatch({ type: FETCH_DIARY_FEEDBACKS, data: [], loading: false })
        })
    } else dispatch({ type: FETCH_DIARY_FEEDBACKS, data: [], loading: false })
  }
}
export const setDiaryFilters = (filters) => {
  return (dispatch) => {
    dispatch({ type: SET_DIARY_FILTERS, data: filters })
    dispatch(getDiaryTasks())
  }
}
export const setDiaryQuickFilters = (quickFilters, isChange = false) => {
  return (dispatch) => {
    dispatch({ type: SET_DIARY_QUICK_FILTERS, data: quickFilters })
    isChange && dispatch(getDiaryTasks())
  }
}
export const setOverDueFilters = (overDueFilters) => {
  return (dispatch) => {
    dispatch({ type: SET_OVERDUE_FILTERS, data: overDueFilters })
    dispatch(getDiaryTasks())
  }
}
export const setOverDueQuickFilters = (overDueQuickFilters, isChange = false) => {
  return (dispatch) => {
    dispatch({ type: SET_OVERDUE_QUICK_FILTERS, data: overDueQuickFilters })
    isChange && dispatch(getDiaryTasks())
  }
}
export const setIsOverDue = (isOverDue) => {
  return (dispatch) => {
    dispatch({ type: SET_IS_OVER_DUE, data: isOverDue })
  }
}
export const setDiaryMenuAction = (action) => {
  return (dispatch) => {
    dispatch({ type: SET_DIARY_MENU_ACTION, data: action })
    if (DIARY_MENU_ACTION.VIEW_MY_DIARY === action) {
      dispatch({ type: SET_SELECTED_AGENT, data: null })
      dispatch(getDiaryTasks())
      dispatch(setDiaryOverDueCount())
    }
  }
}
export const setSelectedAgent = (agent) => {
  return (dispatch) => {
    dispatch({ type: SET_SELECTED_AGENT, data: agent })
    if (agent) {
      dispatch(getDiaryTasks())
      dispatch(setDiaryOverDueCount())
    }
  }
}
export const setDiaryDataSort = (dataSort) => {
  return (dispatch) => {
    dispatch({ type: SET_DIARY_DATA_SORT, data: dataSort })
    if (dataSort) {
      dispatch(getDiaryTasks())
      dispatch(setDiaryOverDueCount())
    }
  }
}
export const setOverDueDataSort = (dataSort) => {
  return (dispatch) => {
    dispatch({ type: SET_DIARY_OVER_DUE_SORT, data: dataSort })
    if (dataSort) {
      dispatch(getDiaryTasks())
      dispatch(setDiaryOverDueCount())
    }
  }
}
