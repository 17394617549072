/** @format */

import * as ActionTypes from '../actions/legal'

const initialState = {}

const Legal = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_BUY_RENT_LEGAL_VIEW:
      return {
        ...state,
        buyRentLegalView: action.data,
      }
    case ActionTypes.FETCH_BUY_RENT_LEGAL_VIEW_LOADER:
      return {
        ...state,
        buyRentLegalViewLoader: action.data,
      }
    case ActionTypes.FETCH_BUY_RENT_LEGAL_VIEW_SUCCESS:
      return {
        ...state,
        buyRentLegalViewLoader: action.data,
      }
    default:
      return state
  }
}
export default Legal
