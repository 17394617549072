import React from 'react'

const SortItem = (props) => {
  return (
    <button className={`btn btn-block ${props.active ? 'active':''}`} onClick={() => props.callBack(props.item)}>
      {props.item.label}
    </button>
  )
}

export default SortItem
