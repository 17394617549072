/** @format */

import React, { Component } from 'react'
import './style.scss'
import { setOptions, Document, Page, pdfjs } from 'react-pdf'
import PDF from '../../images/pdf.png'
import clsx from 'clsx'
import Word from '../../images/word.png'
import EXCEL from '../../images/excel.png'
const pdfjsVersion = '2.0.305'
// setOptions({
//   workerSrc: `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsVersion}/pdf.worker.js`
// });
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
// import { Document, Page } from "react-pdf/build/entry.noworker";

export default class FileViewer extends Component {
  state = {
    numPages: null,
    pageNumber: 1,
    error: false
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages })
  }

  render() {
    const { pageNumber, error } = this.state
    const { file, timeLine, payment } = this.props
    var sliceFirstFour = file.slice(4)
    var filePath = timeLine ? 'http' + sliceFirstFour : 'https' + sliceFirstFour

    return (
      error ?
          <img
            style={{
              width: 60,
              height: 60,}}
            src={PDF}
          /> :
      <div
        style={{
          width: 60,
          height: 60,
          overflow: 'hidden',}}>
        <Document file={payment ? file : filePath} onLoadError={() => this.setState({error: true})} onLoadSuccess={this.onDocumentLoadSuccess}>
          <Page pageNumber={pageNumber} />
        </Document>
      </div>
    )
  }
}
