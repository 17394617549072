/** @format */
import moment from 'moment'
import _, { isArray } from 'lodash'
import YellowLead from './../../../images/yellow-marker.svg'
import RedLead from './../../../images/red-marker.svg'
import { TaskTypeLabels } from './../../../utils/constant_data'
import { capitalize_Words } from '../../../utils/commonFormat'
import { TasksType } from './../../../utils/constant_data'
import {
  DIARY_QUICK_FILTERS,
  DIARY_TASK_TILE_COLOR,
  DIARY_TASK_ACTION,
} from '../../../utils/constant_data'
import { capitalizeWordsWithoutUnderscore } from '../../../utils/commonFormat'
import { getPermissionValue } from '../../_unlockComponent'
import { PermissionActions, PermissionFeatures } from '../../../utils/permissionConstants'
import { ColumnName } from '../../TableConfig/DiaryConfig'

const isOverDue = () => {
  if (!window.location.pathname.includes('diary')) {
    return true
  }
  return false
}
const removeExtraChar = (str) => {
  return str.replace(/[_ ]+/g, '').toLocaleLowerCase()
}
const tileColor = {
  FOLLOWUP: '#FFC61B',
  CLOSED: '#9A9A9A',
  CONNECT: '#7BB461',
  MEETING: '#006FF2',
  VIEWING: '#006FF2',
  MORNINGMEETING: '#73C2FE',
  DAILYUPDATES: '#71C2FE',
  MEETINGWITHPP: '#71C2FE',
  REASSIGN: '#006FF2',
}
const classificationColor = {
  WARM: '#ff6600',
  HOT: '#d30404',
  COLD: '#0082eb',
  DEFAULT: '#9a9a9a',
}

const getTaskAlert = (data) => {
  if (data.taskType === TasksType.CONNECT) {
    //TODO need to get server time
    var curDate = moment().format('YYYY-MM-DD')
    var dataDate = moment(new Date(data.start)).format('YYYY-MM-DD')
    var time = moment.duration(moment(moment()).diff(moment(new Date(data.start)))).asMinutes()
    time = time.toFixed(0)

    if (
      curDate === dataDate &&
      !data.status.includes('completed') &&
      !data.status.includes('cancelled')
    ) {
      if (time > 30 && time < 60) {
        return YellowLead
      }
      if (time > 60) {
        return RedLead
      }
    }
  }
}
export const getRequirement = (data) => {
  const getArea = (armsLeadAreas) => {
    if (isArray(armsLeadAreas)) {
      let area = []
      for (let i = 0; i < armsLeadAreas.length; i++) {
        armsLeadAreas[i].area && area.push(armsLeadAreas[i].area.name)
      }
      return area.toString()
    }

    if (_.isObject(armsLeadAreas)) {
      return armsLeadAreas && armsLeadAreas.area && armsLeadAreas.area.name
    }
  }
  if (data.armsProjectLead) {
    if (data.armsProjectLead && data.armsProjectLead.hasBooking) {
      return (
        `Booked
        ${
          data.armsProjectLead && data.armsProjectLead.unit && data.armsProjectLead.unit !== null
            ? data.armsProjectLead &&
              data.armsProjectLead.unit &&
              data.armsProjectLead.unit.type &&
              (data.armsProjectLead.unit.type === 'regular' ||
                data.armsProjectLead.unit.type === 'regular')
              ? 'Unit'
              : 'Pearl'
            : '--'
        }
        In  ${data.armsProjectLead.projectType || data.armsProjectLead.projectName ? '' : ''}` +
        (data.armsProjectLead.project && data.armsProjectLead.project.name
          ? data.armsProjectLead.project.name
          : 'Any Project')
      )
    } else {
      return (
        `Looking to Invest in  ${
          data.armsProjectLead.projectType || data.armsProjectLead.projectName ? '' : ''
        }` +
        (data && data.armsProjectLead && data.armsProjectLead.projectName
          ? data.armsProjectLead.projectName === 'Any'
            ? 'Any Project'
            : data.armsProjectLead.projectName
          : data.armsProjectLead.project && data.armsProjectLead.project.name
          ? data.armsProjectLead.project.name
          : 'Any Project') +
        (data.armsProjectLead.projectType ? ` ${data.armsProjectLead.projectType}` + '' : '')
      )
    }
  } else if (data.armsLead) {
    let area = getArea(data.armsLead.armsLeadAreas)
    let purpose = data.armsLead.purpose
    if (purpose && purpose.toLocaleLowerCase() === 'sale') {
      purpose = 'buy'
    }
    return (
      (purpose != null ? capitalize_Words(purpose) + ' - ' : '') +
      ((data.armsLead.size === 0 || !data.armsLead.size) &&
      (!data.armsLead.max_size ||
        data.armsLead.max_size === 10000000 ||
        data.armsLead.max_size === 0)
        ? ' Any '
        : (data.armsLead.size !== null ? data.armsLead.size : '') +
          (data.armsLead.max_size !== null && data.armsLead.max_size != data.armsLead.size
            ? ' to ' + data.armsLead.max_size
            : '') +
          (data.armsLead.size != null || data.armsLead.max_size != null
            ? ' ' + data.armsLead.size_unit + ' '
            : '')) +
      (data.armsLead.subtype ? capitalize_Words(data.armsLead.subtype) : '') +
      (area ? ' in ' + area : '')
    )
  } else if (data.wanted) {
    let area = getArea(data.wanted.wanted_areas)
    let purpose = data.wanted.purpose
    if (purpose && purpose.toLocaleLowerCase() === 'sale') {
      purpose = 'buy'
    } else if (purpose && purpose.toLocaleLowerCase() === 'invest') {
      return (
        'Wanted - ' +
        capitalize_Words(purpose) +
        ' - ' +
        (data.wanted.projectType ? data.wanted.projectType + ' in ' : '') +
        data.wanted.projectName
      )
    }

    return (
      'Wanted - ' +
      (purpose ? capitalize_Words(purpose) + ' - ' : '') +
      (data.wanted.size !== 0
        ? (data.wanted.size != null ? data.wanted.size : '') +
          (data.wanted.max_size != null && data.wanted.max_size != data.wanted.size
            ? ' to ' + data.wanted.max_size
            : '') +
          (data.wanted.size != null || data.wanted.max_size != null
            ? ' ' + data.wanted.size_unit + ' '
            : '')
        : ' Any ') +
      ' ' +
      (data.wanted.subtype ? capitalize_Words(data.wanted.subtype) : '') +
      (area ? ' in ' + area : '')
    )
  }
  return ''
}
const getClassification = (data) => {
  let lead = data.armsProjectLead || data.armsLead || data.wanted
  if (lead) {
    return lead.leadCategory ? capitalize_Words(lead.leadCategory) : 'Not Defined'
  }
  // if (data && data.armsProjectLead) {
  //   return data.armsProjectLead.leadCategory ? data.armsProjectLead.leadCategory : 'Not Defined'
  // } else if (data && data.armsLead) {
  //   return data.armsLead.leadCategory ? data.armsLead.leadCategory : 'Not Defined'
  // }else if(data && data.wanted){
  //   return data.wanted.leadCategory ? data.wanted.leadCategory : 'Not Defined'
  // }
  return ''
}
const getClassificationColor = (data) => {
  let dependsOn = ''
  let lead = data.armsProjectLead || data.armsLead || data.wanted
  if (lead) {
    dependsOn = lead.leadCategory
  }

  // if (data && data.armsProjectLead) {
  //   dependsOn = data.armsProjectLead.leadCategory
  // } else if (data && data.armsLead) {
  //   dependsOn = data.armsLead.leadCategory
  // } else if (data && data.armsLead) {
  //   dependsOn = data.armsLead.leadCategory
  // }
  if (!dependsOn) dependsOn = 'DEFAULT'
  return classificationColor[dependsOn && dependsOn.toLocaleUpperCase()]
}

const isClassificationDisable = (data, props) => {
  if (
    props.isReadOnly ||
    (data &&
      data.status &&
      (data.status.includes('completed') || data.status.includes('cancelled')))
  ) {
    return true
  }
  let lead = data.armsProjectLead || data.armsLead || data.wanted
  if (lead) {
    return false
  }
  // if (data && data.armsProjectLead) {
  //   return false
  // } else if (data && data.armsLead) {
  //   return false
  // }else if (data && data.wanted) {
  //   return false
  // }
  return true
}
export const getWantedAssignedTo = (rowData) => {
  let assignto = DIARY_TASK_ACTION.ASSIGN_TO_AREA_MANAGER
  if (
    rowData.wanted &&
    rowData.wanted.purpose &&
    rowData.wanted.purpose.toLocaleLowerCase() === 'invest'
  ) {
    assignto = DIARY_TASK_ACTION.ASSIGN_TO_INVESTMENT_ADVISOR
  } else if (
    rowData.wanted &&
    rowData.wanted.purpose &&
    (removeExtraChar(rowData.wanted.purpose).toLocaleLowerCase() === 'sell' ||
      removeExtraChar(rowData.wanted.purpose).toLocaleLowerCase() === 'rentout')
  ) {
    assignto = DIARY_TASK_ACTION.ASSIGN_TO_CATALOGER
  }
  return assignto
}
const getActions = (leadType, taskType, props, rowData) => {
  switch (leadType) {
    case 'project':
      switch (taskType) {
        case removeExtraChar(TasksType.CONNECT):
          return [DIARY_TASK_ACTION.Task_Detail].filter((f) => f)
        case removeExtraChar(TasksType.FOLLOWUP):
          return [DIARY_TASK_ACTION.Task_Detail].filter((f) => f)
        case removeExtraChar(TasksType.MEETING):
          return [
            DIARY_TASK_ACTION.Task_Detail,
            !props.isReadOnly && DIARY_TASK_ACTION.CONNECT,
            !props.isReadOnly && DIARY_TASK_ACTION.CANCEL_MEETING,
            DIARY_TASK_ACTION.RESCHEDULE_MEETING,
          ].filter((f) => f)
        case removeExtraChar(TasksType.VIEWING):
          return []
      }
      break
    case 'buyrent':
      switch (taskType) {
        case removeExtraChar(TasksType.CONNECT):
          return [DIARY_TASK_ACTION.Task_Detail].filter((f) => f)
        case removeExtraChar(TasksType.FOLLOWUP):
          return [DIARY_TASK_ACTION.Task_Detail].filter((f) => f)
          break
        case removeExtraChar(TasksType.MEETING):
          return []
        case removeExtraChar(TasksType.VIEWING):
          return [
            DIARY_TASK_ACTION.Task_Detail,
            props.assignedToMe && !props.isReadOnly && DIARY_TASK_ACTION.CONNECT,
            props.assignedToMe && !props.isReadOnly && DIARY_TASK_ACTION.CANCEL_VIEWING,
            DIARY_TASK_ACTION.RESCHEDULE_VIEWING,
          ].filter((f) => f)
      }
      break
    case 'wanted':
      switch (taskType) {
        case removeExtraChar(TasksType.CONNECT):
          return [DIARY_TASK_ACTION.Task_Detail].filter((f) => f)
        case removeExtraChar(TasksType.FOLLOWUP):
          return [
            DIARY_TASK_ACTION.Task_Detail,
            !props.isReadOnly && DIARY_TASK_ACTION.EDIT_TASK,
          ].filter((f) => f)
        case removeExtraChar(TasksType.MEETING):
          return []
        case removeExtraChar(TasksType.VIEWING):
          return []
        case removeExtraChar(TasksType.REASSIGN):
          return [DIARY_TASK_ACTION.Task_Detail, DIARY_TASK_ACTION.REASSIGN_LEAD]
      }
      break
    default:
      switch (taskType) {
        case removeExtraChar(TasksType.MORNINGMEETING):
          return [
            DIARY_TASK_ACTION.Task_Detail,
            !props.isReadOnly &&
              getPermissionValue(PermissionFeatures.DIARY, PermissionActions.DELETE) &&
              DIARY_TASK_ACTION.DELETE,
          ].filter((f) => f)
        case removeExtraChar(TasksType.DAILYUPDATE):
          return [
            DIARY_TASK_ACTION.Task_Detail,
            !props.isReadOnly &&
              getPermissionValue(PermissionFeatures.DIARY, PermissionActions.DELETE) &&
              DIARY_TASK_ACTION.DELETE,
          ].filter((f) => f)
        case removeExtraChar(TasksType.MEETINGWITHPP):
          return [
            DIARY_TASK_ACTION.Task_Detail,
            !props.isReadOnly &&
              getPermissionValue(PermissionFeatures.DIARY, PermissionActions.DELETE) &&
              DIARY_TASK_ACTION.DELETE,
          ].filter((f) => f)
      }
      break
  }
}
const getActionList = (data, props) => {
  let actionList = []
  let leadType = null

  if (data.armsProjectLead) leadType = 'project'
  else if (data.armsLead) leadType = 'buyrent'
  else if (data.wanted) leadType = 'wanted'

  let taskType = data.taskType
  let act = getActions(leadType, removeExtraChar(taskType), props, data)
  return act
}

export const getPropValue = (templateProperty, cellIndex, rowIndex, rowData, props, columnName) => {
  switch (columnName) {
    case ColumnName.TIME_LAPSE:
      if (templateProperty === 'label') {
        if (isOverDue()) {
          return moment(new Date(rowData.start)).format('hh:mm a DD MMM')
        } else {
          let duration = moment.duration(moment(rowData.end).diff(moment(rowData.start)))
          duration =
            duration.asHours() < 1
              ? Number(duration.asMinutes()).toFixed(0) + ' min'
              : Number(duration.asHours()).toFixed(2) + ' hours'
          return moment(new Date(rowData.start)).format('hh:mm a') + ' ' + duration
        }
      }
      break
    case ColumnName.COMMENT:
      if (rowData && rowData.response) {
        return rowData && rowData.response
      } else {
        return '--'
      }

    case ColumnName.TYPE_TILE:
      if (templateProperty === 'color') {
        //color tile
        if (
          rowData &&
          rowData.status &&
          (rowData.status.includes('completed') || rowData.status.includes('cancelled'))
        ) {
          return DIARY_TASK_TILE_COLOR['CLOSED']
        }
        let dependsOn =
          rowData.taskType && rowData.taskType.replace(/[ -_]/g, '').toLocaleUpperCase()
        return DIARY_TASK_TILE_COLOR[dependsOn]
      }
      break
    case ColumnName.TASK_ALERT:
      if (templateProperty === 'icon') {
        return getTaskAlert(rowData)
      }
      break
    case ColumnName.TYPE:
      if (templateProperty === 'label') {
        return TaskTypeLabels[rowData.taskType]
          ? TaskTypeLabels[rowData.taskType]
          : capitalizeWordsWithoutUnderscore(rowData.taskType)
      }
      break
    case ColumnName.REASON:
      if (templateProperty === 'chipColor') {
        //reason chip background color
        if (rowData.reason) {
          return rowData.reason.colorCode
        }
        return ''
      } else if (templateProperty === 'border') {
        //reason chip background color
        if (rowData.reason) {
          return '1px solid ' + rowData.reason.colorCode
        }
        return ''
      }
      break
    case ColumnName.CLASSIFICATION:
      if (templateProperty === 'textColor') {
        //classification label color
        return getClassificationColor(rowData)
      } else if (templateProperty === 'value') {
        return getClassification(rowData)
      } else if (templateProperty === 'disabled') {
        return isClassificationDisable(rowData, props)
      }
      break
    case ColumnName.CLIENT_NAME:
      if (templateProperty === 'label') {
        let lead = rowData.armsProjectLead || rowData.armsLead || rowData.wanted
        if (lead && lead.customer) {
          return (
            (lead.customer.first_name ? lead.customer.first_name : '') +
            ' ' +
            (lead.customer.last_name ? lead.customer.last_name : '')
          )
        } else {
          return ''
        }
      }
    case ColumnName.REQUIREMENT:
      if (templateProperty === 'label') {
        return getRequirement(rowData)
      }
      break
    case ColumnName.LEAD_ID:
      if (templateProperty === 'label') {
        if (rowData.armsProjectLead) return rowData.armsProjectLeadId
        else if (rowData.armsLead) return rowData.armsLeadId
        else if (rowData.wanted) return rowData.wantedId
        else return ''
      }
      break
    case ColumnName.CONNECT_BTN:
      if (templateProperty == 'display') {
        return props.isReadOnly ||
          (rowData &&
            rowData.status &&
            (rowData.status.includes('completed') || rowData.status.includes('cancelled'))) ||
          (rowData.armsLead && !rowData.armsLead.customer) ||
          (rowData.armsProjectLead && !rowData.armsProjectLead.customer) ||
          (rowData.wanted && !rowData.wanted.customer) ||
          (rowData.armsLead === null &&
            rowData.armsProjectLead === null &&
            rowData.wanted === null) ||
          rowData.taskType === TasksType.MEETING ||
          rowData.taskType === TasksType.VIEWING ||
          rowData.taskType === TasksType.REASSIGN
          ? 'none'
          : 'block'
      }
      break
    case ColumnName.ACTION_BTN:
      if (templateProperty == 'display') {
        return (rowData &&
          rowData.status &&
          (rowData.status.includes('completed') || rowData.status.includes('cancelled'))) ||
          (rowData.armsLead === null &&
            rowData.armsProjectLead === null &&
            rowData.wanted === null &&
            rowData.taskType !== TasksType.MORNINGMEETING &&
            rowData.taskType !== TasksType.DAILYUPDATE &&
            rowData.taskType !== TasksType.MEETINGWITHPP) ||
          (props.isReadOnly && props.diaryMode && props.diaryMode === 'Slot')
          ? 'none'
          : 'block'
      } else if (templateProperty === 'actionItems') {
        return getActionList(rowData, props)
      }
      break
    case ColumnName.DETAILS_BTN:
      if (templateProperty === 'display') {
        return rowData.armsLead || rowData.armsProjectLead || rowData.wanted ? 'block' : 'none'
      }
      break
    case ColumnName.MARK_AS_DONE_BTN:
      if (templateProperty === 'display') {
        return (rowData &&
          rowData.status &&
          (rowData.status.includes('completed') || rowData.status.includes('cancelled'))) ||
          (rowData.armsLead === null &&
            rowData.armsProjectLead === null &&
            rowData.wanted === null &&
            rowData.taskType !== TasksType.MORNINGMEETING &&
            rowData.taskType !== TasksType.DAILYUPDATE &&
            rowData.taskType !== TasksType.MEETINGWITHPP) ||
          (rowData && rowData.taskType === TasksType.CONNECT) ||
          (props.isReadOnly && props.diaryMode && props.diaryMode === 'Slot')
          ? 'none'
          : 'block'
      }
      break
  }
}
const FilterKeyMap = {
  date: 'date[]',
  followUpReason: 'reasonTag',
  leadType: 'leadType',
  wantedLeadId: 'wantedId',
  projectLeadId: 'projectId',
  buyRentLeadId: 'buyrentId',
  clientId: 'customerId',
  clientName: 'customerName',
  clientContact: 'customerPhoneNumber',
  followUpFromDate: 'followUpFromDate',
  followUpToDate: 'followUpToDate',
}
export const mapFiltersToQuery = (filters, quickFilters, dataSort) => {
  const mapToParams = (filters, quickFilters) => {
    let queryParams = []
    if (filters) {
      let appliedFilters = Object.keys(filters)
      let filtersQueryArr = appliedFilters.map((key) => {
        if (key === 'followUpFromDate') {
          return FilterKeyMap[key] + '=' + moment(filters[key]).format('YYYY-MM-DD')
        }

        if (key === 'followUpToDate') {
          return FilterKeyMap[key] + '=' + moment(filters[key]).format('YYYY-MM-DD')
        }
        if (typeof filters[key] === 'object') {
          if (filters[key].value && Array.isArray(filters[key].value)) {
            return filters[key].value
              .map((feedback) => {
                return FilterKeyMap[key] + '=' + feedback
              })
              .join('&')
          } else return FilterKeyMap[key] + '=' + filters[key].value
        } else return FilterKeyMap[key] + '=' + filters[key]
      })
      queryParams = [...filtersQueryArr]
    }
    if (quickFilters) {
      let quickFiltersQueryArr = quickFilters.map((quickFilter) => {
        if (
          quickFilter.value.toLocaleUpperCase() === DIARY_QUICK_FILTERS.TODAY.toLocaleUpperCase()
        ) {
          return quickFilter.for + '=' + moment().add(0, 'days').format('YYYY-MM-DD')
        } else if (
          quickFilter.value.toLocaleUpperCase() === DIARY_QUICK_FILTERS.TOMORROW.toLocaleUpperCase()
        ) {
          return quickFilter.for + '=' + moment().add(1, 'days').format('YYYY-MM-DD')
        } else {
          return quickFilter.for + '=' + quickFilter.value
        }
      })
      queryParams = [...queryParams, ...quickFiltersQueryArr]
    }
    if (dataSort && dataSort.value) {
      queryParams = [...queryParams, dataSort.value]
    }
    return queryParams.join('&')
  }
  return mapToParams(filters, quickFilters)

  // if (isOverDue()) {
  //   return mapToParams(props.overDueFilters, props.overDueQuickFilters)
  // } else {
  //   return mapToParams(props.diaryFilters, props.diaryQuickFilter)
  // }
}

// var isReadOnly = false

// const setisReadOnly = (readOnly) => {
//   isReadOnly = readOnly
// }
// const getIsReadOnly = () => {
//   return isReadOnly
// }

export const canMarkCloseAsLost = (lead, type) => {
  if (type === 'Project') {
    if (lead && lead.payment && lead.payment.length > 0) {
      return true
    } else {
      return false
    }
  } else {
    if (lead && lead.commissions && lead.commissions.length > 0) {
      return true
    } else {
      return false
    }
  }
}
