/** @format */

import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import TableCell from './../Table/TableCell'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import _ from 'lodash'

const useStyles = makeStyles({
  row: (props) => ({
    ...props.rowStyles(props.rowIndex, props.data),
    height: props.rowHeight(props.rowIndex, props.data),
    background:
      props.data.id === Number(props.moreFilter.rowData && props.moreFilter.rowData.id)
        ? '#046ef21f'
        : null,
    border: '1px solid #dee2e6',
    fontSize: '12px',
    verticalAlign: 'middle',
    '&:hover': {
      ...props.rowHoverStyles(props.rowIndex, props.data),
      background: props.rowHoverColor(props.rowIndex, props.data),
    },
  }),

  selected: (props) => ({
    backgroundColor: props.selectedRowColor(props.rowIndex, props.data),
  }),
})

const TableRow = (props) => {
  const classes = useStyles(props)
  const [isChecked, setChecked] = useState(false)

  useEffect(() => {
    setChecked(false)
  }, [props.isSelectAll])

  const shouldStickLeft = (index) => {
    if (!props.fixedColumns) return undefined
    return props.fixedColumns.left >= index + 1
  }
  const shouldStickRight = (index, length) => {
    if (!props.fixedColumns) return undefined
    return length - props.fixedColumns.right <= index
  }
  const getCellName = (index) => {
    if (props.cellNames[index]) return props.cellNames[index]
    return index
  }

  return (
    <tr
      className={clsx(
        classes.row,
        typeof props.rowsClassName == 'function'
          ? props.rowsClassName(props.rowIndex, props.data)
          : props.rowsClassName,
        props.rowIndex == props.selectedRowIndex ? classes.selected : '',
        props.rowIndex == props.selectedRowIndex ? 'rowSelected' : '',
        'rowHover'
      )}
      onClick={(event) => {
        props.onRowClick(props.rowIndex, props.data, event)
      }}
    >
      {props.isMultiSelect && (
        <td className="checkboxRow tableMultiSelectCBContainer">
          <input
            className={'checkboxInputRow tableMultiSelectCB'}
            type="checkbox"
            disabled={
              props.data.status === 'closed_won'
                ? true
                : false
            }
            checked={
              props.data.status === 'closed_won'
                ? false
                : props.isSelectAll || isChecked
            }
            onChange={(e) => {
              setChecked(e.target.checked)
              props.onMultiSelect &&
                props.onMultiSelect(e.target.checked, props.rowIndex, props.data)
            }}
          ></input>
        </td>
      )}
      {props.rowTemplate.map((template, index) => {
        return (
          <TableCell
            key={'cell' + props.rowIndex + '' + index}
            template={template}
            data={props.data}
            rowIndex={props.rowIndex}
            cellIndex={index}
            cellName={getCellName(index)}
            onCellClick={props.onCellClick}
            cellsClassName={props.cellsClassName}
            stickLeft={shouldStickLeft(index)}
            stickRight={shouldStickRight(index, props.rowTemplate.length)}
          />
        )
      })}
    </tr>
  )
}

export const mapStateToProps = (state) => {
  return {
    ...state,
    moreFilter: state.GeneralSetting.moreFilter,
  }
}
export default connect(mapStateToProps, null)(TableRow)
// export default React.memo(TableRow, (prevProps, nextProps) => {
// return _.isEqual(prevProps.data, nextProps.data)
// &&
// _.isEqual(prevProps.rowTemplate, nextProps.rowTemplate)
// })

TableRow.propTypes = {
  rowHeight: PropTypes.func,
  rowHoverColor: PropTypes.func,
  selectedRowColor: PropTypes.func,
  onRowClick: PropTypes.func,
  onCellClick: PropTypes.func,
  data: PropTypes.any,
  rowIndex: PropTypes.number,
  rowTemplate: PropTypes.array,
  rowsClassName: PropTypes.any,
  cellsClassName: PropTypes.string,
  rowStyles: PropTypes.func,
  isMultiSelect: PropTypes.bool,
  isSelectAll: PropTypes.bool,
  onMultiSelect: PropTypes.func,
}

TableRow.defaultProps = {
  rowHeight: () => {},
  rowHoverColor: () => {},
  selectedRowColor: () => {},
  onRowClick: () => {},
  onCellClick: () => {},
  rowsClassName: '',
  cellsClassName: '',
  rowStyles: () => {},
  rowHoverStyles: () => {},
  isMultiSelect: false,
  isSelectAll: false,
  onMultiSelect: () => {},
}
