/** @format */

import React, { useEffect, useState } from 'react'
import { Modal, ModalBody } from 'reactstrap'
import { capitalize_Words } from '../../../utils/commonFormat'
import { getPermissionValue } from '../../_unlockComponent'
import { PermissionActions, PermissionFeatures } from '../../../utils/permissionConstants'

const ClientNewLead = (props) => {
  let buyRentLeadCreate = getPermissionValue(PermissionFeatures.BUY_RENT_LEADS, PermissionActions.CREATE)
  let projectLeadCreate = getPermissionValue(PermissionFeatures.PROJECT_LEADS, PermissionActions.CREATE)
  return (
    <>
      <Modal
        isOpen={props.isOpen}
        toggle={(toggle) => props.onToggle(!toggle)}
        className=""
        size="md"
      >
        <ModalBody>
          <div className="popup-body-header-title">
            <div className={'row'}>
              <div className={'col-md-12'}>
                <h5>
                  Create new lead for
                  {
                  props.lead && props.lead.customer && props.lead.customer.customerName
                    ? ` ${capitalize_Words(props.lead.customer.customerName)}`
                    : ' Client'
                  }
                </h5>
              </div>
            </div>
          </div>
          <div className="close-icon-container" style={{ height: '10px' }}>
            <i className="fal fa-times-circle close-icon" onClick={() => props.onToggle(false)}></i>
          </div>
          <div className="container">
            <div className="row">
              {/*<div className="col-md-12 col-lg-12 pt-3 pb-3 d-flex">
                <p>

                </p>
              </div>*/}
              <div className="col-md-12 col-lg-12 pt-3 p-0 d-flex mt-2">
                <div className="btn-group">
                  {projectLeadCreate && (
                    <button
                    className="btn-sm btn-primary px-3 rounded-pill mr-4 border border-primary"
                    onClick={
                      () => props.history.push({
                        pathname: '/new-lead',
                        state: { customer: props.lead ? props.lead.customer : null }
                      })
                    }
                  >
                    Project Lead
                  </button>
                  )}
                  {buyRentLeadCreate && (
                    <button
                    className="btn-sm btn-primary px-3 rounded-pill border border-secondary"
                    onClick={
                      () => props.history.push({
                        pathname: '/add-buy-rent-lead',
                        state: { customer: props.lead.customer }
                      })
                    }
                  >
                    Buy/Rent Lead
                  </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}


export default ClientNewLead
