/** @format */

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import React from 'react'
import { capitalize_Words } from '../../../utils/commonFormat'

const CancelConfirmModal = (props) => {
  return (
    <Modal
      isOpen={props.cancelConfirmModal}
      toggle={() => props.setCancelConfirmModal(false)}
      className="general-content"
    >
      <ModalHeader>Cancel {capitalize_Words(props.diary.taskType).replace('_', ' ')} </ModalHeader>
      <ModalBody>
        <p>Are you sure you want to continue ? </p>
      </ModalBody>
      <ModalFooter>
        <Button
          color="secondary"
          onClick={() => {
            props.setCancelConfirmModal(false)
            props.setSelectedDiary(null)
          }}
        >
          No
        </Button>
        <Button color="danger" onClick={() => {
          props.setCancelConfirmModal(false)
          props.setDiaryAction(props.diary.taskType === 'meeting' ? 'cancel-meeting': 'cancel-task')
        }}>
          Yes
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default CancelConfirmModal
