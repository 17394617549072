/** @format */

import React, { useState } from 'react'
import Swal from 'sweetalert2'
import { Modal, ModalBody } from 'reactstrap'
import Button from '../../common/Button'
import axios from 'axios'
import config from '../../../config'
import { FEEDBACK_ACTIONS } from '../../../utils/constant_data'
import { showToastMsg } from '../../../utils/helper'
import { canMarkCloseAsLost } from '../../common/Diary/DiaryUtilHelper'

const RWR = (props) => {
  const [reason, setReason] = useState(props.connectFeedback.comment)
  const [loader, setLoader] = useState(false)
  const { leadType, lead, singleLeadRecord } = props
  const [isBackList, setIsBlackList] = useState(
    lead && lead.customer && lead.customer.blacklisted ? true : false
  )

  const closedLost = () => {
    if (canMarkCloseAsLost(singleLeadRecord && singleLeadRecord, leadType)) {
      Swal.fire(
        'Error',
        'This lead cannot be Closed as Lost as it has some payments. Delete all payments before closing this lead.',
        'error'
      ).then(() => {})
    } else {
      setLoader(true)
      let url = `${config.apiPath}/api/leads/project`
      if (leadType === 'BuyRent') url = `${config.apiPath}/api/leads`
      else if (leadType === 'Wanted') url = `${config.apiPath}/api/wanted`

      axios({
        method: 'patch',
        url: url,
        params: {
          id: leadType === 'Wanted' ? lead && lead.id : lead && [lead.id],
          blacklistCustomer: isBackList,
        },
        data: {
          [leadType === 'Wanted' ? 'reason' : 'reasons']: reason,
          [['BuyRent', 'Project'].indexOf(leadType) > -1 ? 'customerId' : 'customer_id']:
            lead.customer && lead.customer.id,
        },
      }).then((res) => {
        setLoader(false)
        props.setRwrModal(false)
        props.callback()
        if (
          leadType !== 'Wanted' ||
          props.connectFeedback.tag !== FEEDBACK_ACTIONS.CLIENT_IS_INTERESTED_IN_INVESTMENT
        )
          showToastMsg('Lead has been Closed Lost', 'success')
      })
    }
  }

  return (
    <>
      <Modal
        isOpen={props.rwrModal}
        toggle={(toggle) => props.setRwrModal(!toggle)}
        backdrop={'static'}
      >
        <ModalBody>
          <div className={'d-flex align-items-center'} style={{ columnGap: 20 }}>
            <h4 className={'mb-0'}>Reject Lead</h4>
          </div>

          <div className={'row mt-3 justify-content-center mb-4'}>
            <div className="col-12">
              <textarea
                className="task-description form-control"
                rows={6}
                value={reason}
                defaultValue={props.connectFeedback.comment}
                onChange={(e) => setReason(e.target.value)}
                placeholder="Description"
              />
            </div>
          </div>

          <div>
            <input
              type={'checkbox'}
              defaultChecked={lead && lead.customer && lead.customer.blacklisted}
              name={'blacklist'}
              value={isBackList}
              onChange={(e) => setIsBlackList(!isBackList)}
            />{' '}
            &nbsp; Blacklist
          </div>

          <div className="btn-sm d-flex justify-content-center mt-3 mb-4">
            <Button
              text={loader ? <span className="fa fa-circle-o-notch fa-spin"></span> : 'Close Lead'}
              buttonColor="#026ff2"
              textColor={'white'}
              style={{ width: 115 }}
              className={'px-4 rounded-pill py-2'}
              onClick={() => {
                if (!loader && reason) closedLost()
              }}
            />
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}
export default RWR
