import * as ActionTypes from "../actions/area";

const initialState = {};

const Areas = (state=initialState,action)=>{
  switch(action.type){
    case ActionTypes.FETCH_AREAS_BEGIN:
      return state;
    case ActionTypes.FETCH_AREAS_SUCCESS:
      return{
        ...state,
        allAreas:action.payload
      }
    case ActionTypes.FETCH_AREA_GROUPS:
      return{
        ...state,
        areaGroups:action.payload
      }
    case ActionTypes.FETCH_AREAS_FAILED:
      return{
        ...state,
        allAreas:action.payload
      }
    case ActionTypes.FETCH_AIRA_AREAS_SUCCESS:
        return{
          ...state,
          airaAreas:action.payload
        }
    case ActionTypes.FETCH_AIRA_AREAS_FAILED:
        return{
          ...state,
          airaAreas:action.payload
        }
    case ActionTypes.SEARCH_QUERY_BEGINS:
      return state;
    case ActionTypes.SEARCH_QUERY_SUCCESS:
      return{
        ...state,
        allAreas:action.payload
      }
    case ActionTypes.SEARCH_QUERY_FAILED:
      return{
        ...state,
        areaError:action.payload
      }
      case ActionTypes.FETCH_AREAS_LOADER:
        return {
          ...state,
          areaLoader:action.payload
        }
        case ActionTypes.FETCH_AREAS_LOADER_SUCCESS:
          return {
            ...state,
            areaLoader:action.payload
          }
        case ActionTypes.DELETE_AREA_BEGIN:
          return state
        case ActionTypes.DELETE_AREA_SUCCESS:
          return{
            ...state,
            deletedArea:action.payload
          }
        case ActionTypes.DELETE_AREA_FAILED:
          return{
            ...state,
            deleteError:action.payload
          }
          case ActionTypes.SELECT_AGENT_VALUE:
            return{
              ...state,
              agentValue:action.payload
            }
    default:
      return state
  }
}

export default Areas;
