/** @format */

import React, { useState, useEffect } from 'react'
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap'
import Button from '../../common/Button'
import { getRequirement } from '../../common/Diary/DiaryUtilHelper'

const ReAssignWarningModal = ({
  modalConfirmation,
  toggleModalConfirmation,
  assignLeadFun,
  warningMessages,
  user_data,
  pushLeadIds,
  filteredSelectedLeads,
}) => {
  return (
    <Modal
      isOpen={modalConfirmation}
      toggle={toggleModalConfirmation}
      dialogClassName={`modal-main-legal`}
      style={{ maxWidth: '800px' }}
    >
      <ModalHeader>Confirm Re-assignment</ModalHeader>
      <ModalBody
        style={{
          overflowY: 'scroll',
          height: 'calc(100% - 20px)',
          maxHeight: '500px',
        }}
      >
        {warningMessages
          ? Object.keys(warningMessages).map((key, index) => (
              <>
                <p
                  key={index}
                  style={{ fontSize: '16px', fontWeight: '600', marginBottom: '10px' }}
                >
                  {' '}
                  Client Name: {warningMessages[key].customer}
                </p>{' '}
                <p style={{ fontSize: '16px', marginBottom: '15px' }} key={index}>
                  {warningMessages[key].customer}'s last lead was assigned to Advisor{' '}
                  <strong>{warningMessages[key].lastAgentName}</strong>, while you are trying to
                  assign leads to Advisor <strong>{warningMessages[key].newAgentName}</strong>.
                  Please confirm assignment:
                </p>
                {warningMessages[key].lead &&
                  warningMessages[key].lead.map((item, indx) => (
                    <>
                      <p style={{ fontSize: '16px', marginBottom: '10px' }} key={indx}>
                        <input
                          type="checkbox"
                          onChange={() => {
                            pushLeadIds(item.id)
                          }}
                        />{' '}
                        <span style={{ marginLeft: '5px', marginTop: '-5px' }}>
                          {item.id}
                          {' - '}
                          {getRequirement({
                            [(item && item.purpose === 'sale') ||
                            (item && item.purpose === 'rent') ||
                            (item && item.purpose === 'buy')
                              ? 'armsLead'
                              : 'armsProjectLead']: item,
                          })}{' '}
                        </span>
                      </p>
                    </>
                  ))}
                <hr />
              </>
            ))
          : null}
      </ModalBody>
      <ModalFooter>
        <Button
          text={'Cancel'}
          textColor={'white'}
          display={'inline-block'}
          buttonColor="#026ff2"
          className={'px-4 mr-2 bg-secondary'}
          onClick={() => {
            toggleModalConfirmation(false)
          }}
        />
        <Button
          disabled={filteredSelectedLeads && filteredSelectedLeads.length ? false : true}
          text={'Continue'}
          textColor={'white'}
          display={'inline-block'}
          // buttonColor="#026ff2"
          className={'px-4 mr-2 bg-success text-white'}
          onClick={() => {
            assignLeadFun(user_data.id, user_data.firstName, user_data.lastName, true)
          }}
        />
      </ModalFooter>
    </Modal>
  )
}

export default ReAssignWarningModal
