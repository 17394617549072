/** @format */

import React, { useEffect, useState, useRef } from 'react'
import useOutsideListner from './../../../hooks/OutsideListner'
import moment from 'moment'
import { Calendar } from 'react-rainbow-components'
import { DIARY_QUICK_FILTERS } from '../../../utils/constant_data'

const DiaryDateQuickFilter = (props) => {
  let itemStyle = {
    border: '1px solid #ebebeb',
    borderRadius: 4,
    margin: 2,
    padding: '7px 12px',
    backgroundColor: 'white',
    cursor: 'pointer',
    textAlign: 'center',
    minWidth: 92,
    ...props.style,
  }
  const selectedItemStyle = {
    color: '#007bfe',
  }

  const [isCalenderVisible, setCalenderVisibility] = useState(false)
  const [selectedDate, setSelectedDate] = useState(moment().format('YYYY-MM-DD'))
  const calenderRef = useRef(null)

  useEffect(() => {
    let dateFilters =
      props.selectedFilters && props.selectedFilters.filter((f) => f.label === 'Date')
    if (dateFilters && dateFilters.length > 0) {
      if (dateFilters[0].value === 'Today') {
        setSelectedDate(moment().format('YYYY-MM-DD'))
      } else if (dateFilters[0].value === 'Tomorrow') {
        setSelectedDate(moment().add(1, 'days').format('YYYY-MM-DD'))
      } else {
        setSelectedDate(moment(dateFilters[0].value).format('YYYY-MM-DD'))
      }
    }
  }, [props.selectedFilters])

  useOutsideListner(calenderRef, (event) => {
    event.stopPropagation()
    setCalenderVisibility(false)
  })

  const getCalender = () => {
    return (
      isCalenderVisible && (
        <div ref={calenderRef} className="calenderContainer">
          <Calendar
            id="calendar-1"
            // locale={state.locale.name}
            value={selectedDate}
            onChange={(value) => {
              setSelectedDate(moment(value).format('YYYY-MM-DD'))
              setOrRemoveFilter(moment(value).format('YYYY-MM-DD'))
              // event.stopPropagation()
              // event.preventDefault()
              setCalenderVisibility(false)
            }}
          />
        </div>
      )
    )
  }
  const setOrRemoveFilter = (date = null) => {
    let dateFilters =
      props.selectedFilters && props.selectedFilters.filter((f) => f.label === 'Date')
    let otherFilters =
      props.selectedFilters && props.selectedFilters.filter((f) => f.label != 'Date')

    let isApplied = dateFilters.filter((f) => f.value === date).length > 0 ? true : false
    !isApplied &&
      props.setSelectedQuickFilter([...otherFilters, { label: 'Date', value: date, for: 'date[]' }])
  }
  return (
    <>
      <div
        className={`quick-filter-item`}
        onClick={() => {
          setOrRemoveFilter(DIARY_QUICK_FILTERS.TODAY)
          setSelectedDate(moment().add(0, 'days'))
        }}
        style={{
          ...itemStyle,
          color:
            props.selectedFilters.filter((f) => f.value === 'Today').length > 0 ? '#007bfe' : '',
        }}
      >
        {'Today'}
      </div>
      {/* <div
        className={`quick-filter-item`}
        onClick={() => {
          setOrRemoveFilter(DIARY_QUICK_FILTERS.TOMORROW)
          setSelectedDate(moment().add(1, 'days'))
        }}
        style={{
          ...itemStyle,
          color:
            props.selectedFilters.filter((f) => f.value === 'Tomorrow').length > 0 ? '#007bfe' : '',
        }}
      >
        {'Tomorrow'}
      </div> */}
      <div
        className={`quick-filter-item`}
        onClick={() => {
          !isCalenderVisible && setCalenderVisibility(true)
        }}
        style={{
          ...itemStyle,
          color:
            props.selectedFilters.filter(
              (f) =>
                f.label === 'Date' &&
                f.value != 'Tomorrow' &&
                f.label === 'Date' &&
                f.value != 'Today'
            ).length > 0
              ? '#007bfe'
              : '',
          position: 'relative',
        }}
      >
        {moment(selectedDate).format('DD-MMM-YYYY')}
        {getCalender()}
      </div>
    </>
  )
}

export default DiaryDateQuickFilter
