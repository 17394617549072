/** @format */

import React, { Component } from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import { buyAndRentLeads, fetchUsers, fetchFreshBuyRentLeads } from '../../../../../actions/leads'
import { fetchUsersForDeletion } from '../../../../../actions/user'
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from 'reactstrap'
import ScaFileUploader from '../../../../../components/common/ScaWorkflow/ScaFileUploader'
import Select from 'react-select'
import _ from 'lodash'
import Avatar from '../../../../../images/dummy-avatar.png'
import 'react-dates/initialize'
import CallIcon from '../../../../../images/callIcon.svg'
import CallModal from '../../../../../components/InvestmentCallModal'
import 'react-dates/lib/css/_datepicker.css'
import Pagination from 'rc-pagination'
import 'rc-pagination/assets/index.css'
import local from '../../../../../local/en_US'
import './brStyle.scss'
import Load from '../../../../../components/Loader'
import axios from 'axios'
import config from '../../../../../config'
import Swal from 'sweetalert2'
import ActionDropList from '../../../../../components/LeadActionDropList'
import YellowLead from '../../../../../images/yellow-marker.svg'
import RedLead from '../../../../../images/red-marker.svg'
import { Link, withRouter } from 'react-router-dom'
import { scrollToBottom, showToastMsg } from '../../../../../utils/helper'
import InvestmentLeadInner from '../../ProjectLead/Listing/investmentLeadInner'
import ConnectModal from '../../../../../components/Modals/ConnectFeedback/ConnectModal'
import DiaryModal from '../../../../../components/Modals/DiaryModal'
import AdditionalDetails from '../../../../../components/Modals/AdditionalDetailsModal/AdditionalDetails'
import DiaryTaskModal from '../../../../../components/Modals/DiaryTaskModal/DiaryTaskModal'
import ReferModal from '../../../../../components/Modals/ReferModal'
import {
  BUY_RENT_LEAD_TYPE_SALE,
  BuyRentLeadUrl,
  managerSelectionDropdown,
} from '../../../../../utils/constant_data'
import { isAira } from '../../../../../components/common/LeadView/Wanted/WantedUtilHelper'
import { isREA } from '../../../../../utils/helper'
import {
  investmentMeeting,
  fetchLeadTimeLine,
  fetchLeads,
  investmentShareLead,
  fetchLeadAttachments,
  fetchAllComments,
  addComment,
  deleteLeadAttachment,
} from '../../../../../actions/leads'
import { RenderLeadStatus } from '../../../../../utils/general'
import { StaticData } from '../../../../../StaticData'
import ClientNewLead from '../../../../../components/Modals/ClientNewLeadModal'
import RequirementReadViewModal from '../../../../../components/Modals/ConnectFeedback/ConnectInfo/RequirementReadViewModal'
import { fetchLegalDocuments } from '../../../../../actions/property'
import { isReadOnly, leadStatusClass } from '../../../../../utils/lead'
import { accountsPermissions } from '../../../../../utils/checkPermissions'
import ConnectFlow from '../../../../../components/Modals/ConnectFeedback/ConnectFlow'
import CloseAsDuplicateModal from '../../../../../components/Modals/CloseDuplicate'
import CloseAsLostModal from '../../../../../components/Modals/CloseLost'
import LeadClickComponent from "../../../../../callCenter/LeadClickComponent";

class BuyAndRentLeadInner extends Component {
  constructor(props) {
    super(props)
    this.state = {
      edit: false,
      legalDocs: {},
      selectedLeads: [],
      allSelected: false,
      additionalInfoToggleState: false,
      createNewLeadToggleState: false,
      startDate: null,
      endDate: null,
      focusedInput: null,
      filterActiveButton: null,
      filterClearButton: false,
      activeProjectFilterButton: null,
      filterId: {},
      statusValue: null,
      projectValue: {},
      usersAll: [],
      assignedValue: {},
      activeAssignedActiveButton: null,
      tooltipOpen: false,
      activePage: 1,
      deleteOpen: false,
      showUserDropdown: false,
      byNameInputValue: '',
      userItems: [],
      items: [],
      url: `/api/user/leadAgents?userRole=all&pageSize=${config.pageSize}&rcm=true`,
      clearButton: false,
      leadId: 0,
      searchButton: true,
      deletionReason: '',
      reOpenModal: false,
      itemId: '',
      leadModal: false,
      userId: '',
      showDropShadow: false,
      selectedItemLeads: [],
      allSelectedElements: [],
      filterSelected: false,
      filterAllUsers: [],
      userData: {},
      userRole: '',
      success: false,
      type: 'buy-rent',
      dropdownOptions: [
        { name: 'All', value: 'all' },
        { name: 'Sales Level 2', value: 'regional_head' },
        { name: 'Business Centre Manager', value: 'business_centre_manager' },
        { name: 'Call Centre Manager', value: 'call_centre_manager' },
        { name: 'Agency21: Real Estate Manager', value: 'branch_manager' },
        { name: 'Graana: Real Estate Manager', value: 'zonal_manager' },
        { name: 'Business Centre Advisor', value: 'business_centre_agent' },
        { name: 'Telesales Warrior', value: 'call_centre_warrior' },
        { name: 'Agency21 : Real Estate Advisor', value: 'sales_agent' },
        { name: 'Graana : Real Estate Advisor', value: 'area_manager' },
      ],
      filterOrgValue: '',
      filterRoleDropdown: '',
      userListForSearchFilters: [],
      clearButtonToggle: false,
      showButton: false,
      transferUserID: '',
      transferToggleState: false,
      showMoreActions: false,
      moreActionId: false,
      userInfo: {},
      getServerTime: '',
      commentShow: false,
      commentLoader: false,
      leadComments: [],
      selectedLead: null,
      loginUserId: localStorage.getItem('user_id'),
      taskModal: false,
      leadTaskModal: false,
      fetchLeadsInModal: false,
      diaryModal: false,
      setItem: {},
      shareToggleState: false,
      shareloader: false,
      agents: [],
      shareLeadType: '',
      singleLeadIdState: null,
      agentCount: 0,
      scaDocumentToggleState: false,
      scaDocument: null,
      legalCount: null,
      legalServicesFee: null,
      callLogs: [],
      customerPhoneLoader: true,
      callingFrom: '',
      callToggleState: false,
      customersPhoneNumbers: null,
      currentPage: 1,
      connectFlow: false,
      newTask: null,
      updateLeadTasks: false,
      closeAsDuplicateModal: false,
      closeAsLostModal: false,
    }
  }
  componentWillReceiveProps(nextprops) {
    const { showMoreActions } = this.state

    const { tab, leads, tabUrl } = nextprops

    if (tab === 'buyrent' && tabUrl === 'existinglead') {
      if (leads && leads.rows && leads.rows.length === 1) {
        // this.setState({ taskModal: true, selectedLead: leads && leads.rows && leads.rows[0] })
      }
    }

    if (nextprops.showMoreActions && showMoreActions) {
      this.setState({
        showMoreActions: false,
      })
    }
    this.getServerTime()
  }

  componentDidMount() {
    this.fetchLegalPaymentInfo()
    this.getUserDetail()
    this.fetchUsers()
    this.fetchAllUserForFilter()
    this.fetchUserOrgID()
    const urlParams = new URLSearchParams(window.location.search)
    const page = urlParams.get('page')
    this.setState({ currentPage: page })
  }

  commmentToggle = () => {
    this.setState({
      commentShow: !this.state.commentShow,
    })
  }

  getServerTime = () => {
    axios.get(`${config.apiPath}/api/user/serverTime?fullTime=true`).then((res) => {
      this.setState({
        getServerTime: res.data,
      })
    })
  }

  callToggle = (customer, callingFrom) => {
    this.setState({
      customerPhoneLoader: true,
      callingFrom,
    })
    axios
      .get(`${config.apiPath}/api/customer/getNumbers?customerId=${customer.id}`)
      .then((res) => {
        this.setState({
          customersPhoneNumbers: res.data,
          customerPhoneLoader: false,
        })
      })
      .catch((err) => {
        this.setState({
          customerPhoneLoader: false,
        })
      })
    this.setState({ callToggleState: !this.state.callToggleState })
  }

  viewLeadDetails = (item) => {
    this.setState({
      selectedLead: item,
    })
    this.setState({ taskModal: true })
  }

  fetchCallLogs = (leadDetails) => {
    this.setState({ callLogs: [] })
    axios
      .get(
        `${config.apiPath}/api/leads/tasks?web=true&cmLeadId=${leadDetails.id}&taskType[]=called`
      )
      .then((res) => {
        this.setState({
          callLogs: res.data,
        })
      })
  }

  fetchUserOrgID = () => {
    axios.get(`${config.apiPath}/api/user/me`).then((res) => {
      this.setState({
        userInfo: res.data,
      })
    })
  }

  fetchAllComments = (id) => {
    this.setState({ commentLoader: true })
    axios
      .get(`${config.apiPath}/api/leads/comments?rcmLeadId=${id}&type=comment&web=true`)
      .then((res) => {
        this.setState({ commentLoader: false, leadComments: res.data })
        scrollToBottom('br-comment-scroll-container')
      })
  }

  deleteToggle = (id) => {
    this.setState({
      deleteOpen: !this.state.deleteOpen,
      leadId: id,
    })
  }

  getScaDocument = (id) => {
    axios
      .get(`${config.apiPath}/api/legal/scadocuments?leadId=${id}&addedBy=seller`)
      .then((res) => {
        this.setState({ scaDocument: res.data })
      })
  }

  deletionToggle = () => {
    this.setState({
      success: !this.state.success,
    })
  }

  fetchDeletionUsersFilter = (pageNumber = 1) => {
    const role = localStorage.getItem('role')
    const { fetchUsersForDeletion, organizationsDetail } = this.props
    const { filterRoleDropdown, filterOrgValue, userInfo } = this.state

    this.setState({ pageNumber })
    const url = `/api/user/leadAgents?userRole=${
      filterRoleDropdown ? filterRoleDropdown.value : 'all'
    }&pageSize=${config.pageSize}&orgId=${
      filterOrgValue
        ? filterOrgValue.value
        : role === 'super_admin'
        ? organizationsDetail && organizationsDetail[0] && organizationsDetail[0].id
        : userInfo.organizationId
    }`
    fetchUsersForDeletion(pageNumber, url)
  }

  fetchAllUserForSearchFilter = () => {
    const role = localStorage.getItem('role')
    const { filterRoleDropdown, filterOrgValue, userInfo } = this.state
    const { organizationsDetail } = this.props
    axios
      .get(
        `${config.apiPath}/api/user/leadAgents?userRole=${
          filterRoleDropdown ? filterRoleDropdown.value : 'all'
        }&orgId=${
          filterOrgValue
            ? filterOrgValue.value
            : role === 'super_admin'
            ? organizationsDetail && organizationsDetail[0] && organizationsDetail[0].id
            : userInfo.organizationId
        }&all=true`
      )
      .then((res) => {
        this.setState({
          userListForSearchFilters: res.data.rows,
        })
      })
  }

  getUserDetail = () => {
    axios.get(`${config.apiPath}/api/user/me`).then((res) =>
      this.setState({
        userData: res.data,
      })
    )
  }

  textItemRender = (current, type, element) => {
    if (type === 'prev') {
      return 'Prev'
    }
    if (type === 'next') {
      return 'Next'
    }
    return element
  }

  fetchUsers = (pageNumber = 1) => {
    const { fetchUsers } = this.props
    this.setState({ pageNumber })

    fetchUsers(pageNumber, this.state.url)
  }

  toggle = (id) => {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen,
      leadId: id,
    })
  }

  reOpenToggle = (id) => {
    this.setState({
      reOpenModal: !this.state.reOpenModal,
      itemId: id,
    })
  }

  leadAssignToggle = (id) => {
    this.setState({
      leadModal: !this.state.leadModal,
      userId: id,
    })
  }

  selectLead = (id, item) => {
    let { selectedLeads, selectedItemLeads } = this.state
    const { leads } = this.props
    let leadIds = Object.assign([], selectedLeads)
    let leadItems = Object.assign([], selectedItemLeads)
    if (leadIds.indexOf(id) === -1) {
      leadIds.push(id)
      leadItems.push(item)
    } else {
      leadIds.splice(selectedLeads.indexOf(id), 1)
      leadItems.splice(selectedItemLeads.indexOf(item), 1)
    }
    this.setState({
      selectedLeads: leadIds,
      selectedItemLeads: leadItems,
      showDropShadow: true,
      filterSelected: leadIds.length < (leads && leads.rows.length) ? false : true,
    })
  }

  fetchAllUserForFilter = (value) => {
    axios
      .get(
        `${config.apiPath}/api/user/leadAgents?userRole=${value ? value : 'all'}&all=true&rcm=true`
      )
      .then((res) =>
        this.setState({
          filterAllUsers: res.data,
        })
      )
  }

  handleQuerySearch = (value, pageNumber = 1) => {
    let url = `/api/user/leadAgents?userRole=${value ? value : 'all'}&pageSize=${config.pageSize}&rcm=true`
    this.fetchAllUserForFilter(value)
    this.setState({ url, pageNumber })
    this.props.fetchUsers(pageNumber, url)
  }

  selectAll = () => {
    const { leads } = this.props
    const { allSelected } = this.state
    if (leads && leads.rows && leads.rows.length) {
      let leadIds = leads.rows.map((item) => {
        return item.id
      })
      this.setState({
        selectedLeads: allSelected ? [] : leadIds,
        allSelected: !allSelected,
        allSelectedElements: leads,
        filterSelected: !allSelected,
      })
    }
  }

  searchBy = (arr = [], searchKeys = [], value = ' ') => {
    return (
      arr &&
      arr.filter((item) =>
        searchKeys.length
          ? searchKeys.some((key) =>
              `${item.firstName + ' ' + item.lastName}`.toLowerCase().includes(value.toLowerCase())
            )
          : true
      )
    )
  }

  fetchLegalPaymentInfo = () => {
    return axios.get(`${config.apiPath}/api/leads/legalPayment`).then((res) => {
      this.setState({
        legalServicesFee: res.data,
      })
    })
  }

  handleDeleteByNameChange = (e) => {
    let value = e.target.value
    const { userListForSearchFilters } = this.state

    this.setState({
      byNameInputValue: value,
      items: this.searchBy(userListForSearchFilters, ['firstName'], value),
    })
  }

  searchFilter = (page, value) => {
    const role = localStorage.getItem('role')
    const pageNumber = page > 1 ? 1 : page
    const { organizationsDetail } = this.props
    const { filterRoleDropdown, filterOrgValue, userInfo } = this.state
    let newUrl = `/api/user/leadAgents?userRole=${
      filterRoleDropdown ? filterRoleDropdown.value : ''
    }&pageSize=${config.pageSize}&name=${value}&orgId=${
      filterOrgValue
        ? filterOrgValue.value
        : role === 'super_admin'
        ? organizationsDetail && organizationsDetail[0] && organizationsDetail[0].id
        : userInfo.organizationId
    }&rcm=true`
    this.setState({ pageNumber })

    this.props.fetchUsersForDeletion(pageNumber, newUrl)
  }

  hasBooking = () => {
    if (this.props.popup) return null
    return this.props.location.pathname === BuyRentLeadUrl ? false : true
  }

  deleteProjectLead = () => {
    const { selectedLeads, leadId, deletionReason } = this.state
    const { filterResponse } = this.props
    axios
      .delete(`${config.apiPath}/api/leads/remove?rcm=true&reason=${deletionReason}`, {
        params: {
          id: selectedLeads.length > 0 ? selectedLeads : [leadId],
        },
      })
      .then((res) => {
        if ((selectedLeads.length > 0 && filterResponse) || (leadId && filterResponse)) {
          this.props.fetchFiltersData()
        } else {
          this.props.fetchBRLeads(undefined, undefined, undefined, this.hasBooking())
          this.props.fetchFreshBuyRentLeads(
            `${config.apiPath}/api/v1/leads?web=true&pageSize=${config.pageSize}&page=${1}`
          )
        }
        Swal.fire({
          type: 'success',
          title: 'Success',
          icon: 'success',
          text: res && res.data && res.data,
        })
      })
      .catch((error) => {
        Swal.fire({
          type: 'error',
          icon: 'error',
          title: 'Error',
          text: error && error.response && error.response.data && error.response.data.message,
        })
        this.setState({
          deleteOpen: false,
        })
      })
  }

  handleByNameChange = (e) => {
    let value = e.target.value
    const { filterAllUsers } = this.state

    this.setState({
      byNameInputValue: value,
      items: this.searchBy(filterAllUsers, ['firstName'], value),
    })
  }

  leadAssign = () => {
    const { selectedLeads, leadId, userId } = this.state
    const { filterResponse } = this.props
    let data = {
      userId,
      leadId: selectedLeads.length > 0 ? selectedLeads : [leadId],
    }

    axios.patch(`${config.apiPath}/api/leads/assign`, data).then((res) => {
      Swal.fire({
        type: 'success',
        icon: 'success',
        title: 'Success',
        text: res && res.data && res.data.message,
      })
      this.setState({
        tooltipOpen: false,
        leadAssignToggle: false,
        leadModal: false,
      })
      if ((selectedLeads.length > 0 && filterResponse) || (leadId && filterResponse)) {
        this.props.fetchFiltersData()
      } else {
        this.props.fetchBRLeads(undefined, undefined, undefined, this.hasBooking())
      }
    })
  }

  clearFilter = () => {
    this.setState({
      clearButton: false,
      byNameInputValue: '',
    })
    this.fetchUsers()
  }

  _handleKeyDown = (e) => {
    let value = e.target.value

    this.setState({
      byNameInputValue: value,
      showButton: true,
      showUserDropdown: false,
    })
    if (e.key === 'Enter') {
      this.fetchFiltersData(value)
    }
  }

  capitalize_Words = (str) => {
    return (
      str &&
      str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
      })
    )
  }

  reOpen = () => {
    const { itemId, selectedLeads } = this.state
    const { filterResponse } = this.props
    axios
      .patch(
        `${config.apiPath}/api/leads`,
        { status: 'open' },
        {
          params: {
            id: selectedLeads.length ? selectedLeads : [itemId],
          },
        }
      )
      .then((res) => {
        if ((selectedLeads.length > 0 && filterResponse) || (itemId && filterResponse)) {
          this.props.fetchFiltersData()
        } else {
          this.props.fetchBRLeads(undefined, undefined, undefined, this.hasBooking())
        }
        Swal.fire({
          type: 'success',
          title: 'Success',
          icon: 'success',
          text: 'Lead re-opened Successfully',
        })
      })
  }

  getServerTime = () => {
    axios.get(`${config.apiPath}/api/user/serverTime?fullTime=true`).then((res) => {
      this.setState({
        getServerTime: res.data,
      })
    })
  }

  timeStatusColors = (lead) => {
    const { getServerTime } = this.state
    var statusColor = ''
    var curDate = moment(getServerTime).format('DD')
    var leadDate = moment(lead.assigned_at).format('DD')
    var time = moment.duration(moment(getServerTime).diff(moment(lead.assigned_at))).asMinutes()
    time = time.toFixed(0)
    if (lead.shared_with_armsuser_id != null) {
      statusColor = 'shared'
    } else {
      if (curDate === leadDate && lead.status === 'open') {
        if (time < 30) {
          if (lead.readAt != null) {
            statusColor = 'leadOpen'
          } else {
            statusColor = 'new'
          }
        }
        if (time > 30 && time < 60) {
          statusColor = 'delay__30__60'
        }
        if (time > 60) {
          statusColor = 'delay__60__above'
        }
      } else {
        if (lead.status === 'open') {
          if (time < 30) {
            if (lead.readAt != null) {
              statusColor = 'leadOpen'
            } else {
              statusColor = 'new'
            }
          }
          if (time > 30 && time < 60) {
            statusColor = 'delay__30__60'
          }
          if (time > 60) {
            statusColor = 'delay__60__above'
          }
        }
      }
    }

    return statusColor
  }

  additionalInfoToggle = () => {
    this.setState({ additionalInfoToggleState: !this.state.additionalInfoToggleState })
  }
  createNewLeadToggle = () => {
    this.setState({ createNewLeadToggleState: !this.state.createNewLeadToggleState })
  }

  localStateUpdate = (id) => {
    this.setState({ singleLeadIdState: id })
    this.props.updatePropState(id)
    this.props.setSelectedLeadId(id)
  }

  shareToggle = (shareLeadType) => {
    const { shareToggleState } = this.state
    this.setState({
      shareToggleState: !shareToggleState,
      shareLeadType: shareLeadType,
    })
  }
  fetchAgents = (data, page, LeadType) => {
    const role_id = localStorage.getItem('roleId')
    const { shareLeadType } = this.state
    this.setState({
      shareloader: true,
    })
    axios
      .get(
        LeadType === 'assign'
          ? `${
              config.apiPath
            }/api/role/sub-users?roleId=${role_id}&addManager=${true}&page=${page}&pageSize=${config.pageSize}&web=true&leadType=BuyRent`
          : // `${config.apiPath}/api/user/agents?leads=true&searchBy=${
            //     data ? data : 'myTeam'
            //   }&all=true`
            `${
              config.apiPath
            }/api/user/agents?sharing=${true}&page=${page}&pageSize=${config.pageSize}&web=true&leadType=BuyRent`
      )
      .then((res) => {
        this.setState({
          agents: LeadType === 'assign' ? res.data : res.data.rows,
          agentCount: LeadType !== 'assign' ? res.data.count : null,
          shareloader: false,
        })
      })
  }

  filterReferLeadAgentName = (filterName, page) => {
    this.setState({
      shareloader: true,
    })
    axios
      .get(
        `${
          config.apiPath
        }/api/user/agents?sharing=${true}&page=${page}&pageSize=${config.pageSize}&web=true&name=${filterName}&leadType=BuyRent`
      )
      .then((res) => {
        this.setState({
          agents: res.data.rows,
          shareloader: false,
        })
      })
  }

  handleSCAModal = () => {
    this.setState({ scaDocumentToggleState: !this.state.scaDocToggle })
  }

  investmentLeadShareFun = (id, firtName, lastName) => {
    const { addInvestmentShareLead, fetchLeadTimeLine, fetchLeads } = this.props
    const { singleLeadIdState, type } = this.state
    const user_id = localStorage.getItem('user_id')
    let shareData = {
      userId: id,
      leadId: singleLeadIdState,
    }

    const showLeadReferSuccessMsgHandler = () => {
      showToastMsg(
        `Lead has successfully been referred to <b>${firtName + ' ' + lastName} </b>`,
        'success'
      )
    }
    addInvestmentShareLead(
      shareData,
      fetchLeadTimeLine,
      singleLeadIdState,
      type,
      showLeadReferSuccessMsgHandler
    )
    setTimeout(() => {
      const { history, user } = this.props
      const { pathname } = this.props.location

      const urlParams = new URLSearchParams(window.location.search)
      const pageSize = urlParams.get('pageSize')
      const page = urlParams.get('page')
      var offset = 0
      if (isNaN((page - 1) * pageSize)) {
        offset = 0
      } else {
        offset = (page - 1) * pageSize
      }
      let query = { offset, pageSize }
      if (!(page && pageSize) && !this.props.popup) {
        history.push(`${pathname}?page=${page || '1'}&pageSize=${pageSize || config.pageSize}`)
      }

      // if(this.props.customer)
      //   query.clientId =  this.props.customer.id

      // this.props.fetchLeads(
      //   // query,
      //   // this.props.leadOrder && this.props.leadOrder.value,
      //   // (data) => {
      //   //   this.setState({ initialCount: data.count })
      //   // },
      //   // this.hasBooking()
      // )

      // this.setState({
      //   shareToggleState: false,
      // })

      this.props.fetchBRLeads(undefined, undefined, undefined, this.hasBooking())
      this.props.fetchFreshBuyRentLeads(
        `${config.apiPath}/api/v1/leads?web=true&pageSize=${config.pageSize}&page=${1}`
      )
      this.setState({
        shareToggleState: false,
      })
    }, 0)
  }

  closeWonFun = (item) => {
    let payload = Object.create({})
    payload.reasons = 'payment_done'
    var leadId = []
    leadId.push(item.id)
    axios
      .patch(`${config.apiPath}/api/leads`, payload, { params: { id: leadId } })
      .then((response) => {
        Swal.fire('Success', 'Lead has been closed successfully', 'success')
      })
      .catch((error) => {
        console.log(error)
      })
  }

  assignLeadFun = (id, firstName, lastName) => {
    let space = ' '
    const { addInvestmentShareLead, fetchLeadTimeLine } = this.props
    const { singleLeadIdState } = this.state
    const user_id = localStorage.getItem('user_id')
    let assignData = {
      leadId: [singleLeadIdState],
      // type: 'investment',
      userId: id,
    }
    axios.patch(`${config.apiPath}/api/leads/assign`, assignData).then((res) => {
      this.setState({
        shareToggleState: false,
      })
      Swal.fire(
        'Success',
        `Lead re-assign to <b>${firstName + space + lastName}</b> Successfully`,
        'success'
      )
      setTimeout(() => {
        this.props.fetchBRLeads(undefined, undefined, undefined, this.hasBooking())
        this.props.fetchFreshBuyRentLeads(
          `${config.apiPath}/api/v1/leads?web=true&pageSize=${config.pageSize}&page=${1}`
        )

        // this.props.fetchLeads()
      }, 300)
    })
  }

  render() {
    const {
      leads,
      activeStatusActiveButton,
      showRows,
      deletionUsersLoader,
      deletionUsers,
      organizationsDetail,
      pageType,
      popup,
      clientLeadModal,
      tabUrl,
      otherleads
    } = this.props
    const urlParams = new URLSearchParams(window.location.search)
    const pageSize = urlParams.get('pageSize')
    const page = urlParams.get('page')
    const {
      selectedLeads,
      selectedLead,
      items,
      pageNumber,
      deletionReason,
      showDropShadow,
      selectedItemLeads,
      allSelectedElements,
      allSelected,
      success,
      dropdownOptions,
      filterOrgValue,
      filterRoleDropdown,
      byNameInputValue,
      showUserDropdown,
      clearButtonToggle,
      showButton,
      userInfo,
      commentLoader,
      leadComments,
      taskModal,
      leadTaskModal,
      fetchLeadsInModal,
      diaryModal,
      additionalInfoToggleState,
      createNewLeadToggleState,
      setItem,
      shareToggleState,
      shareloader,
      agents,
      shareLeadType,
      singleLeadIdState,
      agentCount,
      scaDocumentToggleState,
      itemId,
      scaDocument,
      legalCount,
      legalServicesFee,
      customersPhoneNumbers,
      customerPhoneLoader,
      callingFrom,
      callToggleState,
      showAlertToggle,
      callLogs,
      currentPage,
      legalDocs,
      connectFlow,
      newTask,
      updateLeadTasks,
      closeAsDuplicateModal,
      closeAsLostModal,
    } = this.state

    const role = localStorage.getItem('role')

    let statusElement = selectedItemLeads && selectedItemLeads.map((item) => item.status)
    const count = (str) => _.countBy(str)['closed_won'] || 0
    const closedCount = (str) => _.countBy(str)['closed_lost'] || 0
    const closed = (str) => _.countBy(str)['closed'] || 0

    let allStatusElement =
      allSelectedElements &&
      allSelectedElements.rows &&
      allSelectedElements.rows.map((item) => item.status)

    const organizationsDetailOptions =
      organizationsDetail &&
      organizationsDetail.map((item) => ({
        label: item.name,
        value: item.id,
      }))

    const roleDropdownOptions =
      dropdownOptions &&
      dropdownOptions.map((item) => ({
        label: item.name,
        value: item.value,
      }))

    let currentURL = this.props.location.pathname

    return (
      <>
        {selectedLeads && selectedLeads.length > 1 ? (
          <div className="row">
            <>
              <div className="col-md-9">
                <div className="mt-2 mb-4">
                  {
                    this.props.dialerLeadInformation.isOwnLead && (
                      <div className={'btn-group'}>
                        <button
                          className="link-style projectLeadButton"
                          style={{padding: '0 !important'}}
                          onClick={() => {
                            this.setState({success: true})
                            this.fetchDeletionUsersFilter(1)
                            this.fetchAllUserForSearchFilter()
                          }}
                          disabled={
                            selectedLeads.length === 0
                              ? true
                              : statusElement.includes('closed_won') ||
                              statusElement.includes('closed_lost')
                                ? true
                                : false
                          }
                        >
                          Re-Assign
                        </button>
                       {/* <LeadClickComponent leadData={{leadId: item.id, diaryId: item.latestDiaryId}}/>*/}
                      </div>
                    )
                  }

                  <button
                    className=" link-style ml-2 projectLeadButton"
                    onClick={() => this.deleteToggle()}
                    disabled={selectedLeads.length === 0 ? true : false}
                  >
                    Delete
                  </button>

                  <button
                    className=" link-style ml-2 projectLeadButton"
                    onClick={() => this.reOpenToggle()}
                    disabled={
                      count(statusElement) + closedCount(statusElement) === statusElement.length &&
                      !selectedLeads
                        ? false
                        : count(statusElement) +
                            closedCount(statusElement) +
                            closed(statusElement) ===
                            statusElement.length && selectedItemLeads.length
                        ? false
                        : count(allStatusElement) +
                            closedCount(allStatusElement) +
                            closed(allStatusElement) ===
                            selectedLeads.length && allSelected
                        ? false
                        : allSelected && activeStatusActiveButton
                        ? false
                        : true
                    }
                  >
                    Reopen
                  </button>
                </div>
              </div>
            </>
          </div>
        ) : null}
        <div className={selectedLeads && selectedLeads.length > 1 ? 'row' : 'row'}>
          <div className="col-sm-12 col-md-12">
            <div>
              <table
                className="br-lead-table"
                id="table"
                style={{
                  width: '100%',
                  height:
                    (showRows && selectedLeads && selectedLeads.length === 0) ||
                    (showRows && selectedLeads && selectedLeads.length === 1)
                      ? 'calc(100vh - 400px)'
                      : selectedLeads && selectedLeads.length > 1 && showRows
                      ? 'calc(100vh - 412px)'
                      : showRows === false && selectedLeads && selectedLeads.length > 1
                      ? 'calc(100vh - 322px)'
                      : 'calc(100vh - 60vh)',
                }}
              >
                <thead>
                  <tr>
                    <th style={{ maxWidth: '30px', minWidth: '30px' }}></th>
                    <th style={{ maxWidth: 150, minWidth: 150 }}>ID</th>
                    <th style={{ maxWidth: 100, minWidth: 100 }}>Lead Type</th>
                    <th style={{ maxWidth: 160, minWidth: 160 }}>Client Name</th>
                    <th style={{ maxWidth: 150, minWidth: 150 }}>Property Type</th>
                    <th style={{ maxWidth: 160, minWidth: 160 }}>Property s</th>
                    <th style={{ maxWidth: 130, minWidth: 130 }}>Area</th>
                    <th style={{ maxWidth: 130, minWidth: 130 }}>City</th>
                    <th style={{ maxWidth: 100, minWidth: 100, textAlign: 'center' }}>Status</th>
                    <th style={{ maxWidth: 150, minWidth: 150 }}>Assigned to</th>
                    <th style={{ maxWidth: 150, minWidth: 150 }}>Advisor’s Contact No</th>
                    <th style={{ maxWidth: 130, minWidth: 130 }}>Origin</th>
                    <th style={{ maxWidth: 130, minWidth: 130 }}>Wanted ID </th>
                    <th style={{ maxWidth: 150, minWidth: 150 }}>Closing Reason</th>
                    <th style={{ maxWidth: 138, minWidth: 138 }}>Created Date</th>
                    <th style={{ maxWidth: 70, minWidth: 70 }}></th>
                  </tr>
                </thead>
                <tbody>
                  {leads &&
                    leads.rows.map((item, i) => {
                      let checkForRead = isReadOnly(item, 'BuyRent')
                      var statusColor = this.timeStatusColors(item)
                      let statusColorImage =
                        isREA(this.props.currentUser) && item.requiredProperties
                          ? ''
                          : statusColor === 'delay__30__60'
                          ? YellowLead
                          : statusColor === 'delay__60__above'
                          ? RedLead
                          : ''
                      return (
                        <tr
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                          if (!this.props.dialerLeadInformation.isOwnLead) {
                            return;
                          }
                            this.setState({ selectedLead: item })
                            if (clientLeadModal && tabUrl === 'existinglead')
                              this.viewLeadDetails(item)
                            if(otherleads)
                              this.setState({taskModal:true})
                            if (!popup) this.viewLeadDetails(item)
                            this.props.onRowClick && this.props.onRowClick(item)
                          }}
                          className={`${statusColor} ${
                            selectedLead && selectedLead.id === item.id ? 'in-action' : ''
                          } investLeadStatus hover__for__btns hoverForTr ${
                            selectedLeads.indexOf(item.id) !== -1 && showDropShadow
                              ? 'backgroundOnClick'
                              : ''
                          } `}
                        >
                          <td style={{ maxWidth: '30px', minWidth: '30px' }}>
                            <img src={statusColorImage} className="lead__reprecentation 3" />
                          </td>
                          <td style={{ maxWidth: 120, minWidth: 120 }} className="max-table-width">
                            {!popup ? (
                              <a href={'#!'} onClick={() => this.viewLeadDetails(item)}>
                                {' '}
                                {item.id}
                              </a>
                            ) : (
                              item.id
                            )}
                            {!item.requiredProperties && statusColor === 'shared' && (
                              <span className="statusText investLeadStatus shared">Referred</span>
                            )}
                            {!item.requiredProperties && statusColor === 'new' && (
                              <span className="statusText investLeadStatus new">New</span>
                            )}
                            {item.requiredProperties &&
                              item.assigned_to_armsuser_id != this.props.currentUser.id && (
                                <span className="statusText investLeadStatus shared">
                                  Demand Lead
                                </span>
                              )}
                          </td>
                          <td style={{ maxWidth: 100, minWidth: 100 }} className="max-table-width">
                            {item && item.purpose
                              ? this.capitalize_Words(
                                  item && item.purpose === 'sale'
                                    ? BUY_RENT_LEAD_TYPE_SALE
                                    : item.purpose
                                )
                              : '--'}
                          </td>
                          <td style={{ maxWidth: 160, minWidth: 160 }} className="max-table-width">
                            {/*this.props.currentUser &&
                            isREA(this.props.currentUser) &&
                            item.assigned_to_armsuser_id != this.props.currentUser.id
                              ? '--'
                              : */item.customer && item.customer.customerName !== ' '
                              ? item && item.customer && item.customer.customerName
                              : '--'}
                          </td>
                          <td style={{ maxWidth: 150, minWidth: 150 }} className="max-table-width">
                            {item && item.type ? this.capitalize_Words(item && item.type) : '--'}
                          </td>
                          <td style={{ maxWidth: 160, minWidth: 160 }} className="max-table-width">
                            {item && item.subtype
                              ? this.capitalize_Words(item && item.subtype)
                              : '--'}
                          </td>

                          <td style={{ maxWidth: 130, minWidth: 130 }} className="max-table-width">
                            {item.armsLeadAreas &&
                              item.armsLeadAreas.map((childItem, index) => (
                                <span key={index}>{(index ? ', ' : '') + childItem.area.name}</span>
                              ))}
                            {!item.armsLeadAreas || !item.armsLeadAreas.length ? (
                              <span>--</span>
                            ) : (
                              ''
                            )}
                          </td>
                          <td style={{ maxWidth: 130, minWidth: 130 }} className="max-table-width">
                            {item && item.city && item.city.name
                              ? item && item.city.name.toUpperCase().replace(/_/g, ' ')
                              : '--'}
                          </td>
                          <td
                            className={'max-table-width buyRentLeadStatus'}
                            style={{ maxWidth: '100px', minWidth: '100px' }}
                          >
                            <div className={item ? leadStatusClass(item, 'BuyRent') : ''}>
                              {RenderLeadStatus(item.status, StaticData.projectLeadStatus, true)}
                            </div>
                          </td>
                          <td style={{ maxWidth: 150, minWidth: 150 }} className="max-table-width">
                            {item && item.armsuser
                              ? item &&
                                item.armsuser &&
                                this.capitalize_Words(item.armsuser.firstName) +
                                  ' ' +
                                  this.capitalize_Words(item.armsuser.lastName)
                              : '--'}
                          </td>

                          <td style={{ maxWidth: 150, minWidth: 150 }} className="max-table-width">
                            {item.armsuser != null ? item.armsuser.phoneNumber : '--'}
                          </td>

                          <td style={{ maxWidth: 130, minWidth: 130 }} className="max-table-width">
                            {item && item.origin
                              ? item && item.origin.toUpperCase().replace(/_/g, ' ')
                              : '--'}
                          </td>
                          <td style={{ maxWidth: 130, minWidth: 130 }} className="max-table-width">
                            {item && item.wanted_id ? item.wanted_id : '--'}
                          </td>
                          <td style={{ maxWidth: 150, minWidth: 150 }} className="max-table-width">
                            {item && item.reason
                              ? item && item.reason.toUpperCase().replace(/_/g, ' ')
                              : '--'}
                          </td>

                          <td style={{ maxWidth: 138, minWidth: 138 }} className="max-table-width">
                            {moment(item && item.createdAt).format('DD-MM-YYYY (hh:mm a)')}
                          </td>

                          {/* <td style={{ maxWidth: 200, minWidth: 200 }} className="max-table-width">
                            <button className="btn btn-view" onClick={() => viewTimeline(item.id)}>
                              View
                            </button>
                          </td>*/}
                          {!popup || otherleads && (
                            <td
                              className="actions-td cus-z-index"
                              style={{ maxWidth: 120, minWidth: 120 }}
                              onClick={(e) => e.stopPropagation()}
                            >
                              <div className="d-flex justify-content-center button-padding">
                                <div className="dropdown main__dropdown--action">
                                  {accountsPermissions('buyrentReassign') &&
                                    item.status !== 'closed_won' &&
                                    !item.requiredProperties && (
                                    <div className={'btn-group'}>
                                      <button
                                        disabled={!this.props.dialerLeadInformation.isOwnLead}
                                        className="btn-cus-add mr-1 action-cus"
                                        type="button"
                                        id="dropdownMenuButton"
                                        onClick={() => {
                                          this.localStateUpdate(item.id)
                                          this.setState({ edit: true, selectedLead: item })
                                          this.fetchAgents(null, 1, 'assign')
                                          this.shareToggle('assign')
                                        }}
                                      >
                                        Re-assign
                                      </button>
                                      <LeadClickComponent leadData={{leadId: item.id, diaryId: item.latestDiaryId,leadType:"BuyRent"}}/>
                                    </div>
                                    )}
                                </div>
                              </div>
                            </td>
                          )}
                        </tr>
                      )
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {callToggleState && (
          <CallModal
            callToggleState={callToggleState}
            customersPhoneNumbers={customersPhoneNumbers}
            customerPhoneLoader={customerPhoneLoader}
            callLogs={callLogs}
            whichPage={'investmentLeadList'}
            callingFrom={callingFrom}
            checkForRead={isReadOnly(selectedLead, 'BuyRent')}
            addInvestmentCallFun={this.addInvestmentCallFun}
            callToggle={this.callToggle}
            closeCallModal={this.closeCallModal}
            showAlertToggle={showAlertToggle}
          />
        )}

        {scaDocumentToggleState ? (
          <ScaFileUploader
            selectedItem={itemId}
            addedBy={'seller'}
            scaType={'leadId'}
            scaDocument={scaDocument}
            guideReferenceModal={this.handleSCAModal}
            getScaDocument={this.getScaDocument}
          />
        ) : null}

        {!popup || otherleads && (
          <>
            <Modal
              isOpen={this.state.commentShow}
              toggle={this.commmentToggle}
              className="delete-popup"
            >
              <ModalHeader>
                Comments
                <button
                  type="button"
                  className="close close-icon"
                  aria-label="Close"
                  onClick={() => this.setState({ commentShow: false })}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </ModalHeader>
              <ModalBody id={'br-comment-scroll-container'}>
                {commentLoader ? (
                  <div className="loader-content">
                    <span className={'fa fa-circle-o-notch fa-spin loader-color'}></span>
                  </div>
                ) : leadComments && leadComments.length === 0 ? (
                  <p>No comments found</p>
                ) : (
                  leadComments &&
                  leadComments.map((item) => (
                    <div className="row mb-3">
                      <div className="col-md-1">
                        <img src={Avatar} className="avatar" />
                      </div>

                      <div className="col-md-10 ml-1">
                        <div className="comment-title">
                          {item &&
                            item.armsuser &&
                            item.armsuser.firstName + ' ' + item.armsuser.lastName}
                          <span className="comment-date">
                            {' '}
                            {moment(item && item.createdAt).format('DD-MM-YYYY (hh:mm a)')}
                          </span>
                        </div>
                        <div className="comment-body">{item.value}</div>
                      </div>
                    </div>
                  ))
                )}
              </ModalBody>
            </Modal>

            <Modal
              isOpen={success}
              toggle={this.deletionToggle}
              className="new-modal-content"
              onClick={() => this.setState({ showUserDropdown: false })}
            >
              <ModalHeader>Assign Leads</ModalHeader>
              <ModalBody>
                <>
                  <p>Assign selected lead to:</p>
                  <div className="row">
                    {role === 'super_admin' && (
                      <div className="col-md-3">
                        <Select
                          className="over-ride-class"
                          options={organizationsDetailOptions}
                          value={
                            filterOrgValue
                              ? filterOrgValue
                              : organizationsDetailOptions && organizationsDetailOptions[0]
                          }
                          onChange={(value) => {
                            this.setState(
                              {
                                filterOrgValue: value,
                                filterRoleDropdown: roleDropdownOptions && roleDropdownOptions[0],
                                byNameInputValue: '',
                                showUserDropdown: false,
                              },
                              () => {
                                this.fetchDeletionUsersFilter()
                                this.fetchAllUserForSearchFilter()
                              }
                            )
                          }}
                        />
                      </div>
                    )}

                    <div className="col-md-3">
                      <Select
                        className="over-ride-class"
                        options={roleDropdownOptions}
                        value={
                          filterRoleDropdown
                            ? filterRoleDropdown
                            : role === 'super_admin'
                            ? roleDropdownOptions && roleDropdownOptions[0]
                            : userInfo.organizationId
                        }
                        onChange={(value) =>
                          this.setState(
                            {
                              filterRoleDropdown: value,
                              showButton: true,
                              clearButtonToggle: false,
                            },
                            () => {
                              this.fetchDeletionUsersFilter()
                              this.fetchAllUserForSearchFilter()
                            }
                          )
                        }
                      />
                    </div>

                    <div className="col-sm-3">
                      <div className="inputStyleContainer">
                        <input
                          type="text"
                          className="user_search"
                          placeholder="Select a name"
                          onChange={(e) => {
                            this.handleDeleteByNameChange(e)
                            this.setState({
                              showUserDropdown: true,
                            })
                          }}
                          value={byNameInputValue}
                          onKeyDown={(e) => this._handleKeyDown(e)}
                        />
                      </div>
                      {showUserDropdown ? (
                        <div className="menuList">
                          {items && items.length ? (
                            items &&
                            items.map((item) => (
                              <div
                                className="menuListOption"
                                key={item.id}
                                onClick={() =>
                                  this.setState({
                                    byNameInputValue:
                                      item.firstName.trim() + ' ' + item.lastName.trim(),
                                    query: item.firstName + ' ' + item.lastName,
                                    showUserDropdown: false,
                                    showButton: true,
                                  })
                                }
                              >
                                {item.firstName + ' ' + item.lastName}
                              </div>
                            ))
                          ) : (
                            <div className="menuListOption">No User found</div>
                          )}
                        </div>
                      ) : null}
                    </div>
                    <div className={role === 'admin' ? 'col-md-4' : 'col-md-1'} />
                    <div className="col-md-2 text-right">
                      <button
                        className="newFormSearch"
                        type="submit"
                        onClick={() => {
                          if (showButton) {
                            this.searchFilter(pageNumber, byNameInputValue)
                            this.setState({
                              clearButtonToggle: false,
                            })
                          }
                        }}
                      >
                        <i className="fal fa-search"></i>
                      </button>
                      <input
                        type="submit"
                        value="Clear"
                        className={clearButtonToggle ? 'newClearDisabledButton' : 'newFormClear'}
                        type="submit"
                        disabled={clearButtonToggle}
                        onClick={() => {
                          this.props.fetchUsersForDeletion(
                            1,
                            `/api/user/leadAgents?userRole=all&pageSize=${config.pageSize}&orgId=${
                              filterOrgValue
                                ? filterOrgValue.value
                                : role === 'super_admin'
                                ? organizationsDetail &&
                                  organizationsDetail[0] &&
                                  organizationsDetail[0].id
                                : userInfo.organizationId
                            }`
                          )
                          this.setState({
                            clearButtonToggle: true,
                            byNameInputValue: '',
                            filterRoleDropdown: roleDropdownOptions && roleDropdownOptions[0],
                          })
                        }}
                      />
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="user-form">
                      <table className="deletion-table" id="table" style={{ width: '100%' }}>
                        <thead>
                          <tr>
                            <th style={{ minWidth: 90, maxWidth: 90 }}>Id</th>
                            <th style={{ minWidth: 200, maxWidth: 200 }}>Name</th>
                            <th style={{ minWidth: 165, maxWidth: 165 }}>Title</th>
                            <th style={{ minWidth: 200, maxWidth: 200 }}>Organisation</th>
                            <th style={{ minWidth: 160, maxWidth: 160 }}>Region</th>
                            <th style={{ minWidth: 180, maxWidth: 180 }}>Role</th>
                            <th style={{ minWidth: 170, maxWidth: 170 }}>Team</th>
                            <th style={{ minWidth: 120, maxWidth: 120 }}>Action</th>
                          </tr>
                        </thead>
                        {deletionUsersLoader ? (
                          <Load title="Users" />
                        ) : deletionUsers &&
                          deletionUsers.rows &&
                          deletionUsers.rows.length === 0 ? (
                          <div
                            style={{ marginTop: '60px', marginBottom: 50 }}
                            className="text-center"
                          >
                            <h3 className="dimgray">No Users Found</h3>
                          </div>
                        ) : (
                          <tbody>
                            {deletionUsers &&
                              deletionUsers.rows &&
                              deletionUsers.rows.map((item) => (
                                <tr>
                                  <td style={{ minWidth: 90, maxWidth: 90 }}>{item.id}</td>
                                  <td style={{ minWidth: 200, maxWidth: 200 }}>
                                    {item.firstName + ' ' + item.lastName}
                                  </td>
                                  <td style={{ minWidth: 165, maxWidth: 165 }}>{item.title}</td>
                                  <td style={{ minWidth: 200, maxWidth: 200 }}>
                                    {item && item.organization && item.organization.name}
                                  </td>
                                  <td style={{ minWidth: 160, maxWidth: 160 }}>
                                    {item && item.region && item.region.name}
                                  </td>
                                  <td style={{ minWidth: 180, maxWidth: 180 }}>
                                    {item && item.armsUserRole && item.armsUserRole.subRole}
                                  </td>
                                  <td style={{ minWidth: 170, maxWidth: 170 }}>
                                    {item && item.armsTeam && item.armsTeam.teamName}
                                  </td>
                                  <td style={{ minWidth: 120, maxWidth: 120 }}>
                                    <div className={'btn-group'}>
                                      <button
                                        disabled={!this.props.dialerLeadInformation.isOwnLead}
                                        className=" link-style mr-2 ml-2 btn-hollow-activate"
                                        onClick={() => {
                                          this.leadAssignToggle(item.id)
                                        }}
                                      >
                                        Re-Assign
                                      </button>
                                      <LeadClickComponent leadData={{leadId: item.id, diaryId: item.latestDiaryId,leadType:'BuyRent'}}/>
                                    </div>

                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        )}
                      </table>
                    </div>
                    <div style={{ marginLeft: '40%' }}>
                      <Pagination
                        className={'paginationCustomStyle'}
                        onChange={this.fetchDeletionUsersFilter}
                        current={pageNumber}
                        showPrevNextJumpers={false}
                        itemRender={this.textItemRender}
                        total={deletionUsers && deletionUsers.count}
                        showPrevNextJumpers={false}
                        itemRender={this.textItemRender}
                        locale={local}
                      />
                    </div>
                  </div>
                </>
              </ModalBody>
            </Modal>
            <Modal
              isOpen={this.state.deleteOpen}
              toggle={this.deleteToggle}
              className="delete-popup"
            >
              <ModalHeader>Delete Leads?</ModalHeader>
              <ModalBody>
                <p className="text-danger">
                  {(selectedLeads && selectedLeads.length === 1) ||
                  (selectedLeads && selectedLeads.length === 0)
                    ? 'Are you sure you want to delete this lead? This process cannot be undone'
                    : `Are you sure you want to delete these ${
                        selectedLeads && selectedLeads.length
                      } leads? This process cannot be undone`}
                </p>
                <hr />
                <p>
                  <textarea
                    onChange={(e) => this.setState({ deletionReason: e.target.value })}
                    placeholder="Enter Reason"
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    rows="3"
                  ></textarea>
                </p>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="secondary"
                  onClick={() => {
                    this.setState({ deleteOpen: false, deletionReason: '' })
                  }}
                >
                  Cancel
                </Button>
                <Button
                  color="danger"
                  onClick={() => {
                    this.deleteProjectLead()
                  }}
                  disabled={!deletionReason ? true : false}
                >
                  Delete
                </Button>
              </ModalFooter>
            </Modal>

            <Modal
              isOpen={this.state.reOpenModal}
              toggle={this.reOpenToggle}
              className="delete-popup"
            >
              <ModalHeader>Re-Open Leads?</ModalHeader>
              <ModalBody>
                <p>
                  {(selectedLeads && selectedLeads.length === 1) ||
                  (selectedLeads && selectedLeads.length === 0)
                    ? 'Are you sure you want to re-open?'
                    : `Are you sure you want to re-open these ${
                        selectedLeads && selectedLeads.length
                      } leads?`}
                </p>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="secondary"
                  onClick={() => {
                    this.setState({ reOpenModal: false, deletionReason: '' })
                  }}
                >
                  Cancel
                </Button>
                <Button
                  color="success"
                  onClick={() => {
                    this.reOpen()
                  }}
                >
                  Re-Open
                </Button>
              </ModalFooter>
            </Modal>
            <Modal isOpen={this.state.leadModal} toggle={this.leadAssignToggle}>
              <ModalHeader>Assign Leads?</ModalHeader>
              <ModalBody>
                <p>Are you sure you want to re-assign?</p>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="secondary"
                  onClick={() => {
                    this.setState({ leadModal: false, deletionReason: '' })
                  }}
                >
                  Cancel
                </Button>
                <Button
                  color="success"
                  onClick={() => {
                    this.leadAssign()
                  }}
                >
                  Assign
                </Button>
              </ModalFooter>
            </Modal>

            {closeAsDuplicateModal && (
              <CloseAsDuplicateModal
                lead={selectedLead}
                leadType={'BuyRent'}
                modal={closeAsDuplicateModal}
                setModal={(toggle) => this.setState({ closeAsDuplicateModal: toggle })}
                callback={() => {
                  this.setState({ closeAsDuplicateModal: false })
                  this.props.refreshLead()
                }}
              />
            )}

            {closeAsLostModal && (
              <CloseAsLostModal
                lead={selectedLead}
                leadType={'BuyRent'}
                modal={closeAsLostModal}
                setModal={(toggle) => this.setState({ closeAsLostModal: toggle })}
                callback={() => {
                  this.setState({ closeAsLostModal: false })
                  this.props.refreshLead()
                }}
              />
            )}
          </>
        )}
        {additionalInfoToggleState && (
          <AdditionalDetails
            onToggle={this.additionalInfoToggle}
            isOpen={additionalInfoToggleState}
            additionalDetailLeadrecord={setItem}
            ApiCall={false}
            checkLeadtype={'Buy/Rent'}
          ></AdditionalDetails>
        )}

        <ReferModal
          shareToggleState={shareToggleState}
          shareLeadType={shareLeadType}
          managerSelectionDropdown={managerSelectionDropdown}
          loader={shareloader}
          agents={agents}
          agentCount={agentCount}
          filterReferLeadAgentName={this.filterReferLeadAgentName}
          fetchAgents={this.fetchAgents}
          investmentLeadShareFun={this.investmentLeadShareFun}
          assignLeadFun={this.assignLeadFun}
          shareToggle={this.shareToggle}
        />

        {leadTaskModal ? (
          <DiaryTaskModal
            taskModalToggle={leadTaskModal}
            leadId={(selectedLead && selectedLead.id) || ''}
            lead={selectedLead}
            diary={null}
            leadType={'BuyRent'}
            diaryAction={'add-lead-task'}
            closeTaskModal={() => this.setState({ leadTaskModal: false })}
            callBack={(toggle) => this.setState({ fetchLeadsInModal: toggle })}
            userId={
              selectedLead.wanted_id && isAira() ? selectedLead.assigned_to_armsuser_id : null
            }
          />
        ) : null}

        {
        selectedLead &&
        (!selectedLead.requiredProperties
          // selectedLead.assigned_to_armsuser_id == this.props.currentUser.id
          ) &&
        taskModal
        ? (
          <ConnectModal
            connectModal={taskModal}
            lead={selectedLead}
            leadType={'BuyRent'}
            setNewTask={(newTask) =>
              this.setState({ taskModal: false, newTask: newTask, connectFlow: true })
            }
            customer={selectedLead.customer}
            checkForRead={isReadOnly(selectedLead, 'BuyRent')}
            setConnectModal={(toggle) => {
              this.setState({ taskModal: toggle })
              this.props.refreshLead()
            }}
            setCallRecord={(callRecord) => {
              this.setState({ taskModal: false })
              // setConnectFeedbackModal(true)
              // setCallRecord(callRecord)
            }}
            updateLeadTasks={updateLeadTasks}
            onAction={(action) => {
              if (action === 'scheduled-task') this.setState({ diaryModal: true, taskModal: false })
              else if (action === 'additional-details')
                this.setState({ additionalInfoToggleState: true, setItem: selectedLead })
              else if (action === 'refer-lead') {
                this.fetchAgents(null, 1, 'share')
                this.shareToggle('share')
              } else if (action === 'reassign-lead') {
                this.fetchAgents(null, 1, 'assign')
                this.shareToggle('assign')
              } else if (action === 'close-as-duplicate')
                this.setState({ closeAsDuplicateModal: true })
              else if (action === 'close-as-lost') this.setState({ closeAsLostModal: true })
            }}
          />
        ) : null}
        {selectedLead &&
          selectedLead.requiredProperties &&
          selectedLead.assigned_to_armsuser_id != this.props.currentUser.id &&
          taskModal && (
            <RequirementReadViewModal
              open={taskModal}
              toggle={() => this.setState({ taskModal: false })}
              lead={selectedLead}
              leadType={'BuyRent'}
              onAction={(action) => {
                if (action === 'scheduled-task') this.setState({ diaryModal: true })
                else if (action === 'additional-details')
                  this.setState({ additionalInfoToggleState: true, setItem: selectedLead })
              }}
            ></RequirementReadViewModal>
          )}

        {diaryModal && (
          <DiaryModal
            leadType={'BuyRent'}
            diaryModal={diaryModal}
            checkForRead={isReadOnly(selectedLead, 'BuyRent')}
            setDiaryModal={(toggle) => this.setState({ diaryModal: toggle })}
            setLeadTaskModal={(toggle) => this.setState({ leadTaskModal: toggle })}
            fetchLeadsInModal={fetchLeadsInModal}
            setFetchLeadsInModal={(toggle) => {
              this.setState({ fetchLeadsInModal: toggle })
              this.props.refreshLead()
            }}
            lead={selectedLead}
            userId={
              selectedLead.wanted_id && isAira() ? selectedLead.assigned_to_armsuser_id : null
            }
          />
        )}
        {createNewLeadToggleState && (
          <ClientNewLead
            onToggle={this.createNewLeadToggle}
            isOpen={createNewLeadToggleState}
            lead={selectedLead}
            {...this.props}
          ></ClientNewLead>
        )}

        {connectFlow && (
          <ConnectFlow
            setConnectModal={(toggle) => {
              this.setState({ taskModal: toggle, connectFlow: false })
            }}
            setConnectFlow={() => this.setState({ connectFlow: false })}
            diary={newTask}
            actionType={'Connect'}
            callback={() => {
              this.setState({ connectFlow: false })
              this.props.refreshLead()
            }}
          />
        )}
      </>
    )
  }
}
export const mapDispatchToProps = (dispatch) => {
  return {
    fetchBRLeads: (query, sortingOrder, callback, hasBooking) =>
      dispatch(buyAndRentLeads(query, callback, hasBooking)),
    fetchUsers: (page, url) => dispatch(fetchUsers(page, url)),
    fetchUsersForDeletion: (page, url) => dispatch(fetchUsersForDeletion(page, url)),
    fetchFreshBuyRentLeads: (query) => dispatch(fetchFreshBuyRentLeads(query)),
    addInvestmentShareLead: (obj, callback, leadId, type, showLeadReferSuccessMsgHandler) =>
      dispatch(investmentShareLead(obj, callback, leadId, type, showLeadReferSuccessMsgHandler)),
    fetchLeadTimeLine: (id) => dispatch(fetchLeadTimeLine(id)),
    fetchLeads: (query, sortingOrder) => dispatch(fetchLeads(query, sortingOrder)),
    fetchLegalDocumentsProp: (lead, addedBy, callback) =>
      dispatch(fetchLegalDocuments(lead, addedBy, true, callback)),
  }
}
const mapStateToProps = (state) => {
  return {
    users: state.Leads.users,
    sellerDocsLoader: state.Property.sellerDocsLoader,
    buyerDocsLoader: state.Property.buyerDocsLoader,
    userLoading: state.Leads.userLoading,
    deletionUsers: state.user.deletionUsers,
    deletionUsersLoader: state.user.deletionUsersLoader,
    currentUser: state.user.userInfo,
    dialerLeadInformation: state.Diary.dialerLeadInformation
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BuyAndRentLeadInner))
