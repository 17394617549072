/** @format */

import React, { useEffect } from 'react'
import QuickFilterItem from './QuickFilterItem'
import menuItems from '../Menu/MenuItems'
import { extractRoute } from '../../../utils/helper'
import { setQuickFilter } from '../../../actions/generalSetting'
import { setDiaryQuickFilters, setOverDueQuickFilters } from '../../../actions/diary'
import { connect } from 'react-redux'

const QuickFilter = (props) => {
  const isDiaryView = () => {
    if (window.location.pathname.includes('diary')) return true
    else if (window.location.pathname.includes('overdue')) return false
    else return null
  }
  let quickFilters = []
  let submenu
  // select quick filter on base of current route
  Object.keys(menuItems).map((key) => {
    // check if any one menu link or sub menu link matches then select the corresponding quick filter immediately
    menuItems[key].map((menu) => {
      if (extractRoute(menu.link) === props.path && menu.quickFilter)
        quickFilters = menu.quickFilter
      else if (menu.subMenus) {
        submenu = menu.subMenus.filter((submenu) => extractRoute(submenu.link) === props.path)
        if (submenu.length && submenu[0].quickFilter) quickFilters = submenu[0].quickFilter
      }
    })
  })
  useEffect(() => {
    let defaultF = []
    for (let i = 0; i < quickFilters.length; i++) {
      if (quickFilters[i].default == true) {
        defaultF.push(quickFilters[i])
      }
    }
    defaultF.length > 0 && props.setQuickFilter([...(props.quickFilter || []), ...defaultF])
    if (window && window.location && window.location.pathname.includes('diary')) {
      defaultF.length > 0 && props.setDiaryQuickFilter([...(props.quickFilter || []), ...defaultF])
    }
  }, [])

  return (
    <>
      <div className={'quick-filter d-flex'}>
        {quickFilters.map((filterItem, k) => (
          <QuickFilterItem
            key={k}
            item={filterItem}
            selectedFilters={props.quickFilter || []}
            setSelectedQuickFilter={(quickFilter) => {
              props.setQuickFilter(quickFilter)
              if (isDiaryView() === true) {
                props.setDiaryQuickFilter(quickFilter, true)
              } else if (isDiaryView() === false) {
                props.setOverDueQuickFilters(quickFilter, true)
              }
            }}
          />
        ))}
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    quickFilter: state.GeneralSetting.quickFilter,
  }
}
export const mapDispatchToProps = (dispatch) => {
  return {
    setQuickFilter: (quickFilters) => dispatch(setQuickFilter(quickFilters)),
    setDiaryQuickFilter: (quickFilters, isChange) =>
      dispatch(setDiaryQuickFilters(quickFilters, isChange)),
    setOverDueQuickFilters: (quickFilters, isChange) =>
      dispatch(setOverDueQuickFilters(quickFilters, isChange)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(QuickFilter)
