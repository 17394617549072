/** @format */

import React from 'react'
import { Modal, ModalBody } from 'reactstrap'

import Properties from '../../Properties'

const ShortListedPropertiesModal = ({
  shortListedPropertiesToggle,
  toggleButton,
  singleLeadRecord,
}) => {
  return (
    <Modal
      isOpen={shortListedPropertiesToggle}
      toggle={toggleButton}
      size="lg"
      style={{ maxWidth: '1500px', width: '100%' }}
    >
      <ModalBody>
        <div className="comment-main-wrapper">
          <div className="comment-popup-body-header">
            <div className="popup-body-header-title">Properties</div>
            <i
              className="fal fa-times-circle popup-body-header-icon"
              onClick={() => {
                toggleButton(false)
              }}
            ></i>
          </div>
        </div>

        <Properties toggleButton={toggleButton} singleLeadRecord={singleLeadRecord} />
      </ModalBody>
    </Modal>
  )
}

export default ShortListedPropertiesModal
