/** @format */

import { StaticData } from '../../../StaticData'
import {
  BuyRentCompanyLeadUrl,
  BuyRentDemandLeadUrl, BuyRentMyLeadUrl,
  DIARY_QUICK_FILTERS, ProjectCompanyLeadUrl, ProjectDeactivatedLeadUrl,
  ProjectLeadUrl, ProjectMyLeadUrl, ReferedBuyRentDealUrl,
  ReferedBuyRentLeadUrl,
  ReferedProjectLeadUrl,
  SUB_MENUS_LABEL,
  TeamBuyRentDealUrl,
  TeamBuyRentLeadUrl,
  TeamProjectDealUrl,
  TeamProjectLeadUrl,
  WantedQualifiedLeadUrl, WantedTeamLeadUrl,
} from '../../../utils/constant_data'
import { PermissionFeatures, PermissionActions } from './../../../utils/permissionConstants'
import { getPermissionValue } from '../../../../src/components/_unlockComponent'
import config from '../../../config'
export const diaryFilter = [
  {
    label: 'Date',
    value: DIARY_QUICK_FILTERS.TODAY,
    default: true,
    type: 'diaryDateSelector',
    for: 'date[]',
  },
  {
    label: DIARY_QUICK_FILTERS.FOLLOWUP,
    value: DIARY_QUICK_FILTERS.FOLLOWUP.toLocaleLowerCase().replace(/[ ]+/g, '_'),
    for: 'taskType[]',
  },
  {
    label: DIARY_QUICK_FILTERS.MEETINGS,
    value: DIARY_QUICK_FILTERS.MEETINGS.toLocaleLowerCase(),
    for: 'taskType[]',
  },
  {
    label: DIARY_QUICK_FILTERS.HOT,
    value: DIARY_QUICK_FILTERS.HOT,
    for: 'leadCategory',
  },
  {
    label: DIARY_QUICK_FILTERS.CONNECT,
    value: DIARY_QUICK_FILTERS.CONNECT.toLocaleLowerCase(),
    for: 'taskType[]',
  },
]

const diarySortOptions = [
  { label: 'Time Based', value: '' },
  { label: 'Task Type', value: 'orderBy=type' },
  { label: 'Classification', value: 'orderBy=class' },
]

const menuItems = {
  leads: [
    {
      label: 'Diary',
      link: '/diary',
      style: {},
      isShow: getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.DIARY_PAGE_VIEW),
      subMenus: [
        {
          label: SUB_MENUS_LABEL.DIARY,
          link: '/diary',
          quickFilter: diaryFilter,
          sortOptions: diarySortOptions,
          moreFilter: true,
          isShow: getPermissionValue(
            PermissionFeatures.WEB_PAGES,
            PermissionActions.DIARY_PAGE_VIEW
          ),
        },
        {
          label: SUB_MENUS_LABEL.OVERDUE,
          link: '/overdue',
          quickFilter: diaryFilter.slice(2),
          sortOptions: diarySortOptions,
          moreFilter: true,
          showBadge: true,
          isShow: getPermissionValue(
            PermissionFeatures.WEB_PAGES,
            PermissionActions.DIARY_PAGE_VIEW
          ),
        },
      ],
    },
    {
      label: 'Leads',
      link: `${
        getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.WANTED_LEADS_PAGE_VIEW)
          ? '/wanted'
          : getPermissionValue(
              PermissionFeatures.WEB_PAGES,
              PermissionActions.BUYRENT_LEADS_PAGE_VIEW
            )
          ? `/buy-rent-lead?page=1&pageSize=${config.pageSize}`
          : getPermissionValue(
              PermissionFeatures.WEB_PAGES,
              PermissionActions.PROJECT_LEADS_PAGE_VIEW
            )
          ? `${ProjectLeadUrl}?page=1&pageSize=${config.pageSize}`
          : ''
      }`,
      isShow:
        getPermissionValue(
          PermissionFeatures.WEB_PAGES,
          PermissionActions.WANTED_LEADS_PAGE_VIEW
        ) ||
        getPermissionValue(
          PermissionFeatures.WEB_PAGES,
          PermissionActions.BUYRENT_LEADS_PAGE_VIEW
        ) ||
        getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.PROJECT_LEADS_PAGE_VIEW),
      style: {},
      subMenus: [
        {
          label: 'Wanted',
          link: '/wanted',
          hideLabel: true,
          quickFilter: [],
          isShow: getPermissionValue(
            PermissionFeatures.WEB_PAGES,
            PermissionActions.WANTED_LEADS_PAGE_VIEW
          ),
          moreFilter: true,
        },
        {
          label: 'Qualified Lead',
          link: WantedQualifiedLeadUrl,
          hideLabel: true,
          quickFilter: [],
          isShow: getPermissionValue(
            PermissionFeatures.WEB_PAGES,
            PermissionActions.WANTED_LEADS_PAGE_VIEW
          ),
          moreFilter: true,
        },
        {
          label: "Team's Leads",
          link: WantedTeamLeadUrl,
          hideLabel: true,
          quickFilter: [],
          isShow: getPermissionValue(
            PermissionFeatures.WEB_PAGES,
            PermissionActions.WANTED_LEADS_PAGE_VIEW
          ),
          moreFilter: true,
        },
        {
          label: 'Buy/Rent',
          link: `/buy-rent-lead?page=1&pageSize=${config.pageSize}`,
          hideLabel: true,
          quickFilter: [
            {
              label: 'Buy',
              name: 'purpose',
              value: 'buy',
            },
            {
              label: 'Rent',
              name: 'purpose',
              value: 'rent',
            },
            {
              label: 'Aging: 60min or above',
              name: 'time',
              value: '60',
            },
            {
              label: 'Re-assigned to Me',
              name: 'reassignToMe',
              value: 'true',
            },
          ],
          moreFilter: true,
          sortOptions: StaticData.InvestmentLeadsSortingOption,
          isShow: getPermissionValue(
            PermissionFeatures.WEB_PAGES,
            PermissionActions.BUYRENT_LEADS_PAGE_VIEW
          ),
        },
        {
          label: 'Buy/Rent - Deactivated leads',
          link: `/buy-rent-deactivated-lead?page=1&pageSize=${config.pageSize}`,
          hideLabel: true,
          quickFilter: [
            {
              label: 'Buy',
              name: 'purpose',
              value: 'buy',
            },
            {
              label: 'Rent',
              name: 'purpose',
              value: 'rent',
            },
            {
              label: 'Aging: 60min or above',
              name: 'time',
              value: '60',
            },
          ],
          moreFilter: true,
          sortOptions: StaticData.InvestmentLeadsSortingOption,
          isShow: getPermissionValue(
            PermissionFeatures.WEB_PAGES,
            PermissionActions.BUYRENT_LEADS_PAGE_VIEW
          ),
        },
        {
          label: 'Buy/Rent - Deactivated leads',
          link: `/buy-rent-deactivated-deal?page=1&pageSize=${config.pageSize}`,
          hideLabel: true,
          quickFilter: [
            {
              label: 'Buy',
              name: 'purpose',
              value: 'buy',
            },
            {
              label: 'Rent',
              name: 'purpose',
              value: 'rent',
            },
            {
              label: 'Aging: 60min or above',
              name: 'time',
              value: '60',
            },
          ],
          moreFilter: true,
          sortOptions: StaticData.InvestmentLeadsSortingOption,
          isShow: getPermissionValue(
            PermissionFeatures.WEB_PAGES,
            PermissionActions.BUYRENT_LEADS_PAGE_VIEW
          ),
        },
        {
          label: 'My Leads',
          link: `${BuyRentMyLeadUrl}?page=1&pageSize=${config.pageSize}`,
          hideLabel: true,
          quickFilter: [
            {
              label: 'Buy',
              name: 'purpose',
              value: 'buy',
            },
            {
              label: 'Rent',
              name: 'purpose',
              value: 'rent',
            },
            {
              label: 'Re-assigned to Me',
              name: 'reassignToMe',
              value: 'true',
            },
          ],
          moreFilter: true,
          sortOptions: StaticData.InvestmentLeadsSortingOption,
          isShow: getPermissionValue(
            PermissionFeatures.WEB_PAGES,
            PermissionActions.BUYRENT_LEADS_PAGE_VIEW
          ),
        },
        {
          label: 'Company Leads',
          link: `${BuyRentCompanyLeadUrl}?page=1&pageSize=${config.pageSize}`,
          hideLabel: true,
          quickFilter: [
            {
              label: 'Buy',
              name: 'purpose',
              value: 'buy',
            },
            {
              label: 'Rent',
              name: 'purpose',
              value: 'rent',
            },
          ],
          moreFilter: true,
          sortOptions: StaticData.InvestmentLeadsSortingOption,
          isShow: getPermissionValue(
            PermissionFeatures.WEB_PAGES,
            PermissionActions.BUYRENT_LEADS_PAGE_VIEW
          ),
        },
        {
          label: 'BuyRent Refered Lead',
          link: `${ReferedBuyRentLeadUrl}?page=1&pageSize=${config.pageSize}`,
          hideLabel: true,
          quickFilter: [
            {
              label: 'Buy',
              name: 'purpose',
              value: 'buy',
            },
            {
              label: 'Rent',
              name: 'purpose',
              value: 'rent',
            },
          ],
          moreFilter: true,
          sortOptions: StaticData.InvestmentLeadsSortingOption,
          isShow: getPermissionValue(
            PermissionFeatures.WEB_PAGES,
            PermissionActions.PROJECT_LEADS_PAGE_VIEW
          ),
        },
        {
          label: 'BuyRent Referred Deals',
          link: `${ReferedBuyRentDealUrl}?page=1&pageSize=${config.pageSize}`,
          hideLabel: true,
          quickFilter: [
            {
              label: 'Buy',
              name: 'purpose',
              value: 'buy',
            },
            {
              label: 'Rent',
              name: 'purpose',
              value: 'rent',
            },
          ],
          moreFilter: true,
          sortOptions: StaticData.InvestmentLeadsSortingOption,
          isShow: getPermissionValue(
            PermissionFeatures.WEB_PAGES,
            PermissionActions.PROJECT_LEADS_PAGE_VIEW
          ),
        },
        {
          label: 'Demand Lead',
          link: `${BuyRentDemandLeadUrl}?page=1&pageSize=${config.pageSize}`,
          hideLabel: true,
          quickFilter: [],
          moreFilter: true,
          sortOptions: StaticData.InvestmentLeadsSortingOption,
          isShow: getPermissionValue(
            PermissionFeatures.WEB_PAGES,
            PermissionActions.BUYRENT_LEADS_PAGE_VIEW
          ),
        },
        {
          label: 'BuyRent Team Lead',
          link: `${TeamBuyRentLeadUrl}?page=1&pageSize=${config.pageSize}`,
          hideLabel: true,
          quickFilter: [
            {
              label: 'Buy',
              name: 'purpose',
              value: 'buy',
            },
            {
              label: 'Rent',
              name: 'purpose',
              value: 'rent',
            },
          ],
          moreFilter: true,
          sortOptions: StaticData.InvestmentLeadsSortingOption,
          isShow: getPermissionValue(
            PermissionFeatures.WEB_PAGES,
            PermissionActions.PROJECT_LEADS_PAGE_VIEW
          ),
        },
        {
          label: 'BuyRent Team Deal',
          link: `${TeamBuyRentDealUrl}?page=1&pageSize=${config.pageSize}`,
          hideLabel: true,
          quickFilter: [
          ],
          moreFilter: true,
          sortOptions: StaticData.InvestmentLeadsSortingOption,
          isShow: getPermissionValue(
            PermissionFeatures.WEB_PAGES,
            PermissionActions.MY_DEALS_PROJECT
          ),
        },
        {
          label: 'Teams',
          link: `/teams?page=1&pageSize=${config.pageSize}`,
          hideLabel: true,
          isShow: getPermissionValue(
            PermissionFeatures.WEB_PAGES,
            PermissionActions.TEAMS_PAGE_VIEW
          ),
        },
        {
          label: 'Project',
          link: `${ProjectLeadUrl}?page=1&pageSize=${config.pageSize}`,
          hideLabel: true,
          quickFilter: [
            {
              label: 'Facebook Leads',
              name: 'faceBookFilter',
              value: 'yes',
            },
            {
              label: 'Single Task',
              name: 'singleTask',
              value: true,
            },
            {
              label: 'Hot',
              name: 'leadCategory',
              value: 'Hot',
            },
            {
              label: 'Aging: 60min or above',
              name: 'time',
              value: '60',
            },
            {
              label: 'Re-assigned to Me',
              name: 'reassignToMe',
              value: 'true',
            },
          ],
          moreFilter: true,
          sortOptions: StaticData.InvestmentLeadsSortingOption,
          isShow: getPermissionValue(
            PermissionFeatures.WEB_PAGES,
            PermissionActions.PROJECT_LEADS_PAGE_VIEW
          ),
        },
        {
          label: 'My Project Leads',
          link: `${ProjectMyLeadUrl}?page=1&pageSize=${config.pageSize}`,
          hideLabel: true,
          quickFilter: [
            {
              label: 'Facebook Leads',
              name: 'faceBookFilter',
              value: 'yes',
            },
            {
              label: 'Single Task',
              name: 'singleTask',
              value: true,
            },
            {
              label: 'Hot',
              name: 'leadCategory',
              value: 'Hot',
            },
            {
              label: 'Aging: 60min or above',
              name: 'time',
              value: '60',
            },
            {
              label: 'Re-assigned to Me',
              name: 'reassignToMe',
              value: 'true',
            },
          ],
          moreFilter: true,
          sortOptions: StaticData.InvestmentLeadsSortingOption,
          isShow: getPermissionValue(
            PermissionFeatures.WEB_PAGES,
            PermissionActions.PROJECT_LEADS_PAGE_VIEW
          ),
        },
        {
          label: 'Company Leads',
          link: `${ProjectCompanyLeadUrl}?page=1&pageSize=${config.pageSize}`,
          hideLabel: true,
          quickFilter: [
            {
              label: 'Facebook Leads',
              name: 'faceBookFilter',
              value: 'yes',
            },
            {
              label: 'Single Task',
              name: 'singleTask',
              value: true,
            },
            {
              label: 'Hot',
              name: 'leadCategory',
              value: 'Hot',
            },
            {
              label: 'Aging: 60min or above',
              name: 'time',
              value: '60',
            },
            {
              label: 'Re-assigned to Me',
              name: 'reassignToMe',
              value: 'true',
            },
          ],
          moreFilter: true,
          sortOptions: StaticData.InvestmentLeadsSortingOption,
          isShow: getPermissionValue(
            PermissionFeatures.WEB_PAGES,
            PermissionActions.PROJECT_LEADS_PAGE_VIEW
          ),
        },
        {
          label: 'Deactivated Leads',
          link: `${ProjectDeactivatedLeadUrl}?page=1&pageSize=${config.pageSize}`,
          hideLabel: true,
          quickFilter: [
            {
              label: 'Facebook Leads',
              name: 'faceBookFilter',
              value: 'yes',
            },
            {
              label: 'Single Task',
              name: 'singleTask',
              value: true,
            },
            {
              label: 'Hot',
              name: 'leadCategory',
              value: 'Hot',
            },
            {
              label: 'Aging: 60min or above',
              name: 'time',
              value: '60',
            },
            {
              label: 'Re-assigned to Me',
              name: 'reassignToMe',
              value: 'true',
            },
          ],
          moreFilter: true,
          sortOptions: StaticData.InvestmentLeadsSortingOption,
          isShow: getPermissionValue(
            PermissionFeatures.WEB_PAGES,
            PermissionActions.PROJECT_LEADS_PAGE_VIEW
          ),
        },
        {
          label: 'Deactivated Deals',
          link: `${ProjectDeactivatedLeadUrl}?page=1&pageSize=${config.pageSize}`,
          hideLabel: true,
          quickFilter: [
            {
              label: 'Facebook Leads',
              name: 'faceBookFilter',
              value: 'yes',
            },
            {
              label: 'Single Task',
              name: 'singleTask',
              value: true,
            },
            {
              label: 'Hot',
              name: 'leadCategory',
              value: 'Hot',
            },
            {
              label: 'Aging: 60min or above',
              name: 'time',
              value: '60',
            },
            {
              label: 'Re-assigned to Me',
              name: 'reassignToMe',
              value: 'true',
            },
          ],
          moreFilter: true,
          sortOptions: StaticData.InvestmentLeadsSortingOption,
          isShow: getPermissionValue(
            PermissionFeatures.WEB_PAGES,
            PermissionActions.PROJECT_LEADS_PAGE_VIEW
          ),
        },
        {
          label: 'Project Refered Lead',
          link: `${ReferedProjectLeadUrl}?page=1&pageSize=${config.pageSize}`,
          hideLabel: true,
          quickFilter: [
            {
              label: 'Facebook Leads',
              name: 'faceBookFilter',
              value: 'yes',
            },
            {
              label: 'Single Task',
              name: 'singleTask',
              value: true,
            },
            {
              label: 'Hot',
              name: 'leadCategory',
              value: 'Hot',
            },
            {
              label: 'Aging: 60min or above',
              name: 'time',
              value: '60',
            },
            {
              label: 'Re-assigned to Me',
              name: 'reassignToMe',
              value: 'true',
            },
          ],
          moreFilter: true,
          sortOptions: StaticData.InvestmentLeadsSortingOption,
          isShow: getPermissionValue(
            PermissionFeatures.WEB_PAGES,
            PermissionActions.PROJECT_LEADS_PAGE_VIEW
          ),
        },
        {
          label: 'Project Team Lead',
          link: `${TeamProjectLeadUrl}?page=1&pageSize=${config.pageSize}`,
          // hideLabel: true,
          quickFilter: [
            {
              label: 'Facebook Leads',
              name: 'faceBookFilter',
              value: 'yes',
            },
            {
              label: 'Single Task',
              name: 'singleTask',
              value: true,
            },
            {
              label: 'Hot',
              name: 'leadCategory',
              value: 'Hot',
            },
            {
              label: 'Aging: 60min or above',
              name: 'time',
              value: '60',
            },
            {
              label: 'Re-assigned to Me',
              name: 'reassignToMe',
              value: 'true',
            },
          ],
          moreFilter: true,
          sortOptions: StaticData.InvestmentLeadsSortingOption,
          isShow: getPermissionValue(
            PermissionFeatures.WEB_PAGES,
            PermissionActions.PROJECT_LEADS_PAGE_VIEW
          ),
        },
        {
          label: 'Project Team Deal',
          link: `${TeamProjectDealUrl}?page=1&pageSize=${config.pageSize}`,
          hideLabel: true,
          quickFilter: [
            {
              label: 'Facebook Leads',
              name: 'faceBookFilter',
              value: 'yes',
            },
            {
              label: 'Hot',
              name: 'leadCategory',
              value: 'Hot',
            },
            {
              label: 'Aging: 60min or above',
              name: 'time',
              value: '60',
            },
            {
              label: 'Re-assigned to Me',
              name: 'reassignToMe',
              value: 'true',
            },
          ],
          moreFilter: true,
          sortOptions: StaticData.InvestmentLeadsSortingOption,
          isShow: getPermissionValue(
            PermissionFeatures.WEB_PAGES,
            PermissionActions.MY_DEALS_PROJECT
          ),
        },
      ],
    },
    {
      label: 'Deals',
      link: `${
        getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.MY_DEALS_BUY_RENT)
          ? `/buy-rent-deal?page=1&pageSize=${config.pageSize}`
          : `/project-deal?page=1&pageSize=${config.pageSize}`
      }`,
      isShow:
        getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.MY_DEALS_BUY_RENT) ||
        getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.MY_DEALS_PROJECT),
      style: {},
      subMenus: [
        {
          label: 'Buy/Rent',
          link: `/buy-rent-deal?page=1&pageSize=${config.pageSize}`,
          hideLabel: true,
          quickFilter: [],
          moreFilter: true,
          sortOptions: StaticData.InvestmentLeadsSortingOption,
          isShow: getPermissionValue(
            PermissionFeatures.WEB_PAGES,
            PermissionActions.MY_DEALS_BUY_RENT
          ),
        },
        {
          label: 'Project',
          link: `/project-deal?page=1&pageSize=${config.pageSize}`,
          hideLabel: true,
          quickFilter: [
          ],
          moreFilter: true,
          sortOptions: StaticData.InvestmentLeadsSortingOption,
          isShow: getPermissionValue(
            PermissionFeatures.WEB_PAGES,
            PermissionActions.MY_DEALS_PROJECT
          ),
        },
      ],
    },
  ],
}

export default menuItems
