/** @format */
// Last Parent Id 17
// Last Child(collapse) Id 17

import UserManagement from '../../images/icons_small/user-management.svg'
import OrganizationsIcons from '../../images/icons_small/organizations.png'
import AreasGroupIcon from '../../images/icons_small/areasGroup.png'
import TeamManagement from '../../images/icons_small/team-management.svg'
import Projects from '../../images/icons_small/projects.svg'
import Targets from '../../images/icons_small/targets.svg'
import LeadImport from '../../images/icons_small/lead-import.svg'
import Leads from '../../images/icons_small/leads.svg'
import Sales from '../../images/icons_small/sales.svg'
import OrgChart from '../../images/icons_small/org-chart.svg'
import AreaSmall from '../../images/icons_small/area.svg'
import ApprovalDiscount from '../../images/icons_small/approvalDiscount.png'
import ClientManagementIcon from '../../images/team.svg'
import RoleManagemt from '../../images/icons_large/roles-management.svg'
import UserLarge from '../../images/icons_large/user-management.svg'
import TeamLarge from '../../images/icons_large/team-management.svg'
import ProjectLarge from '../../images/icons_large/projects.svg'
import TargetLarge from '../../images/icons_large/targets.svg'
import LeadImportLarge from '../../images/icons_large/lead-import.svg'
import LeadsLarge from '../../images/icons_large/leads.svg'
import SalesLarge from '../../images/icons_large/sales.svg'
import OrgChartLarge from '../../images/icons_large/org-chart.svg'
import AreaLarge from '../../images/icons_large/area.svg'
import Propsure from '../../images/icons_large/Propsur.svg'
import Legal from '../../images/icons_small/legal-module.svg'
import morningView from '../../images/icons_large/morning-meeting.svg'
import ActivityLog from '../../images/icons_large/activity-logs.svg'
import {
  BuyRentDemandLeadUrl,
  ReferedBuyRentDealUrl,
  ReferedBuyRentLeadUrl,
  ReferedProjectDealUrl,
  ReferedProjectLeadUrl,
  TeamBuyRentDealUrl,
  TeamBuyRentLeadUrl,
  TeamProjectDealUrl,
  TeamProjectLeadUrl,
  WantedQualifiedLeadUrl,
  WantedTeamLeadUrl,
} from '../../utils/constant_data'
import {PermissionFeatures, PermissionActions} from '../../utils/permissionConstants'
import {getPermissionValue} from '../../../src/components/_unlockComponent'
import Diary from '../../views/Lead/Diary'
import diaryIconsm from '../../images/icons_small/diary.svg'
import projectleadIconsm from '../../images/icons_small/project-leads.svg'
import buyrentIconsm from '../../images/icons_small/rent.svg'
import AddIcon from '../../images/add.svg'
import config from '../../config'
import PowerBIICon from '../../images/powerbi.svg'
import call_Icon from '../../images/call-icon2.svg'

let firstProject = JSON.parse(localStorage.getItem('projectData'))
let organizationId = parseInt(localStorage.getItem('organizationId'))
let userRole = localStorage.getItem('role')
let isTeamLead = (localStorage.getItem('user_agency_mgmt') !== '' && localStorage.getItem('user_agency_mgmt') !== null) ? JSON.parse(localStorage.getItem('user_agency_mgmt')).data.isTeamLead : false;
const callCenterUser = {
  name: 'Call Center',
  url: '/PowerBI',
  activeTab: 'PowerBI',
  itemId: 17,
  roles: ['super_admin', 'admin'],
  feature: PermissionFeatures.POWER_BI,
  action: PermissionActions.CALL_CENTER,
  resetQuickFilter: true,
  isPermit: getPermissionValue(PermissionFeatures.WEB_PAGES),
}


const saleable_inventories = [
  {
    name: 'Installment Inventory',
    url: '/saleable-inventories/installment',
    activeTab: 'saleable-inventoriesinstallment',
    itemId: 234345,
    roles: ['super_admin', 'admin'],
    feature: PermissionFeatures.WEB_PAGES,
    action: PermissionActions.BOOKING_FORM,
    isPermit:
      getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.BOOKING_FORM) ||
      getPermissionValue(PermissionFeatures.PROJECT_LEADS, PermissionActions.UPDATE),
    isShow:
      getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.BOOKING_FORM) ||
      getPermissionValue(PermissionFeatures.PROJECT_LEADS, PermissionActions.UPDATE) ||
      getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.PROJECTS_PAGE_VIEW) ||
      getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.FLOOR_PAGE_VIEW) ||
      getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.UNIT_PAGE_VIEW) ||
      getPermissionValue(
        PermissionFeatures.WEB_PAGES,
        PermissionActions.INVESTMENT_PRODUCTS_PAGE_VIEW
      ),
    resetQuickFilter: true,
  },
  {
    name: 'Rental / Appreciation Inventory',
    url: '/saleable-inventories/rental_appreciation',
    activeTab: 'saleable-inventoriesrental_appreciation',
    itemId: 234345,
    roles: ['super_admin', 'admin'],
    feature: PermissionFeatures.WEB_PAGES,
    action: PermissionActions.BOOKING_FORM,
    isPermit:
      getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.BOOKING_FORM) ||
      getPermissionValue(PermissionFeatures.PROJECT_LEADS, PermissionActions.UPDATE),
    isShow:
      getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.BOOKING_FORM) ||
      getPermissionValue(PermissionFeatures.PROJECT_LEADS, PermissionActions.UPDATE) ||
      getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.PROJECTS_PAGE_VIEW) ||
      getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.FLOOR_PAGE_VIEW) ||
      getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.UNIT_PAGE_VIEW) ||
      getPermissionValue(
        PermissionFeatures.WEB_PAGES,
        PermissionActions.INVESTMENT_PRODUCTS_PAGE_VIEW
      ),
    resetQuickFilter: true,
  },
]
const internal_saleable_inventories = [
  {
    name: 'Installment',
    url: '/internal-saleable-inventories/installment',
    activeTab: 'saleable-inventoriesinstallment',
    itemId: 8658568,
    roles: ['super_admin', 'admin'],
    feature: PermissionFeatures.WEB_PAGES,
    action: PermissionActions.BOOKING_FORM,
    isPermit:
      getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.BOOKING_FORM) ||
      getPermissionValue(PermissionFeatures.PROJECT_LEADS, PermissionActions.UPDATE),
    isShow:
      getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.BOOKING_FORM) ||
      getPermissionValue(PermissionFeatures.PROJECT_LEADS, PermissionActions.UPDATE) ||
      getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.PROJECTS_PAGE_VIEW) ||
      getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.FLOOR_PAGE_VIEW) ||
      getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.UNIT_PAGE_VIEW) ||
      getPermissionValue(
        PermissionFeatures.WEB_PAGES,
        PermissionActions.INVESTMENT_PRODUCTS_PAGE_VIEW
      ),
    resetQuickFilter: true,
  },
  {
    name: 'Rental / Appreciation',
    url: '/internal-saleable-inventories/rental_appreciation',
    activeTab: 'saleable-inventoriesrental_appreciation',
    itemId: 8658568,
    roles: ['super_admin', 'admin'],
    feature: PermissionFeatures.WEB_PAGES,
    action: PermissionActions.BOOKING_FORM,
    isPermit:
      getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.BOOKING_FORM) ||
      getPermissionValue(PermissionFeatures.PROJECT_LEADS, PermissionActions.UPDATE),
    isShow:
      getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.BOOKING_FORM) ||
      getPermissionValue(PermissionFeatures.PROJECT_LEADS, PermissionActions.UPDATE) ||
      getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.PROJECTS_PAGE_VIEW) ||
      getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.FLOOR_PAGE_VIEW) ||
      getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.UNIT_PAGE_VIEW) ||
      getPermissionValue(
        PermissionFeatures.WEB_PAGES,
        PermissionActions.INVESTMENT_PRODUCTS_PAGE_VIEW
      ),
    resetQuickFilter: true,
  },
]
const external_saleable_inventories = [
  {
    name: 'Installment',
    url: '/external-saleable-inventories/installment',
    activeTab: 'saleable-inventoriesinstallment',
    itemId: 6346346,
    roles: ['super_admin', 'admin'],
    feature: PermissionFeatures.WEB_PAGES,
    action: PermissionActions.BOOKING_FORM,
    isPermit:
      getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.BOOKING_FORM) ||
      getPermissionValue(PermissionFeatures.PROJECT_LEADS, PermissionActions.UPDATE),
    isShow:
      getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.BOOKING_FORM) ||
      getPermissionValue(PermissionFeatures.PROJECT_LEADS, PermissionActions.UPDATE) ||
      getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.PROJECTS_PAGE_VIEW) ||
      getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.FLOOR_PAGE_VIEW) ||
      getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.UNIT_PAGE_VIEW) ||
      getPermissionValue(
        PermissionFeatures.WEB_PAGES,
        PermissionActions.INVESTMENT_PRODUCTS_PAGE_VIEW
      ),
    resetQuickFilter: true,
  },
  {
    name: 'Rental / Appreciation',
    url: '/external-saleable-inventories/rental_appreciation',
    activeTab: 'saleable-inventoriesrental_appreciation',
    itemId: 6346346,
    roles: ['super_admin', 'admin'],
    feature: PermissionFeatures.WEB_PAGES,
    action: PermissionActions.BOOKING_FORM,
    isPermit:
      getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.BOOKING_FORM) ||
      getPermissionValue(PermissionFeatures.PROJECT_LEADS, PermissionActions.UPDATE),
    isShow:
      getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.BOOKING_FORM) ||
      getPermissionValue(PermissionFeatures.PROJECT_LEADS, PermissionActions.UPDATE) ||
      getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.PROJECTS_PAGE_VIEW) ||
      getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.FLOOR_PAGE_VIEW) ||
      getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.UNIT_PAGE_VIEW) ||
      getPermissionValue(
        PermissionFeatures.WEB_PAGES,
        PermissionActions.INVESTMENT_PRODUCTS_PAGE_VIEW
      ),
    resetQuickFilter: true,
  },
]
const external_in_progress_booking = [
  {
    name: 'In Progress',
    url: '/external/in-progress-booking/InProgress',
    activeTab: 'in-progress-bookingInProgress',
    itemId: 23434456,
    roles: ['super_admin', 'admin'],
    feature: PermissionFeatures.WEB_PAGES,
    action: PermissionActions.BOOKING_FORM,
    isPermit:
      getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.BOOKING_FORM) ||
      getPermissionValue(PermissionFeatures.PROJECT_LEADS, PermissionActions.UPDATE),
    isShow:
      getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.BOOKING_FORM) ||
      getPermissionValue(PermissionFeatures.PROJECT_LEADS, PermissionActions.UPDATE),
    resetQuickFilter: true,
  },
  {
    name: 'Completed',
    url: '/external/in-progress-booking/Completed',
    activeTab: 'in-progress-bookingCompleted',
    itemId: 23434456,
    roles: ['super_admin', 'admin'],
    feature: PermissionFeatures.WEB_PAGES,
    action: PermissionActions.BOOKING_FORM,
    isPermit:
      getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.BOOKING_FORM) ||
      getPermissionValue(PermissionFeatures.PROJECT_LEADS, PermissionActions.UPDATE),
    isShow:
      getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.BOOKING_FORM) ||
      getPermissionValue(PermissionFeatures.PROJECT_LEADS, PermissionActions.UPDATE),
    resetQuickFilter: true,
  },
]


const internal_in_progress_booking = [
  {
    name: 'In Progress',
    url: '/internal/in-progress-booking/InProgress',
    activeTab: 'in-progress-bookingInProgress',
    itemId: 234344990,
    roles: ['super_admin', 'admin'],
    feature: PermissionFeatures.WEB_PAGES,
    action: PermissionActions.BOOKING_FORM,
    isPermit:
      getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.BOOKING_FORM) ||
      getPermissionValue(PermissionFeatures.PROJECT_LEADS, PermissionActions.UPDATE),
    isShow:
      getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.BOOKING_FORM) ||
      getPermissionValue(PermissionFeatures.PROJECT_LEADS, PermissionActions.UPDATE),
    resetQuickFilter: true,
  },
  {
    name: 'Completed',
    url: '/internal/in-progress-booking/Completed',
    activeTab: 'in-progress-bookingCompleted',
    itemId: 234344990,
    roles: ['super_admin', 'admin'],
    feature: PermissionFeatures.WEB_PAGES,
    action: PermissionActions.BOOKING_FORM,
    isPermit:
      getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.BOOKING_FORM) ||
      getPermissionValue(PermissionFeatures.PROJECT_LEADS, PermissionActions.UPDATE),
    isShow:
      getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.BOOKING_FORM) ||
      getPermissionValue(PermissionFeatures.PROJECT_LEADS, PermissionActions.UPDATE),
    resetQuickFilter: true,
  },
]


const agency21User = {
  name: 'LCCVT Report',
  url: '/PBIAgency21',
  activeTab: 'PBIAgency21',
  itemId: 17,
  roles: ['super_admin', 'admin'],
  feature: PermissionFeatures.POWER_BI,
  action: PermissionActions.LCCVT_REPORT,
  resetQuickFilter: true,
  isPermit: getPermissionValue(PermissionFeatures.WEB_PAGES),
}

const graanaUser = {
  name: 'LTRCT Report',
  url: '/PBIGraanaLTRCT',
  activeTab: 'PBIGraanaLTRCT',
  itemId: 17,
  roles: ['super_admin', 'admin'],
  feature: PermissionFeatures.POWER_BI,
  action: PermissionActions.LTRCT_REPORT,
  resetQuickFilter: true,
  isPermit: getPermissionValue(PermissionFeatures.WEB_PAGES),
}

const reUser = {
  name: 'RE Report',
  url: '/REReport',
  activeTab: 'REReport',
  itemId: 17,
  roles: ['super_admin', 'admin'],
  feature: PermissionFeatures.POWER_BI,
  action: PermissionActions.RE_REPORT,
  resetQuickFilter: true,
  isPermit: getPermissionValue(PermissionFeatures.WEB_PAGES),
}
const ccmcUser = {
  name: 'CCMC Report',
  url: '/CCMCReport',
  activeTab: 'CCMCReport',
  itemId: 17,
  roles: ['super_admin', 'admin'],
  feature: PermissionFeatures.POWER_BI,
  action: PermissionActions.CCMC_REPORT,
  resetQuickFilter: true,
  isPermit: getPermissionValue(PermissionFeatures.WEB_PAGES),
}

let powerBIChildren = [callCenterUser]
powerBIChildren.push(reUser)

if (userRole === 'Company Management') {
  powerBIChildren.push(agency21User)
  powerBIChildren.push(ccmcUser)
  powerBIChildren.push(graanaUser)
} else if (organizationId === 1) {
  //agency21 user
  powerBIChildren.push(agency21User)
  powerBIChildren.push(ccmcUser)
} else if (organizationId === 2) {
  // graana user
  powerBIChildren.push(graanaUser)
  powerBIChildren.push(ccmcUser)
}

let ReportsChildrens = [
  {
    name: 'CCMC',
    url: '/reports/CCMC',
    activeTab: 'reportsCCMC',
    itemId: 2525,
    roles: ['sub_admin 1','super_admin', 'admin'],
    feature: PermissionFeatures.WEB_PAGES,
    action: PermissionActions.DIARY_PAGE_VIEW,
    resetQuickFilter: true,
    isPermit: getPermissionValue(PermissionFeatures.WEB_PAGES,  PermissionActions.DIARY_PAGE_VIEW),
  },
]

powerBIChildren.push(reUser)

export const menus = [
  {
    name: 'Create',
    url: `/users?page=1&pageSize=${config.pageSize}`,
    iconsSmall: AddIcon,
    iconsLarge: AddIcon,
    id: 0,
    icon: 'fa fa-plus',
    roles: ['super_admin', 'admin'],
    feature: PermissionFeatures.WEB_PAGES,
    action: PermissionActions.USER_PAGE_VIEW,
    isShow:
      getPermissionValue(PermissionFeatures.CLIENTS, PermissionActions.UPDATE) ||
      getPermissionValue(PermissionFeatures.PROJECT_LEADS, PermissionActions.UPDATE) ||
      getPermissionValue(PermissionFeatures.BUY_RENT_LEADS, PermissionActions.UPDATE) ||
      getPermissionValue(PermissionFeatures.DIARY, PermissionActions.UPDATE) ||
      getPermissionValue(PermissionFeatures.PROPERTIES, PermissionActions.UPDATE),
    children: [
      {
        name: 'Client Registration',
        url: '/add-client',
        activeTab: 'add-client',
        itemId: 1,
        roles: ['super_admin', 'admin'],
        feature: PermissionFeatures.CLIENTS,
        action: PermissionActions.UPDATE,
      },
      {
        name: 'Property Registration',
        url: '/addArmsProperty',
        activeTab: 'addArmsProperty',
        itemId: 1,
        roles: ['super_admin'],
        feature: PermissionFeatures.PROPERTIES,
        action: PermissionActions.UPDATE,
      },
      {
        name: 'Diary Task',
        url: '/diary?createTask=true',
        activeTab: '/diary?create-task=true',
        itemId: 1,
        roles: ['super_admin', 'admin'],
        feature: PermissionFeatures.DIARY,
        action: PermissionActions.UPDATE,
      },
      {
        name: 'Existing Client Lead',
        url: '/clientManagement',
        activeTab: 'clientManagement',
        itemId: 1,
        roles: ['super_admin', 'admin'],
        feature: PermissionFeatures.PROJECT_LEADS || PermissionFeatures.BUY_RENT_LEADS,
        action: PermissionActions.UPDATE,
      },
    ],
  },
  {
    name: 'Administration',
    url: `/users?page=1&pageSize=${config.pageSize}`,
    iconsSmall: UserManagement,
    iconsLarge: UserLarge,
    id: 1,
    icon: 'fa fa-plus',
    roles: ['super_admin', 'admin'],
    feature: PermissionFeatures.WEB_PAGES,
    action: PermissionActions.USER_PAGE_VIEW,
    isShow:
      getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.ORGANIZATIONS_PAGE_VIEW) ||
      getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.ROLE_PAGE_VIEW) ||
      getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.USER_PAGE_VIEW) ||
      getPermissionValue(
        PermissionFeatures.WEB_PAGES,
        PermissionActions.DEACTIVATE_USER_PAGE_VIEW
      ) ||
      getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.ACTIVITY_LOGS_PAGE_VIEW),
    children: [
      {
        name: 'Organizations',
        url: '/organizations',
        activeTab: 'organizations',
        itemId: 1,
        roles: ['super_admin', 'admin'],
        feature: PermissionFeatures.WEB_PAGES,
        action: PermissionActions.ORGANIZATIONS_PAGE_VIEW,
        isPermit: getPermissionValue(
          PermissionFeatures.WEB_PAGES,
          PermissionActions.ORGANIZATIONS_PAGE_VIEW
        ),
      },
      {
        name: 'Roles',
        url: '/roles',
        activeTab: 'roles',
        itemId: 1,
        roles: ['super_admin', 'admin'],
        feature: PermissionFeatures.WEB_PAGES,
        action: PermissionActions.ROLE_PAGE_VIEW,
        isPermit: getPermissionValue(
          PermissionFeatures.WEB_PAGES,
          PermissionActions.ROLE_PAGE_VIEW
        ),
      },
      {
        name: 'Users',
        url: `/users?page=1&pageSize=${config.pageSize}`,
        activeTab: 'users',
        itemId: 1,
        roles: ['super_admin', 'admin'],
        feature: PermissionFeatures.WEB_PAGES,
        action: PermissionActions.USER_PAGE_VIEW,
        isPermit: getPermissionValue(
          PermissionFeatures.WEB_PAGES,
          PermissionActions.USER_PAGE_VIEW
        ),
      },
      {
        name: 'Deactivated Users',
        url: `/deactivatedUser?page=1&pageSize=${config.pageSize}`,
        activeTab: 'deactivatedUser',
        itemId: 1,
        roles: ['super_admin', 'admin'],
        feature: PermissionFeatures.WEB_PAGES,
        action: PermissionActions.DEACTIVATE_USER_PAGE_VIEW,
        isPermit: getPermissionValue(
          PermissionFeatures.WEB_PAGES,
          PermissionActions.DEACTIVATE_USER_PAGE_VIEW
        ),
      },
      {
        name: 'Activity Logs',
        url: `/activity-logs?page=1&pageSize=${config.pageSize}`,
        activeTab: 'activity-logs',
        itemId: 1,
        roles: ['super_admin'],
        feature: PermissionFeatures.WEB_PAGES,
        action: PermissionActions.ACTIVITY_LOGS_PAGE_VIEW,
        isPermit: getPermissionValue(
          PermissionFeatures.WEB_PAGES,
          PermissionActions.ACTIVITY_LOGS_PAGE_VIEW
        ),
      },
      {
        name: 'Office locations',
        url: `/office-locations?page=1&pageSize=${config.pageSize}`,
        activeTab: 'office-locations',
        itemId: 1,
        roles: ['super_admin'],
        feature: PermissionFeatures.WEB_PAGES,
        action: PermissionActions.OFFICE_LOCATIONS_VIEW,
        isPermit: getPermissionValue(
          PermissionFeatures.WEB_PAGES,
          PermissionActions.OFFICE_LOCATIONS_VIEW
        ),
      },
      {
        name: 'Franchise Area Groups',
        url: '/areaGroup?page=1&pageSize=10',
        activeTab: 'areaGroup',
        itemId: 1,
        roles: ['super_admin'],
        feature: PermissionFeatures.WEB_PAGES,
        action: PermissionActions.AREA_GROUPS,
        isPermit: getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.AREA_GROUPS),
      },
      {
        name: 'Teams',
        url: `/teams?page=1&pageSize=${config.pageSize}`,
        roles: ['admin', 'gm'],
        activeTab: 'teams',
        itemId: 1,
        feature: PermissionFeatures.WEB_PAGES,
        action: PermissionActions.OFFICE_LOCATIONS_VIEW,
        isPermit: getPermissionValue(
          PermissionFeatures.WEB_PAGES,
          PermissionActions.OFFICE_LOCATIONS_VIEW
        ),
      },
    ],
  },
  {
    name: 'Lead Sources & Distribution',
    url: '/roles',
    iconsSmall: RoleManagemt,
    iconsLarge: RoleManagemt,
    id: 2,
    icon: 'fa fa-plus',
    roles: ['super_admin', 'admin'],
    activeTab: 'addRoles',
    isShow:
      getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.AREA_GROUPS_PAGE_VIEW) ||
      getPermissionValue(
        PermissionFeatures.WEB_PAGES,
        PermissionActions.AIRA_MANAGEMENT_PAGE_VIEW
      ) ||
      getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.AGENT_MANAGEMENT) ||
      getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.CAMPAIGN_MANAGEMENT) ||
      getPermissionValue(
        PermissionFeatures.WEB_PAGES,
        PermissionActions.CM_LEAD_IMPORT_PAGE_VIEW
      ) ||
      getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.RULE_ID),
    children: [
      {
        name: 'Advisor - Wanted Leads',
        url: `/airaManagement?page=1&pageSize=${config.pageSize}`,
        activeTab: 'airaManagement',
        itemId: 2,
        roles: ['super_admin', 'admin'],
        feature: PermissionFeatures.WEB_PAGES,
        action: PermissionActions.AIRA_MANAGEMENT_PAGE_VIEW,
        isPermit: getPermissionValue(
          PermissionFeatures.WEB_PAGES,
          PermissionActions.AIRA_MANAGEMENT_PAGE_VIEW
        ),
      },
      {
        name: 'Advisor - Buy/Rent Leads',
        url: `/areasGroup?page=1&pageSize=${config.pageSize}`,
        roles: ['super_admin', 'admin'],
        feature: PermissionFeatures.WEB_PAGES,
        action: PermissionActions.AREA_GROUPS_PAGE_VIEW,
        activeTab: 'areasGroup',
        itemId: 2,
        isPermit: getPermissionValue(
          PermissionFeatures.WEB_PAGES,
          PermissionActions.AREA_GROUPS_PAGE_VIEW
        ),
      },
      {
        name: 'Advisor - Project Leads ',
        url: `/agentsManagement?page=1&pageSize=${config.pageSize}`,
        activeTab: 'agentsManagement',
        itemId: 2,
        roles: ['super_admin', 'admin'],
        feature: PermissionFeatures.WEB_PAGES,
        action: PermissionActions.AGENT_MANAGEMENT,
        isPermit: getPermissionValue(
          PermissionFeatures.WEB_PAGES,
          PermissionActions.AGENT_MANAGEMENT
        ),
      },
      {
        name: 'Facebook Campaigns',
        url: `/campaignManagement?page=1&pageSize=${config.pageSize}`,
        activeTab: 'campaignManagement',
        itemId: 2,
        roles: ['super_admin', 'admin'],
        feature: PermissionFeatures.WEB_PAGES,
        action: PermissionActions.CAMPAIGN_MANAGEMENT,
        isPermit: getPermissionValue(
          PermissionFeatures.WEB_PAGES,
          PermissionActions.CAMPAIGN_MANAGEMENT
        ),
      },
      {
        name: 'Project Lead Rules',
        url: '/leadSource',
        activeTab: 'leadSource',
        itemId: 2,
        roles: ['super_admin', 'admin'],
        feature: PermissionFeatures.WEB_PAGES,
        action: PermissionActions.RULE_ID,
        isPermit: getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.RULE_ID),
      },
      {
        name: 'Import Project Leads',
        url: '/cmlead',
        activeTab: 'cmlead',
        itemId: 2,
        roles: ['sub_admin 4', 'super_admin'],
        feature: PermissionFeatures.WEB_PAGES,
        action: PermissionActions.CM_LEAD_IMPORT_PAGE_VIEW,
        isPermit: getPermissionValue(
          PermissionFeatures.WEB_PAGES,
          PermissionActions.CM_LEAD_IMPORT_PAGE_VIEW
        ),
      },
    ],
  },
  {
    name: 'Clients',
    url: '/clientManagement',
    iconsSmall: ClientManagementIcon,
    iconsLarge: UserLarge,
    id: 22,
    icon: 'fa fa-gear',
    roles: [
      'super_admin',
      'admin',
      'admin 0',
      'admin 1',
      'admin 2',
      'admin 3',
      'sub_admin 1',
      'sub_admin 2',
    ],
    activeTab: 'clientManagement',
    isShow: getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.CLIENTS_PAGE_VIEW),
    children: [
      {
        name: 'Clients',
        url: '/clientManagement',
        roles: [
          'super_admin',
          'admin',
          'admin 0',
          'admin 1',
          'admin 2',
          'admin 3',
          'sub_admin 1',
          'sub_admin 2',
        ],
        feature: PermissionFeatures.WEB_PAGES,
        action: PermissionActions.CLIENTS_PAGE_VIEW,
        activeTab: 'clientManagement',
        itemId: 22,
        isPermit: getPermissionValue(
          PermissionFeatures.WEB_PAGES,
          PermissionActions.CLIENTS_PAGE_VIEW
        ),
      },
    ],
  },
  {
    name: 'Project Leads',
    url: `/project-lead?page=1&pageSize=${config.pageSize}`,
    iconsSmall: projectleadIconsm,
    iconsLarge: projectleadIconsm,
    id: 16,
    icon: 'fa fa-plus',
    roles: ['super_admin', 'admin'],
    feature: PermissionFeatures.WEB_PAGES,
    action: PermissionActions.USER_PAGE_VIEW,
    isShow:
      getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.PROJECT_LEADS_PAGE_VIEW) ||
      getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.MY_DEALS_PROJECT),
    children: [
      {
        name: 'Leads',
        url: `/project-lead?page=1&pageSize=${config.pageSize}`,
        iconsSmall: projectleadIconsm,
        iconsLarge: projectleadIconsm,
        id: 20,
        icon: 'fa fa-plus',
        roles: ['super_admin', 'admin'],
        feature: PermissionFeatures.WEB_PAGES,
        action: PermissionActions.USER_PAGE_VIEW,
        isShow: getPermissionValue(
          PermissionFeatures.WEB_PAGES,
          PermissionActions.PROJECT_LEADS_PAGE_VIEW
        ),
        children: [
          {
            name: 'All Leads',
            url: `/project-lead?page=1&pageSize=${config.pageSize}`,
            activeTab: 'project-lead',
            itemId: 20,
            roles: ['super_admin', 'admin'],
            feature: PermissionFeatures.WEB_PAGES,
            action: PermissionActions.PROJECT_LEADS_PAGE_VIEW,
            resetQuickFilter: true,
            isPermit: getPermissionValue(
              PermissionFeatures.WEB_PAGES,
              PermissionActions.PROJECT_LEADS_PAGE_VIEW
            ),
          },
          {
            name: 'My Leads',
            url: `/project-my-lead?page=1&pageSize=${config.pageSize}`,
            activeTab: 'project-my-lead',
            itemId: 20,
            roles: ['super_admin', 'admin'],
            feature: PermissionFeatures.WEB_PAGES,
            action: PermissionActions.PROJECT_LEADS_PAGE_VIEW,
            resetQuickFilter: true,
            isPermit: getPermissionValue(
              PermissionFeatures.WEB_PAGES,
              PermissionActions.PROJECT_LEADS_PAGE_VIEW
            ),
          },
          {
            name: 'Company Leads',
            url: `/project-company-lead?page=1&pageSize=${config.pageSize}`,
            activeTab: 'project-company-lead',
            itemId: 20,
            roles: ['super_admin', 'admin'],
            feature: PermissionFeatures.WEB_PAGES,
            action: PermissionActions.PROJECT_LEADS_PAGE_VIEW,
            resetQuickFilter: true,
            isPermit: getPermissionValue(
              PermissionFeatures.WEB_PAGES,
              PermissionActions.PROJECT_LEADS_PAGE_VIEW
            ),
          },
          {
            name: 'Referred By Me',
            url: `${ReferedProjectLeadUrl}?page=1&pageSize=${config.pageSize}`,
            activeTab: 'referred-project-lead',
            itemId: 20,
            roles: ['super_admin', 'admin'],
            feature: PermissionFeatures.WEB_PAGES,
            action: PermissionActions.PROJECT_LEADS_PAGE_VIEW,
            resetQuickFilter: true,
            isPermit: getPermissionValue(
              PermissionFeatures.WEB_PAGES,
              PermissionActions.PROJECT_LEADS_PAGE_VIEW
            ),
          },
          {
            name: 'Deactivated Leads',
            url: `/project-deactivated-lead?page=1&pageSize=${config.pageSize}`,
            activeTab: 'project-deactivated-lead',
            itemId: 20,
            roles: ['super_admin', 'admin'],
            feature: PermissionFeatures.WEB_PAGES,
            action: PermissionActions.PROJECT_LEADS_PAGE_VIEW,
            resetQuickFilter: true,
            isPermit: getPermissionValue(
              PermissionFeatures.WEB_PAGES,
              PermissionActions.PROJECT_LEADS_PAGE_VIEW
            ),
          },
          {
            name: "Team's Leads",
            url: `${TeamProjectLeadUrl}?page=1&pageSize=${config.pageSize}`,
            activeTab: 'team-project-lead',
            itemId: 20,
            roles: ['super_admin', 'admin'],
            feature: PermissionFeatures.WEB_PAGES,
            resetQuickFilter: true,
            action: PermissionActions.PROJECT_LEADS_PAGE_VIEW,
            isPermit: getPermissionValue(
              PermissionFeatures.WEB_PAGES,
              PermissionActions.PROJECT_LEADS_PAGE_VIEW
            ),
            hideForTerminal: true,
          },
        ],
      },
      {
        name: 'Deals',
        url: `/project-deal?page=1&pageSize=${config.pageSize}`,
        iconsSmall: projectleadIconsm,
        iconsLarge: projectleadIconsm,
        id: 21,
        icon: 'fa fa-plus',
        roles: ['super_admin', 'admin'],
        feature: PermissionFeatures.WEB_PAGES,
        action: PermissionActions.USER_PAGE_VIEW,
        isShow: getPermissionValue(
          PermissionFeatures.WEB_PAGES,
          PermissionActions.MY_DEALS_PROJECT
        ),
        children: [
          {
            name: 'My Deals',
            url: `/project-deal?page=1&pageSize=${config.pageSize}`,
            activeTab: 'project-deal',
            itemId: 21,
            roles: ['super_admin', 'admin'],
            feature: PermissionFeatures.WEB_PAGES,
            action: PermissionActions.MY_DEALS_PROJECT,
            resetQuickFilter: true,
            isPermit: getPermissionValue(
              PermissionFeatures.WEB_PAGES,
              PermissionActions.MY_DEALS_PROJECT
            ),
          },
          {
            name: 'Referred By Me',
            url: `${ReferedProjectDealUrl}?page=1&pageSize=${config.pageSize}`,
            activeTab: 'referred-project-deal',
            itemId: 21,
            roles: ['super_admin', 'admin'],
            feature: PermissionFeatures.WEB_PAGES,
            action: PermissionActions.PROJECT_LEADS_PAGE_VIEW,
            resetQuickFilter: true,
            isPermit: getPermissionValue(
              PermissionFeatures.WEB_PAGES,
              PermissionActions.PROJECT_LEADS_PAGE_VIEW
            ),
          },
          {
            name: 'Deactivated Deals',
            url: `/project-deactivated-deal?page=1&pageSize=${config.pageSize}`,
            activeTab: 'project-deactivated-deal',
            itemId: 21,
            roles: ['super_admin', 'admin'],
            feature: PermissionFeatures.WEB_PAGES,
            action: PermissionActions.PROJECT_LEADS_PAGE_VIEW,
            resetQuickFilter: true,
            isPermit: getPermissionValue(
              PermissionFeatures.WEB_PAGES,
              PermissionActions.PROJECT_LEADS_PAGE_VIEW
            ),
          },
          {
            name: "Team's Deals",
            url: `${TeamProjectDealUrl}?page=1&pageSize=${config.pageSize}`,
            activeTab: 'team-project-deal',
            itemId: 21,
            roles: ['super_admin', 'admin'],
            feature: PermissionFeatures.WEB_PAGES,
            action: PermissionActions.MY_DEALS_PROJECT,
            isPermit: getPermissionValue(
              PermissionFeatures.WEB_PAGES,
              PermissionActions.MY_DEALS_PROJECT
            ),
            hideForTerminal: true,
            resetQuickFilter: true,
          },
        ],
      },
    ],
  },
  //diary
  {
    name: 'Diary',
    iconsSmall: diaryIconsm,
    iconsLarge: diaryIconsm,
    id: 13,
    icon: 'fa fa-plus',
    roles: ['super_admin', 'admin'],
    url: '/diary',
    feature: PermissionFeatures.WEB_PAGES,
    action: PermissionActions.USER_PAGE_VIEW,
    isShow: getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.DIARY_PAGE_VIEW),
    children: [
      {
        name: 'Diary',
        url: '/diary',
        activeTab: 'diary',
        itemId: 13,
        roles: ['super_admin', 'admin'],
        feature: PermissionFeatures.WEB_PAGES,
        action: PermissionActions.DIARY_PAGE_VIEW,
        isPermit: getPermissionValue(
          PermissionFeatures.WEB_PAGES,
          PermissionActions.DIARY_PAGE_VIEW
        ),
      },
    ],
  },
  {
    name: 'Project Inventory',
    url:
      firstProject !== null
        ? `/available-inventory?projectId=${
            firstProject && firstProject[0] && firstProject[0].length === 0 ? 0 : firstProject[0].id
          }&projectName=${
            firstProject && firstProject[0].length === 0 ? 'amazon' : firstProject[0].name
          }&floorId=${
            firstProject && firstProject[0] && firstProject[0].floors.length === 0
              ? 0
              : firstProject && firstProject[0] && firstProject[0].floors[0].id
          }`
        : '/available-inventory',
    iconsSmall: Projects,
    iconsLarge: ProjectLarge,
    id: 6,
    icon: 'fa fa-plus',
    roles: [
      'super_admin',
      'admin 0',
      'admin 1',
      'admin 2',
      'admin 3',
      'sub_admin 1',
      'sub_admin 2',
      'sub_admin 3',
    ],
    isShow:
      getPermissionValue(
        PermissionFeatures.WEB_PAGES,
        PermissionActions.PROJECT_INVENTORY_PAGE_VIEW
      ) ||
      getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.PROJECTS_PAGE_VIEW) ||
      getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.FLOOR_PAGE_VIEW) ||
      getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.UNIT_PAGE_VIEW) ||
      getPermissionValue(
        PermissionFeatures.WEB_PAGES,
        PermissionActions.INVESTMENT_PRODUCTS_PAGE_VIEW
      ),
    children: [
      {
        name: 'Projects',
        url: '/projectmain',
        activeTab: 'projectmain',
        itemId: 6,
        roles: ['sub_admin 3', 'super_admin'],
        feature: PermissionFeatures.WEB_PAGES,
        action: PermissionActions.PROJECTS_PAGE_VIEW,
        isPermit: getPermissionValue(
          PermissionFeatures.WEB_PAGES,
          PermissionActions.PROJECTS_PAGE_VIEW
        ),
      },
      {
        name: 'Floors',
        // url: `/floors?projectId=${firstProject && firstProject[0].id}&name=${
        //   firstProject && firstProject[0].name
        // }`,
        url: firstProject
          ? `/floor?projectId=${firstProject && firstProject[0].id}&projectName=${
              firstProject && firstProject[0].name
            }`
          : '/floor',
        activeTab: 'floor',
        itemId: 6,
        roles: ['sub_admin 3', 'super_admin'],
        feature: PermissionFeatures.WEB_PAGES,
        action: PermissionActions.FLOOR_PAGE_VIEW,
        isPermit: getPermissionValue(
          PermissionFeatures.WEB_PAGES,
          PermissionActions.FLOOR_PAGE_VIEW
        ),
      },
      /*{
        name: 'Units',
        url:
          firstProject !== null
            ? `/units?projectId=${
                firstProject && firstProject[0] && firstProject[0].length === 0
                  ? 0
                  : firstProject[0].id
              }&projectName=${
                firstProject && firstProject[0].length === 0 ? 'amazon' : firstProject[0].name
              }&floorId=${
                firstProject && firstProject[0] && firstProject[0].floors.length === 0
                  ? 0
                  : firstProject && firstProject[0] && firstProject[0].floors[0].id
              }`
            : '/units',
        activeTab: 'units',
        expanded: 'Units',
        itemId: 6,
        roles: ['sub_admin 3', 'super_admin'],
        feature: PermissionFeatures.WEB_PAGES,
        action: PermissionActions.UNIT_PAGE_VIEW,
        isPermit: getPermissionValue(
          PermissionFeatures.WEB_PAGES,
          PermissionActions.UNIT_PAGE_VIEW
        ),
      },
      {
        name: 'Investment Products',
        url: `/investmentProducts?page=1&pageSize=${config.pageSize}`,
        activeTab: 'investmentProducts',
        itemId: 6,
        roles: ['sub_admin 3', 'super_admin'],
        feature: PermissionFeatures.WEB_PAGES,
        action: PermissionActions.INVESTMENT_PRODUCTS_PAGE_VIEW,
        isPermit: getPermissionValue(
          PermissionFeatures.WEB_PAGES,
          PermissionActions.INVESTMENT_PRODUCTS_PAGE_VIEW
        ),
      },
      {
        name: 'Projects Inventory',
        url:
          firstProject !== null
            ? `/available-inventory?projectId=${
                firstProject && firstProject[0] && firstProject[0].length === 0
                  ? 0
                  : firstProject[0].id
              }&projectName=${
                firstProject && firstProject[0].length === 0 ? 'amazon' : firstProject[0].name
              }&floorId=${
                firstProject && firstProject[0] && firstProject[0].floors.length === 0
                  ? 0
                  : firstProject && firstProject[0] && firstProject[0].floors[0].id
              }`
            : '/available-inventory',
        activeTab: 'available-inventory',
        itemId: 6,
        roles: [
          'super_admin',
          'admin 0',
          'admin 1',
          'admin 2',
          'admin 3',
          'sub_admin 1',
          'sub_admin 2',
        ],
        feature: PermissionFeatures.WEB_PAGES,
        action: PermissionActions.PROJECT_INVENTORY_PAGE_VIEW,
        isPermit: getPermissionValue(
          PermissionFeatures.WEB_PAGES,
          PermissionActions.PROJECT_INVENTORY_PAGE_VIEW
        ),
      },*/
    ],
  },
  {
    name: 'Wanted Leads',
    url: '',
    iconsSmall: Leads,
    iconsLarge: LeadsLarge,
    id: 14,
    icon: 'fa fa-plus',
    roles: ['super_admin', 'admin'],
    feature: PermissionFeatures.WEB_PAGES,
    action: PermissionActions.USER_PAGE_VIEW,
    isShow: getPermissionValue(
      PermissionFeatures.WEB_PAGES,
      PermissionActions.WANTED_LEADS_PAGE_VIEW
    ),
    children: [
      {
        name: 'My Leads',
        url: '/wanted',
        activeTab: 'wanted',
        itemId: 14,
        roles: ['super_admin', 'admin'],
        feature: PermissionFeatures.WEB_PAGES,
        action: PermissionActions.WANTED_LEADS_PAGE_VIEW,
        isPermit: getPermissionValue(
          PermissionFeatures.WEB_PAGES,
          PermissionActions.WANTED_LEADS_PAGE_VIEW
        ),
      },
      {
        name: 'My Qualified Leads',
        url: WantedQualifiedLeadUrl,
        activeTab: WantedQualifiedLeadUrl.replace('/', ''),
        itemId: 14,
        roles: ['super_admin', 'admin'],
        feature: PermissionFeatures.WEB_PAGES,
        action: PermissionActions.WANTED_LEADS_PAGE_VIEW,
        isPermit: getPermissionValue(
          PermissionFeatures.WEB_PAGES,
          PermissionActions.WANTED_LEADS_PAGE_VIEW
        ),
      },
      {
        name: "Team's Leads",
        url: WantedTeamLeadUrl,
        activeTab: WantedTeamLeadUrl.replace('/', ''),
        itemId: 14,
        roles: ['super_admin', 'admin'],
        feature: PermissionFeatures.WEB_PAGES,
        action: PermissionActions.WANTED_LEADS_PAGE_VIEW,
        isPermit: getPermissionValue(
          PermissionFeatures.WEB_PAGES,
          PermissionActions.WANTED_LEADS_PAGE_VIEW
        ),
        hideForTerminal: true,
      },
    ],
  },
  {
    name: 'Buy/Rent Leads',
    url: `/buy-rent-lead?page=1&pageSize=${config.pageSize}`,
    iconsSmall: buyrentIconsm,
    iconsLarge: buyrentIconsm,
    id: 15,
    icon: 'fa fa-plus',
    roles: ['super_admin', 'admin'],
    feature: PermissionFeatures.WEB_PAGES,
    action: PermissionActions.USER_PAGE_VIEW,
    isShow:
      getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.BUYRENT_LEADS_PAGE_VIEW) ||
      getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.MY_DEALS_BUY_RENT),
    children: [
      {
        name: 'Leads',
        url: `/buy-rent-lead?page=1&pageSize=${config.pageSize}`,
        iconsSmall: buyrentIconsm,
        iconsLarge: buyrentIconsm,
        id: 18,
        icon: 'fa fa-plus',
        roles: ['super_admin', 'admin'],
        feature: PermissionFeatures.WEB_PAGES,
        action: PermissionActions.BUYRENT_LEADS_PAGE_VIEW,
        isShow: getPermissionValue(
          PermissionFeatures.WEB_PAGES,
          PermissionActions.BUYRENT_LEADS_PAGE_VIEW
        ),
        children: [
          {
            name: 'All Leads',
            url: `/buy-rent-lead?page=1&pageSize=${config.pageSize}`,
            activeTab: 'buy-rent-lead',
            itemId: 18,
            resetQuickFilter: true,
            roles: ['super_admin', 'admin'],
            feature: PermissionFeatures.WEB_PAGES,
            action: PermissionActions.BUYRENT_LEADS_PAGE_VIEW,
          },
          {
            name: 'My Leads',
            url: `/buy-rent-my-lead?page=1&pageSize=${config.pageSize}`,
            activeTab: 'buy-rent-my-lead',
            itemId: 18,
            resetQuickFilter: true,
            roles: ['super_admin', 'admin'],
            feature: PermissionFeatures.WEB_PAGES,
            action: PermissionActions.BUYRENT_LEADS_PAGE_VIEW,
            isPermit: getPermissionValue(
              PermissionFeatures.WEB_PAGES,
              PermissionActions.BUYRENT_LEADS_PAGE_VIEW
            ),
          },
          {
            name: 'Company Leads',
            url: `/buy-rent-company-lead?page=1&pageSize=${config.pageSize}`,
            activeTab: 'buy-rent-company-lead',
            itemId: 18,
            resetQuickFilter: true,
            roles: ['super_admin', 'admin'],
            feature: PermissionFeatures.WEB_PAGES,
            action: PermissionActions.BUYRENT_LEADS_PAGE_VIEW,
            isPermit: getPermissionValue(
              PermissionFeatures.WEB_PAGES,
              PermissionActions.BUYRENT_LEADS_PAGE_VIEW
            ),
          },
          {
            name: 'My Demand Leads',
            url: `${BuyRentDemandLeadUrl}?page=1&pageSize=${config.pageSize}`,
            activeTab: BuyRentDemandLeadUrl.replace('/', ''),
            itemId: 18,
            roles: ['super_admin', 'admin'],
            resetQuickFilter: true,
            feature: PermissionFeatures.WEB_PAGES,
            action: PermissionActions.BUYRENT_LEADS_PAGE_VIEW,
            isPermit: getPermissionValue(
              PermissionFeatures.WEB_PAGES,
              PermissionActions.BUYRENT_LEADS_PAGE_VIEW
            ),
            REAOnly: true,
          },
          {
            name: 'Referred By Me',
            url: `${ReferedBuyRentLeadUrl}?page=1&pageSize=${config.pageSize}`,
            activeTab: ReferedBuyRentLeadUrl.replace('/', ''),
            itemId: 18,
            roles: ['super_admin', 'admin'],
            resetQuickFilter: true,
            feature: PermissionFeatures.WEB_PAGES,
            action: PermissionActions.BUYRENT_LEADS_PAGE_VIEW,
            isPermit: getPermissionValue(
              PermissionFeatures.WEB_PAGES,
              PermissionActions.BUYRENT_LEADS_PAGE_VIEW
            ),
          },
          {
            name: 'Deactivated Leads',
            url: `/buy-rent-deactivated-lead?page=1&pageSize=${config.pageSize}`,
            activeTab: 'buy-rent-deactivated-lead',
            itemId: 18,
            resetQuickFilter: true,
            roles: ['super_admin', 'admin'],
            feature: PermissionFeatures.WEB_PAGES,
            action: PermissionActions.BUYRENT_LEADS_PAGE_VIEW,
            isPermit: getPermissionValue(
              PermissionFeatures.WEB_PAGES,
              PermissionActions.BUYRENT_LEADS_PAGE_VIEW
            ),
          },
          {
            name: "Team's Leads",
            url: `${TeamBuyRentLeadUrl}?page=1&pageSize=${config.pageSize}`,
            activeTab: TeamBuyRentLeadUrl.replace('/', ''),
            itemId: 18,
            roles: ['super_admin', 'admin'],
            resetQuickFilter: true,
            feature: PermissionFeatures.WEB_PAGES,
            action: PermissionActions.BUYRENT_LEADS_PAGE_VIEW,
            isPermit: getPermissionValue(
              PermissionFeatures.WEB_PAGES,
              PermissionActions.BUYRENT_LEADS_PAGE_VIEW
            ),
            hideForTerminal: true,
          },
        ],
      },
      {
        name: 'Deals',
        url: `/buy-rent-deal?page=1&pageSize=${config.pageSize}`,
        iconsSmall: buyrentIconsm,
        iconsLarge: buyrentIconsm,
        id: 19,
        icon: 'fa fa-plus',
        roles: ['super_admin', 'admin'],
        feature: PermissionFeatures.WEB_PAGES,
        action: PermissionActions.MY_DEALS_BUY_RENT,
        isShow: getPermissionValue(
          PermissionFeatures.WEB_PAGES,
          PermissionActions.MY_DEALS_BUY_RENT
        ),
        children: [
          {
            name: 'My Deals',
            url: `/buy-rent-deal?page=1&pageSize=${config.pageSize}`,
            activeTab: 'buy-rent-deal',
            itemId: 19,
            roles: ['super_admin', 'admin'],
            resetQuickFilter: true,
            feature: PermissionFeatures.WEB_PAGES,
            action: PermissionActions.MY_DEALS_BUY_RENT,
            isPermit: getPermissionValue(
              PermissionFeatures.WEB_PAGES,
              PermissionActions.MY_DEALS_BUY_RENT
            ),
          },
          {
            name: 'Referred By Me',
            url: `${ReferedBuyRentDealUrl}?page=1&pageSize=${config.pageSize}`,
            activeTab: ReferedBuyRentDealUrl.replace('/', ''),
            itemId: 19,
            roles: ['super_admin', 'admin'],
            resetQuickFilter: true,
            feature: PermissionFeatures.WEB_PAGES,
            action: PermissionActions.BUYRENT_LEADS_PAGE_VIEW,
            isPermit: getPermissionValue(
              PermissionFeatures.WEB_PAGES,
              PermissionActions.BUYRENT_LEADS_PAGE_VIEW
            ),
          },
          {
            name: 'Deactivated Deals',
            url: `/buy-rent-deactivated-deal?page=1&pageSize=${config.pageSize}`,
            activeTab: 'buy-rent-deactivated-deal',
            itemId: 19,
            resetQuickFilter: true,
            roles: ['super_admin', 'admin'],
            feature: PermissionFeatures.WEB_PAGES,
            action: PermissionActions.BUYRENT_LEADS_PAGE_VIEW,
            isPermit: getPermissionValue(
              PermissionFeatures.WEB_PAGES,
              PermissionActions.BUYRENT_LEADS_PAGE_VIEW
            ),
          },
          {
            name: "Team's Deals",
            url: `${TeamBuyRentDealUrl}?page=1&pageSize=${config.pageSize}`,
            activeTab: TeamBuyRentDealUrl.replace('/', ''),
            itemId: 19,
            roles: ['super_admin', 'admin'],
            feature: PermissionFeatures.WEB_PAGES,
            resetQuickFilter: true,
            action: PermissionActions.BUYRENT_LEADS_PAGE_VIEW,
            isPermit: getPermissionValue(
              PermissionFeatures.WEB_PAGES,
              PermissionActions.MY_DEALS_BUY_RENT
            ),
            hideForTerminal: true,
          },
        ],
      },
    ],
  },

  /*
  {
    name: 'Diary, Leads, Deals',
    url: `${
      getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.DIARY_PAGE_VIEW)
        ? '/diary'
        : getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.WANTED_LEADS_PAGE_VIEW)
        ? '/wanted'
        : getPermissionValue(
            PermissionFeatures.WEB_PAGES,
            PermissionActions.BUYRENT_LEADS_PAGE_VIEW
          )
        ? '/buy-rent-lead?page=1&pageSize=50'
        : getPermissionValue(
            PermissionFeatures.WEB_PAGES,
            PermissionActions.PROJECT_LEADS_PAGE_VIEW
          )
        ? `${ProjectLeadUrl}?page=1&pageSize=10`
        : `${
            getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.MY_DEALS_BUY_RENT)
              ? '/buy-rent-deal?page=1&pageSize=50'
              : '/project-deal?page=1&pageSize=10'
          }`
    }`,
    iconsSmall: Leads,
    iconsLarge: LeadsLarge,
    id: 3,
    icon: 'fa fa-plus',
    roles: [
      'super_admin',
      'admin 0',
      'admin 1',
      'admin 2',
      'admin 3',
      'sub_admin 1',
      'sub_admin 2',
    ],
    isShow:
      getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.WANTED_LEADS_PAGE_VIEW) ||
      getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.BUYRENT_LEADS_PAGE_VIEW) ||
      getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.PROJECT_LEADS_PAGE_VIEW) ||
      getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.MY_DEALS_PROJECT) ||
      getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.MY_DEALS_BUY_RENT) ||
      getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.DIARY_PAGE_VIEW),
    children: [
      {
        name: 'Diary, Leads, Deals',
        url: `${
          getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.DIARY_PAGE_VIEW)
            ? '/diary'
            : getPermissionValue(
                PermissionFeatures.WEB_PAGES,
                PermissionActions.WANTED_LEADS_PAGE_VIEW
              )
            ? '/wanted'
            : getPermissionValue(
                PermissionFeatures.WEB_PAGES,
                PermissionActions.BUYRENT_LEADS_PAGE_VIEW
              )
            ? '/buy-rent-lead?page=1&pageSize=50'
            : getPermissionValue(
                PermissionFeatures.WEB_PAGES,
                PermissionActions.PROJECT_LEADS_PAGE_VIEW
              )
            ? `${ProjectLeadUrl}?page=1&pageSize=10`
            : `${
                getPermissionValue(
                  PermissionFeatures.WEB_PAGES,
                  PermissionActions.MY_DEALS_BUY_RENT
                )
                  ? '/buy-rent-deal?page=1&pageSize=50'
                  : '/project-deal?page=1&pageSize=10'
              }`
        }`,
        activeTab: 'diary',
        itemId: 3,
        roles: [
          'super_admin',
          'admin 0',
          'admin 1',
          'admin 2',
          'admin 3',
          'sub_admin 1',
          'sub_admin 2',
        ],
        isShow: true,
        // feature: PermissionFeatures.WEB_PAGES,
        // action: PermissionActions.LEADS_PAGE_VIEW,
      },
    ],
  },*/

  {
    name: 'Properties',
    url: '/propertiesView',
    iconsSmall: OrgChart,
    iconsLarge: OrgChartLarge,
    id: 5,
    icon: 'fa fa-plus',
    roles: [
      'super_admin',
      'admin 0',
      'admin 1',
      'admin 2',
      'admin 3',
      'sub_admin 1',
      'sub_admin 2',
    ],
    isShow:
      getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.GRAANA_PAGE_VIEW) ||
      getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.FIELD_APP_PAGE_VIEW) ||
      getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.ARMS_PAGE_VIEW),
    children: [
      {
        name: 'Graana Properties',
        url: '/graanaView',
        activeTab: 'graanaView',
        itemId: 5,
        roles: [
          'super_admin',
          'admin 0',
          'admin 1',
          'admin 2',
          'admin 3',
          'sub_admin 1',
          'sub_admin 2',
        ],
        feature: PermissionFeatures.WEB_PAGES,
        action: PermissionActions.GRAANA_PAGE_VIEW,
        isPermit: getPermissionValue(
          PermissionFeatures.WEB_PAGES,
          PermissionActions.GRAANA_PAGE_VIEW
        ),
      },
      {
        name: 'Field App Properties',
        url: '/fieldApp',
        activeTab: 'fieldApp',
        itemId: 5,
        roles: [
          'super_admin',
          'admin 0',
          'admin 1',
          'admin 2',
          'admin 3',
          'sub_admin 1',
          'sub_admin 2',
        ],
        feature: PermissionFeatures.WEB_PAGES,
        action: PermissionActions.FIELD_APP_PAGE_VIEW,
        isPermit: getPermissionValue(
          PermissionFeatures.WEB_PAGES,
          PermissionActions.FIELD_APP_PAGE_VIEW
        ),
      },
      {
        name: 'ARMS Properties',
        url: '/armsView',
        activeTab: 'armsView',
        itemId: 5,
        roles: [
          'super_admin',
          'admin 0',
          'admin 1',
          'admin 2',
          'admin 3',
          'sub_admin 1',
          'sub_admin 2',
        ],
        feature: PermissionFeatures.WEB_PAGES,
        action: PermissionActions.ARMS_PAGE_VIEW,
        isPermit: getPermissionValue(
          PermissionFeatures.WEB_PAGES,
          PermissionActions.ARMS_PAGE_VIEW
        ),
      },
      // {
      //   name: '',
      //   url: '/propertyDetails',
      //   activeTab: '',
      //   itemId: 16,
      //   roles: ['sub_admin 2'],
      // },
    ],
  },

  {
    name: 'Targets',
    url: '/targets?page=1',
    iconsSmall: Targets,
    iconsLarge: TargetLarge,
    id: 7,
    icon: 'fa fa-plus',
    roles: ['sub_admin 1', 'admin 3', 'sub_admin 2', 'super_admin'],
    isShow:
      getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.SET_TARGETS_PAGE_VIEW) ||
      getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.MY_TARGETS_PAGE_VIEW),
    children: [
      {
        name: 'Set Targets',
        url: `/targets?page=1&pageSize=${config.pageSize}`,
        activeTab: 'targets',
        itemId: 7,
        roles: ['sub_admin 1', 'admin 3', 'super_admin'],
        feature: PermissionFeatures.WEB_PAGES,
        action: PermissionActions.SET_TARGETS_PAGE_VIEW,
        isPermit: getPermissionValue(
          PermissionFeatures.WEB_PAGES,
          PermissionActions.SET_TARGETS_PAGE_VIEW
        ),
      },
      {
        name: 'My Targets',
        url: '/mytargets',
        activeTab: 'mytargets',
        itemId: 7,
        roles: [
          'sub_admin 2',
          'super_admin',
          'admin 0',
          'admin 1',
          'admin 2',
          'admin 3',
          'sub_admin 1',
        ],
        feature: PermissionFeatures.WEB_PAGES,
        action: PermissionActions.MY_TARGETS_PAGE_VIEW,
        isPermit: getPermissionValue(
          PermissionFeatures.WEB_PAGES,
          PermissionActions.MY_TARGETS_PAGE_VIEW
        ),
      },
    ],
  },
  {
    name: 'Morning Meeting',
    id: 8,
    url: '/morningView',
    iconsSmall: morningView,
    iconsLarge: morningView,
    roles: ['sub_admin 1'],
    isShow: getPermissionValue(
      PermissionFeatures.WEB_PAGES,
      PermissionActions.MORNING_MEETINGS_PAGE_VIEW
    ),
    children: [
      {
        name: 'Morning Meeting',
        url: '/morningView',
        activeTab: 'morningView',
        roles: ['sub_admin 1', 'super_admin'],
        itemId: 8,
        feature: PermissionFeatures.WEB_PAGES,
        action: PermissionActions.MORNING_MEETINGS_PAGE_VIEW,
        isPermit: getPermissionValue(
          PermissionFeatures.WEB_PAGES,
          PermissionActions.MORNING_MEETINGS_PAGE_VIEW
        ),
      },
    ],
  },


  {
    name: 'Internal Inventory',
    url: '/internal-saleable-inventories',
    iconsSmall: PowerBIICon,
    iconsLarge: PowerBIICon,
    id: 634634677777,
    icon: 'fa fa-warehouse',
    roles: ['sub_admin 8', 'super_admin'],
    isShow: true,
    children: [
      {
        name: 'Saleable Inventory',
        url: '/internal-saleable-inventories',
        iconsSmall: PowerBIICon,
        iconsLarge: PowerBIICon,
        id: 8658568,
        icon: 'fa fa-warehouse',
        roles: ['sub_admin 8', 'super_admin'],
        isShow:
          getPermissionValue(
            PermissionFeatures.WEB_PAGES,
            PermissionActions.PROJECT_PAYMENTS_PAGE_VIEW
          ) ||
          getPermissionValue(
            PermissionFeatures.WEB_PAGES,
            PermissionActions.GAP_PAYMENTS_PAGE_VIEW
          ) ||
          getPermissionValue(
            PermissionFeatures.WEB_PAGES,
            PermissionActions.BUY_RENT_PAYMENTS_PAGE_VIEW
          ) ||
          getPermissionValue(
            PermissionFeatures.WEB_PAGES,
            PermissionActions.RENTAL_DASHBOARD_PAGE_VIEW
          ) ||
          getPermissionValue(PermissionFeatures.PROJECT_LEADS, PermissionActions.UPDATE) ||
          getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.PROJECTS_PAGE_VIEW) ||
          getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.FLOOR_PAGE_VIEW) ||
          getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.UNIT_PAGE_VIEW) ||
          getPermissionValue(
            PermissionFeatures.WEB_PAGES,
            PermissionActions.INVESTMENT_PRODUCTS_PAGE_VIEW
          ),
        children: internal_saleable_inventories,
      },
      {
        name: 'Booking',
        url: '/in-progress-booking',
        iconsSmall: PowerBIICon,
        iconsLarge: PowerBIICon,
        id: 234344990,
        icon: 'fa fa-bars-progress',
        roles: ['sub_admin 8', 'super_admin'],
        isShow:
          getPermissionValue(
            PermissionFeatures.WEB_PAGES,
            PermissionActions.PROJECT_PAYMENTS_PAGE_VIEW
          ) ||
          getPermissionValue(
            PermissionFeatures.WEB_PAGES,
            PermissionActions.GAP_PAYMENTS_PAGE_VIEW
          ) ||
          getPermissionValue(
            PermissionFeatures.WEB_PAGES,
            PermissionActions.BUY_RENT_PAYMENTS_PAGE_VIEW
          ) ||
          getPermissionValue(
            PermissionFeatures.WEB_PAGES,
            PermissionActions.RENTAL_DASHBOARD_PAGE_VIEW
          ) ||
          getPermissionValue(PermissionFeatures.PROJECT_LEADS, PermissionActions.UPDATE),
        children: internal_in_progress_booking,
      },
    ],
  },

  {
    name: 'External Inventory',
    url: '/external-saleable-inventories',
    iconsSmall: PowerBIICon,
    iconsLarge: PowerBIICon,
    id: 634634666666,
    icon: 'fa fa-warehouse',
    roles: ['sub_admin 8', 'super_admin'],
    isShow: true,
    children: [
      {
        name: 'Saleable Inventory',
        url: '/external-saleable-inventories',
        iconsSmall: PowerBIICon,
        iconsLarge: PowerBIICon,
        id: 6346346,
        icon: 'fa fa-warehouse',
        roles: ['sub_admin 8', 'super_admin'],
        isShow:
          getPermissionValue(
            PermissionFeatures.WEB_PAGES,
            PermissionActions.PROJECT_PAYMENTS_PAGE_VIEW
          ) ||
          getPermissionValue(
            PermissionFeatures.WEB_PAGES,
            PermissionActions.GAP_PAYMENTS_PAGE_VIEW
          ) ||
          getPermissionValue(
            PermissionFeatures.WEB_PAGES,
            PermissionActions.BUY_RENT_PAYMENTS_PAGE_VIEW
          ) ||
          getPermissionValue(
            PermissionFeatures.WEB_PAGES,
            PermissionActions.RENTAL_DASHBOARD_PAGE_VIEW
          ) ||
          getPermissionValue(PermissionFeatures.PROJECT_LEADS, PermissionActions.UPDATE) ||
          getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.PROJECTS_PAGE_VIEW) ||
          getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.FLOOR_PAGE_VIEW) ||
          getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.UNIT_PAGE_VIEW) ||
          getPermissionValue(
            PermissionFeatures.WEB_PAGES,
            PermissionActions.INVESTMENT_PRODUCTS_PAGE_VIEW
          ),
        children: external_saleable_inventories,
      },
      {
        name: 'Booking',
        url: '/in-progress-booking?productType=external',
        iconsSmall: PowerBIICon,
        iconsLarge: PowerBIICon,
        id: 23434456,
        icon: 'fa fa-bars-progress',
        roles: ['sub_admin 8', 'super_admin'],
        isShow:
          getPermissionValue(
            PermissionFeatures.WEB_PAGES,
            PermissionActions.PROJECT_PAYMENTS_PAGE_VIEW
          ) ||
          getPermissionValue(
            PermissionFeatures.WEB_PAGES,
            PermissionActions.GAP_PAYMENTS_PAGE_VIEW
          ) ||
          getPermissionValue(
            PermissionFeatures.WEB_PAGES,
            PermissionActions.BUY_RENT_PAYMENTS_PAGE_VIEW
          ) ||
          getPermissionValue(
            PermissionFeatures.WEB_PAGES,
            PermissionActions.RENTAL_DASHBOARD_PAGE_VIEW
          ) ||
          getPermissionValue(PermissionFeatures.PROJECT_LEADS, PermissionActions.UPDATE),
        children: external_in_progress_booking,
      },
    ],
  },

  {
    name: 'Buy/Rent Payments',
    url: `/buyrentsales?page=1&pageSize=${config.pageSize}`,
    id: 10,
    iconsSmall: Sales,
    iconsLarge: SalesLarge,
    icon: 'fa fa-plus',
    roles: ['super_admin', 'sub_admin 5', 'sub_admin 6'],
    isShow:
      getPermissionValue(
        PermissionFeatures.WEB_PAGES,
        PermissionActions.PROJECT_PAYMENTS_PAGE_VIEW
      ) ||
      getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.GAP_PAYMENTS_PAGE_VIEW) ||
      getPermissionValue(
        PermissionFeatures.WEB_PAGES,
        PermissionActions.BUY_RENT_PAYMENTS_PAGE_VIEW
      ) ||
      getPermissionValue(
        PermissionFeatures.WEB_PAGES,
        PermissionActions.RENTAL_DASHBOARD_PAGE_VIEW
      ),
    children: [
      // {
      //   name: 'Project Payments',
      //   url: `/sales?page=1&pageSize=${config.pageSize}`,
      //   activeTab: 'sales',
      //   itemId: 10,
      //   roles: ['super_admin', 'sub_admin 5', 'sub_admin 6'],
      //   feature: PermissionFeatures.WEB_PAGES,
      //   action: PermissionActions.PROJECT_PAYMENTS_PAGE_VIEW,
      //   isPermit: getPermissionValue(
      //     PermissionFeatures.WEB_PAGES,
      //     PermissionActions.PROJECT_PAYMENTS_PAGE_VIEW
      //   ),
      // },
      {
        name: 'Buy/Rent Payments',
        url: `/buyrentsales?page=1&pageSize=${config.pageSize}`,
        activeTab: 'buyrentsales',
        itemId: 10,
        roles: ['super_admin', 'sub_admin 5', 'sub_admin 6'],
        feature: PermissionFeatures.WEB_PAGES,
        action: PermissionActions.BUY_RENT_PAYMENTS_PAGE_VIEW,
        isPermit: getPermissionValue(
          PermissionFeatures.WEB_PAGES,
          PermissionActions.BUY_RENT_PAYMENTS_PAGE_VIEW
        ),
      },
      {
        name: 'Rental Dashboard',
        url: `/rentaldashboard?page=1&pageSize=${config.pageSize}`,
        activeTab: 'rentaldashboard',
        itemId: 10,
        roles: [
          'sub_admin 2',
          'super_admin',
          'admin 0',
          'admin 1',
          'admin 2',
          'admin 3',
          'sub_admin 1',
        ],
        feature: PermissionFeatures.WEB_PAGES,
        action: PermissionActions.RENTAL_DASHBOARD_PAGE_VIEW,
        isPermit: getPermissionValue(
          PermissionFeatures.WEB_PAGES,
          PermissionActions.RENTAL_DASHBOARD_PAGE_VIEW
        ),
      },
      // {
      //   name: 'Booking Unit',
      //   url: `/bookingform?page=1&pageSize=${config.pageSize}`,
      //   activeTab: 'bookingform',
      //   itemId: 10,
      //   roles: [
      //     'sub_admin 2',
      //     'super_admin',
      //     'admin 0',
      //     'admin 1',
      //     'admin 2',
      //     'admin 3',
      //     'sub_admin 1',
      //   ],
      //   feature: PermissionFeatures.WEB_PAGES,
      //   action: PermissionActions.BOOKING_FORM,
      //   isPermit: getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.BOOKING_FORM),
      // },
    ],
  },
  {
    name: 'Propsure',
    url: '/propsure',
    iconsSmall: Propsure,
    iconsLarge: Propsure,
    id: 11,
    icon: 'fa fa-plus',
    roles: ['super_admin', 'sub_admin 7', 'sub_admin 9'],
    isShow: getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.PROPSURE_PAGE_VIEW),
    children: [
      {
        name: 'Propsure Requests',
        url: `/propsure?page=1&pageSize=${config.pageSize}`,
        activeTab: 'propsure',
        itemId: 11,
        roles: ['super_admin', 'sub_admin 7'],
        feature: PermissionFeatures.WEB_PAGES,
        action: PermissionActions.PROPSURE_PAGE_VIEW,
        isPermit: getPermissionValue(
          PermissionFeatures.WEB_PAGES,
          PermissionActions.PROPSURE_PAGE_VIEW
        ),
      },
    ],
  },
  {
    name: 'Legal',
    url: '/legalBuyRent',
    iconsSmall: Legal,
    iconsLarge: Legal,
    id: 12,
    icon: 'fa fa-plus',
    roles: ['sub_admin 8', 'super_admin'],
    isShow:
      getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.LEGAL_PAGE_VIEW) ||
      getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.TRANSFER_REQUESTS),
    children: [
      {
        name: 'Documents',
        url: `/legalBuyRent?page=1&pageSize=${config.pageSize}`,
        activeTab: 'legalBuyRent',
        itemId: 12,
        roles: ['sub_admin 8', 'super_admin'],
        feature: PermissionFeatures.WEB_PAGES,
        action: PermissionActions.LEGAL_PAGE_VIEW,
        isPermit: getPermissionValue(
          PermissionFeatures.WEB_PAGES,
          PermissionActions.LEGAL_PAGE_VIEW
        ),
      },
      {
        name: 'Transfer Requests',
        url: `/transferRequest?page=1&pageSize=${config.pageSize}`,
        activeTab: 'transferRequest',
        itemId: 12,
        roles: ['sub_admin 8', 'super_admin'],
        feature: PermissionFeatures.WEB_PAGES,
        action: PermissionActions.TRANSFER_REQUESTS,
        isPermit: getPermissionValue(
          PermissionFeatures.WEB_PAGES,
          PermissionActions.TRANSFER_REQUESTS
        ),
      },
    ],
  },
  {
    name: 'BI Dashboards',
    url: '/PowerBI',
    iconsSmall: PowerBIICon,
    iconsLarge: PowerBIICon,
    id: 17,
    icon: 'fa fa-plus',
    roles: ['sub_admin 8', 'super_admin'],
    isShow: getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.POWER_BI),
    children: powerBIChildren,
  },
  {
    name: 'Reports',
    url: '/reports',
    iconsSmall: PowerBIICon,
    iconsLarge: PowerBIICon,
    id: 2525,
    icon: 'fa fa-phone',
    roles: ['sub_admin 1'],
    // isShow: getPermissionValue(
    //   PermissionFeatures.WEB_PAGES,
    //   PermissionActions.POWER_BI
    // ),
    isShow: true,
    children: ReportsChildrens,
  },
  {
    name: 'Call center Report',
    url: '/reports/inbound-logs',
    activeTab: 'report-inbound-logs',
    id: 50505050,
    icon: 'fa fa-warehouse',
    iconsSmall: call_Icon,
    iconsLarge: call_Icon,
    roles: ['sub_admin 1', 'super_admin', 'admin'],
    feature: PermissionFeatures.WEB_PAGES,
    action: PermissionActions.DIARY_PAGE_VIEW,
    //resetQuickFilter: true,
    isShow: isTeamLead,
    isPermit:  isTeamLead ? (
      PermissionFeatures.WEB_PAGES,
        PermissionActions.DIARY_PAGE_VIEW
    ) :false ,
    children:[
      {
        name: 'Inbound Logs',
        url: '/reports/inbound-logs',
        activeTab: 'reportsinbound-logs',
        itemId: 50505050,
        roles: ['sub_admin 1', 'super_admin', 'admin'],
        feature: PermissionFeatures.WEB_PAGES,
        action: PermissionActions.DIARY_PAGE_VIEW,
        resetQuickFilter: true,
        isPermit: getPermissionValue(
          PermissionFeatures.WEB_PAGES,
          PermissionActions.DIARY_PAGE_VIEW
        ),
      },
      {
        name: 'Inbound & Outbound User Logs',
        url: '/reports/inbound-and-outbound-user-logs',
        activeTab: 'reportsinbound-and-outbound-user-logs',
        itemId: 50505050,
        roles: ['sub_admin 1', 'super_admin', 'admin'],
        feature: PermissionFeatures.WEB_PAGES,
        action: PermissionActions.DIARY_PAGE_VIEW,
        resetQuickFilter: true,
        isPermit: getPermissionValue(
          PermissionFeatures.WEB_PAGES,
          PermissionActions.DIARY_PAGE_VIEW
        ),
      },
      {
        name: 'Outbound Logs',
        url: '/reports/outbound-logs',
        activeTab: 'reportsoutbound-logs',
        itemId: 50505050,
        roles: ['sub_admin 1', 'super_admin', 'admin'],
        feature: PermissionFeatures.WEB_PAGES,
        action: PermissionActions.DIARY_PAGE_VIEW,
        resetQuickFilter: true,
        isPermit: getPermissionValue(
          PermissionFeatures.WEB_PAGES,
          PermissionActions.DIARY_PAGE_VIEW
        ),
      },

      {
        name: 'Active Agents Logs',
        url: '/reports/active-agents',
        activeTab: 'reportsactive-agents',
        itemId: 50505050,
        roles: ['sub_admin 1', 'super_admin', 'admin'],
        feature: PermissionFeatures.WEB_PAGES,
        action: PermissionActions.DIARY_PAGE_VIEW,
        resetQuickFilter: true,
        isPermit: getPermissionValue(
          PermissionFeatures.WEB_PAGES,
          PermissionActions.MORNING_MEETINGS_PAGE_VIEW
        ),
      },
      {
        name: 'Agents Performances',
        url: '/reports/agent-performances',
        activeTab: 'reportsagent-performances',
        itemId: 50505050,
        roles: ['sub_admin 1', 'super_admin', 'admin'],
        feature: PermissionFeatures.WEB_PAGES,
        action: PermissionActions.DIARY_PAGE_VIEW,
        resetQuickFilter: true,
        isPermit: getPermissionValue(
          PermissionFeatures.WEB_PAGES,
          PermissionActions.MORNING_MEETINGS_PAGE_VIEW
        ),
      },
      {
        name: 'Campaign Outage',
        url: '/campaign-outage',
        activeTab: 'campaign-outage',
        itemId: 50505050,
        roles: ['super_admin', 'admin'],
        feature: PermissionFeatures.WEB_PAGES,
        action: PermissionActions.DIARY_PAGE_VIEW,
        isPermit: getPermissionValue(
          PermissionFeatures.WEB_PAGES,
          PermissionActions.MORNING_MEETINGS_PAGE_VIEW
        ),
      },

      {
        name: 'Missed Call Logs',
        url: '/missed-call-logs',
        activeTab: 'missed-call-logs',
        itemId: 50505050,
        roles: ['super_admin', 'admin'],
        feature: PermissionFeatures.WEB_PAGES,
        action: PermissionActions.DIARY_PAGE_VIEW,
        isPermit: getPermissionValue(
          PermissionFeatures.WEB_PAGES,
          PermissionActions.MORNING_MEETINGS_PAGE_VIEW
        ),
      },
      {
        name: 'Agent Waiting Time',
        url: '/Agent-waiting-time',
        activeTab: 'Agent-waiting-time',
        itemId: 50505050,
        roles: ['super_admin', 'admin'],
        feature: PermissionFeatures.WEB_PAGES,
        action: PermissionActions.DIARY_PAGE_VIEW,
        isPermit: getPermissionValue(
          PermissionFeatures.WEB_PAGES,
          PermissionActions.MORNING_MEETINGS_PAGE_VIEW
        ),
      },
      {
        name: 'Agent Miscall logs',
        url: '/reports/miscal-agents',
        activeTab: 'miscal-agents',
        itemId: 50505050,
        roles: ['super_admin', 'admin'],
        feature: PermissionFeatures.WEB_PAGES,
        action: PermissionActions.DIARY_PAGE_VIEW,
        isPermit: getPermissionValue(
          PermissionFeatures.WEB_PAGES,
          PermissionActions.MORNING_MEETINGS_PAGE_VIEW
        ),
      },


    ]
  },


]
