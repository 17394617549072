/** @format */

import axios from 'axios'
import config from '../config'
import moment from 'moment'
import { showToastMsg } from '../utils/helper'
import _ from 'lodash'
import Swal from 'sweetalert2'
import { properties } from '../utils/constant_data'
import { fetchSingleLead } from './leads'

export const FETCH_ARMS_DATA_BEGIN = 'FETCH_ARMS_DATA_BEGIN'
export const FETCH_ARMS_DATA_SUCCESS = 'FETCH_ARMS_DATA_SUCCESS'
export const FETCH_ARMS_DATA_FAIL = 'FETCH_ARMS_DATA_FAIL'
export const FETCH_ARMS_DATA_FLAG = 'FETCH_ARMS_DATA_FLAG'
export const FETCH_FIELD_DATA_BEGIN = 'FETCH_FIELD_DATA_BEGIN'
export const FETCH_FIELD_DATA_SUCCESS = 'FETCH_FIELD_DATA_SUCCESS'
export const FETCH_FIELD_DATA_FAIL = 'FETCH_FIELD_DATA_FAIL'
export const FETCH_FIELD_DATA_FLAG = 'FETCH_FIELD_DATA_FLAG'
export const FETCH_GRAANA_DATA_BEGIN = 'FETCH_GRAANA_DATA_BEGIN'
export const FETCH_GRAANA_DATA_SUCCESS = 'FETCH_GRAANA_DATA_SUCCESS'
export const FETCH_GRAANA_DATA_FAIL = 'FETCH_GRAANA_DATA_FAIL'
export const FETCH_GRAANA_DATA_FLAG = 'FETCH_GRAANA_DATA_FLAG'
export const FETCH_SUB_PROPERTIES = 'FETCH_SUB_PROPERTIES'
export const FETCH_PROPERTY_DETAIL_BEGIN = 'FETCH_PROPERTY_DETAIL_BEGIN'
export const FETCH_PROPERTY_DETAIL_SUCCESS = 'FETCH_PROPERTY_DETAIL_SUCCESS'
export const FETCH_PROPERTY_DETAIL_FAIL = 'FETCH_PROPERTY_DETAIL_FAIL'
export const FETCH_PROPERTY_DETAIL_FLAG = 'FETCH_PROPERTY_DETAIL_FLAG'
export const FETCH_MATCH_DATA_LOADER = 'FETCH_MATCH_DATA_LOADER'
export const ADD_SHORTLISTED_PROPERTIES = 'ADD_SHORTLISTED_PROPERTIES'
export const ADD_SHORTLISTED_PROPERTIES_LOADER = 'ADD_SHORTLISTED_PROPERTIES_LOADER'
export const FETCH_MATCH_DATA = 'FETCH_MATCH_DATA'
export const FETCH_SELECTED_MATCH_PROPERTIES = 'FETCH_SELECTED_MATCH_PROPERTIES'
export const FETCH_SELECTED_SHORT_LISTED_PROPERTY = 'FETCH_SELECTED_SHORT_LISTED_PROPERTY'
export const FETCH_SELECTED_SHORT_LISTED_PROPERTY_LOADER =
  'FETCH_SELECTED_SHORT_LISTED_PROPERTY_LOADER'
export const FETCH_BUYER_DOCS_LOADER = 'FETCH_BUYER_DOCS_LOADER'
export const FETCH_SELLER_DOCS_LOADER = 'FETCH_SELLER_DOCS_LOADER'
export const FETCH_SELETED_PROPERTY_LEGAL_DOCUMENTS_BUYER =
  'FETCH_SELETED_PROPERTY_LEGAL_DOCUMENTS_BUYER'
export const FETCH_SELETED_PROPERTY_LEGAL_DOCUMENTS_SELLER =
  'FETCH_SELETED_PROPERTY_LEGAL_DOCUMENTS_SELLER'
export const SET_PROPERTY_LEGAL_DOCUMENT_LOADER = 'SET_PROPERTY_LEGAL_DOCUMENT_LOADER'
export const FETCH_PROPERTY_LEGAL_DOCUMENT_COMMENTS = 'FETCH_PROPERTY_LEGAL_DOCUMENT_COMMENTS'
export const SET_BUY_RENT_DETAILS_MODAL_TOGGLE = 'SET_BUY_RENT_DETAILS_MODAL_TOGGLE'
export const FETCH_BUY_RENT_DETAILS_MODAL_TOGGLE = 'FETCH_BUY_RENT_DETAILS_MODAL_TOGGLE'
export const FETCH_LEGAL_PAYMENT_INFO = 'FETCH_LEGAL_PAYMENT_INFO'
export const SET_BUY_RENT_ACTION = 'SET_BUY_RENT_ACTION'
export const FETCH_SHORTLISTED_COMMENT = 'FETCH_SHORTLISTED_COMMENT'
export const FETCH_SHORTLISTED_COMMENT_LOADER = 'FETCH_SHORTLISTED_COMMENT_LOADER'
export const FETCH_PROPERTIES_COUNTING = 'FETCH_PROPERTIES_COUNTING'

export const addShortlistedProperties = (lead, data) => {
  return (dispatch) => {
    dispatch({ type: ADD_SHORTLISTED_PROPERTIES_LOADER, data: true })
    axios.post(`${config.apiPath}/api/leads/${lead.id}/shortlist`, data).then((res) => {
      dispatch({ type: ADD_SHORTLISTED_PROPERTIES_LOADER, data: false })
      return dispatch({ type: ADD_SHORTLISTED_PROPERTIES, data: res })
    })
  }
}

export const setBuyRentDetailsModalToggle = (val) => {
  return (dispatch) => {
    return dispatch({ type: SET_BUY_RENT_DETAILS_MODAL_TOGGLE, data: val })
  }
}

const convertToInteger = (val) => {
  if (val === '') {
    return null
  } else if (typeof val === 'string' && val != '') {
    return parseInt(val)
  } else {
    return val
  }
}

export const updatePropertyRentDetails = (formData, selectedProperty, lead_id) => {
  let payload = Object.create({})
  if (formData && formData.type === 'rent') {
    payload = {
      advance: formData.advance ? convertToInteger(formData.advance) : null,
      contract_months: formData.contractLength ? convertToInteger(formData.contractLength) : null,
      security: formData.security ? convertToInteger(formData.security) : null,
      monthlyRent: formData.monthlyRent ? convertToInteger(formData.monthlyRent) : null,
    }
  } else {
    payload = {
      payment: formData.agreedAmount ? convertToInteger(formData.agreedAmount) : null,
      advance: formData.down_payment ? convertToInteger(formData.down_payment) : null,
    }
  }
  payload.shortlist_id = selectedProperty && selectedProperty.id
  var leadId = []
  leadId.push(lead_id)
  return (dispatch) => {
    dispatch({ type: FETCH_SELECTED_SHORT_LISTED_PROPERTY_LOADER, data: true })
    axios
      .patch(`${config.apiPath}/api/leads`, payload, { params: { id: leadId } })
      .then((response) => {
        if (response.data) {
          dispatch(fetchSelectedProperty(lead_id))
          dispatch(fetchSingleLead(lead_id, false, null))
        }
      })
      .catch((error) => {
        console.log(error)
      })
    dispatch({ type: FETCH_SELECTED_SHORT_LISTED_PROPERTY_LOADER, data: false })
  }
}

export const fetchMatchingPropertiesCount = (params = {}) => {
  return (dispatch) => {
    axios
      .get(`${config.apiPath}/api/leads/all-matches`, { params })
      .then((res) => {
        dispatch({
          type: FETCH_PROPERTIES_COUNTING,
          data: res.data,
        })
      })
      .catch((err) => {
        dispatch({ type: FETCH_PROPERTIES_COUNTING, data: null })
      })
  }
}

const formatComment = (comment) => {
  return {
    name:
      comment && comment.armsuser && comment.armsuser.firstName + ' ' + comment.armsuser.lastName,
    date: moment(comment.createdAt).format('dddd MMM D, LT'),
    comment: comment.value,
    title: 'viewing',
  }
}

export const fetchShortListedPropertiesCommment = (id) => {
  return (dispatch) => {
    dispatch({ type: FETCH_SHORTLISTED_COMMENT_LOADER, data: true })
    axios
      .get(`${config.apiPath}/api/leads/comments?shortListPropertyId=${id}&web=true`)
      .then((res) => {
        dispatch({ type: FETCH_SHORTLISTED_COMMENT_LOADER, data: false })
        return dispatch({
          type: FETCH_SHORTLISTED_COMMENT,
          data: res.data.map((comment) => formatComment(comment)),
        })
      })
  }
}

export const fetchSelectedProperty = (lead_id) => {
  return (dispatch) => {
    dispatch({ type: FETCH_SELECTED_SHORT_LISTED_PROPERTY_LOADER, data: true })
    axios
      .get(`${config.apiPath}/api/leads/byId?id=${lead_id}`)
      .then((response) => {
        if (response.data) {
          dispatch({
            type: FETCH_SELECTED_SHORT_LISTED_PROPERTY,
            data: response.data.paymentProperty,
          })
          dispatch({ type: FETCH_SELECTED_SHORT_LISTED_PROPERTY_LOADER, data: false })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

export const addSelectedProperty = (property, lead_id) => {
  let payload = Object.create({})
  payload.shortlist_id = property && property.id
  var leadId = []
  leadId.push(lead_id)
  return (dispatch) => {
    axios
      .patch(`${config.apiPath}/api/leads`, payload, { params: { id: leadId } })
      .then((response) => {
        if (response.data) {
          dispatch(fetchSelectedProperty(lead_id))
          dispatch(fetchSingleLead(lead_id, false, null))
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

export const fetchLegalDocuments = (lead, addedBy, scaflow = false, callback = null) => {
  let lead_id = lead && lead.id
  let lead_purpose = lead && (lead.purpose === 'buy' || lead.purpose === 'sale') ? 'sale' : 'rent'
  // let lead_purpose = lead && lead.purpose
  let legalType = null
  addedBy === 'buyer'
    ? (legalType = lead && lead.legalTypeBuyer)
    : (legalType = lead && lead.legalTypeSeller)

  return (dispatch) => {
    if (addedBy === 'buyer') {
      dispatch({ type: FETCH_BUYER_DOCS_LOADER, data: true })
    } else {
      dispatch({ type: FETCH_SELLER_DOCS_LOADER, data: true })
    }
    axios
      .get(
        `${config.apiPath}/api/legal/documents/${lead_id}?addedBy=${addedBy}&leadType=${lead_purpose}&legalType=${legalType}&scaflow=${scaflow}`
      )
      .then((res) => {
        if (res.data && res.data.length) {
          if (addedBy === 'buyer') {
            dispatch({
              type: FETCH_SELETED_PROPERTY_LEGAL_DOCUMENTS_BUYER,
              data: res.data,
            })
            dispatch({ type: FETCH_BUYER_DOCS_LOADER, data: false })
          } else {
            dispatch({
              type: FETCH_SELETED_PROPERTY_LEGAL_DOCUMENTS_SELLER,
              data: res.data,
            })
            dispatch({ type: FETCH_SELLER_DOCS_LOADER, data: false })
          }

          if (callback) callback(res.data)
        }
      })
      .catch((error) => {
        console.log('error: ', error)
        if (addedBy === 'buyer') {
          dispatch({ type: FETCH_BUYER_DOCS_LOADER, data: false })
        } else {
          dispatch({ type: FETCH_SELLER_DOCS_LOADER, data: false })
        }
        if (callback) callback([])
      })
  }
}

export const fetchLegalDocumentComments = (doc_id) => {
  return (dispatch) => {
    dispatch({ type: SET_PROPERTY_LEGAL_DOCUMENT_LOADER, data: true })
    axios
      .get(`${config.apiPath}/api/legal/document/comments?documentId=${doc_id}`)
      .then((res) => {
        if (res.data) {
          dispatch({
            type: FETCH_PROPERTY_LEGAL_DOCUMENT_COMMENTS,
            data: res.data,
          })
        }
      })
      .catch((error) => {
        console.log('error: ', error)
      })

    dispatch({ type: SET_PROPERTY_LEGAL_DOCUMENT_LOADER, data: false })
  }
}

export const assignLegalDocumentToLegal = (doc_id, lead_id, comment) => {
  return (dispatch) => {
    dispatch({ type: SET_PROPERTY_LEGAL_DOCUMENT_LOADER, data: true })
    axios
      .patch(`${config.apiPath}/api/legal/document?documentId=${doc_id}&leadId=${lead_id}`, {
        status: 'pending_legal',
        remarks: comment,
      })
      .then((res) => {
        dispatch(fetchSelectedProperty(lead_id))
        dispatch(fetchSingleLead(lead_id, false, null))
      })
      .catch((error) => {
        console.log(`ERROR: /api/leads/legalDocument?id=${doc_id}`, error)
      })
    dispatch({ type: SET_PROPERTY_LEGAL_DOCUMENT_LOADER, data: false })
  }
}

export const assignAllLegalDocumentsToLegal = (docIds, lead_id, comment) => {
  const payload =
    docIds &&
    docIds.length > 0 &&
    docIds.map((doc) => {
      return { documentId: doc, status: 'pending_legal', remarks: comment }
    })
  return (dispatch) => {
    dispatch({ type: SET_PROPERTY_LEGAL_DOCUMENT_LOADER, data: true })
    axios
      .patch(`${config.apiPath}/api/legal/documents?leadId=${lead_id}`, payload)
      .then((res) => {
        dispatch(fetchSelectedProperty(lead_id))
        dispatch(fetchSingleLead(lead_id, false, null))
      })
      .catch((error) => {
        console.log(`ERROR: /api/legal/document?documentIds=${docIds}`, error)
      })
    dispatch({ type: SET_PROPERTY_LEGAL_DOCUMENT_LOADER, data: false })
  }
}

export const uploadLegalDocumentAttachment = (
  legalAttachment,
  doc_id,
  lead_id,
  scaflow = false,
  propertyId = null,
  leadId = null,
  docCategory = null,
  addedBy = null
) => {
  var sizeInMB = (legalAttachment.size / (1024 * 1024)).toFixed(2)
  let fd = new FormData()
  fd.append('file', legalAttachment)
  const otherDocsParams =
    !!docCategory && docCategory === 'other'
      ? `&leadId=${leadId}&docCategory=${docCategory}&addedBy=${addedBy}`
      : ''
  let url = `${config.apiPath}/api/legal/document?legalId=${doc_id}&scaflow=${scaflow}&propertyId=${propertyId}`
  url += otherDocsParams
  return (dispatch) => {
    if (sizeInMB <= 10) {
      dispatch({ type: SET_PROPERTY_LEGAL_DOCUMENT_LOADER, data: true })
      axios
        .post(url, fd)
        .then((res) => {
          if (res.data) {
            dispatch(fetchSelectedProperty(lead_id))
            dispatch(fetchSingleLead(lead_id, false, null))
          }
        })
        .catch((error) => {
          console.log('Attachment Error: ', error)
        })
      dispatch({ type: SET_PROPERTY_LEGAL_DOCUMENT_LOADER, data: false })
    } else {
      Swal.fire('Failed', 'Selected file size should be less than or equal to 10 MB size', 'error')
    }
  }
}

export const updateLegalService = (
  lead_id,
  legal_type,
  added_by,
  legal_description,
  singleLeadRecord
) => {
  return (dispatch) => {
    // dispatch({ type: SET_PROPERTY_LEGAL_DOCUMENT_LOADER, data: true })
    axios
      .post(
        `${
          config.apiPath
        }/api/legal/updateService?leadId=${lead_id}&legalType=${legal_type}&legalDescription=${
          legal_type === 'internal' ? 'agent' : legal_description
        }&addedBy=${added_by}`
      )
      .then((res) => {
        dispatch(fetchSelectedProperty(lead_id))
        dispatch(fetchSingleLead(lead_id, false, null))
        // if(added_by === 'buyer')
        //   dispatch(fetchLegalDocuments({ ...singleLeadRecord, legalTypeBuyer: legal_type }, 'buyer', true))
        // else if(added_by === 'seller')
        //   dispatch(fetchLegalDocuments({ ...singleLeadRecord, legalTypeSeller: legal_type }, 'seller', true))
      })
      .catch((error) => {
        console.log(
          `${config.apiPath}/api/legal/updateService?leadId=${lead_id}&legalType=${legal_type}&legalDescription=${legal_description}}&addedBy=${added_by}`,
          error
        )
      })
    // dispatch({ type: SET_PROPERTY_LEGAL_DOCUMENT_LOADER, data: false })
  }
}

export const setBuyerSellerCommissionApplicable = (value, lead_id, shortlist_id, added_by) => {
  let payload = Object.create({})
  payload.shortlist_id = shortlist_id
  if (added_by == 'buyer') {
    payload.commissionNotApplicableBuyer = value
  } else {
    payload.commissionNotApplicableSeller = value
  }
  var leadId = []
  leadId.push(lead_id)
  return (dispatch) => {
    // dispatch({ type: SET_PROPERTY_LEGAL_DOCUMENT_LOADER, data: true })
    axios
      .patch(`${config.apiPath}/api/leads`, payload, { params: { id: leadId } })
      .then((response) => {
        dispatch(fetchSelectedProperty(lead_id))
        dispatch(fetchSingleLead(lead_id, false, null))
        dispatch(disableLegalDocs(lead_id, added_by))
      })
      .catch((error) => {
        console.log(`${config.apiPath}/api/leads`, error)
      })
    // dispatch({ type: SET_PROPERTY_LEGAL_DOCUMENT_LOADER, data: false })
  }
}

export const disableLegalDocs = (lead_id, added_by) => {
  return (dispatch) => {
    axios
      .post(`${config.apiPath}/api/legal/document/disable?leadId=${lead_id}&addedBy=${added_by}`)
      .then((res) => {
        if (res.data.disableLegaldocuments) {
          dispatch(deleteLegalDocs(lead_id, added_by))
        }
      })
      .catch((error) => {
        console.log(`/api/legal/document/disable?leadId=${lead_id}&addedBy=${added_by}`, error)
      })
  }
}

export const deleteLegalDocs = (lead_id, added_by) => {
  return (dispatch) => {
    axios
      .delete(`${config.apiPath}/api/legal/documents?leadId=${lead_id}&addedBy=${added_by}`)
      .then((res) => {})
      .catch((error) => {
        console.log(`/api/legal/documents?leadId=${lead_id}&addedBy=${added_by}`, error)
      })
  }
}

export const setTransferDate = (date, lead_id, added_by) => {
  return (dispatch) => {
    // dispatch({ type: SET_PROPERTY_LEGAL_DOCUMENT_LOADER, data: true })
    axios
      .patch(`${config.apiPath}/api/legal/transferDate?addedBy=${added_by}&leadId=${lead_id}`, {
        transferDate: date,
      })
      .then((res) => {
        dispatch(fetchSelectedProperty(lead_id))
        dispatch(fetchSingleLead(lead_id, false, null))
      })
      .catch((error) => {
        console.log(`legal/transferDate?addedBy=${added_by}&leadId=${lead_id}`, error)
      })
    // dispatch({ type: SET_PROPERTY_LEGAL_DOCUMENT_LOADER, data: false })
  }
}

export const requestLegalServices = (lead_id, selected_shortlisted_property_id, added_by) => {
  return (dispatch) => {
    // dispatch({ type: SET_PROPERTY_LEGAL_DOCUMENT_LOADER, data: true })
    axios
      .post(
        `${config.apiPath}/api/legal/requestService?leadId=${lead_id}&shortlistId=${selected_shortlisted_property_id}&addedBy=${added_by}`
      )
      .then((response) => {
        if (response.data) {
          dispatch(fetchSelectedProperty(lead_id))
          dispatch(fetchSingleLead(lead_id, false, null))
        }
      })
      .catch((error) => {
        console.log(
          `/api/legal/requestService?leadId=${lead_id}&shortlistId=${selected_shortlisted_property_id}&addedBy=${added_by}`,
          error
        )
      })
    // dispatch({ type: SET_PROPERTY_LEGAL_DOCUMENT_LOADER, data: false })
  }
}

export const fetchLegalPaymentInfo = () => {
  return (dispatch) => {
    // dispatch({ type: SET_PROPERTY_LEGAL_DOCUMENT_LOADER, data: true })
    axios
      .get(`${config.apiPath}/api/leads/legalPayment`)
      .then((response) => {
        if (response.data) {
          dispatch({
            type: FETCH_LEGAL_PAYMENT_INFO,
            data: response.data,
          })
        }
      })
      .catch((error) => {
        console.log(`/api/leads/legalPayment`, error)
      })
    // dispatch({ type: SET_PROPERTY_LEGAL_DOCUMENT_LOADER, data: false })
  }
}

export const downloadLegalDocs = (item) => {
  let item_id = item && item.id
  return (dispatch) => {
    let fileType = item && item.fileType && item.fileType.split('/')
    let setFileType = ''
    if (fileType) {
      let whichFileType = fileType[1]
      if (whichFileType === 'jpeg' || whichFileType === 'jpg' || whichFileType === 'png') {
        setFileType = 'image/jpeg'
      }
      if (whichFileType === 'pdf') {
        setFileType = `application/${whichFileType}`
      }
    }
    axios
      .get(`${config.apiPath}/api/legal/document?legalId=${item_id}`, {
        responseType: 'arraybuffer',
        headers: {
          Accept: setFileType,
        },
      })
      .then((res) => {
        const blob = new Blob([res.data], { type: setFileType })
        const blobUrl = URL.createObjectURL(blob)
        window.open(blobUrl, '_blank')
      })
      .catch((error) => {
        Swal.fire({
          type: 'error',
          icon: 'error',
          title: 'Error',
          text: 'File Not Found!!!',
        })
      })
  }
}

export const deleteLegalDocument = (doc_id, lead_id) => {
  return (dispatch) => {
    axios
      .delete(`${config.apiPath}/api/legal/document?id=${doc_id}`)
      .then((res) => {
        dispatch(fetchSelectedProperty(lead_id))
        dispatch(fetchSingleLead(lead_id, false, null))
        Swal.fire('Success', 'Document Deleted Successfully', 'success')
      })
      .catch((error) => {
        console.log(`${config.apiPath}/api/legal/document?legalId=${doc_id}`, error)
      })
  }
}

export const updatePaymentStatus = (payment_id, status, lead_id) => {
  return (dispatch) => {
    // dispatch({ type: SET_PROPERTY_LEGAL_DOCUMENT_LOADER, data: true })
    axios
      .patch(`${config.apiPath}/api/leads/project/payment?id=${payment_id}`, {
        status: status,
      })
      .then((res) => {
        dispatch(fetchSelectedProperty(lead_id))
        dispatch(fetchSingleLead(lead_id, false, null))
        showToastMsg('Payment Status Changed Successfully', 'success')
      })
      .catch((error) => {
        console.log(`${config.apiPath}/api/leads/project/payment?id=${payment_id}`, error)
      })
    // dispatch({ type: SET_PROPERTY_LEGAL_DOCUMENT_LOADER, data: false })
  }
}

export const getUserPermission = (armsUserRoleId) => {
  return (dispatch) => {
    // dispatch({ type: SET_PROPERTY_LEGAL_DOCUMENT_LOADER, data: true })
    axios
      .get(`${config.apiPath}/api/rolepermission/fetch?roleId=${armsUserRoleId}`)
      .then((res) => {
        console.log('user permissions', res)
      })
      .catch((error) => {
        console.log(`${config.apiPath}/api/rolepermission/fetch?roleId=${armsUserRoleId}`, error)
      })
    // dispatch({ type: SET_PROPERTY_LEGAL_DOCUMENT_LOADER, data: false })
  }
}

export const selectDifferentProperty = (lead_id) => {
  return (dispatch) => {
    // dispatch({ type: SET_PROPERTY_LEGAL_DOCUMENT_LOADER, data: true })
    axios
      .patch(`${config.apiPath}/api/leads/unselectProperty?leadId=${lead_id}`)
      .then((res) => {
        dispatch(fetchSelectedProperty(lead_id))
        dispatch(fetchSingleLead(lead_id, false, null))
        showToastMsg(
          'Property has been removed successfully. You can select a new property for transaction.',
          'success'
        )
      })
      .catch((error) => {
        console.log(`${config.apiPath}/api/leads/unselectProperty?leadId=${lead_id}`, error)
      })
    // dispatch({ type: SET_PROPERTY_LEGAL_DOCUMENT_LOADER, data: false })
  }
}

export const setBuyRentAction = (shortListedArray, singleShortListed) => {
  let extractShortListedObject = _.find(shortListedArray.rows, { id: singleShortListed.id })

  return (dispatch) => {
    if (shortListedArray && shortListedArray.length === 0) {
      return dispatch({ type: SET_BUY_RENT_ACTION, data: {} })
    } else {
      return dispatch({ type: SET_BUY_RENT_ACTION, data: extractShortListedObject })
    }
  }
}

export const fetchArmsProperty = (
  page = 1,
  pageSize = config.pageSize,
  searchValue = '',
  searchBy = '',
  propType = ''
) => {
  let url = ''
  return (dispatch) => {
    dispatch({ type: FETCH_ARMS_DATA_BEGIN })
    dispatch({ type: FETCH_ARMS_DATA_FLAG, payload: false })
    if (searchBy === 'area') {
      url = `${config.apiPath}/api/inventory/all?propType=${propType}&searchBy=area&q=${searchValue}&pageSize=${pageSize}&page=${page}`
    } else {
      url = `${config.apiPath}/api/inventory/all?propType=arms&searchBy=${searchBy}&q=${searchValue}&page=${page}&pageSize=${pageSize}`
    }
    axios
      .get(url)
      .then((res) => {
        dispatch({ type: FETCH_ARMS_DATA_SUCCESS, payload: res.data })
        dispatch({ type: FETCH_ARMS_DATA_FLAG, payload: true })
      })
      .catch((err) => {
        dispatch({ type: FETCH_ARMS_DATA_FLAG, payload: false })
        dispatch({ type: FETCH_ARMS_DATA_FAIL, payload: err })
      })
  }
}

export const fetchFieldProperty = (
  page = 1,
  pageSize = config.pageSize,
  statusValue = '',
  searchValue = '',
  searchBy = '',

  propType = '',
  statusFilter = ''
) => {
  let url = ''

  return (dispatch) => {
    dispatch({ type: FETCH_FIELD_DATA_BEGIN })
    dispatch({ type: FETCH_FIELD_DATA_FLAG, payload: false })

    if (searchBy === 'area') {
      url = `${config.apiPath}/api/inventory/all?propType=${propType}&searchBy=area&q=${searchValue}&pageSize=${pageSize}&page=${page}`
    } else if (searchBy === 'status') {
      url = `${config.apiPath}/api/inventory/all?propType=${propType}&status=${statusFilter}&pageSize=${pageSize}&page=${page}`
    } else {
      url = `${config.apiPath}/api/inventory/all?propType=fields&searchBy=${searchBy}&q=${searchValue}&page=${page}&pageSize=${pageSize}`
    }

    axios
      .get(
        url
        // undo when properties field app table ui completed
        // `${config.apiPath}/api/inventory/all?propType=fields&status=${statusValue}&searchBy=${searchBy}&q=${searchValue}&page=${page}&pageSize=${pageSize}`
      )
      .then((res) => {
        dispatch({ type: FETCH_FIELD_DATA_SUCCESS, payload: res.data })
        dispatch({ type: FETCH_FIELD_DATA_FLAG, payload: true })
      })
      .catch((err) => {
        dispatch({ type: FETCH_FIELD_DATA_FLAG, payload: false })
        dispatch({ type: FETCH_FIELD_DATA_FAIL, payload: err })
      })
  }
}

export const fetchGraanaProperty = (
  page = 1,
  pageSize = config.pageSize,
  statusValue = '',
  searchValue = '',
  searchBy = '',
  propType = ''
) => {
  let url = ''
  return (dispatch) => {
    dispatch({ type: FETCH_GRAANA_DATA_BEGIN })
    dispatch({ type: FETCH_GRAANA_DATA_FLAG, payload: false })
    if (searchBy === 'area') {
      url = url = `${config.apiPath}/api/inventory/all?propType=${propType}&searchBy=area&q=${searchValue}&pageSize=${pageSize}&page=${page}`
    } else {
      url = `${config.apiPath}/api/inventory/all?propType=graana&propStatus=${statusValue}&searchBy=${searchBy}&q=${searchValue}&page=${page}&pageSize=${pageSize}`
    }
    axios
      .get(url)
      .then((res) => {
        dispatch({ type: FETCH_GRAANA_DATA_SUCCESS, payload: res.data })
        dispatch({ type: FETCH_GRAANA_DATA_FLAG, payload: true })
      })
      .catch((err) => {
        dispatch({ type: FETCH_GRAANA_DATA_FLAG, payload: false })
        dispatch({ type: FETCH_GRAANA_DATA_FAIL, payload: err })
      })
  }
}

export const fetchProprtyDetails = (url) => {
  return (dispatch) => {
    dispatch({ type: FETCH_PROPERTY_DETAIL_BEGIN })
    dispatch({ type: FETCH_PROPERTY_DETAIL_FLAG, payload: false })
    axios
      .get(url)
      .then((res) => {
        dispatch({ type: FETCH_PROPERTY_DETAIL_SUCCESS, payload: res.data })
      })
      .catch((err) => {
        dispatch({ type: FETCH_PROPERTY_DETAIL_FLAG, paylaod: false })
        dispatch({ type: FETCH_PROPERTY_DETAIL_FAIL, payload: err })
      })
  }
}

export const fetchSubProperties = (property) => {
  return (dispatch) => {
    dispatch({ type: FETCH_SUB_PROPERTIES, payload: properties.subType[property] })
  }
}

export const setShortlistedProperties = (leadId, properties, updateMatches) => {
  return (dispatch) => {
    axios
      .post(
        `${config.apiPath}/api/leads/${leadId}/shortlist`,
        // filter out those properties that are already selected
        properties.filter((p) => !p.hasOwnProperty('shortlisted_id'))
      )
      .then((res) => {
        updateMatches(res.data)
      })
      .catch((err) => {})
  }
}

export const fetchShortlistedProperties = (leadId) => {
  return (dispatch) => {
    dispatch({ type: FETCH_SELECTED_MATCH_PROPERTIES, payload: [], shortlistedPropertyFlag: false })
    axios
      .get(`${config.apiPath}/api/leads/${leadId}/shortlist`)
      .then((res) => {
        dispatch({
          type: FETCH_SELECTED_MATCH_PROPERTIES,
          payload: res.data,
          shortlistedPropertyFlag: true,
        })
      })
      .catch((err) => {
        dispatch({
          type: FETCH_SELECTED_MATCH_PROPERTIES,
          paylaod: false,
          shortlistedPropertyFlag: true,
        })
      })
  }
}

export const fetchMatches = (params = {}) => {
  return (dispatch) => {
    dispatch({ type: FETCH_MATCH_DATA_LOADER, data: true })
    axios
      .get(`${config.apiPath}/api/leads/matches`, { params })
      .then((res) => {
        dispatch({ type: FETCH_MATCH_DATA_LOADER, data: false })
        return dispatch({
          type: FETCH_MATCH_DATA,
          data: res.data,
        })
      })
      .catch((err) => {
        dispatch({ type: FETCH_MATCH_DATA, data: null })
      })
  }
}
