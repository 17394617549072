/** @format */

import React, { useState } from 'react'
import { Field, reduxForm, reset } from 'redux-form'
import _ from 'lodash'

import { paymentRanger, sizeRanger, bedBathRanger } from '../../ReduxForm'
import { leadType, priceRange, sizeRange, bedBathRange } from '../../../utils/constant_data'

const PropertiesFilter = ({ updateFilterDataFun, LeadDetail }) => {
  let priceMax = LeadDetail && LeadDetail.price
  let anyValue = 10000000000

  let findIndexForSize =LeadDetail&&LeadDetail.size_unit!==null? _.findLastIndex(sizeRange.sizeUnit, function (o) {
    return o.value == LeadDetail.size_unit.toString()
  }):0

  return (
    <div>
      <div className="row">
        <div className="col-2-2">
          <Field
            component={sizeRanger}
            options={sizeRange}
            type="number"
            name="sizeRanger"
            dataValue={{
              size_min: sizeRange.unitRange[sizeRange.sizeUnit[findIndexForSize].value].indexOf(
                LeadDetail && LeadDetail.size
              ),
              min: sizeRange.unitRange[sizeRange.sizeUnit[findIndexForSize].value].indexOf(
                LeadDetail && LeadDetail.size
              ),
              size_max: LeadDetail && LeadDetail.max_size,
              max: LeadDetail && LeadDetail.max_size,
              unit: sizeRange.sizeUnit[findIndexForSize],
            }}
            label="Final Size"
            LeadDetail={LeadDetail}
            onChange={(value) => {
              updateFilterDataFun('marlaRange', value)
            }}
            filterHeight={true}
          />
        </div>

        <div className="col-md-3">
          <Field
            component={paymentRanger}
            purpose={leadType[0].value}
            options={priceRange}
            type="number"
            name="priceRanger"
            dataValue={{
              price_min: LeadDetail && LeadDetail.min_price,
              price_max: priceMax === null || priceMax === 0 ? anyValue : priceMax,
              min: priceRange[LeadDetail && LeadDetail.purpose] && priceRange[LeadDetail && LeadDetail.purpose].length ? priceRange[LeadDetail && LeadDetail.purpose].indexOf(
                LeadDetail && LeadDetail.min_price
              ) : 0,
              max: priceMax === null || priceMax === 0 ? anyValue : priceMax,
            }}
            onChange={(value) => {
              updateFilterDataFun('priceRanger', value)
            }}
            filterHeight={true}
          />
        </div>

        <div className="col-2-2">
          <Field
            component={bedBathRanger}
            options={bedBathRange}
            service_type="Beds"
            type="number"
            dataValue={{
              minVal:
                LeadDetail && LeadDetail.bed !== null
                  ? bedBathRange[bedBathRange.indexOf(LeadDetail && LeadDetail.bed)]
                  : 0,
              maxVal:
                LeadDetail && LeadDetail.maxBed === null
                  ? 1000
                  : LeadDetail && LeadDetail.maxBed !== null
                  ? bedBathRange[bedBathRange.indexOf(LeadDetail && LeadDetail.maxBed)]
                  : 0,
              min:
                LeadDetail && LeadDetail.bed !== null
                  ? bedBathRange[bedBathRange.indexOf(LeadDetail && LeadDetail.bed)]
                  : 0,
              max:
                LeadDetail && LeadDetail.maxBed === null
                  ? 1000
                  : LeadDetail && LeadDetail.maxBed !== null
                  ? bedBathRange[bedBathRange.indexOf(LeadDetail && LeadDetail.maxBed)]
                  : 0,
            }}
            name="bedRanger"
            onChange={(value) => {
              updateFilterDataFun('bedRanger', value)
            }}
            filterHeight={true}
          />
        </div>

        <div className="col-2-2">
          <Field
            component={bedBathRanger}
            options={bedBathRange}
            dataValue={{
              minVal:
                LeadDetail && LeadDetail.bath !== null
                  ? bedBathRange[bedBathRange.indexOf(LeadDetail && LeadDetail.bath)]
                  : 0,
              maxVal:
                LeadDetail && LeadDetail.maxBath === null
                  ? 1000
                  : LeadDetail && LeadDetail.maxBath !== null
                  ? bedBathRange[bedBathRange.indexOf(LeadDetail && LeadDetail.maxBath)]
                  : 0,
              min:
                LeadDetail && LeadDetail.bath !== null
                  ? [bedBathRange.indexOf(LeadDetail && LeadDetail.bath)]
                  : 0,
              max:
                LeadDetail && LeadDetail.maxBath === null
                  ? 1000
                  : LeadDetail && LeadDetail.maxBath !== null
                  ? [bedBathRange.indexOf(LeadDetail && LeadDetail.maxBath)]
                  : 0,
            }}
            service_type="Baths"
            type="number"
            name="bathRanger"
            onChange={(value) => {
              updateFilterDataFun('bathRanger', value)
            }}
            filterHeight={true}
          />
        </div>
      </div>
    </div>
  )
}
export default reduxForm({
  form: 'PropertiesForm',
})(PropertiesFilter)
