import React, {useEffect,useState, useMemo } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import axios from "axios";
import config from "../../config";
import { showToastMsg } from "../../utils/helper";
import { setTitle } from '../../actions/generalSetting'
import { connect } from 'react-redux'

 const AddCampaignForm = (props) => {
   const { setTitle, isaddCampaignFormOpen,
    openAddCampaignFormHandler,
    editCampaignData,
    editMode,
    getCampaignsData,}=props;
  const [queueId, setQueueId] = useState();
  const [queueName, setQueueName] = useState("");
  const [outage, setOutage] = useState(0);
  const [phoneNumbersList, setPhoneNumbersList] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [indexForUpdatePhoneNo, setindexForUpdatePhoneNo] = useState();
  const [updatePhoneNoMode, setUpdatePhoneNoMode] = useState(false);
  const [isSubmitForm,setIsSubmitForm] = useState(false)


  const RemoveContactNoFromContactList = (Propvalue) => {
    var array = [...phoneNumbersList];
    var index = array.findIndex((arr) => arr.phone_number === Propvalue);
    if (index !== -1) {
      if (editMode) {
        var indexData = array[index];
        array[index] = { ...indexData, action: "delete" };
        setPhoneNumbersList(array);
      } else {
        array.splice(index, 1);
        setPhoneNumbersList(array);
      }
    }
  };

  const NoForUpdate = (Propvalue) => {
    setPhoneNumber(Propvalue);
    var array = [...phoneNumbersList];
    var index = array.findIndex((arr) => arr.phone_number === Propvalue);
    setindexForUpdatePhoneNo(index);
    setUpdatePhoneNoMode(true);
  };


  const addNewPhoneNumberInList = () => {
    if (editMode) {
      setPhoneNumbersList([
        ...phoneNumbersList,
        { phone_number: phoneNumber, action: "create" },
      ]);
    } else {
      setPhoneNumbersList([...phoneNumbersList, { phone_number: phoneNumber, action: "create" }]);
    }

    setPhoneNumber("");
  };

  const UpdatedPhoneNo = () => {
    var array = [...phoneNumbersList];
    var indexData = array[indexForUpdatePhoneNo];
    array[indexForUpdatePhoneNo] = {
      ...indexData,
      phone_number: phoneNumber,
      action: "update",
    };
    setPhoneNumbersList(array);
    setPhoneNumber("");
  };


  useEffect(()=>{
   setTitle({ name: 'Campaign Outage' })
    isSubmitFormChecker()
  },[queueId ,queueName , phoneNumbersList.length])


  const isSubmitFormChecker=()=>{
    if(queueId && queueName && phoneNumbersList.length > 0)
    {
      setIsSubmitForm(true)
    }else{
      setIsSubmitForm(false)
    }
  }

  const EmptyAllFieldsdata=()=>{
    setQueueId();
    setQueueName("");
    setOutage(0);
    setPhoneNumbersList([]);
    setindexForUpdatePhoneNo();
    setUpdatePhoneNoMode(false);
    setPhoneNumber("")
  }


  const AddAndEditCampaign = () => {
    let url = `${config.apiPath}/api/campaign/${
      editMode ? "editCampaign" : "create"
    } `;
    axios
      .post(url, {
        queue_id: queueId.toString(),
        queue_name: queueName,
        outage: outage,
        phones: phoneNumbersList,
      })
      .then((result) => {
        showToastMsg(
          `Campaign ${editMode ? "Updated" : "Added"}  Sucessfully`,
          "success"
        );
        openAddCampaignFormHandler();
        getCampaignsData();
      })
      .catch((error) => {});
  };

  useMemo(() => {
    if (editMode && editCampaignData) {
      const { queue_id, queue_name, phones, outage } = editCampaignData;
      setQueueId(parseInt(queue_id));
      setQueueName(queue_name);
      setOutage(outage);
      setPhoneNumbersList(phones);
      setindexForUpdatePhoneNo();
      setUpdatePhoneNoMode(false);
      setPhoneNumber("")
    } else {
      EmptyAllFieldsdata()

    }
  }, [editMode, editCampaignData,isaddCampaignFormOpen]);

  return (
    <div>
      <Modal
        isOpen={isaddCampaignFormOpen}
        toggle={openAddCampaignFormHandler}
        size="lg"
        style={{ maxWidth: "730px", width: "100%" }}
        contentClassName="campaign-modal-width"
      >
        <div className="createClientModal">
          <ModalHeader
            style={{
              background: "none !important",
              border: "none",
            }}
          >
            <div className="row ml-1 mr-3">
              <div className="heading-wrap pl-3 pt-2">
                <h5 style={{ color: "#7d7e7f" }}>
                  {" "}
                  {editMode ? "Edit Campaign" : "Add Campaign"}{" "}
                </h5>
              </div>

              <div className="close-icon-container">
                <i
                  className="fal fa-times-circle close-icon"
                  onClick={() => {
                    openAddCampaignFormHandler();
                  }}
                ></i>
              </div>
            </div>
          </ModalHeader>

          <ModalBody>
            <div className={`filterMainWrap padding__modal`}>
              <div className="row p-3">
                <div className="col-md-12 col-lg-12 pt-2 mb-3">
                  <input
                    value={queueId}
                    type="number"
                    disabled={editMode}
                    className="form-control"
                    placeholder="UAN Number"
                    onChange={(e) => {setQueueId(e.target.value)}}
                  />
                </div>
                <div className="col-md-12 col-lg-12 pt-2 mb-3">
                  <input
                    placeholder="UAN Number Name"
                    type="text"
                    disabled={editMode}
                    value={queueName}
                    className="form-control"
                    onChange={(e) => {setQueueName(e.target.value)}}
                  />
                </div>
                <div className="col-md-12 col-lg-12 pt-2 mb-3">
                  <label
                    className="form-check-label mr-2 pl-1"
                    htmlFor="radio1"
                  >
                    {" "}
                    OUTAGE{" "}
                  </label>
                  <input
                    type="radio"
                    value="No"
                    checked={outage === 0}
                    onChange={(e) => {
                      setOutage(0);
                    }}
                  />
                  No
                  <input
                    type="radio"
                    value="Yes"
                    checked={outage === 1}
                    onChange={(e) => {
                      setOutage(1);
                    }}
                  />
                  Yes
                </div>

                <div className=" buttonIn col-md-12 col-lg-12 pt-2">
                  <input
                    value={phoneNumber}
                    className="form-control inputB"
                    type="number"
                    placeholder="Phone Number"
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />

                  <button
                    onClick={() =>{
                      updatePhoneNoMode
                        ? UpdatedPhoneNo()
                        : addNewPhoneNumberInList()

                    }
                    }
                    className="addNobutton btn-primary"
                  >
                    {updatePhoneNoMode ? "Update" : "Add"}
                  </button>
                </div>

                <ul className="mb-2 PhoneNoContainer">
                  {phoneNumbersList &&
                    phoneNumbersList.map((phoneNo) => {

                        return (
                       ( phoneNo.phone_number && phoneNo.action !== 'delete' ) &&
                        <li >
                         Phone No : <i
                            className="fa fa-edit PhoneNoEditIcon mr-1"
                            onClick={() => {
                              NoForUpdate(phoneNo.phone_number);
                            }}
                          ></i>
                          <i
                            className="fal fa-times-circle PhoneNodeleteIcon mr-1"
                            onClick={() => {
                              RemoveContactNoFromContactList(
                                phoneNo.phone_number
                              );
                            }}
                          ></i>
                          <span style={{color:'#026FF2'}}>{phoneNo.phone_number}</span>
                        </li>
                      );
                    })}
                </ul>

                <div className="col-lg-12">
                  <div className="btn-wrap">
                    <button
                      className="btn btn-secondary  mr-2"
                      onClick={() => {
                        openAddCampaignFormHandler();
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      disabled={!isSubmitForm}
                      onClick={() => {AddAndEditCampaign()}}
                      className="btn btn-success"
                    >
                      {editMode ? "Update" : "Add"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
        </div>
      </Modal>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setTitle: (title) => dispatch(setTitle(title)),
})

 export default connect(null, mapDispatchToProps)(AddCampaignForm);
