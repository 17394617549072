/** @format */

import React from 'react'
import Select from 'react-select'
import { StaticData } from '../../../../../StaticData'
import { LEAD_ORIGIN } from '../../../../../utils/constant_data'

const MoreFilters = (props) => {
  const {
    onChangeSelectFilter,
    allProjects,
    selectedProjectFilter,
    activeFilterNameList,
    allCities,
    selectedCity,
    selectedFormId,
    allFormId,
    selectedAssignedLead,
    selectedBulk,
    usersFilterOption,
    selectedCreated,
    allTeams,
    selectedTeam,
    selectedfaceBookFilter,
    allAgents,
    nonTerminalAgents,
    selectedAgents,
    allOrg,
    selectedOrg,
    selectedOrigin,
    selectedSingle,
    selectedFollowUp,
    selectedAging,
    assignToMe,
    leadCategory,
    projectLeadQuickFilter,
    filters,
    emailId,
    PhoneNo,
    country,
    countriesListWihCountriesCode,
    isTerminalUser,
  } = props
  const loginUserRole = localStorage.getItem('role')

  return (
    <div>
      <div className="row">
        <div className="col-3 mt-2">
          <div
            className={`filter__Top ${
              projectLeadQuickFilter && projectLeadQuickFilter['followUp'] !== undefined
                ? 'active__filter'
                : ''
            }`}
          >
            <Select
              value={
                filters && filters['followUp'] !== undefined
                  ? filters['followUp']
                  : projectLeadQuickFilter && projectLeadQuickFilter['followUp'] !== undefined
                  ? projectLeadQuickFilter['followUp']
                  : null
              }
              options={StaticData.followUpOptions}
              placeholder="Follow up"
              onChange={(value) => {
                onChangeSelectFilter('followUp', value)
              }}
            />
          </div>
        </div>

        <div className="col-3 mt-2">
          <div
            className={`filter__Top ${
              projectLeadQuickFilter && projectLeadQuickFilter['projectId'] !== undefined
                ? 'active__filter'
                : ''
            }`}
          >
            <Select
              value={
                filters && filters['projectId'] !== undefined
                  ? filters['projectId']
                  : projectLeadQuickFilter && projectLeadQuickFilter['projectId'] !== undefined
                  ? projectLeadQuickFilter['projectId']
                  : null
              }
              options={allProjects}
              placeholder="Project"
              onChange={(value) => {
                onChangeSelectFilter('project', value)
              }}
            />
          </div>
        </div>

        <div className="col-3 mt-2">
          <div
            className={`filter__Top ${
              projectLeadQuickFilter && projectLeadQuickFilter['cityId'] !== undefined
                ? 'active__filter'
                : ''
            }`}
          >
            <Select
              value={
                filters && filters['cityId'] !== undefined
                  ? filters['cityId']
                  : projectLeadQuickFilter && projectLeadQuickFilter['cityId'] !== undefined
                  ? projectLeadQuickFilter['cityId']
                  : null
              }
              options={allCities}
              placeholder="City"
              onChange={(value) => {
                onChangeSelectFilter('city', value)
              }}
            />
          </div>
        </div>

        <div className="col-3 mt-2">
          <div
            className={`filter__Top ${
              projectLeadQuickFilter && projectLeadQuickFilter['leadsAssigned'] !== undefined
                ? 'active__filter'
                : ''
            }`}
          >
            <Select
              value={
                filters && filters['leadsAssigned'] !== undefined
                  ? filters['leadsAssigned']
                  : projectLeadQuickFilter && projectLeadQuickFilter['leadsAssigned'] !== undefined
                  ? projectLeadQuickFilter['leadsAssigned']
                  : null
              }
              options={StaticData.assignedFilterOption}
              placeholder="Assigned Leads"
              onChange={(value) => {
                onChangeSelectFilter('leadsAssigned', value)
              }}
            />
          </div>
        </div>

        <div className="col-3 mt-2">
          <div
            className={`filter__Top ${
              projectLeadQuickFilter && projectLeadQuickFilter['bulk'] !== undefined
                ? 'active__filter'
                : ''
            }`}
          >
            <Select
              value={
                filters && filters['bulk'] !== undefined
                  ? filters['bulk']
                  : projectLeadQuickFilter && projectLeadQuickFilter['bulk'] !== undefined
                  ? projectLeadQuickFilter['bulk']
                  : null
              }
              options={StaticData.facebookFilterData}
              placeholder="Bulk Uploaded"
              onChange={(value) => {
                onChangeSelectFilter('bulk', value)
              }}
            />
          </div>
        </div>
        <div className="col-3 mt-2">
          <div
            className={`filter__Top ${
              projectLeadQuickFilter && projectLeadQuickFilter['aging'] !== undefined
                ? 'active__filter'
                : ''
            }`}
          >
            <Select
              value={
                filters && filters['aging'] !== undefined
                  ? filters['aging']
                  : projectLeadQuickFilter && projectLeadQuickFilter['aging'] !== undefined
                  ? projectLeadQuickFilter['aging']
                  : null
              }
              options={StaticData.aging}
              placeholder="Search by Aging"
              onChange={(value) => {
                onChangeSelectFilter('aging', value)
              }}
            />
          </div>
        </div>
        <div className="col-3 mt-2">
          <div
            className={`filter__Top ${
              projectLeadQuickFilter && projectLeadQuickFilter['createdBy'] !== undefined
                ? 'active__filter'
                : ''
            }`}
          >
            <Select
              value={
                filters && filters['createdBy'] !== undefined
                  ? filters['createdBy']
                  : projectLeadQuickFilter && projectLeadQuickFilter['createdBy'] !== undefined
                  ? projectLeadQuickFilter['createdBy']
                  : null
              }
              options={usersFilterOption}
              placeholder="Created By"
              onChange={(value) => {
                onChangeSelectFilter('createdBy', value)
              }}
            />
          </div>
        </div>

        {['admin 2', 'admin 1', 'admin 3'].indexOf(loginUserRole) > -1 && (
          <div className="col-3 mt-2">
            <div
              className={`filter__Top ${
                projectLeadQuickFilter && projectLeadQuickFilter['team'] !== undefined
                  ? 'active__filter'
                  : ''
              }`}
            >
              <Select
                value={
                  filters && filters['team'] !== undefined
                    ? filters['team']
                    : projectLeadQuickFilter && projectLeadQuickFilter['team'] !== undefined
                    ? projectLeadQuickFilter['team']
                    : null
                }
                options={allTeams}
                placeholder="Search by Team"
                onChange={(value) => {
                  onChangeSelectFilter('team', value)
                }}
              />
            </div>
          </div>
        )}

        <div className="col-3 mt-2">
          <div
            className={`filter__Top ${
              projectLeadQuickFilter && projectLeadQuickFilter['faceBookFilter'] !== undefined
                ? 'active__filter'
                : ''
            }`}
          >
            <Select
              value={
                filters && filters['faceBookFilter'] !== undefined
                  ? filters['faceBookFilter']
                  : projectLeadQuickFilter && projectLeadQuickFilter['faceBookFilter'] !== undefined
                  ? projectLeadQuickFilter['faceBookFilter']
                  : null
              }
              options={StaticData.facebookFilterData}
              placeholder="Facebook Leads"
              onChange={(value) => {
                onChangeSelectFilter('faceBookFilter', value)
              }}
            />
          </div>
        </div>

        <div className="col-3 mt-2">
          <div
            className={`filter__Top ${
              projectLeadQuickFilter && projectLeadQuickFilter['assignedTo'] !== undefined
                ? 'active__filter'
                : ''
            }`}
          >
            <Select
              value={
                filters && filters['assignedTo'] !== undefined
                  ? filters['assignedTo']
                  : projectLeadQuickFilter && projectLeadQuickFilter['assignedTo'] !== undefined
                  ? projectLeadQuickFilter['assignedTo']
                  : null
              }
              options={allAgents}
              placeholder="Assignee"
              onChange={(value) => {
                onChangeSelectFilter('assignedTo', value)
              }}
            />
          </div>
        </div>

        {['admin 2', 'admin 1', 'admin 3'].indexOf(loginUserRole) > -1 ? (
          ''
        ) : (
          <div className="col-3 mt-2">
            <div
              className={`filter__Top ${
                projectLeadQuickFilter && projectLeadQuickFilter['organization'] !== undefined
                  ? 'active__filter'
                  : ''
              }`}
            >
              <Select
                value={
                  filters && filters['organization'] !== undefined
                    ? filters['organization']
                    : projectLeadQuickFilter && projectLeadQuickFilter['organization'] !== undefined
                    ? projectLeadQuickFilter['organization']
                    : null
                }
                options={allOrg}
                placeholder="By Organization"
                onChange={(value) => {
                  onChangeSelectFilter('organization', value)
                }}
              />
            </div>
          </div>
        )}

        {['admin 2', 'admin 1', 'admin 3'].indexOf(loginUserRole) > -1 && (
          <div className="col-3 mt-2">
            <div
              className={`filter__Top ${
                projectLeadQuickFilter && projectLeadQuickFilter['organization'] !== undefined
                  ? 'active__filter'
                  : ''
              }`}
            >
              <Select
                value={
                  filters && filters['organization'] !== undefined
                    ? filters['organization']
                    : projectLeadQuickFilter && projectLeadQuickFilter['organization'] !== undefined
                    ? projectLeadQuickFilter['organization']
                    : null
                }
                options={allOrg}
                placeholder="By Organization"
                onChange={(value) => {
                  onChangeSelectFilter('organization', value)
                }}
              />
            </div>
          </div>
        )}
        <div className="col-3 mt-2">
          <div
            className={`filter__Top ${
              projectLeadQuickFilter && projectLeadQuickFilter['singleTask'] !== undefined
                ? 'active__filter'
                : ''
            }`}
          >
            <Select
              value={
                filters && filters['singleTask'] !== undefined
                  ? filters['singleTask']
                  : projectLeadQuickFilter && projectLeadQuickFilter['singleTask'] !== undefined
                  ? projectLeadQuickFilter['singleTask']
                  : null
              }
              options={StaticData.singleFilterData}
              placeholder="Single Task"
              onChange={(value) => {
                onChangeSelectFilter('singleBookFilter', value)
              }}
            />
          </div>
        </div>
        <div className="col-3 mt-2">
          <div
            className={`filter__Top ${
              projectLeadQuickFilter && projectLeadQuickFilter['leadCategory'] !== undefined
                ? 'active__filter'
                : ''
            }`}
          >
            <Select
              value={
                filters && filters['leadCategory'] !== undefined
                  ? filters['leadCategory']
                  : projectLeadQuickFilter && projectLeadQuickFilter['leadCategory'] !== undefined
                  ? projectLeadQuickFilter['leadCategory']
                  : ''
              }
              options={StaticData.leadCategories}
              placeholder="Classification"
              onChange={(value) => {
                onChangeSelectFilter('leadCategory', value)
              }}
            />
          </div>
        </div>
        <div className="col-3 mt-2">
          <div
            className={`filter__Top ${
              projectLeadQuickFilter && projectLeadQuickFilter['formId'] !== undefined
                ? 'active__filter'
                : ''
            }`}
          >
            <Select
              value={
                filters && filters['formId'] !== undefined
                  ? filters['formId']
                  : projectLeadQuickFilter && projectLeadQuickFilter['formId'] !== undefined
                  ? projectLeadQuickFilter['formId']
                  : null
              }
              options={allFormId}
              placeholder="Form Id"
              onChange={(value) => {
                onChangeSelectFilter('formId', value)
              }}
            />
          </div>
        </div>
 {/* add by tk */}
 <div className="col-3 mt-2">

 <div
            className={`filter__Top ${
              projectLeadQuickFilter && projectLeadQuickFilter['country'] !== undefined
                ? 'active__filter'
                : ''
            }`}
          >
    <Select
    value={
      filters && filters['country'] !== undefined
        ? filters['country']
        : projectLeadQuickFilter && projectLeadQuickFilter['country'] !== undefined
        ? projectLeadQuickFilter['country']
        : null
    }
    options={countriesListWihCountriesCode && countriesListWihCountriesCode}
    noOptionsMessage={() => 'No options found'}
    placeholder="Country"
    onChange={(value) => {
      onChangeSelectFilter('country',value)
    }}
  />
      </div>
</div>

<div className="mt-2 col-3">

        <div
          className={`filter__Top ${
              projectLeadQuickFilter && projectLeadQuickFilter['emailId'] !== undefined
                ? 'active__filter'
                : ''
            }`}
          >
                          <input
                            type="string"
                            placeholder="Client Email ID"
                            className={
                              activeFilterNameList.includes('emailId') === true
                                ? 'customStyle'
                                : 'Id_textfield'
                            }

                            value={
                              filters && filters['emailId'] !== undefined
                                ? filters['emailId']
                                : projectLeadQuickFilter && projectLeadQuickFilter['emailId'] !== undefined
                                ? projectLeadQuickFilter['emailId']
                                : ""
                            }

                            onChange={(e) => {
                              onChangeSelectFilter('emailId', e.target.value)
                            }}
                          />
                        </div>
                        </div>


<div className="mt-2 col-3">
<div
            className={`filter__Top ${
              projectLeadQuickFilter && projectLeadQuickFilter['phoneNo'] !== undefined
                ? 'active__filter'
                : ''
            }`}
          >
                          <input
                            type="text"
                            placeholder="Client Phone #"
                            className={
                              activeFilterNameList.includes('phoneNo') === true
                                ? 'customStyle'
                                : 'Id_textfield'
                            }
                            value={
                              filters && filters['phoneNo'] !== undefined
                                ? filters['phoneNo']
                                : projectLeadQuickFilter &&
                                  projectLeadQuickFilter['phoneNo'] !== undefined
                                ? projectLeadQuickFilter['phoneNo']
                                :  ""
                            }
                            onChange={(e) => {
                              onChangeSelectFilter('phoneNo', e.target.value)
                            }}
                          />
                        </div>
                        </div>


{/* add by tk */}

        {['sub_admin 1'].indexOf(loginUserRole) > -1 && (
          <div className="col-3 mt-2">
            <div
              className={`filter__Top ${
                projectLeadQuickFilter && projectLeadQuickFilter['assignToMe'] !== undefined
                  ? 'active__filter'
                  : ''
              }`}
            >
              <Select
                value={
                  filters && filters['assignToMe'] !== undefined
                    ? filters['assignToMe']
                    : projectLeadQuickFilter && projectLeadQuickFilter['assignToMe'] !== undefined
                    ? projectLeadQuickFilter['assignToMe']
                    : null
                }
                options={StaticData.assignToMeFilterData}
                placeholder="Assigned To Me"
                onChange={(value) => {
                  onChangeSelectFilter('assignToMeFilter', value)
                }}
              />
            </div>
          </div>
        )}
        {(!isTerminalUser) &&
        <div className="col-3 mt-2">
          <Select
            className="customFilterColor"
            value={
              filters && filters['userHierarchy'] !== undefined
                ? filters['userHierarchy']
                : projectLeadQuickFilter &&
                projectLeadQuickFilter['userHierarchy'] !== undefined
                ? projectLeadQuickFilter['userHierarchy']
                : null
            }
            options={nonTerminalAgents}
            noOptionsMessage={() => 'No options found'}
            placeholder="Advisor's Hierarchy"
            onChange={(value) => {
              onChangeSelectFilter('userHierarchy', value)
            }}
          />
        </div>
        }
        {(!isTerminalUser) &&
        <div className="col-3 mt-2">
          <Select
            className="customFilterColor"
            value={
              filters && filters['teamHierarchy'] !== undefined
                ? filters['teamHierarchy']
                : projectLeadQuickFilter &&
                projectLeadQuickFilter['teamHierarchy'] !== undefined
                ? projectLeadQuickFilter['teamHierarchy']
                : null
            }
            options={nonTerminalAgents}
            noOptionsMessage={() => 'No options found'}
            placeholder="Advisor's Team"
            onChange={(value) => {
              onChangeSelectFilter('teamHierarchy', value)
            }}
          />
        </div>
        }
        <div className="col-3 mt-2">
            <Select
              value={
                filters && filters['origin'] !== undefined
                  ? filters['origin']
                  : projectLeadQuickFilter &&
                  projectLeadQuickFilter['origin'] !== undefined
                  ? projectLeadQuickFilter['origin']
                  : ''}
              options={LEAD_ORIGIN}
              placeholder="Origin"
              isMulti={true}
              onChange={(value) => {
                onChangeSelectFilter('origin', value)
              }}
            />
        </div>
      </div>
    </div>
  )
}

export default MoreFilters
