/** @format */

import React, { Component } from 'react';
import { GoogleApiWrapper, Map as ReactMap, Marker } from 'google-maps-react';
import PropTypes from 'prop-types';

class GoogleMapContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      center: {
        lat: props.defaultLat ? props.defaultLat : 33.6844,
        lng: props.defaultLng ? props.defaultLng : 73.0479,
      },
      zoom: props.defaultLat ? 18 : 11,
      markerPosition: props.defaultLng && props.defaultLat ? {lat: props.defaultLat, lng: props.defaultLng} : null,
      apiReady: false,
      showSearch: false,
    };
    this.map = React.createRef();
    this.searchBox = React.createRef();
  }

  setLatLng = (data) => {
    const { onSelect } = this.props;
    this.setState((prevState) => ({
      center: {
        ...prevState.center,
        lat: data.lat,
        lng: data.lng,
      },
      zoom: 18,
      markerPosition: {
        ...prevState.markerPosition,
        lat: data.lat,
        lng: data.lng,
      },
    }));
    onSelect(data);
  };

  onApiLoad = () => {
    const { google } = this.props;
    this.setState({ apiReady: true });
    const autoComplete = new google.maps.places.Autocomplete(this.searchBox.current, {
      componentRestrictions: { country: 'pk' },
    });
    this.map.map.controls[google.maps.ControlPosition.TOP_LEFT].push(this.searchBox.current);
    setTimeout(() => {
      if(this.searchBox.current !== null){
        this.searchBox.current.style.top = '5px';
        this.searchBox.current.style.left = '5px';
        this.setState({ showSearch: true });
      }

    }, 2000);
    // Bias the SearchBox results towards current map's viewport.
    this.map.map.addListener('bounds_changed', () => {
      setTimeout(() => {
        if(this.searchBox.current !== null) {
          this.searchBox.current.style.top = '5px';
          this.searchBox.current.style.left = '5px';
          this.setState({ showSearch: true });
        }
      }, 1000);
      autoComplete.setBounds(this.map.map.getBounds());
    });

    autoComplete.addListener('place_changed', () => {
      const place = autoComplete.getPlace();

      if (place.length === 0) {
        return null;
      }
      const coordinates = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };
      this.setLatLng(coordinates);
      return place;
    });
  };

  render() {
    const { center, zoom, apiReady, showSearch, markerPosition } = this.state;
    const { onSelect, google, isMapVisible } = this.props;
    const bool = true;

    return (
      <div style={{ height: '70vh', width: '100%', position: 'relative', zIndex: '99999' }}>
        <ReactMap
          ref={(ref) => {
            this.map = ref;
          }}
          streetViewControlOptions={false}
          google={google}
          initialCenter={center}
          center={center}
          defaultZoom={zoom}
          zoom={zoom}
          streetViewControl={false}
          mapTypeControl={false}
          fullscreenControl={bool}
          scrollwheel={bool}
          onReady={() => {
            this.onApiLoad();
          }}
          visible={isMapVisible}
          onClick={(ev, m, e) => {
            this.setState((prevState) => ({
              markerPosition: {
                ...prevState.markerPosition,
                lat: e.latLng.lat(),
                lng: e.latLng.lng(),
              },
            }));
            onSelect({ lat: e.latLng.lat(), lng: e.latLng.lng() });
          }}
        >
          {markerPosition && (
            <Marker
              title="The marker`s title will appear as a tooltip."
              name="SOMA"
              position={markerPosition}
            />
          )}
          {apiReady && (
            <input
              ref={this.searchBox}
              hidden={!showSearch}
              placeholder="Search Places..."
              type="text"
              style={{
                width: '300px',
                height: '40px',
                border: 'none',
                fontSize: '15px',
                padding: '10px',
                top: '5px !important',
                left: '5px !important',
              }}
            />
          )}
        </ReactMap>
      </div>
    );
  }
}
GoogleMapContainer.propTypes = {
  onSelect: PropTypes.func,
  google: PropTypes.objectOf,
};

GoogleMapContainer.defaultProps = {
  onSelect: '',
  google: '',
};
export default GoogleApiWrapper({
  apiKey: 'AIzaSyBwFJarUGm7020jyPhZ6mjjuWbYyj1vgGA',
  libraries: ['places'],
})(GoogleMapContainer);
