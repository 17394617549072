/** @format */

import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { makeStyles } from '@material-ui/styles'
import Select from 'react-select'
import axios from 'axios'
import config from '../../../config'
import clsx from 'clsx'
import Button from '../Button'
import { DateRangePicker } from 'react-dates'
import 'react-dates/lib/css/_datepicker.css'
import moment from 'moment'
import { getPermissionValue } from '../../_unlockComponent'
import { PermissionFeatures, PermissionActions } from '../../../utils/permissionConstants'
// import { fetchAllClients } from '../../../actions/leads'

const fieldHeight = {
  control: (base) => ({
    ...base,
  }),
  singleValue: (base) => ({
    ...base,
    color: 'hsl(0,0%,50%)',
  }),
}
const customStyles = {
  control: (base) => ({
    ...base,
    '&:hover': {
      borderColor: '#046ef2',
      color: '#046ef2',
    },
    '&': {
      borderColor: '#046ef2',
      color: '#046ef2',
      boxShadow: '0 0 0 1px #2684ff',
    },
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: 'inherit',
  }),
  indicatorSeparator: (base) => ({
    ...base,
    backgroundColor: '#046ef2',
  }),
  singleValue: (base) => ({
    ...base,
    color: 'inherit',
  }),
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    gridGap: '15px',
    // rowGap: '30px !important',
    marginTop: '30px',
    marginBottom: '30px',
    '& > div': {
      width: '32%',
    },
  },
  textbox: {
    height: '38px',
    paddingLeft: '10px',
    border: '1px solid #dbdbdb',
    borderRadius: '5px',
    width: '100% !important',
    outline: 'none',
    '&:focus': {
      border: '1px solid #2684ff',
      boxShadow: '0 0 0 1px #2684ff',
    },
  },
  dropdown: {
    // border: '1px solid #dbdbdb',
    // borderRadius: '5px',
    '&:focus': {
      // border: '1px solid #2684ff !important',
      // boxShadow: '0 0 0 1px #2684ff !important',
    },
  },
  btnContainer: {
    display: 'flex',
    gridGap: '10px',
  },
  selected: {
    border: '1px solid #2684ff',
    boxShadow: '0 0 0 1px #2684ff',
    color: '#046ef2',
  },
})
const DiaryFilters = (props) => {
  const classes = useStyles(props)
  const [filters, setFilters] = useState(props.selectedFilters)
  const [clientNames, setClientNames] = useState([])
  const [reasons, setResons] = useState({})
  const [isClientLoading, setClientLoading] = useState(false)
  const [isFollowupLoading, setFollowupLoading] = useState(false)
  const [focusedFollowInput, setFocusedFollowInput] = useState(null)

  useEffect(() => {
    fetchReasons()
    fetchClientNames()
  }, [])

  useEffect(() => {
    setFilters(props.selectedFilters)
  }, [props.selectedFilters])

  const fetchClientNames = (name = null) => {
    setClientLoading(true)
    let nameParam = ''
    if (name) {
      nameParam = `?name=${name}`
    }
    axios
      .get(`${config.apiPath}/api/customer/teamClients${nameParam}`)
      .then((res) => {
        if (res && res.data && res.data.result) {
          setClientNames(
            res.data.result.map((item) => {
              return {
                label: item.first_name + ' ' + item.last_name,
                value: item.first_name + ' ' + item.last_name,
              }
            })
          )
        }
        setClientLoading(false)
      })
      .catch(() => {
        setClientLoading(false)
      })
  }
  const fetchReasons = () => {
    setFollowupLoading(true)
    axios
      .get(`${config.apiPath}/api/feedbacks/fetch`)
      .then((res) => {
        if (res && res.data) {
          let sections = Object.keys(res.data)
          let result = {}
          for (let i = 0; i < sections.length; i++) {
            let sectionArr = res.data[sections[i]]
            for (let j = 0; j < sectionArr.length; j++) {
              let id = sectionArr[j].id
              let tags = sectionArr[j].tags
              if (tags && sectionArr[j].section === 'Actions') {
                try {
                  tags = JSON.parse(tags[0])
                  let newTags = Object.keys(tags).map((key) => tags[key])
                  tags = newTags
                } catch (e) {
                  tags = null
                }
              }
              if (tags) {
                for (let k = 0; k < tags.length; k++) {
                  if (result[tags[k]]) result[tags[k]] = [...result[tags[k]], id]
                  else result[tags[k]] = [id]
                }
              }
            }
          }

          let response = Object.keys(result).map((item) => {
            return {
              label: item,
              value: item,
            }
          })
          setResons(response)
        }
        setFollowupLoading(false)
      })
      .catch(() => {
        setFollowupLoading(false)
      })
  }
  const getLeadTypeList = () => {
    let list = []

    getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.WANTED_LEADS_PAGE_VIEW) &&
      list.push({ label: 'Wanted', value: 'Wanted' })
    getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.PROJECT_LEADS_PAGE_VIEW) &&
      list.push({ label: 'Project', value: 'Project' })
    getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.BUYRENT_LEADS_PAGE_VIEW) &&
      list.push({ label: 'Buy/Rent', value: 'BuyRent' })

    return list
  }
  return (
    <Modal
      isOpen={props.isOpen}
      toggle={props.toggle}
      style={{ minWidth: 850 }}
      className="device-modal diaryFilterPopup"
    >
      <ModalBody>
        <div className={'col-sm-12'}>
          <h5>Advance Filters</h5>
          <div className="close-icon-container">
            <i
              style={{ top: 5 }}
              className="mt-1 fal fa-times-circle close-icon"
              onClick={props.toggle}
            ></i>
          </div>
        </div>
        <div className={classes.container}>
          {/* <div>
            <DatePicker
              // minDate={new Date()}
              selected={filters.date}
              onChange={(value) =>
                setFilters({ ...filters, date: moment(value).format('YYYY-MM-DD') })
              }
              dateFormat="YYYY-MM-DD"
              value={filters.date}
              placeholder={'Date'}
              className={clsx(
                filters.date ? 'diary-filter-date-picker-selected' : 'diary-filter-date-picker'
              )}
            />
          </div> */}
          <div>
            <Select
              isLoading={isFollowupLoading}
              value={filters.followUpReason ? filters.followUpReason : null}
              styles={filters.followUpReason ? customStyles : fieldHeight}
              options={reasons}
              noOptionsMessage={() => 'No Follow up Found'}
              placeholder="Follow up reason"
              onChange={(value) => {
                setFilters({ ...filters, followUpReason: value })
              }}
            />
          </div>
          <div>
            <Select
              value={filters.leadType ? filters.leadType : null}
              styles={filters.leadType ? customStyles : fieldHeight}
              options={getLeadTypeList()}
              className={clsx(classes.dropdown)}
              noOptionsMessage={() => 'No Lead Type Found'}
              placeholder="Lead Type"
              onChange={(value) => {
                setFilters({ ...filters, leadType: value })
              }}
            />
          </div>
          <div>
            <input
              type="number"
              placeholder="Client ID"
              className={clsx(classes.textbox, filters.clientId ? classes.selected : '')}
              value={filters.clientId ? filters.clientId : ''}
              onChange={(e) => {
                let value = e.target.value
                setFilters({ ...filters, clientId: value })
              }}
            />
          </div>
          {getPermissionValue(
            PermissionFeatures.WEB_PAGES,
            PermissionActions.WANTED_LEADS_PAGE_VIEW
          ) && (
            <div>
              <input
                type="number"
                placeholder="Wanted Lead ID"
                className={clsx(classes.textbox, filters.wantedLeadId ? classes.selected : '')}
                value={filters.wantedLeadId ? filters.wantedLeadId : ''}
                onChange={(e) => {
                  let value = e.target.value
                  setFilters({ ...filters, wantedLeadId: value })
                }}
              />
            </div>
          )}
          {getPermissionValue(
            PermissionFeatures.WEB_PAGES,
            PermissionActions.PROJECT_LEADS_PAGE_VIEW
          ) && (
            <div>
              <input
                type="number"
                placeholder="Project Lead ID"
                className={clsx(classes.textbox, filters.projectLeadId ? classes.selected : '')}
                value={filters.projectLeadId ? filters.projectLeadId : ''}
                onChange={(e) => {
                  let value = e.target.value
                  setFilters({ ...filters, projectLeadId: value })
                }}
              />
            </div>
          )}
          {getPermissionValue(
            PermissionFeatures.WEB_PAGES,
            PermissionActions.BUYRENT_LEADS_PAGE_VIEW
          ) && (
            <div>
              <input
                type="number"
                placeholder="Buy/Rent Lead ID"
                className={clsx(classes.textbox, filters.buyRentLeadId ? classes.selected : '')}
                value={filters.buyRentLeadId ? filters.buyRentLeadId : ''}
                onChange={(e) => {
                  let value = e.target.value
                  setFilters({ ...filters, buyRentLeadId: value })
                }}
              />
            </div>
          )}
          <div
            className={
              'followUpDateRange ' +
              (filters.followUpFromDate && filters.followUpToDate ? 'selectedDateRange' : '')
            }
          >
            <DateRangePicker
              isOutsideRange={() => false}
              startDatePlaceholderText={'Follow Up Date'}
              endDatePlaceholderText={''}
              startDate={filters.followUpFromDate}
              endDate={filters.followUpToDate}
              startDateId="client_date_input_start"
              minimumNights={0}
              endDateId="client_date_input_end"
              onDatesChange={({ startDate: dateStart, endDate: dateEnd }) => {
                // let startDate = dateStart ? moment(dateStart).format() : filters.startDate
                // let endDate = dateEnd ? moment(dateEnd).format() : filters.endDate
                if (dateStart) {
                  setFilters((filter) => ({
                    ...filter,
                    followUpFromDate: dateStart,
                  }))
                }
                if (dateEnd) {
                  setFilters((filter) => ({
                    ...filter,
                    followUpToDate: dateEnd,
                  }))
                }
              }}
              focusedInput={focusedFollowInput}
              onFocusChange={(input) => setFocusedFollowInput(input)}
              showClearDates
            />
          </div>
          <div>
            <Select
              isLoading={isClientLoading}
              onInputChange={(change) => {
                if (change) fetchClientNames(change)
              }}
              value={filters.clientName ? filters.clientName : null}
              styles={filters.clientName ? customStyles : fieldHeight}
              options={clientNames}
              noOptionsMessage={() => 'No client name found'}
              placeholder="Client Name"
              onChange={(value) => {
                setFilters({ ...filters, clientName: value })
              }}
            />
          </div>
          <div>
            <input
              type="number"
              placeholder="Client Contact Number"
              className={clsx(classes.textbox, filters.clientContact ? classes.selected : '')}
              value={filters.clientContact ? filters.clientContact : ''}
              onChange={(e) => {
                let value = e.target.value
                setFilters({ ...filters, clientContact: value })
              }}
            />
          </div>
        </div>
        <hr></hr>
        <div className={classes.btnContainer}>
          <div>
            <Button
              text="Search"
              buttonColor="#026ff2"
              textColor="white"
              action="search"
              width="80px"
              className="searchBtn"
              onClick={() => {
                props.onSearch({ filters })
              }}
            ></Button>
          </div>
          <div>
            <Button
              text="Clear"
              buttonColor="#ffffff"
              action="details"
              className="detailsBtn"
              textColor="#2e96f5"
              width="80px"
              onClick={() => {
                props.onClear()
              }}
            ></Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

// export const mapDispatchToProps = (dispatch) => {
//   return {
//     fetchAllClients: (clientName) => dispatch(fetchAllClients(clientName)),
//   }
// }

// export const mapStateToProps = (state) => {
//   return {
//     isClientFilterLoading: state.Leads.isClientFilterLoading,
//   }
// }

export default DiaryFilters
