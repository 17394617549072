/** @format */

const _ = require('lodash')
const moment = require('moment')
const Swal = require('sweetalert2')
const commonFormat = require('./commonFormat')

const docsValidationHtml = (docs, type) => {
  let docsValidationHtml = ''
  let category = ''
  docs.map((doc) => {
    if (
      doc.status !== 'approved' &&
      (doc.category !== 'police_verification_report_optional' || doc.status === 'uploaded')
    ) {
      category = doc.category === 'cnic' ? 'CNIC' : doc.category && doc.category.replace(/_/g, ' ')
      docsValidationHtml += `<li>${type} ${commonFormat.capitalizeWordsWithoutUnderscore(
        category
      )} is not approved </li>`
    }
  })
  return docsValidationHtml
}

const General = {
  boldQuery: (str, query) => {
    const n = str.toUpperCase()
    const q = query.toUpperCase()
    const x = n.indexOf(q)
    if (!q || x === -1) {
      return str // bail early
    }
    const l = q.length
    return str.substr(0, x) + '<b>' + str.substr(x, l) + '</b>' + str.substr(x + l)
  },
  extractSelectArray: (array) => {
    if (array && array.length > 0) {
      return array && array.map((item) => item.value)
    } else {
      return
    }
  },
  MakeMarlaRangerList: (LeadDetail, List) => {

    let findIndexForSize =LeadDetail&&LeadDetail.size_unit!==null? _.findLastIndex(List.sizeUnit, function (o) {
      return o.value == LeadDetail.size_unit.toString()
    }):0

    return {
      size_min:
        List.unitRange[List.sizeUnit[findIndexForSize].value][
          List.unitRange[List.sizeUnit[findIndexForSize].value].indexOf(
            LeadDetail && LeadDetail.size
          )
        ],
      min:
        List.unitRange[List.sizeUnit[findIndexForSize].value][
          List.unitRange[List.sizeUnit[findIndexForSize].value].indexOf(
            LeadDetail && LeadDetail.size
          )
        ],
      size_max:
        List.unitRange[List.sizeUnit[findIndexForSize].value][
          List.unitRange[List.sizeUnit[findIndexForSize].value].indexOf(
            LeadDetail && LeadDetail.max_size
          )
        ],
      max:
        List.unitRange[List.sizeUnit[findIndexForSize].value][
          List.unitRange[List.sizeUnit[findIndexForSize].value].indexOf(
            LeadDetail && LeadDetail.max_size
          )
        ],
      unit: List.sizeUnit[findIndexForSize],
    }
  },
  MakeBathRangerList: (LeadDetail, List) => {
    return {
      minVal: List[List.indexOf(LeadDetail && LeadDetail.bath)],
      maxVal: List[List.indexOf(LeadDetail && LeadDetail.maxBath)],
      min: List[List.indexOf(LeadDetail && LeadDetail.bath)],
      max: List[List.indexOf(LeadDetail && LeadDetail.maxBath)],
    }
  },
  MakeBedRangerList: (LeadDetail, List) => {
    return {
      minVal: List[List.indexOf(LeadDetail && LeadDetail.bed)],
      maxVal: List[List.indexOf(LeadDetail && LeadDetail.maxBed)],
      min: List[List.indexOf(LeadDetail && LeadDetail.bed)],
      max: List[List.indexOf(LeadDetail && LeadDetail.maxBed)],
    }
  },
  MakePriceRangerList: (LeadDetail, List) => {
    let anyValue = 10000000000
    return {
      price_min: LeadDetail && LeadDetail.min_price,
      price_max: LeadDetail && LeadDetail.price === 0 ? anyValue : LeadDetail && LeadDetail.price,
      min: LeadDetail && LeadDetail.purpose && LeadDetail.min_price && List[LeadDetail && LeadDetail.purpose] && List[LeadDetail && LeadDetail.purpose].length ? List[LeadDetail && LeadDetail.purpose].indexOf(LeadDetail && LeadDetail.min_price) : 0,
      max:
      LeadDetail && LeadDetail.purpose && LeadDetail.price && List[LeadDetail && LeadDetail.purpose] && List[LeadDetail && LeadDetail.purpose].length && List[LeadDetail && LeadDetail.purpose].indexOf(LeadDetail && LeadDetail.price) === 0
          ? anyValue
          : LeadDetail && LeadDetail.purpose && LeadDetail.price && List[LeadDetail && LeadDetail.purpose] && List[LeadDetail && LeadDetail.purpose].length ? List[LeadDetail && LeadDetail.purpose].indexOf(LeadDetail && LeadDetail.price) : 10000000000,
    }
  },
  RenderLeadStatus: (Status, LeadStatus, buyRent) => {
    let LeadStatusValue = _.find(LeadStatus, { value: Status })
    switch (Status) {
      case 'follow_up':
        return buyRent ? 'Shortlisting' : 'In-Progress'
      case 'meeting':
        return 'In-Progress'
      case 'in_progress':
        return 'In-Progress'
      case 'refund':
        return 'Refund'
      case 'buyback':
        return 'Buy/Back'
      case 'intermall adjustment':
        return 'Inter mall Adjustment'
      case 'pending_token':
        return 'Pending Token'
      case 'shortlisting':
        return 'Shortlisting'
      case 'offer':
        return 'Shortlisting'
      case 'viewing':
        return 'Shortlisting'
      case 'propsure':
        return 'Shortlisting'
      default:
        return LeadStatusValue && LeadStatusValue.label
    }
  },
  MakeShortListing: (status) => {
    let obj = {
      viewing: 'Shortlisting',
      propsure: 'Shortlisting',
      in_progress: 'Shortlisting',
      offer: 'Shortlisting',
      shortlisting: 'Shortlisting',
    }

    return obj[status]
  },
  MakeProjectLeadListing: (status) => {
    let obj = {
      in_progress: 'In Progress',
      pending_token: 'Pending Token',
    }

    return obj[status]
  },
  CommentFormat: (comment) => {
    if (comment && comment.length > 0) {
      return {
        name: 'Dummy User',
        date: moment(comment.createdAt).fromNow(),
        comment: comment.value,
      }
    } else {
      return []
    }
  },
  OrderBy: (array, values, by) => {
    return _.orderBy(array, values, by)
  },

  AddPropsureReportsFee(pendingPropsures, type) {
    let total = 0
    if (pendingPropsures && pendingPropsures.length) {
      pendingPropsures.map((item) => {
        if (item.propsureReport && item.propsureReport.fee && item.addedBy === type) {
          total = Number(total) + Number(item.propsureReport.fee)
        }
      })
      return total
    } else {
      return 0
    }
  },
  propsurePaymentType(property, type) {
    let singlePayment = null
    if (property && property.cmInstallments && property.cmInstallments.length) {
      property.cmInstallments.map((item) => {
        if (item.addedBy === type && item.paymentCategory === 'propsure_services')
          singlePayment = item
      })
      return singlePayment
    } else return singlePayment
  },
  propsurePendingStatuses(property) {
    if (property) {
      let { propsures, cmInstallments } = property

      if (propsures && propsures.length > 0) {
        let checkForPropsurePending = _.every(propsures, { status: 'verified' })
        let total_amount = _.sumBy(propsures, function (item) {
          return item.serviceFee
        })

        if (!checkForPropsurePending) {
          return 'Propsure Requested'
        } else if (
          (checkForPropsurePending &&
            cmInstallments &&
            cmInstallments.length === 0 &&
            total_amount > 0) ||
          (checkForPropsurePending &&
            cmInstallments &&
            cmInstallments[0] &&
            cmInstallments[0].status !== 'cleared' &&
            total_amount > 0)
        ) {
          return 'Propsure Payment Pending'
        } else if (
          (cmInstallments &&
            cmInstallments[0] &&
            cmInstallments[0].status === 'cleared' &&
            checkForPropsurePending) ||
          total_amount === 0
        ) {
          return 'Propsure Completed'
        } else {
          return false
        }
      }
    }
  },

  checkClearedStatuses(lead, legalDocCounts, legalServicesFee) {
    let check = false
    let paymentCheck = true
    let propsureCheck = true
    let legalServicesCheck = true
    let commissionsLength = 2
    let legalServicesCount = 2
    let cleared = 0
    let legalPaymentCleared = 0
    let legalCount = Number(legalDocCounts.sellerCount) + Number(legalDocCounts.buyerCount)
    if (lead.commissionNotApplicableSeller === true) legalCount = Number(legalDocCounts.buyerCount)

    let legalDocCount = legalDocCounts.count
    if (lead.commissionNotApplicableBuyer === true || lead.commissionNotApplicableSeller === true) {
      commissionsLength = 1
      legalServicesCount = 1
    }
    const { commissions, propsureOutstandingPayment, sellerLegalMail, legalMailSent } = lead
    if (!lead.commissionNotApplicableSeller && !sellerLegalMail)
      legalServicesCount = legalServicesCount - 1
    if (!lead.commissionNotApplicableBuyer && !legalMailSent)
      legalServicesCount = legalServicesCount - 1
    if (legalServicesFee && legalServicesFee.fee <= 0) legalServicesCount = 0
    if (commissions && commissions.length) {
      commissions.map((item) => {
        if (item.status === 'cleared' && item.paymentCategory === 'commission') cleared++
        if (item.status === 'cleared' && item.paymentCategory === 'legal_payment')
          legalPaymentCleared++
        if (item.status !== 'cleared' && item.paymentCategory === 'commission') paymentCheck = false
        if (item.status !== 'cleared' && item.paymentCategory === 'propsure_services')
          propsureCheck = false
        if (item.status !== 'cleared' && item.paymentCategory === 'legal_payment')
          legalServicesCheck = false
      })
      if (
        paymentCheck &&
        propsureCheck &&
        legalServicesCheck &&
        propsureOutstandingPayment <= 0 &&
        cleared === commissionsLength &&
        legalPaymentCleared === legalServicesCount &&
        Number(legalDocCount) === Number(legalCount)
      )
        check = true
      return check
    } else return check
  },

  checkCloseWon(lead, buyerDocs, sellerDocs, legalServicesFee, user) {
    let isBuyerCommissionNotClear = false
    let isBuyerLegalPaymentNotClear = false
    let isBuyerPropsureServiceNotClear = false
    let isSellerCommissionNotClear = false
    let isSellerLegalPaymentNotClear = false
    let isSellerPropsureServiceNotClear = false
    let buyerCommissionPaymentFound = false
    let sellerCommissionPaymentFound = false

    let checkForDocuments = true
    // if (user && user.organization && user.organization.type === 'Franchise') {
    //   checkForDocuments = false
    // }
    const { commissions, propsureOutstandingPayment } = lead
    if (commissions && commissions.length) {
      commissions.map((item) => {
        if (item.addedBy === 'buyer') {
          if (item.paymentCategory === 'commission') buyerCommissionPaymentFound = true
          if (item.status !== 'cleared') {
            if (item.paymentCategory === 'commission') isBuyerCommissionNotClear = true
            else if (item.paymentCategory === 'legal_payment') isBuyerLegalPaymentNotClear = true
            else if (item.paymentCategory === 'propsure_services')
              isBuyerPropsureServiceNotClear = true
          }
        } else if (item.addedBy === 'seller') {
          if (item.paymentCategory === 'commission') sellerCommissionPaymentFound = true
          if (item.status !== 'cleared') {
            if (item.paymentCategory === 'commission') isSellerCommissionNotClear = true
            else if (item.paymentCategory === 'legal_payment') isSellerLegalPaymentNotClear = true
            else if (item.paymentCategory === 'propsure_services')
              isSellerPropsureServiceNotClear = true
          }
        }
      })
    }

    let paymentsValidationHtml = ''
    let documentsValidationHtml = ''

    if (lead.commissionNotApplicableBuyer === false) {
      if (isBuyerCommissionNotClear || !buyerCommissionPaymentFound)
        paymentsValidationHtml += `<li>Buyer advisor's commission payment is not cleared </li>`
      if (isBuyerLegalPaymentNotClear)
        paymentsValidationHtml += `<li>Buyer advisor's legal payment is not cleared</li>`
      if (isBuyerPropsureServiceNotClear)
        paymentsValidationHtml += `<li>Buyer advisor's propsure payment is not cleared</li>`
      if (propsureOutstandingPayment > 0)
        paymentsValidationHtml += `<li>Buyer advisor's propsure outstanding payment is not cleared</li>`

      if (checkForDocuments && buyerDocs && buyerDocs.length)
        documentsValidationHtml += docsValidationHtml(buyerDocs, "Buyer client's")
    }

    if (lead.commissionNotApplicableSeller === false) {
      if (isSellerCommissionNotClear || !sellerCommissionPaymentFound)
        paymentsValidationHtml += `<li>Seller advisor's commission payment is not cleared</li>`
      if (isSellerLegalPaymentNotClear)
        paymentsValidationHtml += `<li>Seller advisor's legal payment is not cleared</li>`
      if (isSellerPropsureServiceNotClear)
        paymentsValidationHtml += `<li>Seller advisor's propsure payment is not cleared</li>`

      if (checkForDocuments && sellerDocs && sellerDocs.length)
        documentsValidationHtml += docsValidationHtml(sellerDocs, "Seller client's")
    }

    let allErrors = ''
    if (paymentsValidationHtml.length)
      allErrors += `<div><strong>Payments</strong><br/><ul style="list-style-type: disc">${paymentsValidationHtml}</ul></div><br/>`
    if (checkForDocuments && documentsValidationHtml.length)
      allErrors += `<div><strong>Legal Documents</strong><br/><ul style="list-style-type: disc">${documentsValidationHtml}</ul></div><br/>`

    if (allErrors.length) {
      Swal.fire({
        title: '',
        html: `<div class='text-left'>Unable to close this lead as won because of following reasons: <br/> <br/> ${allErrors}</div>`,
        icon: 'error',
        width: 700,
      })
      return false
    }
    return true
  },
}

module.exports = General
