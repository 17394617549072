/** @format */

import React, { Component, useEffect, useState, useRef } from 'react'
import { DateRangePicker } from 'react-dates'
import './style.css'
import {
  newFormatPrice,
  formatPriceRange,
  IpformatPriceRange,
  formatUnitPriceRange,
  projectInvestmentPriceRange,
  formatFrequencyIp,
  formatInstallmentRange,
} from '../../utils/formatPrice'
import { formatSizeRange } from '../../utils/formatSize'
import { formatBedBathRange } from '../../utils/formatBedBath'
import { DatePicker } from 'react-rainbow-components'
import Select, { components } from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { sizeRange } from '../../utils/constant_data'
import _, { values } from 'lodash'
import InputRange from 'react-input-range'
import 'react-input-range/lib/css/index.css'
import moment from 'moment'
import Swal from 'sweetalert2'

import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import './form-style.scss'
import { unitPriceRange } from '../../utils/constant_data'
import PhoneInput from 'react-phone-input-2'
import { InvestmentRangeMasking } from '../../utils/InvestmentRangeMasking'
import { removeZerowithoutPlus } from '../../utils/validate'
import { ListMasking, RenderList } from '../../utils/SelectMethods'
import { getPermissionByAction } from '../../hooks/Helper'
export const normalizeCnic = (value) => {
  if (!value) {
    return value
  }
  const onlyNums = value && value.toString().replace(/[^\d]/g, '')
  if (onlyNums.length <= 5) {
    return onlyNums
  }
  if (onlyNums.length <= 12) {
    return `${onlyNums.slice(0, 5)}-${onlyNums.slice(5)}`
  }
  return `${onlyNums.slice(0, 5)}-${onlyNums.slice(5, 12)}-${onlyNums.slice(12, 13)}`
}

export const normalizeFloorPrice = (Num) => {
  let x, x1, x2
  Num += ''
  Num = Num.replace(',', '')
  Num = Num.replace(',', '')
  Num = Num.replace(',', '')
  Num = Num.replace(',', '')
  Num = Num.replace(',', '')
  Num = Num.replace(',', '')
  Num = Num.replace(/[^\d]/g, '')
  x = Num.split('.')
  x1 = x[0]
  x2 = x.length > 1 ? '.' + x[1] : ''
  var rgx = /(\d+)(\d{3})/
  while (rgx.test(x1)) x1 = x1.replace(rgx, '$1' + ',' + '$2')
  return x1 + x2
}

export const normalizeNumber = (value) => {
  if (!value) return value
  if (!value.replace) return value
  const onlyNums = value.replace(/[^\d]/g, '')
  if ((onlyNums && onlyNums.length > 3) || onlyNums > 100 || onlyNums < 0) {
    return
  } else {
    return onlyNums
  }
}

export const customDropdown = ({ type, label, input, options, meta: { touched, error } }) => {
  const { ValueContainer, Placeholder } = components
  const CustomValueContainer = ({ children, ...props }) => {
    return (
      <ValueContainer {...props}>
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, (child) =>
          child && child.type !== Placeholder ? child : null
        )}
      </ValueContainer>
    )
  }
  return (
    <div className="inputform options">
      <CreatableSelect
        components={{
          ValueContainer: CustomValueContainer,
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
        }}
        styles={{
          container: (provided, state) => ({
            ...provided,
          }),

          valueContainer: (provided, state) => ({
            ...provided,
            overflow: 'visible',
          }),

          control: (base, state) => ({
            ...base,
            boxShadow: 'none',
            border: '1px solid #ced4da',
          }),
          placeholder: (provided, state) => ({
            ...provided,
            position: 'absolute',
            transitions: '0.5s',
            top: state.hasValue || state.selectProps.inputValue ? -1 : '50%',
            transition: 'top 0.1s, font-size 0.1s',
            fontSize: (state.hasValue || state.selectProps.inputValue) && 13,
          }),
        }}
        isMulti
        options={options}
        noResultsText={() => null}
        placeholder="Dropdown Value"
        value={input.value}
        onChange={(value) => input.onChange(value)}
        noOptionsMessage={() => null}
      />
      {touched && error && <p className="price-box">{error}</p>}
    </div>
  )
}

export const normalizeFloat = (value) => {
  value = value.replace(/\b(100+)/, '')
  const sections = value.split('.')
  return sections
}

export const cnicFormat = (value) => {
  let st = JSON.stringify(value)
  const onlyNums = st.replace(/[^\d]/g, '')
  if (onlyNums.length <= 5) {
    return onlyNums
  }
  if (onlyNums.length <= 12) {
    return `${onlyNums.slice(0, 5)}-${onlyNums.slice(5)}`
  }
  return `${onlyNums.slice(0, 5)}-${onlyNums.slice(5, 12)}-${onlyNums.slice(12, 13)}`
}
export const disabledField = ({ type, label, input, meta: { touched, error } }) => {
  return (
    <div className="inputform">
      {' '}
      <input
        className="form-control input-box"
        {...input}
        type={type}
        placeholder={label}
        readOnly="readonly"
      />
    </div>
  )
}

export class singleDatePickerComponent extends Component {
  state = {
    date: new Date(),
  }

  render() {
    const { input } = this.props

    return (
      <div className="rainbow-p-vertical_large rainbow-p-horizontal_xx-large rainbow-m-horizontal_xx-large">
        <DatePicker
          value={input.value || this.state.date}
          onChange={(value) => {
            input.onChange(value)
            this.setState({ date: value })
          }}
        />
      </div>
    )
  }
}
export class DateRanger extends Component {
  constructor(props) {
    super(props)
    this.state = {
      startDate: null,
      endDate: null,
      focusedInput: null,
    }
  }

  onFocusChange = (value) => {
    this.setState({ focused: !this.state.focused })
    const { input } = this.props
    input.onFocus(value)
  }

  render() {
    const { startDate, endDate, focusedInput } = this.state
    const { input, editing } = this.props
    return (
      <div className="defaultBorderColor">
        <DateRangePicker
          isOutsideRange={() => false}
          startDate={
            startDate !== null ? startDate : editing ? moment(input.value.startDate) : startDate
          }
          className="activeDatePicker"
          value={input.value}
          endDate={endDate !== null ? endDate : editing ? moment(input.value.endDate) : endDate}
          startDateId="date_input_start"
          endDateId="date_input_end"
          onDatesChange={({ startDate: dateStart, endDate: dateEnd }) => {
            input.onChange({
              startDate: moment(dateStart).format('YYYY-MM-DD'),
              endDate: moment(dateEnd).format('YYYY-MM-DD'),
            })
            this.setState(() => {
              return {
                startDate: dateStart,
                endDate: dateEnd,
              }
            })
          }}
          numberOfMonth={1}
          focusedInput={focusedInput}
          onFocusChange={(input) => this.setState({ focusedInput: input })}
          showClearDates
        />
      </div>
    )
  }
}
export class renderField extends Component {
  constructor(props) {
    super(props)
    this.state = {
      inputValue: '',
      fieldActive: false,
      colorChange: false,
    }
  }

  activeField = () => {
    this.setState({
      fieldActive: true,
      colorChange: false,
    })
  }

  disableFocus = (e) => {
    if (e.target.value === '') {
      this.setState({
        fieldActive: false,
      })
    }
    this.setState({
      colorChange: true,
    })
  }

  updateInputValue = (e) => {
    this.setState({
      inputValue: e.target.value,
    })
    this.activateField(e)
    e.preventDefault()
  }
  render() {
    const { fieldActive } = this.state
    const {
      type,
      value,
      editing,
      label,
      height,
      input,
      min,
      fieldId,
      customValue,
      adminInner,
      max,
      meta: { touched, error },
    } = this.props
    if (input.value) {
      type === 'date'
        ? input.onChange(moment(input.value || '').format('YYYY-MM-DD'))
        : input.onChange(input.value)
    }
    const dateProps =
      type === 'date'
        ? {
            min,
            max,
            value: moment(input.value).format('YYYY-MM-DD'),
            onChange: (e) => {
              input.onChange(moment(e.target.value))
            },
          }
        : null

    return (
      <div className="inputform newForm">
        <input
          className={
            height
              ? `form-control input-box focusText fieldHeight`
              : `form-control input-box focusText`
          }
          id={fieldId}
          {...input}
          {...dateProps}
          onFocus={() => this.activeField()}
          onBlur={(e) => this.disableFocus(e)}
          type={type}
          placeholder={fieldActive && !fieldActive ? '' : label}
          disabled={customValue}
          autoComplete="new-password"
          style={{padding: "20px 15px 20px 15px", borderRadius: "10px"}}
        />
        <span className={this.state.colorChange ? 'label-container-disabled ' : 'label-container'}>
          {(this.state.fieldActive && fieldId) || editing ? (
            <label className="field-active">{label}</label>
          ) : null}
        </span>

        {touched && error && <p className="price-box user-validation">{error}</p>}
      </div>
    )
  }
}

export class renderFieldBooking extends Component {
  constructor(props) {
    super(props)
    this.state = {
      inputValue: '',
      fieldActive: false,
      colorChange: false,
    }
  }

  activeField = () => {
    this.setState({
      fieldActive: true,
      colorChange: false,
    })
  }

  disableFocus = (e) => {
    if (e.target.value === '') {
      this.setState({
        fieldActive: false,
      })
    }
    this.setState({
      colorChange: true,
    })
  }

  updateInputValue = (e) => {
    this.setState({
      inputValue: e.target.value,
    })
    this.activateField(e)
    e.preventDefault()
  }
  render() {
    const { fieldActive } = this.state
    const {
      type,
      value,
      editing,
      label,
      height,
      input,
      min,
      fieldId,
      customValue,
      disabled,
      adminInner,
      max,
      meta: { touched, error },
    } = this.props


    return (
      <div className="inputform newForm">
        <input
          className={
            height
              ? `form-control input-box focusText fieldHeight`
              : `form-control input-box focusText`
          }
          id={fieldId}
          {...input}
          onFocus={() => this.activeField()}
          onBlur={(e) => this.disableFocus(e)}
          type={type}
          disabled={disabled}
          placeholder={fieldActive && !fieldActive ? '' : label}
          value={customValue}
          style={{padding: "20px 15px 20px 15px", borderRadius: "10px"}}

        />
        <span className={this.state.colorChange ? 'label-container-disabled ' : 'label-container'}>
          {(this.state.fieldActive && fieldId) || editing ? (
            <label className="field-active">{label}</label>
          ) : null}
        </span>

        {touched && error && <p className="price-box user-validation">{error}</p>}
      </div>
    )
  }
}

export const productDescription = ({ type, label, input, rows }) => {
  return (
    <textarea
      rows={rows}
      value={input.value}
      className="description-area"
      placeholder="Description"
      onChange={(e) => input.onChange(e.target.value)}
    />
  )
}
export const shadowAccess = ({ showLinkAccess, type, label, input }) => {
  return (
    <>
      {showLinkAccess ? (
        <div className={`shadowCheckbox`}>
          <a onClick={(e) => input.onChange('shadow_access')} className="accesslink">
            + Add linked Access
          </a>
        </div>
      ) : null}
    </>
  )
}

export const priceField = ({ type, label, input, meta: { touched, error } }) => {
  var pricebubble = newFormatPrice(input.value)
  return (
    <div className="text-container">
      <input
        className="form-control input-box"
        {...input}
        type={type}
        placeholder={label}
        autoComplete="off"
      />

      {pricebubble && pricebubble.length > 4 && (
        <span id="clearBtn1" className="clearBtn">
          {' '}
          {pricebubble}{' '}
        </span>
      )}
      {touched && error && <p className="price-box">{error}</p>}
    </div>
  )
}

export const ratePerSqFt = ({
  type,
  label,
  standardRate,
  catCharges,
  hasDisabled,
  input,
  meta: { touched, error },
}) => {
  var standardRateFormattedValue = standardRate && standardRate.replace(/,/g, '')
  const catFormattedCharges = catCharges && catCharges

  const formattedValue = standardRateFormattedValue * (parseInt(catFormattedCharges, 10) + 1)

  return (
    <div className="text-container">
      <input
        className="form-control input-box"
        {...input}
        type={type}
        value={standardRate ? formattedValue : input.value}
        disabled={hasDisabled}
        placeholder={label}
        autoComplete="off"
      />

      {touched && error && <p className="price-box">{error}</p>}
    </div>
  )
}

export const unitPrice = ({
  type,
  label,
  rate,
  size,
  hasDisabled,
  input,
  meta: { touched, error },
}) => {
  var standardRateFormattedValue = rate && rate.replace(/,/g, '')
  const hasSize = size && size

  const formattedValue = parseInt(standardRateFormattedValue * hasSize, 10)

  return (
    <div className="text-container">
      <input
        className="form-control input-box"
        {...input}
        type={type}
        value={formattedValue ? formattedValue : label}
        disabled={hasDisabled}
        placeholder={label}
        autoComplete="off"
      />

      {touched && error && <p className="price-box">{error}</p>}
    </div>
  )
}

export const dummyRender = ({ type, label, hasDisabled, input, meta: { touched, error } }) => {
  return (
    <div className="text-container">
      <input
        className="form-control input-box"
        {...input}
        type={type}
        disabled={hasDisabled}
        placeholder={label}
        autoComplete="off"
      />

      {touched && error && <p className="price-box">{error}</p>}
    </div>
  )
}

export const discountPrice = ({
  type,
  label,
  unitPrice,
  discountPrice,
  hasDisabled,
  input,
  meta: { touched, error },
}) => {
  const formattedValue = unitPrice - discountPrice

  return (
    <div className="text-container">
      <input
        className="form-control input-box"
        {...input}
        type={type}
        value={formattedValue ? formattedValue : label}
        disabled={hasDisabled}
        placeholder={label}
        autoComplete="off"
      />

      {touched && error && <p className="price-box">{error}</p>}
    </div>
  )
}

export const renderReservationField = ({
  type,
  label,
  rate,
  size,
  discount,
  downPayment,
  possessessionCharges,
  customValue,
  hasDisabled,
  installmentYear,
  input,
  FullPaymentDiscount,
  discountedAmount,
  meta: { touched, error },
}) => {
  let formattedValue
  let unitPrice = rate * size
  let discountedPrice = unitPrice - discountedAmount

  if (customValue === 'down_payment') {
    formattedValue = (unitPrice - discount) * downPayment
  } else if (customValue === 'installement_amount') {
    formattedValue = unitPrice - discount - possessessionCharges
  } else if (customValue === 'quarterly_installments') {
    formattedValue = installmentYear / 4
  } else if (customValue === 'monthly_installments') {
    formattedValue = (installmentYear / 12).toFixed(2)
  } else if (customValue === 'possession_charges') {
    formattedValue = (unitPrice - discount) * possessessionCharges
  } else if (customValue === 'full_payment_price') {
    formattedValue = discountedPrice * (1 - FullPaymentDiscount)
  }

  return (
    <div className="text-container">
      <input
        className="form-control input-box"
        {...input}
        type={type}
        value={formattedValue ? formattedValue : label}
        disabled={hasDisabled}
        placeholder={label}
        autoComplete="off"
      />

      {touched && error && <p className="price-box">{error}</p>}
    </div>
  )
}

export const renderPossessioncharges = ({
  type,
  label,
  size,
  rate,
  discount,
  possessessionCharges,
  hasDisabled,
  input,
  meta: { touched, error },
}) => {
  const unitPrice = size * rate

  const formattedValue = (unitPrice - discount) * possessessionCharges
  return (
    <div className="text-container">
      <input
        className="form-control input-box"
        {...input}
        type={type}
        value={formattedValue ? formattedValue : label}
        disabled={hasDisabled}
        placeholder={label}
        autoComplete="off"
      />

      {touched && error && <p className="price-box">{error}</p>}
    </div>
  )
}

export class percentageField extends Component {
  constructor(props) {
    super(props)
    this.state = {
      inputValue: '',
      fieldActive: false,
      colorChange: false,
    }
  }

  activeField = () => {
    this.setState({
      fieldActive: true,
      colorChange: false,
    })
  }

  disableFocus = (e) => {
    if (e.target.value === '') {
      this.setState({
        fieldActive: false,
      })
    }
    this.setState({
      colorChange: true,
    })
  }

  updateInputValue = (e) => {
    this.setState({
      inputValue: e.target.value,
    })
    this.activateField(e)
    e.preventDefault()
  }
  render() {
    const {
      type,
      label,
      input,
      fieldId,
      customPercentage,
      editing,
      withoutZero,
      meta: { touched, error },
      customValue,
      disabled,
    } = this.props
    return (
      <div className="text-container">
        <input
          className={
            disabled ? 'form-control input-box disabled-focus-text' : 'form-control input-box'
          }
          {...input}
          type={type}
          disabled={customValue}
          placeholder={this.state.fieldActive ? null : label}
          autoComplete="off"
          onFocus={() => this.activeField()}
          onBlur={(e) => this.disableFocus(e)}
        />
        <span className={this.state.colorChange ? 'label-container-disabled' : 'label-container'}>
          {(this.state.fieldActive && fieldId) || editing ? (
            <label className="field-active">{label}</label>
          ) : null}
        </span>
        {(input.value.length > 0 && withoutZero) || (editing && customPercentage) ? (
          <span id="clearBtn1" className="clearBtn">
            {' '}
            {`${input.value} %`}{' '}
          </span>
        ) : null}
        {touched && error && <p className="price-box">{error}</p>}
      </div>
    )
  }
}

export const SelectField = ({ input, data, meta: { touched, error }, children }) => (
  <div className="field">
    <div className="control">
      <select className="form-control input-box" {...input}>
        <option>Select property type</option>
        {data.map((each, index) => (
          <option value={each} key={index}>
            {each}
          </option>
        ))}
      </select>

      {touched && error && (
        <p className="price-box" style={{ marginBottom: '10px' }}>
          {error}
        </p>
      )}
    </div>
  </div>
)

export const description = ({ type, label, input, meta: { touched, error } }) => {
  return (
    <div className="inputform">
      <textarea
        className="form-control textarea-box"
        {...input}
        type={type}
        placeholder={label}
        autoComplete="off"
      />
      {touched && error && <p className="price-box">{error}</p>}
    </div>
  )
}
export const isPhone = (value, format) => {
  if (value) {
    // let expectedLength = format.split('.').length - 1
    // console.log('val: ', value, ' format: ', format, expectedLength, '   ', value.length)

    // return expectedLength != value.length ? 'Please enter a valid phone number' : undefined
    return value.length < 12 ? 'Please enter a valid phone number' : undefined
  }
}
export const isCnic = (value) => {
  return /\d{5}-\d{7}-\d{1}/.test(value) ? undefined : 'Please enter a valid cnic number'
}

export const normalizePhone = (value) => {
  if (value.length === 1 && parseInt(value) === 0) {
    return
  }
  if (!value) {
    return value
  }
  // let ne = '0'+ value
  let st = JSON.stringify(value)
  const onlyNums = st.replace(/[^\d]/g, '')
  if (onlyNums.length <= 8) {
    return `${onlyNums.slice(0, 3)}${onlyNums.slice(3)}`
  }
  return `${onlyNums.slice(0, 3)}${onlyNums.slice(3, 6)}${onlyNums.slice(6, 10)}`
}

export const normalizeEmployeeCode = (value) => {
  if (value.length === 1 && parseInt(value) === 0) {
    return
  }
  if (!value) {
    return value
  }
  // let ne = '0'+ value
  let st = JSON.stringify(value)
  const onlyNums = st.replace(/[^\d]/g, '')
  if (onlyNums.length <= 8) {
    return `${onlyNums.slice(0, 3)}${onlyNums.slice(3)}`
  }
  return `${onlyNums.slice(0, 3)}${onlyNums.slice(3, 6)}${onlyNums.slice(6, 8)}`
}

export default normalizePhone

export const requirementDropdown = ({ type, label, input, meta: { touched, error } }) => {
  return (
    <div className="inputform">
      <select className="form-control input-box" {...input} required>
        <option value="">-Requirement Type-</option>
        <option value="buy">Buy/Sale</option>
        <option value="rent">Rent</option>
      </select>
      {touched && error && <p className="price-box">{error}</p>}
    </div>
  )
}

export const taskTypeDD = ({ type, label, input, meta: { touched, error } }) => {
  return (
    <div className="inputform">
      <select className="form-control input-box" {...input} required>
        <option value="">Task Type</option>
        <option value="Meeting">Meeting</option>
        <option value="FollowUp">Follow Up</option>
        <option value="Viewing">Viewing</option>
        <option value="reminder">Reminder</option>
        <option value="other">Other</option>
      </select>
      {touched && error && <p className="price-box">{error}</p>}
    </div>
  )
}

export const unitAvail = ({ type, label, input, meta: { touched, error } }) => {
  return (
    <div className="inputform">
      <select className="form-control input-box" {...input} required>
        <option value="">Status</option>
        <option value="Available">Available</option>
        <option value="Not Available">Not Available</option>
      </select>
      {touched && error && <p className="price-box">{error}</p>}
    </div>
  )
}

// export const projectType = ({
//   type,
//   label,
//   input,
//   meta: { touched, error },
// }) => {
//   return (
//     <div className="inputform options">
//       <select className="form-control input-box" {...input} required>
//         <option value="">ProjectLead Type</option>
//         <option value="mall">Mall</option>
//         <option value="hostel">Hostel</option>
//       </select>
//       {touched && error && <p className="price-box">{error}</p>}
//     </div>
//   );
// };

export const unitStatus = ({ type, label, input, meta: { touched, error } }) => {
  const options = [
    { value: 'Available', label: 'Available' },
    { value: 'Not Available', label: 'Not Available' },
  ]
  return (
    <div className="inputform options">
      <Select
        options={options}
        placeholder="Status"
        value={input.value}
        onChange={(value) => input.onChange(value)}
        noOptionsMessage={() => 'No Status'}
      />
      {touched && error && <p className="price-box">{error}</p>}
    </div>
  )
}

export const assigneComponent = ({ type, label, dropdown, input, meta: { touched, error } }) => {
  const options =
    dropdown &&
    dropdown.map((item) => ({
      label: item.label,
      value: item.value,
    }))
  return (
    <div className="inputform options">
      <Select
        options={options}
        placeholder="Select Status"
        value={input.value}
        onChange={(value) => input.onChange(value)}
        noOptionsMessage={() => 'No Status Found'}
      />
      {touched && error && <p className="price-box">{error}</p>}
    </div>
  )
}

export const customTextArea = ({ label, input, defaultValue }) => {
  return (
    <textarea
      placeholder={label}
      rows={6}
      // value={input || input.value ? input.value : input ? input : null}
      value={input.value ? input.value : defaultValue}
      onChange={(value) => input.onChange(value ? value : defaultValue)}
      className="text-area-field"
    />
  )
}

export const unitPriceRanger = ({ input, reset = false, className, isFilterChange }) => {
  const initialValue = {
    price_min: unitPriceRange.min,
    price_max: unitPriceRange.max,
    min: unitPriceRange.min,
    max: unitPriceRange.max,
  }
  const [value, setValue] = useState(initialValue)
  const [showRanger, setShowRanger] = useState(false)
  const ref = useRef(null)

  const setValues = () => {
    return input.onChange(value)
  }

  const usePrevious = (value) => {
    const ref = useRef()
    useEffect(() => {
      ref.current = value
    })
    return ref.current
  }

  const prevVal = usePrevious({ reset, isFilterChange })

  const handleClickOutside = (event) => {
    let new_val = { ...value }
    if (ref.current && !ref.current.contains(event.target) && showRanger) {
      if (parseFloat(value.price_min) > parseFloat(value.price_max) || value.price_max === '') {
        new_val.price_max = unitPriceRange.max
        new_val.max = unitPriceRange.max
      }
      if (value.price_min === '') new_val.price_min = 0

      if (showRanger) event.preventDefault()

      setShowRanger(false)
      setValue(new_val)
    }
  }

  useEffect(() => {
    if (reset && !prevVal.reset) setValue(initialValue)

    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  })

  useEffect(() => {
    setValues()
  }, [value])

  useEffect(() => {
    if (!isFilterChange && prevVal && prevVal.isFilterChange) setValue(initialValue)
  }, [isFilterChange])

  return (
    <div ref={ref} className={'unit-price-range'}>
      <div className={'payment-ranger ' + className} onClick={() => setShowRanger(!showRanger)}>
        <div className="size-border">PKR</div>
        <div className="button_text">{formatUnitPriceRange(value.price_min, value.price_max)}</div>
      </div>
      {showRanger && (
        <div className="ranger-modal-body">
          <div className="row mb-4">
            <div className="col-md-6">
              <div className="label">Min</div>
              <input
                type="number"
                value={value.price_min}
                className={'input-field'}
                onChange={(e) => {
                  let val =
                    parseFloat(e.target.value) >= unitPriceRange.max
                      ? unitPriceRange.max
                      : parseFloat(e.target.value) < 0
                      ? 0
                      : e.target.value
                  setValue({
                    ...value,
                    min: val > value.max ? value.max : val,
                    price_min: val,
                  })
                }}
              />
            </div>
            <div className="col-md-6">
              <div className="label">Max</div>
              <input
                type="number"
                value={value.price_max}
                className="input-field"
                onChange={(e) => {
                  let val =
                    parseFloat(e.target.value) > unitPriceRange.max
                      ? unitPriceRange.max
                      : !isNaN(e.target.value) &&
                        e.target.value !== '' &&
                        parseFloat(e.target.value) < 1
                      ? 1
                      : e.target.value

                  setValue({
                    ...value,
                    max: val < value.min ? value.min : val,
                    price_max: val,
                  })
                }}
              />
            </div>
          </div>
          <InputRange
            draggableTrack={false}
            minValue={unitPriceRange.min}
            maxValue={unitPriceRange.max}
            value={value}
            onChange={(val) => {
              setValue({
                ...value,
                min: val.min,
                max: val.max,
                price_min: val.min,
                price_max: val.max,
              })
            }}
            onChangeComplete={(value) => console.log(value)}
          />
          {parseFloat(value.price_min) > parseFloat(value.price_max) && (
            <div className="below-label text-danger">Max value couldn't be less than min value</div>
          )}

          <div className="button-container">
            <button
              type="button"
              disabled={parseFloat(value.price_min) > parseFloat(value.price_max)}
              className="button"
              onClick={() => {
                setShowRanger(false)
                setValues()
              }}
            >
              Done
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export const bedBathRanger = ({
  label,
  input,
  options,
  service_type,
  reset = false,
  className,
  dataValue,
  filterHeight,
}) => {
  const initialValue = dataValue
    ? dataValue
    : {
        minVal: 0,
        maxVal: options[options.length - 1],
        min: 0,
        max: options.length - 1,
      }
  const [value, setValue] = useState(initialValue)
  const [showRanger, setShowRanger] = useState(false)
  const ref = useRef(null)

  const setValues = () => {
    return input.onChange(value)
  }

  const usePrevious = (value) => {
    const ref = useRef()
    useEffect(() => {
      ref.current = value
    })
    return ref.current
  }

  const prevVal = usePrevious({ reset })

  const handleClickOutside = (event) => {
    let new_val = { ...value }
    if (ref.current && !ref.current.contains(event.target) && showRanger) {
      if (parseFloat(value.minVal) > parseFloat(value.maxVal) || value.maxVal === '') {
        new_val.maxVal = options[options.length - 1]
        new_val.max = options.length - 1
      }
      if (value.minVal === '') new_val.maxVal = 0

      // if (showRanger) event.preventDefault()

      setShowRanger(false)
      setValue(new_val)
    }
  }

  useEffect(() => {
    if (reset && !prevVal.reset) setValue(initialValue)

    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  })

  useEffect(() => {
    setValues()
  }, [value])

  // on manual change input - slide to closest value
  const closest = (size, op) => {
    if (size === '' && op === 'lg') return options[options.length - 1]
    let closestVal = options.reduce((a, b) => {
      return Math.abs(b - size) < Math.abs(a - size) ? b : a
    })
    return options.indexOf(closestVal)
  }

  return (
    <div ref={ref}>
      <div
        className={'payment-ranger ' + className}
        onClick={() => setShowRanger(!showRanger)}
        style={{ border: filterHeight ? '1px solid #026ff2' : null }}
      >
        {service_type && (
          <div
            className="size-border"
            style={{
              color: filterHeight ? '#026ff2' : null,
              borderRight: filterHeight ? '1px solid #026ff2' : null,
            }}
          >
            {service_type}
          </div>
        )}
        <div className="button_text" style={{ color: filterHeight ? '#026ff2' : null }}>
          {formatBedBathRange(value.minVal, value.maxVal)}
        </div>
      </div>
      {showRanger && (
        <div className="ranger-modal-body">
          <div className="row mb-4">
            <div className="col-md-6">
              <div className="label">Min</div>
              <input
                type="number"
                value={value.minVal}
                className={'input-field'}
                onChange={(e) => {
                  let val =
                    parseFloat(e.target.value) >= options[options.length - 1]
                      ? options[options.length - 1]
                      : parseFloat(e.target.value) < 0
                      ? 0
                      : e.target.value
                  let closet = closest(val, 'sm')
                  setValue({
                    ...value,
                    min: closet > value.max ? value.max : closet,
                    minVal: val,
                  })
                }}
              />
            </div>
            <div className="col-md-6">
              <div className="label">Max</div>
              <input
                type="number"
                value={value.maxVal}
                className="input-field"
                onChange={(e) => {
                  let val =
                    parseFloat(e.target.value) > options[options.length - 1]
                      ? options[options.length - 1]
                      : !isNaN(e.target.value) &&
                        e.target.value !== '' &&
                        parseFloat(e.target.value) < 1
                      ? 1
                      : e.target.value

                  let closet = closest(val, 'lg')
                  setValue({
                    ...value,
                    max: closet < value.min ? value.min : closet,
                    maxVal: val,
                  })
                }}
              />
            </div>
          </div>
          <InputRange
            draggableTrack={false}
            minValue={0}
            maxValue={options.length - 1}
            value={value}
            onChange={(val) => {
              setValue({
                ...value,
                minVal: options[val.min],
                maxVal: options[val.max],
                min: val.min,
                max: val.max,
              })
            }}
            onChangeComplete={(value) => console.log(value)}
          />
          {parseFloat(value.minVal) > parseFloat(value.maxVal) && (
            <div className="below-label text-danger">Max value couldn't be less than min value</div>
          )}

          <div className="button-container">
            <button
              type="button"
              disabled={parseFloat(value.minVal) > parseFloat(value.maxVal)}
              className="button"
              onClick={() => {
                setShowRanger(false)
                setValues()
              }}
            >
              Done
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

/* taimoor khan update paymentRanger */
export const paymentRanger = ({
  label,
  input,
  options,
  purpose,
  reset = false,
  className,
  dataValue,
  filterHeight,
  disabled,
}) => {
  const initialValue = dataValue
    ? dataValue
    : {
        price_min: 0,
        price_max: options[purpose][options[purpose].length - 1],
        min: 0,
        max: options[purpose].length - 1,
      }
  const [value, setValue] = useState(initialValue)
  // for masking
  const [value2, setValue2] = useState(initialValue)
  // for masking
  const [showRanger, setShowRanger] = useState(false)
  const ref = useRef(null)

  const setValues = () => {
    let v = { ...value }
    if (value.price_min === '') {
      v.price_min = 0
      v.min = 0
    }
    if (value.price_max === '') {
      v.price_max = options[purpose][options[purpose].length - 1]
      v.max = options[purpose].length - 1
    }
    return input.onChange(v)
  }

  const usePrevious = (value) => {
    const ref = useRef()
    useEffect(() => {
      ref.current = value
    })
    return ref.current
  }

  const prevVal = usePrevious({ reset })

  const handleClickOutside = (event) => {
    let new_val = { ...value }
    if (ref.current && !ref.current.contains(event.target) && showRanger) {
      if (parseFloat(value.price_min) > parseFloat(value.price_max) || value.price_max === '') {
        new_val.price_max = options[purpose][options[purpose].length - 1]
        new_val.max = options[purpose].length - 1
      }
      if (value.price_min === '') new_val.price_min = 0

      // if (showRanger) event.preventDefault()

      setShowRanger(false)
      setValue(new_val)
    }
  }

  useEffect(() => {
    if (reset && !prevVal.reset) setValue(initialValue)

    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  })

  useEffect(() => {
    setValues()
  }, [value, value2])

  // on manual change input - slide to closest value
  const closest = (size, op) => {
    if (size === '' && op === 'lg') return options[purpose][options[purpose].length - 1]
    let closestVal = options[purpose].reduce((a, b) => {
      return Math.abs(b - size) < Math.abs(a - size) ? b : a
    })
    return options[purpose].indexOf(closestVal)
  }

  let isMax = options['buy'][options['buy'].length - 1]

  return (
    <div ref={ref}>
      <div
        className={'payment-ranger ' + `${disabled && 'disableRanger '}` + className}
        onClick={() => !disabled && setShowRanger(!showRanger)}
        style={{ border: filterHeight ? '1px solid #026ff2' : null }}
      >
        {purpose === 'IpInvestmentPlanDuration' ? (
          <div
            className="frequency-investment"
            style={{
              color: filterHeight ? '#026ff2' : null,
              borderRight: filterHeight ? '1px solid #026ff2' : null,
            }}
          >
            Duration
          </div>
        ) : purpose === 'IpFrequency' ? (
          <div
            className="frequency-investment"
            style={{
              color: filterHeight ? '#026ff2' : null,
              borderRight: filterHeight ? '1px solid #026ff2' : null,
            }}
          >
            Months
          </div>
        ) : !(
            purpose === 'IpDownPayment' ||
            purpose === 'IpPossessionCharges' ||
            purpose === 'IpNoOfInstallments'
          ) ? (
          <div
            className="size-border"
            style={{
              color: filterHeight ? '#026ff2' : null,
              borderRight: filterHeight ? '1px solid #026ff2' : null,
            }}
          >
            PKR
          </div>
        ) : (
          ''
        )}
        <div
          className={
            purpose === 'IpFrequency' || purpose === 'IpInvestmentPlanDuration'
              ? 'frequency-investment-text'
              : 'button_text'
          }
          style={{ color: filterHeight ? '#026ff2' : null }}
        >
          {!(purpose === 'IpDownPayment' || purpose === 'IpPossessionCharges')
            ? formatPriceRange(value.price_min, value.price_max, purpose)
            : IpformatPriceRange(value.price_min, value.price_max, purpose)}
        </div>
      </div>
      {showRanger && (
        <div className="ranger-modal-body">
          <div className="row mb-4">
            <div className="col-md-6">
              <div className="label">Min</div>

              <input
                // for masking and this was before type='number'
                type="text"
                value={
                  typeof value2.price_min === 'number'
                    ? InvestmentRangeMasking(value2.price_min, 'setMasking')
                    : value2.price_min
                }
                className={'input-field'}
                onChange={(e) => {
                  setTimeout(() => {
                    setValues()
                  }, 500)

                  let val =
                    parseFloat(e.target.value) >= options[purpose][options[purpose].length - 1]
                      ? options[purpose][options[purpose].length - 1]
                      : parseFloat(e.target.value) < 0
                      ? 0
                      : InvestmentRangeMasking(e.target.value, 'unMask')
                  let closet = closest(val, 'sm')
                  // for masking min
                  const onlyNums = val && val.toString().replace(/[^\d]/g, '')
                  let modifiedValue = InvestmentRangeMasking(onlyNums, 'setMasking')
                  let val2 = InvestmentRangeMasking(val, 'unMask')
                  // for masking min
                  // for masking and for restricting entering other value than integers
                  if (InvestmentRangeMasking(val, 'checkDatatype')) {
                    setValue({
                      ...value,
                      min: closet > value.max ? value.max : closet,
                      // for masking
                      price_min: val2,
                    })

                    // for masking min
                    setValue2({
                      ...value2,
                      min: closet > value2.max ? value2.max : closet,
                      price_min: modifiedValue,
                    })
                    // for masking min
                  }
                }}
              />
            </div>
            <div className="col-md-6">
              <div className="label">Max</div>
              <input
                // for masking and this was before type='number'
                type="text"
                value={
                  isMax === value2.price_max ||
                  InvestmentRangeMasking(isMax, 'setMasking') === value2.price_max.toLocaleString()
                    ? 'Any'
                    : typeof value2.price_max === 'number'
                    ? InvestmentRangeMasking(value2.price_max, 'setMasking')
                    : value2.price_max
                }
                className="input-field"
                onChange={(e) => {
                  setTimeout(() => {
                    setValues()
                  }, 500)
                  let val =
                    parseFloat(e.target.value) > options[purpose][options[purpose].length - 1]
                      ? options[purpose][options[purpose].length - 1]
                      : !isNaN(e.target.value) &&
                        e.target.value !== '' &&
                        parseFloat(e.target.value) < 1
                      ? 1
                      : // for masking and this was previous e.target.value
                        InvestmentRangeMasking(e.target.value, 'unMask')
                  // for masking
                  let closet = closest(val, 'lg')

                  // for masking max
                  const onlyNums = val && val.toString().replace(/[^\d]/g, '')
                  let modifiedValue = InvestmentRangeMasking(onlyNums, 'setMasking')
                  let val2 = InvestmentRangeMasking(val, 'unMask')
                  // for masking max

                  // for masking and for restricting entering other value than integers
                  if (InvestmentRangeMasking(val, 'checkDatatype')) {
                    let closet = closest(val, 'lg')
                    setValue({
                      ...value,
                      max: closet < value.min ? value.min : closet,
                      price_max: val2,
                    })

                    // for masking max
                    setValue2({
                      ...value2,
                      max: closet < value2.min ? value2.min : closet,
                      price_max: modifiedValue,
                    })
                    // for masking max
                  }
                }}
              />
            </div>
          </div>
          <InputRange
            draggableTrack={false}
            minValue={0}
            maxValue={options[purpose].length - 1}
            value={value}
            onChange={(val) => {
              setValue({
                ...value,
                price_min: options[purpose][val.min],
                price_max: options[purpose][val.max],
                min: val.min,
                max: val.max,
              })

              // for masking
              setValue2({
                ...value2,
                price_min: InvestmentRangeMasking(
                  options[purpose][val.min],
                  'setMaskingForInputRange'
                ),
                price_max: InvestmentRangeMasking(
                  options[purpose][val.max],
                  'setMaskingForInputRange'
                ),
                min: val.min,
                max: val.max,
              })
              // for masking
            }}
            onChangeComplete={(value) => {}}
          />
          {parseFloat(value.price_min) > parseFloat(value.price_max) && (
            <div className="below-label text-danger">Max value couldn't be less than min value</div>
          )}

          <div className="button-container">
            <button
              type="button"
              disabled={parseFloat(value.price_min) > parseFloat(value.price_max)}
              className="button"
              onClick={() => {
                setShowRanger(false)
                setValues()
              }}
            >
              Done
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

// infinite Ranger
export const infiniteRanger = ({
  label,
  input,
  options,
  reset = false,
  className,
  dataValue,
  filterHeight,
  purpose,
}) => {
  const initialValue = dataValue
    ? dataValue
    : {
        minVal: 0,
        maxVal: options[options.length - 1],
        min: 0,
        max: options.length - 1,
      }

  const [value, setValue] = useState(initialValue)
  const [showRanger, setShowRanger] = useState(false)
  const ref = useRef(null)

  const setValues = () => {
    return input.onChange(value)
  }

  const usePrevious = (value) => {
    const ref = useRef()
    useEffect(() => {
      ref.current = value
    })
    return ref.current
  }

  const prevVal = usePrevious({ reset })

  const handleClickOutside = (event) => {
    let new_val = { ...value }
    if (ref.current && !ref.current.contains(event.target) && showRanger) {
      if (parseFloat(value.minVal) > parseFloat(value.maxVal) || value.maxVal === '') {
        new_val.maxVal = options[options.length - 1]
        new_val.max = options.length - 1
      }
      if (value.minVal === '') new_val.maxVal = 0

      // if (showRanger) event.preventDefault()

      setShowRanger(false)
      setValue(new_val)
    }
  }

  useEffect(() => {
    if (reset && !prevVal.reset) setValue(initialValue)

    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  })

  useEffect(() => {
    setValues()
  }, [value])

  // on manual change input - slide to closest value
  const closest = (size, op) => {
    if (size === '' && op === 'lg') return options[options.length - 1]
    let closestVal = options.reduce((a, b) => {
      return Math.abs(b - size) < Math.abs(a - size) ? b : a
    })
    return options.indexOf(closestVal)
  }

  return (
    <div ref={ref}>
      <div
        className={'payment-ranger ' + className}
        onClick={() => setShowRanger(!showRanger)}
        style={{ border: filterHeight ? '1px solid #026ff2' : null, paddingRight: '50px' }}
      >
        <div
          className="button_text"
          style={{ width: '100px', color: filterHeight ? '#026ff2' : null }}
        >
          {/*{!dataValue && !flag ? 'Any' : formatBedBathRange(value.minVal, value.maxVal)}*/}
          {formatInstallmentRange(value.minVal, value.maxVal)}
        </div>
      </div>
      {showRanger && (
        <div className="ranger-modal-body">
          <div className="row mb-4">
            <div className="col-md-6">
              <div className="label">Min</div>
              <input
                type="number"
                value={value.minVal}
                className={'input-field'}
                onChange={(e) => {
                  let val =
                    parseFloat(e.target.value) >= options[options.length - 1]
                      ? options[options.length - 1]
                      : parseFloat(e.target.value) < 0
                      ? 0
                      : e.target.value
                  let closet = closest(val, 'sm')
                  setValue({
                    ...value,
                    min: closet > value.max ? value.max : closet,
                    minVal: val,
                  })
                }}
              />
            </div>
            <div className="col-md-6">
              <div className="label">Max</div>
              <input
                type="number"
                value={value.maxVal}
                className="input-field"
                onChange={(e) => {
                  let val =
                    parseFloat(e.target.value) > options[options.length - 1]
                      ? options[options.length - 1]
                      : !isNaN(e.target.value) &&
                        e.target.value !== '' &&
                        parseFloat(e.target.value) < 1
                      ? 1
                      : e.target.value

                  let closet = closest(val, 'lg')
                  setValue({
                    ...value,
                    max: closet < value.min ? value.min : closet,
                    maxVal: val,
                  })
                }}
              />
            </div>
          </div>
          <InputRange
            draggableTrack={false}
            minValue={0}
            maxValue={options.length - 1}
            value={value}
            onChange={(val) => {
              setValue({
                ...value,
                minVal: options[val.min],
                maxVal: options[val.max],
                min: val.min,
                max: val.max,
              })
            }}
            onChangeComplete={(value) => console.log(value)}
          />
          {parseFloat(value.minVal) > parseFloat(value.maxVal) && (
            <div className="below-label text-danger">Max value couldn't be less than min value</div>
          )}

          <div className="button-container">
            <button
              type="button"
              disabled={parseFloat(value.minVal) > parseFloat(value.maxVal)}
              className="button"
              onClick={() => {
                setShowRanger(false)
                setValues()
              }}
            >
              Done
            </button>
          </div>
        </div>
      )}
    </div>
  )
}
//volume Range

export const volumeRanger = ({
  label,
  input,
  options,
  reset = false,
  className,
  dataValue,
  filterHeight,
  purpose,
}) => {
  const initialValue = dataValue
    ? dataValue
    : {
        minVal: 0,
        maxVal: options[options.length - 1],
        min: 0,
        max: options.length - 1,
      }

  const [value, setValue] = useState(initialValue)
  const [showRanger, setShowRanger] = useState(false)
  const ref = useRef(null)

  const setValues = () => {
    return input.onChange(value)
  }

  const usePrevious = (value) => {
    const ref = useRef()
    useEffect(() => {
      ref.current = value
    })
    return ref.current
  }

  const prevVal = usePrevious({ reset })

  const handleClickOutside = (event) => {
    let new_val = { ...value }
    if (ref.current && !ref.current.contains(event.target) && showRanger) {
      if (parseFloat(value.minVal) > parseFloat(value.maxVal) || value.maxVal === '') {
        new_val.maxVal = options[options.length - 1]
        new_val.max = options.length - 1
      }
      if (value.minVal === '') new_val.maxVal = 0

      // if (showRanger) event.preventDefault()

      setShowRanger(false)
      setValue(new_val)
    }
  }

  useEffect(() => {
    if (reset && !prevVal.reset) setValue(initialValue)

    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  })

  useEffect(() => {
    setValues()
  }, [value])

  // on manual change input - slide to closest value
  const closest = (size, op) => {
    if (size === '' && op === 'lg') return options[options.length - 1]
    let closestVal = options.reduce((a, b) => {
      return Math.abs(b - size) < Math.abs(a - size) ? b : a
    })
    return options.indexOf(closestVal)
  }

  return (
    <div ref={ref}>
      <div
        className={'payment-ranger ' + className}
        onClick={() => setShowRanger(!showRanger)}
        style={{ border: filterHeight ? '1px solid #026ff2' : null, paddingRight: '50px' }}
      >
        <div className={'frequency-investment'}>
          {purpose === 'frequency' ? 'Months' : 'Duration'}
        </div>

        <div className={'frequency-investment-text'}>
          {formatFrequencyIp(value.minVal, value.maxVal, purpose)}
        </div>
      </div>
      {showRanger && (
        <div className="ranger-modal-body">
          <div className="row mb-4">
            <div className="col-md-6">
              <div className="label">Min</div>
              <input
                type="number"
                value={value.minVal}
                className={'input-field'}
                onChange={(e) => {
                  let val =
                    parseFloat(e.target.value) >= options[options.length - 1]
                      ? options[options.length - 1]
                      : parseFloat(e.target.value) < 0
                      ? 0
                      : e.target.value
                  let closet = closest(val, 'sm')
                  setValue({
                    ...value,
                    min: closet > value.max ? value.max : closet,
                    minVal: val,
                  })
                }}
              />
            </div>
            <div className="col-md-6">
              <div className="label">Max</div>
              <input
                type="number"
                value={value.maxVal}
                className="input-field"
                onChange={(e) => {
                  let val =
                    parseFloat(e.target.value) > options[options.length - 1]
                      ? options[options.length - 1]
                      : !isNaN(e.target.value) &&
                        e.target.value !== '' &&
                        parseFloat(e.target.value) < 1
                      ? 1
                      : e.target.value

                  let closet = closest(val, 'lg')
                  setValue({
                    ...value,
                    max: closet < value.min ? value.min : closet,
                    maxVal: val,
                  })
                }}
              />
            </div>
          </div>
          <InputRange
            draggableTrack={false}
            minValue={0}
            maxValue={options.length - 1}
            value={value}
            onChange={(val) => {
              setValue({
                ...value,
                minVal: options[val.min],
                maxVal: options[val.max],
                min: val.min,
                max: val.max,
              })
            }}
            onChangeComplete={(value) => console.log(value)}
          />
          {parseFloat(value.minVal) > parseFloat(value.maxVal) && (
            <div className="below-label text-danger">Max value couldn't be less than min value</div>
          )}

          <div className="button-container">
            <button
              type="button"
              disabled={parseFloat(value.minVal) > parseFloat(value.maxVal)}
              className="button"
              onClick={() => {
                setShowRanger(false)
                setValues()
              }}
            >
              Done
            </button>
          </div>
        </div>
      )}
    </div>
  )
}
{
  /* taimoor khan update priceRanger*/
}

export const priceRanger = ({ input, options, purpose, reset = false, className }) => {
  const initialValue = {
    price_min: options[purpose][0],
    price_max: options[purpose][options[purpose].length - 1],
    min: 0,
    max: options[purpose].length - 1,
  }

  const [value, setValue] = useState(initialValue)
  // for masking
  const [value2, setValue2] = useState(initialValue)
  // for masking
  const [showRanger, setShowRanger] = useState(false)
  const ref = useRef(null)

  const setValues = () => {
    return input.onChange(value)
  }

  const updateColor = () => {
    setValues((previousState) => {})
  }

  // for masking
  const setMasking = (val) => {
    let modifiedValue = Number(val).toLocaleString()
    return modifiedValue
  }

  const setMaskingForInputRange = (val) => {
    const onlyNums = val && val.toString().replace(/[^\d]/g, '')
    let modifiedValue = Number(onlyNums).toLocaleString()
    return modifiedValue
  }

  const unMask = (val) => {
    if (value !== 'NaN') {
      return val.toString().replace(/,/g, '')
    } else {
      return 'any'
    }
  }
  // for masking

  const usePrevious = (value) => {
    const ref = useRef()
    useEffect(() => {
      ref.current = value
    })
    return ref.current
  }

  const prevVal = usePrevious({ reset })

  const handleClickOutside = (event) => {
    let new_val = { ...value }
    if (ref.current && !ref.current.contains(event.target) && showRanger) {
      if (parseFloat(value.price_min) > parseFloat(value.price_max) || value.price_max === '') {
        new_val.price_max = options[purpose][options[purpose].length - 1]
        new_val.max = options[purpose].length - 1
      }
      if (value.price_min === '') new_val.price_min = 0

      if (showRanger) event.preventDefault()

      setShowRanger(false)
      setValue(new_val)
    }
  }

  useEffect(() => {
    if (reset && !prevVal.reset) setValue(initialValue)

    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  })

  useEffect(() => {
    setValues()
  }, [value])

  // on manual change input - slide to closest value
  const closest = (size, op) => {
    if (size === '' && op === 'lg') return options[purpose][options[purpose].length - 1]
    let closestVal = options[purpose].reduce((a, b) => {
      return Math.abs(b - size) < Math.abs(a - size) ? b : a
    })
    return options[purpose].indexOf(closestVal)
  }

  return (
    <div ref={ref}>
      <div className={'payment-ranger ' + className} onClick={() => setShowRanger(!showRanger)}>
        <div className="size-border">PKR</div>
        <div className="button_text">
          {projectInvestmentPriceRange(
            InvestmentRangeMasking(value.price_min !== 'NaN' ? value.price_min : 50000, 'unMask'),
            value.price_max,
            purpose
          )}
        </div>
      </div>
      {showRanger && (
        <div className="ranger-modal-body">
          <div className="row mb-4">
            <div className="col-md-6">
              <div className="label">Min</div>
              <input
                // for masking and this was before type='number'
                type="text"
                // for masking min and was before value.price_min
                value={
                  typeof value2.price_min === 'number'
                    ? InvestmentRangeMasking(value2.price_min, 'setMasking')
                    : value2.price_min
                }
                // for masking min
                className={'input-field'}
                onChange={(e) => {
                  updateColor()
                  setTimeout(() => {
                    // setValues()
                  }, 500)

                  let val =
                    parseFloat(e.target.value) >= options[purpose][options[purpose].length - 1]
                      ? options[purpose][options[purpose].length - 1]
                      : parseFloat(e.target.value) < 0
                      ? 0
                      : // for masking
                        InvestmentRangeMasking(e.target.value, 'unMask')
                  let closet = closest(val, 'sm')
                  // for masking min
                  const onlyNums = val && val.toString().replace(/[^\d]/g, '')
                  let modifiedValue = InvestmentRangeMasking(onlyNums, 'setMasking')
                  let val2 = InvestmentRangeMasking(val, 'unMask')
                  // for masking min
                  // for masking and for restricting entering other value than integers
                  if (InvestmentRangeMasking(val, 'checkDatatype')) {
                    setValue({
                      ...value,
                      min: closet > value.max ? value.max : closet,
                      price_min: val2,
                    })
                    // for masking min
                    setValue2({
                      ...value2,
                      min: closet > value2.max ? value2.max : closet,
                      price_min: modifiedValue,
                    })
                  }
                  // for masking min
                }}
              />
            </div>
            <div className="col-md-6">
              <div className="label">Max</div>
              <input
                // for masking and this was before type="number"
                type="text"
                value={
                  typeof value2.price_max === 'number'
                    ? InvestmentRangeMasking(value2.price_max, 'setMasking')
                    : value2.price_max
                }
                // for masking
                className="input-field"
                onChange={(e) => {
                  setTimeout(() => {
                    setValues()
                  }, 500)
                  let val =
                    parseFloat(e.target.value) > options[purpose][options[purpose].length - 1]
                      ? options[purpose][options[purpose].length - 1]
                      : !isNaN(e.target.value) &&
                        e.target.value !== '' &&
                        parseFloat(e.target.value) < 1
                      ? 1
                      : // for masking and this was previous e.target.value
                        InvestmentRangeMasking(e.target.value, 'unMask')
                  // for masking
                  let closet = closest(val, 'lg')

                  // for masking
                  const onlyNums = val && val.toString().replace(/[^\d]/g, '')
                  let modifiedValue = InvestmentRangeMasking(onlyNums, 'setMasking')
                  let val2 = InvestmentRangeMasking(val, 'unMask')
                  // for masking

                  // for masking and for restricting entering other value than integers
                  if (InvestmentRangeMasking(val, 'checkDatatype')) {
                    setValue({
                      ...value,
                      max: closet < value.min ? value.min : closet,
                      // for masking  and before: price_max: val
                      price_max: val2,
                      // for masking
                    })
                    // for masking max
                    setValue2({
                      ...value2,
                      max: closet < value2.min ? value2.min : closet,
                      price_max: modifiedValue,
                    })
                    // for masking max
                  }
                }}
              />
            </div>
          </div>
          <InputRange
            draggableTrack={false}
            minValue={0}
            maxValue={options[purpose].length - 1}
            value={value}
            onChange={(val) => {
              updateColor()
              setValue({
                ...value,
                price_min: options[purpose][val.min],
                price_max: options[purpose][val.max],
                min: val.min,
                max: val.max,
              })
              // for masking
              setValue2({
                ...value2,
                price_min: InvestmentRangeMasking(
                  options[purpose][val.min],
                  'setMaskingForInputRange'
                ),
                price_max: InvestmentRangeMasking(
                  options[purpose][val.max],
                  'setMaskingForInputRange'
                ),
                min: val.min,
                max: val.max,
              })
              // for masking
            }}
            onChangeComplete={(value) => {}}
          />
          {parseFloat(value.price_min) > parseFloat(value.price_max) && (
            <div className="below-label text-danger">Max value couldn't be less than min value</div>
          )}

          <div className="button-container">
            <button
              type="button"
              disabled={parseFloat(value.price_min) > parseFloat(value.price_max)}
              className="button"
              onClick={() => {
                setShowRanger(false)
                setValues()
              }}
            >
              Done
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export const sizeRanger = ({
  label,
  input,
  options,
  reset = false,
  className,
  dataValue,
  filterHeight,
  LeadDetail,
}) => {
  let findIndexForSize =
    LeadDetail &&
    _.findLastIndex(options.sizeUnit, function (o) {
      if(LeadDetail&&LeadDetail.size_unit!==null){
        return o.value == LeadDetail.size_unit.toString()
      }else{
        return 0
      }

    })

  const initialValue = dataValue
    ? dataValue
    : {
        size_min: 0,
        size_max:
          options.unitRange[options.sizeUnit[findIndexForSize ? findIndexForSize : 3].value][
            options.unitRange[options.sizeUnit[findIndexForSize ? findIndexForSize : 3].value]
              .length - 1
          ],
        min: 0,
        max:
          options.unitRange[options.sizeUnit[findIndexForSize ? findIndexForSize : 3].value]
            .length - 1,
        unit: options.sizeUnit[findIndexForSize ? findIndexForSize : 3],
      }
  const [value, setValue] = useState(initialValue)
  const [showRanger, setShowRanger] = useState(false)
  const ref = useRef(null)

  const setValues = () => {
    return input.onChange(value)
  }

  const handleClickOutside = (event) => {
    let new_val = { ...value }
    if (ref.current && !ref.current.contains(event.target) && showRanger) {
      if (parseFloat(value.size_min) > parseFloat(value.size_max) || value.size_max === '') {
        new_val.size_max =
          options.unitRange[value.unit.value][options.unitRange[value.unit.value].length - 1]
        new_val.max = options.unitRange[value.unit.value].length - 1
      }
      if (value.size_min === '') new_val.size_min = 0

      // if (showRanger) event.preventDefault()

      setShowRanger(false)
      setValue(new_val)
    }
  }

  const usePrevious = (value) => {
    const ref = useRef()
    useEffect(() => {
      ref.current = value
    })
    return ref.current
  }

  const prevVal = usePrevious({ reset })

  useEffect(() => {
    if (reset && !prevVal.reset) setValue(initialValue)

    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  })

  useEffect(() => {
    setValues()
  }, [value])

  // on manual change input - slide to closest value
  const closest = (size, op, unit) => {
    if (size === '' && op === 'lg')
      return options.unitRange[unit][options.unitRange[unit].length - 1]
    let closestVal = options.unitRange[unit].reduce((a, b) => {
      return Math.abs(b - size) < Math.abs(a - size) ? b : a
    })
    return options.unitRange[unit].indexOf(closestVal)
  }
  if(!value.unit){
    value.unit = {value: 'marla', label: 'Marla'}
  }
  let isMax = 
   value.unit ? sizeRange.unitRange[value.unit.value][sizeRange.unitRange[value.unit.value].length - 1] : 0

  return (
    <div ref={ref}>
      <div
        className={'payment-ranger ' + className}
        onClick={() => setShowRanger(!showRanger)}
        style={{ border: filterHeight ? '1px solid #026ff2' : null }}
      >
        <div
          className="size-border"
          style={{
            color: filterHeight ? '#026ff2' : null,
            borderRight: filterHeight ? '1px solid #026ff2' : null,
          }}
        >
          {value.unit.label}
        </div>
        <div className="button_text" style={{ color: filterHeight ? '#026ff2' : null }}>
          {formatSizeRange(value.size_min, value.size_max, value.unit.value)}
        </div>
      </div>
      {showRanger && (
        <div className="ranger-modal-body size-ranger-modal">
          <div className="row mb-4">
            <div className="col-md-12">
              <Select
                options={options.sizeUnit}
                value={value.unit}
                placeholder="Select Unit"
                onChange={(val) => {
                  setValue({
                    ...value,
                    min: closest(parseFloat(value.size_min), 'sm', val.value),
                    max: closest(parseFloat(value.size_max), 'lg', val.value),
                    size_max:
                      options.unitRange[val ? val.value : value.unit.value][
                        sizeRange.unitRange[val ? val.value : value.unit.value].length - 1
                      ],
                    unit: val,
                  })
                  input.onChange(val)
                }}
              />
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-md-12">
              <InputRange
                draggableTrack={false}
                minValue={0}
                maxValue={options.unitRange[value.unit.value].length - 1}
                value={value}
                onChange={(val) => {
                  setValue({
                    ...value,
                    size_min: options.unitRange[value.unit.value][val.min],
                    size_max: options.unitRange[value.unit.value][val.max],
                    min: val.min,
                    max: val.max,
                  })
                }}
                onChangeComplete={(value) => console.log(value)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="label">Min</div>
              <input
                type="number"
                value={value.size_min}
                className="input-field"
                onChange={(e) => {
                  // we have fixed values in slider but user can type any and we need to move slider accordingly
                  // which is why this code is written to move slider to nearst fixed value
                  // also to check if value is not greater than max value
                  let val =
                    parseFloat(e.target.value) >=
                    options.unitRange[value.unit.value][
                      options.unitRange[value.unit.value].length - 1
                    ]
                      ? options.unitRange[value.unit.value][
                          options.unitRange[value.unit.value].length - 1
                        ]
                      : parseFloat(e.target.value) < 0
                      ? 0
                      : e.target.value
                  let closet = closest(val, 'sm', value.unit.value)
                  setValue({
                    ...value,
                    min: closet > value.max ? value.max : closet,
                    size_min: val,
                  })
                }}
              />
            </div>
            <div className="col-md-6">
              <div className="label">Max</div>
              <input
                type={isMax === value.size_max ? 'text' : 'number'}
                value={isMax === value.size_max ? 'Any' : value.size_max}
                className="input-field"
                onChange={(e) => {
                  // we have fixed set of values in slider but user can type any and we need to move slider accordingly
                  // which is why this code is written to move slider to nearest fixed value
                  // also to check if value is not less than min value
                  let val =
                    parseFloat(e.target.value) >
                    options.unitRange[value.unit.value][
                      options.unitRange[value.unit.value].length - 1
                    ]
                      ? options.unitRange[value.unit.value][
                          options.unitRange[value.unit.value].length - 1
                        ]
                      : !isNaN(e.target.value) &&
                        e.target.value !== '' &&
                        parseFloat(e.target.value) < 1
                      ? 1
                      : e.target.value

                  let closet = closest(val, 'lg', value.unit.value)
                  setValue({
                    ...value,
                    max: closet < value.min ? value.min : closet,
                    size_max: val,
                  })
                }}
              />
            </div>
          </div>
          {parseFloat(value.size_min) > parseFloat(value.size_max) && (
            <div className="below-label text-danger">Max value couldn't be less than min value</div>
          )}

          <div className="button-container">
            <button
              type="button"
              className="button"
              disabled={parseFloat(value.size_min) > parseFloat(value.size_max)}
              onClick={() => {
                setShowRanger(false)
                setValues()
              }}
            >
              Done
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export const paymentPlanRanger = ({ label, input, options, editing, editData, disabled }) => {
  const [showField, setField] = useState(false)
  const [value, setValue] = useState({
    size_min: 1,
    size_max:
      options.unitRange[options.sizeUnit[3].value][
        options.unitRange[options.sizeUnit[3].value].length - 1
      ],
    min: 1,
    max: options.unitRange[options.sizeUnit[3].value].length - 1,
    unit: options.sizeUnit[3],
  })
  const [showRanger, setShowRanger] = useState(false)
  const ref = useRef(null)

  const setValues = () => {
    return input.onChange(value)
  }

  const handleClickOutside = (event) => {
    let new_val = { ...value }
    if (ref.current && !ref.current.contains(event.target)) {
      if (parseFloat(value.size_min) > parseFloat(value.size_max) || value.size_max === '') {
        new_val.size_max =
          options.unitRange[value.unit.value][options.unitRange[value.unit.value].length - 1]
        new_val.max = options.unitRange[value.unit.value].length - 1
      }
      if (value.size_min === '') new_val.size_min = 0

      if (showRanger) event.preventDefault()

      setShowRanger(false)
      setValue(new_val)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  })

  useEffect(() => {
    if (editing) {
      let paymentPlanDuration = JSON.parse(editData && editData.paymentPlanDuration)

      let value1 = paymentPlanDuration && paymentPlanDuration[0]
      let value2 = paymentPlanDuration && paymentPlanDuration[1]

      setValue({
        ...value,
        min: value1 !== null ? value1 : 1,
        size_min: value1 !== null ? value1 : 1,
        max: value2 !== null ? value2 : 5,
        size_max: value2 !== null ? value2 : 5,
      })
    }
  }, [])

  return (
    <div ref={ref}>
      <div
        style={{ background: disabled ? '#f2f2f2' : null }}
        className="payment-duration-plan"
        onClick={() => {
          return disabled ? null : setShowRanger(!showRanger)
        }}
      >
        <div className={editing || showField ? 'size-border-focus' : 'size-border'}>Duration</div>
        <div className={editing || showField ? 'button_text_black' : 'button_text'}>
          {editData &&
          editData.paymentPlanDuration === null &&
          value.size_min === 1 &&
          value.size_max === 5
            ? '0-0 Year'
            : value.size_min === value.size_max
            ? value.size_min + ' Year'
            : value.size_min + ' - ' + value.size_max + ' Years'}
        </div>
      </div>
      {showRanger && (
        <div className="ranger-payment-plan-body size-ranger-modal">
          <div className="row mb-4">
            <div className="col-md-12">
              <InputRange
                draggableTrack={false}
                minValue={1}
                maxValue={5}
                allowSameValues={true}
                value={value}
                onChange={(val) => {
                  if (val.min === 0) {
                    return
                  }
                  setField(true)
                  setValue({
                    ...value,
                    size_min: options.unitRange[value.unit.value][val.min],
                    size_max: options.unitRange[value.unit.value][val.max],
                    min: val.min,
                    max: val.max,
                  })
                }}
                onChangeComplete={(value) => console.log(value)}
              />
            </div>
          </div>
          {parseFloat(value.size_min) > parseFloat(value.size_max) && (
            <div className="below-label text-danger">Max value couldn't be less than min value</div>
          )}

          <div className="button-container">
            <button
              type="button"
              className="button"
              disabled={parseFloat(value.size_min) > parseFloat(value.size_max)}
              onClick={() => {
                setShowRanger(false)
                setValues()
              }}
            >
              Done
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export const RenderSelect = ({
  label,
  data,
  disabled,
  createPermission,
  updatePermission,
  input,
                               customVal,
  meta: { touched, error },
}) => {
  let options = RenderList(data, 'value', 'label', null)
  useEffect(() => {
    if(!customVal)
    input.onChange(options[0])
  }, [])
  return (
    <div className="inputform options">
      <Select
        options={options}
        placeholder={label ? label : 'Dropdown'}
        value={customVal ? customVal : input.value}
        isDisabled={updatePermission ? false : createPermission ? false : disabled}
        onChange={(value) => input.onChange(value)}
        noOptionsMessage={() => 'No Dropdown'}
      />
      {touched && error && <p className="price-box">{error}</p>}
    </div>
  )
}

export const renderCustomDropdown = ({ label, dropdown, input, meta: { touched, error } }) => {
  const options =
    dropdown &&
    dropdown.map((item) => ({
      label: item.label,
      value: item.value,
    }))
  return (
    <div className="inputform options">
      <Select
        className="over-ride-class"
        options={options}
        placeholder={label ? label : 'Dropdown'}
        value={input.value}
        onChange={(value) => input.onChange(value)}
        noOptionsMessage={() => 'No Dropdown'}
      />
      {touched && error && <p className="price-box">{error}</p>}
    </div>
  )
}

export const renderCustomDropdownComp = ({
  label,
  permissions,
  dropdown,
  input,
  isBookingForm,
                                           onUserInput,
  meta: { touched, error },
}) => {
  const options =
    dropdown &&
    dropdown.map((item) => ({
      label: item.label,
      value: item.value,
    }))
  return (
    <div className="inputform options">
      <Select
        className="over-ride-class"
        options={options}
        placeholder={label ? label : 'Dropdown'}
        isDisabled={permissions && !getPermissionByAction(permissions, 'Create')}
        value={input.value}
        onChange={(value) => input.onChange(value)}
        noOptionsMessage={() => 'No Dropdown'}
        onInputChange={(change) => {
          if (isBookingForm && change && change.length > 2) {
            onUserInput(change);
          }
        }}
      />
      {touched && error && <p className="price-box">{error}</p>}
    </div>
  )
}

export const renderSelectionField = ({
  label,
  input,
  createPermission,
  updatePermission,
  inventory,
  meta: { touched, error },
}) => {
  const [isVisible, setIsVisible] = useState(false)
  return (
    <div className="inputform options mt-2">
      {inventory.is_discount ? (
        <>
          {' '}
          <input
            type="checkbox"
            disabled={updatePermission ? false : createPermission ? false : true}
            value={input.value}
            onClick={() => setIsVisible(!isVisible)}
          />{' '}
          <span className="ml-2">{label}</span>
        </>
      ) : null}
      {isVisible && (
        <div className="mt-4">
          <div className="mb-2">Discount Amount (Percentage %)</div>
          <input
            className="form-control input-box focusText"
            {...input}
            type="number"
            onChange={(e) => {e.target.value > 100 ? alert("Discount should not exceed 100%") : input.onChange(e.target.value)}}
            placeholder="Enter Value"
            autoComplete="new-password"
          />
        </div>
      )}
    </div>
  )
}

export const renderCheckBox = ({
  label,
  input,
  meta: { touched, error },
}) => {
  return (
    <div className="inputform options mt-2">
      <>
          {' '}
          <input
            type="checkbox"
            value={input.value}
            checked={input.value}
            onClick={(value) => input.onChange(value)}
          />{' '}
           <span className="ml-2">{label}</span>
      </>
    </div>
  )
}

export const renderTextInput = ({ fieldValue, input, disabled }) => {
  return (
    <div className="inputform options mt-2">
      <input
        className="form-control input-box focusText"
        {...input}
        type="number"
        onChange={(e) => {
          input.onChange(e.target.value)
        }}
        placeholder="Enter Value"
        value={input.value}
        autoComplete="new-password"
        disabled={disabled}
      />
    </div>
  )
}

export const renderCustomArea = ({
  createPermission,
  updatePermission,
  booking,
                                   isProductTypeSelected,
  input,
  disabled,
                                   max,
                                   isFractional
}) => {
  useEffect(() => {
    input.onChange(max)
  }, [max])
  return (
    <div className="inputform options mt-2">
      <input
        className="form-control input-box focusText"
        {...input}
        type="number"
        min={1}
        max={max}
        onKeyPress={(e) => {e.key === 'Enter' && e.preventDefault()}}
        onChange={(e) => {
          if (
            e.target.value === '' ||
            (parseFloat(e.target.value, 10) <= max && parseFloat(e.target.value, 10) >= 0)
          ){
            input.onChange(e.target.value)
          }
          else input.onChange(max)
        }}
        placeholder="Enter Value"
        value={input.value}
        autoComplete="new-password"
        disabled={isFractional==="no" ? true : !isProductTypeSelected ? true : booking ? true : updatePermission ? false : createPermission ? false : disabled}
      />
    </div>
  )
}

export const renderOptions = ({
  label,
  options,
  input,
  defaultValue,
  meta: { touched, error },
  multi,
  styles,
  disabled,
  className,
  errorShow,
  errorMessageText,
  name,
}) => {
  if (input.name === 'project') {
    options && options.length > 0 && options.unshift({ label: 'Any', value: 0 })
  }

  return (
    <div className="inputform options">
      <Select
        className={className}
        options={options}
        placeholder={label ? label : 'Dropdown'}
        value={input.value || defaultValue}
        onChange={(value) => input.onChange(value)}
        noOptionsMessage={() => 'No Dropdown'}
        isMulti={multi}
        styles={
          styles
            ? styles
            : {
                singleValue: (provided) =>
                  styles
                    ? {
                        ...provided,
                        ...styles.singleValue,
                      }
                    : provided,
                control: (provided) =>
                  styles
                    ? {
                        ...provided,
                        ...styles.control,
                      }
                    : provided,
              }
        }
        isDisabled={disabled ? true : false}
      />
      {errorShow && <p className="error-show-font">{errorMessageText}</p>}
    </div>
  )
}

export const renderPhoneField = ({ input, value }) => {
  return (
    <PhoneInput
      placeholder="Enter phone number"
      country={'pk'}
      inputProps={{
        name: 'phone',
        style: {
          width: '100%',
          height: 38,
          marginTop: '4px!important',
        },
      }}
      value={input.value.phone}
      onChange={(value, country) => input.onChange({ phone: value, ...country })}
      inputClass={'form-control'}
      countryCodeEditable={false}
    />
  )
}

export const investmentLeadAreaDrodpown = ({
  label,
  options,
  isMulti,
  input,
  styles,
  meta: { touched, error },
  className,
  city = null,
  customValue = null,
  errorMessageText,
  errorShow,
}) => {
  return (
    <div className="inputform options">
      <Select
        className={className}
        options={options}
        placeholder={label ? label : 'Dropdown'}
        value={customValue ? customValue : input.value}
        closeMenuOnSelect={false}
        isMulti
        styles={
          styles
            ? styles
            : {
                singleValue: (provided) =>
                  styles
                    ? {
                        ...provided,
                        ...styles.singleValue,
                      }
                    : provided,
                control: (provided) =>
                  styles
                    ? {
                        ...provided,
                        ...styles.control,
                      }
                    : provided,
              }
        }
        isLoading={city && !options}
        onChange={(value) => input.onChange(value)}
        noOptionsMessage={() => 'No Dropdown'}
      />
      {errorShow && <p className="error-show-font">{errorMessageText}</p>}
    </div>
  )
}

export const investmentLeadCityDropdown = ({
  label,
  options,
  input,
  meta: { touched, error },
  styles,
  fetchAreas,
  className,
  onCallback,
  errorShow,
  errorMessageText,
  defaultValue,
}) => {
  return (
    <div className="inputform options">
      <Select
        className={className}
        options={options}
        placeholder={label ? label : 'Dropdown'}
        value={input.value || defaultValue}
        onChange={(value) => {
          input.onChange(value)
          fetchAreas(value.value)
          onCallback && onCallback(value.value)
        }}
        noOptionsMessage={() => 'No Dropdown'}
        styles={
          styles
            ? styles
            : {
                singleValue: (provided) =>
                  styles
                    ? {
                        ...provided,
                        ...styles.singleValue,
                      }
                    : provided,
                control: (provided) =>
                  styles
                    ? {
                        ...provided,
                        ...styles.control,
                      }
                    : provided,
              }
        }
      />
      {errorShow && <p className="error-show-font">{errorMessageText}</p>}
    </div>
  )
}

export const unitFloorDropdown = ({ type, label, options, input, meta: { touched, error } }) => {
  const modifiedOption = options.map((item) => ({
    label: item.name,
    value: item.name,
  }))
  return (
    <div className="inputform options">
      <Select
        options={modifiedOption}
        placeholder="Floors"
        value={input.value}
        onChange={(value) => input.onChange(value)}
        noOptionsMessage={() => 'No Dropdowns'}
      />

      {touched && error && <p className="price-box">{error}</p>}
    </div>
  )
}

export const GeneralSelectComponent = ({
  label,
  noMessage,
  options,
  input,
  meta: { touched, error },
}) => {
  return (
    <div className="inputform options">
      <Select
        options={options}
        placeholder={label}
        value={input.value}
        onChange={(value) => input.onChange(value)}
        noOptionsMessage={() => noMessage}
      />

      {touched && error && <p className="price-box">{error}</p>}
    </div>
  )
}

export const selectFloor = ({ type, label, input, options, meta: { touched, error } }) => {
  return (
    <div className="inputform options">
      <CreatableSelect
        options={options}
        placeholder="Select Floor"
        value={input.value}
        onChange={(value) => input.onChange(value)}
        noOptionsMessage={() => 'No Status'}
      />
      {touched && error && <p className="price-box">{error}</p>}
    </div>
  )
}

export const productType = ({ type, label, input, options, meta: { touched, error } }) => {
  return (
    <div className="inputform options">
      {input.value && <span className="custom-placeholder">Add Product Types</span>}
      <CreatableSelect
        placeholder="Unit Type"
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
        }}
        value={input.value}
        isMulti
        onChange={(value) => input.onChange(value)}
        noOptionsMessage={() => null}
      />
      {touched && error && <p className="price-box">{error}</p>}
    </div>
  )
}

export const optionChoose = ({ type, label, placeholder, input, meta: { touched, error } }) => {
  const { ValueContainer, Placeholder } = components
  const CustomValueContainer = ({ children, ...props }) => {
    return (
      <ValueContainer {...props}>
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, (child) =>
          child && child.type !== Placeholder ? child : null
        )}
      </ValueContainer>
    )
  }
  const options = [
    { value: 'yes', label: 'Yes' },
    { value: 'no', label: 'No' },
  ]
  return (
    <div className="inputform options">
      <Select
        components={{
          ValueContainer: CustomValueContainer,
        }}
        styles={{
          container: (provided, state) => ({
            ...provided,
          }),
          valueContainer: (provided, state) => ({
            ...provided,
            overflow: 'visible',
          }),

          control: (base, state) => ({
            ...base,
            boxShadow: 'none',
            border: '1px solid #ced4da',
          }),
          placeholder: (provided, state) => ({
            ...provided,
            position: 'absolute',
            transitions: '0.5s',
            top: state.hasValue || state.selectProps.inputValue ? -1 : '50%',
            transition: 'top 0.1s, font-size 0.1s',
            fontSize: (state.hasValue || state.selectProps.inputValue) && 13,
          }),
        }}
        options={options}
        placeholder={placeholder}
        value={input.value}
        onChange={(value) => input.onChange(value)}
        noOptionsMessage={() => 'No Options'}
      />
      {touched && error && <p className="price-box">{error}</p>}
    </div>
  )
}

export const customFieldChooice = ({
  type,
  label,
  placeholder,
  input,
  meta: { touched, error },
}) => {
  const { ValueContainer, Placeholder } = components
  const CustomValueContainer = ({ children, ...props }) => {
    return (
      <ValueContainer {...props}>
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, (child) =>
          child && child.type !== Placeholder ? child : null
        )}
      </ValueContainer>
    )
  }
  const options = [
    { value: 'text', label: 'Text' },
    { value: 'dropdown', label: 'Dropdown' },
  ]
  return (
    <div className="inputform options">
      <Select
        options={options}
        components={{
          ValueContainer: CustomValueContainer,
        }}
        styles={{
          container: (provided, state) => ({
            ...provided,
          }),

          valueContainer: (provided, state) => ({
            ...provided,
            overflow: 'visible',
          }),

          control: (base, state) => ({
            ...base,
            boxShadow: 'none',
            border: '1px solid #ced4da',
          }),
          placeholder: (provided, state) => ({
            ...provided,
            position: 'absolute',
            transitions: '0.5s',
            top: state.hasValue || state.selectProps.inputValue ? -1 : '50%',
            transition: 'top 0.1s, font-size 0.1s',
            fontSize: (state.hasValue || state.selectProps.inputValue) && 13,
          }),
        }}
        placeholder="Type"
        value={input.value}
        onChange={(value) => input.onChange(value)}
        noOptionsMessage={() => 'No Options'}
      />
      {touched && error && <p className="price-box">{error}</p>}
    </div>
  )
}

export const projectType = ({ type, label, input, meta: { touched, error } }) => {
  const { ValueContainer, Placeholder } = components
  const CustomValueContainer = ({ children, ...props }) => {
    return (
      <ValueContainer {...props}>
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, (child) =>
          child && child.type !== Placeholder ? child : null
        )}
      </ValueContainer>
    )
  }
  const options = [
    { value: 'mall', label: 'Mall' },
    { value: 'hotel', label: 'Hotel' },
    { value: 'residential', label: 'Residential' },
  ]
  return (
    <div className="inputform options">
      <Select
        options={options}
        components={{
          ValueContainer: CustomValueContainer,
        }}
        styles={{
          container: (provided, state) => ({
            ...provided,
          }),

          valueContainer: (provided, state) => ({
            ...provided,
            overflow: 'visible',
          }),

          control: (base, state) => ({
            ...base,
            boxShadow: 'none',
            border: '1px solid #ced4da',
          }),
          placeholder: (provided, state) => ({
            ...provided,
            position: 'absolute',
            transitions: '0.5s',
            top: state.hasValue || state.selectProps.inputValue ? -1 : '50%',
            transition: 'top 0.1s, font-size 0.1s',
            fontSize: (state.hasValue || state.selectProps.inputValue) && 13,
          }),
        }}
        placeholder="Project Type"
        value={input.value}
        onChange={(value) => input.onChange(value)}
        noOptionsMessage={() => 'No Floors'}
      />
      {touched && error && <p className="price-box">{error}</p>}
    </div>
  )
}

export const hourDropDown = ({ type, label, input, meta: { touched, error } }) => {
  return (
    <div className="inputform">
      <select className="form-control input-box" {...input} placeholder={label} required>
        <option value="00:00">00:00</option>
        <option value="01:00">01:00</option>
        <option value="02:00">02:00</option>
        <option value="03:00">03:00</option>
        <option value="04:00">04:00</option>
        <option value="05:00">05:00</option>
        <option value="06:00">06:00</option>
        <option value="07:00">07:00</option>
        <option value="08:00">08:00</option>
        <option value="09:00">09:00</option>
        <option value="10:00">10:00</option>
        <option value="11:00">11:00</option>
        <option value="12:00">12:00</option>
        <option value="13:00">13:00</option>
        <option value="14:00">14:00</option>
        <option value="15:00">15:00</option>
        <option value="16:00">16:00</option>
        <option value="17:00">17:00</option>
        <option value="18:00">18:00</option>
        <option value="19:00">19:00</option>
        <option value="20:00">20:00</option>
        <option value="21:00">21:00</option>
        <option value="22:00">22:00</option>
        <option value="23:00">23:00</option>
      </select>
      {touched && error && <p className="price-box">{error}</p>}
    </div>
  )
}
export const userDropDown = ({ userData, input, meta: { touched, error } }) => {
  const capitalize_Words = (str) => {
    return (
      str &&
      str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
      })
    )
  }
  let options =
    userData &&
    userData.map((item) => ({
      label: `${item.firstName} ${item.lastName}`,
      value: item.id,
    }))

  const formatOptionLabel = ({ value, label }) => (
    <div style={{ display: 'flex' }}>
      <div>{capitalize_Words(label)}</div>
    </div>
  )
  return (
    <div>
      <Select
        defaultValue={options[0]}
        isDisabled={input.disabled}
        value={input.value}
        formatOptionLabel={formatOptionLabel}
        onChange={(value) => {
          input.onChange(value)
        }}
        options={options}
        placeholder="Select User"
      />
    </div>
  )
}
export const parentRoleDropDown = ({ parentRolesData, input, meta: { touched, error } }) => {
  let options =
    parentRolesData &&
    parentRolesData.map((item) => ({
      label: `${item.firstName} ${item.lastName}`,
      value: item.id,
    }))
  const capitalize_Words = (str) => {
    return (
      str &&
      str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
      })
    )
  }
  const formatOptionLabel = ({ value, label, customAbbreviation }) => (
    <div style={{ display: 'flex' }}>
      <div>{capitalize_Words(label)}</div>
      <div style={{ marginLeft: '10px', color: '#ccc' }}>
        {capitalize_Words(customAbbreviation)}
      </div>
    </div>
  )
  if (options.length === 0) {
    options.push({ label: 'None', value: null })
  }
  return (
    <div>
      <Select
        // defaultValue={editing ? options[0] : null}
        value={input.value}
        formatOptionLabel={formatOptionLabel}
        onChange={(value) => {
          input.onChange(value)
        }}
        options={options}
        placeholder="Select Manager"
      />
    </div>
  )
}

export const reportingTeamDropDown = ({ reportingTeamData, input, meta: { touched, error } }) => {
  let options =
    reportingTeamData &&
    reportingTeamData.map((item) => ({
      label: item.teamName,
      value: item.id,
    }))
  return (
    <div className="accessMulti">
      <Select
        value={input.value}
        isMulti={true}
        onChange={(value) => {
          input.onChange(value)
        }}
        options={options}
        placeholder="Select Reporting Team"
      />
    </div>
  )
}
export const teamDropDown = ({ teamData, input, meta: { touched, error }, ...props }) => {
  let options =
    teamData &&
    teamData.map((item) => ({
      label: item.teamName,
      value: item.id,
    }))
  if (!input.value.value) {
    input.value = null
  }
  return (
    <div>
      <Select
        defaultValue={options[0]}
        value={input.value}
        // formatOptionLabel={formatOptionLabel}
        onChange={(value) => {
          input.onChange(value)
        }}
        options={options}
        placeholder="Select Team"
        {...props}
      />
    </div>
  )
}
export const roleDropDown = ({ rolesData, input, meta: { touched, error } }) => {
  let options =
    rolesData &&
    rolesData.map((item) => ({
      label: item.roleName,
      value: item.id,
      customAbbreviation: item.description,
      roleName: item.subRole,
      parentRoleId: item.parentRoleID,
    }))
  if (!input.value.value) {
    input.value = null
  }
  const capitalize_Words = (str) => {
    return (
      str &&
      str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
      })
    )
  }
  const formatOptionLabel = ({ value, label, customAbbreviation }) => (
    <div style={{ display: 'flex' }}>
      <div>{capitalize_Words(label)}</div>
      <div style={{ marginLeft: '10px', color: '#ccc' }}>
        {capitalize_Words(customAbbreviation)}
      </div>
    </div>
  )
  return (
    <div>
      <Select
        defaultValue={options[0]}
        value={input.value}
        formatOptionLabel={formatOptionLabel}
        onChange={(value) => {
          input.onChange(value)
        }}
        options={options}
        placeholder="Select Role"
      />
    </div>
  )
}

export const franchiseDropDown = ({ franchiseData, input, meta: { touched, error } }) => {
  let options =
    franchiseData &&
    franchiseData
      .filter((item) => item.type === 'Franchise')
      .map((filteredItem) => {
        return {
          label: filteredItem.name,
          value: filteredItem.id,
        }
      })
  return (
    <div className="inputform accessMulti">
      <Select
        isMulti={true}
        value={input.value}
        onChange={(value) => {
          input.onChange(value)
        }}
        options={options}
        placeholder={'Select Franchise'}
      />
    </div>
  )
}

export const cityDropDown = ({ mainCities, input, meta: { touched, error } }) => {
  return (
    <div>
      <Select
        defaultValue={mainCities[0]}
        value={input.value}
        onChange={(value) => {
          input.onChange(value)
        }}
        options={mainCities}
        placeholder="Select City"
      />
    </div>
  )
}
export const shadowRoleDropDown = ({ rolesData, input, meta: { touched, error } }) => {
  let options =
    rolesData &&
    rolesData.map((item) => ({
      label: item.roleName,
      value: item.id,
      customAbbreviation: item.description,
      roleName: item.subRole,
    }))

  const capitalize_Words = (str) => {
    return (
      str &&
      str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
      })
    )
  }
  const formatOptionLabel = ({ value, label, customAbbreviation }) => (
    <div style={{ display: 'flex' }}>
      <div>{capitalize_Words(label)}</div>
      <div style={{ marginLeft: '10px', color: '#ccc' }}>
        {capitalize_Words(customAbbreviation)}
      </div>
    </div>
  )
  return (
    <div>
      <Select
        defaultValue={options[0]}
        value={input.value}
        formatOptionLabel={formatOptionLabel}
        onChange={(value) => {
          input.onChange(value)
        }}
        options={options}
        placeholder="Select Role"
      />
    </div>
  )
}

export const leadCityDropdown = ({ cities, label, input, meta: { touched, error } }) => {
  const [leadCityMenu, setLeadCityMenu] = useState(false)
  const handleSelected = (value) => {
    if (value && value.length === cities.length) {
      setTimeout(() => {
        setLeadCityMenu(false)
      }, 100)
    }
  }
  let options =
    cities &&
    cities.map((item) => ({
      label: item.name,
      value: item.id,
    }))
  return (
    <div onClick={() => setLeadCityMenu(true)}>
      <Select
        menuIsOpen={leadCityMenu ? true : false}
        isMulti
        value={input.value}
        onChange={(value) => {
          input.onChange(value)
          handleSelected(value)
        }}
        onBlur={() => {
          setLeadCityMenu(false)
        }}
        options={options}
        placeholder={label}
      />
    </div>
  )
}
export const officeLocation = ({ data, label, input, multi, meta: { touched, error } }) => {
  const [leadCityMenu, setLeadCityMenu] = useState(false)
  const handleSelected = (value) => {
    if (value && value.length === data.length) {
      setTimeout(() => {
        setLeadCityMenu(false)
      }, 100)
    }
  }
  let options = data && data.length > 0 ? data : [{ label: 'None', value: null }]

  return (
    <div>
      <Select
        // menuIsOpen={leadCityMenu ? true : false}
        isMulti={multi}
        value={input.value}
        onChange={(value) => {
          input.onChange(value)
          // handleSelected(value)
        }}
        // onBlur={() => {
        //   setLeadCityMenu(false)
        // }}
        options={options}
        placeholder={label}
      />
    </div>
  )
}

// export const leadCityDropdown = ({ cities, input, meta: { touched, error } }) => {
//   return (
//     <div>
//       <Select
//         onChange={(value) => {
//           input.onChange(value)
//         }}
//         options={cities}
//         placeholder="Select Lead City"
//         isMulti=
//       />
//     </div>
//   )
// }

export const groupAreasDropdown = ({ groupAreasData, input, meta: { touched, error } }) => {
  const [menuToggle, setMenuToggle] = useState(false)
  const handleSelected = (value) => {
    if (value && value.length === groupAreasData.length) {
      setTimeout(() => {
        setMenuToggle(false)
      }, 100)
    }
  }
  let options =
    groupAreasData &&
    groupAreasData.map((item) => ({
      label: item.name,
      value: item.id,
    }))
  return (
    <div onClick={() => setMenuToggle(true)}>
      <Select
        menuIsOpen={menuToggle ? true : false}
        isMulti
        value={input.value}
        onChange={(value) => {
          input.onChange(value)
          handleSelected(value)
        }}
        onBlur={() => {
          setMenuToggle(false)
        }}
        options={options}
        placeholder="Select Area Group"
      />
    </div>
  )
}

export const managerDropdown = ({
  managerList,
  defaultValue,
  managerId,
  input,
  meta: { touched, error },
}) => {
  managerId(defaultValue && defaultValue.id)
  return (
    <div className="inputform">
      <select
        className={`form-control input-box ${input.value ? 'selectedText' : ''}`}
        {...input}
        required
        value={defaultValue && defaultValue.id}
        disabled={defaultValue ? defaultValue : true}
      >
        <option>{defaultValue ? '' : 'Manager does not exist'}</option>
        {managerList.map((item) => (
          <option value={item.id} managerZoneId={item.zoneId} key={item.id}>
            Manager : {item.firstName + ' ' + item.lastName}
          </option>
        ))}
      </select>
      {touched && error && <p className="price-box">{error}</p>}
    </div>
  )
}

export const NewManagerList = ({ newManagerList, input, meta: { touched, error } }) => {
  return (
    <div className="inputform">
      <select
        className={`form-control input-box ${input.value ? 'selectedText' : ''}`}
        {...input}
        required
      >
        <option>Select Manager List</option>
        {newManagerList &&
          newManagerList.map((item) => (
            <option value={item.id} managerZoneId={item.zoneId} key={item.id}>
              {item.firstName + ' ' + item.lastName}
            </option>
          ))}
      </select>
      {touched && error && <p className="price-box">{error}</p>}
    </div>
  )
}

export const fetchRegionsList = ({ regions, input, disabled, meta: { touched, error } }) => {
  return (
    <div className="inputform">
      <select
        className={`form-control input-box ${input.value ? 'selectedText' : ''}`}
        {...input}
        required
        disabled={disabled}
      >
        <option>Select Region</option>
        {regions.map((item) => (
          <option value={item.id} key={item.id}>
            {item.name}
          </option>
        ))}
      </select>
      {touched && error && <p className="price-box">{error}</p>}
    </div>
  )
}

export const fetchRegionalZones = ({
  regionalZones,
  organizationName,
  input,
  meta: { touched, error },
}) => {
  return (
    <div className="inputform">
      <select
        className={`form-control input-box ${input.value ? 'selectedText' : ''}`}
        {...input}
        required
      >
        <option>
          {organizationName === 'agency21' || organizationName === 'Agency21'
            ? 'Select Branch'
            : 'Select Zone'}
        </option>
        {regionalZones &&
          regionalZones.map((item) => <option value={item.id}>{item.zone_name}</option>)}
      </select>
      {touched && error && <p className="price-box">{error}</p>}
    </div>
  )
}

export const subRoleDropdown = ({ subRoleData, input, meta: { touched, error } }) => {
  function capitalize_Words(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    })
  }
  return (
    <div className="inputform">
      <select
        className={`form-control input-box ${input.value ? 'selectedText' : ''}`}
        {...input}
        required
      >
        <option>Select Sub Role</option>
        {subRoleData &&
          subRoleData.map((item) => (
            <option value={item.id} subRoleName={item.subRole} key={item.id}>
              {capitalize_Words(item.subRole.replace(/_/g, ' '))}
            </option>
          ))}
      </select>
      {touched && error && <p className="price-box">{error}</p>}
    </div>
  )
}

export const TeamType = ({ input, disabled, isPP }) => {
  return (
    <div className="inputform">
      <select
        className={`form-control input-box ${input.value || isPP ? 'selectedText' : ''}`}
        {...input}
        value={isPP ? 'realEstate' : input.value}
        disabled={disabled ? true : isPP ? true : false}
        defaultValue="realEstate"
        required
      >
        <option>Team Type</option>
        {isPP ? (
          <option value="realEstate">Real Estate</option>
        ) : (
          <>
            <option value="realEstate">Real Estate</option>
            <option value="businessCentre">Business Centre</option>
            <option value="callCentre">Call Centre</option>
          </>
        )}
      </select>
    </div>
  )
}

export const TeamZonesDropdown = ({ input, zones }) => {
  return (
    <div className="inputform">
      <select
        className={`form-control input-box ${input.value ? 'selectedText' : ''}`}
        {...input}
        required
      >
        <option>Select Zones</option>
        {zones &&
          zones.map((item) => (
            <option value={item.id} key={item.id}>
              {item.zone_name}
            </option>
          ))}
      </select>
    </div>
  )
}

export class TeamProjects extends Component {
  constructor(props) {
    super(props)
    this.state = {
      multiValue: [],
      selectedValue: '',
      menuToggle: false,
    }
  }

  handleMultiChange = (option) => {
    const projects = option.map((item) => ({
      areaName: item.value,
      areaId: item.areaId,
    }))

    this.setState((state) => {
      return {
        multiValue: option,
        projects,
      }
    })
  }

  handleSelected = (value) => {
    const { projects } = this.props
    if (value && value.length === projects.length) {
      setTimeout(() => {
        this.setState({
          menuToggle: false,
        })
      }, 100)
    }
  }

  render() {
    const { input, projects, projectType } = this.props
    const { menuToggle } = this.state
    let modifiedProjects
    modifiedProjects =
      projects &&
      projects.map((item) => ({
        label: item.name,
        value: item.id,
      }))

    return (
      <div className="inputform" onClick={() => this.setState({ menuToggle: true })}>
        <Select
          {...input}
          menuIsOpen={menuToggle ? true : false}
          options={modifiedProjects}
          value={input.value}
          onChange={(value) => {
            input.onChange(value)
            this.handleSelected(value)
          }}
          isMulti={projectType === 'realEstate' ? false : true}
          onBlur={() => {
            this.props.input.onBlur(this.props.input.value)
            this.setState({
              menuToggle: false,
            })
          }}
          onFocus={() => this.setState({ menuToggle: true })}
          placeholder="Select Projects"
        />
      </div>
    )
  }
}

export const clientPurpose = ({ input }) => {
  return (
    <div className="inputform">
      <select className="form-control input-box" {...input} required>
        <option> Client Purpose</option>
        <option value="Realestate">Real Estate</option>
        <option value="Project">Project</option>
      </select>
    </div>
  )
}

export const branchDropdown = ({ input, officeBranch }) => {
  return (
    <div className="inputform">
      <select
        className={`form-control input-box ${input.value ? 'selectedText' : ''}`}
        {...input}
        required
      >
        <option>
          {officeBranch.length > 0 ? 'Select Office Branch' : 'No Branches added for this city'}
        </option>
        {officeBranch.map((item) => (
          <option value={item.id}>{item.branchName}</option>
        ))}
      </select>
    </div>
  )
}

export const zoneManagerDropdown = ({ input, zoneName }) => {
  return (
    <div className="inputform">
      <select
        className={`form-control input-box ${input.value ? 'selectedText' : ''}`}
        {...input}
        required
        disabled
      >
        <option>{zoneName}</option>
      </select>
    </div>
  )
}

export const accessOfficeLocations = ({
  data,
  label,
  input,
  multi,
  meta: { touched, error },
  loading = false,
}) => {
  const [leadCityMenu, setLeadCityMenu] = useState(false)
  const handleSelected = (value) => {
    if (value && value.length === data.length) {
      setTimeout(() => {
        setLeadCityMenu(false)
      }, 100)
    }
  }
  let options = data.reduce((unique, o) => {
    if (!unique.some((obj) => obj.label === o.label && obj.value === o.value)) {
      unique.push(o)
    }
    return unique
  }, [])

  return (
    <div className={'officeMulti'} onClick={() => setLeadCityMenu(true)}>
      <Select
        menuIsOpen={leadCityMenu ? true : false}
        isMulti={true}
        className={`projSelect cus-org-select`}
        value={input.value}
        onChange={(value) => {
          input.onChange(value)
          handleSelected(value)
        }}
        onBlur={() => {
          setLeadCityMenu(false)
        }}
        options={options}
        placeholder={label}
        isLoading={loading}
      />
    </div>
  )
}
export const accessOrganization = ({ data, label, input, multi, meta: { touched, error } }) => {
  const role = localStorage.getItem('role')
  let options =
    data &&
    data.map((item) => ({
      label: item.name,
      value: item.id,
    }))
  return (
    <div className="inputform accessMulti">
      <Select
        isMulti={true}
        value={input.value}
        onChange={(value) => {
          input.onChange(value)
        }}
        options={options}
        placeholder={label}
      />
    </div>
  )
}

export const RenderSelectDropdown = ({ options, placeholder, input, ...props }) => {
  return (
    <div className="inputform accessMulti">
      <Select
        value={input.value ? input.value : null}
        onChange={(value) => {
          input.onChange(value)
        }}
        options={options}
        placeholder={placeholder}
        {...props}
      />
    </div>
  )
}

export const organizationDropdown = ({ input, organizations, isDisabled, isGroupAdmin }) => {
  const role = localStorage.getItem('role')

  let modifiedOrganization

  if (organizations) {
    modifiedOrganization =
      role === 'super_admin' || isGroupAdmin
        ? [{ name: 'None', id: 0 }, ...organizations]
        : organizations
  }

  let options =
    organizations &&
    modifiedOrganization.map((item) => ({
      label: item.name,
      value: item.id,
    }))
  return (
    <div className="inputform text-left over-ride-class">
      <Select
        styles={{
          menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
          menu: (provided) => ({ ...provided, zIndex: 9999 }),
        }}
        value={input.value}
        onChange={(value) => {
          input.onChange(value)
        }}
        options={options}
        isDisabled={isDisabled}
        placeholder="Select Organization"
      />
    </div>
  )
}

// export const userTeamDropdown = ({ input, teams }) => {
//   return (
//     <div className="inputform">
//       <select
//         className={`form-control input-box ${input.value ? 'selectedText' : ''}`}
//         {...input}
//         required
//       >
//         <option>Select Team</option>
//         {teams &&
//           teams.map((item) => (
//             <option value={item.id} organizationname={item.name} key={item.id}>
//               {item.teamName}
//             </option>
//           ))}
//       </select>
//     </div>
//   )
// }
export class userTeamDropdown extends Component {
  constructor(props) {
    super(props)
    this.state = {
      multiValue: [],
      selectedValue: '',
      menuToggle: false,
    }
  }

  // handleMultiChange = (option) => {
  //   console.log('###', option)
  //   const teams = option.map((item) => ({
  //     TeamName: item.value,
  //     Id: item.id,
  //   }))

  //   this.setState((state) => {
  //     return {
  //       multiValue: option,
  //       teams,
  //     }
  //   })
  // }

  handleSelected = (value) => {
    const { teams } = this.props
    if (value && value.length === teams.length) {
      setTimeout(() => {
        this.setState({
          menuToggle: false,
        })
      }, 100)
    }
  }

  render() {
    const { input, teams, roleName } = this.props
    const { menuToggle } = this.state
    let modifiedTeams
    modifiedTeams =
      teams &&
      teams.map((item) => ({
        label: item.teamName,
        value: item.id,
      }))

    return (
      <div className="inputform" onClick={() => this.setState({ menuToggle: true })}>
        <Select
          {...input}
          menuIsOpen={menuToggle ? true : false}
          options={modifiedTeams}
          value={input.value}
          onChange={(value) => {
            input.onChange(value)
            this.handleSelected(value)
          }}
          // isMulti={projectType === 'realEstate' ? false : true}
          isMulti={
            roleName == 'business_centre_manager' ||
            roleName == 'business_centre_agent' ||
            roleName == 'zonal_manager' ||
            roleName == 'area_manager' ||
            roleName == 'sales_agent' ||
            roleName == 'branch_manager' ||
            roleName == 'call_centre_warrior' ||
            roleName == 'call_centre_agent' ||
            roleName == 'call_centre_manager' ||
            roleName == 'pp_manager' ||
            roleName == 'pp_agent'
              ? false
              : true
          }
          onBlur={() => {
            this.props.input.onBlur(this.props.input.value)
            this.setState({
              menuToggle: false,
            })
          }}
          onFocus={() => this.setState({ menuToggle: true })}
          placeholder="Select Teams"
        />
      </div>
    )
  }
}
export class regionalDropdown extends Component {
  constructor(props) {
    super(props)
    this.state = {
      multiValue: [],
      selectedValue: '',
      menuToggle: false,
    }
  }

  handleSelected = (value) => {
    const { regions } = this.props
    if (value && value.length === regions.length) {
      setTimeout(() => {
        this.setState({
          menuToggle: false,
        })
      }, 100)
    }
  }

  render() {
    const { input, regions, roleName } = this.props
    const { menuToggle } = this.state
    let regionsOptions
    regionsOptions =
      regions &&
      regions.map((item) => ({
        label: item.name,
        value: item.id,
      }))

    return (
      <div className="inputform" onClick={() => this.setState({ menuToggle: true })}>
        <Select
          {...input}
          menuIsOpen={menuToggle ? true : false}
          options={regionsOptions}
          value={input.value}
          onChange={(value) => {
            input.onChange(value)
            this.handleSelected(value)
          }}
          isMulti={true}
          onBlur={() => {
            this.props.input.onBlur(this.props.input.value)
            this.setState({
              menuToggle: false,
            })
          }}
          onFocus={() => this.setState({ menuToggle: true })}
          placeholder="Select Regions"
        />
      </div>
    )
  }
}
export const ZonesDropDown = ({ input, zonesData, organizationName, editing }) => {
  let zonesLength = Object.keys(zonesData && zonesData).length
  return (
    <div className="inputform">
      {editing && zonesLength === 0 ? null : (
        <select
          className="form-control input-box"
          {...input}
          required
          onClick={() =>
            zonesLength === 0
              ? Swal.fire({
                  type: 'error',
                  icon: 'error',
                  title: 'Error',
                  text: `${
                    organizationName === 'Agency21' || organizationName === 'agency21'
                      ? 'Branch'
                      : 'Zones'
                  } already assigned , Please create new one`,
                })
              : ''
          }
        >
          <option>
            {organizationName === 'Agency21' || organizationName === 'agency21'
              ? 'Select Branch'
              : 'Select Zone'}
          </option>
          {zonesData &&
            zonesData.map((item) => (
              <option value={item.id} zoneName={item.zone_name}>
                {item.zone_name}
              </option>
            ))}
        </select>
      )}
    </div>
  )
}

export const BranchManagers = ({ input, ManagerListShow, branchManagerData }) => {
  return (
    <div className="inputform">
      <select
        className={`form-control input-box ${input.value ? 'selectedText' : ''}`}
        {...input}
        required
      >
        <option>Select Branch Managers</option>
        {branchManagerData &&
          branchManagerData.map((item) => (
            <option value={item.id} zoneId={item.zoneId} key={item.id}>
              {item.firstName + ' ' + item.lastName}
            </option>
          ))}
      </select>
    </div>
  )
}

export const zonalManagerDropdown = ({ input, ManagerListShow, zonalManagerData }) => {
  return (
    <div className="inputform">
      <select
        className={`form-control input-box ${input.value ? 'selectedText' : ''}`}
        {...input}
        required
      >
        <option>Select Zonal Managers</option>
        {zonalManagerData &&
          zonalManagerData.map((item) => (
            <option value={item.id} zoneId={item.zoneId} key={item.id}>
              {item.firstName + ' ' + item.lastName}
            </option>
          ))}
      </select>
    </div>
  )
}

export const agencyDropDown = ({ input, agencies, type }) => {
  return (
    <div className="inputform">
      <select className="form-control input-box" {...input} required>
        <option>Select Agency</option>
        {agencies &&
          agencies.map((item) => (
            <option value={type === 'text' ? item.name : item.id + ',' + item.name} key={item.id}>
              {item.name}
            </option>
          ))}
      </select>
    </div>
  )
}

export const areaMangaerDropdown = ({ input, zoneAreas }) => {
  let areas =
    zoneAreas &&
    zoneAreas.map((item) => ({
      label: item.areaName,
      value: item.areaName,
    }))
  return (
    <div className="inputform">
      <Select
        name="filters"
        placeholder="Select your areas"
        value={areas}
        options={areas}
        isMulti
      />
    </div>
  )
}

export const propertyDropDown = ({ type, label, input, meta: { touched, error } }) => {
  return (
    <div className="inputform">
      <select className="form-control input-box" {...input} required>
        <option value=""> Property Type </option>
        <option value="House">House</option>
        <option value="Appartment">Appartment</option>
        <option value="plot">Plot</option>
        <option value="room">Room</option>
      </select>
      {touched && error && <p className="price-box">{error}</p>}
    </div>
  )
}

export const areaUnit = ({ type, label, input, meta: { touched, error } }) => {
  return (
    <div className="inputform">
      <select className="form-control input-box" {...input} required>
        <option value=""> Area Unit </option>
        <option value="sqft">Sqft</option>
        <option value="marla">Marla</option>
        <option value="kanal">Kanal</option>
      </select>
      {touched && error && <p className="price-box">{error}</p>}
    </div>
  )
}

export const SelectAssigned = ({ type, label, input, meta: { touched, error } }) => {
  return (
    <div className="inputform">
      <select className="form-control input-box" {...input} required>
        <option value=""> Area Unit </option>
        <option value="sqft">Sqft</option>
        <option value="marla">Marla</option>
        <option value="kanal">Kanal</option>
      </select>
      {touched && error && <p className="price-box">{error}</p>}
    </div>
  )
}

export class fileUplaod extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedValue: '',
      fileName: '',
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps && nextProps.hasFile === undefined) {
      this.setState({
        selectedValue: 3 * Math.random(),
        fileName: '',
      })
    }
  }

  onChange = ({ target: { files } }) => {
    const { setError, setSuccess } = this.props
    files.value = null
    if (files[0].type !== 'text/csv') {
      Swal.fire({
        type: 'error',
        icon: 'error',
        title: 'Error',
        text: 'Sorry, This file is not supported. Please select CSV instead.',
      })
      return
    }
    const {
      input: { onChange },
    } = this.props
    setError(false)
    setSuccess(false)
    onChange(files)
    this.setState({
      fileName: files,
    })
  }

  render() {
    const { fileName } = this.state
    const { buttonDisabled, fileFlag } = this.props
    return (
      <div className="fileUploadStyle">
        <input
          key={this.state.selectedValue}
          type="file"
          disabled={buttonDisabled}
          accept=".csv"
          onChange={this.onChange}
          style={{ outline: 'none' }}
        />
        <label id="fileInput">
          {fileName && !fileFlag ? fileName[0].name : 'Please choose a file'}
        </label>
      </div>
    )
  }
}

export class FileInput extends Component {
  constructor(props) {
    super(props)
    this.state = {
      images: [],
      defaultImage: '',
    }

    this.removeImages.bind(this)
  }

  componentWillMount() {
    this.setState({
      defaultImage: this.props.builtImage,
    })
  }

  fileToBase64(filesList) {
    /// logic
    // console.log(value.target.files);
    var base64ImgArray = []
    for (var i = 0; i < filesList.length; i++) {
      this.readBase64(filesList[i])
    }
    this.props.input.onChange(this.state.images)
  }

  removeImages(index) {
    let { images } = this.state
    images.splice(index, 1)
    this.setState({
      images,
    })
  }

  defaultRemove(index) {
    this.setState({
      defaultImage: '',
    })
  }

  readBase64 = (file) => {
    var reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      // console.log(reader.result);
      // base64ImgArray.push(reader.result);
      var { images } = this.state
      images.push(reader.result)
      this.setState({ images, defaultImage: '' })
    }
    reader.onerror = (error) => {}
  }

  render() {
    return (
      <>
        <input
          multiple
          onChange={(value) => {
            this.fileToBase64(value.target.files)
          }}
          type="file"
        />

        <div class="grid-container">
          {this.state.defaultImage && this.state.defaultImage ? (
            <div className="grid-item" style={{ marginTop: '10px' }}>
              <div className="closeButton" onClick={() => this.defaultRemove(0)}>
                X
              </div>
              <img src={this.state.defaultImage} alt="images" className="imagePreview"></img>
            </div>
          ) : (
            this.state.images &&
            this.state.images.map((items, index) => {
              return (
                <div className="grid-item" style={{ marginTop: '10px' }}>
                  <div className="closeButton" onClick={() => this.removeImages(index)}>
                    X
                  </div>
                  <img src={items} alt="images" className="imagePreview"></img>
                </div>
              )
            })
          )}
        </div>
      </>
    )
  }
}

export const SegmentControl = (props) => {
  return (
    <div className={props.containerClass || ''}>
      <ul className="requirement-type">
        {props.Search === true && (
          <li
            className={`${
              props.currentValue === props.thirdValue
                ? 'active-requirement text-capitalize'
                : 'text-capitalize'
            }`}
            onClick={() => {
              props.onPurposeChange(props.thirdValue)
            }}
          >
            {props.thirdValue.split('_').join(' ')}
          </li>
        )}
        <li
          className={`${
            props.currentValue === props.firstValue
              ? 'active-requirement text-capitalize'
              : 'text-capitalize'
          }`}
          onClick={() => {
            props.onPurposeChange(props.firstValue)
          }}
        >
          {props.firstValue.split('_').join(' ')}
        </li>
        <li
          className={`${
            props.currentValue === props.secondValue
              ? 'active-requirement text-capitalize'
              : 'text-capitalize'
          }`}
          onClick={() => {
            props.onPurposeChange(props.secondValue)
          }}
        >
          {props.secondValue.split('_').join(' ')}
        </li>
      </ul>
    </div>
  )
}

export class RenderAreasSelection extends Component {
  constructor(props) {
    super(props)
    this.state = {
      multiValue: [],
      selectedValue: '',
      menuToggle: false,
      inputValue: '',
    }
  }
  handleMultiChange = (option) => {
    const areas = option.map((item) => ({
      areaName: item.value,
      areaId: item.areaId,
    }))

    this.setState((state) => {
      return {
        multiValue: option,
        areas: areas,
      }
    })
  }

  onInputChange = (query, { action }) => {
    // Prevents resetting our input after option has been selected
    if (action !== 'set-value') this.setState({ inputValue: query })
  }

  handleSelected = (value) => {
    const { options } = this.props

    if (value && value.length === options.length) {
      setTimeout(() => {
        this.setState({
          menuToggle: false,
        })
      }, 100)
    }
  }

  render() {
    const { options, input, editing, accountsTeam } = this.props
    const { menuToggle, inputValue } = this.state
    const groupeData = _.groupBy(options, 'city.name')
    let result = _.map(_.values(groupeData), (arr) => _.groupBy(arr, 'city.name'))

    const groupStyles = {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    }
    const groupBadgeStyles = {
      backgroundColor: '#EBECF0',
      borderRadius: '2em',
      color: '#172B4D',
      display: 'inline-block',
      fontSize: 12,
      fontWeight: 'normal',
      lineHeight: '1',
      minWidth: 1,
      padding: '0.16666666666667em 0.5em',
    }

    const formatGroupLabel = (data) => (
      <>
        {accountsTeam ? null : (
          <div style={groupStyles}>
            <span>{accountsTeam ? null : data.label}</span>
            <span style={groupBadgeStyles}>{data.options.length}</span>
          </div>
        )}
      </>
    )

    const groupedOptions = result.map((item, index) => ({
      label: `${Object.keys(item)}`,
      options: item[Object.keys(item)],
    }))

    return (
      <div
        onClick={() => {
          this.setState({ menuToggle: true })
          return editing
            ? ''
            : options.length === 0
            ? Swal.fire({
                type: 'error',
                icon: 'error',
                title: 'Error',
                text: `Please options from`,
              })
            : ''
        }}
      >
        <Select
          {...input}
          options={groupedOptions}
          formatGroupLabel={formatGroupLabel}
          menuIsOpen={menuToggle ? true : false}
          inputValue={inputValue}
          onSelectResetsInput={false}
          onBlurResetsInput={false}
          blurInputOnSelect={false} //set by default, but to be sure
          closeMenuOnSelect={false} //prevents menu close after select, which would also result in input blur
          onInputChange={this.onInputChange} //the function to handle our search input
          value={input.value}
          onChange={(value) => {
            input.onChange(value)
            this.handleSelected(value)
          }}
          onBlur={() => {
            this.props.input.onBlur(this.props.input.value)
            this.setState({
              menuToggle: false,
            })
          }}
          onFocus={() => this.setState({ menuToggle: true })}
          placeholder={accountsTeam ? 'Select Teams' : 'Select Areas : Type to filter'}
          isMulti
        />
      </div>
    )
  }
}

// export const RenderSelectInput = ({ input, options, name, id }) => (

// );

export class RenderSelectInput extends Component {
  render() {
    const {
      meta: { touched, error },
      disabled,
    } = this.props
    const className = ` form-group mb-3 ${touched && error ? 'has-danger' : ''}`
    let valuesToDisplay

    if (this.props.input.value.constructor === Array) {
      var valuesArray = []
      this.props.input.value.map((item) => {
        let options = Object.assign([], this.props.options)
        options.map((option) => {
          if (option.value == item) {
            valuesArray.push(option)
          }
        })
      })
      valuesToDisplay = valuesArray
    } else if (this.props.input.value) {
      valuesToDisplay = this.props.options.filter((option) => {
        if (option.value == this.props.input.value) {
          return true
        }
      })
    }
    return (
      <div>
        <Select
          {...this.props}
          value={this.props.input.value}
          onChange={(value) => {
            if (value && value.constructor === Array) {
              var valuesArray = []
              value &&
                value.map((item) => {
                  return valuesArray.push(item)
                })
              this.props.input.onChange(valuesArray)
            } else {
              this.props.input.onChange(value && value)
              // this.props.input.onChange(
              //   value && { id: value.value, name: value.label }
              // );
            }
          }}
          // key={Math.random(0,100)}
          onBlur={() => this.props.input.onBlur(this.props.input.value)}
          options={this.props.options}
          placeholder={this.props.placeholder}
          isMulti={this.props.multi ? true : false}
          className={'react-select-container'}
          isDisabled={disabled ? true : false}
        />
      </div>
    )
  }
}
// export class RenderCityInput extends Component {
//   constructor(){

//   }
//   render() {
//     const {getOptionLabel,getOptionValue,clearFlag} = this.props

//     const {
//       meta: { touched, error },
//     } = this.props;
//     const divClass = ` form-group mb-3 ${
//       touched && error ? "has-danger" : ""
//     }`;
//     // let valuesToDisplay;

//     // if (this.props.input.value.constructor === Array) {
//     //   var valuesArray = [];
//     //   this.props.input.value.map((item) => {
//     //     let options = Object.assign([], this.props.options);
//     //     options.map((option) => {
//     //       if (option.value == item) {
//     //         valuesArray.push(option);
//     //       }
//     //     });
//     //   });
//     //   valuesToDisplay = valuesArray;
//     // } else if (this.props.input.value) {
//     //   valuesToDisplay = this.props.options.filter((option) => {
//     //     if (option.id == this.props.input.value) {
//     //       return true;
//     //     }
//     //   });
//     // }

//     let a = values.map((item)=> {
//       label:item.name,
//       value:item .value
//     })
//     return (
//       <div>
//         <Select
//           closeMenuOnSelect={false}

//           {...this.props}
//           // value={valuesToDisplay && console.log(valuesToDisplay)}
//           onChange={(value) => {
//             if(value == null){
//               return
//             }
//             else if (value.length > 0) {

//               var valuesArray = [];
//               value.map((item) => {

//                 return valuesArray.push(item.name);
//               });
//               this.props.input.onChange(valuesArray);
//             } else {
//               this.props.input.onChange(value && value);
//               this.props.input.onChange(
//                 value && { id: value.value, name: value.label }
//               );
//             }
//           }}
//           // key={Math.random(0,100)}
//           onBlur={() => this.props.input.onBlur(this.props.input.value)}
//           options={this.props.options}
//           value={''}
//           placeholder={this.props.placeholder}
//           isMulti={this.props.multi ? true : false}
//           className={"react-select-container"}
//           getOptionLabel={getOptionLabel ? ({name})=> name : (option=>option.label)}
//           getOptionValue={getOptionValue ? ({id})=> id : (option=>option.value)}
//         />
//         <div className={divClass}>
//           <div className="text-help">{touched ? error : ""}</div>
//         </div>
//       </div>
//     );
//   }
//   // constructor(props) {
//   //   super(props);
//   //   this.state = {
//   //     select: {
//   //       value: [],
//   //     }
//   //   };
//   // }

//   // componentWillReceiveProps(nextProps){
//   //   if(nextProps.clearValue){
//   //     this.setValue(null)
//   //   }
//   // }

//   // setValue = value => {
//   //   this.setState(prevState => ({
//   //     select: {
//   //       ...prevState.select,
//   //       value
//   //     }
//   //   }));
//   // };

//   // handleChange = value => {
//   //   console.log('in handleChange of renderCity',value)
//   //   this.setValue(value);
//   // };

//   // handleClick = () => {
//   //   this.setValue(null);
//   // };
//   render() {
//     const { select } = this.state;
//     const {options,getOptionLabel,getOptionValue,input} = this.props;

//     const option = [
//       { value: "one", label: "One" },
//       { value: "two", label: "Two" }
//     ];
//     const newValues = option.map((item)=>({
//       label:item.value,
//       value:item.value
//     }))

//     return (
//       <div>
//         {/* <p>
//           <button type="button" onClick={this.handleClick}>
//             Reset value
//           </button>
//         </p> */}
//         <Select
//           {...input}
//           closeMenuOnSelect={false}
//           name="form-field-name"
//           value={input.value}
//           onChange={ input.onChange(input.value)}
//           options={options}
//           isMulti
//           getOptionLabel={getOptionLabel ? ({name})=> name : (option=>option.label)}
//           getOptionValue={getOptionValue ? ({id})=> id : (option=>option.value)}
//         />
//       </div>
//     );
//   }
// }
export class RenderMultiAreasSelection extends Component {
  constructor(props) {
    super(props)
    this.state = {
      multiValue: [],
      selectedValue: '',
      menuToggle: false,
      inputValue: '',
    }
  }
  handleMultiChange = (option) => {
    const areas = option.map((item) => ({
      areaName: item.value,
      areaId: item.areaId,
    }))

    this.setState((state) => {
      return {
        multiValue: option,
        areas: areas,
      }
    })
  }

  onInputChange = (query, { action }) => {
    // Prevents resetting our input after option has been selected
    if (action !== 'set-value') this.setState({ inputValue: query })
  }

  handleSelected = (value) => {
    const { options } = this.props

    if (value && value.length === options.length) {
      setTimeout(() => {
        this.setState({
          menuToggle: false,
        })
      }, 100)
    }
  }

  render() {
    const { options, input, editing, areaFlag } = this.props
    const { menuToggle, inputValue } = this.state
    const groupeData = _.groupBy(options, 'city.name')
    let result = _.map(_.values(groupeData), (arr) => _.groupBy(arr, 'city.name'))

    const groupStyles = {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    }
    const groupBadgeStyles = {
      backgroundColor: '#EBECF0',
      borderRadius: '2em',
      color: '#172B4D',
      display: 'inline-block',
      fontSize: 12,
      fontWeight: 'normal',
      lineHeight: '1',
      minWidth: 1,
      padding: '0.16666666666667em 0.5em',
    }

    const formatGroupLabel = (data) => (
      <div style={groupStyles}>
        <span>{data.label}</span>
        <span style={groupBadgeStyles}>{data.options.length}</span>
      </div>
    )

    const groupedOptions = result.map((item, index) => ({
      label: `${Object.keys(item)}`,
      options: item[Object.keys(item)],
    }))

    let modifiedOptions =
      options &&
      options.map((item) => ({
        label: item.name,
        value: item.id,
      }))

    return (
      <div
        onClick={() => {
          this.setState({ menuToggle: true })
          return editing
            ? ''
            : options.length === 0
            ? Swal.fire({
                type: 'error',
                icon: 'error',
                title: 'Error',
                text: `Please select city from dropdown`,
              })
            : ''
        }}
      >
        <Select
          className={areaFlag ? 'highlight' : 'customFilterColor'}
          {...input}
          options={modifiedOptions}
          formatGroupLabel={formatGroupLabel}
          menuIsOpen={menuToggle ? true : false}
          inputValue={inputValue}
          isClearable={false}
          onSelectResetsInput={false}
          onBlurResetsInput={false}
          blurInputOnSelect={false} //set by default, but to be sure
          closeMenuOnSelect={false} //prevents menu close after select, which would also result in input blur
          onInputChange={this.onInputChange} //the function to handle our search input
          value={input.value}
          onChange={(value) => {
            input.onChange(value)
            this.handleSelected(value)
          }}
          onBlur={() => {
            this.props.input.onBlur(this.props.input.value)
            this.setState({
              menuToggle: false,
            })
          }}
          onFocus={() => this.setState({ menuToggle: true })}
          placeholder={this.props.areaTableFlag ? 'Select Cities' : 'Select Areas : Type to filter'}
          isMulti
        />
      </div>
    )
  }
}

// export const RenderSelectInput = ({ input, options, name, id }) => (

// export const isEmail = value =>
export const isRequired = (value) => (value ? undefined : 'This Field is Required')

export const isFieldRequired = (value) => (value ? undefined : true)

export const isAdminFieldRequired = (value, allValues) => {
  if (value !== allValues.password) {
    return undefined
  } else if (value === undefined) {
    return undefined
  } else {
    return true
  }
}

export const passwordsMustMatch = (value, allValues) => {
  return value !== allValues.password ? 'Passwords do not match' : undefined
}

export const renderPhoneNumber = ({
  input,
  value,
  phoneNumber,
  setPhone,
  meta: { touched, error },
}) => {
  return (
    <>
      <PhoneInput
        placeholder="Enter phone number"
        country={'pk'}
        inputProps={{
          name: 'phone',
          style: {
            width: '100%',
            height: 38,
            marginTop: '4px!important',
          },
        }}
        value={removeZerowithoutPlus(input.value)}
        onChange={(value, country) => {
          input.onChange(value)
        }}
        inputClass={'form-control'}
        countryCodeEditable={false}
      />
      {touched && error && <p className="price-box user-validation">{error}</p>}
    </>
  )
}
