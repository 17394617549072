/** @format */

import React, { useEffect, useRef, useState } from 'react'
import { Field, formValueSelector, reduxForm } from 'redux-form'
import { Form, Input } from 'reactstrap'
import {
  priceRange,
  properties,
  sizeRange,
  leadType as leadTypes,
  allLeadTypes,
  bedBathRange,
} from '../../../utils/constant_data'
import {
  bedBathRanger,
  investmentLeadAreaDrodpown,
  investmentLeadCityDropdown,
  isFieldRequired,
  paymentRanger,
  renderOptions,
  sizeRanger,
  renderPhoneField,
} from '../../ReduxForm'
import { fetchAreas } from '../../../actions/area'
import { fetchSubProperties } from '../../../actions/property'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import Button from '../../common/Button'
import AddPropertyModal from '../AddPropertyModal'
import { connectFormReqFields } from '../../../utils/validate'
import { normalizeCnic } from '../../../utils/formatCnic'
import RequirementReadView from './ConnectInfo/RequirementReadView'
import Chip from '../../common/Chip'
import { formatPriceRange } from '../../../utils/formatPrice'

export const isInvalidPhone = (value) => value && value.length < 6

const useStyles = makeStyles({
  root: {
    display: 'grid',
    gridAutoColumns: '50%',
    gridAutoFlow: 'column',
    gridTemplateAreas: '"CLIENT REQUIREMENT"\n' + '"ACTION ACTION"',
  },
  clientSection: { gridArea: 'CLIENT' },
  requirementSection: { gridArea: 'REQUIREMENT' },
  actionSection: { gridArea: 'ACTION' },
  field: {
    borderRadius: '4px!important',
  },
  form: {
    overflowY: 'hidden',
    overflowX: 'hidden',
    '&:hover': {
      overflowY: 'auto',
    },
  },
  clientForm: { height: 472 },
  requirementForm: { height: 472, bottom: 1, marginBottom: 18, position: 'relative' },
})

const required = (value) => (value ? undefined : 'Required')

const closest = (size, op, options) => {
  if (size === '' && op === 'lg') return options['rent'][options['rent'].length - 1]
  let closestVal = options['rent'].reduce((a, b) => {
    return Math.abs(b - size) < Math.abs(a - size) ? b : a
  })
  return options['rent'].indexOf(closestVal)
}

const closestSize = (size, op, unit, options) => {
  if (!unit) {
    return op === 'lg' ? 1 : 0
  }
  if (size === '' && op === 'lg') return options.unitRange[unit][options.unitRange[unit].length - 1]
  let closestVal = options.unitRange[unit].reduce((a, b) => {
    return Math.abs(b - size) < Math.abs(a - size) ? b : a
  })
  return options.unitRange[unit].indexOf(closestVal)
}

let ConnectForm = (props) => {
  const submitBtnRef = useRef(null)
  const [propertyModal, setPropertyModal] = useState(false)

  const classes = useStyles(props)
  const {
    handleSubmit,
    submitting,
    fetchAreas,
    fetchSubProperties,
    cities,
    areas,
    subProperties,
    change,
    lead,
    leadType,
    leadProperty,
    projects,
    projectTypes,
    selectedValues: { purpose, type, phone0, email },
    initialValues: { min_price, price, size, max_size, size_unit, bed, bath, maxBed, maxBath },
    missingRequiredFields,
    invalid,
    pristine,
  } = props

  useEffect(() => {
    if (lead.city_id || lead.cityId) fetchAreas(lead.city_id || lead.cityId)
  }, [])

  useEffect(() => {
    if (lead.type) fetchSubProperties(lead.type)
  }, [lead])

  useEffect(() => {
    props.setPurpose(purpose.value)
  }, [purpose])

  useEffect(() => {
    props.setIsValidPhone(!phone0 || !phone0.phone || isInvalidPhone(phone0.phone) ? false : true)
  }, [phone0])

  let minVal =
    min_price !== null
      ? parseFloat(min_price) >= priceRange['rent'][priceRange['rent'].length - 1]
        ? priceRange['rent'][priceRange['rent'].length - 1]
        : parseFloat(min_price) < 0
        ? 0
        : min_price
      : 0
  let minCloset = closest(minVal, 'sm', priceRange)

  let maxVal =
    price !== null
      ? parseFloat(price) > priceRange['rent'][priceRange['rent'].length - 1]
        ? priceRange['rent'][priceRange['rent'].length - 1]
        : !isNaN(price) && price !== '' && parseFloat(price) < 1
        ? priceRange['rent'][priceRange['rent'].length - 1]
        : price
      : priceRange['rent'][priceRange['rent'].length - 1]

  let maxCloset = closest(maxVal, 'lg', priceRange)

  let minSizeVal =
    size !== null && size_unit
      ? parseFloat(size) >=
        sizeRange.unitRange[size_unit][sizeRange.unitRange[size_unit].length - 1]
        ? sizeRange.unitRange[size_unit][sizeRange.unitRange[size_unit].length - 1]
        : parseFloat(size) < 0
        ? 0
        : size
      : 0
  let minSizeClosest = closestSize(minSizeVal, 'sm', size_unit, sizeRange)

  let maxSizeVal =
    max_size !== null && size_unit
      ? parseFloat(max_size) >
        sizeRange.unitRange[size_unit][sizeRange.unitRange[size_unit].length - 1]
        ? sizeRange.unitRange[size_unit][sizeRange.unitRange[size_unit].length - 1]
        : !isNaN(max_size) && max_size !== '' && parseFloat(max_size) < 1
        ? sizeRange.unitRange[size_unit][sizeRange.unitRange[size_unit].length - 1]
        : max_size
      : sizeRange.unitRange[size_unit][sizeRange.unitRange[size_unit].length - 1]
  let maxSizeClosest = closestSize(maxSizeVal, 'lg', size_unit, sizeRange)

  const isRequired = (field) => missingRequiredFields.indexOf(field) > -1

  const validateEmail = (value) => {
    let format = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    return format.test(value)
  }
  const readOnly = leadType !== 'Wanted'

  if (submitBtnRef.current) {
    submitBtnRef.current.style.setProperty('width', '100px')
    submitBtnRef.current.style.setProperty('color', '#007bff', 'important')
    submitBtnRef.current.style.setProperty('border', '1px solid #007bff', 'important')
    submitBtnRef.current.style.setProperty('background-image', 'none')
    submitBtnRef.current.style.setProperty('opacity', '1')
  }

  return (
    <>
      <Form onSubmit={handleSubmit} className={classes.root}>
        <div className={`${classes.clientSection} pr-4 border-right`}>
          <div>
            <h4>Client Details</h4>
          </div>
          <div className={`${classes.form} ${classes.clientForm} pr-2 mt-3`}>
            <Field
              validate={[required]}
              name={'first_name'}
              className={`${classes.field} form-control`}
              placeholder="First Name"
              component={'input'}
            />
            {isRequired('first_name') && (
              <small className={'text-danger'}>First Name is required</small>
            )}
            <br />
            <Field
              validate={[required]}
              name={'last_name'}
              className={`${classes.field} form-control`}
              placeholder={'Last Name'}
              component={'input'}
            />
            {isRequired('last_name') && (
              <small className={'text-danger'}>Last Name is required</small>
            )}
            <br />
            <Field
              component={renderPhoneField}
              name={'phone0'}
              className={`${classes.field} form-control`}
              placeholder={'Primary #'}
              validate={isRequired('phone') ? required : undefined}
            />
            {isRequired('phone') ? (
              <small className={'text-danger'}>Phone is required</small>
            ) : phone0 && isInvalidPhone(phone0.phone) ? (
              <small className={'text-danger'}>Enter Correct Number</small>
            ) : (
              <></>
            )}
            <br />
            <Field
              name={'phone1'}
              className={`${classes.field} form-control`}
              placeholder={'Secondary #'}
              component={renderPhoneField}
            />
            <br />
            <Field
              name={'phone2'}
              className={`${classes.field} form-control`}
              placeholder={'Secondary #'}
              component={renderPhoneField}
            />
            <br />
            <Field
              name={'email'}
              className={`${classes.field} form-control`}
              placeholder={'Email'}
              component={'input'}
            />
            {email && !validateEmail(email) ? (
              <small className={'text-danger'}>Email is not valid</small>
            ) : (
              ''
            )}
            <br />
            <Field
              name={'cnic'}
              normalize={normalizeCnic}
              placeholder={'CNIC/NTN'}
              className={`${classes.field} form-control`}
              component={'input'}
            />
            <br />
            <Field
              rows={1}
              name={'address'}
              className={`${classes.field} form-control`}
              placeholder={'Address 1'}
              component={'textarea'}
            />
            <br />
            <Field
              rows={1}
              name={'secondary_address'}
              className={`${classes.field} form-control`}
              placeholder={'Secondary Address'}
              component={'textarea'}
            />
          </div>
        </div>
        {readOnly ? (
          <RequirementReadView
            styles={{ requirement: { maxHeight: 427, marginBottom: 63 } }}
            lead={lead}
            leadType={leadType}
          />
        ) : (
          <div className={`${classes.requirementSection} pl-4 pr-2`}>
            <div>
              <h4>Requirements</h4>
            </div>
            <div className={`${classes.form} ${classes.requirementForm} pr-2 mt-3`}>
              {['BuyRent', 'Wanted'].indexOf(leadType) > -1 && (
                <>
                  <Field
                    component={renderOptions}
                    className={''}
                    type="text"
                    name="purpose"
                    validate={required}
                    label="Purpose"
                    options={leadType === 'BuyRent' ? leadTypes : allLeadTypes}
                  />
                  {isRequired('purpose') && (
                    <small className={'text-danger'}>Purpose is required</small>
                  )}
                  <br />
                </>
              )}
              {leadType !== 'Wanted' ||
                (purpose.value !== 'sell' && purpose.value !== 'rent out' && (
                  <>
                    <Field
                      component={investmentLeadCityDropdown}
                      className={''}
                      validate={required}
                      type="text"
                      name="city"
                      options={cities.map((c) => {
                        return { label: c.name, value: c.id }
                      })}
                      label="City"
                      fetchAreas={fetchAreas}
                      onChange={(value) => change('areas', null)}
                    />
                    {isRequired('city') && (
                      <small className={'text-danger'}>City is required</small>
                    )}
                    <br />{' '}
                  </>
                ))}
              {(leadType === 'BuyRent' ||
                (leadType === 'Wanted' && ['sale', 'buy', 'rent'].indexOf(purpose.value) > -1)) && (
                <>
                  <Field
                    component={investmentLeadAreaDrodpown}
                    className={''}
                    type="text"
                    validate={required}
                    name="areas"
                    label="Areas"
                    options={
                      areas &&
                      areas.items &&
                      areas.items.map((item) => ({
                        label: item.name,
                        value: item.id,
                      }))
                    }
                    defaultValue={null}
                  />
                  {isRequired('area') && <small className={'text-danger'}>Area is required</small>}
                  <br />
                  <Field
                    component={renderOptions}
                    className={''}
                    type="text"
                    name="type"
                    label="Property Type"
                    options={
                      properties.type &&
                      properties.type.map((item) => ({
                        label: item.name,
                        value: item.value,
                      }))
                    }
                    onChange={(value) => {
                      change('subtype', null)
                      fetchSubProperties(value.value)
                    }}
                  />
                  {isRequired('type') && (
                    <small className={'text-danger'}>Property Type is required</small>
                  )}
                  <br />
                  <Field
                    component={renderOptions}
                    className={''}
                    type="text"
                    name="subtype"
                    label="Property SubType"
                    defaultValue={null}
                    options={
                      subProperties &&
                      subProperties.map((item) => ({
                        label: item.name,
                        value: item.value,
                      }))
                    }
                  />
                  {isRequired('subtype') && (
                    <small className={'text-danger'}>Property Sub Type is required</small>
                  )}
                  <br />
                  <Field
                    component={sizeRanger}
                    className={''}
                    options={sizeRange}
                    type="number"
                    name="sizeRanger"
                    label="Final Size"
                    dataValue={{
                      max: maxSizeClosest < size ? size : maxSizeClosest,
                      size_max: maxSizeVal,
                      min: minSizeClosest > max_size ? max_size : minSizeClosest,
                      size_min: minSizeVal,
                      unit: sizeRange.sizeUnit.filter((u) => u.value === lead.size_unit).length
                        ? sizeRange.sizeUnit.filter((u) => u.value === lead.size_unit)[0]
                        : sizeRange.sizeUnit[3],
                    }}
                  />
                  <br />
                  <Field
                    component={paymentRanger}
                    className={''}
                    purpose={'rent'}
                    options={priceRange}
                    type="number"
                    name="priceRanger"
                    dataValue={{
                      max: maxCloset < min_price ? min_price : maxCloset,
                      price_max: maxVal,
                      min: minCloset > price ? price : minCloset,
                      price_min: minVal,
                    }}
                  />
                  <br />
                  {type === 'residential' && (
                    <>
                      <Field
                        component={bedBathRanger}
                        dataValue={{
                          max: maxBed || bedBathRange[bedBathRange.length - 1],
                          maxVal: maxBed || bedBathRange[bedBathRange.length - 1],
                          min: bed || 0,
                          minVal: bed || 0,
                        }}
                        className={''}
                        service_type={'Beds'}
                        options={bedBathRange}
                        type="number"
                        name="bedRanger"
                      />
                      <br />
                      <Field
                        component={bedBathRanger}
                        dataValue={{
                          max: maxBath || bedBathRange[bedBathRange.length - 1],
                          maxVal: maxBath || bedBathRange[bedBathRange.length - 1],
                          min: bath || 0,
                          minVal: bath || 0,
                        }}
                        className={''}
                        service_type={'Bath'}
                        purpose={'rent'}
                        options={bedBathRange}
                        type="number"
                        name="bathRanger"
                      />
                      <br />
                    </>
                  )}
                </>
              )}
              {leadType === 'Project' ||
                (leadType === 'Wanted' && purpose.value === 'invest' && (
                  <>
                    <Field
                      name="project"
                      label="Project"
                      placeholder={'Project'}
                      component={renderOptions}
                      type="text"
                      options={
                        projects && projects.items
                          ? projects.items.map((p) => {
                              return { value: p.id, label: p.name, productTypes: p.productTypes }
                            })
                          : []
                      }
                    />
                    <br />
                    <Field
                      name="projectType"
                      label="Product Type"
                      component={renderOptions}
                      type="text"
                      options={projectTypes}
                    />
                    <br />
                    <Field
                      component={paymentRanger}
                      className={''}
                      purpose={'rent'}
                      options={priceRange}
                      type="number"
                      name="priceRanger"
                      dataValue={{
                        max: maxCloset < min_price ? min_price : maxCloset,
                        price_max: maxVal,
                        min: minCloset > price ? price : minCloset,
                        price_min: minVal,
                      }}
                    />
                    {/*<div className={`${classes.data}`}>{lead.minPrice ? formatPriceRange(lead.minPrice, leadType === 'BuyRent' ? lead.price : lead.maxPrice, 'project') : '--'}</div>*/}
                    <hr />
                  </>
                ))}
              {leadType === 'Wanted' && ['sell', 'rent out'].indexOf(purpose.value) > -1 && (
                <>
                  {leadProperty ? (
                    <>
                      <div>
                        <strong>Property: </strong>{' '}
                        <strong
                          style={{ textDecoration: 'underline', cursor: 'pointer' }}
                          className={'text-primary'}
                          onClick={() => setPropertyModal(true)}
                        >
                          {leadProperty.id}
                        </strong>
                      </div>
                      {/*<div>
                        <strong>Property Title: </strong>{' '}
                        <strong
                        >
                          {leadProperty.title}
                        </strong>
                      </div>*/}
                    </>
                  ) : (
                    <>
                      <Button
                        text={'Add Property'}
                        textColor={'white'}
                        buttonColor="#026ff2"
                        className={'px-5 rounded-pill'}
                        onClick={() => setPropertyModal(true)}
                      />
                      <small className="text-danger mt-2">Property is required</small>
                    </>
                  )}
                  <br />
                </>
              )}
              {['sell', 'rent out'].indexOf(purpose.value) === -1 && (
                <Field
                  name={'description'}
                  className={`${classes.field} form-control`}
                  placeholder="Description"
                  component={'textarea'}
                  rows={4}
                />
              )}
            </div>
          </div>
        )}
        <div className={`d-flex justify-content-end ${classes.actionSection}`}>
          <button
            ref={submitBtnRef}
            type={'submit'}
            disabled={
              !phone0 ||
              !phone0.phone ||
              isInvalidPhone(phone0.phone) ||
              props.shouldAssign ||
              (leadType === 'Wanted' &&
                ['sell', 'rent out'].indexOf(purpose.value) > -1 &&
                !leadProperty)
            }
            className={'btn rounded-pill'}
          >
            {submitting && !props.shouldAssign ? (
              <span className="fa fa-circle-o-notch fa-spin"></span>
            ) : (
              'Update'
            )}
          </button>
        </div>
      </Form>

      <AddPropertyModal
        wantedId={lead.id}
        propertyId={leadProperty && leadProperty.id}
        callBack={(action, data) => {
          if (action === 'added') {
            props.setLeadProperty(data)
          }

          if (['added', 'updated'].indexOf(action) > -1)
            submitBtnRef.current && submitBtnRef.current.click()
        }}
        purpose={purpose}
        defaultCustomer={lead.customer}
        propertyModal={propertyModal}
        setPropertyModal={(toggle) => setPropertyModal(toggle)}
      />
    </>
  )
}

export const mapDispatchToProps = (dispatch) => {
  return {
    fetchAreas: (cityId) => dispatch(fetchAreas(cityId)),
    fetchSubProperties: (property) => dispatch(fetchSubProperties(property)),
  }
}

const fetchDropdownVal = (options, val) => {
  val = options.filter((type) => type.value === val || type.id === val)
  if (val.length) return { label: val[0].label || val[0].name, value: val[0].value || val[0].id }
  else
    return options[0]
      ? { label: options[0].label || options[0].name, value: options[0].value || options[0].id }
      : {}
}

const mapStateToProps = (state, ownProps) => {
  const { lead, leadType, requiredFieldCheck } = ownProps
  const initialPurpose = fetchDropdownVal(
    leadType === 'BuyRentLead' ? leadTypes : allLeadTypes,
    lead.purpose || allLeadTypes[0]
  )

  const area_key = leadType === 'Wanted' ? 'wanted_areas' : 'armsLeadAreas'

  let selectedProject = null
  if (lead.projectId && state.Projects.allProjects && state.Projects.allProjects.items) {
    selectedProject = state.Projects.allProjects.items.filter((p) => p.id === lead.projectId)
    if (selectedProject.length)
      selectedProject = {
        value: selectedProject[0].id,
        label: selectedProject[0].name,
        productTypes: selectedProject[0].productTypes,
      }
    else selectedProject = null
  }

  let selectedProjectType = null
  if (selectedProject && selectedProject.productTypes) {
    selectedProjectType = selectedProject.productTypes.filter((t) => t.name === lead.projectType)
    if (selectedProjectType.length)
      selectedProjectType = { value: selectedProjectType[0].id, label: selectedProjectType[0].name }
  }

  let leadSpecificFields =
    ['BuyRent', 'Wanted'].indexOf(leadType) > -1
      ? {
          purpose: initialPurpose,
          type: fetchDropdownVal(properties.type, lead.type),
          subtype: lead.type ? fetchDropdownVal(properties.subType[lead.type], lead.subtype) : [],
          areas: lead[area_key]
            ? lead[area_key]
                .filter((a) => a.area)
                .map((area) => {
                  return { label: area.area.name, value: area.area.id }
                })
            : [],
          size: lead.size,
          max_size: lead.max_size,
          price: lead.max_price,
          min_price: lead.min_price,
          size_unit: lead.size_unit || 'marla',
          bed: lead.bed,
          bath: lead.bath,
          maxBed: lead.bed_max,
          maxBath: lead.bath_max,
        }
      : {}
  leadSpecificFields =
    ['Project', 'Wanted'].indexOf(leadType) > -1
      ? {
          ...leadSpecificFields,
          project: selectedProject,
          projectType: selectedProjectType,
        }
      : {}

  const selector = formValueSelector('client_requirement')

  let missingRequiredFields = []
  // if (requiredFieldCheck) {
  const requiredFields = connectFormReqFields(selector(state, 'purpose'))
  requiredFields.customer.map((field) => {
    if (field === 'phone') {
      if (!selector(state, 'phone0') || !selector(state, 'phone0').phone)
        missingRequiredFields.push(field)
    } else {
      if (!selector(state, field)) missingRequiredFields.push(field)
    }
  })
  requiredFields.lead.map((field) => {
    if (!selector(state, field)) missingRequiredFields.push(field)
  })
  // }

  let cC = lead.customer.customerContacts
  return {
    cities: state.user.cities,
    areas: state.Areas.allAreas,
    subProperties: state.Property.subProperties,
    projects: state.Projects.allProjects,
    projectTypes: selector(state, 'project')
      ? selector(state, 'project').productTypes.map((t) => {
          return { value: t.id, label: t.name }
        })
      : selectedProject && selectedProject.productTypes
      ? selectedProject.productTypes.map((t) => {
          return { value: t.id, label: t.name }
        })
      : [],
    initialValues: {
      first_name: lead.customer.first_name,
      last_name: lead.customer.last_name,
      email: lead.customer.email,
      phone0: cC && cC[0] ? cC[0] : {},
      phone1: cC && cC[1] ? cC[1] : {},
      phone2: cC && cC[2] ? cC[2] : {},
      address: lead.customer.address,
      secondary_address: lead.customer.secondary_address,
      cnic: lead.customer.cnic ? normalizeCnic(lead.customer.cnic) : '',
      city: fetchDropdownVal(state.user.cities, lead.city_id || lead.cityId),
      description: lead.description,
      ...leadSpecificFields,
    },
    selectedValues: {
      purpose: selector(state, 'purpose') || initialPurpose,
      type: selector(state, 'type') ? selector(state, 'type').value : lead.type,
      email: selector(state, 'email'),
      phone0: selector(state, 'phone0'),
    },
    missingRequiredFields,
  }
}
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    reduxForm({
      form: 'client_requirement',
    })(ConnectForm)
  )
)
