import config from '../config'
import io from "socket.io-client";

const socketConnection = (params) => {
  const socket = io(config.callSocketPath, {
    transports: ['websocket'],
    reconnectionAttempts: 5,
    reconnectionDelay: 10000,
    timeout: 7200000,
    forceNew:true,
    auth: {
      token: params.token
    }
  });
  return socket;
}
export default socketConnection;

export const agentUuId = () => {
  let uuid = ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16));
  uuid = uuid.replace("-",'_');
  uuid = uuid.replace("-",'_');
  uuid = uuid.replace("-",'_');
  uuid = uuid.replace("-",'_');
  const u_c = localStorage.getItem('agent_unique');
  if (u_c !==undefined && u_c !==null && u_c !==''){
    uuid = localStorage.getItem('agent_unique');
  } else {
    localStorage.setItem('agent_unique',uuid);
  }
  return uuid;
}
