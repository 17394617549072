/** @format */

import React, { useState, useEffect } from 'react'
import TableCell from './../Table/TableCell'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import _ from 'lodash'

const useStyles = makeStyles({
  header: (props) => ({
    display: 'table-header-group',
    height: props.headerHeight,
    backgroundColor: props.headerColor,
    fontSize: '12px',
    verticalAlign: 'middle',
    zIndex: 3,
  }),
  fixedHeader: {
    position: 'sticky',
    top: 0,
  },
})

const TableHeader = (props) => {
  const classes = useStyles(props)

  const shouldStickLeft = (index) => {
    if (!props.fixedColumns) return undefined
    return props.fixedColumns.left >= index + 1
  }
  const shouldStickRight = (index, length) => {
    if (!props.fixedColumns) return undefined
    return length - props.fixedColumns.right <= index
  }

  return (
    <thead className={clsx(classes.header, props.fixedHeader ? classes.fixedHeader : '')}>
      <tr className={props.headerClassName}>
        {props.isMultiSelect && (
          <th className="checkboxHeader tableMultiSelectCBContainer">
            <input
              className={'checkboxInputHeader tableMultiSelectCB'}
              type="checkbox"
              onChange={(e) => {
                props.onSelectAll && props.onSelectAll(e.target.checked)
              }}
            ></input>
          </th>
        )}
        {props.headerTemplate.map((template, index) => {
          return (
            <>
              <TableCell
                key={'headercell' + index}
                template={template}
                data={props.headers}
                isHeader={true}
                rowIndex={-1}
                cellIndex={index}
                onCellClick={props.onCellClick}
                cellsClassName={props.headerCellsClassName}
                cellWidth={props.cellWidth[index] ? props.cellWidth[index] : 'auto'}
                cellMinWidth={
                  props.cellMinWidth && props.cellMinWidth[index] !== undefined
                    ? props.cellMinWidth[index]
                    : 'auto'
                }
                stickLeft={shouldStickLeft(index)}
                stickRight={shouldStickRight(index, props.headerTemplate.length)}
                color={props.headerColor}
              />
            </>
          )
        })}
      </tr>
    </thead>
  )
}
export default React.memo(TableHeader, (prevProps, nextProps) => {
  return _.isEqual(prevProps, nextProps)
})

TableHeader.propTypes = {
  cellWidth: PropTypes.array,
  headerCellsClassName: PropTypes.string,
  headerTemplate: PropTypes.any,
  headerColor: PropTypes.string,
  fixedHeader: PropTypes.bool,
  fixedColumns: PropTypes.object,
  onCellClick: PropTypes.func,
  isMultiSelect: PropTypes.bool,
}

TableHeader.defaultProps = {
  cellWidth: [],
  headerCellsClassName: '',
  headerTemplate: [],
  headerColor: 'white',
  fixedHeader: false,
  fixedColumns: { left: 0, right: 0 },
  onCellClick: () => {},
  isMultiSelect: false,
}
