export const ERROR_SHOW = "ERROR_SHOW";
export const SUCCESS_MESSAGE = "SUCCESS_MESSAGE";

export const setErrorMessage = (value) => {
  return (dispatch) => {
    dispatch({ type: ERROR_SHOW });
    return dispatch({ type: ERROR_SHOW, data: value });
  };
};

export const setSuccessMessage = (value) => {
  return (dispatch) => {
    dispatch({ type: SUCCESS_MESSAGE });
    return dispatch({ type: SUCCESS_MESSAGE, data: value });
  };
};
