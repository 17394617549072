/** @format */

import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import config from '../../config'
import '../../components/common/Diary/diary.scss'
import Pagination from 'rc-pagination'
import moment from 'moment'
import { DateRangePicker } from 'react-dates'
import { CSVLink } from 'react-csv'
import { connect } from 'react-redux'
import { setTitle } from '../../actions/generalSetting'
import CallCenterDatePicker from '../../callCenter/CallCenterDatePicker'

const InboundAndOutboundCampaignLog = (props) => {
  const { setTitle } = props
  const [loader, setLoader] = useState(false)
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [reportData, setReportData] = useState([])
  const [totalRows, setTotalRows] = useState(1)
  const [pagePermission, setPagePermission] = useState(true)
  const [filters, setFilters] = useState({
    fromDate: moment().startOf('month'),
    toDate: moment().endOf('month'),
    queueId: '',
  })
  const [focusedInput, setFocusedInput] = useState(null)
  const [csvData, setCsvData] = useState([])
  const csvLink = useRef()

  const getData = async (page, perPage) => {
    const param = {
      // page,
      // pageSize:perPage,
      startingDate: moment(filters.fromDate).format('YYYY-MM-DD'),
      endingDate: moment(filters.toDate).format('YYYY-MM-DD'),
      // queueId:filters.queueId,
    }
    setLoader(true)
    console.log(param)
    await axios
      .get(`${config.apiPath}/api/cdrreport/getUserCallLogs`, { params: param })
      .then((res) => {
        const { data } = res.data
        console.log(JSON.stringify(res.data.data), 'DATA')
        setReportData(data)
        setTotalRows((data && data.length > 0 && res.data.data[0].total_rows) || 0)
      })
    setLoader(false)
  }

  const getCsvData = async () => {
    const param = {
      page,
      pageSize: perPage,
      startingDate: moment(filters.fromDate).format('YYYY-MM-DD'),
      endingDate: moment(filters.toDate).format('YYYY-MM-DD'),
      queueId: filters.queueId,
      isCSV: 1,
    }
    await axios
      .get(`${config.apiPath}/api/cdrreport/getUserCallLogs`, { params: param })
      .then((data) => {
        const allRebates =
          data.data.data.length > 0
            ? data.data.data.map((data) => ({
                'Campaign Name': data.user_name,
                'Inbound Answer Calls': data.inbound_total_attended
                  ? data.inbound_total_attended
                  : 0,
                'Inbound Unanswered Calls': data.inbound_not_attended
                  ? data.inbound_not_attended
                  : 0,
                'Inbound Unique Phone Call': data.inbound_unique_phone_no
                  ? data.inbound_unique_phone_no
                  : 0,
                'Inbound Total Calls': data.inbound_total_calls ? data.inbound_total_calls : 0,
                'Outbound Answer Calls': data.outbound_total_attended
                  ? data.outbound_total_attended
                  : 0,
                'Outbound Unanswered Calls': data.outbound_not_attended
                  ? data.outbound_not_attended
                  : 0,
                'Outbound Unique Phone Call': data.outbound_unique_phone_no
                  ? data.outbound_unique_phone_no
                  : 0,
                'Outbound Total Calls': data.outbound_total_calls ? data.outbound_total_calls : 0,
              }))
            : []

        setCsvData(allRebates)
      })
      .catch((e) => console.log(e))
    csvLink.current.link.click()
  }
  useEffect(() => {
    setTitle({ name: 'Inbound & Outbound User Logs' })
    let isTeamLead =
      localStorage.getItem('user_agency_mgmt') !== '' &&
      localStorage.getItem('user_agency_mgmt') !== null
        ? JSON.parse(localStorage.getItem('user_agency_mgmt')).data.isTeamLead
        : false
    setPagePermission(isTeamLead)
    getData(page, perPage)
  }, [])
  useEffect(() => {
    getData(page, perPage)
  }, [page])

  return (
    <>
      {pagePermission ? (
        <div className="card mt-2">
          <div style={{ padding: '1%', height: '85vh' }}>
            <div className="ccmc-filters row">
              <div className="col-md-8 row">
                <div className="col-md-4 ccmcDateRange">
                  {/*<CallCenterDatePicker targetDateHandle={(dateStart, dateEnd) => {
                    if (dateStart) {
                      setFilters((filters) => ({
                        ...filters,
                        fromDate: dateStart,
                      }))
                    }
                    if (dateEnd) {
                      setFilters((filters) => ({
                        ...filters,
                        toDate: dateEnd,
                      }))
                    }
                  }}/>*/}
                  <DateRangePicker
                    isOutsideRange={() => false}
                    startDate={filters.fromDate}
                    endDate={filters.toDate}
                    startDateId="buyrent_date_input_start"
                    minimumNights={1}
                    endDateId="buyrent_date_input_end"
                    className="activeDatePicker"
                    onDatesChange={({ startDate: dateStart, endDate: dateEnd }) => {
                      if (dateStart) {
                        setFilters((filters) => ({
                          ...filters,
                          fromDate: dateStart,
                        }))
                      }
                      if (dateEnd) {
                        setFilters((filters) => ({
                          ...filters,
                          toDate: dateEnd,
                        }))
                      }
                    }}
                    focusedInput={focusedInput}
                    onFocusChange={(input) => setFocusedInput(input)}
                    showClearDates
                    noBorder={false}
                  />
                </div>

                <div className="col-md-4">{/* <label>queue id</label> */}</div>
              </div>
              <div className="col-md-1"></div>
              <div className="col-md-3 row justify-content-end ">
                {/*  <div className="col-md-6">
                  <button className="export-btn" onClick={exportData}>
                    Export
                  </button>
                  </div> */}
                <div className="col-md-6">
                  <div className="ccmc-clearFilter">
                    <button className={`newFormSearch`} onClick={() => getData(1, perPage)}>
                      <span className={`fa fa-search`}></span>
                    </button>
                    {/* <button className={`newFormClear`} onClick={clearFilter}>
                      Clear
                    </button> */}
                    {reportData.length > 0 && (
                      <div>
                        <button onClick={getCsvData} className="newFormSearch ml-1">
                          <span className={`fa fa-download`}></span>
                        </button>
                        <CSVLink
                          data={csvData}
                          filename="campaign_inbound_and_outbound_Call_logs.csv"
                          className="hidden"
                          ref={csvLink}
                          target="_blank"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="reports-table">
              <table style={{ height: '80%' }}>
                <thead>
                  <tr>
                    <th style={{ minWidth: '210px' }}>Agent Name</th>
                    <th style={{ minWidth: '210px' }}>Inbound Answered</th>
                    <th style={{ minWidth: '210px' }}>Inbound Answered Unique Clients </th>
                    <th style={{ minWidth: '210px' }}>Inbound Unanswered</th>
                    <th style={{ minWidth: '210px' }}>Inbound Unanswered Unique Clients</th>
                    <th style={{ minWidth: '210px' }}>Inbound Total Calls</th>
                    <th style={{ minWidth: '210px' }}>Outbound Answered</th>
                    <th style={{ minWidth: '210px' }}>Outbound Answered Unique Clients</th>
                    <th style={{ minWidth: '210px' }}>Outbound Unanswered</th>
                    <th style={{ minWidth: '210px' }}>Outbound Unanswered Unique Clients</th>
                    <th style={{ minWidth: '210px' }}>Outbound Total Calls</th>
                  </tr>
                </thead>
                <tbody>
                  {loader ? (
                    <tr>
                      <p
                        style={{ paddingLeft: '36vw', paddingTop: '10vh' }}
                        colSpan={10}
                        className={''}
                      >
                        Loading ...
                      </p>
                    </tr>
                  ) : (
                    <>
                      {reportData.length > 0 ? (
                        reportData.map((e) => {
                          return <TableTr data={e} />
                        })
                      ) : (
                        <tr>
                          <p
                            style={{ paddingLeft: '36vw', paddingTop: '10vh' }}
                            colSpan={10}
                            className={''}
                          >
                            No Data Found
                          </p>
                        </tr>
                      )}
                    </>
                  )}
                </tbody>
                {reportData.length > 0 && !loader && (
                  <tfoot className="row mt-2 mb-1">
                    <tr className="col-md-12 d-flex flex-column justify-content-center">
                      <td colSpan={10} align="center">
                        <div className="pagination justify-content-center">
                          <Pagination
                            className="ps-pagination"
                            hideOnSinglePage={true}
                            pageSize={perPage}
                            current={page}
                            showPrevNextJumpers={false}
                            onChange={(cPage, pSize) => {
                              setPage(cPage)
                            }}
                            total={totalRows}
                          />
                        </div>
                      </td>
                    </tr>
                  </tfoot>
                )}
              </table>
            </div>
          </div>
        </div>
      ) : null}
    </>
  )
}

const TableTr = ({ data }) => {
  return (
    <tr className="hover__for__btns hoverForTr">
      <td style={{ minWidth: '210px' }}>{data.user_name}</td>
      <td style={{ minWidth: '210px' }}>{data.inbound_total_attended}</td>
      <td style={{ minWidth: '210px' }}>{data.inbound_unique_phone_no}</td>
      <td style={{ minWidth: '210px' }}>{data.inbound_not_attended}</td>
      <td style={{ minWidth: '210px' }}>{data.inbound_unique_unanswered}</td>
      <td style={{ minWidth: '210px' }}>{data.inbound_total_calls}</td>
      <td style={{ minWidth: '210px' }}>{data.outbound_total_attended}</td>
      <td style={{ minWidth: '210px' }}>{data.outbound_unique_phone_no}</td>
      <td style={{ minWidth: '210px' }}>{data.outbound_not_attended}</td>
      <td style={{ minWidth: '210px' }}>{data.outbound_unique_unanswered}</td>
      <td style={{ minWidth: '210px' }}>{data.outbound_total_calls}</td>
    </tr>
  )
}

const mapDispatchToProps = (dispatch) => ({
  setTitle: (title) => dispatch(setTitle(title)),
})
const mapStateToProps = (state) => ({})
export default connect(mapStateToProps, mapDispatchToProps)(InboundAndOutboundCampaignLog)
