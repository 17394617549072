/** @format */

import { Input, Modal, ModalBody } from 'reactstrap'
import React, { useEffect, useState } from 'react'
import './ClosedLost.scss'
import Chip from '../../common/Chip'
import Button from '../../common/Button'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { getDiaryFeedbacks } from '../../../actions/diary'
import { capitalize_Words, capitalizeWordsWithoutUnderscore } from '../../../utils/commonFormat'
import { FEEDBACK_ACTIONS } from '../../../utils/constant_data'
import Swal from 'sweetalert2'
import UpdatePropertyTitle from './UpdatePropertyTitle'
import { makeStyles } from '@material-ui/styles'
import dialer_agent from '../../Dialer/dailer_agent'
import axios from 'axios'
import config from '../../../config'

const useStyles = makeStyles({
  feedbackWraper: (props) => ({
    height:
      props.taskType === 'meeting' && props.actionType === 'Cancel'
        ? 'unset'
        : 'calc(100vh - 326px)',
    overflowY: 'auto',
    minHeight: 200,
  }),
  mainContentWraper: (props) => ({
    height:
      props.taskType === 'meeting' && props.actionType === 'Cancel'
        ? 'unset'
        : 'calc(100vh - 200px)',
    overflowY: 'auto',
    minHeight: 200,
  }),
})

const ConnectFeedback = ({
  connectFeedbackModal,
  setConnectFeedbackModal,
  setNextFlow,
  setNextAction,
  diaryFeedbacks,
  fetchDiaryFeedbacks,
  actionType,
  leadType,
  taskType,
  taskStatus,
  viewingProperties,
  propertyId,
  taskId,
  feedbackLoading,
  lead,
  phone,
}) => {
  const classes = useStyles({ actionType, taskType })
  const [connectFeedback, setConnectFeedback] = useState({})
  const [selectedProperty, setSelectedProperty] = useState([propertyId])
  const [agent, setAgent] = useState({})

  const task_type = capitalizeWordsWithoutUnderscore(taskType, true)
  const FA = FEEDBACK_ACTIONS

  let additionalActions = null
  let actionAdded = false
  let updatedDiaryFeedbacks = diaryFeedbacks
  let rejectFeedback = null
  let noActionRequired = null
  let showOkButton = true

  // filter actions on base of task type
  diaryFeedbacks &&
    Object.keys(diaryFeedbacks).map((key, i) => {
      if (key === 'Actions' && diaryFeedbacks[key]) {
        additionalActions = diaryFeedbacks[key].filter((action) => {
          let tags = JSON.parse(action.tags[0])

          if (taskType === 'meeting' && taskStatus === 'completed')
            return FA.SETUP_ANOTHER_MEETING in tags || FA.BOOK_UNIT in tags
          else if (taskType === 'meeting' && taskStatus !== 'completed')
            return (
              FA.BOOK_UNIT in tags ||
              FA.RESCHEDULE_MEETING in tags ||
              FA.SETUP_ANOTHER_MEETING in tags
            )
          else if (taskType === 'viewing' && taskStatus === 'completed')
            return FA.SHORTLIST_PROPERTIES in tags
          else if (taskType === 'viewing' && taskStatus !== 'completed')
            return FA.RESCHEDULE_VIEWING in tags || FA.SETUP_MORE_VIEWING
          else return true
        })
        delete diaryFeedbacks[key]
      }
    })

  // sorting
  if (additionalActions) {
    updatedDiaryFeedbacks = diaryFeedbacks
    diaryFeedbacks &&
      Object.keys(diaryFeedbacks).map((key, i) => {
        if (
          (key.indexOf('No Action Required') > -1 || key.indexOf('Reject') > -1) &&
          !actionAdded
        ) {
          updatedDiaryFeedbacks['Actions'] = additionalActions
          actionAdded = true
        }
        if (key.indexOf('Reject') > -1) {
          rejectFeedback = diaryFeedbacks[key]
          delete diaryFeedbacks[key]
        } else if (key.indexOf('No Action Required') > -1) {
          noActionRequired = diaryFeedbacks[key]
          delete diaryFeedbacks[key]
        } else updatedDiaryFeedbacks[key] = diaryFeedbacks[key]
      })

    if (!diaryFeedbacks || !Object.keys(diaryFeedbacks).length) {
      updatedDiaryFeedbacks['Actions'] = additionalActions
      showOkButton = false
    }
    if (noActionRequired) updatedDiaryFeedbacks['No Action Required'] = noActionRequired
    if (rejectFeedback) updatedDiaryFeedbacks['Reject'] = rejectFeedback
  }

  useEffect(() => {
    if (actionType && leadType) {
      fetchDiaryFeedbacks({
        actionType: actionType,
        leadType,
        taskType: task_type === 'Follow up' && actionType !== 'Done' ? 'Connect' : task_type,
      })
    }
    dialer_agent().then((result) => {
      if (result.success) {
        setAgent(result)
      } else {
        setAgent({})
      }
    })
  }, [])

  useEffect(() => {
    if (actionType === 'Cancel' && diaryFeedbacks) {
      Object.keys(diaryFeedbacks).map((key, j) => {
        diaryFeedbacks[key].map((feedback, i) => {
          let tag = feedback.tags && feedback.tags.length ? feedback.tags[0] : null
          if (tag)
            setConnectFeedback({
              section: feedback.section,
              colorCode: feedback.colorCode,
              tag: tag,
              id: feedback.id,
            })
        })
      })
    }
  }, [diaryFeedbacks])

  return (
    <>
      {connectFeedbackModal && connectFeedbackModal && (
        <Modal
          isOpen={connectFeedbackModal}
          toggle={() => setConnectFeedbackModal(false)}
          size={taskType === 'viewing' ? 'xl' : 'lg'}
          backdrop={'static'}
        >
          <ModalBody className={'px-0'}>
            <div className="close-icon-container">
              <i
                className="mt-1 fal fa-times-circle close-icon"
                onClick={() => setConnectFeedbackModal(false)}
              ></i>
            </div>
            <h4 className={'px-4'}>
              {actionType === 'Cancel'
                ? `Cancel ${capitalizeWordsWithoutUnderscore(taskType)}`
                : ['Viewing', 'Meeting'].indexOf(task_type) > -1 && actionType === 'Connect'
                ? `Connect Before ${task_type}`
                : `${capitalizeWordsWithoutUnderscore(taskType)} Feedback`}
            </h4>
            <div
              className={`mt-3 px-4 ${
                viewingProperties && viewingProperties.length ? classes.mainContentWraper : ''
              }`}
            >
              <div
                className={`${
                  taskType === 'viewing' &&
                  ['Cancel', 'Done'].indexOf(actionType) > -1 &&
                  (!viewingProperties || !viewingProperties.length)
                    ? 'd-flex justify-content-center align-items-center'
                    : ''
                } row`}
              >
                {taskType === 'viewing' &&
                  ['Cancel', 'Done'].indexOf(actionType) > -1 &&
                  viewingProperties.map((property) => {
                    return (
                      <UpdatePropertyTitle
                        selectedProperty={selectedProperty}
                        setSelectedProperty={(properties) => {
                          setSelectedProperty(properties)
                        }}
                        onComment={(task) => {
                          setConnectFeedback((feedback) => {
                            return {
                              ...feedback,
                              otherTasksToUpdate:
                                task.id !== taskId
                                  ? feedback.otherTasksToUpdate
                                    ? [
                                        ...feedback.otherTasksToUpdate.filter(
                                          (t) => t.id !== task.id
                                        ),
                                        task,
                                      ]
                                    : [task]
                                  : feedback.otherTasksToUpdate || [],
                              comment: task.id === taskId ? task.comment : feedback.comment, // for main task on which feedback open
                            }
                          })
                        }}
                        propertyId={propertyId}
                        property={property}
                        showChange={false}
                        showCheckbox={true}
                        showComment={actionType === 'Done' && taskType === 'viewing'}
                      />
                    )
                  })}
                {actionType !== 'Done' || taskType !== 'viewing' ? (
                  <div className={'col-12'}>
                    <Input
                      rows={4}
                      type={'textarea'}
                      onChange={(e) =>
                        setConnectFeedback({
                          ...connectFeedback,
                          comment: e.target.value,
                        })
                      }
                      value={connectFeedback.comment}
                      name={'response'}
                      placeholder={'Comment'}
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <br />
              {((actionType && leadType && feedbackLoading) ||
                (taskType === 'viewing' &&
                  ['Cancel', 'Done'].indexOf(actionType) > -1 &&
                  (!viewingProperties || !viewingProperties.length))) && (
                <div className={'d-flex justify-content-center align-items-center'}>
                  <span className="fa fa-circle-o-notch fa-spin text-center text-primary"></span>
                </div>
              )}
              <div
                className={`${
                  (!viewingProperties || !viewingProperties.length) && leadType
                    ? classes.feedbackWraper
                    : ''
                }`}
              >
                {updatedDiaryFeedbacks &&
                  Object.keys(updatedDiaryFeedbacks).map((key, j) => {
                    return updatedDiaryFeedbacks[key].map((feedback, i) => {
                      return feedback.tags ? (
                        <div key={i}>
                          <div className={'mb-3 float-left font-weight-bold'}>
                            {feedback.section}
                          </div>
                          <div
                            style={
                              leadType
                                ? {
                                    display: 'flex',
                                    clear: 'both',
                                    flexWrap: 'wrap',
                                    gridGap: 15,
                                  }
                                : {display: 'flex',
                                  clear: 'both',
                                  flexWrap: 'wrap',
                                  gridGap: 15,}
                            }
                          >
                            {feedback.section === 'Actions'
                              ? feedback.tags &&
                                Object.keys(JSON.parse(feedback.tags[0]))
                                  .filter((tag) => {
                                    // remove book a unit action when lead is converted into deal (unit booked)
                                    return !(
                                      leadType === 'Project' &&
                                      lead &&
                                      lead.hasBooking &&
                                      FA.BOOK_UNIT === tag
                                    )
                                  })
                                  .map((tag, k) => (
                                    <Chip
                                      key={k}
                                      className="space-between"
                                      padding={'6px 15px'}
                                      cursor={'pointer'}
                                      fontSize={12}
                                      value={tag}
                                      onClick={() => {
                                        setNextAction(
                                          tag,
                                          {
                                            section: feedback.section,
                                            colorCode: feedback.colorCode,
                                            tag: JSON.parse(feedback.tags[0])[tag],
                                            id: feedback.id,
                                            comment:
                                              connectFeedback.comment ||
                                              JSON.parse(feedback.tags[0])[tag],
                                            otherTasksToUpdate: connectFeedback.otherTasksToUpdate
                                              ? connectFeedback.otherTasksToUpdate
                                                  .filter(
                                                    (task) =>
                                                      selectedProperty.indexOf(task.propertyId) > -1
                                                  )
                                                  .map((task) => {
                                                    // check for separate comment for each property tile or single comment for all tile or use tag as comment
                                                    const comment =
                                                      (actionType === 'Done' &&
                                                      taskType === 'viewing'
                                                        ? task.comment
                                                        : connectFeedback.comment) ||
                                                      JSON.parse(feedback.tags[0])[tag]
                                                    return { id: task.id, comment: comment }
                                                  })
                                              : [],
                                          },
                                          selectedProperty
                                        )
                                      }}
                                      chipColor={
                                        feedback.section === 'Actions'
                                          ? '#026ff2'
                                          : connectFeedback &&
                                            JSON.parse(feedback.tags[0])[tag] ===
                                              connectFeedback.tag
                                          ? connectFeedback.colorCode
                                          : 'white'
                                      }
                                      textColor={
                                        feedback.section === 'Actions' ||
                                        (connectFeedback &&
                                          JSON.parse(feedback.tags[0])[tag] === connectFeedback.tag)
                                          ? 'white'
                                          : 'black'
                                      }
                                      text={tag}
                                      border={`2px solid ${feedback.colorCode}`}
                                      borderRadius={20}
                                    />
                                  ))
                              : feedback.tags &&
                                feedback.tags.map((tag, k) => {
                                  return (
                                    <Chip
                                      key={k}
                                      className="space-between"
                                      padding={'6px 15px'}
                                      cursor={'pointer'}
                                      fontSize={12}
                                      value={tag}
                                      onClick={() => {
                                        setConnectFeedback({
                                          ...connectFeedback,
                                          section: feedback.section,
                                          colorCode: feedback.colorCode,
                                          tag: tag,
                                          id: feedback.id,
                                        })
                                      }}
                                      chipColor={
                                        feedback.section === 'Actions'
                                          ? '#026ff2'
                                          : connectFeedback &&
                                            tag === connectFeedback.tag &&
                                            feedback.section === connectFeedback.section
                                          ? connectFeedback.colorCode
                                          : 'white'
                                      }
                                      textColor={
                                        feedback.section === 'Actions' ||
                                        (connectFeedback &&
                                          tag === connectFeedback.tag &&
                                          feedback.section === connectFeedback.section)
                                          ? 'white'
                                          : 'black'
                                      }
                                      text={tag}
                                      border={`2px solid ${feedback.colorCode}`}
                                      borderRadius={20}
                                    />
                                  )
                                })}
                          </div>
                          {j < Object.keys(updatedDiaryFeedbacks).length - 1 && <hr />}
                        </div>
                      ) : (
                        <></>
                      )
                    })
                  })}
              </div>
            </div>
            {showOkButton && (
              <div className={'d-flex justify-content-center mt-2 pt-4 border-top'}>
                <Button
                  text={'OK'}
                  textColor={'white'}
                  buttonColor="#026ff2"
                  className={'px-5 rounded-pill'}
                  onClick={async (event) => {
                    if (connectFeedback.id) {
                      setNextFlow({
                        ...connectFeedback,
                        comment: connectFeedback.comment || connectFeedback.tag,
                        otherTasksToUpdate: connectFeedback.otherTasksToUpdate
                          ? connectFeedback.otherTasksToUpdate
                              .filter((task) => selectedProperty.indexOf(task.propertyId) > -1)
                              .map((task) => {
                                // check for separate comment for each property tile or single comment for all tile or use tag as comment
                                const comment =
                                  (actionType === 'Done' && taskType == 'viewing'
                                    ? task.comment
                                    : connectFeedback.comment) || connectFeedback.tag
                                return { id: task.id, comment: comment }
                              })
                          : [],
                      })

                    } else if (!leadType) {
                      setNextFlow({
                        ...connectFeedback,
                        comment: connectFeedback.comment,
                        otherTasksToUpdate: [],
                      })
                    } else
                      Swal.fire({
                        icon: 'warning',
                        text: 'Feedback is missing',
                      })
                  }}
                />
              </div>
            )}
          </ModalBody>
        </Modal>
      )}
    </>
  )
}

export const mapDispatchToProps = (dispatch) => {
  return {
    fetchDiaryFeedbacks: (params) => dispatch(getDiaryFeedbacks(params)),
  }
}

export const mapStateToProps = (state) => {
  return {
    diaryFeedbacks: state.Diary.diaryFeedbacks,
    feedbackLoading: state.Diary.feedbackLoading,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ConnectFeedback))
