/** @format */

import React, { useState, useEffect, useRef } from 'react'
import Table from '../Table/Table'
import {
  headerTemplate,
  headerTemplateOfSmallDiary,
  bodyTemplate,
  overDueheaderTemplate,
  ColumnName,
  bodyTemplateShowDiary,
} from '../../TableConfig/DiaryConfig'
import { getPropValue } from './DiaryUtilHelper'
import './diary.scss'
import 'rc-pagination/assets/index.css'
import local from '../../../local/en_US'
import Pagination from 'rc-pagination'
import { FEEDBACK_ACTIONS, TasksType } from '../../../utils/constant_data'

const Diary = (props) => {
  const selectedDiaryRef = useRef(null)
  const handleActions = (payload) => {
    if (['connect', 'task-details', 'assign-to-area-manager'].indexOf(payload) > -1) {
      props.setActionType('Connect')
      props.setAgain(false)
      setTimeout(() => props.setConnectModal(true), 500)
      props.setAction(payload)
    } else if (payload === 'additional-details') {
      props.setAdditionalDetailsModal(true)
      props.setAction(payload)
    } else if (payload === 'refer-lead') {
      props.setReferModal('share')
      props.callAgentsfun(null, 1, 'share')
      props.setAction(payload)
    } else if (payload === 'reassign-lead') {
      props.setReferModal('assign')
      props.callAgentsfun(null, null, 'assign')
      // props.localStateUpdate(item.id);
      // props.setSelectedLead(item)
    } else if (payload === 'details') {
      props.setDiaryModal()
      props.setDiaryAction('detail')
    } else if (payload === 'edit-task') {
      props.setDiaryModal()
      props.setDiaryAction('quick-edit')
    } else if (payload === 'delete') {
      props.setDeleteDiary()
    } else if (['cancel-meeting', 'mark-as-done', 'cancel-viewing'].indexOf(payload) > -1) {
      props.setNextAction(payload)
    } else if (typeof payload === 'object') {
      if (payload.value) {
        if (payload.value === 'Hot' || payload.value === 'Cold' || payload.value === 'Warm') {
          props.setClassification(selectedDiaryRef.current, payload)
        }
      }
    } else if (payload === 'investment-guide') props.setGuideReferenceModal(true)
    else if (payload === 'reschedule-viewing') {
      props.rescheduleViewing()
    } else if (payload === 'reschedule-meeting') {
      props.rescheduleMeeting()
    }
  }
  const textItemRender = (current, type, element) => {
    if (type === 'prev') {
      return 'Prev'
    }
    if (type === 'next') {
      return 'Next'
    }
    return element
  }

  const getPagination = () => {
    return (
      <div className="pagination">
        <div className="totalCountAlignment">
          <span className="totalCountStyle">
            Showing
            <span className="totalCountSubStyle">{`${
              (props.pagination.currentPage - 1) * props.pagination.pageSize + 1
            }-${
              props.pagination.currentPage * props.pagination.pageSize < props.dataCount
                ? props.pagination.currentPage * props.pagination.pageSize
                : props.dataCount
            }/${props.dataCount}`}</span>
          </span>
        </div>
        <Pagination
          className="ps-pagination"
          hideOnSinglePage={true}
          pageSize={props.pagination.pageSize}
          current={parseInt(props.pagination.currentPage, 10)}
          showPrevNextJumpers={false}
          itemRender={textItemRender}
          onChange={(cPage, pSize) => {
            // setCurrentPage(cPage)
            props.onChangePage(cPage, pSize)
          }}
          total={props.dataCount}
          locale={local}
        />
      </div>
    )
  }
  const getDiaryHeaderTemplate = () => {
    if (props.isShowShortDiary) {
      return headerTemplateOfSmallDiary()
    } else {
      return headerTemplate()
    }
  }
  const getDiaryBodyTemplate = () => {
    if (props.isShowShortDiary) {
      return bodyTemplateShowDiary(getPropValue, handleActions, props)
    } else {
      return bodyTemplate(getPropValue, handleActions, props)
    }
  }
  const detailsCellIndex = props.isShowShortDiary ? 5 : 8
  return (
    <>
      <Table
        headerTemplate={getDiaryHeaderTemplate()}
        bodyTemplate={getDiaryBodyTemplate()}
        data={props.data}
        headerHeight={'40px'}
        headerColor={'#f5f5f5'}
        tableClassName={'borderCollapse'}
        headerClassName={'fontSize10'}
        rowsClassName={'fontSize10'}
        tableContinerClassName={'diaryContainer noselect'}
        rowHeight={() => '40px'}
        onRowClick={(data, event) => {
          if (selectedDiaryRef) selectedDiaryRef.current = data.rowData
          props.setDiary(data.rowData)
        }}
        onCellClick={(cellIndex, rowIndex, rowData, cellName, event) => {
          if (
            (rowData.taskType === TasksType.MORNINGMEETING ||
              rowData.taskType === TasksType.DAILYUPDATE ||
              rowData.taskType === TasksType.MEETINGWITHPP) &&
            cellIndex <= detailsCellIndex
          ) {
            handleActions('details')
          } else if (
            ColumnName.LEAD_ID != cellName &&
            ColumnName.CLASSIFICATION != cellName &&
            cellIndex <= detailsCellIndex
          ) {
            handleActions('details')
          } else if (ColumnName.LEAD_ID === cellName) {
            handleActions('task-details')
          }
        }}
        fixedHeader={true}
        cellMinWidth={
          props.isShowShortDiary && props.isShowShortDiary === true
            ? [
                '72px',
                '15px',
                '30px',
                'auto',
                '100px',
                '100px',
                '100px',
                '10%',
                '15%',
                '100px',
                '10px',
                '10px',
                '10px',
                '10px',
              ]
            : [
                '72px',
                'auto',
                'auto',
                'auto',
                'auto',
                'auto',
                'auto',
                'auto',
                'auto',
                '100px',
                'auto',
                'auto',
                'auto',
                '20px',
              ]
        }
        cellWidth={
          props.isShowShortDiary && props.isShowShortDiary === true
            ? ['72px', '10px', '30px', '100px', '100px', 'auto', '80px', '80px', '80px', '20px']
            : [
                '72px',
                '10px',
                '30px',
                '10%',
                '16%',
                '12%',
                '10%',
                '12%',
                '20%',
                '80px',
                '80px',
                '80px',
                '20px',
              ]
        }
        rowsClassName={(rowIndex, rowData) =>
          rowData &&
          rowData.status &&
          (rowData.status.includes('completed') || rowData.status.includes('cancelled'))
            ? 'completedTask'
            : ''
        }
        rowStyles={(rowIndex, rowData) => {
          return {
            backgroundColor:
              rowData &&
              rowData.status &&
              (rowData.status.includes('completed') || rowData.status.includes('cancelled'))
                ? '#eeeeee'
                : '#ffffff',
            cursor: 'pointer',
          }
        }}
        selectedRowColor={(rowIndex, rowData) => {
          return '#046ef21f'
        }}
        rowHoverStyles={(rowIndex, rowData) => {
          return { boxShadow: '72px 2px 5px 0px #c9c9c9' }
        }}
      ></Table>
      {getPagination()}
    </>
  )
}

export default Diary
