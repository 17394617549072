import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import PDFThumbnail from '../../PDFThumbnail'
import clsx from 'clsx'
import Word from '../../../images/word.png'
import EXCEL from '../../../images/excel.png'
import IMAGE from '../../../images/image.png'
import axios from 'axios'
import config from '../../../config'
import Swal from 'sweetalert2'

const useStyles = makeStyles({
  attachmentRoot: {
    display: 'flex',
    gridGap: '10px',
    overflowX: 'auto',
    overflowY: 'hidden',
    width: '100%',
    height: 80
  },
  attachment: {
    width: '60px',
    height: '60px',
    cursor: 'pointer',
  },
  singleAttachmentWrap: {
    cursor: 'pointer',
    position: 'relative'
  },
  attachmentLoader: {
    position: 'absolute',
    top: '30%',
    margin: 'auto',
    left: 0,
    right: 0
  }
})

let Attachment = props => {
  const classes = useStyles(props)
  const [loadingAttachment, setAttachmentLoading] = useState([])

  const extractFileExtension = (str) => {
    let fileExtension = str.split('.').pop()
    return fileExtension
  }


  const openDocument = (item) => {
    setAttachmentLoading(l => [...l, item.id])
    var fileType = item && item.fileType && item.fileType.split('/')
    var whichFileType = fileType[1]
    var setFileType = ''
    if (whichFileType === 'jpeg' || whichFileType === 'jpg' || whichFileType === 'png') {
      setFileType = 'image/jpeg'
    }
    if (whichFileType === 'pdf') {
      setFileType = `application/${whichFileType}`
    }
    if (whichFileType === 'svg+xml') {
      setFileType = `image/svg+xml`
    }
    axios
      .get(`${config.apiPath}/api/legal/document?legalId=${item.id}`, {
        responseType: 'arraybuffer',
        headers: {
          Accept: setFileType,
        },
      })
      .then((res) => {
        const blob = new Blob([res.data], { type: setFileType })
        const blobUrl = URL.createObjectURL(blob)
        setAttachmentLoading(l => l.filter(a => a !== item.id))
        window.open(blobUrl, '_blank')
      })
      .catch((error) => {
        Swal.fire({
          type: 'error',
          icon: 'error',
          title: 'Error',
          text: 'File Not Found!!!',
        })
      })
  }

  const openAttachment = (attachment) => {
    if(attachment.secure)
      openDocument(attachment)
    else
      window.open(attachment.value, '_blank')
  }

  if (props.attachments && Array.isArray(props.attachments) && props.attachments.length > 0) {
    return (
      <div className={classes.attachmentRoot}>
        {props.attachments.map((item) => {
          return (
            <div className={classes.singleAttachmentWrap} onClick={() => openAttachment(item)}>
              {extractFileExtension(item.value) === 'pdf' ? (
                <PDFThumbnail file={item.value} />
              ) : (
                <img
                  className={clsx(classes.attachment, 'tumbnailImg')}
                  src={
                    extractFileExtension(item.value) === 'word'
                      ? Word
                      : (extractFileExtension(item.value) === 'xlsx' || extractFileExtension(item.value) === 'csv')
                      ? EXCEL
                      : ((extractFileExtension(item.value) === 'jpg' ||
                      extractFileExtension(item.value) === 'jpeg' ||
                      extractFileExtension(item.value) === 'svg' ||
                      extractFileExtension(item.value) === 'png' ||
                      extractFileExtension(item.value) === 'webp') &&
                      item.secure
                      ) ? IMAGE :
                      extractFileExtension(item.value) === 'jpg' ||
                      extractFileExtension(item.value) === 'jpeg' ||
                      extractFileExtension(item.value) === 'svg' ||
                      extractFileExtension(item.value) === 'png' ||
                      extractFileExtension(item.value) === 'webp' ?
                      item.value : null
                  }
                  alt="word"
                />
              )}
              {loadingAttachment.indexOf(item.id) > -1 ?
              <span className={`fa fa-circle-o-notch fa-spin text-center ${classes.attachmentLoader}`}></span>: <></>}
            </div>
          )
        })}
      </div>
    )
  } else {
    return <div style={{ marginLeft: '15px' }}>No Attachment Found</div>
  }
}

export const mapDispatchToProps = (dispatch) => {
  return {
  }
}

const mapStateToProps = (state) => {
  return {
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Attachment))

