/** @format */
import moment, { isMoment } from 'moment'
import { capitalize_Words } from '../../../../utils/commonFormat'
import { ColumnName } from '../../../TableConfig/ProjectLeadConfig'
import { StaticData } from '../../../../StaticData'
import React from 'react'
import { normalizePhoneFormat } from '../../../../utils/formatPhone'
import axios from 'axios'
import config from '../../../../config'
import Swal from 'sweetalert2'
import { getPermissionValue } from '../../../_unlockComponent'
import { PermissionActions, PermissionFeatures } from '../../../../utils/permissionConstants'
import {
  getLeadTag,
  isLeadClosed,
  leadStatusColor,
  leadTimeStatusColor,
} from '../../../../utils/lead'
import YellowLead from '../../../../images/yellow-marker.svg'
import RedLead from '../../../../images/red-marker.svg'
import { RenderLeadStatus } from '../../../../utils/general'
import { ReferedBuyRentLeadUrl, ReferedProjectLeadUrl } from '../../../../utils/constant_data'

const extractAdditionalDetails = (value) => {
  let objectKeys = Object.keys(value)
  console.log("org", value)

  if (objectKeys.includes("please_specify_your_budget_range")) {
    let tempInfo = (value['please_specify_your_budget_range'].map((internalItem) => internalItem)).toString()
    tempInfo = tempInfo.replaceAll("_", " ") + " Budget"
    value['please_specify_your_budget_range'] = tempInfo.split(",")
    console.log(value)
  }else if (objectKeys.includes("please_specify_your_budget_range?")) {
    let tempInfo = (value['please_specify_your_budget_range?'].map((internalItem) => internalItem)).toString()
    tempInfo = tempInfo.replaceAll("_", " ") + " Budget"
    value['please_specify_your_budget_range?'] = tempInfo.split(",")
    console.log(value)
  }

  let renderAdditionalInformations =
    objectKeys &&
    objectKeys.map((item, index) =>
      value[item].map((internalItem) => <span>{(index ? ', ' : '') + internalItem}</span>)
    )

  return renderAdditionalInformations
}

// const extractAdditionalDetails = (value) => {
//     let objectKeys = Object.keys(value)
//     let renderAdditionalInformations
//     if (objectKeys.includes("please_specify_your_budget_range")) {
//       let tempInfo = (value['please_specify_your_budget_range'].map((internalItem) => internalItem)).toString()
//       tempInfo = tempInfo.replaceAll("_", " ") + " Budget"
//       renderAdditionalInformations = <span>{tempInfo}</span>
//     }
//     else {
//       renderAdditionalInformations =
//       objectKeys &&
//       objectKeys.map((item, index) =>
//         value[item].map((internalItem) => <span>{(index ? ', ' : '') + internalItem}</span>)
//       )
//     }

  //   return renderAdditionalInformations
  // }

export const getPropValue = (templateProperty, cellIndex, rowIndex, rowData, props, columnName) => {
  switch (columnName) {
    case ColumnName.LEAD_ID:
      if (templateProperty === 'label') {
        let tag = ''
        if (props.page !== ReferedProjectLeadUrl) {
          tag = getLeadTag(rowData)
          tag =
            tag && ['delay__30__60', 'delay__60__above'].indexOf(tag) === -1 ? (
              <span className="statusText investLeadStatus shared">{tag}</span>
            ) : (
              ''
            )
        }
        return (
          <>
            <span className="project-lead-link">{rowData.id}</span>
            {tag}
          </>
        )
      }
      break
    case ColumnName.DATE_CREATED:
      if (templateProperty === 'label')
        return moment(rowData.createdAt).format('DD-MM-YYYY (hh:mm a)')
      break
    case ColumnName.TIME_STATUS:
      if (templateProperty === 'label' && props.serverTime) {
        const leadTag = getLeadTag(rowData, props.serverTime)
        return ['delay__30__60', 'delay__60__above'].indexOf(leadTag) > -1 ? (
          <img src={leadTag === 'delay__30__60' ? YellowLead : RedLead} style={{ width: 12 }} />
        ) : (
          ''
        )
      }
      break
    case ColumnName.CLIENT_NAME:
      if (templateProperty === 'label') {
        return rowData.customer
          ? (rowData.customer.first_name
              ? capitalize_Words(rowData.customer.first_name) + ' '
              : '') +
              (rowData.customer.last_name ? capitalize_Words(rowData.customer.last_name) : '')
          : ''
      }
      break
    case ColumnName.PROJECT_NAME:
      if (templateProperty === 'label') {
        return rowData.projectName
          ? capitalize_Words(rowData.projectName)
          : rowData.project && rowData.project.name
          ? capitalize_Words(rowData.project.name)
          : 'Any'
      }
      break
    case ColumnName.PROJECTNAME:
      if (templateProperty === 'label') {
        return rowData.project && rowData.project.name
          ? capitalize_Words(rowData.project.name)
          : 'Any'
      }
      break
    case ColumnName.ASSIGNED_TO:
      if (templateProperty === 'label')
        return rowData.armsuser
          ? `${rowData.armsuser.firstName} ${rowData.armsuser.lastName}`
          : '--'
      break
    case ColumnName.STATUS:
      const { background, textColor } = leadStatusColor(rowData.status.toLowerCase())

      if (templateProperty === 'text') {
        return capitalize_Words(
          RenderLeadStatus(
            rowData.status && rowData.status.toLowerCase(),
            StaticData.projectLeadStatus
          )
        )
      } else if (templateProperty === 'chipColor') {
        return background
      } else if (templateProperty === 'textColor') {
        return textColor
      }
      break
    case ColumnName.CLASSIFICATION:
      if (templateProperty === 'label') {
        let leadCategoryColor =
          (rowData.leadCategory &&
            StaticData.leadCategories.filter((c) => c.value === rowData.leadCategory).length &&
            StaticData.leadCategories.filter((c) => c.value === rowData.leadCategory)[0]
              .colorCode) ||
          null
        return rowData.leadCategory ? (
          <span
            className={'lead-category-status'}
            style={{
              color: leadCategoryColor,
            }}
          >
            {rowData.leadCategory}
          </span>
        ) : (
          '--'
        )
      }
      break
    case ColumnName.FOLLOW_UP_TIME:
      if (templateProperty === 'label')
        return rowData.followUP ? moment(rowData.followUP.start).format('D MMM, h:mm a') : '--'
      break
    case ColumnName.ORIGIN:
      if (templateProperty === 'label') return rowData.origin ? rowData.origin : '--'
      break
    case ColumnName.UNIT_TYPE:
      if (templateProperty === 'label')
        return rowData.productTypes && rowData.productTypes.name != null
          ? rowData.productTypes.name
          : '--'
      break
    case ColumnName.CITY:
      if (templateProperty === 'label')
        return rowData && rowData.city && rowData.city != null && rowData.city.name != null
          ? rowData.city.name
          : '--'
      break
    case ColumnName.ADDITIONAL_DETAILS:
      if (templateProperty === 'label')
        return (rowData && rowData.fbAdditionalDetails === '{}') ||
          (rowData && rowData.fbAdditionalDetails === null)
          ? '--'
          : <>{extractAdditionalDetails(JSON.parse(rowData.fbAdditionalDetails))}</>
      break
    case ColumnName.ADVISOR_CONTACT_NO:
      if (templateProperty === 'label')
        return rowData.armsuser && rowData.armsuser.phoneNumber
          ? normalizePhoneFormat(rowData.armsuser.phoneNumber)
          : '--'
      break
    case ColumnName.FORM_ID:
      if (templateProperty === 'label') return rowData.compaignId || '--'
      break
    case ColumnName.UNIT:
      if (templateProperty === 'label')
        return rowData.unit && rowData.unit.name ? rowData.unit.name : '--'
      break
    case ColumnName.FLOOR:
      if (templateProperty === 'label')
        return rowData.floor && rowData.floor.name ? rowData.floor.name : '--'
      break
    case ColumnName.TRANSFERRED_FROM:
      if (templateProperty === 'label')
        return rowData.transferredFrom ? `${rowData.transferredFrom.firstName} ${rowData.transferredFrom.lastName}` : '--'
      break
    case ColumnName.TRANSFERRED_DATE:
      if (templateProperty === 'label')
      return rowData.transferedDate ? moment(rowData.transferedDate).format('D MMM, h:mm a') : '--'
      break
    case ColumnName.FLOOR:
      if (templateProperty === 'label')
        return rowData.floor && rowData.floor.name ? rowData.floor.name : '--'
      break
    case ColumnName.FINAL_PRICE:
      if (templateProperty === 'label')
        return rowData.unit && rowData.unit.finalPrice
          ? Math.round(rowData.unit.finalPrice).toLocaleString()
          : '--'
      break
    case ColumnName.CLOSING_REASON:
      if (templateProperty === 'label') return rowData.reasons || '--'
      break
    case ColumnName.ACTION:
      if (templateProperty === 'display')
        return rowData.requiredProperties ||
          props.hideAction ||
          // isLeadClosed(rowData) ||
          !getPermissionValue(PermissionFeatures.PROJECT_LEADS, PermissionActions.ASSIGN_REASSIGN)
          ? 'none'
          : 'block'
  }
}

const arrayParams = ['leadCategory', 'time', 'faceBookFilter']

export const mapFiltersToQuery = (filters, quickFilters) => {
  const mapToParams = (filters, quickFilters) => {
    let queryParams = []
    if (filters) {
      let appliedFilters = Object.keys(filters)
      let filtersQueryArr = appliedFilters.map((key) => {
        if (Array.isArray(filters[key])) {
          return filters[key]
            .map((v) => {
              return `${key}[]=${v.value}`
            })
            .join('&')
        } else if (typeof filters[key] === 'object') {
          if (isMoment(filters[key])) {
            return `${key}=${moment(filters[key]).format('YYYY-MM-DD')}`
          } else if (
            filters &&
            filters[key] &&
            filters[key].value &&
            Array.isArray(filters && filters[key] && filters[key].value)
          ) {
            return filters[key].value
              .map((feedback) => {
                if (arrayParams.indexOf(key) > -1) return `${key}[]=${feedback}`
                else return `${key}=${feedback}`
              })
              .join('&')
          } else {
            if (arrayParams.indexOf(key) > -1) {
              return `${key}[]=${filters && filters[key] && filters[key].value}`
            } else {
              return `${key}=${filters && filters[key] && filters[key].value}`
            }
          }
        } else {
          if (arrayParams.indexOf(key) > -1) {
            return `${key}[]=${filters[key]}`
          } else {
            return `${key}=${filters && filters[key] && filters[key]}`
          }
        }
      })
      queryParams = [...filtersQueryArr]
    }
    if (quickFilters) {
      let quickFiltersQueryArr = quickFilters.map((quickFilter) => {
        if (arrayParams.indexOf(quickFilter.name) > -1) {
          return `${quickFilter.name}[]=${quickFilter.value}`
        } else {
          return `${quickFilter.name}=${quickFilter.value}`
        }
      })
      queryParams = [...queryParams, ...quickFiltersQueryArr]
    }
    return queryParams.join('&')
  }
  return mapToParams(filters, quickFilters)
}

export const assignLeadFun = (
  id,
  firstName,
  lastName,
  selectedLeads,
  filteredSelectedLeads,
  type,
  cb = null,
  is_confirm = false
) => {
  let finalLeadIds
  if (filteredSelectedLeads.length > 0) {
    finalLeadIds = selectedLeads.filter((value) => filteredSelectedLeads.includes(value))
  } else {
    finalLeadIds = selectedLeads
  }

  let assignData = {
    leadId: finalLeadIds,
    type: type,
    userId: id,
    web: true,
  }

  if (is_confirm) {
    assignData.skipDiffAgentWarning = true
  }

  axios.patch(`${config.apiPath}/api/leads/assign`, assignData).then((res) => {
    if (is_confirm || (res && res.data && res.data.message)) {
      Swal.fire(
        'Success',
        `Leads Re-assigned to <b>${firstName + ' ' + lastName}</b> successfully`,
        'success'
      )
    }
    cb && cb(res.data)
  })
}
