import { bedBathRange } from './constant_data'


export const isMaxVal = (value) => {
  return bedBathRange[bedBathRange.length-1] <= value
}

export const isMinVal = (value) => {
  return bedBathRange[0] >= value
}

export const formatBedBathRange = (min, max) => {
  min = min === null ? bedBathRange[0] : min
  max = (max === null || max === 0) ? bedBathRange[bedBathRange.length-1] : max
  let isMin = isMinVal(min)
  let isMax = isMaxVal(max)
  if(isMin && isMax)
    return "Any"
  else if(!isMin && isMax)
    return min + " or More"
  else if(isMin && !isMax)
    return "Upto "+max
  else
    return min +" - "+ max
}
