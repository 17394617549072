/** @format */

import React, { useRef, useState } from 'react'
import moment from 'moment'
import {
  capitalize_Words,
  capitalizeWordsWithoutUnderscore,
  renderLabelIcon,
} from '../../utils/commonFormat'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import Tooltip from './Tooltip/Tooltip'
import { TaskTypeLabels } from '../../utils/constant_data'

// const useStyles = makeStyles({
//   root: (props) => ({
//     color: props.textColor,
//     textDecoration: props.textDecoration,
//
//     '&:hover': {
//       color: props.hoverColor,
//     },
//   }),
// })

const Label = (props) => {
  // const classes = useStyles(props)
  const labelRef = useRef()
  const formatDate = (date, format) => {
    return moment(date).format(format)
  }
  const getValue = (type) => {
    if (Array.isArray(props.label)) {
      return props.label.join(props.seperator)
    } else {
      switch (type) {
        case 'priceMasking':
          return props.label.toLocaleString()
        case 'date':
          return formatDate(props.label, props.format)
          break
        case 'capitalizeWords':
          return capitalize_Words(props.label)

        case 'capitalizeWordsWithoutUnderscore':
          return capitalizeWordsWithoutUnderscore(props.label)
        case 'labelIcon':
          return (
            <>
              {props.label} <i className={`${props.labelIconCode} ml-2`}></i>
            </>
          )
        default:
          return props.label
      }
      return props.label
    }
  }

  return (
    <Tooltip content={getValue(props.type)} direction="top" contentRef={labelRef}>
      <span
        style={{
          color: props.textColor,
          textDecoration: props.textDecoration,
        }}
        onMouseEnter={(e) => e.target.style.color = props.hoverColor}
        onMouseLeave={(e) => e.target.style.color = props.textColor}
        className={props.className} onClick={props.onClick} ref={labelRef}>
        {getValue(props.type)}
      </span>
    </Tooltip>
  )
}

export default Label

Label.propTypes = {
  type: PropTypes.string,
  label: PropTypes.any,
  seperator: PropTypes.string,
  format: PropTypes.string,
  textColor: PropTypes.string,
  hoverColor: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
}

Label.defaultProps = {
  type: '',
  label: '',
  seperator: '',
  format: '',
  textColor: 'black',
  hoverColor: 'none',
  onClick: () => {},
  className: '',
}
