/** @format */

const { checkBaseDomain,socketBaseDomain } = require('./utils/checkBaseDomain')

const env = process.env.NODE_ENV || 'development'

const host = window.location.hostname
//projectInventory
const projectInventoryNew = true

const config = {
  development: {
    apiPath: checkBaseDomain(host),
    callSocketPath: socketBaseDomain(host).socketUrl,
    callApiPath: socketBaseDomain(host).callUrl,
    asteriskUrl: socketBaseDomain(host).asteriskUrl,
    apiPath2:"http://192.168.18.217:3050",
    domain: 'graana.rocks',
    revivePath: 'https://revive.graana.rocks',
    projectInventoryNew:projectInventoryNew,
    lockScreen: true,
    pageSize: 25,
    imaratURL: "https://imaratdev.propsure.rocks"
  },
  production: {
    apiPath: checkBaseDomain(host),
    callSocketPath: socketBaseDomain(host).socketUrl,
    callApiPath: socketBaseDomain(host).callUrl,
    asteriskUrl: socketBaseDomain(host).asteriskUrl,
    apiPath2:"http://192.168.18.217:3050",
    projectInventoryNew:projectInventoryNew,
    domain: 'graana.com',
    revivePath: 'https://revive.graana.rocks',
    lockScreen: true,
    pageSize: 25,
    imaratURL: "https://portal.imarat.com.pk/"
  },
  domain: '.graana.com',

}
module.exports = config[env]
