/** @format */

import React, { useEffect, useState } from 'react'
import { Modal, ModalBody } from 'reactstrap'
import InvestmentLead from '../../../views/Lead/MyLead/ProjectLead/Listing'
import BuyAndRentLead from '../../../views/Lead/MyLead/BuyRentLead/Listing'
import { makeStyles } from '@material-ui/styles'
import LeadView from './../../common/LeadView/Wanted/WantedLeads'
import { getCustomerWantedLeads } from '../../../actions/wantedLeads'
import { connect } from 'react-redux'
import Load from '../../Loader'
import { setExcludeLead } from '../../../actions/leads'
import { fetchLeads } from '../../../actions/leads'
import { buyAndRentLeads } from '../../../actions/leads'
import { getWantedLeads } from '../../../actions/wantedLeads'
import Select from 'react-select'
import clsx from 'clsx'
import axios from 'axios'
import config from '../../../config'
import Button from '../Button'
import './diary.scss'
import { capitalizeWordsWithoutUnderscore } from '../../../utils/commonFormat'
import { getPermissionValue } from '../../_unlockComponent'
import { PermissionActions, PermissionFeatures } from '../../../utils/permissionConstants'
const fieldHeight = {
  control: (base) => ({
    ...base,
  }),
  singleValue: (base) => ({
    ...base,
    color: 'hsl(0,0%,50%)',
  }),
}
const customStyles = {
  control: (base) => ({
    ...base,
    '&:hover': {
      borderColor: '#046ef2',
      color: '#046ef2',
    },
    '&': {
      borderColor: '#046ef2',
      color: '#046ef2',
      boxShadow: '0 0 0 1px #2684ff',
    },
  }),
}
const useStyles = makeStyles({
  leadMenu: {
    whiteSpace: 'nowrap',
    cursor: 'pointer',
  },
  badge: {
    background: '#026ff2',
    width: 16,
    height: 16,
    display: 'inline-block',
    fontSize: 9,
    color: 'white',
    textAlign: 'center',
    borderRadius: 10,
    top: -2,
    right: 0,
    position: 'absolute',
    lineHeight: '16px',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    gridGap: '15px',
    // rowGap: '30px !important',
    // marginTop: '30px',
    marginBottom: '10px',
    '& > div': {
      width: '200px',
    },
  },
  textbox: {
    height: '38px',
    paddingLeft: '10px',
    border: '1px solid #dbdbdb',
    borderRadius: '5px',
    width: '100% !important',
    outline: 'none',
    '&:focus': {
      border: '1px solid #2684ff',
      boxShadow: '0 0 0 1px #2684ff',
    },
  },
  dropdown: {
    // border: '1px solid #dbdbdb',
    // borderRadius: '5px',
    '&:focus': {
      // border: '1px solid #2684ff !important',
      // boxShadow: '0 0 0 1px #2684ff !important',
    },
  },
  btnContainer: {
    display: 'flex',
    gridGap: '10px',
  },
  selected: {
    border: '1px solid #2684ff',
    boxShadow: '0 0 0 1px #2684ff',
    color: '#046ef2',
  },
  filterMainDiv: {
    display: 'flex',
  },
})
const getProjectLeadStatus = () => {
  return '&status[]=open&status[]=in-progress&status[]=token&status[]=payment&status[]=meeting&status[]=in_progress'
}
const getBuyRentLeadStatus = () => {
  return '&status[]=open&status[]=follow_up&status[]=meeting&status[]=offer&status[]=viewing&status[]=propsure&status[]=shortlisting&status[]=token&status[]=payment'
}
const LeadSelection = (props) => {
  const getDefaultTab = () => {
    if (props.taskType === 'follow_up') {
      if (
        getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.WANTED_LEADS_PAGE_VIEW)
      ) {
        return 'wanted'
      } else if (
        getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.BUYRENT_LEADS_PAGE_VIEW)
      ) {
        return 'buyrent'
      } else return 'project'
    } else if (props.taskType === 'viewing') {
      return 'buyrent'
    } else if (props.taskType === 'meeting') {
      if (
        getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.BUYRENT_LEADS_PAGE_VIEW)
      ) {
        return 'buyrent'
      } else if (
        getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.PROJECTS_PAGE_VIEW)
      ) {
        return 'project'
      }
    }
  }
  const classes = useStyles(props)
  const [tab, setTab] = useState(getDefaultTab())
  const [wantedPagination, setWantedPagination] = useState({ currentPage: 1, pageSize: config.pageSize })
  const [selectedLead, setSelectedLead] = useState(null)
  const [searchBy, setSearchBy] = useState(null)
  const [filter, setFilter] = useState('')

  const [isClientLoading, setClientLoading] = useState(false)
  const [clientNames, setClientNames] = useState([])

  const [isBuyRentLoading, setBuyRentLoading] = useState(false)
  const [isProjectLoading, setProjectLoading] = useState(false)

  const fetchClientNames = (name = null) => {
    setClientLoading(true)
    let nameParam = ''
    if (name) {
      nameParam = `?name=${name}`
    }
    axios
      .get(`${config.apiPath}/api/customer/teamClients${nameParam}`)
      .then((res) => {
        if (res && res.data && res.data.result) {
          setClientNames(
            res.data.result.map((item) => {
              return {
                label: item.first_name + ' ' + item.last_name,
                value: item.first_name + ' ' + item.last_name,
              }
            })
          )
        }
        setClientLoading(false)
      })
      .catch(() => {
        setClientLoading(false)
      })
  }

  useEffect(() => {
    fetchClientNames()
    getLeads()
  }, [])

  const getLeads = () => {
    setProjectLoading(true)
    setBuyRentLoading(true)
    if (props.taskType === 'meeting' || props.taskType === 'follow_up') {
      props.fetchProjectLeads(
        { offset: 0, pageSize: config.pageSize },
        '',
        () => {
          setProjectLoading(false)
        },
        null,
        false
      )
    }
    if (
      props.taskType === 'meeting' ||
      props.taskType === 'viewing' ||
      props.taskType === 'follow_up'
    ) {
      props.fetchBRLeads(
        { offset: 0, pageSize: config.pageSize },
        '',
        () => {
          setBuyRentLoading(false)
        },
        undefined
      )
    }
    props.taskType === 'follow_up' && props.fetchWantedLeads({ currentPage: 1, pageSize: config.pageSize })
  }

  const getLeadsByTab = (tab) => {
    if (tab === 'project') {
      setProjectLoading(true)
      props.fetchProjectLeads(
        { offset: 0, pageSize: config.pageSize },
        '',
        () => {
          setProjectLoading(false)
        },
        null,
        false
      )
    } else if (tab === 'buyrent') {
      setBuyRentLoading(true)
      props.fetchBRLeads(
        { offset: 0, pageSize: config.pageSize },
        '',
        () => {
          setBuyRentLoading(false)
        },
        undefined
      )
    } else if (tab === 'wanted') {
      props.fetchWantedLeads({ currentPage: 1, pageSize: config.pageSize })
    }
  }

  // useEffect(() => {
  //   getLeadsByTab(tab)
  // }, [tab])

  const onContinue = () => {
    props.onLeadSelection && props.onLeadSelection(selectedLead, tab)
  }

  const getFilters = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div className={classes.container}>
          <div>
            <Select
              classNamePrefix="leadSelectionFilter"
              styles={searchBy ? customStyles : fieldHeight}
              value={
                searchBy
                  ? searchBy === 'id'
                    ? { value: 'id', label: 'Lead ID' }
                    : { value: 'clientName', label: 'Client Name' }
                  : null
              }
              options={[
                { value: 'id', label: 'Lead ID' },
                { value: 'clientName', label: 'Client Name' },
              ]}
              className={clsx(classes.dropdown)}
              placeholder="Search By "
              onChange={(e) => {
                setSearchBy(e.value)
              }}
            />
          </div>
          {searchBy === 'id' && (
            <div>
              <input
                type="number"
                placeholder="Enter Lead ID"
                className={clsx(classes.textbox, filter ? classes.selected : '')}
                value={filter}
                onChange={(e) => {
                  let value = e.target.value
                  setFilter(value)
                }}
              />
            </div>
          )}
          {searchBy === 'clientName' && (
            <div>
              <Select
                classNamePrefix="leadSelectionFilter"
                isLoading={isClientLoading}
                onInputChange={(change) => {
                  if (change) fetchClientNames(change)
                }}
                // value={filter ? filter : null}
                styles={filter ? customStyles : fieldHeight}
                options={clientNames}
                noOptionsMessage={() => 'No client name found'}
                placeholder="Client Name"
                onChange={(value) => {
                  value && setFilter(value.value)
                }}
              />
            </div>
          )}
        </div>
        <div style={{ display: 'flex', gridGap: '10px', flexDirection: 'row-reverse' }}>
          <div>
            <Button
              text="Search"
              buttonColor="#026ff2"
              textColor="white"
              action="search"
              width="80px"
              className="searchBtn"
              onClick={() => {
                if (tab === 'project') {
                  setProjectLoading(true)
                  props.fetchProjectLeads(
                    { offset: 0, pageSize: config.pageSize },
                    '',
                    () => {
                      setProjectLoading(false)
                    },
                    undefined,
                    false,
                    `&assignToMe=true&${searchBy}=${filter}${getProjectLeadStatus()}`
                  )
                } else if (tab === 'buyrent') {
                  setBuyRentLoading(true)
                  props.fetchBRLeads(
                    { offset: 0, pageSize: config.pageSize },
                    '',
                    () => {
                      setBuyRentLoading(false)
                    },
                    undefined,
                    `&assignToMe=true&${searchBy}=${filter}${getBuyRentLeadStatus()}`
                  )
                } else if (tab === 'wanted') {
                  props.fetchWantedLeads(
                    { currentPage: 1, pageSize: config.pageSize },
                    undefined,
                    `&${searchBy}=${filter}`
                  )
                }
              }}
            ></Button>
          </div>
          <div>
            <Button
              text="Clear"
              buttonColor="#ffffff"
              action="details"
              className="detailsBtn"
              textColor="#2e96f5"
              width="80px"
              onClick={() => {
                setFilter('')
                setSearchBy('')
                getLeadsByTab(tab)
              }}
            ></Button>
          </div>
        </div>
      </div>
    )
  }
  return (
    <>
      <Modal
        isOpen={props.isOpen}
        toggle={(toggle) => {
          props.setOpen(!toggle)
        }}
        contentClassName={`no-custom`}
        size={'xl'}
      >
        <ModalBody className={'d-flex flex-column'}>
          <div className="close-icon-container">
            <i
              className="fal fa-times-circle close-icon"
              onClick={() => {
                props.setOpen(false)
              }}
            ></i>
          </div>
          <>
            <div className="float-left w-100">
              <h4>Select Lead</h4>
              <div className="d-flex my-3 sub-menu">
                {props.taskType === 'follow_up' &&
                  getPermissionValue(
                    PermissionFeatures.WEB_PAGES,
                    PermissionActions.WANTED_LEADS_PAGE_VIEW
                  ) && (
                    <div
                      onClick={() => setTab('wanted')}
                      className={`menu-item p-2 ${tab === 'wanted' ? 'selected' : ''}`}
                    >
                      <a className={`${classes.leadMenu}`}>Wanted</a>
                      {props.wantedLeads && props.wantedLeads.rows ? (
                        <span className={classes.badge}>{props.wantedLeads.count}</span>
                      ) : (
                        <></>
                      )}
                    </div>
                  )}
                {(props.taskType === 'meeting' ||
                  props.taskType === 'follow_up' ||
                  props.taskType === 'viewing') &&
                  getPermissionValue(
                    PermissionFeatures.WEB_PAGES,
                    PermissionActions.BUYRENT_LEADS_PAGE_VIEW
                  ) && (
                    <div
                      onClick={() => setTab('buyrent')}
                      className={`menu-item p-2 ${tab === 'buyrent' ? 'selected' : ''}`}
                    >
                      <a className={`${classes.leadMenu}`}>Buy/Rent</a>
                      {props.buyrentLeads &&
                      props.buyrentLeads.rows &&
                      props.buyrentLeads.rows.length > 0 ? (
                        <span className={classes.badge}>{props.buyrentLeads.count}</span>
                      ) : (
                        <></>
                      )}
                    </div>
                  )}
                {(props.taskType === 'follow_up' || props.taskType === 'meeting') &&
                  getPermissionValue(
                    PermissionFeatures.WEB_PAGES,
                    PermissionActions.PROJECT_LEADS_PAGE_VIEW
                  ) && (
                    <div
                      onClick={() => setTab('project')}
                      className={`menu-item p-2 ${tab === 'project' ? 'selected' : ''}`}
                    >
                      <a className={`${classes.leadMenu}`}>Project</a>
                      {props.projectLeads &&
                      props.projectLeads.rows &&
                      props.projectLeads.rows.length > 0 ? (
                        <span className={classes.badge}>{props.projectLeads.count}</span>
                      ) : (
                        <></>
                      )}
                    </div>
                  )}
              </div>
            </div>
            <div>{getFilters()}</div>
            <div>
              <div style={{ display: tab === 'project' ? 'block' : 'none' }}>
                <div
                  className="dash-container br-lead-page-wrap"
                  style={{ display: isProjectLoading ? 'block' : 'none' }}
                >
                  <div className="text-center" style={{ marginTop: 60, marginBottom: 50 }}>
                    <Load title="Leads"></Load>
                  </div>
                </div>
                <div style={{ display: isProjectLoading ? 'none' : 'block' }}>
                  <InvestmentLead
                    onRowClick={(lead) => {
                      setSelectedLead(lead)
                    }}
                    location={{ search: `?page=1&pageSize=${config.pageSize}` }}
                    popup={true}
                    fromPage={'leadSelection'}
                    onChangePage={(cPage, pSize) => {
                      setProjectLoading(true)
                      props.fetchProjectLeads(
                        { offset: cPage, pageSize: pSize },
                        '',
                        () => {
                          setProjectLoading(false)
                        },
                        undefined,
                        false
                      )
                    }}
                  />
                </div>
              </div>
              <div style={{ display: tab === 'buyrent' ? 'block' : 'none' }}>
                <div
                  className="dash-container br-lead-page-wrap"
                  style={{ display: isBuyRentLoading ? 'block' : 'none' }}
                >
                  <div className="text-center" style={{ marginTop: 60, marginBottom: 50 }}>
                    <Load title="Leads"></Load>
                  </div>
                </div>
                <div
                  style={{ display: isBuyRentLoading ? 'none' : 'block' }}
                  className={'leadSelection'}
                >
                  <BuyAndRentLead
                    location={{ search: `?page=1&pageSize=${config.pageSize}` }}
                    popup={true}
                    fromPage={'leadSelection'}
                    onChangePage={(cPage, pSize) => {
                      setBuyRentLoading(true)
                      props.fetchBRLeads(
                        { offset: cPage, pageSize: pSize },
                        '',
                        () => {
                          setBuyRentLoading(false)
                        },
                        undefined
                      )
                    }}
                    onRowClick={(lead) => {
                      setSelectedLead(lead)
                    }}
                  />
                </div>
              </div>
              <div
                style={{
                  display: ['project', 'buyrent'].indexOf(tab) > -1 ? 'none' : 'block',
                  backgroundColor: 'white',
                  height:
                    props.wantedLeads && props.wantedLeads.rows && props.wantedLeads.rows.length > 5
                      ? 'calc(100vh - 175px)'
                      : 'auto',
                  border: '1px solid #dddd',
                  borderRadius: '3px',
                }}
              >
                {props.isLoading ? (
                  <Load title="Leads"></Load>
                ) : props.wantedLeads && props.wantedLeads.rows && props.wantedLeads.rows.length ? (
                  <LeadView
                    hideAction={true}
                    data={props.wantedLeads.rows}
                    dataCount={props.wantedLeads.count}
                    pagination={wantedPagination}
                    onChangePage={(cPage, pSize) => {
                      setWantedPagination({ currentPage: cPage, pageSize: pSize })
                      props.fetchWantedLeads({ currentPage: cPage, pageSize: pSize })
                    }}
                    fromPage={'leadSelection'}
                    onRowClick={(lead) => {
                      setSelectedLead(lead)
                    }}
                    rowHeight={'35px'}
                  ></LeadView>
                ) : (
                  <div className="dash-container br-lead-page-wrap">
                    <div className="text-center" style={{ marginTop: 60, marginBottom: 50 }}>
                      <h3 className="dimgray">No Leads Found</h3>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div
              className="match-properties-button-container"
              style={{ marginTop: '0px', justifyContent: 'flex-end' }}
            >
              <div className="match-properties-button">
                <button
                  className="selected-matches"
                  onClick={onContinue}
                  disabled={!selectedLead}
                  style={{ marginTop: '20px', width: '150px' }}
                >
                  Continue
                </button>
              </div>
            </div>
          </>
        </ModalBody>
      </Modal>
    </>
  )
}

export const mapDispatchToProps = (dispatch) => {
  return {
    fetchProjectLeads: (
      req,
      sortingOrder,
      callback,
      hasBooking,
      onlyOpenStatus,
      query = `&assignToMe=true${getProjectLeadStatus()}`
    ) =>
      dispatch(
        fetchLeads(
          req,
          sortingOrder,
          callback,
          null,
          onlyOpenStatus,
          false,
          '',
          null,
          query,
          'createTask'
        )
      ),
    fetchBRLeads: (
      req,
      sortingOrder,
      callback,
      hasBooking,
      query = `&assignToMe=true${getBuyRentLeadStatus()}`
    ) => dispatch(buyAndRentLeads(req, callback, false, null, null, null, null, query, false)),
    fetchWantedLeads: (pagination, customerId, query = '') =>
      dispatch(
        getWantedLeads(pagination, customerId, `&showAllLeads=true&assignToMe=true${query}`)
      ),
  }
}
export const mapStateToProps = (state) => {
  return {
    wantedLeads: state.WantedLeads.leads,
    projectLeads: state.Leads.leads,
    buyrentLeads: state.Leads.brLeads,
    isLoading: state.WantedLeads.isLoading,
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(LeadSelection)

