/** @format */

import React, {useEffect, useState} from 'react'
import { makeStyles } from '@material-ui/styles'
import PropTypes from 'prop-types'
import { TasksType, callFeedback } from '../../utils/constant_data'
import moment from 'moment'

import Call from '../../images/connection.jpeg'
import Meeting from '../../images/meet.jpeg'
import Viewing from '../../images/viewing.jpeg'
import Task from '../../images/follow_up.jpeg'
import NoTask from '../../images/task-icon.svg'
import DoneIcon from '../../images/done.svg'

import Chip from './Chip'
import { capitalize_Words, capitalizeWordsWithoutUnderscore } from '../../utils/commonFormat'
import Label from './Label'
import Accordion from '../Accordion'

import config from '../../config';
import axios from "axios";

const useStyles = makeStyles({
  tile: {
    // display: 'grid',
    gridTemplateColumns: '8% 92%',
    fontSize: 12,
    margin: '10px',
    borderRadius: '4px',
    boxShadow: '0px 2px 3px 0px #e9e9e9',
    background: 'white',
    padding: '10px',
  },
  hr: {
    borderWidth: 2,
  },
  date: {
    fontSize: '10px',
  },
  doneImg: {
    marginLeft: '5px',
  },
  firstRow: {
    display: 'flex',
    // width: '400px',
    height: '30px',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginRight: 10
  },
  firstRowLeft: {
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'flex-end',
  },
  firstRowRight: {
    display: 'flex',
    alignSelf: 'flex-start',
  },
  secondRow: {
    marginLeft: '34px',
  },
  reason: {
    marginLeft: '10px',
    fontSize: '10px',
    color: '#616161',
  },
  reasonDefaultText: {
    marginRight: '10px',
  },
  callAudio: {
    marginLeft: '0px',
    marginTop: '10px',
    fontSize: '10px',
    color: '#616161',
    display: 'flex'
  },
})



const ClientHistory = (props) => {
  const classes = useStyles()

  const getCallLogs = (clientCalls) => {
    let abc;
    for(let i=0; i< clientCalls.length; i++) {
    }
  }

  const getTile = (icon, task) => {
    let clientCalls = task.armsDiaryCalls;
    return (
      <>
        <div className={classes.tile}>
          <div className={classes.firstRow}>
            <div className={classes.firstRowLeft}>
              <img src={icon} width="25px" height="25px" className="mr-2"></img>
              <Label
                label={capitalizeWordsWithoutUnderscore(task.taskType)}
                className="font-weight-bold"
              ></Label>

              {task.status !== 'completed' && task.reason && task.reasonTag && (
                <div className={classes.reason}>
                  <Label
                    label="| Reason"
                    textColor={'#616161'}
                    className={classes.reasonDefaultText}
                  ></Label>
                  <Chip
                    text={task.reasonTag}
                    textColor={'#616161'}
                    border={`1px solid ${task.reason.colorCode}`}
                    borderRadius={'15px'}
                  ></Chip>
                </div>
              )}
            </div>

            <div className={classes.firstRowRight} style={task.status !== 'completed' ? {
              marginRight: 15
            } : {}}>
              <div>
                <Label
                  className={classes.date}
                  label={task.start}
                  type={'date'}
                  format={'ddd MMM D, LT'}
                ></Label>
                {task.user ? <div>by <strong>{task.user.firstName + ' ' + task.user.lastName}</strong></div> : ''}
                  </div>
              {task.status === 'completed' ? (
                <img className={classes.doneImg} src={DoneIcon} width="15px" height="15px"></img>
              ) : <span style={{width: 5}}></span>}
            </div>
          </div>
          <div className={classes.secondRow}>
            {task.feedbackTag && task.status === 'completed' && (
              <div>
                <Label
                  label={task.feedbackTag}
                  textColor={'#3379f4'}
                  className="font-weight-bold"
                ></Label>
              </div>
            )}
            {task.response && task.status === 'completed' && task.feedbackTag !== task.response && (
              <div>
                <Label label={task.response} textColor={'#8a8a8a'}></Label>
              </div>
            )}
            {clientCalls.length > 0 && <div style={{position: 'relative'}} className={classes.callAudio}>
              <audio style={{height: 25}} controls>
                <source src={`${config.asteriskUrl}/api/recording.php?filename=`+(clientCalls[clientCalls.length - 1].callRecording)} type="audio/mpeg"/>
              </audio>
              <Accordion diaryId={task.id}/>
            </div>}
          </div>
          {/* <div>{task.status === 'completed' ? getDoneTile(task) : getPendingTile(task)}</div> */}
        </div>
      </>
    )
  }

  const getHistoryItem = (historyItem) => {
    switch (historyItem.taskType) {
      case TasksType.CONNECT:
        return getTile(Call, historyItem)
        break
      case TasksType.MEETING:
        return getTile(Meeting, historyItem)
        break
      case TasksType.TASK:
      case TasksType.FOLLOWUP:
        return getTile(Task, historyItem)
        break
      case TasksType.VIEWING:
        return getTile(Viewing, historyItem)
    }
  }
  const renderHistory = () => {
    return (
      <div id={'task-history'} className={'mt-3 pr-2'} style={{ overflowY: 'auto', height: 'calc(100% - 60px)' }}>
        {props.history.length ? (
          props.history.map((hist, k) => {
            return <div key={k}>{getHistoryItem(hist)}</div>
          })
        ) : (
          <div className={'d-flex justify-content-center'}>
            <img style={{ height: 250 }} src={NoTask} />
          </div>
        )}
      </div>
    )
  }

  return renderHistory()
}

export default ClientHistory

ClientHistory.propTypes = {
  history: PropTypes.array,
}

ClientHistory.defaultProps = {
  history: [],
}
