/** @format */

import axios from 'axios'
import config from '../config'

export const FETCH_LEAD_ORG_BEGIN = 'FETCH_LEAD_ORG_BEGIN'
export const FETCH_LEAD_ORG_SUCCESS = 'FETCH_LEAD_ORG_SUCCESS'
export const FETCH_LEAD_ORG_FAILED = 'FETCH_LEAD_ORG_FAILED'

export const ADD_RULE_BEGIN = 'ADD_RULE_BEGIN'
export const ADD_RULE_SUCCESS = 'ADD_RULE_SUCCESS'
export const ADD_RULE_FAILED = 'ADD_RULE_FAILED'

export const FETCH_RULE_BEGIN = 'FETCH_RULE_BEGIN'
export const FETCH_RULE_BEGIN_FLAG = 'FETCH_RULE_BEGIN_FLAG'
export const FETCH_RULE_SUCCESS_FLAG = 'FETCH_RULE_BEGIN_FLAG'
export const FETCH_RULE_SUCCESS = 'FETCH_RULE_SUCCESS'
export const FETCH_RULE_FAILED = 'FETCH_RULE_FAILED'

export const FETCH_FB_COMPAIGN_BEGIN = 'FETCH_FB_COMPAIGN_BEGIN'
export const FETCH_FB_COMPAIGN_BEGIN_FLAG = 'FETCH_FB_COMPAIGN_BEGIN_FLAG'
export const FETCH_FB_COMPAIGN_SUCCESS_FLAG = 'FETCH_FB_COMPAIGN_SUCCESS_FLAG'
export const FETCH_FB_COMPAIGN_SUCCESS = 'FETCH_FB_COMPAIGN_SUCCESS'
export const FETCH_FB_COMPAIGN_FAILED = 'FETCH_FB_COMPAIGN_FAILED'

export const FETCH_FB_ACTIVE_COMPAIGN_BEGIN = 'FETCH_FB_ACTIVE_COMPAIGN_BEGIN'
export const FETCH_FB_ACTIVE_COMPAIGN_BEGIN_FLAG = 'FETCH_FB_ACTIVE_COMPAIGN_BEGIN_FLAG'
export const FETCH_FB_ACTIVE_COMPAIGN_SUCCESS_FLAG = 'FETCH_FB_ACTIVE_COMPAIGN_SUCCESS_FLAG'
export const FETCH_FB_ACTIVE_COMPAIGN_SUCCESS = 'FETCH_FB_ACTIVE_COMPAIGN_SUCCESS'
export const FETCH_FB_ACTIVE_COMPAIGN_FAILED = 'FETCH_FB_ACTIVE_COMPAIGN_FAILED'

export const FETCH_FB_DEACTIVE_COMPAIGN_BEGIN = 'FETCH_FB_DEACTIVE_COMPAIGN_BEGIN'
export const FETCH_FB_DEACTIVE_COMPAIGN_BEGIN_FLAG = 'FETCH_FB_DEACTIVE_COMPAIGN_BEGIN_FLAG'
export const FETCH_FB_DEACTIVE_COMPAIGN_SUCCESS_FLAG = 'FETCH_FB_DEACTIVE_COMPAIGN_SUCCESS_FLAG'
export const FETCH_FB_DEACTIVE_COMPAIGN_SUCCESS = 'FETCH_FB_DEACTIVE_COMPAIGN_SUCCESS'
export const FETCH_FB_DEACTIVE_COMPAIGN_FAILED = 'FETCH_FB_DEACTIVE_COMPAIGN_FAILED'

export const AGENT_CITY_TOGGLE = 'AGENT_CITY_TOGGLE'

export const agentToggle = () => {
  return (dispatch) => {
    dispatch({ type: AGENT_CITY_TOGGLE })
  }
}

export const fetchLeadOrg = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_LEAD_ORG_BEGIN })
    axios
      .get(`${config.apiPath}/api/leadSourceManagment/org`)
      .then((res) => {
        dispatch({ type: FETCH_LEAD_ORG_SUCCESS, payload: res.data.rows })
      })
      .catch((error) => dispatch({ type: FETCH_LEAD_ORG_FAILED, payload: error }))
  }
}

export const addNewLeadRule = (type, originId, projectIds, cityIds, products) => {
  return (dispatch) => {
    dispatch({ type: ADD_RULE_BEGIN })
    axios
      .post(`${config.apiPath}/api/leadSourceManagment/rule`, {
        type: type ? 'regular' : 'otherwise',
        originId: originId,
        projectIds: projectIds,
        cityIds: cityIds,
        products: products,
      })
      .then((res) => dispatch({ type: ADD_RULE_SUCCESS, paylaod: res.data }))
      .catch((err) => dispatch({ type: ADD_RULE_FAILED, paylaod: err }))
  }
}

export const fetchRules = (originId) => {
  return (dispatch) => {
    dispatch({ type: FETCH_RULE_BEGIN })
    dispatch({ type: FETCH_RULE_BEGIN_FLAG, payload: false })
    axios
      .get(`${config.apiPath}/api/leadSourceManagment/rules?organizationId=${originId}`)
      .then((res) => {
        dispatch({ type: FETCH_RULE_SUCCESS, payload: res.data })
        dispatch({ type: FETCH_RULE_SUCCESS_FLAG, payload: true })
      })
      .catch((err) => dispatch({ type: FETCH_RULE_FAILED, payload: err }))
  }
}

export const fetchFacebookComp = (id) => {
  return (dispatch) => {
    dispatch({ type: FETCH_FB_COMPAIGN_BEGIN })
    dispatch({ type: FETCH_FB_COMPAIGN_BEGIN_FLAG, payload: false })
    axios
      .get(`${config.apiPath}/api/leadSourceManagment/fbCampaign?organizationId=${id}&all=true`)
      .then((res) => {
        dispatch({ type: FETCH_FB_COMPAIGN_SUCCESS, payload: res.data })
        dispatch({ type: FETCH_FB_COMPAIGN_SUCCESS_FLAG, payload: true })
      })
      .catch((err) => dispatch({ type: FETCH_FB_COMPAIGN_FAILED, payload: err }))
  }
}

export const fetchActiveFacebookComp = (id, page = 1, pageSize = config.pageSize) => {
  return (dispatch) => {
    dispatch({ type: FETCH_FB_ACTIVE_COMPAIGN_BEGIN })
    dispatch({ type: FETCH_FB_ACTIVE_COMPAIGN_BEGIN_FLAG, payload: false })
    axios
      .get(
        `${config.apiPath}/api/leadSourceManagment/fbCampaign?organizationId=${id}&deactive=false&page=${page}&pageSize=${pageSize}`
      )
      .then((res) => {
        dispatch({ type: FETCH_FB_ACTIVE_COMPAIGN_SUCCESS, payload: res.data })
        dispatch({ type: FETCH_FB_ACTIVE_COMPAIGN_SUCCESS_FLAG, payload: true })
      })
      .catch((err) => dispatch({ type: FETCH_FB_ACTIVE_COMPAIGN_FAILED, payload: err }))
  }
}

export const fetchDeActiveFacebookComp = (id, page = 1, pageSize = config.pageSize) => {
  return (dispatch) => {
    dispatch({ type: FETCH_FB_DEACTIVE_COMPAIGN_BEGIN })
    dispatch({ type: FETCH_FB_DEACTIVE_COMPAIGN_BEGIN_FLAG, payload: false })
    axios
      .get(
        `${config.apiPath}/api/leadSourceManagment/fbCampaign?organizationId=${id}&deactive=true&page=${page}&pageSize=${pageSize}`
      )
      .then((res) => {
        dispatch({ type: FETCH_FB_DEACTIVE_COMPAIGN_SUCCESS, payload: res.data })
        dispatch({ type: FETCH_FB_DEACTIVE_COMPAIGN_SUCCESS_FLAG, payload: true })
      })
      .catch((err) => dispatch({ type: FETCH_FB_DEACTIVE_COMPAIGN_FAILED, payload: err }))
  }
}
