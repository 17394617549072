/** @format */
import moment, { isMoment } from 'moment'
import { capitalize_Words } from '../../../../utils/commonFormat'
import { getPermissionValue } from '../../../_unlockComponent'
import { PermissionActions, PermissionFeatures } from '../../../../utils/permissionConstants'
import { DIARY_TASK_ACTION } from '../../../../utils/constant_data'
const removeExtrachar = (string) => {
  if(string)
    return string.replace(/[ -_]/g, '').toLocaleLowerCase()
  return string;
}

const getWantedAssignedTo = (rowData) => {
  let assignto = DIARY_TASK_ACTION.ASSIGN_TO_AREA_MANAGER
  if (rowData && rowData.purpose && rowData.purpose.toLocaleLowerCase() === 'invest') {
    assignto = DIARY_TASK_ACTION.ASSIGN_TO_INVESTMENT_ADVISOR
  } else if (
    rowData &&
    rowData.purpose &&
    (removeExtrachar(rowData.purpose).toLocaleLowerCase() === 'sell' ||
      removeExtrachar(rowData.purpose).toLocaleLowerCase() === 'rentout')
  ) {
    assignto = DIARY_TASK_ACTION.ASSIGN_TO_CATALOGER
  }
  return assignto
}

export const wantedStatusColors = status => {
  let background = ''
  let textColor = ''
  switch (removeExtrachar(status)) {
    case 'open':
      background = '#ceecfc'
      textColor = '#28566a'
      break
    case 'inprogress':
      background = '#fdf2c6 '
      textColor = '#906121'
      break
    case 'assigned':
      background = '#caf4a4'
      textColor = '#536d3a'
      break
    case 'rejected':
      background = '#fde0e2'
      textColor = '#7a3635'
      break
  }
  return {
    background,
    textColor
  }
}

export const getPropValue = (templateProperty, cellIndex, rowIndex, rowData, props) => {
  switch (cellIndex) {
    case 1:
      if (templateProperty === 'label') {
        return rowData.customer
          ? (rowData.customer.first_name
              ? capitalize_Words(rowData.customer.first_name) + ' '
              : '') +
              (rowData.customer.last_name ? capitalize_Words(rowData.customer.last_name) : '')
          : ''
      }
      break
    case 2:
      if (templateProperty === 'label') {
        let purpose = rowData.purpose
        if (purpose && purpose.toLocaleLowerCase() === 'sale') {
          purpose = 'buy'
        }
        return (
          capitalize_Words(purpose) +
          ' - ' +
          (rowData.size ? rowData.size + ' ' + rowData.size_unit : 'Any') +
          ' ' +
          (rowData.subtype ? capitalize_Words(rowData.subtype) : '') +
          (rowData.wanted_areas && rowData.wanted_areas.length > 0
            ? ' in ' +
              rowData.wanted_areas
                .map((areas) => {
                  if (areas.area) {
                    return areas.area.name
                  }
                })
                .join(',')
            : '')
        )
      }
      break
    case 3:
      const { background, textColor } = wantedStatusColors(rowData.armsStatus)

      if (templateProperty === 'text') {
        return capitalize_Words(rowData.armsStatus)
      } else if (templateProperty === 'chipColor') {
        return background
      } else if (templateProperty === 'textColor') {
        return textColor
      }
      break
    case 9:
      if (templateProperty === 'display')
        return props.hideAction || !getPermissionValue(PermissionFeatures.WANTED_LEADS, PermissionActions.ASSIGN_REASSIGN)
            ? 'none' : 'block'
      else if (templateProperty === 'actionItems') {
        let item = []
        item.push({ label: 'Scheduled Tasks', action: 'scheduled-tasks' })
        rowData.armsStatus !== 'rejected' &&
          rowData.armsStatus !== 'assigned' &&
          getPermissionValue(PermissionFeatures.WANTED_LEADS, PermissionActions.UPDATE) &&
          item.push(getWantedAssignedTo(rowData))
        item.push({ label: 'Lead Details', action: 'details' })
        // item.push({ label: 'Additional Information', action: 'additional-information' })
        rowData.armsStatus !== 'rejected' &&
          rowData.armsStatus !== 'assigned' &&
          getPermissionValue(PermissionFeatures.WANTED_LEADS, PermissionActions.ASSIGN_REASSIGN) &&
          item.push({ label: 'Re-Assign', action: 're-assign' })

        return item
      }
      break
  }
}

const FilterKeyMap = {
  startDate: 'startDate',
  endDate: 'endDate',
  clientName: 'clientName',
  phone: 'contactNo',
  id: 'id',
  leadStatus: 'status',
  userHierarchy: 'userHierarchy',
  teamHierarchy: 'teamHierarchy',
  cityId: 'cityId',
  areaId: 'areaId'
}
export const mapFiltersToQuery = (filters, quickFilters) => {
  const mapToParams = (filters, quickFilters) => {
    let queryParams = []
    if (filters) {
      let appliedFilters = Object.keys(filters)
      let filtersQueryArr = appliedFilters.map((key) => {
        if (typeof filters[key] === 'object') {
          if (isMoment(filters[key])) {
            return FilterKeyMap[key] + '=' + moment(filters[key]).format('YYYY-MM-DD')
          } else if (filters[key].value && Array.isArray(filters[key].value)) {
            return filters[key].value
              .map((feedback) => {
                return FilterKeyMap[key] + '=' + feedback
              })
              .join('&')
          } else return FilterKeyMap[key] + '=' + filters[key].value
        } else return FilterKeyMap[key] + '=' + filters[key]
      })
      queryParams = [...filtersQueryArr]
    }
    if (quickFilters) {
      let quickFiltersQueryArr = quickFilters.map((quickFilter) => {
        return quickFilter.for + '=' + quickFilter.value
      })
      queryParams = [...queryParams, ...quickFiltersQueryArr]
    }
    return queryParams.join('&')
  }
  return mapToParams(filters, quickFilters)

  // if (isOverDue()) {
  //   return mapToParams(props.overDueFilters, props.overDueQuickFilters)
  // } else {
  //   return mapToParams(props.diaryFilters, props.diaryQuickFilter)
  // }
}
export const isAira = () => {
  return getPermissionValue(PermissionFeatures.WANTED_LEADS, PermissionActions.Assign_Company_Leads)
}
