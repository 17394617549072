/** @format */

import axios from 'axios'
import config from '../config'
import history from '../utils/routingHistory'
import { showToastMsg } from '../utils/helper'

export const ADD_TEAM = 'ADD_TEAM'
export const SIGN_UP_FAIL = 'SIGN_UP_FAIL'
export const LOADER = 'LOADER'
export const LOADER_SUCCESS = 'LOADER_SUCCESS'
export const FETCH_TEAMS = 'FETCH_TEAMS'
export const FETCH_TEAMS_FAILURE = 'FETCH_TEAMS_FAILURE'
export const CLEAR_BUTTON_LOADER = 'CLEAR_BUTTON_LOADER'
export const CLEAR_BUTTON_SUCCESS = 'CLEAR_BUTTON_SUCCESS'
export const UPDATE_TEAM = 'UPDATE_TEAM'
export const UPDATE_TEAM_FAILURE = 'UPDATE_TEAM_FAILURE'
export const DEACTIVATED_FETCH_TEAM = 'DEACTIVATED_FETCH_TEAM'
export const DEACTIVATED_TEAM_FAILURE = 'DEACTIVATED_TEAM_FAILURE'
export const DELETE_TEAM = 'DELETE_TEAM'
export const ACTIVE_TEAM_SUCCESS = 'ACTIVE_TEAM_SUCCESS'
export const ACTIVE_TEAM_FAILURE = 'ACTIVE_TEAM_FAILURE'
export const ADD_TEAM_LOADER = 'ADD_TEAM_LOADER'
export const ADD_TEAM_LOADER_SUCCESS = 'ADD_TEAM_LOADER_SUCCESS'
export const FETCH_ALL_TEAMS = 'FETCH_ALL_TEAMS'

export const addTeam = (data) => {
  return (dispatch) => {
    dispatch({ type: ADD_TEAM_LOADER })
    axios
      .post(`${config.apiPath}/api/user/addUserTeamDetails`, data)
      .then(function (response) {
        dispatch({ type: ADD_TEAM_LOADER_SUCCESS })
        showToastMsg('Team has been successfully added', 'success', 2000)
        setTimeout(() => {
          history.push(`/teams?page=1&pageSize=${config.pageSize}`)
        }, 2000)
        return dispatch({ type: ADD_TEAM, data: response })
      })
      .catch(function (xhr, status, err) {
        showToastMsg(xhr.response.data.message, 'error', 2000)
        return dispatch({ type: SIGN_UP_FAIL, data: xhr })
      })
  }
}

export const editTeam = (data, userId) => {
  return (dispatch) => {
    dispatch({ type: ADD_TEAM_LOADER })
    axios
      .patch(`${config.apiPath}/api/user/updateuserTeamsDetails`, data)
      .then(function (response) {
        dispatch({ type: ADD_TEAM_LOADER_SUCCESS })
        showToastMsg('Team has been successfully updated.', 'success', 2000)
        setTimeout(() => {
          history.push(`/teams?page=1&pageSize=${config.pageSize}`)
        }, 2000)
        return dispatch({ type: UPDATE_TEAM, data: response })
      })
      .catch(function (xhr, status, err) {
        showToastMsg(xhr.response.data, 'error', 2000)

        return dispatch({ type: UPDATE_TEAM_FAILURE, data: xhr })
      })
  }
}

export const fetchTeams = (req) => {
  const urlParams = new URLSearchParams(window.location.search)
  const page = urlParams.get('page')
  var endpoint = `${config.apiPath}/api/user/usersteam`
  if (req && req.offset && req.pageSize) {
    endpoint = `${config.apiPath}/api/user/usersteam?pageSize=` + config.pageSize + '&page=' + page
  } else if (req && req.all === true) {
    endpoint = `${config.apiPath}/api/user/usersteam`
  }
  return (dispatch) => {
    dispatch({ type: CLEAR_BUTTON_SUCCESS })
    dispatch({ type: LOADER })
    axios
      .get(endpoint)
      .then(function (response) {
        dispatch({ type: LOADER_SUCCESS })
        dispatch({ type: CLEAR_BUTTON_SUCCESS })
        return dispatch({ type: FETCH_TEAMS, data: response.data })
      })
      .catch(function (xhr, status, err) {
        return dispatch({ type: FETCH_TEAMS_FAILURE, data: xhr })
      })
  }
}

export const fetchAllTeams = () => {
  return (dispatch) => {
    axios.get(`${config.apiPath}/api/user/usersteam`).then((res) => {
      dispatch({ type: FETCH_ALL_TEAMS, data: res.data })
    })
  }
}

export const fetchFiltersData = (url, offset) => {
  const urlParams = new URLSearchParams(window.location.search)
  const page = urlParams.get('page')
  return (dispatch) => {
    dispatch({ type: CLEAR_BUTTON_SUCCESS })

    dispatch({ type: LOADER })
    dispatch({ type: FETCH_TEAMS })
    axios
      .get(`${url}&page=${page}&pageSize=${config.pageSize}`)
      .then(function (response) {
        dispatch({ type: LOADER_SUCCESS })
        dispatch({ type: CLEAR_BUTTON_LOADER })
        return dispatch({ type: FETCH_TEAMS, data: response.data })
      })
      .catch(function (xhr, status, err) {
        return dispatch({ type: FETCH_TEAMS_FAILURE, data: xhr })
      })
  }
}

export const fetchDeactivatedTeam = (req) => {
  const urlParams = new URLSearchParams(window.location.search)
  const page = urlParams.get('page')
  var endpoint = `${config.apiPath}/api/user/teams?status=false&deActivated=true`
  if (req && req.offset && req.pageSize) {
    endpoint =
      `${config.apiPath}/api/user/teams?status=false&deActivated=true&page=` +
      page +
      '&pageSize=' +
      config.pageSize
  } else if (req && req.all === true) {
    endpoint = `${config.apiPath}/api/user/teams?status=false&deActivated=true`
  }
  return (dispatch) => {
    dispatch({ type: CLEAR_BUTTON_SUCCESS })
    dispatch({ type: LOADER })
    axios
      .get(endpoint)
      .then(function (response) {
        dispatch({ type: CLEAR_BUTTON_SUCCESS })
        dispatch({ type: LOADER_SUCCESS })
        return dispatch({ type: DEACTIVATED_FETCH_TEAM, data: response.data })
      })
      .catch(function (xhr, status, err) {
        return dispatch({ type: DEACTIVATED_TEAM_FAILURE, data: xhr })
      })
  }
}

export const activeTeam = (id) => {
  return (dispatch) => {
    dispatch({ type: DELETE_TEAM, id: true })
    axios
      .patch(`${config.apiPath}/api/user/team?teamId=${id}`, { status: true })
      .then((response) => {
        return dispatch({ type: ACTIVE_TEAM_SUCCESS, data: id })
      })
      .catch(function (xhr, status, err) {
        return dispatch({ type: ACTIVE_TEAM_FAILURE, data: xhr })
      })
  }
}

export const fetchTeamFilterData = (url, offset) => {
  const urlParams = new URLSearchParams(window.location.search)
  const page = urlParams.get('page')
  return (dispatch) => {
    dispatch({ type: CLEAR_BUTTON_SUCCESS })
    dispatch({ type: LOADER })
    dispatch({ type: FETCH_TEAMS })
    axios
      .get(`${url}&page=${page}&pageSize=${config.pageSize}`)
      .then(function (response) {
        dispatch({ type: LOADER_SUCCESS })
        dispatch({ type: CLEAR_BUTTON_LOADER })
        return dispatch({ type: DEACTIVATED_FETCH_TEAM, data: response.data })
      })
      .catch(function (xhr, status, err) {
        return dispatch({ type: DEACTIVATED_TEAM_FAILURE, data: xhr })
      })
  }
}
