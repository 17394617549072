/** @format */

import React, { useEffect, useState, useRef } from 'react'
import SlotScheduler from './SlotScheduler'
import axios from 'axios'
import config from '../../config'
import { connect } from 'react-redux'
import Label from '../common/Label'
import { Calendar } from 'react-rainbow-components'
import moment from 'moment'
import useOutsideListner from './../../hooks/OutsideListner'
import PropTypes from 'prop-types'
import { scrollIntoShiftView } from './SlotHelper'
import CircleLoader from './../../components/CircleLoader'
import { getHoursGridExtension, getTimeUsingCoords, pad, hasTimeCredit } from './SlotHelper'
import Swal from 'sweetalert2'
import { getPermissionValue } from '../_unlockComponent'
import { PermissionFeatures, PermissionActions } from '../../utils/permissionConstants'

const SlotManager = (props) => {
  const totalDayHours = 24
  const [selectedDate, setSelectedDate] = useState(moment().format())
  const [isCalenderVisible, setCalenderVisibility] = useState(false)
  const [shifts, setShifts] = useState(false)
  const [gridHours, setGridHours] = useState(totalDayHours)
  const calenderRef = useRef(null)
  const [isLoading, setLoading] = useState(false)
  const [diary, setDiary] = useState([])
  const [slotTemplate, setSlotTemplate] = useState([])
  const [assignShiftLoader, setAssignShiftLoader] = useState(false)
  const assign_24_7_shifts = () => {
    setAssignShiftLoader(true)
    axios.patch(`${config.apiPath}/api/user/247shift`).then((res) => {
      setShifts(res.data)
      // let hoursExtension = getHoursGridExtension(res.data, selectedDate)
      // setGridHours(totalDayHours + hoursExtension)
      setAssignShiftLoader(false)
    })
  }
  const getShiftSchedule = () => {
    setLoading(true)
    let params = ''
    if (props.userId) {
      params = `?userId=${props.userId}`
    }
    axios.get(`${config.apiPath}/api/slotManagement/user-shifts${params}`).then((res) => {
      if (res.data && res.data.length <= 0) {
        if (
          getPermissionValue(
            PermissionFeatures.BUY_RENT_LEADS,
            PermissionActions.Assign_Company_Leads
          ) ||
          getPermissionValue(
            PermissionFeatures.BUY_RENT_LEADS,
            PermissionActions.BUY_RENT_REA_WORKFLOW
          ) ||
          getPermissionValue(PermissionFeatures.BUY_RENT_LEADS, PermissionActions.UPDATE) ||
          (getPermissionValue(PermissionFeatures.PROJECT_LEADS, PermissionActions.UPDATE) &&
            !getPermissionValue(
              PermissionFeatures.PROJECT_LEADS,
              PermissionActions.Assign_Company_Leads
            ))
        ) {
          assign_24_7_shifts()
        }
      } else {
        setShifts(res.data)
        let hoursExtension = getHoursGridExtension(res.data, selectedDate)
        setGridHours(totalDayHours + hoursExtension)
        setLoading(false)
      }
    })
  }

  const getScheduledTasks = () => {
    setLoading(true)
    let params = ''
    if (props.userId) {
      params = `&userId=${props.userId}`
    }
    axios
      .get(
        `${config.apiPath}/api/slotManagement/user-slots-diaries?startDate=${moment(
          selectedDate
        ).format('YYYY-MM-DD')}&endDate=${moment(selectedDate).format('YYYY-MM-DD')}${params}`
      )
      .then((res) => {
        setDiary(res.data)
        setLoading(false)
      })
  }

  const getSlotTemplate = () => {
    setLoading(true)
    let params = ''
    if (props.userId) {
      params = `?userId=${props.userId}`
    }
    axios.get(`${config.apiPath}/api/slotManagement/slot${params}`).then((res) => {
      setSlotTemplate(res.data)
      setLoading(false)
    })
  }

  useEffect(() => {
    getShiftSchedule()
    getScheduledTasks()
    getSlotTemplate()
  }, [])

  useEffect(() => {
    //TODO get task for next day if shift is for next day
    getScheduledTasks()
    if (shifts && selectedDate) {
      let hoursExtension = getHoursGridExtension(shifts, selectedDate)
      setGridHours(totalDayHours + hoursExtension)
    }
  }, [selectedDate])
  useOutsideListner(calenderRef, (event) => {
    event.stopPropagation()
    setCalenderVisibility(false)
  })

  const isMarkedDate = (thisDate) => {
    return moment(selectedDate).isSame(thisDate, 'day')
  }

  const setDateAfter = (dateAfter) => {
    //set date after with respect to selected Date
    setSelectedDate(moment(selectedDate).add(dateAfter, 'days').format())
  }
  const setDateAt = (dateAfter) => {
    // set date after n date
    setSelectedDate(moment().add(dateAfter, 'days').format())
  }
  const getFilters = () => {
    return (
      <div className="filterMain noselect">
        <div className="filterContainer">
          <div className="filterButton" onClick={() => setDateAt(0)}>
            <Label
              label="Today"
              textColor={isMarkedDate(moment().add(0, 'days')) ? '#84b3ff' : '#5e5e63'}
              hoverColor={'#84b3ff'}
            ></Label>
          </div>
          <div className="filterButton" onClick={() => setDateAt(1)}>
            <Label
              label="Tomorrow"
              textColor={isMarkedDate(moment().add(1, 'days')) ? '#84b3ff' : '#5e5e63'}
              hoverColor={'#84b3ff'}
            ></Label>
          </div>
          <div className="filterButton" onClick={() => setDateAt(3)}>
            <Label
              label="After 3 days"
              textColor={isMarkedDate(moment().add(3, 'days')) ? '#84b3ff' : '#5e5e63'}
              hoverColor={'#84b3ff'}
            ></Label>
          </div>
          <div className="filterButton" onClick={() => setDateAt(7)}>
            <Label
              label="After 7 days"
              textColor={isMarkedDate(moment().add(7, 'days')) ? '#84b3ff' : '#5e5e63'}
              hoverColor={'#84b3ff'}
            ></Label>
          </div>
        </div>

        <div className="filterCalenderContainer">
          <div className="fa fa-angle-left calNavigator" onClick={() => setDateAfter(-1)}></div>
          <div className="calContainer" onClick={() => setCalenderVisibility(!isCalenderVisible)}>
            <Label
              label={moment(selectedDate).format('MMMM Do YYYY')}
              textColor={'#5e5e63'}
              hoverColor={'#84b3ff'}
            ></Label>
          </div>
          <div className="fa fa-angle-right calNavigator" onClick={() => setDateAfter(1)}></div>
          {getCalender()}
        </div>
      </div>
    )
  }
  const getCalender = () => {
    return (
      isCalenderVisible && (
        <div ref={calenderRef} className="calenderContainer">
          <Calendar
            id="calendar-1"
            // locale={state.locale.name}
            value={selectedDate}
            onChange={(value) => {
              setSelectedDate(moment(value).format())
              setCalenderVisibility(!isCalenderVisible)
            }}
          />
        </div>
      )
    )
  }
  return (
    <>
      {getFilters()}
      {isLoading || assignShiftLoader ? (
        <CircleLoader loading={isLoading || assignShiftLoader}></CircleLoader>
      ) : (
        <SlotScheduler
          setSelectedSlots={(selectedSlots) => {
            if (selectedSlots && selectedSlots.length > 0) {
              let startSelected = selectedSlots[0]
              let endSelected = selectedSlots[selectedSlots.length - 1]

              let startTime = getTimeUsingCoords(startSelected)
              let endTime = getTimeUsingCoords(endSelected, true)
              let startDate = moment(selectedDate).format('YYYY-MM-DD')
              let endDate = moment(selectedDate).format('YYYY-MM-DD')
              let startHourIndex = startSelected.split(',')[1]
              let endHourIndex = endSelected.split(',')[1]

              let endMinutesIndex = endSelected.split(',')[0]

              if (endMinutesIndex == 11 && endHourIndex >= 23) {
                //if last slot (day end) is selected
                endDate = moment(selectedDate).add(1, 'days').format('YYYY-MM-DD')
              } else {
                if (startHourIndex > 23) {
                  startDate = moment(selectedDate).add(1, 'days').format('YYYY-MM-DD')
                }

                if (endHourIndex > 23) {
                  endDate = moment(selectedDate).add(1, 'days').format('YYYY-MM-DD')
                }
              }
              // "2021-11-10 12:15 AM"
              var startDateTime = moment(startDate + ' ' + startTime, [
                'YYYY-MM-DD HH:mm A',
              ]).format('YYYY-MM-DDTHH:mm:ss')
              var endDateTime = moment(endDate + ' ' + endTime, ['YYYY-MM-DD HH:mm A']).format(
                'YYYY-MM-DDTHH:mm:ss'
              )
              let template = slotTemplate

              let slotArr = []
              slotArr = selectedSlots.map((slot) => {
                let time = slot.split(',')[0] * 5
                let hour = slot.split(',')[1] % 24
                let timeSlot = pad(hour) + ':' + pad(time) + ':00'
                return template.find((t) => t.startTime === timeSlot)
              })
              let hasTime = true //hasTimeCredit(selectedSlots, diary, shifts, selectedDate, slotTemplate)
              props.setSelectedSlots(startDateTime, endDateTime, slotArr, hasTime, diary)
            } else {
              props.setSelectedSlots(null, null, [], true, null)
            }
          }}
          shifts={shifts}
          diaries={diary}
          gridHours={gridHours}
          slotTemplate={slotTemplate}
          date={selectedDate}
          taskType={props.taskType}
        ></SlotScheduler>
      )}
    </>
  )
}
export const mapDispatchToProps = (dispatch) => {
  return {}
}
const mapStateToProps = (state) => {
  return {
    // userInfo: state.user.userInfo,
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(SlotManager)

SlotManager.propTypes = {
  setSelectedSlots: PropTypes.func,
  taskType: PropTypes.string,
}

SlotManager.defaultProps = {
  setSelectedSlots: () => {},
  taskType: '',
}
