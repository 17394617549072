import { sizeRange } from './constant_data'
import { formatPrice } from './formatPrice'


export const isSizeMaxVal = (size, unit) => {
  return sizeRange.unitRange[unit][sizeRange.unitRange[unit].length-1] <= size
}

export const isSizeMinVal = (size, unit) => {
  return sizeRange.unitRange[unit][0] >= size
}

export const formatSizeMaxValue = (min, max, unit) => {
  let isSizeMax = isSizeMaxVal(max, unit)
  if(isSizeMax)
    if(min === 0)
      return "Any"
    else
      return "More"
  else
    return formatPrice(max)
}

export const formatSizeRange = (min, max, unit) => {
  if(!unit)
    unit = 'marla'

  if(min === null)
    min = sizeRange.unitRange[unit][0]

  if(max === null || max === 0)
    max = sizeRange.unitRange[unit][sizeRange.unitRange[unit].length-1]


  let isSizeMin = isSizeMinVal(min, unit)
  let isSizeMax = isSizeMaxVal(max, unit)
  if(isSizeMin && isSizeMax)
    return "Any"
  else if(!isSizeMin && isSizeMax)
    return formatPrice(min) + " or More"
  else if(isSizeMin && !isSizeMax)
    return "Upto "+formatPrice(max)
  else
    return formatPrice((min)) +" - "+ formatPrice(max)
}
