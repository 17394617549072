/** @format */

import axios from 'axios'
import config from '../config'

export const ALL_ENTITIES = 'ALL_ENTITIES'
export const ALL_ACTIVITY_LOGS = 'ALL_ACTIVITY_LOGS'
export const ALL_ACTIVITY_LOG_LOADER = 'ALL_ACTIVITY_LOG_LOADER'
export const ALL_ACTIVITY_LOG_FAILURE = 'ALL_ACTIVITY_LOG_FAILURE'
export const FETCH_ACTIVITY_LOG_FILTER_LOADER = 'FETCH_ACTIVITY_LOG_FILTER_LOADER'
export const FETCH_ACTIVITY_LOG_FILTER = 'FETCH_ACTIVITY_LOG_FILTER'
export const FETCH_ACTIVITY_LOG_FAILURE = 'FETCH_ACTIVITY_LOG_FAILURE'

export const fetchActivityLogs = (req) => {
  const urlParams = new URLSearchParams(window.location.search)
  const page = urlParams.get('page')
  var endpoint = `${config.apiPath}/api/log?pageSize=` + config.pageSize + '&page=' + page
  if (req && req.offset && req.pageSize) {
    endpoint = `${config.apiPath}/api/log?pageSize=` + config.pageSize + '&page=' + page
  } else if (req && req.all === true) {
    endpoint = `${config.apiPath}/api/log?entity`
  }
  return (dispatch) => {
    dispatch({ type: ALL_ACTIVITY_LOG_LOADER, data: true })
    axios
      .get(endpoint)
      .then(function (response) {
        dispatch({ type: ALL_ACTIVITY_LOG_LOADER, data: false })

        return dispatch({ type: ALL_ACTIVITY_LOGS, data: response.data })
      })
      .catch(function (xhr, status, err) {
        return dispatch({ type: ALL_ACTIVITY_LOG_FAILURE, data: xhr })
      })
  }
}

export const fetchAllEntities = () => {
  return (dispatch) => {
    axios.get(`${config.apiPath}/api/log/entities`).then((res) => {
      return dispatch({ type: ALL_ENTITIES, data: res.data })
    })
  }
}

export const fetchActivityLogFiltersData = (url, offset) => {
  const urlParams = new URLSearchParams(window.location.search)
  const page = urlParams.get('page')
  return (dispatch) => {
    dispatch({ type: ALL_ACTIVITY_LOG_LOADER, data: true })
    axios
      .get(`${url}&page=${page}&pageSize=${config.pageSize}`)
      .then(function (response) {
        dispatch({ type: ALL_ACTIVITY_LOG_LOADER, data: false })
        return dispatch({ type: ALL_ACTIVITY_LOGS, data: response.data })
      })
      .catch(function (xhr, status, err) {
        return dispatch({ type: ALL_ACTIVITY_LOG_FAILURE, data: xhr })
      })
  }
}
