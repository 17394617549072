/** @format */

import React, { useRef } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import PropTypes from 'prop-types'
import { SLOT_TASK_TILE_COLOR } from '../../utils/constant_data'

// getBackgroundColor = (type) => {}
const useStyles = makeStyles({
  root: (props) => ({
    width: '100%',
    height: '100%',
    cursor: 'cell', //crosshair
    // background: props.isCellShift ? '#ffffff' : '#fafafa',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  badge: {
    border: '1px solid #b5b2a9',
    fontSize: '8px',
    color: '#b5b2a9',
    borderRadius: '50px',
    width: '20px',
    height: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0px 0px 8px 1px #b5b2a9',
  },
})
const Slot = (props) => {
  const classes = useStyles(props)
  const slotRef = useRef(null)

  const getBackgroundColor = () => {
    if (props.isSelected && props.taskType) {
      let color = SLOT_TASK_TILE_COLOR[props.taskType.replace(/[ -_]/g, '').toLocaleUpperCase()]
      return { background: color }
    }
    let color = props.isCellShift ? '#ffffff' : '#fafafa'
    if (props.diary && props.diary.diary && props.diary.diary.length > 0) {
      let diary = props.diary.diary
      if (diary.length > 1) {
        //multiple
        // color = 'yellow'
      } else {
        color = SLOT_TASK_TILE_COLOR[diary[0].taskType.replace(/[ -_]/g, '').toLocaleUpperCase()]
      }
    }
    return { background: color }
  }
  const getBadge = () => {
    if (props.diary && props.diary.diary && props.diary.diary.length > 0) {
      let diary = props.diary.diary
      if (diary.length > 1) {
        return <span className={classes.badge}>+{diary.length}</span>
        //multiple
      }
    }
    return <></>
  }
  return (
    <div
      ref={slotRef}
      style={getBackgroundColor()}
      className={clsx(classes.root, props.isSelected ? classes.selected : '')}
      onMouseDown={() => {
        props.handleSlotMouseDown(props.rowIndex, props.colIndex)
      }}
      onMouseEnter={() => {
        props.handleSlotMouseEnter(props.rowIndex, props.colIndex)
      }}
      onMouseUp={() => {
        props.handleSlotMouseUp(props.rowIndex, props.colIndex)
      }}
      //   onMouseLeave={(event) => {
      //     props.hanldeSlotMouseLeave(props.rowIndex, props.colIndex)
      //   }}
      onClick={() => {
        // setSelected(true)
        // props.onSlotClick(props.rowIndex, props.colIndex)
        // console.log(props.rowIndex, props.colIndex)
      }}
    >
      {getBadge()}
    </div>
  )
}

export default React.memo(Slot)

Slot.propTypes = {
  isSelected: PropTypes.bool,
  taskType: PropTypes.string,
}

Slot.defaultProps = {
  isSelected: false,
  taskType: '',
}
