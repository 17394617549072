/** @format */

import React, { useRef, useState } from 'react'
import useOutsideListner from './../../hooks/OutsideListner'
import { getPermissionValue } from '../_unlockComponent'

const ActionButton = (props) => {
  const menuRef = useRef(null)
  const [isMenuOpen, setOpen] = useState(false)

  useOutsideListner(menuRef, (event) => {
    event.stopPropagation()
    setOpen(false)
  })

  const getStyles = () => {
    let styles = {}
    if (props.orientation == 'left') {
      styles.right = '100%'
    } else if (props.orientation == 'right') {
      styles.left = '100%'
    }
    return styles
  }

  const getMenu = () => {
    return props.actionItems.map((item, index) => {
      if (
        !props.permission ||
        (props.permission && getPermissionValue(props.permission.feature, props.permission.action))
      ) {
        return (
          <div
            key={index}
            className="container_separator"
            onClick={() => {
              item.onClick(item.action)
              setOpen(false)
            }}
          >
            {item.label}
          </div>
        )
      }
    })
  }

  return (
    <div ref={menuRef} className="text-center pr-2 table-5-width parent_container">
      <i
        className={
          isMenuOpen
            ? 'far fa-ellipsis-v box_icon box_active_color '
            : 'far fa-ellipsis-v box_icon ' + props.iconClassName
        }
        onClick={() => {
          setOpen(!isMenuOpen)
        }}
      ></i>
      {isMenuOpen && (
        <div className="box" style={{ ...getStyles(), zIndex: 999 }}>
          {getMenu()}
        </div>
      )}
    </div>
  )
}

export default ActionButton
