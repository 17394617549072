/** @format */
import config from '../config'

let firstProject = JSON.parse(localStorage.getItem('projectData'))

export const PermissionFeatures = {
  PROJECT_LEADS: 'Project Leads',
  WANTED_LEADS: 'Wanted Leads',
  BUY_RENT_LEADS: 'Buy/Rent Leads',
  RENTAL_PAYMENTS: 'Rent Payments',
  BUYRENT_LEADS_FOR_PPS: 'Buy/Rent Leads for PPs',
  DIARY: 'Diary',
  GAP_PAYMENTS: 'GAP Payments',
  BUYRENT_PAYMENTS: 'Buy/Rent Payments',
  PROJECT_PAYMENTS: 'Project Payments',
  BOOKING_FORMS: 'Booking Forms',
  LEGAL_DOCUMENTS: 'Legal Documents',
  PROPSURE_DOCUMENTS: 'Propsure Requests',
  INVENTORY: 'Inventory',
  INVESTMENT_PRODUCTS: 'Investment Products',
  PROPERTIES: 'Properties',
  LEADS_ASSIGNMENT_BUSINESS_RULE: 'Leads Assignment Business Rule',
  LMS_AGENTS_MANAGEMENT: 'Agent - Project Leads',
  AGENT_BUYRENT: 'Agent - Buy/Rent Leads',
  AGENT_WANTED: 'Agent - Wanted Leads',
  PROJECT_LEAD_RULES: 'Project Leads Assignment Rules',
  CAMPAIGNS: 'Campaigns',
  TEAMS: 'Teams',
  TARGETS: 'Targets',
  ORGANIZATION: 'Organizations',
  ROLE: 'Roles',
  USERS: 'Users',
  CLIENTS: 'Clients',
  WEB_PAGES: 'Web (Pages)',
  APP_PAGES: 'App (Pages)',
  CONTACTS: 'Contacts',
  RENT_PAYMENTS: 'Rent Payments',
  TEAM_MANAGEMENT: 'Teams',
  OFFICE_LOCATIONS: 'Office Locations',
  AREA_GROUPS: 'Area Groups',
  POWER_BI: 'Power BI',
}

export const PermissionActions = {
  POWER_BI: 'Power BI',
  CREATE: 'Create',
  READ: 'Read',
  UPDATE: 'Update',
  DELETE: 'Delete',
  LEADS_ASSIGNMENT_BUSINESS_RULE: 'Leads Assignment Business Rule',
  ELIGIABLE_FOR_COMPANY_LEADS: 'Eligiable for Company Leads',
  Assign_Company_Leads: 'Assign Company Leads',
  ASSIGN_REASSIGN: 'Reassign',
  RE_ASSIGN: 'Re-assign',
  SHARE: 'Refer',
  DOWNLOAD_REBATE_DATA: 'Download Rebate Data',
  CLEARANCE: 'Clearance',
  ORGANIZATIONS_PAGE_VIEW: 'Organizations',
  USER_PAGE_VIEW: 'Users',
  ROLE_PAGE_VIEW: 'Roles',
  PERMISSION_PAGE_VIEW: 'Permission',
  BUYRENT_LEADS_PAGE_VIEW: 'Buy/Rent Leads',
  PROJECT_LEADS_PAGE_VIEW: 'Project Leads',
  WANTED_LEADS_PAGE_VIEW: 'Wanted Leads',
  INVENTORY_PAGE_VIEW: 'Inventory',
  PROJECT_INVENTORY_PAGE_VIEW: 'Project Inventory',
  INVESTMENT_PRODUCTS_PAGE_VIEW: 'Investment Products',
  PROPERTIES_PAGE_VIEW: 'Properties',
  CLIENTS_PAGE_VIEW: 'Clients',
  PROJECT_PAYMENTS_PAGE_VIEW: 'Project Payments',
  BOOKING_FORM: 'Booking Form',
  RENTAL_DASHBOARD_PAGE_VIEW: 'Rent Payments',
  GAP_PAYMENTS_PAGE_VIEW: 'GAP Payments',
  BUY_RENT_PAYMENTS_PAGE_VIEW: 'Buy/Rent Payments',
  PROPSURE_PAGE_VIEW: 'Propsure Report Requests',
  LEGAL_PAGE_VIEW: 'Legal Documents',
  MORNING_MEETINGS_PAGE_VIEW: 'Morning Meetings',
  ACTIVITY_LOGS_PAGE_VIEW: 'Activity Logs',
  ORG_CHART_PAGE_VIEW: 'Org Chart View',
  TARGETS_PAGE_VIEW: 'Targets',
  MY_TARGETS_PAGE_VIEW: 'My Targets',
  CM_LEAD_IMPORT_PAGE_VIEW: 'Import Project Leads',
  LEAD_SOURCES_PAGE_VIEW: 'Lead Sources',
  LEADS_PAGE_VIEW: 'Leads',
  AREA_GROUPS_PAGE_VIEW: 'Agent - Buy/Rent Leads',
  TEAMS_PAGE_VIEW: 'Teams',
  DIARY_PAGE_VIEW: 'Diary',
  ACCOUNTS_PAGE_VIEW: 'Accounts',
  TEAM_DIARY_PAGE_VIEW: `Team's Diary`,
  AVAILABLE_INVENTORY_PAGE_VIEW: 'Available Inventory',
  DASHBOARD_PAGE_VIEW: 'Dashboard',
  KPIS_PAGE_VIEW: 'KPIs',
  ASSIGNED_AREAS_PAGE_VIEW: 'Assigned Areas',
  CLIENT_LEADS_PAGE_VIEW: 'Client Leads',
  RENT_PAGE_VIEW: 'Rent',
  BUY_PAGE_VIEW: 'Buy',
  SELL_RENT_OUT_PAGE_VIEW: 'Sell/Rent Out',
  INVEST_PAGE_VIEW: 'Invest',
  PROPERTY_LEADS_PAGE_VIEW: 'Property Leads',
  ARMS_PAGE_VIEW: 'ARMS Properties',
  FIELD_APP_PAGE_VIEW: 'Field App Properties',
  GRAANA_PAGE_VIEW: 'Graana Properties',
  FLOOR_PAGE_VIEW: 'Floors',
  PROJECTS_PAGE_VIEW: 'Projects',
  UNIT_PAGE_VIEW: 'Units',
  DELETE_FLOOR_PAGE_VIEW: 'Delete Floors',
  DELETE_PROJECTS_PAGE_VIEW: 'Delete Projects',
  DELETE_UNIT_PAGE_VIEW: 'Delete Units',
  TRANSFER_REQUESTS: 'Transfer Requests',
  MY_DEALS_BUY_RENT: 'Buy/Rent Deals',
  MY_DEALS_PROJECT: 'Project Deals',
  RULE_ID: 'Project Lead Rules',
  AGENT_MANAGEMENT: 'Agent - Project Leads',
  CAMPAIGN_MANAGEMENT: 'Facebook Campaigns',
  DIARY: 'Diary',
  FIELD_APP: 'Field App',
  BUY_RENT_PP_WORKFLOW: 'BuyRent PP Workflow',
  BUY_RENT_REA_WORKFLOW: 'Execute REA workflow',
  WORK_AS_RE_AGENT_IN_LEAD_WORKFLOW: 'Work as RE Agent in Leads workflow',
  ACCESS_TO_INVENTORY: 'Access to Inventory',
  DEACTIVATE_USER_PAGE_VIEW: 'Deactivated Users',
  AIRA_MANAGEMENT_PAGE_VIEW: 'Agent - Wanted Leads',
  SET_TARGETS_PAGE_VIEW: 'Set Targets',
  DOWNLOAD_INVENTORY_DATA: 'Download Inventory Data',
  DOWNLOAD_RENTAL_DATA: 'Download Rent Data',
  RENT_PAYMENTS: 'Rent Payments',
  TEAM_MANAGEMENT: 'Teams',
  OFFICE_LOCATIONS_VIEW: 'Office Locations',
  AREA_GROUPS: 'Area Groups',
  LCCVT_REPORT: 'LCCVT Report',
  CCMC_REPORT: 'CCMC Report',
  LTRCT_REPORT: 'LTRCT Report',
  RE_REPORT: 'RE Report',
  CALL_CENTER: 'Call Center',
}

export const PermissionPageURL = {
  Organizations: '/organizations',
  Roles: '/roles',
  Users: '/users',
  Teams: `/teams?page=1&pageSize=${config.pageSize}`,
  DEACTIVATED_USERS: `/deactivatedUser?page=1&pageSize=${config.pageSize}`,
  ACTIVITY_LOGS: `/activity-logs?page=1&pageSize=${config.pageSize}`,
  'Agent - Wanted Leads': `/airaManagement?page=1&pageSize=${config.pageSize}`,
  'Agent - Buy/Rent Leads': `/areasGroup?page=1&pageSize=${config.pageSize}`,
  'Project Deals': `/agentsManagement?page=1&pageSize=${config.pageSize}`,
  'Facebook Campaigns': `/campaignManagement?page=1&pageSize=${config.pageSize}`,
  'Lead Sources': '/leadSource',
  'Import Project Leads': '/cmlead',
  Diary: '/diary',
  'Wanted Leads': '/wanted',
  'Buy/Rent Leads': `/buy-rent-lead?page=1&pageSize=${config.pageSize}`,
  'Project Leads': `/project-deal?page=1&pageSize=${config.pageSize}`,
  Clients: '/clientManagement',
  'Graana Properties': '/graanaView',
  'Field App Properties': '/fieldApp',
  'ARMS Properties': '/armsView',
  Projects: '/projectmain',
  'Inventory Management': '/projectmain',
  Floors: `/floor?projectId=${firstProject && firstProject[0].id}&projectName=${
    firstProject && firstProject[0].name
  }`,
  Units:
    firstProject !== null
      ? `/units?projectId=${
          firstProject && firstProject[0] && firstProject[0].length === 0 ? 0 : firstProject[0].id
        }&projectName=${
          firstProject && firstProject[0].length === 0 ? 'amazon' : firstProject[0].name
        }&floorId=${
          firstProject && firstProject[0] && firstProject[0].floors.length === 0
            ? 0
            : firstProject && firstProject[0] && firstProject[0].floors[0].id
        }`
      : '',
  'Investment Products': `/investmentProducts?page=1&pageSize=${config.pageSize}`,
  'Project Inventory':
    firstProject !== null
      ? `/available-inventory?projectId=${
          firstProject && firstProject[0] && firstProject[0].length === 0 ? 0 : firstProject[0].id
        }&projectName=${
          firstProject && firstProject[0].length === 0 ? 'amazon' : firstProject[0].name
        }&floorId=${
          firstProject && firstProject[0] && firstProject[0].floors.length === 0
            ? 0
            : firstProject && firstProject[0] && firstProject[0].floors[0].id
        }`
      : '',
  'Set Targets': `/targets?page=1&pageSize=${config.pageSize}`,
  'My Targets': '/mytargets',
  'Morning Meetings': '/morningView',
  'Project Payments': `/sales?page=1&pageSize=${config.pageSize}`,
  'Buy/Rent Payments': `/buyrentsales?page=1&pageSize=${config.pageSize}`,
  'Propsure Report Requests': `/propsure?page=1&pageSize=${config.pageSize}`,
  'Legal Documents': `/legalBuyRent?page=1&pageSize=${config.pageSize}`,
  'Transfer Requests': `/transferRequest?page=1&pageSize=${config.pageSize}`,
}
