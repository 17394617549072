/** @format */

import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import { DateRangePicker } from 'react-dates'
import axios from 'axios'
import PhoneInput from 'react-phone-input-2'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'

import CustomButton from '../../components/common/Button'
import config from '../../config'
import 'react-dates/lib/css/_datepicker.css'
import './style.scss'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    gridGap: '15px',
    marginTop: '20px',
    marginBottom: '20px',
    '& > div': {
      width: '23.9%',
    },
  },
  textbox: {
    height: '38px',
    paddingLeft: '10px',
    border: '1px solid #dbdbdb',
    borderRadius: '5px',
    width: '100% !important',
    outline: 'none',
    '&:focus': {
      border: '1px solid #2684ff',
      boxShadow: '0 0 0 1px #2684ff',
    },
  },
  dropdown: {
    '&:focus': {},
  },
  btnContainer: {
    display: 'flex',
    gridGap: '10px',
  },
  selected: {
    border: '1px solid #2684ff',
    boxShadow: '0 0 0 1px #2684ff',
    color: '#046ef2',
  },
})

const MoreFilter = (props) => {
  const [focusedInput, setFocusedInput] = useState(null)
  const [isClientLoading, setClientLoading] = useState(false)
  const [defaultClientlist, setDefaultClientlist] = useState([])
  const [clientNames, setClientNames] = useState([])
  const [showdefaultClientlist, setshowDefaultClientlist] = useState(false)
  const [productList, setProductList] = useState([])
  const [agents, setAgents] = useState([])
  const [advisors, setAdvisors] = useState([])

  const {
    filters,
    feedFormInput,
    toggleShowMoreFilter,
    searchFilter,
    bookingStatus,
    resetFilters,
  } = props

  const classes = useStyles(props)

  const fieldHeight = {
    control: (base) => ({
      ...base,
    }),
    singleValue: (base) => ({
      ...base,
      color: 'hsl(0,0%,50%)',
    }),
  }
  const customStyles = {
    control: (base) => ({
      ...base,
      '&:hover': {
        borderColor: '#046ef2',
        color: '#046ef2',
      },
      '&': {
        borderColor: '#046ef2',
        color: '#046ef2',
        boxShadow: '0 0 0 1px #2684ff',
      },
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: 'inherit',
    }),
    indicatorSeparator: (base) => ({
      ...base,
      backgroundColor: '#046ef2',
    }),
    singleValue: (base) => ({
      ...base,
      color: 'inherit',
    }),
  }

  useEffect(() => {
    fetchClientNames()
    fetchAgents()
    fetchAdvisors()
  }, [])

  const fetchClientNames = () => {
    axios
      .get(`${config.apiPath}/api/imarat/customers`)
      .then((res) => {
        setClientNames(
          res.data.map((item) => {
            return {
              label: item.name,
              value: item.id,
            }
          })
        )
      })
      .catch(() => {
        setClientLoading(false)
      })

    axios.get(`${config.apiPath}/api/imarat/product_types`).then((res) => {
      setProductList(
        res &&
          res.data.map((item) => {
            return {
              label: item.name,
              value: item.id,
            }
          })
      )
    })
  }

  const fetchAgents = () => {
    axios
      .get(`${config.apiPath}/api/imarat/customers/agents`)
      .then((res) => {
        setAgents(
          res.data.map((item) => {
            return {
              label: item.name,
              value: item.id,
            }
          })
        )
      })
      .catch(() => {
        setClientLoading(false)
      })
  }

  const fetchAdvisors = () => {
    axios
      .get(`${config.apiPath}/api/imarat/customers/advisors`)
      .then((res) => {
        setAdvisors(
          res.data.map((item) => {
            return {
              label: item.name,
              value: item.id,
            }
          })
        )
      })
      .catch(() => {
        setClientLoading(false)
      })
  }

  return (
    <>
      <div className={'row mt-2'}>
        <div className={'col-sm-12'}>
          <h4>More Filters</h4>
          <div className="close-icon-container">
            <i
              style={{ top: 5 }}
              className="mt-1 fal fa-times-circle close-icon"
              onClick={() => toggleShowMoreFilter(false)}
            ></i>
          </div>
        </div>
        <div className="user-form" style={{ width: '100%' }}>
          <div className="row pl-2 pr-2">
            <div className="col-md-3">
              <input
                type="number"
                name="id"
                placeholder="Booking ID"
                onChange={(e) => {
                  let value = e.target.value
                  feedFormInput('id', value)
                }}
                value={filters.id}
                className="Id_textfield"
              />
            </div>

            <div className="col-md-3">
              <input
                type="number"
                name="lead_id"
                placeholder="Lead ID"
                onChange={(e) => {
                  let value = e.target.value
                  feedFormInput('lead_id', value)
                }}
                value={filters.lead_id}
                className="Id_textfield"
              />
            </div>

            <div className="col-md-3">
              <div
                className={
                  'inprogressBooking ' +
                  (filters.fromDate && filters.toDate ? 'selectedDateRange' : '')
                }
              >
                <DateRangePicker
                  isOutsideRange={() => false}
                  startDate={filters.fromDate}
                  endDate={filters.toDate}
                  startDateId="project_date_input_start"
                  minimumNights={0}
                  endDateId="project_date_input_end"
                  onDatesChange={({ startDate: dateStart, endDate: dateEnd }) => {
                    if (dateStart) {
                      feedFormInput('fromDate', dateStart)
                    }
                    if (dateEnd) {
                      feedFormInput('toDate', dateEnd)
                    }
                  }}
                  focusedInput={focusedInput}
                  onFocusChange={(input) => setFocusedInput(input)}
                  showClearDates
                />
              </div>
            </div>

            <div className="col-md-3">
              <Select
                value={filters.clientName ? filters.clientName : null}
                styles={filters.clientName ? customStyles : fieldHeight}
                options={clientNames}
                noOptionsMessage={() => 'No client name found'}
                placeholder="Client Name"
                onChange={(value) => {
                  feedFormInput('clientName', value)
                }}
              />
            </div>
          </div>

          <div className="row mt-3 pl-2 pr-2">
            <div className="col-md-3">
              <Select
                value={filters.projectId ? filters.projectId : null}
                styles={filters.projectId ? customStyles : fieldHeight}
                options={
                  props.projects && props.projects.data.length
                    ? props.projects.data.map((item) => {
                        return { label: item.name, value: item.id }
                      })
                    : []
                }
                className={clsx(classes.dropdown)}
                noOptionsMessage={() => ''}
                placeholder="Project"
                onChange={(value) => feedFormInput('projectId', value)}
              />
            </div>

            <div className="col-md-3">
              <Select
                value={filters.productId ? filters.productId : null}
                styles={filters.productId ? customStyles : fieldHeight}
                options={productList}
                className={clsx(classes.dropdown)}
                noOptionsMessage={() => 'No option found'}
                placeholder="Product Type"
                onChange={(value) => feedFormInput('productId', value)}
              />
            </div>

            <div className="col-md-3">
              <input
                type="number"
                name="booked_sq_ft"
                placeholder="Sqft"
                onChange={(e) => {
                  let value = e.target.value
                  feedFormInput('booked_sq_ft', value)
                }}
                value={filters.booked_sq_ft}
                className="Id_textfield"
              />
            </div>

            <div className="col-md-3">
              <input
                type="number"
                name="rate_per_sq_ft"
                placeholder="Rate Per Sqft"
                onChange={(e) => {
                  let value = e.target.value
                  feedFormInput('rate_per_sq_ft', value)
                }}
                value={filters.rate_per_sq_ft}
                className="Id_textfield"
              />
            </div>
          </div>

          <div className="row mt-3 pl-2 pr-2">
            <div className="col-md-3">
              <Select
                value={filters.agent_name ? filters.agent_name : null}
                styles={filters.agent_name ? customStyles : fieldHeight}
                options={agents}
                noOptionsMessage={() => 'No client name found'}
                placeholder="Agent Name"
                onChange={(value) => {
                  feedFormInput('agent_name', value)
                }}
              />
            </div>

            <div className="col-md-3">
              <Select
                value={filters.advisor_name ? filters.advisor_name : null}
                styles={filters.advisor_name ? customStyles : fieldHeight}
                options={advisors}
                noOptionsMessage={() => 'No advisor name found'}
                placeholder="Adivsor Name"
                onChange={(value) => {
                  feedFormInput('advisor_name', value)
                }}
              />
            </div>

            <div className="col-md-3">
              <input
                type="text"
                name="old_reference_no"
                placeholder="Old Reference No"
                onChange={(e) => {
                  let value = e.target.value
                  feedFormInput('old_reference_no', value)
                }}
                value={filters.old_reference_no}
                className="Id_textfield"
              />
            </div>

            <div className="col-md-3">
              <input
                type="text"
                name="unique_code"
                placeholder="New Reference No"
                onChange={(e) => {
                  let value = e.target.value
                  feedFormInput('unique_code', value)
                }}
                value={filters.unique_code}
                className="Id_textfield"
              />
            </div>
          </div>
        </div>
      </div>
      <hr />

      <div className="row mt-2 mb-2">
        <div className="col-md-4 d-flex justify-content-start">
          <CustomButton
            text="Search"
            buttonColor="#1173ef"
            className="client-search-button"
            textColor="#fff"
            width="80px"
            onClick={() => {
              searchFilter(bookingStatus, 1, 10)
              toggleShowMoreFilter(false)
            }}
          />
          <span className="ml-2">
            <CustomButton
              text="Clear"
              buttonColor="#fff"
              borderColor="#2e96f5"
              className="clear-button-client"
              textColor="#2e96f5"
              width="80px"
              onClick={() => {
                toggleShowMoreFilter(false)
                resetFilters()
              }}
            ></CustomButton>
          </span>
        </div>
      </div>
    </>
  )
}

export default MoreFilter
