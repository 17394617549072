/** @format */

import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, Input } from 'reactstrap'
import Button from '../../common/Button'
import config from '../../../config'
import axios from 'axios'
import Swal from 'sweetalert2'
import { connect } from 'react-redux'
import { getDiaryFeedbacks } from '../../../actions/diary'
import Chip from '../../common/Chip'
import CloseLostWarningModal from './CloseLostWarningModal'

const CloseAsDuplicateModal = (props) => {
  const [loader, setLoader] = useState(false)
  const [confirmationModal, setConfirmationModal] = useState(false)
  const [connectFeedback, setConnectFeedback] = useState({})
  const { modal, setModal, lead, leadType, callback, fetchDiaryFeedbacks, diaryFeedbacks } = props

  const closeLeadAsLost = (params, callback) => {
    let url = `${config.apiPath}/api/leads/project`
    if (params && params.leadType === 'BuyRent') url = `${config.apiPath}/api/leads`
    if (params && params.leadType === 'Wanted') url = `${config.apiPath}/api/wanted`
    axios({
      method: 'patch',
      url: url,
      params: {
        id:
          params && params.leadType === 'Wanted'
            ? params && params.leadId
            : [params && params.leadId],
      },
      data: {
        [params && params.leadType === 'Wanted' ? 'reason' : 'reasons']:
          params && params.reason ? params.reason : params.feedbackTag,
        cancelAllTask: params && params.cancelAllTask,
        feedbackId: params && params.feedbackId,
        feedbackTag: params && params.feedbackTag,
      },
    }).then((res) => {
      callback()
      Swal.fire({
        type: 'success',
        icon: 'success',
        title: 'Success',
        text: 'Lead has been marked as Closed Lost',
      })
    })
  }

  const closeAsLostFun = () => {
    if (connectFeedback.feedbackId) {
      closeLeadAsLost(connectFeedback, callback)
    } else {
      Swal.fire({
        icon: 'warning',
        text: 'Feedback is missing',
      })
    }
  }

  const closeConfirmationModal = () => {
    setConfirmationModal(!confirmationModal)
  }

  useEffect(() => {
    fetchDiaryFeedbacks({
      section: 'Reject',
      leadType: 'Project',
      taskType: 'Connect',
      actionType: 'Connect',
    })
  }, [])

  return (
    <>
      <Modal isOpen={modal} toggle={setModal} size="lg" backdrop={loader ? 'static' : true}>
        <ModalBody className={'px-0'}>
          <div className="close-icon-container">
            <i
              className="mt-1 fal fa-times-circle close-icon"
              onClick={() => !loader && setModal(false)}
            ></i>
          </div>
          <h4 className={'px-3'}>Close Lead as Lost</h4>
          <div className={'col-12 mt-3'}>
            <Input
              rows={4}
              type={'textarea'}
              onChange={(e) =>
                setConnectFeedback({
                  ...connectFeedback,
                  reason: e.target.value,
                })
              }
              value={connectFeedback.comment}
              name={'response'}
              placeholder={'Comment'}
            />
          </div>

          <div className={'col-12 mt-4'}>
            <h6 className={'px-1 mb-3'}>Reject</h6>
            {diaryFeedbacks &&
              diaryFeedbacks.Reject &&
              diaryFeedbacks.Reject[0] &&
              diaryFeedbacks.Reject[0].tags.map((tag, index) => (
                <Chip
                  key={index}
                  className="space-between mb-3 ml-3"
                  padding={'6px 15px'}
                  cursor={'pointer'}
                  fontSize={12}
                  value={tag}
                  onClick={() => {
                    setConnectFeedback({
                      ...connectFeedback,
                      colorCode:
                        diaryFeedbacks &&
                        diaryFeedbacks.Reject &&
                        diaryFeedbacks.Reject[0].colorCode,
                      feedbackTag: tag,
                      cancelAllTask: true,
                      leadType: leadType,
                      leadId: lead.id,
                      feedbackId:
                        diaryFeedbacks && diaryFeedbacks.Reject && diaryFeedbacks.Reject[0].id,
                    })
                  }}
                  chipColor={
                    diaryFeedbacks &&
                    diaryFeedbacks.Reject &&
                    diaryFeedbacks.Reject[0].section === 'Actions'
                      ? '#026ff2'
                      : connectFeedback && tag === connectFeedback.feedbackTag
                      ? connectFeedback.colorCode
                      : 'white'
                  }
                  textColor={
                    (diaryFeedbacks &&
                      diaryFeedbacks.Reject &&
                      diaryFeedbacks.Reject[0].section === 'Actions') ||
                    (connectFeedback && tag === connectFeedback.feedbackTag)
                      ? 'white'
                      : 'black'
                  }
                  text={tag}
                  border={`2px solid ${
                    diaryFeedbacks && diaryFeedbacks.Reject && diaryFeedbacks.Reject[0].colorCode
                  }`}
                  borderRadius={20}
                />
              ))}
          </div>

          <div className={'d-flex justify-content-center mt-2 pt-4 border-top'}>
            <Button
              text={'OK'}
              textColor={'white'}
              buttonColor="#026ff2"
              className={'px-5 rounded-pill'}
              onClick={() => {
                setConfirmationModal(!confirmationModal)
              }}
            />
          </div>
        </ModalBody>
      </Modal>

      <CloseLostWarningModal
        confirmationModal={confirmationModal}
        toggleConfirmationModal={closeConfirmationModal}
        closeAsLostFun={closeAsLostFun}
      />
    </>
  )
}

export const mapDispatchToProps = (dispatch) => {
  return {
    fetchDiaryFeedbacks: (params) => dispatch(getDiaryFeedbacks(params)),
  }
}

export const mapStateToProps = (state) => {
  return {
    diaryFeedbacks: state.Diary.diaryFeedbacks,
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CloseAsDuplicateModal)
