import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { leadRejectReasons } from '../../../utils/constant_data'
import { showErrorMessage } from '../../../utils/commonFormat'
import { ToastContainer } from 'react-toastify'
import React, { useState } from 'react'
import axios from 'axios'
import config from '../../../config'
import ClosedLostConfirm from '../ClosedLostConfirm'
import './ClosedLost.scss'

const CloseLostFeedback = ({
    callback,
    leadId,
    closeLostToggle,
    closeLostToggleState,
    setCloseLostToggleState,
    fetchSingleLeadDetails,
  }) => {
  const [loader, setLoader] = useState(false)
  const [reason, setReason] = useState('')
  const [closeLostConfirmState, setCloseLostConfirmState] = useState(false)

  const lowercasedFilter = reason.toLowerCase()
  const filteredData = leadRejectReasons.filter((item) => {
    return Object.keys(item).some((key) => item[key].toLowerCase().includes(lowercasedFilter))
  })

  const closedLost = () => {
    setLoader(true)
    setCloseLostConfirmState(false)
    axios({
      method: 'patch',
      url: `${config.apiPath}/api/leads/project`,
      params: { id: [leadId] },
      data: { reasons: reason },
    }).then((res) => {
      setLoader(false)
      setCloseLostToggleState(false)
      callback()
      fetchSingleLeadDetails && fetchSingleLeadDetails(leadId)
    })
  }


  return <>
    <Modal
        isOpen={closeLostToggleState}
        toggle={() => {
          if(!loader)
            setCloseLostToggleState(false)
        }}
        size="lg"
        className="feedback-popup-width close-lost-modal"
        contentClassName="general-popup-normal-size feedback-width"
      >
      <ModalHeader>Reject with Reason

        <div className="close-icon-container">
          <i
            className="fal fa-times-circle close-icon"
            onClick={() => {
              if(!loader)
                setCloseLostToggleState(false)
            }}
          ></i>
        </div>
      </ModalHeader>
        <ModalBody>
          <div className="feedback-container">
            <div>
              <textarea
                rows="5"
                onChange={(e) => setReason(e.target.value)}
                value={reason}
                placeholder="Enter reason of rejection "
              />
            </div>
            <div className="feedback-area">
              {filteredData &&
              filteredData.map((item, i) => (
                <div key={i}
                     className="space-between"
                     style={{ borderColor: `${item.colorCode}` }}
                     id={item.name}
                     value={item.name}
                     onClick={() => setReason(item.name)}
                >
                  {item.name}
                </div>
              ))}
            </div>
            <div className="closed-lost-button-container">
              <button
                type="button"
                className="closed-lost-button-success"
                onClick={() => {
                  if (!reason) {
                    showErrorMessage('Please select reason')
                    return
                  }
                  if(!loader)
                    setCloseLostConfirmState(true)
                }}
              >
                {loader ? <i className={`fa fa-circle-o-notch fa-spin`}></i> : 'Reject'}
              </button>
            </div>

            <ToastContainer
              position="bottom-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnVisibilityChange
              draggable
              pauseOnHover
            />
          </div>
        </ModalBody>
      </Modal>

    <ClosedLostConfirm
      closedLost={() => closedLost()}
      closeLostToggleState={closeLostConfirmState}
      setCloseLostToggleState={(toggle) => setCloseLostConfirmState(toggle)}
    />
    </>
}

export default CloseLostFeedback
