/** @format */

import axios from 'axios'
import config from '../config'

export const FETCH_PROPSURE = 'FETCH_PROPSURE'
export const FETCH_PROPSURE_LOADER = 'FETCH_PROPSURE_LOADER'
export const FETCH_PROPSURE_SUCCESS = 'FETCH_PROPSURE_SUCCESS'
export const FETCH_PROPSURE_FAILURE = 'FETCH_PROPSURE_FAILURE'
export const FETCH_PROPSURE_FILTER = 'FETCH_PROPSURE_FILTER'
export const FETCH_PROPSURE_FILTER_LOADER = 'FETCH_PROPSURE_FILTER_LOADER'
export const FETCH_PROPSURE_FILTER_SUCCESS = 'FETCH_PROPSURE_FILTER_SUCCESS'
export const FETCH_FILTER_FAILURE = 'FETCH_FILTER_FAILURE'

export const fetchPropsure = (req) => {
  const urlParams = new URLSearchParams(window.location.search)
  const page = urlParams.get('page')
  var endpoint =
    `${config.apiPath}/api/inventory/propsure/properties?pageSize=` + config.pageSize + '&page=' + page
  if (req && req.offset && req.pageSize) {
    endpoint =
      `${config.apiPath}/api/inventory/propsure/properties?pageSize=` + config.pageSize + '&page=' + page
  } else if (req && req.all === true) {
    endpoint = `${config.apiPath}/api/inventory/propsure/properties`
  }
  return (dispatch) => {
    dispatch({ type: FETCH_PROPSURE_LOADER, data: true })
    axios
      .get(endpoint)
      .then(function (response) {
        dispatch({ type: FETCH_PROPSURE_SUCCESS, data: false })

        return dispatch({ type: FETCH_PROPSURE, data: response.data })
      })
      .catch(function (xhr, status, err) {
        return dispatch({ type: FETCH_PROPSURE_FAILURE, data: xhr })
      })
  }
}

export const fetchPropsureFiltersData = (url, offset) => {
  const urlParams = new URLSearchParams(window.location.search)
  const page = urlParams.get('page')
  return (dispatch) => {
    dispatch({ type: FETCH_PROPSURE_FILTER_LOADER, data: true })
    axios
      .get(`${url}&page=${page}&pageSize=${config.pageSize}`)
      .then(function (response) {
        dispatch({ type: FETCH_PROPSURE_FILTER_SUCCESS, data: false })
        return dispatch({ type: FETCH_PROPSURE_FILTER, data: response.data })
      })
      .catch(function (xhr, status, err) {
        return dispatch({ type: FETCH_FILTER_FAILURE, data: xhr })
      })
  }
}
