/** @format */

import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import Select from "react-select";
import Load from "../../../components/Loader";
import { capitalize_Words } from "../../../utils/commonFormat";
import "./style.scss";
import Button from "../../../components/common/Button";

// import { bankList } from "../../../utils/constant_data";

const ClientBankAccount = ({
  openManageAccount,
  onManageAccount,
  loader,
  headerType,
  data,
  AddNewBankAccountDetail,
  onAddNewBankAccount,
  onSelectBank,
  SelectedBank,
  AccountTitle,
  onAccountTitle,
  onIbanChange,
  onAccountNoChange,
  AccountNo,
  onBranchNameChange,
  BranchName,
  IBAN,
  BankAccounts,
  onEditBankAccount,
  onDeleteBankAccount,
  onUpdateBankAccount,
  onCreateBankAccount,
  BankAccountEditMode,
  SelectedRecordId,
  validationCheck,
  checkIbanTrueOrFalse,
  bankNamesOpstions,
  loadingOnsubmit,
  addBankAccountFrom,
  onPressCancelBtn,
}) => {
  const className = "col-md-6";

  let contentClass =
    !AddNewBankAccountDetail && openManageAccount
      ? "client-Bank-account-modal-width"
      : " ";
  return (
    <>
      <>
        <div
          className={`${
            !AddNewBankAccountDetail && openManageAccount ? "createClientModal " : " createClientModal "
          }`}
        >
          <div
            className={`${
              !AddNewBankAccountDetail && openManageAccount
                ? "BankAccountAndNextOfkin-form"
                : validationCheck === true
                ? "client-add-bank-account-formError"
                : "client-add-bank-account-form1"
            }`}
          >
            <table
              className="ClientBankAccountModal"
              id="table"
              style={{ width: "100%" }}
            >
              <thead>
                {!AddNewBankAccountDetail && openManageAccount && (
                  <tr>
                    <th style={{ minWidth: 140 }}>Account Title</th>
                    <th style={{ minWidth: 100 }}>Bank</th>
                    <th style={{ minWidth: 200 }}>IBAN </th>
                    <th style={{ minWidth: 140 }}>ACCOUNT NO</th>
                    <th style={{ minWidth: 120 }}>BRANCH</th>
                    <th style={{ minWidth: 80 }}>Action</th>
                  </tr>
                )}
              </thead>
              {loader ? (
                <Load title="Users" />
              ) : //  agents && agents && agents.length === 0
              0 == 2 ? (
                <div
                  style={{ marginTop: "60px", marginBottom: 50 }}
                  className="text-center"
                >
                  <h3 className="dimgray">No Users Found</h3>
                </div>
              ) : (
                <tbody>
                  {!AddNewBankAccountDetail &&
                  openManageAccount &&
                  BankAccounts &&
                  BankAccounts.length > 0
                    ? BankAccounts.map((item) => (
                        <tr>
                          <td
                            className="org-td max-table-width"
                            style={{ minWidth: 140 }}
                          >
                            {item && item.id
                              ? capitalize_Words(item.accountTitle)
                              : "--"}
                          </td>

                          <td
                            className="org-td max-table-width"
                            style={{ minWidth: 100 }}
                          >
                            {item.bankName}
                          </td>

                          <td
                            className="org-td max-table-width"
                            style={{ minWidth: 200 }}
                          >
                            {item.IBAN}
                          </td>
                          <td
                            className="org-td max-table-width"
                            style={{ minWidth: 140 }}
                          >
                            {item.accountNumber}
                          </td>
                          <td
                            className="org-td max-table-width"
                            style={{ minWidth: 120 }}
                          >
                            {item.branch}
                          </td>

                          <td
                            className="org-td max-table-width"
                            style={{ minWidth: 80, maxWidth: 80 }}
                            className="newClientThreeDotButton"
                          >
                            <button className={`btn `} data-toggle="dropdown">
                              <span className="far fa-ellipsis-v"></span>
                            </button>

                            <div class="dropdown-menu">
                              <button
                                class={`dropdown-item`}
                                onClick={() => {
                                  onEditBankAccount(item, true);
                                }}
                              >
                                Edit
                              </button>

                              <button
                                class={`dropdown-item`}
                                onClick={() => {
                                  onDeleteBankAccount(item);
                                }}
                              >
                                Delete
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))
                    : !AddNewBankAccountDetail && (
                        <div className="col-12">
                          <div className="text-center pt-5 mt-5 font-weight-bold">
                            No Data Found
                          </div>
                        </div>
                      )}

                  {AddNewBankAccountDetail && openManageAccount && (
                    <div className="addBankAccountForm mx-5">
                      <div className="row">
                        <h4 className="col-6">
                          {BankAccountEditMode
                            ? "Edit Bank Account"
                            : AddNewBankAccountDetail
                            ? "Add Bank Account"
                            : " Bank Accounts"}
                        </h4>
                      </div>

                      <div className={"basicInfoContainer row "}>
                        <div className={className}>
                          <label className="labelWidth mt-2">
                            Account Title<span class="text-danger"> *</span>
                          </label>

                          <div className="filterInputWrap">
                            <input
                              className={
                                validationCheck === true && AccountTitle === ""
                                  ? `form-control  invalid`
                                  : ` form-control`
                              }
                              type="string"
                              placeholder={"Enter Account Title"}
                              value={AccountTitle}
                              onChange={(e) => {
                                onAccountTitle(e.target.value);
                              }}
                              required
                            />
                            {validationCheck === true &&
                              AccountTitle === "" && (
                                <p className="requiredCheck">Required</p>
                              )}
                          </div>
                        </div>

                        <div className={className}>
                          <label className="labelWidth mt-2">
                            Bank <span class="text-danger"> *</span>
                          </label>

                          <div className="filterInputWrap">
                            <Select
                              className={
                                validationCheck === true &&
                                SelectedBank === null
                                  ? ` invaliddropdown w-100`
                                  : ` w-100`
                              }
                              placeholder={"Select Bank"}
                              options={bankNamesOpstions}
                              value={SelectedBank}
                              onChange={(value) => onSelectBank(value)}
                              required
                            />

                            {validationCheck === true &&
                              SelectedBank === null && (
                                <p className="requiredCheck">Required</p>
                              )}
                          </div>
                        </div>
                      </div>

                      <div className="basicInfoContainer d-flex">
                        <div style={{ width: "48%" }}>
                          <label className="mt-2">
                            IBAN
                            {AccountNo === "" && (
                              <span class="text-danger"> *</span>
                            )}
                          </label>

                          <div className="filterInputWrap">
                            <input
                              className={
                                // validationCheck === true  && checkIbanTrueOrFalse === false && IBAN.length !== 0
                                (validationCheck === true &&
                                  checkIbanTrueOrFalse === false &&
                                  (AccountNo === "" || AccountNo === null) &&
                                  (IBAN.length !== 0 || IBAN.length === 0)) ||
                                (validationCheck === true &&
                                  checkIbanTrueOrFalse === false &&
                                  IBAN.length !== 0)
                                  ? `form-control  invalid`
                                  : ` form-control`
                              }
                              type="string"
                              placeholder={"Enter IBAN"}
                              value={IBAN}
                              onChange={(e) => {
                                onIbanChange(e.target.value);
                              }}
                              required
                            />
                            {validationCheck === true &&
                            checkIbanTrueOrFalse === false &&
                            IBAN.length !== 0 ? (
                              <p className="requiredCheck">Invalid IBAN</p>
                            ) : (
                              validationCheck === true &&
                              (AccountNo === "" || AccountNo === null) &&
                              IBAN === "" && (
                                <p className="requiredCheck">Required</p>
                              )
                            )}
                          </div>
                        </div>

                        <div className="m-auto orClass pt-4">OR</div>

                        <div style={{ width: "48%" }}>
                          <label className=" mt-2">
                            Account No
                            {/* <span class="text-danger"> *</span> */}
                          </label>

                          <div className="filterInputWrap">
                            <input
                              className={
                                ` form-control`
                                // validationCheck === true && AccountNo === "" &&  IBAN.length === 0
                                // ? `form-control  invalid`
                                // : ` form-control`
                              }
                              type="number"
                              placeholder={"Enter Account No"}
                              value={AccountNo}
                              onChange={(e) => {
                                onAccountNoChange(e.target.value);
                              }}
                            />
                            {/* {(validationCheck === true &&  AccountNo === "" &&  IBAN.length === 0 ) &&
                                (
                                <p className="requiredCheck">Required</p>
                              )
                              } */}
                          </div>
                        </div>
                      </div>

                      <div className="basicInfoContainer row">
                        <div className={className}>
                          <label className=" m-auto">Branch</label>
                          <input
                            className="form-control"
                            type="string"
                            placeholder={"Enter Branch Name"}
                            value={BranchName}
                            onChange={(e) => {
                              onBranchNameChange(e.target.value);
                            }}
                          />
                        </div>
                      </div>

                      <div className={`basicInfoContainer row my-4`}>
                        <div className="col-6 registerButtons">
                          <Button
                            text={"Cancel"}
                            textColor={"black"}
                            width={"90px"}
                            height={"30px"}
                            display={"inline-block"}
                            buttonColor="#fff"
                            className={"px-4 rounded-pill small"}
                            style={{ border: "1px solid #d3d3d3" }}
                            onClick={() => {
                              onAddNewBankAccount();
                              addBankAccountFrom === "BookingForm" &&
                                onPressCancelBtn();
                            }}
                          />
                          <Button
                            text={
                              BankAccountEditMode &&
                              BankAccountEditMode === true
                                ? "Update"
                                : "Save"
                            }
                            textColor={"white"}
                            width={"90px"}
                            height={"30px"}
                            loader={loadingOnsubmit}
                            display={"inline-block"}
                            buttonColor="#026ff2"
                            className={"px-4 rounded-pill ml-2 small"}
                            onClick={() => {
                              BankAccountEditMode && data && data !== null
                                ? onUpdateBankAccount(data)
                                : addBankAccountFrom === "BookingForm"
                                ? onCreateBankAccount()
                                : onCreateBankAccount(data.id);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </tbody>
              )}
            </table>
          </div>
        </div>
      </>
    </>
  );
};

export default ClientBankAccount;
