/** @format */
import { PropertyWorkFlowTile } from './PropertyWorkFlowTile'
import { Button, Modal, ModalBody } from 'reactstrap'
import React, { createRef, useState, useEffect } from 'react'
import { Feedback } from './Feedback'
import { areaManagerCheckList, constants } from '../../utils/constant_data'
import axios from 'axios'
import Loader from '../Loader'
import config from '../../config'
import { capitalize_Words } from '../../utils/commonFormat'
import moment from 'moment'
import RWR from '../../components/Modals/ConnectFeedback/RWR'
import { DiaryItem } from '../CardInfo/DiaryItem'
import { isREA, sortListByDateValue } from '../../utils/helper'
import SlotModal from '../SlotManagement/SlotModal'
import { capitalizeWordsWithoutUnderscore, showSuccessMessage } from '../../utils/commonFormat'
import ConnectFeedback from '../Modals/ConnectFeedback'
import { getLeadType } from '../../utils/helper'
import { FEEDBACK_ACTIONS } from '../../utils/constant_data'
import { fetchSingleLead } from '../../actions/leads'
import { connect } from 'react-redux'
import { showToastMsg } from '../../utils/helper'
import { withRouter } from 'react-router-dom'
import { isAira } from '../common/LeadView/Wanted/WantedUtilHelper'
import { element } from 'prop-types'
const ViewingScreen = ({
  property,
  user,
  singleLeadRecord,
  setTempSelectedProperties,
  tempSelectedProperties,
  selectedViewing,
  viewingModal,
  setViewingModal,
  userId,
  checkForRead,
  fetchSingleLead,
  currentUser,
}) => {
  const [selectedDiary, setSelectedDiary] = useState(null)
  const [showFeedback, showFeedbackToggle] = useState(false)
  const [userFeedback, setUserFeedback] = useState('')
  const [selectedCheckList, setSelectedCheckList] = useState([])
  const [loading, setLoading] = useState(false)
  const [slotModal, setSlotModal] = useState(false)
  const [connectFeedback, setConnectFeedback] = useState({})
  const [followUpModal, setFollowUpModal] = useState(false)
  const [connectFeedbackModal, setConnectFeedbackModal] = useState(false)
  const [dairyModal, setDairyModal] = useState(false)
  const [diary, setDiary] = useState(null)
  const [rwrModal, setRwrModal] = useState(false)
  const [sendData, setSendData] = useState(null)
  const [actionType, setActionType] = useState('Done')
  const createViewing = (bookedTime) => {
    setLoading(true)
    let customer =
      (singleLeadRecord.customer &&
        singleLeadRecord.customer.customerName &&
        capitalize_Words(singleLeadRecord.customer.customerName)) ||
      ''
    let areaName = (property.area && property.area.name && property.area.name) || ''
    let customerId = singleLeadRecord.customer && singleLeadRecord.customer.id
    let date = bookedTime ? moment(bookedTime.date).format('YYYY-MM-DDTHH:mm:ssZ') : ''
    let start = bookedTime ? moment(bookedTime.startTime).format('YYYY-MM-DDTHH:mm:ssZ') : ''
    let end = bookedTime ? moment(bookedTime.endTime).format('YYYY-MM-DDTHH:mm:ssZ') : ''
    let body = {
      date: date,
      time: start,
      diaryTime: start,
      start: start,
      end: end,
      propertyId: property.shortlisted_id,
      leadId: singleLeadRecord.id,
      subject: 'Viewing with ' + customer + ' at ' + areaName,
      taskCategory: 'leadTask',
      customerId: customerId,
      slots:
        bookedTime && bookedTime.slots
          ? bookedTime.slots.map((slot) => {
              return slot.id
            })
          : '',
      userId: userId ? userId : null,
    }
    axios
      .post(`${config.apiPath}/api/leads/viewing`, body)
      .then((res) => {
        axios.get(`${config.apiPath}/api/leads/${singleLeadRecord.id}/shortlist`).then((res) => {
          let temp = res.data.rows.filter((d) => d.id === property.shortlisted_id)
          property.diaries = temp[0].diaries
          setLoading(false)
        })
      })
      .catch((error) => {
        console.log(error)
      })
  }
  const setNextFlow = (connectFeedback) => {
    if (Object.keys(connectFeedback).length) {
      if (connectFeedback.section === 'Follow up') {
        setSendData({
          id: diary.id,
          comments: connectFeedback.otherTasksToUpdate[0]
            ? connectFeedback.otherTasksToUpdate[0].comment
            : connectFeedback.comment,
          response: connectFeedback.comment,
          feedbackId: connectFeedback.id,
          feedbackTag: connectFeedback.tag,
          status: 'completed',
        })
      } else if (connectFeedback.section === 'Cancel Viewing') {
        setSendData({
          id: diary.id,
          comments: connectFeedback.otherTasksToUpdate[0]
            ? connectFeedback.otherTasksToUpdate[0].comment
            : connectFeedback.comment,
          response: connectFeedback.comment,
          feedbackId: connectFeedback.id,
          feedbackTag: connectFeedback.tag,
          status: 'cancelled',
        })
      }
      if (['Follow up', 'Cancel Viewing'].indexOf(connectFeedback.section) > -1)
        setFollowUpModal(true)
      else if (connectFeedback.section === 'Reject') {
        setSendData({
          id: diary.id,
          comments: connectFeedback.otherTasksToUpdate[0]
            ? connectFeedback.otherTasksToUpdate[0].comment
            : connectFeedback.comment,
          response: connectFeedback.comment,
          feedbackId: connectFeedback.id,
          feedbackTag: connectFeedback.tag,
          status: 'cancelled',
        })
        setRwrModal(true)
      }
    }
  }
  const saveOrUpdateDiaryTask = (taskData) => {
    let promise
    if ('id' in taskData) {
      promise = axios.patch(`${config.apiPath}/api/diary/update?id=${taskData.id}`, taskData)
    } else {
      promise = axios.post(`${config.apiPath}/api/leads/task`, taskData)
    }
    promise.then((res) => {
      setLoading(true)
      axios.get(`${config.apiPath}/api/leads/${singleLeadRecord.id}/shortlist`).then((res) => {
        let temp = res.data.rows.filter((d) => d.id === property.shortlisted_id)
        property.diaries = temp[0].diaries
        property.diaries.forEach((element) => {
          // element.status
        })
        if (taskData.status === 'completed') {
          showToastMsg('Viewing done successfully', 'success')
        }
        if (taskData.status === 'cancelled') {
          showToastMsg('Viewing cancel successfully', 'success')
        }
        setLoading(false)
      })
      setDiary(null)
    })
  }

  const canBook = () => {
    if (isAira()) return true
    return property.diaries.filter(
      (d) => ['completed', 'cancelled'].indexOf(d.status) === -1 && d.userId === user.id
    ).length > 0
      ? false
      : true
  }

  return (
    <Modal
      size="lg"
      isOpen={viewingModal}
      toggle={(toggle) => setViewingModal(toggle)}
      style={{ maxWidth: 890, width: '100%' }}
      contentClassName="viewing-modal"
    >
      <ModalBody className="">
        <h4 className="float-left">Viewings </h4>
        <div className="close-icon-container float-right">
          <i className="fal fa-times-circle close-icon" onClick={() => setViewingModal(false)}></i>
        </div>
        <div className="float-left w-100">
          <div className="list-view-cus w-100 px-2">
            <PropertyWorkFlowTile property={property} />
          </div>
          {/* {property && property.diaries.length === 0 ? (
            <div className="loader-spinner ">
              {property && property.diaries.length === 0 && <Loader title="Fetching Data" />}
            </div>
          ) : ( */}
          <div className="list-view-cus w-100">
            <div
              className={'w-100 float-left overflow-auto mt-2 mb-4 px-2'}
              style={{ maxHeight: 450, minHeight: 300 }}
            >
              {property &&
                property.diaries.map(
                  (diary, index) =>
                    user &&
                    (diary.userId === user.id || (userId && userId === diary.userId)) &&
                    diary.status !== 'cancelled' && (
                      <DiaryItem
                        key={index}
                        index={index}
                        className={`mt-2`}
                        dropdown={diary.status !== 'completed'}
                        item={diary}
                        lead={singleLeadRecord}
                        cardType={diary.taskType}
                        user={currentUser}
                        cancelViewing={() => {
                          setActionType('Cancel')
                          setConnectFeedbackModal(true)
                          setDiary(diary)
                        }}
                        doneViewing={() => {
                          setActionType('Done')
                          setDiary(diary)
                          setConnectFeedbackModal(true)
                        }}
                        checkForRead={checkForRead}
                      />
                    )
                )}
            </div>
            <div className="row d-flex justify-content-center text-center mt-2">
              <Button
                color="primary"
                className={'book-view round-btn-outline px-4 py-2'}
                onClick={() => {
                  if (canBook()) setSlotModal(true)
                }}
                disabled={
                  (isREA(currentUser) &&
                    singleLeadRecord.assigned_to_armsuser_id != currentUser.id) ||
                  !canBook()
                }
              >
                {loading && !showFeedback ? (
                  <span className="fa fa-circle-o-notch fa-spin"></span>
                ) : (
                  'Book viewing'
                )}
              </Button>
            </div>
            <SlotModal
              userId={userId ? userId : null}
              slotModal={slotModal}
              setSlotModal={(toggle) => setSlotModal(toggle)}
              title={
                'Select slot for Viewing' +
                (userId && singleLeadRecord.armsuser
                  ? ` for ${singleLeadRecord.armsuser.firstName} ${singleLeadRecord.armsuser.lastName} `
                  : '')
              }
              callBack={(bookedTime) => {
                setSlotModal(false)
                createViewing(bookedTime)
              }}
              taskType={'viewing'}
            />
            <SlotModal
              userId={userId ? userId : null}
              slotModal={followUpModal}
              setSlotModal={(toggle) => setFollowUpModal(toggle)}
              title={'Select slot Follow-up task'}
              callBack={(bookedTime) => {
                setFollowUpModal(false)
                setConnectFeedbackModal(false)
                if (sendData) {
                  saveOrUpdateDiaryTask(sendData)
                }
                saveOrUpdateDiaryTask(
                  {
                    subject: `Follow Up Scheduled`,
                    notes: sendData.response,
                    taskCategory: 'leadTask',
                    [getLeadType(diary) === 'Project'
                      ? 'leadId'
                      : getLeadType(diary) === 'BuyRent'
                      ? 'armsLeadId'
                      : 'wantedId']:
                      diary && (diary.armsLeadId || diary.armsProjectLeadId || diary.wantedId),
                    start: bookedTime
                      ? moment(bookedTime.startTime).format('YYYY-MM-DDTHH:mm:ssZ')
                      : '',
                    diaryTime: bookedTime
                      ? moment(bookedTime.startTime).format('YYYY-MM-DDTHH:mm:ssZ')
                      : '',
                    date: bookedTime ? moment(bookedTime.date).format('YYYY-MM-DDTHH:mm:ssZ') : '',
                    end: bookedTime
                      ? moment(bookedTime.endTime).format('YYYY-MM-DDTHH:mm:ssZ')
                      : '',
                    slots:
                      bookedTime && bookedTime.slots
                        ? bookedTime.slots.map((slot) => {
                            return slot.id
                          })
                        : '',
                    taskType: 'follow_up',
                    reasonId: sendData.feedbackId,
                    reasonTag: sendData.feedbackTag,
                  },
                  true
                )
              }}
              taskType={'follow_up'}
            />
            {rwrModal && (
              <RWR
                connectFeedback={connectFeedback}
                rwrModal={rwrModal}
                lead={singleLeadRecord}
                singleLeadRecord={singleLeadRecord}
                leadType={'BuyRent'}
                setRwrModal={(toggle) => setRwrModal(toggle)}
                callback={() => {
                  if (sendData) {
                    saveOrUpdateDiaryTask(sendData)
                    setViewingModal(false)
                    // fetchSingleLead(singleLeadRecord.id)
                  }
                  setConnectFeedbackModal(false)
                }}
              />
            )}
            {connectFeedbackModal && (
              <ConnectFeedback
                canClose={true}
                actionType={actionType}
                taskType={'viewing'}
                taskStatus={'completed'}
                leadType={'BuyRent'}
                connectFeedback={connectFeedback}
                setNextFlow={(feedback) => setNextFlow(feedback)}
                setNextAction={(action, feedback, selectedProperty) => {
                  saveOrUpdateDiaryTask({
                    id: diary.id,
                    comments:
                      feedback &&
                      feedback.otherTasksToUpdate &&
                      feedback.otherTasksToUpdate[0] &&
                      feedback.otherTasksToUpdate[0].comment
                        ? feedback.otherTasksToUpdate[0].comment
                        : feedback.comment,
                    response:
                      feedback &&
                      feedback.otherTasksToUpdate &&
                      feedback.otherTasksToUpdate[0] &&
                      feedback.otherTasksToUpdate[0].comment
                        ? feedback.otherTasksToUpdate[0].comment
                        : feedback.comment,
                    feedbackId: feedback.id,
                    feedbackTag: feedback.tag,
                    status: 'completed',
                  })
                  setConnectFeedbackModal(false)
                  setViewingModal(false)
                }}
                screen={'viewing'}
                connectFeedbackModal={connectFeedbackModal}
                setConnectFeedbackModal={(toggle) => setConnectFeedbackModal(toggle)}
                viewingProperties={property ? [property] : []}
                propertyId={property && property.id}
              />
            )}
          </div>
          {/* )} */}
        </div>
      </ModalBody>
    </Modal>
  )
}
export const mapStateToProps = (state) => {
  return {
    currentUser: state.user.userInfo,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    fetchSingleLead: (id) => dispatch(fetchSingleLead(id)),
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewingScreen))
