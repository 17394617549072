/** @format */

import React from 'react'
import Modal from 'react-bootstrap/Modal'
import ModalBody from 'react-bootstrap/ModalBody'

import times from '../../images/close.png'

import './modalsStyle.scss'

const CustomModal = (props) => {
  const { children, modalOpenState, headerTitle, modalWidth, toggleModal } = props

  return (
    <div>
      <Modal
        show={modalOpenState}
        dialogClassName={modalWidth}
        onHide={() => toggleModal(false)}
        className="customModal"
      >
        <ModalBody className="modal-body">
          <div className="timeline-body">
            <div class="table-header-main">
              <div className="row">
                <div className="col-md-6">{headerTitle}</div>
                <div className="col-md-6 text-right">
                  <img
                    src={times}
                    className="image-close"
                    onClick={() => {
                      toggleModal(false)
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="children pt-0">{children}</div>
          </div>
        </ModalBody>{' '}
      </Modal>
    </div>
  )
}

export default CustomModal
