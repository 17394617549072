/** @format */

import axios from 'axios'
import config from '../config'
import { shouldShowAllLeads } from '../utils/helper'
import { mapFiltersToQuery } from './../components/common/LeadView/Wanted/WantedUtilHelper'
import { FETCH_BUYRENT_DEALS, FETCH_BUYRENT_LEADS, FETCH_BUYRENT_REFERRED_LEADS } from './buyrentLeads'
axios.defaults.withCredentials = true

export const FETCH_WANTED_LEADS = 'FETCH_WANTED_LEADS'
export const FETCH_WANTED_QUALIFIED_LEADS = 'FETCH_WANTED_QUALIFIED_LEADS'
export const FETCH_WANTED_TEAM_LEADS = 'FETCH_WANTED_TEAM_LEADS'
export const SET_WANTED_FILTERS = 'SET_WANTED_FILTERS'
export const FETCH_CUTOMER_WANTED_LEADS = 'FETCH_CUTOMER_WANTED_LEADS'

export const getWantedLeads = (
  pagination = { currentPage: 1, pageSize: config.pageSize },
  customerId = null,
  query = ''
) => {
  return (dispatch, getState) => {
    let wantedState = { ...getState().WantedLeads }
    let moreFilters = wantedState.filters.filters
    let quickFilters = wantedState.filters.quickFilters
    let filters = mapFiltersToQuery(moreFilters, quickFilters)
    let showAllLeads = shouldShowAllLeads(getState().user)
    let allLeadParam = ''
    if (showAllLeads) {
      allLeadParam = `&showAllLeads=${showAllLeads}`
    }
    let redux_key = FETCH_WANTED_LEADS
    if(query.indexOf('pageType=qualifiedLeads') > -1)
      redux_key = FETCH_WANTED_QUALIFIED_LEADS
    else if(query.indexOf('pageType=teamLead') > -1)
      redux_key = FETCH_WANTED_TEAM_LEADS

    const { currentPage, pageSize } = pagination
    var url = `${config.apiPath}/api/wanted?page=${currentPage}&pageSize=${pageSize}`
    if (customerId) url += `&customerId=${customerId}`
    if (filters) url += `&${filters}`
    if (showAllLeads) url += allLeadParam
    if (query) {
      url += query
    }
    dispatch({ type: redux_key, data: [], loading: true })
    axios
      .get(url)
      .then(function (response) {
        dispatch({ type: redux_key, data: response.data, loading: false })
      })
      .catch(function (xhr, status, err) {
        return dispatch({ type: redux_key, data: [], loading: false })
      })
  }
}

export const getCustomerWantedLeads = (customerId = null) => {
  return (dispatch, getState) => {
    var url = `${config.apiPath}/api/wanted?page=1&pageSize=${config.pageSize}&customerId=${customerId}&customerLeads=true`
    dispatch({ type: FETCH_CUTOMER_WANTED_LEADS, data: [], loading: true })
    axios
      .get(url)
      .then(function (response) {
        dispatch({
          type: FETCH_CUTOMER_WANTED_LEADS,
          data:
            getState().Leads.excludeLead.type === 'Wanted'
              ? {
                  rows: response.data.rows.filter(
                    (dt) => dt.id !== getState().Leads.excludeLead.lead.id
                  ),
                  count: response.data.count,
                }
              : response.data,
          loading: false,
        })
      })
      .catch(function (xhr, status, err) {
        return dispatch({ type: FETCH_CUTOMER_WANTED_LEADS, data: [], loading: false })
      })
  }
}

export const destroyWantedLeadState = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_WANTED_LEADS, data: [], loading: false })
  }
}
export const destroyWantedQualifiedLeadState = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_WANTED_QUALIFIED_LEADS, data: [], loading: false })
  }
}
export const destroyWantedTeamLeadState = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_WANTED_TEAM_LEADS, data: [], loading: false })
  }
}

export const setWantedFilters = (filters, cb) => {
  return (dispatch) => {
    dispatch({ type: SET_WANTED_FILTERS, data: filters })
    cb && cb()
  }
}
