import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import config from "../../config";
import Pagination from "rc-pagination";
import moment from "moment";
import { connect } from "react-redux";
import { setTitle } from "../../actions/generalSetting";
import { CSVLink } from "react-csv";
import { DateRangePicker } from 'react-dates'

const AgentWaitingTime = (props) => {
  const csvLink = useRef();
  const { setTitle } = props;
  const [loader, setLoader] = useState(false);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [waitingtimeData, setWaitingtimeData] = useState([]);
  const [totalRows, setTotalRows] = useState(1);
  const [pagePermission, setPagePermission] = useState(true);
  const [startDate, setStartDate] = useState(moment(new Date()).subtract(10,'days'));
  const [endDate, setEndDate] = useState(moment(new Date()));
  const [focusedInputForIncoming, setFocusedInputForIncoming] = useState(null)
  const [csvData, setCsvData] = useState([]);

  const getData = async (startDate, endDate) => {
    setLoader(true);
    const formattedStartDate = moment(startDate).format('YYYY-MM-DD');
    const formattedEndDate = moment(endDate).format('YYYY-MM-DD');
    await axios
      .get(
        `${config.apiPath}/api/cdrreport/waitingTimeReport?startDate=${formattedStartDate}&endDate=${formattedEndDate}&isSupervisor=true`
      )
      .then((res) => {
        setWaitingtimeData(res.data);
        setTotalRows((res.data.length > 0 && res.data[0].total_rows) || 0);
      });
    setLoader(false);
  };

    const getCsvData = async () => {
     const formattedStartDate = moment(startDate).format('YYYY-MM-DD');
     const formattedEndDate = moment(endDate).format('YYYY-MM-DD');
    await axios
      .get(
        `${config.apiPath}/api/cdrreport/waitingTimeReport?startDate=${formattedStartDate}&endDate=${formattedEndDate}&isSupervisor=true`
      )
      .then((res) => {
        const allRebates =
          waitingtimeData.length > 0
            ? waitingtimeData.map((data) => ({
                Name: data.first_name
                  ? data.first_name + " " + data.last_name
                  : "",
                "Waiting Time": data.waiting_time
                  ? secondsToHms(data.waiting_time)
                  : "",
                "Total Talk Time": data.total_talk_time
                  ? secondsToHms(data.total_talk_time)
                  : "",
                "Total Time Spend": data.total_time_spend
                  ? secondsToHms(data.total_time_spend)
                  : "",
              }))
            : [];
        setCsvData(allRebates);
      })
      .catch((e) => console.log(e));
    csvLink.current.link.click();
  };

  useEffect(() => {
    setTitle({ name: "Agent Waiting Time" });
    let isTeamLead =
      localStorage.getItem("user_agency_mgmt") !== "" &&
      localStorage.getItem("user_agency_mgmt") !== null
        ? JSON.parse(localStorage.getItem("user_agency_mgmt")).data.isTeamLead
        : false;
    setPagePermission(isTeamLead);
    getData(startDate,endDate);
  }, [startDate, endDate]);

  return (
    <>
      {pagePermission ? (
        <div className="card mt-2">
          <div style={{ padding: "1%", height: "85vh" }}>
            <div className="ccmc-filters row">
              <div className="col-9">
                <div className="col-md-5">
                  <div className="input__main ">
                    <div className="clientDateContainer">
                      { <DateRangePicker
                        isOutsideRange={() => false}
                        startDate={startDate}
                        endDate={endDate}
                        startDateId="callCenter_date_input_start_for_incoming"
                        minimumNights={0}
                        endDateId="callCenter_date_input_end_for_incoming"
                        onDatesChange={({ startDate: dateStart, endDate: dateEnd }) => {
                          setStartDate(dateStart)
                          setEndDate(dateEnd)
                        }}
                        focusedInput={focusedInputForIncoming}
                      onFocusChange={(input) => setFocusedInputForIncoming(input)}
                      showClearDates
                      />
                      }
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-3 row justify-content-end ">
                <div className="col-md-6">
                  <div className="ccmc-clearFilter">
                    <button
                      className={`newFormSearch`}
                      onClick={() => getData(startDate,endDate)}
                    >
                      <span className={`fa fa-search`}></span>
                    </button>

                    {waitingtimeData.length > 0 && (
                      <div>
                        <button
                          onClick={getCsvData}
                          className="newFormSearch ml-1"
                        >
                          <span className={`fa fa-download`}></span>
                        </button>
                        <CSVLink
                          data={csvData}
                          filename="agent performances_inbound_and_outbound_Call_logs.csv"
                          className="hidden"
                          ref={csvLink}
                          target="_blank"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="reports-table">
              <table style={{ height: "80%" }}>
                <thead>
                  <tr>
                    <th style={{ minWidth: "200px" }}>Name</th>
                    <th style={{ minWidth: "200px" }}>Waiting Time</th>
                    <th style={{ minWidth: "200px" }}>Total Talk Time</th>
                    <th style={{ minWidth: "530px" }}>Total Time Spend</th>
                  </tr>
                </thead>
                <tbody>
                  {loader ? (
                    <tr>
                      <p
                        style={{ paddingLeft: "36vw", paddingTop: "10vh" }}
                        colSpan={10}
                        className={""}
                      >
                        Loading ...
                      </p>
                    </tr>
                  ) : (
                    <>
                      {waitingtimeData.length > 0 ? (
                        waitingtimeData.map((e) => {
                          return <TableTr data={e} />;
                        })
                      ) : (
                        <tr>
                          <p
                            style={{ paddingLeft: "36vw", paddingTop: "10vh" }}
                            colSpan={10}
                            className={""}
                          >
                            No Data Found
                          </p>
                        </tr>
                      )}
                    </>
                  )}
                </tbody>
                {waitingtimeData.length > 0 && !loader && (
                  <tfoot className="row mt-2 mb-1">
                    <tr className="col-md-12 d-flex flex-column justify-content-center">
                      <td colSpan={10}>
                        <div className="pagination justify-content-center">
                          <Pagination
                            className="ps-pagination"
                            hideOnSinglePage={true}
                            pageSize={perPage}
                            current={page}
                            showPrevNextJumpers={false}
                            onChange={(cPage, pSize) => {
                              setPage(cPage);
                            }}
                            total={totalRows}
                          />
                        </div>
                      </td>
                    </tr>
                  </tfoot>
                )}
              </table>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

const TableTr = ({ data }) => {
  return (
    <tr className="hover__for__btns hoverForTr">
      <td style={{ minWidth: "200px" }}>
        {data.first_name ? data.first_name + " " + data.last_name : ""}
      </td>
      <td style={{ minWidth: "200px" }}>
        {data.waiting_time ? secondsToHms(data.waiting_time) : ""}
      </td>
      <td style={{ minWidth: "200px" }}>
        {data.total_talk_time ? secondsToHms(data.total_talk_time) : ""}
      </td>
      <td style={{ minWidth: "530px" }}>
        {data.total_time_spend ? secondsToHms(data.total_time_spend) : ""}
      </td>
    </tr>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setTitle: (title) => dispatch(setTitle(title)),
});
const mapStateToProps = (state) => ({});

const secondsToHms = (d) => {
  d = Number(d);
  var h = Math.floor(d / 3600);
  var m = Math.floor((d % 3600) / 60);
  var s = Math.floor((d % 3600) % 60);

  var hDisplay = h > 0 ? h + (h == 1 ? "" : "") : "";
  var mDisplay = m > 0 ? m + (m == 1 ? "" : "") : "";
  var sDisplay = s > 0 ? s + (s == 1 ? "" : "") : "";
  if (hDisplay.length === 1) {
    hDisplay = "0" + hDisplay;
  } else if (hDisplay.length === 0) {
    hDisplay = "00";
  }

  if (mDisplay.length === 1) {
    mDisplay = "0" + mDisplay;
  } else if (mDisplay.length === 0) {
    mDisplay = "00";
  }
  if (sDisplay.length === 1) {
    sDisplay = "0" + sDisplay;
  } else if (sDisplay.length === 0) {
    sDisplay = "00";
  }

  return `${hDisplay}:${mDisplay}:${sDisplay}`;
};

export default connect(mapStateToProps, mapDispatchToProps)(AgentWaitingTime);
