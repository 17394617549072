import React, {useState, useEffect, useMemo} from "react";
import BarChart from "./BarChart";
import axios from "axios";
import config from "../config";
import moment from "moment";
import {DateRangePicker} from "react-dates";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import "./style.scss";
import {dateAddDays} from '../utils/setDate'
import AgentStatsComponent from "./reports/AgentStatsComponent";

const BarCharts = ({isOpenBarChartsModel, isOpenBarChartHandler}) => {
  const [focusedInputForIncoming, setFocusedInputForIncoming] = useState(null);
  const [focusedInputForOutgoing, setFocusedInputForOutgoing] = useState(null);
  const [startDateForIncoming, setStartDateForIncoming] = useState(moment());
  const [endDateForIncoming, setEndDateForIncoming] = useState(moment());
  const [startDateForOutgoing, setStartDateForOutgoing] = useState(moment());
  const [endDateForOutgoing, setEndDateForOutgoing] = useState(moment());
  const [dataForApi, setDataForApi] = useState({});
  const [filterInTitles, setFilterInTitles] = useState([
    "ANSWER",
    "NO ANSWER",
    "BUSY",
    "FAILED",
    "REJECTED",
    "AGENT MISCALL",
    "CUSTOMER MISCALL",
    "AGENT MICROPHONE PERMISSION",
  ]);
  const [filterInColor] = useState([
    "#90ee7e",
    "#d4526e",
    "#ffff00",
    "#f9a3a4",
    "#f48024",
    "#A5978B",
    "#2b908f",
    "#303030",
    "#303030",
    "#303030",
  ]);
  const [grapTitles, setGrapTile] = useState([]);
  const [grapValues, setGrapValues] = useState([]);
  const [grapColors, setGrapColors] = useState([]);

  const [grapTitlesForOutgoing, setGrapTileForOutgoing] = useState([]);
  const [grapValuesForOutgoing, setGrapValuesForOutgoing] = useState([]);
  const [grapColorsForOutgoing, setGrapColorsForOutgoing] = useState([]);

  const filterData = (data, type) => {
    let arrTitles = [];
    let arrValues = [];
    let arrColors = [];

    const check = (value, indexProp) => {
      Object.keys(data).filter((dataTwo) => {
        if (dataTwo === value) {
          arrTitles.push(dataTwo);
          arrValues.push(data[dataTwo]);
          arrColors.push(filterInColor[indexProp]);
        }
      });
    };

    filterInTitles.map((dataOne, indexProp) => {
      check(dataOne, indexProp);
    });

    if (type === 'outgoing') {
      setGrapTileForOutgoing(arrTitles)
      setGrapValuesForOutgoing(arrValues)
      setGrapColorsForOutgoing(arrColors)

    } else {
      setGrapTile(arrTitles)
      setGrapValues(arrValues)
      setGrapColors(arrColors)
    }

  };

  const dailyCallGrapApiHandler = (startDate, endDate, direction) => {
    axios
      .post(`${config.apiPath}/api/cdrreport/dailyCallGraph`, {
        startDate: startDate,
        endDate: endDate,
        direction: direction,
      })
      .then((res) => {
        res && res.data && res.data.dispositions && setFilterInTitles((filterInTitles) => (res.data.dispositions))
        res && res.data && res.data.graph && filterData(res.data.graph, direction);
      });
  };


  useEffect(() => {
    const currentDate = moment(new Date()).format("YYYY-MM-DD")
    isOpenBarChartsModel && dailyCallGrapApiHandler(currentDate, currentDate, 'incoming')
    isOpenBarChartsModel && dailyCallGrapApiHandler(currentDate, currentDate, 'outgoing')

  }, [isOpenBarChartsModel])


  return (
    <div>
      <Modal
        isOpen={isOpenBarChartsModel}
        toggle={isOpenBarChartHandler}
        size="xl"
        backdrop="static"
        /*style={{ maxWidth: "800px", width: "100%" }}
        contentClassName="campaign-modal-width"*/
      >
        <div className="createClientModal">
          <ModalHeader
            style={{
              background: "none !important",
              border: "none",
            }}
          >
            <div className="row ml-1 mr-3">
              <div className="heading-wrap pl-3 pt-2">
                <h5 style={{color: "#7d7e7f"}}>
                  {" "}
                  {/* {editMode ? "Edit Campaign" : "Add Campaign"}{" "} */}
                </h5>
              </div>

              <div className="close-icon-container">
                <i
                  className="fal fa-times-circle close-icon"
                  onClick={() => {
                    isOpenBarChartHandler();
                  }}
                ></i>
              </div>
            </div>
          </ModalHeader>
          <ModalBody>
            <div>
              <AgentStatsComponent
                armUserId={''}
                isSupervisor={false}
                currentArmUser={(armUserId) => {
                }}
                setStartDateAndEndDataHAndler={(fromDate, toDate) => {
                 /* setDate({
                      startDate: moment(fromDate).format("YYYY-MM-DD"),
                      endDate: moment(toDate).format("YYYY-MM-DD")
                    }
                  )*/
                }}
              />
            </div>
            <hr/>
            <div className="row">
              <div className={"col-6"}>
                <div
                  className={
                    "callCenterDateRange " +
                    (startDateForIncoming && endDateForIncoming ? "selectedDateRange" : "")
                  }
                >
                  <DateRangePicker
                    isOutsideRange={() => false}
                    startDate={startDateForIncoming}
                    endDate={endDateForIncoming}
                    startDateId="callCenter_date_input_start_for_incoming"
                    minimumNights={0}
                    endDateId="callCenter_date_input_end_for_incoming"
                    onDatesChange={({
                                      startDate: dateStart,
                                      endDate: dateEnd,
                                    }) => {
                      if (dateStart) {
                        setStartDateForIncoming(dateStart);
                        setDataForApi({
                          ...dataForApi,
                          startDateForIncoming: moment(dateStart).format("YYYY-MM-DD"),
                        });
                      }
                      if (dateEnd) {
                        setEndDateForIncoming(dateEnd);
                        setDataForApi({
                          ...dataForApi,
                          endDateForIncoming: moment(dateEnd).format("YYYY-MM-DD"),
                        });
                      }
                      if (dateStart && dateEnd) {
                        dailyCallGrapApiHandler(
                          moment(dateStart).format("YYYY-MM-DD"),
                          moment(dateEnd).format("YYYY-MM-DD"),
                          "incoming"
                        );
                      }
                    }}
                    focusedInput={focusedInputForIncoming}
                    onFocusChange={(input) => setFocusedInputForIncoming(input)}
                    showClearDates
                  />
                  <BarChart Proplabel="Inbound Call Logs" grapTitles={grapTitles}
                            graphValues={grapValues} grapColors={grapColors}/>
                </div>
              </div>


              <div className={"col-6"}>
                <div
                  className={
                    "callCenterDateRange " +
                    (startDateForOutgoing && endDateForOutgoing ? "selectedDateRange" : "")
                  }
                >
                  <DateRangePicker
                    isOutsideRange={() => false}
                    startDate={startDateForOutgoing}
                    endDate={endDateForOutgoing}
                    startDateId="callCenter_date_input_start_for_incoming"
                    minimumNights={0}
                    endDateId="callCenter_date_input_end_for_incoming"
                    onDatesChange={({
                                      startDate: dateStart,
                                      endDate: dateEnd,
                                    }) => {
                      if (dateStart) {
                        setStartDateForOutgoing(dateStart);
                        setDataForApi({
                          ...dataForApi,
                          startDateForOutgoing: moment(dateStart).format("YYYY-MM-DD"),
                        });
                      }
                      if (dateEnd) {
                        setEndDateForOutgoing(dateEnd);
                        setDataForApi({
                          ...dataForApi,
                          endDateForOutgoing: moment(dateEnd).format("YYYY-MM-DD"),
                        });
                      }
                      if (dateStart && dateEnd) {
                        dailyCallGrapApiHandler(
                          moment(dateStart).format("YYYY-MM-DD"),
                          moment(dateEnd).format("YYYY-MM-DD"),
                          "outgoing"
                        );
                      }
                    }}
                    focusedInput={focusedInputForOutgoing}
                    onFocusChange={(input) => setFocusedInputForOutgoing(input)}
                    showClearDates
                  />
                  <BarChart Proplabel="OutBound Call Logs" grapTitles={grapTitlesForOutgoing}
                            graphValues={grapValuesForOutgoing} grapColors={grapColorsForOutgoing}/>
                </div>
              </div>


            </div>
          </ModalBody>
        </div>
      </Modal>
    </div>
  );
};

export default BarCharts;
