/** @format */

import React, { useState, useEffect, useRef } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from 'reactstrap'
import axios from 'axios'
import config from '../../config'
import { MapContainer, TileLayer, Marker, Popup, Polygon, useMap } from 'react-leaflet'
import './geoTagModal.scss'
import Select from 'react-select'
import 'leaflet/dist/leaflet.css'
import L from 'leaflet'
import * as turf from '@turf/turf'
import MyPolygon from './MyPolygon'
import Loader from 'react-loader-spinner'

delete L.Icon.Default.prototype._getIconUrl

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})

const GeoTagModal = (props) => {
  // props destructure
  const { modalFlag, toggle, geoTagHandler, existingLngValue, existingLatValue } = props
  // state defined
  const [cityData, setCityData] = useState('')
  const [cityCordValue, setCityCordValue] = useState('')
  const [cityValue, setCityValue] = useState('')
  const [centerMapValue, setCenterMapValue] = useState(
    existingLngValue !== null && existingLatValue !== null
      ? [existingLatValue, existingLngValue]
      : [33.70329225438966, 73.11342895086334]
  )
  const [zoomValue, setZoomValue] = useState(
    existingLngValue !== null && existingLatValue !== null ? 16 : 10
  )
  const [areaPositions, setAreaPositions] = useState(null)
  const [mapLoader, setMapLoader] = useState(true)
  const [areaOptions, setAreaOptions] = useState(null)
  const [areaValue, setAreaValue] = useState(null)
  const [phaseOptions, setPhaseOptions] = useState([])
  const [phaseValue, setPhaseValue] = useState(null)
  const [phasePositions, setPhasePositions] = useState(null)
  const [plotPositions, setPlotPositions] = useState([])
  const [plotOptions, setPlotOptions] = useState([])
  const [plotValue, setPlotValue] = useState(null)
  const [prePlotPos, setPrePlotPos] = useState(null)
  const [markerPos, setMarkerPos] = useState(
    existingLngValue !== null && existingLatValue !== null
      ? [existingLatValue, existingLngValue]
      : null
  )
  const [blockSector, setBlockSector] = useState(null)
  const [propsureId, setPropsureId] = useState(null)

  // useEffect here
  useEffect(() => {
    axios
      .get(`https://maps.graana.rocks/api/map/cities?secure=true`)
      .then((res) => {
        const data = res.data.map((item) => {
          return {
            value: item.id,
            label: item.city_name,
            geoDataType: JSON.parse(item.geoData).type,
            geoDataCord: JSON.parse(item.geoData).coordinates[0].map((inner) =>
              inner.map((v) => [v[1], v[0]])
            ),
          }
        })
        setCityData(data)
        setMapLoader(false)
      })
      .catch((err) => console.log(err))
  }, [])
  // functions here
  const cityHandler = (value) => {
    selectValueReset()
    setCityValue(value)
    setCityCordValue(value.geoDataCord)
    const geoData = { type: value.geoDataType, coordinates: [value.geoDataCord] }
    const center = getCentroid(geoData)
    setCenterMapValue(center)
    setZoomValue(10)
    fetchAreas(value.value)
  }
  const areaHandler = (value) => {
    setAreaValue(value)
    const areaCenter = { type: value.geoDataType, coordinates: [value.geoDataCord] }
    const center = getCentroid(areaCenter)
    setCenterMapValue(center)
    setZoomValue(10)
    setAreaPositions(value.geoDataCord)
    fetchPhase(value.value)
  }
  const phaseHandler = (value) => {
    setPhaseValue(value)
    setPlotValue(null)
    const areaCenter = { type: value.geoDataType, coordinates: [value.geoDataCord] }
    const center = getCentroid(areaCenter)
    setCenterMapValue(center)
    setZoomValue(10)
    setPhasePositions(value.geoDataCord)
    fetchBlockSectors(value.value)
    fetchSubPhases(value.value)
  }
  const plotHandler = (value) => {
    setPlotValue(value)
    const plotCenter = { type: value.geoDataType, coordinates: [value.geoDataCord] }
    const center = getCentroid(plotCenter)
    setCenterMapValue(center)
    setZoomValue(10)
    setMarkerPos(center)
    setPlotPositions(value.geoDataCord)
  }
  const fetchAreas = (id) => {
    axios
      .get(`https://maps.graana.rocks/api/map/housing-scheme?cityId=${id}&secure=true`)
      .then((res) => {
        const data = res.data.map((item) => {
          return {
            value: item.id,
            label: item.housing_scheme_name,
            geoDataType: JSON.parse(item.geoData).type,
            geoDataCord: JSON.parse(item.geoData).coordinates[0].map((inner) =>
              inner.map((v) => [v[1], v[0]])
            ),
          }
        })
        setAreaOptions(data)
      })
      .catch((err) => console.log(err))
  }
  const fetchPhase = (id) => {
    axios
      .get(`https://maps.graana.rocks/api/map/phase-sector?housingSchemeId=${id}&secure=true`)
      .then((res) => {
        const data = res.data.map((item) => {
          return {
            value: item.id,
            label: item.phase_sector_name,
            geoDataType: JSON.parse(item.geoData).type,
            geoDataCord: JSON.parse(item.geoData).coordinates[0].map((inner) =>
              inner.map((v) => [v[1], v[0]])
            ),
          }
        })
        setPhaseOptions(data)
      })
      .catch((err) => console.log(err))
  }
  const fetchBlockSectors = (id) => {
    axios
      .get(`https://maps.graana.rocks/api/map/block-subsector?phaseSectorId=${id}&&secure=true`)
      .then((res) => {
        if (res.data.length == 0) {
          setBlockSector(res.data)
        } else {
          const data = res.data.map((item) => {
            return {
              value: item.id,
              label: item.Plot_No,
              street: item.Street,
              geoDataType: JSON.parse(item.geoData).type,
              geoDataCord: JSON.parse(item.geoData).coordinates[0].map((inner) =>
                inner.map((v) => [v[1], v[0]])
              ),
            }
          })
          setBlockSector(data)
        }
      })
      .catch((err) => console.log(err))
  }
  const fetchSubPhases = (id) => {
    axios
      .get(`https://maps.graana.rocks/api/map/plots?phaseSectorId=${id}&secure=true`)
      .then((res) => {
        const data = res.data.map((item) => {
          return {
            value: item.id,
            label: item.Plot_No,
            street: item.Street,
            geoDataType: JSON.parse(item.geoData).type,
            geoDataCord: JSON.parse(item.geoData).coordinates[0].map((inner) =>
              inner.map((v) => [v[1], v[0]])
            ),
            Area_In: item.Area_In,
            Land_Use: item.Land_Use,
            Legal_Status: item.Legal_Status,
            block_subsector: item.block_subsector,
            housing_scheme: item.housing_scheme,
            total_area: item.total_area,
          }
        })
        setPrePlotPos(data)
        setPlotOptions(data)
      })
      .catch((err) => console.log(err))
  }
  const getCentroid = (geoJSON) => {
    const poly =
      geoJSON.type === 'Polygon'
        ? turf.polygon(geoJSON.coordinates)
        : turf.multiPolygon(geoJSON.coordinates)
    const centroid = turf.centroid(poly)
    const lon = centroid.geometry.coordinates[1]
    const lat = centroid.geometry.coordinates[0]
    const marker = [lat, lon]
    return marker
  }
  const selectValueReset = () => {
    setAreaPositions(null)
    setAreaValue(null)
    setPhaseValue(null)
    setPhasePositions(null)
    setPlotPositions(null)
    setPrePlotPos(null)
    setPlotValue(null)
    setMarkerPos(null)
  }
  const onSelectPlot = (plot, item) => {
    const position = [plot.lat, plot.lng]
    setMarkerPos(position)
    setPlotValue(item)
    setCenterMapValue(position)
    setPropsureId(item.value)
  }
  const saveGeoTag = () => {
    geoTagHandler(markerPos, propsureId)
    toggle()
  }
  // Constant value here
  const fillBlueOptions = { fillColor: 'blue' }

  return (
    <Modal className="geo-tag-modal" isOpen={modalFlag} toggle={toggle}>
      <ModalHeader>
        <h5>Geo Tagging</h5>
      </ModalHeader>
      {cityData !== null && cityCordValue !== null && mapLoader == false ? (
        <ModalBody>
          <div className="main-modal-map-wrap">
            <div className="map-main-wrap">
              <MapContainer
                style={{ width: '60vw', height: '60vh' }}
                center={centerMapValue}
                zoom={zoomValue}
                scrollWheelZoom={false}
              >
                <TileLayer
                  attribution="&copy; Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ | &copy; <a href=“https://www.openstreetmap.org/copyright”>OpenStreetMap</a> contributors"
                  url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
                  maxNativeZoom={19}
                  maxZoom={20}
                />
                <MyPolygon
                  centerValue={centerMapValue}
                  positions={cityCordValue}
                  areaPositions={areaPositions}
                  phasePositions={phasePositions}
                  prePlotPositions={prePlotPos}
                  plotPositions={plotPositions}
                  onSelectPlot={(plotLngLat, item) => onSelectPlot(plotLngLat, item)}
                  blockSector={blockSector}
                  markerPos={markerPos}
                />
                {markerPos !== null ? <Marker position={markerPos}></Marker> : null}
              </MapContainer>
            </div>
            <div className="select-wrap">
              <div className="main-input-wrap pt-3">
                <Select
                  placeholder={'Select City'}
                  options={cityData}
                  value={cityValue}
                  onChange={(value) => cityHandler(value)}
                />
              </div>
              <div className="main-input-wrap pt-3">
                <Select
                  placeholder={'Select Housing Scheme'}
                  options={areaOptions}
                  value={areaValue}
                  onChange={(value) => {
                    areaHandler(value)}}
                />
              </div>
              {phaseOptions.length || phaseValue !== null ? (
                <div className="main-input-wrap pt-3">
                  <Select
                    placeholder={'Select Phase'}
                    options={phaseOptions}
                    value={phaseValue}
                    onChange={(value) => phaseHandler(value)}
                  />
                </div>
              ) : null}
              {plotOptions.length || plotValue !== null ? (
                <div className="main-input-wrap pt-3">
                  <Select
                    placeholder={'Select Plot'}
                    options={plotOptions}
                    value={plotValue}
                    onChange={(value) => plotHandler(value)}
                  />
                </div>
              ) : null}
              {plotOptions.length &&
              plotValue !== null &&
              plotValue !== undefined &&
              plotOptions !== null ? (
                <div className="pt-3">
                  <h6>
                    Propsure Plot ID <span>{plotValue.value}</span>
                  </h6>
                  <div className="card cus-card">
                    <div className="main-wrap-card">
                      <div className="title-card">
                        <p className="title">
                          Plot no. {plotValue.label}, Street {plotValue.street}, Sector{' '}
                          {plotValue.housing_scheme.housing_scheme_name}
                        </p>
                      </div>
                      <hr />
                      <div className="other-info">
                        <div className="info-cus">
                          <span className="label">Type</span>
                          <span className="value">
                            {plotValue.Land_Use == 'RES' ? 'Residential' : plotValue.Land_Use}
                          </span>
                        </div>
                        <div className="info-cus">
                          <span className="label">Area</span>
                          <span className="value">
                            {plotValue.total_area !== null && plotValue.Area_In !== null
                              ? `${plotValue.total_area} ${plotValue.Area_In}`
                              : '--'}
                          </span>
                        </div>
                        <div className="info-cus">
                          <span className="label">LOP Status</span>
                          <span className="value">
                            {plotValue.Legal_Status !== null ? plotValue.Legal_Status : '--'}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </ModalBody>
      ) : (
        <div className="loader-parent">
          <Loader type="ThreeDots" color="#0f73ee" height="40" width="40" />{' '}
        </div>
      )}
      <ModalFooter>
        <Button onClick={() => saveGeoTag()}>Save</Button>
        <Button onClick={toggle}>Close</Button>
      </ModalFooter>
    </Modal>
  )
}
export default GeoTagModal
