/** @format */
export const TasksType = {
  CALL: 'called',
  MEETING: 'meeting',
  TASK: 'task',
  FOLLOWUP: 'follow_up',
  VIEWING: 'viewing',
  MORNINGMEETING: 'morning_meeting',
  DAILYUPDATE: 'daily_update',
  MEETINGWITHPP: 'meeting_with_pp',
  CONNECT: 'connect',
  REASSIGN: 're-assign',
  //more to add
}
export const TaskTypeLabels = {
  called: 'Call',
  meeting: 'Meeting',
  task: 'Task',
  follow_up: 'Follow Up',
  viewing: 'Viewing',
  morning_meeting: 'Morning Meeting',
  daily_update: 'Daily Update',
  meeting_with_pp: 'Meeting with PP',
  connect: 'Connect',
  're-assign': 'Re Assign',
}
export const taskTypeData = [
  { label: 'Follow Up', value: 'follow up' },
  { label: 'Other', value: 'other' },
]

export const managerSelectionDropdown = [
  { label: 'Team', value: 'myTeam' },
  { label: 'Other', value: 'others' },
]

export const callStatus = [
  { value: 'no_response', name: 'No Response' },
  { value: 'powered_off', name: 'Powered Off' },
  { value: 'invalid_number', name: 'Invalid Number' },
  { value: 'follow_up', name: 'Follow-up' },
  { value: 'DNC', name: 'DNC' },
  { value: 'details_texted', name: 'No Response but Details Sent' },
  { value: 'other_agent', name: 'In contact with some other advisor' },
  { value: 'call_back', name: 'Call Back' },
  { value: 'awaiting_call', name: 'Awaiting Response' },
  { value: 'details_sent', name: 'Details Sent' },
  { value: 'expected_visit', name: 'Expected Visit' },
  { value: 'not_interested', name: 'Not Interested' },
]

export const meetingStatusValues = [
  {
    value: 'will_get_back_if_interested',
    name: 'Will get back if interested',
    colorCode: '#FEC107',
  },
  { value: 'client_will_call_later', name: 'Client will call later', colorCode: '#FEC107' },
  { value: 'needs_time_to_think', name: 'Needs Time to Think', colorCode: '#FEC107' },
  { value: 'needs_follow_up', name: 'Needs Follow Up', colorCode: '#FEC107' },
  {
    value: 'will_interact_via_whatsapp_sms_email ',
    name: 'Will Interact via WhatsApp/SMS/Email',
    colorCode: '#FEC107',
  },
  { value: 'asked_to_call_back_later', name: 'Asked to Call back later', colorCode: '#FEC107' },
  {
    value: 'asked_to_share_details_via_whatsapp_sms_email',
    name: 'Asked to Share Details via Whatsapp/SMS/Email',
    colorCode: '#FEC107',
  },
  { value: 'interested_for_investment', name: 'Interested for Investment', colorCode: '#FEC107' },
  { value: 'deal_done', name: 'Deal done', colorCode: '#0F73EF' },
  { value: 'agreed_to_meet_again', name: 'Agreed to Meet Again', colorCode: '#0F73EF' },
  { value: 'not_interested', name: 'Not Interested', colorCode: '#DC3546' },
  {
    value: 'not_interested_out_of_city',
    name: 'Not Interested - Out of City',
    colorCode: '#DC3546',
  },
  {
    value: 'not_interested_re_only',
    name: 'Not Interested - Interested in RE Only',
    colorCode: '#DC3546',
  },
  {
    value: 'interested_in_projects_in_other_city',
    name: 'Interested in Projects in Other City',
    colorCode: '#DC3546',
  },
  {
    value: 'not_interested_low_budget',
    name: 'Out of clients budget/preference',
    colorCode: '#DC3546',
  },
  { value: 'duplicate', name: 'Duplicate', colorCode: '#DC3546' },
  { value: 'property_agent', name: 'Property Advisor', colorCode: '#DC3546' },
  { value: 'DNC', name: 'DNC', colorCode: '#DC3546' },
]

export const callFeedback = [
  { value: 'no_response', name: 'No Response', colorCode: '#FEC107' },
  { value: 'powered_off', name: 'Powered Off', colorCode: '#FEC107' },
  { value: 'number_busy', name: 'Number Busy', colorCode: '#FEC107' },
  { value: 'busy_occupied_unavailable', name: 'Busy/Occupied/Unavailable', colorCode: '#FEC107' },
  { value: 'call_declined', name: 'Call declined', colorCode: '#FEC107' },
  { value: 'network_issue', name: 'Network Issue', colorCode: '#FEC107' },
  { value: 'call_disconnected', name: 'Call Disconnected', colorCode: '#FEC107' },
  {
    value: 'no_response_details_shared',
    name: 'No Response But Details Shared',
    colorCode: '#FEC107',
  },
  {
    value: 'details_shared_via_whatsapp_sms_email',
    name: 'Details Shared via WhatsApp/SMS/Email',
    colorCode: '#FEC107',
  },
  {
    value: 'will_get_back_if_interested',
    name: 'Will get back if interested',
    colorCode: '#FEC107',
  },
  { value: 'client_will_call_later', name: 'Client will call later', colorCode: '#FEC107' },
  { value: 'needs_time_to_think', name: 'Needs Time to Think', colorCode: '#FEC107' },
  { value: 'needs_follow_up', name: 'Needs Follow Up', colorCode: '#FEC107' },
  {
    value: 'will_interact_via_whatsapp_sms_email ',
    name: 'Will Interact via WhatsApp/SMS/Email',
    colorCode: '#FEC107',
  },
  { value: 'asked_to_call_back_later', name: 'Asked to Call back later', colorCode: '#FEC107' },
  {
    value: 'asked_to_share_details_via_whatsapp_sms_email',
    name: 'Asked to Share Details via Whatsapp/SMS/Email',
    colorCode: '#FEC107',
  },
  { value: 'interested_for_investment', name: 'Interested for Investment', colorCode: '#FEC107' },
  { value: 'agreed_to_meet', name: 'Agreed to Meet', colorCode: '#0F73EF' },
  { value: 'wrong_number', name: 'Wrong Number', colorCode: '#DC3546' },
  { value: 'not_interested', name: 'Not Interested', colorCode: '#DC3546' },
  { value: 'invalid_number', name: 'Invalid Number', colorCode: '#DC3546' },
  {
    value: 'not_interested_out_of_city',
    name: 'Not Interested - Out of City',
    colorCode: '#DC3546',
  },
  {
    value: 'not_interested_re_only',
    name: 'Not Interested - Interested in RE Only',
    colorCode: '#DC3546',
  },
  {
    value: 'interested_in_projects_in_other_city',
    name: 'Interested in Projects in Other City',
    colorCode: '#DC3546',
  },
  {
    value: 'not_interested_low_budget',
    name: 'Out of clients budget/preference',
    colorCode: '#DC3546',
  },
  { value: 'lead_generated_by_mistake', name: 'Lead Generated by Mistake', colorCode: '#DC3546' },
  { value: 'duplicate', name: 'Duplicate', colorCode: '#DC3546' },
  { value: 'property_agent', name: 'Property Advisor', colorCode: '#DC3546' },
  { value: 'DNC', name: 'DNC', colorCode: '#DC3546' },
]

export const rejectFeedback = [
  { value: 'wrong_number', name: 'Wrong Number', colorCode: '#DC3546' },
  { value: 'not_interested', name: 'Not Interested', colorCode: '#DC3546' },
  { value: 'invalid_number', name: 'Invalid Number', colorCode: '#DC3546' },
  {
    value: 'not_interested_out_of_city',
    name: 'Not Interested - Out of City',
    colorCode: '#DC3546',
  },
  {
    value: 'not_interested_re_only',
    name: 'Not Interested - Interested in RE Only',
    colorCode: '#DC3546',
  },
  {
    value: 'interested_in_projects_in_other_city',
    name: 'Interested in Projects in Other City',
    colorCode: '#DC3546',
  },
  {
    value: 'not_interested_low_budget',
    name: 'Out of clients budget/preference',
    colorCode: '#DC3546',
  },
  { value: 'lead_generated_by_mistake', name: 'Lead Generated by Mistake', colorCode: '#DC3546' },
  { value: 'duplicate', name: 'Duplicate', colorCode: '#DC3546' },
  { value: 'property_agent', name: 'Property Advisor', colorCode: '#DC3546' },
  { value: 'DNC', name: 'DNC', colorCode: '#DC3546' },
]

export const meetingStatus = [
  { value: 'visited', name: 'Visited' },
  { value: 'deal_expected', name: 'Deal Expected' },
  { value: 'deal_signed', name: 'Deal Signed' },
  { value: 'cancel_meeting', name: 'Cancel Meeting' },
]

export const closedWonReason = [
  {
    name: 'Client not responding',
    value: 'client_not_responding',
  },
  {
    name: 'Client withdrew requirements',
    value: 'client_withdrew_requirements',
  },
  {
    name: 'No property found',
    value: 'no_property_found',
  },
  {
    name: 'Client got property from some other source',
    value: 'property_other_source',
  },
  {
    name: 'Client not satisfied with options',
    value: 'client_not_satisfied',
  },
  { value: 'low_budget', name: 'Low Budget' },
  { value: 'different_city', name: 'Different City' },
]

export const activityLogSearchFilter = [
  { label: 'Lead ID', value: 'leadId' },
  { label: 'User ID', value: 'userId' },
  { label: 'Entity Name', value: 'entityName' },
  { label: 'Date Range', value: 'dateRange' },
]

export const activityLogTableContent = [
  { width: 7, name: 'ID' },
  'Account Name',
  { width: 7, name: 'Action' },
  'Entity ID',
  'Entity',
  { width: 30, name: 'Activity' },
  'Date',
  'Actions',
]
export const leadCitiesTableContent = [{ width: 7, name: 'ID' }]

export const unitType = [
  { value: 'fullUnit', label: 'Full Unit' },
  { value: 'pearl', label: 'Pearl' },
]

export const paymentType = [
  { value: 'cash', label: 'Cash' },
  { value: 'cheque', label: 'Cheque' },
  { value: 'pay-Order', label: 'Pay-Order' },
  { value: 'Remittance', label: 'Remittance' },
  { value: 'bank-Transfer', label: 'Bank-Transfer' },
  { value: 'e-Gateway', label: 'E-Gateway' },
  { value: 'Buy Back Adjustment', label: 'Buy Back Adjustment' },
  { value: 'Rebate Adjustment', label: 'Rebate Adjustment' },
  { value: 'Rent Adjustment', label: 'Rent Adjustment' },
  { value: 'Inter-Mall Adjustment', label: 'Inter-Mall Adjustment' },
]

export const onlyUnitType = [{ value: 'fullUnit', label: 'Full Unit' }]

export const schedule = [
  {
    day: 'Monday',
    timeArray: [
      { name: '12am', value: false },
      { name: '01am', value: false },
      { name: '02am', value: false },
      { name: '03am', value: false },
      { name: '04am', value: false },
      { name: '05am', value: false },
      { name: '06am', value: false },
      { name: '07am', value: false },
      { name: '08am', value: false },
      { name: '09am', value: false },
      { name: '10am', value: false },
      { name: '11am', value: false },
      { name: '12pm', value: false },
      { name: '01pm', value: false },
      { name: '02pm', value: false },
      { name: '03pm', value: false },
      { name: '04pm', value: false },
      { name: '05pm', value: false },
      { name: '06pm', value: false },
      { name: '07pm', value: false },
      { name: '08pm', value: false },
      { name: '09pm', value: false },
      { name: '10pm', value: false },
      { name: '11pm', value: false },
    ],
  },
  {
    day: 'Tuesday',
    timeArray: [
      { name: '12am', value: false },
      { name: '01am', value: false },
      { name: '02am', value: false },
      { name: '03am', value: false },
      { name: '04am', value: false },
      { name: '05am', value: false },
      { name: '06am', value: false },
      { name: '07am', value: false },
      { name: '08am', value: false },
      { name: '09am', value: false },
      { name: '10am', value: false },
      { name: '11am', value: false },
      { name: '12pm', value: false },
      { name: '01pm', value: false },
      { name: '02pm', value: false },
      { name: '03pm', value: false },
      { name: '04pm', value: false },
      { name: '05pm', value: false },
      { name: '06pm', value: false },
      { name: '07pm', value: false },
      { name: '08pm', value: false },
      { name: '09pm', value: false },
      { name: '10pm', value: false },
      { name: '11pm', value: false },
    ],
  },
  {
    day: 'Wednesday',
    timeArray: [
      { name: '12am', value: false },
      { name: '01am', value: false },
      { name: '02am', value: false },
      { name: '03am', value: false },
      { name: '04am', value: false },
      { name: '05am', value: false },
      { name: '06am', value: false },
      { name: '07am', value: false },
      { name: '08am', value: false },
      { name: '09am', value: false },
      { name: '10am', value: false },
      { name: '11am', value: false },
      { name: '12pm', value: false },
      { name: '01pm', value: false },
      { name: '02pm', value: false },
      { name: '03pm', value: false },
      { name: '04pm', value: false },
      { name: '05pm', value: false },
      { name: '06pm', value: false },
      { name: '07pm', value: false },
      { name: '08pm', value: false },
      { name: '09pm', value: false },
      { name: '10pm', value: false },
      { name: '11pm', value: false },
    ],
  },
  {
    day: 'Thursday',
    timeArray: [
      { name: '12am', value: false },
      { name: '01am', value: false },
      { name: '02am', value: false },
      { name: '03am', value: false },
      { name: '04am', value: false },
      { name: '05am', value: false },
      { name: '06am', value: false },
      { name: '07am', value: false },
      { name: '08am', value: false },
      { name: '09am', value: false },
      { name: '10am', value: false },
      { name: '11am', value: false },
      { name: '12pm', value: false },
      { name: '01pm', value: false },
      { name: '02pm', value: false },
      { name: '03pm', value: false },
      { name: '04pm', value: false },
      { name: '05pm', value: false },
      { name: '06pm', value: false },
      { name: '07pm', value: false },
      { name: '08pm', value: false },
      { name: '09pm', value: false },
      { name: '10pm', value: false },
      { name: '11pm', value: false },
    ],
  },
  {
    day: 'Friday',
    timeArray: [
      { name: '12am', value: false },
      { name: '01am', value: false },
      { name: '02am', value: false },
      { name: '03am', value: false },
      { name: '04am', value: false },
      { name: '05am', value: false },
      { name: '06am', value: false },
      { name: '07am', value: false },
      { name: '08am', value: false },
      { name: '09am', value: false },
      { name: '10am', value: false },
      { name: '11am', value: false },
      { name: '12pm', value: false },
      { name: '01pm', value: false },
      { name: '02pm', value: false },
      { name: '03pm', value: false },
      { name: '04pm', value: false },
      { name: '05pm', value: false },
      { name: '06pm', value: false },
      { name: '07pm', value: false },
      { name: '08pm', value: false },
      { name: '09pm', value: false },
      { name: '10pm', value: false },
      { name: '11pm', value: false },
    ],
  },
  {
    day: 'Saturday',
    timeArray: [
      { name: '12am', value: false },
      { name: '01am', value: false },
      { name: '02am', value: false },
      { name: '03am', value: false },
      { name: '04am', value: false },
      { name: '05am', value: false },
      { name: '06am', value: false },
      { name: '07am', value: false },
      { name: '08am', value: false },
      { name: '09am', value: false },
      { name: '10am', value: false },
      { name: '11am', value: false },
      { name: '12pm', value: false },
      { name: '01pm', value: false },
      { name: '02pm', value: false },
      { name: '03pm', value: false },
      { name: '04pm', value: false },
      { name: '05pm', value: false },
      { name: '06pm', value: false },
      { name: '07pm', value: false },
      { name: '08pm', value: false },
      { name: '09pm', value: false },
      { name: '10pm', value: false },
      { name: '11pm', value: false },
    ],
  },
  {
    day: 'Sunday',
    timeArray: [
      { name: '12am', value: false },
      { name: '01am', value: false },
      { name: '02am', value: false },
      { name: '03am', value: false },
      { name: '04am', value: false },
      { name: '05am', value: false },
      { name: '06am', value: false },
      { name: '07am', value: false },
      { name: '08am', value: false },
      { name: '09am', value: false },
      { name: '10am', value: false },
      { name: '11am', value: false },
      { name: '12pm', value: false },
      { name: '01pm', value: false },
      { name: '02pm', value: false },
      { name: '03pm', value: false },
      { name: '04pm', value: false },
      { name: '05pm', value: false },
      { name: '06pm', value: false },
      { name: '07pm', value: false },
      { name: '08pm', value: false },
      { name: '09pm', value: false },
      { name: '10pm', value: false },
      { name: '11pm', value: false },
    ],
  },
]

export const BathOptions = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
  { value: '7', label: '7' },
  { value: '8', label: '8' },
  { value: '9', label: '9' },
  { value: '10', label: '10' },
]

export const BedOptions = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
  { value: '7', label: '7' },
  { value: '8', label: '8' },
  { value: '9', label: '9' },
  { value: '10', label: '10' },
]
export const bankList = [
  { value: 'HBL', label: 'HBL' },
  { value: 'NBP', label: 'NBP' },
  { value: 'Meezan Bank', label: 'Meezan Bank' },
  { value: 'MCB Bank', label: 'MCB Bank' },
  { value: 'UBL', label: 'UBL' },
  { value: 'Allied Bank', label: 'Allied Bank' },
  { value: 'Faysal Bank', label: 'Faysal Bank' },
  { value: 'BANK ALFALAH', label: 'BANK ALFALAH' },
  { value: 'Soneri Bank', label: 'Soneri Bank' },
  { value: 'Askari Bank', label: 'Askari Bank' },
]

export const FilterStatusOpt = [
  { value: 'onhold', label: 'Pending' },
  { value: 'pending', label: 'Approved' },
  { value: 'rejected', label: 'Rejected' },
]

export const properties = {
  type: [
    { value: 'residential', name: 'Residential' },
    { value: 'plot', name: 'Plot' },
    { value: 'commercial', name: 'Commercial' },
  ],
  subType: {
    residential: [
      { value: 'house', name: 'House' },
      { value: 'guest house', name: 'Guest house' },
      { value: 'apartment', name: 'Apartment' },
      { value: 'upper portion', name: 'Upper portion' },
      { value: 'lower portion', name: 'Lower portion' },
      { value: 'farm house', name: 'Farm house' },
      { value: 'room', name: 'Room' },
      { value: 'penthouse', name: 'Penthouse' },
      { value: 'hotel suites', name: 'Hotel suites' },
      { value: 'basement', name: 'Basement' },
      { value: 'annexe', name: 'Annexe' },
      { value: 'hostel', name: 'Hostel' },
      { value: 'other', name: 'Other' },
    ],
    plot: [
      { value: 'residential plot', name: 'Residential Plot' },
      { value: 'commercial plot', name: 'Commercial Plot' },
      { value: 'commercial land', name: 'Commercial Land' },
      { value: 'agricultural land', name: 'Agricultural Land' },
      { value: 'industrial land', name: 'Industrial Land' },
      { value: 'plot file', name: 'Plot File' },
      { value: 'farmhouse plot', name: 'Farmhouse Plot' },
    ],
    commercial: [
      { value: 'office', name: 'Office' },
      { value: 'shop', name: 'Shop' },
      { value: 'warehouse', name: 'Warehouse' },
      { value: 'factory', name: 'Factory' },
      { value: 'building', name: 'Building' },
      { value: 'theatre', name: 'Theatre' },
      { value: 'Gym', name: 'Gym' },
      { value: 'Food Court', name: 'Food Court' },
      { value: 'hall', name: 'Hall' },
      { value: 'plaza', name: 'Plaza' },
      { value: 'other', name: 'Other' },
    ],
  },
}

export const sizeRange = {
  sizeUnit: [
    { value: 'sqm', label: 'M²' },
    { value: 'sqft', label: 'Ft²' },
    { value: 'sqyd', label: 'Yd²' },
    { value: 'marla', label: 'Marla' },
    { value: 'kanal', label: 'Kanal' },
    { value: 'acre', label: 'Acre' },
  ],
  unitRange: {
    marla: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 15, 20, 30, 40, 50, 10000000],
    acre: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 100000],
    kanal: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 16, 20, 30, 40, 50, 100, 10000000],
    sqft: [
      0,
      1,
      100,
      500,
      1000,
      2000,
      3000,
      4000,
      5000,
      6000,
      7000,
      8000,
      9000,
      10000,
      15000,
      10000000,
    ],
    sqyd: [
      0,
      1,
      100,
      500,
      1000,
      2000,
      3000,
      4000,
      5000,
      6000,
      7000,
      8000,
      9000,
      10000,
      15000,
      10000000,
    ],
    sqm: [
      0,
      1,
      10,
      20,
      30,
      40,
      50,
      100,
      200,
      300,
      400,
      500,
      1000,
      2000,
      3000,
      4000,
      5000,
      10000,
      20000,
      30000,
      40000,
      50000,
      60000,
      10000000,
    ],
  },
}

export const leadType = [
  { label: 'Rent', value: 'rent' },
  { label: 'Buy', value: 'buy' },
]

export const allLeadTypes = [
  { label: 'Buy', value: 'buy' },
  { label: 'Rent', value: 'rent' },
  { label: 'Sell', value: 'sell' },
  { label: 'Rentout', value: 'rentout' },
  { label: 'Invest', value: 'invest' },
]
export const infiniteRange = [1, 100, 200, 300, 400, 500]
export const investmentProduct = {
  frequency: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  investmentPlanDuration: [1, 4, 8, 12, 16, 20, 24],
}
export const priceRange = {
  project: [
    500000,
    1000000,
    2000000,
    3000000,
    4000000,
    5000000,
    6000000,
    7000000,
    8000000,
    10000000,
    12500000,
    15000000,
    20000000,
    25000000,
    30000000,
    40000000,
    50000000,
    100000000,
    250000000,
    500000000,
    1000000000,
    10000000000,
  ],
  sale: [
    500000,
    1000000,
    2000000,
    3000000,
    4000000,
    5000000,
    6000000,
    7000000,
    8000000,
    10000000,
    15000000,
    20000000,
    25000000,
    30000000,
    40000000,
    50000000,
    100000000,
    250000000,
    500000000,
    1000000000,
    10000000000,
  ],
  buy: [
    0,
    500000,
    1000000,
    2000000,
    3000000,
    4000000,
    5000000,
    6000000,
    7000000,
    8000000,
    10000000,
    15000000,
    20000000,
    25000000,
    30000000,
    40000000,
    50000000,
    100000000,
    250000000,
    500000000,
    1000000000,
    10000000000,
  ],
  rent: [
    0,
    5000,
    10000,
    15000,
    20000,
    25000,
    30000,
    35000,
    40000,
    45000,
    50000,
    60000,
    70000,
    80000,
    90000,
    100000,
    125000,
    150000,
    200000,
    250000,
    300000,
    400000,
    500000,
    1000000,
    10000000000,
  ],
  sell: [
    0,
    500000,
    1000000,
    2000000,
    3000000,
    4000000,
    5000000,
    6000000,
    7000000,
    8000000,
    10000000,
    15000000,
    20000000,
    25000000,
    30000000,
    40000000,
    50000000,
    100000000,
    250000000,
    500000000,
    1000000000,
    10000000000,
  ],
  rentOut: [
    0,
    5000,
    10000,
    15000,
    20000,
    25000,
    30000,
    35000,
    40000,
    45000,
    50000,
    60000,
    70000,
    80000,
    90000,
    100000,
    125000,
    150000,
    200000,
    250000,
    300000,
    400000,
    500000,
    1000000,
    10000000000,
  ],
  IpDownPayment: [1, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
  IpPossessionCharges: [1, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
  IpFrequency: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  IpInvestmentPlanDuration: [1, 4, 8, 12, 16, 20, 24],
  IpNoOfInstallments: [
    1,
    5,
    10,
    15,
    20,
    25,
    30,
    35,
    40,
    45,
    50,
    55,
    60,
    65,
    70,
    75,
    80,
    85,
    90,
    95,
    100,
  ],
}

export const leadRejectReasons = [
  { value: 'wrong_number', name: 'Wrong Number', colorCode: '#DC3546' },
  { value: 'not_interested', name: 'Not Interested', colorCode: '#DC3546' },
  { value: 'invalid_number', name: 'Invalid Number', colorCode: '#DC3546' },
  {
    value: 'not_interested_out_of_city',
    name: 'Not Interested - Out of City',
    colorCode: '#DC3546',
  },
  {
    value: 'not_interested_re_only',
    name: 'Not Interested - Interested in RE Only',
    colorCode: '#DC3546',
  },
  {
    value: 'interested_in_projects_in_other_city',
    name: 'Interested in Projects in Other City',
    colorCode: '#DC3546',
  },
  {
    value: 'not_interested_low_budget',
    name: 'Out of clients budget/preference',
    colorCode: '#DC3546',
  },
  { value: 'lead_generated_by_mistake', name: 'Lead Generated by Mistake', colorCode: '#DC3546' },
  { value: 'duplicate', name: 'Duplicate', colorCode: '#DC3546' },
  { value: 'property_agent', name: 'Property Advisor', colorCode: '#DC3546' },
  { value: 'DNC', name: 'DNC', colorCode: '#DC3546' },
]

export const taskTypes = [
  {
    label: 'Task',
    value: 'task',
  },
  {
    label: 'Meeting',
    value: 'meeting',
  },
  {
    label: 'Follow Up',
    value: 'follow_up',
  },
  {
    label: 'Other',
    value: 'other',
  },
]

export const attachmentTypes = [
  {
    label: 'Attachment',
    value: 'attachment',
  },
  {
    label: 'Signed Services Agreement',
    value: 'signed_services_agreement',
  },
]

export const bedBathRange = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 1000]

export const constants = {
  lead_closed_won: 'closed_won',
  lead_closed_lost: 'closed_lost',
  any_value: 10000000000,
  size_any_value: 10000000,
}

export const areaManagerCheckList = [
  'Client Pre-Viewing Follow Up Call - 30 Minutes Prior to Viewing',
  'PP/POC Pre-Viewing Follow Up Call - 30 Minutes Prior to Viewing',
  'Reach viewing location - 15 Minutes Prior to Viewing',
  'Ensure Key by PP/POC',
  'Visit the property before viewing with the client',
  'Sell core features of property as required by client',
  'Brief and try to sign-up on service charge agreeement SCA by the client',
  'Pitch Propsure Service/Brochure',
  'Up Sell other services (As and when Applicable)',
]

export const colors = ['#bb0909', '#7308a5', '#1700ff', '#08adae', '#086105']

export const unitStatuses = [
  { value: 'Available', label: 'Available', color: '#28566a', bgColor: '#ceecfc' },
  { value: 'Token', label: 'Token', bgColor: '#fde0e2', color: '#7a3635' },
  { value: 'Hold', label: 'Hold', bgColor: '#fef3c6', color: '#916121' },
  { value: 'Sold', label: 'Sold', bgColor: '#fde0e2', color: '#7a3635' },
  { value: 'Payment', label: 'Payment', bgColor: '#fde0e2', color: '#7a3635' },
]
export const projectUnitStatusesDropDown = [
  { value: 'Available', label: 'Available', color: '#28566a', bgColor: '#ceecfc' },
  { value: 'Hold', label: 'Hold', bgColor: '#fef3c6', color: '#916121' },
]

export const unitPriceRange = {
  min: 0,
  max: 10000000000,
}

export const DIARY_QUICK_FILTERS = {
  TODAY: 'Today',
  TOMORROW: 'Tomorrow',
  CALENDER: 'Setect Date',
  FOLLOWUP: 'Follow up',
  MEETINGS: 'Meeting',
  HOT: 'Hot',
  CONNECT: 'Connect',
}

export const ProjectLeadUrl = '/project-lead'
export const ProjectMyLeadUrl = '/project-my-lead'
export const ProjectCompanyLeadUrl = '/project-company-lead'
export const ProjectDeactivatedLeadUrl = '/project-deactivated-lead'
export const ProjectDeactivatedDealUrl = '/project-deactivated-deal'
export const ProjectDealUrl = '/project-deal'
export const ReferedProjectLeadUrl = '/referred-project-lead'
export const ReferedProjectDealUrl = '/referred-project-deal'
export const TeamProjectLeadUrl = '/team-project-lead'
export const TeamProjectDealUrl = '/team-project-deal'
// export const ProjectMyLeadUrl = '/project-my-lead'
export const BuyRentLeadUrl = '/buy-rent-lead'
export const BuyRentMyLeadUrl = '/buy-rent-my-lead'
export const BuyRentCompanyLeadUrl = '/buy-rent-company-lead'
export const BuyRentDeactivatedLeadUrl = '/buy-rent-deactivated-lead'
export const BuyRentDeactivatedDealUrl = '/buy-rent-deactivated-deal'
export const BuyRentDealUrl = '/buy-rent-deal'
export const BuyRentDemandLeadUrl = '/buy-rent-demand-lead'
export const ReferedBuyRentLeadUrl = '/referred-buy-rent-lead'
export const ReferedBuyRentDealUrl = '/referred-buy-rent-deal'
export const TeamBuyRentLeadUrl = '/team-buy-rent-lead'
export const TeamBuyRentDealUrl = '/team-buy-rent-deal'
export const WantedLeadUrl = '/wanted-lead'
export const WantedQualifiedLeadUrl = '/wanted-qualified-lead'
export const WantedTeamLeadUrl = '/wanted-team-lead'

export const DIARY_MENU_ACTION = {
  VIEW_AGENT_DIARY: 'viewagentdiary',
  VIEW_MY_DIARY: 'viewmydiary',
  VIEW_DIALY_REPORT: 'viewdailyreport',
}
export const DIARY_STATS = {
  TOTAL_TASKS: 'Total Tasks of day',
  DONE_TASKS: 'Done Tasks',
  REMAINING_TASKS: 'Remaining Tasks',
  OVERDUE_TASKS: 'Overdue Tasks',
  TOTAL_CONNECT: 'Total Connect',
  CONNECT_DONE: 'Connect tasks done',
  TOTAL_FOLLOWUP: 'Total FollowUps',
  FOLLOWUP_DONE: 'Follow ups done',
  CALL_DONE: 'Total Calls done',
  TOTAL_MEETING: 'Total meetings',
  MEETING_DONE: 'Meetings done',
  TOTAL_VIEWING: 'Total viewings',
  VIEWING_DONE: 'Viewings done',
  Wanted_Leads_Rejected: 'WantedLeads Rejected',
  BuyRent_Leads_Rejected: 'BuyRentLeads Rejected',
  Project_Leads_Rejected: 'ProjectLeads Rejected',
}
export const FEEDBACK_ACTIONS = {
  ADD_MEETING: 'Add Meeting',
  BOOK_UNIT: 'Book a Unit',
  SETUP_VIEWING: 'Setup Viewing',
  ASSIGN: 'Assign',
  RESCHEDULE_MEETING: 'Reschedule Meeting',
  SETUP_ANOTHER_MEETING: 'Setup Another Meeting',
  RESCHEDULE_VIEWING: 'Reschedule Viewings',
  SHORTLIST_PROPERTIES: 'Shortlist Properties',
  SETUP_MORE_VIEWING: 'Setup More Viewings',
  OFFER: 'Offer',
  PROPSURE: 'Propsure',
  SELECT_PROPERTY_FOR_TRANSACTION: 'Select Property for Transaction',
  CLIENT_IS_INTERESTED_IN_INVESTMENT: 'Client is interested in Investment',
}
export const DIARY_TASK_TILE_COLOR = {
  FOLLOWUP: '#FFC61B',
  CLOSED: '#9A9A9A',
  CONNECT: '#7BB461',
  MEETING: '#006FF2',
  VIEWING: '#006FF2',
  MORNINGMEETING: '#73C2FE',
  DAILYUPDATE: '#71C2FE',
  MEETINGWITHPP: '#71C2FE',
  REASSIGN: '#006FF2',
}
export const SLOT_TASK_TILE_COLOR = {
  FOLLOWUP: '#FFE8A3',
  CLOSED: '#D6D6D6',
  CONNECT: '#CAE1C0',
  MEETING: '#99C5FA',
  VIEWING: '#99C5FA',
  MORNINGMEETING: '#C7E7FF',
  DAILYUPDATE: '#C7E7FF',
  MEETINGWITHPP: '#C7E7FF',
  REASSIGN: '#99C5FA',
}
export const SUB_MENUS_LABEL = {
  DIARY: 'Diary',
  OVERDUE: 'Overdue',
}
export const DIARY_TASK_ACTION = {
  Task_Details: { label: 'Lead Details', action: 'task-details' }, //lead details
  Task_Detail: { label: 'Task Details', action: 'details' },
  LEAD_ADDITIONAL_DETAILS: { label: 'Additonal Information', action: 'additional-details' },
  EDIT_TASK: { label: 'Edit Task', action: 'edit-task' },
  MARK_AS_DONE: { label: 'Mark as Done', action: 'mark-as-done' },
  REASSIGN_LEAD: { label: 'Re Assign', action: 'reassign-lead' },
  REFER_LEAD: { label: 'Refer Lead', action: 'refer-lead' },
  DELETE: { label: 'Delete', action: 'delete' },
  ASSIGN_TO_AREA_MANAGER: { label: 'Assign to Area Manager', action: 'assign-to-area-manager' },
  ASSIGN_TO_CATALOGER: { label: 'Assign to Cataloger', action: 'assign-to-area-manager' },
  ASSIGN_TO_INVESTMENT_ADVISOR: {
    label: 'Assign to Investment Advisor',
    action: 'assign-to-area-manager',
  },

  CANCEL_VIEWING: { label: 'Cancel Viewing', action: 'cancel-viewing' },
  CANCEL_MEETING: { label: 'Cancel Meeting', action: 'cancel-meeting' },
  CONNECT: { label: 'Connect', action: 'connect' },
  INVESTMENT_GUIDE: { label: 'Add Investment Guide Ref #', action: 'investment-guide' },
  RESCHEDULE_MEETING: { label: 'Reschedule Meeting', action: 'reschedule-meeting' },
  RESCHEDULE_VIEWING: { label: 'Reschedule Viewing', action: 'reschedule-viewing' },
  CLOSE_AS_DUPLICATE: { label: 'Close as Duplicate', action: 'close-as-duplicate' },
  CLOSE_AS_LOST: { label: 'Close as Lost', action: 'close-as-lost' },
  RE_OPEN_LEAD: { label: 'Re-Open Lead', action: 're-open' },
}

export const BUY_RENT_LEAD_TYPE_SALE = 'Buy'
export const BUY_RENT_LEAD_TYPE_RENT="Rent"

export const LEAD_ORIGIN = [
  {
    label: 'ARMS',
    value: 'arms',
  },
  {
    label: 'Wanted',
    value: 'wanted',
  },
  {
    label: 'Facebook',
    value: 'facebook',
  },
  {
    label: 'Graana Project Page',
    value: 'graana_project_page_lead',
  },
]

export const LeadStatusURL = [
  '/buy-rent-lead',
  '/buy-rent-my-lead',
  '/buy-rent-deal',
  '/buy-rent-demand-lead',
  '/referred-buy-rent-lead',
  '/referred-buy-rent-deal',
  '/buy-rent-company-lead',
  '/buy-rent-deactivated-lead',
  '/buy-rent-deactivated-deal',
  '/team-buy-rent-lead',
  '/team-buy-rent-deal',
  '/project-lead',
  '/project-my-lead',
  '/project-company-lead',
  '/referred-project-lead',
  '/project-deactivated-lead',
  '/team-project-lead',
  '/project-deal',
  '/referred-project-deal',
  '/project-deactivated-deal',
  '/team-project-deal',
]

export const PRODUCTS = [
  { label: 'ARMS', value: 'ARMS' },
  { label: 'CXP Portal', value: 'CXP' },
]

export const INVENTORY_DETAIL_VARS = [
  {label: 'Unique Code:', value: 'unique_code'},
  {label: 'Inventory Type:', value: 'inventory_type'},
  {label: 'Inventory Total Rooms:', value: 'total_rooms'},
  {label: 'Total Washrooms:', value: 'total_washrooms'},
  {label: 'Inventory Features:', value: 'inventory_features'},
  {label: 'Inventory Rate per Sq.ft:', value: 'rate_per_sq_ft'},
  {label: 'Category View:', value: 'label', is_multi: true ,RootInnerObj :'category_view'},
  {label: 'Tower Name:', value: 'tower'},
  {label: 'Sale Type:', value: 'sale_type'},
  {label: 'Inventory Is Fractional:', value: 'is_fractional'},
  {label: 'Status:', value: 'status'},
  {label: 'Remaining Sq.ft:', value: 'remaining_sq_ft'},
  {label: 'Old Reference No:', value: 'old_reference_no'},
  // {label: 'Created Date:', value: 'created_date', isDate: true}
]

// export const productTypesInstallment = [
//   { label: "Total Amount:", value: 'total_unit_price' },
//   { label: "Down Payment", labelOf:"down_payment_percentage_Value", value: 'down_payment_Value',showPercentage:true,},
//   { label: "Possession Charges",labelOf:"possession_charges_percentage_Value",  value: 'possession_charges_Value',showPercentage:true},
//   { label: "Installment Frequency:",is_multi: true,RootInnerObj :'types', value: 'installment_frequency'  },
//   { label: "Number of Installment:", is_multi: true,RootInnerObj :'types', value: 'installments' },
//   { label: "Installment Amount:", value: 'installemt_ammount_Value'},
//   { label: "Per month Amount:", value: 'per_month_Amount_Value'},
// ];

export const productTypesInstallment = [
  { label: "Total Amount:", value: 'total_unit_price' },
  { label: "Down Payment",is_multi: true,RootInnerObj :'types', value: 'down_payment',showPercentage:true,},
  { label: "Possession Charges",is_multi: true,RootInnerObj :'types', value: 'possession_charges',showPercentage:true},
  { label: "Installment Frequency:",is_multi: true,RootInnerObj :'types', value: 'installment_frequency'  },
  { label: "Number of Installment:", is_multi: true,RootInnerObj :'types', value: 'installments' },
  { label: "Installment Amount:", is_multi: true,RootInnerObj :'types', value: 'installment_amount'},
  { label: "Per month Amount:", is_multi: true,RootInnerObj :'types', value: 'per_month_amount'},
];

export const productTypesRental = [
  { label: "Total Amount:", value: 'total_unit_price' },
  { label: "Min Rental Amount (annual)",is_multi: true,RootInnerObj :'types', value: 'min_rental_percentage',showPercentage:true,},
  { label: "Max Rental Amount (annual)",is_multi: true,RootInnerObj :'types', value: 'max_rental_percentage',showPercentage:true},
  { label: "Min Appreciation Amount (annual)",is_multi: true,RootInnerObj :'types', value: 'min_appreciation_percentage',showPercentage:true},
  { label: "Max Appreciation Amount (annual)", is_multi: true,RootInnerObj :'types', value: 'max_appreciation_percentage',showPercentage:true},
];

export const projectDetails = [
  { label: "Project Name:", is_multi: true,RootInnerObj :'project', value: "name" },
  { label: "Project Code:", is_multi: true,RootInnerObj :'project', value: "project_code" },
  { label: "Project Type:", is_multi: true,RootInnerObj :'project', value: "project_type" },
  { label: "Internal/External:", is_multi: true,RootInnerObj :'project', value: "internal_or_external" },
  { label: "Common Area:", is_multi: true,RootInnerObj :'project', value: "common_area" },
  { label: "Constructed Area:", is_multi: true,RootInnerObj :'project', value: "net_area" },
  { label: "Gross Area:", is_multi: true,RootInnerObj :'project', value: "gross_area" },
  { label: "Total Floor:", is_multi: true,RootInnerObj :'project', value: "total_floor" },
  { label: "Developer:", is_multi: true,RootInnerObj :'project', value: "developer_id" },
  { label: "Project Booking Date:", is_multi: true,RootInnerObj :'project', value: "project_booking_date" },
  { label: "Installment Available:", is_multi: true,RootInnerObj :'project', value: "installment_available" },
  { label: "Rental Available:", is_multi: true,RootInnerObj :'project', value: "rental_available" },
  { label: "Investment Available:", is_multi: true,RootInnerObj :'project', value: "investment_available" },
  { label: "Parking Slot Available:", is_multi: true,RootInnerObj :'project', value: "parking_slot_available" },
  { label: "Category Charges Available:", is_multi: true,RootInnerObj :'project', value: "category_charges_available" },
  // { label: "Created At:", is_multi: true,RootInnerObj :'project', value: "created_at", isDate: true },
  { label: "Construction Status:", is_multi: true,RootInnerObj :'project', value: "construction_status" },
  { label: "Project Status:", value: "status" },
];

export const floorDetails = [
  { label: "Floor Name:", is_multi: true,RootInnerObj :'floor', value: "floor_name" },
  { label: "Floor Description:", is_multi: true,RootInnerObj :'floor', value: "floor_description" },
  { label: "Gross Area:", is_multi: true,RootInnerObj :'floor', value: "gross_area" },
  { label: "Floor Net Area:", is_multi: true,RootInnerObj :'floor', value: "floor_net_area" },
  { label: "Floor Common Area:", is_multi: true,RootInnerObj :'floor', value: "floor_common_area" },
  { label: "Floor Type:", is_multi: true,RootInnerObj :'floor', value: "floor_type" },
  // { label: "Create Date:", is_multi: true,RootInnerObj :'floor', value: "created_at", isDate: true },
];


