/** @format */

import React, { useEffect, useState } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import { normalizeCnicAndNTN } from '../../../../utils/formatCnic'
import Chip from '../../../common/Chip'
import AddClient from '../../../../views/AddClient'
import {isAira } from '../../../../components/common/LeadView/Wanted/WantedUtilHelper'

const useStyles = makeStyles({
  clientSection: { gridArea: 'CLIENT' },
  form: {
    overflowY: 'hidden',
    overflowX: 'hidden',
    '&:hover': {
      overflowY: 'auto',
    },
  },
  clientInfo: { maxHeight: 500 },
  data: { fontWeight: 'bold', color: '#026ff2' },
})

let ClientReadView = (props) => {
  const classes = useStyles(props)
  const { customer, editable, checkForRead, setLeadCustomer } = props
  const [activeClientModal, setActiveClientModal] = useState(false)
  const [detailToggle, setDetailToggle] = useState(false)
  const [generateLoader, setGenerateLoader] = useState(false)

  const detailToggleFunc = () => {
    setDetailToggle(!detailToggle)
    setGenerateLoader(true)
  }

  const clientModalToggle = () => {
    setActiveClientModal(!activeClientModal)
    setDetailToggle(!detailToggle)
  }

  const apiCallDoneClientEdit = (data) => {
    setActiveClientModal(!activeClientModal)
    setDetailToggle(!detailToggle)
    setLeadCustomer({
      ...customer,
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      cnic: data.cnic,
      address: data.address,
      secondary_address: data.secondary_address,
      customerContacts: data.customersContacts
    })
  }

  return (
    <>
      <div className={`${classes.clientSection} pr-4 border-right`}>
        <div className={'d-flex justify-content-between'}>
          <div className={'d-flex align-items-center'} style={{ columnGap: 10 }}>
            <h4>Client Details</h4>
            {((checkForRead &&
              !checkForRead.diaryReadOnly &&
              editable) || isAira()) && (
                <>
                  {/*<a target={'_blank'} href={`/edit-client/${customer.id}`}>
                    <i className={'fa fa-edit text-primary mb-2'}></i>
                  </a>*/}
                  <span style={{cursor: 'pointer'}} onClick={() => setActiveClientModal(true)}>
                    <i className={'fa fa-edit text-primary mb-2'}></i>
                  </span>
                </>
              )}
          </div>
          {customer && customer.blacklisted && (
            <div style={{ marginTop: 1 }}>
              <Chip
                borderRadius={15}
                text={'Blacklisted'}
                chipColor={'#cccccc'}
                padding={'5px 15px'}
              />
            </div>
          )}
        </div>
        <div className={`${classes.form} ${classes.clientInfo} pr-2 mt-3`}>
          <div className={`${classes.label}`}>First Name</div>
          <div className={`${classes.data}`}>{customer && customer.first_name || '--'}</div>
          <hr />
          <div className={`${classes.label}`}>Last Name</div>
          <div className={`${classes.data}`}>{customer && customer.last_name || '--'}</div>
          <hr />
          {editable ? (
            <>
              <div className={`${classes.label}`}>Primary #</div>
              <div className={`${classes.data}`}>{customer && customer.phone}</div>
              <hr />
              {!props.isReadOnly &&
              customer && customer.customerContacts &&
              Array.isArray(customer.customerContacts) &&
                customer.customerContacts
                  .filter((p) => p.phone !== customer.phone)
                  .map((phone, index) => (
                    <>
                      <div className={`${classes.label}`}>Secondary #</div>
                      <div className={`${classes.data}`}>{phone.phone || '--'}</div>
                      <hr />
                    </>
                  ))}
            </>
          ) : (
            <></>
          )}

          <div className={`${classes.label}`}>Email</div>
          <div className={`${classes.data}`}>{customer && customer.email || '--'}</div>
          <hr />
          <div className={`${classes.label}`}>CNIC/NTN</div>
          <div className={`${classes.data}`}>
            {customer && customer.cnic ? normalizeCnicAndNTN(customer.cnic) : '--'}
          </div>
          <hr />
          <div className={`${classes.label}`}>Address</div>
          <div className={`${classes.data}`}>{customer && customer.address || '--'}</div>
          <hr />
          <div className={`${classes.label}`}>Secondary Address</div>
          <div className={`${classes.data}`}>{customer && customer.secondary_address || '--'}</div>
        </div>
      </div>

      {activeClientModal &&
      <AddClient
        activeClientModal={activeClientModal}
        fromPage={'buyrentlead'}
        showAdditionInfo={true}
        apiCallDonClientCreated={apiCallDoneClientEdit}
        shareToggle={detailToggleFunc}
        clientModalToggle={clientModalToggle}
        editable={true}
        clientId={customer && customer.id}
      />}
    </>
  )
}

export const mapDispatchToProps = (dispatch) => {
  return {}
}

const mapStateToProps = (state) => {
  return {}
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ClientReadView))
