import React, {useEffect, useState,useRef} from 'react';
import axios from "axios";
import config from "../../config";
import {hourFormat} from "../../views/CallCenter/InboundLog";
import moment from "moment";
import { CSVLink } from 'react-csv'
import { setTitle } from "../../actions/generalSetting";
import { connect } from 'react-redux'

const ActiveAgentsReports = (props) => {
  const csvLink = useRef();
  const {setTitle}=props;
  const [agents, setAgents] = useState([])
  const [csvData, setCsvData] = useState([])
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [filters, setFilters] = useState({
    fromDate: moment().startOf('month'),
    toDate: moment().endOf('month'),
    queueId:{},
    agentName:{},
    status:{}
  })
  useEffect(() => {
    setTitle({ name: 'Active Agents' })
    getActiveAgents().then((results) => {
      setAgents(results)
    }).catch((error) => {
      setAgents(error)
    })
  }, [])
  const getActiveAgents = () => {
    return new Promise((resolve, reject) => {
      axios.post(`${config.apiPath}/api/callcenter/asterisk-active-users`, {}).then((res) => {
        if (res.data.success) {
          resolve(res.data.data)
        } else {
          reject([])
        }
      }).catch((error) => {
        reject([])
      })
    })
  }
  const getCsvData = async () => {
 await axios
    .post(
      `${config.apiPath}/api/callcenter/asterisk-active-users`,{}
    )
    .then((res) => {

    const allRebates = res.data.data.length > 0
            ? res.data.data.map((data) => (
              {
                'Agent ID': data.agentId,
                'Agent Name': data.agentName,
                'First Login': data.loginTime?moment(data.loginTime).format('DD-MM-YYYY (hh:mm A)'):'',
                'Last Login': data.loginAt ? moment(data.loginAt).format('DD-MM-YYYY (hh:mm A)'):'',
                'Time Spent': secondsToHms(data.total_seconds),
                'Status': data.status,


            }))
            : [];
            setCsvData(allRebates);
          })
          .catch((e) => console.log(e))
        csvLink.current.link.click()
  }
  const secondsToHms = (d) => {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);
    var hDisplay = h > 0 ? h + (h == 1 ? "" : "") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? "" : "") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? ""  : "") : "";
    console.log(hDisplay,mDisplay,sDisplay);
    if (hDisplay.length===1){
      hDisplay = "0"+hDisplay;
    } else if(hDisplay.length ===0){
      hDisplay = "00";
    }
    console.log(mDisplay.length,'mDisplay.length')
    if (mDisplay.length===1){
      mDisplay = "0"+mDisplay;
    } else if(mDisplay.length===0) {
      mDisplay = "00";
    }
    if (sDisplay.length===1){
      sDisplay = "0"+sDisplay;
    } else if (sDisplay.length===0){
      sDisplay = "00";
    }
    return `${hDisplay}:${mDisplay}:${sDisplay}`;
  }
  return (
    <>
      <div className="card mt-2">
    <div className="card-header">
          <div className="col-md-12 row justify-content-end ">
      <button  onClick={getCsvData}className="newFormSearch ml-1">
        <span className={`fa fa-download`}></span>
                        </button>
                        <CSVLink
                          data={csvData}
                          filename="Active_agents.csv"
                          className="hidden"
                          ref={csvLink}
                          target="_blank"
                        />
                        </div>
        </div>
        <div className="card-body">
          <div className="reports-table">
          <table style={{ height: '80%' }}>
            <thead>
            <tr>
              <th style={{ minWidth: '160px' }}>#</th>
              <th style={{ minWidth: '160px' }}>Agent Name</th>
              <th style={{ minWidth: '160px' }}>Agent Id</th>
              <th style={{ minWidth: '160px' }}>First Login</th>
              <th style={{ minWidth: '160px' }}>Last Login</th>
              <th style={{ minWidth: '160px' }}>Time Spent</th>
              <th style={{ minWidth: '160px' }}>Agent Status</th>
            </tr>
            </thead>
            <tbody>
            {
              agents.map((agent, index) => {
                return (
                  <tr key={index}>
                    <td style={{ minWidth: '160px' }}>{index+1}</td>
                    <td style={{ minWidth: '160px' }}>{agent.agentName}</td>
                    <td style={{ minWidth: '160px' }}>{agent.agentId}</td>
                    <td style={{ minWidth: '160px' }}>{moment(agent.loginTime).format('DD-MM-YYYY (hh:mm A)')}</td>
                    <td style={{ minWidth: '160px' }}>{moment(agent.loginAt).format('DD-MM-YYYY (hh:mm A)')}</td>
                    <td style={{ minWidth: '160px' }}>{secondsToHms(agent.total_seconds)}</td>
                    <td style={{ minWidth: '160px' }}>{agent.status}</td>
                  </tr>
                )
              })
            }
            </tbody>
          </table>
          </div>
        </div>
      </div>
    </>
  )
}

const mapDispatchToProps = (dispatch) => ({
  setTitle: (title) => dispatch(setTitle(title)),
})

const mapStateToProps = (state) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(ActiveAgentsReports);

