/** @format */

import React, { useEffect, useState, useRef } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Input, InputGroup, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import moment from 'moment'
import axios from 'axios'
import config from '../../../config'
import Select from 'react-select'
import SlotModal from '../../SlotManagement/SlotModal'
import Button from '../../common/Button'
import setHours from 'date-fns/setHours'
import setMinutes from 'date-fns/setMinutes'
import { capitalizeWordsWithoutUnderscore } from '../../../utils/commonFormat'
import { editDiary, investmentTask } from '../../../actions/leads'
import { connect } from 'react-redux'
import Calendar from '../../../images/calendar.svg'
import { getDiaryFeedbacks } from '../../../actions/diary'
import { PropertyWorkFlowTile } from '../../AddPropertyWorkflow/PropertyWorkFlowTile'
import Tooltip from '../../common/Tooltip/Tooltip'
import clsx from 'clsx'
import { TaskTypeLabels } from '../../../utils/constant_data'
import LeadSelection from '../../common/Diary/LeadSelection'
import PropertySelection from '../../common/Diary/PropertySelection'
import { PermissionActions, PermissionFeatures } from '../../../utils/permissionConstants'
import { getPermissionValue } from '../../_unlockComponent'
import { showToastMsg } from '../../../utils/helper'
import { withRouter } from 'react-router-dom'
const useStyles = makeStyles({
  root: {
    display: 'grid',
    gridAutoColumns: '40fr 60fr',
    gridAutoFlow: 'column',
    gridTemplateAreas: '"HISTORY CLIENT"\n' + '"CONNECT CONNECT"',
  },
  reasonBackground: (props) =>
    props.diary &&
    props.diary.reasonTag !== null &&
    props.diary.reason && {
      border:
        props.diary && props.diary.reasonTag !== null && props.diary.reason
          ? `1px solid ${props.diary.reason.colorCode}`
          : 'none',
      borderRadius: '15px',
      padding: '5px 10px 5px 10px',
    },
  outcomeBackground: (props) =>
    props.diary &&
    props.diary.feedbackTag !== null && {
      border:
        props.diary && props.diary.feedbackTag !== null && props.diary.armsFeedback
          ? `1px solid ${props.diary.armsFeedback.colorCode}`
          : 'none',
      borderRadius: '15px',
      padding: '5px 10px 5px 10px',
    },
  historySection: { gridArea: 'HISTORY', background: '#fafafa' },
  formSection: { gridArea: 'CLIENT' },
  connectSection: { gridArea: 'CONNECT' },
  notes: {
    display: '-webkit-box',
    '-webkit-line-clamp': '4',
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
  },
})

const DiaryTaskModal = (props) => {
  const { classes, reasonBackground, outcomeBackground, notes } = useStyles(props)
  const {
    leadId,
    taskModalToggle,
    setTaskModalAction,
    setLeadTasks,
    callBack,
    lead,
    leadType,
    addTask,
    diary,
    diaryAction,
    taskCategory,
    setDiary,
    fetchDiaryTasks,
    fetchDiaryFeedbacks,
    diaryFeedbacks,
    screen,
  } = props
  const [slotModal, setSlotModal] = useState(false)
  const [bookedTime, setBookedTime] = useState(null)
  const [editTask, setEditTask] = useState(false)
  const [loading, setLoading] = useState(false)
  const [taskFeedback, setTaskFeedback] = useState(null)
  const [property, setProperty] = useState(null)
  const [leadSelectionOpen, setLeadSelectionOpen] = useState(false)
  const [propertySelectionOpen, setPropertySelectionOpen] = useState(false)

  let section = 'Internal'

  let leadTypeParam = ''
  let taskTypes = []
  let reasons = []

  if (diaryFeedbacks) {
    Object.keys(diaryFeedbacks).map((key, j) => {
      diaryFeedbacks[key].map((feedback, i) => {
        feedback.tags &&
          feedback.tags.map((tag, k) => {
            reasons = [
              ...reasons,
              {
                fid: feedback.id,
                value: tag,
                label: tag,
              },
            ]
          })
      })
    })
  }

  if (leadType === 'Project') {
    leadTypeParam = 'cmLeadId'
    taskTypes = [
      {
        value: 'meeting',
        label: TaskTypeLabels.meeting,
      },
      {
        value: 'follow_up',
        label: TaskTypeLabels.follow_up,
      },
    ]
  } else if (leadType === 'BuyRent') {
    leadTypeParam = 'rcmLeadId'
    taskTypes = [
      {
        value: 'meeting',
        label: TaskTypeLabels.meeting,
      },
      {
        value: 'follow_up',
        label: TaskTypeLabels.follow_up,
      },
    ]
  } else if (leadType === 'Wanted') {
    leadTypeParam = 'wantedId'
    taskTypes = [
      {
        value: 'follow_up',
        label: TaskTypeLabels.follow_up,
      },
    ]
  } else {
    taskTypes = [
      {
        value: 'morning_meeting',
        label: TaskTypeLabels.morning_meeting,
      },
      {
        value: 'daily_update',
        label: TaskTypeLabels.daily_update,
      },
      {
        value: 'meeting_with_pp',
        label: TaskTypeLabels.meeting_with_pp,
      },
      (getPermissionValue(PermissionFeatures.PROJECT_LEADS, PermissionActions.UPDATE) ||
        getPermissionValue(PermissionFeatures.BUY_RENT_LEADS, PermissionActions.UPDATE)) && {
        value: 'meeting',
        label: 'Meeting with Client',
      },
      {
        value: 'follow_up',
        label: TaskTypeLabels.follow_up,
      },
      getPermissionValue(PermissionFeatures.BUY_RENT_LEADS, PermissionActions.UPDATE) && {
        value: 'viewing',
        label: TaskTypeLabels.viewing,
      },
    ].filter((f) => f)
  }

  const initialTaskObj = {
    subject: 'Scheduled task',
    date: setHours(setMinutes(new Date(), 30), 16),
    taskType: taskTypes.length == 1 ? taskTypes : '',
    description: '',
    diaryTime: '',
    reasons: '',
    isRecurring: false,
    slots: '',
    end: moment(new Date()).add(2, 'hours').format('HH:mm'),
    lead: null,
  }
  const [taskObj, setTaskObj] = useState(initialTaskObj)

  const taskChangeHandler = (value) => {
    setTaskObj({ ...taskObj, taskType: value })
  }
  const notesRef = useRef(null)
  const [notesTooltipOpen, setNotesTooltipOpen] = useState(false)

  const title = diaryAction === 'add-lead-task' ? 'Add Lead Task' : 'Add Task'

  useEffect(() => {
    if (diaryAction && diaryAction === 'add-lead-task') {
      if (taskTypes.length === 1) {
        taskChangeHandler(taskTypes[0])
        fetchDiaryFeedbacks({
          section,
          leadType: capitalizeWordsWithoutUnderscore(leadType),
          taskType: capitalizeWordsWithoutUnderscore(taskTypes[0].value, true),
        })
      }
    }
    if (diaryAction && diaryAction === 'add') {
      setDiary(null)
    }
    if (
      diaryAction &&
      diaryAction === 'detail' &&
      diary &&
      diary.taskType === 'viewing' &&
      !property
    ) {
      let leadDetail = null
      if (leadType && leadType === 'Project') {
        leadDetail = diary.armsProjectLead
      }
      if (leadType && leadType === 'BuyRent') {
        leadDetail = diary.armsLead
      }
      if (leadDetail) {
        let propertyId = diary.propertyId
        let origin = leadDetail.origin
        if (origin === 'arms') {
          axios
            .get(`${config.apiPath}/api/inventory/${propertyId}`)
            .then((res) => {
              setProperty(res.data)
            })
            .catch((err) => {})
        }
      }
    }
    if (diaryAction === 'quick-edit') {
      setEditTask(true)
    }
    props.callBack && props.callBack(false)
  }, [])

  useEffect(() => {
    if (diary && editTask) {
      setTaskObj({
        ...taskObj,
        /*taskType: taskTypes.find(o => o.value === diary.taskType),*/
        description: diary.notes,
        date: diary.date,
        end: diary.end,
        diaryTime: diary.start,
        slots: diary.slots,
      })
      !diaryFeedbacks &&
        leadType &&
        diary.taskType !== 'meeting' &&
        diary.taskCategory !== 'simpleTask' &&
        fetchDiaryFeedbacks({
          section,
          leadType: capitalizeWordsWithoutUnderscore(leadType),
          taskType: capitalizeWordsWithoutUnderscore(diary.taskType, true),
        })
    }
  }, [editTask])

  const addTaskFunc = (action) => {
    setLoading(true)
    const user_id = localStorage.getItem('user_id')

    let taskSubject = taskObj.subject
    if (action === 'add-lead-task') {
      taskSubject = `${capitalizeWordsWithoutUnderscore(taskObj.taskType.value)} with ${
        lead && lead.customer ? lead.customer.customerName : 'client'
      }`
    }

    let taskData = {
      //[leadTypeParam]: leadId,
      date: moment(taskObj.date).format('YYYY-MM-DDTHH:mm:ssZ'),
      diaryTime: moment(taskObj.diaryTime).format('YYYY-MM-DDTHH:mm:ssZ'),
      status: 'pending',
      start: moment(taskObj.diaryTime).format('YYYY-MM-DDTHH:mm:ssZ'),
      slots: taskObj.slots,
      // armsProjectLeadId: leadType && leadType === 'project' ? lead.id : null,
      leadId:
        leadType && leadType === 'Project'
          ? lead.id
          : taskObj.leadType === 'project'
          ? taskObj.lead.id
          : null,
      wantedId:
        leadType && leadType === 'Wanted'
          ? lead.id
          : taskObj.leadType === 'wanted'
          ? taskObj.lead.id
          : null,
      armsLeadId:
        leadType && leadType === 'BuyRent'
          ? lead.id
          : taskObj.leadType === 'buyrent'
          ? taskObj.lead.id
          : null,
      reasons: taskObj.reasons,
      end: moment(taskObj.end).format('YYYY-MM-DDTHH:mm:ssZ'),
      subject: taskSubject,
      taskType: taskObj.taskType ? taskObj.taskType.value : 'task',
      time: taskObj.diaryTime,
      notes: taskObj.description,
      userId: props.userId ? props.userId : user_id,
      taskCategory: taskCategory ? taskCategory : 'leadTask',
      reasonId: taskFeedback && taskFeedback.id ? taskFeedback.id : null,
      reasonTag: taskFeedback && taskFeedback.tag ? taskFeedback.tag : null,
      isRecurring: taskObj.isRecurring,
      addedBy: props.userId ? props.userId : 'self',
    }
    if (
      taskObj &&
      taskObj.taskType &&
      taskObj.taskType.value === 'viewing' &&
      taskObj.lead &&
      taskObj.property
    ) {
      axios
        .post(`${config.apiPath}/api/leads/viewing`, {
          date: taskData.date,
          time: taskData.time,
          diaryTime: taskData.diaryTime,
          start: taskData.start,
          end: taskData.end,
          propertyId: taskObj.property.id,
          leadId: taskData.armsLeadId,
          subject:
            'Viewing' +
            (taskObj.lead.customer && ' with ' + taskObj.lead.customer.customerName) +
            (taskObj.property && taskObj.property.area && ' at ' + taskObj.property.area.name),
          taskCategory: 'leadTask',
          customer_Id: taskObj.lead.customer && taskObj.lead.customer.id,
          slots: taskData.slots,
          userId: taskData.userId,
        })
        .then((res) => {
          props.closeTaskModal()
          props.callBack && props.callBack(true)
          setBookedTime(null)
          showToastMsg('Task created successfully', 'success')
          if (screen && screen === 'diary') {
            fetchDiaryTasks()
          }
        })
        .catch((error) => {
          console.log(error)
        })
    } else {
      addTask(
        taskData,
        (leadId, data) => {
          props.closeTaskModal()
          props.callBack && props.callBack(true)
          setBookedTime(null)
          showToastMsg('Task created successfully', 'success')
          {
     
          }
          if (screen && screen === 'diary') {
            fetchDiaryTasks()
          } else if (screen === undefined || screen !== 'diary') {
            setTimeout(() => {
              props.history.push(`/diary`)
            }, 2000)
          }
        },
        leadId
      )
    }
  }

  const editTaskFunc = () => {
    setLoading(true)
    const user_id = localStorage.getItem('user_id')

    let taskData = {
      [leadTypeParam]: leadId,
      date: taskObj.date,
      diaryTime: moment(taskObj.diaryTime).format('YYYY-MM-DDTHH:mm:ssZ'),
      start: moment(taskObj.diaryTime).format('YYYY-MM-DDTHH:mm:ssZ'),
      end: moment(taskObj.end).format('YYYY-MM-DDTHH:mm:ssZ'),
      slots: taskObj.slots,
      time: taskObj.diaryTime,
      notes: taskObj.description,
      userId: user_id,
      addedBy: 'self',
    }
    axios
      .patch(`${config.apiPath}/api/diary/update?id=${diary.id}`, taskData)
      .then((res) => {
        props.closeTaskModal()
        setTaskObj(initialTaskObj)
        setLoading(false)
        showToastMsg('Task updated successfully', 'success')
        if (screen && screen === 'diary') {
          fetchDiaryTasks()
        } else if (screen && screen === 'lead-task') {
          fetchDiaryTasks(leadType)
        }
      })
      .catch((error) => {
        showToastMsg(error, 'error')
        setLoading(false)
      })
  }
  const getIsAddButtonDisable = () => {
    let hasLead = true
    let hasProperty = true
    if (
      diaryAction === 'add' &&
      (taskObj.taskType.value === 'follow_up' ||
        taskObj.taskType.value === 'meeting' ||
        taskObj.taskType.value === 'viewing') &&
      !taskObj.lead
    ) {
      hasLead = false
    }
    if (diaryAction === 'add' && taskObj.taskType.value === 'viewing' && !taskObj.property) {
      hasProperty = false
    }
    if (taskObj.taskType.value && taskObj.diaryTime && !loading && hasLead && hasProperty) {
      return false
    }
    return true
  }
  return (
    <>
      <Modal
        isOpen={taskModalToggle}
        size={diary && diary.taskType === 'viewing' && property ? 'lg' : 'md'}
      >
        <ModalBody className={'pb-2 pt-5 pl-5 pr-5'}>
          <div className="popup-body-header-title">
            <div className={'row'}>
              <div className={'col-md-12'}>
                <h4>
                  {!editTask &&
                    (diaryAction === 'add' || diaryAction === 'add-lead-task'
                      ? title
                      : diaryAction === 'detail'
                      ? 'Task Details'
                      : diaryAction === 'edit' || diaryAction === 'quick-edit'
                      ? 'Edit Task'
                      : '')}
                  {editTask && 'Edit Task'}
                </h4>
              </div>
            </div>
          </div>
          <div className="close-icon-container">
            <i
              className="fal fa-times-circle close-icon"
              onClick={() => {
                if (diary !== null) {
                  setDiary(null)
                }
                setBookedTime(null)
                setProperty(null)
                props.closeTaskModal()
              }}
            ></i>
          </div>

          <div className="row">
            {diaryAction &&
              diaryAction === 'detail' &&
              diary &&
              diary.taskType === 'viewing' &&
              property && (
                <>
                  <div className={'w-100'}>
                    <PropertyWorkFlowTile property={property} />
                  </div>
                </>
              )}
            {diaryAction === 'add' || diaryAction === 'add-lead-task' || editTask ? (
              <>
                <div
                  className={
                    editTask
                      ? `col-md-12 col-lg-12 pt-3 p-0 d-flex`
                      : `col-md-12 col-lg-12 pt-2 pb-2`
                  }
                >
                  <h6 className={editTask ? `col-md-3 pb-1 small` : `pb-1 small`}>Task Type</h6>
                  {!editTask && (
                    <Select
                      className="taskType"
                      options={taskTypes}
                      value={taskObj.taskType !== null ? taskObj.taskType : null}
                      menuPortalTarget={document.body}
                      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                      onChange={(e) => {
                        taskChangeHandler(e)
                        e.value !== 'meeting' &&
                          fetchDiaryFeedbacks({
                            section,
                            leadType: capitalizeWordsWithoutUnderscore(leadType),
                            taskType: capitalizeWordsWithoutUnderscore(e.value, true),
                          })
                      }}
                      placeholder="Select Task Type"
                      closeMenuOnSelect={true}
                    />
                  )}
                  {editTask && diary && (
                    <span className={`col-md-9 small`}>
                      {capitalizeWordsWithoutUnderscore(diary.taskType, true)}
                    </span>
                  )}
                </div>

                {editTask &&
                  diary &&
                  diary.taskCategory &&
                  diary.taskCategory === 'simpleTask' &&
                  diary.isRecurring && (
                    <div className="col-md-12 col-lg-12 pt-3 p-0 d-flex">
                      <h6 className={'col-md-3 font-sm'}>Recurring Task</h6>
                      <div className={`col-md-9 font-sm`}>Yes</div>
                    </div>
                  )}
                {!editTask &&
                  diaryAction === 'add' &&
                  (taskObj.taskType.value === 'follow_up' ||
                    taskObj.taskType.value === 'meeting' ||
                    taskObj.taskType.value === 'viewing') && (
                    <div className="col-md-12 col-lg-12 pt-2 pb-2">
                      <h6 className="pb-1 small">Lead</h6>
                      <div
                        className="form-control"
                        style={{ color: '#6f777f', cursor: 'pointer' }}
                        onClick={() => {
                          setLeadSelectionOpen(true)
                        }}
                      >
                        {taskObj.lead ? (
                          <>
                            <span style={{ marginRight: '10px', color: '#495057' }}>
                              {taskObj.lead.id}
                            </span>
                            <span>
                              {taskObj.lead.customer ? taskObj.lead.customer.customerName : ''}
                            </span>
                          </>
                        ) : (
                          'Select Lead'
                        )}
                      </div>
                    </div>
                  )}
                {!editTask && diaryAction === 'add' && taskObj.taskType.value === 'viewing' && (
                  <div className="col-md-12 col-lg-12 pt-2 pb-2">
                    <h6 className="pb-1 small">Property</h6>
                    <div
                      className="form-control"
                      style={{ color: '#6f777f', cursor: taskObj.lead ? 'pointer' : 'not-allowed' }}
                      onClick={() => {
                        taskObj.lead && setPropertySelectionOpen(true)
                      }}
                    >
                      {taskObj && taskObj.property ? (
                        <span style={{ color: '#495057' }}>
                          {taskObj.property.size +
                            ' ' +
                            taskObj.property.size_unit +
                            ' ' +
                            taskObj.property.subtype +
                            ' in ' +
                            (taskObj.property.area && taskObj.property.area.name)}
                        </span>
                      ) : (
                        'Select Property'
                      )}
                    </div>
                  </div>
                )}
                {!editTask &&
                  // diaryAction === 'add-lead-task' &&
                  diaryFeedbacks &&
                  taskObj.taskType.value === 'follow_up' && (
                    /*|| (editTask && diary && diaryFeedbacks && diary.taskType === 'follow_up')*/ <div className="col-md-12 col-lg-12 pt-2 pb-2">
                      <h6 className="pb-1 small">Reason</h6>
                      <Select
                        className="reason"
                        placeholder="Select Reason"
                        options={reasons}
                        /*value={
                        diary && diary.reasonTag !== '' && ({value: diary.reasonTag, label: diary.reasonTag})
                      }*/
                        isDisabled={editTask}
                        closeMenuOnSelect={true}
                        onChange={(e) => {
                          setTaskFeedback({ ...taskFeedback, id: e.fid, tag: e.label })
                        }}
                      />
                    </div>
                  )}
                {editTask &&
                  diary &&
                  diary.taskCategory &&
                  diary.taskCategory === 'leadTask' &&
                  diary.taskType === 'follow_up' && (
                    <div className="col-md-12 col-lg-12 pt-3 p-0 d-flex">
                      <h6 className={'col-md-3 font-sm'}>Reason</h6>
                      <div className={`col-md-9 font-sm`}>
                        <span className={reasonBackground}>
                          {diary.reasonTag ? diary.reasonTag : '--'}
                        </span>
                      </div>
                    </div>
                  )}
                <div className="col-md-12 col-lg-12 pt-3 pb-2">
                  <h6 className="pb-1 small">Book Slot</h6>
                  <InputGroup>
                    {/*<img
                    src={Calendar}
                    style={{ position: 'absolute', height: 20, right: 10, top: 10, zIndex: '4' }}
                  />*/}
                    <Input
                      value={
                        bookedTime
                          ? `${moment(bookedTime.startTime).format('hh:mm A')} - ${moment(
                              bookedTime.endTime
                            ).format('hh:mm A')} (${moment(bookedTime.startTime).format(
                              'YYYY-MM-DD'
                            )})`
                          : diary
                          ? `${moment(diary.start).format('hh:mm A')} - ${moment(diary.end).format(
                              'hh:mm A'
                            )} (${moment(diary.date).format('YYYY-MM-DD')})`
                          : ''
                      }
                      onClick={() => setSlotModal(true)}
                      placeholder={'Book Slot'}
                      style={{
                        cursor: 'pointer',
                        caretColor: 'transparent',
                        background: "url('/static/media/calendar.2f0f2863.svg')",
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: '97%',
                        backgroundSize: '5%',
                      }}
                    ></Input>
                  </InputGroup>
                  <SlotModal
                    userId={props.userId ? props.userId : null}
                    slotModal={slotModal}
                    setSlotModal={setSlotModal}
                    title={`Book Time Slot${
                      props.userId && props.lead && props.lead.armsuser
                        ? ` for ${
                            props.lead.armsuser.firstName + ' ' + props.lead.armsuser.lastName
                          }`
                        : ''
                    }`}
                    taskType={taskObj && taskObj.taskType && taskObj.taskType.value}
                    callBack={(bookedTime) => {
                      setSlotModal(false)
                      setBookedTime(bookedTime)
                      setTaskObj({
                        ...taskObj,
                        diaryTime: bookedTime ? bookedTime.startTime : '',
                        date: bookedTime ? bookedTime.date : '',
                        end: bookedTime ? bookedTime.endTime : '',
                        slots:
                          bookedTime && bookedTime.slots
                            ? bookedTime.slots.map((slot) => {
                                return slot.id
                              })
                            : '',
                      })
                    }}
                  />
                </div>

                {diaryAction === 'add' &&
                  taskObj.taskType &&
                  !(
                    taskObj.taskType.value === 'follow_up' ||
                    taskObj.taskType.value === 'meeting' ||
                    taskObj.taskType.value === 'viewing'
                  ) && (
                    <div className="col-md-12 col-lg-12 pt-3 pb-2">
                      <label>
                        <input
                          type="checkbox"
                          name="taxIncluded"
                          className="checkbox-icon"
                          onChange={(e) =>
                            setTaskObj({ ...taskObj, isRecurring: e.target.checked })
                          }
                        />
                        <span className={'pl-2 align-text-top small'}>Recurring Task</span>
                      </label>
                    </div>
                  )}
                <div className="col-md-12 col-lg-12 pt-3 pb-2">
                  <textarea
                    className="task-description form-control"
                    rows={4}
                    placeholder="Description"
                    defaultValue={taskObj.description ? taskObj.description : ''}
                    value={taskObj.description}
                    onChange={(e) => {
                      if (e.target.value.length <= 1000) {
                        setTaskObj({ ...taskObj, description: e.target.value })
                      }
                    }}
                  />
                  {taskObj.description && taskObj.description.length >= 1000 && (
                    <div style={{ fontSize: '10px', color: 'red' }}>
                      Maximum limit of 1000 characters exceeded.
                    </div>
                  )}
                </div>
              </>
            ) : diaryAction === 'detail' && diary ? (
              <>
                <div className="col-md-12 col-lg-12 pt-3 p-0 d-flex">
                  <div className={'col-md-3 font-sm'}>Task Type</div>
                  <div className={'col-md-9 font-sm bold700'}>
                    {capitalizeWordsWithoutUnderscore(diary.taskType, true)}
                  </div>
                </div>
                <div className="col-md-12 col-lg-12 pt-3 p-0 d-flex">
                  <div className={'col-md-3 font-sm'}>Time Slot</div>
                  <div className={'col-md-9 font-sm bold700'}>
                    {diary.start ? moment(diary.start).format('hh:mm A') : '--'} to
                    {diary.end ? ` ${moment(diary.end).format('hh:mm A')}` : '--'}
                    {diary.start ? ` (${moment(diary.start).format('Do MMM, YYYY')})` : '--'}
                  </div>
                </div>
                {diary.isRecurring && (
                  <>
                    <div className="col-md-12 col-lg-12 pt-3 p-0 d-flex">
                      <div className={'col-md-3 font-sm'}>Recurring Task</div>
                      <div className={'col-md-9 font-sm bold700'}>Yes</div>
                    </div>
                  </>
                )}
                {diary.taskCategory &&
                  diary.taskCategory === 'leadTask' &&
                  diary.taskType === 'follow_up' && (
                    <div className="col-md-12 col-lg-12 pt-3 p-0 d-flex">
                      <div className={'col-md-3 font-sm'}>Reason</div>
                      <div className={`col-md-9 font-sm`}>
                        <span className={reasonBackground}>
                          {diary.reasonTag ? diary.reasonTag : '--'}
                        </span>
                      </div>
                    </div>
                  )}
                <div className="col-md-12 col-lg-12 pt-3 p-0 pb-3 d-flex">
                  <div className={'col-md-3 font-sm'}>Description</div>
                  <Tooltip
                    content={diary.notes}
                    direction="right"
                    contentRef={notesRef}
                    open={notesTooltipOpen}
                    styles={{
                      whiteSpace: 'normal',
                      wordBreak: 'break-all',
                      width: '100%',
                      lineHeight: '15px',
                      left: '100%',
                    }}
                  >
                    <div
                      onMouseEnter={() => setNotesTooltipOpen(true)}
                      onMouseLeave={() => setNotesTooltipOpen(false)}
                      className={clsx('col-md-12 font-sm bold700 text-break', notes)}
                      ref={notesRef}
                    >
                      {diary.notes ? diary.notes : '--'}
                    </div>
                  </Tooltip>
                </div>
                {diary.taskCategory && diary.taskCategory === 'leadTask' && (
                  <>
                    {diary.feedbackTag && (
                      <div className="col-md-12 col-lg-12 pt-3 p-0 d-flex">
                        <div className={'col-md-3 font-sm'}>Outcome</div>
                        <div className={`col-md-9 font-sm`}>
                          <span className={outcomeBackground}>
                            {diary.feedbackTag ? diary.feedbackTag : '--'}
                          </span>
                        </div>
                      </div>
                    )}
                  </>
                )}
                {diary.response && (
                  <div
                    className={`col-md-12 col-lg-12  p-0 d-flex ${
                      diary.taskCategory && diary.taskCategory === 'leadTask' ? 'pt-3' : ''
                    }`}
                  >
                    <div className={'col-md-3 font-sm'}>Comments</div>
                    <div className={`col-md-9 font-sm text-break`}>
                      {diary.response ? diary.response : '--'}
                    </div>
                  </div>
                )}
              </>
            ) : (
              <></>
            )}
          </div>
        </ModalBody>
        <ModalFooter className={'justify-content-start pl-4 pb-5'}>
          <div className={'row'}>
            <div className="col-lg-12">
              <div
                className={
                  diaryAction === 'detail' && !editTask
                    ? `btn-wrap d-flex flex-row-reverse`
                    : `btn-wrap`
                }
              >
                <Button
                  text={diaryAction === 'detail' && !editTask ? `OK` : `Cancel`}
                  textColor={diaryAction === 'detail' && !editTask ? 'white' : 'black'}
                  display={'inline-block'}
                  buttonColor={diaryAction === 'detail' && !editTask ? '#026ff2' : '#fff'}
                  className={
                    diaryAction === 'detail' && !editTask
                      ? 'px-4 rounded-pill ml-2 small'
                      : `px-4 rounded-pill mr-2 small`
                  }
                  style={{
                    border:
                      diaryAction === 'detail' && !editTask
                        ? '1px solid transparent'
                        : '1px solid #d3d3d3',
                  }}
                  onClick={() => {
                    setBookedTime(null)
                    setProperty(null)
                    if (!editTask) {
                      if (diary !== null) {
                        setDiary(null)
                      }
                      props.closeTaskModal()
                    } else if (diaryAction === 'quick-edit') {
                      setDiary(null)
                      setEditTask(false)
                      props.closeTaskModal()
                    } else {
                      setEditTask(false)
                    }
                  }}
                />
                {diaryAction === 'add' || diaryAction === 'add-lead-task' ? (
                  <Button
                    text={'Add Task'}
                    textColor={diaryAction === 'detail' && !editTask ? 'black' : 'white'}
                    display={'inline-block'}
                    buttonColor={diaryAction === 'detail' && !editTask ? '#fff' : '#026ff2'}
                    loader={loading}
                    disabled={getIsAddButtonDisable()}
                    className={'px-4 rounded-pill small'}
                    style={{
                      border:
                        diaryAction === 'detail' && !editTask
                          ? '1px solid #d3d3d3'
                          : '1px solid transparent',
                    }}
                    onClick={() =>
                      taskObj.taskType.value && taskObj.diaryTime ? addTaskFunc(diaryAction) : null
                    }
                  />
                ) : (diaryAction === 'detail' || diaryAction === 'quick-edit') && diary ? (
                  <Button
                    text={editTask ? `Update` : `Edit Task`}
                    textColor={diaryAction === 'detail' && !editTask ? 'black' : 'white'}
                    display={'inline-block'}
                    buttonColor={diaryAction === 'detail' && !editTask ? '#fff' : '#026ff2'}
                    loader={loading}
                    className={'px-4 rounded-pill small'}
                    style={{
                      border:
                        diaryAction === 'detail' && !editTask
                          ? '1px solid #d3d3d3'
                          : '1px solid transparent',
                    }}
                    disabled={
                      props.isReadOnly
                        ? true
                        : ['completed', 'cancelled'].includes(diary.status)
                        ? true
                        : loading
                    }
                    onClick={() =>
                      diary && diary.id && !editTask
                        ? setEditTask(true)
                        : editTask
                        ? editTaskFunc()
                        : null
                    }
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </ModalFooter>
      </Modal>
      {leadSelectionOpen && (
        <LeadSelection
          isOpen={leadSelectionOpen}
          setOpen={(open) => {
            setLeadSelectionOpen(open)
          }}
          taskType={taskObj && taskObj.taskType && taskObj.taskType.value}
          onLeadSelection={(lead, leadType) => {
            console.log(lead, leadType)
            setTaskObj({ ...taskObj, lead: lead, leadType: leadType })
            setLeadSelectionOpen(false)
          }}
        ></LeadSelection>
      )}
      {propertySelectionOpen && (
        <PropertySelection
          isOpen={propertySelectionOpen}
          setOpen={(open) => {
            setPropertySelectionOpen(open)
          }}
          // leadId={50108}
          onPropertySelection={(property) => {
            setTaskObj({ ...taskObj, property: property })
          }}
          leadId={taskObj && taskObj.lead && taskObj.lead.id}
        ></PropertySelection>
      )}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    ...state,
    addTaskLoader: state.Leads.addTaskLoader,
    diaryFeedbacks: state.Diary.diaryFeedbacks,
  }
}
const mapdispatchToProps = (dispatch) => {
  return {
    addTask: (data, callback, leadId) => dispatch(investmentTask(data, callback, leadId)),
    editDiary: (data, fetchTimeline, id, leadId) =>
      dispatch(editDiary(data, fetchTimeline, id, leadId)),
    fetchDiaryFeedbacks: (params) => dispatch(getDiaryFeedbacks(params)),
  }
}

export default withRouter(connect(mapStateToProps, mapdispatchToProps)(DiaryTaskModal))
