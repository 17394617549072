
import React, {useEffect} from 'react';
import axios from "axios";
import config from "../../config";

 const dialer_agent = async () =>{
   return new Promise(async (resolve,reject)=>{
     await axios.get(`${config.apiPath}/api/asterisk/asteriskUserDetail`).then((response) => {
       if (Object.keys(response.data.data).length>0){
         resolve ({
           success:true,
           "agentUser":response.data.data.asteriskUserId,
           "agentPassword":response.data.data.password,
           agentServer:response.data.data.asteriskServer,
           callTone: response.data.data.callTone
         })
       } else {
         resolve({
           success:false
         });
       }
     }).catch((error) => {
       resolve({
         success:false
       });
     })
   });


  /*return {
    "1902":{
      "agentUser":1008,
      "agentPassword":"7M4FAFdzTn"
    },
    "783":{
      "agentUser":1002,
      "agentPassword":"9yxweu9AqR"
    }
  };*/
}
export default dialer_agent;
