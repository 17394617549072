/** @format */

import React, { lazy, Suspense, Component } from 'react'
import { Switch, Route, withRouter, Router, useParams } from 'react-router-dom'

import { withCookies } from 'react-cookie'
import Loader from 'react-loader-spinner'
import 'jquery'
import 'font-awesome/css/font-awesome.min.css'
import './App.css'
import axios from 'axios'
import Layout from './components/NavBar'
import './App.scss'
import { text_truncate } from './utils/checkEllipsis'
import PrivateRoute from './components/auth/privateRoute'
import config from './config'
import {
  ProjectLeadUrl,
  ProjectDealUrl,
  ProjectCompanyLeadUrl,
  ProjectMyLeadUrl,
  BuyRentDealUrl,
  WantedQualifiedLeadUrl,
  WantedTeamLeadUrl,
  BuyRentLeadUrl,
  ReferedProjectLeadUrl,
  TeamProjectLeadUrl,
  TeamProjectDealUrl,
  BuyRentDemandLeadUrl,
  TeamBuyRentDealUrl,
  TeamBuyRentLeadUrl,
  ReferedBuyRentLeadUrl,
  BuyRentCompanyLeadUrl,
  BuyRentMyLeadUrl,
  BuyRentDeactivatedLeadUrl,
  ProjectDeactivatedLeadUrl,
  ProjectDeactivatedDealUrl,
  BuyRentDeactivatedDealUrl,
  ReferedProjectDealUrl,
  ReferedBuyRentDealUrl,
} from './utils/constant_data'
import { PermissionFeatures, PermissionActions } from './utils/permissionConstants'
import { getPermissionValue } from './../src/components/_unlockComponent'
import history from './utils/routingHistory'
import InProgressBooking from './views/InProgressBooking'
import SaleableInventory from './views/SaleableInventory'
import ExternalSaleableInventory from './views/ExternalSaleableInventory'
import InternalSaleableInventory from './views/InternalSaleableInventory'
import CompactView from './views/Reports/CCMC/compactView'
import SummaryView from './views/Reports/CCMC/summaryView'
import CcmcGM from './views/Reports/CCMC/ccmcGM'
import InboundLog from "./views/CallCenter/InboundLog";
import InboundAndOutboundCampaignLog from "./views/CallCenter/InboundAndOutboundCampaignLog";
import OutboundCampaignLog from "./views/CallCenter/OutboundCampaignLog";
import OutboundLog from "./views/CallCenter/OutboundLog";
import CampaignOutage from './views/CallCenter/CampaignOutage'
import MissedCallLog from './views/CallCenter/MissedCallLogs'
import AgentWaitingTime from './views/CallCenter/AgentWaitingTime'
import ActiveAgentsReports from "./callCenter/reports/ActiveAgentsReports";
import AgentPerformanceReport from "./callCenter/reports/AgentPerformanceReport";
import UserMiscallLogs from "./callCenter/reports/UserMiscallLogs";

// Routes
const Login = lazy(() => import('./views/Login/index'))
const Logout = lazy(() => import('./views/Logout/index'))
const Dashboard = lazy(() => import('./views/Dashboard/index'))
const Admin = lazy(() => import('./views/Admin'))
const Users = lazy(() => import('./views/Users'))
const ChangePassword = lazy(() => import('./views/ChangePassword'))
const UserProfile = lazy(() => import('./views/Admin/userProfile'))
const DeactivatedUser = lazy(() => import('./views/deactivatedUser/index'))
const Organizations = lazy(() => import('./views/Organizations/index'))
const AddOrganization = lazy(() => import('./views/AddOrganization'))
const Team = lazy(() => import('./views/Teams/teams'))
const TeamForm = lazy(() => import('./components/TeamForm'))
const DeactivatedProjects = lazy(() => import('./views/Projects/deactivatedProjects'))
const DeactivatedTeam = lazy(() => import('./views/deactivatedTeams/index'))
const Targets = lazy(() => import('./views/Targets'))
const MyTargets = lazy(() => import('./views/MyTargets'))
const CmLeadImport = lazy(() => import('./views/CmLead'))
const AddProject = lazy(() => import('./components/ProjectForm/index'))
const OrgChart = lazy(() => import('./views/OrgChart'))
const RentAndSaleLeadNew = lazy(() => import('./views/BuyRentLeadNew'))
const BuyRentLead = lazy(() => import('./views/Lead/MyLead/BuyRentLead/Listing/BuyRentLead'))
const BuyRentMyLead = lazy(() => import('./views/Lead/MyLead/BuyRentLead/Listing/BuyRentMyLead'))
const BuyRentCompanyLead = lazy(() =>
  import('./views/Lead/MyLead/BuyRentLead/Listing/BuyRentCompanyLead')
)
const BuyRentDeactivatedLead = lazy(() =>
  import('./views/Lead/MyLead/BuyRentLead/Listing/BuyRentDeactivatedLead')
)
const BuyRentDeactivatedDeal = lazy(() =>
  import('./views/Lead/MyLead/BuyRentLead/Listing/BuyRentDeactivatedDeal')
)
const BuyRentDeal = lazy(() => import('./views/Lead/MyLead/BuyRentLead/Listing/BuyRentDeal'))
const ProjectLead = lazy(() => import('./views/Lead/MyLead/ProjectLead/Listing/ProjectLead'))
const ProjectMyLead = lazy(() => import('./views/Lead/MyLead/ProjectLead/Listing/ProjectMyLead'))
const ProjectCompanyLead = lazy(() =>
  import('./views/Lead/MyLead/ProjectLead/Listing/ProjectCompanyLead')
)
const ProjectDeactivatedLead = lazy(() =>
  import('./views/Lead/MyLead/ProjectLead/Listing/ProjectDeactivatedLead')
)
const ProjectDeactivatedDeal = lazy(() =>
  import('./views/Lead/MyLead/ProjectLead/Listing/ProjectDeactivatedDeal')
)
const ProjectDeal = lazy(() => import('./views/Lead/MyLead/ProjectLead/Listing/ProjectDeals'))
const ReferedByMeLead = lazy(() =>
  import('./views/Lead/MyLead/ProjectLead/Listing/ReferedByMeLead')
)
const ReferedByMeDeal = lazy(() =>
  import('./views/Lead/MyLead/ProjectLead/Listing/ReferedByMeDeal')
)
const TeamLeads = lazy(() => import('./views/Lead/MyLead/ProjectLead/Listing/TeamLeads'))
const TeamDeals = lazy(() => import('./views/Lead/MyLead/ProjectLead/Listing/TeamDeals'))
const DemandLead = lazy(() => import('./views/Lead/MyLead/BuyRentLead/Listing/DemandLead'))
const BuyRentReferedByMeLead = lazy(() =>
  import('./views/Lead/MyLead/BuyRentLead/Listing/ReferedByMeLead')
)
const BuyRentReferedByMeDeal = lazy(() =>
  import('./views/Lead/MyLead/BuyRentLead/Listing/ReferedByMeDeal')
)
const BuyRentTeamLeads = lazy(() => import('./views/Lead/MyLead/BuyRentLead/Listing/TeamLeads'))
const BuyRentTeamDeals = lazy(() => import('./views/Lead/MyLead/BuyRentLead/Listing/TeamDeals'))
const AreaTable = lazy(() => import('./views/AreaGroups'))
const AccountView = lazy(() => import('./views/AccountViews'))
const AccountViewRe = lazy(() => import('./views/AccountViewRe'))
const AccountViewRentalDashboard = lazy(() => import('./views/AccountViewRentalDashboard'))
const BookingFormDashboard = lazy(() => import('./views/BookingFormDashboard'))
const BuyRentSale = lazy(() => import('./views/BuyRentSales'))
const UserEditInfo = lazy(() => import('./views/editUserInfo'))
const MorningView = lazy(() => import('./views/MorningView'))
const PropsureView = lazy(() => import('./views/PropsureRequestedView'))
const DeletedUnits = lazy(() => import('./views/DeletedUnits'))
const DeletedFloors = lazy(() => import('./views/DeletedFloors'))
const LegalBuyRent = lazy(() => import('./views/LegalBuyRentView'))
const TransferRequest = lazy(() => import('./views/LegalTransfer'))
const InvestmentLead = lazy(() => import('./views/InvestmentLead'))
const ProjectLeadDetail = lazy(() => import('./views/Lead/MyLead/ProjectLead/Detail'))
const LeadDetail = lazy(() => import('./views/LeadDetail'))
const BuyRentLeadDetail = lazy(() => import('./views/BuyRentLeadDetail'))
const LeadManagement = lazy(() => import('./views/LeadManagement'))
const CampaignManagement = lazy(() => import('./views/CampaignManagement'))
const ProjectDiscountApprovals = lazy(() => import('./views/ProjectDiscountApprovals'))
const ClientManagement = lazy(() => import('./views/ClientManagement'))
const AddClient = lazy(() => import('./views/AddClient'))
const addNextOfKin = lazy(() => import('./views/addNextOfKin'))
const ActivityLog = lazy(() => import('./views/ActivityLog'))
const AgentManagement = lazy(() => import('./views/AgentsManagement'))
const ArmsView = lazy(() => import('./views/ArmsView'))
const FieldAppView = lazy(() => import('./views/FieldAppView'))
const GraanaView = lazy(() => import('./views/GraanaView'))
const MyTeam = lazy(() => import('./views/MyTeam'))
const LeadCreation = lazy(() => import('./views/LeadCreation'))
const PropertyDetail = lazy(() => import('./components/PropertyDetail'))
const AddArmsProperty = lazy(() => import('./components/AddArmsProperty'))
const BuyRentLeadCreation = lazy(() => import('./views/BuyRentLeadCreation'))
// create new project inventory
const InvestmentProduct = lazy(() => import('./views/InvestmentProducts'))
const InvestmentProductNew = lazy(() => import('./views/InvestmentProductsNew'))
const Projects = lazy(() => import('./views/Projects'))
const ProjectsNew = lazy(() => import('./views/ProjectsNew'))
const Floor = lazy(() => import('./views/Floor'))
const FloorNew = lazy(() => import('./views/FloorNew'))
const Units = lazy(() => import('./views/Unit'))
const UnitsNew = lazy(() => import('./views/UnitNew'))
const AvailableInventory = lazy(() => import('./views/AvailableInventory'))
const AvailableInventoryNew = lazy(() => import('./views/AvailableInventoryNew'))
// create new project inventory
const InvestmentProductForm = lazy(() => import('./components/Forms/InvestmentProductCreation'))
const AttachmentPageForGP = lazy(() => import('./views/AttachmentPageForGP'))
const Roles = lazy(() => import('./views/Roles/index'))
const AddRoles = lazy(() => import('./views/AddRoles'))
const Permissions = lazy(() => import('./views/Permissions/index'))
const WantedLeads = lazy(() => import('./views/Lead/MyLead/WantedLead'))
const QualifiedLeads = lazy(() => import('./views/Lead/MyLead/WantedLead/QualifiedLead'))
const WantedTeamLeads = lazy(() => import('./views/Lead/MyLead/WantedLead/TeamLead'))
const PowerBI = lazy(() => import('./views/PowerBI'))
const PBIAgency21 = lazy(() => import('./views/PowerBI/PBIAgency21'))
const PBIGraanaLTRCT = lazy(() => import('./views/PowerBI/GraanaLTRCT'))
const REReport = lazy(() => import('./views/PowerBI/REReport'))
const CCMCReport = lazy(() => import('./views/PowerBI/CCMCReport'))

const DiaryView = lazy(() => import('./views/Lead/Diary'))
const TempSlotView = lazy(() => import('./views/TempSlotView'))
const AiraManagement = lazy(() => import('./views/AiraManagement'))
const OfficeLocations = lazy(() => import('./views/OfficeLocations'))
const AddOfficeLocations = lazy(() => import('./views/AddOfficeLocations'))
const FranchiseAreaGroups = lazy(() => import('./views/FranchiseAreaGroups'))
const AddAreaGroup = lazy(() => import('./views/AddAreaGroup'))
const SetPassword = lazy(() => import('./views/SetPassword'))
const BookingStep = lazy(() => import('./views/UnitBookingForm/index'))
const BookedInventoryDetail = lazy(() => import('./views/BookedInventoryDetail/index'))
const InventoryDetail = lazy(() => import('./views/InventoryDetail/index'))
const adminTeamView = lazy(() => import('./views/AdminTeams'))

let userRole = localStorage.getItem('role')

axios.defaults.withCredentials = true

class App extends Component {
  componentDidMount() {
    text_truncate()
  }

  render() {
    return (
      <Router history={history}>
        <Switch>
          <Suspense
            fallback={
              <div className="loader-parent">
                <Loader type="ThreeDots" color="#0f73ee" height="40" width="40" />{' '}
              </div>
            }
          >
            <Layout>
              <Route exact path="/" render={(props) => <Login {...props} />} />
              <Route
                exact
                path="/set-password/:userId/:tempToken"
                render={(props) => <SetPassword {...props} />}
              />
              <Route
                exact
                path="/dashboard"
                component={(props) => <Dashboard {...props} />}
                //  render={(props) => <Dashboard {...props} cookies={this.props.cookies} />}
              />
              {getPermissionValue(
                PermissionFeatures.WEB_PAGES,
                PermissionActions.USER_PAGE_VIEW
              ) && (
                <PrivateRoute
                  exact
                  path="/users"
                  currentUser={userRole || null}
                  roles={[userRole || null]}
                  component={Users}
                />
              )}
              <PrivateRoute
                exact
                path="/teams"
                currentUser={userRole || null}
                roles={[`${userRole}`]}
                component={adminTeamView}
              />
              {getPermissionValue(
                PermissionFeatures.WEB_PAGES,
                PermissionActions.ROLE_PAGE_VIEW
              ) && (
                <PrivateRoute
                  exact
                  path="/roles"
                  currentUser={userRole || null}
                  roles={[`${userRole}`]}
                  component={Roles}
                />
              )}
              {getPermissionValue(
                PermissionFeatures.WEB_PAGES,
                PermissionActions.USER_PAGE_VIEW
              ) && (
                <PrivateRoute
                  exact
                  path="/users/:userId"
                  currentUser={userRole || null}
                  roles={[`${userRole}`]}
                  // roles={['super_admin', 'admin']}
                  component={Admin}
                />
              )}
              <PrivateRoute
                exact
                path="/edit-team/:userId"
                currentUser={userRole || null}
                roles={[userRole || null]}
                component={TeamForm}
              />
              <PrivateRoute
                exact
                path="/deactivatedproperties"
                currentUser={userRole || null}
                roles={[`${userRole}`]}
                component={DeactivatedProjects}
              />
              <PrivateRoute
                exact
                path="/admin"
                currentUser={userRole || null}
                roles={[userRole || null]}
                component={Admin}
              />
              <PrivateRoute
                exact
                path="/cmlead"
                currentUser={userRole || null}
                roles={[userRole || null]}
                component={CmLeadImport}
              />
              <Route
                exact
                path="/updateProfile"
                render={(props) => <UserProfile {...props} cookies={this.props.cookies} />}
              />
              {getPermissionValue(
                PermissionFeatures.WEB_PAGES,
                PermissionActions.PROJECTS_PAGE_VIEW
              ) && (
                <PrivateRoute
                  exact
                  path="/projectmain"
                  currentUser={userRole || null}
                  roles={[`${userRole}`]}
                  component={config.projectInventoryNew === true ? ProjectsNew : Projects}
                />
              )}
              {getPermissionValue(
                PermissionFeatures.WEB_PAGES,
                PermissionActions.INVESTMENT_PRODUCTS_PAGE_VIEW
              ) && (
                <PrivateRoute
                  exact
                  path="/investmentProducts"
                  currentUser={userRole || null}
                  roles={[userRole || null]}
                  component={
                    config.projectInventoryNew === true ? InvestmentProductNew : InvestmentProduct
                  }
                />
              )}
              <PrivateRoute
                exact
                path="/add-product"
                currentUser={userRole || null}
                roles={[userRole || null]}
                component={InvestmentProductForm}
              />
              <PrivateRoute
                exact
                path="/attachments"
                currentUser={userRole || null}
                roles={[userRole || null]}
                component={AttachmentPageForGP}
              />
              <PrivateRoute
                exact
                path="/add-product/:productId"
                currentUser={userRole || null}
                roles={[userRole || null]}
                component={InvestmentProductForm}
              />
              <PrivateRoute
                exact
                path="/edit-project/:projectId"
                currentUser={userRole || null}
                roles={[userRole || null]}
                //STORY 3504 for check Edit Inventory Project
                //roles={userRole || null}
                component={AddProject}
              />
              <PrivateRoute
                exact
                path="/add-project"
                currentUser={userRole || null}
                roles={[userRole || null]}
                component={AddProject}
              />
              {getPermissionValue(
                PermissionFeatures.WEB_PAGES,
                PermissionActions.FLOOR_PAGE_VIEW
              ) && (
                <PrivateRoute
                  exact
                  path="/floor"
                  currentUser={userRole || null}
                  roles={[userRole || null]}
                  component={config.projectInventoryNew === true ? FloorNew : Floor}
                />
              )}
              {getPermissionValue(
                PermissionFeatures.WEB_PAGES,
                PermissionActions.UNIT_PAGE_VIEW
              ) && (
                <PrivateRoute
                  exact
                  path="/units"
                  currentUser={userRole || null}
                  roles={userRole || null}
                  component={config.projectInventoryNew === true ? UnitsNew : Units}
                />
              )}
              <PrivateRoute
                exact
                path="/available-inventory"
                currentUser={userRole || null}
                roles={[userRole || null]}
                component={
                  config.projectInventoryNew === true ? AvailableInventoryNew : AvailableInventory
                }
              />
              <PrivateRoute
                exact
                path="/booked/InventoryDetail/:bookedInventoryID"
                currentUser={userRole || null}
                roles={[userRole || null]}
                component={BookedInventoryDetail}
              />
              <PrivateRoute
                exact
                path="/inventoryDetail/:inventoryID"
                currentUser={userRole || null}
                roles={[userRole || null]}
                component={InventoryDetail}
              />

              <PrivateRoute
                exact
                path="/booking/:stepType"
                currentUser={userRole || null}
                roles={[userRole || null]}
                component={BookingStep}
              />
              <PrivateRoute
                exact
                path="/booking/:stepType/:bookingId"
                currentUser={userRole || null}
                roles={[userRole || null]}
                component={BookingStep}
              />
              {getPermissionValue(
                PermissionFeatures.WEB_PAGES,
                PermissionActions.TEAMS_PAGE_VIEW
              ) && (
                <PrivateRoute
                  exact
                  path="/teams"
                  currentUser={userRole || null}
                  roles={[userRole || null]}
                  component={Team}
                />
              )}
              <PrivateRoute
                exact
                path="/team"
                currentUser={userRole || null}
                roles={[userRole || null]}
                component={TeamForm}
              />
              <PrivateRoute
                exact
                path="/edit-adminTeam/:adminTeamId"
                currentUser={userRole || null}
                roles={[userRole || null]}
                component={TeamForm}
              />
              {getPermissionValue(
                PermissionFeatures.WEB_PAGES,
                PermissionActions.DELETE_PROJECTS_PAGE_VIEW
              ) && (
                <PrivateRoute
                  exact
                  path="/deactivatedProjects"
                  currentUser={userRole || null}
                  roles={[userRole || null]}
                  component={DeactivatedProjects}
                />
              )}
              <Route
                exact
                path="/logout"
                render={(props) => <Logout {...props} cookies={this.props.cookies} />}
              />
              <Route
                exact
                path="/changePassword"
                render={(props) => <ChangePassword {...props} cookies={this.props.cookies} />}
              />
              <PrivateRoute
                exact
                path="/deactivatedTeam"
                currentUser={userRole || null}
                roles={['super_admin', 'admin', 'super_admin']}
                component={DeactivatedTeam}
              />
              <Route
                exact
                path="/deactivatedUser"
                render={(props) => <DeactivatedUser {...props} cookies={this.props.cookies} />}
              />
              <PrivateRoute
                exact
                path="/organizations"
                currentUser={userRole || null}
                roles={[userRole || null]}
                component={Organizations}
              />
              <PrivateRoute
                exact
                path="/add-organization"
                currentUser={userRole || null}
                roles={[userRole || null]}
                component={AddOrganization}
              />
              <PrivateRoute
                exact
                path="/add-roles"
                currentUser={userRole || null}
                roles={[userRole || null]}
                component={AddRoles}
              />
              <PrivateRoute
                exact
                path="/edit-role/:roleId"
                currentUser={userRole || null}
                roles={[userRole || null]}
                component={AddRoles}
              />
              <PrivateRoute
                exact
                path="/add-officeLocations"
                currentUser={userRole || null}
                roles={[userRole || null]}
                component={AddOfficeLocations}
              />
              <PrivateRoute
                exact
                path="/edit-officeLocation/:locationId"
                currentUser={userRole || null}
                roles={[userRole || null]}
                component={AddOfficeLocations}
              />
              <PrivateRoute
                exact
                path="/edit-areaGroup/:areaGroupId"
                currentUser={userRole || null}
                roles={[userRole || null]}
                component={AddAreaGroup}
              />
              <PrivateRoute
                exact
                path="/permissions/:roleId/:roleName"
                currentUser={userRole || null}
                roles={[userRole || null]}
                component={Permissions}
              />
              {/* <PrivateRoute
              exact
              path="/permissions"
              currentUser={userRole || null}
              roles={['super_admin', 'admin']}
              component={Permissions}
            /> */}
              <PrivateRoute
                exact
                path="/edit-organization/:organizationId"
                currentUser={userRole || null}
                roles={[userRole || null]}
                component={AddOrganization}
              />
              {getPermissionValue(
                PermissionFeatures.WEB_PAGES,
                PermissionActions.AREA_GROUPS_PAGE_VIEW
              ) && (
                <PrivateRoute
                  exact
                  path="/areasGroup"
                  currentUser={userRole || null}
                  roles={[`${userRole}`]}
                  // roles={['super_admin', 'admin']}
                  component={AreaTable}
                />
              )}
              {getPermissionValue(
                PermissionFeatures.WEB_PAGES,
                PermissionActions.SET_TARGETS_PAGE_VIEW
              ) && (
                <PrivateRoute
                  exact
                  path="/targets"
                  currentUser={userRole || null}
                  roles={[userRole]}
                  component={Targets}
                />
              )}
              {getPermissionValue(
                PermissionFeatures.WEB_PAGES,
                PermissionActions.MY_TARGETS_PAGE_VIEW
              ) && (
                <PrivateRoute
                  exact
                  path="/mytargets"
                  currentUser={userRole || null}
                  roles={[userRole]}
                  component={MyTargets}
                />
              )}
              {getPermissionValue(
                PermissionFeatures.WEB_PAGES,
                PermissionActions.PROJECT_PAYMENTS_PAGE_VIEW
              ) && (
                <PrivateRoute
                  exact
                  path="/sales"
                  currentUser={userRole || null}
                  roles={[userRole || null]}
                  component={AccountViewRe}
                />
              )}
              {getPermissionValue(
                PermissionFeatures.WEB_PAGES,
                PermissionActions.ORG_CHART_PAGE_VIEW
              ) && (
                <PrivateRoute
                  exact
                  path="/org-chart"
                  currentUser={userRole || null}
                  roles={[userRole || null]}
                  component={OrgChart}
                />
              )}
              {getPermissionValue(
                PermissionFeatures.WEB_PAGES,
                PermissionActions.PROJECT_PAYMENTS_PAGE_VIEW
              ) && (
                <PrivateRoute
                  exact
                  path="/rentaldashboard"
                  currentUser={userRole || null}
                  roles={[userRole || null]}
                  component={AccountViewRentalDashboard}
                />
              )}
              {getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.BOOKING_FORM) && (
                <PrivateRoute
                  exact
                  path="/bookingform"
                  currentUser={userRole || null}
                  roles={[userRole || null]}
                  component={BookingFormDashboard}
                />
              )}
              {getPermissionValue(
                PermissionFeatures.WEB_PAGES,
                PermissionActions.DIARY_PAGE_VIEW
              ) && (
                <PrivateRoute
                  exact
                  path="/diary"
                  currentUser={userRole || null}
                  roles={userRole || null}
                  isOverdue={false}
                  component={DiaryView}
                />
              )}
              <PrivateRoute
                exact
                path="/overdue"
                currentUser={userRole || null}
                roles={[userRole || null]}
                isOverdue={true}
                component={DiaryView}
              />
              <PrivateRoute
                exact
                path="/slot"
                currentUser={userRole || null}
                roles={[userRole || null]}
                component={TempSlotView}
              />
              {/* <PrivateRoute
              exact
              path="/salesRe"
              currentUser={userRole || null}
              roles={['super_admin', 'sub_admin 5', 'sub_admin 6']}
              component={AccountViewRe}
            /> */}
              <PrivateRoute
                exact
                path="/buyrentsales"
                currentUser={userRole || null}
                roles={[userRole || null]}
                component={BuyRentSale}
              />
              <PrivateRoute
                exact
                path="/morningView"
                currentUser={userRole || null}
                roles={[userRole || null]}
                component={MorningView}
              />
              <PrivateRoute
                exact
                path="/propsure"
                currentUser={userRole || null}
                roles={[userRole || null]}
                component={PropsureView}
              />
              {getPermissionValue(
                PermissionFeatures.WEB_PAGES,
                PermissionActions.DELETE_UNIT_PAGE_VIEW
              ) && (
                <PrivateRoute
                  exact
                  path="/deletedUnits"
                  currentUser={userRole || null}
                  roles={[userRole || null]}
                  component={DeletedUnits}
                />
              )}
              {getPermissionValue(
                PermissionFeatures.WEB_PAGES,
                PermissionActions.DELETE_FLOOR_PAGE_VIEW
              ) && (
                <PrivateRoute
                  exact
                  path="/deletedFloors"
                  currentUser={userRole || null}
                  roles={[userRole || null]}
                  component={DeletedFloors}
                />
              )}
              <PrivateRoute
                exact
                path="/legalBuyRent"
                currentUser={userRole || null}
                roles={[`${userRole}`]}
                // roles={['sub_admin 8', 'super_admin']}
                component={LegalBuyRent}
              />
              {getPermissionValue(
                PermissionFeatures.WEB_PAGES,
                PermissionActions.TRANSFER_REQUESTS
              ) && (
                <PrivateRoute
                  exact
                  path="/transferRequest"
                  currentUser={userRole || null}
                  roles={[`${userRole}`]}
                  component={TransferRequest}
                />
              )}
              <PrivateRoute
                exact
                path="/projectDiscountApprovals"
                currentUser={userRole || null}
                roles={['super_admin']}
                component={ProjectDiscountApprovals}
              />
              {getPermissionValue(
                PermissionFeatures.WEB_PAGES,
                PermissionActions.CLIENTS_PAGE_VIEW
              ) && (
                <PrivateRoute
                  exact
                  path="/clientManagement"
                  currentUser={userRole || null}
                  roles={[userRole || null]}
                  component={ClientManagement}
                />
              )}
              <PrivateRoute
                exact
                path="/add-client"
                currentUser={userRole || null}
                roles={[userRole || null]}
                component={AddClient}
              />
              <PrivateRoute
                exact
                path="/edit-client/:clientId"
                currentUser={userRole || null}
                roles={[userRole || null]}
                component={AddClient}
              />
              {getPermissionValue(
                PermissionFeatures.WEB_PAGES,
                PermissionActions.RENTAL_DASHBOARD_PAGE_VIEW
              ) && (
                <>
                  {/*<PrivateRoute
                    exact
                    path="/project-deal-detail/:leadId"
                    currentUser={userRole || null}
                    roles={[userRole || null]}
                    component={ProjectLeadDetail}
                  />*/}
                  <PrivateRoute
                    exact
                    path="/project-lead-detail/:leadId"
                    currentUser={userRole || null}
                    roles={[userRole || null]}
                    component={ProjectLeadDetail}
                  />
                  <PrivateRoute
                    exact
                    path="/projectLeads"
                    currentUser={userRole || null}
                    roles={[userRole || null]}
                    component={InvestmentLead}
                  />
                  <PrivateRoute
                    exact
                    path="/buyRentLeads"
                    currentUser={userRole || null}
                    roles={[userRole || null]}
                    component={RentAndSaleLeadNew}
                  />
                </>
              )}
              <PrivateRoute
                exact
                path="/edit-client/:clientId"
                currentUser={userRole || null}
                roles={[userRole || null]}
                component={AddClient}
              />
              <PrivateRoute
                exact
                path={ProjectDealUrl}
                currentUser={userRole || null}
                roles={[userRole || null]}
                component={ProjectDeal}
              />
              <PrivateRoute
                exact
                path="/project-deal-detail/:leadId"
                currentUser={userRole || null}
                roles={[userRole || null]}
                component={ProjectLeadDetail}
              />
              <PrivateRoute
                exact
                path={ProjectLeadUrl}
                currentUser={userRole || null}
                roles={[userRole || null]}
                component={ProjectLead}
              />
              <PrivateRoute
                exact
                path={ProjectMyLeadUrl}
                currentUser={userRole || null}
                roles={[userRole || null]}
                component={ProjectMyLead}
              />
              <PrivateRoute
                exact
                path={ProjectCompanyLeadUrl}
                currentUser={userRole || null}
                roles={[userRole || null]}
                component={ProjectCompanyLead}
              />
              <PrivateRoute
                exact
                path={ProjectDeactivatedLeadUrl}
                currentUser={userRole || null}
                roles={[userRole || null]}
                component={ProjectDeactivatedLead}
              />
              <PrivateRoute
                exact
                path={ProjectDeactivatedDealUrl}
                currentUser={userRole || null}
                roles={[userRole || null]}
                component={ProjectDeactivatedDeal}
              />
              <PrivateRoute
                exact
                path={ReferedProjectLeadUrl}
                currentUser={userRole || null}
                roles={[userRole || null]}
                component={ReferedByMeLead}
              />
              <PrivateRoute
                exact
                path={ReferedProjectDealUrl}
                currentUser={userRole || null}
                roles={[userRole || null]}
                component={ReferedByMeDeal}
              />
              <PrivateRoute
                exact
                path={TeamProjectLeadUrl}
                currentUser={userRole || null}
                roles={[userRole || null]}
                component={TeamLeads}
              />
              <PrivateRoute
                exact
                path={TeamProjectDealUrl}
                currentUser={userRole || null}
                roles={[userRole || null]}
                component={TeamDeals}
              />
              <PrivateRoute
                exact
                path={BuyRentDemandLeadUrl}
                currentUser={userRole || null}
                roles={[userRole || null]}
                component={DemandLead}
              />
              <PrivateRoute
                exact
                path={ReferedBuyRentLeadUrl}
                currentUser={userRole || null}
                roles={[userRole || null]}
                component={BuyRentReferedByMeLead}
              />
              <PrivateRoute
                exact
                path={ReferedBuyRentDealUrl}
                currentUser={userRole || null}
                roles={[userRole || null]}
                component={BuyRentReferedByMeDeal}
              />
              <PrivateRoute
                exact
                path={TeamBuyRentLeadUrl}
                currentUser={userRole || null}
                roles={[userRole || null]}
                component={BuyRentTeamLeads}
              />
              <PrivateRoute
                exact
                path={TeamBuyRentDealUrl}
                currentUser={userRole || null}
                roles={[userRole || null]}
                component={BuyRentTeamDeals}
              />
              <PrivateRoute
                exact
                path="/project-lead-detail/:leadId"
                currentUser={userRole || null}
                roles={[userRole || null]}
                component={ProjectLeadDetail}
              />
              <PrivateRoute
                exact
                path="/wanted"
                currentUser={userRole || null}
                roles={[userRole || null]}
                component={WantedLeads}
              />
              <PrivateRoute
                exact
                path={WantedQualifiedLeadUrl}
                currentUser={userRole || null}
                roles={[userRole || null]}
                component={QualifiedLeads}
              />
              <PrivateRoute
                exact
                path={WantedTeamLeadUrl}
                currentUser={userRole || null}
                roles={[userRole || null]}
                component={WantedTeamLeads}
              />
              <PrivateRoute
                exact
                path="/projectLeads"
                currentUser={userRole || null}
                roles={[userRole || null]}
                component={InvestmentLead}
              />
              <PrivateRoute
                exact
                path="/buyRentLeads"
                currentUser={userRole || null}
                roles={[userRole || null]}
                component={RentAndSaleLeadNew}
              />
              <PrivateRoute
                exact
                path={BuyRentLeadUrl}
                currentUser={userRole || null}
                roles={[userRole || null]}
                component={BuyRentLead}
              />
              <PrivateRoute
                exact
                path={BuyRentMyLeadUrl}
                currentUser={userRole || null}
                roles={[userRole || null]}
                component={BuyRentMyLead}
              />
              <PrivateRoute
                exact
                path={BuyRentCompanyLeadUrl}
                currentUser={userRole || null}
                roles={[userRole || null]}
                component={BuyRentCompanyLead}
              />
              <PrivateRoute
                exact
                path={BuyRentDeactivatedLeadUrl}
                currentUser={userRole || null}
                roles={[userRole || null]}
                component={BuyRentDeactivatedLead}
              />
              <PrivateRoute
                exact
                path={BuyRentDeactivatedDealUrl}
                currentUser={userRole || null}
                roles={[userRole || null]}
                component={BuyRentDeactivatedDeal}
              />
              <PrivateRoute
                exact
                path={BuyRentDealUrl}
                currentUser={userRole || null}
                roles={[userRole || null]}
                component={BuyRentDeal}
              />
              <PrivateRoute
                exact
                path="/activity-logs"
                currentUser={userRole || null}
                roles={[userRole || null]}
                component={ActivityLog}
              />
              <PrivateRoute
                exact
                path="/office-locations"
                currentUser={userRole || null}
                roles={[userRole || null]}
                component={OfficeLocations}
              />
              {/* <PrivateRoute
                exact
                path="/teams"
                currentUser={userRole || null}
                roles={[userRole || null]}
                component={OfficeLocations}
              />     */}

              <PrivateRoute
                exact
                path="/leadDetails/:leadId"
                currentUser={userRole || null}
                roles={[userRole || null]}
                component={LeadDetail}
              />
              <PrivateRoute
                exact
                path="/buyRentLeadDetails/:leadId"
                currentUser={userRole || null}
                roles={[userRole || null]}
                component={BuyRentLeadDetail}
              />
              <PrivateRoute
                exact
                path="/buy-rent-deail-detail/:leadId"
                currentUser={userRole || null}
                roles={[userRole || null]}
                component={BuyRentLeadDetail}
              />
              {getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.RULE_ID) && (
                <PrivateRoute
                  exact
                  path="/leadSource"
                  currentUser={userRole || null}
                  roles={[userRole || null]}
                  component={LeadManagement}
                />
              )}
              {getPermissionValue(
                PermissionFeatures.WEB_PAGES,
                PermissionActions.CAMPAIGN_MANAGEMENT
              ) && (
                <PrivateRoute
                  exact
                  path="/campaignManagement"
                  currentUser={userRole || null}
                  roles={[userRole || null]}
                  component={CampaignManagement}
                />
              )}
              {getPermissionValue(
                PermissionFeatures.WEB_PAGES,
                PermissionActions.AGENT_MANAGEMENT
              ) && (
                <PrivateRoute
                  exact
                  path="/agentsManagement"
                  currentUser={userRole || null}
                  roles={[userRole || null]}
                  component={AgentManagement}
                />
              )}
              <PrivateRoute
                exact
                path="/airaManagement"
                currentUser={userRole || null}
                roles={[userRole || null]}
                component={AiraManagement}
              />
              <PrivateRoute
                exact
                path="/armsView"
                currentUser={userRole || null}
                roles={[userRole || null]}
                component={ArmsView}
              />
              <PrivateRoute
                exact
                path="/fieldApp"
                currentUser={userRole || null}
                roles={[userRole || null]}
                component={FieldAppView}
              />
              <PrivateRoute
                exact
                path="/graanaView"
                currentUser={userRole || null}
                roles={[userRole || null]}
                component={GraanaView}
              />
              <PrivateRoute
                exact
                path="/myTeam"
                currentUser={userRole || null}
                roles={[userRole || null]}
                component={MyTeam}
              />
              <PrivateRoute
                exact
                path="/new-lead"
                currentUser={userRole || null}
                roles={[userRole || null]}
                component={LeadCreation}
              />
              <PrivateRoute
                exact
                path="/add-buy-rent-lead"
                currentUser={userRole || null}
                roles={[userRole || null]}
                component={BuyRentLeadCreation}
              />
              <PrivateRoute
                exact
                path="/powerBI"
                currentUser={userRole || null}
                roles={[userRole || null]}
                component={(props) => <PowerBI {...props} />}
              />
              <PrivateRoute
                exact
                path="/PBIAgency21"
                currentUser={userRole || null}
                roles={[userRole || null]}
                component={(props) => <PBIAgency21 {...props} />}
              />
              <PrivateRoute
                exact
                path="/PBIGraanaLTRCT"
                currentUser={userRole || null}
                roles={[userRole || null]}
                component={(props) => <PBIGraanaLTRCT {...props} />}
              />
              <PrivateRoute
                exact
                path="/REReport"
                currentUser={userRole || null}
                roles={[userRole || null]}
                component={(props) => <REReport {...props} />}
              />
              <PrivateRoute
                exact
                path="/CCMCReport"
                currentUser={userRole || null}
                roles={[userRole || null]}
                component={(props) => <CCMCReport {...props} />}
              />

              <PrivateRoute
                exact
                path="/saleable-inventories/:inventoryType"
                currentUser={userRole || null}
                roles={[userRole || null]}
                component={(props) => <SaleableInventory {...props} />}
              />
              <PrivateRoute
                exact
                path="/external-saleable-inventories/:inventoryType"
                currentUser={userRole || null}
                roles={[userRole || null]}
                component={(props) => <ExternalSaleableInventory {...props} />}
              />
              <PrivateRoute
                exact
                path="/internal-saleable-inventories/:inventoryType"
                currentUser={userRole || null}
                roles={[userRole || null]}
                component={(props) => <InternalSaleableInventory {...props} />}
              />
              <PrivateRoute
                exact
                path="/:bookingType/in-progress-booking/:bookingStatus"
                currentUser={userRole || null}
                roles={[userRole || null]}
                component={(props) => <InProgressBooking {...props} />}
              />
              <Route
                exact
                path="/userEditInfo"
                component={(props) => <UserEditInfo {...props} />}
              />
              <Route
                exact
                path="/propertyDetails"
                component={(props) => <PropertyDetail {...props} />}
              />
              <Route
                exact
                path="/addArmsProperty"
                component={(props) => <AddArmsProperty {...props} />}
              />
              <Route
                exact
                path="/addAreaGroup"
                component={(props) => <AddAreaGroup {...props} />}
              />
              <Route
                exact
                path="/areaGroup"
                component={(props) => <FranchiseAreaGroups {...props} />}
              />
              <PrivateRoute
                exact
                path="/reports/ccmc"
                currentUser={userRole || null}
                roles={[userRole || null]}
                component={(props) => userRole !== 'Group Management' ? <CompactView {...props} /> : <CcmcGM {...props} />  }
              />

              {/* call center logs */}
              <PrivateRoute
                exact
                path="/reports/inbound-logs"
                currentUser={userRole || null}
                roles={[userRole || null]}
                component={(props) => <InboundLog {...props} />}
              />

              <PrivateRoute
                exact
                path="/reports/inbound-and-outbound-user-logs"
                currentUser={userRole || null}
                roles={[userRole || null]}
                component={(props) => <InboundAndOutboundCampaignLog {...props} />}
              />

              <PrivateRoute
                exact
                path="/reports/outbound-logs"
                currentUser={userRole || null}
                roles={[userRole || null]}
                component={(props) => <OutboundLog {...props} />}
              />
              <PrivateRoute
                exact
                path="/campaign-outage"
                currentUser={userRole || null}
                roles={[userRole || null]}
                component={(props) => <CampaignOutage {...props} />}
              />
              <PrivateRoute
                exact
                path="/missed-call-logs"
                currentUser={userRole || null}
                roles={[userRole || null]}
                component={(props) => <MissedCallLog {...props} />}
              />
               <PrivateRoute
                exact
                path="/Agent-waiting-time"
                currentUser={userRole || null}
                roles={[userRole || null]}
                component={(props) => <AgentWaitingTime {...props} />}
              />

              <PrivateRoute
                exact
                path="/reports/active-agents"
                currentUser={userRole || null}
                roles={[userRole || null]}
                component={(props) => <ActiveAgentsReports {...props} />}
              />
              <PrivateRoute
                exact
                path="/reports/agent-performances"
                currentUser={userRole || null}
                roles={[userRole || null]}
                component={(props) => <AgentPerformanceReport {...props} />}
              />

              <PrivateRoute
                exact
                path="/reports/miscal-agents"
                currentUser={userRole || null}
                roles={[userRole || null]}
                component={(props) => <UserMiscallLogs {...props} />}
              />




              {/* call center logs */}

            </Layout>
          </Suspense>
        </Switch>
      </Router>
    )
  }
}

export default withRouter(withCookies(App))
