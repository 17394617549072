/** @format */

import React, { useState, useEffect } from 'react'
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap'
import Button from '../Button'
import _ from 'lodash'
import Swal from 'sweetalert2'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import axios from 'axios'
import config from '../../../config'
import CircleLoader from './../../../components/CircleLoader'
import { DIARY_STATS } from '../../../utils/constant_data'
import ColorTile from '../ColorTile'
import { getCurrentShift, pad } from '../../SlotManagement/SlotHelper'
import moment from 'moment'

const useStyles = makeStyles({
  infoContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 5fr',
    rowGap: '5px',
    margin: '30px 2px',
    '&>div': {
      fontSize: '14px',
      '&:nth-child(even)': {
        color: '#006FF2',
        fontWeight: 600,
      },
    },
  },
  statsGrid: {
    display: 'grid',
    gridTemplateAreas: '"TOTAL TOTAL OVERDUE OVERDUE"\n' + '"CONNECT FOLLOWUP MEETING VIEWING"',
    gridGap: '10px',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
  },
  card: {
    display: 'flex',
    border: '1px solid #ececec',
    borderRadius: '5px',
  },
  totalTasks: { gridArea: 'TOTAL' },
  overdueTasks: { gridArea: 'OVERDUE' },
  connectTasks: { gridArea: 'CONNECT' },
  followupTasks: { gridArea: 'FOLLOWUP' },
  meetingTasks: { gridArea: 'MEETING' },
  viewingTasks: { gridArea: 'VIEWING' },
  count: { fontSize: '30px', fontWeight: 600 },
  title: { fontSize: '16px' },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    lineHeight: '30px',
  },
  textCenter: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  marginLeft: {
    marginLeft: '20px',
    justifyContent: 'center',
  },
})

const ShiftReport = (props) => {
  const classes = useStyles(props)
  const [stats, setStats] = useState({})
  const [isLoading, setLoading] = useState(false)
  const [shift, setShift] = useState(null)
  const [isShiftEnded, setShiftEnded] = useState(true)
  const fetchStats = (thisShift = shift) => {
    setLoading(true)
    let duration = getCurrentDuration(thisShift)
    let params = ''
    if (!duration) {
      duration = {
        start: moment().startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
        end: moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss'),
      }
    }
    if (duration) {
      params = `?userId=${props.user.id}&startTime=${duration.start}&endTime=${duration.end}`
    }
    axios.get(`${config.apiPath}/api/diary/stats${params}`).then((res) => {
      setStats(res.data)
      setLoading(false)
    })
  }
  const fetchShifts = async () => {
    setLoading(true)
    axios.get(`${config.apiPath}/api/slotManagement/user-shifts`).then((res) => {
      if (res.data) {
        setShift(getCurrentShift(res.data, moment().format()))
        fetchStats(getCurrentShift(res.data, moment().format()))
      }
      // setLoading(false)
    })
  }
  const fetchShiftsStatus = async () => {
    setLoading(true)
    axios.get(`${config.apiPath}/api/shiftEndReport/shift-end/${props.user.id}`).then((res) => {
      if (res.data != undefined && res.data != null) {
        setShiftEnded(res.data)
      }
      setLoading(false)
    })
  }
  const finishShift = (thisShift = shift) => {
    let duration = getCurrentDuration(thisShift)
    if (!duration) {
      duration = {
        start: moment().startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
        end: moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss'),
      }
    }
    let params = ''
    if (duration) {
      params = `?userId=${props.user.id}&startTime=${duration.start}&endTime=${duration.end}`
      axios.post(`${config.apiPath}/api/diary/emailstats${params}`).then((res) => {
        setShiftEnded(true)
      })
    }
  }
  useEffect(() => {
    if (props.open) {
      fetchShifts()
      fetchShiftsStatus()
    }
  }, [props.open])

  const is24HourShift = (shifts) => {
    if (shifts.length >= 3) {
      let shiftCount = shifts.filter((shift) => {
        return (
          (shift.armsShift && shift.armsShift.name === 'Morning') ||
          shift.armsShift.name === 'Evening' ||
          shift.armsShift.name === 'Night'
        )
      }).length
      if (shiftCount >= 3) {
        return true
      }
    }
    return false
  }

  const getCurrentDuration = (thisShift = shift) => {
    const getHour = (time) => parseInt(time.split(':')[0])

    if (thisShift && thisShift.length > 0) {
      if (is24HourShift(thisShift)) {
        return {
          start: moment().startOf('day').format('YYYY-MM-DD HH:mm'),
          end: moment().endOf('day').format('YYYY-MM-DD HH:mm'),
        }
      }
      let startTime = thisShift[0].armsShift && thisShift[0].armsShift.startTime
      let endTime =
        thisShift[thisShift.length - 1].armsShift &&
        thisShift[thisShift.length - 1].armsShift.endTime
      if (!startTime || !endTime) {
        return null
      }
      let startDate = moment().format('YYYY-MM-DD')
      let endDate = moment().format('YYYY-MM-DD')
      let isOverLap = false
      for (let i = 0; i < thisShift.length; i++) {
        if (
          getHour(thisShift[i].armsShift && thisShift[i].armsShift.startTime) > 12 &&
          getHour(thisShift[i].armsShift && thisShift[i].armsShift.endTime) < 12
        ) {
          isOverLap = true
        }
      }

      let startDateTime
      let endDateTime
      if (isOverLap) {
        startDateTime = moment(startDate + ' ' + startTime, ['YYYY-MM-DD HH:mm'])
          .add(-1, 'days')
          .format('YYYY-MM-DDTHH:mm:ss')
        endDateTime = moment(endDate + ' ' + endTime, ['YYYY-MM-DD HH:mm']).format(
          'YYYY-MM-DDTHH:mm:ss'
        )
      } else {
        startDateTime = moment(startDate + ' ' + startTime, ['YYYY-MM-DD HH:mm']).format(
          'YYYY-MM-DDTHH:mm:ss'
        )
        endDateTime = moment(endDate + ' ' + endTime, ['YYYY-MM-DD HH:mm']).format(
          'YYYY-MM-DDTHH:mm:ss'
        )
      }
      return { start: startDateTime, end: endDateTime }
    }
  }

  const getInfo = () => {
    let duration = getCurrentDuration()
    if (!duration) {
      duration = { start: moment().startOf('day').format(), end: moment().endOf('day').format() }
    }
    return (
      <div className={classes.infoContainer}>
        <div>Name:</div>
        {props.user ? <div>{props.user.firstName + ' ' + props.user.lastName}</div> : '<div/>'}
        <div>Report Duration:</div>
        <div>
          {duration
            ? moment(duration.start).format('hh-mm a') +
              ' - ' +
              moment(duration.end).format('hh-mm a ') +
              '(' +
              moment(duration.start).format('YYYY-MM-DD') +
              ')'
            : '--'}
        </div>
      </div>
    )
  }
  const getCard = (countText, title, showTile, textColor, backgroundColor, tileColor, height) => {
    return (
      <div
        className={classes.card}
        style={{ background: backgroundColor, color: textColor, height: height }}
      >
        {showTile && (
          <ColorTile
            color={tileColor}
            width={'10px'}
            height={'inherit'}
            styles={{ borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px' }}
          ></ColorTile>
        )}
        <div
          className={clsx(
            classes.textContainer,
            showTile ? classes.textCenter : classes.marginLeft
          )}
        >
          <div className={classes.count}>{countText.toString().replaceAll('NaN', '0')}</div>
          <div className={classes.title}>{title}</div>
        </div>
      </div>
    )
  }
  const getStatsGrid = () => {
    return (
      <div className={classes.statsGrid}>
        <div className={clsx(classes.totalTasks)}>
          {getCard(
            parseInt(
              stats[DIARY_STATS.CONNECT_DONE] +
                stats[DIARY_STATS.MEETING_DONE] +
                stats[DIARY_STATS.FOLLOWUP_DONE] +
                stats[DIARY_STATS.VIEWING_DONE]
            ) +
              '/' +
              parseInt(
                stats[DIARY_STATS.TOTAL_CONNECT] +
                  stats[DIARY_STATS.TOTAL_MEETING] +
                  stats[DIARY_STATS.TOTAL_FOLLOWUP] +
                  stats[DIARY_STATS.TOTAL_VIEWING]
              ),
            'Total Tasks of the Day',
            false,
            'white',
            '#006FF2',
            null,
            '80px'
          )}
        </div>
        <div className={clsx(classes.overdueTasks)}>
          {getCard(
            parseInt(stats[DIARY_STATS.OVERDUE_TASKS]),
            'Overdue Tasks',
            false,
            'black',
            'FFFFFF',
            null,
            '80px'
          )}
        </div>
        <div className={clsx(classes.connectTasks)}>
          {getCard(
            parseInt(stats[DIARY_STATS.CONNECT_DONE]) +
              '/' +
              parseInt(stats[DIARY_STATS.TOTAL_CONNECT]),
            'Connect Tasks',
            true,
            'black',
            '#f2f8ef',
            '#7BB461',
            '130px'
          )}
        </div>
        <div className={clsx(classes.followupTasks)}>
          {getCard(
            parseInt(stats[DIARY_STATS.FOLLOWUP_DONE]) +
              '/' +
              parseInt(stats[DIARY_STATS.TOTAL_FOLLOWUP]),
            'Follow Up Tasks',
            true,
            'black',
            '#fff9e8',
            '#FFC61B',
            '130px'
          )}
        </div>
        <div className={clsx(classes.meetingTasks)}>
          {getCard(
            parseInt(stats[DIARY_STATS.MEETING_DONE]) +
              parseInt(stats[DIARY_STATS.VIEWING_DONE]) +
              '/' +
              (parseInt(stats[DIARY_STATS.TOTAL_MEETING]) +
                parseInt(stats[DIARY_STATS.TOTAL_VIEWING])),
            'Actions',
            true,
            'black',
            '#f0f4f8',
            '#6B91B9',
            '130px'
          )}
        </div>
        <div className={clsx(classes.viewingTasks)}>
          {getCard(
            parseInt(stats[DIARY_STATS.Wanted_Leads_Rejected]) +
              parseInt(stats[DIARY_STATS.BuyRent_Leads_Rejected]) +
              parseInt(stats[DIARY_STATS.Project_Leads_Rejected]),
            'Leads Rejected',
            true,
            'black',
            '#fde0e2',
            '#c32220',
            '130px'
          )}
        </div>
      </div>
    )
  }
  return (
    <>
      <Modal
        isOpen={props.open}
        toggle={props.toggle}
        size="lg"
        style={{ maxWidth: '800px', width: '100%' }}
        // contentClassName="diary-stat-modal"
      >
        <ModalBody>
          <div>
            <div className="attachment-modal-header">
              <div className="attachment-header-title">Day End Report</div>
              <i
                className="fal fa-times-circle attachment-close-icon"
                onClick={() => props.toggle()}
              ></i>
            </div>
            {isLoading ? (
              <CircleLoader loading={isLoading}></CircleLoader>
            ) : (
              <>
                <div>{getInfo()}</div>
                <div>{getStatsGrid()}</div>
              </>
            )}
          </div>
        </ModalBody>
        <ModalFooter className={'justify-content-start pl-4 mt-3 mb-3'}>
          <Button
            text={'Cancel'}
            textColor={'black'}
            display={'inline-block'}
            buttonColor="#fff"
            className={'px-4 rounded-pill small'}
            style={{ border: '1px solid #d3d3d3' }}
            onClick={() => {
              props.toggle()
            }}
          />
          <Button
            text={'End Day'}
            textColor={'white'}
            display={'inline-block'}
            buttonColor="#026ff2"
            className={'px-4 rounded-pill mr-2 small'}
            disabled={isShiftEnded}
            onClick={() => {
              const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                  confirmButton: 'swalConfirmBtn px-4 rounded-pill small mr-2',
                  cancelButton: 'denyConfirmBtn px-4 rounded-pill mr-2 small',
                },
                buttonsStyling: false,
              })
              swalWithBootstrapButtons
                .fire({
                  title: 'End Day',
                  text:
                    'Are you sure you want to finish your day? You will still be able to work on your remaining tasks, but a report with above stats will be sent to your manager',
                  icon: 'fa fa-plus',
                  type: 'question',
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: 'End Day',
                })
                .then((result) => {
                  if (result.value) {
                    finishShift()
                    props.toggle()
                  }
                })
            }}
          />
        </ModalFooter>
      </Modal>
    </>
  )
}

export default ShiftReport
