/** @format */

import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { capitalize_Words, showInfoMessage, showSuccessMessage } from '../../../utils/commonFormat'
import moment from 'moment'
import TaskIconTimeline from '../../../images/task-icon.svg'
import { DatePicker, DateTimePicker, TimePicker } from 'react-rainbow-components'
import MeetingIconModal from '../../../images/meeting-icon.svg'
import {
  FETCH_LEAD_TASKS,
  fetchLeadTasks,
  fetchLeadTimeLine,
  investmentMeeting,
  investmentTask,
  leadEditToggle,
} from '../../../actions/leads'
import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import config from '../../../config'
import { toast, ToastContainer } from 'react-toastify'
import setHours from 'date-fns/setHours'
import setMinutes from 'date-fns/setMinutes'
import { connect } from 'react-redux'
import MeetingActions from '../../MeetingAction'
import { checkGoogleAuthorize, scrollToBottom, sortListByDateValue } from '../../../utils/helper'
import { constants } from '../../../utils/constant_data'
import { UserInfoFetch } from '../../../actions/user'
import { DiaryItem } from '../../CardInfo/DiaryItem'
import CancelConfirmModal from './CancelConfirm'
import ReferenceGuideModal from './ReferenceGuideModal'

const TaskModal = ({
  leadId,
  lead,
  callBack,
  setTaskToggleState,
  addInvestmentMeeting,
  taskToggleState,
  fetchLeadTasks,
  addTask,
  addMeetingLoader,
  addTaskLoader,
  setFollowupModalFlag,
  leadType,
  meetingFeedback,
  setMeetingFeedback,
  setActiveTab,
  user,
  fetchUser,
  makeTaskListEmpty,
  fromPage,
  tasksFlag,
  callToggle, setCloseLostToggleState,
  taskModalAction,
  setTaskModalAction,
  shouldCloseAfterAdd,
  closeAfterAdd,
  checkForRead,
  showAlertToggle,
  actionListRefer,
  shared_with_armsuser_id
}) => {
  const [meeting, setMeeting] = useState(false)
  const [startDate, setStartDate] = useState(
    moment(new Date()).add(1, 'hours').format('YYYY-MM-DDTHH:mm:ssZ')
  )
  const [showEndTime, setShowEndTime] = useState(false)
  const [diaryAction, setDiaryAction] = useState(null)
  const [selectedDiary, setSelectedDiary] = useState(null)
  const [leadTasks, setLeadTasks] = useState(null)
  const [showTaskMeeting, setShowTaskMeeting] = useState(true)
  const [cancelConfirmModal, setCancelConfirmModal] = useState(false)
  const [guideReferenceModal, setGuideReferenceModal] = useState(false)
  const [guideReference, setGuideReference] = useState(false)

  let leadTypeParam = ''
  if (leadType === 'project') leadTypeParam = 'cmLeadId'
  else if (leadType === 'buy-rent') leadTypeParam = 'rcmLeadId'
  const leadTaskParams = { taskType: ['meeting', 'task'], [leadTypeParam]: leadId }

  const initialTaskObj = {
    subject: '',
    date: setHours(setMinutes(new Date(), 30), 16),
    taskType: '',
    description: '',
    diaryTime: moment(new Date()).add(1, 'hours').format('HH:mm'),
    end: moment(new Date()).add(2, 'hours').format('HH:mm'),
  }
  const [taskObj, setTaskObj] = useState(initialTaskObj)


  useEffect(() => {
    fetchLeadTasks(leadId, leadTaskParams, (data) => {
      setLeadTasks(data)
      scrollToBottom("meeting-scroll-container")
    })
  }, [])


  useEffect(() => {
    if (
      ['add-meeting', 'add-task', 'cancel-task', 'task-done', 'cancel-meeting'].indexOf(
        diaryAction
      ) > -1
    )
      checkGoogleAuthorize(user, afterGoogleAuthCallback)
  }, [diaryAction])

  const afterGoogleAuthCallback = (token_exist_already = false) => {
    if (!token_exist_already) fetchUser()

    if (diaryAction === 'add-meeting') addMeetingFun()
    else if (diaryAction === 'add-task') addTaskFunc()
    else if (diaryAction === 'cancel-task') {
      showInfoMessage('Cancelling task ...')
      deleteDiary(selectedDiary.id, 'task')
    } else if (diaryAction === 'cancel-meeting') {
      showInfoMessage('Cancelling meeting ...')
      deleteDiary(selectedDiary.id, 'meeting')
    } else if (diaryAction === 'task-done') {
      showInfoMessage('Marking task as done...')
      markAsDone(selectedDiary.id)
    }
    setSelectedDiary(null)
    setDiaryAction(null)
  }

  const deleteDiary = (id, type) => {
    let leadTypeParam = ''
    if (leadType === 'project') leadTypeParam = 'cmLeadId'
    else if (leadType === 'buy-rent') leadTypeParam = 'leadId'
    axios
      .delete(`${config.apiPath}/api/diary/delete?id=${id}&${leadTypeParam}=${leadId}`)
      .then((res) => {
        callBack()
        setLeadTasks((tasks) => tasks.filter((task) => id !== task.id))
        toast.dismiss()
        setTimeout(() => {
          if (type === 'meeting') showSuccessMessage('Meeting cancelled')
          else if (type === 'task') showSuccessMessage('Task cancelled')
        }, 700)
      })
  }

  const markAsDone = (id) => {
    let leadTypeParam = ''
    if (leadType === 'project') leadTypeParam = 'leadId'
    else if (leadType === 'buy-rent') leadTypeParam = 'rcmLeadId'

    axios
      .patch(`${config.apiPath}/api/diary/update/?id=${id}`, {
        [leadTypeParam]: leadId,
        status: 'completed',
      })
      .then((res) => {
        callBack()
        setLeadTasks((tasks) =>
          tasks.map((task) => {
            return task.id === id ? { ...task, status: 'completed' } : task
          })
        )
        toast.dismiss()
      })
  }

  const setMeetingDone = () => {
    setLeadTasks((tasks) =>
      tasks.map((task) => {
        return task.id === meeting.id ? { ...task, status: 'completed' } : task
      })
    )
    setMeeting(null)
  }

  const addMeetingFun = () => {
    let endDate = moment(startDate).add(1, 'hours').format('YYYY-MM-DDTHH:mm:ssZ')
    let start = moment(startDate).format('YYYY-MM-DDTHH:mm:ssZ')
    let meetingData = {
      start: start,
      end: endDate,
      subject: `Scheduled a meeting with ${lead && lead.customer ? lead.customer.customerName : 'client'}`,
      leadId: leadId,
      date: start,
      taskType: 'meeting',
      time: start,
      title: 'meeting',
      addedBy: 'self'
    }
    addInvestmentMeeting(meetingData, (projectLeadId, data) => {
      setLeadTasks((tasks) => sortListByDateValue([...tasks, data], 'start'))
      if (closeAfterAdd) {
        setTaskToggleState(false)
        shouldCloseAfterAdd(false)
        callBack('update')
      }
      callBack()
      setTaskModalAction('list')
    })
  }

  const addTaskFunc = () => {
    const user_id = localStorage.getItem('user_id')
    let date = moment(taskObj.date)
    let startTime = moment(taskObj.diaryTime, 'HH:mm:ss')
    let startDateTime = date
      .set({
        hour: startTime.get('hour'),
        minute: startTime.get('minute'),
        second: startTime.get('second'),
      })
      .format('YYYY-MM-DDTHH:mm:ssZ')
    let endTime = startTime.clone().add(1, 'hours')
    if (taskObj.end) {
      endTime = moment(taskObj.end, 'HH:mm:ss')
      if (moment(taskObj.diaryTime, 'HH:mm').isAfter(moment(taskObj.end, 'HH:mm')))
        endTime = moment(taskObj.diaryTime, 'HH:mm:ss').add(1, 'hours')
    }
    let endDateTime = date
      .clone()
      .set({
        hour: endTime.get('hour'),
        minute: endTime.get('minute'),
        second: endTime.get('second'),
      })
      .format('YYYY-MM-DDTHH:mm:ssZ')

    let taskData = {
      [leadTypeParam]: leadId,
      date: date.format('YYYY-MM-DDTHH:mm:ssZ'),
      diaryTime: startDateTime,
      status: 'pending',
      start: startDateTime,
      end: endDateTime,
      subject: taskObj.subject,
      taskType: 'task',
      time: startDateTime,
      notes: taskObj.description,
      userId: user_id,
      taskCategory: 'leadTask',
      addedBy: 'self'
    }

    addTask(
      taskData,
      (leadId, data) => {
        showSuccessMessage('Task added')
        setLeadTasks((tasks) => sortListByDateValue([...tasks, data], 'start'))
        setTaskModalAction('list')
        setTaskObj(initialTaskObj)
        callBack()
      },
      leadId
    )
  }

  let checkForMeetingTask = (checkForRead && !checkForRead.showReadOnly) || (checkForRead && checkForRead.ownLeadReadOnly)
  return (
    <>
    
      {showTaskMeeting && taskToggleState ? (
        <Modal
          isOpen={taskToggleState}
          toggle={(toggle) => {
            setTaskModalAction('list')
            setTaskToggleState(!toggle)
            if (fromPage === 'lead-listing') makeTaskListEmpty()
          }}
          size="lg"
          style={{ maxWidth: '890px', width: '100%' }}
          contentClassName=" task-modal"
        >
          <ModalHeader>
            {taskModalAction === 'list'
              ? 'Meetings & Tasks'
              : taskModalAction === 'add-task'
                ? 'Add Task'
                : taskModalAction === 'add-meeting'
                  ? 'Add Meeting'
                  : ''}
            <div className="close-icon-container">
              <i
                className="mt-1 fal fa-times-circle close-icon"
                onClick={() => {
                  setTaskModalAction('list')
                  setTaskToggleState(false)
                  if (fromPage === 'lead-listing') makeTaskListEmpty()
                }}
              ></i>
            </div>
          </ModalHeader>
          <ModalBody style={{ padding: '0 17px 17px 17px' }}>
            {taskModalAction === 'list' ? (
              <div className={' pr-3 pl-3'}>
                <div className="task-list" id={"meeting-scroll-container"}>
                  {tasksFlag === false ?
                    (<div class={"data-loader"}>
                      <span className="fa fa-circle-o-notch fa-spin"></span>
                    </div>) :
                    leadTasks && leadTasks.length ? (
                      leadTasks.map((task, i) => (
                        <DiaryItem
                          key={i}
                          index={i}
                          className={`mt-2`}
                          dropdown={task.status !== 'completed' && lead &&
                            !([constants.lead_closed_won, constants.lead_closed_lost].indexOf(lead.status) > -1)}
                          item={task}
                          lead={lead}
                          cardType={task.taskType}
                          markAsDone={(id) => {
                            setDiaryAction('task-done')
                            setSelectedDiary(task)
                          }}
                          cancelTask={(id) => {
                            // setDiaryAction('cancel-task')
                            setCancelConfirmModal(true)
                            setSelectedDiary(task)
                          }}
                          doneMeeting={(meeting) => {
                            if ((lead && lead.guideReference) || guideReference)
                              setMeetingFeedback(true)
                            else
                              setGuideReferenceModal(true)
                            setMeeting(meeting)
                          }}
                          cancelMeeting={(id) => {
                            // setDiaryAction('cancel-meeting')
                            setCancelConfirmModal(true)
                            setSelectedDiary(task)
                          }}
                          checkForRead={checkForRead}
                        />
                      ))
                    ) : (
                      <div className="task-image-container mt-4">
                        <img src={TaskIconTimeline} className="container-image" />
                      </div>
                    )}
                </div>
                <br />
                {
                  lead && !([constants.lead_closed_won, constants.lead_closed_lost].indexOf(lead.status) > -1) ?
                    <div className={'row d-flex justify-content-center footer-btn'}>
                      <div className={'col-5 d-flex justify-content-center'}>
                        <div className="task-button mr-2" onClick={() => {
                       
                        if (actionListRefer){
                          if ((shared_with_armsuser_id !== null ) && shared_with_armsuser_id !== user.userInfo.id) {
                            showAlertToggle()
                          } else {
                           
                            setTaskModalAction('add-meeting')
                          }
                        }
                        else{
                          if (checkForMeetingTask) {
                            setTaskModalAction('add-meeting')
                          } else {
                            showAlertToggle()
                          }
                        }
                        
                        }}>
                          Add Meeting
                        </div>


                        <div className="task-button ml-2" onClick={() => {
                          if(actionListRefer){
                            if ((shared_with_armsuser_id !== null ) && shared_with_armsuser_id !== user.userInfo.id) {
                             
                              showAlertToggle()
                            } else {
                              setTaskModalAction('add-task')
                            }
                          }else{
                            if (checkForMeetingTask) {
                              setTaskModalAction('add-task')
                            } else {
                              showAlertToggle()
                            }
                          }
                        
                        }}>
                          Add Task
                        </div>
                      </div>
                    </div> : null}
              </div>
            ) : taskModalAction === 'add-task' ? (
              <div className={" pr-3 pl-3"}>
                <div className={'pr-4 pl-4 task-form'}>
                  <div className="row mt-2">
                    <div className="col-6">
                      <DatePicker
                        minDate={new Date()}
                        selected={taskObj.date}
                        onChange={(date) => setTaskObj({ ...taskObj, date: date })}
                        dateFormat="MMMM d, yyyy"
                        value={taskObj.date}
                        placeholder={'Start date'}
                        wrapperClassName="picker-width"
                      />
                    </div>
                    <div className="col-3">
                      <TimePicker
                        className="rainbow-m-vertical_x-large rainbow-p-horizontal_medium rainbow-m_auto "
                        value={taskObj.diaryTime}
                        placeholder={'Start time'}
                        onChange={(value) => {
                          if (moment(value, 'HH:mm').isAfter(moment(taskObj.end, 'HH:mm'))) {
                            setTaskObj({
                              ...taskObj,
                              diaryTime: value,
                              end: moment(value, 'HH:mm').add(1, 'hours').format('HH:mm')
                            })
                          } else
                            setTaskObj({ ...taskObj, diaryTime: value })

                          setShowEndTime(true)
                        }}
                      />
                    </div>
                    <div className="col-3">
                      <TimePicker
                        disabled={!showEndTime}
                        value={taskObj.end}
                        placeholder="End time"
                        className="rainbow-m-vertical_x-large rainbow-p-horizontal_medium rainbow-m_auto "
                        onChange={(value) => setTaskObj({ ...taskObj, end: value })}
                      />
                    </div>
                  </div>
                  <div className={'row mt-2'}>
                    <div className="col-12">
                      <input
                        type="text"
                        className="task-input-container"
                        placeholder="Title"
                        onChange={(e) => setTaskObj({ ...taskObj, subject: e.target.value })}
                      />
                    </div>
                  </div>
                  <div className={'row mt-2'}>
                    <div className="col-12">
                      <textarea
                        className="task-description"
                        rows={6}
                        placeholder="Description"
                        onChange={(e) => setTaskObj({ ...taskObj, description: e.target.value })}
                      />
                    </div>
                  </div>
                </div>
                <div className={'row d-flex justify-content-center footer-btn'}>
                  <div className={'col-5 d-flex justify-content-center'}>
                    <div
                      className="task-button outline mr-2"
                      onClick={() => setTaskModalAction('list')}
                    >
                      Cancel
                    </div>
                    <div
                      className={
                        taskObj.date && taskObj.diaryTime && taskObj.subject
                          ? 'ml-2 task-button'
                          : 'ml-2 task-button disabled'
                      }
                      onClick={() =>
                        taskObj.date && taskObj.diaryTime && taskObj.subject && !addTaskLoader
                          ? setDiaryAction('add-task')
                          : null
                      }
                    >
                      {addTaskLoader ? (
                        <span className="fa fa-circle-o-notch fa-spin"></span>
                      ) : (
                        'Add Task'
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : taskModalAction === 'add-meeting' ? (
              <div className={' pr-3 pl-3'}>
                <div className="call-image-container">
                  <img src={MeetingIconModal} className="container-image" />
                </div>
                <div className="container-body mt-4">
                  <div className={'row w-100 justify-content-center mb-4'}>
                    <div className={'col-6'}>
                      <DateTimePicker
                        locale={'en-US'}
                        okLabel={'OK'}
                        value={startDate}
                        onChange={(date) => setStartDate(date)}
                      />
                    </div>
                  </div>

                  <div className={'row d-flex justify-content-center footer-btn'}>
                    <div className={'col-sm-5 d-flex justify-content-center'}>
                      <div
                        className="task-button outline mr-2"
                        onClick={() => setTaskModalAction('list')}
                      >
                        Cancel
                      </div>

                      <div
                        className="task-button ml-2"
                        onClick={() => {
                          setDiaryAction('add-meeting')
                        }}
                      >
                        {addMeetingLoader ? (
                          <span className="fa fa-circle-o-notch fa-spin"></span>
                        ) : leadEditToggle && leadEditToggle.type === 'meeting' ? (
                          'Update Meeting'
                        ) : (
                          'Add Meeting'
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
          </ModalBody>
        </Modal>
      ) : (
        <></>
      )}

      {meetingFeedback && (
        <MeetingActions
          callBack={() => {
            setShowTaskMeeting(true)
            setTaskToggleState(false)
            callBack()
          }}
          shouldCloseAfterAdd={(toggle) => {
            shouldCloseAfterAdd(toggle)
          }}
          leadId={leadId}
          leadType={'investment'}
          feedbackType={'Meeting'}
          showFeedback={meetingFeedback}
          taskModal={true}
          user={user}
          fetchUser={() => fetchUser()}
          setShowFeedback={(toggle) => setMeetingFeedback(toggle)}
          setFollowupModalFlag={(toggle) => {
            callBack()
            setFollowupModalFlag(toggle)
            setMeetingFeedback(false)
            setMeetingDone()
          }}
          closedLost={() => {
            callBack('closedLost')
            setMeetingDone()
          }}
          meeting={meeting}
          onMeetingDone={() => {
            setMeetingDone()
            setShowTaskMeeting(false)
          }}
          meetingToggle={() => {
            setShowTaskMeeting(true)
            setTaskModalAction('add-meeting')
            setTaskToggleState(true)
          }}
          callToggle={() => callToggle()}
          setCloseLostToggleState={(toggle) => setCloseLostToggleState(toggle)}
        />
      )}


      {cancelConfirmModal ?
        <CancelConfirmModal
          diary={selectedDiary}
          cancelConfirmModal={cancelConfirmModal}
          setCancelConfirmModal={(toggle) => setCancelConfirmModal(toggle)}
          setDiaryAction={action => setDiaryAction(action)}
          setSelectedDiary={diary => setSelectedDiary(diary)}
        /> : <></>
      }

      {guideReferenceModal &&
        <ReferenceGuideModal
          leadId={leadId}
          callBack={() => {
            setGuideReferenceModal(false)
            setGuideReference(true)
            setMeetingFeedback(true)
          }
          }
          checkForRead={checkForRead}
          guideReferenceModal={guideReferenceModal}
          setGuideReferenceModal={toggle => setGuideReferenceModal(toggle)}
        />
      }
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    ...state,
    leadTasks: state.Leads.tasks,
    addMeetingLoader: state.Leads.addMeetingLoader,
    addTaskLoader: state.Leads.addTaskLoader,
    tasksFlag: state.Leads.tasksFlag,
  }
}
const mapdispatchToProps = (dispatch) => {
  return {
    addInvestmentMeeting: (data, callBack) => dispatch(investmentMeeting(data, callBack)),
    fetchLeadTasks: (leadId, params, callback) =>
      dispatch(fetchLeadTasks(leadId, params, callback)),
    addTask: (data, callback, leadId) => dispatch(investmentTask(data, callback, leadId)),
    makeTaskListEmpty: () => dispatch({ type: FETCH_LEAD_TASKS, payload: [], leadTaskFlag: null }),
  }
}

export default connect(mapStateToProps, mapdispatchToProps)(TaskModal)
