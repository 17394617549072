/** @format */

import React, { Component } from 'react'

import LeadTimelineInner from './leadTimelineInner'
import moment from 'moment'
import { capitalize_Words } from '../../utils/commonFormat'
import General from '../../utils/general'
import './leadTimelineStyle.scss'
import { UserInfoFetch } from '../../actions/user'
import { connect } from 'react-redux'

class LeadTimeLine extends Component {
  render() {
    const {
      content,
      user,
      fetchUser,
      checkForRead,
      closeAfterAdd,
      shouldCloseAfterAdd,
      fetchSingleLead,
    } = this.props
    let alterLeadTimeLine =
      content &&
      content.map((item) => ({
        id: item.id ? item.id : item.entity_id,
        newValue: item.newValue,
        diary: item.diary ? item.diary : null,
        time: item.date,
        containerTitle:
          item.activity === 'ArmsProjectLeads re-assigned' ? (
            <>
              <span className="icon-title-entity-highlight">
                <span className="font-weight-bold">
                  {item && item.armsuser && item.armsuser.firstName + ' ' + item.armsuser.lastName}
                </span>
              </span>
              re-assigned this lead
            </>
          ) : item.activity === 'ArmsProjectLead re-opened (Update Project Lead)' ? (
            <>
              <span className="icon-title-entity-highlight">
                <span className="font-weight-bold">
                  {item && item.armsuser && item.armsuser.firstName + ' ' + item.armsuser.lastName}
                </span>
              </span>
              re-opened this lead
            </>
          ) : item.activity === 'CmInstallments status changed (Update Payment)' &&
            JSON.parse(item && item.previousValue).status &&
            JSON.parse(item && item.newValue).status ? (
            <>
              <span className="icon-title-entity-highlight">
                <span className="font-weight-bold">
                  {item && item.armsuser && item.armsuser.firstName + ' ' + item.armsuser.lastName}
                </span>
              </span>
              changed payment{' '}
              <span
                className="payment-hyper font-weight-bold"
                onClick={() => this.props.updateActiveTab('payment', item.entity_id)}
              >
                ({item.entity_id})
              </span>{' '}
              status from{' '}
              <span className="font-weight-bold leadStatusTextColor">
                {JSON.parse(item && item.previousValue).status === 'pendingAccount' ? (
                  <span className="pendingStatus">Pending Accounts</span>
                ) : JSON.parse(item && item.previousValue).status === 'pendingAccountHq' ? (
                  <span className="pendingStatus">Pending Account HQ</span>
                ) : JSON.parse(item && item.previousValue).status === 'bankPending' ? (
                  <span className="pendingStatus">Bank Pending</span>
                ) : JSON.parse(item && item.previousValue).status === 'pendingSales' ? (
                  <span className="rejected">Pending Sales</span>
                ) : JSON.parse(item && item.previousValue).status === 'pendingSales' ? (
                  <span className="rejected">Pending Sales</span>
                ) : JSON.parse(item && item.previousValue).status === 'notCleared' ? (
                  <span className="rejected">Not Cleared</span>
                ) : JSON.parse(item && item.previousValue).status === 'cleared' ? (
                  <span className="cleared">Cleared</span>
                ) : (
                  <span className="open">Open</span>
                )}
              </span>{' '}
              to{' '}
              <span className="font-weight-bold leadStatusTextColor">
                {JSON.parse(item && item.newValue).status === 'pendingAccount' ? (
                  <span className="pendingStatus">Pending Accounts</span>
                ) : JSON.parse(item && item.newValue).status === 'pendingAccountHq' ? (
                  <span className="pendingStatus">Pending Account HQ</span>
                ) : JSON.parse(item && item.newValue).status === 'bankPending' ? (
                  <span className="pendingStatus">Bank Pending</span>
                ) : JSON.parse(item && item.newValue).status === 'pendingSales' ? (
                  <span className="rejected">Pending Sales</span>
                ) : JSON.parse(item && item.newValue).status === 'notCleared' ? (
                  <span className="rejected">Not Cleared</span>
                ) : JSON.parse(item && item.newValue).status === 'cleared' ? (
                  <span className="cleared">Cleared</span>
                ) : (
                  <span className="open">Open</span>
                )}
              </span>
            </>
          ) : (item.entity === 'ArmsProjectLeads' &&
              item.activity === 'ArmsProjectLeads create (wanted)') ||
            item.activity === 'ArmsProjectLeads create (facebook)' ||
            item.activity === 'ArmsProjectLeads Attachment create' ||
            item.activity === 'ArmsProjectLeads create (project_listing inquiry)' ||
            item.activity === 'ArmsProjectLeads create (ROI-25)' ||
            item.activity === 'ArmsProjectLeads create (Windows)' ||
            item.activity === 'ArmsProjectLeads create(aira)' ||
            item.activity === 'ArmsProjectLeads create (Facebook | Eid Cam AP)' ||
            item.activity === 'ArmsProjectLeads create (ROI-20)' ||
            item.activity === 'ArmsProjectLeads create (project inquiry)' ||
            item.activity === 'ArmsProjectLeads create (agency21_website_project_page)' ||
            item.activity === 'ArmsProjectLeads create (ROI-15)' ||
            item.activity === 'ArmsProjectLeads create (graana_project_listing_page_lead)' ||
            item.activity === 'ArmsProjectLeads create (graana_project_page_lead)' ||
            item.activity === 'ArmsProjectLeads create (aira)' ? (
            <>
              <span className="icon-title-entity-highlight">
                <span className="status-container">Lead Created</span>
              </span>
              <div className="reason-container">
                <span className="font-weight-bold">Lead ID:</span>
                <span className="ml-1">{item.entity_id}</span>
              </div>
              <div className="reason-container">
                <span className="font-weight-bold"> Source:</span>{' '}
                <span className="ml2"> {JSON.parse(item.newValue).origin}</span>
              </div>
            </>
          ) : item.activity === 'token CmInstallments create (Update Project Lead)' ||
            item.activity === 'payment CmInstallments create (Update Project Lead)' ||
            item.activity === 'payment CmInstallments create (Add Installments)' ||
            item.activity === 'token CmInstallments create (Add Installments)' ||
            item.activity === 'tax CmInstallments create (Add Installments)' ? (
            <>
              <span className="icon-title-entity-highlight">
                <span className="font-weight-bold">
                  {item && item.armsuser && item.armsuser.firstName + ' ' + item.armsuser.lastName}
                </span>
              </span>
              added a{' '}
              <span className="font-weight-bold">
                <span className="text-capitalize">
                  {JSON.parse(item && item.newValue).paymentCategory}
                </span>{' '}
                ( <span style={{ fontWeight: 'normal' }}>ID: </span>
                <span
                  className="payment-hyper"
                  onClick={() => this.props.updateActiveTab('payment', item.entity_id)}
                >
                  {item.entity_id}
                </span>{' '}
                )
              </span>
              <div className="reason-container">
                <span className="font-weight-bold">Amount:</span>{' '}
                <span className="ml-1">
                  {Number(JSON.parse(item && item.newValue).installmentAmount).toLocaleString()}
                </span>
              </div>
              <div className="reason-container">
                <span className="font-weight-bold">Status:</span>
                <span className="ml-1">
                  {capitalize_Words(JSON.parse(item.newValue).status.replace(/_/g, ' '))}
                </span>
              </div>
            </>
          ) : item.activity === 'ArmsProjectLeads create(arms)' ? (
            <>
              <span className="icon-title-entity-highlight">
                <span className="font-weight-bold">
                  {item && item.armsuser && item.armsuser.firstName + ' ' + item.armsuser.lastName}{' '}
                </span>
                <span className="status-container">created the lead</span>
              </span>
              <div className="reason-container">
                <span className="font-weight-bold">Lead ID:</span>
                <span className="ml-1">{item.entity_id}</span>
              </div>
            </>
          ) : item.activity === 'ArmsProjectLeads Unit Booked' ? (
            <>
              <span className="icon-title-entity-highlight">
                <span className="font-weight-bold">
                  {item && item.armsuser && item.armsuser.firstName + ' ' + item.armsuser.lastName}
                </span>
              </span>
              Booked a <span className="font-weight-bold">Unit </span>
              <div className="reason-container">
                <span className="font-weight-bold">Project:</span>{' '}
                {item &&
                  item.armsProjectLead &&
                  item.armsProjectLead.project &&
                  item.armsProjectLead.project.name}
              </div>
              <div className="reason-container">
                <span className="font-weight-bold">Floor:</span>{' '}
                {item &&
                  item.armsProjectLead &&
                  item.armsProjectLead.floor &&
                  item.armsProjectLead.floor.name}
              </div>
              <div className="reason-container">
                <span className="font-weight-bold">Unit:</span>{' '}
                {item &&
                  item.armsProjectLead &&
                  item.armsProjectLead.unit &&
                  item.armsProjectLead.unit.name}
              </div>
              <div className="reason-container">
                <span className="font-weight-bold">Investment Product:</span>{' '}
                {item &&
                  item.armsProjectLead &&
                  item.armsProjectLead.projectProduct &&
                  item.armsProjectLead.projectProduct.name}
              </div>
            </>
          ) : item.type === 'attachment' ? (
            <>
              <span className="icon-title-entity-highlight">
                <span className="font-weight-bold">
                  {item && item.armsuser && item.armsuser.firstName + ' ' + item.armsuser.lastName}
                </span>
              </span>
              added an attachment
              <div className="mb-2">
                <span className="reason-container"> {item.fileName}</span>
              </div>
            </>
          ) : item.type === 'comment' ? (
            <span className="icon-title-entity-highlight">
              <span className="font-weight-bold">
                {item.armsuser.firstName + ' ' + item.armsuser.lastName}
              </span>
            </span>
          ) : item.calledOn === 'whatsApp' ? (
            <>
              <span className="icon-title-entity-highlight">
                <span className="font-weight-bold">
                  {item && item.user && item.user.firstName + ' ' + item.user.lastName}
                </span>
              </span>
              contacted on WhatsApp
              <div className="reason-container">
                <span className="font-weight-bold">Outcome:</span>
                <span className="ml-1">{item.response}</span>
              </div>
            </>
          ) : item.taskType === 'called' ? (
            <>
              <span className="icon-title-entity-highlight">
                <span className="font-weight-bold">
                  {item && item.user && item.user.firstName + ' ' + item.user.lastName}
                </span>
              </span>
              called{' '}
              {item && item.customer && item.customer.first_name === null ? (
                <span className="font-weight-bold">Client</span>
              ) : (
                <span className="font-weight-bold">
                  {item &&
                    item.customer &&
                    item.customer.first_name + ' ' + item.customer.last_name}
                </span>
              )}
              <div className="reason-container">
                <span className="font-weight-bold">Outcome:</span>
                <span className="ml-1">{item.response}</span>
              </div>
            </>
          ) : item.taskType === 'other' ||
            item.type === 'follow up' ||
            item.taskType === 'follow_up' ||
            item.taskType === 'meeting' ? (
            <>
              <span className="icon-title-entity-highlight">
                <span className="font-weight-bold">
                  {item && item.user && item.user.firstName + ' ' + item.user.lastName}
                </span>
              </span>
              scheduled a{' '}
              <span className="font-weight-bold">
                {item.taskType === 'follow_up' ? 'Follow Up' : 'Meeting'}
              </span>
              <span className="ml-1">for</span>
              <span className="status-container" style={{ fontWeight: 'normal' }}>
                <span className="font-weight-bold ml-1">
                  {moment(item.start).format('dddd MMM D, LT')}
                </span>
              </span>
              <div className="reason-container">
                <span className="font-weight-bold">Title:</span> {item.subject}
              </div>
              {item.status === 'completed' && (
                <div className="reason-container">
                  <span className="font-weight-bold">Status:</span>{' '}
                  <span className="text-success font-weight-bold text-capitalize">Done</span>
                </div>
              )}
            </>
          ) : item.taskCategory === 'simpleTask' || item.taskCategory === 'leadTask' ? (
            <>
              <span className="icon-title-entity-highlight">
                <span className="font-weight-bold">
                  {item && item.user && item.user.firstName + ' ' + item.user.lastName}
                </span>
              </span>
              added a{' '}
              {item.taskCategory === 'simpleTask' || item.taskCategory === 'leadTask' ? (
                <span className="font-weight-bold">Task</span>
              ) : (
                <span className="font-weight-bold">Followup task</span>
              )}{' '}
              in diary for{' '}
              <span className="status-container font-weight-bold">
                {moment(item.end).format('dddd MMM D, LT')}
              </span>
              <div className="reason-container">
                <span className="font-weight-bold">Title:</span> {item.subject}
              </div>
            </>
          ) : item.entity === 'Diary' ? (
            <div className="status-container">
              {JSON.parse(item.previousValue).response === null ? (
                <>
                  <span className="icon-title-entity-highlight">
                    <span className="font-weight-bold">
                      {item &&
                        item.armsuser &&
                        item.armsuser.firstName + ' ' + item.armsuser.lastName}
                    </span>
                  </span>
                  changed status from
                  <span className="leadTopMenuStatusContainer align-self-center ml-2 mr-2">
                    {JSON.parse(item.previousValue).status !== null && (
                      <span
                        className={
                          JSON.parse(item.previousValue).status === 'meeting'
                            ? 'meeting p-2-1'
                            : JSON.parse(item.previousValue).status === 'closed_lost'
                            ? 'closed_lost p-2-1'
                            : JSON.parse(item.previousValue).status === 'token'
                            ? 'token p-2-1'
                            : JSON.parse(item.previousValue).status === 'follow_up'
                            ? 'nurture p-2-1'
                            : JSON.parse(item.previousValue).status === 'open' ||
                              JSON.parse(item.previousValue).status === 'pending'
                            ? 'open p-2-1'
                            : JSON.parse(item.previousValue).status === 'payment'
                            ? 'payment p-2-1'
                            : JSON.parse(item.previousValue).status === 'closed_won'
                            ? 'closed_won p-2-1'
                            : null
                        }
                      >
                        {JSON.parse(item.previousValue).status === 'follow_up'
                          ? 'Nurture'
                          : capitalize_Words(
                              JSON.parse(item.previousValue).status.replace(/_/g, ' ')
                            )}
                      </span>
                    )}
                  </span>
                </>
              ) : (
                <>
                  <span className="icon-title-entity-highlight">
                    <span className="font-weight-bold">
                      {item &&
                        item.armsuser &&
                        item.armsuser.firstName + ' ' + item.armsuser.lastName}
                    </span>
                  </span>
                  changed status from
                  <span className="status-wrapper middle-response-status ml-2">
                    {JSON.parse(item.previousValue).response !== null && (
                      <span
                        className={
                          JSON.parse(item.previousValue).response === 'meeting'
                            ? 'meeting p-2-1'
                            : JSON.parse(item.previousValue).response === 'closed_lost'
                            ? 'closed_lost p-2-1'
                            : JSON.parse(item.previousValue).response === 'token'
                            ? 'token p-2-1'
                            : JSON.parse(item.previousValue).response === 'follow_up'
                            ? 'nurture p-2-1'
                            : JSON.parse(item.previousValue).response === 'open'
                            ? 'open p-2-1'
                            : JSON.parse(item.previousValue).response === 'payment'
                            ? 'payment p-2-1'
                            : JSON.parse(item.previousValue).response === 'closed_won'
                            ? 'closed_won p-2-1'
                            : null
                        }
                      >
                        {JSON.parse(item.previousValue).response === 'follow_up'
                          ? 'Nurture'
                          : capitalize_Words(
                              JSON.parse(item.previousValue).response.replace(/_/g, ' ')
                            )}
                      </span>
                    )}
                  </span>
                </>
              )}
              to
              <span className="leadTopMenuStatusContainer align-self-center ml-2 mr-2">
                {capitalize_Words(JSON.parse(item.newValue).response.replace(/_/g, ' '))}
              </span>
            </div>
          ) : (item.entity === 'ArmsProjectLeads' &&
              JSON.parse(item && item.newValue).status === 'closed_lost') ||
            (item.entity === 'ArmsProjectLeads' &&
              JSON.parse(item && item.newValue).status === 'closed_won') ? (
            <>
              <span className="icon-title-entity-highlight">
                <span className="font-weight-bold">
                  {' '}
                  {item && item.armsuser && item.armsuser.firstName + ' ' + item.armsuser.lastName}
                </span>
              </span>
              closed lead as
              {JSON.parse(item && item.newValue) &&
              JSON.parse(item && item.newValue).status === 'closed_lost' ? (
                <>
                  <span className="leadTopMenuStatusContainer align-self-center ml-2 mr-2">
                    <span className="closed_lost p-2-1">Closed Lost</span>
                  </span>
                </>
              ) : (
                <span className="leadTopMenuStatusContainer align-self-center ml-2 mr-2">
                  <span className="closed_won p-2-1">Closed Won</span>
                </span>
              )}
              {JSON.parse(item && item.newValue) &&
                JSON.parse(item && item.newValue).reasons !== undefined &&
                JSON.parse(item && item.newValue).status === 'closed_lost' && (
                  <div>
                    Reason:
                    <span className="reason-container">
                      {capitalize_Words(
                        JSON.parse(item && item.newValue) &&
                          JSON.parse(item && item.newValue).reasons.replace(/_/g, ' ')
                      )}
                    </span>
                  </div>
                )}
            </>
          ) : item.entity === 'ArmsProjectLeads' && item.activity === 'Share Project Lead' ? (
            <>
              <span className="icon-title-entity-highlight">
                <span className="font-weight-bold">
                  {item && item.armsuser && item.armsuser.firstName + ' ' + item.armsuser.lastName}{' '}
                </span>
                <span className="status-container">
                  shared lead with{' '}
                  <span className="font-weight-bold">{JSON.parse(item.newValue).armsuser}</span>
                </span>
              </span>
            </>
          ) : item.entity === 'ArmsProjectLeads' && item.activity !== 'Share Project Lead' ? (
            <div className="status-container">
              {JSON.parse(item.previousValue).response === null ? (
                <span className="status-wrapper no-response-status">No response</span>
              ) : (
                <>
                  Lead status changed from
                  {/* "status-wrapper middle-response-status ml-2" */}
                  <span className="leadTopMenuStatusContainer align-self-center ml-2 mr-2">
                    {JSON.parse(item.newValue).status ? (
                      <span
                        className={
                          JSON.parse(item.previousValue).status.replace(/_/g, ' ') === 'meeting'
                            ? 'meeting p-2-1'
                            : JSON.parse(item.previousValue).status.replace(/_/g, ' ') ===
                              'closed_lost'
                            ? 'closed_lost p-2-1'
                            : JSON.parse(item.previousValue).status === 'closed_lost'
                            ? 'closed_lost p-2-1'
                            : JSON.parse(item.previousValue).status.replace(/_/g, ' ') === 'token'
                            ? 'token p-2-1'
                            : JSON.parse(item.previousValue).status === 'follow_up'
                            ? 'nurture p-2-1'
                            : JSON.parse(item.previousValue).status.replace(/_/g, ' ') === 'open'
                            ? 'open p-2-1'
                            : JSON.parse(item.previousValue).status.replace(/_/g, ' ') === 'payment'
                            ? 'payment p-2-1'
                            : JSON.parse(item.previousValue).status.replace(/_/g, ' ') ===
                              'closed_won'
                            ? 'closed_won p-2-1'
                            : JSON.parse(item.previousValue).status === 'closed_won'
                            ? 'closed_won p-2-1'
                            : null
                        }
                      >
                        {JSON.parse(item.previousValue).status === 'follow_up'
                          ? 'Nurture'
                          : capitalize_Words(
                              JSON.parse(item.previousValue).status.replace(/_/g, ' ')
                            )}
                      </span>
                    ) : JSON.parse(item.newValue).response ? (
                      <span
                        className={
                          JSON.parse(item.previousValue).response === 'meeting'
                            ? 'meeting p-2-1'
                            : JSON.parse(item.previousValue).response === 'closed_lost'
                            ? 'closed_lost p-2-1'
                            : JSON.parse(item.previousValue).response === 'token'
                            ? 'token p-2-1'
                            : JSON.parse(item.previousValue).response === 'follow_up'
                            ? 'nurture p-2-1'
                            : JSON.parse(item.previousValue).response === 'open'
                            ? 'open p-2-1'
                            : JSON.parse(item.previousValue).response === 'payment'
                            ? 'payment p-2-1'
                            : JSON.parse(item.previousValue).response === 'closed_won'
                            ? 'closed_won p-2-1'
                            : null
                        }
                      >
                        {JSON.parse(item.previousValue).response === 'follow_up'
                          ? 'Nurture'
                          : capitalize_Words(
                              JSON.parse(item.previousValue).status.replace(/_/g, ' ')
                            )}
                      </span>
                    ) : null}
                  </span>
                </>
              )}
              to
              <span className="leadStatusColorContainer ml-2">
                {JSON.parse(item.newValue).status ? (
                  <span
                    className={
                      JSON.parse(item.newValue).status === 'meeting'
                        ? 'meeting p-2-1'
                        : JSON.parse(item.newValue).status === 'closed_lost'
                        ? 'closed_lost p-2-1'
                        : JSON.parse(item.newValue).status === 'token'
                        ? 'token p-2-1'
                        : JSON.parse(item.newValue).status === 'follow_up'
                        ? 'nurture p-2-1'
                        : JSON.parse(item.newValue).status === 'open'
                        ? 'open p-2-1'
                        : JSON.parse(item.newValue).status === 'payment'
                        ? 'payment p-2-1'
                        : JSON.parse(item.newValue).status === 'closed_won'
                        ? 'closed_won p-2-1'
                        : null
                    }
                  >
                    {JSON.parse(item.newValue).status === 'follow_up'
                      ? 'Nurture'
                      : capitalize_Words(JSON.parse(item.newValue).status.replace(/_/g, ' '))}
                  </span>
                ) : JSON.parse(item.newValue).response ? (
                  <span
                    className={
                      JSON.parse(item.newValue).response === 'meeting'
                        ? 'meeting p-2-1'
                        : JSON.parse(item.newValue).response === 'closed_lost'
                        ? 'closed_lost p-2-1'
                        : JSON.parse(item.newValue).response === 'token'
                        ? 'token p-2-1'
                        : JSON.parse(item.newValue).response === 'follow_up'
                        ? 'nurture p-2-1'
                        : JSON.parse(item.newValue).response === 'open'
                        ? 'open p-2-1'
                        : JSON.parse(item.newValue).response === 'payment'
                        ? 'payment p-2-1'
                        : JSON.parse(item.newValue).response === 'closed_won'
                        ? 'closed_won p-2-1'
                        : null
                    }
                  >
                    {JSON.parse(item.newValue).response === 'follow_up'
                      ? 'Nurture'
                      : capitalize_Words(JSON.parse(item.previousValue).status.replace(/_/g, ' '))}
                  </span>
                ) : null}
              </span>
            </div>
          ) : item.user ? (
            item && item.user && item.user.firstName + ' ' + item.user.lastName
          ) : null,
        type: item.taskType ? item.taskType : item.entity ? item.entity : item.type,
        calledOnSource: item.calledOn,
        contentType: item.type,
        attachmentData: item.type === 'attachment' ? item.value : null,
        subject:
          item.type === 'comment' ? 'added a comment' : item.type === 'attachment' ? '' : null,
        date: item.createdAt,
        commentContent: item.value,
        action: item.action,
        activity: item.activity,
        status: item.status,
      }))

    return (
      <div>
        {alterLeadTimeLine &&
          alterLeadTimeLine.map((item, index) => (
            <>
              <LeadTimelineInner
                user={user}
                fetchUser={() => fetchUser()}
                key={index}
                item={item}
                fetchSingleLead={fetchSingleLead}
                checkForRead={checkForRead}
                closeAfterAdd={closeAfterAdd}
                shouldCloseAfterAdd={(toggle) => shouldCloseAfterAdd(toggle)}
              />
            </>
          ))}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
    user: state.user.userInfo,
  }
}
const mapdispatchToProps = (dispatch) => {
  let type = 'buy-rent'
  return {
    fetchUser: () => dispatch(UserInfoFetch()),
  }
}

export default connect(mapStateToProps, mapdispatchToProps)(LeadTimeLine)
