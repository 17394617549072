/** @format */

import React, { useState } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import Inner from './Inner'
import './style.scss'
import Button from '../../components/common/Button'
const PostRegModal = (props) => {
  const {
    activePostRegModal,
    clientModalToggle,
    closeModal,
    leadType,
    history,
    openRegisterFeedbackModal,
  } = props

  return (
    <Modal
      isOpen={activePostRegModal}
      toggle={clientModalToggle}
      size="lg"
      style={{ maxWidth: 580, width: '100%' }}
    >
      <div className="createClientLeadModal hasScroll" ref={props.setRef}>
        <ModalBody className="postRegmodalform">
            <div class="row">
          <div className="close-icon-container float-right">
            {/* <i
              className="fal fa-times-circle close-icon"
              onClick={() => {
                props.closeModal()
              }}
            ></i> */}
          </div>
          </div>
                <div class="row">
                    <div class="col-md-12 d-flex justify-content-center text-center">
                        <h5>{`Client and ${leadType ? leadType : ''} lead have been registered successfully.`}</h5>
                    </div>
                </div>
                <div class="row feedback-row">
                    <div class="col-md-12 d-flex justify-content-center text-center ">
                        <p>If you have already called your client, you can register call feedback here:</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 d-flex justify-content-center text-center">
                        <Button
                            text={`Register Call Feedback`}
                            //   loader={loadingOnsubmit}
                            textColor={'white'}
                            width={'200px'}
                            height={'30px'}
                            display={'inline-block'}
                            buttonColor="#026ff2"
                            className={'px-4 rounded-pill mr-2 small'}
                            onClick={() => {
                              props.closeActivePostRegModal()
                              props.openRegisterFeedbackModal()
                            }}
                            />
                    </div>
                </div>
                <div class="row or-row">
                    <div class="col-md-12 d-flex justify-content-center text-center ">
                        <p>Or</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 d-flex justify-content-center text-center">
                    <Button
                        text={`Call Now`}
                        //   loader={loadingOnsubmit}
                        textColor={'white'}
                        width={'130px'}
                        height={'30px'}
                        display={'inline-block'}
                        // buttonColor="#026ff2"
                        className={'px-4 rounded-pill mr-2 small bg-success'}
                        onClick={() => {
                          props.closeActivePostRegModal()
                          props.openConnectModal()
                            // return createClient()
                        }}
                        />
                        </div>
                  </div>
                  <div class={'row'}>
                    <div class="col-md-12 d-flex justify-content-center text-center lead-client-footer ">
                    <Button
                        text={`Call Later`}
                        //   loader={loadingOnsubmit}
                        textColor={'#026ff2'}
                        width={'130px'}
                        height={'30px'}
                        display={'inline-block'}
                        buttonColor="#fff"
                        style={{ border: '1px solid #026ff2'}}

                        className={'px-4 rounded-pill mr-2 small'}
                        onClick={() => {
                            props.closeModal()
                            history.push('/clientManagement')
                          }}
                        />
                    </div>
                </div>
        </ModalBody>
      </div>
    </Modal>
  )
}

export default PostRegModal;
