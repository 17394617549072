import axios from "axios";

export const FETCH_DEFAULT_ORGANIZATIONS = "FETCH_DEFAULT_LEADS";
export const FETCH_ORGANIZATIONS_SUCCESS = "FETCH_ORGANIZATIONS_SUCCESS";
export const FETCH_ORGANIZATIONS_FAIL = "FETCH_ORGANIZATIONS_FAIL";

export const fetchOrganizations = (page = 1, url) => {
  return dispatch => {
    dispatch({ type: FETCH_ORGANIZATIONS_SUCCESS });
    axios.get(`${url}&offset=${(page - 1) * 10}`).then(res => {
      dispatch({ type: FETCH_ORGANIZATIONS_FAIL });
      return dispatch({ type: FETCH_DEFAULT_ORGANIZATIONS, data: res.data });
    });
  };
};
