/** @format */

import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select/async'
import debounce from 'debounce-promise'
import Fuse from 'fuse.js'

const FuzzyReactSelect = ({ options, fuzzyOptions, wait, ...props }) => {
  const [fuse, setFuse] = useState(null)

  const searchOptions = (inputValue) =>
    new Promise((resolve) => {
      let value = inputValue.slice(-1)
      resolve(fuse.search(inputValue).map((c) => ({ ...c })))
    })

  const loadOptions = (inputValue) => searchOptions(inputValue)

  const debouncedLoadOptions = debounce(loadOptions, wait)

  useEffect(() => {
    setFuse(new Fuse(options, fuzzyOptions))
    return () => setFuse(null)
  }, [options, fuzzyOptions])

  useEffect(() => {
    if ((options, fuse)) {
      fuse.setCollection(options)
    }
  }, [fuse, options])

  return (
    <Select
      defaultOptions={options}
      {...props}
      loadOptions={(value) => debouncedLoadOptions(value)}
    />
  )
}

FuzzyReactSelect.defaultProps = {
  wait: 300,
}

FuzzyReactSelect.propTypes = {
  options: PropTypes.array.isRequired,
  fuzzyOptions: PropTypes.object.isRequired,
  wait: PropTypes.number,
}

export default FuzzyReactSelect
