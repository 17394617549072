/** @format */

import axios from 'axios'
import config from '../config'

export const FETCH_BUY_RENT_LEGAL_VIEW = 'FETCH_BUY_RENT_LEGAL_VIEW'
export const FETCH_BUY_RENT_LEGAL_VIEW_LOADER = 'FETCH_BUY_RENT_LEGAL_VIEW_LOADER'
export const FETCH_BUY_RENT_LEGAL_VIEW_SUCCESS = 'FETCH_BUY_RENT_LEGAL_VIEW_SUCCESS'
export const FETCH_BUY_RENT_LEGAL_VIEW_FAILURE = 'FETCH_BUY_RENT_LEGAL_VIEW_FAILURE'

export const fetchBuyRentLegalView = (req) => {
  const role = localStorage.getItem('role')
  const urlParams = new URLSearchParams(window.location.search)
  const page = urlParams.get('page')

  if(req && req.transfer && req.transfer === true)
  {
    var endpoint =
    `${config.apiPath}/api/v1/leads?${
      role == 'sub_admin 5'
        ? '&saleType=pendingAccount'
        : role == 'sub_admin 6'
        ? '&saleType=pendingAccountHq'
        : ''
    }&legal=true&legalStatus=pending_upload_by_legal&web=true&pageSize=` +
    config.pageSize +
    '&page=' +
    page + '&transfer=' + true

  }
  else{
  var endpoint =
    `${config.apiPath}/api/v1/leads?${
      role == 'sub_admin 5'
        ? '&saleType=pendingAccount'
        : role == 'sub_admin 6'
        ? '&saleType=pendingAccountHq'
        : ''
    }&legal=true&legalStatus=pending_legal&web=true&pageSize=` +
    config.pageSize +
    '&page=' +
    page
  }
  if (req && req.offset && req.pageSize) {
    endpoint =
      `${config.apiPath}/api/v1/leads?sales=true${
        role == 'sub_admin 5'
          ? '&saleType=pendingAccount'
          : role == 'sub_admin 6'
          ? '&saleType=pendingAccountHq'
          : ''
      }&legal=true&web=true&&pageSize=` +
      config.pageSize +
      '&page=' +
      page
  } else if (req && req.all === true) {
    endpoint = `${config.apiPath}/api/v1/leads?sales=true${
      role == 'sub_admin 5'
        ? '&saleType=pendingAccount'
        : role == 'sub_admin 6'
        ? '&saleType=pendingAccountHq'
        : ''
    }&legal=true&web=true&`
  }
  return (dispatch) => {
    dispatch({ type: FETCH_BUY_RENT_LEGAL_VIEW_LOADER, data: true })
    axios
      .get(endpoint)
      .then(function (response) {
        dispatch({ type: FETCH_BUY_RENT_LEGAL_VIEW_SUCCESS, data: false })

        return dispatch({ type: FETCH_BUY_RENT_LEGAL_VIEW, data: response.data })
      })
      .catch(function (xhr, status, err) {
        return dispatch({ type: FETCH_BUY_RENT_LEGAL_VIEW_FAILURE, data: xhr })
      })
  }
}
export const fetchLegalBuyRentFilter = (url) => {
  const urlParams = new URLSearchParams(window.location.search)
  const page = urlParams.get('page')
  return (dispatch) => {
    dispatch({ type: FETCH_BUY_RENT_LEGAL_VIEW_LOADER, data: true })
    axios
      .get(`${url}&page=${page}&pageSize=${config.pageSize}`)
      .then(function (response) {
        dispatch({ type: FETCH_BUY_RENT_LEGAL_VIEW_SUCCESS, data: false })
        return dispatch({ type: FETCH_BUY_RENT_LEGAL_VIEW, data: response.data })
      })
      .catch(function (xhr, status, err) {
        return dispatch({ type: FETCH_BUY_RENT_LEGAL_VIEW_FAILURE, data: xhr })
      })
  }
}
