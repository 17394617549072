/** @format */

import React from 'react'
import Button from '../../../common/Button'
import { makeStyles } from '@material-ui/styles'
import {
  getLeadActions,
  leadStatusClass,
  isLeadClosed,
  assignedToMe,
  canAssignWantedLead,
  convertWantedLead,
} from '../../../../utils/lead'
import { getRequirement, getWantedAssignedTo } from '../../../common/Diary/DiaryUtilHelper'
import { RenderLeadStatus } from '../../../../utils/general'
import { StaticData } from '../../../../StaticData'
import { capitalize_Words } from '../../../../utils/commonFormat'
import { getPermissionValue } from '../../../_unlockComponent'
import { PermissionActions, PermissionFeatures } from '../../../../utils/permissionConstants'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { mapDispatchToProps } from '../ConnectForm'
import { reduxForm } from 'redux-form'
import { wantedStatusColors } from '../../../common/LeadView/Wanted/WantedUtilHelper'
import { arms_encrypt } from '../../../../utils/helper'

const useStyles = makeStyles({
  title: {
    fontSize: 28,
  },
  subTitle: {
    fontSize: 18,
    fontWeight: 600,
    color: '#026ff2',
  },
  closeBtn: {
    top: '5px!important',
    right: '5px!important',
  },
  bookUnitBtn: {
    border: '1px solid #026ff2',
    color: 'white!important',
    backgroundColor: '#026ff2',
    textDecoration: 'none!important',
  },
})

const LeadDetailHeader = (props) => {
  const classes = useStyles(props)
  const {
    lead,
    leadType,
    purpose,
    isValidPhone,
    leadProperty,
    assignLoader,
    handleSubmit,
    hideClient,
    setShouldAssign,
    storeBackUrl,
  } = props
  let statusStyle = {
    cursor: 'default',
    minWidth: 100,
    padding: 4,
  }
  if (leadType === 'Wanted') {
    const colors = wantedStatusColors(lead.armsStatus)
    statusStyle.color = colors.textColor
    statusStyle.textAlign = 'center'
    statusStyle.backgroundColor = colors.background
  }

  let currentUrl = props.location.pathname

  return (
    <div className={'row px-4 py-2'}>
      <div className={hideClient ? 'col-md-5' : 'col-md-6'}>
        {hideClient ? (
          <h4>Requirements</h4>
        ) : (
          <>
            <div className={'d-flex align-items-center'}>
              <strong className={`${classes.title}`}>
                {lead.customer
                  ? `${
                      lead.customer.first_name ? capitalize_Words(lead.customer.first_name) : ''
                    } ${lead.customer.last_name ? capitalize_Words(lead.customer.last_name) : ''}`
                  : '--'}
              </strong>

              <div
                className={
                  leadType === 'Project'
                    ? 'leadStatusColorContainer'
                    : leadType === 'BuyRent'
                    ? 'buyRentLeadStatus'
                    : ''
                }
              >
                <span
                  style={statusStyle}
                  className={`rounded d-block ml-4 ${leadStatusClass(lead, leadType)}`}
                >
                  {leadType === 'Wanted'
                    ? capitalize_Words(lead.armsStatus)
                    : RenderLeadStatus(
                        lead.status && lead.status.toLowerCase(),
                        StaticData.projectLeadStatus
                      )}
                </span>
              </div>
            </div>
            <div
              style={{
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
              }}
              className={`my-1 ${classes.subTitle}`}
            >
              <span>
                {getRequirement({
                  [leadType === 'Project'
                    ? 'armsProjectLead'
                    : leadType === 'BuyRent'
                    ? 'armsLead'
                    : 'wanted']: lead,
                })}
              </span>
            </div>
          </>
        )}
      </div>

      <div
        className={`${
          hideClient ? 'col-md-7' : 'col-md-6'
        } d-flex align-items-end justify-content-end ${hideClient ? 'pr-5' : ''}`}
      >
        <div className={`close-icon-container`}>
          <i
            onClick={() => props.onClose()}
            className={`fal fa-times-circle close-icon ${classes.closeBtn}`}
          ></i>
        </div>
        <div className={'d-flex'}>
          {leadType === 'Project' ? (
            <>
              {getPermissionValue(PermissionFeatures.PROJECT_LEADS, PermissionActions.UPDATE) &&
                assignedToMe(lead) &&
                !lead.hasBooking &&
                ((lead.project && lead.project.isActive) ||
                  lead.project === null ||
                  (lead.project && lead.project.name === null)) && (
                  <>
                    <Link
                      className={`d-flex align-items-center px-3 rounded mr-1 ${classes.bookUnitBtn}`}
                      to={lead.status == 'open' ? `/booking/ci?clientId=${
                        lead && lead.customer && lead.customer.id
                      }&leadId=${lead ? lead && lead.id : ''}` : `/booking/financial/${arms_encrypt('E',String(lead.imaratBookingId))}?leadId=${lead.id}`}
                    
                    >
                      Book Unit
                    </Link>
                    {/*<Link
                      style={{display:'none'}}
                      hidden={true}
                      to={`/project-lead-detail/${lead.id}`}
                      className={`d-flex align-items-center px-3 rounded mr-1 ${classes.bookUnitBtn}`}
                      onClick={() => props.isLeadRefresh(true)}
                    >
                      Book Unit{' '}
                    </Link>*/}
                  </>
                )}
              {lead.hasBooking && (
                <Link
                  onClick={() => {
                    storeBackUrl(currentUrl)
                    props.isLeadRefresh(true)
                  }}
                  to={`/booking/financial/${arms_encrypt('E',String(lead.imaratBookingId))}?leadId=${lead.id}`}
                  className={`d-flex align-items-center px-3 rounded mr-1 ${classes.bookUnitBtn}`}
                >
                  Payments{' '}
                </Link>
              )}

              {getPermissionValue(PermissionFeatures.DIARY, PermissionActions.UPDATE) &&
                assignedToMe(lead) &&
                !isLeadClosed(lead) && (
                  <Button
                    text={'Add Meeting'}
                    textColor={'#026ff2'}
                    buttonColor="white"
                    style={{ border: '1px solid #026ff2' }}
                    onClick={(action) => {
                      props.isLeadRefresh(true)
                      props.onAction('add-meeting')
                    }}
                    className={'px-2 rounded mr-1'}
                  />
                )}
            </>
          ) : (leadType === 'BuyRent' && !props.location.pathname.includes("buyRentLeadDetails")) ? (
            <>
            <Link
                onClick={() => storeBackUrl(currentUrl)}
                to={
                  isLeadClosed(lead)
                    ? `/buy-rent-deail-detail/${lead.id}`
                    : `/buyRentLeadDetails/${lead.id}`
                }
                className={`d-flex align-items-center px-3 rounded mr-1 ${classes.bookUnitBtn}`}
                onClick={() => props.isLeadRefresh(true)}
              >
                {isLeadClosed(lead) ? 'Details' : 'Open Workflow'}
              </Link>
            </>
          ) : leadType === 'Wanted' && canAssignWantedLead(lead) ? (
            <Button
              text={
                assignLoader ? (
                  <span className="fa fa-circle-o-notch fa-spin"></span>
                ) : (
                  getWantedAssignedTo({ wanted: { purpose: lead.purpose } }).label
                )
              }
              textColor={'white'}
              buttonColor="#026ff2"
              style={{ width: 236, padding: 6 }}
              className={'px-3 rounded mr-1'}
              disabled={assignLoader}
              onClick={
                props.connect &&
                ((['sell', 'rent out'].indexOf(purpose) > -1 && !leadProperty) || !isValidPhone)
                  ? () => {}
                  : props.action === 'connect'
                  ? handleSubmit
                  : () => {
                      setShouldAssign(true)
                      convertWantedLead(lead, (success) => {
                        if (success) props.onClose()
                        setShouldAssign(false)
                      })
                    }
              }
            />
          ) : (
            ''
          )}
          <Button
            text={'Tasks'}
            textColor={'#026ff2'}
            buttonColor="white"
            onClick={(action) => {
              props.onAction('scheduled-task')
              props.isLeadRefresh(true)
            }}
            style={{ border: '1px solid #026ff2' }}
            className={'px-4 rounded mr-1'}
          />

          <Button
            text={'Actions'}
            actionItems={getLeadActions(leadType, lead)}
            type={'dropdown'}
            caretColor={'#026ff2'}
            textColor={'#026ff2'}
            buttonColor="white"
            style={{ border: '1px solid #026ff2', color: '#026ff2' }}
            className={'px-3 rounded mr-1'}
            dataToggle="dropdown"
            onClick={(action) => {
              props.onAction(action)
              if (action && action !== 'additional-details') {
                props.isLeadRefresh(true)
              }
            }}
            orientation={'bottom'}
            width={'100px'}
            autoAdjustOrientation={true}
            // offSetLeft={hideClient ? '0' : '-145px'}
            right={hideClient ? '29px' : null}
            top={!hideClient ? 'unset' : null}
            left={!hideClient ? 'unset' : null}
          />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {}
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    reduxForm({
      form: 'client_requirement',
    })(LeadDetailHeader)
  )
)
