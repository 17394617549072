/** @format */

import { getPermissionValue } from '../components/_unlockComponent'
import { PermissionActions, PermissionFeatures } from './permissionConstants'

const accountsPermission = getPermissionValue(PermissionFeatures.PROJECT_PAYMENTS, [
  PermissionActions.CREATE,
  PermissionActions.READ,
  PermissionActions.UPDATE,
  PermissionActions.DELETE,
])
const accountsHQPermissions = getPermissionValue(
  PermissionFeatures.PROJECT_PAYMENTS,
  PermissionActions.CLEARANCE
)
const AccountHQProjectRebateButtonDownload = getPermissionValue(
  PermissionFeatures.PROJECT_PAYMENTS,
  PermissionActions.DOWNLOAD_REBATE_DATA
)

const AccountHQBuyRentRebateButtonDownload = getPermissionValue(
  PermissionFeatures.BUYRENT_PAYMENTS,
  PermissionActions.DOWNLOAD_REBATE_DATA
)

const ProjectReassign = getPermissionValue(
  PermissionFeatures.PROJECT_LEADS,
  PermissionActions.ASSIGN_REASSIGN
)
const BuyRentReassign = getPermissionValue(
  PermissionFeatures.BUY_RENT_LEADS,
  PermissionActions.ASSIGN_REASSIGN
)
const RentalDownload = getPermissionValue(
  PermissionFeatures.RENTAL_PAYMENTS,
  PermissionActions.DOWNLOAD_RENTAL_DATA
)

const RentalDashboard = getPermissionValue(
  PermissionFeatures.RENTAL_PAYMENTS,
  [
    PermissionActions.CREATE,
    PermissionActions.READ,
    PermissionActions.UPDATE,
    PermissionActions.DELETE,
  ]
)


export const accountsPermissions = (role) => {
  let checkPermission = {
    accounts: accountsPermission && !accountsHQPermissions,
    accountsHq: accountsHQPermissions,
    accountProjectRebate: AccountHQProjectRebateButtonDownload,
    accountBuyrentRebate: AccountHQBuyRentRebateButtonDownload,
    projectReassign: ProjectReassign,
    buyrentReassign: BuyRentReassign,
    rentalDownload: RentalDownload,
    rental: RentalDashboard
  }

  return checkPermission[role]
}
