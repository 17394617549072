/** @format */

import React, { useEffect, useState, useMemo } from 'react'
import { Modal, ModalBody } from 'reactstrap'
import { withRouter } from 'react-router-dom'
import InvestmentLead from '../../../views/Lead/MyLead/ProjectLead/Listing'
import BuyAndRentLead from '../../../views/Lead/MyLead/BuyRentLead/Listing'
import { makeStyles } from '@material-ui/styles'
import LeadView from './../../common/LeadView/Wanted/WantedLeads'
import { getCustomerWantedLeads } from '../../../actions/wantedLeads'
import { connect } from 'react-redux'
import Load from '../../Loader'
import config from '../../../config'
import { setExcludeLead } from '../../../actions/leads'
import Button from '../../common/Button'
import { PermissionActions, PermissionFeatures } from '../../../utils/permissionConstants'
import { getPermissionValue } from '../../../components/_unlockComponent'
import { buyAndRentLeads, fetchLeads } from '../../../actions/leads'
const useStyles = makeStyles({
  leadMenu: {
    whiteSpace: 'nowrap',
    cursor: 'pointer',
  },
  badge: {
    background: '#026ff2',
    width: 15,
    height: 14,
    display: 'inline-block',
    fontSize: 10,
    color: 'white',
    textAlign: 'center',
    borderRadius: 10,
    top: 2,
    right: 0,
    position: 'absolute',
  },
})
const ClientLead = (props) => {
  const classes = useStyles(props)

  const getDefaultTab = () => {
    if (
      getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.WANTED_LEADS_PAGE_VIEW)
    ) {
      return 'wanted'
    }
    if (
      getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.BUYRENT_LEADS_PAGE_VIEW)
    ) {
      return 'buyrent'
    }
    if (
      getPermissionValue(PermissionFeatures.WEB_PAGES, PermissionActions.PROJECT_LEADS_PAGE_VIEW)
    ) {
      return 'project'
    }
  }
  const [tab, setTab] = useState(getDefaultTab())

  useEffect(() => {
    props.setExcludeLead({ type: props.leadType, lead: props.excludeLead })
    props.fetchWantedLeads(props.customer.id)
  }, [])
  useEffect(() => {
    props.setExcludeLead({ type: props.leadType, lead: props.excludeLead })
    props.fetchWantedLeads(props.customer.id)
  }, [props.customer])

  useEffect(() => {
    let selectedTab = null
    if (props.clientLeadModal) {
      if (props.leadType === 'buyrent') selectedTab = 'buyrent'
      if (props.leadType === 'project') selectedTab = 'project'
      if (props.leadType === 'wanted') selectedTab = 'wanted'

      if (
        props.leadType === 'existinglead' &&
        props.projectLeads &&
        props.projectLeads.rows &&
        props.projectLeads.rows.length > 0
      ) {
        selectedTab = 'project'
      } else if (
        props.leadType === 'existinglead' &&
        props.buyrentLeads &&
        props.buyrentLeads.rows &&
        props.buyrentLeads.rows &&
        props.buyrentLeads.rows.length > 0
      ) {
        selectedTab = 'buyrent'
      } else if (
        props.leadType === 'existinglead' &&
        props.wantedLeads &&
        props.wantedLeads.rows &&
        props.wantedLeads.rows &&
        props.wantedLeads.rows.length > 0
      ) {
        selectedTab = 'wanted'
      }
      if (selectedTab === null) selectedTab = getDefaultTab()
      setTab(selectedTab)
    } else if (props.projectLeads && props.buyrentLeads && props.wantedLeads) {
      if (props.projectLeads.rows && props.projectLeads.rows.length) selectedTab = 'project'
      if (props.buyrentLeads.rows && props.buyrentLeads.rows.length) selectedTab = 'buyrent'
      if (props.wantedLeads.rows && props.wantedLeads.rows.length) selectedTab = 'wanted'

      setTab(selectedTab)
    }
/*
    console.log(props.customer,props.leadType,'props.customer')
*/
  }, [props.wantedLeads, props.projectLeads, props.buyrentLeads])

  let projectLeadShow = getPermissionValue(
    PermissionFeatures.WEB_PAGES,
    PermissionActions.PROJECT_LEADS_PAGE_VIEW
  )

  let buyRentLeadShow = getPermissionValue(
    PermissionFeatures.WEB_PAGES,
    PermissionActions.BUYRENT_LEADS_PAGE_VIEW
  )

  let wantedLeadShow = getPermissionValue(
    PermissionFeatures.WEB_PAGES,
    PermissionActions.WANTED_LEADS_PAGE_VIEW
  )

  return (
    <>
      <Modal
        isOpen={props.leadModal}
        toggle={(toggle) => {
          props.setLeadModal(!toggle)
          props.setExcludeLead(null)
        }}
        contentClassName={`no-custom`}
        size={'xl'}
      >
        <ModalBody className={'d-flex flex-column'}>
          <div className="close-icon-container">
            <i
              className="fal fa-times-circle close-icon"
              onClick={() => {
                props.setLeadModal(false)
                props.setExcludeLead(null)
              }}
            ></i>
          </div>
          <div className="float-left w-100">
            <h4>
              {`${props.customer.first_name || ''} ${props.customer.last_name || ''}`}'s Leads
            </h4>
            <div className="d-flex my-3 sub-menu">
              {wantedLeadShow && (
                <div
                  onClick={() => setTab('wanted')}
                  className={`menu-item p-2 ${tab === 'wanted' ? 'selected' : ''}`}
                >
                  <a className={`${classes.leadMenu}`}>Wanted</a>
                  {props.wantedLeads &&
                  props.wantedLeads.rows &&
                  props.wantedLeads.rows.length > 0 ? (
                    <span className={classes.badge}>{props.wantedLeads.rows.length}</span>
                  ) : (
                    <></>
                  )}
                </div>
              )}
              {buyRentLeadShow && (
                <div
                  onClick={() => setTab('buyrent')}
                  className={`menu-item p-2 ${tab === 'buyrent' ? 'selected' : ''}`}
                >
                  <a className={`${classes.leadMenu}`}>Buy/Rent</a>
                  {props.buyrentLeads &&
                  props.buyrentLeads.rows &&
                  props.buyrentLeads.rows.length > 0 ? (
                    <span className={classes.badge}>{props.buyrentLeads.rows.length}</span>
                  ) : (
                    <></>
                  )}
                </div>
              )}

              {projectLeadShow ? (
                <div
                  onClick={() => setTab('project')}
                  className={`menu-item p-2 ${tab === 'project' ? 'selected' : ''}`}
                >
                  <a className={`${classes.leadMenu}`}>Project</a>
                  {props.projectLeads &&
                  props.projectLeads.rows &&
                  props.projectLeads.rows.length > 0 ? (
                    <span className={classes.badge}>{props.projectLeads.rows.length}</span>
                  ) : (
                    <></>
                  )}
                </div>
              ) : null}

              {(props.leadType === 'existinglead' && tab !== 'wanted') ||
              (props.clientLeadModal && tab !== 'wanted') ? (
                <div className="float-right-lead ">
                  <Button
                    text={tab === 'project' ? '+ Add Project Lead' : '+ Add Buy/Rent Lead'}
                    textColor={'white'}
                    buttonColor="#026ff2"
                    className={'px-3 rounded-pill'}
                    onClick={(() =>{
                      props.setLeadModal(false)
                      props.setExcludeLead(null)
                      props.history.push(
                        tab === 'project'
                          ? {
                              pathname: '/new-lead',
                              state: { customer: props.customer },
                            }
                          : {
                              pathname: 'add-buy-rent-lead',
                              state: { customer: props.customer },
                            }
                      )
                      })
                    }
                  />
                </div>
              ) : null}
            </div>
          </div>
          <div style={{ minHeight: 500 }}>
            {projectLeadShow && (
              <>
                <div style={{ display: tab === 'project' ? 'block' : 'none' }}>
                  <InvestmentLead
                    otherleads={true}
                    customer={props.customer}
                    location={{ search: `?page=1&pageSize=${config.pageSize}` }}
                    popup={true}
                    clientLeadModal={props && props.clientLeadModal}
                    tabUrl={props && props.leadType}
                    tab={tab}
                    refreshLead={() => props.fetchLeads(props.customer && props.customer.id)}
                  />
                </div>
              </>
            )}

            {buyRentLeadShow && (
              <>
                <div style={{ display: tab === 'buyrent' ? 'block' : 'none' }}>
                  <BuyAndRentLead
                    otherleads={true}
                    customer={props.customer}
                    location={{ search: `?page=1&pageSize=${config.pageSize}` }}
                    popup={true}
                    clientLeadModal={props && props.clientLeadModal}
                    tabUrl={props && props.leadType}
                    tab={tab}
                    refreshLead={() => props.fetchBRLeads(props.customer && props.customer.id)}
                  />
                </div>
              </>
            )}

            <div
              style={{
                display: ['project', 'buyrent'].indexOf(tab) > -1 ? 'none' : 'block',
                backgroundColor: 'white',
                height:
                  props.wantedLeads && props.wantedLeads.rows && props.wantedLeads.rows.length > 5
                    ? 'calc(100vh - 220px)'
                    : 'auto',
              }}
            >
              {props.isLoading || tab === null ? (
                <Load title="Leads"></Load>
              ) : props.wantedLeads && props.wantedLeads.rows && props.wantedLeads.rows.length ? (
                wantedLeadShow && (
                  <LeadView
                    otherleads={true}
                    hideAction={true}
                    data={props.wantedLeads.rows}
                    dataCount={0}
                    pagination={{ currentPage: 1, pageSize: config.pageSize }}
                    onChangePage={() => {}}
                    refreshLead={() => props.fetchWantedLeads(props.customer && props.customer.id)}
                  ></LeadView>
                )
              ) : (
                <div className="dash-container br-lead-page-wrap">
                  <div className="text-center" style={{ marginTop: 60, marginBottom: 50 }}>
                    <h3 className="dimgray">No Leads Found</h3>
                  </div>
                </div>
              )}
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

export const mapDispatchToProps = (dispatch) => {
  return {
    fetchBRLeads: (query, sortingOrder, callback, hasBooking, clientLead, tabUrl, client) =>
      dispatch(buyAndRentLeads(query, callback, hasBooking, clientLead, tabUrl, client)),
    fetchWantedLeads: (customerId) => dispatch(getCustomerWantedLeads(customerId)),
    fetchLeads: (query, sortingOrder, callback, bookingCondition, specialCase) =>
      dispatch(fetchLeads(query, sortingOrder, callback, bookingCondition, specialCase)),
    setExcludeLead: (data) => dispatch(setExcludeLead(data)),
  }
}

export const mapStateToProps = (state) => {
  return {
    wantedLeads: state.WantedLeads.customerLeads,
    projectLeads: state.Leads.client_leads,
    buyrentLeads: state.Leads.client_brleads,
    isLoading: state.WantedLeads.cLoading,
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ClientLead))
