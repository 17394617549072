/** @format */

import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { Modal, ModalBody } from 'reactstrap'
import LeadTimeLine from '../../LeadTimeLine'
import LeadDetailTabView from '../../LeadDetailTabView'
import PDFThumbnail from '../../PDFThumbnail'
import Word from '../../../images/word.png'
import EXCEL from '../../../images/excel.png'
import { connect } from 'react-redux'


import {
  fetchLeadTimeLine,
  fetchLeadAttachments,
  fetchSingleLead,
  fetchLeadTasks,
} from '../../../actions/leads'
import ClientHistory from '../../common/ClientHistory'
import './style.scss'
const queryString = require('query-string')

const useStyles = makeStyles({
  root: {
    // display: 'grid',
    height: '440px',
    overflow:'auto',
    gridTemplateAreas:
      '"his his his his det det" "att att att att det det" "con con con con con con"',
    gridAutoRows: '400px 100px 100px ',
  },
  history: { gridArea: 'his', width: '700px', position: 'relative' },
  details: { gridArea: 'det', position: 'relative' },
  attachments: {
    gridArea: 'att',
    width: '700px',
  },
  connect: { gridArea: 'con', borderTop: '1px solid #e4e4e5', marginTop: '20px' },
  attachmentRoot: {
    display: 'flex',
    gridGap: '10px',
  },
  connectRoot: {
    display: 'flex',
    gridGap: '15px',
  },
  attachment: {
    width: '60px',
    height: '60px',
    cursor: 'pointer',
  },
  headAbs: {
    position: 'absolute',
    left: '15px',
    top: '-3%',
  },
  heading1: {
    fontSize: '16px',
    fontWeight: 'bold',
  },
  heading2: {
    fontSize: '13px',
    fontWeight: 'bold',
    margin: '5px',
  },
  detailRoot: {
    marginTop: '25px',
    overflow: 'auto',
    height: '-webkit-fill-available',
  },
  historyRoot: {
    marginTop: '25px',
    overflow: 'auto',
    height: '-webkit-fill-available',
  },

})
const AdditionalDetails = (props) => {
  const classes = useStyles(props)
  const [tasks, setTasks] = useState([])
  const [checkData, setCheckData] = useState(true)

  useEffect(() => {

    const parsed = props.location ? queryString.parse(props.location.search) : {}
    if (props.additionalDetailLeadrecord) {
      //todo cmLeadId replace
      const leadTaskParams = {
        taskType: ['called', 'meeting', 'task', 'follow_up'],
        cmLeadId: props.additionalDetailLeadrecord.id,
      }
      // props.fetchLeadTasks(props.additionalDetailLeadrecord.id, leadTaskParams, (data) => {
      //   setTasks(data)
      // })
      //   props.fetchLeadTimeLine(props.additionalDetailLeadrecord.id)
      // props.fetchLeadAttachments(props.additionalDetailLeadrecord.id)
    }
    else{
      setCheckData(false)
    }
  }, [])

  const getHistory = () => {
    return (
      <div className={classes.historyRoot}>
        {/* <LeadTimeLine
          fetchSingleLead={() => {
            return props.additionalDetailLeadrecord
          }}
          closeAfterAdd={false}
          shouldCloseAfterAdd={() => {}}
          content={tasks}
          checkForRead={false}
          updateActiveTab={false}
        /> */}
        <ClientHistory history={tasks}></ClientHistory>
      </div>
    )
  }
  const getDetails = () => {
        const {fetchLeadTimeLine,ApiCall,checkLeadtype,componentOf,leadType} = props
        const {id,projectId,purpose} = props.additionalDetailLeadrecord
        const {armsStatus} =props.ParentComponentData ? props.ParentComponentData :  ""

    return (
      <div className={classes.detailRoot}>
        <LeadDetailTabView
          tabActive={'additionalDetailTab'}
          showReadOnly={false}
          ownLeadReadOnly={false}
          updateCollapse={() => {}}
          collapse={false}
          checkForRead={false}
          fetchLeadTimeLine={fetchLeadTimeLine}
          leadId={id}
          ApiCall={ApiCall}
          checkProjectId={projectId}
          CheckPurpose ={purpose}
          checkLeadtype={checkLeadtype}
          leadType={leadType}
        //  ParentComponentData={ParentComponentData}
          armsStatus={armsStatus}
          componentOf={componentOf}

        />
      </div>
    )
  }
  const extractFileExtension = (str) => {
    let fileExtension = str.split('.').pop()
    return fileExtension
  }
  const getAttachments = () => {
    if (props.leadAttachments && props.leadAttachments.length > 0) {
      return (
        <div className={classes.attachmentRoot}>
          {props.leadAttachments.map((item) => {
            return (
              <div onClick={() => window.open(item.value, '_blank')}>
                {extractFileExtension(item.value) === 'pdf' ? (
                  <PDFThumbnail file={item.value} />
                ) : (
                  <img
                    className={clsx(classes.attachment, 'tumbnailImg')}
                    src={
                      extractFileExtension(item.value) === 'word'
                        ? Word
                        : extractFileExtension(item.value) === 'xlsx'
                        ? EXCEL
                        : extractFileExtension(item.value) === 'jpg' ||
                          extractFileExtension(item.value) === 'png'
                        ? item.value
                        : null
                    }
                    alt="word"
                  />
                )}

                {/* <p className={'wordBreak'}>{item.fileName}</p> */}
              </div>
            )
          })}
        </div>
      )
    } else {
      return <div style={{ marginLeft: '15px' }}>No Attachment Found</div>
    }
  }
  const getConnect = () => {
    if (
      props.singleLeadRecord &&
      props.singleLeadRecord.customer &&
      props.singleLeadRecord.customer.customerContacts &&
      props.singleLeadRecord.customer.customerContacts.length > 0
    ) {
      return (
        <div className={classes.connectRoot}>
          {props.singleLeadRecord.customer.customerContacts.map((phone) => {
            return <div>{phone.phone}</div>
          })}
        </div>
      )
    } else {
      return <div style={{ marginLeft: '15px' }}>No Phone Numbers Found</div>
    }
  }
  const parsed = props.location ? queryString.parse(props.location.search) : {}
 
  return (
    <>
      <Modal
        isOpen={props.isOpen}
        toggle={(toggle) => props.onToggle(!toggle)}
        contentClassName="clientDetails no-custom"
        className=""
        size="md"
      >

        <ModalBody>
        <div className={`ml-3 mt-2 ${clsx(classes.heading1)}`}>Lead's Additional Info</div>
          <div className="additionalDetail close-icon-container">
            <i className="fal fa-times-circle close-icon" onClick={() => props.onToggle(false)}></i>
          </div>
          <div  className={classes.root}>
          {  props.additionalDetailLeadrecord === null || props.additionalDetailLeadrecord === '{}' ? 
          <div className={classes.details}>
          <div className={clsx(classes.heading1, classes.headAbs)}>
          <p className='additionlDetailNotFound'>No Additional Detail Found</p> </div>
          </div>
          : 
    
            
            <div className={classes.details}>
              
              
              {getDetails()}
            </div> }
            
          </div>

        </ModalBody>
      </Modal>
    </>
  )
}

export const mapDispatchToProps = (dispatch) => {
  return {
    // fetchLeadTimeLine: (id) => dispatch(fetchLeadTimeLine(id)),
    fetchLeadAttachments: (id) => dispatch(fetchLeadAttachments(id)),
    fetchLeadTasks: (leadId, params, callback) =>
      dispatch(fetchLeadTasks(leadId, params, callback)),
    fetchSingleLead: (id) => dispatch(fetchSingleLead(id, false)),
  }
}

export const mapStateToProps = (state) => {
  return {
    // leadTimeLineData: state.Leads.leadTimeLineData,
    leadAttachments: state.Leads.leadAttachments,
    singleLeadRecord: state.Leads.singleLeadRecord,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalDetails)




