/** @format */

import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom'
import '../../views/Dashboard/style.css'
import './style.scss'
import axios from 'axios'
import { menus } from './menuRole'
import { UnlockFeature } from '../_unlockComponent'
import { connect } from 'react-redux'
import config from '../../config'
import { fetchProjects } from '../../actions/projects'
import { mouseEnter, unitTrueFlag, unitFalseFlag, unitFloorDetect } from '../../actions/navToggle'
import Loader from 'react-loader-spinner'
import TopLogo from '../../images/logo-white-arms.svg'

import { userRedirectOnClick } from '../../utils/userRedirect'
import Swal from 'sweetalert2'
const queryString = require('query-string')
class CollapseMenu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      addcustomer: '',
      addprop: '',
      properties: '',
      deactivatedproperties: '',
      allcustomers: '',
      deactivatedcustomers: '',
      deactivatedUser: '',
      teams: '',
      deactivatedTeam: '',
      deactivatedProjects: '',
      projectmain: '',
      project: '',
      projectfloor: '',
      projectshops: '',
      bookshop: '',
      users: '',
      zones: '',
      wanted: '',
      Organizations: '',
      sales: '',
      collapse: '',
      showExpended: false,
      projectLeads: '',
      buyRentLeads: '',
      buyrentsales: '',
      unitData: '',
      mouseEnter: false,
      localMouseEnter: false,
      itemId: '',
    }
    this.baseState = this.state
  }
  RouteTo = (route) => {
    let value = route.split('/').join('')
    let newState = { ...this.baseState, [value]: 'active-class' }
    this.setState({ ...newState })
  }
  componentDidMount() {
    let value = this.props.location.pathname.split('/').join('')
    let currentUrl = window.location.href.replace(/^.*\/\/[^\/]+/, '')
    let newState = { ...this.baseState, [value]: 'new-add' }
    this.setState({
      ...newState,
    })

    const role = localStorage.getItem('role')

    if (role === 'imarat_system_user') {
      this.props.fetchProjects(1, `${config.apiPath}/api/project/all`)
    }

    menus.map((item) => {
      item.children.map((navItem) => {
        if (navItem.url === currentUrl) {
          this.setState({
            collapse: item.id,
          })
        }
      })
    })

    var user = localStorage.getItem('user_agency_mgmt')
    var token = user ? JSON.parse(user).data.token : null
    axios.defaults.headers.common['Authorization'] = token
  }
  changeUnitStatus = (name) => {
    if (name === 'Units') {
      this.props.fetchProjects(1, `${config.apiPath}/api/project/all?`, 'not')
      setTimeout(() => {
        this.setState({
          projectmain: '',
        })
      }, 1200)
    }

    setTimeout(() => {
      return this.setState({
        unitData: name,
      })
    }, 1000)
  }

  toggle = (id) => {
    if (!this.state.collapse || id !== this.state.collapse) {
      this.setState({
        collapse: id,
      })
    }
  }
  mouseEnterHandler = (id) => {
    if (!this.state.collapse || id !== this.state.collapse) {
      this.setState({
        collapse: id,
      })
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.location.pathname !== this.props.location.pathname) {
      let value = nextProps.location.pathname.split('/').join('')
      let newState = { ...this.baseState, [value]: 'active-class' }
      this.setState({ ...newState, collapse: this.state.collapse })
    }

    this.setState({
      localMouseEnter: nextProps.mouseEnterFlag,
    })
  }
  elMouseLeave = () => {
    const { mouseEnterFlag } = this.props
    if (!mouseEnterFlag) {
      this.setState({ collapse: '' })
    }
  }
  render() {
    const userType = localStorage.getItem('role')
    const { collapse, showExpended, unitData, localMouseEnter, itemId } = this.state
    const { allProjects, loader } = this.props
    const parsed = queryString.parse(this.props.location.search)

    return (
      <div style={{ marginRight: -15 }}>
        <div className="collapse-left-side-container">
          <div className="dash-child mb-3">
            {(() => {
              return (
                menus.map(
                  (item, index) =>
                    // <UnlockFeature feature={item.feature} action={item.action}/*currentUser={userType || null} roles={item.roles} key={index}*/>
                    item.isShow && (
                      <div
                        onMouseEnter={() => {
                          this.props.mouseEnter()
                          this.setState({ itemId: item.id })
                        }}
                      >
                        <div
                          className={
                            itemId === item.id || item.id === collapse
                              ? 'hoverCollapseChild'
                              : 'collapseChild'
                          }
                          key={index}
                        >
                          <div>
                            <p
                              onClick={() => {
                                this.RouteTo(`/${item.url}`)
                                this.toggle(item.id)
                              }}
                              className={`element ${this.state[item.activeTab]}`}
                            >
                              <span className="iconSmall">
                                <img
                                  src={item.iconsSmall}
                                  alt="NA"
                                  style={{ marginLeft: item.name === 'TEAMS' ? -2 : 0 }}
                                  className="icon-size"
                                />
                              </span>
                            </p>
                          </div>

                          {itemId === item.id ? (
                            <div className="prop-child posRelative">
                              <div className="collapseMenuHover">
                                <ul>
                                  {item.children.map((item, index) => (
                                    <>
                                      {item.isShow ? (
                                        <li
                                          key={index}
                                          className={
                                            (item.expanded && unitData === 'Units') ||
                                            (this.props.history.location.pathname ===
                                              '/projectmain' &&
                                              item.expanded &&
                                              unitData === 'Units')
                                              ? 'active-class'
                                              : `${this.state[item.activeTab]}`
                                          }
                                          onClick={() => {
                                            this.props.unitTrueFlag()
                                            this.props.unitFalseFlag()
                                            this.props.unitFloorDetect(true)
                                            this.setState(
                                              {
                                                collapse: item.itemId,
                                                showExpended: !showExpended,
                                              },
                                              () => this.toggle(item.itemId)
                                            )
                                            return this.changeUnitStatus(item.name)
                                          }}
                                        >
                                          <Link
                                            onClick={() => this.RouteTo(`/${item.url}`)}
                                            to={item.url}
                                            className={this.state[item.activeTab]}
                                          >
                                            {item.name}
                                          </Link>
                                        </li>
                                      ) : (
                                        <UnlockFeature feature={item.feature} action={item.action}>
                                          <li
                                            key={index}
                                            className={
                                              (item.expanded && unitData === 'Units') ||
                                              (this.props.history.location.pathname ===
                                                '/projectmain' &&
                                                item.expanded &&
                                                unitData === 'Units')
                                                ? 'active-class'
                                                : `${this.state[item.activeTab]}`
                                            }
                                            onClick={() => {
                                              this.props.unitTrueFlag()
                                              this.props.unitFalseFlag()
                                              this.props.unitFloorDetect(true)
                                              this.setState(
                                                {
                                                  collapse: item.itemId,
                                                  showExpended: !showExpended,
                                                },
                                                () => this.toggle(item.itemId)
                                              )
                                              return this.changeUnitStatus(item.name)
                                            }}
                                          >
                                            <Link
                                              onClick={() => this.RouteTo(`/${item.url}`)}
                                              to={item.url}
                                              className={this.state[item.activeTab]}
                                            >
                                              {item.name}
                                            </Link>
                                          </li>
                                          {/* {
                                    (item.expanded && unitData === 'Units' || this.props.history.location.pathname === '/units' && item.expanded === 'Units' && item.expanded === 'Units') && (
                                      <div>
                                        {loader ? <><div style={{ marginBottom: 10, display: 'flex', flexDirection: 'row', justifyContent: 'center' }}> <Loader type="Oval" color="#0f73ee" height="20" width="20" /></div></> : allProjects && allProjects.items.length
                                          && allProjects &&
                                          allProjects.items.map((item) => (
                                            <p
                                              className="expanded-area-text"
                                              onClick={() => {
                                                item && item.floors && item.floors.length ?
                                                  this.props.history.push(
                                                    `/units?projectId=${item.id}&projectName=${item.name}&floorId=${item && item.floors && item && item.floors && item.floors[0].id}`,
                                                    { item }
                                                  ) : Swal.fire({
                                                    type: "error",
                                                    title: "Error",
                                                    text: "Please add floor against this project",
                                                  })
                                              }
                                              }
                                            >
                                              <span
                                                className={
                                                  parsed.projectName ===
                                                    item.name
                                                    ? "selected-menu-txt "
                                                    : ""
                                                }
                                              >
                                                {item.name}{" "}
                                              </span>
                                            </p>
                                          ))
                                        }
                                      </div>
                                    )
                                  } */}
                                        </UnlockFeature>
                                      )}
                                    </>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    )
                  // </UnlockFeature>
                ) || <></>
              )
            })()}
          </div>
        </div>
      </div>
    )
  }
}

export const mapDispatchToProps = (dispatch) => {
  return {
    fetchProjects: (page, url, flag) => dispatch(fetchProjects(page, url, flag)),
    mouseEnter: () => dispatch(mouseEnter()),
    unitTrueFlag: () => dispatch(unitTrueFlag()),
    unitFalseFlag: () => dispatch(unitFalseFlag()),
    unitFloorDetect: (data) => dispatch(unitFloorDetect(data)),
  }
}

const mapStateToProps = (state) => {
  return {
    allProjects: state.Projects.projects,
    loader: state.Projects.loader,
    mouseEnterFlag: state.navToggle.mouseEnterFlag,
    collapseMenu: state.navToggle.collapseMenu,
    roleId: state.user.userInfo.roleId,
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CollapseMenu))
