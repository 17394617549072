const ptclCodes = () => {
  return [
    "0992",
    "057",
    "0856",
    "0297",
    "063",
    "062",
    "0942",
    "0928",
    "0829",
    "0453",
    "05828",
    "0832",
    "0939",
    "0825",
    "0543",
    "0943",
    "0966",
    "025",
    "0835",
    "064",
    "0945",
    "0944",
    "041",
    "0723",
    "055",
    "053",
    "086",
    "0547",
    "0925",
    "0995",
    "022",
    "051",
    "0722",
    "0838",
    "0837",
    "047",
    "0544",
    "0826",
    "0844",
    "021",
    "0927",
    "049",
    "0852",
    "0243",
    "065",
    "0847",
    "0454",
    "0848",
    "0823",
    "0922",
    "0998",
    "0574",
    "0926",
    "042",
    "0969",
    "074",
    "0853",
    "0606",
    "0608",
    "0824",
    "0932",
    "0546",
    "0997",
    "0937",
    "0843",
    "0459",
    "0233",
    "0924",
    "061",
    "0828",
    "058",
    "066",
    "0542",
    "0242",
    "0244",
    "0923",
    "044",
    "0457",
    "0855",
    "091",
    "081",
    "068",
    "0604",
    "040",
    "0235",
    "048",
    "0996",
    "056",
    "0726",
    "052",
    "0833",
    "0965",
    "071",
    "0938",
    "0946",
    "0963",
    "0232",
    "0298",
    "046",
    "0238",
    "067",
    "0822",

    '0300',
    '0301',
    '0302',
    '0303',
    '0304',
    '0305',
    '0306',
    '0307',
    '0308',
    '0309',

    '0310',
    '0311',
    '0312',
    '0313',
    '0314',
    '0315',
    '0316',
    '0317',
    '0318',
    '0319',

    '0320',
    '0321',
    '0322',
    '0323',
    '0324',
    '0325',
    '0326',
    '0327',
    '0327',
    '0329',

    '0330',
    '0331',
    '0332',
    '0333',
    '0334',
    '0335',
    '0336',
    '0337',
    '0338',
    '0339',

    '0340',
    '0341',
    '0342',
    '0343',
    '0344',
    '0345',
    '0346',
    '0347',
    '0348',
    '0349',
  ];
}
const scom = () => {
  return [
    '03555'
  ]
}

const validateNumber = (number) => {
  const threeDigit = `${number.charAt(0)}${number.charAt(1)}${number.charAt(2)}` /* ptcl check 3 digit */
  const fourDigit = `${number.charAt(0)}${number.charAt(1)}${number.charAt(2)}${number.charAt(3)}`/* mobile + ptcl */
  const fiveDigit = `${number.charAt(0)}${number.charAt(1)}${number.charAt(2)}${number.charAt(3)}`/* scom */
  let numberFlag = false;

  if (number.length < 6) {
    numberFlag = true
  }
  if (!numberFlag) {
    ptclCodes().map((code, i) => {
      if (threeDigit == code) {
        numberFlag = true
      }
      if (fourDigit == code) {
        numberFlag = true;
      }
    })
  }
  /*  if (!numberFlag) {
      ptclCodes().map((code, i) => {
        if (fourDigit == code) {
          numberFlag = true;
        }
      })
    }*/
  if (!numberFlag) {
    scom().map((code, i) => {
      if (fiveDigit == code) {
        numberFlag = true;
      }
    })
  }
  return numberFlag;
}

export {ptclCodes, scom, validateNumber};
