/** @format */

import React, { useState, useEffect } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import Select from 'react-select'
import Load from '../../../components/Loader'
import Pagination from 'rc-pagination'
import 'rc-pagination/assets/index.css'
import { capitalize_Words } from '../../../utils/commonFormat'
import './style.scss'
import config from '../../../config'

const ReferModal = ({
  shareToggleState,
  shareToggle,
  loader,
  agents,
  fetchAgents,
  filterReferLeadAgentName,
  investmentLeadShareFun,
  shareLeadType,
  assignLeadFun,
  multipleLead,
  leadAssign,
  headerType,
  selectAdvisorInfo,
  agentCount,
  processing,
  leadRow,
  toggleAutoResignModal,
  autoLeadOptionShow
}) => {
  let [searchString, setSearchString] = useState('')
  const [pageSize, setPageSize] = useState(0)
  const [page, setPage] = useState(1)

  searchString = searchString.trim().toLowerCase()

  // if (shareLeadType !== 'share') {
  //   if (searchString.length > 0) {
  //     agents = agents.filter(function (i) {
  //       let fullName = i.firstName + ' ' + i.lastName
  //
  //       return fullName.toLowerCase().match(searchString)
  //     })
  //   }
  // }

  let role = localStorage.getItem('subRole')
  let mainRole = localStorage.getItem('role')

  const handleQueryChange = (current, pageSize) => {
    setPageSize(pageSize)
    setPage(current)
    current && filterReferLeadAgentName(searchString, current)
  }

  const fetchFiltersData = () => {
    filterReferLeadAgentName(searchString, 1)
  }

  const getAutoLeadAssignmentBtn = () => {
    return autoLeadOptionShow ? <button
      className="link-style projectLeadButton m-2"
      style={{ padding: '0 !important' }}
      onClick={() => {
        toggleAutoResignModal(true)
      }}

    >
      Assign automatically based on Area
    </button> : null
  }

  return (
    <>
      <Modal
        isOpen={shareToggleState}
        toggle={shareToggle}
        size="lg"
        // style={{ maxWidth: '1156px', width: '100%' }}
        style={{ maxWidth: '1049px', width: '100%' }}
        contentClassName="share-modal-width "
      >
        <ModalHeader>
          {headerType === 'Select Advisor'
            ? headerType
            : shareLeadType === 'share'
              ? 'Refer Lead'
              : 'Re-assign Lead'}
          <div className="close-icon-container">
            <i
              className="fal fa-times-circle close-icon"
              onClick={() => {
                headerType === 'Select Advisor' ? selectAdvisorInfo({}) : shareToggle()
              }}
            ></i>
          </div>
        </ModalHeader>
        <ModalBody>
          <>
            <div className="share-lead-container">
              <div className="row">
                <div className="col-md-3">
                  <div className="search-box">
                    <input
                      type="text"
                      className="search-box-style"
                      placeholder="Search by advisor name"
                      onChange={(e) => setSearchString(e.target.value)}
                    />
                  </div>
                </div>
                {/*{shareLeadType === 'share' && (*/}
                  <div
                    class="col-1 makeStyles-root-2 makeStyles-root-12ReferMOdalSearchBtn makeStyles-restrictTextSelection-6 searchBtn"
                    onClick={() => {
                      fetchFiltersData()
                      // if (
                      //   searchString !== ''
                      // )

                      // else {}
                      // this.props.setMoreFilter({
                      //   ...this.props.moreFilter,
                      //   showModal: false,
                      //   activeFilter: false,
                      //   filterCount: 0,
                      // })
                    }}
                  >
                    <div class="Tooltip-Wrapper">
                      <span style={{ color: 'white' }} class="makeStyles-root-10 makeStyles-root-14">
                        Search
                      </span>
                    </div>
                  </div>
                {/*)}*/}

                {(shareLeadType === 'assign' && role === 'branch_manager' && multipleLead !== true) ||
                  (shareLeadType === 'assign' && role === 'zonal_manager' && multipleLead !== true) ||
                  (shareLeadType === 'assign' && mainRole === 'sub_admin 1' && multipleLead === true) ||
                  (shareLeadType === 'assign' &&
                    role === 'business_centre_manager' &&
                    multipleLead !== true) ||
                  (shareLeadType === 'assign' &&
                    role === 'call_centre_manager' &&
                    multipleLead !== true) ? (
                  <div className="col-md-3">
                    {/* <Select
                    options={managerSelectionDropdown}
                    placeholder="Select Drodpown"
                    value={
                      managerType && managerType != {}
                        ? Object.values(managerType).length > 0
                          ? managerType
                          : managerSelectionDropdown && managerSelectionDropdown[0]
                        : []
                    }
                    onChange={(value) => {
                      setMangerType(value)
                      fetchAgents(value.value)
                    }}
                  /> */}
                  </div>
                ) : null}
                <div className="col text-right">
                  {getAutoLeadAssignmentBtn()}
                </div>
              </div>
            </div>
            <div className="row ">
              <div className="user-form">
                <table className="share-modal-table" id="table" style={{ width: '100%' }}>
                  <thead>
                    <tr>
                      <th style={{ minWidth: 88, maxWidth: 88 }}>Id</th>
                      <th style={{ minWidth: 150, maxWidth: 150 }}>Name</th>
                      <th style={{ minWidth: 164, maxWidth: 164 }}>Title</th>
                      <th style={{ minWidth: 160, maxWidth: 160 }}>Organisation</th>
                      <th style={{ minWidth: 160, maxWidth: 160 }}>Region</th>
                      <th style={{ minWidth: 168, maxWidth: 168 }}>Role</th>
                      {/* <th style={{ minWidth: 120, maxWidth: 120 }}>Team</th> */}
                      <th style={{ minWidth: 110, maxWidth: 110 }}>Action</th>
                    </tr>
                  </thead>
                  {loader ? (
                    <Load title="Users" />
                  ) : agents && agents && agents.length === 0 ? (
                    <div style={{ marginTop: '60px', marginBottom: 50 }} className="text-center">
                      <h3 className="dimgray">No Users Found</h3>
                    </div>
                  ) : (
                    <tbody>
                      {agents &&
                        agents.map((item) => (
                          <tr>
                            <td style={{ minWidth: 90, maxWidth: 90 }}>{item.id}</td>
                            <td style={{ minWidth: 150, maxWidth: 150 }}>
                              {item.firstName + ' ' + item.lastName}
                            </td>
                            <td style={{ minWidth: 165, maxWidth: 165 }}>
                              {item.title ? item.title : '--'}
                            </td>
                            <td style={{ minWidth: 160, maxWidth: 160 }}>
                              {item && item.organization
                                ? capitalize_Words(item.organization.name)
                                : '--'}
                            </td>

                            <td style={{ minWidth: 160, maxWidth: 160 }}>
                              {(item && item.region && item.region.name) || '--'}
                            </td>
                            <td style={{ minWidth: 160, maxWidth: 160 }}>
                              {item && item.armsUserRole && item.armsUserRole.subRole}
                            </td>

                            {/* <td style={{ minWidth: 120, maxWidth: 120 }}>
                            {item && item.armsTeam ? item && item.armsTeam.teamName : '--'}
                          </td> */}

                            <td style={{ minWidth: 110, maxWidth: 110 }}>
                              <button
                                className=" link-style mr-2 ml-2 btn-hollow-activate"
                                onClick={() => {
                                  shareLeadType === 'advisor'
                                    ? assignLeadFun(item)
                                    : multipleLead
                                      ? leadAssign(item.id)
                                      : shareLeadType === 'assign'
                                        ? assignLeadFun(item.id, item.firstName, item.lastName)
                                        : investmentLeadShareFun(item.id, item.firstName, item.lastName)
                                }}
                              >
                                {processing && leadRow === item.id ? (
                                  <i class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
                                ) : shareLeadType === 'assign' ? (
                                  'Re-assign'
                                ) : shareLeadType === 'advisor' ? (
                                  'Select'
                                ) : (
                                  'Refer'
                                )}
                              </button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  )}
                </table>
                {/*{shareLeadType === 'share' && (*/}
                  <div className="mt-2 row">
                    <div className="col-4 pt-2">
                      <span className="totalCountStyle">
                        Showing
                        <span className="totalCountSubStyle">
                          {(page - 1) * config.pageSize + 1} -
                          {agentCount >= page * config.pageSize ? page * config.pageSize : agents && agentCount}
                        </span>{' '}
                        / {agentCount || 1}
                      </span>
                    </div>

                    <Pagination
                      style={{ display: 'table' }}
                      className={'paginationCustomStyle'}
                      onChange={handleQueryChange}
                      current={page}
                      pageSize={config.pageSize}
                      showPrevNextJumpers={false}
                      total={Math.round(agentCount)}
                    />
                  </div>
                {/*)}*/}
              </div>
            </div>
          </>
        </ModalBody>
      </Modal>

    </>
  )
}

export default ReferModal
