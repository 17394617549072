import React from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from 'reactstrap'
import './style.scss'

const DeleteInvestmentLead = (props) => {
    const {
        deleteToggle,
        selectedLeads,
        deletionReason,
        deleteOpen,
        deleteProjectLead,
        onChangeText,
        deleteInvestmentLeadCloseModal,
    } = props

    return (
        <Modal isOpen={deleteOpen} toggle={deleteToggle} className="delete-popup">
            <ModalHeader>Delete Leads?</ModalHeader>
            <ModalBody>
                <p>
                    <p className="text-danger">
                        {(selectedLeads && selectedLeads.length === 1) ||
                            (selectedLeads && selectedLeads.length === 0)
                            ? 'Are you sure you want to delete this lead? This process cannot be undone'
                            : `Are you sure you want to delete these ${selectedLeads && selectedLeads.length
                            } leads? This process cannot be undone`}
                    </p>
                    <hr />
                    <textarea
                        placeholder="Reason"
                        onChange={(e) => { onChangeText(e) }}
                        className="form-control"
                        id="exampleFormControlTextarea1"
                        rows="3"
                    ></textarea>
                </p>
            </ModalBody>
            <ModalFooter>
                <Button
                    color="secondary"
                    onClick={() => {
                        { deleteInvestmentLeadCloseModal() }
                    }}
                >
                    Cancel
                </Button>
                <Button
                    color="danger"
                    onClick={() => {
                        deleteProjectLead()
                    }}
                    disabled={!deletionReason ? true : false}
                >
                    Delete
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default DeleteInvestmentLead;