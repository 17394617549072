/** @format */

import React, { useState, useEffect } from 'react'
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap'
import RequirementReadView from './RequirementReadView'
import LeadDetailHeader from '../LeadDetailHeader'

const RequirementReadViewModal = (props) => {
  const [isLoading, setLoading] = useState(false)

  return (
    <>
      <Modal
        isOpen={props.open}
        toggle={props.toggle}
        size="lg"
        style={{ maxWidth: '800px', width: '100%' }}
        // contentClassName="diary-stat-modal"
      >
        <ModalBody>
          <div>
            <div className="">
            <LeadDetailHeader
              hideClient={true}
              leadType={props.leadType}
              lead={props.lead}
              onClose={() => props.toggle()}
              onAction={action => props.onAction(action)}
            />
            </div>
            <hr />
            <RequirementReadView
              lead={props.lead}
              hideTitle={true}
              leadProperty={{
                id:
                  props.lead &&
                  props.lead.properties &&
                  props.lead.properties.length > 0 &&
                  props.lead.properties[0].id,
              }}
              leadType={props.leadType}
            ></RequirementReadView>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

export default RequirementReadViewModal
