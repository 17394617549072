/** @format */

import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { Modal, ModalBody } from 'reactstrap'
import LeadTimeLine from '../../../components/LeadTimeLine'
import LeadDetailTabView from '../../../components/LeadDetailTabView'
import PDFThumbnail from '../../../components/PDFThumbnail'
import Word from '../../../images/word.png'
import EXCEL from '../../../images/excel.png'
import { connect } from 'react-redux'

import {
  fetchLeadTimeLine,
  fetchLeadAttachments,
  fetchSingleLead,
  fetchLeadTasks,
} from '../../../actions/leads'
import ClientHistory from '../../common/ClientHistory'

const useStyles = makeStyles({
  root: {
    display: 'grid',
    gridTemplateAreas:
      '"his his his his det det" "att att att att det det" "con con con con con con"',
    gridAutoRows: '400px 100px 100px ',
  },
  history: { gridArea: 'his', width: '700px', position: 'relative' },
  details: { gridArea: 'det', position: 'relative' },
  attachments: {
    gridArea: 'att',
    width: '700px',
  },
  connect: { gridArea: 'con', borderTop: '1px solid #e4e4e5', marginTop: '20px' },
  attachmentRoot: {
    display: 'flex',
    gridGap: '10px',
  },
  connectRoot: {
    display: 'flex',
    gridGap: '15px',
  },
  attachment: {
    width: '60px',
    height: '60px',
    cursor: 'pointer',
  },
  headAbs: {
    position: 'absolute',
    left: '15px',
  },
  heading1: {
    fontSize: '16px',
    fontWeight: 'bold',
  },
  heading2: {
    fontSize: '13px',
    fontWeight: 'bold',
    margin: '5px',
  },
  detailRoot: {
    marginTop: '25px',
    overflow: 'auto',
    height: '-webkit-fill-available',
  },
  historyRoot: {
    marginTop: '25px',
    overflow: 'auto',
    height: '-webkit-fill-available',
  },
})
const ClientDetails = (props) => {
  const classes = useStyles(props)
  const [tasks, setTasks] = useState([])

  useEffect(() => {
    if (props.leadRecord) {
      //todo cmLeadId replace
      const leadTaskParams = {
        taskType: ['called', 'meeting', 'task', 'follow_up'],
        cmLeadId: props.leadRecord.id,
      }
      props.fetchLeadTasks(props.leadRecord.id, leadTaskParams, (data) => {
        setTasks(data)
      })
      //   props.fetchLeadTimeLine(props.leadRecord.id)
      props.fetchLeadAttachments(props.leadRecord.id)
    }
  }, [])

  const getHistory = () => {
    return (
      <div className={classes.historyRoot}>
        {/* <LeadTimeLine
          fetchSingleLead={() => {
            return props.leadRecord
          }}
          closeAfterAdd={false}
          shouldCloseAfterAdd={() => {}}
          content={tasks}
          checkForRead={false}
          updateActiveTab={false}
        /> */}
        <ClientHistory history={tasks}></ClientHistory>
      </div>
    )
  }
  const getDetails = () => {
    return (
      <div className={classes.detailRoot}>
        <LeadDetailTabView
          tabActive={'calls'}
          showReadOnly={false}
          ownLeadReadOnly={false}
          updateCollapse={() => {}}
          collapse={false}
          checkForRead={false}
          fetchLeadTimeLine={props.fetchLeadTimeLine}
          leadId={props.leadRecord.id}
        />
      </div>
    )
  }
  const extractFileExtension = (str) => {
    let fileExtension = str.split('.').pop()
    return fileExtension
  }
  const getAttachments = () => {
    if (props.leadAttachments && props.leadAttachments.length > 0) {
      return (
        <div className={classes.attachmentRoot}>
          {props.leadAttachments.map((item) => {
            return (
              <div onClick={() => window.open(item.value, '_blank')}>
                {extractFileExtension(item.value) === 'pdf' ? (
                  <PDFThumbnail file={item.value} />
                ) : (
                  <img
                    className={clsx(classes.attachment, 'tumbnailImg')}
                    src={
                      extractFileExtension(item.value) === 'word'
                        ? Word
                        : extractFileExtension(item.value) === 'xlsx'
                        ? EXCEL
                        : extractFileExtension(item.value) === 'jpg' ||
                          extractFileExtension(item.value) === 'png'
                        ? item.value
                        : null
                    }
                    alt="word"
                  />
                )}

                {/* <p className={'wordBreak'}>{item.fileName}</p> */}
              </div>
            )
          })}
        </div>
      )
    } else {
      return <div style={{ marginLeft: '15px' }}>No Attachment Found</div>
    }
  }
  const getConnect = () => {
    if (
      props.singleLeadRecord &&
      props.singleLeadRecord.customer &&
      props.singleLeadRecord.customer.customerContacts &&
      props.singleLeadRecord.customer.customerContacts.length > 0
    ) {
      return (
        <div className={classes.connectRoot}>
          {props.singleLeadRecord.customer.customerContacts.map((phone) => {
            return <div>{phone.phone}</div>
          })}
        </div>
      )
    } else {
      return <div style={{ marginLeft: '15px' }}>No Phone Numbers Found</div>
    }
  }
  return (
    <>
      <Modal
        isOpen={props.isOpen}
        toggle={(toggle) => props.onToggle(!toggle)}
        contentClassName="clientDetails no-custom"
        className=""
        size="xl"
      >
        <ModalBody>
          <div className="close-icon-container" style={{ height: '10px' }}>
            <i className="fal fa-times-circle close-icon" onClick={() => props.onToggle(false)}></i>
          </div>
          <div className={classes.root}>
            <div className={classes.history}>
              <div className={clsx(classes.heading1, classes.headAbs)}>History</div>
              {getHistory()}
            </div>
            <div className={classes.details}>
              <div className={clsx(classes.heading1, classes.headAbs)}>Details</div>
              {getDetails()}
            </div>
            <div className={classes.attachments}>
              <div className={classes.heading2}>Attachments</div>
              {getAttachments()}
            </div>
            <div className={classes.connect}>
              <div className={classes.heading2}>Connect with Client</div>
              {getConnect()}
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

export const mapDispatchToProps = (dispatch) => {
  return {
    // fetchLeadTimeLine: (id) => dispatch(fetchLeadTimeLine(id)),
    fetchLeadAttachments: (id) => dispatch(fetchLeadAttachments(id)),
    fetchLeadTasks: (leadId, params, callback) =>
      dispatch(fetchLeadTasks(leadId, params, callback)),
    fetchSingleLead: (id) => dispatch(fetchSingleLead(id, false)),
  }
}

export const mapStateToProps = (state) => {
  return {
    // leadTimeLineData: state.Leads.leadTimeLineData,
    leadAttachments: state.Leads.leadAttachments,
    singleLeadRecord: state.Leads.singleLeadRecord,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientDetails)
