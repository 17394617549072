/** @format */

import * as ActionTypes from '../actions/projects'
const initialState = {}

const Projects = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_PROJECTS:
      return {
        ...state,
        projects: action.data,
      }
    case ActionTypes.FETCH_PROJECT_LOADING:
      return {
        projectLoader: true,
      }
    case ActionTypes.FETCH_PROJECT_SUCCESS:
      return {
        projectLoader: false,
      }
    case ActionTypes.ADD_PROJECT:
      return {
        ...state,
        projects: action.data,
      }

    case ActionTypes.FETCH_FLOORS:
      return {
        ...state,
        floors: action.data,
      }
    case ActionTypes.FETCH_PROJECT_FLOOR:
      return {
        ...state,
        projectFloors: action.data,
      }
    case ActionTypes.FETCH_UNITS:
      return {
        ...state,
        units: action.data,
      }
    case ActionTypes.FETCH_ALL_FLOORS_LOADER:
      return {
        ...state,
        floorsAllLoader: true,
      }
    case ActionTypes.FETCH_ALL_FLOORS_SUCCESS:
      return {
        ...state,
        floorsAllLoader: false,
      }
    case ActionTypes.FETCH_DEACTIVATED_PROJECTS:
      return {
        ...state,
        deactivatedProjects: action.data,
      }
    case ActionTypes.ADD_PROJECT_LOADER:
      return {
        ...state,
        addProjectLoader: action.data,
      }
    case ActionTypes.ADD_PROJECT_LOADER_SUCCESS:
      return {
        ...state,
        addProjectLoader: action.data,
      }
    case ActionTypes.ADD_FLOOR_LOADER:
      return {
        ...state,
        addFloorLoader: action.data,
      }
    case ActionTypes.ADD_FLOOR_LOADER_SUCCESS:
      return {
        ...state,
        addFloorLoader: action.data,
      }
    case ActionTypes.ADD_UNIT_LOADER:
      return {
        ...state,
        addUnitLoader: action.data,
      }
    case ActionTypes.ADD_UNIT_LOADER_SUCCESS:
      return {
        ...state,
        addUnitLoader: action.data,
      }
    case ActionTypes.FETCH_UNITS_LOADING:
      return {
        ...state,
        unitsLoader: true,
      }
    case ActionTypes.FETCH_UNITS_SUCCESS:
      return {
        ...state,
        unitsLoader: false,
      }
    case ActionTypes.FETCH_ALL_PROJECTS:
      return {
        ...state,
        allProjects: action.data,
      }
    case ActionTypes.SET_PROJECT_TITLE:
      return {
        ...state,
        projectDocumentTitle: action.data,
      }
    case ActionTypes.FETCH_DEACTIVATED_PROJECTS_LOADER:
      return {
        ...state,
        deactivatedLoader: action.data,
      }
    case ActionTypes.FETCH_DEACTIVATED_PROJECTS_SUCCESS:
      return {
        ...state,
        deactivatedLoader: action.data,
      }
    case ActionTypes.FETCH_DELETED_UNITS:
      return {
        ...state,
        deletedUnits: action.data,
      }
    case ActionTypes.FETCH_DELETED_UNITS_LOADER:
      return {
        ...state,
        deletedUnitsLoader: action.data,
      }
    case ActionTypes.FETCH_DELETED_UNITS_SUCCESS:
      return {
        ...state,
        deletedUnitsLoader: action.data,
      }
    case ActionTypes.FETCH_DELETED_FLOORS:
      return {
        ...state,
        deletedFloors: action.data,
      }
    case ActionTypes.FETCH_DELETED_FLOORS_LOADER:
      return {
        ...state,
        deletedFloorsLoader: action.data,
      }
    case ActionTypes.FETCH_DELETED_FLOORS_SUCCESS:
      return {
        ...state,
        deletedFloorsLoader: action.data,
      }
    case ActionTypes.SELECTED_IMAGES:
      return {
        ...state,
        selectedImages: action.data,
      }
    case ActionTypes.SELECTED_EDIT_IMAGES:
      return {
        ...state,
        selectedEditImages: action.data,
      }
    case ActionTypes.FETCH_INVESTMENT_PRODUCTS:
      return {
        ...state,
        investmentProducts: action.data,
      }
    case ActionTypes.FETCH_INVESTMENT_PRODUCTS_LOADER:
      return {
        ...state,
        investmentProductsLoader: action.data,
      }
    case ActionTypes.ADD_INVESTMENT_PRODUCT_LOADER:
      return {
        ...state,
        addInvestmentProductLoader: action.data,
      }
    case ActionTypes.ALL_PRODUCTS:
      return {
        ...state,
        allProducts: action.data,
      }
    case ActionTypes.UPDATE_PRODUCT_LOADER:
      return {
        ...state,
        updateProductLoader: action.data,
      }
    default:
      return {
        ...state,
      }
  }
}

export default Projects
