/** @format */

import React, { useState } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import Select from 'react-select'
import Load from '../../../components/Loader'
import { capitalize_Words } from '../../../utils/commonFormat'

const ShareModal = ({
  shareToggleState,
  shareToggle,
  managerSelectionDropdown,
  loader,
  agents,
  fetchAgents,
  investmentLeadShareFun,
  shareLeadType,
  assignLeadFun,
  multipleLead,
  leadAssign,
  headerType,
  selectAdvisorInfo,
}) => {
  let [searchString, setSearchString] = useState('')
  const [managerType, setMangerType] = useState({})

  searchString = searchString.trim().toLowerCase()
  if (searchString.length > 0) {
    agents = agents.filter(function (i) {
      let fullName = i.firstName + ' ' + i.lastName

      return fullName.toLowerCase().match(searchString)
    })
  }

  let role = localStorage.getItem('subRole')
  let mainRole = localStorage.getItem('role')

  return (
    <Modal
      isOpen={shareToggleState}
      toggle={shareToggle}
      size="lg"
      style={{ maxWidth: '1049px', width: '100%' }}
      contentClassName="share-modal-width "
    >
      <ModalHeader>
        {headerType === 'Select Advisor'
          ? headerType
          : shareLeadType === 'share'
          ? 'Share Lead'
          : 'Assign Lead'}
        <div className="close-icon-container">
          <i
            className="fal fa-times-circle close-icon"
            onClick={() => {
              headerType === 'Select Advisor' ? selectAdvisorInfo({}) : shareToggle()
            }}
          ></i>
        </div>
      </ModalHeader>
      <ModalBody>
        <>
          <div className="share-lead-container">
            <div className="row">
              <div className="col-md-3">
                <div className="search-box">
                  <input
                    type="text"
                    className="search-box-style"
                    placeholder="Search by advisor name"
                    onChange={(e) => setSearchString(e.target.value)}
                  />
                </div>
              </div>
              {(shareLeadType === 'assign' && role === 'branch_manager' && multipleLead !== true) ||
              (shareLeadType === 'assign' && role === 'zonal_manager' && multipleLead !== true) ||
              (shareLeadType === 'assign' && mainRole === 'sub_admin 1' && multipleLead === true) ||
              (shareLeadType === 'assign' &&
                role === 'business_centre_manager' &&
                multipleLead !== true) ||
              (shareLeadType === 'assign' &&
                role === 'call_centre_manager' &&
                multipleLead !== true) ? (
                <div className="col-md-3">
                  {console.log(managerType, managerSelectionDropdown)}
                  <Select
                    options={managerSelectionDropdown}
                    placeholder="Select Drodpown"
                    value={
                      managerType && managerType != {}
                        ? Object.values(managerType).length > 0
                          ? managerType
                          : managerSelectionDropdown && managerSelectionDropdown[0]
                        : []
                    }
                    onChange={(value) => {
                      setMangerType(value)
                      fetchAgents(value.value)
                    }}
                  />
                </div>
              ) : null}
            </div>
          </div>
          <div className="row ">
            <div className="user-form">
              <table className="share-modal-table" id="table" style={{ width: '100%' }}>
                <thead>
                  <tr>
                    <th style={{ minWidth: 90, maxWidth: 90 }}>Id</th>
                    <th style={{ minWidth: 150, maxWidth: 150 }}>Name</th>
                    <th style={{ minWidth: 165, maxWidth: 165 }}>Title</th>
                    <th style={{ minWidth: 160, maxWidth: 160 }}>Organisation</th>
                    <th style={{ minWidth: 150, maxWidth: 150 }}>Region</th>
                    <th style={{ minWidth: 170, maxWidth: 170 }}>Role</th>
                    {/* <th style={{ minWidth: 120, maxWidth: 120 }}>Team</th> */}
                    <th style={{ minWidth: 120, maxWidth: 120 }}>Action</th>
                  </tr>
                </thead>
                {loader ? (
                  <Load title="Users" />
                ) : agents && agents && agents.length === 0 ? (
                  <div style={{ marginTop: '60px', marginBottom: 50 }} className="text-center">
                    <h3 className="dimgray">No Users Found</h3>
                  </div>
                ) : (
                  <tbody>
                    {agents &&
                      agents.map((item) => (
                        <tr>
                          <td style={{ minWidth: 90, maxWidth: 90 }}>{item.id}</td>
                          <td style={{ minWidth: 150, maxWidth: 150 }}>
                            {item.firstName + ' ' + item.lastName}
                          </td>
                          <td style={{ minWidth: 165, maxWidth: 165 }}>
                            {item.title ? item.title : '--'}
                          </td>
                          <td style={{ minWidth: 160, maxWidth: 160 }}>
                            {item && item.organization
                              ? capitalize_Words(item.organization.name)
                              : '--'}
                          </td>

                          <td style={{ minWidth: 150, maxWidth: 150 }}>
                            {(item && item.region && item.region.name) || '--'}
                          </td>
                          <td style={{ minWidth: 170, maxWidth: 170 }}>
                            {item && item.armsUserRole && item.armsUserRole.subRole}
                          </td>

                          {/* <td style={{ minWidth: 120, maxWidth: 120 }}>
                            {item && item.armsTeam ? item && item.armsTeam.teamName : '--'}
                          </td> */}

                          <td style={{ minWidth: 120, maxWidth: 120 }}>
                            <button
                              className=" link-style mr-2 ml-2 btn-hollow-activate"
                              onClick={() => {
                                shareLeadType === 'advisor'
                                  ? assignLeadFun(item)
                                  : multipleLead
                                  ? leadAssign(item.id)
                                  : shareLeadType === 'assign'
                                  ? assignLeadFun(item.id)
                                  : investmentLeadShareFun(item.id)
                              }}
                            >
                              {shareLeadType === 'assign'
                                ? 'Assign'
                                : shareLeadType === 'advisor'
                                ? 'Select'
                                : 'Share'}
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </>
      </ModalBody>
    </Modal>
  )
}

export default ShareModal
