/** @format */

import { actionTypes } from 'redux-form'
import * as ActionTypes from '../actions/diary'
import {
  ACTIVATE_DIALER,
  ASSIGN_LEAD_ID_DIARY_ID,
  DIALER_LEAD_INFO,
  GLOBAL_SOCKET_CALL_CENTER,
  SOFT_PHONE_DIAL_NUMBER
} from "../actions/diary";

const initialState = {
  createTaskModalToggle: false,
  activateDialer:false,
  dialerLeadInformation:{
    armLeadUserId:null,
    currentAmsUserId:null,
    isOwnLead:false
  },
  mws_leadId_diaryId:{
    leadId:'',
    diaryId:'',
    leadType:''
  },
  mwsSoftPhoneDial:{
    phoneNumber:'',
    leadId:'',
    diaryId:'',
    leadType:''
  },
  isLoading: false,
  filters: {
    diary: {
      filters: {},
      quickFilters: [],
      dataSort: null,
    },
    overDue: {
      filters: {},
      quickFilters: [],
      dataSort: null,
    },
  },
}

const Diary = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.DIARY_TASK_TOGGLE:
      return {
        ...state,
        createTaskModalToggle: !state.createTaskModalToggle,
      }
    case ActionTypes.ACTIVATE_DIALER:
      return {
        ...state,
        activateDialer:action.payload
      }

    case ActionTypes.FETCH_DIARY_TASKS:
      return {
        ...state,
        diaryTasks: action.data,
        isLoading: action.loading,
      }
    case ActionTypes.FETCH_LEAD_DIARY_TASKS:
      return {
        ...state,
        leadDiaryTasks: action.data,
        isLoadingLeadTask: action.loading,
      }
    case ActionTypes.FETCH_OVERDUE_DIARY_TASKS:
      return {
        ...state,
        overDueDiaryTasks: action.data,
        isLoading: false,
      }
    case ActionTypes.SET_LOADING:
      return {
        ...state,
        isLoading: action.data,
      }
    case ActionTypes.SET_OVERDUE_COUNT:
      return {
        ...state,
        overDueCount: action.data,
      }
    case ActionTypes.FETCH_SOCKET_DATA:
      return {
        ...state,
        isLoading: false,
      }
    case ActionTypes.FETCH_DIARY_FEEDBACKS:
      return {
        ...state,
        diaryFeedbacks: action.data,
        feedbackLoading: action.loading
      }
    case ActionTypes.SET_DIARY_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          diary: {
            ...state.filters.diary,
            filters: action.data,
          },
        },
      }
    case ActionTypes.SET_DIARY_QUICK_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          diary: {
            ...state.filters.diary,
            quickFilters: action.data,
          },
        },
      }
    case ActionTypes.SET_OVERDUE_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          overDue: {
            ...state.filters.overDue,
            filters: action.data,
          },
        },
      }
    case ActionTypes.SET_OVERDUE_QUICK_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          overDue: {
            ...state.filters.overDue,
            quickFilters: action.data,
          },
        },
      }
    case ActionTypes.SET_IS_OVER_DUE:
      return {
        ...state,
        isOverDue: action.data,
      }
    case ActionTypes.SET_DIARY_MENU_ACTION:
      return {
        ...state,
        menuAction: action.data,
      }
    case ActionTypes.SET_SELECTED_AGENT:
      return {
        ...state,
        selectedAgent: action.data,
      }
    case ActionTypes.SET_DIARY_DATA_SORT:
      return {
        ...state,
        filters: {
          ...state.filters,
          diary: {
            ...state.filters.diary,
            dataSort: action.data,
          },
        },
      }
    case ActionTypes.SET_DIARY_OVER_DUE_SORT:
      return {
        ...state,
        filters: {
          ...state.filters,
          overDue: {
            ...state.filters.overDue,
            dataSort: action.data,
          },
        },
      }
    case ActionTypes.SOFT_PHONE_DIAL_NUMBER:
      return {
        ...state,
        mwsSoftPhoneDial:action.payload
      }
      break;
    case ActionTypes.ASSIGN_LEAD_ID_DIARY_ID:
      return {
        ...state,
        mws_leadId_diaryId:action.payload
      }
      break;

    case ActionTypes.DIALER_LEAD_INFO:
      console.log(action.payload,'action .payload')
      return {
        ...state,
        dialerLeadInformation:{...action.payload,isOwnLead: (action.payload.armLeadUserId ===action.payload.currentAmsUserId)}
      }
      break;
    default:
      return state
  }
}
export default Diary
