/** @format */

import * as ActionTypes from '../actions/agentManagement'

const initialState = {
  refreshAgents: false,
}

const agentsManagement = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.AGENT_REFRESH_FLAG:
      return {
        ...state,
        refreshAgents: !state.refreshAgents,
      }
    default:
      return state
  }
}
export default agentsManagement
