import * as ActionTypes from "../actions/target";
const initialState = {};

const Targets = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.FETCH_TARGETS:
            return {
                ...state,
                targets: action.data
            }
        case ActionTypes.FETCH_TARGET_LOADER:
            return {
                ...state,
                targetLoader: action.data
            }
        case ActionTypes.FETCH_TARGET_SUCCESS:
            return {
                ...state,
                targetLoader: action.data
            }

        default:
            return {
                ...state,
            };
    }
};

export default Targets;
