/** @format */

import React, { useEffect, useState } from 'react'
import axios from 'axios'
import config from '../config'
import Modal from 'react-bootstrap/Modal'
import { ModalHeader } from 'reactstrap'
import Loader from '../components/Loader'
import Load from '../components/Loader'
import moment from 'moment/moment'

const AgentMisscall = ({ isOpenMissCall, closeOpenModalState, phoneDialCall }) => {
  const [miscallLogs, setMiscallLogs] = useState([])
  const [loader, setLoader] = useState(true)
  const getMisscallReport = async (selectedArmUserId) => {
    await axios
      .post(`${config.apiPath}/api/cdrreport/missedCallReport`, {})
      .then((res) => {
        setMiscallLogs(res.data)
        setLoader(false)
      })
      .catch((err) => {
        setLoader(false)
      })
  }

  useEffect(() => {
    if (isOpenMissCall) {
      getMisscallReport().then(() => {})
    } else {
      setLoader(true)
    }
  }, [isOpenMissCall])

  return (
    <>
      <div>
        <Modal show={isOpenMissCall} toggle={closeOpenModalState} size="ml" backdrop="static">
          <ModalHeader
            style={{
              background: 'none !important',
              border: 'none',
            }}
          >
            <div className="row ml-1 mr-3">
              <div className="heading-wrap pl-3 pt-2">
                <h5 style={{ color: '#7d7e7f' }}>
                  {' '}
                  {/* {editMode ? "Edit Campaign" : "Add Campaign"}{" "} */}
                </h5>
              </div>

              <div className="close-icon-container">
                <i
                  className="fal fa-times-circle close-icon"
                  onClick={() => {
                    closeOpenModalState()
                  }}
                ></i>
              </div>
            </div>
          </ModalHeader>
          <Modal.Body>
            <div style={{ maxHeight: '400px', overflowY: 'scroll' }}>
              {loader && (
                <div className="dash-container br-lead-page-wrap">
                  <div className="text-center" style={{ marginTop: 60, marginBottom: 50 }}>
                    <Load title="Customer Miscall"></Load>
                  </div>
                </div>
              )}
              {!loader && (
                <table className="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>Phone</th>
                      <th>Date At</th>
                      <th style={{ textAlign: 'center' }} width={'100px'}>
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {miscallLogs.map((row, i) => {
                      return (
                        <tr key={i}>
                          <td>
                            {row.phone_no} ({row.direction == 'incoming' ? 'IN' : 'OUT'})
                          </td>
                          <td>{moment(row.createdAt).format('DD-MM-YYYY (hh:mm A)')}</td>
                          <td style={{ textAlign: 'center' }}>
                            <button
                              onClick={() => {
                                phoneDialCall(row.phone_no, row.id, row.time)
                              }}
                              type={'button'}
                              className={'btn btn-sm btn-primary'}
                            >
                              <i className={'fas fa-phone'} />
                            </button>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              )}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}

export default AgentMisscall
