/** @format */

import Swal from 'sweetalert2'
import { showToastMsg } from '../utils/helper'
// import {
//   USER_LOGIN, ADD_USER, SIGN_UP_FAIL, USER_LOGOUT, USER_LOGIN_FAILURE
// } from '../actions/user'
import * as ActionTypes from '../actions/user'
import { FETCH_USER_SUBUSERS } from '../actions/user'

const initialState = {
  cities: [],
  isLeadClientError: false,
  allOrganisations: []
}

const User = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.USER_LOGIN:
      localStorage.setItem('role', action.data.data.role)
      localStorage.setItem('subRole', action.data.data.subRole)
      localStorage.setItem('zoneId', action.data.data.zoneId)
      return {
        ...state,
        Login: action.data.data,
      }
    case ActionTypes.USER_LOGOUT:
      localStorage.removeItem('role')
      return {}
    case ActionTypes.USER_LOADING:
      return {
        ...state,
        loading: action.data,
        Login: { status: 'loading' },
      }
    case ActionTypes.ADD_USER_LOADER:
      return {
        ...state,
        addUserLoader: true,
      }
    case ActionTypes.ADD_USER_LOADER_SUCCESS:
      return {
        ...state,
        addUserLoader: false,
      }
    case ActionTypes.ADD_ORGANIZATION:
      Swal.fire('Organization Added', 'Organization added Successfully', 'success')
      return {
        ...state,
        organizations: action.data,
      }

    case ActionTypes.FETCH_AGENCIES:
      return {
        ...state,
        agencies: action.data.items,
      }
    case ActionTypes.FETCH_ZONES:
      return {
        ...state,
        Zones: action.data,
      }
    case ActionTypes.RESET_USER_RESPONSE:
      return {
        ...state,
        user: action.data,
      }
    case ActionTypes.SHOW_USER_UPDATE_SUCCESS:
      showToastMsg('User Updated Successfully', 'success')
      return {
        ...state,
        user: action.data,
      }
    case ActionTypes.ADD_USER:
      if (action.data.data.message === 'User created Successfully') {
        showToastMsg('User created Successfully', 'success')
      }
      return {
        ...state,
        user: action.data,
      }
    case ActionTypes.USER_LOGIN_FAILURE:
      return {
        ...state,
        Login: { status: 'fail' },
      }
    case ActionTypes.FAIL_ORGANIZATION:
      Swal.fire({
        type: 'error',
        title: 'Error',
        icon: 'error',
        text: 'Same Organization name is not allowed',
      })
      return false
    case ActionTypes.SIGN_UP_FAIL:
      return false
    case ActionTypes.CLEAR_BUTTON_LOADER:
      return {
        ...state,
        clearButton: false,
      }
    case ActionTypes.CLEAR_BUTTON_SUCCESS:
      return {
        ...state,
        clearButton: true,
      }
    case ActionTypes.DELETE_USER_FAILED:
      return {
        deactivateUserFailureState: action.data,
      }
    case ActionTypes.SHOW_USER_SUCCESS:
      return {
        ...state,
        allUsers: action.data.data,
        isLoading: false,
      }
    case ActionTypes.DEACTIVATED_USERS_FETCH:
      return {
        ...state,
        deactivatedUsersData: action.data,
        isLoading: false,
      }
    case ActionTypes.FETCH_MANAGER_LIST:
      return {
        ...state,
        managerList: action.data,
        isLoading: false,
      }
    case ActionTypes.SHOW_USER_FAILURE:
      Swal.fire({
        type: 'error',
        title: 'Error',
        icon: 'error',
        text: 'Some thing went wrong',
      })
      return {
        ...state,
        allUsers: action.data,
        isLoading: false,
      }
    case ActionTypes.FETCH_USERS_SUCCESS:
      return {
        ...state,
        allUsers: action.data,
        isLoading: false,
      }
    case ActionTypes.FETCH_CITIES:
      return {
        ...state,
        cities: action.data,
      }
    case ActionTypes.FETCH_COUNTRIES:
      return {
        ...state,
        countries: action.data,
      }
    case ActionTypes.FETCH_AIRA_CITIES:
      return {
        ...state,
        airaCities: action.data,
      }
    case ActionTypes.FETCH_ALL_USERS_SUCCESS:
      return {
        ...state,
        allUsersForFIlterDropdown: action.data,
        allUsersForFilter: action.data,
        isLoading: false,
      }
    case ActionTypes.DELETE_USERS_SUCCESS:
      if (action.data) {
        var deleted = state.allUsers.items.filter((x) => x.id !== action.data)
        return {
          ...state,
          allUsers: { ...state.allUsers, items: deleted },
          isLoading: false,
        }
      }
      break
    case ActionTypes.ACTIVE_USERS_SUCCESS:
      if (action.data) {
        var active = state.deactivatedUsersData.items.filter((x) => x.id !== action.data)
        return {
          ...state,
          deactivatedUsersData: {
            ...state.deactivatedUsersData,
            items: active,
          },
          isLoading: false,
        }
      }
      break
    case ActionTypes.FETCH_USERS:
      return {
        ...state,
        isLoading: true,
      }
    case ActionTypes.CHANGED_PASSWORD_SUCCESS:
      return {
        ...state,
        passwordStatus: 'success',
      }
    case ActionTypes.CHANGED_PASSWORD_FAILURE:
      return {
        ...state,
        passwordStatus: 'fail',
      }
    case ActionTypes.CHANGED_PASSWORD:
      return {
        ...state,
        passwordStatus: '',
      }
    case ActionTypes.FETCH_UNASSIGNED_ALL_AREAS:
      return {
        ...state,
        unassignedAreas: action.data,
      }
    case ActionTypes.FETCH_UNASSIGNED_ALL_AREAS_LAODER:
      return {
        ...state,
        unassignedAreasLoader: action.data,
      }
    case ActionTypes.FETCH_UNASSIGNED_ALL_AREAS_SUCCESS:
      return {
        ...state,
        unassignedAreasLoader: action.data,
      }
    case ActionTypes.ALL_USERS:
      return {
        ...state,
        allUsers: action.data,
      }
    case ActionTypes.USERS_INFO_BEGIN:
      return state
    case ActionTypes.USERS_INFO_SUCCESS:
      return {
        ...state,
        userInfo: action.data,
      }
    case ActionTypes.USERS_INFO_FAILURE:
      return {
        ...state,
        userInfo: action.data,
      }
    case ActionTypes.FETCH_DELETION_USERS:
      return {
        ...state,
        deletionUsers: action.data,
      }
    case ActionTypes.FETCH_DELETION_USERS_LOADER:
      return {
        ...state,
        deletionUsersLoader: action.data,
      }
    case ActionTypes.FETCH_DELETION_USERS_SUCCESS:
      return {
        ...state,
        deletionUsersLoader: action.data,
      }
    case ActionTypes.FETCH_USER_DEVICES:
      return {
        ...state,
        devices: action.data,
        deviceFlag: action.deviceFlag,
      }
    case ActionTypes.FETCH_USER_SUBUSERS_SUCCESS:
      return {
        ...state,
        sub_users: action.data,
      }
    case ActionTypes.SET_IS_TERMINAL_USER:
      return {
        ...state,
        isTerminalUser: action.data,
      }
    case ActionTypes.FETCH_ALL_PRIMARY_JOURNEY:
      return {
        ...state,
        allPrimaryJourney: action.data,
      }
    case ActionTypes.LEAD_TYPE:
      return {
        ...state,
        leadType: action.data,
      }
    case ActionTypes.LAST_ADDED_CLIENT:
      return {
        ...state,
        client: action.data,
      }
    case ActionTypes.SET_CLIENT_LEAD_ERROR:
      return {
        ...state,
        isLeadClientError: action.data,
      }
    case ActionTypes.FETCH_ALL_ORGANIZATIONS:
      return {
        ...state,
        allOrganisations: action.data,
      }
    case ActionTypes.FETCH_NON_TERMINAL_AGENTS:
      return {
        ...state,
        nonTerminalAgents: action.data,
      }
    case ActionTypes.FETCH_ALL_AGENTS:
      return {
        ...state,
        allAgents: action.data,
      }
    default:
      return {
        ...state,
      }
  }
}

export default User
