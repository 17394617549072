import axios from 'axios'
import config from '../config'

export const FETCH_ALL_ROLES = 'FETCH_ALL_ROLES'

export const fetchAllRoles = () => {
  return (dispatch) => {
    axios.get(`${config.apiPath}/api/role/all?all=true`).then((res) => {
      dispatch({ type: FETCH_ALL_ROLES, data: res.data.rows })
    })
  }
}
