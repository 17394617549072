import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import moment from 'moment'
import AtttachmentModalEditProperty from '../Modals/AttachmentModalEditProperty'
import "../Modals/modalsStyle.scss"
import config from '../../config'
import axios from 'axios'
import { StaticData } from '../../StaticData'
import {
    investmentMeeting,
    fetchLeadTimeLine,
    fetchLeads,
    investmentShareLead,
    fetchLeadAttachments,
    fetchAllComments,
    addComment,
    deleteLeadAttachment,
  } from '../.../../../actions/leads'
  import _ from 'lodash'
  import Swal from 'sweetalert2'
class UploadImages extends Component {
    constructor(props) {
        super(props)
        this.state = {
            closeLostToggleState: false,
            reason: '',
            loader: false,
            itemIdLocal: '',
            edit: false,
            callToggleState: false,
            investmentCallLoader: false,
            addMeetingLoader: false,
            selectedFiles: [],
            showOverlay: false,
            validFiles: [],
            uploadPercentage: 0,
            progressInfos: [],
            showProgressBar: false,
            disabledButton: false,
            attachmentToggleState: false,
            customersPhoneNumbers: null,
            customerPhoneLoader: true,
            callLogs: [],
            followupModalFlag: false,
            meetingFeedback: false,
            showFeedback: false,
            singleLeadIdState: null,
            meetingToggleState: false,
            callRecord: '',
            activeTab: null,
            shareToggleState: false,
            agents: [],
            shareloader: false,
            commentToggleState: false,
            shareLeadType: '',
            callingFrom: '',
            trFocus: null,
            taskModalAction: 'list',
            followUpModalAction: 'list',
            showCloseIcon: false,
            attachmentLoader: false,
            closeAfterAdd: false,
            closeWonToggleState: false,
            selectedLeads: [],
            selectedItemLeads: [],
            filterSelected: [],
            totalImgesLength : 10,
            CheckTotalImages : 0
        }
    }

    attachmentToggle = (id) => {
        this.props.fetchLeadAttachmentsFunc(id)
        this.setState({ attachmentToggleState: !this.state.attachmentToggleState })
      }
      updateHover = (value) => {
        this.setState({ showCloseIcon: value })
      }

      showAlertToggle = () => {
        this.setState({
          showAlertToggleState: true,
        })
        setTimeout(() => {
          this.setState({
            showAlertToggleState: false,
          })
        }, 3000)
      }
    

      totalPendingImgesLength = (currentUploadedImages)=>
      {
        return  (20 - currentUploadedImages)

      }
      deleteAttachment = (id) => {
        const { fetchLeadAttachmentsFunc, itemId } = this.props
        let promises = []
        promises.push(this.props.deleteLeadAttachmentFun(id))
    
        Promise.all(promises).then((res) => {
          setTimeout(() => {
            fetchLeadAttachmentsFunc(itemId)
            fetchLeadTimeLine(itemId)
          }, 2000)
        })
      }

      addDocuments = (selectedFiles, id = this.props.itemId) => {
        this.setState({
          showProgressBar: true,
          disabledButton: true,
        })
    
        let promises = []
        for (let i = 0; i < selectedFiles.length; i++) {
          let bodyFormData = new FormData()
          bodyFormData.append('file', selectedFiles[i])
    
          const options = {
            onUploadProgress: (progressEvent) => {
              const { loaded, total } = progressEvent
              let percent = Math.floor((loaded * 100) / total)
    
              selectedFiles[i].percentage = percent
    
              this.setState({
                progressInfos: selectedFiles,
                attachmentLoader: true,
              })
            },
          }
    
          promises.push(
            axios.post(`${config.apiPath}/api/leads/attachment?cmLeadId=${id}`, bodyFormData, options)
          )
        }
    
        Promise.all(promises).then((res) => {
          this.setState({
            showProgressBar: false,
            disabledButton: false,
            selectedFiles: [],
            attachmentLoader: false,
          })
    
          this.props.fetchLeadAttachmentsFunc(this.props.itemId)
        })
      }

        dragOver = (e) => {
            e.preventDefault()
          }

          dragEnter = (e) => {
            e.preventDefault()
            this.setState({
              showOverlay: true,
            })
          }
        
          dragLeave = (e) => {
            e.preventDefault()
          }   

          uniqueFiles = (data) => {
            let unique = _.uniqBy(data, 'name')
            return unique
          }

          handleFiles = (files) => {
            for (let i = 0; i < files.length; i++) {
              if (this.validateFile(files[i])) {
                this.setState((prevState) => {
                  return {
                    ...prevState,
                    selectedFiles: this.uniqueFiles([...prevState.selectedFiles, files[i]]),
                    showOverlay: false,
                  }
                })
              }
            }
          }

          fileDrop = (e) => {
            e.preventDefault()
            const files = e.dataTransfer.files
            
            if (files.length > 10) {
              Swal.fire('Warning !', 'Maximum files upload limit is 10', 'warning')
              return false
            }
            if (files.length) {
              this.handleFiles(files)
            }
          }

          removeSpecificData = (value) => {
         
            const {CheckTotalImages}= this.state

            const { selectedFiles } = this.state
            let index = _.findIndex(selectedFiles, { name: value })
            selectedFiles.splice(index, 1)
            this.setState({
              selectedFiles,
              CheckTotalImages: CheckTotalImages-1
            })
          }

          validateFile = (file) => {
            const validTypes = [
              'image/jpeg',
              'image/jpg',
              'image/png',
              'image/gif',
              'image/x-icon',
              'image/bmp',
              'application/pdf',
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              'text/csv',
            ]
            if (validTypes.indexOf(file.type) === -1) {
              Swal.fire('Warning !', 'The file format is not supported', 'warning')
              this.setState({
                showOverlay: false,
              })
              return false
            }
            return true
          }


          addManualAttachments = (event) => {
            let totalUploadImg = 0
            const files = Array.from(event.target.files)    
             totalUploadImg = this.state.selectedFiles.length + files.length +this.props.imagesLength.length
             this.setState({CheckTotalImages:totalUploadImg})
            if (files.length > 20) {
              Swal.fire('Warning !', 'Maximum files upload limit is 20', 'warning')
              return false
            }
        
            if (totalUploadImg < 21)
            {
            files.forEach((file) => {
              if (this.validateFile(file)) {
                file.percentage = 0
                this.setState((prevState) => {
                  return {
                    ...prevState,
                    selectedFiles: this.uniqueFiles([...prevState.selectedFiles, file]),
                  }
                })
              }
            })}
            else{
              Swal.fire('Warning !', 'Maximum files upload Images limit is 20', 'warning')
              return false
            }
          }

         uploadImagesClearFromState =()=>
         {
           this.setState({selectedFiles:[]})
         } 

           

    render() {
        const {
            checkForRead,
            leadAttachmentLoader,
            leadAttachments,
            imagesLength,
            onImageRemove
          } = this.props

          const {      
            showOverlay,
            selectedFiles,
            showProgressBar,
            progressInfos,
            disabledButton,
            attachmentToggleState,     
            showCloseIcon,
            attachmentLoader,
            totalImgesLength,
            CheckTotalImages
          } = this.state

 let unique = _.uniqBy(selectedFiles, 'name')

        return (
            <div>
                        <button
        className="success my-3"
        // disabled={!checkForRead.showReadOnly ? false : true}
        onClick={(e) => {
             if (imagesLength.length < 21) {
              e.stopPropagation()
            this.attachmentToggle()
          
              }
              else{
                alert("cant upload more than 10 pic")
              }
        }}
      >

   {imagesLength && imagesLength.length > 0 ?   "Upload More Images" : "Add Images"}
      </button>
      <div className='row'>
      {/* <span className='mr-2 ml-3'>Maximum Images Can Upload : 20</span>  */}
      {/* <span style={{marginLeft:"2.2rem"}}>Total Uploaded Images : {imagesLength.length}</span>  */}
      {/* <span className='mb-2 ml-3'> {`Pending Images : ${this.totalPendingImgesLength(imagesLength.length)}`}</span> */}
      {/* <span className='mr-2 ml-3 mb-2'> {imagesLength.length < 20 ? "Max Upload Images Limit Is 20" : "Max Upload Images Limit Reached" }  </span>  */}
      </div>
     <AtttachmentModalEditProperty 
          attachmentToggleState={attachmentToggleState}
          attachmentToggle={this.attachmentToggle}
          leadAttachmentLoader={false}
          // check this 
          leadAttachments={leadAttachments}

          updateHover={this.updateHover}
          showCloseIcon={showCloseIcon}
          deleteAttachment={this.deleteAttachment}
          addDocuments={this.addDocuments}
          dragOver={this.dragOver}
          dragEnter={this.dragEnter}
          dragLeave={this.dragLeave}
          fileDrop={this.fileDrop}
          showOverlay={showOverlay}
          checkForRead={checkForRead}
          showProgressBar={showProgressBar}
          progressInfos={progressInfos}
          unique={unique}
          removeSpecificData={this.removeSpecificData}
          disabledButton={disabledButton}
          addManualAttachments={this.addManualAttachments}
          attachmentLoader={attachmentLoader}
          showAlertToggle={this.showAlertToggle}
          onImageUpload={this.props.onImageUpload}
          uploadImagesClearFromState={this.uploadImagesClearFromState}
          onChange={this.props.onChange}
          watermarkHandler={this.props.watermarkHandler}
          imagesLength={this.props.imagesLength}
          onImageRemove={onImageRemove}
          CheckTotalImages={CheckTotalImages}

        /> 
      
            </div>
        )
    }
}

export const mapDispatchToProps = (dispatch) => {
    return {
 
      fetchLeadAttachmentsFunc: (id) => dispatch(fetchLeadAttachments(id)),
    }
  }
  
  export const mapStateToProps = (state) => {
    return {
       leadAttachmentLoader: state.Leads.leadAttachmentLoader,
       leadAttachments: state.Leads.leadAttachments,
    }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UploadImages))