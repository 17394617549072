/** @format */

import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom'
import '../../views/Dashboard/style.css'
import './style.scss'
import axios from 'axios'
import { menus } from './menuRole'
import _ from 'lodash'
import { UnlockFeature } from '../_unlockComponent'
import { connect } from 'react-redux'
import config from '../../config'
import { fetchProjects } from '../../actions/projects'
import { backUrl } from '../../actions/generalSetting'
import {
  showArrowEnter,
  showArrowLeave,
  unitTrueFlag,
  unitFalseFlag,
  unitFloorDetect,
} from '../../actions/navToggle'
import { ProjectDealUrl } from '../../utils/constant_data'
import { setProjectLeadFilters } from '../../actions/projectLeads'
import { fetchFiltersData } from '../../actions/leads'
import { generalQuickFitler, setQuickFilter, setMoreFilter } from '../../actions/generalSetting'
import { isREA } from '../../utils/helper'
class SecondaryNav extends Component {
  constructor(props) {
    super(props)
    this.state = {
      addcustomer: '',
      permissions: '',
      addprop: '',
      properties: '',
      deactivatedproperties: '',
      allcustomers: '',
      leadSource: '',
      agentsManagement: '',
      campaignManagement: '',
      deactivatedcustomers: '',
      deactivatedUser: '',
      teams: '',
      deactivatedTeam: '',
      deactivatedProjects: '',
      projectmain: '',
      project: '',
      projectfloor: '',
      projectshops: '',
      bookshop: '',
      users: '',
      roles: '',
      zones: '',
      wanted: '',
      floor: '',
      legal: '',
      Organizations: '',
      deletedUnits: '',
      deletedFloors: '',
      investmentLead: '',
      sales: '',
      collapse: '',
      showExpended: false,
      projectLeads: '',
      buyRentLeads: '',
      buyrentsales: '',
      unitData: '',
      propsure: '',
      armsView: '',
      fieldApp: '',
      graanaView: '',
      myTeam: '',
      investmentProducts: '',
      airaManagement: '',
      areasGroup: '',
      cmlead: '',
      mouseEnter: false,
    }
    this.baseState = this.state
  }
  RouteTo = (route) => {
    let value = route.split('/').join('')
    let newState = { ...this.baseState, activeTab: value }
    this.setState({ ...newState })
  }
  componentDidMount() {
    let value = this.props.location.pathname.split('/').join('')

    let currentUrl = window.location.href.replace(/^.*\/\/[^\/]+/, '')
    let newState = { ...this.baseState, activeTab: value }
    this.setState({
      ...newState,
    })

    const role = localStorage.getItem('role')

    if (role === 'imarat_system_user') {
      this.props.fetchProjects(1, `${config.apiPath}/api/project/all`)
    }

    menus.map((item) => {
      item.children.map((navItem) => {
        if (navItem.url === currentUrl) {
          this.setState({
            collapse: item.id,
          })
        }
      })
    })

    var user = localStorage.getItem('user_agency_mgmt')
    var token = user ? JSON.parse(user).data.token : null
    axios.defaults.headers.common['Authorization'] = token
  }
  changeUnitStatus = (name) => {
    if (name === 'Units') {
      this.props.fetchProjects(1, `${config.apiPath}/api/project/all?`, 'not')
      setTimeout(() => {
        this.setState({
          projectmain: '',
        })
      }, 1200)
    }

    setTimeout(() => {
      return this.setState({
        unitData: name,
      })
    }, 1000)
  }

  toggle = (id, parentId = null) => {
    if (!this.state.collapse || id !== this.state.collapse) {
      this.setState({
        collapse: id,
      })
    } else if(parentId )
      this.setState({
        collapse: parentId,
      })
  }
  mouseEnterHandler = (id) => {
    if (!this.state.collapse || id !== this.state.collapse) {
      this.setState({
        collapse: id,
      })
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.location.pathname !== this.props.location.pathname) {
      let value = nextProps.location.pathname.split('/').join('')
      let newState = { ...this.baseState, activeTab: value }
      this.setState({ ...newState, collapse: this.state.collapse })
    }
  }
  elMouseLeave = () => {
    const { mouseEnterFlag } = this.props
    if (!mouseEnterFlag) {
      this.setState({ collapse: '' })
    }
  }
  checkToShow = (item) => {
    return (
      (!item.hideForTerminal || this.props.isTerminalUser === false) &&
      (!item.REAOnly || (this.props.user && isREA(this.props.user)))
    )
  }


  matchWithAnyChildren = (item, collapse, match) => {

    if(item.children) {
      for(let i = 0; i <= item.children.length; i += 1) {
        if(item.children[i].id === collapse || item.children.find(child => child.id === collapse)) {
          match = true
          break
        }
        else
          return this.matchWithAnyChildren(item.children[i], collapse, match)
      }
    }
    return match
  }

  isMultipleChilds = (item) => {
    return item.children && item.children.length > 1
  }

  renderParentMenus = (item, index, parentId) => {
    const { collapse } = this.state
    return <div className={
        this.matchWithAnyChildren(item, collapse, false) ||
      collapse === item.id

          ? 'innerBg innerChild-div' : 'innerChild-div'
      }
      key={index}
    >
      <p
        onClick={() => {
          this.RouteTo(`/${item.url}`)
          this.toggle(item.id, parentId)
          setTimeout(() => {
            if (
              _.filter(item.children, function (__nested) {
                return __nested.isPermit
              }).length === 1
            ) {
              this.props.history.push(
                _.filter(item.children, function (__nested) {
                  return __nested.isPermit
                })[0].url
              )
            }
          }, 200)
        }}
        className={`element ${this.state[item.activeTab]}`}
      >
        {!parentId && <span className="iconSmall">
                              <img
                                src={item.iconsSmall}
                                alt="NA"
                                style={{ marginLeft: item.name === 'TEAMS' ? -2 : 0 }}
                                className="icon-size"
                              />
                            </span> }
        <p className={this.state[item.activeTab]}>{item.name}</p>
        {this.isMultipleChilds(item) && <span
          className={
            this.matchWithAnyChildren(item, collapse, false) || collapse === item.id ? 'fa fa-chevron-down' : 'fa fa-chevron-right'
          }
          key={index}
        ></span>}
      </p>
      {this.isMultipleChilds(item) ? this.renderChildMenus(item, index) : ''}
    </div>
  }

  renderChildMenus = (itm, index) => {
    const { collapse, showExpended, unitData } = this.state
    return this.matchWithAnyChildren(itm, collapse, false) || collapse === itm.id
      ? (
      <div className="prop-child">
        <ul>
          {itm.children.map((item, index) =>
            this.checkToShow(item) ? (
              item.children ? this.renderParentMenus(item, index, itm.id) :
              <>
                {item.isShow ? (
                  <li
                    key={index}
                    className={
                      (item.expanded && unitData === 'Units') ||
                      (this.props.history.location.pathname ===
                        '/projectmain' &&
                        item.expanded &&
                        unitData === 'Units')
                        ? 'active-class'
                        : this.state.activeTab &&
                        item.activeTab === this.state.activeTab
                        ? 'active-class'
                        : ''
                    }
                    onClick={() => {
                      /*this.props.fetchFiltersData(
                        ProjectDealUrl,
                        null,
                        null,
                        { status: '' }
                      )*/
                      this.props.unitTrueFlag()
                      this.props.unitFalseFlag()
                      this.props.unitFloorDetect(true)
                      this.setState(
                        {
                          collapse: item.itemId,
                          showExpended: !showExpended,
                        },
                        () => this.toggle(item.itemId)
                      )
                      return this.changeUnitStatus(item.name)
                    }}
                  >
                    <Link
                      onClick={() => {
                        this.RouteTo(`/${item.url}`)
                      }}
                      to={item.url}
                      className={
                        this.state.activeTab &&
                        item.activeTab === this.state.activeTab
                          ? 'active-class'
                          : ''
                      }
                    >
                      {item.name}
                    </Link>
                  </li>
                ) : (
                  <UnlockFeature
                    feature={item.feature}
                    action={item.action}
                    //currentUser={userType || null}
                    //roles={item.roles}
                    key={index}
                  >
                    <li
                      key={index}
                      className={
                        (item.expanded && unitData === 'Units') ||
                        (this.props.history.location.pathname ===
                          '/projectmain' &&
                          item.expanded &&
                          unitData === 'Units')
                          ? 'active-class'
                          : this.state.activeTab &&
                          item.activeTab === this.state.activeTab
                          ? 'active-class'
                          : ''
                      }
                      onClick={() => {
                        this.props.unitTrueFlag()
                        this.props.unitFalseFlag()
                        this.props.unitFloorDetect(true)
                        this.setState(
                          {
                            collapse: item.itemId,
                            showExpended: !showExpended,
                          },
                          () => this.toggle(item.itemId)
                        )
                        return this.changeUnitStatus(item.name)
                      }}
                    >
                      <Link
                        onClick={() => {
                          this.RouteTo(`/${item.url}`)
                          backUrl(item.url)
                          if (!item.resetQuickFilter) {
                            return
                          } else {
                            this.props.setQuickFilter([])
                            setMoreFilter({
                              filterCount: 0,
                              activeFilter: false,
                            })
                            this.props.setProjectLeadFilter({}, () => null)
                          }
                        }}
                        to={item.url}
                        className={
                          this.state.activeTab &&
                          item.activeTab === this.state.activeTab
                            ? 'active-class'
                            : ''
                        }
                      >
                        {item.name}
                      </Link>
                    </li>
                  </UnlockFeature>
                )}
              </>
            ) : (
              <></>
            )
          )}
        </ul>
      </div>
    ) : null
  }

  render() {
    const { collapse, showExpended, unitData } = this.state
    const { backUrl, setMoreFilter } = this.props

    return (
      <div>

        {menus[0].isShow ? (
          <div className="row">
            <div class="dropdown dropdown-create dropright col-md-12" style={{ marginTop: 28 }}>
              <a
                class="btn btn-primary create-button col-md-12"
                data-toggle="dropdown"
                role="button"
                aria-haspopup="true"
                aria-expanded="true"
              >
                <span className="iconSmall">
                  <img
                    src={menus[0].iconsSmall}
                    alt="NA"
                    style={{ marginRight: '10px', marginTop: '-3px' }}
                    className="icon-size"
                  />
                </span>

                {menus[0].name}
              </a>

              <ul class="dropdown-menu hero" aria-labelledby="">
                {menus[0].children.map((item, index) => (
                  <>
                    {item.isShow ? (
                      <li
                        key={index}
                        className={
                          (item.expanded && unitData === 'Units') ||
                          (this.props.history.location.pathname === '/projectmain' &&
                            item.expanded &&
                            unitData === 'Units')
                            ? 'class="dropdown-item"'
                            : this.state.activeTab && item.activeTab === this.state.activeTab
                            ? 'class="dropdown-item"'
                            : 'class="dropdown-item"'
                        }
                        onClick={() => {
                          this.props.unitTrueFlag()
                          this.props.unitFalseFlag()
                          this.props.unitFloorDetect(true)
                          this.setState(
                            {
                              collapse: item.itemId,
                              showExpended: !showExpended,
                            },
                            () => this.toggle(item.itemId)
                          )
                          return this.changeUnitStatus(item.name)
                        }}
                      >
                        <Link
                          onClick={() => this.RouteTo(`/${item.url}`)}
                          to={item.url}
                          className={
                            this.state.activeTab && item.activeTab === this.state.activeTab
                              ? ''
                              : ''
                          }
                        >
                          {item.name}
                        </Link>
                      </li>
                    ) : (
                      <UnlockFeature
                        feature={item.feature}
                        action={item.action}
                        //currentUser={userType || null}
                        //roles={item.roles}
                        key={index}
                      >
                        <li
                          key={index}
                          className={
                            (item.expanded && unitData === 'Units') ||
                            (this.props.history.location.pathname === '/projectmain' &&
                              item.expanded &&
                              unitData === 'Units')
                              ? 'class="dropdown-item"'
                              : this.state.activeTab && item.activeTab === this.state.activeTab
                              ? 'class="dropdown-item"'
                              : 'class="dropdown-item"'
                          }
                          onClick={() => {
                            this.props.unitTrueFlag()
                            this.props.unitFalseFlag()
                            this.props.unitFloorDetect(true)
                            this.setState(
                              {
                                collapse: item.itemId,
                                showExpended: !showExpended,
                              },
                              () => this.toggle(item.itemId)
                            )
                            return this.changeUnitStatus(item.name)
                          }}
                        >
                          <Link
                            onClick={() => {
                              this.RouteTo(`/${item.url}`)
                            }}
                            to={item.url}
                            className={
                              this.state.activeTab && item.activeTab === this.state.activeTab
                                ? ''
                                : ''
                            }
                          >
                            {item.name}
                          </Link>
                        </li>
                      </UnlockFeature>
                    )}
                  </>
                ))}
              </ul>
              {/* <ul class="dropdown-menu hero" aria-labelledby="dropdownMenuButton">
                          <li class="dropdown-item">
                            <a href="#">Register Client</a>
                          </li>
                        </ul> */}
              <hr
                style={{
                  border: '.3px solid #363535',
                  marginTop: '25px',
                  marginBottom: '-1px',
                  marginLeft: '10px',
                }}
              />
            </div>
          </div>
        ) : (
          ''
        )}

        <div style={{ marginRight: -14, marginTop: '-40px' }}>
          <div className="left-side-container">
            <div className="dash-child mb-3">
              {(() => {
                return (
                  menus.map(
                    (item, index) =>
                      item.isShow &&
                      (item.id !== 0 ? (
                        this.renderParentMenus(item, index)
                      ) : null)
                    // </UnlockFeature>
                  ) || <></>
                )
              })()}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export const mapDispatchToProps = (dispatch) => {
  return {
    fetchProjects: (page, url, flag) => dispatch(fetchProjects(page, url, flag)),
    showArrowEnter: () => dispatch(showArrowEnter()),
    showArrowLeave: () => dispatch(showArrowLeave()),
    unitTrueFlag: () => dispatch(unitTrueFlag()),
    unitFloorDetect: (data) => dispatch(unitFloorDetect(data)),
    unitFalseFlag: () => dispatch(unitFalseFlag()),
    generalQuickFitler: (data) => dispatch(generalQuickFitler(data)),
    backUrl: (data) => dispatch(backUrl(data)),
    setQuickFilter: (quickFilter) => dispatch(setQuickFilter(quickFilter)),
    setMoreFilter: (status) => dispatch(setMoreFilter(status)),
    setProjectLeadFilter: (filters, cb) => dispatch(setProjectLeadFilters(filters, cb)),
  }
}

const mapStateToProps = (state) => {
  return {
    loader: state.Projects.loader,
    mouseEnterFlag: state.navToggle.mouseEnterFlag,
    isTerminalUser: state.user.isTerminalUser,
    user: state.user.userInfo,
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SecondaryNav))
