/** @format */

import * as ActionTypes from '../actions/generalSetting'

export const defaultMoreFilterValue = {
  showModal: false,
  activeFilter: false,
  filterCount: 0,
}
export const defaultDataSort = null

const initialState = {
  moreFilter: defaultMoreFilterValue,
  dataSort: defaultDataSort,
}

const GeneralSetting = (state = initialState, action) => {
  let pathName = window.location.pathname.replace(/\//g, '')
  let formattedName = `GENERAL_QUICK_FILTER_${pathName.toUpperCase()}`
  let retainWithoutFilter = `RETAIN_WITHOUT_FILTER_${pathName.toUpperCase()}`
  switch (action.type) {
    case ActionTypes.SETTING_TITLE:
      return {
        ...state,
        title: action.data,
      }

    case ActionTypes.MORE_FILTER:
      return {
        ...state,
        moreFilter: action.data,
      }

    case ActionTypes.DATA_SORT:
      return {
        ...state,
        dataSort: action.data,
      }

    case ActionTypes.QUICK_FILTER:
      return {
        ...state,
        quickFilter: action.data,
      }

    case formattedName:
      return {
        ...state,
        ['quickFilter' + pathName]: action.data,
      }

    case retainWithoutFilter:
      return {
        ...state,
        ['retainWithoutFilter' + pathName]: action.data,
      }
    case ActionTypes.BACK_URL:
      return {
        ...state,
        backUrl: action.data,
      }

    default:
      return state
  }
}

export default GeneralSetting
