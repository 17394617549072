/** @format */
import moment, { isBetween, isSameOrAfter } from 'moment'

const getShiftByType = (shifts, type) => {
  return shifts.find((shift) => {
    return shift.armsShift && shift.armsShift.name === type
  })
}
const getHour = (date) => {
  if (date) {
    let hour = date
    hour = hour.split(':')
    hour = hour.length > 0 ? parseInt(hour[0]) : null
    return hour
  }
  return null
}

export const scrollIntoShiftView = (allShifts, selectedDate) => {
  let currentDayShift = getCurrentShift(allShifts, selectedDate)
  if (currentDayShift) {
    let shift = null
    shift = getShiftByType(currentDayShift, 'Morning')
    if (!shift) shift = getShiftByType(currentDayShift, 'Evening')
    if (!shift) shift = getShiftByType(currentDayShift, 'Night')

    let slotCount = currentDayShift.reduce((slotCount, shift) => {
      let start = getHour(shift.armsShift && shift.armsShift.startTime)
      let end = getHour(shift.armsShift && shift.armsShift.endTime)
      if (start && end && start > end) {
        end = 23 + end
      }
      return slotCount + (end - start)
    }, 0)

    if (shift) {
      return nSlotsMove(shift, slotCount)
    }
  }
}
export const scrollIntoCurrentTimeView = () => {
  let start = moment().format('HH')
  if (start > 6) {
    start = start - 5
  } else {
    start = 0
  }
  return start
}
const nSlotsMove = (shift, slotCount) => {
  let start = shift.armsShift && shift.armsShift.startTime
  start = start && parseInt(start.split(':')[0])
  if (slotCount <= 13) return start + Math.floor((slotCount - 13) / 2)
  else return start
}
export const getCurrentShift = (allShifts, selectedDate) => {
  let currentWeekDay = moment(selectedDate).format('dddd')
  let currentDayShift = null
  if (allShifts && currentWeekDay) {
    currentDayShift = allShifts.filter((shift) => {
      return shift.dayName.toLocaleLowerCase() === currentWeekDay.toLocaleLowerCase()
    })
  }
  return currentDayShift
}
export const isCellinShift = (currentDayShift, colIndex) => {
  let isInShift = false
  let c = currentDayShift.some((shift) => {
    if (!isInShift) {
      let start = getHour(shift.armsShift && shift.armsShift.startTime)
      let end = getHour(shift.armsShift && shift.armsShift.endTime)
      if (start && end && start > end) {
        end = 24 + end
      }
      isInShift = colIndex >= start && colIndex < end
    }
  })

  return isInShift
}

export const getHoursGridExtension = (allShifts, selectedDate) => {
  let currentShift = getCurrentShift(allShifts, selectedDate)
  let ext = currentShift.reduce((currentCount, shift) => {
    let start = getHour(shift.armsShift && shift.armsShift.startTime)
    let end = getHour(shift.armsShift && shift.armsShift.endTime)
    if (start && end && start > end) return currentCount + end
    else return currentCount
  }, 0)
  return ext
}
export const pad = (d, defaultReturn = '00') => {
  if (d === null || d === undefined) {
    return defaultReturn
  } else {
    return d < 10 ? '0' + d.toString() : d.toString()
  }
}

export const getTimeUsingCoords = (coords, end = false) => {
  //get time based on index
  //coords format should be => row,col
  let thisCoords = coords
  thisCoords = thisCoords.split(',')
  let rowIndex = parseInt(thisCoords[0])
  let colIndex = parseInt(thisCoords[1])

  let minutes = rowIndex * 5 + (end ? 5 : 0)
  if (end && rowIndex === 11) {
    rowIndex = 0
    colIndex++
    minutes = rowIndex * 5
  }
  let hour = colIndex % 12
  hour = hour == 0 ? 12 : hour
  return hour + ':' + pad(minutes) + (Math.floor(colIndex / 12) % 2 === 0 ? 'AM' : 'PM')
}

const nSlotsInTimeSpan = (startTime, endTime) => {
  //return total slot that can occur in time span
  let minutes = moment(endTime, 'YYYY-MM-DD HH:mm:ss').diff(
    moment(startTime, 'YYYY-MM-DD HH:mm:ss'),
    'minutes'
  )
  return minutes / 5
}
const getShiftByCoords = (coord, allShifts) => {
  let coordTime = getTimeUsingCoords(coord)
  for (let i = 0; i < allShifts.length; i++) {
    let thisTime = moment(coordTime, 'hh:mm A').format('HH:mm')
    let startTime =
      allShifts[i].armsShift && moment(allShifts[i].armsShift.startTime, 'HH:mm').format('HH:mm')

    let endTime =
      allShifts[i].armsShift && moment(allShifts[i].armsShift.endTime, 'HH:mm').format('HH:mm')

    let startHour = startTime && parseInt(startTime.split(':')[0])
    let endHour = endTime && parseInt(endTime.split(':')[0])
    if (startHour && endHour && startHour > endHour) {
      thisTime = moment(moment().format('YYYY-MM-DD') + ' ' + thisTime)
      startTime = moment(moment().format('YYYY-MM-DD') + ' ' + startTime)
      endTime = moment(moment().add(1, 'days').format('YYYY-MM-DD') + ' ' + endTime)
      if (
        moment(thisTime, 'YYYY-MM-DD HH:mm').isBetween(
          moment(startTime, 'YYYY-MM-DD HH:mm'),
          moment(endTime, 'YYYY-MM-DD HH:mm')
        )
      ) {
        return allShifts[i]
      }
    }

    if (
      moment(thisTime, 'HH:mm').isBetween(
        moment(startTime, 'HH:mm'),
        moment(endTime, 'HH:mm'),
        'minutes'
      )
    ) {
      return allShifts[i]
    }
  }
}
const getDiariesInTimeSpan = (startTime, endTime, diaries, slotTemplate) => {
  let slotInSpan = slotTemplate.filter((t) => {
    if (
      moment(t.startTime, 'HH:mm:ss').isSameOrAfter(moment(startTime, 'YYYY-MM-DD HH:mm:ss')) &&
      moment(t.startTime, 'HH:mm:ss').isBefore(moment(endTime, 'YYYY-MM-DD HH:mm:ss'))
    ) {
      return t
    }
  })
  let diariesInSpan = diaries.filter((d) => {
    if (slotInSpan.findIndex((s) => s.id === d.slotId) != -1) {
      return d
    }
  })
  return diariesInSpan
}
const nSlotsInSelected = (
  selectedSlots,
  currentTime,
  startTime,
  endTime,
  occupiedSlots,
  slotTemplate
) => {
  // startTime = moment(startTime, 'HH:mm:ss') //current time
  // endTime = moment(endTime, 'HH:mm:ss') //shift end time
  let selectedStartTime = moment(
    moment().format('YYYY-MM-DD') + ' ' + getTimeUsingCoords(selectedSlots[0]),
    'YYYY-MM-DD hh:mm A'
  )
  //todo if end > 23 add 1 days in date
  let selectedEndTime = moment(
    moment().format('YYYY-MM-DD') +
      ' ' +
      getTimeUsingCoords(selectedSlots[selectedSlots.length - 1], true),
    'YYYY-MM-DD hh:mm A'
  )
  //selected end is before current time return 0
  if (selectedEndTime.isBefore(currentTime)) {
    return 0
  }
  //selected start is after shift start time and selected end is less than shift end time return selectedslots length
  // if (selectedStartTime.isAfter(startTime) && selectedEndTime.isBefore(endTime)) {
  //   //minus booked slots from length
  //   let occSlots = getDiariesInTimeSpan(
  //     moment(selectedStartTime).format('YYYY-MM-DD HH:mm:ss'),
  //     moment(selectedEndTime).format('YYYY-MM-DD HH:mm:ss'),
  //     occupiedSlots,
  //     slotTemplate
  //   )
  //   let length = selectedSlots.length - (occSlots ? occSlots.length : 0)
  //   return length
  // }

  //if selected start is before current time donot count selection that is before current time
  //if selected end is after shift end than dont count selection that is after shift end
  if (selectedEndTime.isSameOrAfter(currentTime)) {
    let slots = selectedSlots
    if (selectedStartTime.isBefore(currentTime)) {
      let hour = parseInt(moment(currentTime).format('HH:mm').split(':')[0])
      let minutes = parseInt(moment(currentTime).format('HH:mm').split(':')[1])
      let startIndex = slots.indexOf(minutes / 5 + ',' + hour)
      if (startIndex != -1) slots = slots.slice(startIndex)
    }
    if (selectedEndTime.isAfter(endTime)) {
      //if select end time is after shift end
      let endHour = parseInt(moment(endTime).format('HH:mm').split(':')[0])
      let endMinutes = parseInt(moment(endTime).format('HH:mm').split(':')[1])
      let endIndex = slots.indexOf(endMinutes / 5 + ',' + endHour)
      if (endIndex != -1) slots = slots.slice(0, endIndex)
    }

    //minus booked slots from length
    let occSlots = getDiariesInTimeSpan(
      moment(selectedStartTime).format('YYYY-MM-DD HH:mm:ss'),
      moment(selectedEndTime).format('YYYY-MM-DD HH:mm:ss'),
      occupiedSlots,
      slotTemplate
    )

    let length = slots.length - (occSlots ? occSlots.length : 0)
    return length
  }
}

export const hasTimeCredit = (
  selectedSlots,
  occupiedSlots,
  allShifts,
  selectedDate,
  slotTemplate
) => {
  //if 40% of the slots are free than it will return true
  let currentDayShift = getCurrentShift(allShifts, moment(selectedDate).format())

  if (currentDayShift && selectedSlots && selectedSlots.length > 0) {
    if (currentDayShift) {
      //TODO getserver time

      let thisShift = getShiftByCoords(selectedSlots[0], currentDayShift)
      if (!thisShift)
        thisShift = getShiftByCoords(selectedSlots[selectedSlots.length - 1], currentDayShift)
      if (thisShift) {
        let currentStartTime = moment().format('HH:mm:ss')
        currentStartTime = currentStartTime.split(':')
        currentStartTime =
          currentStartTime[0] + ':' + pad(Math.round(parseInt(currentStartTime[1]) / 5) * 5) + ':00'
        let shiftEndTime = thisShift.armsShift && thisShift.armsShift.endTime
        let shiftStartTime = thisShift.armsShift && thisShift.armsShift.startTime
        //if current time is in shift
        let sTime = currentStartTime
        let shifteTime = shiftEndTime
        let shiftsTime = shiftStartTime
        if (
          thisShift.armsShift &&
          parseInt(thisShift.armsShift.startTime.split(':')[0]) >
            parseInt(thisShift.armsShift.endTime.split(':')[0])
        ) {
          //TODO add selected filtered date in shfit start and end time
          sTime = moment(moment().format('YYYY-MM-DD') + ' ' + sTime)
          shiftsTime = moment(moment().format('YYYY-MM-DD') + ' ' + shiftStartTime)
          shifteTime = moment(moment().add(1, 'days').format('YYYY-MM-DD') + ' ' + shiftEndTime)
        } else {
          {
            //TODO add selected filtered date in shfit start and end time
            sTime = moment(moment().format('YYYY-MM-DD') + ' ' + sTime)
            shiftsTime = moment(moment().format('YYYY-MM-DD') + ' ' + shiftStartTime)
            shifteTime = moment(moment().format('YYYY-MM-DD') + ' ' + shiftEndTime)
          }
        }

        // if (
        //   moment(sTime, 'YYYY-MM-DD HH:mm:ss').isSameOrAfter(
        //     moment(shiftsTime, 'YYYY-MM-DD HH:mm:ss')
        //   ) &&
        //   moment(sTime, 'YYYY-MM-DD HH:mm:ss').isSameOrBefore(
        //     moment(shifteTime, 'YYYY-MM-DD HH:mm:ss')
        //   )
        // ) {}
        //current time is in shift
        if (
          moment(sTime, 'YYYY-MM-DD HH:mm:ss').isBefore(moment(shiftsTime, 'YYYY-MM-DD HH:mm:ss'))
        ) {
          //if current time is before shift start, (shift has not started)
          sTime = shiftsTime
        } else if (
          moment(sTime, 'YYYY-MM-DD HH:mm:ss').isAfter(moment(shifteTime, 'YYYY-MM-DD HH:mm:ss'))
        ) {
          //current time is after shift end, (shift has passed)
          return true
        }
        let totalSlotInSpan = nSlotsInTimeSpan(sTime, shifteTime)

        let totalOccupiedSlots = getDiariesInTimeSpan(
          sTime,
          shifteTime,
          occupiedSlots,
          slotTemplate
        )
        let totalSlotsInSelected = nSlotsInSelected(
          selectedSlots,
          sTime, //start time
          shiftsTime, //shift start time
          shifteTime, //shift end time
          occupiedSlots,
          slotTemplate
        )

        let totalDiariesInSpan = totalOccupiedSlots.length
        let percentage40ofAvailableSlots = Math.ceil((totalSlotInSpan / 100) * 40)
        if (totalDiariesInSpan + totalSlotsInSelected > percentage40ofAvailableSlots) {
          return false
        }
        // }
      }
    }
  }

  return true
}
