/** @format */

import React, { useEffect, useState } from 'react'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'

// import { StaticData } from './../../StaticData'
import { priceRange } from '../../utils/constant_data'
import {
  renderOptions,
  customTextArea,
  priceRanger,
  paymentRanger,
} from '../../components/ReduxForm/index'

let ProjectLeadCreationInner = ({
  cities,
  allProjects,
  condition,
  addLeadLoader,
  handleSubmit,
  setShareToggleState,
  selectedClient,
  setName,
  history,
  errorMessage,
  cityId,
  lead,
  editable,
  project,
  isClientPage,
  setLeadModal
}) => {
  const [productType, setProductType] = useState([])
  const [city, setCity] = useState(null)
  const [proj, setPro] = useState(null)
  const [selectedProdType, setSelectedProdType] = useState(null)
  const [price, setPrice] = useState(null)
  const [fieldClass, setFieldClass] = useState(isClientPage || editable ? 'col-md-4' : 'col-md-3')
  const [fieldClassDescription, setfieldClassDescription] = useState(
    isClientPage ? 'col-md-8' : 'col-md-6'
  )
  const customStyles = {
    control: (base) => ({
      ...base,
      height: 35,
      minHeight: 35,
      '&:hover': {
        borderColor: '#ff2420',
        color: '#ff2420',
      },
      '&': {
        borderColor: '#ff2420',
        color: '#ff2420',
      },
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: 'inherit',
    }),
    indicatorSeparator: (base) => ({
      ...base,
      backgroundColor: '#ff2420',
    }),
    singleValue: (base) => ({
      ...base,
      color: 'inherit',
    }),
  }

  const closest = (size, op, options) => {
    if (size === '' && op === 'lg') return options['rent'].length - 1
    let closestVal = options['rent'].reduce((a, b) => {
      return Math.abs(b - size) < Math.abs(a - size) ? b : a
    })
    return options['rent'].indexOf(closestVal)
  }

  const findIndexOfMin = (minPrice, priceRange) => {
    let min = priceRange.project.indexOf(minPrice)
    return min
  }

  useEffect(() => {
    if (lead) {
      if (cities.length > 0) {
        const found = cities && cities.find((tt) => tt.id === lead.city.id)
        cityId = { label: found.name, value: found.id }
        setCity({ label: found.name, value: found.id })
        // this.setState({ cityId: found.id })
      }
      if (lead.project) {
        const found =
          allProjects &&
          allProjects.items &&
          allProjects.items.find((tt) => tt.id === lead.project.id)
        if (found) {
          setPro({ label: found.name, value: found.id })
        }
        if (lead.productType) {
          setProductType({ name: lead.productType.name, id: lead.productType.id })
        }
        if (allProjects && allProjects.items) {
          let project = allProjects.items.filter((item) => item.id === lead.project.id)
          if (project.length) setProductType(project[0].productTypes)
        }
        if (lead.productTypes) {
          const foundType =
            allProjects &&
            allProjects.items &&
            allProjects.items
              .filter((area) => lead.project.id === area.id)
              .map((item) => item.productTypes)
          const projectTypeId =
            foundType && foundType[0] && foundType[0].filter((res) => res.name === lead.projectType)

          const sendData = {
            label: projectTypeId && projectTypeId[0] && projectTypeId[0].name,
            value: projectTypeId && projectTypeId[0] && projectTypeId[0].id,
          }
          setSelectedProdType(sendData)
        }
      }
    }
  }, [lead, cities, allProjects])

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="row">
          {(!isClientPage || editable) && (
            <div className={fieldClass}>
              <div className="subtitle">
                Client <span className="text-danger ml-1">*</span>
              </div>
              <div
                className={
                  errorMessage.client && !selectedClient
                    ? 'client-wrapper-error'
                    : 'select-client-wrapper'
                }
                style={{
                  cursor: lead && lead.customer !== undefined ? 'not-allowed' : null,
                  backgroundColor: selectedClient ? '#eee' : lead && lead.customer !== undefined ? '#eaedf0' : null,
                  borderColor: lead && lead.customer !== undefined ? ' rgb(230, 230, 230)' : null,
                }}
                onClick={() => {
                  if (selectedClient) {
                    return;
                  }
                  if (editable !== true) {
                    setShareToggleState(true)
                    setName('Client')
                  }
                }}
              >
                {selectedClient === null ? (
                  'Select Client'
                ) : (
                  <span className="black-color">
                    {selectedClient.firstName
                      ? selectedClient.firstName + ' ' + selectedClient.lastName
                      : selectedClient.first_name + ' ' + selectedClient.last_name}
                  </span>
                )}
              </div>
              {errorMessage.client && !selectedClient && (
                <p className="error-show-font">Client is required</p>
              )}
            </div>
          )}
          <div className={fieldClass}>
            <div className="subtitle">
              City <span className="text-danger ml-1">*</span>
            </div>
            <Field
              component={renderOptions}
              errorShow={errorMessage.city && (!cityId || !city) ? true : false}
              errorMessageText="City is required"
              styles={errorMessage.city && (!cityId || !city) ? customStyles : ''}
              type="text"
              name="cityId"
              label="Select City"
              defaultValue={city}
              options={
                cities &&
                cities.map((item) => ({
                  label: item.name,
                  value: item.id,
                }))
              }
            />
          </div>
          <div className={fieldClass}>
            <div className="subtitle">Project</div>
            <Field
              component={renderOptions}
              type="text"
              name="project"
              label="Select Project"
              options={
                allProjects &&
                allProjects.items &&
                allProjects.items.map((item) => ({
                  label: item.name,
                  value: item.id,
                  item: item,
                }))
              }
              defaultValue={proj}
              // onChange={(value) => setProductType(value.item.productTypes)}
              onChange={(value) => setProductType(value && value.item && value.item.productTypes)}
            />
          </div>
        </div>

        <div className="row mt-4">
          <div className={fieldClass}>
            <div className="subtitle">Unit Type</div>
            {editable && selectedProdType && selectedProdType.label !== undefined ? (
              <Field
                component={renderOptions}
                type="text"
                name="projectType"
                label="Unit Type"
                defaultValue={selectedProdType}
                options={
                  productType &&
                  productType.map((item) => ({
                    label: item.name,
                    value: item.id,
                  }))
                }
              />
            ) : (
              <Field
                component={renderOptions}
                type="text"
                name="projectType"
                label="Unit Type"
                options={
                  productType &&
                  productType.map((item) => ({
                    label: item.name,
                    value: item.id,
                  }))
                }
              />
            )}
          </div>
          <div className={fieldClass}>
            <div className="subtitle">Investment Range</div>
            <Field
              component={paymentRanger}
              type="number"
              options={priceRange}
              name="ranger"
              purpose="project"
              label="Final Price"
              dataValue={
                editable
                  ? {
                    max: findIndexOfMin(lead.maxPrice, priceRange),
                    price_max: lead.maxPrice,
                    min: findIndexOfMin(lead.minPrice, priceRange),
                    price_min: lead.minPrice,
                  }
                  : null
              }
            />
          </div>
        </div>

        <div className="row mt-4">
          <div className={`${fieldClassDescription} mt-2`}>
            <Field component={customTextArea} type="text" name="description" label="Description" />
          </div>
        </div>
        {!isClientPage && (
          <div className="mt-4">
            <button
              type="button"
              className="cancel mr-2"
              style={{ marginLeft: '10px' }}
              onClick={() => {
                if (editable) {
                  setLeadModal()
                }
                else {
                  history.push('project-lead')
                }
              }}
            >
              Cancel
            </button>
            <button disabled={addLeadLoader}  type="submit" className="add-button">
              {addLeadLoader ? <span className={'fa fa-circle-o-notch fa-spin'}></span> : 'Submit'}
            </button>
          </div>
        )}
      </form>
    </>
  )
}

let selector = formValueSelector('leadCreationForms')
const mapStateToProps = (state, ownProps) => {
  const cityId = selector(state, 'cityId')
  const purpose = selector(state, 'purpose')
  if (ownProps.editable === true) {
    return {
      initialValues: {
        customerId: ownProps.lead.customer,
        cityId: { label: ownProps.lead.city.name, value: ownProps.lead.city.id },
        projectId: ownProps.lead.projectId,
        projectName: ownProps.lead.projectName,
        description: ownProps.lead.description,
        projects: ownProps.lead.projectType,
        subTypeId: ownProps.lead.productTypes && ownProps.lead.productTypes.id,
        ranger: {
          minPrice: ownProps.lead.maxPrice,
          maxPrice: ownProps.lead.minPrice,
        },
      },
    }
  } else {
    return {
      cityId,
    }
  }
}
export default withRouter(
  connect(mapStateToProps)(
    reduxForm({
      form: 'leadCreationForms',
      initialValues: {
        ranger: { min: 55000, max: 10000000 },
      },
    })(ProjectLeadCreationInner)
  )
)
