/** @format */

import React, { Component } from 'react'
import Select from 'react-select'
import { DateRangePicker, isInclusivelyBeforeDay } from 'react-dates'
import { connect } from 'react-redux'
import {
  fetchLeads,
  fetchFiltersData,
  fetchFreshLeads,
  setProjectLeadQuickFilters,
  fetchAllClients,
  investmentShareLead,
} from '../../../../../actions/leads'
import { setMoreFilter, setTitle } from '../../../../../actions/generalSetting'
import 'react-dates/initialize'
import './style.scss'
import 'react-dates/lib/css/_datepicker.css'
import moment from 'moment'
import axios from 'axios'
import config from '../../../../../config'
import Pagination from 'rc-pagination'
import 'rc-pagination/assets/index.css'
import { StaticData } from '../../../../../StaticData'
import local from '../../../../../local/en_US'
import InvestmentLeadInner from './investmentLeadInner'
import Load from '../../../../../components/Loader'
import CloseLostFeedback from '../../../../../components/Modals/FeedbackModal/ClosedLost'
import ReadOnlyAlert from '../../../../../components/ReadOnlyAlert'
import { UserInfoFetch } from '../../../../../actions/user'
import { ToastContainer } from 'react-toastify'
import DeleteInvestmentLead from '../../../../../components/Modals/DeleteInvestmentLead'
import LeadReopen from '../../../../../components/Modals/LeadReopen'
import Swal from 'sweetalert2'
import MoreFilters from './MoreFilters'
import './../../../../../components/common/LeadView/moreFilters.scss'
import {
  ProjectLeadUrl,
  managerSelectionDropdown,
  BuyRentDealUrl,
  ProjectDealUrl,
} from '../../../../../utils/constant_data'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import ConnectModal from '../../../../../components/Modals/ConnectFeedback/ConnectModal'
import DiaryTaskModal from '../../../../../components/Modals/DiaryTaskModal/DiaryTaskModal'
import DiaryModal from '../../../../../components/Modals/DiaryModal'
import Button from '../../../../../components/common/Button'
import { PermissionFeatures, PermissionActions } from '../../../../../utils/permissionConstants'
import { getPermissionValue } from '../../../../../components/_unlockComponent'
import ReferModal from '../../../../../components/Modals/ReferModal'
import ReAssignWarningModal from '../../../../../components/Modals/ReferModal/ReAssignWarningModal'
import { accountsPermissions } from '../../../../../utils/checkPermissions'
import { shouldShowAllLeads, showToastMsg } from '../../../../../utils/helper'
import Diary from '../../../../../components/common/Diary/Diary'
import ReferenceGuideModal from '../../../../../components/Modals/TaskModal/ReferenceGuideModal'
import { isReadOnly } from '../../../../../utils/lead'
import SlotModal from '../../../../../components/SlotManagement/SlotModal'
import { diaryCreateUpdateAPI, makeTaskTimePayload } from '../../../../../utils/diary'
import ConnectFlow from '../../../../../components/Modals/ConnectFeedback/ConnectFlow'
import AdditionalDetails from '../../../../../components/Modals/AdditionalDetailsModal/AdditionalDetails'
import CloseAsDuplicateModal from '../../../../../components/Modals/CloseDuplicate'
import CloseAsLostModal from '../../../../../components/Modals/CloseLost'
class InvestmentLead extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: this.props.location.pathname === ProjectLeadUrl ? 'Project Leads' : 'Project Deals',
      clientNameToggle: false,
      followupModalFlag: false,
      startDate: null,
      url: '',
      endDate: null,
      date: null,
      focusedInput: '',
      meetingFeedback: false,
      showMoreActions: false,
      countDiffer: 0,
      initialCount: 0,
      newCount: 0,
      filterId: '',
      itemId: '',
      additionalInfoToggleState: false,
      usersAll: [],
      countriesListWihCountriesCode: [],
      showRows: false,
      clientNameValue: {},
      statusValue: {},
      followUpValue: {},
      reOpenActionEnable: false,
      dateFilterToggleActive: false,
      clearButton: true,
      followUpModal: false,
      resetAll: false,
      closeLostToggleState: false,
      singleLeadId: null,
      selectedSort: { label: 'Recently Modified First', value: '&order=Desc&field=updatedAt' },
      showBackgroundIndex: false,
      singleLead: null,
      loginUserId: localStorage.getItem('user_id'),
      showReadOnlyPopup: false,
      deletionReason: '',
      deleteOpen: false,
      leadId: '',
      selectedLead: null,
      projects: [],
      selectedProjectFilter: null,
      activeFilterNameList: [],
      newActiveFilterNameList: [],
      activeName: '',
      cities: [],
      formId: [],
      selectedCity: null,
      selectedFormId: null,
      selectedAssignedLead: null,
      selectedBulk: null,
      selectedCreated: null,
      allTeams: [],
      selectedTeam: null,
      selectedfaceBookFilter: null,
      allAgents: [],
      selectedAgents: null,
      allOrg: [],
      selectedOrg: null,
      selectedOrigin: null,
      selectedSingle: null,
      selectedFollowUp: null,
      assignToMe: null,
      leadCategory: null,
      selectedAging: null,
      filterToggle: false,
      getServerTime: '',
      selectedLeads: [],
      selectedItemLeads: [],
      filterSelected: [],
      shareToggleState: false,
      modalConfirmation: false,
      processing: false,
      warningMessages: null,
      user_data: {},
      shareloader: false,
      shareLeadType: '',
      agents: [],
      agentCount: 0,
      allSelected: false,
      allSelectedElements: false,
      filterSelectedItem: false,
      showAlertToggleState: false,
      filter: false,
      taskModal: false,
      leadTaskModal: false,
      fetchLeadsInModal: false,
      diaryModal: false,
      filters: {},
      orgLoaded: false,
      bulkAction: false,
      userHierarchy: false,
      teamHierarchy: false,
      guideReferenceModal: false,
      currentPage: 1,
      emailId: '',
      phoneNo: '',
      country: '',
      updateLeadTasks: false,
      slotModal: false,
      connectFlow: false,
      newTask: null,
      closeAsDuplicateModal: false,
      closeAsLostModal: false,
      nonTerminalAgents: [],
    }

    this.projectLeadPageView = getPermissionValue(
      PermissionFeatures.PROJECT_LEADS,
      PermissionActions.READ
    )
    this.projectDealsPageView = getPermissionValue(
      PermissionFeatures.WEB_PAGES,
      PermissionActions.MY_DEALS_PROJECT
    )
    this.lead_table_ref = React.createRef()
  }

  componentDidMount() {
    const {
      name,
      filter, //Fix Nav ARMS-3065
    } = this.state
    !this.props.popup && this.props.setTitle({ name })

    document.title = this.state.name
    const { history, projectLeadQuickFilter } = this.props
    const { pathname } = this.props.location

    const urlParams = new URLSearchParams(window.location.search)
    const pageSize = urlParams.get('pageSize')
    const page = urlParams.get('page')
    this.setState({ currentPage: page ? page : 1 })
    var offset = 0
    if (isNaN((page - 1) * pageSize)) {
      offset = 0
    } else {
      offset = (page - 1) * pageSize
    }
    let query = { offset, pageSize }
    if (!(page && pageSize) && !this.props.popup) {
      history.push(`${pathname}?page=${page || '1'}&pageSize=${pageSize || config.pageSize}`)
    }
    if (this.props.customer) query.clientId = this.props.customer.id
    if (!(this.props.fromPage && this.props.fromPage === 'leadSelection')) {
      if (
        !filter || //Fix Nav ARMS-3065
        projectLeadQuickFilter === undefined ||
        Object.keys(projectLeadQuickFilter).length === 0
      ) {
        this.props.fetchLeads(
          query,

          this.props.leadOrder && this.props.leadOrder.value,
          (data) => {
            this.setState({ initialCount: data.count })
          },
          this.hasBooking(),
          Boolean(this.props.bookUnitRedirect),
          this.props.clientLeadModal,
          this.props.tabUrl,
          this.props.customer
        )
      } else {
        setTimeout(() => {
          this.fetchFiltersData(projectLeadQuickFilter)
        }, 300)
      }
    }
    if (!this.props.popup) {
      this.props.fetchAllClients(null, 'project')
      this.fetchAllProject()
      this.fetchAllCities()
      this.fetchAllFormIds()
      this.fetchAllUsers()
      this.fetchAllTeams()
      this.fetchAllAgents()
      this.fetchAllAgents(true)
      document.querySelector('span i.sbicon-times.txt-gray')
    }

    this.getCountriesNameWithCountriesCode()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    //Fix Nav ARMS-3065
    const { projectLeadQuickFilter } = prevProps
    if (
      projectLeadQuickFilter !== undefined &&
      Object.keys(projectLeadQuickFilter).length === 0 &&
      prevState.filter
    ) {
      this.setState({ filter: false })
    }
    //Fix Nav ARMS-3065
    // when order change
    if (
      this.props.leadOrder &&
      (!prevProps.leadOrder || prevProps.leadOrder.value !== this.props.leadOrder.value)
    )
      this.fetchFiltersData(this.props.projectLeadQuickFilter || {})

    // when quick filter update
    if (
      this.props.quickFilter &&
      JSON.stringify(prevProps.quickFilter) !== JSON.stringify(this.props.quickFilter)
    )
      this.fetchFiltersData(this.props.projectLeadQuickFilter || {})

    const { user } = this.props
    if (!this.props.popup && user && !prevState.orgLoaded) {
      this.fetchAllOrganizations(user)
      this.setState({ orgLoaded: true })
    }
  }

  hasBooking = () => {
    if (this.props.popup) return null
    return this.props.location.pathname === ProjectLeadUrl ? false : true
  }

  leadAssign = (id) => {
    const { selectedLeads, leadId, shareToggleState } = this.state
    const { filterResponse } = this.props

    let data = {
      userId: id,
      leadId: selectedLeads.length > 0 ? selectedLeads : [leadId],
      type: 'investment',
    }

    axios.patch(`${config.apiPath}/api/leads/assign`, data).then((res) => {
      showToastMsg(res && res.data && res.data.message, 'success')

      this.setState({
        tooltipOpen: false,
        leadAssignToggle: false,
        leadModal: false,
        shareToggleState: false,
        selectedLeads: [],
        filterSelectedItem: false,
      })

      if ((selectedLeads.length > 0 && filterResponse) || (leadId && filterResponse)) {
        this.props.fetchFiltersData()
      } else {
        this.props.fetchLeads(
          undefined,
          undefined,
          undefined,
          this.hasBooking(),
          undefined,
          this.props.clientLeadModal,
          this.props.tabUrl
        )
      }
    })
  }

  fetchAgents = (data, page, LeadType) => {
    const role_id = localStorage.getItem('roleId')
    const isGroupManager = localStorage.getItem('groupManager')

    let groupManagerParam = ''
    if (
      (isGroupManager && isGroupManager === true) ||
      isGroupManager.toLocaleLowerCase() === 'true'
    ) {
      groupManagerParam = `&groupManager=true`
    }

    this.setState({
      shareloader: true,
    })
    axios
      .get(
        LeadType === 'assign'
          ? `${
              config.apiPath
            }/api/role/sub-users?roleId=${role_id}&addManager=${true}${groupManagerParam}&page=${page}&pageSize=${config.pageSize}&web=true&leadType=Project`
          : `${
              config.apiPath
            }/api/user/agents?sharing=${true}&page=${page}&pageSize=${config.pageSize}&web=true&leadType=Project`
      )
      .then((res) => {
        this.setState({
          agents: LeadType === 'assign' ? res.data : res.data.rows,
          agentCount: LeadType !== 'assign' ? res.data.count : '',
          shareloader: false,
        })
      })
  }

  investmentLeadShareFun = (id) => {
    const { addInvestmentShareLead, fetchLeadTimeLine, fetchLeads } = this.props
    const { singleLeadIdState } = this.state
    const user_id = localStorage.getItem('user_id')
    let shareData = {
      userId: id,
      leadId: singleLeadIdState,
    }

    addInvestmentShareLead(shareData, fetchLeadTimeLine, singleLeadIdState)
    setTimeout(() => {
      showToastMsg('Lead Shared Successfully', 'success')
      fetchLeads(
        undefined,
        undefined,
        undefined,
        this.hasBooking(),
        undefined,
        this.props.clientLeadModal
      )
      this.setState({
        shareToggleState: false,
      })
    }, 800)
  }

  investmentLeadReferFun = (id, firtName, lastName) => {
    const { addInvestmentShareLead, fetchLeads, history, pathname } = this.props
    const { selectedLead } = this.state
    let shareData = {
      userId: id,
      leadId: selectedLead && selectedLead.id,
    }

    const showLeadReferSuccessMsgHandler = () => {
      setTimeout(() => {
        showToastMsg(
          `Lead has successfully been referred to <b>${firtName + ' ' + lastName} </b>`,
          'success'
        )
      }, 800)
    }

    addInvestmentShareLead(shareData, null, selectedLead.id, null, showLeadReferSuccessMsgHandler)
    setTimeout(() => {
      const { history, user } = this.props
      const { pathname } = this.props.location

      const urlParams = new URLSearchParams(window.location.search)
      const pageSize = urlParams.get('pageSize')
      const page = urlParams.get('page')
      var offset = 0
      if (isNaN((page - 1) * pageSize)) {
        offset = 0
      } else {
        offset = (page - 1) * pageSize
      }
      let query = { offset, pageSize }
      if (!(page && pageSize) && !this.props.popup) {
        history.push(`${pathname}?page=${page || '1'}&pageSize=${pageSize || config.pageSize}`)
      }

      if (this.props.customer) query.clientId = this.props.customer.id

      this.props.fetchLeads(
        query,
        this.props.leadOrder && this.props.leadOrder.value,
        (data) => {
          this.setState({ initialCount: data.count })
        },
        this.hasBooking()
      )

      this.setState({
        shareToggleState: false,
      })
    }, 0)
  }

  assignLeadFun = (id, firstName, lastName, is_confirm = false) => {
    const { singleLeadIdState, bulkAction, selectedLeads } = this.state
    let assignData = {
      leadId: bulkAction && selectedLeads.length > 0 ? selectedLeads : [singleLeadIdState],
      type: 'investment',
      userId: id,
    }
    if (is_confirm) {
      assignData.skipDiffAgentWarning = true
    }

    this.setState({
      processing: true,
      user_data: { id: id, firstName: firstName, lastName: lastName },
    })

    axios.patch(`${config.apiPath}/api/leads/assign`, assignData).then((res) => {
      if (is_confirm || (res && res.data && res.data.message)) {
        this.setState({
          shareToggleState: false,
          bulkAction: false,
          selectedLeads: [],
          user_data: {},
          modalConfirmation: false,
          processing: false,
          warningMessages: null,
        })
        Swal.fire(
          'Success',
          `Leads Re-assigned to <b>${firstName + ' ' + lastName}</b> successfully`,
          'success'
        )
        setTimeout(() => {
          this.handleQueryChange(1, this.props.pageSize)
        }, 300)
      } else {
        this.setState({
          warningMessages: res && res.data,
          processing: false,
        })
        this.toggleModalConfirmation()
      }
    })
  }
  componentWillMount() {
    this.getServerTime()
  }

  componentWillReceiveProps(nextProps) {
    const { leadsData, tabUrl, tab } = nextProps
    // if (tab === 'project') {
    //   if (leadsData && leadsData.rows && leadsData.rows.length === 1 && tabUrl === 'existinglead') {
    //     this.setState({
    //       taskModal: true,
    //       selectedLead: leadsData && leadsData.rows && leadsData.rows[0],
    //     })
    //   }
    // }
  }

  shareToggle = (shareLeadType) => {
    const { shareToggleState } = this.state
    if (shareLeadType) this.fetchAgents(null, 1, shareLeadType)
    this.setState({
      shareToggleState: !shareToggleState,
      shareLeadType: shareLeadType,
      bulkAction: true,
    })
  }

  toggleModalConfirmation = () => {
    const { modalConfirmation } = this.state
    this.setState({
      modalConfirmation: !modalConfirmation,
    })
  }

  checkReassign = (selectedLeads) => {
    let count = 0
    selectedLeads.map((item) => {
      if (item !== undefined) {
        count++
      }
    })
    return count
  }

  reloadLeadHandler = () => {
    this.setState(
      {
        countDiffer: 0,
        initialCount: this.state.newCount,
      },
      () => {
        this.handleQueryChange(1, this.props.pageSize)
      }
    )
  }

  selectLead = (id, item) => {
    let { selectedLeads, selectedItemLeads } = this.state
    const { leads } = this.props
    let leadIds = Object.assign([], selectedLeads)
    let leadItems = Object.assign([], selectedItemLeads)
    if (leadIds.indexOf(id) === -1) {
      leadIds.push(id)
      leadItems.push(item)
    } else {
      leadIds.splice(selectedLeads.indexOf(id), 1)
      leadItems.splice(selectedItemLeads.indexOf(item), 1)
    }
    this.setState({
      selectedLeads: leadIds,
      selectedItemLeads: leadItems,
      showDropShadow: true,
      filterSelected: leadIds.length < (leads && leads.rows.length) ? false : true,
    })
  }

  fetchAllUsers = () => {
    axios.get(`${config.apiPath}/api/user/all/names`).then((res) => {
      this.setState({
        usersAll: res.data,
      })
    })
  }

  fetchAllOrganizations = (info) => {
    axios.get(`${config.apiPath}/api/user/organizations?limit=2`).then((res) => {
      var object = []
      res.data.rows &&
        res.data.rows.length &&
        res.data.rows.filter((item) => {
          return object.push({ label: item.name, value: item.name })
        })
      this.setState({
        allOrg:
          info && info.organizationName != null
            ? [{ label: info.organizationName, value: info.organizationName }]
            : object,
      })
    })
  }

  fetchAllTeams = () => {
    axios.get(`${config.apiPath}/api/user/teams?all=true`).then((res) => {
      let array = []
      res.data &&
        res.data.rows.length > 0 &&
        res.data.rows.filter((item) => {
          return array.push({ label: item.teamName, value: item.id })
        })
      this.setState({ allTeams: array })
    })
  }

  fetchAllAgents = (excludeTerminalUsers = null) => {
    const role_id = localStorage.getItem('roleId')
    const isGroupManager = localStorage.getItem('groupManager')

    let groupManagerParam = ''
    if (
      (isGroupManager && isGroupManager === true) ||
      isGroupManager.toLocaleLowerCase() === 'true'
    ) {
      groupManagerParam = `&groupManager=true`
    }
    let url = `${config.apiPath}/api/role/sub-users?roleId=${role_id}${groupManagerParam}`
    if (excludeTerminalUsers) url += '&excludeTerminal=true'

    axios.get(url).then((res) => {
      let array = excludeTerminalUsers ? this.state.nonTerminalAgents : []
      res &&
        res.data &&
        res.data.length > 0 &&
        res.data.filter((item) => {
          return array.push({
            id: item.id,
            label: item.firstName + ' ' + item.lastName,
            value: item.firstName + ' ' + item.lastName,
          })
        })

      this.setState({ [excludeTerminalUsers ? 'nonTerminalAgents' : 'allAgents']: array })
    })
  }

  updateBackgroundIndex = (value) => {
    this.setState({ showBackgroundIndex: value })
  }

  textItemRender = (current, type, element) => {
    if (type === 'prev') {
      return 'Prev'
    }
    if (type === 'next') {
      return 'Next'
    }
    return element
  }

  fetchFiltersData = (projectLeadQuickFilter, page = null) => {
    const {
      statusValue,
      activeFilterNameList,
      newActiveFilterNameList,
      selectedOrigin,
    } = this.state
    const urlParams = new URLSearchParams(window.location.search)
    const pageSize = urlParams.get('pageSize')
    page = page || urlParams.get('page')
    let sortingOrder =
      this.props.leadOrder && this.props.leadOrder.value ? this.props.leadOrder.value : ''

    if (this.props.history)
      this.props.history.push(
        `${window.location.pathname}?page=${page || 1}&pageSize=${pageSize || 50}`
      )

    let showAllLeads = shouldShowAllLeads(this.props.curretUser)
    let showAllLeadsParam = ''
    if (showAllLeads) {
      showAllLeadsParam = `&showAllLeads=${showAllLeads}`
    }
    let url = `${config.apiPath}/api/v1/leads/projects?id=${
      projectLeadQuickFilter && projectLeadQuickFilter['id'] !== undefined
        ? projectLeadQuickFilter['id']
        : ''
    }&hasBooking=${this.hasBooking()}&followUp=${
      projectLeadQuickFilter && projectLeadQuickFilter['followUp'] !== undefined
        ? projectLeadQuickFilter['followUp'].value
        : ''
    }&organization=${
      projectLeadQuickFilter && projectLeadQuickFilter['organization'] !== undefined
        ? projectLeadQuickFilter['organization'].value
        : ''
    }&formId=${
      projectLeadQuickFilter && projectLeadQuickFilter['formId'] !== undefined
        ? projectLeadQuickFilter['formId'].value
        : ''
    }&assignedTo=${
      projectLeadQuickFilter && projectLeadQuickFilter['assignedTo'] !== undefined
        ? projectLeadQuickFilter['assignedTo'].value
        : ''
    }&leadsAssigned=${
      projectLeadQuickFilter && projectLeadQuickFilter['leadsAssigned'] !== undefined
        ? projectLeadQuickFilter['leadsAssigned'].value
        : ''
    }&team=${
      projectLeadQuickFilter && projectLeadQuickFilter['team'] !== undefined
        ? projectLeadQuickFilter['team'].value
        : ''
    }&bulk=${
      projectLeadQuickFilter && projectLeadQuickFilter['bulk'] !== undefined
        ? projectLeadQuickFilter['bulk'].value
        : ''
    }&createdBy=${
      projectLeadQuickFilter && projectLeadQuickFilter['createdBy'] !== undefined
        ? projectLeadQuickFilter['createdBy'].value
        : ''
    }&projectId=${
      projectLeadQuickFilter && projectLeadQuickFilter['projectId'] !== undefined
        ? projectLeadQuickFilter['projectId'].value
        : ''
    }&cityId=${
      projectLeadQuickFilter && projectLeadQuickFilter['cityId'] !== undefined
        ? projectLeadQuickFilter['cityId'].value
        : ''
    }&clientName=${
      projectLeadQuickFilter && projectLeadQuickFilter['clientName'] !== undefined
        ? projectLeadQuickFilter['clientName'].value
        : ''
    }&fromDate=${
      projectLeadQuickFilter && projectLeadQuickFilter['startDate'] !== undefined
        ? moment(projectLeadQuickFilter['startDate']).format('YYYY-MM-DD')
        : ''
    }&toDate=${
      projectLeadQuickFilter && projectLeadQuickFilter['endDate'] !== undefined
        ? moment(projectLeadQuickFilter['endDate']).format('YYYY-MM-DD')
        : ''
    }&${
      projectLeadQuickFilter && projectLeadQuickFilter['aging'] !== undefined
        ? `time[]=${
            projectLeadQuickFilter['aging'].value === 'custom'
              ? null
              : projectLeadQuickFilter['aging'].value
          }`
        : 'time='
    }&${
      projectLeadQuickFilter && projectLeadQuickFilter['faceBookFilter'] !== undefined
        ? `faceBookFilter[]=${projectLeadQuickFilter['faceBookFilter'].value}`
        : 'faceBookFilter='
    }&${
      projectLeadQuickFilter && projectLeadQuickFilter['leadCategory'] !== undefined
        ? `leadCategory[]=${projectLeadQuickFilter['leadCategory'].value}`
        : ''
    }&singleTask=${
      projectLeadQuickFilter && projectLeadQuickFilter['singleTask'] !== undefined
        ? projectLeadQuickFilter['singleTask'].value
        : ''
    }&assignToMe=${
      projectLeadQuickFilter && projectLeadQuickFilter['assignToMe'] !== undefined
        ? projectLeadQuickFilter['assignToMe'].value
        : ''
    }${showAllLeadsParam}&userHierarchy=${
      projectLeadQuickFilter && projectLeadQuickFilter['userHierarchy'] !== undefined
        ? projectLeadQuickFilter['userHierarchy'].id
        : ''
    }&teamHierarchy=${
      projectLeadQuickFilter && projectLeadQuickFilter['teamHierarchy'] !== undefined
        ? projectLeadQuickFilter['teamHierarchy'].id
        : ''
    }&countryCode=${
      projectLeadQuickFilter && projectLeadQuickFilter['country'] !== undefined
        ? projectLeadQuickFilter['country'].value
        : ''
    }&emailId=${
      projectLeadQuickFilter['emailId'] !== undefined ? projectLeadQuickFilter['emailId'] : ''
    }&phoneNo=${
      projectLeadQuickFilter['phoneNo'] !== undefined ? projectLeadQuickFilter['phoneNo'] : ''
    }${showAllLeadsParam}`

    if (selectedOrigin != null && Object.keys(selectedOrigin).length !== 0 && selectedOrigin)
      selectedOrigin.map((item) => (url += `&origin[]=${item.value}`))

    if (statusValue && statusValue.value) url += `&status=${statusValue.value}`

    // apply quick filter values
    let quickFilter = this.props.quickFilter
    if (quickFilter)
      quickFilter.map((filter) => {
        if (['assignToMe', 'singleTask'].indexOf(filter.name) > -1)
          url = `${url}&${filter.name}=${filter.value}`
        else url = `${url}&${filter.name}[]=${filter.value}`
      })

    url = `${url}${sortingOrder}`

    this.setState({
      url,
      searchCompare: true,
      filterResponse: true,
      currentPage: page,
    })
    this.props.fetchFiltersData(
      url,
      null,
      () => {
        this.setState({
          clearButton: false,
          filter: true,
          activeFilterNameList: finalNameList,
        })
      },
      projectLeadQuickFilter && projectLeadQuickFilter['status']
    )
    const finalNameList = [...new Set([...activeFilterNameList, ...newActiveFilterNameList])]

    let filterCount =
      projectLeadQuickFilter !== undefined && Object.keys(projectLeadQuickFilter).length

    if (
      (projectLeadQuickFilter && projectLeadQuickFilter['startDate']) ||
      (projectLeadQuickFilter && projectLeadQuickFilter['endDate'])
    ) {
      filterCount = filterCount - 1
    }

    this.props.setMoreFilter({
      ...this.props.moreFilter,
      showModal: false,
      filterCount,
      activeFilter:
        projectLeadQuickFilter !== undefined && Object.keys(projectLeadQuickFilter).length
          ? true
          : false,
    })
  }

  followUpModalLocal = (value) => {
    this.setState({ followUpModal: value })
  }

  additionalInfoToggle = () => {
    this.setState({ additionalInfoToggleState: !this.state.additionalInfoToggleState })
  }

  clearButton = (current, pageSize) => {
    this.setState({
      url: '',
      filter: false,
      emailId: '',
      phoneNo: '',
    })
    this.props.history.push(`${window.location.pathname}?page=${1}&pageSize=${config.pageSize}`)
    this.props.setProjectLeadQuickFilters({})
    this.setState({ filters: {}, statusValue: {}, selectedOrigin: null })

    this.props.setMoreFilter({
      ...this.props.moreFilter,
      // showModal: false,
      activeFilter: false,
      filterCount: 0,
    })

    // apply quick filter values
    let quickFilter = this.props.quickFilter

    let showAllLeads = shouldShowAllLeads(this.props.curretUser)
    let showAllLeadsParam = ''
    if (showAllLeads) {
      showAllLeadsParam = `&showAllLeads=${showAllLeads}`
    }
    let url = `${
      config.apiPath
    }/api/v1/leads/projects?hasBooking=${this.hasBooking()}${showAllLeadsParam}`
    if (quickFilter) {
      let sortingOrder =
        this.props.leadOrder && this.props.leadOrder.value ? this.props.leadOrder.value : ''

      quickFilter.map((filter) => {
        if (['assignToMe', 'singleTask'].indexOf(filter.name) > -1)
          url = `${url}&${filter.name}=${filter.value}`
        else url = `${url}&${filter.name}[]=${filter.value}`
      })

      url = `${url}${sortingOrder}`
    } else this.props.history.push(`${this.props.location.pathname}?page=1&pageSize=${config.pageSize}`)

    if (this.state.searchCompare) {
      this.props.fetchFiltersData(url, null, null, { status: '' })
      // this.props.fetchLeads(undefined, undefined, undefined, this.hasBooking())
      this.setState({
        searchCompare: false,
      })
    }
  }

  handleQueryChange = (current, pageSize) => {
    const offsetValue = parseInt(current - 1) * pageSize
    this.setState({ currentPage: current })
    let route = `${this.props.location.pathname}?page=${current}&pageSize=${pageSize}`
    let queryValue = { offset: offsetValue, pageSize }
    const { history } = this.props
    history && history.push(route)
    if (this.props.onChangePage) {
      this.props.onChangePage(current, pageSize)
    } else if (this.state.filter) {
      this.props.fetchFiltersData(this.state.url, null, null, this.state.statusValue)
    } else {
      this.props.fetchLeads(
        queryValue,
        this.props.leadOrder && this.props.leadOrder.value,
        undefined,
        this.hasBooking(),
        undefined,
        this.props.clientLeadModal
      )
    }
  }

  updatePropState = (id) => {
    this.setState({
      itemId: id,
    })
  }
  //
  // fetchClientNames = () => {
  //   axios
  //     .get(`${config.apiPath}/api/customer/teamClients?type=project`)
  //     .then((res) => this.setState({ clientNames: res.data }))
  // }

  fetchAllCities = () => {
    axios.get(`${config.apiPath}/api/cities?all=true`).then((res) => {
      let array = []
      res.data &&
        res.data.length &&
        res.data.filter((item) => {
          return array.push({ label: item.name, value: item.id })
        })
      this.setState({ cities: array })
    })
  }

  fetchAllFormIds = () => {
    axios.get(`${config.apiPath}/api/leads/formIds`).then((res) => {
      let array = []
      res.data &&
        res.data.length &&
        res.data.filter((item) => {
          return array.push({ label: item.form_id, value: item.id })
        })
      this.setState({ formId: array })
    })
  }

  fetchAllProject = () => {
    axios.get(`${config.apiPath}/api/project/all?offset=0`).then((res) => {
      let array = []
      res.data &&
        res.data.items.length &&
        res.data.items.filter((item) => {
          return array.push({ label: item.name, value: item.id })
        })
      this.setState({ projects: array })
    })
  }

  getServerTime = () => {
    axios.get(`${config.apiPath}/api/user/serverTime?fullTime=true`).then((res) => {
      this.setState({
        getServerTime: res.data,
      })
    })
  }

  timeStatusColors = (lead) => {
    const { getServerTime } = this.state
    var statusColor = ''
    var curDate = moment(getServerTime).format('DD')
    var leadDate = moment(lead.assigned_at).format('DD')
    var time = moment.duration(moment(getServerTime).diff(moment(lead.assigned_at))).asMinutes()
    time = time.toFixed(0)
    let user_id = localStorage.getItem('user_id')

    if (user_id !== lead && lead.shared_with_armsuser_id) {
      statusColor = 'Refered'
    } else if (lead.shared_with_armsuser_id != null) {
      statusColor = 'shared'
    } else {
      if (curDate === leadDate && lead.status === 'open') {
        if (time < 30) {
          if (lead.readAt != null) {
            statusColor = 'leadOpen'
          } else {
            statusColor = 'new'
          }
        }
        if (time > 30 && time < 60) {
          statusColor = 'delay__30__60'
        }
        if (time > 60) {
          statusColor = 'delay__60__above'
        }
      } else {
        if (lead.status === 'open') {
          if (time < 30) {
            if (lead.readAt != null) {
              statusColor = 'leadOpen'
            } else {
              statusColor = 'new'
            }
          }
          if (time > 30 && time < 60) {
            statusColor = 'delay__30__60'
          }
          if (time > 60) {
            statusColor = 'delay__60__above'
          }
        }
      }
    }

    return statusColor
  }

  checkForReadOnly = (check) => {
    if (check) {
      this.setState({ showReadOnlyPopup: true })
    } else {
      this.setState({ showReadOnlyPopup: false })
    }
  }

  onChangeDeleteReasong = (e) => {
    this.setState({ deletionReason: e.target.value })
  }

  deleteInvestmentLeadCloseModal = () => {
    this.setState({ deleteOpen: false, deletionReason: '' })
  }

  deleteToggle = () => {
    this.setState({
      deleteOpen: !this.state.deleteOpen,
    })
  }

  setSelectedLeadId = (id) => {
    this.setState({
      leadId: id,
    })
  }

  onChangeFollowUp = (object) => {
    this.setState({
      followUpStatusActiveButton: object.value,
      filterClearButton: true,
      filter: true,
      followUpValue: object,
    })
  }

  onChangeSelectFilter = (name, value) => {
    const { filters } = this.state
    if (name === 'userHierarchy') {
      this.setState({ filters: { ...filters, userHierarchy: value } })
    }
    if (name === 'teamHierarchy') {
      this.setState({ filters: { ...filters, teamHierarchy: value } })
    }
    if (name === 'project') {
      this.setState({ selectedProjectFilter: value, filters: { ...filters, projectId: value } })
    }
    if (name === 'city') {
      this.setState({ selectedCity: value, filters: { ...filters, cityId: value } })
    }
    if (name === 'formId') {
      this.setState({ selectedFormId: value, filters: { ...filters, formId: value } })
    }
    if (name === 'leadsAssigned') {
      this.setState({ selectedAssignedLead: value, filters: { ...filters, leadsAssigned: value } })
    }
    if (name === 'bulk') {
      this.setState({ selectedBulk: value, filters: { ...filters, bulk: value } })
    }
    if (name === 'createdBy') {
      this.setState({ selectedCreated: value, filters: { ...filters, createdBy: value } })
    }
    if (name === 'team') {
      this.setState({ selectedTeam: value, filters: { ...filters, team: value } })
    }
    if (name === 'faceBookFilter') {
      this.setState({
        selectedfaceBookFilter: value,
        filters: { ...filters, faceBookFilter: value },
      })
    }

    if (name === 'assignToMeFilter') {
      this.setState({ assignToMe: value, filters: { ...filters, assignToMe: value } })
    }

    if (name === 'assignedTo') {
      this.setState({ selectedAgents: value, filters: { ...filters, assignedTo: value } })
    }
    if (name === 'organization') {
      this.setState({ selectedOrg: value, filters: { ...filters, organization: value } })
    }
    if (name === 'origin') {
      this.setState({ selectedOrigin: value, filters: { ...filters, origin: value } })
    }
    if (name === 'singleBookFilter') {
      this.setState({ selectedSingle: value, filters: { ...filters, singleTask: value } })
    }
    if (name === 'followUp') {
      this.setState({ selectedFollowUp: value, filters: { ...filters, followUp: value } })
    }
    if (name === 'aging') {
      this.setState({ selectedAging: value, filters: { ...filters, aging: value } })
    }
    if (name === 'leadCategory') {
      this.setState({ leadCategory: value, filters: { ...filters, leadCategory: value } })
    }
    if (name === 'country') {
      this.setState({ country: value, filters: { ...filters, country: value } })
    }
    if (name === 'emailId') {
      this.setState({ emailId: value, filters: { ...filters, emailId: value } })
    }
    if (name === 'phoneNo') {
      this.setState({ phoneNo: value, filters: { ...filters, phoneNo: value } })
    }

    this.setState({
      filter: true,
      activeName: name,
      newActiveFilterNameList: [...this.state.newActiveFilterNameList, name],
    })
  }

  deleteProjectLead = () => {
    const { selectedLeads, leadId, deletionReason } = this.state
    const { filterResponse } = this.props
    axios
      .delete(`${config.apiPath}/api/leads/remove?reason=${deletionReason}`, {
        params: {
          id: [leadId],
        },
      })
      .then((res) => {
        this.props.fetchLeads(undefined, undefined, undefined, this.hasBooking())
        this.setState({
          deleteOpen: false,
          deletionReason: '',
        })
        showToastMsg(res && res.data && res.data, 'success')
      })
      .catch((error) => {
        showToastMsg(
          error && error.response && error.response.data && error.response.data.message,
          'error'
        )
        this.setState({
          deleteOpen: false,
          deletionReason: '',
        })
      })
  }

  selectAll = () => {
    const { leadsData } = this.props
    const { allSelected } = this.state
    let leadIds = leadsData.rows.map((item) => {
      if (item.status !== 'closed_lost' && item.status !== 'closed_won') {
        return item.id
      }
    })
    this.setState({
      selectedLeads: allSelected ? [] : leadIds,
      allSelected: !allSelected,
      allSelectedElements: leadsData,
      filterSelectedItem: !allSelected,
    })
  }

  toggleConnectModal = () => {
    this.setState({ taskModal: false })
  }

  reOpenToggle = (id) => {
    this.setState({
      reOpenModal: !this.state.reOpenModal,
      itemId: this.state.leadId,
    })
  }

  reOpen = () => {
    const { leadId, selectedLeads } = this.state
    const { filterResponse } = this.props
    axios
      .patch(
        `${config.apiPath}/api/leads/project`,
        { status: 'open' },
        {
          params: {
            id: [leadId],
          },
        }
      )
      .then((res) => {
        this.props.fetchLeads(undefined, undefined, undefined, this.hasBooking())
        this.setState({ reOpenModal: false })
        showToastMsg('Lead re-opened Successfully', 'success')
      })
  }

  toggleFilterButton = () => {
    this.setState({
      filterToggle: !this.state.filterToggle,
    })
  }

  getCountriesNameWithCountriesCode = () => {
    axios.get(`${config.apiPath}/api/user/getCountryCodes`).then((res) => {
      let countriesListWihCode = res.data.map((item) => {
        return {
          label: item.name,
          value: item.phone,
        }
      })
      this.setState({ countriesListWihCountriesCode: countriesListWihCode })
    })
  }

  showAlertToggle = () => {
    this.setState({
      showAlertToggleState: true,
    })
    setTimeout(() => {
      this.setState({
        showAlertToggleState: false,
      })
    }, 3000)
  }

  render() {
    if (this.props.location.pathname === ProjectDealUrl) {
      if (!this.projectDealsPageView) {
        return null
      }
    }
    if (this.props.location.pathname === ProjectLeadUrl) {
      if (!this.projectLeadPageView && !this.props.popup) {
        return null
      }
    }
    const {
      leadsData,
      pageSize,
      loading,
      page,
      user,
      fetchUser,
      moreFilter,
      setMoreFilter,
      popup,
      projectLeadQuickFilter,
      clientNames,
      otherleads,
    } = this.props
    const {
      clearButton,
      startDate,
      endDate,
      focusedInput,
      filterId,
      clientNameValue,
      statusValue,
      usersAll,
      itemId,
      followUpModal,
      closeLostToggleState,
      singleLeadId,
      showBackgroundIndex,
      loginUserId,
      followupModalFlag,
      deleteOpen,
      deletionReason,
      reOpenModal,
      projects,
      selectedProjectFilter,
      activeFilterNameList,
      newActiveFilterNameList,
      cities,
      selectedCity,
      formId,
      selectedFormId,
      selectedAssignedLead,
      selectedBulk,
      selectedCreated,
      allTeams,
      selectedTeam,
      selectedfaceBookFilter,
      allAgents,
      nonTerminalAgents,
      selectedAgents,
      allOrg,
      selectedOrg,
      assignToMe,
      selectedOrigin,
      selectedSingle,
      selectedFollowUp,
      selectedAging,
      filterToggle,
      countDiffer,
      selectedLeads,
      selectedItemLeads,
      filterSelected,
      shareToggleState,
      modalConfirmation,
      processing,
      warningMessages,
      user_data,
      shareloader,
      agents,
      shareLeadType,
      filterSelectedItem,
      showAlertToggleState,
      leadCategory,
      reOpenActionEnable,
      taskModal,
      leadTaskModal,
      fetchLeadsInModal,
      diaryModal,
      selectedLead,
      filters,
      agentCount,
      teamHierarchy,
      userHierarchy,
      guideReferenceModal,
      currentPage,
      emailId,
      phoneNo,
      country,
      countriesListWihCountriesCode,
      additionalInfoToggleState,
      updateLeadTasks,
      slotModal,
      connectFlow,
      newTask,
      closeAsDuplicateModal,
      closeAsLostModal,
    } = this.state

    let statusElement = selectedItemLeads && selectedItemLeads.map((item) => item.status)

    let role = localStorage.getItem('role')

    const clientNameFilterOptions =
      clientNames &&
      clientNames.result &&
      clientNames.result.map((item) => ({
        label: item.first_name + ' ' + item.last_name,
        value: item.first_name + ' ' + item.last_name,
      }))

    const statusValues = [
      { value: 'all', name: 'All' },
      { value: 'open', name: 'Open' },
      { value: 'called', name: 'Called' },
      { value: 'no_response', name: 'No Response' },
      { value: 'powered_off', name: 'Powered Off' },
      { value: 'invalid_number', name: 'Invalid Number' },
      { value: 'not_interested', name: 'Not Interested' },
      { value: 'DNC', name: 'DNC' },
      { value: 'details_texted', name: 'No Response but Details Sent' },
      { value: 'other_agent', name: 'In contact with some other advisor' },
      { value: 'call_back', name: 'Call Back' },
      { value: 'awaiting_call', name: 'Awaiting Response' },
      { value: 'follow_up', name: 'Follow up' },
      { value: 'details_sent', name: 'Details Sent' },
      { value: 'expected_visit', name: 'Expected Visit' },
      { value: 'meeting_planned', name: 'Meeting Planned' },
      { value: 'visited', name: 'Visited' },
      { value: 'deal_expected', name: 'Deal Expected' },
      { value: 'deal_signed', name: 'Deal Signed' },
      { value: 'token', name: 'Token' },
      { value: 'payment', name: 'Payment' },
      { value: 'closed_won', name: 'Closed Won' },
      { value: 'closed_lost', name: 'Closed Lost' },
    ]

    const usersFilterOption =
      usersAll &&
      usersAll.map((item) => ({
        label: item.firstName + ' ' + item.lastName,
        value: item.id,
      }))

    const statusFilterOption =
      statusValues &&
      statusValues.map((item) => ({
        label: item.name,
        value: item.value,
      }))

    const fieldHeight = {
      control: (base) => ({
        ...base,
        height: 35,
        minHeight: 35,
        border: '1px solid rgb(204, 204, 204)',
        boxShadow: 'none',
        '&:hover': {
          border: '1px solid rgb(204, 204, 204)',
        },
      }),
      singleValue: (base) => ({
        ...base,
        color: 'hsl(0,0%,50%)',
      }),
    }

    const customStyles = {
      control: (base) => ({
        ...base,
        height: 35,
        minHeight: 35,
        '&:hover': {
          borderColor: '#046ef2',
          color: '#046ef2',
        },
        '&': {
          borderColor: '#046ef2',
          color: '#046ef2',
        },
      }),
      dropdownIndicator: (base) => ({
        ...base,
        color: 'inherit',
      }),
      indicatorSeparator: (base) => ({
        ...base,
        backgroundColor: '#046ef2',
      }),
      singleValue: (base) => ({
        ...base,
        color: 'inherit',
      }),
    }
    let checkForRead = { ownLeadReadOnly: false, showReadOnly: false, checkForManager: false }

    let checkForfilters =
      filterId !== '' ||
      emailId !== '' ||
      country !== '' ||
      phoneNo !== '' ||
      clientNameValue.value !== undefined ||
      statusValue.value !== undefined ||
      startDate !== null ||
      endDate !== null ||
      selectedProjectFilter !== null ||
      selectedCity !== null ||
      selectedAssignedLead !== null ||
      selectedBulk !== null ||
      selectedCreated !== null ||
      selectedTeam !== null ||
      selectedfaceBookFilter !== null ||
      selectedAgents != null ||
      selectedOrg != null ||
      selectedOrigin != null ||
      selectedFollowUp != null ||
      selectedAging != null ||
      selectedSingle != null ||
      assignToMe != null ||
      leadCategory != null ||
      selectedFormId != null ||
      userHierarchy != null ||
      teamHierarchy != null

    const loginUserRole = localStorage.getItem('role')

    let reasonColWidth = 130
    if (this.props.location.pathname === ProjectDealUrl) {
      if (this.lead_table_ref.current)
        reasonColWidth = this.lead_table_ref.current.offsetWidth - 1193
      if (reasonColWidth < 130) reasonColWidth = 130
    }

    return (
      <div
        onClick={() => this.setState({ showMoreActions: true })}
        className="dash-container investment-lead-page-wrap"
      >
        <div className={`topAlertToggle ${showAlertToggleState ? 'showToggle' : null}`}>
          <ReadOnlyAlert closeAlert={this.checkForReadOnly} />
        </div>
        {!popup && countDiffer > 0 ? (
          <div className="refreshPage-main-wrap">
            <div className="message-wrap">
              <p>
                {countDiffer == 1
                  ? 'There is 1 new lead....'
                  : `There are ${countDiffer} new leads....`}
                <span className="reload-btn" onClick={() => this.reloadLeadHandler()}>
                  Reload
                </span>
              </p>
            </div>
          </div>
        ) : null}
        <div>
          <div>
            {loading ? (
              <Load title="Project Leads" />
            ) : (
              <>
                {leadsData && leadsData.rows && leadsData.rows.length === 0 ? (
                  <div style={{ marginTop: '60px', marginBottom: 50 }} className="text-center">
                    <h3 className="dimgray">No Leads Found</h3>
                  </div>
                ) : (
                  <>
                    {selectedLeads && selectedLeads.length > 1 && !reOpenActionEnable ? (
                      <button
                        className="link-style projectLeadButton m-2"
                        style={{ padding: '0 !important' }}
                        onClick={() => {
                          this.setState({ success: true })
                          this.shareToggle('assign')
                        }}
                        disabled={
                          selectedLeads.length === 0
                            ? true
                            : statusElement.includes('closed_won')
                            ? true
                            : false
                        }
                      >
                        Reassign {`${this.checkReassign(selectedLeads)} Leads`}
                      </button>
                    ) : null}

                    <table
                      ref={this.lead_table_ref}
                      className={
                        loginUserRole === 'sub_admin 1' && filterToggle
                          ? `inv-lead-table changeHeightForAssign `
                          : selectedLeads && selectedLeads.length > 1
                          ? `inv-lead-table changeHeightOfAssign `
                          : `inv-lead-table ${filterToggle ? 'changeHeight' : ''}`
                      }
                    >
                      <thead>
                        <tr>
                          {!popup && accountsPermissions('projectReassign') && (
                            <th style={{ maxWidth: '30px', minWidth: '30px' }}>
                              <input
                                type="checkbox"
                                onClick={() => this.selectAll()}
                                checked={filterSelectedItem ? true : false}
                              />
                            </th>
                          )}

                          <th style={{ maxWidth: '30px', minWidth: '30px' }}></th>
                          <th style={{ maxWidth: '120px', minWidth: '120px' }}>Lead ID</th>
                          {this.props.location.pathname !== ProjectDealUrl && (
                            <th style={{ maxWidth: '140px', minWidth: '140px' }}>Date Created</th>
                          )}
                          <th style={{ maxWidth: '130px', minWidth: '130px' }}>Client Name</th>
                          <th style={{ maxWidth: '130px', minWidth: '130px' }}>Project</th>
                          <th style={{ maxWidth: '130px', minWidth: '130px' }}>Assigned To</th>
                          <th style={{ maxWidth: '40px', minWidth: '40px', textAlign: 'center' }}>
                            Status
                          </th>
                          {this.props.location.pathname !== ProjectDealUrl && (
                            <>
                              <th
                                style={{
                                  maxWidth: '160px',
                                  minWidth: '160px',
                                  textAlign: 'center',
                                }}
                              >
                                Classification
                              </th>
                              <th style={{ maxWidth: '150px', minWidth: '150px' }}>Follow-up</th>
                            </>
                          )}
                          <th style={{ maxWidth: '130px', minWidth: '130px' }}>Origin</th>

                          {this.props.location.pathname === ProjectDealUrl && (
                            <th style={{ maxWidth: '130px', minWidth: '130px' }}>Final Price</th>
                          )}
                          {this.props.location.pathname === ProjectDealUrl && (
                            <>
                              <th style={{ maxWidth: '130px', minWidth: '130px' }}>Floor</th>
                              <th style={{ maxWidth: '130px', minWidth: '130px' }}>Unit</th>
                            </>
                          )}
                          {this.props.location.pathname !== ProjectDealUrl && (
                            <>
                              <th style={{ maxWidth: '130px', minWidth: '130px' }}>Unit Type</th>
                              <th style={{ maxWidth: '120px', minWidth: '120px' }}>City</th>
                              <th style={{ maxWidth: '150px', minWidth: '150px' }}>
                                Additional Details
                              </th>
                            </>
                          )}

                          {this.props.location.pathname !== ProjectDealUrl && (
                            <>
                              <th style={{ maxWidth: '150px', minWidth: '150px' }}>
                                Advisor Contact No
                              </th>
                              <th style={{ maxWidth: '130px', minWidth: '130px' }}>Form Id</th>
                            </>
                          )}
                          <th style={{ maxWidth: reasonColWidth, minWidth: reasonColWidth }}>
                            Closing Reason
                          </th>

                          <th
                            style={{
                              zIndex: showBackgroundIndex
                                ? 999
                                : itemId || followUpModal
                                ? 999999
                                : null,
                            }}
                          ></th>
                          <th style={{maxWidth: 70, minWidth: 70}}></th>
                        </tr>
                      </thead>
                      <tbody>
                        {leadsData &&
                          leadsData.rows.map((item, key) => {
                            let statusColor = this.timeStatusColors(item)
                            checkForRead = isReadOnly(item, 'Project')
                            let readOnly =
                              (item.armsuser &&
                                parseInt(loginUserId) !== parseInt(item.armsuser.id)) ||
                              item.status === 'closed_lost' ||
                              item.status === 'closed_won'
                                ? true
                                : false
                            return (
                              <InvestmentLeadInner
                                additionalInfoToggle={this.additionalInfoToggle}
                                reasonColWidth={reasonColWidth}
                                setGuideReferenceModal={(toggle) =>
                                  this.setState({ guideReferenceModal: toggle })
                                }
                                otherleads={otherleads || false}
                                popup={popup || false}
                                item={item}
                                tabUrl={this.props.tabUrl}
                                key={key}
                                selectedLead={selectedLead}
                                pageType={this.props.location.pathname}
                                selectedLeads={selectedLeads}
                                selectedItemLeads={selectedItemLeads}
                                filterSelected={filterSelected}
                                selectLead={this.selectLead}
                                updateBackgroundIndex={this.updateBackgroundIndex}
                                updatePropState={this.updatePropState}
                                itemId={itemId}
                                clientLeadModal={this.props.clientLeadModal}
                                fetchUser={() => fetchUser()}
                                user={user}
                                toggleLeadDetail={this.props.toggleLeadDetail}
                                updateList={() => this.handleQueryChange(1, pageSize)}
                                statusColor={statusColor}
                                followupModalFlag={followupModalFlag}
                                followUpModalLocal={this.followUpModalLocal}
                                setCloseLostToggleState={(leadId) => {
                                  this.setState({
                                    closeLostToggleState: true,
                                    singleLeadId: leadId,
                                  })
                                }}
                                readOnly={readOnly}
                                checkForReadOnly={this.checkForReadOnly}
                                checkForRead={checkForRead}
                                setSelectedLeadId={this.setSelectedLeadId}
                                setSelectedLead={(lead) => this.setState({ selectedLead: lead })}
                                deleteToggle={this.deleteToggle}
                                reOpenToggle={this.reOpenToggle}
                                showAlertToggle={this.showAlertToggle}
                                setSetupTaskModal={(toggle) => this.setState({ taskModal: true })}
                                setDiaryModal={(toggle) => this.setState({ diaryModal: true })}
                                hasBooking={this.hasBooking()}
                                leadsData={leadsData}
                                bookUnitRedirect={this.props.bookUnitRedirect}
                                onRowClick={(rowData) => {
                                  this.props.onRowClick && this.props.onRowClick(rowData)
                                }}
                              />
                            )
                          })}
                      </tbody>
                    </table>

                    {additionalInfoToggleState && (
                      <AdditionalDetails
                        onToggle={this.additionalInfoToggle}
                        isOpen={additionalInfoToggleState}
                        additionalDetailLeadrecord={selectedLead}
                        checkLeadtype={'Project'}
                      ></AdditionalDetails>
                    )}
                    {!popup && (
                      <>
                        <DeleteInvestmentLead
                          deleteOpen={deleteOpen}
                          deleteToggle={this.deleteToggle}
                          selectedLeads={[]}
                          deletionReason={deletionReason}
                          deleteProjectLead={this.deleteProjectLead}
                          onChangeText={this.onChangeDeleteReasong}
                          deleteInvestmentLeadCloseModal={this.deleteInvestmentLeadCloseModal}
                        />

                        <LeadReopen
                          reOpenModal={reOpenModal}
                          reOpenToggle={this.reOpenToggle}
                          selectedLeads={[]}
                          reOpen={this.reOpen}
                        />
                      </>
                    )}

                    {(!popup || (this.props.fromPage && this.props.fromPage === 'leadSelection')) &&
                      leadsData &&
                      leadsData.count !== 0 && (
                        <div className="row mt-3 mb-2">
                          <div className="col-md-4 totalCountAlignment tai">
                            <span className="totalCountStyle tai">
                              Showing
                              <span className="totalCountSubStyle">
                                {((popup ? this.state.currentPage : page) - 1) * config.pageSize + 1} -
                                {leadsData &&
                                leadsData.count >=
                                  (popup ? this.state.currentPage : page) * pageSize
                                  ? (popup ? this.state.currentPage : page) * pageSize
                                  : leadsData && leadsData.count}
                              </span>{' '}
                              / {leadsData && leadsData.count}
                            </span>
                          </div>
                          <div
                            className="col-md-6"
                            style={{ marginLeft: leadsData && leadsData.count < 21 ? 20 : 0 }}
                          >
                            {leadsData && leadsData.count > config.pageSize && (
                              <div>
                                <Pagination
                                  className="ps-pagination"
                                  hideOnSinglePage={true}
                                  pageSize={config.pageSize}
                                  current={parseInt(popup ? this.state.currentPage : page, 10)}
                                  showPrevNextJumpers={false}
                                  itemRender={this.textItemRender}
                                  onChange={this.handleQueryChange}
                                  total={leadsData.count}
                                  locale={local}
                                />
                              </div>
                            )}
                          </div>
                          <div className="col-md-2"></div>
                        </div>
                      )}
                  </>
                )}
              </>
            )}
          </div>
        </div>

        <ReferModal
          shareToggleState={shareToggleState}
          shareToggle={this.shareToggle}
          managerSelectionDropdown={managerSelectionDropdown}
          loader={shareloader}
          agents={agents}
          fetchAgents={this.fetchAgents}
          filterReferLeadAgentName={this.filterReferLeadAgentName}
          investmentLeadShareFun={this.investmentLeadReferFun}
          assignLeadFun={this.assignLeadFun}
          shareLeadType={shareLeadType}
          agentCount={agentCount}
          processing={processing}
        />

        <ReAssignWarningModal
          modalConfirmation={modalConfirmation}
          toggleModalConfirmation={this.toggleModalConfirmation}
          assignLeadFun={this.assignLeadFun}
          warningMessages={warningMessages}
          user_data={user_data}
        />

        {!popup && (
          <>
            {closeLostToggleState ? (
              <CloseLostFeedback
                leadId={singleLeadId}
                callback={() => {
                  this.handleQueryChange(1, pageSize)
                }}
                closeLostToggle={() => this.setState({ closeLostToggleState: false })}
                closeLostToggleState={closeLostToggleState}
                setCloseLostToggleState={(toggle) =>
                  this.setState({ closeLostToggleState: toggle })
                }
              />
            ) : (
              <></>
            )}

            <ToastContainer
              position="bottom-right"
              hideProgressBar={false}
              newestOnTop={false}
              rtl={false}
              closeButton={false}
            />

            <Modal
              isOpen={moreFilter.showModal}
              toggle={() =>
                setMoreFilter({
                  ...moreFilter,
                  showModal: false,
                })
              }
              style={{ minWidth: 1100 }}
              className="device-modal"
            >
              <ModalBody>
                <div className={'row'}>
                  <div className={'col-sm-12'}>
                    <h4>More Filters</h4>
                    <div className="close-icon-container">
                      <i
                        style={{ top: 5 }}
                        className="mt-1 fal fa-times-circle close-icon"
                        onClick={() =>
                          setMoreFilter({
                            ...moreFilter,
                            showModal: false,
                          })
                        }
                      ></i>
                    </div>
                  </div>
                  <div className={'col-12'}>
                    <div className="mt-2" id="moreFilters">
                      <div className="row">
                        <div className="mt-2 col-3">
                          <input
                            type="number"
                            placeholder="Lead ID"
                            className={
                              activeFilterNameList.includes('leadId') === true
                                ? 'customStyle'
                                : 'Id_textfield'
                            }
                            value={
                              filters && filters['id'] !== undefined
                                ? filters['id']
                                : projectLeadQuickFilter &&
                                  projectLeadQuickFilter['id'] !== undefined
                                ? projectLeadQuickFilter['id']
                                : null
                            }
                            onChange={(e) => {
                              let value = e.target.value
                              this.setState({
                                filters: { ...filters, id: value },
                                filterActiveButton: value.value,
                                filterClearButton: true,
                                filterId: value,
                                filter: true,
                                newActiveFilterNameList: [...newActiveFilterNameList, 'leadId'],
                              })
                            }}
                          />
                        </div>
                        <div className="mt-2 col-3">
                          <div
                            style={{ zIndex: 9 }}
                            onClick={() => {
                              if (followUpModal) {
                                this.setState({
                                  followUpModal: false,
                                  resetAll: true,
                                  itemId: false,
                                })
                              }
                            }}
                          >
                            <div
                              className={`filterDateRange ${
                                projectLeadQuickFilter &&
                                projectLeadQuickFilter['startDate'] !== undefined
                                  ? 'selectedBorderColor '
                                  : 'defaultBorderColor '
                              }`}
                            >
                              <DateRangePicker
                                isOutsideRange={(day) => !isInclusivelyBeforeDay(day, moment())}
                                startDate={startDate}
                                className="activeDatePicker"
                                endDate={endDate}
                                startDateId="date_input_start"
                                endDateId="date_input_end"
                                onDatesChange={({ startDate: dateStart, endDate: dateEnd }) =>
                                  this.setState(() => {
                                    return {
                                      filters: {
                                        ...filters,
                                        startDate: dateStart,
                                        endDate: dateEnd,
                                      },
                                      startDate: dateStart,
                                      endDate: dateEnd,
                                      filter: true,
                                      filterClearButton: true,
                                      dateActiveButton: true,
                                      newActiveFilterNameList: [
                                        ...newActiveFilterNameList,
                                        'startEndDate',
                                      ],
                                    }
                                  })
                                }
                                numberOfMonth={1}
                                focusedInput={focusedInput}
                                onFocusChange={(input) => this.setState({ focusedInput: input })}
                                showClearDates
                                minimumNights={0}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="mt-2 col-3">
                          <Select
                            value={
                              filters && filters['clientName'] !== undefined
                                ? filters['clientName']
                                : projectLeadQuickFilter &&
                                  projectLeadQuickFilter['clientName'] !== undefined
                                ? projectLeadQuickFilter['clientName']
                                : null
                            }
                            styles={
                              projectLeadQuickFilter &&
                              projectLeadQuickFilter['clientName'] !== undefined
                                ? customStyles
                                : fieldHeight
                            }
                            options={clientNameFilterOptions}
                            noOptionsMessage={() => 'No client name found'}
                            placeholder="Client Name"
                            isLoading={this.props.isClientFilterLoading}
                            onInputChange={(change) => {
                              if (change) this.props.fetchAllClients(change, 'project')
                            }}
                            onChange={(value) => {
                              this.setState(() => {
                                return {
                                  filters: { ...filters, clientName: value },
                                  activeFilterClientName: value.value,
                                  filterClearButton: true,
                                  clientNameValue: value,
                                  filter: true,
                                  newActiveFilterNameList: [
                                    ...newActiveFilterNameList,
                                    'clientName',
                                  ],
                                }
                              })
                            }}
                          />
                        </div>
                        <div className="mt-2 col-3">
                          <Select
                            value={
                              filters && filters['status'] !== undefined
                                ? filters['status']
                                : projectLeadQuickFilter &&
                                  projectLeadQuickFilter['status'] !== undefined
                                ? projectLeadQuickFilter['status']
                                : null
                            }
                            styles={
                              projectLeadQuickFilter &&
                              projectLeadQuickFilter['status'] !== undefined
                                ? customStyles
                                : fieldHeight
                            }
                            options={
                              this.state.name === 'Project Leads'
                                ? StaticData.projectLeadStatusesExcludeClosedWon
                                : StaticData.projectLeadStatuses
                            }
                            noOptionsMessage={() => 'No Status found'}
                            placeholder="Status"
                            onChange={(value) => {
                              this.setState({
                                filters: { ...filters, status: value },
                                reOpenActionEnable:
                                  value.value === 'closed_won' || value.value === 'closed_lost'
                                    ? true
                                    : false,
                                activeStatusActiveButton: value.value,
                                filterClearButton: true,
                                filter: true,
                                statusValue: value,
                                newActiveFilterNameList: [...newActiveFilterNameList, 'status'],
                              })
                            }}
                          />
                        </div>
                      </div>
                      <MoreFilters
                        selectedSingle={selectedSingle}
                        selectedfaceBookFilter={selectedfaceBookFilter}
                        selectedFollowUp={selectedFollowUp}
                        allProjects={projects}
                        allCities={cities}
                        selectedProjectFilter={selectedProjectFilter}
                        activeFilterNameList={activeFilterNameList}
                        selectedCity={selectedCity}
                        allFormId={formId}
                        selectedFormId={selectedFormId}
                        selectedAssignedLead={selectedAssignedLead}
                        selectedBulk={selectedBulk}
                        usersFilterOption={usersFilterOption}
                        selectedCreated={selectedCreated}
                        allTeams={allTeams}
                        selectedTeam={selectedTeam}
                        allAgents={allAgents}
                        nonTerminalAgents={nonTerminalAgents}
                        selectedAgents={selectedAgents}
                        allOrg={allOrg}
                        selectedOrg={selectedOrg}
                        selectedAging={selectedAging}
                        selectedOrigin={selectedOrigin}
                        onChangeFollowUp={this.onChangeFollowUp}
                        onChangeSelectFilter={this.onChangeSelectFilter}
                        leadCategory={leadCategory}
                        assignToMe={assignToMe}
                        filters={filters}
                        projectLeadQuickFilter={projectLeadQuickFilter}
                        emailId={emailId}
                        phoneNo={phoneNo}
                        country={country}
                        countriesListWihCountriesCode={countriesListWihCountriesCode}
                        isTerminalUser={this.props.isTerminalUser}
                      />
                    </div>
                  </div>
                </div>
                <hr></hr>
                <div className={'d-flex'} style={{ gridGap: 10 }}>
                  <div>
                    <Button
                      text="Search"
                      buttonColor="#026ff2"
                      textColor="white"
                      action="search"
                      width="80px"
                      className="searchBtn"
                      onClick={() => {
                        if (!this.props.popup) {
                          this.props.history.push(
                            `${window.location.pathname}?page=${1}&pageSize=${config.pageSize}`
                          )
                        }
                        this.setState({ currentPage: 1 })

                        if (checkForfilters) {
                          this.fetchFiltersData(filters ? filters : projectLeadQuickFilter, 1)
                          this.props.setProjectLeadQuickFilters(filters)
                        } else {
                          this.props.setMoreFilter({
                            ...this.props.moreFilter,
                            showModal: false,
                            activeFilter: false,
                            filterCount: 0,
                          })
                        }
                      }}
                    ></Button>
                  </div>
                  <div>
                    <Button
                      text="Clear"
                      buttonColor="#ffffff"
                      action="details"
                      className="detailsBtn"
                      textColor="#2e96f5"
                      width="80px"
                      disabled={clearButton}
                      onClick={() => {
                        this.setState({ currentPage: 1 })
                        this.clearButton()
                      }}
                    ></Button>
                  </div>
                </div>
              </ModalBody>
            </Modal>
            {leadTaskModal && (
              <DiaryTaskModal
                taskModalToggle={leadTaskModal}
                leadId={(selectedLead && selectedLead.id) || ''}
                lead={selectedLead}
                diary={null}
                leadType={'Project'}
                diaryAction={'add-lead-task'}
                closeTaskModal={() =>
                  this.setState({
                    leadTaskModal: false,
                  })
                }
                callBack={(toggle) => this.setState({ fetchLeadsInModal: toggle })}
              />
            )}
          </>
        )}

        {taskModal && (
          <ConnectModal
            connectModal={taskModal}
            lead={selectedLead}
            leadType={'Project'}
            action={null}
            setNewTask={(newTask) =>
              this.setState({ taskModal: false, newTask: newTask, connectFlow: true })
            }
            onAction={(action) => {
              if (action === 'scheduled-task') this.setState({ diaryModal: true })
              else if (action === 'additional-details')
                this.setState({ additionalInfoToggleState: true })
              else if (action === 'refer-lead') this.shareToggle('share')
              else if (action === 'reassign-lead') this.shareToggle('assign')
              else if (action === 'investment-guide') this.setState({ guideReferenceModal: true })
              else if (action === 'close-as-duplicate')
                this.setState({ closeAsDuplicateModal: true })
              else if (action === 'close-as-lost') this.setState({ closeAsLostModal: true })
              else if (action === 'add-meeting') this.setState({ slotModal: true })
            }}
            callBack={(action) => {}}
            updateLeadTasks={updateLeadTasks}
            setConnectModal={(toggle, refreshLead) => {
              this.setState({ taskModal: toggle })
              if (otherleads || refreshLead) {
                this.props.refreshLead()
              } else {
                return this.props.clientLeadModal
                  ? null
                  : this.handleQueryChange(currentPage, pageSize)
              }
            }}
            setLeadTaskModal={(toggle) => this.setState({ leadTaskModal: toggle })}
            setCallRecord={(callRecord) => this.setState({ taskModal: false })}
          />
        )}

        {connectFlow && (
          <ConnectFlow
            setConnectModal={(toggle) => {
              this.setState({ taskModal: toggle, connectFlow: false })
            }}
            setConnectFlow={() => this.setState({ connectFlow: false })}
            diary={newTask}
            actionType={'Connect'}
            callback={() => {
              this.setState({ connectFlow: false })
              this.handleQueryChange(currentPage, pageSize)
            }}
          />
        )}

        {diaryModal && (
          <DiaryModal
            leadType={'Project'}
            checkForRead={isReadOnly(selectedLead, 'Project')}
            diaryModal={diaryModal}
            setDiaryModal={(toggle) => this.setState({ diaryModal: toggle })}
            setLeadTaskModal={(toggle) => this.setState({ leadTaskModal: toggle })}
            fetchLeadsInModal={fetchLeadsInModal}
            setFetchLeadsInModal={(toggle) => {
              this.setState({ fetchLeadsInModal: toggle })
              this.handleQueryChange(currentPage, pageSize)
            }}
            lead={selectedLead}
          />
        )}

        {guideReferenceModal && (
          <ReferenceGuideModal
            leadId={selectedLead && selectedLead.id}
            callBack={() => {
              this.setState({ guideReferenceModal: false })
              this.handleQueryChange(1, pageSize)
            }}
            guideReferenceModal={guideReferenceModal}
            setGuideReferenceModal={(toggle) => this.setState({ guideReferenceModal: toggle })}
          />
        )}

        <SlotModal
          slotModal={slotModal}
          setSlotModal={(toggle) => this.setState({ slotModal: toggle })}
          title={`Select slot for Meeting Task`}
          taskType={'meeting'}
          callBack={(bookedTime) => {
            this.setState({ slotModal: false })
            diaryCreateUpdateAPI(
              makeTaskTimePayload(bookedTime, 'meeting', {
                leadId: selectedLead && selectedLead.id,
              }),
              true,
              () => this.setState({ updateLeadTasks: !this.state.updateLeadTasks })
            )
          }}
        />

        {closeAsDuplicateModal && (
          <CloseAsDuplicateModal
            lead={selectedLead}
            leadType={'Project'}
            modal={closeAsDuplicateModal}
            setModal={(toggle) => this.setState({ closeAsDuplicateModal: toggle })}
            callback={() => {
              this.setState({ closeAsDuplicateModal: false })
              this.handleQueryChange(currentPage, pageSize)
              this.toggleConnectModal()
            }}
          />
        )}

        {closeAsLostModal && (
          <CloseAsLostModal
            lead={selectedLead}
            leadType={'Project'}
            modal={closeAsLostModal}
            setModal={(toggle) => this.setState({ closeAsLostModal: toggle })}
            callback={() => {
              this.setState({ closeAsLostModal: false })
              this.handleQueryChange(currentPage, pageSize)
              this.toggleConnectModal()
            }}
          />
        )}
      </div>
    )
  }
}

export const mapDispatchToProps = (dispatch) => {
  return {
    fetchLeads: (
      query,
      sortingOrder,
      callback,
      hasBooking,
      onlyOpenStatus,
      clientLead,
      tabUrl,
      customer
    ) =>
      dispatch(
        fetchLeads(
          query,
          sortingOrder,
          callback,
          hasBooking,
          onlyOpenStatus,
          clientLead,
          tabUrl,
          customer
        )
      ),
    fetchFreshLeads: (query, callback) => dispatch(fetchFreshLeads(query, callback)),
    fetchFiltersData: (url, offset, callback, status) =>
      dispatch(fetchFiltersData(url, offset, callback, status)),
    setTitle: (title) => dispatch(setTitle(title)),
    fetchUser: () => dispatch(UserInfoFetch()),
    setMoreFilter: (status) => dispatch(setMoreFilter(status)),
    setProjectLeadQuickFilters: (filters) => dispatch(setProjectLeadQuickFilters(filters)),
    fetchAllClients: (clientName, type) =>
      dispatch(fetchAllClients(clientName, type, '&ownCustomer=true')),
    addInvestmentShareLead: (obj, callback, leadId, type, showLeadReferSuccessMsgHandler) =>
      dispatch(investmentShareLead(obj, callback, leadId, type, showLeadReferSuccessMsgHandler)),
  }
}

const mapStateToProps = (state, ownProps) => {
  const urlParams = new URLSearchParams(ownProps.location.search)
  const pageSize = urlParams.get('pageSize')
  const page = urlParams.get('page')
  return {
    page,
    pageSize,
    ...urlParams,
    leadsData:
      ownProps.fromPage && ownProps.fromPage === 'leadSelection'
        ? state.Leads.leads
        : ownProps.popup
        ? state.Leads.client_leads
        : state.Leads.leads,
    loading: ownProps.popup ? state.Leads.cloading : state.Leads.loading,
    leadOrder: state.GeneralSetting.dataSort,
    freshLeads: state.Leads.freshLeads,
    user: state.user.userInfo,
    moreFilter: state.GeneralSetting.moreFilter,
    quickFilter: state.GeneralSetting.quickFilter,
    projectLeadQuickFilter: state.Leads.projectLeadQuickFilter,
    clientNames: state.Leads.allClients,
    isClientFilterLoading: state.Leads.isClientFilterLoading,
    isTerminalUser: state.user.isTerminalUser,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InvestmentLead)
