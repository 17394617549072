/** @format */

import React, { Component, Fragment } from "react";
import Pagination from "rc-pagination";
import { connect } from "react-redux";
import axios from "axios";
import config from "../../../config";

import Load from "../../../components/Loader";

import Swal from "sweetalert2";
import { normalizeCnic } from "../../../utils/formatCnic";

// import ClinetCreationModal from './modal'
// import './style.scss'
import { validIban } from "../../../utils/checkIban";
import Inner from "./ClientBankAccount";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { showToastMsg } from '../../../utils/helper'

class AddBank extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: {
        accountTitle: "",
        iBan: "",
        accountNo: "",
        branchName: "",
        checkRequiredField: false,
        validationCheck: false,
        bankNamesOpstions: [],
      },
      IBAN: '',
      AccountTitle: '',
      SelectedBank: null,
      AccountNo: '',
      BranchName: '',
      checkIbanTrueOrFalse: true,
      validationCheck: false,
    };
  }

  componentDidMount() {
    this.fetchAllBanks();
    this.emptyAddBankAccountform();
  }

  checkBankAccountsValidation = (IBAN, accountNo) => {
    var IBAN = IBAN.replace(/-/g, "");
    var accountNo = accountNo.replace(/-/g, "");
    if (
      (validIban(IBAN) === true && IBAN !== "") ||
      (accountNo !== "" &&
        ((IBAN !== 1 && validIban(IBAN) === true) || IBAN.length === 0))
    ) {
      this.setState({ IBANValidation: false })
      return false
    } else {
      this.setState({ IBANValidation: true })
      return true
    }
  }

  onCreateBankAccount = () => {
    const {
      validationCheck,
      IBAN,
      SelectedBank,
      AccountTitle,
      AccountNo,
      BranchName,
      checkIbanTrueOrFalse,
    } = this.state

    this.setState({ checkIbanTrueOrFalse: validIban(IBAN) })

    if (
      AccountTitle &&
      AccountTitle !== "" &&
      SelectedBank &&
      SelectedBank.value &&
      SelectedBank.value !== null &&
      this.checkBankAccountsValidation(IBAN, AccountNo) === false
    ) {
      this.setState({
        loadingOnsubmit: true,
      });
      let body = {
        customerId: this.props && this.props.id,
        accountTitle: AccountTitle,
        accountNumber: AccountNo,
        branch: BranchName,
        IBAN: IBAN,
        bankName: SelectedBank && SelectedBank.label,
        bankId: SelectedBank && SelectedBank.value,
      };
      axios
        .post(`${config.apiPath}/api/booking/createCustomerBankDetails`, body)
        .then((res) => {
           
          if (res && res.status === 200) {
            showToastMsg('Bank account has been added successfully.', 'success')

            this.props.apiCallDonBankCreated(res.data[0]);

            this.props.BankModalToggle();
            this.setState({
              loading: false,
              loadingOnsubmit: false,
            });
          }
        })
        .catch(() => {
          this.setState({
            loading: false,
            loadingOnsubmit: false,
          });
        });
    } else {
      this.setState({
        validationCheck: true,
        loadingOnsubmit: false,
      });
    }
  };

  // Bank account form handlers start
  onSelectBank = (data) => {
    this.setState({ SelectedBank: data, validationCheck: false });
  };

  onAccountTitle = (data) => {
    this.setState({ AccountTitle: data, validationCheck: false });
  };

  onIbanChange = (data) => {
    if (data.length <= 24) {
      this.setState({
        IBAN: data,
        checkIbanTrueOrFalse: true,
        validationCheck: false,
      });
    }
  };
  onAccountNoChange = (data) => {
    this.setState({
      AccountNo: data,
      checkIbanTrueOrFalse: false,
      validationCheck: false,
    });
  };
  onBranchNameChange = (data) => {
    this.setState({ BranchName: data, validationCheck: false });
  };
  // Bank account form handlers end

  checkBankAccountsValidation = (IBAN, AccountNo, checkIbanTrueOrFalse) => {
    var IBAN = IBAN.replace(/-/g, "");
    var AccountNo = AccountNo.replace(/-/g, "");
    if (
      (validIban(IBAN) === true && IBAN !== "") ||
      (AccountNo !== "" &&
        ((IBAN !== 1 && validIban(IBAN) === true) || IBAN.length === 0))
    ) {
      this.setState({ IBANValidation: false });
      return false;
    } else {
      this.setState({ IBANValidation: true });
      return true;
    }
  };

  // empty form when create new bank account
  emptyAddBankAccountform = () => {
    this.setState({
      AccountTitle: "",
      SelectedBank: null,
      IBAN: "",
      AccountNo: "",
      BranchName: "",
      validationCheck: false,
    });
  };

  // fetch names of bank names for listing dropdown
  fetchAllBanks = () => {
    axios.get(`${config.apiPath}/api/banks`).then((res) => {
      let bankOptions = res.data.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
      this.setState({ bankNamesOpstions: bankOptions });
    });
  };

  handleChange = (e) => {
    const { formData } = this.state;
    var name = e.target.name;
    var value = e.target.value;
    var newFormData = { ...formData };
    newFormData[name] = value;
    this.setState({
      formData: newFormData,
    });
  };

  onPressCancelBtn = (e) => {
    this.setState({ validationCheck: false });
    this.emptyAddBankAccountform();
  };

  onSelectBank = (change) => {
    this.setState({
      SelectedBank: change,
    });
  };
  render() {
    const {
      formData,
      IBAN,
      AccountTitle,
      SelectedBank,
      AccountNo,
      BranchName,
      bankNamesOpstions,
      validationCheck,
      checkIbanTrueOrFalse,
      loadingOnsubmit,
    } = this.state;
    const { activeBankModal, BankModalToggle, addBankAccountFrom } = this.props;
    return (
      <Modal
        isOpen={activeBankModal}
        toggle={BankModalToggle}
        size="lg"
        style={{ maxWidth: "730px", width: "100%" }}
        contentClassName="comment-modal-width"
      >
        <div className="createClientModal">
            {/* <ModalHeader toggle={BankModalToggle}>Add Bank Account</ModalHeader>  */}
          <ModalBody>
            <div className={`filterMainWrap padding__modal`}>
              <Inner
                formData={formData}
                handleChange={this.handleChange}
                handleChangePhone={this.handleChangePhone}
                onCreateBankAccount={this.onCreateBankAccount}
                checkPurchaserPage={true}
                onSelectBank={this.onSelectBank}
                addBankAccountFrom={addBankAccountFrom}
                AddNewBankAccountDetail={true}
                BankAccountEditMode={false}
                openManageAccount={activeBankModal}
                onAccountTitle={this.onAccountTitle}
                onAccountNoChange={this.onAccountNoChange}
                onIbanChange={this.onIbanChange}
                onBranchNameChange={this.onBranchNameChange}
                IBAN={IBAN}
                AccountTitle={AccountTitle}
                SelectedBank={SelectedBank}
                AccountNo={AccountNo}
                BranchName={BranchName}
                bankNamesOpstions={bankNamesOpstions}
                validationCheck={validationCheck}
                checkIbanTrueOrFalse={checkIbanTrueOrFalse}
                onPressCancelBtn={this.onPressCancelBtn}
                onAddNewBankAccount={BankModalToggle}
                loadingOnsubmit={loadingOnsubmit}
              />
            </div>
          </ModalBody>
        </div>
      </Modal>
    );
  }
}

export const mapStateTopProps = (state, ownProps) => {
  return {};
};

export const mapDispatchTopProps = (dispatch) => {
  return {};
};

export default connect(mapStateTopProps, mapDispatchTopProps)(AddBank);
