import React from "react";
import ReactApexChart from "react-apexcharts";

export default class BarChart extends React.Component {
  constructor(props) {
    super(props);
    const { Proplabel} = this.props;
    this.state = {
      series: [
        {
          data: [],
        },
      ],
      options: {
        chart: {
          type: "bar",
          height: 800,
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            barHeight: "100%",
            distributed: true,
            horizontal: false,

          },
        },
        colors: [],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 1,
          colors: ["#fff"],
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          labels: {
            show: true,
          },
        },
        title: {
          text: Proplabel,
          align: "center",
          offsetX: 10,
          offsetY: 10,
        },
        tooltip: {
          theme: "dark",
          x: {
            show: false,
          },
          y: {
            title: {
              formatter: function () {
                return "";
              },
            },
          },
        },
      },
    };
  }

  componentWillReceiveProps(nextProps) {
    const { options } = this.state;
    if (
      nextProps &&
      nextProps.graphValues &&
      nextProps.graphValues.length > 0 &&
      nextProps &&
      nextProps.grapColors &&
      nextProps.grapColors.length > 0 &&
      nextProps &&
      nextProps.grapTitles &&
      nextProps.grapTitles.length > 0
    ) {
      this.setState(() => ({
        series: [
          {
            data: nextProps.graphValues,
          },
        ],

        options: {
          ...options,
          colors: nextProps.grapColors,
          xaxis: {
            categories: nextProps.grapTitles,
            labels: {
              rotate: -45,
              trim:true
            }
          },
        },
      }));
    }
  }

  render() {
    return (
      <div id="chart">
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="bar"
          height={380}
        />
      </div>
    );
  }
}
