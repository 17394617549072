/** @format */

import React from 'react'
import SortItem from './SortItem'
import menuItems from '../Menu/MenuItems'
import { extractRoute } from '../../../utils/helper'
import { connect } from 'react-redux'
import { setDataSort } from '../../../actions/generalSetting'
import { setDiaryDataSort, setOverDueDataSort } from '../../../actions/diary'

const Sort = (props) => {
  const isDiaryView = () => {
    if (window.location.pathname.includes('diary')) return true
    else if (window.location.pathname.includes('overdue')) return false
    else return null
  }

  let pageSortOptions = []
  let submenu
  // select sort options on base of current route
  Object.keys(menuItems).map((key) => {
    // check if any one menu link or sub menu link matches then select the respected sort options
    menuItems[key].map((menu) => {
      if (extractRoute(menu.link) === props.path && menu.sortOptions)
        pageSortOptions = menu.sortOptions
      else if (menu.subMenus) {
        submenu = menu.subMenus.filter((submenu) => extractRoute(submenu.link) === props.path)
        if (submenu.length && submenu[0].sortOptions) pageSortOptions = submenu[0].sortOptions
      }
    })
  })

  return (
    <div className={`dropright dropdown sort__img__dropDown ml-3 sort d-inline-block`}>
      <i
        className={`fa fa-sort-amount-down sort-icon ${props.dataSort ? 'active' : ''}`}
        id="dropdownMenuButton"
        data-toggle="dropdown"
        data-flip={false}
        data-posi
        aria-haspopup="true"
        aria-expanded="false"
      ></i>
      <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
        {pageSortOptions.map((item, k) => (
          <SortItem
            active={props.dataSort ? props.dataSort.value === item.value : k === 0}
            key={k}
            item={item}
            callBack={(sortData) => {
              props.setDataSort(sortData)
              if (isDiaryView() === true) {
                props.setDiaryDataSort(sortData)
              } else if (isDiaryView() === false) {
                props.setOverDueDataSort(sortData)
              }
            }}
          />
        ))}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    dataSort: state.GeneralSetting.dataSort,
  }
}
export const mapDispatchToProps = (dispatch) => {
  return {
    setDataSort: (dataSort) => dispatch(setDataSort(dataSort)),
    setDiaryDataSort: (dataSort) => dispatch(setDiaryDataSort(dataSort)),
    setOverDueDataSort: (dataSort) => dispatch(setOverDueDataSort(dataSort)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Sort)
