import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { connect } from 'react-redux'
import './index.scss'
import SingleDevice from './SingleDevice'
import Button from '../../common/Button'

const ConfirmModal = (props) => {

  return (
    <Modal
      isOpen={props.confirmModal}
      toggle={(toggle) => props.setConfirmModal(!toggle)}
      contentClassName="modal-action-size"
      className="modal-action-size device-modal"
    >
      <ModalBody>
        <div className={"row mt-3 justify-content-center"}>
          <div className={"col-sm-11"}>
            <h4>Click on notification on mobile device to make a call</h4>
          </div>
        </div>

        <div className={"devices-list mt-4 mb-5"}>
          <SingleDevice
            device={props.device}
            fromList={false}
          />
        </div>
        <div className={"d-flex mt-2 mb-2 justify-content-center"}>
          <Button
                  textColor={'white'}
                  buttonColor='#026ff2' className={"px-5 rounded-pill"} onClick={() => props.callBack()}
                  text={"OK"}
          />
        </div>
      </ModalBody>
    </Modal>
  )
}
export default ConfirmModal
