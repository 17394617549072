/** @format */

import React from 'react'
const CryptoJS = require('crypto-js')

let permissions = null
export const UnlockAccess = ({ children, currentUser, roles }) => {
  let permission = false
  if (roles && roles.length > 0) {
    permission = roles.includes(currentUser)
  }
  return <>{permission && children}</>
}

export const UnlockFeature = ({ children, feature, action }) => {
  return <>{getPermissionValue(feature, action) && children}</>
}

export const getPermissionValue = (feature, action) => {
  let permission = false
  if (!permissions) {
    permissions = localStorage.getItem('permissions')
    if (permissions && (permissions !== null || permissions !== '')) {
      let bytes = CryptoJS.AES.decrypt(permissions, 'secret key 099')
      permissions = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
    } else {
      return true
    }
  }
  if (permissions && permissions.armsPermission) {
    let featurePermissions = permissions.armsPermission[feature]
    if (featurePermissions) {
      let actionPermission = featurePermissions.find((element) => {
        if (Array.isArray(action)) {
          return action.includes(element.action)
        }
        return element.action === action
      })

      if (actionPermission) {
        permission = actionPermission.value
      }
    }
  }

  return permission
}
