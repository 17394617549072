/** @format */

import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Select from 'react-select'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { fetchAllProjects } from '../../actions/projects'
import { fetchCities } from '../../actions/user'
import LeadCreationInner from '../BuyRentLeadCreation/leadCreationInner'
import ProjectLeadCreationInner from '../LeadCreation/leadCreationInner'
import { properties, leadType, sizeRange, priceRange } from '../../utils/constant_data'
import { productType } from '../../components/ReduxForm'
import { addLead, addBuyRentLead } from '../../actions/leads'
import { setLeadType, setLeadClientError } from '../../actions/user'
import { fetchAreas } from '../../actions/area'
import { fetchSubProperties } from '../../actions/property'
import { ThemeConsumer } from 'react-bootstrap/esm/ThemeProvider'
import { PermissionFeatures, PermissionActions } from '../../utils/permissionConstants'
import { getPermissionValue } from '../../components/_unlockComponent'
class AddLead extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // leadOption: { label: 'Select Lead Type', value: 'selectleadtype' },
      leadOption: { },
      errorMessage: {
        client: false,
        city: false,
      },
      buyRentErrorMessage: {
        client: false,
        city: false,
        leadAreas: false,
        type: false,
        subtype: false,
      },
      productType: [],
    }
  }
  componentDidMount = () => {
    this.props.fetchCities()
    this.props.fetchAllProjects()
    this.setLeadOptions()
  }
  setLeadOptions = () => {
    let types = []
    let isBuyRent = getPermissionValue(PermissionFeatures.BUY_RENT_LEADS, PermissionActions.CREATE)
    let isWantedLeads = getPermissionValue(
      PermissionFeatures.WANTED_LEADS,
      PermissionActions.CREATE
    )
    let isProjectLeads = getPermissionValue(
      PermissionFeatures.PROJECT_LEADS,
      PermissionActions.CREATE
    )
    if (isBuyRent) {
      types.push({ label: 'Rent', value: 'rent' }, { label: 'Buy', value: 'buy' })
      // if (isAira()) {
      //   types.push(
      //     { label: "Sell", value: "sell" },
      //     { label: "Rent Out", value: "rent out" }
      //   );
      // }
    }
    if (isProjectLeads) {
      types.push({ label: 'Invest', value: 'project' })
    }
    this.setState({ leadOption: [this.state.leadOption, ...types] })
    // {label:'Invest',value:'project'},{label:'Rent',value:'rent'},{label:'Buy',value:'buy'}
  }
  checkLeadType = (leadType) => {
    if (leadType === 'selectleadtype') {
      // this.props.setLeadClientError(false)
    }
    this.props.setLeadType(leadType)
    this.setState({ selectedLeadType: leadType })
  }
  setName = (name) => {
    this.setState({ name })
  }
  addProjectLead = async (value) => {
    if (this.props.client) {
      let selectedClient = this.props.client
      let location = this.props.location
      let data
      let phones =
        selectedClient &&
        selectedClient.customerContacts &&
        selectedClient.customerContacts.map((item) => item.phone)
      if (location && location.state) {
        phones = [location.state && location.state.customer && location.state.customer.phone]
      }
      if (!value.cityId) {
        this.props.setLeadClientError(true)
        this.setState({
          errorMessage: {
            ...this.state.errorMessage,
            city: !value.cityId ? true : false,
            client: !selectedClient ? true : false,
          },
        })
      } else {
        this.props.setLeadClientError(false)
        data = {
          customerId: this.props.client && this.props.client.id,
          cityId: value.cityId.value,
          projectId: value.project && value.project.value ? value.project.value : null,
          projectName: value.project && value.project.label ? value.project.label : null,
          projectType:
            value.projectType && value.projectType.label ? value.projectType.label : null,
          minPrice: value.ranger.price_min,
          maxPrice: value.ranger.price_max,
          description: value.description,
          armsProjectTypeId:
            value.projectType && value.projectType.value ? value.projectType.value : null,
          phones: phones,
        }
        this.props.addLeadFunc(data, true)
      }
    } else {
      if (!value.cityId) {
        this.props.setLeadClientError(true)
        this.setState({
          errorMessage: {
            ...this.state.errorMessage,
            city: !value.cityId ? true : false,
          },
        })
      }
    }
  }
  addBuyRentLead = (value) => {
    const {purpose}=this.props;
    if (this.props.client) {
      let selectedClient = this.props.client
      let location = this.props.location
      let phones =
        selectedClient &&
        selectedClient.customerContacts &&
        selectedClient.customerContacts.map((item) => item.phone)
      if (location && location.state) {
        phones = [location.state && location.state.customer && location.state.customer.phone]
      }
      let data
      if (!selectedClient || !value.cityId || !value.areaIds || !value.type || !value.subtype) {
        this.setState({
          buyRentErrorMessage: {
            ...this.state.buyRentErrorMessage,
            city: !value.cityId ? true : false,
            client: !selectedClient ? true : false,
            leadAreas: !value.areaIds ? true : false,
            type: !value.type ? true : false,
            subtype: !value.subtype ? true : false,
          },
        })
      } else {
        const { selectedLeadType } = this.state
        data = {
          customerId: selectedClient ? selectedClient && selectedClient.id : null,
          city_id: value.cityId.value,
          type: value.type.value,
          subtype: value.subtype.value,
          purpose:purpose?purpose: selectedLeadType,
          min_price: value.ranger.price_min,
          price: value.ranger.price_max,
          size: value.sizeRanger.size_min,
          max_size: value.sizeRanger.size_max,
          bed: value.bedRanger && value.bedRanger.minVal ? value.bedRanger.minVal : 0,
          maxBed: value.bedRanger && value.bedRanger.maxVal ? value.bedRanger.maxVal : 0,
          bath: value.bathRanger && value.bathRanger.minVal ? value.bathRanger.minVal : 0,
          maxBath: value.bathRanger && value.bathRanger.maxVal ? value.bathRanger.maxVal : 0,
          size_unit: value.sizeRanger.unit.value,
          description: value.description,
          leadAreas: value.areaIds.map((area) => area.value),
          phones,
        }

        this.props.addBuyRentLeadFunc(data, true)
      }
    } else {
      if (!value.cityId || !value.areaIds || !value.type || !value.subtype) {
        this.setState({
          buyRentErrorMessage: {
            ...this.state.buyRentErrorMessage,
            city: !value.cityId ? true : false,
            leadAreas: !value.areaIds ? true : false,
            type: !value.type ? true : false,
            subtype: !value.subtype ? true : false,
          },
        })
      }
    }
  }
  render() {
    const {
      isModal,
      setTitle,
      fetchUserClients,
      userClients,
      fetchCities,
      cities,
      areas,
      subProperties,
      fetchSubProperties,
      fetchAreas,
      addLeadLoader,
      addLeadFunc,
      history,
      location,
      allProjects,
      selectedLeadType
    } = this.props
    const {
      leadOption,
      errorMessage,
      productType,
      buyRentErrorMessage,
    } = this.state
    const className = isModal ? 'col-md-6' : 'col-md-4'
    const classNameAddress = isModal ? 'col-md-12' : 'col-md-8'
    return (
      <>
        <div className={'permAddressContainer row'}>
          {/* <div className={`col-md-4`}>
            <div className="subtitle">Select lead Type</div>
            <div className="filterInputWrap">
              <Select
                type="text"
                className={'select-control'}
                placeholder={'Select Lead Type'}
                name={'leadType'}
                onChange={(e) => {
                  this.checkLeadType(e.value)
                }}
                options={leadOption}
                //   value={formData.district}
                required
              />
            </div>
          </div> */}
        </div>
        {selectedLeadType === 'project' && (
          <ProjectLeadCreationInner
            setName={this.setName}
            cities={cities}
            allProjects={allProjects}
            addLeadLoader={addLeadLoader}
            onSubmit={(data) => {
              this.addProjectLead(data)
            }}
            history={history}
            errorMessage={errorMessage}
            isClientPage={true}
          />
        )}
        {(selectedLeadType === 'buy' || selectedLeadType === 'rent' || selectedLeadType === 'rentOut'|| selectedLeadType === 'sell' ) && (
          <LeadCreationInner
            userClients={userClients}
            areas={areas}
            leadType={leadType}
            sizeRange={sizeRange}
            properties={properties}
            subProperties={subProperties}
            fetchSubProperties={fetchSubProperties}
            fetchAreas={fetchAreas}
            cities={cities}
            addLeadLoader={addLeadLoader}
            onSubmit={(data) => {
              this.addBuyRentLead(data)
            }}
            history={history}
            errorMessage={buyRentErrorMessage}
            isClientPage={true}
          />
        )}
      </>
    )
  }
}
export const mapDispatchToProps = (dispatch) => {
  return {
    fetchCities: () => dispatch(fetchCities()),
    fetchAllProjects: () => dispatch(fetchAllProjects()),
    addLeadFunc: (data) => dispatch(addLead(data, true)),
    addBuyRentLeadFunc: (data) => dispatch(addBuyRentLead(data, true)),
    setLeadType: (leadType) => dispatch(setLeadType(leadType)),
    fetchAreas: (cityId) => dispatch(fetchAreas(cityId)),
    fetchSubProperties: (property) => dispatch(fetchSubProperties(property)),
    setLeadClientError: (property) => dispatch(setLeadClientError(property)),
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
    userClients: state.Leads.userClients,
    cities: state.user.cities,
    allProjects: state.Projects.allProjects,
    addLeadLoader: state.Leads.addLeadLoader,
    leadType: state.user.leadType,
    client: state.user.client,
    areas: state.Areas.allAreas,
    subProperties: state.Property.subProperties,
    purpose:state.user.leadType
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AddLead))
