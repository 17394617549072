/** @format */

import axios from 'axios'
import config from '../config'
import { shouldShowAllLeads } from '../utils/helper'
import { mapFiltersToQuery } from './../components/common/LeadView/Project/ProjectUtilHelper'
import { SET_BUYRENT_LEADS_COUNT_FILTER } from './buyrentLeads'
axios.defaults.withCredentials = true

export const FETCH_PROJECT_LEADS = 'FETCH_PROJECT_LEADS'
export const FETCH_PROJECT_MY_LEADS = 'FETCH_PROJECT_MY_LEADS'
export const FETCH_PROJECT_COMPANY_LEADS = 'FETCH_PROJECT_COMPANY_LEADS'
export const FETCH_PROJECT_DEACTIVATED_LEADS = 'FETCH_PROJECT_DEACTIVATED_LEADS'
export const FETCH_PROJECT_DEACTIVATED_DEALS = 'FETCH_PROJECT_DEACTIVATED_DEALS'
export const FETCH_PROJECT_DEALS = 'FETCH_PROJECT_DEALS'
export const FETCH_PROJECT_REFERRED_LEADS = 'FETCH_PROJECT_REFERRED_LEADS'
export const FETCH_PROJECT_REFERRED_DEALS = 'FETCH_PROJECT_REFERRED_DEALS'
export const FETCH_PROJECT_TEAM_LEADS = 'FETCH_PROJECT_TEAM_LEADS'
export const FETCH_PROJECT_TEAM_DEALS = 'FETCH_PROJECT_TEAM_DEALS'
export const SET_PROJECT_LEADS_FILTERS = 'SET_PROJECT_LEADS_FILTERS'
export const SET_PROJECT_LEADS_COUNT_FILTER = 'SET_PROJECT_LEADS_COUNT_FILTER'
export const SET_FORM_IDS = 'SET_FORM_IDS'

export const getProjectLeads = (
  pagination = { currentPage: 1, pageSize: config.pageSize },
  customerId = null,
  query = ''
) => {
  return (dispatch, getState) => {
    let projectLeadState = { ...getState().ProjectLeads }
    let moreFilters = projectLeadState.filters.filters
    let quickFilters = getState().GeneralSetting.quickFilter
    let leadOrder = getState().GeneralSetting.dataSort
    let filters = mapFiltersToQuery(moreFilters, quickFilters)
    let showAllLeads = shouldShowAllLeads(getState().user)
    let allLeadParam = ''
    if (showAllLeads) {
      allLeadParam = `&showAllLeads=${showAllLeads}`
    }
    const { currentPage, pageSize } = pagination
    var url = `${config.apiPath}/api/v1/leads/projects?web=true&page=${currentPage}&pageSize=${pageSize}&isShowCount=true`
    if (customerId) url += `&customerId=${customerId}`
    if (filters) url += `&${filters}`
    if (showAllLeads) url += allLeadParam
    if (leadOrder) url += leadOrder.value
    if (query) {
      url += query
    }
    let redux_key = FETCH_PROJECT_LEADS
    if (query.indexOf('companyLead=no') > -1) redux_key = FETCH_PROJECT_MY_LEADS
    else if (query.indexOf('companyLead=yes') > -1) redux_key = FETCH_PROJECT_COMPANY_LEADS
    else if (query.indexOf('hasBooking=false&deactivatedLead=yes') > -1)
      redux_key = FETCH_PROJECT_DEACTIVATED_LEADS
    else if (query.indexOf('hasBooking=true&deactivatedLead=yes') > -1)
      redux_key = FETCH_PROJECT_DEACTIVATED_DEALS
    else if (query.indexOf('pageType=myDeals') > -1) redux_key = FETCH_PROJECT_DEALS
    else if (query.indexOf('pageType=referredLeads&hasBooking=false') > -1)
      redux_key = FETCH_PROJECT_REFERRED_LEADS
    else if (query.indexOf('pageType=referredLeads&hasBooking=true') > -1)
      redux_key = FETCH_PROJECT_REFERRED_DEALS
    else if (query.indexOf('pageType=teamLeads') > -1) redux_key = FETCH_PROJECT_TEAM_LEADS
    else if (query.indexOf('pageType=teamDeals') > -1) redux_key = FETCH_PROJECT_TEAM_DEALS

    dispatch({ type: redux_key, data: [], loading: true })
    axios
      .get(url)
      .then(function (response) {
        dispatch({ type: redux_key, data: response.data, loading: false })
      })
      .catch(function (xhr, status, err) {
        return dispatch({ type: redux_key, data: [], loading: false })
      })
  }
}

export const destroyProjectLeadState = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_PROJECT_LEADS, data: [], loading: false })
  }
}
export const destroyProjectDeactivatedLeadState = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_PROJECT_DEACTIVATED_LEADS, data: [], loading: false })
  }
}
export const destroyProjectDeactivatedDealState = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_PROJECT_DEACTIVATED_DEALS, data: [], loading: false })
  }
}
export const destroyProjectMyLeadState = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_PROJECT_MY_LEADS, data: [], loading: false })
  }
}
export const destroyProjectCompanyLeadState = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_PROJECT_COMPANY_LEADS, data: [], loading: false })
  }
}
export const destroyProjectDealState = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_PROJECT_DEALS, data: [], loading: false })
  }
}
export const destroyProjectReferredLeadState = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_PROJECT_REFERRED_LEADS, data: [], loading: false })
  }
}
export const destroyProjectReferredDealState = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_PROJECT_REFERRED_DEALS, data: [], loading: false })
  }
}
export const destroyProjectTeamLeadState = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_PROJECT_TEAM_LEADS, data: [], loading: false })
  }
}
export const destroyProjectTeamDealState = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_PROJECT_TEAM_DEALS, data: [], loading: false })
  }
}

export const setProjectLeadFilters = (filters, cb = null) => {
  return (dispatch) => {
    dispatch({ type: SET_PROJECT_LEADS_FILTERS, data: filters })
    cb && cb()
  }
}

export const setProjectLeadCountFilter = (status) => {
  return (dispatch) => {
    dispatch({ type: SET_PROJECT_LEADS_COUNT_FILTER, data: status })
  }
}

export const fetchFormIds = () => {
  return (dispatch) => {
    dispatch({ type: SET_FORM_IDS, data: [] })
    axios.get(`${config.apiPath}/api/leads/formIds`).then((res) => {
      let array = []
      res.data &&
        res.data.length &&
        res.data.filter((item) => {
          return array.push({ label: item.form_id, value: item.id })
        })
      dispatch({ type: SET_FORM_IDS, data: array })
    })
  }
}
