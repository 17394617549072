/** @format */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  buyAndRentLeads,
  fetchRentAndLeadFilter,
  fetchFreshBuyRentLeads,
  setBuyRentQuickFilters,
} from '../../../../../actions/leads'
import { fetchProjects } from '../../../../../actions/projects'
import { setMoreFilter, setTitle } from '../../../../../actions/generalSetting'
import moment from 'moment'
import Load from '../../../../../components/Loader'
import { DateRangePicker, isInclusivelyBeforeDay } from 'react-dates'
import config from '../../../../../config'
import Select from 'react-select'
import BuyAndRentLeadInner from './buyRentLeadInner'
import Pagination from 'rc-pagination'
import 'rc-pagination/assets/index.css'
import local from '../../../../../local/en_US'
import axios from 'axios'
import _ from 'lodash'
import jwt_decode from 'jwt-decode'
import { StaticData } from '../../../../../StaticData'
import TimlinesModal from '../../../../../components/LeadTimelinesModal'
import { Modal, ModalBody, ModalFooter } from 'reactstrap'
import {
  BuyRentLeadUrl,
  BuyRentDealUrl,
  leadType as rentTypeOptions,
} from '../../../../../utils/constant_data'
import Button from '../../../../../components/common/Button'
import { PermissionFeatures, PermissionActions } from '../../../../../utils/permissionConstants'
import { getPermissionValue } from '../../../../../components/_unlockComponent'
import { isAira } from './../../../../../components/common/LeadView/Wanted/WantedUtilHelper'
import { shouldShowAllLeads } from '../../../../../utils/helper'
class BuyAndRentLead extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: this.props.location.pathname === BuyRentLeadUrl ? 'Buy/Rent Leads' : 'Buy/Rent Deals',
      url: '',
      selectedLeads: [],
      countriesListWihCountriesCode: [],
      pageSize: 50,
      allSelected: false,
      openTeamDropdown: false,
      startDate: null,
      endDate: null,
      focusedInput: null,
      filterActiveButton: null,
      filterClearButton: false,
      activeProjectFilterButton: null,
      filterId: '',
      wantedId: '',
      emailId: '',
      phoneNo: '',
      country: '',
      statusValue: {},
      projectValue: {},
      usersAll: [],
      assignedValue: {},
      activeAssignedActiveButton: null,
      origin: [],
      fitlerOriginValue: {},
      activeOriginButton: null,
      filter: false,
      teamToolTips: [],
      activePropertyTypeFilterButton: null,
      propertyTypeValue: '',
      leadType: '',
      activePropertySubFilterButton: null,
      propertySubTypeFilterValue: '',
      reOpenModal: false,
      itemId: '',
      searchCompare: false,
      statusValueName: '',
      activeLeadTypeFilterButton: null,
      activeStatusActiveButton: null,
      dateActiveButton: false,
      filterResponse: false,
      reOpenActionEnable: false,
      activeFilterAssignedLeads: null,
      assignedLeadsValue: {},
      activeFilterClientName: null,
      clientNameValue: '',
      activeFilterCreatedBy: null,
      createdByValue: {},
      clientNames: [],
      showRows: false,
      leadTypeFilterToggleActive: false,
      propertyTypeFilterToggleActive: false,
      propSubTypeFilterToggleActive: false,
      statusFilterToggleActive: false,
      assignedFilterToggleActive: false,
      dateFilterToggleActive: false,
      originFilterToggleActive: false,
      clientFilterToggleActive: false,
      idFilterToggleActive: false,
      wantedIdFilterToggleActive: false,
      assignedLeadFilterToggleActive: false,
      bulkFilterToggleActive: false,
      createdFilterToggleActive: false,
      clientCountShow: false,
      organizationsDetail: [],
      showMoreActions: false,
      cityForFilter: [],
      blueFilterForCity: false,
      teamDataForFilter: [],
      searchByTeam: null,
      teamBlueStatus: false,
      searchByAging: null,
      agingBLueFilter: false,
      initialCount: 0,
      newCount: null,
      countDiffer: 0,
      initialFlag: false,
      timelineModalActive: false,
      dataForTimelinePopup: [],
      timelineLoading: false,
      orgFilterData: [],
      searchByOrg: null,
      orgBlueStatus: false,
      allCities: [],
      selectedCity: null,
      allAreas: [],
      selectedArea: null,
      blueForCityFilter: false,
      blueForAreaFilter: false,
      clearButton: true,
      activeFilterNameList: [],
      newActiveFilterNameList: [],
      assignToMe: null,
      selectedLead: null,
      filters: {},
      allAgents: [],
      currentPage: 1,
      nonTerminalAgents: [],
    }
    this.userBuyRentLeads = getPermissionValue(
      PermissionFeatures.BUY_RENT_LEADS,
      PermissionActions.READ
    )
    this.userBuyRentDeals = getPermissionValue(
      PermissionFeatures.WEB_PAGES,
      PermissionActions.MY_DEALS_BUY_RENT
    )
  }

  componentDidMount() {
    document.title = this.state.name
    //Fix Nav ARMS-3065, add for fix navigation when filtered some data
    const { filter } = this.state
    //Fix Nav ARMS-3065
    const { history, buyRentQuickFilter } = this.props
    const { pathname } = this.props.location
    const urlParams = new URLSearchParams(window.location.search)
    const pageSize = urlParams.get('pageSize')
    const page = urlParams.get('page')
    this.setState({ currentPage: page ? page : 1 })
    var offset = 0
    if (isNaN((page - 1) * pageSize)) {
      offset = 0
    } else {
      offset = (page - 1) * pageSize
    }
    let query = { offset, pageSize }
    if (this.props.customer) query.clientId = this.props.customer.id

    if (!(page && pageSize) && !this.props.popup) {
      history.push(`${BuyRentLeadUrl}?page=${page || '1'}&pageSize=${pageSize || config.pageSize}`)
    }
    if (!(this.props.fromPage && this.props.fromPage === 'leadSelection')) {
      if (
        !filter || //Fix Nav ARMS-3065
        buyRentQuickFilter === undefined ||
        !Object.keys(buyRentQuickFilter).length
      ) {
        this.props.fetchBRLeads(
          query,
          this.props.leadOrder && this.props.leadOrder.value,
          (data) => {
            this.setState({ initialCount: data.count })
          },
          this.hasBooking(),
          this.props.clientLeadModal,
          this.props.tabUrl,
          this.props.customer
        )
      } else {
        setTimeout(() => {
          this.fetchFiltersData(buyRentQuickFilter)
        }, 300)
      }
    }

    if (!this.props.popup) {
      this.props.fetchProjects(1, `${config.apiPath}/api/project/all?`)
      this.fetchAllUsers()
      this.fetchAllAgents()
      this.fetchOrigin()
      this.fetchClientNames()
      this.fetchOrganisations()
      this.fetchAllTeams()
      this.fetchCities()
      this.fetchAllOrganizations()
      this.fetchAllCities()
      this.fetchAllAreasGroup()
      this.fetchAllAgents(true)
    }
    this.getCountriesNameWithCountriesCode()
  }

  componentWillReceiveProps(nextProps) {
    const { user } = this.props
    if (user && user.userInfo && !this.props.popup) {
      this.fetchAllOrganizations(user.userInfo)
    }
  }
  componentWillMount() {
    // const { setTitle } = this.props
    const { setTitle } = this.props
    const { name } = this.state
    !this.props.popup && setTitle({ name })
  }

  hasBooking = () => {
    if (this.props.popup) return null
    return this.props.location.pathname === BuyRentLeadUrl ? false : true
  }

  fetchAllCities = () => {
    axios.get(`${config.apiPath}/api/cities?all=true`).then((res) => {
      var array = []
      res.data &&
        res.data.length > 0 &&
        res.data.filter((item) => {
          return array.push({ label: item.name, value: item.id })
        })
      this.setState({
        allCities: array,
      })
    })
  }

  searchArea = (e) => {
    this.setState({
      selectedCity: e,
      newActiveFilterNameList: [...this.state.newActiveFilterNameList, 'city'],
    })
  }

  fetchAllAreasGroup = () => {
    axios.get(`${config.apiPath}/api/areas/areaGroups?all=true`).then((res) => {
      var array = []
      res.data &&
        res.data.rows.length > 0 &&
        res.data.rows.filter((item) => {
          return array.push({ label: item.name, value: item.id })
        })
      this.setState({ allAreas: array })
    })
  }

  onChangeAreaFilter = (e) => {
    this.setState({
      selectedArea: e,
      newActiveFilterNameList: [...this.state.newActiveFilterNameList, 'area'],
    })
  }

  fetchClientNames = () => {
    axios
      .get(`${config.apiPath}/api/customer/teamClients`)
      .then((res) => this.setState({ clientNames: res.data }))
  }

  fetchOrganisations = () => {
    axios.get(`${config.apiPath}/api/user/organizations?limit=2`).then((res) => {
      this.setState({
        organizationsDetail: res.data.rows,
      })
    })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    //Fix Nav ARMS-3065
    const { buyRentQuickFilter } = prevProps
    if (
      buyRentQuickFilter !== undefined &&
      Object.keys(buyRentQuickFilter).length === 0 &&
      prevState.filter
    ) {
      this.setState({ filter: false })
    }
    //Fix Nav ARMS-3065

    if (
      this.props.leadOrder &&
      (!prevProps.leadOrder || prevProps.leadOrder.value !== this.props.leadOrder.value)
    )
      this.fetchFiltersData(this.props.buyRentQuickFilter || {})
    // when quick filter update
    if (
      this.props.quickFilter &&
      JSON.stringify(prevProps.quickFilter) !== JSON.stringify(this.props.quickFilter)
    )
      this.fetchFiltersData(this.props.buyRentQuickFilter || {})
  }

  fetchAllTeams = () => {
    var role = localStorage.getItem('role')
    var mm = localStorage.getItem('user_agency_mgmt')
    var obj = JSON.parse(mm)
    var obj2 = jwt_decode(obj.data.token)
    var regionId = obj2.region_id
    var armsTeamId = obj2.armsTeamId
    var orgId = obj2.organizationId
    var endPoint = ''
    if (role === 'super_admin' || role === 'admin') {
      endPoint = '/api/user/teams?all=true&status=true'
    } else if (role === 'admin 3') {
      endPoint = `/api/user/teams?all=true&status=true&regionId=${regionId}`
    } else if (role === 'sub_admin 1') {
      endPoint = `/api/user/teams?all=true&status=true&id=${armsTeamId}`
    } else if (role === 'admin 2') {
      endPoint = `/api/user/teams?all=true&status=true&organizationId=${orgId}`
    }
    axios.get(`${config.apiPath}${endPoint}`).then((res) => {
      var object = []
      res.data.rows &&
        res.data.rows.length &&
        res.data.rows.filter((item) => {
          return object.push({ label: item.teamName, value: item.id })
        })
      this.setState({
        teamDataForFilter: object,
      })
    })
  }

  arrayForSelectFilter = (array) => {
    var newArray = []
    array &&
      array.length &&
      array.length > 0 &&
      array.filter((item) => {
        return newArray.push({ value: item.id, label: item.name })
      })
    return newArray
  }

  fetchFiltersData = (buyRentQuickFilter) => {
    const { initialCount, statusValue } = this.state
    let groupManager = localStorage.getItem('groupManager')

    this.setState({ leadsBeforeFilterCount: initialCount, filterRefreshFlag: true })

    let sortingOrder =
      this.props.leadOrder && this.props.leadOrder.value ? this.props.leadOrder.value : ''

    if (this.props.history)
      this.props.history.push(`${window.location.pathname}?page=${1}&pageSize=${config.pageSize}`)

    let showAllLeads = shouldShowAllLeads(this.props.curretUser)
    let showAllLeadsParam = '',
      userHierarchy = '',
      teamHierarchy = ''
    if (showAllLeads) {
      showAllLeadsParam = `&showAllLeads=${showAllLeads}`
    }
    if (buyRentQuickFilter.userHierchary) {
      userHierarchy = `&userHierarchy=${buyRentQuickFilter.userHierchary.id}`
    }
    if (buyRentQuickFilter.teamHierarchy) {
      teamHierarchy = `&teamHierarchy=${buyRentQuickFilter.teamHierarchy.id}`
    }

    let url = `${config.apiPath}/api/v1/leads?type=${
      buyRentQuickFilter['type'] !== undefined ? buyRentQuickFilter['type'].value : ''
    }&subtype=${
      buyRentQuickFilter['subtype'] !== undefined ? buyRentQuickFilter['subtype'].value : ''
    }&assignedTo=${
      buyRentQuickFilter['assignedTo'] !== undefined ? buyRentQuickFilter['assignedTo'].value : ''
    }&origin=${
      buyRentQuickFilter['origin'] !== undefined ? buyRentQuickFilter['origin'].value : ''
    }&fromDate=${
      buyRentQuickFilter['startDate'] !== undefined
        ? moment(buyRentQuickFilter['startDate']).format('YYYY-MM-DD')
        : ''
    }&aging=${
      buyRentQuickFilter['aging'] !== undefined ? buyRentQuickFilter['aging'].value : ''
    }&assignToMe=${
      buyRentQuickFilter['assignToMe'] !== undefined ? buyRentQuickFilter['assignToMe'].value : ''
    }&toDate=${
      buyRentQuickFilter['endDate'] !== undefined
        ? moment(buyRentQuickFilter['endDate']).format('YYYY-MM-DD')
        : ''
    }&id=${buyRentQuickFilter['id'] !== undefined ? buyRentQuickFilter['id'] : ''}&leadsAssigned=${
      buyRentQuickFilter['leadsAssigned'] !== undefined
        ? buyRentQuickFilter['leadsAssigned'].value
        : ''
    }&createdBy=${
      buyRentQuickFilter['createdBy'] !== undefined ? buyRentQuickFilter['createdBy'].value : ''
    }&clientName=${
      buyRentQuickFilter['clientName'] !== undefined ? buyRentQuickFilter['clientName'] : ''
    }&hasBooking=${this.hasBooking()}&team=${
      buyRentQuickFilter['team'] !== undefined ? buyRentQuickFilter['team'].value : ''
    }&organization=${
      buyRentQuickFilter['organization'] !== undefined
        ? buyRentQuickFilter['organization'].value
        : ''
    }&cityId=${
      buyRentQuickFilter['cityId'] !== undefined ? buyRentQuickFilter['cityId'].value : ''
    }&areaGroupId=${
      buyRentQuickFilter['areaId'] !== undefined ? buyRentQuickFilter['areaId'].value : ''
    }&wantedId=${
      buyRentQuickFilter['wantedId'] !== undefined ? buyRentQuickFilter['wantedId'] : ''
    }&countryCode=${
      buyRentQuickFilter['country'] !== undefined ? buyRentQuickFilter['country'].value : ''
    }&emailId=${
      buyRentQuickFilter['emailId'] !== undefined ? buyRentQuickFilter['emailId'] : ''
    }&phoneNo=${buyRentQuickFilter['phoneNo'] !== undefined ? buyRentQuickFilter['phoneNo'] : ''}&${
      buyRentQuickFilter['purpose'] !== undefined
        ? `purpose[]=${buyRentQuickFilter['purpose'].value}`
        : 'pupose='
    }${showAllLeadsParam}${userHierarchy}${teamHierarchy}`

    if (statusValue && statusValue.value) url += `&status=${statusValue.value}`


    if (Boolean(groupManager)) url += `&groupManager=true`

    // apply quick filter values
    let quickFilter = this.props.quickFilter

    if (quickFilter)
      quickFilter.map((filter) => {
        if (filter.name === 'assignToMe') url = `${url}&${filter.name}=${filter.value}`
        else url = `${url}&${filter.name}[]=${filter.value}`
      })

    url = `${url}${sortingOrder}`

    this.setState({
      url,
      searchCompare: true,
      filterResponse: true,
    })

    this.props.fetchRentAndLeadFilter(
      url,
      undefined,
      () => {
        this.setState({
          clearButton: false,
          filter: true,
        })
      },
      buyRentQuickFilter && buyRentQuickFilter['status']
    )

    let filterCount =
      buyRentQuickFilter !== undefined &&
      buyRentQuickFilter['sorting'] !== null &&
      !buyRentQuickFilter['sorting'] &&
      Object.keys(buyRentQuickFilter).length

    if (
      (buyRentQuickFilter && buyRentQuickFilter['startDate']) ||
      (buyRentQuickFilter && buyRentQuickFilter['endDate'])
    ) {
      filterCount = filterCount - 1
    }

    //
    this.props.setMoreFilter({
      ...this.props.moreFilter,
      showModal: false,
      filterCount,
      activeFilter:
        buyRentQuickFilter !== undefined && Object.keys(buyRentQuickFilter).length ? true : false,
    })
  }

  fetchAllUsers = () => {
    axios.get(`${config.apiPath}/api/user/all/names?all=true`).then((res) => {
      this.setState({
        usersAll: res.data,
      })
    })
  }
  fetchAllAgents = (excludeTerminalUsers = null) => {
    const role_id = localStorage.getItem('roleId')
    const isGroupManager = localStorage.getItem('groupManager')

    let groupManagerParam = ''
    if (
      (isGroupManager && isGroupManager === true) ||
      isGroupManager.toLocaleLowerCase() === 'true'
    ) {
      groupManagerParam = `&groupManager=true`
    }

    let url = `${config.apiPath}/api/role/sub-users?roleId=${role_id}${groupManagerParam}`
    if (excludeTerminalUsers) url += '&excludeTerminal=true'

    axios.get(url).then((res) => {
      let array = excludeTerminalUsers ? this.state.nonTerminalAgents : []
      res &&
        res.data &&
        res.data.length > 0 &&
        res.data.filter((item) => {
          return array.push({
            id: item.id,
            label: item.firstName + ' ' + item.lastName,
            value: item.firstName + ' ' + item.lastName,
          })
        })

      this.setState({ [excludeTerminalUsers ? 'nonTerminalAgents' : 'allAgents']: array })
    })
  }

  fetchCities = () => {
    axios.get(`${config.apiPath}/api/cities?all=true`).then((res) => {
      this.setState({
        cityForFilter: this.arrayForSelectFilter(res.data),
      })
    })
  }

  reOpenToggle = (id) => {
    this.setState({
      reOpenModal: !this.state.reOpenModal,
      itemId: id,
    })
  }

  fetchOrigin = () => {
    axios.get(`${config.apiPath}/api/leads/project/origins?rcmLead=true`).then((res) => {
      this.setState({
        origin: res.data,
      })
    })
  }

  handleLeadType = (value) => {
    this.setState({
      leadType: value,
      filter: true,
      filterClearButton: true,
    })
  }

  handleStatusSearch = (value, name) => {
    this.setState({
      statusValue: value,
      statusValueName: name,
      filter: true,
      filterClearButton: true,
    })
  }

  capitalize_Words = (str) => {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    })
  }

  clearButton = (current, pageSize) => {
    this.setState({
      url: '',
      filter: false,
    })

    this.props.history.push(`${window.location.pathname}?page=${1}&pageSize=${config.pageSize}`)

    this.props.setBuyRentQuickFilters({})
    this.setState({ filters: {}, statusValue: {} })

    this.props.setMoreFilter({
      ...this.props.moreFilter,
      activeFilter: false,
      filterCount: 0,
    })

    // apply quick filter values
    let quickFilter = this.props.quickFilter
    let showAllLeads = shouldShowAllLeads(this.props.curretUser)
    let showAllLeadsParam = ''
    if (showAllLeads) {
      showAllLeadsParam = `&showAllLeads=${showAllLeads}`
    }

    let url = `${config.apiPath}/api/v1/leads?hasBooking=${this.hasBooking()}${showAllLeadsParam}`
    if (quickFilter) {
      let sortingOrder =
        this.props.leadOrder && this.props.leadOrder.value ? this.props.leadOrder.value : ''

      quickFilter.map((filter) => {
        if (filter.name === 'assignToMe') url = `${url}&${filter.name}=${filter.value}`
        else url = `${url}&${filter.name}[]=${filter.value}`
      })

      url = `${url}${sortingOrder}`
    } else this.props.history.push(`${this.props.location.pathname}?page=1&pageSize=${config.pageSize}`)

    if (this.state.searchCompare) {
      this.props.fetchRentAndLeadFilter(url, null, null, { status: '' })
      // this.props.fetchLeads(undefined, undefined, undefined, this.hasBooking())
      this.setState({
        searchCompare: false,
      })
    }
  }

  handleQueryChange = (current, pageSize) => {
    const { buyRentQuickFilter } = this.props
    const offsetValue = parseInt(current - 1) * pageSize
    this.setState({ currentPage: current })
    let route = `${
      this.props.location.pathname === BuyRentLeadUrl ? BuyRentLeadUrl : BuyRentDealUrl
    }?page=${current}&pageSize=${pageSize}`
    let queryValue = { offset: offsetValue, pageSize }
    const { history } = this.props
    history && history.push(route)

    if (this.props.onChangePage) {
      this.props.onChangePage(current, pageSize)
    } else if (this.state.filter) {
      this.props.fetchRentAndLeadFilter(
        this.state.url,
        null,
        null,
        buyRentQuickFilter && buyRentQuickFilter['status']
      )
    } else {
      this.props.fetchBRLeads(
        queryValue,
        this.props.leadOrder && this.props.leadOrder.value,
        undefined,
        this.hasBooking(),
        this.props.clientLeadModal,
        this.props.tabUrl
      )
    }
  }

  // cityFilterHandle = (e) => {
  //   this.setState({
  //     cityIdForFilter: e,
  //   })
  // }

  textItemRender = (current, type, element) => {
    if (type === 'prev') {
      return 'Prev'
    }
    if (type === 'next') {
      return 'Next'
    }
    return element
  }

  filterChangeForTeam = (e) => {
    this.setState({
      searchByTeam: e,
      newActiveFilterNameList: [...this.state.newActiveFilterNameList, 'team'],
    })
  }

  agingChange = (e) => {
    this.setState({
      searchByAging: e,
      newActiveFilterNameList: [...this.state.newActiveFilterNameList, 'age'],
    })
  }
  reloadLeadHandler = () => {
    this.setState(
      {
        countDiffer: 0,
        initialCount: this.state.newCount,
      },
      () => {
        this.handleQueryChange(1, this.props.pageSize)
      }
    )
  }

  viewTimeline = (id) => {
    this.setState({
      timelineModalActive: true,
      timelineLoading: true,
    })
    axios.get(`${config.apiPath}/api/leads/timeLine?leadId=${id}`).then((res) => {
      this.setState({
        dataForTimelinePopup: res.data,
        timelineLoading: false,
      })
    })
  }

  viewTimelineModalClose = () => {
    this.setState({
      timelineModalActive: false,
      dataForTimelinePopup: [],
    })
  }

  searchOrgFilter = (e) => {
    this.setState({
      searchByOrg: e,
      newActiveFilterNameList: [...this.state.newActiveFilterNameList, 'organization'],
    })
  }

  fetchAllOrganizations = (info) => {
    axios.get(`${config.apiPath}/api/user/organizations?limit=2`).then((res) => {
      var object = []
      res.data.rows &&
        res.data.rows.length &&
        res.data.rows.filter((item) => {
          return object.push({ label: item.name, value: item.name })
        })
      this.setState({
        orgFilterData:
          info && info.organizationName != null
            ? [{ label: info.organizationName, value: info.organizationName }]
            : object,
      })
    })
  }

  getCountriesNameWithCountriesCode = () => {
    axios.get(`${config.apiPath}/api/user/getCountryCodes`).then((res) => {
      let countriesListWihCode = res.data.map((item) => {
        return {
          label: item.name,
          value: item.phone,
        }
      })
      this.setState({ countriesListWihCountriesCode: countriesListWihCode })
    })
  }

  setSelectedLeadId = (id) => {
    this.setState({
      leadId: id,
    })
  }
  updatePropState = (id) => {
    this.setState({
      itemId: id,
    })
  }
  render() {
    const {
      brLeads,
      loading,
      page,
      fetchRentAndLeadFilter,
      pageSize,
      moreFilter,
      setMoreFilter,
      popup,
      buyRentQuickFilter,
    } = this.props

    if (this.props.location.pathname === BuyRentDealUrl) {
      if (!this.userBuyRentDeals) {
        return null
      }
    }
    if (this.props.location.pathname === BuyRentLeadUrl) {
      if (!this.userBuyRentLeads && !this.props.popup) {
        return null
      }
    }

    const {
      startDate,
      endDate,
      focusedInput,
      filterClearButton,
      filterId,
      wantedId,
      emailId,
      PhoneNo,
      country,
      statusValue,
      usersAll,
      assignedValue,
      origin,
      fitlerOriginValue,
      leadType,
      propertySubTypeFilterValue,
      filterResponse,
      reOpenActionEnable,
      assignedLeadsValue,
      clientNameValue,
      createdByValue,
      clientNames,
      showRows,
      organizationsDetail,
      showMoreActions,
      teamDataForFilter,
      countDiffer,
      timelineModalActive,
      dataForTimelinePopup,
      timelineLoading,
      orgFilterData,
      allCities,
      allAreas,
      clearButton,
      filters,
      currentPage,
      countriesListWihCountriesCode,
    } = this.state

    const fieldHeight = {
      control: (base) => ({
        ...base,
        height: 35,
        minHeight: 35,
        border: '1px solid rgb(204, 204, 204)',
        boxShadow: 'none',
        '&:hover': {
          border: '1px solid rgb(204, 204, 204)',
        },
      }),
      singleValue: (base) => ({
        ...base,
        color: 'hsl(0,0%,50%)',
      }),
    }

    const customStyles = {
      control: (base) => ({
        ...base,
        height: 35,
        minHeight: 35,
        '&:hover': {
          borderColor: '#046ef2',
          color: '#046ef2',
        },
        '&': {
          borderColor: '#046ef2',
          color: '#046ef2',
        },
      }),
      dropdownIndicator: (base) => ({
        ...base,
        color: 'inherit',
      }),
      indicatorSeparator: (base) => ({
        ...base,
        backgroundColor: '#046ef2',
      }),
      singleValue: (base) => ({
        ...base,
        color: 'inherit',
      }),
    }

    const propertyType = [
      { value: 'residential', name: 'Residential' },
      { value: 'plot', name: 'Plot' },
      { value: 'commercial', name: 'Commercial' },
    ]

    const propertySubType = [
      { value: 'house', name: 'House' },
      { value: 'guest house', name: 'Guest house' },
      { value: 'residential', name: 'Residential' },
      { value: 'apartment', name: 'Apartment' },
      { value: 'upper portion', name: 'Upper portion' },
      { value: 'lower portion', name: 'Lower portion' },
      { value: 'farm house', name: 'Farm house' },
      { value: 'room', name: 'Room' },
      { value: 'penthouse', name: 'Penthouse' },
      { value: 'hotel suites', name: 'Hotel suites' },
      { value: 'basement', name: 'Basement' },
      { value: 'annexe', name: 'Annexe' },
      { value: 'hostel', name: 'Hostel' },
      { value: 'other', name: 'Other' },
      { value: 'residential plot', name: 'Residential Plot' },
      { value: 'commercial plot', name: 'Commercial Plot' },
      { value: 'agricultural land', name: 'Agricultural Land' },
      { value: 'industrial land', name: 'Industrial Land' },
      { value: 'plot file', name: 'Plot File' },
      { value: 'farmhouse plot', name: 'Farmhouse Plot' },
      { value: 'office', name: 'Office' },
      { value: 'shop', name: 'Shop' },
      { value: 'warehouse', name: 'Warehouse' },
      { value: 'factory', name: 'Factory' },
      { value: 'building', name: 'Building' },
      { value: 'theatre', name: 'Theatre' },
      { value: 'Gym', name: 'Gym' },
      { value: 'Food Court', name: 'Food Court' },
      { value: 'other', name: 'Other' },
      { value: 'hall', name: 'Hall' },
      { value: 'land', name: 'Land' },
      { value: 'plaza', name: 'Plaza' },
    ]

    const propertyTypeFilter = propertyType.map((item) => ({
      label: item.name,
      value: item.value,
    }))

    const propertySubTypeFilter = propertySubType.map((item) => ({
      label: item.name,
      value: item.value,
    }))

    const usersFilterOption =
      usersAll &&
      usersAll.map((item) => ({
        label: item.firstName + ' ' + item.lastName,
        value: item.firstName + ' ' + item.lastName,
      }))

    const originFilterOption =
      origin &&
      origin.map((item) => ({
        label: item.origins,
        value: item.origins,
      }))

    const assignedFilterOption = [
      { label: 'Assigned to my team', value: 'added' },
      { label: 'Created by my team', value: 'created' },
      { label: 'Shared', value: 'shared' },
    ]

    const clientNameFilterOptions =
      clientNames &&
      clientNames.result &&
      clientNames.result.map((item) => ({
        label: item.first_name + ' ' + item.last_name,
        value: item.first_name + ' ' + item.last_name,
      }))
    const createdByFilterOptions =
      usersAll &&
      usersAll.map((item) => ({
        label: item.firstName + ' ' + item.lastName,
        value: item.id,
      }))

    return (
      <>
        <div
          className="dash-container br-lead-page-wrap"
          onClick={() => this.setState({ showMoreActions: true })}
        >
          {!popup && countDiffer > 0 ? (
            <div className="refreshPage-main-wrap">
              <div className="message-wrap">
                <p>
                  {countDiffer == 1
                    ? 'There is 1 new lead....'
                    : `There are ${countDiffer} new leads....`}
                  <span className="reload-btn" onClick={() => this.reloadLeadHandler()}>
                    Reload
                  </span>
                </p>
              </div>
            </div>
          ) : null}
          {!popup && (
            <Modal
              isOpen={moreFilter.showModal}
              toggle={() =>
                setMoreFilter({
                  ...moreFilter,
                  showModal: false,
                })
              }
              style={{ minWidth: 1100 }}
              className="device-modal"
            >
              <ModalBody>
                <div className={'row'}>
                  <div className={'col-sm-12'}>
                    <h4>More Filters</h4>
                    <div className="close-icon-container">
                      <i
                        style={{ top: 5 }}
                        className="mt-1 fal fa-times-circle close-icon"
                        onClick={() =>
                          setMoreFilter({
                            ...moreFilter,
                            showModal: false,
                          })
                        }
                      ></i>
                    </div>
                  </div>
                  <div className="user-form">
                    <div className="row ">
                      <div className="col-3 mt-2">
                        <Select
                          className="customFilterColor"
                          value={
                            filters && filters['purpose'] !== undefined
                              ? filters['purpose']
                              : buyRentQuickFilter && buyRentQuickFilter['purpose'] !== undefined
                              ? buyRentQuickFilter['purpose']
                              : null
                          }
                          styles={
                            buyRentQuickFilter && buyRentQuickFilter['purpose'] !== undefined
                              ? customStyles
                              : fieldHeight
                          }
                          options={rentTypeOptions}
                          noOptionsMessage={() => 'No Lead Type Found'}
                          placeholder="Lead Type"
                          onChange={(value) =>
                            this.setState({
                              filters: { ...filters, purpose: value },
                              activeLeadTypeFilterButton: value.value,
                              filterClearButton: true,
                              leadType: value,
                              filter: true,
                              newActiveFilterNameList: [
                                ...this.state.newActiveFilterNameList,
                                'lead_type',
                              ],
                            })
                          }
                        />
                      </div>
                      <div className="col-3 mt-2">
                        <Select
                          className="customFilterColor"
                          value={
                            filters && filters['type'] !== undefined
                              ? filters['type']
                              : buyRentQuickFilter && buyRentQuickFilter['type'] !== undefined
                              ? buyRentQuickFilter['type']
                              : null
                          }
                          styles={
                            buyRentQuickFilter && buyRentQuickFilter['type'] !== undefined
                              ? customStyles
                              : fieldHeight
                          }
                          options={propertyTypeFilter}
                          noOptionsMessage={() => 'No property type found'}
                          placeholder="Property Type"
                          onChange={(value) =>
                            this.setState({
                              filters: { ...filters, type: value },
                              activePropertyTypeFilterButton: value.value,
                              filterClearButton: true,
                              propertyTypeValue: value,
                              filter: true,
                              newActiveFilterNameList: [
                                ...this.state.newActiveFilterNameList,
                                'property_type',
                              ],
                            })
                          }
                        />
                      </div>
                      <div className="col-3 mt-2">
                        <Select
                          className="customFilterColor"
                          value={
                            filters && filters['subtype'] !== undefined
                              ? filters['subtype']
                              : buyRentQuickFilter && buyRentQuickFilter['subtype'] !== undefined
                              ? buyRentQuickFilter['subtype']
                              : null
                          }
                          styles={
                            buyRentQuickFilter && buyRentQuickFilter['subtype'] !== undefined
                              ? customStyles
                              : fieldHeight
                          }
                          options={propertySubTypeFilter}
                          noOptionsMessage={() => 'No property subtype found'}
                          placeholder="Prop Subtype"
                          onChange={(value) =>
                            this.setState({
                              filters: { ...filters, subtype: value },
                              activePropertySubFilterButton: value.value,
                              filterClearButton: true,
                              propertySubTypeFilterValue: value,
                              filter: true,
                              newActiveFilterNameList: [
                                ...this.state.newActiveFilterNameList,
                                'sub_type',
                              ],
                            })
                          }
                        />
                      </div>
                      <div className="col-3 mt-2">
                        <input
                          type="number"
                          placeholder="ID"
                          className={
                            buyRentQuickFilter && buyRentQuickFilter['id'] !== undefined
                              ? 'customStyle'
                              : 'Id_textfield'
                          }
                          value={filters && filters.id}
                          onChange={(e) => {
                            let value = e.target.value
                            this.setState({
                              filters: { ...filters, id: value },
                              filterActiveButton: value.value,
                              filterClearButton: true,
                              filterId: value,
                              filter: true,
                              newActiveFilterNameList: [
                                ...this.state.newActiveFilterNameList,
                                'leadId',
                              ],
                            })
                          }}
                        />
                      </div>
                      <div className="col-3 mt-2">
                        <Select
                          className="customFilterColor"
                          styles={
                            buyRentQuickFilter && buyRentQuickFilter['origin'] !== undefined
                              ? customStyles
                              : fieldHeight
                          }
                          options={originFilterOption}
                          value={
                            filters && filters['origin'] !== undefined
                              ? filters['origin']
                              : buyRentQuickFilter && buyRentQuickFilter['origin'] !== undefined
                              ? buyRentQuickFilter['origin']
                              : null
                          }
                          noOptionsMessage={() => 'No Origin found'}
                          placeholder="Origin"
                          onChange={(value) => {
                            this.setState((prevState) => {
                              return {
                                filters: { ...filters, origin: value },
                                activeOriginButton: value.value,
                                filterClearButton: true,
                                fitlerOriginValue: value,
                                filter: true,
                                newActiveFilterNameList: [
                                  ...this.state.newActiveFilterNameList,
                                  'origin',
                                ],
                              }
                            })
                          }}
                        />
                      </div>
                      <div className="col-3 mt-2">
                        <div
                          className={
                            buyRentQuickFilter && buyRentQuickFilter['startDate'] !== undefined
                              ? 'selectedBorderColor'
                              : 'defaultBorderColor'
                          }
                        >
                          <DateRangePicker
                            isOutsideRange={(day) => !isInclusivelyBeforeDay(day, moment())}
                            startDate={startDate}
                            endDate={endDate}
                            startDateId="date_input_start"
                            endDateId="date_input_end"
                            onDatesChange={({ startDate: dateStart, endDate: dateEnd }) =>
                              this.setState((prevState) => {
                                return {
                                  filters: { ...filters, startDate: dateStart, endDate: dateEnd },
                                  startDate: dateStart,
                                  endDate: dateEnd,
                                  filter: true,
                                  filterClearButton: true,
                                  dateActiveButton: true,
                                  newActiveFilterNameList: [
                                    ...this.state.newActiveFilterNameList,
                                    'startEndDate',
                                  ],
                                }
                              })
                            }
                            focusedInput={focusedInput}
                            onFocusChange={(input) => this.setState({ focusedInput: input })}
                            showClearDates
                            minimumNights={0}
                          />
                        </div>
                      </div>
                      {this.props.location.pathname === BuyRentLeadUrl && (
                        <div className="col-3 mt-2">
                          <Select
                            className="customFilterColor"
                            value={
                              filters && filters['status'] !== undefined
                                ? filters['status']
                                : buyRentQuickFilter && buyRentQuickFilter['status'] !== undefined
                                ? buyRentQuickFilter['status']
                                : null
                            }
                            styles={
                              buyRentQuickFilter && buyRentQuickFilter['status'] !== undefined
                                ? customStyles
                                : fieldHeight
                            }
                            options={
                              this.state.name === 'Buy/Rent Leads'
                                ? StaticData.buyRentLeadStatusesExcludeClosedWon
                                : StaticData.buyRentLeadStatuses
                            }
                            noOptionsMessage={() => 'No Status found'}
                            placeholder="Status"
                            onChange={(value) => {
                              if (
                                value.value === 'closed_won' ||
                                value.value === 'closed_lost' ||
                                value.value === 'closed'
                              ) {
                                this.setState({
                                  reOpenActionEnable: true,
                                })
                              }
                              this.setState((prevState) => {
                                return {
                                  filters: { ...filters, status: value },
                                  activeStatusActiveButton: value.value,
                                  filterClearButton: true,
                                  filter: true,
                                  statusValue: value,
                                  newActiveFilterNameList: [
                                    ...this.state.newActiveFilterNameList,
                                    'status',
                                  ],
                                }
                              })
                            }}
                          />
                        </div>
                      )}
                      <div className="col-3 mt-2">
                        <Select
                          className="customFilterColor"
                          value={
                            filters && filters['assignedTo'] !== undefined
                              ? filters['assignedTo']
                              : buyRentQuickFilter && buyRentQuickFilter['assignedTo'] !== undefined
                              ? buyRentQuickFilter['assignedTo']
                              : null
                          }
                          styles={
                            buyRentQuickFilter && buyRentQuickFilter['assignedTo'] !== undefined
                              ? customStyles
                              : fieldHeight
                          }
                          options={this.state.allAgents}
                          noOptionsMessage={() => 'No Users found'}
                          placeholder="Assignee"
                          onChange={(value) => {
                            this.setState((prevState) => {
                              return {
                                filters: { ...filters, assignedTo: value },
                                activeAssignedActiveButton: value.value,
                                filterClearButton: true,
                                filter: true,
                                assignedValue: value,
                                newActiveFilterNameList: [
                                  ...this.state.newActiveFilterNameList,
                                  'assignee',
                                ],
                              }
                            })
                          }}
                        />
                      </div>
                      <div className="col-3 mt-2">
                        <Select
                          className="customFilterColor"
                          value={
                            filters && filters['leadsAssigned'] !== undefined
                              ? filters['leadsAssigned']
                              : buyRentQuickFilter &&
                                buyRentQuickFilter['leadsAssigned'] !== undefined
                              ? buyRentQuickFilter['leadsAssigned']
                              : null
                          }
                          styles={
                            buyRentQuickFilter && buyRentQuickFilter['leadsAssigned'] !== undefined
                              ? customStyles
                              : fieldHeight
                          }
                          options={assignedFilterOption}
                          noOptionsMessage={() => 'No options found'}
                          placeholder="Assigned Leads"
                          onChange={(value) =>
                            this.setState((prevState) => {
                              return {
                                filters: { ...filters, leadsAssigned: value },
                                activeFilterAssignedLeads: value.value,
                                filterClearButton: true,
                                assignedLeadsValue: value,
                                filter: true,
                                newActiveFilterNameList: [
                                  ...this.state.newActiveFilterNameList,
                                  'assigned',
                                ],
                              }
                            })
                          }
                        />
                      </div>
                      <div className="col-3 mt-2 ">
                        <input
                          type="text"
                          placeholder="Client Name"
                          className={
                            buyRentQuickFilter && buyRentQuickFilter['clientName'] !== undefined
                              ? 'customStyle'
                              : 'Id_textfield'
                          }
                          value={
                            filters && filters['clientName'] !== undefined
                              ? filters['clientName']
                              : buyRentQuickFilter && buyRentQuickFilter['clientName'] !== undefined
                              ? buyRentQuickFilter['clientName']
                              : null
                          }
                          onChange={(e) => {
                            this.setState({
                              filters: { ...filters, clientName: e.target.value },
                              clientNameValue: e.target.value,
                              newActiveFilterNameList: [
                                ...this.state.newActiveFilterNameList,
                                'client',
                              ],
                            })
                          }}
                        />
                      </div>
                      <div className="col-3 mt-2">
                        <Select
                          className="customFilterColor"
                          value={
                            filters && filters['createdBy'] !== undefined
                              ? filters['createdBy']
                              : buyRentQuickFilter && buyRentQuickFilter['createdBy'] !== undefined
                              ? buyRentQuickFilter['createdBy']
                              : null
                          }
                          styles={
                            buyRentQuickFilter && buyRentQuickFilter['createdBy'] !== undefined
                              ? customStyles
                              : fieldHeight
                          }
                          options={createdByFilterOptions}
                          noOptionsMessage={() => 'No options found'}
                          placeholder="Created By"
                          onChange={(value) =>
                            this.setState((prevState) => {
                              return {
                                filters: { ...filters, createdBy: value },
                                activeFilterCreatedBy: value.value,
                                filterClearButton: true,
                                createdByValue: value,
                                filter: true,
                                newActiveFilterNameList: [
                                  ...this.state.newActiveFilterNameList,
                                  'created_by',
                                ],
                              }
                            })
                          }
                        />
                      </div>
                      <div className="col-3 mt-2">
                        <Select
                          className="customFilterColor"
                          value={
                            filters && filters['team'] !== undefined
                              ? filters['team']
                              : buyRentQuickFilter && buyRentQuickFilter['team'] !== undefined
                              ? buyRentQuickFilter['team']
                              : null
                          }
                          styles={
                            buyRentQuickFilter && buyRentQuickFilter['team'] !== undefined
                              ? customStyles
                              : fieldHeight
                          }
                          options={teamDataForFilter}
                          noOptionsMessage={() => 'No options found'}
                          placeholder="Search by Team"
                          onChange={(value) => {
                            this.setState({ filters: { ...filters, team: value } })
                          }}
                        />
                      </div>
                      <div className="col-3 mt-2">
                        <Select
                          className="customFilterColor"
                          value={
                            filters && filters['aging'] !== undefined
                              ? filters['aging']
                              : buyRentQuickFilter && buyRentQuickFilter['aging'] !== undefined
                              ? buyRentQuickFilter['aging']
                              : null
                          }
                          styles={
                            buyRentQuickFilter && buyRentQuickFilter['aging'] !== undefined
                              ? customStyles
                              : fieldHeight
                          }
                          options={StaticData.aging}
                          noOptionsMessage={() => 'No options found'}
                          placeholder="Search by Aging"
                          onChange={(value) => {
                            this.setState({ filters: { ...filters, aging: value } })
                          }}
                        />
                      </div>

                      <div className="col-3 mt-2">
                        <Select
                          className="customFilterColor"
                          value={
                            filters && filters['organization'] !== undefined
                              ? filters['organization']
                              : buyRentQuickFilter &&
                                buyRentQuickFilter['organization'] !== undefined
                              ? buyRentQuickFilter['organization']
                              : null
                          }
                          styles={
                            buyRentQuickFilter && buyRentQuickFilter['organization'] !== undefined
                              ? customStyles
                              : fieldHeight
                          }
                          options={orgFilterData}
                          noOptionsMessage={() => 'No options found'}
                          placeholder="By Organization"
                          onChange={(value) => {
                            this.setState({ filters: { ...filters, organization: value } })
                          }}
                        />
                      </div>
                      <div className="col-3 mt-2">
                        <Select
                          className="customFilterColor"
                          value={
                            filters && filters['cityId'] !== undefined
                              ? filters['cityId']
                              : buyRentQuickFilter && buyRentQuickFilter['cityId'] !== undefined
                              ? buyRentQuickFilter['cityId']
                              : null
                          }
                          styles={
                            buyRentQuickFilter && buyRentQuickFilter['cityId'] !== undefined
                              ? customStyles
                              : fieldHeight
                          }
                          options={allCities}
                          noOptionsMessage={() => 'No options found'}
                          placeholder="City"
                          onChange={(value) => {
                            this.setState({ filters: { ...filters, cityId: value } })
                          }}
                        />
                      </div>
                      <div className="col-3 mt-2">
                        <Select
                          className="customFilterColor"
                          value={
                            filters && filters['areaId'] !== undefined
                              ? filters['areaId']
                              : buyRentQuickFilter && buyRentQuickFilter['areaId'] !== undefined
                              ? buyRentQuickFilter['areaId']
                              : null
                          }
                          styles={
                            buyRentQuickFilter && buyRentQuickFilter['areaId'] !== undefined
                              ? customStyles
                              : fieldHeight
                          }
                          options={allAreas}
                          noOptionsMessage={() => 'No options found'}
                          placeholder="Area Groups"
                          onChange={(value) => {
                            this.setState({ filters: { ...filters, areaId: value } })
                          }}
                        />
                      </div>
                      <div className="col-3 mt-2">
                        <input
                          type="number"
                          placeholder="Wanted ID"
                          className={
                            buyRentQuickFilter && buyRentQuickFilter['wantedId'] !== undefined
                              ? 'customStyle'
                              : 'Id_textfield'
                          }
                          value={
                            filters && filters['wantedId'] !== undefined
                              ? filters['wantedId']
                              : buyRentQuickFilter && buyRentQuickFilter['wantedId'] !== undefined
                              ? buyRentQuickFilter['wantedId']
                              : null
                          }
                          onChange={(e) => {
                            let value = e.target.value
                            this.setState({
                              filters: { ...filters, wantedId: value },
                              filterActiveButton: value.value,
                              filterClearButton: true,
                              wantedId: value,
                              filter: true,
                              newActiveFilterNameList: [
                                ...this.state.newActiveFilterNameList,
                                'wanted_id',
                              ],
                            })
                          }}
                        />
                      </div>
                      {!this.props.isTerminalUser && (
                        <div className="col-3 mt-2">
                          <Select
                            className="customFilterColor"
                            value={
                              filters && filters['teamHierarchy'] !== undefined
                                ? filters['teamHierarchy']
                                : buyRentQuickFilter &&
                                  buyRentQuickFilter['teamHierarchy'] !== undefined
                                ? buyRentQuickFilter['teamHierarchy']
                                : null
                            }
                            styles={
                              buyRentQuickFilter &&
                              buyRentQuickFilter['teamHierarchy'] !== undefined
                                ? customStyles
                                : fieldHeight
                            }
                            options={this.state.nonTerminalAgents}
                            noOptionsMessage={() => 'No options found'}
                            placeholder="Advisor's Team"
                            onChange={(value) => {
                              this.setState({ filters: { ...filters, teamHierarchy: value } })
                            }}
                          />
                        </div>
                      )}
                      {!this.props.isTerminalUser && (
                        <div className="col-3 mt-2">
                          <Select
                            className="customFilterColor"
                            value={
                              filters && filters['userHierchary'] !== undefined
                                ? filters['userHierchary']
                                : buyRentQuickFilter &&
                                  buyRentQuickFilter['userHierchary'] !== undefined
                                ? buyRentQuickFilter['userHierchary']
                                : null
                            }
                            styles={
                              buyRentQuickFilter &&
                              buyRentQuickFilter['userHierchary'] !== undefined
                                ? customStyles
                                : fieldHeight
                            }
                            options={this.state.nonTerminalAgents}
                            noOptionsMessage={() => 'No options found'}
                            placeholder="Advisor's Hierarchy"
                            onChange={(value) => {
                              this.setState({
                                filters: { ...filters },
                              })
                              this.setState({ filters: { ...filters, userHierchary: value } })
                            }}
                          />
                        </div>
                      )}
                      <div className="col-3 mt-2">
                        <Select
                          className="customFilterColor"
                          value={
                            filters && filters['country'] !== undefined
                              ? filters['country']
                              : buyRentQuickFilter && buyRentQuickFilter['country'] !== undefined
                              ? buyRentQuickFilter['country']
                              : null
                          }
                          styles={
                            buyRentQuickFilter && buyRentQuickFilter['country'] !== undefined
                              ? customStyles
                              : fieldHeight
                          }
                          options={countriesListWihCountriesCode && countriesListWihCountriesCode}
                          noOptionsMessage={() => 'No options found'}
                          placeholder="Country"
                          onChange={(value) => {
                            this.setState({ filters: { ...filters, country: value } })
                          }}
                        />
                      </div>
                      <div className="col-3 mt-2">
                        <input
                          type="email"
                          placeholder="Client Email ID"
                          className={
                            buyRentQuickFilter && buyRentQuickFilter['emailId'] !== undefined
                              ? 'customStyle'
                              : 'Id_textfield'
                          }
                          value={
                            filters && filters['emailId'] !== undefined
                              ? filters['emailId']
                              : buyRentQuickFilter && buyRentQuickFilter['emailId'] !== undefined
                              ? buyRentQuickFilter['emailId']
                              : null
                          }
                          onChange={(e) => {
                            let value = e.target.value
                            this.setState({
                              filters: { ...filters, emailId: value },
                              filterActiveButton: value.value,
                              filterClearButton: true,
                              emailId: value,
                              filter: true,
                              newActiveFilterNameList: [
                                ...this.state.newActiveFilterNameList,
                                'email_id',
                              ],
                            })
                          }}
                        />
                      </div>
                      <div className="col-3 mt-2">
                        <input
                          type="number"
                          placeholder="Client Phone #"
                          className={
                            buyRentQuickFilter && buyRentQuickFilter['phoneNo'] !== undefined
                              ? 'customStyle'
                              : 'Id_textfield'
                          }
                          value={
                            filters && filters['phoneNo'] !== undefined
                              ? filters['phoneNo']
                              : buyRentQuickFilter && buyRentQuickFilter['phoneNo'] !== undefined
                              ? buyRentQuickFilter['phoneNo']
                              : null
                          }
                          onChange={(e) => {
                            let value = e.target.value
                            this.setState({
                              filters: { ...filters, phoneNo: value },
                              filterActiveButton: value.value,
                              filterClearButton: true,
                              phoneNo: value,
                              filter: true,
                              newActiveFilterNameList: [
                                ...this.state.newActiveFilterNameList,
                                'phone_no',
                              ],
                            })
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <hr></hr>
                <div className={'d-flex'} style={{ gridGap: 10 }}>
                  <div>
                    <Button
                      text="Search"
                      buttonColor="#026ff2"
                      textColor="white"
                      action="search"
                      width="80px"
                      className="searchBtn"
                      onClick={() => {
                        if (!this.props.popup) {
                          this.props.history.push(
                            `${window.location.pathname}?page=${1}&pageSize=${config.pageSize}`
                          )
                        }
                        this.setState({ currentPage: 1 })

                        if (
                          statusValue.value !== undefined ||
                          assignedValue.value !== undefined ||
                          fitlerOriginValue.value !== undefined ||
                          clientNameValue !== '' ||
                          startDate !== null ||
                          endDate !== null ||
                          filterId !== undefined ||
                          assignedLeadsValue.value !== undefined ||
                          createdByValue.value !== undefined
                        ) {
                          this.fetchFiltersData(filters ? filters : buyRentQuickFilter)
                          this.props.setBuyRentQuickFilters(filters)
                        } else {
                          this.props.setMoreFilter({
                            ...this.props.moreFilter,
                            showModal: false,
                            activeFilter: false,
                            filterCount: 0,
                          })
                        }
                      }}
                    ></Button>
                  </div>
                  <div>
                    <Button
                      text="Clear"
                      buttonColor="#ffffff"
                      action="details"
                      className="detailsBtn"
                      textColor="#2e96f5"
                      width="80px"
                      disabled={clearButton}
                      onClick={() => this.clearButton()}
                    ></Button>
                  </div>
                </div>
              </ModalBody>
            </Modal>
          )}
          {loading ? (
            <Load title="Buy/Rent Leads" />
          ) : (
            <>
              {brLeads === null || (brLeads && brLeads.rows && brLeads.rows.length === 0) ? (
                <div style={{ marginTop: '60px', marginBottom: 50 }} className="text-center">
                  <h3 className="dimgray">No Leads Found</h3>
                </div>
              ) : (
                <>
                  <div>
                    <BuyAndRentLeadInner
                      customer={this.props.customer}
                      otherleads={this.props.otherleads}
                      popup={popup}
                      leads={brLeads}
                      pageType={this.props.location.pathname}
                      viewTimeline={this.viewTimeline}
                      fetchFiltersData={(url, pageSize) =>
                        fetchRentAndLeadFilter(this.state.url, this.state.pageSize)
                      }
                      organizationsDetail={organizationsDetail}
                      filterResponse={filterResponse}
                      tabUrl={this.props.tabUrl}
                      tab={this.props.tab}
                      activeStatusActiveButton={reOpenActionEnable}
                      filterClearButton={filterClearButton}
                      showRows={showRows}
                      showMoreActions={showMoreActions}
                      updatePropState={this.updatePropState}
                      setSelectedLeadId={this.setSelectedLeadId}
                      clientLeadModal={this.props.clientLeadModal}
                      refreshLead={() => {
                        if (this.props.otherleads) this.props.refreshLead()
                        else this.handleQueryChange(currentPage, 50)
                      }}
                      onRowClick={(rowData) => {
                        this.props.onRowClick && this.props.onRowClick(rowData)
                      }}
                    />

                    {!popup && (
                      <div className="timelineModal">
                        <TimlinesModal
                          active={timelineModalActive}
                          dataForTimelinePopup={dataForTimelinePopup}
                          timelineLoading={timelineLoading}
                          viewTimelineModalClose={this.viewTimelineModalClose}
                        />
                      </div>
                    )}

                    {(!popup || (this.props.fromPage && this.props.fromPage === 'leadSelection')) &&
                      brLeads &&
                      brLeads.count !== 0 && (
                        <div className="row mt-3 mb-2">
                          <div className="col-md-4 totalCountAlignment">
                            <span className="totalCountStyle">
                              Showing
                              <span className="totalCountSubStyle">
                                {((popup ? this.state.currentPage : page) - 1) * config.pageSize + 1} -
                                {brLeads &&
                                brLeads.count >= (popup ? this.state.currentPage : page) * pageSize
                                  ? (popup ? this.state.currentPage : page) * pageSize
                                  : brLeads && brLeads.count}
                              </span>{' '}
                              / {brLeads && brLeads.count}
                            </span>
                          </div>
                          <div
                            className="col-md-6"
                            style={{ marginLeft: brLeads && brLeads.count < 21 ? 20 : 0 }}
                          >
                            {brLeads && brLeads.count > config.pageSize && (
                              <Pagination
                                className="ps-pagination"
                                hideOnSinglePage={true}
                                pageSize={config.pageSize}
                                showPrevNextJumpers={false}
                                itemRender={this.textItemRender}
                                current={parseInt(popup ? this.state.currentPage : page, 10)}
                                onChange={this.handleQueryChange}
                                total={brLeads.count}
                                locale={local}
                              />
                            )}
                          </div>
                          <div className="col-md-2"></div>
                        </div>
                      )}
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </>
    )
  }
}

export const mapDispatchToProps = (dispatch) => {
  return {
    fetchBRLeads: (query, sortingOrder, callback, hasBooking, clientLead, tabUrl, client) =>
      dispatch(buyAndRentLeads(query, callback, hasBooking, clientLead, tabUrl, client)),
    fetchProjects: (page, url) => dispatch(fetchProjects(page, url)),
    fetchRentAndLeadFilter: (url, offset, callback, status) =>
      dispatch(fetchRentAndLeadFilter(url, offset, callback, status)),
    setTitle: (title) => dispatch(setTitle(title)),
    fetchFreshBuyRentLeads: (query, callback) => dispatch(fetchFreshBuyRentLeads(query, callback)),
    setMoreFilter: (moreFilter) => dispatch(setMoreFilter(moreFilter)),
    setBuyRentQuickFilters: (moreFilter) => dispatch(setBuyRentQuickFilters(moreFilter)),
  }
}

const mapStateToProps = (state, ownProps) => {
  const urlParams = new URLSearchParams(ownProps.location.search)
  const pageSize = urlParams.get('pageSize')
  const page = urlParams.get('page')

  return {
    page,
    pageSize,
    ...urlParams,
    brLeads:
      ownProps.fromPage && ownProps.fromPage === 'leadSelection'
        ? state.Leads.brLeads
        : ownProps.popup
        ? state.Leads.client_brleads
        : state.Leads.brLeads,
    loading: ownProps.popup ? state.Leads.cbrloading : state.Leads.brloading,
    allProjects: state.Projects.projects,
    freshBrLeads: state.Leads.freshBrLeads,
    moreFilter: state.GeneralSetting.moreFilter,
    quickFilter: state.GeneralSetting.quickFilter,
    leadOrder: state.GeneralSetting.dataSort,
    buyRentQuickFilter: state.Leads.buyRentQuickFilter,
    curretUser: state.user,
    user: state.user.userInfo,
    isTerminalUser: state.user.isTerminalUser,
    dialerLeadInformation:state.Diary.dialerLeadInformation

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BuyAndRentLead)
