import * as ActionTypes from "../actions/cmlead";

const initialState = {};

const ErrorMessage = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.ERROR_SHOW:
      return {
        ...state,
        error: action.data,
      };
    case ActionTypes.SUCCESS_MESSAGE:
      return { ...state, success: action.data };
    default: {
      return {
        ...state,
      };
    }
  }
};

export default ErrorMessage;
