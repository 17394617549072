/** @format */

import axios from 'axios'
import config from '../config'
import { showToastMsg } from '../utils/helper'

export const FETCH_PROJECTS = 'FETCH_PROJECTS'
export const FETCH_PROJECT_LOADING = 'FETCH_PROJECT_LOADING'
export const FETCH_PROJECT_SUCCESS = 'FETCH_PROJECT_SUCCESS'
export const ADD_PROJECT = 'ADD_PROJECT'
export const FETCH_FLOORS = 'FETCH_FLOORS'
export const ADD_FLOOR = 'ADD_FLOOR'
export const FETCH_UNITS = 'FETCH_UNITS'
export const ADD_UNITS = 'ADD_UNITS'
export const FETCH_DEACTIVATED_PROJECTS = 'FETCH_DEACTIVATED_PROJECTS'
export const UPDATE_PROJECT = 'UPDATE_PROJECT'
export const FAILURE_UPATE_PROJECT = 'FAILURE_UPDATE_PROJECT'
export const ADD_PROJECT_LOADER = 'ADD_PROJECT_LOADER'
export const ADD_PROJECT_LOADER_SUCCESS = 'ADD_PROJECT_LOADER_SUCCESS'
export const ADD_UNIT_LOADER = 'ADD_UNIT_LOADER'
export const ADD_UNIT_LOADER_SUCCESS = 'ADD_UNIT_LOADER_SUCCESS'
export const ADD_FLOOR_LOADER = 'ADD_FLOOR_LOADER'
export const ADD_FLOOR_LOADER_SUCCESS = 'ADD_FLOOR_LOADER_SUCCESS'
export const FETCH_PROJECT_FLOOR = 'FETCH_PROJECT_FLOOR'
export const FETCH_ALL_FLOORS_LOADER = 'FETCH_ALL_FLOORS_LOADER'
export const FETCH_ALL_FLOORS_SUCCESS = 'FETCH_ALL_FLOORS_SUCCESS'
export const FETCH_UNITS_LOADING = 'FETCH_UNITS_LOADING'
export const FETCH_UNITS_SUCCESS = 'FETCH_UNITS_SUCCESS'
export const FETCH_ALL_PROJECTS = 'FETCH_ALL_PROJECTS'
export const SET_PROJECT_TITLE = 'SET_PROJECT_TITLE'
export const FETCH_DEACTIVATED_PROJECTS_LOADER = 'FETCH_DEACTIVATED_PROJECTS_LOADER'
export const FETCH_DEACTIVATED_PROJECTS_SUCCESS = 'FETCH_DEACTIVATED_PROJECTS_SUCCESS'
export const FETCH_DELETED_UNITS = 'FETCH_DELETED_UNITS'
export const FETCH_DELETED_UNITS_LOADER = 'FETCH_DELETED_UNITS_LOADER'
export const FETCH_DELETED_UNITS_SUCCESS = 'FETCH_DELETED_UNITS_SUCCESS'
export const FETCH_DELETED_UNITS_FAILURE = 'FETCH_DELETED_UNITS_FAILURE'
export const FETCH_DELETED_FLOORS = 'FETCH_DELETED_FLOORS'
export const FETCH_DELETED_FLOORS_LOADER = 'FETCH_DELETED_FLOORS_LOADER'
export const FETCH_DELETED_FLOORS_SUCCESS = 'FETCH_DELETED_FLOORS_SUCCESS'
export const FETCH_DELETED_FLOORS_FAILURE = 'FETCH_DELETED_FLOORS_FAILURE'
export const SELECTED_IMAGES = 'SELECTED_IMAGES'
export const SELECTED_EDIT_IMAGES = 'SELECTED_EDIT_IMAGES'
export const FETCH_INVESTMENT_PRODUCTS = 'FETCH_INVESTMENT_PRODUCTS'
export const FETCH_INVESTMENT_PRODUCTS_LOADER = 'FETCH_INVESTMENT_PRODUCTS_LOADER'
export const FETCH_INVESTMENT_PRODUCTS_FAILURE = 'FETCH_INVESTMENT_PRODUCTS_FAIL'
export const ADD_INVESTMENT_PRODUCT = 'ADD_INVESTMENT_PRODUCT'
export const ADD_INVESTMENT_PRODUCT_LOADER = 'ADD_INVESTMENT_PRODUCT_LOADER'
export const ALL_PRODUCTS = 'ALL_PRODUCTS'
export const UPDATE_PRODUCT_LOADER = 'UPDATE_PRODUCT_LOADER'

export const addInvestmentProduct = (data) => {
  return (dispatch) => {
    dispatch({ type: ADD_INVESTMENT_PRODUCT_LOADER, data: true })
    axios.post(`${config.apiPath}/api/project/addProduct`, data).then((res) => {
      dispatch({ type: ADD_INVESTMENT_PRODUCT_LOADER, data: false })
      window.location.href = `/investmentProducts?page=1&pageSize=${config.pageSize}`
      return dispatch({ type: ADD_INVESTMENT_PRODUCT, data: res.data })
    })
  }
}

export const updateInvestmentProduct = (data, id) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_PRODUCT_LOADER, data: true })
    axios.patch(`${config.apiPath}/api/project/projectProduct?id=${id}`, data).then((res) => {
      dispatch({ type: UPDATE_PRODUCT_LOADER, data: false })
      window.location.href = `/investmentProducts?page=1&pageSize=${config.pageSize}`
    })
  }
}
export const fetchInvestmentProducts = (req) => {
  const urlParams = new URLSearchParams(window.location.search)
  const page = urlParams.get('page')
  var endpoint =
    `${config.apiPath}/api/project/projectProduct?pageSize=` + config.pageSize + '&page=' + page
  if (req && req.offset && req.pageSize) {
    endpoint =
      `${config.apiPath}/api/project/projectProduct?pageSize=` + config.pageSize + '&page=' + page
  } else if (req && req.all === true) {
    endpoint = `${config.apiPath}/api/project/projectProduct?all=true`
  }
  return (dispatch) => {
    dispatch({ type: FETCH_INVESTMENT_PRODUCTS_LOADER, data: true })
    axios
      .get(endpoint)
      .then(function (response) {
        dispatch({ type: FETCH_INVESTMENT_PRODUCTS_LOADER, data: false })
        if (req.all === true) {
          return dispatch({ type: ALL_PRODUCTS, data: response.data })
        } else {
          return dispatch({ type: FETCH_INVESTMENT_PRODUCTS, data: response.data })
        }
      })
      .catch(function (xhr, status, err) {
        return dispatch({ type: FETCH_INVESTMENT_PRODUCTS_FAILURE, data: xhr })
      })
  }
}

export const fetchInvestmentProductsNew = (req) => {
  const urlParams = new URLSearchParams(window.location.search)
  const page = urlParams.get('page')
  var endpoint = `${config.apiPath}/api/project/projectProduct?pageSize=` + 50 + '&page=' + page
  if (req && req.offset && req.pageSize) {
    endpoint = `${config.apiPath}/api/project/projectProduct?pageSize=` + 50 + '&page=' + page
  } else if (req && req.all === true) {
    endpoint = `${config.apiPath}/api/project/projectProduct?all=true`
  }
  return (dispatch) => {
    dispatch({ type: FETCH_INVESTMENT_PRODUCTS_LOADER, data: true })
    axios
      .get(endpoint)
      .then(function (response) {
        dispatch({ type: FETCH_INVESTMENT_PRODUCTS_LOADER, data: false })
        if (req.all === true) {
          return dispatch({ type: ALL_PRODUCTS, data: response.data })
        } else {
          return dispatch({ type: FETCH_INVESTMENT_PRODUCTS, data: response.data })
        }
      })
      .catch(function (xhr, status, err) {
        return dispatch({ type: FETCH_INVESTMENT_PRODUCTS_FAILURE, data: xhr })
      })
  }
}

export const fetchFilterProducts = (url, origin) => {
  const urlParams = new URLSearchParams(window.location.search)
  const page = urlParams.get('page')
  return (dispatch) => {
    dispatch({ type: FETCH_INVESTMENT_PRODUCTS_LOADER, data: true })
    axios
      .get(`${url}&page=${page}&pageSize=${config.pageSize}`)
      .then(function (response) {
        dispatch({ type: FETCH_INVESTMENT_PRODUCTS_LOADER, data: false })
        return dispatch({ type: FETCH_INVESTMENT_PRODUCTS, data: response.data })
      })
      .catch(function (xhr, status, err) {
        return dispatch({ type: FETCH_INVESTMENT_PRODUCTS_FAILURE, data: xhr })
      })
  }
}

export const fetchFilterProductsNew = (url, origin) => {
  const urlParams = new URLSearchParams(window.location.search)
  const page = urlParams.get('page')
  return (dispatch) => {
    dispatch({ type: FETCH_INVESTMENT_PRODUCTS_LOADER, data: true })
    axios
      .get(`${url}&page=${page}&pageSize=50`)
      .then(function (response) {
        dispatch({ type: FETCH_INVESTMENT_PRODUCTS_LOADER, data: false })
        return dispatch({ type: FETCH_INVESTMENT_PRODUCTS, data: response.data })
      })
      .catch(function (xhr, status, err) {
        return dispatch({ type: FETCH_INVESTMENT_PRODUCTS_FAILURE, data: xhr })
      })
  }
}

export const setProjectTitle = (title) => ({
  type: SET_PROJECT_TITLE,
  data: title,
})

export const selectImages = (images) => ({
  type: SELECTED_IMAGES,
  data: images,
})

export const selectEDITImages = (images) => ({
  type: SELECTED_EDIT_IMAGES,
  data: images,
})

export const fetchProjectsNew = (page = 1, url, flag, callback = null) => {
  return (dispatch) => {
    if (flag !== 'not') {
      dispatch({ type: FETCH_PROJECT_LOADING })
    }
    const excludeIsActive =
      flag && flag === 'main_page' ? `&excludeIsActive=true` : `&excludeIsActive=false`

    axios.get(`${url}`).then((res) => {
      if (flag !== 'not') {
        dispatch({ type: FETCH_PROJECT_SUCCESS })
      }

      if (callback) callback(res.data)

      return dispatch({ type: FETCH_PROJECTS, data: res.data })
    })
  }
}

export const fetchDeactivatedProjectsNew = (page = 1, url) => {
  return (dispatch) => {
    dispatch({ type: FETCH_DEACTIVATED_PROJECTS_LOADER, data: true })
    axios.get(`${url}&offset=${(page - 1) * 10}`).then((res) => {
      dispatch({ type: FETCH_DEACTIVATED_PROJECTS_SUCCESS, data: false })
      return dispatch({ type: FETCH_DEACTIVATED_PROJECTS, data: res.data })
    })
  }
}

export const addProjectNew = (data, fetchProjectData, images) => {
  let modifiedData = {
    ...data,
    userId: localStorage.getItem('user_id'),
  }
  return (dispatch) => {
    dispatch({ type: ADD_PROJECT_LOADER, data: true })
    return axios
      .post(`${config.apiPath}/api/project/create`, modifiedData)
      .then(function (response) {
        dispatch({ type: ADD_PROJECT_LOADER, data: false })
        images &&
          images.map((item) => {
            let formData = new FormData()
            formData.append('image', item)
            dispatch({ type: ADD_PROJECT_LOADER, data: true })
            axios
              .post(
                `${config.apiPath}/api/project/images?projectId=${response.data.id}`,
                formData,
                {
                  headers: {
                    'Content-Type': 'multipart/form-data',
                  },
                }
              )
              .then((res) => {
                const lastItem = images[images.length - 1]
                if (item === lastItem) {
                  showToastMsg('Project Created Successfully', 'success')
                  dispatch({ type: ADD_PROJECT_LOADER, data: false })
                  setTimeout(() => {
                    window.location.href = '/projectmain'
                  }, 1000)
                }
                showToastMsg('Project Created Successfully', 'success')
              })
          })

        if (images === undefined) {
          showToastMsg('Project Created Successfully', 'success')
          setTimeout(() => {
            window.location.href = '/projectmain'
          }, 1000)
        }

        return dispatch({ type: addProject, data: response })
      })
      .catch((error) => {
        showToastMsg(error && error.response && error.response.data, 'error')
      })
  }
}

export const fetchProjects = (page = 1, url, flag, callback = null) => {
  return (dispatch) => {
    if (flag !== 'not') {
      dispatch({ type: FETCH_PROJECT_LOADING })
    }
    const excludeIsActive =
      flag && flag === 'main_page' ? `&excludeIsActive=true` : `&excludeIsActive=false`

    axios.get(`${url}&offset=${(page - 1) * 500}${excludeIsActive}`).then((res) => {
      if (flag !== 'not') {
        dispatch({ type: FETCH_PROJECT_SUCCESS })
      }

      if (callback) callback(res.data)

      return dispatch({ type: FETCH_PROJECTS, data: res.data })
    })
  }
}

export const addProject = (data, fetchProjectData, images) => {
  let modifiedData = {
    ...data,
    userId: localStorage.getItem('user_id'),
  }
  return (dispatch) => {
    dispatch({ type: ADD_PROJECT_LOADER, data: true })
    return axios
      .post(`${config.apiPath}/api/project/create`, modifiedData)
      .then(function (response) {
        dispatch({ type: ADD_PROJECT_LOADER, data: false })
        images &&
          images.map((item) => {
            let formData = new FormData()
            formData.append('image', item)
            dispatch({ type: ADD_PROJECT_LOADER, data: true })
            axios
              .post(
                `${config.apiPath}/api/project/images?projectId=${response.data.id}`,
                formData,
                {
                  headers: {
                    'Content-Type': 'multipart/form-data',
                  },
                }
              )
              .then((res) => {
                const lastItem = images[images.length - 1]
                if (item === lastItem) {
                  showToastMsg('Project Created Successfully', 'success')
                  dispatch({ type: ADD_PROJECT_LOADER, data: false })
                  setTimeout(() => {
                    window.location.href = '/projectmain'
                  }, 1000)
                }
                showToastMsg('Project Created Successfully', 'success')
              })
          })

        if (images === undefined) {
          showToastMsg('Project Created Successfully', 'success')
          setTimeout(() => {
            window.location.href = '/projectmain'
          }, 1000)
        }

        return dispatch({ type: addProject, data: response })
      })
      .catch((error) => {
        showToastMsg(error && error.response && error.response.data, 'error')
      })
  }
}

export const editProject = (data, projectId, images) => {
  return (dispatch) => {
    dispatch({ type: ADD_PROJECT_LOADER, data: true })
    axios
      .put(`${config.apiPath}/api/project/?id=${projectId}`, data)
      .then(function (response) {
        dispatch({ type: ADD_PROJECT_LOADER, data: false })
        images &&
          images.map((item, index) => {
            let formData = new FormData()
            formData.append('image', item)
            dispatch({ type: ADD_PROJECT_LOADER, data: true })
            axios
              .post(`${config.apiPath}/api/project/images?projectId=${projectId}`, formData, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              })
              .then((res) => {
                const lastItem = images[images.length - 1]
                if (item === lastItem) {
                  showToastMsg('Project Updated Successfully', 'success')
                  dispatch({ type: ADD_PROJECT_LOADER, data: false })
                  setTimeout(() => {
                    window.location.href = '/projectmain'
                  }, 1000)
                }
              })
          })

        if (images === undefined) {
          showToastMsg('Project Updated Successfully', 'success')
          setTimeout(() => {
            window.location.href = '/projectmain'
          }, 1000)
        }
        return dispatch({ type: UPDATE_PROJECT, data: response })
      })
      .catch(function (xhr, status, err) {
        dispatch({ type: ADD_PROJECT_LOADER, data: false })
        showToastMsg(xhr.response.data, 'error')
        return dispatch({ type: UPDATE_PROJECT, data: xhr })
      })
  }
}

export const fetchFloors = (page = 1, url) => {
  return (dispatch) => {
    dispatch({ type: FETCH_ALL_FLOORS_LOADER })
    axios.get(`${url}&offset=${(page - 1) * 500}`).then((res) => {
      dispatch({ type: FETCH_ALL_FLOORS_SUCCESS })
      return dispatch({ type: FETCH_FLOORS, data: res.data })
    })
  }
}
export const fetchFloorsNew = (page = 1, url) => {
  return (dispatch) => {
    dispatch({ type: FETCH_ALL_FLOORS_LOADER })
    axios.get(`${url}`).then((res) => {
      dispatch({ type: FETCH_ALL_FLOORS_SUCCESS })
      return dispatch({ type: FETCH_FLOORS, data: res.data.data })
    })
  }
}

export const fetchAllProjects = (params = {}, cb = null) => {
  return (dispatch) => {
    axios
      .get(`${config.apiPath}/api/project/all`, {
        params,
      })
      .then((res) => {
        dispatch({ type: FETCH_ALL_PROJECTS, data: res.data })
        if (cb) cb(res.data)
      })
  }
}
export const fetchAllProjectsNew = (params = {}, cb = null) => {
  return (dispatch) => {
    axios
      .get(`${config.apiPath}/api/imarat/projects`, {
        params,
      })
      .then((res) => {
        dispatch({ type: FETCH_ALL_PROJECTS, data: res.data })
        if (cb) cb(res.data)
      })
  }
}
export const fetchProjectFloors = (url) => {
  return (dispatch) => {
    axios.get(`${url}`).then((res) => {
      return dispatch({ type: FETCH_PROJECT_FLOOR, data: res.data })
    })
  }
}
export const fetchProjectFloorsNew = (url) => {
  return (dispatch) => {
    axios.get(`${url}`).then((res) => {
      return dispatch({ type: FETCH_PROJECT_FLOOR, data: res.data })
    })
  }
}

export const addFloor = (data, fetchFloors) => {
  return (dispatch) => {
    dispatch({ type: ADD_FLOOR_LOADER, data: true })
    return axios
      .post(`${config.apiPath}/api/project/floor/create`, data)
      .then(function (response) {
        showToastMsg('Floor Created Successfully', 'success')
        fetchFloors()
        dispatch({ type: ADD_FLOOR_LOADER_SUCCESS, data: false })
        return dispatch({ type: ADD_FLOOR, data: response })
      })
      .catch((error) => {
        showToastMsg(error.response.data, 'error')
      })
  }
}

export const addFloorNew = (data, fetchFloors) => {
  return (dispatch) => {
    dispatch({ type: ADD_FLOOR_LOADER, data: true })
    return axios
      .post(`${config.apiPath}/api/project/floor/create`, data)
      .then(function (response) {
        showToastMsg('Floor Created Successfully', 'success')
        fetchFloors()
        dispatch({ type: ADD_FLOOR_LOADER_SUCCESS, data: false })
        return dispatch({ type: ADD_FLOOR, data: response })
      })
      .catch((error) => {
        showToastMsg(error.response.data, 'error')
      })
  }
}

export const fetchUnits = (page = 1, url, callback = null) => {
  return (dispatch) => {
    dispatch({ type: FETCH_UNITS_LOADING })
    axios.get(`${url}&offset=${(page - 1) * 500}&all=true`).then((res) => {
      dispatch({ type: FETCH_UNITS_SUCCESS })
      if (callback) callback(res.data)
      return dispatch({ type: FETCH_UNITS, data: res.data })
    })
  }
}
export const fetchUnitsNew = (page = 1, url, callback = null) => {
  return (dispatch) => {
    dispatch({ type: FETCH_UNITS_LOADING })
    axios.get(`${url}&offset=${(page - 1) * 500}&all=true`).then((res) => {
      dispatch({ type: FETCH_UNITS_SUCCESS })
      if (callback) callback(res.data)
      return dispatch({ type: FETCH_UNITS, data: res.data })
    })
  }
}

export const addUnit = (data, fetchProjectData) => {
  let modifiedData = {
    ...data,
    userId: localStorage.getItem('user_id'),
  }
  return (dispatch) => {
    dispatch({ type: ADD_UNIT_LOADER, data: true })
    return axios
      .post(`${config.apiPath}/api/project/shop/create`, modifiedData)
      .then(function (response) {
        showToastMsg('Unit Created Successfully', 'success')
        fetchProjectData()
        dispatch({ type: ADD_UNIT_LOADER_SUCCESS, data: false })
        return dispatch({ type: addProject, data: response })
      })
      .catch((error) => {
        showToastMsg(error.response.data, 'error')
      })
  }
}

export const addUnitNew = (data, fetchProjectData) => {
  let modifiedData = {
    ...data,
    userId: localStorage.getItem('user_id'),
  }
  return (dispatch) => {
    dispatch({ type: ADD_UNIT_LOADER, data: true })
    return axios
      .post(`${config.apiPath}/api/project/shop/create`, modifiedData)
      .then(function (response) {
        showToastMsg('Unit Created Successfully', 'success')
        fetchProjectData()
        dispatch({ type: ADD_UNIT_LOADER_SUCCESS, data: false })
        return dispatch({ type: addProject, data: response })
      })
      .catch((error) => {
        showToastMsg(error.response.data, 'error')
      })
  }
}

export const fetchDeactivatedProjects = (page = 1, url) => {
  return (dispatch) => {
    dispatch({ type: FETCH_DEACTIVATED_PROJECTS_LOADER, data: true })
    axios.get(`${url}&offset=${(page - 1) * 10}`).then((res) => {
      dispatch({ type: FETCH_DEACTIVATED_PROJECTS_SUCCESS, data: false })
      return dispatch({ type: FETCH_DEACTIVATED_PROJECTS, data: res.data })
    })
  }
}
export const fetchDeletedUnits = (req) => {
  const urlParams = new URLSearchParams(window.location.search)
  const page = urlParams.get('page')
  var endpoint =
    `${config.apiPath}/api/project/shops/deleted?pageSize=` + config.pageSize + '&page=' + page
  if (req && req.offset && req.pageSize) {
    endpoint =
      `${config.apiPath}/api/project/shops/deleted?pageSize=` + config.pageSize + '&page=' + page
  } else if (req && req.all === true) {
    endpoint = `${config.apiPath}/api/project/shops/deleted`
  }
  return (dispatch) => {
    dispatch({ type: FETCH_DELETED_UNITS_LOADER, data: true })
    axios
      .get(endpoint)
      .then(function (response) {
        dispatch({ type: FETCH_DELETED_UNITS_SUCCESS, data: false })

        return dispatch({ type: FETCH_DELETED_UNITS, data: response.data })
      })
      .catch(function (xhr, status, err) {
        return dispatch({ type: FETCH_DELETED_UNITS_FAILURE, data: xhr })
      })
  }
}

export const fetchDeletedFloors = (req) => {
  const urlParams = new URLSearchParams(window.location.search)
  const page = urlParams.get('page')
  var endpoint =
    `${config.apiPath}/api/project/floors/deleted?pageSize=` + config.pageSize + '&page=' + page
  if (req && req.offset && req.pageSize) {
    endpoint =
      `${config.apiPath}/api/project/floors/deleted?pageSize=` + config.pageSize + '&page=' + page
  } else if (req && req.all === true) {
    endpoint = `${config.apiPath}/api/project/floors/deleted`
  }
  return (dispatch) => {
    dispatch({ type: FETCH_DELETED_FLOORS_LOADER, data: true })
    axios
      .get(endpoint)
      .then(function (response) {
        dispatch({ type: FETCH_DELETED_FLOORS_SUCCESS, data: false })
        return dispatch({ type: FETCH_DELETED_FLOORS, data: response.data })
      })
      .catch(function (xhr, status, err) {
        return dispatch({ type: FETCH_DELETED_FLOORS_FAILURE, data: xhr })
      })
  }
}
