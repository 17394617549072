/** @format */

import {  Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Upload from '../../../images/upload.png'
import _ from 'lodash'
import axios from 'axios'
import config from '../../../config'
import '../../Modals/modalsStyle.scss'
import Loader from 'react-loader-spinner'
import Button from '../../common/Button'
import './ScaFileUploader.scss'
import { showToastMsg } from '../../../utils/helper'

const ScaFileUploader = (props) => {
  const [showOverlay, setShowOverlay] = useState(false)
  const [showProgressBar, setShowProgressBar] = useState(false)
  const [attachmentLoader, setAttachmentLoader] = useState(false)
  const [selectedFiles, setSelectedFiles] = useState(null)
  const [disabledButton, setDisabledButton] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [alreadySelected, setAlreadySelected] = useState(false)
  const [loaderFlag, setLoaderFlag] = useState(false)
  const [showOkButton, setshowOkButton] = useState()

  // let unique = _.uniqBy(selectedFiles, 'name')

  const dragOver = (e) => {
    e.preventDefault()
  }
  const dragEnter = (e) => {
    e.preventDefault()
    setShowOverlay(true)
  }

  const dragLeave = (e) => {
    e.preventDefault()
    setShowOverlay(false)
  }
  const fileDrop = (e) => {
    e.preventDefault()
    const files = e.dataTransfer.files
    if (files.length > 1) {
      showToastMsg( 'Maximum files upload limit is 1', 'warning')
      return false
    }
    if (files.length) {
      handleFiles(files)
    }
  }

  useEffect((async) => {
    setLoaderFlag(true)
    async function fetchAlreadySelectedDocument(){
      await axios
      .get(`${config.apiPath}/api/legal/scadocuments?${props.scaType}=${props.selectedItem}&addedBy=${props.addedBy}`)
      .then((res) => {
        if(res.data[0] && res.data[0].fileName != null){
          setAlreadySelected(true);
          setSelectedFiles(res.data[0])
          setshowOkButton(true);
        }
        setLoaderFlag(false)
      })
      .catch((err) => {
        console.log('Document is not attached' + err)
      })
    } 
    fetchAlreadySelectedDocument();

  }, [props.selectedItem])

  const handleFiles = (files) => {
    for (let i = 0; i < files.length; i++) {
      if (validateFile(files[i])) {
        setSelectedFiles(files[0])
        setShowOverlay(false)
      }
    }
  }

  const addDocuments = () => {
    setSubmitting(true)
    setShowProgressBar(true)
    setDisabledButton(true)
    setAttachmentLoader(true)
    if (props.scaDocument == '' || props.scaDocument.length <= 0) {
      let scaObj = {}
      scaObj['addedBy'] = props.addedBy
      scaObj[props.scaType] = props.selectedItem

      axios
        .post(`${config.apiPath}/api/legal/document/sca`, scaObj)
        .then((res) => {
          addSCADocument(res.data.id)
        })
        .catch((err) => console.log(err))
    } else {
      addSCADocument(props.scaDocument[0].id)
    }
  }
  const addSCADocument = (id) => {
    let fd = new FormData()
    fd.append('file', selectedFiles)
    let url = `${config.apiPath}/api/legal/document?legalId=${id}`
    axios
      .post(url, fd)
      .then((res) => {
        showToastMsg('Document Uploaded Sucessfully', 'success')
        props.guideReferenceModal()
        props.getScaDocument(props.selectedItem)
      })
      .catch((error) => {
        showToastMsg('Some issue occurs while uploading document', 'error')
        props.guideReferenceModal()
      })
  }

  const validateFile = (file) => {
    const validTypes = [      
    'image/jpeg',
    'image/jpg',
    'image/png',
    'image/svg',
    'application/pdf',]
    if (file) {
      if (validTypes.indexOf(file.type) === -1) {
        showToastMsg('The file format is not supported', 'warning')
        setShowOverlay(false)
        return false
      }
    }
    return true
  }

  const addManualAttachments = (event) => {
    setShowProgressBar(true)
    const files = Array.from(event.target.files)

    if (files.length > 10) {
      showToastMsg('Maximum files upload limit is 10', 'warning')
      return false
    }

    if (validateFile(files[0])) {
      files.percentage = 0

      setSelectedFiles(files[0])
    }
  }

  const removeData = () => {
    if(alreadySelected == true){
      setshowOkButton(true)
    }
    setSelectedFiles(null)
    setAlreadySelected(false)
  }
  return (
    <Modal
      isOpen={true}
      toggle={() => {
        if (!submitting) props.guideReferenceModal()
      }}
      size="lg"
      style={{ maxWidth: '700px', width: '100%' }}
      contentClassName="general-popup-normal-size task-modal"
    >
      <ModalBody>
        <div className="close-icon-container">
          <i
            className="mt-1 fal fa-times-circle close-icon"
            onClick={() => {
              if (!submitting) props.guideReferenceModal()
            }}
          ></i>
        </div>
        <div
          className="attachment-main-container"
          style={{ height: '450px !important' }}
        >
          <div className="attachment-modal-header" >
            <div className="attachment-header-title">SCA Documents</div>
          </div>
          <div>
            { loaderFlag ? 
                (
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <Loader type="ThreeDots" color="#0f73ee" height="40" width="40" />
                  </div>
                ) :(alreadySelected == false
              ? 
              <div className="main-attachment-body">
              <div
                className={
                  showOverlay ? 'attachment-body-edges dropdown-overlay' : 'attachment-body-edges'
                }
                onDragOver={dragOver}
                onDragEnter={dragEnter}
                onDragLeave={dragLeave}
                onDrop={fileDrop}
              >
                {showOverlay ? (
                  <div className="drag-drop-overlay-text">Drop Here :) </div>
                ) : (
                  <div className="attachment-body">
                    <img src={Upload} className="upload-icon-size" />
                    <div className="drag-drop-text">Drag and Drop Here</div>
                    <div className="drag-drop-text">Or</div>
                    <div>
                      <input
                        type="file"
                        name="uploadfile"
                        id="img"
                        style={{ display: 'none' }}
                        key={Math.random(10)}
                        // multiple="multiple"
                        onChange={addManualAttachments}
                      />
                      <label htmlFor="img" className="attached-file-text">
                        Browse File
                      </label>
                    </div>
                    <div className="drag-drop-text">Max Upload Size: 10mb</div>
                    <div className="drag-drop-text">(Allowed file types: JPG/PNG/SVG/PDF)</div>
                  </div>
                )}
              </div>
            </div> : null)
            }

            {selectedFiles && (
              <div className="attached-container" style={{ height: 'auto' }}>
                {
                  <div className="attached-main-container">
                    <div className="attached-main-body">
                      <div className="attached-body-content">{selectedFiles.name ? selectedFiles.name : selectedFiles.fileName ? selectedFiles.fileName : ''}</div>
                      <div className="attached-header-close" onClick={() => removeData()}>X</div>
                    </div>
                  </div>
                }
              </div>
            )}

            <div className="attachment-upload-container mt-3">
              {
                showOkButton ? 
                    <Button
                    text={'Cancel'}
                    textColor={'black'}
                    width={'100px'}
                    height={'30px'}
                    display={'inline-block'}
                    buttonColor="#fff"
                    className={'px-4 rounded-pill small'}
                    style={{ border: '1px solid #d3d3d3' }}
                    onClick={() => {
                      props.guideReferenceModal()
                    }}
                    // disabled={(selectedFiles == null || showOkButton == true) ? true : false}
                  />
              : null
              }
              {
                loaderFlag ? 
                null : 
                  <Button
                  loader={submitting?true:false}
                  text={showOkButton ==true ? 'OK' : 'Continue'}
                  textColor={'white'}
                  width={'100px'}
                  height={'30px'}
                  display={'inline-block'}
                  buttonColor="#026ff2"
                  className={'px-4 rounded-pill mr-2 small'}
                  style={{ border: '1px solid #d3d3d3', marginLeft : '20px' }}
                  onClick={() => {
                  if (!submitting) {
                    addDocuments()
                  }
                }}
                  disabled={(selectedFiles == null || alreadySelected == true) ? true : false}
                />
              }
              
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default React.memo(ScaFileUploader)