import Dialer from "../../views/Lead/Diary/Dialer";
import React, {useEffect, useState} from "react";
import dialer_agent from "./dailer_agent";
import axios from "axios";
import config from '../../config';
import history from "../../utils/routingHistory";
import ClientLead from "../Modals/ConnectFeedback/ClientLead";
import {showToastMsg} from "../../utils/helper";
import Swal from "sweetalert2";
import mp3Tune from "../../images/nokia-iphone.mp3"
// import { mapStateToProps } from "../common/Table/TableRow";
import {connect} from "react-redux";
import {setDialerData} from '../../actions/setDialerData'
import {mwsGlobalSocket} from "../../actions/setDialerData";
import {validateNumber} from "../../callCenter/ptclCodes";
import {dialerLeadInfo} from "../../actions/diary";
import {agentUuId} from "../../callCenter/socket";


const DialerComponent = (props) => {
  const [showDialer, setShowDialer] = useState(false);
  const [leadData, setLeadData] = useState({});
  const [leadToggle, setLeadToggle] = useState(false);
  const [agent, setAgent] = useState({});
  const [currentUserId, setCurrentUserId] = useState(0);
  const [reConnect, setReConnect] = useState(true)
  let callRing = null;

  const loadClientInformation = async (phoneNumber) => {
    await getClientDetails(phoneNumber).then((res) => {
      const {isExisting, data} = res;
     // setShowDialer(false);
      if (isExisting === false) {
        let tempPhone = phoneNumber;
        if (tempPhone.indexOf('0') === 0) {
          tempPhone = tempPhone.substring(1);
        } else if (tempPhone.indexOf('+') === 0) {
          tempPhone = tempPhone.substring(3);
        }
        const validateNumb = validateNumber("0"+tempPhone)
        console.log(tempPhone,validateNumb,'validate  Number')
        const temNumber = validateNumb ?'+92'+tempPhone:tempPhone
        console.log(temNumber,'temNumber')
        history.push(`/add-client?phoneNumber=${temNumber}`)
      } else {
        let user = localStorage.getItem('user')
        if (user !== undefined && user !== null && user !== '') {
          user = JSON.parse(user)
          setCurrentUserId(Number(user.id))
          props.dispatchDialerLeadInfo({
            armLeadUserId:data.assigned_to_armsuser_id,
            currentAmsUserId:user.id
          })
        }

        setLeadToggle(true);
        setLeadData(data);
        //  history.push(`/edit-client/${data.id}`)
      }
      console.log(res, 'Result')
    }).catch((error) => {
      console.log(error, 'Result')
    })
  }
  useEffect(() => {
    /* window.addEventListener('message', function (e) {
      try {
        const datas = JSON.parse(e.data);
        switch (datas.action) {
          case 'dailer_received_call':
            if (callRing) {
              callRing.pause();
            }
            getClientDetails(datas.data.phoneNumber).then((res) => {
              const {isExisting, data} = res;
              setShowDialer(false);
              props.closeDialer(false)
              if (isExisting === false) {
                let tempPhone = datas.data.phoneNumber;
                if (tempPhone.indexOf('0') === 0) {
                  tempPhone = tempPhone.substring(1);
                } else if (tempPhone.indexOf('+') === 0) {
                  tempPhone = tempPhone.substring(3);
                }
                history.push(`/add-client?phoneNumber=${tempPhone}`)
              } else {
                setLeadToggle(true);
                setLeadData(data);
                //  history.push(`/edit-client/${data.id}`)
              }
              console.log(res, 'Result')
            }).catch((error) => {
              console.log(error, 'Result')
            })
            break;
          case "call_disconnected":
            showToastMsg(`Call : ${datas.data.phoneNumber} Disconnected Now!`, 'success');
            setShowDialer(true);
            props.closeDialer(true)
            break;
          case "call_in_queue":
            showToastMsg(`Incoming Call!`, 'info');
            console.log('agent call status: ', datas.data.phoneNumber);
            if (datas.data.phoneNumber !== 'live') {
              if (callRing) {
                callRing.play();
              }
              setShowDialer(true);
              props.closeDialer(true);
            }
            break;
        }
        console.log(e.data, datas.action, 'Iframe Data react side')
      } catch (e) {
        console.log(e, 'ERROR')
      }
    });*/

    dialer_agent().then((result) => {
      if (result.success) {
        setAgent(result)
        if (result.callTone) {
          callRing = new Audio(result.callTone);
        } else {
          callRing = new Audio(mp3Tune);
        }
        props.setDialerData(result.agentUser);
      } else {
        setAgent({});
      }
    });
    agentUuId();
  }, []);


  useEffect(() => {
    setShowDialer(props.dialerShow)
    if (props.dialerShow && Object.keys(agent).length === 0) {
      Swal.fire({
        title: '',
        html: `<div class='text-left'>Agent is not configured yet.</div>`,
        icon: 'warning'
      });
    }
  }, [props.dialerShow]);


  const getClientDetails = async (phoneNumber) => {
    return new Promise((reslove, reject) => {
      axios.get(`${config.apiPath}/api/asterisk/inboundCall?phone=${phoneNumber}&secretKey=ABC`).then((response) => {
        reslove({
          success: true,
          message: 'customer loaded',
          isExisting: response.data.length > 0,
          data: response.data.length > 0 ? response.data[0] : {}
        });
      }).catch((error) => {
        reject({
          success: false,
        });
      })
    });
  }

  const reConnectSocketHandle = (agentUser) => {
    setReConnect(false)
    setTimeout(() => {
      setReConnect(true);
    }, 1000)
  }
  return (
    <>
      {
        (Object.keys(agent).length > 0 && leadToggle && Object.keys(leadData).length > 0) && (
          <ClientLead
            leadModal={leadToggle}
            leadType={'existinglead'}
            setLeadModal={() => {
              setLeadToggle(!leadToggle)
            }}
            customer={leadData}
            clientLeadModal={true}
          />
        )
      }
      <div hidden={!showDialer}>
        {
          (Object.keys(agent).length > 0 && !!agent.agentUser && !!agent.agentServer) && reConnect && (<Dialer
            dailerDomain={agent.agentServer}
            agentUser={agent.agentUser}
            agentPassword={agent.agentPassword}
            dialerState={showDialer}
            isCallCenterConnected={props.isCallCenterConnected}
            liveCallRing={props.liveCallRing}
            clientLoadHandle={loadClientInformation}
            mwsSocketGlobalHandle={(socket) => {
              props.mwsGlobalSocket(socket)
            }}
            reConnectSocket={reConnectSocketHandle}
            closeDialer={(flag) => {
              props.closeDialer(flag)
              if (!flag){
                try{
                  document.body.classList.remove('dialer-open');
                }catch (e) {

                }
              }
              setShowDialer(flag)
            }}
          />)
        }
        {
          !reConnect && (
            <div className={'mws_dialer_wrapper bg-white dialer-mws'} style={{
              position: 'absolute',
              zIndex: 99999,
              right: 0,
              top: '0px',
              bottom: 0,
              width: '23%',
              height: '100%',
              border: '1px solid #dcdcdc',
              padding: '1rem'
            }}>
              <div className="sidebar-header">
                <div onClick={() => {
                  props.closeDialer(false)
                }} className={'btn-close'}><i className={'fa fa-close'}></i>
                </div>
              </div>
              <div className="sidebar-content" style={{width: '100%', height: '100%'}}>
                <div className="dialer-content" style={{marginTop: '10px'}}>
                  <div className='phonewrapper'>
                    <div className={'alert alert-danger mt-2'}>Please wait we are connecting ...</div>
                  </div>
                </div>
              </div>
            </div>
          )
        }
      </div>
    </>
  );
}


const mapDispatchToProps = (dispatch) => ({

  setDialerData: (agentUserId) => dispatch(setDialerData(agentUserId)),
  mwsGlobalSocket: (socketObj) => dispatch(mwsGlobalSocket(socketObj)),
  dispatchDialerLeadInfo:(data) => dispatch(dialerLeadInfo(data))
})


const mapStateToProps = (state) => {
  return {
    agentUserId: state.setDialerDataReducer.agentUserId,
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(DialerComponent)
