/** @format */

import React, { Component, Fragment } from 'react'
import Pagination from 'rc-pagination'
import { connect } from 'react-redux'
import axios from 'axios'
import config from '../../config'
import Load from '../../components/Loader'
import Inner from './Inner'
import { normalizeCnic } from '../../utils/formatCnic'
import 'rc-pagination/assets/index.css'
import 'rc-select/assets/index.css'
// import ClinetCreationModal from './modal'
// import './style.scss'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { showToastMsg } from '../../utils/helper'
class AddNextKin extends Component {
  constructor(props) {
    super(props)

    this.state = {
      formData: {
        firstName: 'tai',
        lastName: '',
        address: '',
        email: '',
        cnic: '',
        secondaryAddress: '',
        relationStatus: 'son of',
        checkRequiredField: false,
      },
      phone: {
        countryCode: 'pk',
        dialCode: '',
        phone: '',
      },
      validationCheck: false,
      loadingOnsubmit: false,
      cnicValidation: false,
      phoneValidate: false,
      name: '',
    }
  }
  handleChange = (e, dropDownName, resetArray) => {
    const { formData } = this.state
    if (dropDownName) {
      var name = dropDownName
      var value = e.value
    } else {
      var name = e.target.name
      var value = e.target.value
    }
    var newFormData = { ...formData }
    if (resetArray && resetArray.length > 0) {
      for (let i = 0; i < resetArray.length; i++) {
        newFormData[resetArray[i]] = ''
      }
    }
    newFormData[name] = value
    if (name === 'cnic') {
      this.validateCnic(value)
    }
    this.setState({
      formData: newFormData,
    })
  }

  validateCnic = (value) => {
    var value = value.replace(/-/g, '')
    if (value.length < 13 && value !== '') {
      this.setState({ cnicValidation: true })
    } else {
      this.setState({ cnicValidation: false })
    }
  }

  emptyForm = () => {
    let formData = {
      firstName: '',
      lastName: '',
      address: '',
      email: '',
      cnic: '',
      secondaryAddress: '',
      relationStatus: '',
      relativeName: '',
      profession: '',
      passport: '',
      country: '',
      province: '',
      district: '',
      city: '',
      mCountry: '',
      mProvince: '',
      mDistrict: '',
      mCity: '',
      mAddress: '',
      nationality: '',
      dob: '',
    }
    let phone = {
      countryCode: 'pk',
      dialCode: '',
      phone: '',
    }
    let contact1 = {
      countryCode: 'pk',
      dialCode: '',
      contact1: '',
    }
    let contact2 = {
      countryCode: 'pk',
      dialCode: '',
      contact2: '',
    }

    this.setState({
      formData: formData,
      phone: phone,
      contact1: contact1,
      contact2: contact2,
    })
  }

  createNextKin = () => {
    const { formData, phone, validationCheck } = this.state
    console.log(formData, validationCheck)
    console.log('props', this.props)
    let body
    let cnicn = formData.cnic.replace(/-/g, '')
    if (formData.firstName === '' || formData.lastName === '' || formData.cnic === '') {
      this.setState({
        validationCheck: true,
      })
    } else {
      if (formData.cnic.length < 13) {
        this.setState({ cnicValidation: true })
      } else {
        body = {
          firstName: formData.firstName,
          lastName: formData.lastName,
          relationStatus: formData.relationStatus,
          nameOfRelative: formData.relativeName,
          realationwithApplicant: formData.relationWithApplicant,
          phone: phone.phone,
          cnic: cnicn,
          mailingCountry: formData.mCountry,
          mailingProvince: formData.mProvince,
          mailingDistrict: formData.mDistrict,
          mailingCity: formData.mCity,
          mailingAddress: formData.mAddress,
          Country: formData.country,
          Province: formData.province,
          District: formData.district,
          City: formData.city,
          Address: formData.address,
          customerId: this.props && this.props.id,
        }
      }
      axios
        .post(`${config.apiPath}/api/booking/createKinDetails`, body)
        .then((res) => {
          if (res.data.status === true) {
            showToastMsg('Next Of Kin has been added successfully', 'success')
            this.emptyForm()
            this.props.apiCallDonKinCreated(res.data.data)
          }
        })
        .catch(() => {
          showToastMsg('Something went wrong!!', 'error')
        })
    }
  }

  handleChangePhone = (value, country, e, formattedValue) => {
    const { phone } = this.state
    var newPhone = { ...phone }
    var name = e.target && e.target.name

    if (name === 'phone') {
      newPhone['countryCode'] = country.countryCode
      newPhone['dialCode'] = country.dialCode
      newPhone[name] = '+' + value
      this.validatePhone(value)
      this.setState({
        phone: newPhone,
      })
    }
  }
  validatePhone = (value) => {
    if (value.length < 6 && value !== '') this.setState({ phoneValidate: true })
    else this.setState({ phoneValidate: false })
  }

  render() {
    const {
      formData,
      phone,
      validationCheck,
      loadingOnsubmit,
      cnicValidation,
      phoneValidate,
    } = this.state
    const {
      fromPage,
      activeBankModal,
      activeKinModal,
      shareToggle,
      BankModalToggle,
      editable,
      KinModalToggle,
      checkPurchaser,
    } = this.props
    return (
      <Modal
        isOpen={activeKinModal}
        toggle={KinModalToggle}
        size="lg"
        style={{ maxWidth: '730px', width: '100%' }}
        contentClassName="comment-modal-width"
      >
        <div className="createClientModal">
          <ModalHeader toggle={KinModalToggle}>
            {/* {editAble === true ? 'Edit' : 'Register'} */}
            Add Next of Kin
          </ModalHeader>
          <ModalBody>
            {/* <div className={'addPageHeading'}>
                          <h4>{editAble === true ? 'Edit' : 'Create'} Client</h4>
                      </div> */}
            <div className={`filterMainWrap padding__modal`}>
              <Inner
                formData={formData}
                phone={phone}
                loadingOnsubmit={loadingOnsubmit}
                validationCheck={validationCheck}
                cnicValidation={cnicValidation}
                phoneValidate={phoneValidate}
                handleChange={this.handleChange}
                handleChangePhone={this.handleChangePhone}
                createNextKin={this.createNextKin}
                checkPurchaserPage={true}
              />
            </div>
          </ModalBody>
        </div>
      </Modal>
    )
  }
}

export const mapStateTopProps = (state, ownProps) => {
  return {}
}

export const mapDispatchTopProps = (dispatch) => {
  return {}
}

export default connect(mapStateTopProps, mapDispatchTopProps)(AddNextKin)
