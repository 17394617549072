/** @format */

/** @format */
import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import Label from './Label'
import useOutsideListner from './../../hooks/OutsideListner'
import CallIcon from './../../images/callIcon.svg'
import clsx from 'clsx'
import { bounds } from 'leaflet'
import Tooltip from './Tooltip/Tooltip'
const useStyles = makeStyles({
  root: (props) => ({
    width: props.width,
    height: props.height,
    background: props.buttonColor,
    borderRadius: '5px',
    padding: '5px 5px',
    textAlign: 'center',
    display: props.display,
    cursor: props.disabled ? 'normal' : 'pointer',
    opacity: props.disabled ? '.5' : '1',
  }),
  dropdownRoot: (props) => ({
    minWidth: 'max-content',
    minHeight: 'max-content',
    width: props.dropdownBoxWidth,
    position: 'fixed',
    textAlign: 'left',
    border: '1px solid #e2e2e3',
    backgroundColor: 'white',
    borderRadius: '5px',
    boxShadow: '-0.5px 0px 5px -2.5px rgb(0 0 0 / 75%)',
    zIndex: '1',
    opacity: 0,
  }),
  dropdownItem: {
    backgroundColor: '#fff',
    padding: '8px 20px',
    fontSize: '12px',
    color: '#000',
    outline: 'none',
    border: '0px',
    borderRadius: '0px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f8f9fa',
    },
  },
  iconRoot: {
    marginRight: '15px',
  },
  restrictTextSelection: {
    '-webkit-touch-callout': 'none' /* iOS Safari */,
    '-webkit-user-select': 'none' /* Safari */,
    '-khtml-user-select': 'none' /* Konqueror HTML */,
    '-moz-user-select': 'none' /* Old versions of Firefox */,
    '-ms-user-select': 'none' /* Internet Explorer/Edge */,
    'user-select':
      'none' /* Non-prefixed version, currently
                        supported by Chrome, Edge, Opera and Firefox */,
  },
  imgButton: (props) => ({
    display: props.display,
    textAlign: 'center',
  }),
})
const Button = (props) => {
  const classes = useStyles(props)
  const dropdownRef = useRef(null)
  const menuRef = useRef(null)
  const imgBtnRef = useRef()
  const [isDropDownOpen, setOpen] = useState(false)
  useOutsideListner(dropdownRef, (event) => {
    event.stopPropagation()
    setOpen(false)
  })

  var onParentScrollChange = (e) => {
    isDropDownOpen && setOpen(false)
    document.removeEventListener('scroll', onParentScrollChange, true)
  }

  useEffect(() => {
    if (dropdownRef && dropdownRef.current) {
      let bounds = dropdownRef.current.getBoundingClientRect()
      if (menuRef && menuRef.current) {
        let offSetLeft = props.orientation
          ? props.orientation === 'left'
            ? props.dropdownBoxWidth
              ? -parseInt(props.dropdownBoxWidth)
              : 0
            : props.orientation === 'right'
            ? props.width
              ? parseInt(props.width)
              : 0
            : 0
          : 0
        let offsetTop =
          props.orientation && props.height
            ? props.orientation === 'bottom'
              ? parseInt(props.height)
              : 0
            : 0

        if (props.right) menuRef.current.style.right = `${props.right}`
        menuRef.current.style.top = props.top || bounds.top + offsetTop + 'px'
        menuRef.current.style.left = props.left || bounds.left + offSetLeft + 'px'
        menuRef.current.style.opacity = 1

        if (props.autoAdjustOrientation) {
          if (menuRef && menuRef.current) {
            let menuBounds = menuRef.current.getBoundingClientRect()
            if (menuBounds.top + menuBounds.height > window.innerHeight) {
              // switch orientation to top if menu is cutting screen
              console.log(bounds.top, '-', offsetTop, '-', menuBounds.height, '-', bounds.height)
              menuRef.current.style.top =
                bounds.top + offsetTop + bounds.height - menuBounds.height + 'px'
            }
          }
        }
      }
    }

    if (isDropDownOpen) {
      document.addEventListener('scroll', onParentScrollChange, true)
    }
  }, [isDropDownOpen])
  const getBasicButton = () => {
    return (
      <>
        {props && props.text === '' ? null : (
          <div
            style={props.style}
            className={clsx(classes.root, classes.restrictTextSelection, props.className)}
            onClick={(event) => {
              // event.stopPropagation()
              // event.preventDefault()
              !props.disabled && props.onClick(props.action)
            }}
          >
            {props.loader ? (
              <span
                className={`fa fa-circle-o-notch fa-spin`}
                style={props.loaderColor !== '' ? { color: props.loaderColor } : {}}
              ></span>
            ) : (
              <Label
                label={props.text}
                textDecoration={props.textDecoration}
                textColor={props.textColor}
                labelIcon={props.labelIcon}
              ></Label>
            )}
          </div>
        )}
      </>
    )
  }

  const renderLabelIcon = () => {
    return (
      <div
        style={props.style}
        className={clsx(classes.root, classes.restrictTextSelection, props.className)}
        onClick={(event) => {
          // event.stopPropagation()
          // event.preventDefault()
          !props.disabled && props.onClick(props.action)
        }}
      >
        {props.loader ? (
          <span className="fa fa-circle-o-notch fa-spin"></span>
        ) : (
          <Label
            label={props.text}
            textColor={props.textColor}
            labelIconCode={props.labelIconCode}
            type={props.type}
          ></Label>
        )}
      </div>
    )
  }

  const orientation = () => {
    let styles = {}
    if (props.orientation) styles.top = '0'
    if (props.orientation == 'left') {
      styles.right = '100%'
    } else if (props.orientation == 'right') {
      styles.left = '100%'
    }
    return styles
  }
  const getStyles = () => {
    let styles = {}
  }
  const getDropDownButton = () => {
    return (
      <div
        className={clsx(classes.restrictTextSelection, isDropDownOpen ? 'ddIsOpen' : '')}
        // style={{ position: 'relative' }}
        ref={dropdownRef}
      >
        <div
          className={clsx(classes.root, props.className)}
          onClick={() => {
            setOpen(!isDropDownOpen)
          }}
          style={props.style || {}}
        >
          <Label label={props.text} textColor={props.textColor}></Label>
          <i
            className="fa fa-caret-down"
            aria-hidden="true"
            style={{ marginLeft: '2px', color: props.caretColor || 'white' }}
          ></i>
        </div>
        {isDropDownOpen && (
          <div ref={menuRef} className={classes.dropdownRoot}>
            {getMenu()}
          </div>
        )}
      </div>
    )
  }
  //style={orientation()}
  const getMenu = () => {
    if (props.actionItems && props.actionItems.length > 0) {
      return props.actionItems.map((item, index) => {
        return (
          <div
            className={classes.dropdownItem}
            onClick={() => {
              props.onClick(item.action)
              setOpen(false)
            }}
          >
            {item.icon && (
              <span className={classes.iconRoot}>
                <img src={item.icon} width="18px" height="18px" />
              </span>
            )}
            <Label label={item.label}></Label>
          </div>
        )
      })
    } else {
      return (
        <div className={classes.dropdownItem}>
          <Label label={props.emptyMenuItem ? props.emptyMenuItem : 'No Items Found'}></Label>
        </div>
      )
    }
  }

  const getImageButton = () => {
    return (
      <Tooltip
        content={props.tooltipContent}
        direction={props.tooltipDirection}
        contentRef={imgBtnRef}
        alwaysShowOnHover={props.tooltipContent}
        styles={props.tooltipStyles}
        caretStyles={props.tooltipCaretStyles}
      >
        <div className={clsx(classes.imgButton, props.className)} ref={imgBtnRef}>
          {props.icon && (
            <img
              src={props.icon}
              width={props.width}
              height={props.height}
              onClick={(event) => {
                // event.stopPropagation()
                props.onClick(props.action)
              }}
            />
          )}
        </div>
      </Tooltip>
    )
  }
  const getButtonByType = (type) => {
    switch (type) {
      case 'basic':
        return getBasicButton()
      case 'dropdown':
        return getDropDownButton()
      case 'image':
        return getImageButton()
      case 'labelIcon':
        return renderLabelIcon()
    }
  }
  return getButtonByType(props.type)
}
export default Button
Button.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  text: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  buttonColor: PropTypes.string,
  textColor: PropTypes.string,
  display: PropTypes.string,
  style: PropTypes.object,
  disabled: PropTypes.bool,
  dropdownBoxWidth: PropTypes.string,
  tooltipContent: PropTypes.string,
  tooltipDirection: PropTypes.string,
  tooltipStyles: PropTypes.object,
  tooltipCaretStyles: PropTypes.object,
}
Button.defaultProps = {
  width: 'max-content',
  height: 'max-content',
  text: '',
  className: '',
  type: 'basic',
  buttonColor: '#1173ef',
  textColor: 'black',
  textDecoration: 'inherit',
  onClick: () => {},
  display: 'block',
  disabled: false,
  style: {},
  loader: false,
  loaderColor: '',
  dropdownBoxWidth: '200px',
  tooltipContent: '',
  tooltipDirection: 'top',
  tooltipStyles: {},
  tooltipCaretStyles: {},
}
