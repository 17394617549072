/** @format */

import { Modal, ModalBody, ModalHeader, ModalFooter, Button} from 'reactstrap'
import React, { useEffect, useState } from 'react'
import './category.scss'
import { StaticData } from '../../../StaticData'
import axios from 'axios'
import config from '../../../config'
import { ADD_DETAIL, ADD_DETAIL_LOADER } from '../../../actions/leads'

const CategoryModal = (props) => {
  const [category, setCategory] = useState({})

  useEffect(() => {
    setCategory(props.lead.leadCategory)
  }, [])

  useEffect(() => {
    if(props.lead.leadCategory !== category)
      updateLeadCategory()
  }, [category])

  const updateLeadCategory = () => {
    axios.patch(`${config.apiPath}/api/leads/project?id[]=${props.lead.id}`, {
      leadCategory: category
    }).then((res) => {
      props.fetchLeadTimeLine()
    })
  }

  return (
    <Modal
      isOpen={props.showCategoryModal}
      toggle={() => updateLeadCategory()}
      className="general-content category-modal"
    >
      <ModalHeader>

        Set Classification

        <div className="close-icon-container">
          <i
            className="mt-1 fal fa-times-circle close-icon"
            onClick={() => props.setShowCategoryModal(false)}
          ></i>
        </div>
      </ModalHeader>
      <ModalBody>
        <div className={"lead-categories"}>
          {StaticData.leadCategories.map(c => <div
            style={{
              color: `${category === c.value ? 'white': c.colorCode}`,
              borderColor: c.colorCode,
              backgroundColor:  `${category === c.value ? c.colorCode: 'white'}`
            }}
            className={"lead-category"}
            onClick={() => setCategory(c.value)}>{c.label}</div>)}
        </div>
      </ModalBody>
    </Modal>
  )
}

export default CategoryModal
