import React, { useState, useEffect, useMemo } from "react";
import BarChart from "./BarChart";
import axios from "axios";
import config from "../../config";
import moment from "moment";
import { DateRangePicker } from "react-dates";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
// import "./style.scss";

const SupervisorCallsGrap = (props) => {
  const [isOpenBarChartsModel, setIsOpenBarChartsModel] = useState(true);
  const [focusedInputForIncoming, setFocusedInputForIncoming] = useState(null);
  const [focusedInputForOutgoing, setFocusedInputForOutgoing] = useState(null);
  const [startDateForIncoming, setStartDateForIncoming] = useState(moment());
  const [endDateForIncoming, setEndDateForIncoming] = useState(moment());
  const [startDateForOutgoing, setStartDateForOutgoing] = useState(moment());
  const [endDateForOutgoing, setEndDateForOutgoing] = useState(moment());
  const [dataForApi, setDataForApi] = useState({});
  const [dispositions, setDispositions] = useState([]);
  const [filterInTitles, setFilterInTitles] = useState([
    "ANSWER",
    "NO ANSWER",
    "BUSY",
    "FAILED",
    "REJECTED",
    "AGENT MISCALL",
    "CUSTOMER MISCALL",
    "AGENT MICROPHONE PERMISSION",
    "SIP FAILURE CODE",
    "ANSWERED"
  ]);
  const [agents, setAgents] = useState([]);
  const [filterInColor] = useState([
    "#90ee7e",
    "#d4526e",
    "#ffff00",
    "#f9a3a4",
    "#f48024",
    "#A5978B",
    "#2b908f",
    "#303030",
    "#2b228c",
    '#8c4e22'

  ]);
  const [grapTitles, setGrapTile] = useState([]);
  const [grapValues, setGrapValues] = useState([]);
  const [grapColors, setGrapColors] = useState([]);

  const [grapTitlesForOutgoing, setGrapTileForOutgoing] = useState([]);
  const [grapValuesForOutgoing, setGrapValuesForOutgoing] = useState([]);
  const [grapColorsForOutgoing, setGrapColorsForOutgoing] = useState([]);

  const [incomingSeries, setIncomingSeries] = useState([]);
  const [incomingCategories, setIncomingCategories] = useState([]);

  const [outgoingSeries, setOutgoingSeries] = useState([]);
  const [outgoingCategories, setOutgoingCategories] = useState([]);

  const filterData = (data, type) => {
    let categories = [];

    let ANSWER = [];
    let NOANSWER = [];
    let BUSY = [];
    let FAILED = [];
    let REJECTED = [];
    let AGENTMISCALL = [];
    let CUSTOMERMISCALL = [];
    let AGENTMICROPHONEPERMISSION = [];
    let SIPFAILURECODE = [];
    let ANSWERED = [];


    data.map((row, i) => {
      if (row.name) {
        categories.push(row.name);
      }

      if (row.data.ANSWER) {
        ANSWER.push(row.data["ANSWER"]);
      } else {
        ANSWER.push(0);
      }
      if (row.data["NO ANSWER"]) {
        NOANSWER.push(row.data["NO ANSWER"]);
      } else {
        NOANSWER.push(0);
      }
      if (row.data["BUSY"]) {
        BUSY.push(row.data["BUSY"]);
      } else {
        BUSY.push(0);
      }
      if (row.data["FAILED"]) {
        FAILED.push(row.data["FAILED"]);
      } else {
        FAILED.push(0);
      }

      if (row.data["REJECTED"]) {
        REJECTED.push(row.data["REJECTED"]);
      } else {
        REJECTED.push(0);
      }
      if (row.data["AGENT MISCALL"]) {
        AGENTMISCALL.push(row.data["AGENT MISCALL"]);
      } else {
        AGENTMISCALL.push(0);
      }
      if (row.data["CUSTOMER MISCALL"]) {
        CUSTOMERMISCALL.push(row.data["CUSTOMER MISCALL"]);
      } else {
        CUSTOMERMISCALL.push(0);
      }

      if (row.data["AGENT MICROPHONE PERMISSION"]) {
        AGENTMICROPHONEPERMISSION.push(row.data["AGENT MICROPHONE PERMISSION"]);
      } else {
        AGENTMICROPHONEPERMISSION.push(0);
      }


      if (row.data["SIP FAILURE CODE"]) {
        SIPFAILURECODE.push(row.data["SIP FAILURE CODE"]);
      } else {
        SIPFAILURECODE.push(0);
      }
      if (row.data["ANSWERED"]) {
        ANSWERED.push(row.data["ANSWERED"]);
      } else {
        ANSWERED.push(0);
      }




    });

    let dataform = [
      {
        name: "ANSWER",
        data: ANSWER,
      },
      {
        name: "No ANSWER",
        data: NOANSWER,
      },
      { name: "BUSY", data: BUSY },
      {
        name: "FAILED",
        data: FAILED,
      },
      {
        name: "REJECTED",
        data: REJECTED,
      },
      {
        name: "AGENT MISCALL",
        data: AGENTMISCALL,
      },
      {
        name: "CUSTOMER MISCALL",
        data: CUSTOMERMISCALL,
      },
      {
        name: "AGENT MICROPHONE PERMISSION",
        data: AGENTMICROPHONEPERMISSION,
      },
      {
        name: "SIP FAILURE CODE",
        data: SIPFAILURECODE,
      },
      {
        name: "ANSWERED",
        data: ANSWERED,
      },
    ];
    if (type === "incoming") {
      setIncomingSeries(dataform);
      setIncomingCategories(categories);
    } else {
      setOutgoingSeries(dataform);
      setOutgoingCategories(categories);
    }
  };

  const dailyCallGrapApiHandler = (startDate, endDate, direction) => {
    axios
      .post(`${config.apiPath}/api/cdrreport/dailyCallGraph`, {
        startDate: startDate,
        endDate: endDate,
        direction: direction,
        isSupervisor: true,
      })
      .then((res) => {
        // res && res.data && res.data.dispositions && setDispositions(res.data.dispositions);setFilterInTitles(()=>(res.data.dispositions));
        res &&
          res.data &&
          res.data.graph &&
          filterData(res.data.graph, direction);
      });
  };

  const isOpenBarChartHandler = () => {
    setIsOpenBarChartsModel(!isOpenBarChartsModel);
  };

  useEffect(() => {
    const currentDate = moment(new Date()).format("YYYY-MM-DD");
    isOpenBarChartsModel &&
      dailyCallGrapApiHandler(currentDate, currentDate, "incoming");
    isOpenBarChartsModel &&
      dailyCallGrapApiHandler(currentDate, currentDate, "outgoing");
  }, [isOpenBarChartsModel]);

  return (
    <div>
      <div className="row mt-5">
        <div className={"col-6"}>
          <div
            className={
              "callCenterDateRange " +
              (startDateForIncoming && endDateForIncoming
                ? "selectedDateRange"
                : "")
            }
          >
            <DateRangePicker
              isOutsideRange={() => false}
              startDate={startDateForIncoming}
              endDate={endDateForIncoming}
              startDateId="callCenter_date_input_start_for_incoming"
              minimumNights={0}
              endDateId="callCenter_date_input_end_for_incoming"
              onDatesChange={({ startDate: dateStart, endDate: dateEnd }) => {
                if (dateStart) {
                  setStartDateForIncoming(dateStart);
                  setDataForApi({
                    ...dataForApi,
                    startDateForIncoming:
                      moment(dateStart).format("YYYY-MM-DD"),
                  });
                }
                if (dateEnd) {
                  setEndDateForIncoming(dateEnd);
                  setDataForApi({
                    ...dataForApi,
                    endDateForIncoming: moment(dateEnd).format("YYYY-MM-DD"),
                  });
                }
                if (dateStart && dateEnd) {
                  dailyCallGrapApiHandler(
                    moment(dateStart).format("YYYY-MM-DD"),
                    moment(dateEnd).format("YYYY-MM-DD"),
                    "incoming"
                  );
                }
              }}
              focusedInput={focusedInputForIncoming}
              onFocusChange={(input) => setFocusedInputForIncoming(input)}
              showClearDates
            />
            <BarChart
              series={incomingSeries}
              categories={incomingCategories}
              Proplabel="Inbound Call Logs"
              grapTitles={grapTitles}
              graphValues={grapValues}
              grapColors={grapColors}
              agentNames={agents}
            />
          </div>
        </div>

        <div className={"col-6"}>
          <div
            className={
              "callCenterDateRange " +
              (startDateForOutgoing && endDateForOutgoing
                ? "selectedDateRange"
                : "")
            }
          >
            <DateRangePicker
              isOutsideRange={() => false}
              startDate={startDateForOutgoing}
              endDate={endDateForOutgoing}
              startDateId="callCenter_date_input_start_for_incoming"
              minimumNights={0}
              endDateId="callCenter_date_input_end_for_incoming"
              onDatesChange={({ startDate: dateStart, endDate: dateEnd }) => {
                if (dateStart) {
                  setStartDateForOutgoing(dateStart);
                  setDataForApi({
                    ...dataForApi,
                    startDateForOutgoing:
                      moment(dateStart).format("YYYY-MM-DD"),
                  });
                }
                if (dateEnd) {
                  setEndDateForOutgoing(dateEnd);
                  setDataForApi({
                    ...dataForApi,
                    endDateForOutgoing: moment(dateEnd).format("YYYY-MM-DD"),
                  });
                }
                if (dateStart && dateEnd) {
                  dailyCallGrapApiHandler(
                    moment(dateStart).format("YYYY-MM-DD"),
                    moment(dateEnd).format("YYYY-MM-DD"),
                    "outgoing"
                  );
                }
              }}
              focusedInput={focusedInputForOutgoing}
              onFocusChange={(input) => setFocusedInputForOutgoing(input)}
              showClearDates
            />
            <BarChart
              series={outgoingSeries}
              categories={outgoingCategories}
              Proplabel="OutBound Call Logs"
              grapTitles={grapTitlesForOutgoing}
              graphValues={grapValuesForOutgoing}
              grapColors={grapColorsForOutgoing}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupervisorCallsGrap;
