/** @format */
import $ from 'jquery'

export const text_truncate = () => {
  $(document).on('mouseenter', '.max-table-width', function () {
    var $this = $(this)
    if (this.offsetWidth < this.scrollWidth && !$this.attr('title')) {
      $this.tooltip({
        title: $this.text(),
        placement: 'top',
      })
      $this.tooltip('show')
    }
  })
  $('.hideText').css('width', $('.hideText').parent().width())
}
