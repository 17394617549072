/** @format */

/** @format */
import React, { useRef, useState, useEffect } from 'react'
import Slot from './Slot'
import './SlotManagement.scss'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import Label from '../common/Label'
import useOutsideListner from './../../hooks/OutsideListner'
import PropTypes from 'prop-types'
import {
  scrollIntoShiftView,
  scrollIntoCurrentTimeView,
  isCellinShift,
  getCurrentShift,
  getTimeUsingCoords,
  pad,
} from './SlotHelper'
import moment from 'moment'

const useStyles = makeStyles({
  borderTop: { borderTop: '1px solid black !important' },
  borderBottom: { borderBottom: '1px solid black !important' },
  borderLeft: { borderLeft: '1px solid black !important' },
  borderRight: { borderRight: '1px solid black !important' },
  cursorPointer: { cursor: 'pointer' },

  headerTemplate: (props) => ({
    gridTemplateColumns: `repeat(${props.gridHours}, 60px)`,
  }),
  wrapperTemplate: (props) => ({
    gridTemplateColumns: `repeat(${props.gridHours}, 60px)`,
  }),
})
var interval = null

const SlotScheduler = (props) => {
  const classes = useStyles(props)
  const containerRef = useRef(null)
  const mainRef = useRef(null)
  const selectedSlotRange = useRef(null)
  const hours = props.gridHours
  const slotsPerHour = 12
  const slotWidth = 60 //px
  const slotHeight = 40 //px
  const [selectedSlots, setSelectedSlots] = useState([])
  const [selectionStarted, setSelectionStarted] = useState(false)
  const tickRef = useRef(null)
  const [currentHour, setCurrentHour] = useState(moment().format('HH'))
  let tickTockTimer = null
  const scrollInto = (shifts, date) => {
    if (shifts && date && containerRef && containerRef.current) {
      let nShiftsMove = scrollIntoCurrentTimeView()
      // let nShiftsMove = scrollIntoShiftView(shifts, date)
      containerRef.current.scrollLeft = nShiftsMove * slotWidth
    }
  }
  useEffect(() => {
    scrollInto(props.shifts, props.date)
  }, [props.shifts])

  useEffect(() => {
    scrollInto(props.shifts, props.date)
  }, [props.date])

  useEffect(() => {
    if (selectedSlots.length > 0) {
      let lastSelected = selectedSlots[selectedSlots.length - 1]
      lastSelected = lastSelected.split(',')
      const rowIndex = parseInt(lastSelected[0])
      const colIndex = parseInt(lastSelected[1])
      selectedSlotRange.current.style.top = rowIndex * slotHeight + slotHeight + 'px'
      selectedSlotRange.current.style.left = colIndex * slotWidth + 'px'
    }
  }, [selectedSlots])

  // useEffect(() => {
  //   if (tickRef.current) {
  //     tickTockTimer = setInterval(() => {
  //       let thisHour = moment().format('HH')
  //       if (thisHour != currentHour) {
  //         setCurrentHour(thisHour)
  //       }
  //       tickRef.current.style.left = moment().format('mm') + 'px'
  //     }, 10000)
  //   }
  //   return () => {
  //     if (tickTockTimer) {
  //       clearInterval(tickTockTimer)
  //     }
  //   }
  // }, [tickRef.current])

  useOutsideListner(mainRef, (event) => {
    event.stopPropagation()
    // setSelectedSlots([])
    // props.setSelectedSlots([])
  })
  const markConsective = (lastIndex, targetIndex) => {
    let selSlot = []
    let last = lastIndex.split(',')
    let target = targetIndex.split(',')
    let colStart = parseInt(last[1])
    let colEnd = parseInt(target[1])
    for (let col = colStart; col <= colEnd; col++) {
      let rowStart = 0
      let rowEnd = slotsPerHour - 1
      if (col == colStart) rowStart = parseInt(last[0])
      else if (col == colEnd) rowEnd = parseInt(target[0])
      for (let row = rowStart; row <= rowEnd; row++) {
        selSlot.push(row + ',' + col)
      }
    }
    return selSlot
  }
  const handleSlotClick = (rowIndex, colIndex) => {}
  const handleSlotMouseUp = (rowIndex, cellIndex) => {
    setSelectionStarted(false)
    if (selectedSlots.length)
      // props.setSelectedSlots(
      //   getTimeUsingCoords(selectedSlots[0]),
      //   getTimeUsingCoords(selectedSlots[selectedSlots.length - 1], true)
      // )
      props.setSelectedSlots(selectedSlots)
  }
  const handleSlotMouseDown = (rowIndex, cellIndex) => {
    setSelectionStarted(true)
    let sel = []
    let index = rowIndex + ',' + cellIndex
    sel.push(index)
    setSelectedSlots(sel)
  }

  const handleSlotMouseEnter = (rowIndex, cellIndex) => {
    if (selectionStarted) {
      let sel = [...selectedSlots]
      let index = rowIndex + ',' + cellIndex
      let isExistIndex = sel.indexOf(index)
      let lastSelected = sel[sel.length - 1]
      let selected = lastSelected && lastSelected.split(',')
      let target = index.split(',')

      let selectedRow = parseInt(selected[0])
      let selectedCol = parseInt(selected[1])
      let targetRow = parseInt(target[0])
      let targetCol = parseInt(target[1])

      if (isExistIndex > -1) {
        //deselection
        sel.splice(sel.indexOf(sel[sel.length - 1]), 1)
        // lastLeave && sel.splice(sel.indexOf(lastLeave), 1)
      } else {
        //selection
        if (selectedCol < targetCol) {
          let consectiveSlots = markConsective(lastSelected, index)
          sel = [...sel, ...consectiveSlots]
        }
        sel.push(index)
      }
      if (selectedCol > targetCol || selectedRow > targetRow) {
        let consectiveSlots = markConsective(index, lastSelected)
        sel = sel.filter((item) => consectiveSlots.indexOf(item) == -1)
        sel.push(index)
      }

      // filled empty slots when fast scroll in same column
      if (selectedCol === targetCol) {
        for (let row = selectedRow; row <= targetRow; row++) {
          let index = row + ',' + selectedCol
          if (sel.indexOf(index) === -1) sel.push(index)
        }
      }

      setSelectedSlots([...new Set([...sel])])

      // setSelectedSlots(sel)
    }
  }

  // const pad = (d) => (d < 10 ? '0' + d.toString() : d.toString())
  const generateTimeLapse = () => {
    let timeLapse = []
    let startTime = -5
    for (let i = 0; i < slotsPerHour; i++) {
      timeLapse.push(
        <div>
          <Label label={pad((startTime += 5))}></Label>
        </div>
      )
    }
    return <div className={'timeLapse'}>{timeLapse}</div>
  }
  const generateHeader = () => {
    let headers = []
    let currentHour = moment().format('HH')
    let currentMinute = moment().format('mm')

    for (let i = 0; i < hours; i++) {
      let hour = i % 12
      hour = hour == 0 ? 12 : hour

      let isCurrentHour = false
      if (currentHour == i) {
        isCurrentHour = true
      }
      headers.push(
        <div className="headCol">
          {isCurrentHour && (
            <div
              className={'tick'}
              ref={tickRef}
              // style={{ left: moment().format('mm') + 'px' }}
            ></div>
          )}
          <Label
            label={hour + (Math.floor(i / 12) % 2 === 0 ? 'AM' : 'PM')}
            textColor={isCurrentHour ? '#026ff2' : 'black'}
          ></Label>
        </div>
      )
    }
    return (
      <>
        <span
          className={clsx(classes.cursorPointer, 'leftNavigator fa fa-angle-left')}
          onClick={() => {
            containerRef.current.scrollLeft -= slotWidth
          }}
        />
        <div className={clsx('head', classes.headerTemplate)}>{headers}</div>
        <span
          className={clsx(classes.cursorPointer, 'rightNavigator fa fa-angle-right cursor-pointer')}
          onClick={() => {
            containerRef.current.scrollLeft += slotWidth
          }}
        />
      </>
    )
  }
  const generateColumns = () => {
    let cols = []
    for (let i = 0; i < hours; i++) {
      let isCellShift = false
      if (props.shifts) {
        isCellShift = isCellinShift(getCurrentShift(props.shifts, props.date), i)
      }
      cols.push(<div className="slotCol">{generateSlots(i, isCellShift)}</div>)
    }
    return cols
  }
  const checkNeighbour = (rowIndex, colIndex) => {
    let left = colIndex - 1
    let right = colIndex + 1
    let top = rowIndex - 1
    let bottom = rowIndex + 1

    let border = []
    if (selectedSlots.indexOf(top + ',' + colIndex) === -1) {
      border.push(classes.borderTop) //+= ' top'
    }
    if (selectedSlots.indexOf(bottom + ',' + colIndex) === -1) {
      border.push(classes.borderBottom) //+= ' bottom'
    }
    if (selectedSlots.indexOf(rowIndex + ',' + left) === -1) {
      border.push(classes.borderLeft) //+= ' left'
    }
    if (selectedSlots.indexOf(rowIndex + ',' + right) === -1) {
      border.push(classes.borderRight) //+= ' right'
    }
    return border
  }
  const generateSlots = (colIndex, isShift) => {
    let slots = []
    for (let i = 0; i < slotsPerHour; i++) {
      let isSelected = false
      let borderClasses = ''
      if (selectedSlots.indexOf(i + ',' + colIndex) > -1) {
        isSelected = true
        borderClasses = checkNeighbour(i, colIndex)
      }
      let thisDiary = null
      if (props.slotTemplate && props.diaries) {
        let time = i * 5
        let hour = colIndex % 24
        let timeSlot = pad(hour) + ':' + pad(time) + ':00'
        let template = props.slotTemplate.find((t) => t.startTime === timeSlot)
        thisDiary =
          template &&
          props.diaries.find((d) => {
            let selectedDate = moment()
            if (colIndex > 23) {
              selectedDate = selectedDate.add(1, 'days')
            }
            return (
              d.slotId === template.id &&
              moment(d.start).format('YYYY-MM-DD') === selectedDate.format('YYYY-MM-DD')
            )
          })

        //TODO check date also for appended slots
        if (thisDiary && thisDiary.diary) {
          thisDiary.diary = thisDiary.diary.filter((d) => d.status != 'cancelled')
        }
      }
      slots.push(
        <div className={clsx(isSelected ? classes.selected : '', 'slot', borderClasses)}>
          <Slot
            key={'slot' + colIndex + '-' + i}
            isSelected={isSelected}
            rowIndex={i}
            colIndex={colIndex}
            onSlotClick={handleSlotClick}
            handleSlotMouseDown={handleSlotMouseDown}
            handleSlotMouseEnter={handleSlotMouseEnter}
            handleSlotMouseUp={handleSlotMouseUp}
            // hanldeSlotMouseLeave={hanldeSlotMouseLeave}
            classes={borderClasses}
            isCellShift={isShift}
            diary={thisDiary}
            taskType={props.taskType}
          ></Slot>
        </div>
      )
    }
    return slots
  }
  // const getTimeUsingCoords = (coords, end = false) => {
  //   //coords format should be => row,col
  //   let thisCoords = coords
  //   thisCoords = thisCoords.split(',')
  //   let rowIndex = parseInt(thisCoords[0])
  //   let colIndex = parseInt(thisCoords[1])

  //   let minutes = rowIndex * 5 + (end ? 5 : 0)

  //   let hour = colIndex % 12
  //   hour = hour == 0 ? 12 : hour
  //   return hour + ':' + pad(minutes) + (colIndex >= 12 ? ' PM' : ' AM')
  // }
  const getSlotRangeLabel = () => {
    return (
      selectedSlots.length > 0 && (
        <span
          ref={selectedSlotRange}
          className={'selectedSlotRange'}
          onMouseUp={() => {
            handleSlotMouseUp()
          }}
        >
          <Label
            onClick={() => {}}
            label={
              getTimeUsingCoords(selectedSlots[0]).replace(/[ AM PM]/g, '') +
              '-' +
              getTimeUsingCoords(selectedSlots[selectedSlots.length - 1], true).replace(
                /[ AM PM]/g,
                ''
              )
            }
          ></Label>
        </span>
      )
    )
  }

  return (
    <>
      <div ref={mainRef} className="noselect schedular-main">
        {generateTimeLapse()}
        <div
          className={'leftDragZone'}
          onMouseEnter={() => {
            interval = setInterval(() => {
              if (selectionStarted && containerRef && containerRef.current) {
                containerRef.current.scrollLeft -= slotWidth
              }
            }, 1000)
          }}
          onMouseLeave={() => {
            clearInterval(interval)
          }}
        ></div>
        <div ref={containerRef} className="slotContainer no-scrollbar ">
          {generateHeader()}
          <div className={clsx('slotWrapper full ', classes.wrapperTemplate)}>
            {generateColumns()}
            {getSlotRangeLabel()}
          </div>
        </div>
        <div
          className={'rightDragZone'}
          onMouseEnter={() => {
            interval = setInterval(() => {
              if (selectionStarted && containerRef && containerRef.current) {
                containerRef.current.scrollLeft += slotWidth
                // let sel = [...selectedSlots]
                // let lastSelected = sel[sel.length - 1]
                // let splited = lastSelected.split(',')
                // console.log(lastSelected, ' -- ', splited[0] + ',' + ++splited[1])
                // let consectiveSlots = markConsective(lastSelected, splited[0] + ',' + ++splited[1])
                // setSelectedSlots([...sel, ...consectiveSlots])
              }
            }, 800)
          }}
          onMouseLeave={() => {
            clearInterval(interval)
          }}
        ></div>
      </div>
    </>
  )
}

export default React.memo(SlotScheduler)

SlotScheduler.propTypes = {
  setSelectedSlots: PropTypes.func,
  taskType: PropTypes.string,
}

SlotScheduler.defaultProps = {
  setSelectedSlots: () => {},
  taskType: '',
}
