/** @format */
import { PermissionFeatures, PermissionActions } from './permissionConstants'
import { DIARY_MENU_ACTION } from './constant_data'
import { getPermissionValue } from '../components/_unlockComponent'

export const onClickRedirect = (path, props, callback) => {
  switch (path) {
    case '/users':
      return props.history.push('/admin')
    case '/teams':
      return props.history.push('/team')
    case '/projectmain':
      return props.history.push('/add-project')
    case '/organizations':
      return props.history.push('/add-organization')
    case '/clientManagement':
      return props.history.push('/add-client')
    case '/leadCities':
      return callback('/leadCities')
    case '/projectLeads':
    case '/project-lead':
      return props.history.push('/new-lead')
    case '/buyRentLeads':
    case '/buy-rent-lead':
      return props.history.push('/add-buy-rent-lead')
    case '/agentsManagement':
      return callback('/agentsManagement')
    case '/propertyDetails':
      return callback('/propertyDetails')
    case '/armsView':
      return callback('/armsView')
    case '/investmentProducts':
      return props.history.push('/add-product')
    case '/roles':
      return props.history.push('/add-roles')
    case '/office-locations':
      return props.history.push('/add-officeLocations')
    case '/areaGroup':
      return props.history.push('/addAreaGroup')
    case '/diary':
      return callback('/createDiaryTask')
    default:
      return null
  }
}
export const isDisabled = (path, props) => {
  switch (path) {
    case '/diary':
    case '/overdue':
      return (props && props.selectedAgent) ||
        !getPermissionValue(PermissionFeatures.DIARY, PermissionActions.CREATE)
        ? true
        : false
    case '/project-lead':
      return !getPermissionValue(PermissionFeatures.PROJECT_LEADS, PermissionActions.CREATE)
        ? true
        : false

    case '/buy-rent-lead':
      return !getPermissionValue(PermissionFeatures.BUY_RENT_LEADS, PermissionActions.CREATE)
        ? true
        : false

    case '/teams':
      return !getPermissionValue(PermissionFeatures.TEAMS, PermissionActions.CREATE) ? true : false
  }
}
export const compareName = (path, origin) => {
  // const parsed = queryString.parse(props.location.search)

  switch (path) {
    case '/users':
      return '+ Add New User'
    case '/teams':
      return '+ Add New Team'
    case '/projectmain':
      // return '+ Add New Project'
    case '/organizations':
      return '+ Add New Organization'
    case '/clientManagement':
      return '+ Register New Client'
    case '/projectLeads':
    case '/buyRentLeads':
    case '/project-lead':
    // case '/buy-rent-lead':
    //   return '+ Add New Lead'
    case '/agentsManagement':
      return ''
    case '/propertyDetails':
      return origin == 'arms' ? 'Edit Property' : null
    case '/armsView':
      return '+ Add Property'
    case '/investmentProducts':
      return '+ Add Investment Product'
    case '/roles':
      return '+ Add New Role'
    case '/diary':
      // case '/overdue':
      return '+ Create Task'
    case '/office-locations':
      // case '/overdue':
      return '+ Add Office Locations'
    case '/areaGroup':
      // case '/overdue':
      return '+ Add Area Groups'

    default:
      return null
  }
}
export const headerAction = (path, handler = () => {}, isTerminalUser = false) => {
  switch (path) {
    case '/diary':
    case '/overdue':
      return [
        isTerminalUser === false && {
          label: "View Advisor's Diary",
          action: DIARY_MENU_ACTION.VIEW_AGENT_DIARY,
          // permission: {
          // feature: PermissionFeatures.DIARY,
          // action: PermissionActions.TEAM_DIARY_PAGE_VIEW,
          // },
          onClick: function (payload) {
            handler(payload)
          },
        },
        // {
        //   label: 'View My Diary',
        //   action: DIARY_MENU_ACTION.VIEW_MY_DIARY,
        //   onClick: function (payload) {
        //     handler(payload)
        //   },
        // },
        {
          label: 'View Daily Report',
          action: DIARY_MENU_ACTION.VIEW_DIALY_REPORT,
          onClick: function (payload) {
            handler(payload)
          },
        },
      ].filter((f) => f)
  }
}
