/** @format */

import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import Swal from 'sweetalert2'
import config from '../../config'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import Button from './../common/Button'
import moment from 'moment'
import Chip from '../common/Chip'
import { PropertyWorkFlowTile } from './PropertyWorkFlowTile'
import Label from '../common/Label'
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap'
import CircleLoader from '../CircleLoader'
import { isREA } from '../../utils/helper'
import { fetchAllShortlistedProperties } from '../../actions/leads'
import { showToastMsg } from '../../utils/helper'
const useStyles = makeStyles({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '5px',
    marginTop: '10px',
    fontSize: '15px',
    fontWeight: 'bold',
    '& > div': {
      width: '30%',
    },
  },
  tile: {
    border: '1px solid #e0e0e0',
    height: '50px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '12px',
    marginBottom: '5px',
    borderRadius: '5px',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  offerBtnGroup: {
    display: 'flex',
  },
  col: {
    width: '30%',
    textAlign: 'center',
  },
  name: {
    color: '#005ff3',
    fontWeight: 'bold',
  },
  offer: {},
  offerBtn: {
    margin: 'auto',
    background: 'inherit',
    border: '1px solid #417af3',
    borderRadius: '15px',
    width: '80px',
  },
  chatContainer: {
    height: '450px',
    overflow: 'auto',
    minHeight: '450px',
  },
  footerBtn: {
    // background: '#026ff2',
    borderRadius: '20px',
    fontSize: '14px',
    padding: '10px 0px',
    width: '140px',
    //textAlign: 'center',
  },
  footerTxt: {
    height: '30px',
    width: '220px',
    marginBottom: '15px',
    marginRight: '8px',
    fontSize: '12px',
  },
})

let textForButton = ''

const OfferWorkflow = (props) => {
  const [buyerOfferText, setBuyerOfferText] = useState(null)
  const [sellerOfferText, setSellerOfferText] = useState(null)
  const [loader, setLoader] = useState(false)
  let {
    property,
    singleLeadRecord,
    currentUser,
    viewingModal,
    setViewingModal,
    checkForRead,
  } = props
  const classes = useStyles(props)

  const [offerChat, setOfferChat] = useState([])
  const [isAccepted, setOfferAccepted] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    fetchOffers()
  }, [])

  const fetchOffers = () => {
    axios
      .get(
        `${config.apiPath}/api/offer?leadId=${singleLeadRecord.id}&shortlistedPropId=${property.id}`
      )
      .then((res) => {
        if (res && res.data && res.data.rows && res.data.rows.length <= 0) {
          placeOffer('seller', property.price)
        }
        setOfferChat([...res.data.rows])
        setOfferAccepted(isOfferAccepted([...res.data.rows]))
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const placeOffer = (type, offer) => {
    setLoader(true)
    if (offer && type) {
      let body = {
        offer: offer,
        type: type,
      }
      axios
        .post(
          `${config.apiPath}/api/offer?leadId=${singleLeadRecord.id}&shortlistedPropId=${property.id}`,
          body
        )
        .then((res) => {
          setOfferChat([...offerChat, res.data])
        })
        .catch((error) => {
          console.log(error)
        })
      setBuyerOfferText('')
      setSellerOfferText('')
      setLoader(false)
    }
  }
  const acceptOffer = (offer) => {
    axios
      .patch(
        `${config.apiPath}/api/offer/agree?leadId=${singleLeadRecord.id}&offerId=${offer.id}&addedBy=${offer.to}&shortlistedPropId=${property.id}`
      )
      .then((res) => {
        if (res.data.msg) {
          // helper.errorToast(res.data.msg)
        } else {
          setOfferAccepted(true)
          props.fetchAllShortlistedProperties(singleLeadRecord.id)
          showToastMsg(`${textForButton} Accepted Successfully`, 'success')
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
  const isOfferAccepted = (offers = null) => {
    if (!offers) offers = offerChat
    let accepted = offers.find((offer) => {
      return offer.accept_by_customer_id != null || offer.accept_by_armsuser_id != null
    })
    if (accepted) return true
    return false
  }

  const isBuyerAgent = () => {
    if (!property.sellerFlowAgent) {
      return true
    } else {
      return singleLeadRecord.assigned_to_armsuser_id === currentUser.id
    }
  }
  const isSellerAgent = () => {
    if (!property.sellerFlowAgent) {
      return true
    } else {
      return property.sellerFlowAgent.id === currentUser.id
    }
  }

  const getHeaderTile = () => {
    return (
      <div className={classes.header}>
        <div className={classes.col}>BUYER</div>
        <div className={classes.col}></div>
        <div className={classes.col}>SELLER</div>
      </div>
    )
  }

  const getOfferCol = (offer) => {
    return (
      <div className={classes.col}>
        {/* <div className={classes.name}>{name}</div> */}
        <div className={classes.offer} style={{ fontSize: '14px', color: '#016ff2' }}>
          <strong>{'Rs ' + (offer && offer.toLocaleString())}</strong>
        </div>
      </div>
    )
  }

  const getAcceptOfferBtn = (offer, tileType) => {
    return !isAccepted ? (
      <Button
        text="Accept"
        textColor={'#417af3'}
        className={classes.offerBtn}
        onClick={() => acceptOfferToggleFun(offer)}
      ></Button>
    ) : (
      offer.accept_by_armsuser_id && offer.accept_by_customer_id && isAccepted && (
        <Label label="Accepted" textColor="#89b4f6"></Label>
      )
    )
  }

  const [acceptOfferToggle, setAcceptOfferToggle] = useState(false)
  const [currentOffer, setCurrentOffer] = useState({})

  const acceptOfferToggleFun = (offer) => {
    setAcceptOfferToggle(!acceptOfferToggle)
    setCurrentOffer(offer)
  }

  const acceptOfferFun = () => {
    acceptOffer(currentOffer)
    setAcceptOfferToggle(false)
  }

  const getOfferTile = (offer, isLastTile) => {
    let tileType = offer.from == 'customer' ? 'buyer' : 'seller'
    tileType === 'buyer' ? (textForButton = 'Offer') : (textForButton = 'Demand')
    let acceptOffer = ''
    if (isLastTile) {
      acceptOffer = getAcceptOfferBtn(offer, tileType)
    }
    return (
      <div className={clsx(classes.tile)}>
        {tileType == 'buyer' ? (
          getOfferCol(offer.offer)
        ) : (
          <div className={classes.col}>
            {' '}
            {tileType == 'seller' && isBuyerAgent() ? acceptOffer : ''}
          </div>
        )}
        <Chip
          chipColor="inherit"
          borderRadius="15px"
          border="1px solid #e5e5e5"
          text={moment(offer.createdAt).format('dddd MMM D, LT')}
        ></Chip>
        {tileType == 'seller' ? (
          getOfferCol(offer.offer)
        ) : (
          <div className={classes.col}>
            {' '}
            {tileType == 'buyer' && isSellerAgent() ? acceptOffer : ''}
          </div>
        )}
      </div>
    )
  }

  const findLatest = (offerChat) => {
    let buyer_latest = []
    let seller_latest = []
    offerChat.map((offer, index) => {
      if (offerChat[index].from === 'customer') {
        buyer_latest.push(index)
      }
      if (offerChat[index].from === 'seller') {
        seller_latest.push(index)
      }
    })
    return { seller_latest, buyer_latest }
  }

  const getOfferTimeLine = () => {
    let latest_data = findLatest(offerChat)
    return offerChat.map((offer, index) => {
      return getOfferTile(
        offer,
        offer.from === 'customer'
          ? index === latest_data.buyer_latest[latest_data.buyer_latest.length - 1]
          : index === latest_data.seller_latest[latest_data.seller_latest.length - 1]
      )
    })
  }

  const getFooter = () => {
    return (
      <div className={classes.footer}>
        {checkForRead.showReadOnly && !checkForRead.ownLeadReadOnly ? null : (
          <div className="row">
            <div className="col-md-12 text-left">
              <input
                disabled={!isBuyerAgent() || isAccepted}
                className={classes.footerTxt}
                placeholder="Enter Amount"
                type="number"
                value={buyerOfferText}
                onChange={(e) => setBuyerOfferText(e.target.value)}
              />
            </div>
            <div className="col-md-12 text-left">
              <Button
                text={loader ? <i className={`fa fa-circle-o-notch fa-spin`}></i> : 'Place Offer'}
                className={classes.footerBtn}
                textColor="#fff"
                onClick={() => {
                  if (buyerOfferText && buyerOfferText !== '' && buyerOfferText !== null) {
                    placeOffer('customer', buyerOfferText)
                  }
                }}
                disabled={!isBuyerAgent() || isAccepted}
                buttonColor={!isBuyerAgent() || isAccepted ? 'grey' : '#026ff2'}
              />
            </div>
          </div>
        )}

        {checkForRead.showReadOnly && !checkForRead.ownLeadReadOnly ? null : (
          <div className="row">
            <div className="col-md-12 text-right">
              <input
                disabled={!isSellerAgent() || isAccepted}
                className={classes.footerTxt}
                placeholder="Enter Amount"
                type="number"
                value={sellerOfferText}
                onChange={(e) => setSellerOfferText(e.target.value)}
              />
            </div>
            <div className="col-md-12 text-right" style={{ marginLeft: '59%' }}>
              <Button
                text={loader ? <i className={`fa fa-circle-o-notch fa-spin`}></i> : 'Revise Demand'}
                className={classes.footerBtn}
                textColor="#fff"
                onClick={() => {
                  if (sellerOfferText && sellerOfferText !== '' && sellerOfferText !== null) {
                    placeOffer('seller', sellerOfferText)
                  }
                }}
                disabled={!isSellerAgent() || isAccepted}
                disabled={loader}
                buttonColor={!isSellerAgent() || isAccepted ? 'grey' : '#026ff2'}
              />
            </div>
          </div>
        )}
      </div>
    )
  }
  const showCustomer =
    currentUser && singleLeadRecord && singleLeadRecord.assigned_to_armsuser_id === currentUser.id

  // if lead assigned to current user OR lead assigned to current user and property origin must not be arms then show seller (clients offer)
  const showSeller = property && currentUser && property.assigned_to_armsuser_id === currentUser.id

  return (
    <>
      <Modal
        size="lg"
        isOpen={viewingModal}
        toggle={(toggle) => setViewingModal(toggle)}
        style={{ maxWidth: 890, width: '100%' }}
        contentClassName="viewing-modal"
      >
        <ModalBody className="">
          <h6 className="float-left">Place Offer </h6>
          <div className="close-icon-container float-right">
            <i
              className="fal fa-times-circle close-icon"
              onClick={() => {
                setViewingModal(false)
                props.fetchAllShortlistedProperties(singleLeadRecord.id)
              }}
            ></i>
          </div>
          {loading && <CircleLoader loading={loading}></CircleLoader>}
          {!loading && (
            <div className="float-left w-100">
              <PropertyWorkFlowTile property={property}></PropertyWorkFlowTile>
              {getHeaderTile()}
              <div className={classes.chatContainer}>{getOfferTimeLine()}</div>
              {getFooter()}
            </div>
          )}
        </ModalBody>
      </Modal>

      <Modal
        isOpen={acceptOfferToggle}
        toggle={acceptOfferToggleFun}
        dialogClassName={`modal-main-legal`}
        style={{ top: '15%' }}
      >
        <ModalHeader>Accept {textForButton}</ModalHeader>
        <ModalBody>
          <p>Are you sure you want to accept this {textForButton}?</p>
        </ModalBody>
        <ModalFooter>
          <Button
            text={'Cancel'}
            textColor={'white'}
            display={'inline-block'}
            buttonColor="#026ff2"
            className={'px-4 mr-2 bg-secondary'}
            onClick={() => {
              setAcceptOfferToggle(false)
            }}
          />
          <Button
            text={`Accept ${textForButton}`}
            textColor={'white'}
            display={'inline-block'}
            // buttonColor="#026ff2"
            className={'px-4 mr-2 bg-success text-white'}
            onClick={() => acceptOfferFun(currentOffer)}
          />
        </ModalFooter>
      </Modal>
    </>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchAllShortlistedProperties: (lead_id) => {
      dispatch(fetchAllShortlistedProperties(lead_id))
    },
  }
}

export const mapStateToProps = (state) => {
  return {
    currentUser: state.user.userInfo,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OfferWorkflow)
