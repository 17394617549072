/** @format */

import Swal from 'sweetalert2'
import * as ActionTypes from '../actions/leads'
const initialState = {
  tasksFlag: false,
  followUpTaskFlag: false,
  tasks: [],
}

const Leads = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_LEAD:
      return {
        ...state,
        leads: action.data,
      }

    case ActionTypes.FETCH_CLIENT_LEAD:
      return {
        ...state,
        client_leads: action.data,
      }
    case ActionTypes.LEAD_LOADING:
      return {
        ...state,
        loading: true,
      }
    case ActionTypes.CLIENT_LEAD_LOADING:
      return {
        ...state,
        cloading: true,
      }

    case ActionTypes.LEAD_LOADING_SUCCESS:
      return {
        ...state,
        loading: false,
      }

    case ActionTypes.CLIENT_LEAD_LOADING_SUCCESS:
      return {
        ...state,
        cloading: false,
      }
    case ActionTypes.FETCH_BR_LEAD:
      return {
        ...state,
        brLeads: action.data,
      }
    case ActionTypes.FETCH_BR_CLIENT_LEAD:
      return {
        ...state,
        client_brleads: action.data,
      }
    case ActionTypes.FETCH_BR_LEAD_LOAD:
      return {
        ...state,
        brloading: true,
      }
    case ActionTypes.FETCH_BR_CLIENT_LEAD_LOAD:
      return {
        ...state,
        cbrloading: true,
      }
    case ActionTypes.BR_LEAD_LOADING_SUCCESS:
      return {
        ...state,
        brloading: false,
      }
    case ActionTypes.CLIENT_BR_LEAD_LOADING_SUCCESS:
      return {
        ...state,
        cbrloading: false,
      }
    case ActionTypes.FETCH_USERS:
      return {
        ...state,
        users: action.data,
      }

    case ActionTypes.FETCH_USER_LOADING:
      return {
        ...state,
        userLoading: true,
      }

    case ActionTypes.FETCH_USER_SUCCESS:
      return {
        ...state,
        userLoading: false,
      }
    case ActionTypes.FETCH_ACCOUNT_VIEW:
      return {
        ...state,
        accountView: action.data,
      }
    case ActionTypes.FETCH_ACCOUNT_VIEW_LOADER:
      return {
        ...state,
        accountViewLoader: action.data,
      }
    case ActionTypes.FETCH_ACCOUNT_VIEW_SUCCESS:
      return {
        ...state,
        accountViewLoader: action.data,
      }

    case ActionTypes.FETCH_BUY_RENT_SALES_VIEW:
      return {
        ...state,
        buyRentSalesView: action.data,
      }
    case ActionTypes.FETCH_BUY_RENT_SALES_VIEW_LOADER:
      return {
        ...state,
        buyRentSalesViewLoader: action.data,
      }
    case ActionTypes.FETCH_BUY_RENT_SALES_VIEW_SUCCESS:
      return {
        ...state,
        buyRentSalesViewLoader: action.data,
      }
    case ActionTypes.FETCH_PAYMENT_DETAIL:
      return {
        ...state,
        paymentDetails: action.data,
      }
    case ActionTypes.FETCH_PAYMENT_DETAIL_LOADER:
      return {
        ...state,
        paymentDetailLoader: action.data,
      }
    case ActionTypes.FETCH_PAYMENT_DETAIL_SUCCESS:
      return {
        ...state,
        paymentDetailLoader: action.data,
      }
    case ActionTypes.FETCH_ALL_CLIENT:
      return {
        ...state,
        allClients: action.data,
      }
    case ActionTypes.FETCH_SINGLE_INVESTMENT_LEAD:
      return {
        ...state,
        singleLeadRecord: action.data,
      }
    case ActionTypes.FETCH_SINGLE_INVESTMENT_LOADER:
      return {
        ...state,
        singleLeadRecordLoader: action.data,
      }
    case ActionTypes.FETCH_SINGLE_INVESTMENT_SUCCESS:
      return {
        ...state,
        singleLeadRecordLoader: action.data,
      }
    case ActionTypes.FETCH_LEAD_TIMELINE:
      return {
        ...state,
        leadTimeLineData: action.data,
      }
    case ActionTypes.FETCH_LEAD_TIMELINE_LOADER:
      return {
        ...state,
        leadTimeLineLoader: action.data,
      }
    case ActionTypes.FETCH_LEAD_TIMELINE_SUCCESS:
      return {
        ...state,
        leadTimeLineLoader: action.data,
      }
    case ActionTypes.ADD_DIARY_CALL:
      return {
        ...state,
        diaryCall: action.data,
      }
    case ActionTypes.LEAD_CUSTOM_LOADER:
      return {
        ...state,
        leadCustomLoader: action.data,
      }
    case ActionTypes.SET_DIARY_DATE:
      return {
        ...state,
        diaryDate: action.data,
      }
    case ActionTypes.SET_DIARY_TIME:
      return {
        ...state,
        diaryTime: action.data,
      }
    case ActionTypes.SET_DIARY_END_TIME:
      return {
        ...state,
        diaryEndTime: action.data,
      }
    case ActionTypes.ADD_DIARY_MEETING:
      return {
        ...state,
        addDiaryMeeting: action.data,
      }
    case ActionTypes.FETCH_ALL_COMMENTS:
      return {
        ...state,
        allComments: action.data,
      }
    case ActionTypes.FETCH_ALL_COMMENTS_LOADER:
      return {
        ...state,
        allCommentsLoader: action.data,
      }
    case ActionTypes.ADD_COMMENT:
      return {
        ...state,
        addComment: action.data,
      }
    case ActionTypes.ADD_COMMENT_LOADER:
      return {
        ...state,
        addCommentLoader: action.data,
      }
    case ActionTypes.SET_DESCRIPTION:
      return {
        ...state,
        setDescription: action.data,
      }
    case ActionTypes.ADD_TASK_SUBJECT:
      return {
        ...state,
        setTaskTitle: action.data,
      }
    case ActionTypes.ADD_SELECTED_AGENTS:
      return {
        ...state,
        setSelectedLeads: action.data,
      }
    case ActionTypes.SET_CLOSED_OPTIONS:
      return {
        ...state,
        setClosedOption: action.data,
      }
    case ActionTypes.ADD_DETAIL:
      return {
        ...state,
        addDettail: action.data,
      }
    case ActionTypes.FETCH_FRESH_LEADS:
      return {
        ...state,
        freshLeads: action.data,
      }
    case ActionTypes.FETCH_FRESH_BR_LEADS:
      return {
        ...state,
        freshBrLeads: action.data,
      }
    case ActionTypes.ADD_DETAIL_LOADER:
      return {
        ...state,
        addDetailLoader: action.data,
      }
    case ActionTypes.ADD_TOKEN_LOADER:
      return {
        ...state,
        addTokenLoader: action.data,
      }
    case ActionTypes.ADD_PAYMENT:
      return {
        ...state,
        addPayment: action.data,
      }
    case ActionTypes.ADD_PAYMENT_LOADER:
      return {
        ...state,
        addPaymentLoader: action.data,
      }
    case ActionTypes.ADD_BANK_LOADER:
      return {
        ...state,
        addBankLoader: action.data,
      }
    case ActionTypes.ADD_NEXTOFKIN_LOADER:
      return {
        ...state,
        addNextOfKinLoader: action.data,
      }
    case ActionTypes.ADD_TAX:
      return {
        ...state,
        addTax: action.data,
      }
    case ActionTypes.ADD_TAX_LOADER:
      return {
        ...state,
        addTaxLoader: action.data,
      }
    case ActionTypes.FETCH_SINGLE_PAYMENT:
      return {
        ...state,
        singlePayment: action.data,
      }
    case ActionTypes.EDIT_SINGLE_PAYMENT:
      return {
        ...state,
        editPayment: action.data,
      }
    case ActionTypes.TOGGLE_EDIT:
      return {
        ...state,
        editToggle: action.data,
      }
    case ActionTypes.INVESTMENT_CALL_LOADER:
      return {
        ...state,
        investmentCallLoader: action.data,
      }
    case ActionTypes.INVESTMENT_CALL:
      return {
        ...state,
        investmentCall: action.data,
      }
    case ActionTypes.FETCH_USER_CLIENTS:
      return {
        ...state,
        userClients: action.data,
      }
    case ActionTypes.FETCH_CLIENTS_BANK:
      return {
        ...state,
        clientsBank: action.data,
      }
    case ActionTypes.FETCH_Next_Kin:
      return {
        ...state,
        nextKin: action.data,
      }
    case ActionTypes.ADD_LEAD:
      return {
        ...state,
        addLead: action.data,
      }
    case ActionTypes.ADD_LEAD_LOADER:
      return {
        ...state,
        addLeadLoader: action.data,
      }
    case ActionTypes.ADD_MEETING:
      return {
        ...state,
        addMeeting: action.data,
      }
    case ActionTypes.ADD_MEETING_LOADER:
      return {
        ...state,
        addMeetingLoader: action.data,
      }
    case ActionTypes.SHARE_LEAD_LOADER:
      return {
        ...state,
        shareLeadLoader: action.data,
      }
    case ActionTypes.ADD_TASK_LOADER:
      return {
        ...state,
        addTaskLoader: action.data,
      }
    case ActionTypes.LEAD_EDIT_TOGGLE:
      return {
        ...state,
        leadEditToggle: action.data,
      }
    case ActionTypes.EDIT_DIARY:
      return {
        ...state,
        editDiary: action.data,
      }
    case ActionTypes.TOGGLE_LEAD_TAB:
      return {
        ...state,
        toggleTabState: action.data,
      }
    case ActionTypes.FETCH_PUBLISHED_PRODUCTS:
      return {
        ...state,
        publishedProducts: action.data,
      }
    case ActionTypes.FETCH_LEAD_TASKS:
      return {
        ...state,
        tasks: action.payload,
        tasksFlag: action.leadTaskFlag,
      }
    case ActionTypes.FETCH_FOLLOWUP_LEAD_TASKS:
      return {
        ...state,
        followUpTasks: action.payload,
        followUpTaskFlag: action.leadTaskFlag,
      }
    case ActionTypes.FETCH_ALL_ATTACHMENT_LOADER:
      return {
        ...state,
        attachmentLoader: action.data,
      }
    case ActionTypes.FETCH_ALL_ATTACHMENTS:
      return {
        ...state,
        attachments: action.data,
      }
    case ActionTypes.ACTIVE_INVESTMENT_LEAD_TAB:
      return {
        ...state,
        activeInvestmentLeadTab: action.data,
      }
    case ActionTypes.FETCH_LEAD_ATTACHMENTS:
      return {
        ...state,
        leadAttachments: action.data,
      }

    case ActionTypes.FETCH_ATTACHMENTS_LOADER:
      return {
        ...state,
        leadAttachmentLoader: action.data,
      }

    case ActionTypes.FETCH_BUY_RENT_SHORTLISTED_PROPERTIES:
      return {
        ...state,
        buyRentShortListProperties: action.data,
      }
    case ActionTypes.EXCLUDE_LEAD:
      return {
        ...state,
        excludeLead: action.data,
      }
    case ActionTypes.BUY_RENT_QUICK_FILTER:
      return {
        ...state,
        buyRentQuickFilter: action.data,
      }
    case ActionTypes.PROJECT_LEAD_QUICK_FILTER:
      return {
        ...state,
        projectLeadQuickFilter: action.data,
      }
    case ActionTypes.IS_CLIENT_FILTER_LOADING:
      return {
        ...state,
        isClientFilterLoading: action.data,
      }
    case ActionTypes.FETCH_RENTAL_VIEW:
      return {
        ...state,
        rentalView: action.data,
      }
    case ActionTypes.FETCH_ADD_BOOKING:
      return {
        ...state,
        addLeadBooking: action.data,
      }
    case ActionTypes.FETCH_LEAD_BOOKING:
      return {
        ...state,
        leadBooking: action.data,
      }
    case ActionTypes.FETCH_RENTAL_VIEW_LOADER:
      return {
        ...state,
        rentalViewLoader: action.data,
      }
    case ActionTypes.FETCH_RENTAL_VIEW_SUCCESS:
      return {
        ...state,
        rentalViewLoader: action.data,
      }
    case ActionTypes.FETCH_ADD_BOOKING_SUCCESS:
      return {
        ...state,
        addLeadBookingLoader: action.data,
      }
    case ActionTypes.FETCH_LEAD_BOOKING_SUCCESS:
      return {
        ...state,
        leadBookingLoader: action.data,
      }
    case ActionTypes.LAST_ADDED_LEAD:
      return {
        ...state,
        lastAddedLead: action.data,
      }
    case ActionTypes.FETCH_LEAD_ORIGINS:
      return {
        ...state,
        origins: action.data,
      }
    case ActionTypes.AUTO_LEAD_ASSIGNMENT_LOADER:
      return {
        ...state,
        autoLeadAssignmentLoader: action.data,
      }
    case ActionTypes.AUTO_LEAD_ASSIGNMENT:
      return {
        ...state,
        autoLeadAssignment: action.data,
      }
    case ActionTypes.SELECT_CUSTOMER_DETAIL:
      return {
        ...state,
        selectedCustomerDetail: action.data,
      }
    case ActionTypes.FETCH_ALL_PREVIOUS_ADVISORS:
      return {
        ...state,
        previousAdvisors: action.data,
      }
    case ActionTypes.FETCH_ALL_ORIGINS:
      return {
        ...state,
        allOrigins: action.data,
      }
    default:
      return {
        ...state,
      }
  }
}

export default Leads
