/** @format */

const { capitalize_Words } = require('./commonFormat')

const SelectMethods = {
  RenderList(data, itemId, itemName, nestedObj) {
    let list =
      data &&
      data.map((item) =>
        nestedObj !== null
          ? {
              label: item[nestedObj] && item[nestedObj][itemName],
              value: item[nestedObj] && item[nestedObj][itemId],
              customValue: item[nestedObj] && item[nestedObj][itemName].replace(/[^\w\s]/gi, ''),
            }
          : {
              label: item[itemName],
              value: item[itemId],
              customValue: item[itemName].replace(/[^\w\s]/gi, ''),
            }
      )

    return list
  },
  ListMasking(data, itemId, itemName, checkSameParams) {
    if (checkSameParams) {
      return { label: capitalize_Words(data), value: data }
    } else {
      return { label: data[itemName], value: data[itemId] }
    }
  },
}

module.exports = SelectMethods
