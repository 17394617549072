/** @format */

import config from '../config'
import axios from 'axios'
import { shouldShowAllLeads } from './helper'

export const fetchAllAgents = (excludeTerminalUsers = null, cb) => {
  const role_id = localStorage.getItem('roleId')
  const isGroupManager = localStorage.getItem('groupManager')

  let groupManagerParam = ''
  if (
    (isGroupManager && isGroupManager === true) ||
    isGroupManager.toLocaleLowerCase() === 'true'
  ) {
    groupManagerParam = `&groupManager=true`
  }
  let url = `${config.apiPath}/api/role/sub-users?roleId=${role_id}${groupManagerParam}`
  if (excludeTerminalUsers) url += '&excludeTerminal=true'

  axios.get(url).then((res) => {
    let array = []
    res &&
      res.data &&
      res.data.length > 0 &&
      res.data.filter((item) => {
        return array.push({
          label:item &&  item.firstName + ' ' + item && item.lastName,
          value: item && item.id,
        })
      })

    cb(array)
  })
}

export const fetchAllOrganizations = (cb) => {
  axios.get(`${config.apiPath}/api/user/organizations?limit=2`).then((res) => {
    let object = []
    res.data.rows &&
      res.data.rows.length &&
      res.data.rows.filter((item) => {
        return object.push({ label: item.name, value: item.name })
      })
    cb(object)
  })
}

export const fetchCountry = (cb) => {
  axios.get(`${config.apiPath}/api/user/getCountryCodes`).then((res) => {
    let countriesListWihCode = res.data.map((item) => {
      return {
        label: item.name,
        value: item.phone,
      }
    })
    cb(countriesListWihCode)
  })
}

export const fetchReferredAgents = (user, leadType, page = 1, name = null, cb) => {
  if(user) {
    let allOrg = shouldShowAllLeads(user)
    let url = `${
      config.apiPath
    }/api/user/agents?sharing=${true}&page=${page}&pageSize=${config.pageSize}&web=true&leadType=${leadType}`
    if (name)
      url += `&name=${name}`
    if (allOrg)
      url += `&allOrg=true`
    axios
      .get(url)
      .then((res) => {
        cb(res.data)
      })
  }
}
