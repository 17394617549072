export const DateFormat = (currentDate) => {
    var today = new Date(currentDate);
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    var result = dd + '-' + mm + '-' + yyyy;
    return result
}

export const dateAddDays = (days, date=null) => {
  let result = date !== null ? new Date(date): new Date()
  result.setDate(result.getDate() + days);
  return result;
}

export const calculateDateDiff = (from, to =null) => {
  if(to === null)
    to = new Date()
  else
    to = new Date(to)

  let diff = (to - from); // milliseconds
  let diffDays = Math.floor(diff / 86400000); // days
  let diffHrs = Math.floor((diff % 86400000) / 3600000); // hours
  let diffMins = Math.round(((diff % 86400000) % 3600000) / 60000); // minutes
  if(diffMins < 0 || diffHrs < 0 || diffDays)
    return null

  diff = `${diffMins}min`
  if(diffHrs)
    diff = `${diffHrs}hrs, ${diff}`

  if(diffDays === 1)
    diff = `${diffDays}day, ${diff}`
  else if(diffDays > 1)
    diff = `${diffDays}days, ${diff}`
  return diff
}

export const formatDate = (formate = '') => {

}
