/** @format */

import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Form, Modal, ModalBody } from 'reactstrap'
import ConnectForm from './ConnectForm'
import ClientContact from './ClientContact'
import moment from 'moment'
import ClientHistory from '../../common/ClientHistory'
import { fetchAllLeadAttachments, fetchLeadTasks, fetchSingleLead } from '../../../actions/leads'
import { connect } from 'react-redux'
import ConnectInfo from './ConnectInfo/index'
import Attachment from './Attachment'
import axios from 'axios'
import config from '../../../config'
import { connectFormReqFields, missingFieldHtml } from '../../../utils/validate'
import Swal from 'sweetalert2'
import ClientLead from './ClientLead'
import { getLeadType, scrollToBottom } from '../../../utils/helper'
import LeadDetailHeader from './LeadDetailHeader'
import { backUrl } from '../../../actions/generalSetting'
import { diaryCreateUpdateAPI } from '../../../utils/diary'
import { capitalizeWordsWithoutUnderscore } from '../../../utils/commonFormat'
import ReadOnlyAlert from '../../ReadOnlyAlert'
import { getPermissionValue } from '../../_unlockComponent'
import { PermissionActions, PermissionFeatures } from '../../../utils/permissionConstants'
import { assignedToMe, isLeadClosed } from '../../../utils/lead'
import Button from '../../common/Button'
import Loader from 'react-loader-spinner'

const useStyles = makeStyles({
  root: {
    display: 'grid',
    gridTemplateColumns: '45% 55%',
    gridTemplateAreas: '"HISTORY CLIENT"\n' + '"CONNECT CONNECT"',
  },
  historySection: {
    display: 'grid',
    gridTemplateRows: '85% 13%',
    gridTemplateColumns: '100%',
    gridRowGap: 10,
    gridArea: 'HISTORY',
    background: '#fafafa',
    maxHeight: 610,
    overflow: 'hidden',
  },
  formSection: { gridArea: 'CLIENT' },
  connectSection: { gridArea: 'CONNECT' },
  attachment: {},
  // modal: {overflowY:'hidden!important'},
  modalDialog: {
    transform: 'translate(0, -20px)!important',
  },
})

const ConnectModal = (props) => {
  const classes = useStyles(props)
  const [tasks, setTasks] = useState([])
  const [leadCount, setLeadCount] = useState(0)
  const [requiredFieldCheck, setRequiredFieldCheck] = useState(false)
  const [leadModal, setLeadModal] = useState(false)
  const [leadProperty, setLeadProperty] = useState(null)
  const [newInfo, setNewInfo] = useState(null)
  const [purpose, setPurpose] = useState(null)
  const { leadType } = props
  const [lead, setLead] = useState(props.lead)
  const [isValidPhone, setIsValidPhone] = useState(false)
  const [loading, setLoading] = useState(false)
  const [leadRefresh, setLeadRefresh] = useState(false)
  const [diaryId, setDiaryId] = useState(null)

  const fetchLeadCount = () => {
    axios
      .get(`${config.apiPath}/api/leads/count`, {
        params: {
          leadTypes: ['buyrent', 'project', 'wanted'],
          customerId: lead.customer.id,
        },
      })
      .then((res) => {
        setLeadCount(res.data.leadCount)
      })
      .catch((error) => {})
  }

  const fetchLeadProperty = () => {
    setLeadProperty({ id: lead.properties[0].id })
    // axios
    //   .get(`${config.apiPath}/api/property/one-property/${lead.properties[0].id}`)
    //   .then((res) => {
    //     setLeadProperty(res.data)
    //   })
    //   .catch((error) => {})
  }

  useEffect(() => {
    if (lead && lead.customer) fetchLeadCount()

    if (leadType === 'Wanted' && lead && lead.properties && lead.properties.length)
      fetchLeadProperty()
  }, [])

  useEffect(() => {
    if (lead && lead.wanted)
      setLead(props.lead)
    else
      setLead({...lead, status: props.lead && props.lead.status ? props.lead.status : null })
  }, [props.lead])

  const onSubmitConnectForm = (data) => {
    const cC = lead.customer.customerContacts
    let newCustomerContacts = []
    for (let i = 0; i <= 2; i++) {
      if (data[`phone${i}`].phone) {
        if (cC && cC[i]) newCustomerContacts.push({ id: cC[i].id, ...data[`phone${i}`] })
        else newCustomerContacts.push(data[`phone${i}`])
      } else if (cC && cC[i]) newCustomerContacts.push(cC[i])
    }

    let dataToUpdate = {
      customer_id: lead.customer.id,
      customer: {
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        phone: newCustomerContacts[0] && newCustomerContacts[0].phone,
        dialCode: newCustomerContacts[0] && newCustomerContacts[0].dialCode,
        countryCode: newCustomerContacts[0] && newCustomerContacts[0].countryCode,
        address: data.address,
        secondary_address: data.secondary_address,
        cnic: data.cnic != '' && data.cnic != null ? data.cnic.replace(/-/g, '') : null,
        customersContacts: newCustomerContacts,
      },
    }
    if (leadType === 'Wanted') {
      dataToUpdate = {
        ...dataToUpdate,
        description: data.description,
        purpose: data.purpose.value,
      }

      if (data.purpose.value === 'invest') {
        if (data.projectType) {
          dataToUpdate.productId = data.projectType.value
          dataToUpdate.projectType = data.projectType.label
        }
        if (data.project) {
          dataToUpdate.projectId = data.project.value
          dataToUpdate.projectName = data.project.label
        }
        if (data.priceRanger) {
          dataToUpdate.max_price = data.priceRanger.price_max
          dataToUpdate.min_price = data.priceRanger.price_min
        }
      } else if (data.purpose.value !== 'sell' && data.purpose.value !== 'rentout') {
        if (data.type) dataToUpdate.type = data.type.value
        if (data.subtype) dataToUpdate.subtype = data.subtype.value
        dataToUpdate.areas = data.areas.map((a) => a.value)
        if (data.city) dataToUpdate.city_id = data.city.value
        if (data.priceRanger) {
          dataToUpdate.max_price = data.priceRanger.price_max
          dataToUpdate.min_price = data.priceRanger.price_min
        }
        if (data.sizeRanger) {
          dataToUpdate.size = data.sizeRanger.size_min
          dataToUpdate.max_size = data.sizeRanger.size_max
          dataToUpdate.size_unit = data.sizeRanger.unit.value
        }

        if (data.type.value === 'residential') {
          dataToUpdate = {
            ...dataToUpdate,
            bed: data.bedRanger.minVal,
            [leadType === 'Wanted' ? 'bed_max' : 'maxBed']: data.bedRanger.maxVal,
            bath: data.bathRanger.minVal,
            [leadType === 'Wanted' ? 'bath_max' : 'maxBath']: data.bathRanger.maxVal,
          }
        }
      }
    }
    if (
      data.purpose.value == 'sale' ||
      data.purpose.value == 'buy' ||
      data.purpose.value == 'rent'
    ) {
      if (dataToUpdate.areas.length > 0) {
        return axios
          .patch(
            `${config.apiPath}/api/wanted?id=${lead.id}&leadType=${leadType}&showOnly=true`,
            dataToUpdate
          )
          .then((res) => {
            if (res.data.id != props.currentUser.id) {
              Swal.fire({
                html: `Lead will be assigned to ${
                  res.data && res.data.firstName ? res.data.firstName + ' ' : ''
                }${
                  res.data && res.data.lastName ? res.data.lastName : ''
                } based on the first selected area ${
                  data.areas.length > 0 ? data.areas[0].label : ''
                }. Are you sure you want to continue?`,
                showCancelButton: true,
                confirmButtonText: 'Save',
              }).then((result) => {
                if (result.isConfirmed) {
                  return updateDataCall(lead.id, leadType, dataToUpdate, data)
                } else {
                  props.setShouldAssign(false)
                  return true
                }
              })
            } else return updateDataCall(lead.id, leadType, dataToUpdate, data)
          })
          .catch((err) => {
            return updateDataCall(lead.id, leadType, dataToUpdate, data)
          })
      }
    } else {
      return updateDataCall(lead.id, leadType, dataToUpdate, data)
    }
  }
  const updateDataCall = (leadId, leadType, dataToUpdate, data) => {
    return axios
      .patch(`${config.apiPath}/api/wanted?id=${leadId}&leadType=${leadType}`, dataToUpdate)
      .then((res) => {
        // set updated data in same format as initially load ed
        dataToUpdate = {
          ...dataToUpdate,
          purpose: data.purpose.value,
          city: data.city,
          wanted_areas: data.areas,
          properties: leadProperty ? [leadProperty] : null,
        }
        setNewInfo(dataToUpdate)

        if (data.shouldAssign) {
          let missingRequiredFields = missingFieldHtml(dataToUpdate)

          if (missingRequiredFields.length) {
            Swal.fire({
              title: '',
              html: `<p class='text-left mb-1 font-weight-bold'>Following mandatory field(s) are required before leads assignment:</p>${missingRequiredFields}`,
              icon: 'error',
              width: 650,
            })
            props.setShouldAssign(false)

            if (props.callBack) props.callBack()
          } else {
            props.convertLead(lead)
            if (props.updateDiary)
              props.updateDiary({
                dataToUpdate,
                propertyId: leadProperty ? leadProperty.id : null,
              })
          }
        } else {
          Swal.fire('Success', 'Details updated successfully', 'success')
          if (props.updateDiary) props.updateDiary(dataToUpdate)

          if (props.callBack) props.callBack()
        }
      })
  }

  const storeBackUrl = (data) => {
    const { backUrl } = props
    backUrl(`${data}?page=1&pageSize=${config.pageSize}`)
  }

  const renderLeadTask = () => {
    const leadTaskParams = {
      taskType: ['connect', 'meeting', 'task', 'follow_up', 'viewing'],
      [leadType === 'Project'
        ? 'cmLeadId'
        : leadType === 'BuyRent'
        ? 'rcmLeadId'
        : 'wantedId']: lead.id,
    }
    props.fetchLeadTasks(lead.id, leadTaskParams, (data) => {
      if(!props.diary && data){
        const connectTask = data.filter((task) => { return task.taskType === 'connect' && task.status !== 'completed'})
        const followUpTask = data.filter((task) => { return task.taskType === 'follow_up' && task.status !== 'completed'})
        if(connectTask[0] && connectTask[0].id){
          setDiaryId(connectTask[0].id)
        }else if(followUpTask[0] && followUpTask[0].id){
          setDiaryId(followUpTask[0].id)
        }
      }
      setTasks(data)
      scrollToBottom('task-history')
    })
  }

  useEffect(() => {
    if (lead && leadType !== 'Wanted' && lead.id) {
      // props.fetchSingleLeadProps(lead.id, leadType === 'Project' ? true : false, (data) => {
      //   setLead(data)
      // },leadType === 'Project' ? true : true)
      props.fetchAllLeadAttachments(lead.id, leadType)
    }
  }, [])

  useEffect(() => {
    lead && renderLeadTask()
  }, [props.updateLeadTasks])

  const onCallCompleted = (type, number) => {
    const { diary, setNewTask } = props

    let data = {
      calledOn: type != 'phone' ? 'whatsApp' : 'phone',
      title: type != 'phone' ? 'whatsApp' : 'phone',
      calledNumber: number,
    }

    if (diary) {
      setNewTask({
        ...data,
        id: diary.id,
      })
    } else {
      const connectTask = tasks.filter(
        (task) =>
          ['connect', 'follow_up'].indexOf(task.taskType) > -1 && task.status !== 'completed'
      )
      if (connectTask.length)
        setNewTask({
          ...connectTask[0],
          ...data,
        })
      else {
        setLoading(true)
        diaryCreateUpdateAPI(
          {
            subject: `${capitalizeWordsWithoutUnderscore('connect')} scheduled`,
            taskCategory: 'leadTask',
            taskType: 'connect',
            status: 'pending',
            currentSlot: true,
            [leadType === 'Project'
              ? 'leadId'
              : leadType === 'BuyRent'
              ? 'armsLeadId'
              : 'wantedId']: lead.id,
          },
          false,
          (data) => {
            setLoading(false)
            if (data && data.length === 2 && data[1].length) {
              setNewTask(data[1][0])
            } else
              setNewTask(data)
          }
        )
      }
    }
  }
  const isLeadRefresh = (value) => {
    setLeadRefresh(value)
  }
  return (
    <>
      <Modal
        isOpen={props.connectModal}
        toggle={(toggle) => props.setConnectModal(!toggle)}
        contentClassName={`no-custom`}
        className={`${classes.modalDialog}`}
        modalClassName={`${classes.modal}`}
        size={'xl'}
        backdrop={props.again ? 'static' : false}
      >
        <ModalBody className={'p-0'}>
          {lead ? <>
          <LeadDetailHeader
            isLeadRefresh={isLeadRefresh}
            leadType={leadType}
            lead={props.lead.id === lead.id ? lead : props.lead}
            purpose={purpose}
            leadProperty={leadProperty}
            storeBackUrl={storeBackUrl}
            isValidPhone={isValidPhone}
            assignLoader={props.shouldAssign}
            setShouldAssign={(toggle) => props.setShouldAssign(toggle)}
            action={props.action}
            onAction={(action) => props.onAction(action)}
            onClose={() => {
              if (props.fromClient) {
                props.history.push('/diary')
              }
              props.setConnectModal(false, leadRefresh)
            }}
            onSubmit={(data) => {
              props.setShouldAssign(true)
              onSubmitConnectForm({ ...data, shouldAssign: true })
            }}
          />

          <div className="float-left w-100 border-top">
            {lead && 'assigned_to_armsuser_id' in lead && <>
            {['BuyRent', 'Project'].indexOf(leadType) > -1 ? (
              (!getPermissionValue(
                leadType === 'Project'
                  ? PermissionFeatures.PROJECT_LEADS
                  : PermissionFeatures.BUY_RENT_LEADS,
                PermissionActions.UPDATE
              ) ||
                !assignedToMe(lead)) && (
                <div className={`showToggle`}>
                  <ReadOnlyAlert />
                </div>
              )
            ) : !getPermissionValue(PermissionFeatures.WANTED_LEADS, PermissionActions.UPDATE) ||
              ['rejected', 'assigned'].indexOf(lead.armsStatus) > -1 ? (
              <div className={`showToggle`}>
                <ReadOnlyAlert />
              </div>
            ) : (
              ''
            )}
            </> }
            <div className={classes.root}>
              <div className={`${classes.historySection} pt-4`}>
                <div className={`pl-4 pr-2 ${classes.activity}`}>
                  <div className={'d-flex justify-content-between mt-1 pr-3'}>
                    <h4>History</h4>
                    {leadCount && leadCount - 1 > 0 ? (
                      <small className={'mt-2'}>
                        This client has <strong>{leadCount ? leadCount - 1 : 0}</strong> other
                        leads. &nbsp;
                        <span
                          style={{ cursor: 'pointer' }}
                          onClick={() => setLeadModal(true)}
                          className={'text-decoration-underline text-primary'}
                        >
                          View
                        </span>
                      </small>
                    ) : (
                      <></>
                    )}
                  </div>
                  {!props.tasksFlag ? (
                    <ClientHistory history={tasks}></ClientHistory>
                  ) : (
                    <div className={'d-flex justify-content-center align-items-center h-75'}>
                      <span className="fa fa-circle-o-notch fa-spin text-center text-primary"></span>
                    </div>
                  )}
                </div>
                <div className={`px-4 ${classes.attachment} d-flex align-items-center`}>
                  <Attachment attachments={props.leadAttachments} />
                </div>
              </div>
              <div className={`${classes.formSection} px-4 pt-4 pb-3`}>
                {leadType === 'Wanted' && ['connect'].indexOf(props.action) > -1 ? (
                  <ConnectForm
                    requiredFieldCheck={requiredFieldCheck}
                    leadType={leadType}
                    lead={lead}
                    setIsValidPhone={(boole) => setIsValidPhone(boole)}
                    shouldAssign={props.shouldAssign}
                    leadProperty={leadProperty}
                    setPurpose={(purpose) => setPurpose(purpose)}
                    setLeadProperty={(property) => setLeadProperty(property)}
                    onSubmit={(data) => onSubmitConnectForm({ ...data, shouldAssign: false })}
                  />
                ) : (
                  <ConnectInfo
                    leadProperty={leadProperty}
                    lead={props.lead.id === lead.id ? lead : props.lead}
                    setLead={(lead) => setLead(lead)}
                    leadType={leadType}
                    isReadOnly={props.isReadOnly}
                  />
                )}
              </div>
              <div className={classes.connectSection}>
                {((leadType === 'Project' &&
                  getPermissionValue(PermissionFeatures.PROJECT_LEADS, PermissionActions.UPDATE)) ||
                  (leadType === 'BuyRent' &&
                    getPermissionValue(
                      PermissionFeatures.BUY_RENT_LEADS,
                      PermissionActions.UPDATE
                    )) ||
                  (leadType === 'Wanted' &&
                    getPermissionValue(
                      PermissionFeatures.WANTED_LEADS,
                      PermissionActions.UPDATE
                    ))) &&
                (assignedToMe(lead) || leadType === 'Wanted') &&
                !isLeadClosed(lead) ? (
                  <ClientContact
                    customer={lead.customer}
                    loading={loading}
                    disableRegister={props.tasksFlag}
                    leadType={leadType}
                    onCallCompleted={(type, number) => onCallCompleted(type, number)}
                    leadId = {lead.id}
                    diaryId = {props.diary? props.diary.id : !!diaryId ? diaryId : null }
                  />
                ) : (
                  <div className={'d-flex justify-content-center p-4 border-top'}>
                    <Button
                      text={'OK'}
                      textColor={'white'}
                      buttonColor="#026ff2"
                      className={'px-5 rounded-pill'}
                      onClick={() => props.setConnectModal(false)}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          </> : <>
              <div style={{ marginTop: "20px", height: 760 }} className="d-flex align-items-center text-center">
                <h3 className="dimgray">Loading Details</h3>
                <p className="dimgray">Hold Your Horses!</p>
                <Loader type="ThreeDots" color="#0f73ee" height="40" width="40" />
              </div>
          </>}
        </ModalBody>
      </Modal>

      {leadModal && (
        <ClientLead
          leadModal={leadModal}
          leadType={leadType}
          excludeLead={lead}
          setLeadModal={(toggle) => setLeadModal(toggle)}
          customer={lead.customer}
        />
      )}
    </>
  )
}

export const mapDispatchToProps = (dispatch) => {
  return {
    fetchAllLeadAttachments: (id, leadType) => dispatch(fetchAllLeadAttachments(id, leadType)),
    fetchLeadTasks: (leadId, params, callback) =>
      dispatch(fetchLeadTasks(leadId, params, callback)),
    fetchSingleLeadProps: (leadId, byProject, callBack,check_MyDeal_Or_MyLeads) =>
      dispatch(fetchSingleLead(leadId, byProject, callBack,check_MyDeal_Or_MyLeads)),
    backUrl: (data) => dispatch(backUrl(data)),
  }
}

export const mapStateToProps = (state) => {
  return {
    leadAttachments: state.Leads.leadAttachments,
    tasksFlag: state.Leads.tasksFlag,
    currentUser: state.user.userInfo,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConnectModal)
