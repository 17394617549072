/** @format */

import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import config from '../../config'
import './bookingForm.scss'
import Select from 'react-select'
import Button from '../common/Button'
import DeleteIcon from '../../images/delete-applicant.svg'
import ClientCreationModal from '../../components/Modals/ClientCreationModal'
import BankDetailsModal from '../../components/Modals/AddBankDetails'
import NextKinModal from '../../components/Modals/AddNextKinDetails'
import AddNextOfkin from '../../views/addNextOfKin'
import AddClientModal from '../../views/AddClient'
import AddNextKinModal from '../../views/AddNextKin'
import AddBank from '../../components/Modals/BankAccountModal'
import { newFormatPrice } from '../../utils/formatPrice'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { element } from 'prop-types'
import {
  fetchUserClients,
  fetchClientsBank,
  fetchNextOfKin,
  fetchSingleLead,
} from '../../actions/leads'
import { capitalizeWordsWithoutUnderscore, capitalize_Words } from '../../utils/commonFormat'

import Swal from 'sweetalert2'
import { showToastMsg } from '../../utils/helper'

const AddBookingForm = ({
  singleLeadRecord,
  userClients,
  clientsBank,
  nextKin,
  dispatchFetchClientsBank,
  dispatchFetchSingleLead,
  dispatchFetchNextOfKin,
  currentUser,
  addNextOfKinLoader,
  addBankLoader,
  toggleBookingForm,
}) => {
  const [applicantsArr, setApplicantsArr] = useState([])
  const [shareToggleState, setShareToggleState] = useState(false)
  const [shareToggleBankState, setShareToggleBankState] = useState(false)
  const [shareToggleNextKin, setShareToggleNextKin] = useState(false)
  const [name, setName] = useState('')
  const [activeClientModal, setactiveClientModal] = useState(false)
  const [activeBankModal, setactiveBankModal] = useState(false)
  const [activeKinModal, setactiveKinModal] = useState(false)
  const [nextOfKin, setNextOfKin] = useState(nextKin)
  const [clientBanks, setClientBanks] = useState(clientsBank)

  const [createNewNextofKin, setCreateNewNextofKin] = useState(true)
  const [Id, setId] = useState()
  const [showField, setShowField] = useState(false)
  const [loader, setLoader] = useState(false)
  const [counting, setCounting] = useState()
  const [bookingId, setBookingId] = useState(null)
  const [canEdit, setCanEdit] = useState(false)
  const [addBankAccountFrom] = useState('BookingForm')
  const [addNextOfKinFrom] = useState('BookingForm')
  const [formValidation, setFormValidation] = useState({})
  const validationFields = {
    isCustomerValid: true,
    isKinValid: true,
    isbankAccountIdValid: true,
    ispercentageValid: true,
  }
  // setApplicantId
  useEffect(() => {
    let array
    const newapplicantsArr = [...applicantsArr]
    if (
      applicantsArr.length === 0 &&
      singleLeadRecord &&
      singleLeadRecord.bookingForms &&
      singleLeadRecord.bookingForms.length === 0
    ) {
      array = [singleLeadRecord && singleLeadRecord.purchaser]
      delete array.map((item) => {
        item.customerId = item.id
        delete item.id
      })
      newapplicantsArr[0] = {
        ...validationFields,
        ...array[0],
      }
      setApplicantsArr(newapplicantsArr)
    } else if (applicantsArr.length === 0) {
      array =
        singleLeadRecord &&
        singleLeadRecord.bookingForms &&
        singleLeadRecord.bookingForms[0] &&
        singleLeadRecord.bookingForms[0].bookedApplicants
      // setApplicantsArr(array.reverse())
      array.map((item, idx) => {
        if (newapplicantsArr[idx]) {
          newapplicantsArr[idx] = {
            ...validationFields,
            ...item,
          }
        } else {
          newapplicantsArr[idx] = {
            ...validationFields,
            ...item,
          }
        }
        setApplicantsArr(newapplicantsArr)
      })
    }
    if (
      singleLeadRecord &&
      singleLeadRecord.bookingForms &&
      singleLeadRecord.bookingForms.length > 0
    ) {
      setBookingId(singleLeadRecord.bookingForms[0].id)
    }
    fetchUserClients()
    if (
      currentUser &&
      singleLeadRecord &&
      currentUser.id === singleLeadRecord.assigned_to_armsuser_id &&
      singleLeadRecord.status !== 'closed_won'
    ) {
      setCanEdit(true)
    }
    setNextOfKin(nextKin)
    setClientBanks(clientsBank)
  }, [singleLeadRecord, clientsBank, nextKin])

  const bankShareToggle = () => {
    setShareToggleBankState(!shareToggleBankState)
    setName('')
  }

  const kinShareToggle = () => {
    setShareToggleNextKin(!shareToggleNextKin)
    setName('')
  }
  const shareToggle = () => {
    setShareToggleState(!shareToggleState)
    setName('')
  }
  const clientModalToggle = () => {
    setactiveClientModal(!activeClientModal)
    setShareToggleState(!shareToggleState)
    fetchUserClients()
  }
  const BankModalToggle = () => {
    setactiveBankModal(!activeBankModal)
    setShareToggleBankState(!shareToggleBankState)
  }
  const KinModalToggle = () => {
    setactiveKinModal(!activeKinModal)
    setShareToggleNextKin(!shareToggleBankState)
  }
  const apiCallDonClientCreated = (newClient) => {
    if (newClient) {
      onchangeClient(newClient)
      setactiveClientModal(false)
      setShareToggleState(false)
    }
  }

  const apiCallDonBankCreated = (newBank) => {
    if (newBank) {
      onChangeBank(newBank)
      bankShareToggle()
    }
  }
  const apiCallDonKinCreated = (newKin) => {
    if (newKin) {
      onChangeKin(newKin)
      // kinShareToggle()
      setactiveKinModal(false)
      setShareToggleNextKin(false)
      setCreateNewNextofKin(false)
    }
  }

  const onChangeBank = (value) => {
    let newApplicantArr = applicantsArr.map((elements) => {
      if (elements.customerId === value.customerId || elements.id === value.customerId) {
        elements.bank = value
        elements.bankAccountId = value.id
      }
      return elements
    })
    bankShareToggle()
    setApplicantsArr(applicantsArr)
  }

  const handleShare = (e, id) => {
    let newApplicantArr = applicantsArr.map((elements) => {
      if (elements.id === id || elements.customerId === id) {
        elements.percentage = e
      }
      return elements
    })
    setApplicantsArr(newApplicantArr)
  }

  const onChangeKin = (value) => {
    if (value.id !== applicantsArr[counting].nextOfKinId) {
      applicantsArr[counting].isKin = true
    }
    applicantsArr[counting].nextKin = value
    applicantsArr[counting].nextOfKinId = value.id
    setApplicantsArr(applicantsArr)
    kinShareToggle()
  }

  const onchangeClient = (value) => {
    let arrayNext = []
    arrayNext = [...applicantsArr]
    let customerCheck
    // arrayNext = arrayNext.filter((elememnt) => {
    //   if (elememnt && elememnt.createdAt !== null) {
    //     return elememnt
    //   }
    // })
    // arrayNext = arrayNext.filter((elememnt) => {
    //   if (Id !== elememnt.id) {
    //     return elememnt
    //   }
    // })
    if (Id === undefined) {
      value = { ...value, customerId: value.id, bookingId: bookingId, ...validationFields }
      delete value.id
      arrayNext.filter((elememnt) => {
        if (
          (elememnt.customerId && elememnt.customerId === value.customerId) ||
          (elememnt.customer && elememnt.customer.customerId === value.customerId)
        ) {
          customerCheck = true
        }
      })
      if (customerCheck === true) {
        showToastMsg('Applicant already exists.', 'error')
      } else {
        arrayNext[counting] = value
        let arrayAlready = arrayNext
        setApplicantsArr(arrayAlready)
        shareToggle()
      }
    } else {
      if (applicantsArr[counting] !== value.id) {
        value = {
          ...value,
          id: applicantsArr[counting].id,
          isCustomer: true,
          nextOfKinDetail: null,
          nextOfKinId: null,
          percentage: applicantsArr[counting].percentage,
          bankAccountId: null,
          customerId: value.id,
          bookingId: bookingId,
          ...validationFields,
        }
      }
      //let array = [...arrayNext, value]
      applicantsArr[counting] = value
      setApplicantsArr(applicantsArr)
      shareToggle()
    }
  }

  const addJointApplicant = () => {
    let array = [...applicantsArr]
    array.push({
      bookingId: bookingId ? bookingId : null,
      percentage: '',
      bankAccountId: null,
      nextOfKinId: null,
      isKinChanged: false,
      createdAt: null,
      updatedAt: null,
      deletedAt: null,
      ...validationFields,
    })
    setApplicantsArr(array)
  }

  const deleteJointApplicant = (index, applicantId) => {
    if (applicantId) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'order-2 swalConfirmBtn px-4 rounded-pill small mr-2',
          denyButton: 'order-1 denyConfirmBtn px-4 rounded-pill mr-2 small',
        },
        buttonsStyling: false,
      })

      swalWithBootstrapButtons
        .fire({
          // title: 'Are you sure you want to delete this task?',
          // html: deleteMsg + 'Are you sure you want to delete this next ok kin?',
          html: 'Are you sure you want to delete this applicant?',
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: 'Yes',
          confirmButtonColor: '#026ff2',
          denyButtonText: `No`,
          denyButtonColor: '#fff',
          icon: 'warning',
        })
        .then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            // if (deleteCheck === true) {
            axios
              .delete(`${config.apiPath}/api/booking/deleteApplicant/${applicantId}`)
              .then((res) => {
                if (res.status === 200) {
                  showToastMsg('Applicant deleted successfully', 'success')
                  const newapplicantsArr = res.data.data
                  setApplicantsArr(newapplicantsArr)
                }
              })
              .catch(() => {
                showToastMsg('Something went wrong!!', 'error')
              })

            // }
          }
        })
    } else {
      let array = [...applicantsArr]
      array.splice(index, 1)
      setApplicantsArr(array)
    }
  }

  const sendData = () => {
    let totalShare = 0
    let errors = []
    totalShare = applicantsArr
      .map((item) => parseFloat(item.percentage))
      .reduce((prev, curr) => prev + curr)
    let newApplicantArr = applicantsArr.map((item, idx) => {
      if (item.customerId === '' || item.customerId === null || item.customerId === undefined) {
        item.isCustomerValid = false
        errors.push(idx + '_customerInvalid')
      } else {
        item.isCustomerValid = true
      }
      if (item.nextOfKinId === '' || item.nextOfKinId === null || item.nextOfKinId === undefined) {
        item.isKinValid = false
        errors.push(idx + '_kinInvalid')
      } else {
        item.isKinValid = true
      }
      if (
        item.bankAccountId === '' ||
        item.bankAccountId === null ||
        item.bankAccountId === undefined
      ) {
        item.isbankAccountIdValid = false
        errors.push(idx + '_bankAccountInvalid')
      } else {
        item.isbankAccountIdValid = true
      }
      if (item.percentage === '' || item.percentage === null || item.percentage === undefined) {
        item.ispercentageValid = false
        errors.push(idx + '_percentageInvalid')
      } else {
        item.ispercentageValid = true
      }
      return item
    })
    if (errors.length > 0) {
      setApplicantsArr(newApplicantArr)
    } else {
      if (totalShare > 100 || totalShare < 100 || totalShare <= 0) {
        showToastMsg('Sum of Share percentage should be equal to 100%.', 'error')
      } else {
        let body = {}
        setLoader(true)
        body = applicantsArr
        axios
          .patch(`${config.apiPath}/api/booking/updateApplicant`, body)
          .then((res) => {
            if (res.data) {
              setLoader(false)
              showToastMsg('Booking form has been submitted successfully.', 'success')
              const newapplicantsArr = [...applicantsArr]
              res.data.data.map((item, idx) => {
                if (newapplicantsArr[idx]) {
                  newapplicantsArr[idx] = {
                    ...validationFields,
                    ...item,
                  }
                } else {
                  newapplicantsArr[idx] = {
                    ...validationFields,
                    ...item,
                  }
                }
                dispatchFetchSingleLead(singleLeadRecord.id)
                setApplicantsArr(newapplicantsArr)
              })
            }
          })
          .catch(() => {
            setLoader(false)
            showToastMsg('Something went wrong!!', 'error')
          })
      }
    }
  }
  return (
    <>
      <ModalBody>
        <div className="investment-form-body">
          <div className="main-title">Booking Details</div>
          <div className="row mr-2 ml-2">
            <div className="col-md-5">
              <div className="row field-wrapper">
                <div className="col-md-5 pl-0">
                  <span className="title">Project:</span>
                </div>
                <div className="col-md-7 ">
                  <span className="sub-title">
                    {singleLeadRecord && singleLeadRecord.project && singleLeadRecord.project.name
                      ? capitalize_Words(singleLeadRecord.project.name)
                      : ''}
                  </span>{' '}
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div className="row field-wrapper">
                <div className="col-md-5 pl-0">
                  <span className="title">Floor:</span>
                </div>
                <div className="col-md-7 ">
                  <span className="sub-title">
                    {singleLeadRecord && singleLeadRecord.floor && singleLeadRecord.floor.name
                      ? capitalize_Words(singleLeadRecord.floor.name)
                      : ''}
                  </span>{' '}
                </div>
              </div>
            </div>
          </div>
          <div className="row mr-2 ml-2">
            <div className="col-md-5">
              <div className="row field-wrapper">
                <div className="col-md-5 pl-0">
                  <span className="title">Unit:</span>
                </div>
                <div className="col-md-7 ">
                  <span className="sub-title">
                    {singleLeadRecord && singleLeadRecord.unit && singleLeadRecord.unit.name
                      ? capitalize_Words(singleLeadRecord.unit.name)
                      : ''}
                  </span>{' '}
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div className="row field-wrapper">
                <div className="col-md-5 pl-0">
                  <span className="title">Price:</span>
                </div>
                <div className="col-md-7 ">
                  <span className="sub-title">
                    {singleLeadRecord && singleLeadRecord.unit && singleLeadRecord.unit.unit_price
                      ? newFormatPrice(singleLeadRecord.unit.unit_price).toLocaleString()
                      : ''}
                  </span>{' '}
                </div>
              </div>
            </div>
          </div>
          <div className="row mr-2 ml-2">
            <div className="col-md-5">
              <div className="row field-wrapper">
                <div className="col-md-5 pl-0">
                  <span className="title">Size:</span>
                </div>
                <div className="col-md-7 ">
                  <span className="sub-title">
                    {singleLeadRecord && singleLeadRecord.unit && singleLeadRecord.unit.area
                      ? singleLeadRecord.unit.area + ' Sqft'
                      : ''}
                  </span>{' '}
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div className="row field-wrapper">
                <div className="col-md-5 pl-0">
                  <span className="title">Rate/Sqft:</span>
                </div>
                <div className="col-md-7 ">
                  <span className="sub-title">
                    {singleLeadRecord &&
                    singleLeadRecord.unit &&
                    singleLeadRecord.unit.rate_per_sqft
                      ? Number(singleLeadRecord.unit.rate_per_sqft).toLocaleString()
                      : ''}
                  </span>{' '}
                </div>
              </div>
            </div>
          </div>
          <div className="row mr-2 ml-2">
            <div className="col-md-5">
              <div className="row field-wrapper">
                <div className="col-md-5 pl-0">
                  <span className="title">Investment Product:</span>
                </div>
                <div className="col-md-7 ">
                  <span className="sub-title">
                    {singleLeadRecord &&
                      singleLeadRecord.projectProduct &&
                      singleLeadRecord.projectProduct.name}
                  </span>{' '}
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div className="row field-wrapper">
                <div className="col-md-5 pl-0">
                  <span className="title">Product Plan:</span>
                </div>
                <div className="col-md-7 ">
                  <span className="sub-title">
                    {capitalizeWordsWithoutUnderscore(
                      singleLeadRecord && singleLeadRecord.paymentPlan
                    )}
                  </span>{' '}
                </div>
              </div>
            </div>
          </div>
          <div className="row mr-2 ml-2">
            <div className="col-md-5">
              <div className="row field-wrapper">
                <div className="col-md-5 pl-0">
                  <span className="title">Instalment Frequency:</span>
                </div>
                <div className="col-md-7 ">
                  <span className="sub-title">
                    {singleLeadRecord && singleLeadRecord.installmentFrequency !== null
                      ? capitalize_Words(singleLeadRecord && singleLeadRecord.installmentFrequency)
                      : '--'}
                  </span>{' '}
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div className="row field-wrapper">
                <div className="col-md-5 pl-0">
                  <span className="title">Payment Plan Duration:</span>
                </div>
                <div className="col-md-7 ">
                  <span className="sub-title">
                    {singleLeadRecord && singleLeadRecord.paymentPlanDuration !== null
                      ? singleLeadRecord && singleLeadRecord.paymentPlanDuration + ' Years'
                      : '--'}
                  </span>{' '}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="applicants-form-body">
          <div className="main-title">Applicants</div>
          {applicantsArr.map((applicant, count) => (
            <div className=" ml-1 applicant-form">
              <div className="row">
                <div className="col-md-12 applicant-no">
                  <p>Applicant # {count + 1}</p>
                  {(applicant &&
                    applicant.customerId &&
                    applicant.customerId === singleLeadRecord.customer.id) ||
                  count == 0 ||
                  !canEdit ? null : (
                    <img
                      src={DeleteIcon}
                      alt="deleteMeeting"
                      className="icon-main icon-delete"
                      onClick={() => deleteJointApplicant(count, applicant.id)}
                    />
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-5">
                  <div className="input__main">
                    <label>Applicant Name</label>
                    <span className={`text-danger`}> *</span>
                    <div
                      className="filterInputWrap"
                      onClick={() => {
                        if (canEdit) {
                          setShareToggleState(true)
                          setName('Enter Client')
                          setCounting(count)
                          setId(applicant.customerId ? applicant.customerId : applicant.id)
                        }
                      }}
                    >
                      {applicant === null ? (
                        <>
                          <input
                            className={`form-control text-field ${
                              applicant.isCustomerValid === false ? `invalid` : ``
                            }`}
                            placeholder="Select Client"
                            style={canEdit ? { cursor: 'pointer', caretColor: 'transparent' } : {}}
                            value={
                              applicant && applicant.customerName
                                ? applicant.customerName
                                : (applicant.id || applicant.customerId) && applicant.firstName
                                ? applicant.firstName + ' ' + applicant.lastName
                                : applicant.customer
                                ? applicant.customer.first_name + ' ' + applicant.customer.last_name
                                : ''
                            }
                          />
                        </>
                      ) : (
                        <span className="black-color">
                          <input
                            className={`form-control text-field ${
                              applicant.isCustomerValid === false ? `invalid` : ``
                            }`}
                            placeholder="Select Client"
                            style={canEdit ? { cursor: 'pointer', caretColor: 'transparent' } : {}}
                            value={
                              applicant && applicant.customerName
                                ? applicant.customerName
                                : (applicant.id || applicant.customerId) && applicant.firstName
                                ? applicant.firstName + ' ' + applicant.lastName
                                : applicant.customer
                                ? applicant.customer.first_name + ' ' + applicant.customer.last_name
                                : ''
                            }
                            // disabled={applicantsArr[0]}
                          />
                        </span>
                      )}
                      {applicant.isCustomerValid === false && (
                        <p className={'requiredCheck'}>Required</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-5">
                  <div
                    className="input__main"
                    onClick={() => {
                      if (canEdit && applicant.customerId && applicant.customerId !== null) {
                        setShareToggleNextKin(true)
                        setName('Next of Kin')
                        setCounting(count)
                        setNextOfKin([])

                        dispatchFetchClientsBank(
                          applicant.customerId ? applicant.customerId : applicant.id
                        )
                        dispatchFetchNextOfKin(
                          applicant.customerId ? applicant.customerId : applicant.id
                        )
                        setId(applicant.customerId ? applicant.customerId : applicant.id)
                      }

                      // if (clientsBank) {
                      //   setLoader(false)
                      // } else {
                      //   setLoader(true)
                      // }
                    }}
                  >
                    <label>Next Of Kin</label>
                    <span className={`text-danger`}> *</span>
                    <div className="filterInputWrap">
                      {applicant === null ? (
                        <>
                          <input
                            className={`form-control text-field ${
                              applicant.isKinValid === false &&
                              (applicant.nextOfKinId === null ||
                                applicant.nextOfKinId === undefined)
                                ? `invalid`
                                : ``
                            }`}
                            disabled={!applicant.customerId || applicant.customerId === null}
                            placeholder="Select Kin"
                            style={canEdit ? { cursor: 'pointer', caretColor: 'transparent' } : {}}
                          />
                        </>
                      ) : (
                        <span className="black-color">
                          <input
                            className={`form-control text-field ${
                              applicant.isKinValid === false &&
                              (applicant.nextOfKinId === null ||
                                applicant.nextOfKinId === undefined)
                                ? `invalid`
                                : ``
                            }`}
                            style={canEdit ? { cursor: 'pointer', caretColor: 'transparent' } : {}}
                            placeholder="Select Next of Kin"
                            disabled={!applicant.customerId || applicant.customerId === null}
                            value={
                              applicant && applicant.nextKin
                                ? applicant.nextKin.firstName + ' ' + applicant.nextKin.lastName
                                : applicant &&
                                  applicant.nextOfKinDetail &&
                                  applicant.nextOfKinDetail.firstName
                                ? applicant.nextOfKinDetail.firstName +
                                  ' ' +
                                  applicant.nextOfKinDetail.lastName
                                : ''
                            }
                            // disabled={applicantsArr[0]}
                          />
                        </span>
                      )}
                      {applicant.isKinValid === false &&
                        (applicant.nextOfKinId === null || applicant.nextOfKinId === undefined) && (
                          <p className={'requiredCheck'}>Required</p>
                        )}
                    </div>
                  </div>
                </div>
                {applicantsArr.length > 1 && (
                  <div className="col-md-2">
                    <div className="input__main">
                      <label>Share %</label>
                      <span className={`text-danger`}> *</span>
                      <div className="filterInputWrap">
                        <input
                          type="number"
                          className={
                            applicant.ispercentageValid === false &&
                            (applicant.percentage === '' ||
                              applicant.percentage === null ||
                              applicant.percentage === undefined)
                              ? `form-control invalid`
                              : `form-control`
                          }
                          placeholder={'Enter Share'}
                          name={'accountTitle'}
                          disabled={!applicant.customerId || applicant.customerId === null}
                          onChange={(e) => {
                            if (canEdit) {
                              handleShare(
                                e.target.value,
                                applicant.id
                                  ? applicant.id
                                  : applicant.customerId
                                  ? applicant.customerId
                                  : null
                              )
                            }
                          }}
                          value={applicant.percentage}
                          // required
                        />
                        {applicant.ispercentageValid === false &&
                          (applicant.percentage === '' ||
                            applicant.percentage === null ||
                            applicant.percentage === undefined) && (
                            <p className={'requiredCheck'}>Required</p>
                          )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="row bank-info">
                <div className="col-md-5">
                  <div
                    className="input__main"
                    onClick={() => {
                      if (canEdit && applicant.customerId && applicant.customerId !== null) {
                        setShareToggleBankState(true)
                        setName('Bank Account')
                        setClientBanks([])
                        if (clientsBank) {
                          setLoader(false)
                        } else {
                          setLoader(true)
                        }
                        dispatchFetchClientsBank(
                          applicant.customerId ? applicant.customerId : applicant.id
                        )
                        dispatchFetchNextOfKin(
                          applicant.customerId ? applicant.customerId : applicant.id
                        )
                        // setApplicantId(applicant.id)
                        setId(applicant.customerId ? applicant.customerId : applicant.id)
                      }
                    }}
                  >
                    <label>Account Title</label>
                    <span className={`text-danger`}> *</span>
                    <div className="filterInputWrap">
                      <span className="black-color">
                        <input
                          className={`form-control text-field ${
                            !applicant.isbankAccountIdValid &&
                            (applicant.bankAccountId === null ||
                              applicant.bankAccountId === undefined)
                              ? `invalid`
                              : ``
                          }`}
                          placeholder="Select Bank"
                          disabled={!applicant.customerId || applicant.customerId === null}
                          style={canEdit ? { cursor: 'pointer', caretColor: 'transparent' } : {}}
                          value={
                            applicant && applicant.bank && applicant.bank.id
                              ? applicant.bank.accountTitle
                              : applicant.customerBankDetail
                              ? applicant.customerBankDetail.accountTitle
                              : ''
                          }
                        />
                      </span>
                      {!applicant.isbankAccountIdValid &&
                        (applicant.bankAccountId === null ||
                          applicant.bankAccountId === undefined) && (
                          <p className={'requiredCheck'}>Required</p>
                        )}
                      {/*)}*/}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row pl-3 pr-3 bankDetails">
                {(applicant.bank !== undefined &&
                  applicant.bank !== null &&
                  applicant.bank !== '') ||
                (applicant.customerBankDetail !== undefined &&
                  applicant.customerBankDetail !== null) ? (
                  <>
                    <div className="row mb-2 pl-0 field-wrapper pr-0">
                      <span className={`col-md-2 title`}>Bank:</span>
                      <span className={`col-md-4 text-blue sub-title`}>
                        {applicant && applicant.bank && applicant.bank.bankName
                          ? applicant.bank.bankName
                          : applicant.customerBankDetail
                          ? applicant.customerBankDetail.bankName
                          : '--'}
                      </span>
                    </div>
                    <div className="row mb-2 pl-0 field-wrapper pr-0">
                      <span className={`col-md-2 title`}>IBAN:</span>
                      <span className={`col-md-4 text-blue sub-title`}>
                        {applicant && applicant.bank && applicant.bank.IBAN
                          ? applicant.bank.IBAN
                          : applicant.customerBankDetail
                          ? applicant.customerBankDetail.IBAN
                          : '--'}
                      </span>
                    </div>
                    <div className="row mb-2 pl-0 field-wrapper pr-0">
                      <span className={`col-md-2 title`}>Account Number:</span>
                      <span className={`col-md-4 text-blue sub-title`}>
                        {applicant && applicant.bank && applicant.bank.accountNumber
                          ? applicant.bank.accountNumber
                          : applicant.customerBankDetail
                          ? applicant.customerBankDetail.accountNumber
                          : '--'}
                      </span>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          ))}
        </div>
        {canEdit && (
          <>
            <div className="joint-applicant row">
              <div className="col-md-4 ">
                <Button
                  text={'+ Add Joint Applicant'}
                  textColor={'#026ff2'}
                  display={'inline-block'}
                  buttonColor={'#fff'}
                  //   loader={loading}
                  //   disabled={taskObj.taskType.value && taskObj.diaryTime && !loading ? false : true}
                  style={{ border: '1px solid transparent' }}
                  onClick={
                    (e) => addJointApplicant()
                    // taskObj.taskType.value && taskObj.diaryTime ? addTaskFunc(diaryAction) : null
                  }
                />
              </div>
            </div>
            <div className="row update-applicant justify-content-end">
              <div className="col-md-5 flex-btns justify-content-end">
                {/* <div> */}
                <Button
                  text={'Cancel'}
                  textColor={'black'}
                  display={'inline-block'}
                  buttonColor={'#fff'}
                  //   '#026ff2'}
                  //   loader={loading}
                  //   disabled={taskObj.taskType.value && taskObj.diaryTime && !loading ? false : true}
                  className={'px-4 rounded-pill medium'}
                  style={{ border: '1px solid #d3d3d3' }}
                  onClick={
                    toggleBookingForm
                    // taskObj.taskType.value && taskObj.diaryTime ? addTaskFunc(diaryAction) : null
                  }
                />
                {/* </div>
                  <div> */}
                <Button
                  text={'Submit'}
                  textColor={'white'}
                  display={'inline-block'}
                  buttonColor={'#026ff2'}
                  loader={loader}
                  //   disabled={taskObj.taskType.value && taskObj.diaryTime && !loading ? false : true}
                  className={'px-4 rounded-pill medium updateInfo'}
                  style={{ border: '1px solid transparent' }}
                  onClick={
                    (e) => {
                      sendData()
                    }
                    // taskObj.taskType.value && taskObj.diaryTime ? addTaskFunc(diaryAction) : null
                  }
                />
                {/* </div> */}
              </div>
            </div>
          </>
        )}
      </ModalBody>
      <BankDetailsModal
        shareToggleBankState={shareToggleBankState}
        bankShareToggle={bankShareToggle}
        name={name}
        BankModalToggle={BankModalToggle}
        onChangeBank={onChangeBank}
        clientsBank={clientBanks && clientBanks}
        loader={addBankLoader}
      />
      <NextKinModal
        shareToggleNextKin={shareToggleNextKin}
        kinShareToggle={kinShareToggle}
        name={name}
        KinModalToggle={KinModalToggle}
        onChangeKin={onChangeKin}
        nextKin={nextOfKin && nextOfKin}
        loader={addNextOfKinLoader}
      />
      <ClientCreationModal
        shareToggleState={shareToggleState}
        name={name}
        clients={userClients && userClients.rows}
        clientModalToggle={clientModalToggle}
        shareToggle={shareToggle}
        onchangeClient={onchangeClient}
      />

      <AddNextOfkin
        activeClientModal={activeKinModal}
        clientModalToggle={KinModalToggle}
        // onAddNewNextOfKinRecordHandler={
        //   this.onAddNewNextOfKinRecordHandler
        // }
        fromPage={'clientNextOfKin'}
        SelectedRecordId={Id}
        name={name}
        NextOfKinSelectedRecordCustomerId={Id}
        addNextOfKinFrom={addNextOfKinFrom}
        apiCallDonKinCreated={apiCallDonKinCreated}
        SelectedRecordIdOfnextOfKin={Id}
        createNewNextofKin={createNewNextofKin}
        nextOfkinCancel={KinModalToggle}
      />

      {/* <AddNextKinModal
        KinModalToggle={KinModalToggle}
        name={name}
        activeKinModal={activeKinModal}
        id={Id}
        apiCallDonKinCreated={apiCallDonKinCreated}
      /> */}

      <AddBank
        BankModalToggle={BankModalToggle}
        apiCallDonBankCreated={apiCallDonBankCreated}
        // name={name}
        activeBankModal={activeBankModal}
        id={Id}
        addBankAccountFrom={addBankAccountFrom}
      />
      <AddClientModal
        name={name}
        activeClientModal={activeClientModal}
        fromPage={'investmentLead'}
        apiCallDonClientCreated={apiCallDonClientCreated}
        shareToggle={shareToggle}
        clientModalToggle={clientModalToggle}
      />
    </>
  )
}
const mapDispatchToProps = (dispatch) => {
  return {
    getClientsData: () => dispatch(fetchUserClients()),
    fetchUserClients: () => dispatch(fetchUserClients()),
    dispatchFetchClientsBank: (id) => dispatch(fetchClientsBank(id)),
    dispatchFetchNextOfKin: (id) => dispatch(fetchNextOfKin(id)),
    dispatchFetchSingleLead: (id) => dispatch(fetchSingleLead(id)),
  }
}

const mapStateToProps = (state) => {
  return {
    userClients: state.Leads.userClients,
    currentUser: state.user.userInfo,
    addNextOfKinLoader: state.Leads.addNextOfKinLoader,
    addBankLoader: state.Leads.addBankLoader,
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddBookingForm))
