/** @format */

import React, { useState } from 'react'
import { Input, Modal, ModalBody } from 'reactstrap'
import Button from '../../common/Button'
import { PropertyWorkFlowTile } from '../../AddPropertyWorkflow/PropertyWorkFlowTile'
import moment from 'moment'
import { makeStyles } from '@material-ui/styles'
import UpdatePropertyTitle from './UpdatePropertyTitle'
import { connect } from 'react-redux'

const useStyles = makeStyles({
  modalContent: {
    minHeight: 300,
    maxHeight: 'calc(100vh - 160px)',
    overflowY: 'auto',
  },
})
const RescheduleViewing = (props) => {
  const classes = useStyles(props)
  const [selectedProperty, setSelectedProperty] = useState([props.propertyId])

  return (
    <>
      <Modal isOpen={props.modal} toggle={(toggle) => props.setModal(!toggle)} size={'xl'}>
        <ModalBody>
          <div className="close-icon-container">
            <i className="fal fa-times-circle close-icon" onClick={() => props.setModal(false)}></i>
          </div>
          <div className={'mb-4'}>
            <h4>{`${
              props.action === 'Cancel'
                ? 'Select Properties for cancellation'
                : props.action + ' Viewing(s)'
            }`}</h4>
          </div>
          {props.properties && (
            <div
              className={`${
                !props.properties.length ? 'd-flex justify-content-center align-items-center' : ''
              } ${classes.modalContent}`}
            >
              {props.properties.length ? (
                props.properties.map((property) => {
                  return (
                    <UpdatePropertyTitle
                      propertyId={props.propertyId}
                      selectedProperty={selectedProperty}
                      setSelectedProperty={(properties) => setSelectedProperty(properties)}
                      showChange={props.action === 'Reschedule'}
                      showComment={false}
                      onComment={() => {}}
                      action={props.action}
                      onChange={(task) => {
                        props.setSlotModal(true)
                        props.setTask(task)
                      }}
                      property={property}
                      showCheckbox={props.action === 'Cancel'}
                    />
                  )
                })
              ) : (
                <span className="fa fa-circle-o-notch fa-spin text-primary"></span>
              )}
            </div>
          )}
          <div className="row d-flex justify-content-center text-center mt-2">
            <Button
              text={'Done'}
              textColor={'white'}
              buttonColor="#026ff2"
              className={'px-5 rounded-pill'}
              onClick={() => {
                // return selected diary ids each belongs to unique property to callback
                let selectedProperties =
                  props.properties && props.properties.length
                    ? props.properties.filter(
                        (property) => selectedProperty.indexOf(property.id) > -1
                      )
                    : []
                let selectedDiaries = []
                selectedProperties.map((p) => {
                  let d = p.diaries.filter(
                    (d) =>
                      ['completed', 'cancelled'].indexOf(d.status) === -1 &&
                      d.userId === props.user.id &&
                      d.id !== props.excludeDiaryId
                  )
                  if (d.length) selectedDiaries.push(d[0].id)
                })
                props.callback(selectedDiaries)
              }}
            />
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

export const mapDispatchToProps = (dispatch) => {
  return {}
}

export const mapStateToProps = (state) => {
  return {
    user: state.user.userInfo,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RescheduleViewing)
