/** @format */

import React, { Component, useState } from 'react'
import { Link, withRouter } from 'react-router-dom'
import moment from 'moment'
import FollowUpModal from './FollowUpModal'
import setHours from 'date-fns/setHours'
import AttachmentModal from '../../../../../components/Modals/AttachmentModal'
import setMinutes from 'date-fns/setMinutes'
import { normalizePhoneFormat } from '../../../../../utils/formatPhone'
import config from '../../../../../config'
import axios from 'axios'
import { connect } from 'react-redux'
import { showToastMsg } from '../../../../../utils/helper'
import Swal from 'sweetalert2'
import _ from 'lodash'
import ShareModal from '../../../../../components/Modals/ShareModal'
import ReferModal from '../../../../../components/Modals/ReferModal'
import CallIcon from '../../../../../images/callIcon.svg'
import CallModal from '../../../../../components/InvestmentCallModal'
import {
  investmentMeeting,
  fetchLeadTimeLine,
  fetchLeads,
  investmentShareLead,
  fetchLeadAttachments,
  fetchAllComments,
  addComment,
  deleteLeadAttachment,
} from '../../../../../actions/leads'
import { capitalize_Words, showErrorMessage } from '../../../../../utils/commonFormat'
import 'react-toastify/dist/ReactToastify.min.css'
import 'react-datepicker/dist/react-datepicker.css'
import './style.scss'
import YellowLead from '../../../../../images/yellow-marker.svg'
import RedLead from '../../../../../images/red-marker.svg'
import MeetingActions from '../../../../../components/MeetingAction'
import TaskModal from '../../../../../components/Modals/TaskModal/TaskModal'
import {
  BuyRentLeadUrl,
  constants,
  managerSelectionDropdown,
  ProjectDealUrl,
  ProjectLeadUrl,
  WantedLeadUrl,
} from '../../../../../utils/constant_data'
import CommentModal from '../../../../../components/Modals/CommentModal'
import ActionDropList from '../../../../../components/LeadActionDropList'
import { StaticData } from '../../../../../StaticData'
import ClientDetails from '../../../../../components/Modals/DetailsModal/ClientDetails'
import AdditionalDetails from '../../../../../components/Modals/AdditionalDetailsModal/AdditionalDetails'
import { RenderLeadStatus } from '../../../../../utils/general'
import ClientNewLead from '../../../../../components/Modals/ClientNewLeadModal'
import { accountsPermissions } from '../../../../../utils/checkPermissions'
import Diary from '../../../../../components/common/Diary/Diary'
import { leadStatusClass } from '../../../../../utils/lead'
import { getPermissionValue } from '../../../../../components/_unlockComponent'
import { PermissionActions, PermissionFeatures } from '../../../../../utils/permissionConstants'
import LeadClickComponent from "../../../../../callCenter/LeadClickComponent";

class InvestmentLeadInner extends Component {
  constructor(props) {
    super(props)
    this.state = {
      closeLostToggleState: false,
      reason: '',
      loader: false,
      itemIdLocal: '',
      edit: false,
      initialCount: 0,
      callToggleState: false,
      investmentCallLoader: false,
      startDate: setHours(setMinutes(new Date(), 30), 16),
      addMeetingLoader: false,
      selectedFiles: [],
      showOverlay: false,
      validFiles: [],
      uploadPercentage: 0,
      progressInfos: [],
      showProgressBar: false,
      disabledButton: false,
      attachmentToggleState: false,
      customersPhoneNumbers: null,
      customerPhoneLoader: true,
      callLogs: [],
      followupModalFlag: false,
      meetingFeedback: false,
      showFeedback: false,
      singleLeadIdState: null,
      meetingToggleState: false,
      detailsToggleState: false,
      createNewLeadToggleState: false,
      callRecord: '',
      activeTab: null,
      shareToggleState: false,
      agents: [],
      shareloader: false,
      commentToggleState: false,
      shareLeadType: '',
      callingFrom: '',
      trFocus: null,
      taskModalAction: 'list',
      followUpModalAction: 'list',
      showCloseIcon: false,
      attachmentLoader: false,
      closeAfterAdd: false,
      closeWonToggleState: false,
      selectedLeads: [],
      selectedItemLeads: [],
      filterSelected: [],
      agentCount: 0,
      leadCount: null,
      isClientLeads: false
    }
  }

  addInvestmentCallFun = (type, number) => {
    const {
      item: { id, customer },
    } = this.props
    this.setState({
      investmentCallLoader: true,
    })

    let subject = `${type != 'phone' ? 'contacted' : 'called'} ${customer && customer.customerName}`
    let data = {
      start: moment().format(),
      end: moment().format(),
      time: moment().format(),
      date: moment().format(),
      taskType: 'called',
      response: 'Called',
      subject: subject,
      customerId: customer && customer.id,
      taskCategory: 'leadTask',
      leadId: id,
      calledOn: type != 'phone' ? 'whatsApp' : 'phone',
      title: type != 'phone' ? 'whatsApp' : 'phone',
      calledNumber: number,
    }

    if (type === 'whatsApp') {
      axios.post(`${config.apiPath}/api/leads/project/meeting`, data).then((res) => {
        window.open(`https://wa.me/${number}`, '_blank')
        // window.location.href = `leadDetails/${id}`
        this.setState({
          investmentCallLoader: false,
          callToggleState: false,
          callRecord: res.data,
        })
      })
    } else {
      // in case of phone call we will log call after feedback
      this.setState({
        investmentCallLoader: false,
        callToggleState: false,
        callRecord: data,
      })
    }
  }

  callToggle = (customer, callingFrom) => {
    this.setState({
      customerPhoneLoader: true,
      callingFrom,
    })
    axios
      .get(`${config.apiPath}/api/customer/getNumbers?customerId=${customer.id}`)
      .then((res) => {
        this.setState({
          customersPhoneNumbers: res.data,
          customerPhoneLoader: false,
        })
      })
      .catch((err) => {
        this.setState({
          customerPhoneLoader: false,
        })
      })
    this.setState({ callToggleState: !this.state.callToggleState })
  }

  fetchCallLogs = (leadDetails) => {
    this.setState({ callLogs: [] })
    axios
      .get(
        `${config.apiPath}/api/leads/tasks?web=true&cmLeadId=${leadDetails.id}&taskType[]=called`
      )
      .then((res) => {
        this.setState({
          callLogs: res.data,
        })
      })
  }

  meetingToggle = () => {
    this.setState({ meetingToggleState: !this.state.meetingToggleState })
  }

  addMeetingFun = () => {
    const {
      addInvestmentMeeting,
      item: { id },
    } = this.props
    const { startDate } = this.state
    this.setState({ addMeetingLoader: true })
    let endDate = moment(startDate).add(1, 'hours').format('YYYY-MM-DDTHH:mm:ssZ')
    let start = moment(startDate).format('YYYY-MM-DDTHH:mm:ssZ')
    let meetingData = {
      start: start,
      end: endDate,
      subject: 'Scheduled a meeting with client',
      leadId: id,
      date: start,
      taskType: 'meeting',
      time: start,
    }
    addInvestmentMeeting(meetingData)
    setTimeout(() => {
      window.location.href = `leadDetails/${id}`
      this.setState({
        meetingToggleState: false,
        addMeetingLoader: false,
        edit: false,
      })
    }, 800)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.activeTab !== prevState.activeTab && this.state.activeTab === 'payment')
      this.props.history.push(`/leadDetails/${this.props.item.id}?tab=payment`)
  }

  componentDidMount() {
    const { tabUrl, leadsData } = this.props

    if (leadsData && leadsData.rows && leadsData.rows.length === 8 && tabUrl === 'existinglead') {
      this.setState({
        taskModal: true,
        selectedLead: leadsData && leadsData.rows && leadsData.rows[0],
      })
    }
  }

  dragOver = (e) => {
    e.preventDefault()
  }

  addDocuments = (selectedFiles, id = this.props.itemId) => {
    this.setState({
      showProgressBar: true,
      disabledButton: true,
    })

    let promises = []
    for (let i = 0; i < selectedFiles.length; i++) {
      let bodyFormData = new FormData()
      bodyFormData.append('file', selectedFiles[i])

      const options = {
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent
          let percent = Math.floor((loaded * 100) / total)

          selectedFiles[i].percentage = percent

          this.setState({
            progressInfos: selectedFiles,
            attachmentLoader: true,
          })
        },
      }

      promises.push(
        axios.post(`${config.apiPath}/api/leads/attachment?cmLeadId=${id}`, bodyFormData, options)
      )
    }

    Promise.all(promises).then((res) => {
      this.setState({
        showProgressBar: false,
        disabledButton: false,
        selectedFiles: [],
        attachmentLoader: false,
      })

      this.props.fetchLeadAttachmentsFunc(this.props.itemId)
    })
  }

  dragEnter = (e) => {
    e.preventDefault()
    this.setState({
      showOverlay: true,
    })
  }

  dragLeave = (e) => {
    e.preventDefault()
  }

  removeSpecificData = (value) => {
    const { selectedFiles } = this.state
    let index = _.findIndex(selectedFiles, { name: value })
    selectedFiles.splice(index, 1)

    this.setState({
      selectedFiles,
    })
  }

  attachmentToggle = (id) => {
    this.props.fetchLeadAttachmentsFunc(id)
    this.setState({ attachmentToggleState: !this.state.attachmentToggleState })
  }

  validateFile = (file) => {
    const validTypes = [
      'image/jpeg',
      'image/jpg',
      'image/png',
      'image/gif',
      'image/x-icon',
      'image/bmp',
      'application/pdf',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'text/csv',
    ]
    if (validTypes.indexOf(file.type) === -1) {
      Swal.fire('Warning !', 'The file format is not supported', 'warning')
      this.setState({
        showOverlay: false,
      })
      return false
    }
    return true
  }

  addManualAttachments = (event) => {
    const files = Array.from(event.target.files)

    if (files.length > 10) {
      Swal.fire('Warning !', 'Maximum files upload limit is 10', 'warning')
      return false
    }

    files.forEach((file) => {
      if (this.validateFile(file)) {
        file.percentage = 0
        this.setState((prevState) => {
          return {
            ...prevState,
            selectedFiles: this.uniqueFiles([...prevState.selectedFiles, file]),
          }
        })
      }
    })
  }

  uniqueFiles = (data) => {
    let unique = _.uniqBy(data, 'name')
    return unique
  }

  handleFiles = (files) => {
    for (let i = 0; i < files.length; i++) {
      if (this.validateFile(files[i])) {
        this.setState((prevState) => {
          return {
            ...prevState,
            selectedFiles: this.uniqueFiles([...prevState.selectedFiles, files[i]]),
            showOverlay: false,
          }
        })
      }
    }
  }

  fileDrop = (e) => {
    e.preventDefault()
    const files = e.dataTransfer.files
    if (files.length > 10) {
      Swal.fire('Warning !', 'Maximum files upload limit is 10', 'warning')
      return false
    }
    if (files.length) {
      this.handleFiles(files)
    }
  }

  callToggle = (customer, callingFrom) => {
    this.setState({
      customerPhoneLoader: true,
      callingFrom,
    })
    axios
      .get(`${config.apiPath}/api/customer/getNumbers?customerId=${customer.id}`)
      .then((res) => {
        this.setState({
          customersPhoneNumbers: res.data,
          customerPhoneLoader: false,
        })
      })
      .catch((err) => {
        this.setState({
          customerPhoneLoader: false,
        })
      })
    this.setState({ callToggleState: !this.state.callToggleState })
  }

  fetchCallLogs = (leadDetails) => {
    this.setState({ callLogs: [] })
    axios
      .get(
        `${config.apiPath}/api/leads/tasks?web=true&cmLeadId=${leadDetails.id}&taskType[]=called`
      )
      .then((res) => {
        this.setState({
          callLogs: res.data,
        })
      })
  }

  detectModal = (value) => {
    if (!value) {
      this.setState({ followupModalFlag: false })
    }
  }

  closeLostToggle = () => {
    this.setState({ closeLostToggleState: !this.state.closeLostToggleState })
  }

  localStateUpdate = (id) => {
    this.setState({ singleLeadIdState: id })
    this.props.updatePropState(id)
    this.props.setSelectedLeadId(id)
  }
  followUpModal = (id) => {
    this.setState({ followupModalFlag: !this.state.followupModalFlag })
    this.props.updatePropState(id)
  }
  addFollowUp = (event) => {
    this.followUpModal()
    event.stopPropagation()
  }

  closeCallModal = () => {
    this.setState({
      callToggleState: false,
      callLogs: [],
    })
  }

  showFeedbackToggle = () => {
    const { showFeedback } = this.state
    this.setState({ showFeedback: !showFeedback })
  }

  fetchLeadTimeLine = (id) => {
    this.props.fetchLeadTimeLine(id)
    this.props.fetchLeads()
  }

  shareToggle = (shareLeadTypePara) => {
    const { shareToggleState } = this.state
    this.setState({
      shareToggleState: !shareToggleState,
      shareLeadType: shareLeadTypePara,
    })
  }

  // fetchAgents = (data,page) => {
  //   const { shareLeadType } = this.state
  //   this.setState({
  //     shareloader: true,
  //   })
  //   axios
  //     .get(
  //       shareLeadType === 'assign'
  //         ? `${config.apiPath}/api/user/agents?leads=true&searchBy=${
  //             data ? data : 'myTeam'
  //           }&all=true&page=${page}&pageSize=50?web=true`
  //         : `${config.apiPath}/api/user/agents?sharing=${true}&page=${page}&pageSize=50?web=true`
  //     )
  //     .then((res) => {
  //       this.setState({
  //         agents: res.data,
  //         shareloader: false,
  //       })
  //     })
  // }

  fetchAgents = (data, page, LeadType) => {
    const role_id = localStorage.getItem('roleId')

    this.setState({
      shareloader: true,
    })
    axios
      .get(
        LeadType === 'assign'
          ? `${
              config.apiPath
            }/api/role/sub-users?roleId=${role_id}&addManager=${true}&page=${page}&pageSize=${config.pageSize}&web=true&leadType=Project`
          : `${
              config.apiPath
            }/api/user/agents?sharing=${true}&page=${page}&pageSize=${config.pageSize}&web=true&leadType=Project`
      )
      .then((res) => {
        this.setState({
          agents: LeadType === 'assign' ? res.data : res.data.rows,
          agentCount: LeadType !== 'assign' ? res.data.count : '',

          shareloader: false,
        })
      })
  }

  filterReferLeadAgentName = (filterName, page) => {
    this.setState({
      shareloader: true,
    })
    axios
      .get(
        `${
          config.apiPath
        }/api/user/agents?sharing=${true}&page=${page}&pageSize=${config.pageSize}&web=true&name=${filterName}&leadType=Project`
      )
      .then((res) => {
        this.setState({
          agents: res.data.rows,
          agentCount: res.data.count,
          shareloader: false,
        })
      })
  }

  updateHover = (value) => {
    this.setState({ showCloseIcon: value })
  }
  investmentLeadShareFun = (id) => {
    const { addInvestmentShareLead, fetchLeadTimeLine, fetchLeads } = this.props
    const { singleLeadIdState } = this.state
    const user_id = localStorage.getItem('user_id')
    let shareData = {
      userId: id,
      leadId: singleLeadIdState,
    }

    addInvestmentShareLead(shareData, fetchLeadTimeLine, singleLeadIdState)
    setTimeout(() => {
      Swal.fire('Success', 'Lead Shared Successfully', 'success')
      fetchLeads()
      this.setState({
        shareToggleState: false,
      })
    }, 800)
  }

  hasBooking = () => {
    if (this.props.popup) return null
    return this.props.location.pathname === ProjectLeadUrl ? false : true
  }

  investmentLeadReferFun = (id, firtName, lastName) => {
    const { addInvestmentShareLead, fetchLeadTimeLine, fetchLeads, history, pathname } = this.props
    const { singleLeadIdState } = this.state
    const user_id = localStorage.getItem('user_id')
    let shareData = {
      userId: id,
      leadId: singleLeadIdState,
    }

    const showLeadReferSuccessMsgHandler = () => {
      showToastMsg(
        `Lead has successfully been referred to <b>${firtName + ' ' + lastName} </b>`,
        'success'
      )
    }

    addInvestmentShareLead(
      shareData,
      fetchLeadTimeLine,
      singleLeadIdState,
      null,
      showLeadReferSuccessMsgHandler
    )
    setTimeout(() => {
      const { history, user } = this.props
      const { pathname } = this.props.location

      const urlParams = new URLSearchParams(window.location.search)
      const pageSize = urlParams.get('pageSize')
      const page = urlParams.get('page')
      var offset = 0
      if (isNaN((page - 1) * pageSize)) {
        offset = 0
      } else {
        offset = (page - 1) * pageSize
      }
      let query = { offset, pageSize }
      if (!(page && pageSize) && !this.props.popup) {
        history.push(`${pathname}?page=${page || '1'}&pageSize=${pageSize || config.pageSize}`)
      }

      if (this.props.customer) query.clientId = this.props.customer.id

      this.props.fetchLeads(
        query,
        this.props.leadOrder && this.props.leadOrder.value,
        (data) => {
          this.setState({ initialCount: data.count })
        },
        this.hasBooking()
      )

      this.setState({
        shareToggleState: false,
      })
    }, 0)
  }

  assignLeadFun = (id, firstName, lastName) => {
    let space = ' '
    const { addInvestmentShareLead, fetchLeadTimeLine } = this.props
    const { singleLeadIdState } = this.state
    const user_id = localStorage.getItem('user_id')
    let assignData = {
      leadId: [singleLeadIdState],
      type: 'investment',
      userId: id,
    }
    axios.patch(`${config.apiPath}/api/leads/assign`, assignData).then((res) => {
      this.setState({
        shareToggleState: false,
      })
      Swal.fire(
        'Success',
        `Lead re-assigned to <b>${firstName + space + lastName}</b> successfully`,
        'success'
      )
      setTimeout(() => {
        this.props.updateList()
      }, 300)
    })
  }

  deleteAttachment = (id) => {
    const { fetchLeadAttachmentsFunc, itemId } = this.props
    let promises = []
    promises.push(this.props.deleteLeadAttachmentFun(id))

    Promise.all(promises).then((res) => {
      setTimeout(() => {
        fetchLeadAttachmentsFunc(itemId)
        fetchLeadTimeLine(itemId)
      }, 2000)
    })
  }

  commentToggle = (value) => {
    const { commentToggleState } = this.state
    this.setState({
      commentToggleState: value ? value : !commentToggleState,
    })
  }

  addCommentToServer = (comment) => {
    const { fetchAllComments, addInvestmentComment } = this.props
    const { singleLeadIdState } = this.state
    let commentObject = {
      value: comment,
      type: 'comment',
      cmLeadId: singleLeadIdState && singleLeadIdState,
      title: 'comment',
    }

    addInvestmentComment(commentObject, fetchAllComments)
  }

  extractAdditionalDetails = (value) => {
    // const parsedData = JSON.parse(value)

    let objectKeys = Object.keys(value)

    let renderAdditionalInformations =
      objectKeys &&
      objectKeys.map((item, index) =>
        value[item].map((internalItem) => <span>{(index ? ', ' : '') + internalItem}</span>)
      )

    return renderAdditionalInformations
  }

  closeWonToggle = () => {
    const { closeWonToggleState } = this.state
    this.setState({
      closeWonToggleState: !closeWonToggleState,
    })
  }
  detailToggle = () => {
    this.setState({ detailsToggleState: !this.state.detailsToggleState })
  }
  createNewLeadToggle = () => {
    this.setState({ createNewLeadToggleState: !this.state.createNewLeadToggleState })
  }

  viewLeadDetails = (item) => {

    this.props.setSetupTaskModal(true)
    this.props.setSelectedLead(item)
  }

  render() {
    const urlParams = new URLSearchParams(window.location.search)
    const paginationPage = urlParams.get('page')
    const {
      item,
      item: { id, customer, project, status, createdAt },
      statusColor,
      fetchLeadTimeLine,
      setCloseLostToggleState,
      fetchUser,
      user,
      allComments,
      allCommentsLoader,
      addCommentLoader,
      checkForRead,
      updateList,
      leadAttachmentLoader,
      leadAttachments,
      selectLead,
      selectedLeads,
      pageType,
      popup,
      reasonColWidth,
      selectedLead,
      clientLeadModal,
      tabUrl,
      otherleads
    } = this.props

    const {
      followupModalFlag,
      meetingToggleState,
      detailsToggleState,
      createNewLeadToggleState,
      showOverlay,
      selectedFiles,
      showProgressBar,
      progressInfos,
      disabledButton,
      attachmentToggleState,
      showFeedback,
      singleLeadIdState,
      callRecord,
      shareToggleState,
      agents,
      shareloader,
      commentToggleState,
      shareLeadType,
      taskModalAction,
      followUpModalAction,
      showCloseIcon,
      attachmentLoader,
      closeAfterAdd,
      closeWonToggleState,
      agentCount,
      customersPhoneNumbers,
      customerPhoneLoader,
      callingFrom,
      callToggleState,
      showAlertToggle,
      callLogs,
      isClientLeads
    } = this.state

    const commentData =
      allComments &&
      allComments.map((item) => ({
        name: item && item.armsuser && item.armsuser.firstName + ' ' + item.armsuser.lastName,
        date: moment(item.createdAt),
        comment: item.value,
        title: item.title,
      }))

    let unique = _.uniqBy(selectedFiles, 'name')
    // date difference test
    var date1 = new Date('4/27/2021')
    var date2 = new Date('5/3/2021')

    // To calculate the time difference of two dates
    var Difference_In_Time = date2.getTime() - date1.getTime()

    // To calculate the no. of days between two dates
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24)

    //To display the final no. of days (result)
    // console.log(Difference_In_Days)
    let statusColorImage =
      statusColor === 'delay__30__60'
        ? YellowLead
        : statusColor === 'delay__60__above'
        ? RedLead
        : ''

    let role = localStorage.getItem('role')
    let leadCategoryColor =
      (item.leadCategory &&
        StaticData.leadCategories.filter((c) => c.value === item.leadCategory).length &&
        StaticData.leadCategories.filter((c) => c.value === item.leadCategory)[0].colorCode) ||
      null

    return (
      <>
        <tr
          className={` hoverForTr
          ${selectedLead && selectedLead.id === item.id ? ' in-action ' : ''}
          detail-main detail-main-cursor investLeadStatus hover__for__btns`}
          tabIndex="0"
          onClick={(e) => {
            if (!this.props.dialerLeadInformation.isOwnLead) {
              return;
            }
            if (clientLeadModal && tabUrl === 'existinglead') this.viewLeadDetails(item)
            if (!popup) {this.viewLeadDetails(item)}
            else if (popup && otherleads) {
              this.viewLeadDetails(item)
              this.props.setSelectedLead(item)
            }
            else {
              this.props.setSelectedLead(item)
            }

            this.props.bookUnitRedirect && this.props.bookUnitRedirect(item.id)
            this.props.onRowClick && this.props.onRowClick(item)
          }}
        >
          {!popup && accountsPermissions('projectReassign') && (
            <td style={{ maxWidth: '30px', minWidth: '30px' }}>
              <input
                className="checkbox-position"
                type="checkbox"
                checked={selectedLeads.indexOf(item.id) !== -1 ? true : false}
                onClick={(e) => {
                  e.stopPropagation()
                  selectLead(item.id, item)
                }}
              />
            </td>
          )}

          <td style={{ maxWidth: '30px', minWidth: '30px' }}>
            {statusColor !== 'shared' || statusColor !== 'new' ? (
              <img src={statusColorImage} className="lead__reprecentation" />
            ) : (
              ''
            )}
          </td>
          <td className="max-table-width" style={{ maxWidth: 120, minWidth: 120 }}>
            {!popup ? (
              <a
                onClick={() => {
                  if (!popup) this.viewLeadDetails(item)
                }}
                href={'#!'}
              >
                {id}
              </a>
            ) : (
              id
            )}
            {statusColor === 'Refered' && (
              <span className="statusText investLeadStatus shared">Referred</span>
            )}
          </td>
          {this.props.location.pathname !== ProjectDealUrl && (
            <td className="max-table-width" style={{ maxWidth: '140px', minWidth: '140px' }}>
              {moment(createdAt).format('DD-MM-YYYY (hh:mm a)')}
            </td>
          )}
          <td className="max-table-width" style={{ maxWidth: '130px', minWidth: '130px' }}>
            {customer && customer.customerName !== ' ' ? customer && customer.customerName : '--'}
          </td>
          <td className="max-table-width" style={{ maxWidth: '130px', minWidth: '130px' }}>
            {project ? (project && project.name !== null ? project && project.name : '--') : '--'}
          </td>
          <td className="max-table-width" style={{ maxWidth: '130px', minWidth: '130px' }}>
            {item.armsuser ? item.armsuser.firstName + ' ' + item.armsuser.lastName : '--'}
          </td>
          <td
            className={'max-table-width leadStatusColorContainer'}
            style={{ maxWidth: '100px', minWidth: '100px' }}
          >
            <div className={item ? leadStatusClass(item, 'Project') : ''}>
              {RenderLeadStatus(status && status.toLowerCase(), StaticData.projectLeadStatus)}
            </div>
          </td>
          {this.props.location.pathname !== ProjectDealUrl && (
            <>
              <td
                className="max-table-width text-center daw"
                style={{ maxWidth: '160px', minWidth: '160px' }}
              >
                {item.leadCategory ? (
                  <span
                    className={'lead-category-status'}
                    style={{
                      color: leadCategoryColor,
                    }}
                  >
                    {item.leadCategory}
                  </span>
                ) : (
                  '--'
                )}
              </td>
              <td className="max-table-width" style={{ maxWidth: '150px', minWidth: '150px' }}>
                {item.followUP ? moment(item.followUP.start).format('D MMM, h:mm a') : '--'}
                {item.followUP &&
                moment().startOf('day').diff(moment(item.followUP.start).startOf('day'), 'days') ===
                  0 ? (
                  <span className="statusText followup-today">Today</span>
                ) : (
                  ''
                )}
              </td>
            </>
          )}
          <td className="max-table-width" style={{ maxWidth: '130px', minWidth: '130px' }}>
            {item.origin ? item.origin : '--'}
          </td>

          {pageType === ProjectDealUrl && (
            <td className="max-table-width" style={{ maxWidth: '120px', minWidth: '120px' }}>
              {item.unit && item.unit.finalPrice
                ? Math.round(item.unit.finalPrice).toLocaleString()
                : '--'}
            </td>
          )}

          {pageType === ProjectDealUrl && (
            <>
              <td className="max-table-width" style={{ maxWidth: '130px', minWidth: '130px' }}>
                {(item.floor && item.floor.name) || '--'}
              </td>
              <td className="max-table-width" style={{ maxWidth: '130px', minWidth: '130px' }}>
                {(item.unit && item.unit.name) || '--'}
              </td>{' '}
            </>
          )}
          {pageType !== ProjectDealUrl && (
            <>
              <td className="max-table-width" style={{ maxWidth: '130px', minWidth: '130px' }}>
                {item.productTypes && item.productTypes.name != null
                  ? item.productTypes.name
                  : '--'}
              </td>
              <td className="max-table-width" style={{ maxWidth: '120px', minWidth: '120px' }}>
                {item && item.city && item.city != null && item.city.name != null
                  ? item.city.name
                  : '--'}
              </td>
              <td className="max-table-width" style={{ maxWidth: '150px', minWidth: '150px' }}>
                {(item && item.fbAdditionalDetails === '{}') ||
                (item && item.fbAdditionalDetails === null)
                  ? '--'
                  : this.extractAdditionalDetails(JSON.parse(item.fbAdditionalDetails))}
              </td>
            </>
          )}

          {this.props.location.pathname !== ProjectDealUrl && (
            <>
              <td className="max-table-width" style={{ maxWidth: '150px', minWidth: '150px' }}>
                {item.armsuser && item.armsuser.phoneNumber
                  ? normalizePhoneFormat(item.armsuser.phoneNumber)
                  : '--'}
              </td>

              <td className="max-table-width" style={{ maxWidth: '130px', minWidth: '130px' }}>
                {item && item.compaignId !== null ? item.compaignId : '--'}
              </td>
            </>
          )}
          <td
            className="max-table-width"
            style={{ maxWidth: reasonColWidth, minWidth: reasonColWidth }}
          >
            {item.reasons ? item.reasons : '--'}
          </td>

          {!popup && (
            <td
              className="actions-td cus-z-index"
              style={{ maxWidth: 120, minWidth: 120 }}
              onClick={(e) => e.stopPropagation()}
            >
              <div className="d-flex justify-content-center button-padding">
                {this.props.location.pathname === '/project-deal' && (
                  <button
                    className="phone__button btn"
                    onClick={(e) => {
                      e.stopPropagation()
                      this.callToggle(item.customer, 'outer')
                      this.fetchCallLogs(item)
                    }}
                    style={{
                      marginRight: 3,
                      border: 'none',
                      backgroundColor: '#1773ef',
                      paddingBottom: 1,
                      paddingTop: 1,
                    }}
                  >
                    <img src={CallIcon} />
                  </button>
                )}

                {accountsPermissions('projectReassign') &&
                  item.status !== 'closed_won' &&
                  !item.requiredProperties && (
                    <div className="dropdown main__dropdown--action">
                      <button
                        className="btn-cus-add ml-1 action-cus"
                        type="button"
                        id="dropdownMenuButton"
                        onClick={() => {
                          this.localStateUpdate(id)
                          this.setState({ edit: true })
                          this.fetchAgents(null, null, 'assign')
                          this.shareToggle('assign')
                          this.props.setSelectedLead(item)
                        }}
                      >
                        Re-assign
                      </button>
                    </div>
                  )}
              </div>
            </td>
          )}
          <td
            className="actions-td cus-z-index"
            style={{maxWidth: 120, minWidth: 120}}
            onClick={(e) => e.stopPropagation()}
          >
            <div className="d-flex justify-content-center button-padding">
              <div className="dropdown main__dropdown--action">
                <div className={'btn-group'}>
                  <LeadClickComponent leadData={{leadId: item.id, diaryId: item.latestDiaryId,leadType:'Project'}}/>
                </div>
              </div>
            </div>
          </td>
        </tr>
        {!popup && (
          <>
            {followupModalFlag ? (
              <FollowUpModal
                user={user}
                fetchUser={() => fetchUser()}
                callBack={() => updateList()}
                id={id}
                lead={item}
                checkForRead={checkForRead}
                isOpen={followupModalFlag}
                whichPage={'investmentLeadList'}
                modalToggle={() => this.followUpModal(id)}
                detectModal={this.detectModal}
                followUpAction={followUpModalAction}
                setFollowUpAction={(action) => this.setState({ followUpModalAction: action })}
                setFollowUpAction={(action) => this.setState({ followUpModalAction: action })}
                shouldCloseAfterAdd={(toggle) => this.setState({ closeAfterAdd: toggle })}
                closeAfterAdd={closeAfterAdd}
                showAlertToggle={showAlertToggle}
              />
            ) : null}

            {callToggleState && (
              <CallModal
                callToggleState={callToggleState}
                customersPhoneNumbers={customersPhoneNumbers}
                customerPhoneLoader={customerPhoneLoader}
                callLogs={callLogs}
                checkForRead={checkForRead}
                whichPage={'investmentLeadList'}
                callingFrom={callingFrom}
                addInvestmentCallFun={this.addInvestmentCallFun}
                callToggle={this.callToggle}
                closeCallModal={this.closeCallModal}
                showAlertToggle={showAlertToggle}
              />
            )}

            {showFeedback ? (
              <MeetingActions
                callBack={() => {}}
                shouldCloseAfterAdd={(toggle) => this.setState({ closeAfterAdd: toggle })}
                leadId={singleLeadIdState}
                leadType={'investment'}
                feedbackType={'Call'}
                showFeedback={showFeedback}
                setShowFeedback={(toggle) => this.showFeedbackToggle(toggle)}
                setFollowupModalFlag={(toggle) => {
                  this.followUpModal()
                  this.setState({ showFeedback: false })
                }}
                fetchUser={() => this.props.fetchUser()}
                closedLost={() => {
                  this.setState({ showFeedback: false })
                  updateList()
                }}
                meeting={callRecord}
                meetingToggle={() => {
                  this.setState({
                    taskModalAction: 'add-meeting',
                  })
                  this.meetingToggle()
                }}
                callToggle={() => {
                  this.callToggle(customer, 'outer')
                  this.fetchCallLogs(item)
                }}
                setFollowupModalFlag={(toggle) =>
                  this.setState({
                    followupModalFlag: toggle,
                    followUpModalAction: 'add',
                  })
                }
                setCloseLostToggleState={(toggle) => setCloseLostToggleState(id)}
                showAlertToggle={showAlertToggle}
              />
            ) : (
              <></>
            )}
            {detailsToggleState && (
              <ClientDetails
                onToggle={this.detailToggle}
                isOpen={detailsToggleState}
                leadRecord={item}
              ></ClientDetails>
            )}
            {createNewLeadToggleState && (
              <ClientNewLead
                onToggle={this.createNewLeadToggle}
                isOpen={createNewLeadToggleState}
                lead={item}
                {...this.props}
              ></ClientNewLead>
            )}
            <AttachmentModal
              attachmentToggleState={attachmentToggleState}
              attachmentToggle={this.attachmentToggle}
              leadAttachmentLoader={leadAttachmentLoader}
              leadAttachments={leadAttachments}
              updateHover={this.updateHover}
              showCloseIcon={showCloseIcon}
              deleteAttachment={this.deleteAttachment}
              addDocuments={this.addDocuments}
              dragOver={this.dragOver}
              dragEnter={this.dragEnter}
              dragLeave={this.dragLeave}
              fileDrop={this.fileDrop}
              showOverlay={showOverlay}
              checkForRead={checkForRead}
              showProgressBar={showProgressBar}
              progressInfos={progressInfos}
              unique={unique}
              removeSpecificData={this.removeSpecificData}
              disabledButton={disabledButton}
              addManualAttachments={this.addManualAttachments}
              attachmentLoader={attachmentLoader}
              showAlertToggle={showAlertToggle}
            />

            <CommentModal
              commentToggleState={commentToggleState}
              commentToggle={this.commentToggle}
              allCommentsLoader={allCommentsLoader}
              commentData={commentData}
              checkForRead={checkForRead}
              addCommentToServer={this.addCommentToServer}
              addCommentLoader={addCommentLoader}
              fetchLeadTimeLine={fetchLeadTimeLine}
              leadId={singleLeadIdState && singleLeadIdState}
            />

            {meetingToggleState  && (
              <TaskModal
                user={user}
                fetchUser={() => fetchUser()}
                leadId={item.id}
                lead={item}
                leadType={'project'}
                callBack={(action) => {
                  if (action === 'closedLost') {
                    updateList()
                  }
                }}
                taskModalAction={taskModalAction}
                checkForRead={checkForRead}
                setTaskModalAction={(action) => this.setState({ taskModalAction: action })}
                fromPage={'lead-listing'}
                meetingToggle={() => this.meetingToggle()}
                setActiveTab={() => this.setState({ activeTab: 'payment' })}
                setTaskToggleState={(toggle) => this.setState({ meetingToggleState: toggle })}
                taskToggleState={meetingToggleState}
                followupModalFlag={followupModalFlag}
                setFollowupModalFlag={(toggle) =>
                  this.setState({
                    followupModalFlag: toggle,
                    followUpModalAction: 'add',
                  })
                }
                meetingFeedback={this.state.meetingFeedback}
                setMeetingFeedback={(toggle) => this.setState({ meetingFeedback: toggle })}
                setCloseLostToggleState={(toggle) => setCloseLostToggleState(toggle)}
                callToggle={() => {
                  this.callToggle(customer, 'outer')
                  this.fetchCallLogs(item)
                }}
                shouldCloseAfterAdd={(toggle) => this.setState({ closeAfterAdd: toggle })}
                closeAfterAdd={closeAfterAdd}
                showAlertToggle={showAlertToggle}
                actionListRefer={true}
                shared_with_armsuser_id={item.shared_with_armsuser_id}
              />
            )}
            <ReferModal
              shareToggleState={shareToggleState}
              shareToggle={this.shareToggle}
              managerSelectionDropdown={managerSelectionDropdown}
              loader={shareloader}
              agents={agents}
              fetchAgents={this.fetchAgents}
              filterReferLeadAgentName={this.filterReferLeadAgentName}
              investmentLeadShareFun={this.investmentLeadReferFun}
              assignLeadFun={this.assignLeadFun}
              shareLeadType={shareLeadType}
              agentCount={agentCount}
            />
          </>
        )}
      </>
    )
  }
}

export const mapDispatchToProps = (dispatch) => {
  return {
    addInvestmentMeeting: (data) => dispatch(investmentMeeting(data)),
    fetchLeadTimeLine: (id) => dispatch(fetchLeadTimeLine(id)),
    fetchLeads: (query, sortingOrder, callback, bookingCondition, specialCase) =>
      dispatch(fetchLeads(query, sortingOrder, callback, bookingCondition, specialCase)),
    addInvestmentShareLead: (obj, callback, leadId, type, showLeadReferSuccessMsgHandler) =>
      dispatch(investmentShareLead(obj, callback, leadId, type, showLeadReferSuccessMsgHandler)),
    fetchAllComments: (id) => dispatch(fetchAllComments(id)),
    addInvestmentComment: (obj, callback) => dispatch(addComment(obj, callback)),
    deleteLeadAttachmentFun: (id) => dispatch(deleteLeadAttachment(id)),
    fetchLeadAttachmentsFunc: (id) => dispatch(fetchLeadAttachments(id)),
  }
}

export const mapStateToProps = (state) => {
  return {
    allCommentsLoader: state.Leads.allCommentsLoader,
    allComments: state.Leads.allComments,
    userInfo: state.user.userInfo,
    leadAttachmentLoader: state.Leads.leadAttachmentLoader,
    leadAttachments: state.Leads.leadAttachments,
    singleLeadRecord: state.Leads.singleLeadRecord,
    leadOrder: state.GeneralSetting.dataSort,
    dialerLeadInformation:state.Diary.dialerLeadInformation
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(InvestmentLeadInner))
