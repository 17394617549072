import { SET_UNIT_FALSE_FLAG, NAV_TOGGLE, MOUSE_ENTER, MOUSE_LEAVE, SHOW_ARROW_ENTER, SHOW_ARROW_LEAVE, SHOW_COLLAPSE_MENU, SHOW_COLLAPSE_CLOSE, SET_UNIT_TRUE_FLAG, UNIT_FLAG_DETECT } from '../actions/navToggle'
const initialState = {
  sideBarToggle: false,
  mouseEnterFlag: false,
  showArrow: false,
  unitFloorFlag: true,
  unitFloorFalseFlag: true
}

export const navToggle = (state = initialState, action) => {
  switch (action.type) {
    case NAV_TOGGLE:
      return {
        ...state,
        sideBarToggle: !state.sideBarToggle
      }
    case MOUSE_ENTER:
      return {
        ...state,
        mouseEnterFlag: true
      }

    case SHOW_COLLAPSE_MENU:
      return {
        ...state,
        collapseMenu: true
      }
    case SHOW_COLLAPSE_CLOSE:
      return {
        ...state,
        collapseMenu: false
      }
    case MOUSE_LEAVE:
      return {
        ...state,
        mouseEnterFlag: false
      }
    case SHOW_ARROW_ENTER:
      return {
        ...state,
        showArrow: true
      }
    case SHOW_ARROW_LEAVE:
      return {
        ...state,
        showArrow: false
      }
    case SET_UNIT_TRUE_FLAG:
      return {
        ...state,
        unitFloorFlag: true
      }
    case UNIT_FLAG_DETECT:
      return {
        ...state,
        unitFloorDetectState: action.data
      }
    case SET_UNIT_FALSE_FLAG:
      return {
        ...state,
        unitFloorFalseFlag: state.unitFloorFalseFlag
      }
    default:
      return state
  }
}