/** @format */

import React, { useState, useMemo, useEffect, useRef } from 'react'
import moment from 'moment'

import { connect } from 'react-redux'
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from 'reactstrap'
import axios from 'axios'
import { DateTimePicker } from 'react-rainbow-components'

import config from '../../config'
import {
  leadEditToggle,
  editDiary,
  fetchLeadTimeLine,
  investmentMeeting,
  leadTabToggle,
} from '../../actions/leads'
import FollowUpModal from '../../views/InvestmentLead/FollowUpModal'
import { meetingStatus } from '../../utils/constant_data'
import MeetingIconModal from '../../images/meeting-icon.svg'
import { extractFileExtension, showInfoMessage } from '../../utils/commonFormat'
import PDFThumbnail from '../../components/PDFThumbnail'
import './timelineIconStyle.scss'
import Comment from '../../images/comment.svg'
import Call from '../../images/call.svg'
import Attachment from '../../images/attachment.svg'
import Meeting from '../../images/meeting.svg'
import BookUnit from '../../images/book-unit.svg'
import Status from '../../images/status.svg'
import ShareLeadTimeline from '../../images/share_lead_timeline.svg'
import WhatsappIcon from '../../images/whatsapp.svg'
import PaymentIcon from '../../images/Payment.svg'
import LeadStatus from '../../images/lead.svg'
import Task from '../../images/task.svg'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-toastify/dist/ReactToastify.min.css'
import MeetingActions from '../MeetingAction'
import CancelConfirmModal from '../Modals/TaskModal/CancelConfirm'
import { checkGoogleAuthorize } from '../../utils/helper'
import { toast } from 'react-toastify'
import TaskModal from '../Modals/TaskModal/TaskModal'
import CloseLostFeedback from '../Modals/FeedbackModal/ClosedLost'
import CallModal from '../InvestmentCallModal'
import ReferenceGuideModal from '../Modals/TaskModal/ReferenceGuideModal'

const TimelineIcon = ({
  timelineData,
  leadEditToggleFun,
  leadEditToggleState,
  editDiary,
  fetchLeadTimeLineFun,
  singleLeadRecord,
  leadTabToggleFun,
  leadType,
  user,
  fetchUser,
  checkForRead,
  fetchSingleLead,
  closeAfterAdd,
  shouldCloseAfterAdd,
}) => {
  let meetingCurrentDate = new Date()
  const [timelineId, setTimeLineId] = useState('')
  const [editShow, setEditShow] = useState(false)
  const [attachmentDeletion, setAttachmentDeletion] = useState(false)
  const [meetingToggleState, setMeetingToggleState] = useState(false)
  const [commentToggleState, setCommentToggleState] = useState(false)
  const [showFeedback, setShowFeedback] = useState(false)
  const [followupModalFlag, setFollowupModalFlag] = useState(false)
  const dropdownRef = useRef(null)
  const [meetingStartDate, setMeetingStartDate] = useState(meetingCurrentDate)
  const [meetingEndDate, setMeetingEndDate] = useState(meetingCurrentDate)
  const [meetingLoader, setMeetingLoader] = useState(false)
  const [editToggle, setEditToggle] = useState(false)
  const [cancelConfirmModal, setCancelConfirmModal] = useState(false)
  const [selectedDiary, setSelectedDiary] = useState(null)
  const [diaryAction, setDiaryAction] = useState(null)
  const [taskToggleState, setTaskToggleState] = useState(false)
  const [followUpModalAction, setFollowUpModalAction] = useState('list')
  const [taskModalAction, setTaskModalAction] = useState('list')
  const [callToggleState, setCallToggleState] = useState(false)
  const [meetingFeedback, setMeetingFeedback] = useState(false)
  const [callLogs, setcallLogs] = useState([])
  const [customerPhoneLoader, setcustomerPhoneLoader] = useState(false)
  const [closeLostToggleState, setCloseLostToggleState] = useState(false)
  const [customersPhoneNumbers, setcustomersPhoneNumbers] = useState(null)
  const [investmentCallLoader, setInvetmentCallLoader] = useState(false)
  const [callRecord, setCallRecord] = useState('')
  const [guideReferenceModal, setGuideReferenceModal] = useState(false)

  useMemo(() => {
    if (leadEditToggleState && leadEditToggleState.condition && editShow) {
      setEditShow(false)
      leadEditToggleFun({ condition: false })
    }
  }, [leadEditToggleState])

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideDropdown, true)
    window.scrollTo(1, 100)
    return () => {
      document.removeEventListener('click', handleClickOutsideDropdown, true)
    }
  })

  useEffect(() => {
    if (['cancel-task', 'cancel-meeting', 'task-done'].indexOf(diaryAction) > -1)
      checkGoogleAuthorize(user, afterGoogleAuthCallback)
  }, [diaryAction])

  const afterGoogleAuthCallback = (token_exist_already = false) => {
    if (!token_exist_already) fetchUser()

    if (diaryAction === 'cancel-task' || diaryAction === 'cancel-meeting') {
      cancelMeeting(selectedDiary.id)
    } else if (diaryAction === 'task-done') markAsDone(selectedDiary.id)
  }

  const markAsDone = (id) => {
    axios
      .patch(`${config.apiPath}/api/diary/update/?id=${id}`, {
        leadId: singleLeadRecord && singleLeadRecord.id,
        status: 'completed',
      })
      .then((res) => {
        fetchLeadTimeLineFun(singleLeadRecord && singleLeadRecord.id)
      })
  }

  const followUpModal = () => {
    setFollowupModalFlag(!followupModalFlag)
  }

  const handleClickOutsideDropdown = (event) => {
    // Need to close dropdowns when click outside
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) setEditShow(false)
  }

  const editDiaryFunc = (response, id) => {
    let data = {
      response,
      leadId: singleLeadRecord && singleLeadRecord.id,
    }
    editDiary(data, fetchLeadTimeLineFun, id, singleLeadRecord && singleLeadRecord.id)
  }

  const showFeedbackToggle = () => {
    setEditShow(false)
    setShowFeedback(!showFeedback)
  }

  const updateMeeting = (response, id) => {
    setMeetingLoader(true)
    let data = {
      time: moment(meetingStartDate).format(),
      start: moment(meetingStartDate).format(),
      end: editToggle ? moment(meetingEndDate).format() : moment(meetingStartDate).format(),
      date: moment(meetingStartDate).format(),
    }
    editDiary(data, fetchLeadTimeLineFun, id, singleLeadRecord && singleLeadRecord.id)
    setTimeout(() => {
      setMeetingLoader(false)
    }, 500)
  }

  const cancelMeeting = (id) => {
    axios
      .delete(
        `${config.apiPath}/api/diary/delete?id=${id}&cmLeadId=${
          singleLeadRecord && singleLeadRecord.id
        }`
      )
      .then((res) => {
        fetchLeadTimeLineFun(singleLeadRecord && singleLeadRecord.id)

        toast.dismiss()
        setSelectedDiary(null)
        setDiaryAction(null)
      })
  }

  const addInvestmentCallFun = (type, number) => {
    setInvetmentCallLoader(true)
    let subject = `${type != 'phone' ? 'contacted' : 'called'} ${
      singleLeadRecord && singleLeadRecord.customer && singleLeadRecord.customer.customerName
    }`
    let data = {
      start: moment().format(),
      end: moment().format(),
      time: moment().format(),
      date: moment().format(),
      taskType: 'called',
      response: 'Called',
      subject: subject,
      customerId: singleLeadRecord.customer && singleLeadRecord.customer.id,
      taskCategory: 'leadTask',
      leadId: singleLeadRecord && singleLeadRecord.id,
      calledOn: type != 'phone' ? 'whatsApp' : 'phone',
      title: type != 'phone' ? 'whatsApp' : 'phone',
    }

    if (type === 'whatsApp') {
      axios.post(`${config.apiPath}/api/leads/project/meeting`, data).then((res) => {
        setCallRecord(res.data)
        if (type && type === 'whatsApp') {
          window.open(`https://wa.me/${number}`, '_blank')
        }
        setCallToggleState(false)
        // showFeedbackToggle()
        setInvetmentCallLoader(false)
        setShowFeedback(true)
      })
    } else {
      // in case of phone call we will log call after feedback
      setCallRecord(data)
      setCallToggleState(false)
      setInvetmentCallLoader(false)
      setShowFeedback(true)
    }
  }

  const closeLostToggle = () => {
    setShowFeedback(false)
    setCloseLostToggleState(!closeLostToggleState)
  }

  const commentToggle = () => {
    setEditShow(false)
    setCommentToggleState(!commentToggleState)
  }

  const attachmentToggle = (type) => {
    setEditShow(false)
    if (type === 'attachment') {
      setAttachmentDeletion(!attachmentDeletion)
    }
  }

  const dateStartHandler = (value) => {
    setMeetingStartDate(value)
  }
  const dateEndHandler = (value) => {
    setMeetingEndDate(value)
  }

  const meetingToggle = () => {
    setEditShow(false)
    setMeetingToggleState(!meetingToggleState)
    setMeetingStartDate(moment(timelineData.time).toDate())
  }

  const deleteAttachment = (id, type) => {
    if (type === 'attachment') {
      axios.delete(`${config.apiPath}/api/leads/comments/remove?id=${id}`).then((res) => {
        fetchLeadTimeLineFun(singleLeadRecord && singleLeadRecord.id)
      })
    } else if (type === 'comment') {
      axios.delete(`${config.apiPath}/api/leads/comments/remove?id=${id}`).then((res) => {
        fetchLeadTimeLineFun(singleLeadRecord && singleLeadRecord.id)
      })
    }
  }

  const callToggle = (callToggleState) => {
    setCallToggleState(!callToggleState)
  }

  const getCustomerNumbers = (customer) => {
    setcustomerPhoneLoader(true)
    setCallToggleState(true)
    axios
      .get(`${config.apiPath}/api/customer/getNumbers?customerId=${singleLeadRecord.customer.id}`)
      .then((res) => {
        setcustomersPhoneNumbers(res.data)
        setcustomerPhoneLoader(false)
      })
      .catch((err) => {
        setcustomerPhoneLoader(false)
      })
  }

  const fetchCallLogs = (leadDetails) => {
    setcallLogs([])
    axios
      .get(
        `${config.apiPath}/api/leads/tasks?web=true&cmLeadId=${leadDetails.id}&taskType[]=called`
      )
      .then((res) => {
        setcallLogs(res.data)
      })
  }

  return (
    <>
      <div className="component-container">
        <div className="icon-container">
          <div className="icon-position-left">
            <img
              src={
                timelineData.activity === 'ArmsProjectLeads Unit Booked'
                  ? BookUnit
                  : timelineData.activity === 'token CmInstallments create (Update Project Lead)' ||
                    timelineData.activity === 'token CmInstallments create (Add Installments)' ||
                    timelineData.activity ===
                      'payment CmInstallments create (Update Project Lead)' ||
                    timelineData.activity === 'payment CmInstallments create (Add Installments)' ||
                    timelineData.activity === 'tax CmInstallments create (Add Installments)' ||
                    timelineData.activity === 'CmInstallments status changed (Update Payment)'
                  ? PaymentIcon
                  : timelineData.type === 'called' && timelineData.calledOnSource !== 'whatsApp'
                  ? Call
                  : timelineData.calledOnSource === 'whatsApp'
                  ? WhatsappIcon
                  : timelineData.type === 'attachment'
                  ? Attachment
                  : timelineData.activity === 'Share Project Lead'
                  ? ShareLeadTimeline
                  : timelineData.type === 'meeting' ||
                    timelineData.type === 'follow_up' ||
                    timelineData.type === 'viewing' ||
                    timelineData.type === 'call'
                  ? Meeting
                  : timelineData.activity === 'ArmsProjectLeads create (wanted)' ||
                    timelineData.activity === 'ArmsProjectLeads create(arms)' ||
                    timelineData.activity === 'ArmsProjectLeads create (facebook)' ||
                    timelineData.activity === 'ArmsProjectLeads Attachment create' ||
                    timelineData.activity === 'ArmsProjectLeads create (project_listing inquiry)' ||
                    timelineData.activity === 'ArmsProjectLeads create (ROI-25)' ||
                    timelineData.activity === 'ArmsProjectLeads create (Windows)' ||
                    timelineData.activity === 'ArmsProjectLeads create(aira)' ||
                    timelineData.activity === 'ArmsProjectLeads create (Facebook | Eid Cam AP)' ||
                    timelineData.activity === 'ArmsProjectLeads create (ROI-20)' ||
                    timelineData.activity === 'ArmsProjectLeads create (project inquiry)' ||
                    timelineData.activity ===
                      'ArmsProjectLeads create (agency21_website_project_page)' ||
                    timelineData.activity === 'ArmsProjectLeads create (ROI-15)' ||
                    timelineData.activity ===
                      'ArmsProjectLeads create (graana_project_listing_page_lead)' ||
                    timelineData.activity ===
                      'ArmsProjectLeads create (graana_project_page_lead)' ||
                    timelineData.activity === 'ArmsProjectLeads create (aira)'
                  ? LeadStatus
                  : (timelineData.type === 'Diary' && timelineData.action !== 'create') ||
                    (timelineData.type === 'ArmsProjectLeads' &&
                      timelineData.action !== 'create') ||
                    (timelineData.type && (timelineData.action !== 'create') === 'ArmsLeads')
                  ? Status
                  : timelineData.type === 'other' ||
                    timelineData.type === 'follow up' ||
                    timelineData.type === 'task'
                  ? Task
                  : timelineData.type === 'comment'
                  ? Comment
                  : null
              }
              className="left-icon-size"
            />
            <div className="icon-title-container">
              <span className="icon-title-entity ">{timelineData.containerTitle}</span>
              <span className="icon-title-text">{timelineData.subject}</span>
              <span className="card-container-field">
                {!checkForRead.showReadOnly || checkForRead.ownLeadReadOnly ? (
                  (timelineData.type === 'meeting' && timelineData.status !== 'completed') ||
                  timelineData.type === 'attachment' ||
                  (timelineData.type === 'follow_up' && timelineData.status !== 'completed') ||
                  timelineData.type === 'comment' ||
                  (timelineData.type === 'task' && timelineData.status !== 'completed') ? (
                    <i
                      className="far fa-ellipsis-v box_icon pl-4 mt-0 box_icon"
                      onClick={(e) => {
                        e.stopPropagation()
                        setEditShow(!editShow)
                        setTimeLineId(timelineData.id)
                      }}
                    />
                  ) : null
                ) : null}

                {editShow && (
                  <div ref={dropdownRef} className="card-container" key={timelineId}>
                    {(timelineData.type === 'meeting' && timelineData.diary !== null) ||
                    (timelineData.diary !== null &&
                      timelineData &&
                      timelineData.diary &&
                      timelineData.diary.taskType === 'meeting') ? (
                      <>
                        {meetingStatus &&
                          meetingStatus.map((item, index) => (
                            <div
                              className={
                                (timelineData &&
                                  timelineData.newValue !== undefined &&
                                  item.value ===
                                    JSON.parse(timelineData && timelineData.newValue).response) ||
                                (timelineData &&
                                  timelineData.newValue !== undefined &&
                                  item.value ===
                                    JSON.parse(timelineData && timelineData.newValue).status)
                                  ? 'container_separator card-container-active-text'
                                  : 'container_separator card-container-text'
                              }
                              key={index}
                              onClick={() => editDiaryFunc(item.value, timelineData.id)}
                            >
                              {item.name}
                            </div>
                          ))}
                      </>
                    ) : (
                      <>
                        {timelineData.type === 'meeting' ? (
                          <div
                            className="container_separator card-container-text"
                            onClick={() =>
                              timelineData.type === 'meeting' ? meetingToggle() : null
                            }
                          >
                            Edit
                          </div>
                        ) : null}

                        {timelineData.type === 'meeting' ? (
                          <>
                            <div
                              className="container_separator card-container-text"
                              onClick={() => {
                                if (singleLeadRecord && singleLeadRecord.guideReference)
                                  showFeedbackToggle()
                                else setGuideReferenceModal(true)
                              }}
                            >
                              Meeting Done
                            </div>
                            <div
                              className="container_separator card-container-text"
                              onClick={() => {
                                setSelectedDiary(timelineData)
                                setCancelConfirmModal(timelineData.id)
                                // cancelMeeting(timelineData.id)
                              }}
                            >
                              Cancel
                            </div>
                          </>
                        ) : null}

                        {timelineData.type === 'task' ? (
                          <>
                            <div
                              className="container_separator card-container-text"
                              onClick={() => {
                                setSelectedDiary(timelineData)
                                setDiaryAction('task-done')
                              }}
                            >
                              Mark as Done
                            </div>
                            <div
                              className="container_separator card-container-text"
                              onClick={() => {
                                setSelectedDiary(timelineData)
                                setCancelConfirmModal(timelineData.id)
                              }}
                            >
                              Cancel
                            </div>
                          </>
                        ) : null}

                        {timelineData.type === 'follow_up' ? (
                          <div
                            className="container_separator card-container-text"
                            onClick={() => {
                              setSelectedDiary(timelineData)
                              setDiaryAction('task-done')
                            }}
                          >
                            Mark as done
                          </div>
                        ) : null}

                        {timelineData.type === 'follow_up' ? (
                          <div
                            className="container_separator card-container-text"
                            onClick={() => {
                              setSelectedDiary(timelineData)
                              setCancelConfirmModal(timelineData.id)
                            }}
                          >
                            Cancel follow up
                          </div>
                        ) : null}

                        {timelineData.type === 'attachment' || timelineData.type === 'comment' ? (
                          <div
                            className="container_separator card-container-text"
                            onClick={() => {
                              if (timelineData.type === 'comment') {
                                commentToggle()
                              } else {
                                attachmentToggle(timelineData.type)
                              }
                            }}
                          >
                            Delete
                          </div>
                        ) : null}
                      </>
                    )}
                  </div>
                )}
              </span>
            </div>
          </div>
          <div className="icon-position-right">
            <div className="time-main-container">
              <span className="time-main-container-text">
                {moment(timelineData.date).format('dddd MMM D, LT')}
              </span>
            </div>
          </div>
        </div>
        <div className="main-content">
          {timelineData.type === 'attachment' ? (
            extractFileExtension(timelineData.attachmentData) === 'pdf' ? (
              <div
                className="main-content-attachment"
                onClick={() => window.open(timelineData.attachmentData, '_blank')}
              >
                <PDFThumbnail file={timelineData.attachmentData} timeLine={true} />
              </div>
            ) : (
              <img
                src={timelineData.attachmentData}
                className="attachment-image-size"
                onClick={() => window.open(timelineData.attachmentData, '_blank')}
              />
            )
          ) : timelineData.type === 'comment' ? (
            <p className="main-content-text">
              {' '}
              <span className="font-weight-bold">Comment:</span> {timelineData.commentContent}
            </p>
          ) : null}
        </div>
      </div>

      {guideReferenceModal && (
        <ReferenceGuideModal
          leadId={singleLeadRecord && singleLeadRecord.id}
          callBack={() => {
            setGuideReferenceModal(false)
            showFeedbackToggle()
          }}
          checkForRead={checkForRead}
          guideReferenceModal={guideReferenceModal}
          setGuideReferenceModal={(toggle) => setGuideReferenceModal(toggle)}
        />
      )}

      {cancelConfirmModal ? (
        <CancelConfirmModal
          diary={{ taskType: selectedDiary.type }}
          cancelConfirmModal={cancelConfirmModal}
          setCancelConfirmModal={(toggle) => setCancelConfirmModal(toggle)}
          setDiaryAction={(action) => setDiaryAction(action)}
          setSelectedDiary={(diary) => setSelectedDiary(diary)}
        />
      ) : (
        <></>
      )}
      {followupModalFlag && (
        <FollowUpModal
          user={user}
          fetchUser={() => fetchUser()}
          id={singleLeadRecord && singleLeadRecord.id}
          modalToggle={() => followUpModal()}
          fetchLeadTimeLine={fetchLeadTimeLineFun}
          isOpen={followupModalFlag}
          followUpAction={followUpModalAction}
          setFollowUpAction={(action) => {
            setFollowUpModalAction(action)
            fetchLeadTimeLineFun(singleLeadRecord && singleLeadRecord.id)
          }}
          closeAfterAdd={closeAfterAdd}
          shouldCloseAfterAdd={(toggle) => shouldCloseAfterAdd(toggle)}
          timeline={true}
        />
      )}

      {taskToggleState && (
        <TaskModal
          shouldCloseAfterAdd={(toggle) => shouldCloseAfterAdd(toggle)}
          closeAfterAdd={closeAfterAdd}
          leadId={singleLeadRecord && singleLeadRecord.id}
          lead={singleLeadRecord}
          leadType={'project'}
          user={user}
          fetchUser={() => fetchUser()}
          callBack={(action) => {
            fetchLeadTimeLineFun(singleLeadRecord && singleLeadRecord.id)
            fetchSingleLead(singleLeadRecord && singleLeadRecord.id)
          }}
          setTaskToggleState={(toggle) => {
            setTaskToggleState(toggle)
            fetchLeadTimeLineFun(singleLeadRecord && singleLeadRecord.id)
          }}
          taskToggleState={taskToggleState}
          setFollowupModalFlag={(toggle) => {
            setFollowUpModalAction('add')
            setFollowupModalFlag(toggle)
          }}
          meetingFeedback={meetingFeedback}
          setMeetingFeedback={(toggle) => setMeetingFeedback(toggle)}
          setActiveTab={(activeTab) => {}}
          taskModalAction={taskModalAction}
          setTaskModalAction={(action) => setTaskModalAction(action)}
          setCloseLostToggleState={(toggle) => {}}
          checkForRead={checkForRead}
          callToggle={() => {
            setCallToggleState(true)
            getCustomerNumbers()
            fetchCallLogs(singleLeadRecord)
          }}
        />
      )}

      <Modal isOpen={attachmentDeletion} toggle={attachmentToggle} className="delete-popup">
        <ModalHeader>Delete Attachment?</ModalHeader>
        <ModalBody>
          <p>Are you sure you want to delete this attachment? This process cannot be undone</p>
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={() => {
              setAttachmentDeletion(false)
            }}
          >
            Cancel
          </Button>
          <Button
            color="danger"
            onClick={() => {
              deleteAttachment(timelineData.id, timelineData.type)
            }}
          >
            Delete
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={commentToggleState} toggle={commentToggle} className="delete-popup">
        <ModalHeader>Delete Comment?</ModalHeader>
        <ModalBody>
          <p>Are you sure you want to delete this comment? This process cannot be undone</p>
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={() => {
              setCommentToggleState(false)
            }}
          >
            Cancel
          </Button>
          <Button
            color="danger"
            onClick={() => {
              deleteAttachment(timelineData.id, timelineData.type)
            }}
          >
            Delete
          </Button>
        </ModalFooter>
      </Modal>

      {closeLostToggleState ? (
        <CloseLostFeedback
          leadId={singleLeadRecord && singleLeadRecord.id}
          callback={() => {
            fetchLeadTimeLineFun(singleLeadRecord && singleLeadRecord.id)
            fetchSingleLead(singleLeadRecord && singleLeadRecord.id)
          }}
          closeLostToggle={() => closeLostToggle()}
          closeLostToggleState={closeLostToggleState}
          fetchSingleLeadDetails={fetchSingleLead}
          setCloseLostToggleState={(toggle) => setCloseLostToggleState(toggle)}
        />
      ) : (
        <></>
      )}

      {showFeedback ? (
        <MeetingActions
          leadId={singleLeadRecord && singleLeadRecord.id}
          leadType={leadType}
          user={user}
          fetchUser={() => fetchUser()}
          feedbackType={'Meeting'}
          showFeedback={showFeedback}
          setShowFeedback={(toggle) => showFeedbackToggle(toggle)}
          setFollowupModalFlag={(toggle) => {
            setFollowupModalFlag(true)
            setShowFeedback(false)
            setFollowUpModalAction('add')
          }}
          closedLost={() => {
            fetchLeadTimeLineFun(singleLeadRecord && singleLeadRecord.id)
            setShowFeedback(false)
          }}
          meeting={timelineData}
          shouldCloseAfterAdd={(toggle) => {
            shouldCloseAfterAdd(toggle)
          }}
          callBack={(action) => {
            if (action === 'update') fetchLeadTimeLineFun(singleLeadRecord && singleLeadRecord.id)
          }}
          meetingToggle={() => {
            setTaskModalAction('add-meeting')
            setTaskToggleState(true)
          }}
          callToggle={() => {
            setCallToggleState(true)
            getCustomerNumbers()
            fetchCallLogs(singleLeadRecord)
          }}
          setCloseLostToggleState={(toggle) => setCloseLostToggleState(toggle)}
        />
      ) : (
        <></>
      )}

      {callToggleState ? (
        <CallModal
          callToggleState={callToggleState}
          customersPhoneNumbers={customersPhoneNumbers}
          customerPhoneLoader={customerPhoneLoader}
          callingFrom={'investmentLeadDetails'}
          addInvestmentCallFun={addInvestmentCallFun}
          callToggle={(toggle) => {
            callToggle(toggle)
            fetchLeadTimeLineFun(singleLeadRecord && singleLeadRecord.id)
          }}
          showFeedbackToggle={showFeedbackToggle}
          callLogs={callLogs}
          checkForRead={checkForRead}
          callingFrom={'inner'}
        />
      ) : (
        <></>
      )}

      <Modal
        isOpen={meetingToggleState}
        toggle={meetingToggle}
        size="lg"
        style={{ maxWidth: '700px', width: '100%' }}
        contentClassName="general-popup-normal-size"
      >
        <ModalBody>
          <div className="close-icon-container">
            <i
              className="fal fa-times-circle close-icon"
              onClick={() => setMeetingToggleState(false)}
            ></i>
          </div>
          <div className="call-image-container">
            <img src={MeetingIconModal} className="container-image" />
          </div>
          <div className="container-body mt-4">
            <div className="mr-1 ml-1">
              <DateTimePicker
                formatStyle="small"
                value={meetingStartDate}
                onChange={(value) => {
                  dateStartHandler(value)
                  dateEndHandler(value)
                  setEditToggle(true)
                }}
              />
            </div>
            <div
              className="call-button"
              onClick={() => updateMeeting(meetingStartDate, timelineData.id)}
            >
              {meetingLoader ? (
                <span className="fa fa-circle-o-notch fa-spin"></span>
              ) : (
                'Update Meeting'
              )}
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

export const mapDispatchToProps = (dispatch) => {
  return {
    leadEditToggleFun: (data) => dispatch(leadEditToggle(data)),
    editDiary: (data, fetchTimeline, id, leadId) =>
      dispatch(editDiary(data, fetchTimeline, id, leadId)),
    fetchLeadTimeLineFun: (id) => dispatch(fetchLeadTimeLine(id)),
    addInvestmentMeeting: (data, callBack) => dispatch(investmentMeeting(data, callBack)),
    leadTabToggleFun: (data) => dispatch(leadTabToggle(data)),
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
    leadEditToggleState: state.Leads.leadEditToggle,
    singleLeadRecord: state.Leads.singleLeadRecord,
    addMeetingLoader: state.Leads.addMeetingLoader,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TimelineIcon)
