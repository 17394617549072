/** @format */

import React, { Component } from 'react'
import axios from 'axios'
import config from '../../config'
import Swal from 'sweetalert2'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import Button from './../common/Button'
import moment from 'moment'
import { capitalizeWordsWithoutUnderscore, capitalize_Words } from '../../utils/commonFormat'
import CustomModal from '../../components/Modals/CustomModal'
import PropertiesModalShortListed from '../../components/Modals/ShortlistedProperties/PropertiesModalShortListed'
import { normalizeCnicAndNTN } from '../../utils/formatCnic'
import './leadDetailTabViewStyle.scss'
import CircleLoader from '../CircleLoader'
import AddClientModal from '../../views/AddClient'
import CategoryModal from '../Modals/CategoryModal'
import PaymentMethods from '../../utils/paymentMethods'
import { StaticData } from '../../StaticData'
import { RenderLeadStatus } from '../../utils/general'
import { setBuyRentDetailsModalToggle, selectDifferentProperty } from '../../actions/property'
import AddBookingForm from '../AddBookingForm'
import {
  fetchUserClients,
  fetchClientsBank,
  fetchNextOfKin,
  fetchSingleLead,
} from '../../actions/leads'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import LeadCheck from '../../images/lead-check.svg'
class LeadDetailTabViewInner extends Component {
  constructor(props) {
    super(props)
    this.state = {
      detailToggle: false,
      detailPurchaserToggle: false,
      paymentScheduleState: false,
      investmentScheduleState: false,
      paymentScheduleLoader: false,
      paymentSchedules: [],
      activeClientModal: false,
      activePurchaserModal: false,
      showCategoryModal: false,
      generateLoader: false,
      leadCategoryColor: null,
      togglePropertiesShortListed: false,
      changePropertyConfirmation: false,
      messageString: null,
      titleString: null,
      showChangePropertyBtn: true,
      bookingFormModalState: false,
    }
  }

  detailToggle = () => {
    const { detailToggle } = this.state
    this.setState({ detailToggle: !detailToggle, generateLoader: true })
  }

  detailPurchaserToggle = () => {
    const { detailPurchaserToggle } = this.state
    this.setState({ detailPurchaserToggle: !detailPurchaserToggle, generateLoader: true })
  }

  toggleButtonModalShortListed = (value) => {
    this.setState({ togglePropertiesShortListed: value })
  }

  toggleChangePropertyConfirmation = (value) => {
    this.setState({ changePropertyConfirmation: value })
  }

  handleChange = (value) => {
    const { singleLeadRecord } = this.props
    if (
      singleLeadRecord &&
      singleLeadRecord.commissions &&
      singleLeadRecord.commissions.length > 0
    ) {
      let count = 0
      singleLeadRecord.commissions.map((item) => {
        if (item.paymentCategory === 'commission') count++
      })
      if (count) {
        Swal.fire(
          'Error',
          'Payment already added, cannot select another property',
          'error'
        ).then(() => {})
      } else {
        this.setState({ showChangePropertyBtn: true })
        this.setState({ titleString: 'Selecting Different Property' })
        this.setState({
          messageString:
            'Selecting a different property will remove all payments & legal services request, do you want to continue?',
        })
        this.toggleChangePropertyConfirmation(value)
      }
    } else {
      this.setState({ showChangePropertyBtn: true })
      this.setState({ titleString: 'Selecting Different Property' })
      this.setState({
        messageString:
          'Selecting a different property will remove all payments & legal services request, do you want to continue?',
      })
      this.toggleChangePropertyConfirmation(value)
    }
  }
  generateSalesMemo = () => {
    const { singleLeadRecord } = this.props
    const templateData = {}
    axios
      .post(
        `${config.apiPath}/api/leads/salesMemo?leadId=${singleLeadRecord && singleLeadRecord.id}`,
        '',
        {
          responseType: 'arraybuffer',
          headers: {
            Accept: 'application/pdf',
          },
        }
      )
      .then((res) => {

        const blob = new Blob([res.data], { type: 'application/pdf' })
        const blobUrl = URL.createObjectURL(blob)
        var fileLink = document.createElement('a')
        fileLink.href = blobUrl

        // it forces the name of the downloaded file
        fileLink.download = `SalesMemo_${singleLeadRecord && singleLeadRecord.id}_${
          singleLeadRecord && singleLeadRecord.customer && singleLeadRecord.customer.customerName
        }.pdf`

        // triggers the click event
        fileLink.click()
        window.open(blobUrl)
        this.setState({ generateLoader: false })
      })
      .catch((error) => {
        console.log('error----->', error)
      })
  }
  recieptGenration = () => {
    const { singleLeadRecord } = this.props
    this.setState({ generateLoader: true })
    let discountedAmount =
      (singleLeadRecord && singleLeadRecord.unit && singleLeadRecord.unit.unit_price) -
      (singleLeadRecord && singleLeadRecord.unit && singleLeadRecord.unit.finalPrice)

    let investmentDuration =
      singleLeadRecord &&
      singleLeadRecord.projectProduct &&
      singleLeadRecord.projectProduct.investmentDuration

    let discount =
      (100 * discountedAmount) /
      (singleLeadRecord && singleLeadRecord.unit && singleLeadRecord.unit.unit_price)

    let discountParseValue = discount > 0 ? Number(discount).toFixed(2) : 0

    let templateData = {
      ClientName:
        singleLeadRecord && singleLeadRecord.customer && singleLeadRecord.customer.customerName
          ? singleLeadRecord && singleLeadRecord.customer && singleLeadRecord.customer.customerName
          : '--',
      ClientCNIC:
        singleLeadRecord && singleLeadRecord.unit !== null
          ? normalizeCnicAndNTN(
              singleLeadRecord && singleLeadRecord.customer && singleLeadRecord.customer.cnic
            )
          : '--',
      ProjectName:
        singleLeadRecord && singleLeadRecord.paidProject !== null
          ? singleLeadRecord && singleLeadRecord.paidProject && singleLeadRecord.paidProject.name
          : singleLeadRecord && singleLeadRecord.project && singleLeadRecord.project.name,
      FloorName:
        singleLeadRecord && singleLeadRecord.floor !== null
          ? capitalize_Words(
              singleLeadRecord && singleLeadRecord.floor && singleLeadRecord.floor.name
            )
          : '--',
      UnitName:
        singleLeadRecord && singleLeadRecord.unit !== null
          ? capitalize_Words(
              singleLeadRecord && singleLeadRecord.unit && singleLeadRecord.unit.name
            )
          : '--',
      Size:
        singleLeadRecord && singleLeadRecord.unit !== null
          ? Number(
              singleLeadRecord && singleLeadRecord.unit && singleLeadRecord.unit.area
            ).toLocaleString()
          : '--',
      RatePerSqft:
        singleLeadRecord && singleLeadRecord.unit !== null
          ? Number(
              PaymentMethods.findRatePerSqft(singleLeadRecord && singleLeadRecord.unit)
            ).toLocaleString()
          : '--',
      UnitPrice:
        singleLeadRecord && singleLeadRecord.unit !== null
          ? Number(
              singleLeadRecord && singleLeadRecord.unit && singleLeadRecord.unit.unit_price
            ).toLocaleString()
          : '--',
      ProductName:
        singleLeadRecord &&
        singleLeadRecord.projectProduct &&
        Object.keys(singleLeadRecord && singleLeadRecord.projectProduct).length !== 0
          ? singleLeadRecord && singleLeadRecord.projectProduct.name
          : '--',
      PaymentPlan: capitalizeWordsWithoutUnderscore(
        singleLeadRecord &&
          singleLeadRecord.projectProduct &&
          singleLeadRecord.projectProduct.paymentPlan
      ),
      PlanDuration:
        singleLeadRecord &&
        singleLeadRecord.projectProduct &&
        singleLeadRecord.projectProduct.paymentPlanDuration !== null
          ? singleLeadRecord &&
            singleLeadRecord.projectProduct &&
            singleLeadRecord.projectProduct.paymentPlanDuration &&
            singleLeadRecord &&
            singleLeadRecord.projectProduct &&
            singleLeadRecord.projectProduct.paymentPlanDuration[1] + ' - ' + singleLeadRecord &&
            singleLeadRecord.projectProduct &&
            singleLeadRecord.projectProduct.paymentPlanDuration[3] + ' Years'
          : '--',
      InstallmentFrequency:
        singleLeadRecord && singleLeadRecord.installmentFrequency !== null
          ? capitalize_Words(singleLeadRecord && singleLeadRecord.installmentFrequency)
          : '--',
      ReservationAmount:
        singleLeadRecord &&
        singleLeadRecord.projectProduct &&
        singleLeadRecord.projectProduct.value !== null
          ? singleLeadRecord &&
            singleLeadRecord.projectProduct &&
            singleLeadRecord.projectProduct.reservationAmount === 'percentage'
            ? `${
                singleLeadRecord &&
                singleLeadRecord.projectProduct &&
                singleLeadRecord.projectProduct.value
              } %`
            : `${Number(
                singleLeadRecord &&
                  singleLeadRecord.projectProduct &&
                  singleLeadRecord.projectProduct.value
              ).toLocaleString()}`
          : '--',
      Downpayment:
        singleLeadRecord &&
        singleLeadRecord.projectProduct &&
        singleLeadRecord.projectProduct.downPayment,
      DownPaymentValue:
        singleLeadRecord &&
        singleLeadRecord.projectProduct &&
        singleLeadRecord.projectProduct.downPayment === 0
          ? null
          : Number(singleLeadRecord && singleLeadRecord.downPayment).toLocaleString(),
      PossessionCharges:
        singleLeadRecord &&
        singleLeadRecord.projectProduct &&
        singleLeadRecord.projectProduct.possessionCharges,

      Discount: discountParseValue,
      DiscountAmount:
        discount === 0 || discount < 0
          ? null
          : `${Number(
              (singleLeadRecord && singleLeadRecord.unit && singleLeadRecord.unit.unit_price) -
                (singleLeadRecord && singleLeadRecord.unit && singleLeadRecord.unit.finalPrice)
            ).toLocaleString()}`,
      DiscountedAmount: Number(
        singleLeadRecord && singleLeadRecord.unit && singleLeadRecord.unit.finalPrice
      ).toLocaleString(),
      InvestmentDurationValue:
        investmentDuration === 'limited'
          ? `${
              singleLeadRecord &&
              singleLeadRecord.projectProduct &&
              singleLeadRecord.projectProduct.investmentDurationPeriod
            } Months`
          : 'Unlimited',

      AnnualProfit:
        singleLeadRecord &&
        singleLeadRecord.projectProduct &&
        singleLeadRecord.projectProduct.annualProfit !== null
          ? singleLeadRecord &&
            singleLeadRecord.projectProduct &&
            singleLeadRecord.projectProduct.annualProfit
          : '--',
      AnnualRent:
        singleLeadRecord &&
        singleLeadRecord.projectProduct &&
        singleLeadRecord.projectProduct.monthlyRent !== null
          ? singleLeadRecord &&
            singleLeadRecord.projectProduct &&
            singleLeadRecord.projectProduct.monthlyRent
          : '--',
    }

    axios
      .post(
        `${config.apiPath}/api/leads/KFIReport?leadId=${singleLeadRecord && singleLeadRecord.id}`,
        templateData,
        {
          responseType: 'arraybuffer',
          headers: {
            Accept: 'application/pdf',
          },
        }
      )
      .then((res) => {
        const blob = new Blob([res.data], { type: 'application/pdf' })
        const blobUrl = URL.createObjectURL(blob)
        var fileLink = document.createElement('a')
        fileLink.href = blobUrl

        // it forces the name of the downloaded file
        fileLink.download = `KFI_${singleLeadRecord && singleLeadRecord.id}_${
          singleLeadRecord && singleLeadRecord.customer && singleLeadRecord.customer.customerName
        }.pdf`

        // triggers the click event
        fileLink.click()
        // window.open(blobUrl)
        this.setState({ generateLoader: false })
      })
      .catch((error) => {
        console.log('error----->', error)
      })
  }

  investmentScheduleState = () => {
    const { investmentScheduleState } = this.state
    this.setState({ investmentScheduleState: !investmentScheduleState })
  }

  paymentScheduleToggle = () => {
    const { paymentScheduleState } = this.state
    this.fetchPaymentSchedules()
    this.setState({ paymentScheduleState: !paymentScheduleState })
  }
  bookingFormToggle = () => {
    const { bookingFormModalState } = this.state
    const { singleLeadRecord } = this.props
    this.setState({ bookingFormModalState: !bookingFormModalState })
    this.props.fetchClientsBank(
      this.props &&
        this.props.singleLeadRecord &&
        this.props.singleLeadRecord.customer &&
        this.props.singleLeadRecord.customer.id
    )
    this.props.fetchNextOfKin(
      this.props &&
        this.props.singleLeadRecord &&
        this.props.singleLeadRecord.customer &&
        this.props.singleLeadRecord.customer.id
    )
    if (bookingFormModalState === true) {
      this.props.dispatchFetchSingleLead(singleLeadRecord.id, true, null, singleLeadRecord && singleLeadRecord.hasBooking )
    }
  }

  investmentScheduleToggle = () => {
    const { investmentScheduleState } = this.state
    this.fetchPaymentSchedules()
    this.setState({ investmentScheduleState: !investmentScheduleState })
  }

  fetchPaymentSchedules = () => {
    const { singleLeadRecord } = this.props
    this.setState({ paymentScheduleLoader: true })
    axios
      .get(
        `${config.apiPath}/api/leads/paymentSchedule?leadId=${
          singleLeadRecord && singleLeadRecord.id
        }`
      )
      .then((res) => {
        this.setState({ paymentSchedules: res.data, paymentScheduleLoader: false })
      })
  }

  clientModalToggle = (newClient) => {
    const { activeClientModal, detailToggle } = this.state
    this.setState({
      activeClientModal: !activeClientModal,
      detailToggle: !detailToggle,
    })
  }

  purchaserModalToggle = (newClient) => {
    const { activePurchaserModal, detailPurchaserToggle } = this.state
    this.setState({
      activePurchaserModal: !activePurchaserModal,
      detailPurchaserToggle: !detailPurchaserToggle,
    })
  }

  apiCallDoneClientEdit = () => {
    const { singleLeadRecord, fetchSingleLead } = this.props
    const { activeClientModal, detailToggle } = this.state
    fetchSingleLead && fetchSingleLead(singleLeadRecord.id)

    this.setState({
      activeClientModal: !activeClientModal,
      detailToggle: !detailToggle,
    })
  }

  apiCallDonePurchaserEdit = () => {
    const { singleLeadRecord, fetchLeadTimeLine } = this.props
    const { activePurchaserModal, detailPurchaserToggle } = this.state
    fetchLeadTimeLine(singleLeadRecord.id)
    this.setState({
      activePurchaserModal: !activePurchaserModal,
      detailPurchaserToggle: !detailPurchaserToggle,
    })
  }
  setColor = (label, content) => {
    if (label === 'Classification') {
    }

    let leadCategoryColor =
      (content &&
        StaticData.leadCategories.filter((c) => c.value === content).length &&
        StaticData.leadCategories.filter((c) => c.value === content)[0].colorCode) ||
      null
    // this.setState({leadCategoryColor})

    return leadCategoryColor
  }

  contentMasking = (content, label, componentOf) => {
    if (label === 'Status') {
      if (componentOf === 'wanted') {
        return content
      } else {
        return RenderLeadStatus(content, StaticData.projectLeadStatus, true)
      }
    } else if (label === 'Type') {
      if (content === 'sale') {
        return 'Buy'
      } else {
        return content
      }
    } else {
      return content
    }
  }

  shouldAllowView = label => {
    return ['Client Name', 'Property Owner Name', 'Applicants', 'Schedule of Payment', 'Investment Plan', 'KFI Document'].indexOf(label) > -1
  }


  render() {
    const {
      item,
      item: {
        label,
        content,
        clientShow,
        showDetail,
        investment,
        linkView,
        setCategory,
        className,
        showFormBooking,
        linkViewSales
      },
      singleLeadRecord,
      clientsBank,
      nextKin,
      checkForRead,
      fetchLeadTimeLine,
      checkFrom,
      setOpenScaModal,
      checkLeadtype,
      componentOf,
    } = this.props
    const {
      detailToggle,
      detailPurchaserToggle,
      paymentScheduleState,
      paymentSchedules,
      investmentScheduleState,
      paymentScheduleLoader,
      activeClientModal,
      activePurchaserModal,
      showCategoryModal,
      generateLoader,
      leadCategoryColor,
      togglePropertiesShortListed,
      changePropertyConfirmation,
      messageString,
      titleString,
      showChangePropertyBtn,
      bookingFormModalState,
    } = this.state
    let checkForDisable = !checkForRead.showReadOnly || checkForRead.ownLeadReadOnly

    let bookingFormApplicant =
      singleLeadRecord &&
      singleLeadRecord.bookingForms &&
      singleLeadRecord.bookingForms[0] &&
      singleLeadRecord.bookingForms[0].bookedApplicants.length > 0 &&
      singleLeadRecord.bookingForms[0].bookedApplicants[0]

    return (
      <>
        <div
          className={
            singleLeadRecord && singleLeadRecord.status && singleLeadRecord.status === 'closed_won' && !this.shouldAllowView(label)
              ? 'read-only-mode'
              : ''
          }
        >
          {checkFrom === 'additionalDetailTab' ? (
            <div className="mainContainer" style={{ marginTop: showDetail && 5 }}>
              {label !== undefined && (
                <div className="tab-view-container">
                  <div className="container-fluid">
                    <div className="row pb-2 leadStatusColorContainerAdditionalDetail">
                      <div style={{ width: '45%' }} className="label-color pt-1">
                        {label} :
                      </div>

                      {/* { this.setColor(label,content) } */}
                      <div className="LeadAdditionalInfoWidth">
                        <div
                          style={
                            content !== '--'
                              ? {
                                  color: this.setColor(label, content),
                                }
                              : {}
                          }
                          // style={
                          //   setCategory && content !== '--'
                          //     ? {
                          //         color: item.color,
                          //         borderColor: item.color,
                          //       }
                          //     : {}
                          // }
                          className={
                            (`content-color pt-1 ${className} ${
                              setCategory && content !== '--' ? `lead-category-status` : ''
                            }`,
                            content === 'meeting'
                              ? 'meeting'
                              : content === 'closed_lost'
                              ? 'closed_lost'
                              : content === 'token'
                              ? 'token'
                              : content === 'follow_up'
                              ? 'nurture'
                              : content === 'open'
                              ? 'open'
                              : content === 'payment'
                              ? 'payment'
                              : content === 'closed_won'
                              ? 'closed_won  additionalContentWidth'
                              : content === 'propsure'
                              ? 'shortlisting'
                              : content === 'viewing'
                              ? 'shortlisting'
                              : content === 'assigned'
                              ? 'assigned'
                              : content === 'in_progress'
                              ? 'shortlisting'
                              : content === 'offer'
                              ? 'shortlisting'
                              : content === 'shortlisting'
                              ? 'shortlisting'
                              : 'content-color mt-1')
                          }
                        >
                          {this.contentMasking(content, label, componentOf)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="mainContainer" style={{ marginTop: showDetail && 5 }}>
              <div className="tab-view-container">
                <div className="container-fluid">
                  <div className="row pb-2">
                    <div className="label-color pt-1 col-md-6 pl-0" style={{ fontSize: '13px' }}>
                      {label}

                      {label === 'Booking Form' &&
                        bookingFormApplicant &&
                        bookingFormApplicant.nextOfKinId &&
                        bookingFormApplicant.nextOfKinId !== null &&
                        bookingFormApplicant.bankAccountId !== null && (
                          <img src={LeadCheck} className="w-15 ml-2" />
                        )}
                    </div>
                    {showDetail ? null : ''}
                    {clientShow && (
                      <div className="col-md-6 text-right">
                        <button
                          disabled={
                            label != 'SCA Document'
                              ? false
                              : this.props.singleLeadRecord &&
                                this.props.currentUser &&
                                this.props.singleLeadRecord.assigned_to_armsuser_id ==
                                  this.props.currentUser.id
                              ? !this.props.scaDocument ||
                                (this.props.scaDocument && this.props.scaDocument.length <= 0) ||
                                (this.props.scaDocument.length > 0 &&
                                  this.props.scaDocument[0] &&
                                  !this.props.scaDocument[0].fileName)
                                ? false
                                : this.props.scaDocument &&
                                  this.props.scaDocument.length > 0 &&
                                  this.props.scaDocument[0] &&
                                  (this.props.scaDocument[0].status == 'rejected' ||
                                    this.props.scaDocument[0].status == 'pending_upload' ||
                                    this.props.scaDocument[0].status == 'uploaded')
                                ? false
                                : true
                              : true
                          }
                          type="button"
                          className={`button ${singleLeadRecord && singleLeadRecord.status && singleLeadRecord.status === 'closed_won' && !this.shouldAllowView(label) ? 'read-only-mode' : ''} ${
                            checkForDisable || label === 'SCA Document' ? '' : 'disableHover'
                          }`}
                          onClick={() => {
                            if (
                              (!checkForRead.showReadOnly || checkForRead.ownLeadReadOnly) &&
                              label === 'Client Name'
                            ) {
                              this.setState({ detailToggle: true })
                            } else if (
                              ((!checkForRead.showReadOnly || checkForRead.ownLeadReadOnly) &&
                                label === 'Monthly Rent') ||
                              ((!checkForRead.showReadOnly || checkForRead.ownLeadReadOnly) &&
                                label === 'Agreed Amount')
                            ) {
                              const { dispatch } = this.props
                              dispatch(setBuyRentDetailsModalToggle(true))
                            } else if (
                              (!checkForRead.showReadOnly || checkForRead.ownLeadReadOnly) &&
                              label === 'Property'
                            ) {
                              this.handleChange(true)
                            } else if (
                              (!checkForRead.showReadOnly || checkForRead.ownLeadReadOnly) &&
                              label === 'Booking For'
                            ) {
                              this.setState({ detailPurchaserToggle: true })
                            } else if (label === 'SCA Document') {
                              setOpenScaModal()
                            } else if (label === 'Property Owner Name') {
                              this.generateSalesMemo()
                            }

                          }}
                        >
                          {label === 'Monthly Rent' || label === 'Agreed Amount'
                            ? 'Update'
                            : label === 'Property'
                            ? 'Change'
                            : label === 'SCA Document'
                            ? 'Upload'
                            : label === 'Property Owner Name'? 'Sales memo'
                            : 'Details'}
                          {}
                        </button>
                        {/* {
                        (this.props.scaDocument && this.props.scaDocument[0].fileName &&
                          (this.props.scaDocument[0].status == 'rejected' ||
                          this.props.scaDocument[0].status == 'pending' ||
                          this.props.scaDocument[0].status == 'uploaded') ) ?
                          label === 'SCA Document' ?
                          <div className='uploadedFileWrapper'>
                            <label className="uploadedFileName">{this.props.scaDocument[0].fileName}</label>
                          </div>
                        : null : null
                      } */}
                        {/* {label === 'SCA Document' ?
                      <button
                      style={{ marginTop: '18px' }}
                      disabled={(this.props.scaDocument && (this.props.scaDocument.status == 'rejected' || this.props.scaDocument.status == 'pending' ||
                      this.props.scaDocument.status == 'uploaded' || this.props.scaDocument.length == 0) )? true : false}
                      onClick={() => { this.props.ViewDocument(this.props.selectedItem)}}
                      className={`button`}
                      >View</button>
                      : null} */}
                      </div>
                    )}
                    {setCategory && (
                      <div className="col-md-5 p-0 text-right">
                        <button
                          type="button"
                          className={`button ${checkForDisable ? '' : 'disableHover'}`}
                          onClick={() => {
                            if (!checkForRead.showReadOnly || checkForRead.ownLeadReadOnly) {
                              this.setState({ showCategoryModal: true })
                            }
                          }}
                        >
                          Set
                        </button>
                      </div>
                    )}
                    {showFormBooking && (
                      <div className="col-md-6 justify-content-center align-self-center">
                        <button
                          type="button"
                          className={`button ${checkForDisable ? '' : 'disableHover'}`}
                          onClick={() => {
                            if (!checkForRead.showReadOnly || checkForRead.ownLeadReadOnly) {
                              this.bookingFormToggle()
                            }
                          }}
                        >
                          Open
                        </button>
                      </div>
                    )}
                    {showDetail && (
                      <div className="col-md-6 justify-content-center align-self-center">
                        <button
                          type="button"
                          className={`button`}
                          onClick={() => {
                            this.paymentScheduleToggle()
                          }}
                        >
                          View
                        </button>
                      </div>
                    )}
                  {linkViewSales && (
                  <div className={'col-md-6 justify-content-center align-self-center'}>
                      <button
                        type="button"
                        className={
                          generateLoader
                            ? `generate-loader-height button space-from-edge m-0`
                            : `button space-from-edge m-0`
                        }
                        onClick={() => {
                          this.generateSalesMemo()
                        }}
                      >
                        {generateLoader ? <i className="fa fa-spin fa-circle-o-notch" /> : 'Generate'}
                      </button>
                    </div>
                  )}
                    {linkView && (
                      <div className={'col-md-6 justify-content-center align-self-center'}>
                        <button
                          type="button"
                          className={
                            generateLoader
                              ? `generate-loader-height button space-from-edge m-0`
                              : `button space-from-edge m-0`
                          }
                          onClick={() => {
                            this.recieptGenration()
                          }}
                        >
                          {generateLoader ? (
                            <i className="fa fa-spin fa-circle-o-notch" />
                          ) : (
                            'Generate'
                          )}
                        </button>
                      </div>
                    )}
                    {investment && (
                      <div className="col-md-6 justify-content-center align-self-center">
                        <button
                          type="button"
                          className={`button`}
                          onClick={() => {
                            this.investmentScheduleToggle()
                          }}
                        >
                          View
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                {label === 'SCA Document' &&
                this.props.scaDocument &&
                this.props.scaDocument[0] &&
                this.props.scaDocument[0].fileName ? (
                  <div
                    className="uploadedFileName"
                    title={this.props.scaDocument[0].fileName}
                    onClick={() => {
                      this.props.ViewDocument(this.props.selectedItem)
                    }}
                  >
                    {this.props.scaDocument[0].fileName}
                  </div>
                ) : null}
                <div
                  style={
                    setCategory && content !== '--'
                      ? {
                          color: item.color,
                          borderColor: item.color,
                        }
                      : {}
                  }
                  className={`content-color ${className} ${
                    setCategory && content !== '--' ? `lead-category-status` : ''
                  }`}
                >
                  {content}
                </div>
              </div>
            </div>
          )}
          <Modal
            isOpen={bookingFormModalState}
            toggle={this.bookingFormToggle}
            size="xl"
            contentClassName="comment-popup-size"
            className=""
          >
            <AddBookingForm
              singleLeadRecord={singleLeadRecord}
              clientsBank={clientsBank}
              nextKin={nextKin}
              toggleBookingForm={this.bookingFormToggle}
            />
          </Modal>
          <Modal
            isOpen={detailToggle}
            toggle={this.detailToggle}
            size="lg"
            contentClassName="comment-popup-size"
            className="comment-popup"
          >
            <ModalBody>
              <div className="header-area">
                <div className="header-area-left">
                  Customer Details
                {!singleLeadRecord || singleLeadRecord.status !== 'closed_won' &&
                  <button
                    className={`btn edit__client ${
                      !checkForRead.showReadOnly || checkForRead.ownLeadReadOnly
                        ? ''
                        : 'disableHover'
                    }`}
                    onClick={() => {
                      return !checkForRead.showReadOnly || checkForRead.ownLeadReadOnly
                        ? this.clientModalToggle()
                        : null
                    }}
                  >
                    <i className="fa fa-edit"></i>
                  </button> }
                </div>
                <div
                  className="close-icon-container"
                  onClick={() => this.setState({ detailToggle: false })}
                >
                  <i className="fal fa-times-circle close-icon" />
                </div>
              </div>

              <div className="row mt-4 main__customerDetails">
                <div className="col-md-3 client-text-color">Full Name</div>
                <div className="col-md-9 client-text-color font-weight-bold client-text-color">
                  {' '}
                  {singleLeadRecord &&
                    singleLeadRecord.customer &&
                    singleLeadRecord.customer.customerName}
                </div>
              </div>

              <div className="row mt-3 main__customerDetails">
                <div className="col-md-3 client-text-color">CNIC/NTN</div>
                <div className="col-md-9 client-text-color font-weight-bold client-text-color">
                  {singleLeadRecord && singleLeadRecord.customer && singleLeadRecord.customer.cnic
                    ? normalizeCnicAndNTN(singleLeadRecord.customer.cnic)
                    : '--'}
                </div>
              </div>

              <div className="row mt-3 main__customerDetails">
                <div className="col-md-3 client-text-color">Email</div>
                <div className="col-md-9 client-text-color font-weight-bold client-text-color">
                  {singleLeadRecord && singleLeadRecord.customer && singleLeadRecord.customer.email
                    ? singleLeadRecord.customer.email
                    : '--'}
                </div>
              </div>

              <div className="row mt-3 main__customerDetails">
                <div className="col-md-3 client-text-color">Address</div>
                <div className="col-md-9 client-text-color font-weight-bold client-text-color">
                  {singleLeadRecord &&
                  singleLeadRecord.customer &&
                  singleLeadRecord.customer.address
                    ? singleLeadRecord.customer.address
                    : '--'}
                </div>
              </div>

              <div className="row mt-3 main__customerDetails">
                <div className="col-md-3 client-text-color">Secondary Address</div>
                <div className="col-md-9 client-text-color font-weight-bold client-text-color">
                  {singleLeadRecord &&
                  singleLeadRecord.customer &&
                  singleLeadRecord.customer.secondary_address != null
                    ? singleLeadRecord.customer.secondary_address
                    : '--'}
                </div>
              </div>

              <hr />
              <div className="header-area">
                <div className="header-area-left sub__heading_c">Contact Info</div>
              </div>

              {singleLeadRecord &&
              singleLeadRecord.customer &&
              singleLeadRecord.customer.customerContacts.length > 0 ? (
                singleLeadRecord.customer.customerContacts.map((item, key) => {
                  return (
                    <div className="row mt-3 main__customerDetails" key={key}>
                      <div className="col-md-3 client-text-color">
                        {' '}
                        {key > 0 ? 'Contact ' + key : 'Mobile'}
                      </div>
                      <div className="col-md-9 client-text-color font-weight-bold client-text-color">
                        {item.phone}
                      </div>
                    </div>
                  )
                })
              ) : (
                <div className="row mt-3 main__customerDetails">
                  <div className="col-md-3 client-text-color">Contact</div>
                  <div className="col-md-9 client-text-color font-weight-bold client-text-color">
                    {singleLeadRecord &&
                      singleLeadRecord.customer &&
                      singleLeadRecord.customer.phone}
                  </div>
                </div>
              )}
            </ModalBody>
          </Modal>
          <Modal
            isOpen={detailPurchaserToggle}
            toggle={this.detailPurchaserToggle}
            size="lg"
            contentClassName="comment-popup-size"
            className="comment-popup"
          >
            <ModalBody>
              <div className="header-area">
                <div className="header-area-left">
                  Booking For
                  {/* <button
                  className={`btn edit__client ${
                    !checkForRead.showReadOnly || checkForRead.ownLeadReadOnly ? '' : 'disableHover'
                  }`}
                  onClick={() => {
                    return !checkForRead.showReadOnly || checkForRead.ownLeadReadOnly
                      ? this.purchaserModalToggle()
                      : null
                  }}
                >
                  <i className="fa fa-edit"></i>
                </button> */}
                </div>
                <div
                  className="close-icon-container"
                  onClick={() => this.setState({ detailPurchaserToggle: false })}
                >
                  <i className="fal fa-times-circle close-icon" />
                </div>
              </div>

              <div className="row mt-4 main__customerDetails">
                <div className="col-md-3 client-text-color">Full Name</div>
                <div className="col-md-9 client-text-color font-weight-bold client-text-color">
                  {' '}
                  {singleLeadRecord &&
                    singleLeadRecord.purchaser &&
                    singleLeadRecord.purchaser.customerName}
                </div>
              </div>

              <div className="row mt-3 main__customerDetails">
                <div className="col-md-3 client-text-color">CNIC/NTN</div>
                <div className="col-md-9 client-text-color font-weight-bold client-text-color">
                  {singleLeadRecord && singleLeadRecord.purchaser && singleLeadRecord.purchaser.cnic
                    ? normalizeCnicAndNTN(singleLeadRecord.purchaser.cnic)
                    : '--'}
                </div>
              </div>

              <div className="row mt-3 main__customerDetails">
                <div className="col-md-3 client-text-color">Email</div>
                <div className="col-md-9 client-text-color font-weight-bold client-text-color">
                  {singleLeadRecord &&
                  singleLeadRecord.purchaser &&
                  singleLeadRecord.purchaser.email
                    ? singleLeadRecord.purchaser.email
                    : '--'}
                </div>
              </div>

              <div className="row mt-3 main__customerDetails">
                <div className="col-md-3 client-text-color">Address</div>
                <div className="col-md-9 client-text-color font-weight-bold client-text-color">
                  {singleLeadRecord &&
                  singleLeadRecord.purchaser &&
                  singleLeadRecord.purchaser.address
                    ? singleLeadRecord.purchaser.address
                    : '--'}
                </div>
              </div>

              <div className="row mt-3 main__customerDetails">
                <div className="col-md-3 client-text-color">Secondary Address</div>
                <div className="col-md-9 client-text-color font-weight-bold client-text-color">
                  {singleLeadRecord &&
                  singleLeadRecord.purchaser &&
                  singleLeadRecord.purchaser.secondary_address != null
                    ? singleLeadRecord.purchaser.secondary_address
                    : '--'}
                </div>
              </div>

              <hr />
              <div className="header-area">
                <div className="header-area-left sub__heading_c">Contact Info</div>
              </div>

              {singleLeadRecord &&
              singleLeadRecord.purchaser &&
              singleLeadRecord.purchaser.customerContacts.length > 0
                ? singleLeadRecord.purchaser.customerContacts.map((item, key) => {
                    return (
                      <div className="row mt-3 main__customerDetails">
                        <div className="col-md-3 client-text-color">
                          {' '}
                          {key > 0 ? 'Contact ' + key : 'Mobile'}
                        </div>
                        <div className="col-md-9 client-text-color font-weight-bold client-text-color">
                          {item.phone}
                        </div>
                      </div>
                    )
                  })
                : null}
            </ModalBody>
          </Modal>
          <Modal
            isOpen={investmentScheduleState}
            toggle={this.investmentScheduleToggle}
            size="lg"
            contentClassName="comment-popup-size"
            style={{ minWidth: 950, maxWidth: 950, margin: '0 auto' }}
          >
            <ModalBody>
              <div className="header-area">
                <div
                  className="close-icon-container"
                  onClick={() => this.setState({ investmentScheduleState: false })}
                >
                  <i className="fal fa-times-circle close-icon" />
                </div>
              </div>

              <div className="payment-product-body">
                <div className="main-title">Investment Product Details</div>
                <div className="row mr-2 ml-2">
                  <div className="col-md-6">
                    <div className="row field-wrapper">
                      <div className="col-md-5 pl-0">
                        <span className="title">Product Name</span>
                      </div>
                      <div className="col-md-7 ">
                        <span className="sub-title">
                          {singleLeadRecord &&
                            singleLeadRecord.projectProduct &&
                            singleLeadRecord.projectProduct.name}
                        </span>{' '}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row field-wrapper">
                      <div className="col-md-5  pl-0">
                        <span className="title">Valid Through</span>
                      </div>
                      <div className="col-md-7">
                        <span className="sub-title">
                          {moment(
                            singleLeadRecord &&
                              singleLeadRecord.projectProduct &&
                              singleLeadRecord.projectProduct.validFrom
                          ).format('MMM D , YYYY') +
                            ' - ' +
                            moment(
                              singleLeadRecord &&
                                singleLeadRecord.projectProduct &&
                                singleLeadRecord.projectProduct.validTo
                            ).format('MMM D , YYYY')}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mr-2 ml-2">
                  <div className="col-md-12">
                    <div className="row field-wrapper">
                      <div className="use-flex">
                        <span className="title">Product Description</span>
                      </div>
                      <div className="col-md-8">
                        <span className="sub-title" style={{ color: 'black' }}>
                          {singleLeadRecord &&
                          singleLeadRecord.projectProduct &&
                          singleLeadRecord.projectProduct.description !== null
                            ? singleLeadRecord &&
                              singleLeadRecord.projectProduct &&
                              singleLeadRecord.projectProduct.description
                            : '--'}
                        </span>{' '}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mr-2 ml-2">
                  <div className="col-md-6">
                    <div className="row field-wrapper">
                      <div className="col-md-5 pl-0">
                        <span className="title">Payment Plan</span>
                      </div>
                      <div className="col-md-7 ">
                        <span className="sub-title">
                          {capitalizeWordsWithoutUnderscore(
                            singleLeadRecord && singleLeadRecord.paymentPlan
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row field-wrapper">
                      <div className="col-md-5  pl-0">
                        <span className="title">Installment Frequency</span>
                      </div>
                      <div className="col-md-7">
                        <span className="sub-title text-capitalize">
                          {singleLeadRecord && singleLeadRecord.installmentFrequency !== null
                            ? singleLeadRecord && singleLeadRecord.installmentFrequency
                            : '--'}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mr-2 ml-2">
                  <div className="col-md-6">
                    <div className="row field-wrapper">
                      <div className="col-md-5 pl-0">
                        <span className="title">Payment Plan Duration </span>
                      </div>
                      <div className="col-md-7 ">
                        <span className="sub-title ">
                          {singleLeadRecord && singleLeadRecord.paymentPlanDuration !== null
                            ? singleLeadRecord && singleLeadRecord.paymentPlanDuration + ' Years'
                            : '--'}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="row field-wrapper">
                      <div className="col-md-5  pl-0">
                        <span className="title">Reservation Amount</span>
                      </div>
                      <div className="col-md-7">
                        <span className="sub-title">
                          {singleLeadRecord &&
                          singleLeadRecord.projectProduct &&
                          singleLeadRecord.projectProduct.value !== null
                            ? singleLeadRecord &&
                              singleLeadRecord.projectProduct &&
                              singleLeadRecord.projectProduct.reservationAmount === 'percentage'
                              ? `${
                                  singleLeadRecord &&
                                  singleLeadRecord.projectProduct &&
                                  singleLeadRecord.projectProduct.value
                                } %`
                              : `${Number(
                                  singleLeadRecord &&
                                    singleLeadRecord.projectProduct &&
                                    singleLeadRecord.projectProduct.value
                                ).toLocaleString()}`
                            : '--'}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mr-2 ml-2">
                  <div className="col-md-6">
                    <div className="row field-wrapper">
                      <div className="col-md-5 pl-0">
                        <span className="title">Down Payment</span>
                      </div>
                      <div className="col-md-7 ">
                        <span className="sub-title">
                          {singleLeadRecord &&
                          singleLeadRecord.projectProduct &&
                          singleLeadRecord.projectProduct.downPayment !== null
                          && singleLeadRecord.paymentPlan !== 'full_payment'
                            ? singleLeadRecord &&
                              singleLeadRecord.projectProduct &&
                              singleLeadRecord.projectProduct.downPayment + ' % '
                            : '--'}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row field-wrapper">
                      <div className="col-md-5  pl-0">
                        <span className="title">Possession Charges</span>
                      </div>
                      <div className="col-md-7">
                        <span className="sub-title">
                          {singleLeadRecord &&
                          singleLeadRecord.projectProduct &&
                          singleLeadRecord.projectProduct.possessionCharges !== null
                          && singleLeadRecord.paymentPlan !== 'full_payment'
                            ? singleLeadRecord &&
                              singleLeadRecord.projectProduct &&
                              singleLeadRecord.projectProduct.possessionCharges + ' % '
                            : '--'}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mr-2 ml-2">
                  <div className="col-md-6">
                    <div className="row field-wrapper">
                      <div className="col-md-5 pl-0">
                        <span className="title">Annual Profit</span>
                      </div>
                      <div className="col-md-7 ">
                        <span className="sub-title">
                          {singleLeadRecord &&
                          singleLeadRecord.projectProduct &&
                          singleLeadRecord.projectProduct.annualProfit !== null
                            ? singleLeadRecord &&
                              singleLeadRecord.projectProduct &&
                              singleLeadRecord.projectProduct.annualProfit + ' % '
                            : '--'}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row field-wrapper">
                      <div className="col-md-5 pl-0">
                        <span className="title">Annual Rent</span>
                      </div>
                      <div className="col-md-7 ">
                        <span className="sub-title">
                          {singleLeadRecord &&
                          singleLeadRecord.projectProduct &&
                          singleLeadRecord.projectProduct.monthlyRent !== null
                            ? singleLeadRecord &&
                              singleLeadRecord.projectProduct &&
                              singleLeadRecord.projectProduct.monthlyRent + ' % '
                            : '--'}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mr-2 ml-2">
                  <div className="col-md-6">
                    <div className="row field-wrapper">
                      <div className="col-md-5 pl-0">
                        <span className="title">Investment Duration</span>
                      </div>
                      <div className="col-md-7 ">
                        <span className="sub-title" style={{ textTransform: 'capitalize' }}>
                          {singleLeadRecord &&
                          singleLeadRecord.projectProduct &&
                          singleLeadRecord.projectProduct.investmentDuration !== null
                            ? singleLeadRecord &&
                              singleLeadRecord.projectProduct &&
                              singleLeadRecord.projectProduct.investmentDuration
                            : '--'}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row field-wrapper">
                      <div className="col-md-5 pl-0">
                        <span className="title">Discount</span>
                      </div>
                      <div className="col-md-7 ">
                        <span className="sub-title">
                          {singleLeadRecord &&
                          singleLeadRecord.unit &&
                          singleLeadRecord.unit.discount !== null
                            ? singleLeadRecord &&
                              singleLeadRecord.unit &&
                              Number(singleLeadRecord.unit.discount).toFixed(2).replace(/[.,]00$/, "") + ' % '
                            : '--'}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ModalBody>
          </Modal>
          <CustomModal
            modalOpenState={paymentScheduleState}
            modalWidth={'modal-37 modal-custom'}
            headerTitle="Payment Schedule"
            toggleModal={this.paymentScheduleToggle}
          >
            <table className="schedule-table-wrapper">
              <thead className="header-wrapper">
                <th style={{ minWidth: 160, maxWidth: 160, paddingLeft: 25 }}>Payment Type</th>
                <th style={{ minWidth: 220, maxWidth: 220, paddingLeft: 20, textAlign: 'right' }}>
                  Amount
                </th>
                <th style={{ minWidth: 260, maxWidth: 230, paddingLeft: 20, textAlign: 'right' }}>
                  Due Date
                </th>
              </thead>
              <tbody
                className={`main-body ${
                  !checkForRead.showReadOnly || checkForRead.ownLeadReadOnly ? '' : 'changeHeight'
                }`}
              >
                {paymentScheduleLoader ? (
                  <CircleLoader loading={paymentScheduleLoader} />
                ) : (
                  paymentSchedules &&
                  paymentSchedules.map((item, index) => (
                    <tr
                      key={index}
                      style={{
                        fontWeight:
                          item.paymentType === 'downPayment' ||
                          item.paymentType === 'possessionCharges'
                            ? 'bold'
                            : 'normal',
                        backgroundColor:
                          item.paymentType === 'downPayment' ||
                          item.paymentType === 'possessionCharges'
                            ? '#e5f1fe'
                            : '#fff',
                      }}
                    >
                      <td
                        style={{
                          minWidth: 160,
                          maxWidth: 160,
                          paddingLeft: 25,
                        }}
                      >
                        {item.paymentType === 'fullPayment'
                          ? 'Full Payment'
                          : item.paymentType === 'downPayment'
                          ? 'Down Payment'
                          : item.paymentType === 'possessionCharges'
                          ? 'Possession Charges'
                          : `Installment ${index}`}
                      </td>
                      <td
                        style={{
                          minWidth: 220,
                          maxWidth: 220,
                          paddingLeft: 20,
                          textAlign: 'right',
                        }}
                      >
                        {item.amountDue && item.amountDue.toLocaleString()}
                      </td>
                      <td
                        style={{
                          minWidth: 260,
                          maxWidth: 250,
                          paddingLeft: 20,
                          textAlign: 'right',
                        }}
                      >
                        {moment(item.paymentDueDate).format('MMM D , YYYY')}
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </CustomModal>
          {activeClientModal ? (
            <AddClientModal
              activeClientModal={activeClientModal}
              fromPage={'investmentLead'}
              apiCallDonClientCreated={this.apiCallDoneClientEdit}
              shareToggle={this.detailToggle}
              clientModalToggle={this.clientModalToggle}
              editable={true}
              clientId={singleLeadRecord.customer && singleLeadRecord.customer.id}
            />
          ) : null}

          {activePurchaserModal ? (
            <AddClientModal
              activeClientModal={activePurchaserModal}
              fromPage={'investmentLead'}
              apiCallDonClientCreated={this.apiCallDonePurchaserEdit}
              shareToggle={this.detailPurchaserToggle}
              clientModalToggle={this.purchaserModalToggle}
              editable={true}
              clientId={singleLeadRecord && singleLeadRecord.purchaserId}
            />
          ) : null}
          {bookingFormModalState ? <div></div> : <></>}
          {showCategoryModal ? (
            <CategoryModal
              lead={singleLeadRecord}
              showCategoryModal={showCategoryModal}
              fetchLeadTimeLine={() => fetchLeadTimeLine(singleLeadRecord.id)}
              setShowCategoryModal={(show) => this.setState({ showCategoryModal: show })}
            />
          ) : (
            <></>
          )}

          <PropertiesModalShortListed
            toggleButtonModalShortListed={this.toggleButtonModalShortListed}
            togglePropertiesShortListed={togglePropertiesShortListed}
          />

          {/* <ChangePropertyConfirmationModal
          toggleButtonModalShortListed={this.toggleChangePropertyConfirmation}
          togglePropertiesShortListed={changePropertyConfirmation}
        /> */}

          <Modal
            isOpen={changePropertyConfirmation}
            toggle={this.toggleChangePropertyConfirmation}
            dialogClassName={`modal-main-legal`}
          >
            <ModalHeader>{titleString}</ModalHeader>
            <ModalBody>
              <p>{messageString}</p>
            </ModalBody>
            <ModalFooter>
              <Button
                text={'Cancel'}
                textColor={'white'}
                display={'inline-block'}
                buttonColor="#026ff2"
                className={'px-4 mr-2 bg-secondary'}
                onClick={() => {
                  this.toggleChangePropertyConfirmation(false)
                }}
              />
              {showChangePropertyBtn ? (
                <Button
                  text={'Continue'}
                  textColor={'white'}
                  display={'inline-block'}
                  // buttonColor="#026ff2"
                  className={'px-4 mr-2 bg-success text-white'}
                  onClick={() => {
                    const { dispatch } = this.props
                    const { singleLeadRecord } = this.props
                    dispatch(selectDifferentProperty(singleLeadRecord.id))
                  }}
                />
              ) : null}
            </ModalFooter>
          </Modal>
        </div>
      </>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: (func) => dispatch(func),
    getClientsData: () => dispatch(fetchUserClients()),
    fetchUserClients: () => dispatch(fetchUserClients()),
    fetchClientsBank: (id) => dispatch(fetchClientsBank(id)),
    fetchNextOfKin: (id) => dispatch(fetchNextOfKin(id)),
    dispatchFetchSingleLead: (id,byProject, callBack,check_MyDeal_Or_MyLeads) => dispatch(fetchSingleLead(id,byProject, callBack,check_MyDeal_Or_MyLeads)),
  }
}

const mapStateToProps = (state) => {
  return {
    userClients: state.Leads.userClients,
    clientsBank: state.Leads.clientsBank,
    nextKin: state.Leads.nextKin,
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LeadDetailTabViewInner))
// export default connect()(LeadDetailTabViewInner)
