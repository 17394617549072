/** @format */
import MarkAsDoneIcon from './../../images/markasdone.svg'
export const ColumnName = {
  TIME_LAPSE: 'time-lapse',
  TYPE_TILE: 'type-tile',
  TASK_ALERT: 'task-alert',
  TYPE: 'type',
  REASON: 'reason',
  CLASSIFICATION: 'classification',
  CLIENT_NAME: 'client-name',
  REQUIREMENT: 'requirement',
  LEAD_ID: 'lead-id',
  CONNECT_BTN: 'connect-btn',
  ACTION_BTN: 'action-btn',
  DETAILS_BTN: 'details-btn',
  MARK_AS_DONE_BTN: 'mark-as-done-btn',
  COMMENT: 'comment',
}

export const headerTemplateOfSmallDiary = () => {
  return [
    {
      component: 'LABEL',
      props: {
        label: '',
      },
      columnName: ColumnName.TIME_LAPSE,
      tdClassName: 'padding-5-left backgroundColorfff',
    },
    {
      component: 'LABEL',
      props: {
        label: '',
      },
      columnName: ColumnName.TYPE_TILE,

      tdClassName: 'padding-5-left borderRadiusLeft',
    },
    {
      component: 'LABEL',
      props: {
        label: '',
      },
      columnName: ColumnName.TASK_ALERT,
      tdClassName: 'padding-5-left ',
    },
    {
      component: 'LABEL',
      props: {
        label: 'Type',
      },
      columnName: ColumnName.TYPE,
    },

    {
      component: 'LABEL',
      props: {
        label: 'Lead ID',
      },
      columnName: ColumnName.LEAD_ID,
    },

    {
      component: 'LABEL',
      props: {
        label: 'Client Name',
      },
      columnName: ColumnName.CLIENT_NAME,
    },

    {
      component: 'LABEL',
      props: {
        label: '',
      },
      columnName: ColumnName.CONNECT_BTN,
    },
    {
      component: 'LABEL',
      props: {
        label: '',
      },
      columnName: ColumnName.DETAILS_BTN,
    },
    {
      component: 'LABEL',
      props: {
        label: '',
      },
      columnName: ColumnName.ACTION_BTN,
    },
    {
      component: 'LABEL',
      props: {
        label: '',
      },
      columnName: ColumnName.MARK_AS_DONE_BTN,
      tdClassName: 'borderRadiusRight',
    },
  ]
}

export const headerTemplate = () => {
  return [
    {
      component: 'LABEL',
      props: {
        label: '',
      },
      columnName: ColumnName.TIME_LAPSE,
      tdClassName: 'padding-5-left backgroundColorfff',
    },
    {
      component: 'LABEL',
      props: {
        label: '',
      },
      columnName: ColumnName.TYPE_TILE,

      tdClassName: 'padding-5-left borderRadiusLeft',
    },
    {
      component: 'LABEL',
      props: {
        label: '',
      },
      columnName: ColumnName.TASK_ALERT,
      tdClassName: 'padding-5-left ',
    },
    {
      component: 'LABEL',
      props: {
        label: 'Type',
      },
      columnName: ColumnName.TYPE,
    },
    {
      component: 'LABEL',
      props: {
        label: 'Reason',
      },
      columnName: ColumnName.REASON,
    },
    {
      component: 'LABEL',
      props: {
        label: 'Lead ID',
      },
      columnName: ColumnName.LEAD_ID,
    },

    {
      component: 'LABEL',
      props: {
        label: 'Comment',
      },
      columnName: ColumnName.COMMENT,
    },
    {
      component: 'LABEL',
      props: {
        label: 'Classification',
      },
      columnName: ColumnName.CLASSIFICATION,
    },
    {
      component: 'LABEL',
      props: {
        label: 'Client Name',
      },
      columnName: ColumnName.CLIENT_NAME,
    },
    {
      component: 'LABEL',
      props: {
        label: 'Requirements',
      },
      columnName: ColumnName.REQUIREMENT,
    },
    {
      component: 'LABEL',
      props: {
        label: '',
      },
      columnName: ColumnName.CONNECT_BTN,
    },
    {
      component: 'LABEL',
      props: {
        label: '',
      },
      columnName: ColumnName.DETAILS_BTN,
    },
    {
      component: 'LABEL',
      props: {
        label: '',
      },
      columnName: ColumnName.ACTION_BTN,
    },
    {
      component: 'LABEL',
      props: {
        label: '',
      },
      columnName: ColumnName.MARK_AS_DONE_BTN,
      tdClassName: 'borderRadiusRight',
    },
  ]
}
export const bodyTemplate = (getValue, handleAction, props) => {
  const constructProp = (templateProperty, cellIndex, rowIndex, rowData, columnName) =>
    getValue(templateProperty, cellIndex, rowIndex, rowData, props, columnName)

  return [
    {
      component: 'LABEL',
      props: {
        label: constructProp,
        className: 'break-spaces timeLabel',
        // type: 'date',
        // format: 'hh:mm',
      },
      ignorePropConstruct: ['className'],
      // ignorePropConstruct: ['type', 'format'],
      tdClassName: 'padding-5-left backgroundColorfff minWidthDiaryTime',
    },
    {
      component: 'COLORTILE',
      props: {
        color: constructProp,
        width: '100%',
        // classes: 'borderRight',
      },
      ignorePropConstruct: ['width'],

      tdClassName: 'padding-0 borderLeft borderBottom borderTop borderRadiusLeft',
    },
    {
      component: 'BUTTON',
      props: {
        icon: constructProp,
        type: 'image',
        width: '12px',
        height: '12px',
        onClick: () => {},
        action: 'null',
      },
      ignorePropConstruct: ['onClick', 'type', 'width', 'height', 'action'],
      // showOnHover: false,
      // showOnRowSelect: false,
      tdClassName: 'borderTop borderBottom text-center',
    },
    {
      component: 'LABEL',
      props: {
        // label: 'taskType',
        // type: 'diaryTaskType',
        label: constructProp,
        className: 'elipsis responseLabelWidthForType',
      },
      ignorePropConstruct: ['type'],
      tdClassName: 'borderTop borderBottom',
    },
    {
      component: 'CHIP',
      props: {
        text: 'reasonTag',
        // borderRadius: '5px',
        // chipColor: constructProp,
        className: 'elipsis responseLabelWidth',

        padding: '6px 15px',
        borderRadius: '20px',
        border: constructProp,
        // hide: 'reason',
      },
      notFoundTemplate: { text: '' },
      ignorePropConstruct: ['borderRadius', 'className'],
      tdClassName: 'borderTop borderBottom reasonTd',
    },
    {
      component: 'LABEL',
      props: {
        label: constructProp,
        className: 'diaryLeadLink',
      },
      tdClassName: 'borderTop borderBottom ',
    },
    {
      component: 'LABEL',
      props: {
        label: constructProp,
      },
      // ignorePropConstruct: ['type'],
      tdClassName: 'borderTop borderBottom',
    },
    {
      component: 'INPUTDROPDOWN',
      props: {
        value: constructProp,
        list: [
          { name: 'Hot', value: 'Hot', className: 'hot' },
          { name: 'Warm', value: 'Warm', className: 'warm' },
          { name: 'Cold', value: 'Cold', className: 'cold' },
        ],
        width: '90px',
        height: '25px',
        inputDDclassName: 'inputDD',
        inputDDiconClassName: 'inputDDicon',
        textColor: constructProp,
        disabled: constructProp,
        onChange: handleAction,
      },
      ignorePropConstruct: [
        'list',
        'width',
        'height',
        'inputDDclassName',
        'inputDDiconClassName',
        'onChange',
      ],

      // notFoundTemplate: { value: '--' },
      tdClassName: 'borderTop borderBottom',
    },
    {
      component: 'LABEL',
      props: {
        label: constructProp,
        className: 'elipsis responseLabelWidth',
      },
      tdClassName: 'borderTop borderBottom',
    },
    {
      component: 'LABEL',
      props: {
        label: constructProp,
        className: 'elipsis reasonLabelWidth',
      },
      ignorePropConstruct: ['className'],
      tdClassName: 'borderTop borderBottom',
    },
    {
      component: 'BUTTON',
      props: {
        text: 'Connect',
        buttonColor: '#7cb361',
        textColor: 'white',
        onClick: handleAction,
        action: 'connect',
        width: '70px',
        display: constructProp,
      },
      ignorePropConstruct: ['onClick', 'text', 'buttonColor', 'textColor', 'action', 'width'],
      showOnHover: true,
      showOnRowSelect: false,
      tdClassName: 'borderTop borderBottom',
    },
    {
      component: 'BUTTON',
      props: {
        text: 'Lead Details',
        buttonColor: '#026ff2',
        onClick: handleAction,
        action: 'task-details',
        textColor: '#ffffff',
        display: constructProp,
        // width: '70px',
      },
      ignorePropConstruct: ['onClick', 'text', 'buttonColor', 'action', 'textColor'],
      showOnHover: true,
      showOnRowSelect: false,
      tdClassName: 'borderTop borderBottom',
    },
    {
      component: 'BUTTON',
      props: {
        text: 'Actions',
        type: 'dropdown',
        buttonColor: '#ffffff',
        textColor: '#026ff2',
        orientation: 'left',
        className: 'detailsBtn',
        caretColor: '#026ff2',
        autoAdjustOrientation: true,
        width: '70px',
        display: constructProp,
        actionItems: constructProp,
        onClick: handleAction,
        emptyMenuItem: 'No Action Available',
      },
      ignorePropConstruct: [
        'onClick',
        'autoAdjustOrientation',
        'type',
        'width',
        'orientation',
        'textColor',
        'buttonColor',
        'text',
        'emptyMenuItem',
        'className',
        'caretColor',
      ],
      showOnHover: true,
      showOnRowSelect: false,
      tdClassName: 'borderTop borderBottom',
    },
    {
      component: 'BUTTON',
      props: {
        type: 'image',
        icon: MarkAsDoneIcon,
        onClick: handleAction,
        action: 'mark-as-done',
        className: 'mr-1',
        width: '20px',
        display: constructProp,
        tooltipContent: 'Mark as done',
        tooltipDirection: 'top',
        tooltipStyles: { left: '-50%' },
        tooltipCaretStyles: { left: '80%' },
      },
      ignorePropConstruct: ['onClick', 'icon', 'action', 'className', 'width'],
      showOnHover: true,
      showOnRowSelect: false,
      tdClassName: 'borderTop borderBottom borderRight borderRadiusRight',
    },
  ]
}
export const bodyTemplateShowDiary = (getValue, handleAction, props) => {
  const constructProp = (templateProperty, cellIndex, rowIndex, rowData, columnName) =>
    getValue(templateProperty, cellIndex, rowIndex, rowData, props, columnName)

  return [
    {
      component: 'LABEL',
      props: {
        label: constructProp,
        className: 'break-spaces timeLabel',
        // type: 'date',
        // format: 'hh:mm',
      },
      ignorePropConstruct: ['className'],
      // ignorePropConstruct: ['type', 'format'],
      tdClassName: 'padding-5-left backgroundColorfff',
    },
    {
      component: 'COLORTILE',
      props: {
        color: constructProp,
        width: '100%',
        // classes: 'borderRight',
      },
      ignorePropConstruct: ['width'],

      tdClassName: 'padding-0 borderLeft borderBottom borderTop borderRadiusLeft',
    },
    {
      component: 'BUTTON',
      props: {
        icon: constructProp,
        type: 'image',
        width: '12px',
        height: '12px',
        onClick: () => {},
        action: 'null',
      },
      ignorePropConstruct: ['onClick', 'type', 'width', 'height', 'action'],
      // showOnHover: false,
      // showOnRowSelect: false,
      tdClassName: 'borderTop borderBottom text-center',
    },
    {
      component: 'LABEL',
      props: {
        // label: 'taskType',
        // type: 'diaryTaskType',
        label: constructProp,
      },
      ignorePropConstruct: ['type'],
      tdClassName: 'borderTop borderBottom',
    },
    {
      component: 'LABEL',
      props: {
        label: constructProp,
        className: 'diaryLeadLink',
      },
      tdClassName: 'borderTop borderBottom ',
    },
    {
      component: 'LABEL',
      props: {
        label: constructProp,
      },
      tdClassName: 'borderTop borderBottom',
    },
    {
      component: 'BUTTON',
      props: {
        text: 'Connect',
        buttonColor: '#7cb361',
        textColor: 'white',
        onClick: handleAction,
        action: 'connect',
        width: '70px',
        display: constructProp,
      },
      ignorePropConstruct: ['onClick', 'text', 'buttonColor', 'textColor', 'action', 'width'],
      showOnHover: true,
      showOnRowSelect: false,
      tdClassName: 'borderTop borderBottom',
    },
    {
      component: 'BUTTON',
      props: {
        text: 'Lead Details',
        buttonColor: '#026ff2',
        onClick: handleAction,
        action: 'task-details',
        textColor: '#ffffff',
        display: constructProp,
        // width: '70px',
      },
      ignorePropConstruct: ['onClick', 'text', 'buttonColor', 'action', 'textColor'],
      showOnHover: true,
      showOnRowSelect: false,
      tdClassName: 'borderTop borderBottom',
    },
    {
      component: 'BUTTON',
      props: {
        text: 'Actions',
        type: 'dropdown',
        buttonColor: '#ffffff',
        textColor: '#026ff2',
        orientation: 'left',
        className: 'detailsBtn',
        caretColor: '#026ff2',
        autoAdjustOrientation: true,
        width: '70px',
        display: constructProp,
        actionItems: constructProp,
        onClick: handleAction,
        emptyMenuItem: 'No Action Available',
      },
      ignorePropConstruct: [
        'onClick',
        'autoAdjustOrientation',
        'type',
        'width',
        'orientation',
        'textColor',
        'buttonColor',
        'text',
        'emptyMenuItem',
        'className',
        'caretColor',
      ],
      showOnHover: true,
      showOnRowSelect: false,
      tdClassName: 'borderTop borderBottom',
    },
    {
      component: 'BUTTON',
      props: {
        type: 'image',
        icon: MarkAsDoneIcon,
        onClick: handleAction,
        action: 'mark-as-done',
        className: 'mr-1',
        width: '20px',
        display: constructProp,
        tooltipContent: 'Mark as done',
        tooltipDirection: 'top',
        tooltipStyles: { left: '-50%' },
        tooltipCaretStyles: { left: '80%' },
      },
      ignorePropConstruct: ['onClick', 'icon', 'action', 'className', 'width'],
      showOnHover: true,
      showOnRowSelect: false,
      tdClassName: 'borderTop borderBottom borderRight borderRadiusRight',
    },
  ]
}
export const overDueheaderTemplate = () => {
  return [
    {
      component: 'LABEL',
      props: {
        label: '',
      },
      tdClassName: 'padding-5-left',
    },
    {
      component: 'LABEL',
      props: {
        label: '',
      },
    },
    {
      component: 'LABEL',
      props: {
        label: '',
      },
    },
    {
      component: 'LABEL',
      props: {
        label: '',
      },
    },
    {
      component: 'LABEL',
      props: {
        label: '',
      },
    },
    {
      component: 'LABEL',
      props: {
        label: '',
      },
    },
    {
      component: 'LABEL',
      props: {
        label: '',
      },
    },
    {
      component: 'LABEL',
      props: {
        label: '',
      },
    },
    {
      component: 'LABEL',
      props: {
        label: '',
      },
    },
    {
      component: 'LABEL',
      props: {
        label: '',
      },
    },
    {
      component: 'LABEL',
      props: {
        label: '',
      },
    },
  ]
}
