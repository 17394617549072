export const InvestmentRangeMasking = (val,type) => {
    // for masking

  if(type === "setMasking") 
  { 
 
    // let modifiedValue = Number(val).toLocaleString();
    // return modifiedValue;


    let modifiedValue = ''
     modifiedValue = val && val.toString().replace(/.(?=(..)*...$)/g, '$&,')
      return modifiedValue;
  
  }

   else if(type === "setMaskingForInputRange") 
  { 
      
    // const onlyNums = val && val.toString().replace(/[^\d]/g, "");
    // let modifiedValue = Number(onlyNums).toLocaleString();
    // return modifiedValue;


      let modifiedValue = ''
    modifiedValue = val && val.toString().replace(/.(?=(..)*...$)/g, '$&,');
    return modifiedValue;
  }
  else if(type === "unMask") 
  {  
    if (val !== "NaN") {
      return val.toString().replace(/,/g, "");
    } else {
      return "any";
    } 
}

else if(type === "checkDatatype")
{
    const re = /^[0-9\b]+$/;               
    if (val === '' || re.test(val)) { 
          return true  
    }
    else{
        return false
    }
}

  // for masking
  };

