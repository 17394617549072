/** @format */

import React from 'react'
import DiaryDateQuickFilter from './DiaryDateQuickFilter'
const QuickFilterItem = (props) => {
  const { value } = props.item


  const ChooseTextColorSelected = payload => {
    let textColor = '#fff'
    switch (payload){
      case 'meeting':
      case 'Hot':
      case 'connect':
      case 'follow_up':
        textColor = '#fff'
        break;
      default:
        break;
    }

    return textColor
  }

  const ChooseTextColor = payload => {
    let textColor = ''
    switch (payload){
      case 'meeting':
        textColor = '#71c2fe'
        break;
      case 'Hot':
        textColor = 'red'
        break;
      case 'connect':
        textColor = '#7bb461'
        break;
      case 'follow_up':
        textColor = '#ffc61b'
        break;
      default:
        break;
    }

    return textColor
  }

  const ChooseEntityColor = payload => {
    let entityColor = '#007bfe'
    switch (payload){
      case 'meeting':
        entityColor = '#71c2fe'
        break;
      case 'Hot':
        entityColor = 'red'
        break;
      case 'connect':
        entityColor = '#7bb461'
        break;
      case 'follow_up':
        entityColor = '#ffc61b'
        break;
      default:
        break;
    }

    return entityColor
  }

  let itemStyle = {
    border: '1px solid #ebebeb',
    borderRadius: 4,
    margin: 2,
    padding: '7px 12px',
    backgroundColor: '#fff',
    color: ChooseTextColor(value),
    cursor: 'pointer',
    textAlign: 'center',
    minWidth: 92,
    ...props.style,
    '&:hover': {
      backgroundColor: '#0D47A1 !important',
    }
  }
  const selectedItemStyle = {
    color: ChooseTextColorSelected(value),
    backgroundColor: ChooseEntityColor(value)
  }

  const setOrRemoveFilter = () => {
    if (props.selectedFilters.filter((f) => props.item.label === f.label).length)
      props.setSelectedQuickFilter(
        props.selectedFilters.filter((f) => props.item.label !== f.label)
      )
    else props.setSelectedQuickFilter([...props.selectedFilters, props.item])
  }

  const selected = props.selectedFilters.filter((f) => props.item.label === f.label).length
  if (selected) itemStyle = { ...itemStyle, ...selectedItemStyle }

  const getFilterItem = () => {
    if (props.item.type && props.item.type === 'diaryDateSelector') {
      return (
        <DiaryDateQuickFilter
          selectedFilters={props.selectedFilters}
          setSelectedQuickFilter={props.setSelectedQuickFilter}
        ></DiaryDateQuickFilter>
      )
    } else {
      return (
        <div className={`quick-filter-item`} onClick={() => setOrRemoveFilter()} style={itemStyle}>
          {props.item.label}
        </div>
      )
    }
  }
  return getFilterItem()
}

export default QuickFilterItem
