import React, {useEffect, useRef, useState} from 'react';
import moment from "moment/moment";
import AgentDropDown from "../../components/CallCenter/AgentDropDown";
import {CSVLink} from "react-csv";
import Pagination from "rc-pagination";
import axios from "axios";
import config from "../../config";
import {setTitle} from "../../actions/generalSetting";
import {connect} from "react-redux";
import { DateRangePicker } from 'react-dates'


const UserMiscallLogs = (props) => {

  const csvLink = useRef();


  const [pagePermission, setPagePermission] = useState(true)
  const [armUserId, setArmUserId] = useState('')
  const { setTitle } = props;
  const [startDate, setStartDate] = useState(moment(new Date()).subtract(10,'days'));
  const [endDate, setEndDate] = useState(moment(new Date()));
  const [agentNames, setAgentNames] = useState([])
  const [selectedArmUserId, setSelectedArmUserId] = useState({})
  const [focusedInputForIncoming, setFocusedInputForIncoming] = useState(null)
  const [loader, setLoader] = useState(false)
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [missedCallLogData, setMissedCallLogData] = useState([])
  const [totalRows, setTotalRows] = useState(1)
  
  const [csvData, setCsvData] = useState([]);
  useEffect(() => {
    setTitle({name: 'Agent Miscall Logs'})
    let isTeamLead = 
      localStorage.getItem('user_agency_mgmt') !== '' && 
      localStorage.getItem('user_agency_mgmt') !== null 
        ? JSON.parse(localStorage.getItem('user_agency_mgmt')).data.isTeamLead 
        : false;
    setPagePermission(isTeamLead)
    getMisscallReport(startDate, endDate, '')
  }, [armUserId, startDate, endDate])


/*   const getData = async (startDate,endDate) => {
    armUserId && setLoader(true)
    const formattedStartDate = moment(startDate).format('YYYY-MM-DD');
    const formattedEndDate = moment(endDate).format('YYYY-MM-DD');
    const userId = armUserId || ''
    await axios
      .post(
        `${config.apiPath}/api/cdrreport/callBackReport`, {userId, startDate: formattedStartDate, endDate: formattedEndDate}
      )
      .then((res) => {
        setMissedCallLogData(res.data)
        setTotalRows(res.data.length > 0 && res.data[0].total_rows || 0)
      }).catch((error) => {
        console.error(error);
      });
    setLoader(false)
  } */

  const getCsvData = async () => {
    armUserId && await axios
      .post(
        `${config.apiPath}/api/cdrreport/missedCallReport`, {isSupervisor: true, userId: armUserId}
      )
      .then((res) => {

        const allRebates = missedCallLogData.length > 0
          ? missedCallLogData.map((data) => (
            {
              'Name': data.name,
              'Phone No': data.phone_no,
              'Time': moment(data.time).format('DD-MM-YYYY (hh:mm A)'),
            }))
          : [];

        //console.log("@@allRebates", allRebates)
        setCsvData(allRebates);
      })
      .catch((e) => console.log(e))
    csvLink.current.link.click()
  };

  const getMisscallReport = (startDate, endDate, selectedArmUserId) => {
    const formattedStartDate = moment(startDate).format('YYYY-MM-DD');
    const formattedEndDate = moment(endDate).format('YYYY-MM-DD');
    axios
      .post(`${config.apiPath}/api/cdrreport/callBackReport`, {
          userId: selectedArmUserId  || armUserId, 
          startDate: formattedStartDate, 
          endDate: formattedEndDate}
      )
      .then((res) => {
        setMissedCallLogData(res.data.data)
        setTotalRows(res.data.data.length > 0 && res.data.data.length || 0)
      })
    setLoader(false)
  }

  return (
    <>
      {pagePermission ?
        <div className="card mt-2">
          <div style={{padding: '1%', height: "85vh"}}>
            <div className="ccmc-filters row">
              <div className="col-9">
                <div className="row">
                  <div className="col-4">
                    <AgentDropDown
                      allAgents={(allagents) => {
                        setSelectedArmUserId(() => (''));
                        setAgentNames(() => (allagents))
                      }}
                      propSelectedArmUserId={selectedArmUserId}
                      isSetAgentName={agentNames}
                      armUserId={(armUserId) => {
                        setArmUserId(armUserId)
                      }}
                    />
                  </div>
                  <div className="col-4">

                  <DateRangePicker
                        isOutsideRange={() => false}
                        startDate={startDate}
                        endDate={endDate}
                        startDateId="callCenter_date_input_start"
                        minimumNights={0}
                        endDateId="callCenter_date_input_end"
                        onDatesChange={({ startDate: dateStart, endDate: dateEnd }) => {
                          setStartDate(dateStart)
                          setEndDate(dateEnd)
                        }}
                        focusedInput={focusedInputForIncoming}
                        onFocusChange={(input) => setFocusedInputForIncoming(input)}
                        showClearDates
                      />
                   {/* <input onChange={(e) => {
                      setStartDate(e.target.value)
                      console.log(newArmUserId, armUserId, 'newArmUserId')
                      getMisscallReport(e.target.value, armUserId)
                    }} defaultValue={startDate} type="date" className={'form-control'} placeholder={'Date'}/>*/}
                  </div>
                </div>
              </div>


              <div className="col-3 row justify-content-end ">
                <div className="col-md-6">

                  <div className="ccmc-clearFilter">
                    <button className={`newFormSearch`} onClick={() => getMisscallReport(startDate, endDate, armUserId)}>
                      <span className={`fa fa-search`}></span>
                    </button>

                    {agentNames.length > 0 && (
                      <div>
                        <button onClick={getCsvData} className="newFormSearch ml-1">
                          <span className={`fa fa-download`}></span>
                        </button>
                        <CSVLink
                          data={csvData}
                          filename="agent performances_inbound_and_outbound_Call_logs.csv"
                          className="hidden"
                          ref={csvLink}
                          target="_blank"
                        />
                      </div>
                    )}

                  </div>
                </div>
              </div>
            </div>
            <div className="reports-table">
              <table style={{height: '80%'}}>
                <thead>
                <tr>
                  <th style={{minWidth: '50px'}}>ID</th>
                  <th style={{minWidth: '200px'}}>Name</th>
                  <th style={{minWidth: '200px'}}>Phone</th>
                  <th style={{minWidth: '200px'}}>Missed Call Time</th>
                  <th style={{minWidth: '220px'}}>Call Back Time</th>
                  <th style={{minWidth: '220px'}}>Recording</th>
                  <th style={{minWidth: '220px'}}></th>

                </tr>
                </thead>
                <tbody>
                {loader ? (
                  <tr>
                    <p
                      style={{paddingLeft: '36vw', paddingTop: '10vh'}}
                      colSpan={10}
                      className={''}
                    >
                      Loading ...
                    </p>
                  </tr>
                ) : (
                  <>{


                    missedCallLogData.length > 0 ?
                      missedCallLogData.map((e) => {
                        return <TableTr data={e}/>
                      })
                      : <tr>
                        <p style={{paddingLeft: '36vw', paddingTop: '10vh'}}
                           colSpan={10}
                           className={''}>
                          No Data Found
                        </p>
                      </tr>}
                  </>
                )}
                </tbody>
                {(missedCallLogData.length > 0 && !loader) && (
                  <tfoot className="row mt-2 mb-1">
                  <tr className="col-md-12 d-flex flex-column justify-content-center">
                    <td colSpan={10}>
                      <div className="pagination justify-content-center">
                        <Pagination
                          className="ps-pagination"
                          hideOnSinglePage={true}
                          pageSize={perPage}
                          current={page}
                          showPrevNextJumpers={false}
                          onChange={(cPage, pSize) => {
                            setPage(cPage)
                          }}
                          total={totalRows}
                        />
                      </div>
                    </td>
                  </tr>
                  </tfoot>)}
              </table>
            </div>
          </div>
        </div> : null}
    </>
  )
}


const TableTr = ({data}) => {
  return (
    <tr className="hover__for__btns hoverForTr">
      <td style={{minWidth: '50px'}}>{data.id}</td>
      <td style={{minWidth: '200px'}}>{data.name}</td>
      <td style={{minWidth: '200px'}}>{data.phone_no}</td>
      <td
        style={{minWidth: '200px'}}>{data.misscall_datetime ? moment(data.misscall_datetime).format('DD-MM-YYYY (hh:mm A)') : ''}</td>
      <td
        style={{minWidth: '200px'}}>{data.created_at ? moment(data.created_at).format('DD-MM-YYYY (hh:mm A)') : ''}</td>
      <td style={{minWidth: '200px'}}>
        {
          data.recording && (
            <audio className='audio_st' controls>
              <source src={`${config.asteriskUrl}/api/recording.php?filename=${data.recording}`} type='audio/mp3'/>
            </audio>
          )
        }
      </td>
    </tr>
  )
}

const mapDispatchToProps = (dispatch) => ({
  setTitle: (title) => dispatch(setTitle(title)),
})
const mapStateToProps = (state) => ({})
export default connect(mapStateToProps, mapDispatchToProps)(UserMiscallLogs);
