/** @format */

import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Table from '../../Table/Table'
import { headerTemplate, bodyTemplate } from '../../../TableConfig/WantedLeadConfig'
import { getPropValue } from './WantedUtilHelper'
import './wantedLeads.scss'
import 'rc-pagination/assets/index.css'
import local from '../../../../local/en_US'
import Pagination from 'rc-pagination'
import DiaryModal from '../../../Modals/DiaryModal'
import { diaryCreateTaskToggle } from '../../../../actions/diary'
import DiaryTaskModal from '../../../Modals/DiaryTaskModal/DiaryTaskModal'
import ConnectModal from '../../../Modals/ConnectFeedback/ConnectModal'
import CloseLostFeedback from '../../../Modals/FeedbackModal/ClosedLost'
import ReferModal from '../../../Modals/ReferModal'
import AdditionalDetails from '../../../../components/Modals/AdditionalDetailsModal/AdditionalDetails'
import { managerSelectionDropdown } from '../../../../utils/constant_data'
import config from '../../../../config'
import axios from 'axios'
import { showToastMsg } from '../../../../utils/helper'
import CloseAsLostModal from '../../../../components/Modals/CloseLost'
import { isReadOnly } from '../../../../utils/lead'
import { assignLeadFun } from '../BuyRent/BuyRentUtilHelper'
import ConnectFlow from '../../../Modals/ConnectFeedback/ConnectFlow'
import CloseAsDuplicateModal from '../../../Modals/CloseDuplicate'
import { fetchReferredAgents } from '../../../../utils/user'
// import { canMarkCloseAsLost } from '../../Diary/DiaryUtilHelper'
// import { fetchSingleLead } from '../../../../actions/leads'

const leadType = 'Wanted'

const WantedLeads = (props) => {
  const [selectedLead, setSelectedLead] = useState(null)
  const [isScheduleTaskOpen, scheduleTaskToggle] = useState(false)
  const [isCreateTaskOpen, createTaskToggle] = useState(false)
  const [isDetailsOpen, detailsToggle] = useState(false)
  const [isAdditionalInfoToggle, additionalInfoToggle] = useState(false)
  const [fetchLeadsInModal, setFetchLeadsInModal] = useState(false)
  const [isReAssignOpen, reAssignToggle] = useState(false)
  const [shareloader, setShareloader] = useState(false)
  const [agents, setAgents] = useState([])
  const [action, setAction] = useState('')
  const [ParentComponentData, setParentComponentData] = useState({})
  const [shouldAssign, setShouldAssign] = useState(false)
  const [closeAsLostModal, setCloseAsLostModal] = useState(false)
  const [newTask, setNewTask] = useState('')
  const [shareLeadType, setShareLeadType] = useState(null)
  const [closeAsDuplicateModal, setCloseAsDuplicateModal] = useState(false)
  const [connectFlow, setConnectFlow] = useState(false)
  const [agentCount, setAgentCount] = useState(0)

  // const { fetchSingleLeadProps, singleLeadRecord } = props

  useEffect(() => {
    // fetchAgents()

      fetchReferredAgents(props.user, 'BuyRent', 1, null, (data) => {
        setShareloader(false)
        setAgents(data.rows)
        setAgentCount(data.count)
      })
  }, [])

  const handleCloseAsLost = (selectedLead) => {
    // console.log('singleLeadRecord - 2', singleLeadRecord)
    // canMarkCloseAsLost(singleLeadRecord && singleLeadRecord, 'Wanted')
    setCloseAsLostModal(true)
  }

  const convertLead = (lead) => {
    axios
      .post(`${config.apiPath}/api/wanted/convert-to-lead/${lead.id}`)
      .then((data) => {
        showToastMsg('Lead Assigned successfully', 'success')
        detailsToggle(false)
        props.onChangePage(props.pagination.currentPage, props.pagination.pageSize)
        setShouldAssign(false)
      })
      .catch((error) => {
        showToastMsg('Lead Not Assigned', 'error')
        setShouldAssign(false)
      })
  }

  const handleActions = (payload) => {
    reAssignToggle(true)
    setShareLeadType('assign')
    // if (payload === 'scheduled-tasks') {
    //   setAction(payload)
    //   scheduleTaskToggle(true)
    // } else if (payload === 'details') {
    //   setAction(payload)
    //   detailsToggle(true)
    // } else if (payload === 're-assign') {
    //   setAction(payload)
    //   reAssignToggle(true)
    // } else if (payload === 'assign-to-area-manager') {
    //   setAction(payload)
    //   detailsToggle(true)
    // } else if (payload === 'additional-information') {
    //   setAction(payload)
    //   additionalInfoToggle(true)
    //   setParentComponentData(props.data)
    // }
  }

  // additionalInfoToggle = () => {
  //   this.setState({ additionalInfoToggleState: !this.state.additionalInfoToggleState })
  // }

  const read = (details) => {
    const loginUserId = localStorage.getItem('user_id')
    const loginUserRole = localStorage.getItem('role')
    let returnObject = { ownLeadReadOnly: false, showReadOnly: false, checkForManager: false }
    let arms_assigned_to = details.assigned_to_armsuser_id
    // details && details.assignedToUser ? details.assignedToUser.id : details.assigned_to_armsuser_id
    if (parseInt(loginUserId) === parseInt(arms_assigned_to)) {
      returnObject['ownLeadReadOnly'] = true
    }
    if (loginUserRole === 'sub_admin 1') {
      returnObject['checkForManager'] = true
    }
    if (
      (details.armsuser && parseInt(loginUserId) !== parseInt(arms_assigned_to)) ||
      details.status === 'closed_lost' ||
      details.status === 'closed_won'
    ) {
      returnObject['showReadOnly'] = true
    }
    return returnObject
  }

  const textItemRender = (current, type, element) => {
    if (type === 'prev') {
      return 'Prev'
    }
    if (type === 'next') {
      return 'Next'
    }
    return element
  }
  const fetchAgents = (data) => {
    setShareloader(true)
    const isGroupManager = localStorage.getItem('groupManager')

    let groupManagerParam = ''
    if (
      (isGroupManager && isGroupManager === true) ||
      (isGroupManager && isGroupManager.toLocaleLowerCase() === 'true')
    ) {
      groupManagerParam = `&groupManager=true`
    }
    axios
      .get(
        `${config.apiPath}/api/role/sub-users?roleId=${
          props.user && props.user.id
        }&addManager=${true}${groupManagerParam}`
      )
      .then((res) => {
        setShareloader(false)
        setAgents(res.data)
        setAgentCount(res.data.length)
      })
  }

  const getPagination = () => {
    if (props.dataCount <= props.pagination.pageSize) return ''
    return (
      <div className="pagination">
        <div className="totalCountAlignment">
          <span className="totalCountStyle">
            Showing
            <span className="totalCountSubStyle">{`${
              (props.pagination.currentPage - 1) * props.pagination.pageSize + 1
            }-${
              props.pagination.currentPage * props.pagination.pageSize < props.dataCount
                ? props.pagination.currentPage * props.pagination.pageSize
                : props.dataCount
            }/${props.dataCount}`}</span>
          </span>
        </div>
        <Pagination
          className="ps-pagination"
          hideOnSinglePage={true}
          pageSize={props.pagination.pageSize}
          current={parseInt(props.pagination.currentPage, 10)}
          showPrevNextJumpers={false}
          itemRender={textItemRender}
          onChange={(cPage, pSize) => {
            // setCurrentPage(cPage)
            props.onChangePage(cPage, pSize)
          }}
          total={props.dataCount}
          locale={local}
        />
      </div>
    )
  }

  const showLeadReferSuccessMsgHandler = (firstName, lastName) => {
    setTimeout(() => {
      reAssignToggle(false)
      props.refreshLead()
      showToastMsg(
        `Lead has successfully been referred to <b>${firstName + ' ' + lastName} </b>`,
        'success'
      )
    }, 300)
  }

  const assignLeadFun = (id, firstName, lastName) => {
    let space = ' '
    let assignData = {
      leadId: selectedLead && [selectedLead.id],
      type: 'wanted',
      userId: id,
    }
    axios.patch(`${config.apiPath}/api/leads/assign`, assignData).then((res) => {
      props.onChangePage(props.pagination.currentPage, props.pagination.pageSize)
      showToastMsg(
        `Lead re-assign to <b>${firstName + space + lastName}</b> Successfully`,
        'success'
      )
      setTimeout(() => {
        reAssignToggle(false)
        // fetchAgents()
        fetchReferredAgents(props.user, 'BuyRent', 1, null, (data) => {
          setShareloader(false)
          setAgents(data.rows)
          setAgentCount(data.count)
        })
      }, 300)
    })
  }

  return (
    <>
      <Table
        headerClassName={'wantedLeadsHeader'}
        tableClassName={'wantedLeadsTable taimoorbuyrent'}
        tableContinerClassName={'wantedLeadsTableContainer'}
        rowsClassName={'wantedLeadsRow'}
        cellWidth={[
          '100px',
          '200px',
          '300px',
          '100px',
          '100px',
          '100px',
          '150px',
          '150px',
          '200px',
          '80px',
        ]}
        rowHeight={() => (props.rowHeight ? props.rowHeight : '50px')}
        headerHeight={'50px'}
        headerColor={'#f6f7fa'}
        rowHoverStyles={(rowIndex, rowData) => {
          return { backgroundColor: '#046ef21f' }
        }}
        headerTemplate={headerTemplate()}
        bodyTemplate={bodyTemplate(getPropValue, handleActions, props)}
        data={props.data}
        fixedHeader={true}
        fixedColumns={{ left: 0, right: 1 }}
        onRowClick={(data) => {
          setSelectedLead(data.rowData)
          // fetchSingleLeadProps(data.rowData.id, false, null)
          props.onRowClick && props.onRowClick(data.rowData)
          if (props.otherleads) {
            detailsToggle(true)
          }
        }}
        onCellClick={(cellIndex, rowIndex, rowData, cellName, event) => {
          if (!props.hideAction) {
            if (cellIndex !== 9) {
              detailsToggle(true)
            }
          }
        }}
        selectedRowColor={() => {
          return '#046ef21f'
        }}
      ></Table>
      {getPagination()}

      {isScheduleTaskOpen && (
        <DiaryModal
          leadType={leadType}
          diaryModal={isScheduleTaskOpen}
          setDiaryModal={(toggle) => scheduleTaskToggle(toggle)}
          lead={selectedLead}
          checkForRead={isReadOnly(selectedLead, leadType)}
          setFetchLeadsInModal={(toggle) => {
            setFetchLeadsInModal(toggle)
          }}
          fetchLeadsInModal={fetchLeadsInModal}
          setLeadTaskModal={(toggle) => {
            createTaskToggle(true)
          }}
        />
      )}

      {isCreateTaskOpen && (
        <DiaryTaskModal
          taskModalToggle={isCreateTaskOpen}
          leadId={(selectedLead && selectedLead.id) || ''}
          lead={selectedLead}
          diary={null}
          leadType={leadType}
          diaryAction={'add-lead-task'}
          closeTaskModal={() => createTaskToggle(false)}
          callBack={(toggle) => setFetchLeadsInModal(toggle)}
        />
      )}

      {isDetailsOpen && (
        <ConnectModal
          connectModal={isDetailsOpen}
          lead={selectedLead}
          leadType={leadType}
          customer={selectedLead.customer}
          checkForRead={isReadOnly(selectedLead, leadType)}
          setConnectModal={(toggle,refreshLead) => {
            detailsToggle(false)
            if(refreshLead){
            props.refreshLead()
            }
          }}
          action={action}
          convertLead={(lead) => convertLead(lead)}
          shouldAssign={shouldAssign}
          setShouldAssign={(shouldAssign) => setShouldAssign(shouldAssign)}
          setNewTask={(newTask) => {
            setNewTask({
              ...newTask,
              wantedId: selectedLead && selectedLead.id,
              wanted: selectedLead
            } )
            setConnectFlow(true)
            detailsToggle(false)
          }}
          onAction={(action) => {
            if (action === 'scheduled-task') {
              detailsToggle(false)
              scheduleTaskToggle(true)
            } else if (action === 'additional-details') additionalInfoToggle(true)
            else if (action === 'refer-lead') {
              reAssignToggle(true)
              setShareLeadType('share')
            } else if (action === 'reassign-lead') {
              reAssignToggle(true)
              setShareLeadType('assign')
            } else if (action === 'close-as-duplicate') setCloseAsDuplicateModal(true)
            else if (action === 'close-as-lost') handleCloseAsLost(selectedLead)
          }}
          setCallRecord={(callRecord) => {
            detailsToggle(false)
          }}
          callBack={(action) => {}}
          updateLeadTasks={false}
          setConnectModal={(toggle,refreshLead) => {
            detailsToggle(toggle)
            if(refreshLead){
            props.refreshLead()
            }
          }}
          setLeadTaskModal={(toggle) => scheduleTaskToggle(true)}
        />
      )}

      {isReAssignOpen && (
        <ReferModal
          shareToggleState={isReAssignOpen}
          shareToggle={() => reAssignToggle(!isReAssignOpen)}
          managerSelectionDropdown={managerSelectionDropdown}
          loader={shareloader}
          filterReferLeadAgentName={(name, page) => {
            setShareloader(true)
            fetchReferredAgents(props.user, 'BuyRent', page, name, (data) => {
              setShareloader(false)
              setAgents(data.rows)
              setAgentCount(data.count)
              setShareloader(false)
            })
          }}
          agents={agents}
          shareLeadType={shareLeadType}
          investmentLeadShareFun={(id, firstName, lastName) =>
            props.addInvestmentShareLead(
              {
                userId: id,
                leadId: selectedLead && selectedLead.id,
              },
              null,
              selectedLead.id,
              null,
              showLeadReferSuccessMsgHandler(firstName, lastName)
            )
          }
          assignLeadFun={(id, firstName, lastName) =>
            assignLeadFun(id, firstName, lastName, [selectedLead], null, () => {
              reAssignToggle(false)
              setTimeout(() => {
                props.refreshLead()
              }, 300)
            })
          }
          agentCount={agentCount}
        />
      )}

      {isAdditionalInfoToggle && (
        <AdditionalDetails
          onToggle={additionalInfoToggle}
          isOpen={isAdditionalInfoToggle}
          additionalDetailLeadrecord={selectedLead}
          checkLeadtype={leadType}
        ></AdditionalDetails>
      )}

      {connectFlow && (
        <ConnectFlow
          setConnectModal={(toggle) => {
            setConnectFlow(false)
            detailsToggle(toggle)
          }}
          setConnectFlow={() => {
            setConnectFlow(false)
            props.refreshLead()
          }}
          diary={newTask}
          actionType={'Connect'}
          callback={() => {
            setConnectFlow(false)
            props.refreshLead()
          }}
        />
      )}

      {closeAsDuplicateModal && (
        <CloseAsDuplicateModal
          lead={selectedLead}
          leadType={leadType}
          modal={closeAsDuplicateModal}
          setModal={(toggle) => setCloseAsDuplicateModal(toggle)}
          callback={() => {
            props.refreshLead()
            detailsToggle(false)
            setConnectFlow(false)
            setCloseAsDuplicateModal(false)
          }}
        />
      )}
      {closeAsLostModal && (
        <CloseAsLostModal
          lead={selectedLead}
          leadType={leadType}
          modal={closeAsLostModal}
          setModal={(toggle) => setCloseAsLostModal(toggle)}
          callback={() => {
            props.refreshLead()
            detailsToggle(false)
            setConnectFlow(false)
            setCloseAsLostModal(false)
          }}
        />
      )}
    </>
  )
}

export const mapDispatchToProps = (dispatch) => {
  return {
    // fetchSingleLeadProps: (leadId, byProject, callBack) =>
    //   dispatch(fetchSingleLead(leadId, byProject, callBack)),
  }
}

export const mapStateToProps = (state) => {
  return {
    // singleLeadRecord: state.Leads.singleLeadRecord,
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(WantedLeads)
