import React, {useEffect, useRef, useState} from 'react'
import io from 'socket.io-client';
import mp3Tune from "../../../images/nokia-iphone.mp3"
import socketConnection from "../../../callCenter/socket";
import {showToastMsg} from "../../../utils/helper";
import '../../../callCenter/style.scss'
import Phone from "../../../callCenter/Phone";
import moment from "moment";
import {connect} from "react-redux";
import {getAsteriskAgents, offlineAgent, onlineAgent} from "../../../agentOnlineSession";

const Dialer = (props) => {

  const [globalSipDials, setGlobalSipDials] = useState({});
  const socket = socketConnection({...props, token: 'tokentoken'})
  const [isConnected, setIsConnected] = useState(socket.connected);
  const [liveCall, setLiveCall] = useState({})
  const [windowHeight, setWindowHeight] = useState(window.innerHeight - 40)
  const [agentUser, setAgentUser] = useState('')
  const [agentPassword, setAgentPassword] = useState('')
  /*const [agentDisplayName, setAgentDisplayName] = useState('')
  const [agentArmId, setAgentArmId] = useState('')*/
  const [totalAgents, setTotalAgents] = useState([])
  const [asteriskAccounts, setAsteriskAccounts] = useState([])
  /*  const [callChannel, setCallChannel] = useState('')
    const [callDistChannel, setCallDistChannel] = useState('')*/
  const [isSoftPhoneLoaded, setIsSoftPhoneLoaded] = useState(true)

  const [callUniqueId, setCallUniqueId] = useState('')
  const [queue, setQueue] = useState('')
  const agentUniqueKey = localStorage.getItem('agent_unique');
  useEffect(() => {
    setWindowHeight(window.innerHeight - 40)
  }, [window.innerHeight])

  const updateSocketConnection = (isConnected) => {
    props.isCallCenterConnected(isConnected)
  }


  const updateAgentLists = () => {
    getAsteriskAgents().then((result) => {
      if (result.success) {
        updateAgentsStats(result.data);/* for call transfer to agent etc */
      }
    })
  }
  useEffect(() => {
    if (props.dialerState) {
     updateAgentLists()
    }
  }, [props.dialerState])
  /* useEffect(() => {
     let agentData1 = localStorage.getItem('agent_data');
     if (agentData1 !== undefined && agentData1 !== null) {
       agentData1 = JSON.parse(agentData1);
       setAgentUser((agentUser) => (agentData1.agentId))
       setAgentPassword((agentPassword) => (agentData1.password))
       setAgentDisplayName((agentDisplayName) => (agentData1.name))
     }
   }, [])*/


  const updateAgentsStats = (agents) => {
    let newAgents = [];
    setAsteriskAccounts((asteriskAccounts) => ([]));
    agents.map((agent, index) => {
      if (agent.agentId === agentUser) {
        if (isConnected === false) {
          setIsConnected(true)
          updateSocketConnection(true)
        }
      } else {
        if (agent.agentId) {
          newAgents.push({
            label: agent.agentName,
            accountId: agent.agentId,
            status: agent.status,
            agentArmId: agent.agentArmId,
            loginAt: moment(agent.loginAt).fromNow()
          });
        }
      }
    })
    updateSocketConnection(true)
    setTotalAgents((totalAgents) => ([...agents]))
    setAsteriskAccounts((totalAgents) => ([...newAgents]))
  }

  const getAgentUserName = () => {
    let us = localStorage.getItem('user');
    let name = '';
    try {
      if (us !== undefined && us !== '' && us !== null) {
        us = JSON.parse(us);
        name = us.firstName + " " + us.lastName;
      }
    } catch (e) {
    }
    return name
  }
  const userLoginAgent = (agentUser) => {
    setTimeout(() => {
      onlineAgent({
        agentId: agentUser,
        agentName: getAgentUserName(),
        agentArmId: Number(localStorage.getItem('user_id')),
        agentStatus: 'Connected',
        agentUnique: agentUniqueKey,
        status: 'online',
        isLogin: true
      }).then((agents) => {
        if (agents.success) {
          updateAgentsStats(agents.data); /* for call transfer to agent etc */
        }
      })
    }, 200)
  }
  useEffect(() => {
    setIsConnected(true)
    /* checking for online agent*/
    socket.on('getAgentsOnline', (agents) => {
      agents = JSON.parse(agents);
      try {
        /* const onlineAgent = agents[agents.length - 1];
         if (onlineAgent.agentId !== agentUser) {
           showToastMsg(`${onlineAgent.agentName} (<b>${onlineAgent.agentId}</b>) Online`, 'success', 1000)
         }*/
      } catch (e) {

      }
      updateAgentsStats(agents);/* for call transfer to agent etc */
    })
    props.mwsSocketGlobalHandle({socket: socket, agentId: agentUser});

    socket.on('connect', () => {
      const name = getAgentUserName();
      const socketData = {
        agentId: agentUser,
        agentName: name,
        agentArmId: Number(localStorage.getItem('user_id')),
        agentStatus: 'Connected',
        agentUnique: agentUniqueKey,
        status: 'online',
        isLogin: true
      };
      socket.emit('setAgentsOnline', JSON.stringify(socketData))
      onlineAgent(socketData).then((agents) => {
        if (agents.success) {
          updateAgentsStats(agents.data);/* for call transfer to agent etc */
        }
      })
      setIsConnected(true)
      showToastMsg('Contact center Connected!', 'success', 1200)
    })

    socket.on('disconnect', () => {
      onlineAgent({
        agentId: agentUser,
        agentName: getAgentUserName(),
        agentArmId: Number(localStorage.getItem('user_id')),
        agentStatus: 'Connected',
        agentUnique: agentUniqueKey,
        status: 'online',
        isLogin: true
      }).then((agents) => {
        let newAgents = [];
        if (agents.success) {
          updateAgentsStats(agents.data);/* for call transfer to agent etc */
        }
      })

      offlineAgent(agentUser).then(()=>{

      })

      /*showToastMsg('Contact center disconnected!', 'error', 1200)*/
      setIsConnected(true)
      updateSocketConnection(true)
      socket.connect();
    })
    socket.on(`agent_${agentUser}`, (call) => {
      const newCall = JSON.parse(call)
      setCallUniqueId((callUniqueId) => (newCall.uniqueid))
      switch (newCall.action) {
        case "Hangup":
          setLiveCall((liveCall) => ({...{}}))
          break;
        default:
          props.closeDialer(true)
          setLiveCall((livecall) => ({...livecall, ...newCall}))
          break;
      }
    })
    socket.on(`agent_${agentUniqueKey}`, function (data) {
      console.log(data, 'Listen')
      //showToastMsg(`Hit added: ${data}`,'success')
    });
    socket.on(`agent_queue_${agentUser}`, (data) => {
      data = JSON.parse(data)
      setQueue((queue) => (data.queueId))
    })

    setInterval(() => {

    }, 6000)

    return () => {
      socket.off('connect')
      socket.off('disconnect')
      socket.off(`agent_${agentUser}`)
      socket.off(`agent_queue_${agentUser}`)
      socket.off(`agent_${agentUniqueKey}`)
      socket.off('socket_error')
      socket.off('getAgentsOnline')
    }

  }, [agentUser])

  useEffect(() => {
    if (liveCall.ringing) {
      props.liveCallRing(true)
    } else {
      props.liveCallRing(false)
    }
  }, [liveCall.ringing])

  const softPhoneReload = (isLoaded) => {
    setIsSoftPhoneLoaded(true)
    props.reConnectSocket(agentUser)
    setTimeout(() => {
      setIsSoftPhoneLoaded(true)
    }, 2000)
  }

  /*useEffect(() => {
    setAgentArmId((agentArmId)=>(props.user.id))
    setAgentDisplayName(`${props.user.firstName} ${props.user.lastName}`)
  }, [props.user])*/

  useEffect(() => {
    /* let user = localStorage.getItem('user')*/
    if (props.agentUser !== undefined && props.agentUser !== '' && props.agentUser !== null) {
      setAgentUser((agentUser) => (props.agentUser))
      setAgentPassword((agentPassword) => (props.agentPassword))

      userLoginAgent(props.agentUser)
      /* if (user !== undefined && user !== null && user !== '') {
         user = JSON.parse(user)
         setAgentDisplayName((agentDisplayName) => (`${user.firstName} ${user.lastName}`))
         setAgentArmId((agentArmId) => (user.id))
       }*/
    }
  }, [props.agentPassword, props.agentUser])

  return (
    <div className={'mws_dialer_wrapper bg-white dialer-mws'} style={{
      position: 'absolute',
      zIndex: 10000,
      right: 0,
      top: '0px',
      bottom: 0,
      width: '23%',
      height: '100%',
      border: '1px solid #dcdcdc',
      padding: '1rem'
    }}>
      <div className="sidebar-header">
        <div onClick={() => {
          props.closeDialer(false)
        }} className={'btn-close'}><i className={'fa fa-close'}></i>
        </div>

        {
          isConnected ? (
            <span className={'status'} title={isConnected ? 'Socket connected' : 'Not Connected'}>
          Status &nbsp; &nbsp;&nbsp;&nbsp;<i
              className={isConnected ? 'socket-status connected' : 'socket-status connected'}></i>
        </span>
          ) : (
            <span style={{cursor: 'pointer'}} className={'status'}
                  title={isConnected ? 'Socket connected' : 'Not Connected'}>
          Status &nbsp; &nbsp;&nbsp;&nbsp;<i onClick={() => {
              props.reConnectSocket(agentUser)
            }} className={isConnected ? 'socket-status connected' : 'socket-status connected'}></i>
        </span>
          )
        }
        <label className={isConnected ? 'lbl-agent connectedlbl' : 'lbl-agent disconnectedlbl'}>{agentUser}</label>
        <div className={'total-agents'}>Live agents:&nbsp;&nbsp;
          <span className={'badge bg-success p-1'}>{totalAgents.length ? totalAgents.length : 0}</span>
        </div>

      </div>
      <div className="sidebar-content" style={{width: '100%', height: '100%'}}>
        <div className="dialer-content" style={{marginTop: '10px'}}>
          <div className='phonewrapper'>
            {
              isSoftPhoneLoaded && (agentUser !== '' && agentUser !== undefined) &&
              (
                <Phone
                  onlineAgents={asteriskAccounts}
                  agentUser={agentUser}
                  isSocketConnected={isConnected}
                  password={agentPassword.toString()}
                  domain={props.dailerDomain}
                  socket={socket}
                  callUniqueId={0} /* not used in optimization */
                  callQueueId={queue}
                  updateAgentsStats={(agents) => {
                    updateAgentsStats(agents);/* for call transfer to agent etc */
                  }}
                  refreshAgentLists={(() => {
                    updateAgentLists();
                  })}
                  globalSipDial={(sipDial) => {
                    setGlobalSipDials(() => (sipDial))
                  }}
                  ClientLoadHandle={props.clientLoadHandle}
                  sipReload={softPhoneReload}
                  mwsCloseDialer={(flag) => {
                    props.closeDialer(flag)
                  }}
                  port={8089} ssl={'wss'}/>
              )
            }
            {
              !isSoftPhoneLoaded && (
                <div className="alert alert-danger">Please wait we are connecting ....</div>
              )
            }
          </div>
        </div>
        {/*<iframe allow="microphone 'src';" width={'100%'} height={`100%`}
                src={`${props.dailerDomain}/cx/agc/agent1.php?VD_login=${props.agentUser}&VD_pass=${props.agentPassword}`}
                frameBorder="0"></iframe>*/}
      </div>
      <div className="sidebar-footer"></div>
    </div>
  )
}
const mapDispatchToProps = (dispatch) => ({})
const mapStateToProps = (state) => {
  return {
    user: state.user.userInfo,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dialer);
