/** @format */

import React, { useState } from 'react'
import TableRow from './../Table/TableRow'
import PropTypes from 'prop-types'
import NoResult from '../../NoResult'
import clsx from 'clsx'
import _ from 'lodash'

const TableBody = (props) => {
  const [selected, setSelected] = useState([])
  const getCellNames = () => {
    if (props.headerTemplate) {
      return props.headerTemplate.map((head, index) => {
        if (head.columnName) {
          return head.columnName
        } else return index
      })
    }
  }

  const getBody = () => {
    return props.data.map((dataItem, index) => {
      return (
        <TableRow
          rowTemplate={props.rowTemplate}
          cellNames={getCellNames()}
          data={dataItem}
          rowIndex={index}
          selectedRowIndex={props.selectedRowIndex}
          onRowClick={props.onRowClick}
          onCellClick={props.onCellClick}
          rowsClassName={props.rowsClassName}
          cellsClassName={props.cellsClassName}
          rowStyles={props.rowStyles}
          rowHoverColor={props.rowHoverColor}
          selectedRowColor={props.selectedRowColor}
          rowHeight={props.rowHeight}
          fixedColumns={props.fixedColumns}
          rowHoverStyles={props.rowHoverStyles}
          isMultiSelect={props.isMultiSelect}
          isSelectAll={props.isSelectAll}
          onMultiSelect={(e, rowIndex, rowData) => {
            let thisSelected = [...selected]
            if (e) {
              thisSelected.push(rowIndex)
            } else {
              let index = thisSelected.indexOf(rowIndex)
              index !== -1 && thisSelected.splice(index, 1)
            }
            setSelected(thisSelected)
            props.onMultiSelect(e, thisSelected, rowIndex, rowData)
          }}
        />
      )
    })
  }

  return (
    <tbody className={clsx({ [props.noResultClassName]: props.noResult })}>
      {props.noResult ? <NoResult /> : getBody()}
    </tbody>
  )
}
export default TableBody
// export default React.memo(TableBody, (prevProps, nextProps) => {
//   return _.isEqual(prevProps.data, nextProps.data)
//   // &&
//   // _.isEqual(prevProps.rowTemplate, nextProps.rowTemplate)
// })

TableBody.propTypes = {
  rowHeight: PropTypes.func,
  rowHoverColor: PropTypes.func,
  selectedRowColor: PropTypes.func,
  onRowClick: PropTypes.func,
  onCellClick: PropTypes.func,
  data: PropTypes.any,
  rowIndex: PropTypes.number,
  rowTemplate: PropTypes.array,
  rowsClassName: PropTypes.any,
  cellsClassName: PropTypes.string,
  selectedRowIndex: PropTypes.number,
  fixedColumns: PropTypes.object,
  isMultiSelect: PropTypes.bool,
  isSelectAll: PropTypes.bool,
  onMultiSelect: PropTypes.func,
  singleSlectedLead: PropTypes.object,
}

TableBody.defaultProps = {
  rowHeight: () => {},
  rowHoverColor: () => {},
  selectedRowColor: () => {},
  onRowClick: () => {},
  onCellClick: () => {},
  rowsClassName: '',
  cellsClassName: '',
  isMultiSelect: false,
  isSelectAll: false,
  onMultiSelect: () => {},
}
