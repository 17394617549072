/** @format */

import React, { useEffect, useState, useRef } from 'react'
import useOutsideListner from './../../../hooks/OutsideListner'
import moment from 'moment'
import { Calendar } from 'react-rainbow-components'
import CalendarIcon from '../../../images/calendar.svg'
import './datePicker.scss'

const datePicker = (props) => {
  let itemStyle = {
    border: '1px solid #ebebeb',
    borderRadius: 4,
    margin: 2,
    padding: '7px 12px',
    backgroundColor: 'white',
    cursor: 'pointer',
    // textAlign: 'center',
    minWidth: 92,
    ...props.style,
  }
  const {onDateChangeHandler, calenderId, alreadySelectedDate} = props;
  const selectedItemStyle = {
    color: '#007bfe',
  }

  const [isCalenderVisible, setCalenderVisibility] = useState(false)
  const [selectedDate, setSelectedDate] = useState(moment().format('YYYY-MM-DD'))
  const [dateChanged, setDateChanged] = useState(false)
  const calenderRef = useRef(null)

  useEffect(() => {
      if(alreadySelectedDate)
        setSelectedDate(moment(alreadySelectedDate).format('YYYY-MM-DD'))
  }, [alreadySelectedDate])

  useOutsideListner(calenderRef, (event) => {
    event.stopPropagation()
    setCalenderVisibility(false)
  })

  const getCalender = () => {
    return (
      isCalenderVisible && (
        <div ref={calenderRef} className="calenderContainer">
          <Calendar
            id={calenderId}
            // locale={state.locale.name}
            value={selectedDate}
            onChange={(value) => {
              setSelectedDate(moment(value).format('YYYY-MM-DD'))
              onDateChangeHandler(moment(value).format('YYYY-MM-DD'))
              // event.stopPropagation()
              // event.preventDefault()
              setDateChanged(true)
              setCalenderVisibility(false)
            }}
            {...props}
          />
        </div>
      )
    )
  }

  return (
    <>
      <div
        className={`quick-filter-item`}
        onClick={() => {
          !isCalenderVisible && setCalenderVisibility(true)
        }}
        style={{
          ...itemStyle,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <span>{dateChanged || alreadySelectedDate ? moment(selectedDate).format('DD-MMM-YYYY') : 'DD-MM-YYYY'}</span>
        {/* <div style={{textAlign : 'right'}}> */}
        <img
            src={CalendarIcon}
            style={{ position: 'sticky', height: 20, right: 10, top: 10, zIndex: '4' }}
        />
        {/* </div> */}
        {getCalender()}

      </div>
    </>
  )
}

export default datePicker
