/** @format */

import * as ActionTypes from '../actions/wantedLeads'

const initialState = {
  isLoading: false,
  isQualifiedLeadsLoading: false,
  isTeamLeadsLoading: false,
  filters: {
    filters: {},
    quickFilters: [],
  },
}

const WantedLeads = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_WANTED_LEADS:
      return {
        ...state,
        leads: action.data,
        qualifiedLeads: [],
        teamLeads: [],
        isLoading: action.loading,
      }
    case ActionTypes.FETCH_WANTED_QUALIFIED_LEADS:
      return {
        ...state,
        qualifiedLeads: action.data,
        leads: [],
        teamLeads: [],
        isQualifiedLeadsLoading: action.loading,
      }
    case ActionTypes.FETCH_WANTED_TEAM_LEADS:
      return {
        ...state,
        teamLeads: action.data,
        qualifiedLeads: [],
        leads: [],
        isTeamLeadsLoading: action.loading,
      }
    case ActionTypes.SET_WANTED_FILTERS:
      return {
        ...state,
        filters: { ...state.filters, filters: action.data },
      }
    case ActionTypes.FETCH_CUTOMER_WANTED_LEADS:
      return {
        ...state,
        customerLeads: action.data,
        cLoading: action.loading,
      }
    default:
      return {
        ...state,
      }
  }
}

export default WantedLeads
