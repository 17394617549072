/** @format */

import React, { useState } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import Inner from './Inner'
import './style.scss'

const AddClientModal = (props) => {
  const {
    activeClientModal,
    shareToggle,
    clientModalToggle,
    formData,
    handleChange,
    handleChangePhone,
    phone,
    createClient,
    contact1,
    contact2,
    validationCheck,
    loadingOnsubmit,
    cnicValidation,
    phoneValidate,
    phoneValidate1,
    phoneValidate2,
    editAble,
    emailValidate,
    fromPage,
    checkPurchaserPage,
    SourceOfIncomehandle,
    SourceOfFundsHandler,
    otherSourceOfIncomeHandler,
    otherSourceOfFundsHandler,
    AssociationWithanypoliticalpartyHandler,
    AssociationWithanypoliticalparty,
    ExistingImaratClientHandler,
    ExistingImaratClient,
    OtherProjectsInvHandler,
    OtherProjectsInv,
    RegisterWithFBRHandler,
    RegisterWithFBR,
    soi,
    sourceOfFunds,
    otherSourceOfIncome,
    sof
  } = props

  return (
    <Modal
      isOpen={activeClientModal}
      toggle={clientModalToggle}
      size="lg"
      style={{ maxWidth: '730px', width: '100%' }}
      contentClassName=""

    >
      <div className="createClientModal hasScroll" ref={props.setRef}>
        <ModalHeader toggle={clientModalToggle}>
          {editAble === true ? 'Edit' : 'Register'} Client
        </ModalHeader>
        <ModalBody>
          {/* <div className={'addPageHeading'}>
                        <h4>{editAble === true ? 'Edit' : 'Create'} Client</h4>
                    </div> */}
          <div className={`filterMainWrap padding__modal`}>
            <Inner
              isModal={true}
              formData={formData}
              phone={phone}
              contact1={contact1}
              contact2={contact2}
              showAdditionInfo={props.showAdditionInfo}
              loadingOnsubmit={loadingOnsubmit}
              validationCheck={validationCheck}
              cnicValidation={cnicValidation}
              phoneValidate={phoneValidate}
              phoneValidate1={phoneValidate1}
              phoneValidate2={phoneValidate2}
              emailValidate={emailValidate}
              editAble={editAble}
              handleChange={handleChange}
              handleChangePhone={handleChangePhone}
              createClient={createClient}
              fromPage={fromPage}
              clientModalToggle={clientModalToggle}
              checkPurchaserPage={checkPurchaserPage}
              {...props}
            />
          </div>
        </ModalBody>
      </div>
    </Modal>
  )
}

export default AddClientModal
