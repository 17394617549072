/** @format */

import React, { useState } from 'react'
import { connect } from 'react-redux'
import {
  fetchShortlistedProperties,
  addSelectedProperty,
  setBuyRentDetailsModalToggle,
} from '../../actions/property'
import ShortListedPropertiesTile from '../../components/BuyRentLeadTile/ShortListedPropertiesTile'
import './properties-style.scss'
import _ from 'lodash'

const ShortListedProperties = ({
  singleLeadRecord,
  addShortlistedPropertiesLoader,
  toggleButton,
  shortListedProperties,
  addSelectedProperty,
  setBuyRentDetailsModalToggle,
}) => {
  const [selectedPropertyID, setSelectedPropertyID] = useState(null)
  const [isDisbale, setIsDisable] = useState(true)
  const onAddSelectedProperty = () => {
    const property = shortListedProperties.rows.filter(
      (element) => element.id === selectedPropertyID
    )
    if (property.length) {
      addSelectedProperty(property[0], singleLeadRecord.id)
      toggleButton(false)
      setBuyRentDetailsModalToggle(true)
    }
  }
  return (
    <>
      <div className="mt-2">
        <ShortListedPropertiesTile
          rows={
            shortListedProperties &&
            shortListedProperties.rows &&
            shortListedProperties.rows.length > 0
              ? shortListedProperties.rows
              : []
          }
          selectedPropertyID={selectedPropertyID}
          setSelectedPropertyID={(val) => setSelectedPropertyID(val)}
          setIsDisable={setIsDisable}
          isDisbale={isDisbale}
        />
      </div>

      <div
        className="match-properties-button-container"
        style={{ position: 'absolute', bottom: '20px' }}
      >
        <div className="match-properties-button">
          <div
            className="cancel"
            onClick={() => {
              toggleButton(false)
            }}
          >
            Cancel
          </div>
        </div>
        <div className="match-properties-button">
          <button
            className="selected-matches"
            onClick={() => onAddSelectedProperty()}
            disabled={isDisbale}
            style={{ marginTop: '20px' }}
          >
            {addShortlistedPropertiesLoader ? (
              <i className={`fa fa-circle-o-notch fa-spin`}></i>
            ) : (
              'Continue With Selected Property'
            )}
          </button>
        </div>
      </div>
    </>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSelectedProperties: (leadId) => dispatch(fetchShortlistedProperties(leadId)),
    addSelectedProperty: (property, lead_id) => dispatch(addSelectedProperty(property, lead_id)),
    setBuyRentDetailsModalToggle: (val) => dispatch(setBuyRentDetailsModalToggle(val)),
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
    singleLeadRecord: state.Leads.singleLeadRecord,
    addShortlistedPropertiesLoader: state.Property.addShortlistedPropertiesLoader,
    addShortListedProperties: state.Property.addShortListedProperties,
    shortListedProperties: state.Leads.buyRentShortListProperties,
    buyRentDetailsModalToggle: state.Property.buyRentDetailsModalToggle,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShortListedProperties)
