/** @format */

import React from 'react'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import PropTypes from 'prop-types'

const useStyles = makeStyles({
  root: (props) => ({
    background: props.color,
    width: props.width,
    height: props.height,
  }),
})

const ColorTile = (props) => {
  const classes = useStyles(props)

  return (
    <>
      <div className={clsx(classes.root, props.classes)} style={props.styles}></div>
    </>
  )
}

export default ColorTile

ColorTile.propTypes = {
  width: PropTypes.any,
  height: PropTypes.any,
  color: PropTypes.string,
  classes: PropTypes.string,
  styles: PropTypes.object,
}

ColorTile.defaultProps = {
  width: '100%',
  height: '100%',
  color: 'none',
  classes: '',
  styles: {},
}
