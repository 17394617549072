/** @format */

const PaymentMethods = {
  addPropsureReportPrices(reports) {
    let total = 0
    if (reports && reports.length) {
      reports.map((item, index) => {
        total = Number(total) + Number(item.fee)
      })
      return total
    } else {
      return total
    }
  },
  handleEmptyValue(value) {
    return (value != null && value != '') || value !== undefined ? Number(value) : 0
  },
  findRatePerSqft(unit) {
    if (unit) {
      let { pricePerSqFt, category_charges } = unit
      pricePerSqFt = PaymentMethods.handleEmptyValue(pricePerSqFt)
      category_charges = PaymentMethods.handleEmptyValue(category_charges)
      return pricePerSqFt * (1 + category_charges / 100)
    }
  },
  findUnitPrice(unit) {
    if (unit) {
      let { area } = unit
      area = PaymentMethods.handleEmptyValue(area)
      return area * PaymentMethods.findRatePerSqft(unit)
    }
  },
  __findUnitPrice(inventory) {
    if (inventory) {
      let { total_sq_ft, rate_per_sq_ft } = inventory
      total_sq_ft = PaymentMethods.handleEmptyValue(total_sq_ft)
      return rate_per_sq_ft * total_sq_ft
    }
  },
  findDiscountAmount(unit) {
    if (unit) {
      let { discount } = unit
      discount = PaymentMethods.handleEmptyValue(discount)
      return (PaymentMethods.findUnitPrice(unit) * discount) / 100
    }
  },
  findDiscountPrice(unit) {
    if (unit) {
      return PaymentMethods.findUnitPrice(unit) - PaymentMethods.findDiscountAmount(unit)
    }
  },
  findPaymentPlanDiscount(lead, oneUnit) {
    let fullPaymentDiscount = lead.project != null && lead.project.full_payment_discount
    if (
      lead.installmentDue !== null &&
      lead.installmentDue &&
      lead.installmentDue.includes('Full Payment Disc')
    ) {
      return PaymentMethods.findApprovedDiscountAmount(oneUnit, fullPaymentDiscount)
    } else {
      fullPaymentDiscount = 0
      return PaymentMethods.findApprovedDiscountAmount(oneUnit, fullPaymentDiscount)
    }
  },
  findInternalPaymentPlanDiscount(lead, oneUnit) {
    let fullPaymentDiscount = lead.project != null && lead.project.full_payment_discount
    if (lead.installmentDue !== null && lead.installmentDue.includes('Full Payment Disc')) {
      return PaymentMethods.findApprovedDiscountAmount(oneUnit, fullPaymentDiscount)
    } else {
      fullPaymentDiscount = 0
      return PaymentMethods.findApprovedDiscountAmount(oneUnit, fullPaymentDiscount)
    }
  },
  findRentAmount(unit, pearl) {
    if (unit) {
      let { rentPerSqFt } = unit
      pearl = PaymentMethods.handleEmptyValue(pearl)
      rentPerSqFt = PaymentMethods.handleEmptyValue(rentPerSqFt)
      return pearl * rentPerSqFt
    }
  },
  findMonthlyRentAmount(unit) {
    if (unit) {
      let { rentPerSqFt, area } = unit
      area = PaymentMethods.handleEmptyValue(area)
      rentPerSqFt = PaymentMethods.handleEmptyValue(rentPerSqFt)
      return area * rentPerSqFt
    }
  },
  findApprovedDiscountAmount(unit, discount) {
    if (unit && discount) {
      discount = PaymentMethods.handleEmptyValue(discount)
      return (PaymentMethods.findUnitPrice(unit) * discount) / 100
    } else return 0
  },
  findApprovedDiscountPercentage(unit, price) {
    if (unit && price) {
      price = PaymentMethods.handleEmptyValue(price)
      return (price / PaymentMethods.findUnitPrice(unit)) * 100
    } else if (!price) {
      price = PaymentMethods.handleEmptyValue(price)
      return (price / PaymentMethods.findUnitPrice(unit)) * 100
    }
  },
  findFinalPrice(unit, approvedDiscountPrice, fullPaymentDiscountPrice, isPearl) {
    if (unit) {
      approvedDiscountPrice = PaymentMethods.handleEmptyValue(approvedDiscountPrice)
      fullPaymentDiscountPrice = PaymentMethods.handleEmptyValue(fullPaymentDiscountPrice)
      let totalDiscountPrice = approvedDiscountPrice + fullPaymentDiscountPrice
      if (isPearl) {
        return PaymentMethods.pearlCalculations(unit) - totalDiscountPrice
      } else {
        return PaymentMethods.findUnitPrice(unit) - totalDiscountPrice
      }
    }
  },
  calculateDownPaymentNew(product, inventory) {
    let { down_payment } = product
    let { total_unit_price } = inventory
    down_payment = PaymentMethods.handleEmptyValue(down_payment)
    total_unit_price = PaymentMethods.handleEmptyValue(total_unit_price)

    let finalPrice = (total_unit_price / 100) * down_payment
    return Number(finalPrice).toLocaleString()
  },
  calculateDownPayment(oneProduct, finalPrice, token) {
    let { downPayment } = oneProduct
    downPayment = PaymentMethods.handleEmptyValue(downPayment)
    finalPrice = PaymentMethods.handleEmptyValue(finalPrice)
    token = PaymentMethods.handleEmptyValue(token)
    return (finalPrice * downPayment) / 100
  },
  calculateDownPaymentPercentage(finalPrice, price, token) {
    finalPrice = PaymentMethods.handleEmptyValue(finalPrice)
    token = PaymentMethods.handleEmptyValue(token)
    return (price * 100) / finalPrice - token
  },
  calculateNoOfInstallments(oneProduct, firstFormData) {
    if (oneProduct && firstFormData) {
      let { installmentFrequency, paymentPlanDuration } = firstFormData
      let value = 4
      paymentPlanDuration = PaymentMethods.handleEmptyValue(paymentPlanDuration)
      if (installmentFrequency === 'monthly') value = 12
      else if (installmentFrequency === 'semi annual') value = 2
      return paymentPlanDuration * value
    } else return null
  },
  calculatePossessionCharges(oneProduct, finalPrice, token) {
    let { possessionCharges } = oneProduct
    possessionCharges = PaymentMethods.handleEmptyValue(possessionCharges)
    finalPrice = PaymentMethods.handleEmptyValue(finalPrice)
    token = PaymentMethods.handleEmptyValue(token)
    return (finalPrice * possessionCharges) / 100 - token
  },

  calculatePossessionChargesPercentage(finalPrice, price, token) {
    finalPrice = PaymentMethods.handleEmptyValue(finalPrice)
    token = PaymentMethods.handleEmptyValue(token)
    return (100 * price) / finalPrice - token
  },
  pearlCalculations(unit) {
    if (unit) {
      let { area, pricePerSqFt } = unit
      area = PaymentMethods.handleEmptyValue(area)
      pricePerSqFt = PaymentMethods.handleEmptyValue(pricePerSqFt)
      return area * pricePerSqFt
    }
  },
  findTaxIncluded(payment, taxAmount) {
    payment = PaymentMethods.handleEmptyValue(payment)
    taxAmount = PaymentMethods.handleEmptyValue(taxAmount)
    return Math.ceil((taxAmount / (100 + taxAmount)) * payment)
  },
  findTaxNotIncluded(payment, taxAmount) {
    payment = PaymentMethods.handleEmptyValue(payment)
    taxAmount = PaymentMethods.handleEmptyValue(taxAmount)
    return Math.ceil(payment * (taxAmount / 100))
  },

  findRemainingDownPayment(receivedPayment, unit) {
    if (unit) {
      let { down_payment } = unit
      let result = down_payment - receivedPayment
      return result
    } else return 0
  },

  findRemainingPayment(payment, finalPrice) {
    finalPrice = PaymentMethods.handleEmptyValue(finalPrice)
    let totalPayments = 0
    let remainingTax = 0
    if (payment && payment.length) {
      payment.map((item) => {
        if (item.paymentCategory !== 'tax' && item.status !== 'notCleared') {
          let { taxAmount, installmentAmount } = item
          taxAmount = PaymentMethods.handleEmptyValue(taxAmount)
          installmentAmount = PaymentMethods.handleEmptyValue(installmentAmount)
          let itemPayment = 0
          let itemTax = 0
          if (item.taxIncluded) {
            itemPayment =
              installmentAmount - PaymentMethods.findTaxIncluded(installmentAmount, taxAmount)
          } else {
            itemTax = PaymentMethods.findTaxNotIncluded(installmentAmount, taxAmount)
            itemPayment = installmentAmount
          }
          totalPayments = totalPayments + itemPayment

          remainingTax = remainingTax + itemTax
        }
      })
      finalPrice = finalPrice - totalPayments
    }
    return { remainingPayment: finalPrice, remainingTax: remainingTax }
  },
  findRemainingTaxPaymentTab(payment, outStandingTax) {
    outStandingTax = PaymentMethods.handleEmptyValue(outStandingTax)
    let totalPayments = 0
    if (payment && payment.length) {
      payment.map((item) => {
        if (item.paymentCategory === 'tax' && item.status !== 'notCleared') {
          //changed check from notCleared to cleared as outstanding balance was showing minu values
          totalPayments = totalPayments + item.installmentAmount
        }
      })
      outStandingTax = outStandingTax - totalPayments
    }
    return outStandingTax
  },
  findRemainingTax(payment, outStandingTax) {
    outStandingTax = PaymentMethods.handleEmptyValue(outStandingTax)
    let totalPayments = 0
    if (payment && payment.length) {
      payment.map((item) => {
        if (
          item.paymentCategory === 'tax' &&
          item.status !== 'notCleared' &&
          item.receiptGenerated
        ) {
          totalPayments = totalPayments + item.installmentAmount
        }
      })
      outStandingTax = outStandingTax - totalPayments
    }

    return outStandingTax
  },
  findRemaningPaymentWithClearedStatus(payment, finalPrice) {
    finalPrice = PaymentMethods.handleEmptyValue(finalPrice)
    let totalPayments = 0
    let remainingTax = 0
    if (payment && payment.length) {
      payment.map((item) => {
        if (item.paymentCategory !== 'tax' && item.status === 'cleared') {
          let { taxAmount, installmentAmount } = item
          taxAmount = PaymentMethods.handleEmptyValue(taxAmount)
          installmentAmount = PaymentMethods.handleEmptyValue(installmentAmount)
          let itemPayment = 0
          let itemTax = 0
          if (item.taxIncluded) {
            itemPayment =
              installmentAmount - PaymentMethods.findTaxIncluded(installmentAmount, taxAmount)
          } else {
            itemTax = PaymentMethods.findTaxNotIncluded(installmentAmount, taxAmount)
            itemPayment = installmentAmount
          }
          totalPayments = totalPayments + itemPayment
          remainingTax = remainingTax + itemTax
        }
      })
      finalPrice = finalPrice - totalPayments
    }
    return { remainingPayment: finalPrice, remainingTax: remainingTax }
  },
  findRemainingTaxWithClearedStatus(payment, outStandingTax) {
    outStandingTax = PaymentMethods.handleEmptyValue(outStandingTax)
    let totalPayments = 0
    if (payment && payment.length) {
      payment.map((item) => {
        if (item.paymentCategory === 'tax' && item.status === 'cleared') {
          totalPayments = totalPayments + item.installmentAmount
        }
      })
      outStandingTax = outStandingTax - totalPayments
    }
    return outStandingTax
  },
  findPaymentClearedStatus(payment) {
    let checkCleared = false
    if (payment && payment.length) {
      payment.map((item) => {
        if (item.status === 'cleared') {
          checkCleared = true
        }
      })
    }
    return checkCleared
  },
  findDownPayment(unit) {
    let { project } = unit
    let { down_payment } = project

    let discountedPrice = PaymentMethods.findDiscountPrice(unit)
    let DownPayment = PaymentMethods.handleEmptyValue(down_payment)
    return (discountedPrice * DownPayment) / 100
  },

  findTotalInstallmentAmount(unit) {
    let discountedPrice = PaymentMethods.findDiscountPrice(unit)
    let downPayment = PaymentMethods.findDownPayment(unit)
    let possessionCharges = PaymentMethods.findPossessionCharges(unit)
    return discountedPrice - (downPayment + possessionCharges)
  },
  findPossessionCharges(unit) {
    let { project } = unit
    let discountedPrice = PaymentMethods.findDiscountPrice(unit)
    let { possession_charges } = project
    return (discountedPrice * possession_charges) / 100
  },
  findQuartelyInstallment(unit) {
    let { project } = unit
    let totalInstallmentAmount = PaymentMethods.findTotalInstallmentAmount(unit)
    let { installment_plan } = project
    return totalInstallmentAmount / (installment_plan * 4)
  },
  findMonthlyInstallment(unit) {
    let { project } = unit
    let totalInstallmentAmount = PaymentMethods.findTotalInstallmentAmount(unit)
    let { installment_plan } = project
    return totalInstallmentAmount / (installment_plan * 12)
  },
  findFullPaymentOptionPrice(unit) {
    let {
      project: { full_payment_discount },
    } = unit
    let discountedPrice = PaymentMethods.findDiscountPrice(unit)
    return (discountedPrice * (100 - full_payment_discount)) / 100
  },
  findOutstandingTax(allPayment) {
    let outStandingTax = 0
    allPayment &&
      allPayment.map((item) => {
        if (item.status !== 'notCleared') {
          let discountedPrice = (item.installmentAmount / 100) * item.taxAmount
          outStandingTax += discountedPrice
        }
      })

    return outStandingTax
  },
  findUnitPriceWithoutUnit(data) {
    let { floor, pearlInput } = data
    let { pricePerSqFt } = floor.item
    let unitPrice = pricePerSqFt * parseInt(pearlInput, 10)
    return unitPrice
  },
  findPearlUnitPrice(data) {
    const { floor, unit } = data
    if (floor && unit) {
      let { area } = unit
      let { pricePerSqFt } = floor
      area = PaymentMethods.handleEmptyValue(area)
      pricePerSqFt = PaymentMethods.handleEmptyValue(pricePerSqFt)
      return area * pricePerSqFt
    } else {
      return 0
    }
  },
  findPearlApprovedDiscountAmount(unitPrice, discount) {
    if (unitPrice && discount) {
      discount = PaymentMethods.handleEmptyValue(discount)
      return (unitPrice * discount) / 100
    } else return 0
  },
}
module.exports = PaymentMethods
