/** @format */
import _ from 'lodash'

export const MatchPropertiesTemplate = () => {
  return [
    {
      component: 'LABEL',
      props: {
        label: ' ',
      },
      tdClassName: 'padding-7-left',
    },

    {
      component: 'LABEL',
      props: {
        label: 'Images',
      },
    },
    {
      component: 'LABEL',
      props: {
        label: 'Title',
      },
    },
    {
      component: 'LABEL',
      props: {
        label: 'Area',
      },
    },
    {
      component: 'LABEL',
      props: {
        label: 'Listing Owner',
      },
    },
    {
      component: 'LABEL',
      props: {
        label: 'POC',
      },
    },
    {
      component: 'LABEL',
      props: {
        label: 'Advisor',
      },
    },
    {
      component: 'LABEL',
      props: {
        label: 'Property Type',
      },
    },
    {
      component: 'LABEL',
      props: {
        label: 'Sub Type',
      },
    },
    {
      component: 'LABEL',
      props: {
        label: 'Purpose',
      },
    },
    {
      component: 'LABEL',
      props: {
        label: 'Size/Unit',
      },
    },
    {
      component: 'LABEL',
      props: {
        label: 'Price',
      },
    },
    {
      component: 'LABEL',
      props: {
        label: 'Beds',
      },
    },
    {
      component: 'LABEL',
      props: {
        label: 'Baths',
      },
    },
  ]
}

export const MatchPropertiesRowTemplate = (
  handleAction,
  getValue,
  buyRentShortListProperties,
  matchProperties,
  selectedRow
) => {
  const constructProp = (templateProperty, cellIndex, rowIndex, rowData, columnName) =>
    getValue(templateProperty, cellIndex, rowIndex, rowData, columnName)
  return [
    {
      component: 'CHECKBOX',
      props: {
        onClick: handleAction,
        label: 'id',
        armsuser: 'armsuser',
        tdClassName: 'text-center',
        matchProperties,
        buyRentShortListProperties,
        selectedRow,
      },
      tdClassName: 'text-center',
      ignorePropConstruct: ['onClick'],
    },
    {
      component: 'IMAGESLIDER',
      props: {
        label: 'armsPropertyImages',
        graanaImages: 'property_images',
      },
    },
    {
      component: 'LABEL',
      props: {
        label: 'custom_title',
      },
    },
    {
      component: 'LABEL',
      props: {
        label: 'area.name',
      },
    },
    {
      component: 'LABEL',
      props: {
        label: 'owner_name',
      },
      notFoundTemplate: { label: '--' },
    },
    {
      component: 'LABEL',
      props: {
        label: 'poc_name',
      },
      notFoundTemplate: { label: '--' },
    },
    {
      component: 'LABEL',
      props: {
        label: constructProp,
        seperator: ' ',
      },
      notFoundTemplate: { label: '-' },
    },
    {
      component: 'LABEL',
      props: {
        label: 'type',
        type: 'capitalizeWordsWithoutUnderscore',
      },
      notFoundTemplate: { label: '--' },
    },
    {
      component: 'LABEL',
      props: {
        label: 'subtype',
        type: 'capitalizeWordsWithoutUnderscore',
      },
      notFoundTemplate: { label: '--' },
    },
    {
      component: 'LABEL',
      props: {
        label: 'purpose',
        type: 'capitalizeWordsWithoutUnderscore',
      },
      notFoundTemplate: { label: '--' },
    },
    {
      component: 'LABEL',
      props: {
        label: ['size', 'size_unit'],
        seperator: ' ',
      },
      notFoundTemplate: { label: '--' },
    },
    {
      component: 'LABEL',
      props: {
        label: 'price',
        type: 'priceMasking',
      },
      notFoundTemplate: { label: '--' },
    },
    {
      component: 'LABEL',
      props: {
        label: 'bed',
      },
      notFoundTemplate: { label: '--' },
    },
    {
      component: 'LABEL',
      props: {
        label: 'bath',
      },
      notFoundTemplate: { label: '--' },
    },
  ]
}

export const MatchPropertiesCellWidth = () => {
  return [
    '67px',
    '80px',
    '150px',
    '150px',
    '150px',
    '150px',
    '150px',
    '150px',
    '180px',
    '120px',
    '150px',
    '150px',
    '150px',
    '150px',
  ]
}
