import React from 'react'
import { Modal, ModalBody } from 'reactstrap'
import Button from '../../common/Button'

const InvestLeadForm = (props) => {

  return (
    <>
      <Modal
        isOpen={props.investLeadModal}
        toggle={(toggle) => props.setInvestLeadModal(!toggle)}
        contentClassName="modal-action-size"
        className="modal-action-size"
      >
        <ModalBody>
          <h4>Create Invest Lead</h4>
          <div className="close-icon-container">
            <i
              className="fal fa-times-circle close-icon"
              onClick={() => props.setInvestLeadModal(false)}
            ></i>
          </div>

          <div className={"row mt-3 justify-content-center mb-4"}>
            <div className={"col-sm-11 mt-5 mb-3"}>
              <div
                className="task-button btn-sm d-flex justify-content-center mt-3 mb-4"
              >
                <Button
                  text={"Create lead & close this lead"}
                  buttonColor={"#9fc5f8"}
                  className={"px-3"}
                  style={{
                    border:'1px solid black'
                  }}

                />
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>

    </>
  )
}
export default InvestLeadForm
