/** @format */

import React, { Component, Fragment, useMemo } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Select from "react-select";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { normalizeCnic } from "../../utils/formatCnic";
import { removeZero } from "../../utils/validate";
import DatePicker from "../../components/common/DatePicker/datePicker";
import Button from "../../components/common/Button";
import AddLead from "./AddLead";
import {
  PermissionFeatures,
  PermissionActions,
} from "../../utils/permissionConstants";
import { getPermissionValue } from "../../components/_unlockComponent";
import { StaticData } from "../../StaticData";
import { setLeadType, setLeadClientError } from "../../actions/user";
import { leadType } from "../../utils/constant_data";
import { isAira } from "../../components/common/LeadView/Wanted/WantedUtilHelper";

import "./style.scss";

class Inner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maxIndex: "",
      requiredField: false,
      soiOther: false,
      sofOther: false,

      handlerClick : false,

      // leadOption: { label: 'Select Lead Type', value: 'selectleadtype' },
      leadOption: "",
      isBuyRent: getPermissionValue(
        PermissionFeatures.BUY_RENT_LEADS,
        PermissionActions.CREATE
      ),
      isProjectLeads: getPermissionValue(
        PermissionFeatures.PROJECT_LEADS,
        PermissionActions.CREATE
      ),
      selectedLeadType: null,
    };
    this.textInput = React.createRef();
  }

  componentDidUpdate(prevProps) {
    const {handlerClick} = this.state

    if (prevProps.otherSourceOfIncome !== this.props.otherSourceOfIncome) {
      !handlerClick &&  this.setState({ soiOther: true});
  }
    if (prevProps.sourceOfFunds !== this.props.sourceOfFunds) {
      !handlerClick && this.setState({ sofOther: true });
    }
  }

  componentDidMount = () => {
    this.setLeadOptions();
    if (this.props.otherSourceOfIncome) {
      this.setState({ soiOther: true});
   }
  };

  setLeadOptions = () => {
    let types = [];
    let isBuyRent = getPermissionValue(
      PermissionFeatures.BUY_RENT_LEADS,
      PermissionActions.CREATE
    );
    let isWantedLeads = getPermissionValue(
      PermissionFeatures.WANTED_LEADS,
      PermissionActions.CREATE
    );
    let isProjectLeads = getPermissionValue(
      PermissionFeatures.PROJECT_LEADS,
      PermissionActions.CREATE
    );
    if (isAira()) {
      types.push(
        { label: "Sell", value: "sell" },
        { label: "Rent Out", value: "rentOut" }
      );
    }
    if (isBuyRent) {
      types.push(
        { label: "Rent", value: "rent" },
        { label: "Buy", value: "buy" }
      );
    }
    if (isProjectLeads) {
      types.push({ label: "Invest", value: "project" });
    }
    this.setState({ leadOption: [...types] });
    // {label:'Invest',value:'project'},{label:'Rent',value:'rent'},{label:'Buy',value:'buy'}
  };

  checkLeadType = (leadType) => {
    if (leadType === "selectleadtype") {
      this.setLeadClientError(false);
    }
    this.props.setLeadType(leadType);
    this.setState({ selectedLeadType: leadType });
  };
  checkRequiredField = () => {
    const { formData } = this.props;
    if (
      (formData.bank && formData.bank !== "") ||
      (formData.iBan && formData.iBan !== "") ||
      (formData.accountTitle && formData.accountTitle !== "")
    ) {
      return true;
    } else return false;
  };

  arrayToOptions = (array) => {
    return (
      array &&
      array.map((item) => {
        return {
          label: item,
          value: item,
        };
      })
    );
  };
  getAlreadySelectedData = (selectedValue) => {
    // let alreadySelectedData = []
    if (selectedValue && selectedValue != "") {
      return [{ label: selectedValue, value: selectedValue }];
    } else return [];
  };

  checkData = (finderParam, dataArray) => {
    let returner = false;

    if(dataArray){
      dataArray.filter((data) => {
        if (data === finderParam) {
          returner = true;
  }
      });
    }
    return returner;
  };

  render() {
    const { leadOption, selectedLeadType, soiOther, sofOther } = this.state;
    const {
      formData,
      handleChange,
      handleChangePhone,
      handleChangeContact1,
      handleChangeContact2,
      handleChangeKin,
      phone,
      createClient,
      contact1,
      kinContact,
      contact2,
      validationCheck,
      loadingOnsubmit,
      cnicValidation,
      phoneValidate,
      phoneValidate1,
      phoneValidate2,
      kinPhoneValidate,
      editAble,
      emailValidate,
      fromPage,
      handleDob,
      clientModalToggle,
      requiredField,
      isModal,
      isBookingFor,
      showAdditionInfo,
      SourceOfIncomehandle,
      SourceOfFundsHandler,
      otherSourceOfFundsHandler,
      otherSourceOfIncomeHandler,
      otherSourceOfIncome,

      AssociationWithanypoliticalpartyHandler,
      AssociationWithanypoliticalparty,
      ExistingImaratClientHandler,
      OtherProjectsInvHandler,
      ExistingImaratClient,
      OtherProjectsInv,
      RegisterWithFBRHandler,
      RegisterWithFBR,
      soi,
      sof,
      sourceOfFunds,
      otherSourceOfIncomeMakeEmpty
    } = this.props;
    const className = isModal ? "col-md-6" : "col-md-4";
    const classNameAddress = isModal ? "col-md-12" : "col-md-8";
    const relationShipStatus = ["Son of", "Daughter of", "Wife of"];
    const countries = [
      "Afghanistan",
      "Albania",
      "Algeria",
      "Andorra",
      "Angola",
      "Anguilla",
      "Antigua &amp; Barbuda",
      "Argentina",
      "Armenia",
      "Aruba",
      "Australia",
      "Austria",
      "Azerbaijan",
      "Bahamas",
      "Bahrain",
      "Bangladesh",
      "Barbados",
      "Belarus",
      "Belgium",
      "Belize",
      "Benin",
      "Bermuda",
      "Bhutan",
      "Bolivia",
      "Bosnia &amp; Herzegovina",
      "Botswana",
      "Brazil",
      "British Virgin Islands",
      "Brunei",
      "Bulgaria",
      "Burkina Faso",
      "Burundi",
      "Cambodia",
      "Cameroon",
      "Cape Verde",
      "Cayman Islands",
      "Chad",
      "Chile",
      "China",
      "Colombia",
      "Congo",
      "Cook Islands",
      "Costa Rica",
      "Cote D Ivoire",
      "Croatia",
      "Cruise Ship",
      "Cuba",
      "Cyprus",
      "Czech Republic",
      "Denmark",
      "Djibouti",
      "Dominica",
      "Dominican Republic",
      "Ecuador",
      "Egypt",
      "El Salvador",
      "Equatorial Guinea",
      "Estonia",
      "Ethiopia",
      "Falkland Islands",
      "Faroe Islands",
      "Fiji",
      "Finland",
      "France",
      "French Polynesia",
      "French West Indies",
      "Gabon",
      "Gambia",
      "Georgia",
      "Germany",
      "Ghana",
      "Gibraltar",
      "Greece",
      "Greenland",
      "Grenada",
      "Guam",
      "Guatemala",
      "Guernsey",
      "Guinea",
      "Guinea Bissau",
      "Guyana",
      "Haiti",
      "Honduras",
      "Hong Kong",
      "Hungary",
      "Iceland",
      "India",
      "Indonesia",
      "Iran",
      "Iraq",
      "Ireland",
      "Isle of Man",
      "Israel",
      "Italy",
      "Jamaica",
      "Japan",
      "Jersey",
      "Jordan",
      "Kazakhstan",
      "Kenya",
      "Kuwait",
      "Kyrgyz Republic",
      "Laos",
      "Latvia",
      "Lebanon",
      "Lesotho",
      "Liberia",
      "Libya",
      "Liechtenstein",
      "Lithuania",
      "Luxembourg",
      "Macau",
      "Macedonia",
      "Madagascar",
      "Malawi",
      "Malaysia",
      "Maldives",
      "Mali",
      "Malta",
      "Mauritania",
      "Mauritius",
      "Mexico",
      "Moldova",
      "Monaco",
      "Mongolia",
      "Montenegro",
      "Montserrat",
      "Morocco",
      "Mozambique",
      "Namibia",
      "Nepal",
      "Netherlands",
      "Netherlands Antilles",
      "New Caledonia",
      "New Zealand",
      "Nicaragua",
      "Niger",
      "Nigeria",
      "Norway",
      "Oman",
      "Pakistan",
      "Palestine",
      "Panama",
      "Papua New Guinea",
      "Paraguay",
      "Peru",
      "Philippines",
      "Poland",
      "Portugal",
      "Puerto Rico",
      "Qatar",
      "Reunion",
      "Romania",
      "Russia",
      "Rwanda",
      "Saint Pierre &amp; Miquelon",
      "Samoa",
      "San Marino",
      "Satellite",
      "Saudi Arabia",
      "Senegal",
      "Serbia",
      "Seychelles",
      "Sierra Leone",
      "Singapore",
      "Slovakia",
      "Slovenia",
      "South Africa",
      "South Korea",
      "Spain",
      "Sri Lanka",
      "St Kitts &amp; Nevis",
      "St Lucia",
      "St Vincent",
      "St. Lucia",
      "Sudan",
      "Suriname",
      "Swaziland",
      "Sweden",
      "Switzerland",
      "Syria",
      "Taiwan",
      "Tajikistan",
      "Tanzania",
      "Thailand",
      "Timor L'Este",
      "Togo",
      "Tonga",
      "Trinidad &amp; Tobago",
      "Tunisia",
      "Turkey",
      "Turkmenistan",
      "Turks &amp; Caicos",
      "Uganda",
      "Ukraine",
      "United Arab Emirates",
      "United Kingdom",
      "Uruguay",
      "Uzbekistan",
      "Venezuela",
      "Vietnam",
      "Virgin Islands (US)",
      "Yemen",
      "Zambia",
      "Zimbabwe",
    ];
    const province = [
      "Punjab",
      "Balochistan",
      "Islamabad Capital Territory",
      "Khyber Pakhtunkhwa",
      "Sindh",
    ];
    const { maxIndex } = this.state;
    if (
      !formData.mCountry ||
      formData.mCountry === "" ||
      formData.mCountry === null
    ) {
      formData.mCountry = "Pakistan";
    }
    if (
      !formData.country ||
      formData.country === "" ||
      formData.country === null
    ) {
      formData.country = "Pakistan";
    }

    const checkClient = () => {
      const { userInfo } = this.props;
      const client = this.props.formData;

      if (!client) return "";
      if (!client.originalOwner) {
        if (
          client &&
          client.assigned_to_armsuser_id &&
          client.assigned_to_armsuser_id === userInfo &&
          userInfo.id
        )
          return "Personal Client";
        else
          return client.assigned_to_organization
            ? client.assigned_to_organization
            : "";
      } else {
        if (client && client.originalOwner.id === userInfo && userInfo.id)
          return "Personal Client";
        else {
          if (client && client.originalOwner.organization)
            return client.originalOwner.organization.name;
          else
            return (
              client.originalOwner.firstName +
              " " +
              client.originalOwner.lastName
            );
        }
      }
    };
    const onDateChangeHandler = (value, key) => {
      if (value) formData[key] = value;
      // setLeaves({...leaves , startDate:value});
    };

    const urlParams = new URLSearchParams(window.location.search)
    return (
      <Fragment>
        <div className={"client-creation-content"}>
          <div className={"clientMainContainer container-fluid rounded"}>
            <label className="headers"> </label>
            <div className={"basicInfoContainer row"}>
              <div className={className}>
                <label>First Name </label>
                <span className={`text-danger`}> *</span>
                <div className="filterInputWrap">
                  <input
                    type="text"
                    className={
                      validationCheck === true && formData.firstName === ""
                        ? `form-control invalid`
                        : `form-control`
                    }
                    placeholder={"Enter First Name"}
                    name={"firstName"}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    value={formData.firstName}
                    required
                  />
                  {validationCheck === true && formData.firstName === "" && (
                    <p className={"requiredCheck"}>Required</p>
                  )}
                </div>
              </div>
              <div className={className}>
                <label>Last Name </label>
                <span className={`text-danger`}> *</span>
                <div className="filterInputWrap">
                  <input
                    type="text"
                    className={
                      validationCheck === true && formData.lastName === ""
                        ? `form-control invalid`
                        : `form-control`
                    }
                    placeholder={"Enter Last Name"}
                    name={"lastName"}
                    required
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    value={formData.lastName || ""}
                  />
                  {validationCheck === true && formData.lastName === "" && (
                    <p className={"requiredCheck"}>Required</p>
                  )}
                </div>
              </div>
              <div className={className}>
                <label>Phone #</label>
                <span className={`text-danger`}> *</span>
                <div
                  className={`filterInputMain`}
                  onClick={() => {
                    this.setState({ maxIndex: 1 });
                  }}
                >
                  {this.props && this.props.checkPurchaserPage ? (
                    <div
                      className={`filterInputWrap clientForm ${
                        maxIndex === 1 ? "max-index" : ""
                      }`}
                    >
                      <PhoneInput
                        placeholder="Enter phone number"
                        country={"pk"}
                        inputProps={{
                          disabled:
                            editAble &&
                            formData.clientSource !== "Personal Client"
                              ? true
                              : false,
                          name: "contact1",
                        }}
                        value={
                          contact1 && contact1.contact1 !== ""
                            ? removeZero(phone.phone || "",phone.dialCode)
                            : ""
                        }
                        inputClass={"form-control"}
                        onChange={handleChangePhone}
                        countryCodeEditable={false}
                      />
                      {phoneValidate1 === true && (
                        <p className={"requiredCheck"}>
                          Enter Correct Phone Number
                        </p>
                      )}
                    </div>
                  ) : (
                    <div
                      className={`filterInputWrap ${
                        maxIndex === 1 ? "max-index" : ""
                      }`}
                    >
                      <PhoneInput
                        placeholder="Enter phone number"
                        country={"pk"}
                        inputProps={{
                          disabled:
                            editAble &&
                            formData.clientSource !== "Personal Client"
                              ? true
                              : false,
                          name: "phone",
                        }}
                        enableLongNumbers={true}
                        value={
                          phone && phone.phone !== ""
                            ? removeZero(phone.phone || "",phone.dialCode)
                            : `${contact1.dialCode}${contact1.contact1}`
                        }
                        inputClass={
                          (validationCheck === true && phone.phone === "") ||
                          (validationCheck === true &&
                            phone.phone.length < 2) ||
                          phoneValidate === true
                            ? `MoreFilterinputText invalid`
                            : ` MoreFilterinputText`
                        }
                        onChange={ handleChangePhone}
                        disabled={
                          (this.props.match.path !== "/available-inventory" &&
                            this.props.match.path !== "/add-client" &&
                            this.props.match.path !== "/new-lead" &&
                            this.props.match.path !== "/leadDetails/:leadId" &&
                            this.props.match.path !== "/add-buy-rent-lead" &&
                            this.props.match.path !==
                              "/edit-client/:clientId") ||
                          (editAble &&
                            formData.clientSource !== "Personal Client")
                        }
                        countryCodeEditable={false}
                      />
                      {validationCheck === true && phone.phone === "" ? (
                        <p className={"requiredCheck"}>Required</p>
                      ) : validationCheck === true && phone.phone.length < 2 ? (
                        <p className={"requiredCheck"}>Required</p>
                      ) : (
                        ""
                      )}
                      {phoneValidate === true && (
                        <p className={"requiredCheck"}>
                          Enter Correct Phone Number
                        </p>
                      )}
                    </div>
                  )}
                </div>
              </div>

              <div className={className}>
                <label>Client Source</label>
                <div className="clientDateContainer mb-3">
                  <Select
                    closeMenuOnSelect={true}
                    key={Math.random(10)}
                    className={"select-control"}
                    enableLongNumbers={true}
                    defaultValue={
                      formData.clientSource != "" &&
                      formData.clientSource != null
                        ? {
                            label: formData.clientSource,
                            value: formData.clientSource,
                          }
                        : {
                            label: `${formData.orgName}`,
                            value: `${formData.orgId}`,
                          }
                    }
                    isDisabled={editAble ? true : false}
                    options={this.arrayToOptions(StaticData.clientSource)}
                    onChange={(e) => {
                      handleChange(e, "clientSource");
                    }}
                    placeholder="Client Source"
                  />
                </div>
              </div>
              <div className={className}>
                <label>Client Type</label>
                <div className="clientDateContainer mb-3">
                  <Select
                    closeMenuOnSelect={true}
                    key={Math.random(10)}
                    className={"select-control"}
                    defaultValue={
                      formData.clientType != "" && formData.clientType != null
                        ? {
                            label: formData.clientType,
                            value: formData.clientType,
                          }
                        : {
                            label: `${formData.orgName}`,
                            value: `${formData.orgId}`,
                          }
                    }
                    isDisabled={editAble ? true : false}
                    options={this.arrayToOptions(StaticData.clientType)}
                    onChange={(e) => {
                      handleChange(e, "clientType");
                      this.textInput.current.state.value = {
                        label: "Select Lead type",
                        value: "",
                      };
                    }}
                    placeholder="Client Type"
                  />
                </div>
              </div>
              {!editAble && (
              <div className={className}>
                <label>Lead Type</label>
                  <span className={`text-danger`}> *</span>
                <div className="clientDateContainer mb-3">
                    <Select
                    type="text"
                    ref={this.textInput}
                    className={
                        validationCheck === true &&
                        (selectedLeadType === null || selectedLeadType === "")
                        ? `select-control invalidSelect`
                        : `select-control`
                    }
                      placeholder={"Select Lead Type"}
                      name={"leadType"}
                    onChange={(e) => {
                        this.checkLeadType(e.value);
                    }}
                      styles={
                        validationCheck === true &&
                        (selectedLeadType === null ||
                          selectedLeadType === "") && {
                          control: (styles) => ({
                            ...styles,
                            borderColor: "red",
                          }),
                        }
                      }
                    options={leadOption}
                    // value={selectedLeadType}
                    required
                  />
                    {validationCheck === true &&
                      (selectedLeadType === null ||
                        selectedLeadType === "") && (
                        <p className={"requiredCheck"}>Required</p>
                  )}
                </div>
              </div>
              )}
              </div>
          </div>
          <div>
            <div id="accordion" className={"client-accordin"}>
              <div class="card" style={{ marginTop: "20px" }}>
                <div class="card-header">
                  <div className="accordionHeader">
                    <p
                      class="panel-title"
                      data-toggle="collapse"
                      data-target="#collapseOne"
                      aria-expanded={showAdditionInfo || editAble ? true : ""}
                    >
                      Additional Information
                    </p>
                  </div>
                </div>
                <div
                  id="collapseOne"
                  class={` collapse ${
                    showAdditionInfo || editAble ? "show" : ""
                  }`}
                  data-parent="#accordion"
                >
                  <div class="card-body client-body">
                    <div className={"basicInfoContainer row"}>
                      <div className={className}>
                        <label>Phone #2</label>
                        <div
                          className={`filterInputMain`}
                          onClick={() => {
                            this.setState({ maxIndex: 2 });
                          }}
                        >
                          <div
                            className={`filterInputWrap ${
                              maxIndex === 2 ? "max-index" : ""
                            }`}
                          >
                            <PhoneInput
                              placeholder="Enter phone number"
                              enableLongNumbers={true}
                              country={"pk"}
                              inputProps={{
                                name: "contact1",
                                // required: true,
                                // autoFocus: true
                              }}
                              value={
                                contact1 && contact1.contact1 !== ""
                                  ? removeZero(contact1.contact1,contact1.dialCode)
                                  : ""
                              }
                              inputClass={"form-control"}
                              onChange={handleChangeContact1}
                              countryCodeEditable={false}
                            />
                            {phoneValidate1 === true && (
                              <p className={"requiredCheck"}>
                                Enter Correct Phone Number
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className={className}>
                        <label>Phone #3</label>
                        <div
                          className={`filterInputMain`}
                          onClick={() => {
                            this.setState({ maxIndex: 3 });
                          }}
                        >
                          <div
                            className={`filterInputWrap ${
                              maxIndex === 3 ? "max-index" : ""
                            }`}
                          >
                            <PhoneInput
                              placeholder="Enter phone number"
                              enableLongNumbers={true}
                              country={"pk"}
                              inputProps={{
                                name: "contact2",
                                // required: true,
                                // autoFocus: true
                              }}
                              value={
                                contact2 && contact2.contact2 !== ""
                                  ? removeZero(contact2.contact2,contact2.dialCode)
                                  : ""
                              }
                              inputClass={"form-control"}
                              onChange={handleChangeContact2}
                              countryCodeEditable={false}
                            />
                            {phoneValidate2 === true && (
                              <p className={"requiredCheck"}>
                                Enter Correct Phone Number
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={"basicInfoContainer row"}>
                      <div className={className}>
                        <label>S/O,D/O,W/O</label>
                        <div className="filterInputWrap">
                          <Select
                            closeMenuOnSelect={true}
                            key={Math.random(10)}
                            className={"select-control"}
                            defaultValue={
                              formData.relationStatus != "" &&
                              formData.relationStatus != null && {
                                label: formData.relationStatus,
                                value: formData.relationStatus,
                              }
                            }
                            options={this.arrayToOptions(relationShipStatus)}
                            onChange={(e) => {
                              handleChange(e, "relationStatus");
                            }}
                            placeholder={"(S/O,D/O,W/O)"}
                          />
                        </div>
                      </div>
                      <div className={className}>
                        <label>
                          {formData.relationStatus === ""
                            ? "Son of"
                            : formData.relationStatus}
                        </label>
                        <div className="filterInputWrap">
                          <input
                            type="text"
                            className={"form-control"}
                            placeholder={"Enter Name"}
                            name={"relativeName"}
                            required
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            value={formData.relativeName || ""}
                          />
                        </div>
                      </div>
                    </div>

                    <div className={'basicInfoContainer row'}>
                      <div className={className}>
                        <label>Email</label>
                        <div className={`filterInputMain`}>
                          <div className="filterInputWrap">
                            <input
                              type="email"
                              className={'form-control'}
                              placeholder={'Email'}
                              name={'email'}
                              required
                              onChange={(e) => {
                                handleChange(e)
                              }}
                              value={formData.email}
                            />
                            {emailValidate === true && formData.email != '' && (
                              <p className={'requiredCheck'}>Email is not valid</p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className={className}>
                        <label>Gender</label>
                        <div className="clientDateContainer mb-3">
                          <Select
                            closeMenuOnSelect={true}
                            key={Math.random(10)}
                            className={'select-control'}
                            defaultValue={
                              formData.gender != '' &&
                              formData.gender != null && {
                                label: formData.gender,
                                value: formData.gender,
                              }
                            }
                            options={this.arrayToOptions(StaticData.gender)}
                            name={'gender'}
                            onChange={(e) => {
                              handleChange(e, 'gender')
                            }}
                            placeholder="Gender"
                          />
                        </div>
                      </div>
                      <div className={className}>
                        <label>Date of Birth</label>
                        <span className={`text-danger`}> *</span>
                        <div className= "clientDateContainer">
                          <DatePicker
                            calenderId={'dateOfBirth'}
                            maxDate={new Date()}
                            minDate={new Date("1900")}
                            style={{ margin: 0, padding: "4px 12px", border: ((!!urlParams.get('ci') && (formData.dob === null)) ? "1px solid red" : "1px solid #eaeaea") }}
                            alreadySelectedDate={
                              formData.dob ? formData.dob : null
                            }
                            onDateChangeHandler={(value) =>
                              onDateChangeHandler(value, "dob")
                            }
                          />
                          {!!urlParams.get('ci') && (formData.dob === "" || formData.dob === null) && (
                            <p className={"requiredCheck"}>Required</p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className={'basicInfoContainer row'}>
                      <div className={className}>
                        <label>CNIC/NTN</label>
                        {(formData.clientType === "Dealer" || urlParams.get('ci')) && (
                          <span className={`text-danger`}> *</span>
                        )}
                        {this.props && this.props.checkPurchaserPage ? (
                          <div className={`filterInputMain`}>
                            <div className="filterInputWrap">
                              <input
                                type="text"
                                className={
                                  validationCheck === true &&
                                  (formData.cnic === "" ||
                                    formData.cnic.length < 13)
                                    ? `form-control invalid`
                                    : `form-control`
                                }
                                placeholder={'CNIC/NTN'}
                                name={'cnic'}
                                required
                                onChange={(e) => {
                                  handleChange(e)
                                }}
                                value={normalizeCnic(formData.cnic)}
                              />

                              {cnicValidation === true &&
                              ((formData.cnic &&
                                formData.cnic !== "" &&
                                formData.cnic.length) ||
                                formData.cnic.length === 7 ||
                                formData.cnic.length === 8 ||
                                formData.cnic.length >= 13) ? (
                                <p className={"requiredCheck"}>
                                  Invalid CNIC/NTN format
                                </p>
                              ) : (
                                ""
                              )}

                            {editAble === true &&
                                (formData.cnic === "" || formData.cnic === null) && (
                                  <p className={"requiredCheck"}>Required</p>
                              )}
                            </div>
                          </div>
                        ) : (
                          <div className={`filterInputMain`}>
                            <div className="filterInputWrap">
                              <input
                                type="text"
                                className={
                                  (formData.clientType === "Dealer" || urlParams.get('ci')) &&
                                  (editAble === true && (formData.cnic === "" || formData.cnic === null)) ? "invalid form-control" : "form-control"

                                }
                                placeholder={"CNIC/NTN"}
                                name={"cnic"}
                                required={formData.clientType === "Dealer"}
                                onChange={(e) => {
                                  handleChange(e)
                                }}
                                value={normalizeCnic(formData.cnic)}
                              />
                              {formData.clientType === "Dealer" ? (
                                <>
                                  {validationCheck === true &&
                                    formData.cnic === "" && (
                                      <p className={"requiredCheck"}>
                                        Required
                                      </p>
                                    )}
                              {cnicValidation === true &&
                              ((formData.cnic &&
                                    formData.cnic !== "" &&
                                    formData.cnic.length) ||
                                    formData.cnic.length === 7 ||
                                formData.cnic.length === 8 ||
                                    formData.cnic.length >= 13) ? (
                                    <p className={"requiredCheck"}>
                                      Invalid CNIC/NTN format
                                    </p>
                              ) : (
                                    ""
                                  )}
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                      <div className={className}>
                        <label>CNIC Expiry</label>
                        <div className="clientDateContainer">
                          <DatePicker
                            calenderId={"cnicExpiry"}
                            style={{ margin: 0, padding: "4px 12px" }}
                            alreadySelectedDate={
                              formData.cnicExpiry ? formData.cnicExpiry : null
                            }
                            onDateChangeHandler={(value) =>
                              onDateChangeHandler(value, "cnicExpiry")
                            }
                          />
                        </div>
                      </div>
                      <div className={className}>
                        <label>Name on CNIC</label>
                        <div className="filterInputWrap">
                          <input
                            type="text"
                            className={'form-control'}
                            placeholder={'Enter Name On CNIC'}
                            name={'nameOnCnic'}
                            onChange={(e) => {
                              handleChange(e)
                            }}
                            value={formData.nameOnCnic}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className={"basicInfoContainer row"}>
                      <div className={className}>
                        <label>Profile Pic</label>
                        <div className="filterInputWrap">
                        <input
                            name="profilePic"
                            id="abc"
                            style={{
                              padding: 0,
                              color: "black",
                              width: '100%',
                              height: '100%',
                              cursor: 'pointer',
                            }}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            accept="image/png, image/jpeg"
                            type="file"
                            className="form-control attachment--input"
                          />
                          {/* {formData.profilePic && !formData.profilePic.name && */}
                          {typeof(formData.profilePic) == 'string' &&
                          <>
                            Uploaded
                            <a target={"_blank"} href={formData.profilePic} style={{marginLeft: "6px", fontSize: "smaller"}}>(VIEW)</a>
                          </>
                          }
                        </div>
                      </div>
                      <div className={className}>
                        <label>CNIC Front</label>
                        <span className={`text-danger`}> *</span>
                        <div className="filterInputWrap">
                        <input
                            name="cnicFront"
                            id="abc"
                            style={{
                              padding: 0,
                              color: "black",
                              width: '100%',
                              height: '100%',
                              cursor: 'pointer',
                            }}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            accept="image/png, image/jpeg"
                            type="file"
                            className={(!!urlParams.get('ci') && (formData.cnicFront === "" || formData.cnicFront === null)) ? "invalid form-control attachment--input" : "form-control attachment--input"}
                          />
                          {!!urlParams.get('ci') && (formData.cnicFront === "" || formData.cnicFront === null) && (
                            <p className={"requiredCheck"}>Required</p>
                          )}
                          {formData.cnicFront && !formData.cnicFront.name &&
                          <>
                            Uploaded
                            <a target={"_blank"} href={formData.cnicFront} style={{marginLeft: "6px", fontSize: "smaller"}}>(VIEW)</a>
                          </>
                          }
                        </div>
                      </div>
                      <div className={className}>
                        <label>CNIC Back</label>
                        <span className={`text-danger`}> *</span>
                        <div className="filterInputWrap">
                        <input
                            name="cnicBack"
                            id="abc"
                            style={{
                              padding: 0,
                              color: "black",
                              width: '100%',
                              height: '100%',
                              cursor: 'pointer',
                            }}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            accept="image/png, image/jpeg"
                            type="file"
                            className={(!!urlParams.get('ci') && (formData.cnicBack === "" || formData.cnicBack === null)) ? "invalid form-control attachment--input" : "form-control attachment--input"}
                          />
                          {!!urlParams.get('ci') && (formData.cnicBack === "" || formData.cnicBack === null) && (
                            <p className={"requiredCheck"}>Required</p>
                          )}
                          {formData.cnicBack && !formData.cnicBack.name &&
                          <>
                            Uploaded
                            <a target={"_blank"} href={formData.cnicBack} style={{marginLeft: "6px", fontSize: "smaller"}}>(VIEW)</a>
                          </>
                          }
                        </div>
                      </div>
                    </div>
                    <div className={"basicInfoContainer row"}>
                      <div className={className}>
                        <label>Passport</label>
                        <div className="filterInputWrap">
                          <input
                            type="text"
                            className={'form-control'}
                            placeholder={'Enter Passport Number'}
                            name={'passport'}
                            onChange={(e) => {
                              handleChange(e)
                            }}
                            value={formData.passport}
                            required
                          />
                        </div>
                      </div>
                      <div className={className}>
                        <label>Nationality</label>
                        <div className="filterInputWrap">
                          <input
                            type="text"
                            className={'form-control'}
                            placeholder={'Enter Nationality'}
                            name={'nationality'}
                            onChange={(e) => {
                              handleChange(e)
                            }}
                            value={formData.nationality}
                            required
                          />
                        </div>
                      </div>
                      <div className={className}>
                        <label>Profession</label>
                        <span className="text-danger">*</span>
                        <div className="filterInputWrap">
                          <input
                            type="text"
                            className={
                              (!!urlParams.get('ci') && (formData.profession === "" || formData.profession === null)) ? "invalid form-control" : "form-control"
                            }

                            placeholder={"Enter Profession"}
                            name={"profession"}

                            onChange={(e) => {
                              handleChange(e)
                            }}
                            value={formData.profession}
                            required
                          />
                          {!!urlParams.get('ci') && (formData.profession === "" || formData.profession === null) && (
                            <p className={"requiredCheck"}>Required</p>
                          )}
                        </div>
                      </div>
                    </div>
                    <br />
                    <label className="headers">Mailing Address</label>

                    <div className={'mailAddressContainer row'}>
                      <div className={className}>
                        <label>Country</label>
                        <div className="filterInputWrap">
                          <Select
                            name={'mCountry'}
                            key={Math.random(10)}
                            closeMenuOnSelect={true}
                            className={'select-control'}
                            defaultValue={
                              formData.mCountry != '' &&
                              formData.mCountry != null && {
                                label: formData.mCountry,
                                value: formData.mCountry,
                              }
                            }
                            options={this.arrayToOptions(countries)}
                            onChange={(e) => {
                              handleChange(e, 'mCountry', [
                                'mProvince',
                                'mDistrict',
                                'mCity',
                                'mAddress',
                              ])
                            }}
                            placeholder={'Select Country'}
                          />
                        </div>
                      </div>
                      {formData.mCountry === 'Pakistan' ? (
                        <div className={className}>
                          <label>Province</label>
                          <div className="filterInputWrap">
                            <Select
                              name={'mProvince'}
                              key={Math.random(10)}
                              closeMenuOnSelect={true}
                              className={'select-control'}
                              defaultValue={
                                formData.mProvince !== '' &&
                                formData.mProvince !== null && {
                                  label: formData.mProvince,
                                  value: formData.mProvince,
                                }
                              }
                              options={this.arrayToOptions(province)}
                              onChange={(e) => {
                                handleChange(e, 'mProvince')
                              }}
                              placeholder={'Select Province'}
                            />
                          </div>
                        </div>
                      ) : (
                        <div className={className}>
                          <label>Province</label>
                          <div className="filterInputWrap">
                            <input
                              type="text"
                              className={'form-control'}
                              placeholder={'Enter Province'}
                              name={'mProvince'}
                              onChange={(e) => {
                                handleChange(e)
                              }}
                              value={formData.mProvince}
                              required
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className={'mailAddressContainer row'}>
                      <div className={className}>
                        <label>District</label>
                        <div className="filterInputWrap">
                          <input
                            type="text"
                            className={"form-control"}
                            placeholder={"Enter District"}
                            name={"mDistrict"}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            value={formData.mDistrict}
                            required
                          />
                        </div>
                      </div>
                      <div className={className}>
                        <label>City</label>
                        <div className="filterInputWrap">
                          <input
                            type="text"
                            className={"form-control"}
                            placeholder={"Enter City"}
                            name={"mCity"}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            value={formData.mCity}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className={"mailAddressContainer row"}>
                      <div className={classNameAddress}>
                        <label>Address</label>
                        <div className="filterInputWrap">
                          <textarea
                            type="text"
                            className={"form-control"}
                            placeholder={"Enter Address"}
                            name={"mAddress"}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            rows={4}
                            value={formData.mAddress}
                            required
                          />
                        </div>
                      </div>
                    </div>

                    <br />
                    <label className="headers">Permanent Address</label>
                    <div className={"permAddressContainer row"}>
                      <div className={className}>
                        <label>Country</label>
                        <div className="filterInputWrap">
                          <Select
                            name={"country"}
                            key={Math.random(10)}
                            closeMenuOnSelect={true}
                            className={"select-control"}
                            defaultValue={
                              formData.country != "" &&
                              formData.country != null && {
                                label: formData.country,
                                value: formData.country,
                              }
                            }
                            options={this.arrayToOptions(countries)}
                            onChange={(e) => {
                              handleChange(e, "country", [
                                "province",
                                "district",
                                "city",
                                "address",
                              ]);
                            }}
                            placeholder={"Select Country"}
                          />
                        </div>
                      </div>
                      {formData.country === "Pakistan" ? (
                        <div className={className}>
                          <label>Province</label>
                          <div className="filterInputWrap">
                            <Select
                              name={"province"}
                              key={Math.random(10)}
                              closeMenuOnSelect={true}
                              className={"select-control"}
                              defaultValue={
                                formData.province !== "" &&
                                formData.province !== null && {
                                  label: formData.province,
                                  value: formData.province,
                                }
                              }
                              options={this.arrayToOptions(province)}
                              onChange={(e) => {
                                handleChange(e, "province");
                              }}
                              placeholder={"Select Province"}
                            />
                          </div>
                        </div>
                      ) : (
                        <div className={className}>
                          <label>Province</label>
                          <div className="filterInputWrap">
                            <input
                              type="text"
                              className={"form-control"}
                              placeholder={"Enter Province"}
                              name={"province"}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              value={formData.province}
                              required
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className={'permAddressContainer row'}>
                      <div className={className}>
                        <label>District</label>
                        <div className="filterInputWrap">
                          <input
                            type="text"
                            className={'form-control'}
                            placeholder={'Enter District'}
                            name={'district'}
                            onChange={(e) => {
                              handleChange(e)
                            }}
                            value={formData.district}
                            required
                          />
                        </div>
                      </div>
                      <div className={className}>
                        <label>City</label>
                        <div className="filterInputWrap">
                          <input
                            type="text"
                            className={'form-control'}
                            placeholder={'Enter City'}
                            name={'city'}
                            onChange={(e) => {
                              handleChange(e)
                            }}
                            value={formData.city}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className={'permAddressContainer row'}>
                      <div className={classNameAddress}>
                        <label>Address</label>
                        <span className="text-danger">*</span>
                        <div className="filterInputWrap">
                          <textarea
                            type="text"
                            className={
                              (!!urlParams.get('ci') && (formData.address === "" || formData.address === null)) ? "invalid form-control" : "form-control"
                            }

                            placeholder={"Enter Address"}
                            name={"address"}
                            onChange={(e) => {
                              handleChange(e)
                            }}
                            rows={4}
                            value={formData.address}
                            required
                          />
                          {!!urlParams.get('ci') && (formData.address === "" || formData.address === null) && (
                            <p className={"requiredCheck"}>Required</p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div></div>
                    <br />
                    <label className="headers">Family Information</label>
                    <div className={'permAddressContainer row'}>
                      <div className={className}>
                        <label>Next of Kin Name</label>
                        <span className={`text-danger`}> *</span>
                        <div className="filterInputWrap">
                          <input
                            type="text"
                            className={
                              (!!urlParams.get('ci') && (formData.nextOfKin === "" || formData.nextOfKin === null)) ? "invalid form-control" : "form-control"
                            }                            placeholder={"Next of Kin"}
                            name={"nextOfKin"}
                            onChange={(e) => {
                              handleChange(e)
                            }}
                            value={formData.nextOfKin}
                            required
                          />
                          {!!urlParams.get('ci') && (formData.nextOfKin === "" || formData.nextOfKin === null) && (
                            <p className={"requiredCheck"}>Required</p>
                          )}
                        </div>
                      </div>
                      <div className={className}>
                        <label>Relation With Kin</label>
                        <span className={`text-danger`}> *</span>
                        <div className="filterInputWrap">
                          <input
                            type="text"
                            className={
                              (!!urlParams.get('ci') && (formData.kinRelation === "" || formData.kinRelation === null)) ? "invalid form-control" : "form-control"
                            }                            placeholder={"Relation with Kin"}
                            name={"kinRelation"}
                            onChange={(e) => {
                              handleChange(e)
                            }}
                            value={formData.kinRelation}
                            required
                          />
                          {!!urlParams.get('ci') && (formData.kinRelation === "" || formData.kinRelation === null) && (
                            <p className={"requiredCheck"}>Required</p>
                          )}
                        </div>
                      </div>
                    </div>
                    {/*<div className={'permAddressContainer row'}>
                      <div className={className}>
                        <label>Kin CNIC Front</label>
                        <div className="filterInputWrap">
                          <input
                            type="file"
                            className={
                              validationCheck === true &&
                              (formData.cnic === '' || formData.cnic.length < 13)
                                ? `form-control invalid`
                                : `form-control`
                            }
                            placeholder={'Enter Kin CNIC Front'}
                            name={'kinCnicFront'}
                            onChange={(e) => {
                              handleChange(e)
                            }}
                            value={formData.kinCnicFront}
                            required
                          />
                        </div>
                      </div>
                      <div className={className}>
                        <label>Kin CNIC Back</label>
                        <div className="filterInputWrap">
                          <input
                            type="file"
                            className={'form-control'}
                            placeholder={'Enter Kin CNIC Back'}
                            name={'kinCincBack'}
                            onChange={(e) => {
                              handleChange(e)
                            }}
                            value={formData.kinCincBack}
                            required
                          />
                        </div>
                      </div>
                    </div>*/}
                    <div className={'permAddressContainer row'}>
                      <div className={className}>
                        <label>Mother Name</label>
                        <div className="filterInputWrap">
                          <input
                            type="text"
                            className={'form-control'}
                            placeholder={'Enter Mother Name'}
                            name={'motherName'}
                            onChange={(e) => {
                              handleChange(e)
                            }}
                            value={formData.motherName}
                            required
                          />
                        </div>
                      </div>
                      <div className={className}>
                        <label>Kin CNIC</label>
                        <div className="filterInputWrap">
                          <input
                            type="text"
                            className={
                              validationCheck === true &&
                              (formData && formData.cnic && (formData.cnic === '' || formData.cnic.length < 13))
                                ? `form-control invalid`
                                : `form-control`
                            }
                            placeholder={'Kin CNIC'}
                            name={'kinCnic'}
                            required
                            onChange={(e) => {
                              handleChange(e)
                            }}
                            value={normalizeCnic(formData.kinCnic)}
                          />
                          {validationCheck === true && formData.kinCnic === '' && (
                            <p className={'requiredCheck'}>Required</p>
                          )}
                          {cnicValidation === true &&
                          (formData.kinCnic && ((
                            formData.kinCnic !== '' &&
                            formData.kinCnic.length) ||
                            formData.kinCnic.length === 7 ||
                            formData.kinCnic.length === 8 ||
                            formData.kinCnic.length >= 13)) ? (
                            <p className={'requiredCheck'}>Invalid CNIC/NTN format</p>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    </div>
                    <div className={'permAddressContainer row'}>

                      <div className={className}>
                        <label>Kin Contact</label>
                        <span className={`text-danger`}> *</span>
                        <div
                          className={`filterInputMain`}
                          // onClick={() => {
                          //   this.setState({ maxIndex: 3 })
                          // }}
                        >
                          <div className={`filterInputWrap ${maxIndex === 3 ? 'max-index' : ''}`}>
                            <PhoneInput
                              placeholder="Enter phone number"
                              enableLongNumbers={true}
                              country={"pk"}
                              inputProps={{
                                name: 'kinContact',
                                // required: true,
                                // autoFocus: true
                              }}
                              value={
                                kinContact && kinContact.kinContact !== ""
                                  ? removeZero(kinContact.kinContact,kinContact.dialCode)
                                  // ? kinContact.kinContact
                                  : ""
                              }
                              inputClass={
                                (!!urlParams.get('ci') && kinContact && kinContact.kinContact && kinContact.kinContact.length < 13)
                                  ? `MoreFilterinputText invalid`
                                  : ` MoreFilterinputText`
                              }
                              onChange={handleChangeKin}
                              countryCodeEditable={false}
                            />
                            {kinPhoneValidate === true && (
                              <p className={"requiredCheck"}>
                                Enter Correct Phone Number
                              </p>
                            )}
                            {!!urlParams.get('ci') && ( !kinContact || kinContact.kinContact === "" || kinContact.kinContact === null) && (
                            <p className={"requiredCheck"}>Required</p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className={className}>
                        <label>Kin Email</label>
                        <div className={`filterInputMain`}>
                          <div className="filterInputWrap">
                            <input
                              type="email"
                              className={'form-control'}
                              placeholder={'kin Email'}
                              name={'kinEmail'}
                              required
                              onChange={(e) => {
                                handleChange(e)
                              }}
                              value={formData.kinEmail}
                            />
                            {emailValidate === true && formData.kinEmail != '' && (
                              <p className={'requiredCheck'}>Email is not valid</p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={"permAddressContainer row"}>
                    <div className={className}>
                        <label>Kin CNIC Front</label>
                        <div className="filterInputWrap">
                        <input
                            name="kinCnicFront"
                            id="abc"
                            style={{
                              padding: 0,
                              color: "black",
                              width: '100%',
                              height: '100%',
                              cursor: 'pointer',
                            }}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            type="file"
                            accept="image/png, image/jpeg"
                            className="form-control attachment--input"
                          />
                          {formData.kinCnicFront && !formData.kinCnicFront.name &&
                          <>
                            Uploaded
                            <a target={"_blank"} href={formData.kinCnicFront} style={{marginLeft: "6px", fontSize: "smaller"}}>(VIEW)</a>
                          </>
                          }
                        </div>
                      </div>
                      <div className={className}>
                        <label>Kin CNIC Back</label>
                        <div className="filterInputWrap">
                        <input
                            name="kinCnicBack"
                            id="abc"
                            style={{
                              padding: 0,
                              color: "black",
                              width: '100%',
                              height: '100%',
                              cursor: 'pointer',
                            }}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            type="file"
                            accept="image/png, image/jpeg"
                            className="form-control attachment--input"
                          />
                          {formData.kinCnicBack && !formData.kinCnicBack.name &&
                          <>
                            Uploaded
                            <a target={"_blank"} href={formData.kinCnicBack} style={{marginLeft: "6px", fontSize: "smaller"}}>(VIEW)</a>
                          </>
                          }
                        </div>
                      </div>
                    </div>
                    <br />
                    <label className="headers">Bank Information</label>
                    <div className={'permAddressContainer row'}>
                      <div className={className}>
                        <label>Bank Name</label>
                        <div className="filterInputWrap">
                          <input
                            type="text"
                            className={'form-control'}
                            placeholder={'Enter Bank Name'}
                            name={'bank'}
                            onChange={(e) => {
                              handleChange(e)
                            }}
                            value={formData.bank}
                            required
                          />
                        </div>
                      </div>
                      <div className={className}>
                        <label>IBAN</label>
                        <div className="filterInputWrap">
                          <input
                            type="text"
                            className={'form-control'}
                            placeholder={'Enter IBAN'}
                            name={'iBan'}
                            onChange={(e) => {
                              handleChange(e)
                            }}
                            value={formData.iBan}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className={'permAddressContainer row'}>
                      <div className={className}>
                        <label>Account No</label>
                        <div className="filterInputWrap">
                          <input
                            type="number"
                            className={'form-control'}
                            placeholder={'Enter Account No'}
                            name={'accountNo'}
                            onChange={(e) => {
                              handleChange(e)
                            }}
                            value={formData.accountNo}
                            required
                          />
                        </div>
                      </div>
                      <div className={className}>
                        <label>Account Title</label>
                        <div className="filterInputWrap">
                          <input
                            type="text"
                            className={'form-control'}
                            placeholder={'Enter Account Title'}
                            name={'accountTitle'}
                            onChange={(e) => {
                              handleChange(e)
                            }}
                            value={formData.accountTitle}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className={'permAddressContainer row'}>
                    <div className={className}>
                      <label>Account Type</label>
                      <div className="clientDateContainer mb-3">
                        <Select
                          closeMenuOnSelect={true}
                          key={Math.random(10)}
                          className={'select-control'}
                          defaultValue={
                            formData.accountType != '' &&
                            formData.accountType != null && {
                              label: formData.accountType,
                              value: formData.accountType,
                            }
                          }
                          options={this.arrayToOptions(StaticData.accountType)}
                          name={'accountType'}
                          onChange={(e) => {
                            handleChange(e, 'accountType')
                          }}
                          placeholder="Account Type"
                        />
                      </div>
                     </div>
                      <div className={className}>
                        <label>Filer</label>
                        <div className="clientDateContainer mb-3">
                          <Select
                            closeMenuOnSelect={true}
                            key={Math.random(10)}
                            className={'select-control'}
                            defaultValue={
                              formData.filer != null && {
                                label: formData.filer === true || formData.filer === 'Yes' ? 'Yes' : 'NO',
                                value: formData.filer === true || formData.filer === 'Yes' ? 'Yes' : 'NO',
                              }
                            }
                            options={this.arrayToOptions(StaticData.filer)}
                            name={'filer'}
                            onChange={(e) => {
                              handleChange(e, 'filer')
                            }}

                            placeholder="Filer"
                          />
                        </div>
                      </div>
                    </div>

                    <label className="headers">Other</label>
                    <span className={`text-danger`}> * </span>
                    <br></br>
                    <span className={`text-danger`}>All fields are mandatory</span>
                    <div className="row mt-4 p-2">
                      <div className="col-6">
                        <div className="permAddressContainer row">
                          <label className="ml-2">Source of Income</label>{" "}
                          <span className={`text-danger`}> *</span>
                  </div>
                        <div className={"permAddressContainer row"}>
                          <div className="popup-body-header-title mt-2 ml-1">
                            <span
                              style={{ cursor: "pointer" }}
                              // onClick={() => waterMarkHandlerInner()}
                            >
                              <input
                                type="checkbox"
                                value="salaried"
                                className="checkbox-container mr-1 cursor-pointer"
                                checked={this.checkData("salaried", soi)}
                                onClick={(e) => SourceOfIncomehandle(e)}
                              />{" "}
                              Salaried
                            </span>
                </div>

                          <div className="popup-body-header-title mt-2 ml-1">
                            <span
                              style={{ cursor: "pointer" }}
                              // onClick={() => waterMarkHandlerInner()}
                            >
                              <input
                                type="checkbox"
                                value="business"
                                className="checkbox-container mr-1 cursor-pointer"
                                checked={this.checkData("business", soi)}
                                // checked={soi.filter((data)=>{ if(data === "business") { return true} else{ return false}  }) }
                                onClick={(e) => SourceOfIncomehandle(e)}
                              />{" "}
                              Business
                            </span>
              </div>

                          <div className="popup-body-header-title mt-2 ml-1">
                            <span
                              style={{ cursor: "pointer" }}
                              // onClick={() => waterMarkHandlerInner()}
                            >
                              <input
                                type="checkbox"
                                value="property"
                                className="checkbox-container mr-1 cursor-pointer"
                                checked={this.checkData("property", soi)}
                                onClick={(e) => SourceOfIncomehandle(e)}
                              />{" "}
                              Property
                            </span>
                          </div>

                          <div className="popup-body-header-title mt-2 ml-1">
                            <span
                              style={{ cursor: "pointer" }}
                              // onClick={() => waterMarkHandlerInner()}
                            >
                              <input
                                type="checkbox"
                                value="foreign_source"
                                className="checkbox-container mr-1 cursor-pointer"
                                checked={this.checkData("foreign_source", soi)}
                                onClick={(e) => SourceOfIncomehandle(e)}
                              />{" "}
                              Foreign Source
                            </span>
                          </div>

                          <div
                            onClick={(e) => {

                              this.setState({ soiOther: e.target.checked , handlerClick:true});
                              otherSourceOfIncomeHandler("")
                            }}

                          className="popup-body-header-title mt-2 ml-1">
                            <span
                              style={{ cursor: "pointer" }}
                              // onClick={() => waterMarkHandlerInner()}

                            >
                              <input
                                type="checkbox"
                                name="other"
                                className="checkbox-container mr-1 cursor-pointer"
                                checked={soiOther}
                                onClick={(e) => {
                                  this.setState({ soiOther: e.target.checked, handlerClick:true });
                                  otherSourceOfIncomeHandler("")
                                }}
                              />{" "}
                              Other
                            </span>
                          </div>
                        </div>

                        <div className="permAddressContainer row">
                          {soiOther && (
                            <div className="col-md-6 mt-2">
                              <label>Source of Income other Textbox:</label>
                              <div className="filterInputWrap">
                                <input
                                  type="text"
                                  className={"form-control"}
                                  value={otherSourceOfIncome}
                                  placeholder={"other source of income"}
                                  onChange={(e) =>
                                    otherSourceOfIncomeHandler(e.target.value)
                                  }
                                  required
                                />
                              </div>
                            </div>
                          )}
                        </div>

                        <div className="permAddressContainer row mt-4">
                          {/* {isPaymentLegal ? ( */}
                          <div className="input__main ml-2">
                            <label>Association with any political party</label>
                            <span className={`text-danger`}> *</span>
                            <div className="input__inputText">
                              <div className="form-check-inline">
                                <label
                                  className="form-check-label"
                                  htmlFor="radio1"
                                >
                                  <i></i>
                                  <input
                                    type="radio"
                                    className="form-check-input cursor-pointer"
                                    value="true"
                                    checked={
                                      AssociationWithanypoliticalparty === true
                                        ? true
                                        : false
                                    }
                                    onChange={(e) => {
                                      AssociationWithanypoliticalpartyHandler(
                                        e.target.value
                                      );
                                    }}
                                  />
                                  Yes
                                </label>
                              </div>
                              <div className="form-check-inline">
                                <label
                                  className="form-check-label"
                                  htmlFor="radio2"
                                >
                                  <i></i>
                                  <input
                                    type="radio"
                                    className="form-check-input cursor-pointer"
                                    checked={
                                      AssociationWithanypoliticalparty === false
                                        ? true
                                        : false
                                    }
                                    value="false"
                                    onChange={(e) => {
                                      AssociationWithanypoliticalpartyHandler(
                                        e.target.value
                                      );
                                    }}
                                  />
                                  No
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="permAddressContainer row">
                          {/* {isPaymentLegal ? ( */}
                          <div className="input__main ml-2">
                            <label>Are you an existing client of Imarat Group?</label>
                            <span className={`text-danger`}> *</span>
                            <div className="input__inputText">
                              <div className="form-check-inline">
                                <label
                                  className="form-check-label"
                                  htmlFor="radio1"
                                >
                                  <i></i>
                                  <input
                                    type="radio"
                                    className="form-check-input cursor-pointer"
                                    value="true"
                                    checked={
                                      ExistingImaratClient === true
                                        ? true
                                        : false
                                    }
                                    onChange={(e) => {
                                      ExistingImaratClientHandler(
                                        e.target.value
                                      );
                                    }}
                                  />
                                  Yes
                                </label>
                              </div>
                              <div className="form-check-inline">
                                <label
                                  className="form-check-label"
                                  htmlFor="radio2"
                                >
                                  <i></i>
                                  <input
                                    type="radio"
                                    className="form-check-input cursor-pointer"
                                    checked={
                                      ExistingImaratClient === false
                                        ? true
                                        : false
                                    }
                                    value="false"
                                    onChange={(e) => {
                                      ExistingImaratClientHandler(
                                        e.target.value
                                      );
                                    }}
                                  />
                                  No
                                </label>
                              </div>
                            </div>
                          </div>
                          {
                          // ExistingImaratClient &&
                            <div className={classNameAddress}>
                              <label>Details</label>
                              <div className="filterInputWrap">
                                <textarea
                                  type="text"
                                  className={"form-control"}
                                  placeholder={"Enter Details"}
                                  name={"existingClientInfo"}
                                  onChange={(e) => {
                                    handleChange(e);
                                  }}
                                  rows={4}
                                  value={formData.existingClientInfo}
                                  required
                                />
                              </div>
                            </div>
                          }

                        </div>

                      </div>

                      <div className="col-6">
                        <div className="permAddressContainer row">
                          <label className="ml-2">Source of funds</label>{" "}
                          <span className={`text-danger`}> *</span>
                        </div>
                        <div className={"permAddressContainer row"}>
                          <div className="popup-body-header-title mt-2 ml-1">
                            <span
                              style={{ cursor: "pointer" }}
                              // onClick={() => waterMarkHandlerInner()}
                            >
                              <input
                                type="checkbox"
                                className="checkbox-container mr-1 cursor-pointer"
                                value="form_loan"
                                checked={this.checkData("form_loan", sof)}
                                onClick={(e) => SourceOfFundsHandler(e)}
                              />{" "}
                              From Loan
                            </span>
                          </div>

                          <div className="popup-body-header-title mt-2 ml-1">
                            <span
                              style={{ cursor: "pointer" }}
                              // onClick={() => waterMarkHandlerInner()}
                            >
                              <input
                                type="checkbox"
                                className="checkbox-container mr-1 cursor-pointer"
                                value="saving"
                                checked={this.checkData("saving", sof)}
                                onClick={(e) => SourceOfFundsHandler(e)}
                              />{" "}
                              Saving
                            </span>
                          </div>

                          <div className="popup-body-header-title mt-2 ml-1">
                            <span
                              style={{ cursor: "pointer" }}
                              // onClick={() => waterMarkHandlerInner()}
                            >
                              <input
                                type="checkbox"
                                className="checkbox-container mr-1 cursor-pointer"
                                value="business_income"
                                checked={this.checkData("business_income", sof)}
                                onClick={(e) => SourceOfFundsHandler(e)}
                              />{" "}
                              Business Income
                            </span>
                          </div>

                          <div  className="popup-body-header-title mt-2 ml-1"
                            onClick={()=>{
                              this.setState({handlerClick:true});
                              otherSourceOfFundsHandler('')
                            }}
                          >
                            <span
                              style={{ cursor: "pointer" }}
                              // onClick={() => waterMarkHandlerInner()}
                            >
                              <input
                                type="checkbox"
                                name="specifiedOther"
                                className="checkbox-container mr-1 cursor-pointer"
                                checked={sofOther}
                                onClick={(e) => {
                                  this.setState({ sofOther: e.target.checked,handlerClick:true});
                                  otherSourceOfFundsHandler('')
                                }}
                              />{" "}
                              Other to be specified
                            </span>
                          </div>
                        </div>

                        <div className="permAddressContainer row">
                          {sofOther && (
                            <div className="col-md-6 mt-2">
                              <label>Source of Funds</label>
                              <div className="filterInputWrap">
                                <input
                                  type="text"
                                  className={"form-control"}
                                  placeholder={"Other to be specified"}
                                  name={"iBan"}
                                  value={sourceOfFunds}
                                  onChange={(e) => {
                                    otherSourceOfFundsHandler(e.target.value);
                                  }}
                                  // value={formData.iBan}
                                  required
                                />
                              </div>
                            </div>
                          )}
                        </div>

                        <div className="permAddressContainer row mt-4">
                          {/* {isPaymentLegal ? ( */}
                          <div className="input__main ml-2">
                            <label>Registration with FBR</label>
                            <span className={`text-danger`}> *</span>
                            <div className="input__inputText">
                              <div className="form-check-inline">
                                <label
                                  className="form-check-label"
                                  htmlFor="radio1"
                                >
                                  <i></i>
                                  <input
                                    type="radio"
                                    // disabled={paymentViewMode}
                                    className="form-check-input cursor-pointer"
                                    value="true"
                                    checked={
                                      RegisterWithFBR === true ? true : false
                                    }
                                    onChange={(e) => {
                                      RegisterWithFBRHandler(e.target.value);
                                    }}
                                  />
                                  Yes
                                </label>
                              </div>
                              <div className="form-check-inline">
                                <label
                                  className="form-check-label"
                                  htmlFor="radio2"
                                >
                                  <i></i>
                                  <input
                                    type="radio"
                                    className="form-check-input cursor-pointer"
                                    value="false"
                                    checked={
                                      RegisterWithFBR === false ? true : false
                                    }
                                    onChange={(e) => {
                                      RegisterWithFBRHandler(e.target.value);
                                    }}
                                  />
                                  No
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="permAddressContainer row">
                          {/* {isPaymentLegal ? ( */}
                          <div className="input__main ml-2">
                            <label>Investment in other projects</label>
                            <span className={`text-danger`}> *</span>
                            <div className="input__inputText">
                              <div className="form-check-inline">
                                <label
                                  className="form-check-label"
                                  htmlFor="radio1"
                                >
                                  <i></i>
                                  <input
                                    type="radio"
                                    className="form-check-input cursor-pointer"
                                    value="true"
                                    checked={
                                      OtherProjectsInv === true
                                        ? true
                                        : false
                                    }
                                    onChange={(e) => {
                                      OtherProjectsInvHandler(
                                        e.target.value
                                      );
                                    }}
                                  />
                                  Yes
                                </label>
                              </div>
                              <div className="form-check-inline">
                                <label
                                  className="form-check-label"
                                  htmlFor="radio2"
                                >
                                  <i></i>
                                  <input
                                    type="radio"
                                    className="form-check-input cursor-pointer"
                                    checked={
                                      OtherProjectsInv === false
                                        ? true
                                        : false
                                    }
                                    value="false"
                                    onChange={(e) => {
                                      OtherProjectsInvHandler(
                                        e.target.value
                                      );
                                    }}
                                  />
                                  No
                                </label>
                              </div>
                            </div>
                          </div>

                          {
                            // OtherProjectsInv &&
                            <div className={classNameAddress}>
                              <label>Details</label>
                              <div className="filterInputWrap">
                                <textarea
                                  type="text"
                                  className={"form-control"}
                                  placeholder={"Enter Details"}
                                  name={"otherInvestmentInfo"}
                                  onChange={(e) => {
                                    handleChange(e);
                                  }}
                                  rows={4}
                                  value={formData.otherInvestmentInfo}
                                  required
                                />
                              </div>
                            </div>
                          }

                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {!editAble &&
                isBookingFor &&
                (this.state.isBuyRent || this.state.isProjectLeads) &&
                selectedLeadType !== null && (
                  <div class="card" style={{ marginTop: "20px" }}>
                  <div class="card-header">
                    <div className="accordionHeader">
                        <p
                          class="panel-title"
                          data-toggle="collapse"
                          data-target="#collapsetwo"
                        >
                        Add Lead Requirements
                      </p>
                    </div>
                  </div>
                  <div id="collapsetwo" className="collapse show" data-parent="#accordion">
                    <div class="card-body lead-body">
                      <AddLead isModal={isModal} selectedLeadType = { selectedLeadType } />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={`filterInputMain `}>
          <div className="registerButtons">
            <Button
              text={"Cancel"}
              textColor={"black"}
              width={"90px"}
              height={"30px"}
              display={"inline-block"}
              buttonColor="#fff"
              className={"px-4 rounded-pill small cancelClient"}
              style={{ border: "1px solid #d3d3d3" }}
              onClick={() => {
                urlParams.get('ci') ?
                this.props.history.push(`/booking/ci?clientId=${urlParams.get('clientId')}&leadId=${urlParams.get('leadId')}`)
                :
                fromPage
                  ? clientModalToggle()
                  : this.props.history.push("/clientManagement");
              }}
            />
            <Button
              text={editAble === true ? "Update" : "Register"}
              loader={loadingOnsubmit}
              textColor={"white"}
              width={"90px"}
              height={"30px"}
              display={"inline-block"}
              buttonColor="#026ff2"
              className={"px-4 rounded-pill mr-2 small registerClient"}
              onClick={() => {
                return createClient(selectedLeadType);
              }}
            />
          </div>
        </div>
      </Fragment>
    )
  }
}
export const mapDispatchToProps = (dispatch) => {
  return {
    // fetchCities: () => dispatch(fetchCities()),
    // fetchAllProjects: () => dispatch(fetchAllProjects()),
    // addLeadFunc: (data) => dispatch(addLead(data, true)),
    // addBuyRentLeadFunc: (data) => dispatch(addBuyRentLead(data, true)),
    setLeadType: (leadType) => dispatch(setLeadType(leadType)),
    // fetchAreas: (cityId) => dispatch(fetchAreas(cityId)),
    // fetchSubProperties: (property) => dispatch(fetchSubProperties(property)),
    setLeadClientError: (property) => dispatch(setLeadClientError(property)),
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
   userInfo: state.user.userInfo ,
    // userClients: state.Leads.userClients,
    // cities: state.user.cities,
    // allProjects: state.Projects.allProjects,
    // addLeadLoader: state.Leads.addLeadLoader,
    leadType: state.user.leadType,
    // client: state.user.client,
    // areas: state.Areas.allAreas,
    // subProperties: state.Property.subProperties,


  }
}
// export const mapStateTopProps = (state, ownProps) => {
//   return { userInfo: state.user.userInfo }
// }

// export const mapDispatchTopProps = (dispatch) => {
//   return {}
// }

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Inner));
