/** @format */

import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { fetchShortlistedProperties } from '../../../actions/property'
import ShortListedPropertiesTile from '../../../components/BuyRentLeadTile/ShortListedPropertiesTile'
import './../../Properties/properties-style.scss'
import _ from 'lodash'
import { Modal, ModalBody } from 'reactstrap'
import { fetchAllShortlistedProperties } from '../../../actions/leads'
import Load from '../../Loader'
const PropertySelection = ({
  addShortlistedPropertiesLoader,
  shortListedProperties,
  isOpen,
  setOpen,
  leadId,
  fetchAllShortlistedProperties,
  onPropertySelection,
}) => {
  const [selectedPropertyID, setSelectedPropertyID] = useState(null)
  const [isDisbale, setIsDisable] = useState(true)
  const [isLoading, setLoading] = useState(true)

  const onContinue = () => {
    const property = shortListedProperties.rows.filter(
      (element) => element.id === selectedPropertyID
    )
    if (property.length) {
      onPropertySelection(property[0])
    }
    setOpen(false)
  }
  useEffect(() => {
    setLoading(true)
    fetchAllShortlistedProperties(leadId, () => {
      setLoading(false)
    })
  }, [])
  return (
    <Modal
      isOpen={isOpen}
      toggle={(toggle) => {
        setOpen(!toggle)
      }}
      contentClassName={`no-custom`}
      size={'xl'}
    >
      <ModalBody className={'d-flex flex-column'}>
        <div className="close-icon-container">
          <i
            className="fal fa-times-circle close-icon"
            onClick={() => {
              setOpen(false)
            }}
          ></i>
        </div>
        <>
          <h4>Select Property</h4>
          {isLoading && (
            <div>
              <div className="text-center" style={{ marginTop: 60, marginBottom: 50 }}>
                <Load title="Properties"></Load>
              </div>
            </div>
          )}
          {!isLoading && (
            <>
              {shortListedProperties &&
              shortListedProperties.rows &&
              shortListedProperties.rows.length > 0 ? (
                <div className="mt-2">
                  <ShortListedPropertiesTile
                    rows={
                      shortListedProperties &&
                      shortListedProperties.rows &&
                      shortListedProperties.rows.length > 0
                        ? shortListedProperties.rows
                        : []
                    }
                    selectedPropertyID={selectedPropertyID}
                    setSelectedPropertyID={(val) => {
                      console.log(val)
                      setSelectedPropertyID(val)
                    }}
                    setIsDisable={setIsDisable}
                    isDisbale={isDisbale}
                  />
                </div>
              ) : (
                <div className="dash-container br-lead-page-wrap">
                  <div className="text-center" style={{ marginTop: 60, marginBottom: 50 }}>
                    <h3 className="dimgray">No Properties Found</h3>
                  </div>
                </div>
              )}
              <div
                className="match-properties-button-container"
                style={{ position: 'sticky', bottom: '20px', justifyContent: 'flex-end' }}
              >
                <div className="match-properties-button">
                  <button
                    className="selected-matches"
                    onClick={onContinue}
                    disabled={isDisbale}
                    style={{ marginTop: '20px', width: '150px' }}
                  >
                    {addShortlistedPropertiesLoader ? (
                      <i className={`fa fa-circle-o-notch fa-spin`}></i>
                    ) : (
                      'Continue'
                    )}
                  </button>
                </div>
              </div>
            </>
          )}
        </>
      </ModalBody>
    </Modal>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchAllShortlistedProperties: (leadId, getPropertyCallBack) =>
      dispatch(fetchAllShortlistedProperties(leadId, getPropertyCallBack)),
  }
}

const mapStateToProps = (state) => {
  return {
    shortListedProperties: state.Leads.buyRentShortListProperties,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PropertySelection)
